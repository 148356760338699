<template>
  <div v-bind:class="[flipped ? 'flip-container flipped' : 'flip-container']" class="column pool-main is-centered mb-4 mt-5">
    <section class="hero" style="margin-top: 20px;">
      <div class="hero-body" style="padding: 0px !important;">
        <div class="container animated fadeInUp" style="border-radius: 2rem;     box-shadow: rgb(44 52 60 / 30%) 0px 0px 0px 3px;">
          <div class="columns is-multiline is-mobile"
               style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
            <div
                class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                style="border-radius: 2rem;">
              <div class="columns is-flex-direction-column is-justify-content-center">


                <div class="column is-12">
                  <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                    <ul class="signature-content has-text-left">
                      <li><i><b-icon pack="fas" icon="question-circle" size="is-small"></b-icon></i><span style="font-weight: bold;">Pool</span>: {{ pool_name }} </li>
                      <li><i><b-icon pack="fas" icon="institution" size="is-small"></b-icon></i><span style="font-weight: bold;">Account</span>: {{ treasury_account }}</li>
                      <li><i><b-icon pack="fas" icon="tint" size="is-small"></b-icon></i><span style="font-weight: bold;">{{tokenA}} Balance</span>: {{treasury_account_balanceA }}</li>
                      <li v-if="treasury_account_balanceB !== '?'"><i><b-icon pack="fas" icon="tint" size="is-small"></b-icon></i><span style="font-weight: bold;">{{tokenB}} Balance</span>: {{treasury_account_balanceB }}</li>
                    </ul>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
//import vue store variables to this page
import {mapGetters} from "vuex";

export default {
  name: "PoolCard",
  components: {

  },
  props: {
    accountApproved: {
      type: Boolean,
      default: false,
    },
    pool_name: {
      type: String,
      default: "0",
    },
    pool_id: {
      type: [String, Number],
      default: "0"
    },
    treasury_account: {
      type: String,
      default: "0",
    },
    treasury_account_balanceA: {
      type: [String, Number],
      default: "0",
    },
    treasury_account_balanceB: {
      type: [String, Number],
      default: "0",
    },
    tokenA: {
      type: [String, Object],
      default: "0",
    },
    tokenB: {
      type: [String, Object],
      default: "0",
    },
    reserveA: {
      type: [String, Number],
      default: "0",
    },
    reserveB: {
      type: [String, Number],
      default: "0",
    }

  },
  data: function () {
    return {
      debug: false,
      flipped: false,
      showStakeControls: false,
      stakeAmount: 0.0
    };
  },
  computed: {},
  methods: {
    ...mapGetters("accounts", ["getAccountExists", "getAccountConfirmed"]),

    number_test(n) {
      let result = (n - Math.floor(n)) !== 0;

      if (result) {
        if (this.debug) {
          console.log("Contains Decimal Place");
        }
        return true;
      } else {
        if (this.debug) {
          console.log("No decimal place");
        }
        return false;
      }

    },

    async clickWithdraw(pool_contract){
      if(this.debug){
        console.log("CLAIMING POOL REWARDS")
        console.log(pool_contract);
      }

      let payload = {
        poolID: this.pool_id,
        accountName: this.user_account,
        moduleName: this.pool_stake_token
      }

      if(this.debug){
        console.log(payload);
      }

      if(pool_contract === "factory-stake-fungiv2"){
        await this.$store.dispatch("accounts/withdrawFactoryStakeFungiv2", payload);
      }else if(pool_contract === "factory-stake-tokens-fungiv2"){
        await this.$store.dispatch("accounts/withdrawFactoryStakeAswapTokensFungiv2", payload);
      }

    },

    async clickClaimRewards(pool_contract){
      if(this.debug){
        console.log("CLAIMING POOL REWARDS")
        console.log(pool_contract);
      }

      let payload = {
        poolID: this.pool_id,
        accountName: this.user_account,
        moduleName: this.pool_stake_token
      }

      if(this.debug){
        console.log(payload);
      }

      if(pool_contract === "factory-stake-fungiv2"){
        await this.$store.dispatch("accounts/claimRewardsFactoryStakeFungiv2", payload);
      }else if(pool_contract === "factory-stake-tokens-fungiv2"){
        await this.$store.dispatch("accounts/claimRewardsFactoryStakeAswapTokensFungiv2", payload);
      }

    },

    async clickStake(pool_contract) {

      if(this.debug){
        console.log("CREATING NEW STAKE")
        console.log(pool_contract);
      }

      let payload = {
        poolID: this.pool_id,
        amount: this.stakeAmount,
        accountName: this.user_account,
        moduleName: this.pool_stake_token,
        stakeToken: this.pool_stake_token,
      }

      if(pool_contract === "factory-stake-tokens-fungiv2") {
        payload.moduleName = 'free.tokens-reference'
      }

      if(this.debug){
        console.log(payload);
      }

      if(pool_contract === "factory-stake-fungiv2"){
        await this.$store.dispatch("accounts/stakeFactoryStakeFungiv2", payload);
      } else if(pool_contract === "factory-stake-tokens-fungiv2"){
        await this.$store.dispatch("accounts/stakeFactoryStakeAswapTokensFungiv2", payload);
      }


    },

    async clickPoolControls(){
      let routeData = this.$router.resolve({path: "/pool-controls/" + this.pool_id +"/"+this.pool_contract});
      window.open(routeData.href, "_blank");
    },

  },
};
</script>

<style scoped>



.container4 {
  /* padding: 25px; */
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-color: #1a222e;
  box-shadow: rgb(50 50 93 / 25%) 0px 30px 60px -12px inset, rgb(0 0 0 / 30%) 0px 18px 36px -18px inset;
  border: 2px solid #1b222f;
}

.cow {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#14ff7d, #00f3ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
}

.cow2 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(31 86 112);
  background: -webkit-linear-gradient(#50ffd7, #00b2ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
}

.cow3 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#1cd7ce, #00f3ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
}

.cow4 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(7 8 12);
  background: -webkit-linear-gradient(#b2f8ff, #6889ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
}

.cow5 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#14ff7d, #11ff00) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
}


.email-signature{
  position: relative;
}

.email-signature:after{
  transform: rotateZ(180deg);
  top: auto;
  bottom: 0;
}
.email-signature .signature-details{
  text-align: center;
  width: 46%;
  display: inline-block;
}
.email-signature .signature-icon{
  background-color: #1BC002;
  width: 120px;
  height: 120px;
  padding: 5px;
  border-radius: 50%;
  margin: 0 auto 10px;
}
.email-signature .signature-icon img{
  width: 100%;
  border-radius: 50%;
}
.email-signature .title{
  color: #1BC002;
  font-size: 27px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 0 0 5px;
}
.email-signature .post{
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px 15px 3px;
  box-shadow: 0 0 5px rgba(255,255,255,0.1);
  display: inline-block;
}
.email-signature .signature-content{
  width: 90%;
  padding: 10px 0 15px;
  margin: 15px 0;
  list-style: none;
  vertical-align: top;
  display: inline-block;
  position: relative;
}
.email-signature .signature-content:before,
.email-signature .signature-content:after{
  content: '';
  background: #523a7b;
  height: 100%;
  width: 25px;
  position: absolute;
  left: 10px;
  top: 0;
  z-index: 1;
}
.email-signature .signature-content:after{
  background: linear-gradient(to top right, rgb(57, 36, 94) 50%, transparent 51%);
  height: 10px;
  width: 10px;
  left: 35px;
}
.email-signature .signature-content li{
  color: #44a361;
  background-color: #1b2230;
  font-size: 14px;
  padding: 6px 10px 4px 45px;
  margin-bottom: 7px;
  position: relative;
}
.email-signature .signature-content li:last-child{ margin-bottom: 0; }
.email-signature .signature-content li i{
  color: #7790e9;
  font-size: 14px;
  position: absolute;
  left: 16px;
  top: 9px;
  z-index: 2;
}
@media screen and (max-width:576px){
  .email-signature{ padding: 35px 25px 10px; }
  .email-signature .signature-details,
  .email-signature .signature-content{
    width: 100%;
    margin: 0 0 25px;
  }
}


/deep/ .is-blueish {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}


.small-pool {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 3%;
}

::v-deep .accordion__item {
  cursor: pointer;
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

/deep/ .accordion__item {
  cursor: pointer;
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

.accordion__item {
  cursor: pointer;
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

.highlight-span {
  background-color: rgba(39, 99, 74, 0.68);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  box-shadow: rgba(24, 202, 255, 0.13) 0px 0px 30px 3px !important;
  background-blend-mode: color;
  border: 1px solid rgba(177, 76, 226, 0.56) !important;
  outline: none;
}

.highlight-span span:before {
  background-color: #0a0a0a;
  padding: 7px 20px;
  display: block;
  border-radius: 50px;
  border: 4px solid #3abb81;
  background-blend-mode: color;
}

.highlight-span span:focus,
.highlight-span span:hover {
  background-color: rgba(27, 94, 71, 0.68);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 4px solid #309a6a;
  outline: none;
}

/deep/ .columns:not(:last-child) {
  margin-bottom: 0px;
}

.stake-col-lg{
}

.stake-col-sm{
  padding-left: 0px;
}

.withdraw-col-lg{
  padding-right: 0px;
}

.withdraw-col-sm{
  padding-left: 0px;
}

.withdraw-btn-lg{
  border-bottom-left-radius: 1rem !important; border-top-left-radius: 1rem !important;
}
.withdraw-btn-sm{
  border-radius: 1rem;
}

.claim-btn-lg{
  border-bottom-right-radius: 1rem !important; border-top-right-radius: 1rem !important;
}

.claim-btn-sm{
  border-radius: 1rem;
}

/deep/ .minus {
  border-top-left-radius: 1rem;
}

/deep/ .b-numberinput input {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

/deep/ .button.is-buttonblue {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}


/deep/ .button.is-buttonblue:hover {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}

/* card background */
.home-card-bg {
  background: #ebeae5;
}

.inset-bg {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding-bottom: 2rem;
}

.inset-bg2:active {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  width: 100%;
  height: 30px;
  position: relative;
  border-radius: 1rem;
  align-self: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 4px;
}


.col-title {
  color: #36c0ea;
  font-size: 12px;
  font-family: Source Code Pro,monospace !important;
}

.pending-title {
  color: rgb(120 161 235);
  font-size: 14px;
}

.pool-control-title{
  color: #49566c;
  font-size: 14px;
}

.pool-control-title:hover{
  color: #3abb81;
  font-size: 14px;
}

.pending-stat {
  color: #3cea36;
  font-size: 1em;
}

.pending-col {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: Source Code Pro,monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -1px 0px inset;
  margin-left: 10px;
}

.col-shadow1 {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider {
  font-size: 12px;
}

.pool-name {
  font-size: 16px;
  color: rgb(120 161 235);
  font-family: Source Code Pro,monospace !important;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: #293038;
}

.pool-main {
  background-color: #293038;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
}

.pool-item:hover {
  background-color: #333b4c;
  border: 1px solid rgba(3, 255, 150, 0.38) !important;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
}

.poolselect-bg {
  background-color: #293038 !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

/deep/ .tabs.is-toggle li.is-active a {
  background-color: #314b6a;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #3abb81;
  z-index: 1;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .tabs.is-toggle a {
  border-color: #575f85;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 0;
  position: relative;
  font-family: Source Code Pro,monospace !important;
  color: #195da7;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
  color: #00b89c;
}


.pools-card {
  padding-top: .5rem;
  color: #0d64c6;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
}

/deep/ .field.has-addons .control:first-child .control .button, .field.has-addons .control:first-child .control .input, .field.has-addons .control:first-child .control .select select {
  border-radius: 0 !important;
}

/deep/ .field.has-addons:first-child  .control:first-child .control:first-child  .button:first-child , .field.has-addons:first-child  .control:first-child .control:first-child  .input:first-child , .field.has-addons:first-child .control:first-child .control:first-child  .select:first-child  select:first-child{
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.stake-btn-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.stake-btn-2 {
  font-size: 14px !important;
  max-height: 40px;
  padding-left: 12px !important;
}

.btn {
  color: #87b6e5;
  background: rgba(3, 150, 255, 0.32);
  background-repeat: no-repeat;
  font-family: Source Code Pro,monospace !important;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.btn:focus,
.btn:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    margin-bottom: 30px;
  }
}

</style>
<style lang="scss" scoped>

.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro,monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

</style>
