<template>
  <div v-bind:class="[flipped ? 'flip-container flipped' : 'flip-container']"
       class="column pool-main2 is-centered mb-4 mt-5">
    <div class="columns is-centered pools-card" >
      <div class="column is-1"></div>
      <div
          class="column pool-item is-centered is-align-self-center" v-bind:class="[stake_balance > 0 ? 'highlight-span' : '']"
      >
        <accordion>
          <accordion-item :class="$screen.width < 770 ? 'small-pool' : ''">
            <!-- This slot will handle the title/header of the accordion and is the part you click on -->
            <template slot="accordion-trigger">
              <div class="columns is-justify-content-space-evenly">
                <div class="column is-align-self-center ">
                  <b-tooltip type="is-dark" :label="'Vote: '+pool_name">
                    <div class="pool-name">{{ pool_name }}</div>
                  </b-tooltip>
                </div>
                <div class="column is-align-self-center">
                  <b-tooltip type="is-dark">
                    <div class="col-title">ENDS</div>

                    <div v-if="show_date_unknown === false">{{ pool_end_time }}</div>
                    <div v-if="show_date_unknown === true">?</div>
                  </b-tooltip>
                </div>
                <div class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Stake the token '+pool_vote_token+ ' to Vote'">
                    <div class="col-title">TOKEN</div>
                    <div>{{ pool_vote_token }}</div>
                  </b-tooltip>
                </div>
                <div class="column is-1 is-align-self-center">
                  <b-tooltip type="is-dark" :label="'This vote requires: '+pool_required_votes+' tokens voted to pass.'">
                    <div class="col-title">REQUIRED</div>
                    <div>{{pool_required_votes}}</div>
                  </b-tooltip>
                </div>
                <div class="column is-1 is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Current votes For this proposal: '+pool_for_balance">
                    <div class="col-title" style="color: #4dab73">FOR</div>
                    <div style="color: #4dab73">{{ l_pool_for_balance }}</div>
                  </b-tooltip>
                </div>
                <div class="column is-1 is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Current votes Against this proposal: '+pool_against_balance">
                    <div style="color: #ab4d4d" class="col-title">AGAINST</div>
                    <div style="color: #ab4d4d">{{l_pool_against_balance}}</div>
                  </b-tooltip>
                </div>
              </div>
            </template>
            <!-- pool accordion content -->
            <template slot="accordion-content">

              <div class="columns mt-0 mb-0 descriptionSection">
                <!-- if connected pool buttons-->
                <div class="column is-align-self-center is-vcentered stake-btn-col">
                  <div class="column is-12 is-align-self-center" style=" background-color: rgba(45, 41, 56, 0.55); border-radius: 1rem; box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                    <div class="column voterheader" style="font-size: 30px; color: #48c78e !important"><span style="font-size: 35px; ">{{pool_name}}</span></div>
                  </div>
                </div>
              </div>

              <div class="columns mt-0 mb-0 descriptionSection">
                <!-- if connected pool buttons-->
                <div class="column is-align-self-center is-vcentered stake-btn-col">
                  <div class="column is-12 is-align-self-center" style="background-color: #303241; box-shadow: rgb(50 50 93 / 25%) 0px 30px 60px -12px inset, rgb(0 0 0 / 30%) 0px 18px 36px -18px inset;border-radius: 2rem;padding: 3rem;">
                    <div class="divider is-long"></div>
                    <br/>
                    <div><span style="color: #4ad1e3">{{ pool_description }}</span></div>
                    <br/>
                    <div class="divider is-long"></div>
                  </div>
                </div>

              </div>

              <div class="columns mt-4">
                <div class="column is-12 is-flex is-justify-content-center" style=" background-color: rgba(45, 41, 56, 0.55); border-radius: 1rem; box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                  <div class="container">
                    <div class="columns is-flex-direction-column">


                      <section v-if="show_proposal_failed">
                        <div class="column is-12 is-flex is-justify-content-center" style=" background-color: rgba(45, 41, 56, 0.55); border-radius: 1rem; box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                          <div class="columns p-4" style="width: 100%">
                            <div class="column is-one-third voterheader" style="font-size: 30px; color: #f14668 !important"><b-icon pack="fas" icon="thumbs-down" size="is-medium" style="position: relative; top: 4px;"></b-icon></div>
                            <div class="column is-flex is-flex-direction-column is-justify-content-center is-two-thirds">
                              <span style="color:#f14668">THIS PROPOSAL WAS OPPOSED</span>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section v-if="show_proposal_passed">
                        <div class="column is-12 is-flex is-justify-content-center" style=" background-color: rgba(45, 41, 56, 0.55); border-radius: 1rem; box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                          <div class="columns p-4" style="width: 100%">
                            <div class="column is-one-third voterheader" style="font-size: 30px; color: #48c78e !important"><b-icon pack="fas" icon="thumbs-up" size="is-medium" style="position: relative; top: 4px;"></b-icon></div>
                            <div class="column is-flex is-flex-direction-column is-justify-content-center is-two-thirds">
                              <span style="color:#48c78e">THIS PROPOSAL PASSED</span>
                            </div>
                          </div>
                        </div>
                      </section>

                      <div class="column is-12">
                        <div class="email-signature">
                          <ul class="signature-content has-text-left">
                            <li><i><b-icon pack="fas" icon="question-circle" size="is-small"></b-icon></i><span style="font-weight: bold;">How To Vote</span>: <span style="font-weight: bold; color: #874bd5"> Stake </span> any amount of <span style="font-weight: bold; color: #874bd5"> {{ pool_vote_token }} </span> token to vote. </li>
                            <li><i><b-icon pack="fas" icon="exclamation-triangle" size="is-small"></b-icon></i><span style="font-weight: bold;">Votes are counted when the proposition timer ends - Make sure all votes are staked before the proposition ends for votes to count - Withdraw at anytime, however, withdrawing your stake before the proposition timer ends also withdraws your vote.</span></li>
                            <li><i><b-icon pack="fas" icon="balance-scale" size="is-small"></b-icon></i><span style="font-weight: bold;">In order for this proposition to pass</span>, more tokens must vote for this proposition than against it.</li>
                            <li><i><b-icon pack="fas" icon="balance-scale" size="is-small"></b-icon></i><span style="font-weight: bold;">In order for this proposition to pass</span>, a minimum of <span style="font-weight: bold; color: #874bd5"> {{pool_required_votes}} {{ pool_vote_token }}</span> tokens must vote for this proposition.</li>
                            <li><i><b-icon pack="fas" icon="calendar-plus" size="is-small"></b-icon></i><span style="font-weight: bold;">Vote Start Date</span>: <span style="font-weight: bold; color: #874bd5"> {{ pool_start_time.timep ? new Date(pool_start_time.timep)  : new Date(pool_start_time) }} </span> </li>
                            <li><i><b-icon pack="fas" icon="gavel" size="is-small"></b-icon></i><span style="font-weight: bold;">Vote End Date</span>: <span style="font-weight: bold; color: #874bd5">{{ pool_end_time.timep ? new Date(pool_end_time.timep)  : new Date(pool_end_time) }}</span> </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="divider is-long"></div>

              <div class="columns mt-5">
                <div class="column is-offset-1 is-one-third is-flex is-flex-direction-column is-justify-content-center" style=" background-color: rgba(45, 41, 56, 0.55); border-radius: 1rem; box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                  <div class="voterheader" style="font-size: 60px; color: #48c78e !important"><b-icon pack="fas" icon="thumbs-up" size="is-large" style="position: relative; top: 4px;"></b-icon></div>
                  <br/>
                  <div><span style="color:#9573ba">FOR VOTERS:</span> <span style="color:#3abb81">{{ l_pool_for_voters }}</span></div>
                  <br/>
                  <div class="divider is-long"></div>
                  <br/>
                  <div><span style="color:#9573ba">FOR BALANCE:</span> <span style="color:#3abb81">{{ l_pool_for_balance }}</span></div>
                  <br/>
                </div>
                <div class="column is-2">

                </div>
                <div class="column is-one-third is-flex is-flex-direction-column is-justify-content-center" style=" background-color: rgba(45, 41, 56, 0.55); border-radius: 1rem; box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                  <div class="voterheader" style="font-size: 60px; color: #f14668 !important"><b-icon pack="fas" icon="thumbs-down" size="is-large" style="position: relative; top: 4px;"></b-icon></div>
                  <br/>
                  <div><span style="color:#9573ba">AGAINST VOTERS:</span> <span style="color:#f14668">{{ l_pool_against_voters }}</span></div>
                  <br/>
                  <div class="divider is-long"></div>
                  <br/>
                  <div><span style="color:#9573ba">AGAINST BALANCE:</span> <span style="color:#f14668">{{ l_pool_against_balance }}</span></div>
                  <br/>
                </div>
              </div>

              <div v-if="show_i_voted_against" class="columns mt-4">
                <div class="column is-12 is-flex is-justify-content-center" style=" background-color: rgba(45, 41, 56, 0.55); border-radius: 1rem; box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                  <div class="columns p-4" style="width: 100%">
                    <div class="column is-one-third voterheader" style="font-size: 30px; color: #f14668 !important"><b-icon pack="fas" icon="thumbs-down" size="is-medium" style="position: relative; top: 4px;"></b-icon></div>
                    <div class="column is-flex is-flex-direction-column is-justify-content-center is-one-third">
                      <span style="color:#f14668">I VOTED AGAINST</span>
                    </div>
                    <div class="column is-flex is-flex-direction-column is-justify-content-center is-one-third">
                      <span style="color:#f14668">{{ l_stake_final_vote_balance }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="show_i_voted_for" class="columns mt-4">
                <div class="column is-12 is-flex is-justify-content-center" style=" background-color: rgba(45, 41, 56, 0.55); border-radius: 1rem; box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                  <div class="columns p-4" style="width: 100%">
                    <div class="column is-one-third voterheader" style="font-size: 30px; color: #48c78e !important"><b-icon pack="fas" icon="thumbs-up" size="is-medium" style="position: relative; top: 4px;"></b-icon></div>
                    <div class="column is-flex is-flex-direction-column is-justify-content-center is-one-third">
                      <span style="color:#48c78e">I VOTED FOR</span>
                    </div>
                    <div class="column is-flex is-flex-direction-column is-justify-content-center is-one-third">
                      <span style="color:#48c78e">{{ l_stake_final_vote_balance }}</span>
                    </div>
                  </div>
                </div>
              </div>


              <div class="columns mt-4 mb-3 pending-col leatherbg"
                   style="position: relative; top: 10px; background-color: rgba(38,43,56,0.29); border-radius: 1rem;">
                <div class="column is-3 is-align-self-center">
                  <b-tooltip type="is-dark" :label="'You have 0 '+pool_vote_token">
                    <div class="col-title2">WALLET:</div>
                    <div><span style="color:#3abb81">{{l_wallet_vote_token_balance}}</span></div>
                  </b-tooltip>
                </div>
                <div class="column is-3 is-align-self-center">
                  <b-tooltip type="is-dark" :label="'You have staked 0 '+pool_vote_token+' tokens'">
                    <div class="col-title2">STAKED:</div>
                    <div><span style="color:#3abb81">{{l_stake_balance}}</span></div>
                  </b-tooltip>
                </div>


                <div class="column is-align-self-center stake-btn-col" style="margin-left: 4%; margin-right: 1%;">
                  <div class="columns">
                    <div class="column"  v-bind:class="[$screen.width > 768 ? 'stake-col-lg' : 'stake-col-sm']">
                      <div class="field-body">
                        <div class="field has-addons" style="background-color: transparent !important;">
                          <p class="control is-expanded">
                            <b-numberinput expanded size="is-small" v-model="stakeAmount" min="0" type="is-buttonblue" controls-alignment="left"
                                           controls-position="compact"></b-numberinput>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column" v-bind:class="[$screen.width > 768 ? 'withdraw-col-lg' : 'withdraw-col-sm']">
                      <div class="btn btn-lg stake-btn" v-bind:class="[$screen.width > 768 ? 'withdraw-btn-lg' : 'withdraw-btn-sm']" style="border-bottom-right-radius: 1rem; border-top-right-radius: 1rem;">
                        <b-icon pack="fas" icon="thumbs-up" size="is-medium" style="position: relative; top: 4px;"></b-icon>
                        <p>VOTE FOR</p>
                      </div>
                    </div>
                    <div class="column" v-bind:class="[$screen.width > 768 ? 'withdraw-col-lg' : 'withdraw-col-sm']">
                      <div class="btn btn-lg stake-btn" v-bind:class="[$screen.width > 768 ? 'withdraw-btn-lg' : 'withdraw-btn-sm']" style="border-bottom-right-radius: 1rem; border-top-right-radius: 1rem;">
                        <b-icon pack="fas" icon="thumbs-down" size="is-medium" style="position: relative; top: 4px;"></b-icon>
                        <p>VOTE AGAINST</p>
                      </div>
                    </div>
                  </div>
<!--                  <div class="columns">-->
<!--                    <div class="column" v-bind:class="[$screen.width > 768 ? 'withdraw-col-lg' : 'withdraw-col-sm']">-->
<!--                      <div class="btn btn-lg stake-btn" v-bind:class="[$screen.width > 768 ? 'withdraw-btn-lg' : 'withdraw-btn-sm']" style="border-bottom-right-radius: 1rem; border-top-right-radius: 1rem;">-->
<!--                        <p>WITHDRAW</p>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>


              </div>

            </template>
          </accordion-item>
        </accordion>


      </div>
      <div class="column is-1"></div>
    </div>

  </div>
</template>

<script>
//import vue store variables to this page
import {mapGetters, mapState} from "vuex";
import Accordion from "./accordion.vue";
import AccordionItem from "./accordion-item";

export default {
  name: "PoolCard",
  components: {
    Accordion,
    AccordionItem,
  },
  props: {
    accountApproved: {
      type: Boolean,
      default: false,
    },
    pool_id: {
      type: [String, Number],
      default: "0"
    },
    pool_subject_id: {
      type: [String, Number],
      default: "0"
    },
    pool_name: {
      type: String,
      default: "0",
    },
    pool_description: {
      type: String,
      default: "0",
    },
    pool_vote_token: {
      type: [String, Object],
      default: "0",
    },
    pool_creator: {
      type: String,
      default: "0",
    },
    pool_required_votes: {
      type: [String, Number],
      default: "0",
    },
    pool_account: {
      type: String,
      default: "0",
    },
    pool_start_time: {
      type: [String, Object, Number, Date],
      default: "0",
    },
    pool_end_time: {
      type: [String, Object, Number, Date],
      default: "0",
    },
    pool_initialized: {
      type: Boolean,
      default: false,
    },
    pool_active: {
      type: Boolean,
      default: false,
    },
    pool_finalized: {
      type: Boolean,
      default: false,
    },
    pool_updates: {
      type: [String, Object],
      default: "0",
    },
    pool_balance: {
      type: [String, Number],
      default: "0"
    },
    pool_for_balance: {
      type: [String, Number],
      default: "0"
    },
    pool_against_balance: {
      type: [String, Number],
      default: "0"
    },
    pool_voters: {
      type: [String, Number],
      default: "0"
    },
    pool_for_voters: {
      type: [String, Number],
      default: "0"
    },
    pool_against_voters: {
      type: [String, Number],
      default: "0"
    },
    stake_id: {
      type: [String, Number],
      default: "0"
    },
    stake_account: {
      type: String,
      default: "0"
    },
    stake_balance: {
      type: [String, Number],
      default: "0"
    },
    stake_final_vote_balance: {
      type: [String, Number],
      default: "0"
    },
    stake_voted: {
      type: Boolean,
      default: false,
    },
    stake_vote_for: {
      type: Boolean,
      default: false,
    },
    stake_vote_against: {
      type: Boolean,
      default: false,
    },
    stake_vote_date: {
      type: [String, Object, Number, Date],
      default: "0",
    },
    stake_withdraw_date: {
      type: [String, Object, Number, Date],
      default: "0",
    },
    is_pool_owner: {
      type: Boolean,
      default: false,
    },
    wallet_vote_token_balance: {
      type: [String, Number],
      default: "0",
    },
    show_proposal_passed: {
      type: Boolean,
      default: false,
    },
    show_proposal_failed: {
      type: Boolean,
      default: false,
    },
    show_i_voted_for: {
      type: Boolean,
      default: false,
    },
    show_i_voted_against: {
      type: Boolean,
      default: false,
    },
    show_date_unknown: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      debug: false,
      flipped: false,
      showStakeControls: false,
      stakeAmount: 0.0,
      currentDate: null,
      isMakingCrowdFundTransaction: false,
      isMakingTransaction: false,
      isPledging: false,
      isPledging_poolid: null,
      isPledging_amount: null,
      isWithdrawing: false,
      isWithdrawing_poolid: null,
      isWithdrawing_amount: null,
      isClaiming: false,
      isClaiming_poolid: null,
      isClaiming_amount: null,
      isWithdrawingExpired: false,
      isWithdrawingExpired_poolid: null,
      isWithdrawingExpired_amount: null,
      isGenerating: false,
      isGenerating_poolid: null,
      isGenerating_amount: null,
      //Crowdfunding Pools
      allCrowdFundingPools: [],
      allCrowdFundingPoolsToShowNow: [],
      allActiveCrowdFundingPools:[],
      allActiveCrowdFundingPoolsIds: [],
      allUserCrowdFundingPools: [],
      allUserCrowdFundingPoolsIds:[],
      allCrowdFundingPoolsToShow: [],
      allCrowdFundingPoolIdsUserIsNotStakedIn:[],
      l_pool_funded: this.pool_funded,
      l_pool_maxxed: this.pool_maxxed,
      l_pledge_rewards: this.pledge_rewards,
      l_pledge_balance: this.pledge_balance,
      l_wallet_pledge_balance: this.wallet_pledge_balance,
      l_pool_amount_sold: this.pool_amount_sold,
      l_pool_end_time: this.pool_end_time,
      l_pool_insurance_end_time: this.pool_insurance_end_time,
      l_pool_can_claim: this.pool_can_claim,
      l_stamp_pool_end_time: this.stamp_pool_end_time,
      l_pool_initialized: this.pool_initialized,
      l_pool_paid: this.pool_paid,
      l_pool_lp_claimed: this.pool_lp_claimed,
      l_pool_tokens_locked: this.pool_tokens_locked,
      l_pool_active: this.pool_active,
      l_pool_user_balance: this.pool_user_balance,
      l_funding_failed: this.funding_failed,
      l_pool_amount_to_sell: this.pool_amount_to_sell,
      l_can_generate: this.can_generate,
      l_pool_wl_active: this.pool_wl_active,
      l_pool_wl_end_time: this.pool_wl_end_time,
      l_pool_wl_end_time_full: this.pool_wl_end_time_full,
      l_pool_end_time_full: this.pool_end_time_full,
      l_pool_wl_max_buy: this.pool_wl_max_buy,
      l_isWhitelisted: this.isWhitelisted,
      l_pool_max_buy: this.pool_max_buy,
      //DAO pools
      l_pool_balance: this.pool_balance,
      l_pool_for_balance: this.pool_for_balance,
      l_pool_against_balance: this.pool_against_balance,
      l_pool_voters: this.pool_voters,
      l_pool_for_voters: this.pool_for_voters,
      l_pool_against_voters: this.pool_against_voters,
      l_stake_balance: this.stake_balance,
      l_stake_final_vote_balance: this.stake_final_vote_balance,
      l_stake_voted: this.stake_voted,
      l_stake_vote_for: this.stake_vote_for,
      l_stake_vote_against: this.stake_vote_against,
      l_stake_vote_date: this.stake_vote_date,
      l_stake_withdraw_date: this.stake_withdraw_date,
      l_wallet_vote_token_balance: this.wallet_vote_token_balance

    };
  },
  watch: {


  },
  computed: {
    ...mapState("accounts", ["transactionPolling", "transactionRejected"]),
  },
  methods: {
    ...mapGetters("accounts", ["getAccountExists", "getAccountConfirmed", "getTransactionFailed", "getTransactionPolling", "getAllCrowdFundingPools", "getUserCrowdFundingPools"]),

    number_test(n) {
      const result = (n - Math.floor(n)) !== 0;

      if (result) {
        if (this.debug) {
          console.log("Contains Decimal Place");
        }
        return true;
      } else {
        if (this.debug) {
          console.log("No decimal place");
        }
        return false;
      }

    },

    coinName(namespace, name){
      let newnamespace = namespace+'.';
      let newname = name;
      if(namespace === null || namespace === 'null'){
        newnamespace = '';
      }
      if(name === null || name === 'null'){
        newname = '';
      }
      return newnamespace+newname;
    },

    coinDisplayName(coin){
      let name = coin;
      if(coin === 'coin'){
        name = 'KDA';
      }else if(coin === 'free.stake-token'){
        name = 'STEAK';
      }
      return name;
    },

    reduceBalance(balance, prec = 6){
      if (balance) {
        if (balance.int) balance = balance.int;
        if (balance.decimal) balance = balance.decimal;

        if (parseFloat(balance) % 1 === 0) {
          return parseInt(balance);
        }

        return Math.trunc(parseFloat(balance) * Math.pow(10, prec)) / Math.pow(10, prec);
      }

      if (balance === 0) return 0;
    },

  },
  async created(){
    this.currentDate = new Date().getTime()
  }
};
</script>

<style scoped>

.email-signature{
  position: relative;
}

.email-signature:after{
  transform: rotateZ(180deg);
  top: auto;
  bottom: 0;
}
.email-signature .signature-details{
  text-align: center;
  width: 46%;
  display: inline-block;
}
.email-signature .signature-icon{
  background-color: #1BC002;
  width: 120px;
  height: 120px;
  padding: 5px;
  border-radius: 50%;
  margin: 0 auto 10px;
}
.email-signature .signature-icon img{
  width: 100%;
  border-radius: 50%;
}
.email-signature .title{
  color: #1BC002;
  font-size: 27px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 0 0 5px;
}
.email-signature .post{
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px 15px 3px;
  box-shadow: 0 0 5px rgba(255,255,255,0.1);
  display: inline-block;
}
.email-signature .signature-content{
  width: 90%;
  padding: 10px 0 15px;
  margin: 15px 0;
  list-style: none;
  vertical-align: top;
  display: inline-block;
  position: relative;
}
.email-signature .signature-content:before,
.email-signature .signature-content:after{
  content: '';
  background: #523a7b;
  height: 100%;
  width: 25px;
  position: absolute;
  left: 10px;
  top: 0;
  z-index: 1;
}
.email-signature .signature-content:after{
  background: linear-gradient(to top right, rgb(57, 36, 94) 50%, transparent 51%);
  height: 10px;
  width: 10px;
  left: 35px;
}
.email-signature .signature-content li{
  color: #44a361;
  background-color: #1b2230;
  font-size: 14px;
  padding: 6px 10px 4px 45px;
  margin-bottom: 7px;
  position: relative;
}
.email-signature .signature-content li:last-child{ margin-bottom: 0; }
.email-signature .signature-content li i{
  color: #7790e9;
  font-size: 14px;
  position: absolute;
  left: 16px;
  top: 9px;
  z-index: 2;
}
@media screen and (max-width:576px){
  .email-signature{ padding: 35px 25px 10px; }
  .email-signature .signature-details,
  .email-signature .signature-content{
    width: 100%;
    margin: 0 0 25px;
  }
}


.voterheader {
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -4px, rgb(0 0 0 / 20%) 0px -1px 0px inset;
  border-radius: 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background-color: #05060824;
}

.endcol{
  margin-left: 1rem;
  margin-right: 1rem;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -4px, rgba(0, 0, 0, 0.20) 0px -1px 0px inset;
  border-radius: 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background-color: #05060824;
}

.cow {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(15 47 50);
  background: -webkit-linear-gradient(#3e1046, #6e2a57) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  border-radius: 0.5rem;
  color: #296992;
  margin-right: 10px !important;
}

.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 0.6);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}

.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}

.pending-col2 {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: Source Code Pro,monospace !important;
}

.divider {
  border: none;
  height: 20px;
  width: 90%;
  height: 50px;
  margin-top: 0;
  border-bottom: 1px solid #1f1209;
  box-shadow: 0 20px 20px -20px #333;
  margin: -50px auto 10px;
}

/deep/ .progress-wrapper:not(:last-child) {
  margin-bottom: 0 !important;
}

/deep/ .progress-wrapper .progress.is-medium + .progress-value, .progress-wrapper .is-medium.progress-wrapper.is-not-native + .progress-value, .progress-wrapper .progress.is-medium .progress-value, .progress-wrapper .is-medium.progress-wrapper.is-not-native .progress-value, .progress-wrapper .progress-bar.is-medium + .progress-value, .progress-wrapper .progress-bar.is-medium .progress-value {
  font-size: calc(1.25rem / 1.5);
  line-height: 1.25rem;
  color: #772b7c !important;
  background-color: transparent !important;
}

.descriptionSection {
  position: relative;
  top: 10px;
  color: #3abb81;
}

.leatherbg {
  background: radial-gradient(#00000078 0%, transparent 10%) 0 0, radial-gradient(#0000005c 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 250, 250, 0) 15%, transparent 20%) 8px 9px;
  background-color: #2c243275 !important;
  background-size: 16px 16px;
  border: 2px solid #11426573;
  background-blend-mode: darken;
}

.small-pool {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 3%;
}

::v-deep .accordion__item {
  cursor: pointer;
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

/deep/ .accordion__item {
  cursor: pointer;
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

.accordion__item {
  cursor: pointer;
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

.highlight-span {
  background-color: rgba(39, 99, 74, 0.68);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  box-shadow: rgba(24, 202, 255, 0.13) 0px 0px 30px 3px !important;
  background-blend-mode: color;
  border: 1px solid rgba(177, 76, 226, 0.56) !important;
  outline: none;
}

.highlight-span:before {
  background-color: #0a0a0a;
  padding: 7px 20px;
  display: block;
  border-radius: 50px;
  border: 4px solid #3abb81;
  background-blend-mode: color;
}

.highlight-span:focus,
.highlight-span:hover {
  background-color: rgba(27, 94, 71, 0.68);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 4px solid #309a6a;
  outline: none;
}

/deep/ .columns:not(:last-child) {
  margin-bottom: 0px;
}

.stake-col-lg{
}

.stake-col-sm{
  padding-left: 0px;
}

.withdraw-col-lg{
  padding-right: 0px;
}

.withdraw-col-sm{
  padding-left: 0px;
}

.withdraw-btn-lg{
  border-bottom-left-radius: 1rem !important; border-top-left-radius: 1rem !important;
}
.withdraw-btn-sm{
  border-radius: 1rem;
}

.claim-btn-lg{
  border-bottom-right-radius: 1rem !important; border-top-right-radius: 1rem !important;
}

.claim-btn-sm{
  border-radius: 1rem;
}

/deep/ .minus {
  border-top-left-radius: 1rem;
}

/deep/ .b-numberinput input {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
  box-shadow: inset 0 30px 60px -12px rgba(50, 50, 93, 0.25), inset 0 18px 36px -18px rgba(0, 0, 0, 0.30);
}

/deep/ .button.is-buttonblue {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}


/deep/ .button.is-buttonblue:hover {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}

/* card background */
.home-card-bg {
  background: #ebeae5;
}

.inset-bg {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding-bottom: 2rem;
}

.inset-bg2:active {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  width: 100%;
  height: 30px;
  position: relative;
  border-radius: 1rem;
  align-self: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 4px;
}


.col-title {
  color: #9573ba;
  font-size: 12px;
  font-family: Source Code Pro,monospace !important;
}

.col-title2 {
  color: #36c0ea !important;
  font-size: 12px !important;
  font-family: Source Code Pro,monospace !important;
}

.pending-title {
  color: rgb(120 161 235);
  font-size: 14px;
}

.pool-control-title{
  color: #49566c;
  font-size: 14px;
}

.pool-control-title:hover{
  color: #3abb81;
  font-size: 14px;
}

.pending-stat {
  color: #3cea36;
  font-size: 1em;
}

.pending-col {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: Source Code Pro,monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.col-shadow1 {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider {
  font-size: 12px;
}

.pool-name {
  font-size: 16px;
  color: rgb(120 161 235);
  font-family: Source Code Pro,monospace !important;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: #293038;
}

.pool-main2 {
  background-color: transparent !important;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.pool-item:hover {
  background-color: #333b4c;
  border: 1px solid rgba(3, 255, 150, 0.38) !important;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
}

.poolselect-bg {
  background-color: #293038 !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

/deep/ .tabs.is-toggle li.is-active a {
  background-color: #314b6a;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #3abb81;
  z-index: 1;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .tabs.is-toggle a {
  border-color: #575f85;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 0;
  position: relative;
  font-family: Source Code Pro,monospace !important;
  color: #195da7;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
  color: #00b89c;
}


.pools-card {
  padding-top: .5rem;
  color: #0d64c6;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
}

/deep/ .field.has-addons .control:first-child .control .button, .field.has-addons .control:first-child .control .input, .field.has-addons .control:first-child .control .select select {
  border-radius: 0 !important;
}

/deep/ .field.has-addons:first-child  .control:first-child .control:first-child  .button:first-child , .field.has-addons:first-child  .control:first-child .control:first-child  .input:first-child , .field.has-addons:first-child .control:first-child .control:first-child  .select:first-child  select:first-child{
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.stake-btn-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.stake-btn-2 {
  font-size: 14px !important;
  max-height: 40px;
  padding-left: 12px !important;
}

.btn {
  color: #87b6e5;
  background: rgb(34 77 118);
  background-repeat: no-repeat;
  font-family: Source Code Pro,monospace !important;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.btn:focus,
.btn:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    margin-bottom: 30px;
  }
}

</style>
<style lang="scss" scoped>

.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro,monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

</style>
<style scoped>
.container2 {
  background-color: #1D1F21;
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  padding: 25px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), transparent 85%), radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%), linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 7px -4px #4f545f inset, 0 -2px 1px #5c6574 inset;
}
</style>
<style lang="scss" scoped>
article {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 900px) {
    padding: 80px;
  }

  @media (max-width: 899px) {
    padding-bottom: 56px;
  }
  background-color: rgba(192, 192, 192, 0);
  color: #323232;
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  position: relative;
  & > * + * {
    margin-top: 1.25em;
  }

  &:after {
    content: "🥩";
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding-bottom: 0.25em;
    position: absolute;
    top: 0.75em;
    right: 0.75em;
    width: 1.75em;
    height: 1.75em;
    filter: grayscale(90%);
    background-color: transparent;
    border-left: 1px solid rgba(238, 238, 238, 0.16);
    border-top: 1px solid rgba(238, 238, 238, 0.06);
    border-right: 1px solid rgba(68, 68, 68, 0.41);
    border-bottom: 1px solid rgba(68, 68, 68, 0.39);
  }
}

.headline {
  font-size: 2.5em;
  font-weight: 700;
}

.subhead {
  font-size: 1.25em;
  margin-top: 0.25em;
}

.article-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    width: 3em;
    height: 3em;
    flex-shrink: 0;
  }

  div:first-of-type {
    margin-left: 0.75em;
    font-size: 0.875em;
    margin-right: auto;
    padding-right: 0.75em;
  }

  div:last-of-type {
    font-size: 0.875em;
  }
}

aside {
  padding: 1em;
  border-right: 1px solid rgba(238, 238, 238, 0.11);
  border-bottom: 1px solid rgba(238, 238, 238, 0.06);
  border-left: 1px solid rgba(68, 68, 68, 0.45);
  border-top: 1px solid rgba(68, 68, 68, 0.39);
  clear: both;
  text-shadow: 0 0 10px, 0 0 40px;
}

.tag {
  display: inline-block;
  margin-right: 0.25em;
  margin-top: 0.25em;
  border-right: 1px solid rgba(238, 238, 238, 0.19);
  border-bottom: 1px solid rgba(238, 238, 238, 0.13);
  border-left: 1px solid #444;
  border-top: 1px solid #444;
  padding: 0.125em 0.375em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

h2:not(.subhead) {
  font-size: 1.5em;
  font-weight: 700;
  padding-bottom: 0.375em;
  box-shadow: 0 1px 0 0 #444, 0 2px 0 0 #eee;
}

figure {
  background-color: #c0c0c0;
  box-shadow: 0 0 0 1px #eee, 0 0 0 2px #c0c0c0, 0 0 0 3px #444;
  position: relative;
  margin-bottom: 1.25em;
  width: calc(100% + 1.5em + 2em);
  text-shadow: 0 0 10px, 0 0 40px;
  @media (min-width: 800px) {
    max-width: 50%;
  }
  &:nth-of-type(odd) {
    float: left;
    margin-right: 1.5em;
    margin-left: -3em;
  }

  &:nth-of-type(even) {
    float: right;
    margin-left: 1.5em;
    margin-right: -3em;
  }

  &:after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    background-image: linear-gradient(
            to top,
            rgba(#ff9100, 0.2) 0%,
            rgba(#ffe630, 0.2) 60%
    ),
    linear-gradient(20deg, rgba(#ff0, 0.5) 0%, rgba(#ff0, 0) 35%);
    box-shadow: inset 0px 0px 100px rgba(0, 0, 20, 1);
    z-index: 1;
  }

  img {
    // opacity: 0;
    // filter: hue-rotate(90deg);
    // filter: sepia(20%) brightness(10%) contrast(130%)
  }
  figcaption {
    font-family: "DotGothic16", monospace;
    background-color: #6f4b80;
    box-shadow: inset 0 -1px 0 0 #020202;
    color: #eee;
    text-shadow: 0 0 10px, 0 0 40px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.25em;
    font-size: 0.875em;
    z-index: 2;
  }
}

</style>
