<template>
  <div id="app" >
    <PoolAdminPage :poolid="this.pool" :contract="this.contract" ></PoolAdminPage>
  </div>
</template>

<script>
import PoolAdminPage from '../components/PoolAdminPage.vue'

export default {
  name: 'Home',
  components: {
    PoolAdminPage
  },
  props: {
    pool: {
      type: String,
      default: "0"
    },
    contract: {
      type: String,
      default: "0"
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
