<template>
  <div style="overflow-x: hidden" :style="$screen.width > 1024 ? 'margin-top: -85px' : 'margin-top: -84px'">
    <transition name="fade">

      <!--    Page loading animation section-->
      <div v-if="this.showLoadingModal === true" style="height: 100vh">
        <div class="loader2">
          <div class="loader__bar"></div>
          <div class="loader__bar loader__bar--delay-1"></div>
          <div class="loader__bar loader__bar--delay-2"></div>
          <div class="loader__bar loader__bar--delay-3"></div>
          <div class="loader__bar loader__bar--delay-4"></div>
          <div class="loader__bar loader__bar--delay-5"></div>
          <div>
          </div>
        </div>
      </div>

      <!--    Homepage shown after loading is completed-->
      <section v-if="this.showLoadingModal === false">


        <!--      bubbles-->
        <div
            v-if="this.showLoadingModal === false"
            id="style-10"
            style="width:100vw; overflow-x: hidden !important; overflow-y: auto"
            :style="this.getShowNavBar() === true ? 'height: 92vh !important' : 'height: 104vh !important'">

          <section class="hero" style="z-index: 2">
            <div class="hero-body header-bg3" style="z-index: 2"></div>
          </section>


          <section class="hero" :class="$screen.width > 1024 ? '' : ''" style="z-index: 2">

            <div class="columns mt-4">

              <div class="column" style="z-index: 2">

                <div class="machineinterface">
                  <div class="monitor">
                    <div class="monitor-inner" style="padding-bottom: 60px">
                      <div class="screen-cutout">
                        <div class="screen">

                          <div class="my-pools-shadow column" style="z-index: 2; padding: 0px !important; margin-top: 20px;">
                            <div class="card pools-bg cardborder pb-5 " style="z-index: 2">
                              <div class="card-content poolselect-bg mb-6">
                                <section >
                                  <b-tabs
                                      v-model="goToPage"
                                      headerClass="headertest"
                                      class="font1"
                                      position="is-centered"
                                      type="is-toggle"
                                      expanded
                                  >
                                    <!--                                    <b-tab-item value="0" label="All Pools"></b-tab-item>-->
                                    <b-tab-item value="1" label="NFT Market"></b-tab-item>
                                    <b-tab-item value="2" label="My NFTs"></b-tab-item>
                                    <b-tab-item value="3" label="All NFTs"></b-tab-item>
                                  </b-tabs>
                                </section>
                              </div>

                              <section v-if="allNFTsToShowNow.length > 0">
                                <div class="column is-centered mb-4 mt-5">
                                  <div class="columns is-centered pools-card" >
                                    <div class="column is-1"></div>
                                    <div class="column pool-section-title is-centered is-align-self-center">
                                      <accordion>
                                        <accordion-item :class="$screen.width < 770 ? 'small-pool' : ''">
                                          <template slot="accordion-trigger">
                                            <div class="columns is-centered is-vcentered is-flex-direction-column">
                                              <div class="column if-full pool-col-title mt-0 mb-0 pt-1 pb-0 token2nft">
                                                {{this.collection}} 🔍
                                              </div>
                                              <div v-if="this.collectionDetails.supply" class="column if-full pool-col-title mt-0 mb-0 pt-0 pb-1">
                                                <span style="font-size: 12px; color: #2aaf7b;">Supply: {{this.collectionDetails.supply}}</span>
                                              </div>
                                            </div>
                                          </template>
                                          <!-- pool accordion content -->
                                          <template slot="accordion-content">
                                            <div class="columns mt-5 mb-3 pending-col leatherbg" style="position: relative; top: 10px; background-color: rgba(38,43,56,0.29); border-radius: 1rem;">
                                              <div class="column is-align-self-center stake-btn-col" style="margin-left: 4%; margin-right: 1%;">
                                                <div class="columns">
                                                  <div class="column">
                                                    <div class="field-body">
                                                      <div class="field has-addons">

                                                        <p class="control is-expanded">
                                                          <b-input expanded size="is-small" v-model="searchStringFungiv2Polyfungiv1" min="0" type="is-buttonblue" controls-alignment="left"
                                                                   controls-position="compact"></b-input>
                                                        </p>
                                                        <div @click="clickSearch()" class="btn stake-btn-2" style="border-bottom-right-radius: 1rem; border-top-right-radius: 1rem; width: 35%; overflow: hidden;">
                                                          <p class="font5">SEARCH</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </template>
                                        </accordion-item>
                                      </accordion>
                                    </div>
                                    <div class="column is-1"></div>
                                  </div>
                                </div>


                                <div v-if="wlactive === true && (currentDate - whitelist_endtime) < 0" class="column is-centered mb-4 mt-5">
                                  <div class="columns is-centered pools-card" >
                                    <div class="column is-1"></div>
                                    <div class="column pool-section-title is-centered is-align-self-center">
                                      <div class="columns is-centered is-vcentered is-flex-direction-column">
                                        <div class="column if-full pool-col-title mt-0 mb-0 pt-1 pb-0 token2nft" style="font-size: 20px;">
                                          Presale Active!
                                        </div>
                                        <div v-if="this.collectionDetails.supply" class="column if-full pool-col-title mt-0 mb-0 pt-0 pb-1">
                                          <span style="font-size: 17px; color: #2aaf7b;">Whitelisted Presale Ends in: <countdown :start-time="currentDate" :end-time="whitelist_endtime" ></countdown></span>
                                        </div>
                                        <div class="column if-full pool-col-title mt-0 mb-0 pt-1 pb-1 token2nft">
                                          [Currently pre-selling to whitelisted memebers only!]
                                        </div>
                                      </div>
                                    </div>
                                    <div class="column is-1"></div>
                                  </div>
                                </div>


                                <div v-if="allNFTsToShowNow_Paginated.length > 0" class="column pool-main is-centered mb-6 mt-5 center-flex">
                                  <div class="columns is-multiline is-centered pools-card ">
                                    <FlipCardMintPreview class="column is-3"
                                                         style="margin-right: 10px; margin-left: 10px; margin-top: 20px; width: 316px !important;"
                                                         v-for="p in allNFTsToShowNow_Paginated" :key="p.pool_id"
                                                         @showTxModal="showTxModalEvent()"
                                                         @reloadPage="setReloadPage()"
                                                         :show_extra_buttons="false"
                                                         :show_market_buttons="p.forsale"
                                                         pool_contract="factory-non-fungible"
                                                         :token="p.token" :forsale="p.forsale" :price="p.price" :isPoolOwner="p.ispoolowner" :factoryaccount="p.factoryaccount"
                                                         pool_id="?" pool_name="?" :token_strings="p.a1" :token_decimals="p.a2" :token_integers="p.a3"
                                                         :token_image="p.image" :token_collection="p.collectionid"
                                                         :pool_reward_token_id="p.id"
                                                         :token_name="p.name"
                                                         :wlactive="p.wlactive"
                                                         :token_description="p.description"
                                                         pool_reward_token="free.factory-non-fungible"
                                                         :show_flipper="true"
                                                         stake_balance="0" stake_pending_rewards="0"
                                                         stake_rewards="0"></FlipCardMintPreview>
                                  </div>
                                </div>

                                <div v-if="allNFTsToShowNow_Paginated.length > 0 && pagesFungiv2Polyfungiv1.length > 0"><nav class="pagination-outer" aria-label="Page navigation">
                                  <ul class="pagination">
                                    <li class="page-item">
                                      <a  @click="pageFungiv2Polyfungiv1 !== 1 ? [pageFungiv2Polyfungiv1--,clickShowPageFungiv2Polyfungiv1(pageFungiv2Polyfungiv1)] : true;" href="#" class="page-link" aria-label="Previous">
                                        <span aria-hidden="true">«</span>
                                      </a>
                                    </li>
                                    <li  v-for="pageNumber in pagesFungiv2Polyfungiv1.slice(pageFungiv2Polyfungiv1-1, pageFungiv2Polyfungiv1+5)"
                                         :key="pageNumber" class="page-item">
                                      <a type="button" class="page-link" @click="pageFungiv2Polyfungiv1 = pageNumber;  clickShowPageFungiv2Polyfungiv1(pageFungiv2Polyfungiv1); "> {{ pageNumber }}
                                      </a>
                                    </li>
                                    <li class="page-item">
                                      <a @click="pageFungiv2Polyfungiv1 < pagesFungiv2Polyfungiv1.length ? [pageFungiv2Polyfungiv1++, clickShowPageFungiv2Polyfungiv1(pageFungiv2Polyfungiv1)] : true;"  href="#" class="page-link" aria-label="Next">
                                        <span aria-hidden="true">»</span>
                                      </a>
                                    </li>
                                  </ul>
                                </nav></div>
                              </section>


                              <!-- NO POOLS SECTION -->
                              <section v-if="allNFTsToShowNow.length <= 0">

                                <section class="hero">
                                  <div class="hero-body">
                                    <div class="container container2 animated fadeInUp">
                                      <div class="columns is-multiline is-mobile">
                                        <div id="jp" class="column is-12-desktop is-12-tablet is-12-mobile" style="height: 370px;">
                                          <div class="center" style="width: 100%">
                                            <div class="center-box">
                                              <div class="center-items">
                                                <div class="column is-full is-justify-content-center is-vcentered">
                                                  <article>
                                                    <aside>
                                                    <pre v-if="$screen.width > 576" class="cow" style="overflow: hidden; background: none; margin-left: 0px; font-size: 0.9vw">███    ██  ██████      ███    ██ ███████ ████████ ███████     ███████  ██████  ██    ██ ███    ██ ██████
 ████   ██ ██    ██     ████   ██ ██         ██    ██          ██      ██    ██ ██    ██ ████   ██ ██   ██
 ██ ██  ██ ██    ██     ██ ██  ██ █████      ██    ███████     █████   ██    ██ ██    ██ ██ ██  ██ ██   ██
 ██  ██ ██ ██    ██     ██  ██ ██ ██         ██         ██     ██      ██    ██ ██    ██ ██  ██ ██ ██   ██
  ██   ████  ██████      ██   ████ ██         ██    ███████     ██       ██████   ██████  ██   ████ ██████  </pre>
                                                      <pre  v-if="$screen.width <= 576" class="cow" style="overflow: hidden; background: none; margin-left: 0px;">No NFTs Found</pre>
                                                    </aside>
                                                  </article>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </div>

                                        <div class="divider mt-0 is-centered"></div>
                                        <br/>

                                      </div>
                                    </div>
                                  </div>
                                </section>


                              </section>




                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                    <div style="margin-bottom: 4%"></div>
                  </div>
                </div>

              </div>
            </div>

            <div style="margin-bottom: 10%"></div>

          </section>
        </div>
      </section>

    </transition>

    <!--Sign TX Modal-->
    <b-modal v-if="this.showTxModal === true" v-model="showTxModal" :width="640" scroll="keep"
             style="border-radius:20px;">

      <div class="form-bg" style="border-radius:20px;">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-md-offset-4 blue-bg">
              <div class="form-container form-shadow">
                <div class="form-icon"><img src="../assets/stake-factory-icon.svg" width="70px"></div>
                <div class="container pb-4">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader">
                      A new signature request was just sent to your wallet:
                    </h3>
                  </div>
                </div>
                <b-message class="is-success" style="background-color: rgb(9 40 52);">
                  <div class="columns is-centered pt-4 pb-0">
                    <div class="column is-centered" style="overflow-wrap: anywhere !important;">
                      {{ this.getCurrentSignRequest() }}
                    </div>
                  </div>
                </b-message>

                <div class="container mb-2">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader">
                      Review and sign the transaction in your wallet and click confirm below:
                    </h3>
                  </div>
                </div>

                <form class="form-horizontal">
                  <div class="columns is-mobile is-centered">
                    <div class="column">
                      <div class="notification has-text-centered inset-bg-rounded2" style="background-color: #2d3a45;">
                        <div @click="closeSignatureModal" class="btn2 btn-lg stake-btn"
                             style="border-radius: 1rem !important;">
                          <span>Confirm</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>





  </div>
</template>

<script>
//import vue store variables to this page
import countdown from "@/components/countdownlist";
import {mapGetters, mapActions, mapState} from "vuex";
import FlipCardMintPreview from "./FlipCardMintPreview.vue";
import Accordion from "@/components/accordion";
import AccordionItem from "@/components/accordion-item";


//local state
export default {
  name: "NftPoolsPage",
  props: {
    collection: {
      type: String,
      default: "0"
    }
  },
  components: {
    FlipCardMintPreview,
    Accordion,
    AccordionItem,
    countdown
  },
  data() {
    return {
      debug: true,
      showTxModal: false,
      showTransferModal: false,
      activeTab: "0",
      currentPage: "0",
      goToPage: null,
      rescanAllPoolsOnRefresh: false,
      showLoadingModal: true,
      accountApproved: false,
      user_name: null,
      perPage: 24,
      wlactive: false,

      //nfts
      l_userNFTs:[],
      allNFTsToShowNow: [],
      allNFTsToShowNow_Paginated:[],
      allNFTsToShowNow_Searched: [],

      //Fungiv2 Polyfungiv1
      allPoolsFactoryStakeFungiv2Polyfungiv1: [],
      allActivePoolsFactoryStakeFungiv2Polyfungiv1:[],
      allActivePoolsIdsFungiv2Polyfungiv1: [],
      allUserPoolsFactoryStakeFungiv2Polyfungiv1: [],
      allUserPoolsIdsFungiv2Polyfungiv1:[],
      allPoolsToShowFactoryStakeFungiv2Polyfungiv1: [],
      allPoolsToShowFactoryStakeFungiv2Polyfungiv1_Searched: [],
      allPoolsPaginatedFactoryStakeFungiv2Polyfungiv1: [],
      allPoolIdsUserIsNotStakedInFungiv2Polyfungiv1:[],
      pageFungiv2Polyfungiv1: 1,
      pagesFungiv2Polyfungiv1: [],
      searchedFungiv2Polyfungiv1: false,
      searchStringFungiv2Polyfungiv1: "",
      searchAllFungiv2Polyfungiv1: true,
      collectionDetails: [],
      whitelist_endtime: null,
      whitelist_maxbuy: 0,
      currentDate: null,
      iswhitelisted: false,

    };
  },
  watch: {
    allNFTsToShowNow_Paginated: async function(){
      if(this.debug){
        console.log("allNFTsToShowNow_Paginated change detected");
      }
      this.setPagesFungiv2Polyfungiv1();
    },
    allPoolsPaginatedFactoryStakePolyfungiv1Fungiv2: async function(){
      if(this.debug){
        console.log("allPoolsPaginatedStakePolyfungiv1Fungiv2 change detected");
      }
      this.setPagesPolyfungiv1Fungiv2();
    },
    allPoolsPaginatedFactoryStakePolyfungiv1: async function(){
      if(this.debug){
        console.log("allPoolsPaginatedStakePolyfungiv1 change detected");
      }
      this.setPagesPolyfungiv1();
    },
    goToPage: async function (newValue){

      if(newValue === "2"){

        await this.$router.push({path: `/mynfts`});

      }else if(newValue === "3"){

        await this.$router.push({path: `/explore`});

      }

    },
    //lets watch our transactionPolling variable
    transactionPolling: async function (newValue, oldValue) {
      if (this.debug) {
        console.log("getTransactionPolling newValue: " + newValue);
        console.log("getTransactionPolling oldValue: " + oldValue);
      }
      if (newValue === false && oldValue === true) {


        let name = localStorage.getItem("accountName");

        let t_name_payload = {
          name: name
        }

        await this.$store.dispatch("accounts/getCurrentBindings", t_name_payload);

        if(this.rescanAllPoolsOnRefresh === true){


          setTimeout(async ()=>{
            this.showLoadingModal = true;
            await this.reScanPools();
            this.showLoadingModal = false;
          }, 3000)

        }

        this.rescanAllPoolsOnRefresh = false;

        this.showLoadingModal = false;
      }

    },

  },
  computed: {
    //import state variables from our vue store
    ...mapState("accounts", ["transactionPolling"]),

  },
  methods: {
    //map getters from vue store
    ...mapActions("accounts", ["getURIpolyfungiv1", "getURIpolyfungiv1Image"]),
    ...mapGetters("accounts", ["getAccountExists", "getShowNavBar", "getAccountConfirmed", "getCurrentSignRequest", "getUserNFTs", "getMarketNFTs"]),

    async clickShowPageFungiv2Polyfungiv1(pagenumber) {

      let newfrom = (pagenumber * this.perPage) - this.perPage; //0 9
      let newto = (this.pageFungiv2Polyfungiv1 * this.perPage); //9 18


      if (newfrom < 0) {
        newfrom = 0;
      }

      if (this.debug) {
        console.log("newfrom: " + newfrom);
        console.log("newTo: " + newto);
      }

      if(this.searchAllFungiv2Polyfungiv1 === true){
        await this.getNftsToShowNow(newfrom, newto, this.allNFTsToShowNow);
      }else {
        await this.getNftsToShowNow(newfrom, newto, this.allNFTsToShowNow);
      }



    },

    setPagesFungiv2Polyfungiv1() {

      let nftlist = [];

      if(this.searchAllFungiv2Polyfungiv1 === true){
        nftlist = this.allNFTsToShowNow;
      }else {
        nftlist = this.allNFTsToShowNow_Paginated;
      }

      let numberOfPages = Math.ceil(nftlist.length / this.perPage);

      if(this.debug){
        console.log("setPagesFungiv2Polyfungiv1");
        console.log("nftlist");
        console.log(nftlist);
        console.log("nftlist.length");
        console.log(nftlist.length);
        console.log("numberofpages");
        console.log(numberOfPages);
      }

      this.pagesFungiv2Polyfungiv1 = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesFungiv2Polyfungiv1.push(index);
      }
      if (this.debug) {
        console.log("pagesFungiv2Polyfungiv1");
        console.log(this.pagesFungiv2Polyfungiv1);
      }
    },

    async getNftsToShowNow(from, to, poolsToShow) {

      this.allNFTsToShowNow_Paginated = [];

      const nftsToShowList = poolsToShow;

      for (let i = from; i < to; i++) {

        if (i <= nftsToShowList.length - 1) {

          if (this.allNFTsToShowNow_Paginated.includes(nftsToShowList[i]) === false) {
            this.allNFTsToShowNow_Paginated.push(nftsToShowList[i]);
          }


        }
      }
    },

    async clickSearch(){

      if(this.debug){
        console.log("clickSearch");
      }
      this.searchedFungiv2Polyfungiv1 = true;

      this.allNFTsToShowNow_Searched = [];

      if(this.allNFTsToShowNow && this.allNFTsToShowNow.length >0){


        for(let i = 0; i < this.allNFTsToShowNow.length; i++){

          if(this.debug){
            console.log("comparing");
            console.log(this.allNFTsToShowNow[i].id);
            console.log("to");
            console.log(this.searchStringFungiv2Polyfungiv1)
          }

          let exp = new RegExp(this.searchStringFungiv2Polyfungiv1.toString(), "i");

          if(this.allNFTsToShowNow[i].id.match(exp) !== null || this.allNFTsToShowNow[i].name.match(exp) !== null || this.allNFTsToShowNow[i].collectionid.match(exp) !== null){

            if(this.debug){
              console.log("matched");
            }

            if(this.allNFTsToShowNow_Searched.includes(this.allNFTsToShowNow[i]) === false){

              if(this.debug){
                console.log("inserted");
              }


              let doSearch = false;
              if(this.searchAllFungiv2Polyfungiv1 === false){
                doSearch = true;
              }

              if(doSearch === true){
                this.allNFTsToShowNow_Searched.push(this.allNFTsToShowNow[i]);
              }else if(doSearch === false) {
                this.allNFTsToShowNow_Searched.push(this.allNFTsToShowNow[i]);
              }



            }

          }

        }


        if(this.debug){
          console.log("allNFTsToShowNow_Searched");
          console.log(this.allNFTsToShowNow_Searched);
        }

        this.allNFTsToShowNow_Paginated = this.allNFTsToShowNow_Searched;
      }




    },

    async setReloadPage(){
      this.rescanAllPoolsOnRefresh = true;
    },

    async showTxModalEvent(){

      this.showTxModal = true;

    },

    async closeSignatureModal() {
      this.showTxModal = false;
    },

    coinName(namespace, name){
      let newnamespace = namespace+'.';
      let newname = name;
      if(namespace === null || namespace === 'null'){
        newnamespace = '';
      }
      if(name === null || name === 'null'){
        newname = '';
      }
      return newnamespace+newname;
    },

    reduceBalance(balance, prec = 6){
      if (balance) {
        if (balance.int) balance = balance.int;
        if (balance.decimal) balance = balance.decimal;

        if (parseFloat(balance) % 1 === 0) {
          return parseInt(balance);
        }

        return Math.trunc(parseFloat(balance) * Math.pow(10, prec)) / Math.pow(10, prec);
      }

      if (balance === 0) return 0;
    },

    async reScanPools() {
      //Lets get the users account from local storage if it exists
      let name = localStorage.getItem("accountName");
      this.currentDate = new Date().getTime();
      this.user_name = name;

      //Lets check if our user is logged in, if so, lets grab the users state from the blockchain, including their NFT balances
      if (name === null || name === "") {
        if (this.debug) {
          console.log("No user local accountName found.");
        }
      } else {

        //user nfts reset
        this.l_userNFTs = [];
        this.allNFTsToShowNow = [];


        let t_name_payload = {
          name: name
        }
        //bind factory
        await this.$store.dispatch("accounts/getCurrentBindings", t_name_payload);

        const collection = this.collection;

        let c_payload = {
          collectionid: collection
        }

        console.log("C PAYLOAD");
        console.log(c_payload);

        this.collectionDetails = await this.$store.dispatch("accounts/getCollectionDetails", c_payload)

        //get user nfts from bind
        this.l_userNFTs = await this.$store.dispatch("accounts/getAllCollections", c_payload)

        if(this.debug){
          console.log("COLLECTION NFTS");
          console.log(this.l_userNFTs);
          console.log("COLLECTION DETAILS");
          console.log(this.collectionDetails);
        }

        //Update local state accountApproved so we can check for this before the page loads
        this.accountApproved = this.getAccountConfirmed();

        //Lets go through our complete list of pools
        for (let l = this.l_userNFTs.length - 1; l >= 0; l--) {

          if (this.debug === true) {
            console.log("NFT ID:");
            console.log(this.l_userNFTs[l].id)
          }

          let newa2 = [];
          for (let n = this.l_userNFTs[l].attributes.a2.length - 1; n >= 0; n--) {
            if(this.l_userNFTs[l].attributes.a2[n].decimal !== undefined){
              newa2.push(Number(this.l_userNFTs[l].attributes.a2[n].decimal))
            }else {
              newa2.push(Number(this.l_userNFTs[l].attributes.a2[n]))
            }
          }

          let newa3 = [];
          for (let m = this.l_userNFTs[l].attributes.a3.length - 1; m >= 0; m--) {
            if(this.l_userNFTs[l].attributes.a3[m].int !== undefined){
              newa3.push(Number(this.l_userNFTs[l].attributes.a3[m].int))
            }else {
              newa3.push(Number(this.l_userNFTs[l].attributes.a3[m]))
            }
          }

          let isforsale = this.l_userNFTs[l]["forsale"];

          const isshown = this.l_userNFTs[l]["shown"];

          let isactive = this.l_userNFTs[l]["active"];

          this.wlactive = isactive;

          const check_payload = {
            collectionid: this.collection,
            userid: name,
          }

          let iswhitelisted = await this.$store.dispatch("accounts/checkIfCollectionWhitelisted", check_payload);

          console.log("ISWHITELISTED:");
          console.log(iswhitelisted);

          this.iswhitelisted = iswhitelisted;



          let newprice = this.l_userNFTs[l].marketprice;

          if(isactive === true){
            newprice = this.l_userNFTs[l].price;
          }


          if (this.l_userNFTs[l].price.decimal !== undefined && this.l_userNFTs[l].price.decimal !== null) {
            newprice = this.l_userNFTs[l].price.decimal;
          }

          console.log("NEWPRICE");
          console.log(newprice);

          console.log("isforsale");
          console.log(isforsale);
          console.log(typeof(isforsale));

          let ispoolowner = false;
          if(name === this.l_userNFTs[l].account){
            ispoolowner = true;
          }

          if(this.l_userNFTs[l]["endtime"]){

            let t_pool_endtime = this.l_userNFTs[l]["endtime"];

            if (this.l_userNFTs[l]["endtime"].time !== undefined) {
              t_pool_endtime = this.l_userNFTs[l]["endtime"].time;
            }else if (this.l_userNFTs[l]["endtime"].timep !== undefined) {
              t_pool_endtime = this.l_userNFTs[l]["endtime"].timep;
            }

            let date_pool_end_time = new Date(t_pool_endtime);
            this.whitelist_endtime = date_pool_end_time.getTime();

          }

          if(isforsale === false && isactive === true && iswhitelisted === true && (this.currentDate - this.whitelist_endtime) < 0){
            isforsale = true;
          }

          if(isforsale === false && isactive === true && iswhitelisted === true && (this.currentDate - this.whitelist_endtime) > 0){
            isactive = false;
          }

          const tokenNamespace = this.l_userNFTs[l].token.refName.namespace;
          const tokenName = this.l_userNFTs[l].token.refName.name;

          let nftDetailsObject = {
            id: this.l_userNFTs[l].id,
            collectionid: this.l_userNFTs[l]["collection-id"],
            description: this.l_userNFTs[l].description,
            image: this.l_userNFTs[l].image,
            name: this.l_userNFTs[l].name,
            a1: this.l_userNFTs[l].attributes.a1,
            a2: newa2,
            a3: newa3,
            forsale: isforsale,
            price: newprice,
            token: this.coinName(tokenNamespace, tokenName),
            factoryaccount: this.l_userNFTs[l].factoryaccount,
            ispoolowner: ispoolowner,
            wlactive: isactive
          }

          if (this.allNFTsToShowNow.includes(nftDetailsObject) === false && isshown === true || this.allNFTsToShowNow.includes(nftDetailsObject) === false && ispoolowner === true) {
            this.allNFTsToShowNow.push(nftDetailsObject);
          }



        }

        await this.getNftsToShowNow(0, this.perPage, this.allNFTsToShowNow);




      }
    },



  },
  async created() {

    let name = localStorage.getItem("accountName");

    //Lets check if our user is logged in for the pool creation page, if not, lets redirect them to home page
    if (name === null || name === "" || name === "null") {
      if (this.debug) {
        console.log("No user local accountName found.");
      }
      await this.$router.push({path: `/`});
    }else {
      await this.reScanPools();

      //Close loading screen
      setTimeout(async () => {
        this.showLoadingModal = false;
      }, 500);
    }

  }
};
</script>

<style scoped>

#style-10::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #200d31;
  border-radius: 10px;
}

#style-10::-webkit-scrollbar
{
  width: 10px;
  background-color: #2c2432;
}

#style-10::-webkit-scrollbar-thumb
{
  background-color: #2c2432;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
  rgba(0, 0, 0, .2) 25%,
  transparent 25%,
  transparent 50%,
  rgba(0, 0, 0, .2) 50%,
  rgba(0, 0, 0, .2) 75%,
  transparent 75%,
  transparent)
}



/deep/ .button.is-buttonblue {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}


/deep/ .button.is-buttonblue:hover {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}

.pool-section-title:hover {
  border: 5px solid rgba(72, 199, 142, 0.38);
}


.leatherbg {
  background: radial-gradient(#00000078 0%, transparent 10%) 0 0, radial-gradient(#0000005c 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 250, 250, 0) 15%, transparent 20%) 8px 9px;
  background-color: #2c243275 !important;
  background-size: 16px 16px;
  border: 2px solid #11426573;
  background-blend-mode: darken;
}

.pagination-outer{ text-align: center; }
.pagination{
  background: rgba(21, 21, 21, 0.32);
  font-family: 'Sniglet', cursive;
  padding: 5px;
  box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  display: inline-flex;
  position: relative;
}
.pagination li a.page-link{
  color: #4ea4c9;
  background-color: #262836;
  font-size: 16px;
  line-height: 33px;
  height: 45px;
  width: 50px;
  margin: 0 2px 0 0;
  border: 1px solid #202020;
  border-top: 1px solid #353535;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.3);
  border-radius: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
  padding: 10px;
}
.pagination li.active a.page-link,
.pagination li a.page-link:hover,
.pagination li.active a.page-link:hover{
  color: rgba(46, 204, 113, 0.44);
  background: #1e202a;
  text-shadow: 0 0 15px rgba(46, 204, 113, 0.5);
  border: 1px solid transparent;
  border-bottom: 1px solid #252525;
  box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, 0.5);
}
.pagination li:first-child a.page-link{ border-radius: 40px 0 0 40px; }
.pagination li:last-child a.page-link{
  margin-right: 0;
  border-radius: 0 40px 40px 0;
}
@media only screen and (max-width: 480px){
  .pagination{
    display: block;
    border-radius: 20px;
  }
  .pagination li{
    margin: 5px 2px;
    display: inline-block;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.form-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.btn2 {
  color: #87b6e5;
  background-color: rgba(38, 89, 125, 0.6) !important;
  background-repeat: no-repeat;
  font-family: 'Noto', sans-serif;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}

.btn2:focus,
.btn2:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(38, 89, 125, 0.6);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn2 {
    margin-bottom: 30px;
  }
}

.form-container {
  background-color: #2e3945;
  border-radius: 1rem;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #6ea4db;
  border-width: 20px;
  padding: 40px;
}

.form-container .form-icon {
  font-size: 8px;
  text-align: center;
  line-height: 160px;
  background-color: #2d3a45;
  width: 100px;
  height: 100px;
  margin: 0 auto 15px;
  border-radius: 50px;
  box-shadow: 7px 7px 10px #1f272f, -7px -7px 10px #1f272f;
}

.form-container .title {
  color: #ac40ab;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 20px;
}

.form-container .form-horizontal .form-group {
  margin: 0 0 25px 0;
}

.form-container .form-horizontal .form-group label {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.form-container .form-horizontal .form-control {
  color: #333;
  background: #ecf0f3;
  font-size: 15px;
  height: 50px;
  padding: 20px;
  letter-spacing: 1px;
  border: none;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.form-container .form-horizontal .form-control:focus {
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  outline: none;
}

.form-container .form-horizontal .form-control::placeholder {
  color: #808080;
  font-size: 14px;
}

.inset-bg-rounded2 {
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}


.blue-bg {
  background-color: #314b6a;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #6ea4db;
}

.noscroll::-webkit-scrollbar {
  display: none;
}

.col-title-image {
  height: 30px;
  top: 17px;
  margin-top: 6px;
}

.pool-col-title {
  display: flex;
  flex-direction: column;
}

.machineinterface {
  display: block;
  width: 100%;
  margin: -80px 0px;
  position: relative;
}

.monitor {
  min-height: 100vh;
  padding-top: 85px;
  z-index: 2;
  display: block;
  width: 100%;
  background-color: #47485a;
  position: relative;
  padding-bottom: 40px;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, #26202b08 35px, #26202b0d 70px);
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
}

.monitor-inner {
  padding-top: 15px;
  display: block;
  width: 95%;
  background-image: conic-gradient( #414254 0 10.5%, #414254 11% 11.5%, #414254 12% 38%, #414254 39% 61.5%, #414254 62% 88%, #414254 88.5% 89%, #414254 89.25% );
  margin: auto;
  position: relative;
  top: 20px;
  left: 0;
  right: 0;
  border-radius: 5px;
  margin-top: -51px;
  padding-bottom: 60px;
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient(#3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5%);
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 50px;
}

.screen {
  display: block;
  width: 95%;
  background-color: #4F5555;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  margin-top: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 30px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), transparent 85%), radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%), linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 7px -4px #4f545f inset, 0 -2px 1px #5c6574 inset;
}

.header-bg3 {
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
  height: 1px;
  padding-top: 0px;
}

.b-tabs .tabs {
  overflow: hidden !important;
}

/deep/ .b-tabs .tabs {
  overflow: hidden !important;
}

::v-deep .b-tabs .tabs {
  overflow: hidden !important;
}

.center-flex{
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.pending-col{
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.pools-bg {
  background-color: #222b3a;
}

.pool-main {
  background-color: #222b3a;
  border-radius: 1rem !important;
}

.pool-section-title {
  background-color: #2c3444;
  padding: 1.5rem;
  border: 5px solid rgb(38 43 60);
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #46a9e7;
  font-family: Source Code Pro,monospace !important;
}

/* border for item cards */
.cardborder {
  border-radius: 1rem;
  color: #000000;
}

.poolselect-bg {
  background-color: #222b3a !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}


/deep/ .tabs.is-toggle li.is-active a {
  background-color: #252f3e;
  color: #3abb81;
  z-index: 1;
  font-family: Source Code Pro,monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}


/deep/ .tabs.is-toggle {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  background-color: #252f3e;
}

/deep/ .tabs.is-toggle a {
  margin-bottom: 0;
  position: relative;
  font-family: Source Code Pro,monospace !important;
  border:none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  color: #476074;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: #222b3a;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: #222b3a;
  border:none;
  color: #00b89c;
}

.pools-card{
  padding-top:.5rem;
  color: #0d64c6;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow{
  box-shadow: rgba(0, 0, 0, 0.56) 8px -5px 40px;
  border-radius: 30px;
}

.stake-btn-col{
  display: flex;
  justify-content: center;
}

.btn {
  color: #87b6e5;
  background: rgb(34 77 118);
  background-repeat: no-repeat;
  font-family: Source Code Pro,monospace !important;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.btn:focus,
.btn:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    margin-bottom: 30px;
  }
  .small-pool{
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0 !important;
  }
}

/deep/ input {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
  box-shadow: inset 0 30px 60px -12px rgba(50, 50, 93, 0.25), inset 0 18px 36px -18px rgba(0, 0, 0, 0.30);
}


@media screen and (max-width: 769px), print {
  .screen-cutout {
    background-image: none !important;
    background-color: transparent !important;
  }

  /deep/ .super-select-dropdown {
    background-color: #333b4c !important;
    border: 1px solid !important;
    border-color: #2f597d !important;
    color: #67b6ef !important;
    width: 380px !important;
  }
}

/* page loading style */
.loaderstyle {
  z-index: 1;
  position: absolute !important;
}

#wraper {
  width: 400px;
  height: 350px;
  margin: 90px 11px;
  position: relative;
  border-radius: 40px;
}

#wraper2 {
  width: 450px;
  height: 350px;
  margin: 0 auto;
  position: relative;
  border-radius: 40px;
  margin-left: -36px;
}


</style>
<style lang="scss" scoped>

.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro,monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

$message-header-background-color: #e3f;

.loading-page-container {
  opacity: .95;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  z-index: 1;

}

.loading-page-container .progress {
  position: relative;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 6px solid #20182d;
  background-color: #2c2938;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: hard-light;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;

  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 1;
}

.loading-page-container .progress .inner {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: 50%;
  margin-left: -4px;
  margin-top: -1px;
  width: 196.5px;
  height: 196.5px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.loading-page-container .progress .inner .water {
  opacity: .55;
  position: absolute;
  z-index: 1;
  width: 200%;
  height: 200%;
  left: -50%;
  border-radius: 40%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  animation-name: spin;
  top: 55%;
  background: #32BAFA;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  box-shadow: 0 0 20px #152A4A;

}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  display:flex;
  width: 35px;
  height: 35px;
  border-radius: 0.5rem;
  color: #296992;
}
.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}



.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}


// for styles that do not have ascii characters setting the height
$height: 15px;

.outer {
  margin-bottom: $height;
  width: 100%;
  height: $height;
}

.plain {

  &.outer {
    border: 1px solid limegreen;
  }

  .inner {
    width: 90%;
    background-color: limegreen;
    height: $height;
  }
}

.borderless .inner {
  width: 90%;
  background-color: limegreen;
  height: $height;
}

.punctuation.outer {
  border: 1px solid limegreen;
  line-height: 1.35;
}

@-webkit-keyframes progress-bar-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes spinning {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes pulsing-opacity {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}


.plain .inner, .borderless .inner {
  animation: progress-bar-width steps(20) 5s infinite;
}

.pipes .inner, .blocks .inner, .blocks-gaps {
  animation: progress-bar-width 5s infinite;
  overflow: hidden;
  animation-timing-function: linear;
}

.blocks-gaps .inner {
  letter-spacing: 2px;
}

.activity {
  text-align: center;
  overflow: hidden !important;
}

.spinning {
  animation: spinning steps(8, end) 8s infinite;
}

.pulsing {
  animation: pulsing-opacity steps(3) 0.5s infinite alternate;
}


</style>
<style scoped>
.container3 {
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  /* padding: 25px; */
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), #0f5cbb26 85%), radial-gradient(rgb(38 58 74 / 75%), transparent 70%), linear-gradient(#0e081a00, #211922, #0f142000);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
}
.cow {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#14ff7d, #00f3ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}
</style>

<style lang="scss" scoped>
.container2 {
  background-color: #1D1F21;
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  padding: 25px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background: -webkit-linear-gradient(#3e10463d, #21333e70) !important;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 37px 59px #0e192257 inset, 0 0px 2px #ff0ef8 inset;
  -webkit-animation: scanline-data-v-0d33d4c2 8s linear infinite;
  animation: scanline-data-v-0d33d4c2 8s linear infinite;
}

article {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 900px) {
    padding: 5px;
  }

  @media (max-width: 899px) {
    padding-bottom: 56px;
  }
  background-color: rgba(192, 192, 192, 0);
  color: #323232;
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  position: relative;
  & > * + * {
    margin-top: 1.25em;
  }

  &:after {
    content: "🥩";
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding-bottom: 0.25em;
    position: absolute;
    top: 0.75em;
    right: 0.75em;
    width: 1.75em;
    height: 1.75em;
    filter: invert(100) opacity(40%);

    background-color: transparent;
  }
}

.headline {
  font-size: 2.5em;
  font-weight: 700;
}

.subhead {
  font-size: 1.25em;
  margin-top: 0.25em;
}

.article-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    width: 3em;
    height: 3em;
    flex-shrink: 0;
  }

  div:first-of-type {
    margin-left: 0.75em;
    font-size: 0.875em;
    margin-right: auto;
    padding-right: 0.75em;
  }

  div:last-of-type {
    font-size: 0.875em;
  }
}

aside {
  padding: 4em;
  border-right: 1px solid rgba(171, 15, 232, 0.1);
  border-bottom: 1px solid rgba(171, 15, 232, 0.1);
  border-left: 1px solid rgba(171, 15, 232, 0.1);
  border-top: 1px solid rgba(171, 15, 232, 0.1);
  clear: both;
  text-shadow: 0 0 10px, 0 0 40px;
  background-color: rgb(46 40 66 / 39%);
}

.tag {
  display: inline-block;
  margin-right: 0.25em;
  margin-top: 0.25em;
  border-right: 1px solid rgba(238, 238, 238, 0.19);
  border-bottom: 1px solid rgba(238, 238, 238, 0.13);
  border-left: 1px solid #444;
  border-top: 1px solid #444;
  padding: 0.125em 0.375em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}



figure {
  background-color: #c0c0c0;
  box-shadow: 0 0 0 1px #eee, 0 0 0 2px #c0c0c0, 0 0 0 3px #444;
  position: relative;
  margin-bottom: 1.25em;
  width: calc(100% + 1.5em + 2em);
  text-shadow: 0 0 10px, 0 0 40px;
  @media (min-width: 800px) {
    max-width: 50%;
  }
  &:nth-of-type(odd) {
    float: left;
    margin-right: 1.5em;
    margin-left: -3em;
  }

  &:nth-of-type(even) {
    float: right;
    margin-left: 1.5em;
    margin-right: -3em;
  }

  &:after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    background-image: linear-gradient(
            to top,
            rgba(#ff9100, 0.2) 0%,
            rgba(#ffe630, 0.2) 60%
    ),
    linear-gradient(20deg, rgba(#ff0, 0.5) 0%, rgba(#ff0, 0) 35%);
    box-shadow: inset 0px 0px 100px rgba(0, 0, 20, 1);
    z-index: 1;
  }

  img {
    // opacity: 0;
    // filter: hue-rotate(90deg);
    // filter: sepia(20%) brightness(10%) contrast(130%)
  }
  figcaption {
    font-family: "DotGothic16", monospace;
    background-color: #6f4b80;
    box-shadow: inset 0 -1px 0 0 #020202;
    color: #eee;
    text-shadow: 0 0 10px, 0 0 40px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.25em;
    font-size: 0.875em;
    z-index: 2;
  }
}
.blink {
  opacity: 1;
  animation: blink 1s linear infinite;
}

.scanline  {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba(255,255,255,0.02);
  animation: scanline 8s linear infinite;
  pointer-events: none;
  &:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: rgba(255,255,255,0.02);
    animation: scanline 8s linear infinite;
    pointer-events: none;
    z-index: 10;
  }

}

// Scanline height 1005
@keyframes scanline {
  0.01% {
    height: 0;
  }
  99.99% {
    height: 100%
  }
  100% {
    height: 0;
  }
}
</style>
