<template>
  <div v-bind:class="[flipped ? 'flip-container flipped' : 'flip-container']"
       class="column pool-main is-centered mb-4 mt-5">
    <div class="columns is-centered pools-card" :style="$screen.width < 576 ? 'margin-left: 0px !important; width: 100%':''">
      <div class="column is-1"></div>
      <div class="column pool-item is-centered is-align-self-center" :style="$screen.width < 576 ? 'padding-bottom: 0 !important; margin-bottom !important;' : ''">
        <accordion>
          <accordion-item :class="$screen.width < 770 ? 'small-pool' : ''">
            <!-- This slot will handle the title/header of the accordion and is the part you click on -->
            <template slot="accordion-trigger">
              <div class="columns" :class="$screen.width > 770 ? 'margin-over-large' : ''">
                <div class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Liquidity Pool: '+pool_name+' | Swap '+coinDisplayName(pair_tokenA)+' <=> '+coinDisplayName(pair_tokenB)">
                  <div class="pool-name">{{ pool_name }}</div>
                  <div class="provider">{{ coinDisplayName(pair_tokenA) }} <span style="color: #7d56ac !important;"><b-icon pack="fas" icon="arrows-h" size="is-small" style="position: relative; top: 4px;"></b-icon></span> {{ coinDisplayName(pair_tokenB) }}</div>
                  </b-tooltip>
                </div>
                <div class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Liquidity in pool: '+total_liquidity">
                  <div class="col-title">LIQUIDITY</div>
                  <div>{{ t_poolReserveA }} <span style="color: #7d56ac !important;"><b-icon pack="fas" icon="arrows-h" size="is-small" style="position: relative; top: 4px;"></b-icon></span>  {{ t_poolReserveB }}</div>
                  </b-tooltip>
                </div>
                <div class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Pool swap fee: '+fees">
                  <div class="col-title">FEE</div>
                  <div>{{ (Number(fees) * 100) }}%</div>
                  </b-tooltip>
                </div>
              </div>
            </template>
            <!-- pool accordion content -->
            <template slot="accordion-content">
              <div class="columns mt-3 mb-3"
                   style="position: relative; top: 10px; background-color: rgba(38,43,56,0.29); border-radius: 1rem;">
                <div class="column is-12 is-align-self-center">

                  <!-- if connected pool buttons-->
                  <section v-if="accountApproved === true" class="columns is-justify-content-center is-vcentered is-flex-direction-column">

                    <div class="my-pools-shadow column is-12 is-12-mobile" :class="$screen.width > 1024 ? '':''" style="z-index: 2; padding: 0px !important; border-radius: 3rem !important;"
                         :style="$screen.width < 700 ? '' : 'min-width: 480px;'">
                      <div class="card pools-bg leatherbg cardborder pb-4 " style="z-index: 2">


                        <div class="poolselect-bg mb-0 tradesection container">
                          <div class="columns is-centered is-flex" style="width: 100%; align-items: center;">
                            <div class="column is-8">
                              <b-tabs
                                  v-model="activeTab"
                                  headerClass="headertest"
                                  class="font1"
                                  position="is-centered"
                                  type="is-toggle"
                                  expanded
                              >
                                <b-tab-item value="0" label="Swap"></b-tab-item>
                                <b-tab-item value="1" label="Add"></b-tab-item>
                                <b-tab-item value="2" label="Remove"></b-tab-item>
                              </b-tabs>
<!--                              <div v-if="activeTab === '0'">Swap</div>-->
<!--                              <div v-if="activeTab === '1'">Settings</div>-->
                            </div>
                            <div class="column is-narrow is-narrow-mobile is-flex is-justify-content-center">
                              <a @click="clickShowSwap()" v-if="showSlippage === true" class="btm">
                                <b-icon
                                    pack="fas"
                                    icon="chevron-circle-left"
                                    size="is-small" style="position: relative; top:10px; left:10px; ">
                                </b-icon>
                              </a>
                            </div>
                            <div class="column is-narrow is-narrow-mobile is-flex is-justify-content-center">
                              <b-tooltip v-if="showSlippage === false" type="is-dark" :label="'Swap Settings'">
                              <a @click="clickShowSlippage()" v-if="showSlippage === false" class="btm">
                                <b-icon
                                    pack="fas"
                                    icon="sliders"
                                    size="is-small" style="position: relative; top:10px; left:10px; ">
                                </b-icon>
                              </a>
                              </b-tooltip>
                            </div>
                            <div class="column is-narrow is-narrow-mobile is-flex is-justify-content-center">
                              <b-tooltip type="is-dark" :label="'Pool Page'">
                              <a @click="clickPoolWindow()" class="btm">
                                <b-icon
                                    pack="fas"
                                    icon="window-maximize"
                                    size="is-small" style="position: relative; top:10px; left:10px; ">
                                </b-icon>
                              </a>
                              </b-tooltip>
                            </div>
                          </div>

                        </div>


                        <!--     TRADE SECTION -->
                        <div v-if="activeTab === '0' && showSlippage === false"  class="column is-8 is-12-mobile is-offset-2 is-offset-0-mobile">
                          <!-- TOP SWAP SECTION -->
                          <div class="column pool-main is-centered mb-2">
                            <div class="columns is-centered pools-card" :style="$screen.width < 576 ? 'width: 100% !important; margin: 0 !important':''">
                              <div class="column swap-section-top is-centered is-align-self-center">
                                <div class="columns m-0 p-0 is-flex-direction-column" style="z-index: 6">
                                  <div class="column m-0 p-0">
                                    <p class="control" style="z-index: 3">
                                      <v-super-select ref="topswap" placeholder="Select" @input="inputChange($event)"
                                                      :items="topSwapPairsList"
                                                      v-model="currentSelectionTop" inputHeight="40px"
                                                      inputWidth="120px"
                                                      mobileHeaderBackgroundColor="#2c2432"
                                                      mobileHeaderBorderBottom="#2c2432">
                                        <option value="1">KDA</option>
                                        <option value="2">ADK</option>
                                      </v-super-select>
                                    </p>
                                  </div>
                                  <div class="column m-0 p-0">
                                    <div class="available">{{ displayFixedTopBalance }}</div>
                                  </div>
                                </div>

                                <div v-if="$screen.width > 576" class="field-body">
                                  <div class="field has-addons">
                                    <p class="control margin-large-swap-input"
                                       :class="$screen.width > 764 ? 'margin-large-swap-input' : ''"
                                       style="width: 100%;">
                                      <b-numberinput v-model="topSwapAmount" :exponential=".5"
                                                     @input="[setDoSwapIn(), swapAmountTopChange()]" :min="0" :step="1"
                                                     type="is-buttonblue" expanded controls-position="compact"
                                                     controls-alignment="right"></b-numberinput>
                                    </p>
                                    <p class="control">
                                      <b-button @click="clickTopSwapMaxButton()" type="is-buttonblue" label="MAX"/>
                                    </p>
                                  </div>
                                </div>

                                <div v-if="$screen.width < 576" class="field-body">
                                  <div class="field has-addons">
                                    <p class="control margin-large-swap-input"
                                       :class="$screen.width > 764 ? 'margin-large-swap-input' : ''"
                                       style="width: 100%;">
                                      <b-input v-model="topSwapAmount" @input="[setDoSwapIn(), swapAmountTopChange()]" :min="0"
                                               type="number" custom-class="is-buttonblue-small" expanded
                                               controls-position="compact" controls-alignment="right"></b-input>
                                    </p>
                                    <p class="control">
                                      <b-button @click="clickTopSwapMaxButton()" type="is-buttonblue" label="MAX"/>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- MIDDLE CIRCLE PLUS FLIPPER -->
                          <div class="column pool-main is-centered mb-1 mt-1">
                            <div class="columns is-centered pools-card" :style="$screen.width < 576 ? 'width: 100% !important; margin: 0 !important':''">
                              <div class="column"></div>
                              <div @click="clickFlip()"
                                   class="column is-2 pool-section-round is-centered is-align-self-center btn-flip"
                                   :class="$screen.width < 770 ? 'centered-swap-icon':''" style="width: 50px">
                                <b-icon
                                    pack="fas"
                                    icon="arrow-circle-down"
                                    size="is-small" style="position: relative; top:2px; ">
                                </b-icon>
                              </div>
                              <div class="column"></div>
                            </div>
                          </div>

                          <!-- BOTTOM SWAP INPUT -->
                          <div class="column pool-main is-centered mb-2">
                            <div class="columns is-centered pools-card" :style="$screen.width < 576 ? 'width: 100% !important; margin: 0 !important':''">
                              <div class="column swap-section-top is-centered is-align-self-center">
                                <div class="columns m-0 p-0 is-flex-direction-column">
                                  <div class="column m-0 p-0">
                                    <p class="control" style="z-index: 3">
                                      <v-super-select ref="bottomswap" placeholder="Select"
                                                      @input="inputChangeBottom($event)" :items="bottomSwapPairsList"
                                                      v-model="currentSelectionBottom" inputHeight="40px"
                                                      inputWidth="120px">
                                        <option value="1">KDA</option>
                                        <option value="2">ADK</option>
                                      </v-super-select>
                                    </p>
                                  </div>
                                  <div class="column m-0 p-0">
                                    <div class="available">{{ displayFixedBottomBalance }}</div>
                                  </div>
                                </div>

                                <div v-if="$screen.width > 576" class="field-body">
                                  <div class="field has-addons">
                                    <p class="control margin-large-swap-input"
                                       :class="$screen.width > 764 ? 'margin-large-swap-input' : ''"
                                       style="width: 100%;">
                                      <b-numberinput v-model="bottomSwapAmount" @input="[setDoSwapOut(), swapAmountBottomChange()]"
                                                     :exponential=".5" :min="0" :step="1" type="is-buttonblue" expanded
                                                     controls-position="compact"
                                                     controls-alignment="right"></b-numberinput>
                                    </p>
                                    <p class="control">
                                      <b-button @click="clickBottomSwapMaxButton()" type="is-buttonblue" label="MAX"/>
                                    </p>
                                  </div>
                                </div>

                                <div v-if="$screen.width < 576" class="field-body">
                                  <div class="field has-addons">
                                    <p class="control margin-large-swap-input"
                                       :class="$screen.width > 764 ? 'margin-large-swap-input' : ''"
                                       style="width: 100%;">
                                      <b-input v-model="bottomSwapAmount" @input="[setDoSwapOut(), swapAmountBottomChange()]" :min="0"
                                               type="number" custom-class="is-buttonblue-small" expanded
                                               controls-position="compact" controls-alignment="right"></b-input>
                                    </p>
                                    <p class="control">
                                      <b-button @click="clickBottomSwapMaxButton()" type="is-buttonblue" label="MAX"/>
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>


                          <!-- SWAP BUTTON -->
                          <div v-if="insufficientFunds === false && insufficientLiquidity === false" class="column pool-main is-centered mb-0 mt-0">
                            <div class="columns is-centered pools-card">
                              <div @click="clickSwap()"
                                   class="column pool-section-title is-centered is-align-self-center is-buttonblueswap btn-swap">
                                <span class="font5">Swap</span>
                              </div>
                            </div>
                          </div>


                          <!-- Insufficient Funds Msg -->
                          <div v-if="insufficientFunds === true && insufficientLiquidity === false"
                               class="column pool-main is-centered mb-0 mt-0">
                            <div class="columns is-centered pools-card">
                              <div
                                  class="column insufficient-funds-section is-centered is-align-self-center is-buttonlowfunds btn-lowfunds">
                                <!--                          <b-button expanded label="SWAP" class="is-buttonblueswap font5" style="width: 100%; border-radius: 2rem" />-->
                                <span class="font5">Insufficient Funds</span>
                              </div>
                            </div>
                          </div>

                          <!-- Insufficient Liquidity Msg -->
                          <div v-if="insufficientLiquidity === true" class="column pool-main is-centered mb-0 mt-0">
                            <div class="columns is-centered pools-card">
                              <div
                                  class="column insufficient-funds-section is-centered is-align-self-center is-buttonlowfunds btn-lowfunds">
                                <!--                          <b-button expanded label="SWAP" class="is-buttonblueswap font5" style="width: 100%; border-radius: 2rem" />-->
                                <span class="font5">Insufficient Liquidity</span>
                              </div>
                            </div>
                          </div>

                        </div>

                        <!--     ADD LIQUIDITY -->
                        <div v-if="activeTab === '1' && showSlippage === false"  class="column is-8 is-12-mobile is-offset-2 is-offset-0-mobile">
                          <!-- ADD LIQUIDITY TOP SECTION -->
                          <div class="column pool-main is-centered mb-2">
                            <div class="columns is-centered pools-card" :style="$screen.width < 576 ? 'width: 100% !important; margin: 0 !important':''">
                              <div class="column swap-section-top is-centered is-align-self-center">
                                <div class="columns m-0 p-0 is-flex-direction-column" style="z-index: 6">
                                  <div class="column m-0 p-0">
                                    <p class="control" style="z-index: 3">
                                      <v-super-select ref="topswap" placeholder="Select"
                                                      @input="inputChange($event)" :items="topSwapPairsList"
                                                      v-model="currentSelectionTop" inputHeight="40px"
                                                      inputWidth="120px"
                                                      mobileHeaderBackgroundColor="#2c2432"
                                                      mobileHeaderBorderBottom="#2c2432">
                                        <option value="1">KDA</option>
                                        <option value="2">ADK</option>
                                      </v-super-select>
                                    </p>
                                  </div>
                                  <div class="column m-0 p-0">
                                    <div class="available">{{ displayFixedTopBalance }}</div>
                                  </div>
                                </div>


                                <div v-if="$screen.width > 576" class="field-body">
                                  <div class="field has-addons">
                                    <p class="control margin-large-swap-input"
                                       :class="$screen.width > 764 ? 'margin-large-swap-input' : ''"
                                       style="width: 100%;">
                                      <b-numberinput v-model="topSwapAmount" :exponential=".5"
                                                     @input="swapAmountTopChange()" :min="0" :step="1"
                                                     type="is-buttonblue" expanded controls-position="compact"
                                                     controls-alignment="right"></b-numberinput>
                                    </p>
                                    <p class="control">
                                      <b-button @click="clickTopSwapMaxButton()" type="is-buttonblue"
                                                label="MAX"/>
                                    </p>
                                  </div>
                                </div>

                                <div v-if="$screen.width < 576" class="field-body">
                                  <div class="field has-addons">
                                    <p class="control margin-large-swap-input"
                                       :class="$screen.width > 764 ? 'margin-large-swap-input' : ''"
                                       style="width: 100%;">
                                      <b-input v-model="topSwapAmount" @input="swapAmountTopChange()" :min="0"
                                               type="number" custom-class="is-buttonblue-small" expanded
                                               controls-position="compact" controls-alignment="right"></b-input>
                                    </p>
                                    <p class="control">
                                      <b-button @click="clickTopSwapMaxButton()" type="is-buttonblue"
                                                label="MAX"/>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- MIDDLE CIRCLE PLUS FLIPPER -->
                          <div class="column pool-main is-centered mb-1 mt-1">
                            <div class="columns is-centered pools-card" :style="$screen.width < 576 ? 'width: 100% !important; margin: 0 !important':''">
                              <div class="column"></div>
                              <div @click="clickFlip"
                                   class="column is-2 pool-section-round is-centered is-align-self-center btn-flip"
                                   :class="$screen.width < 770 ? 'centered-swap-icon':''" style="width: 50px">
                                <b-icon
                                    pack="fas"
                                    icon="plus-circle"
                                    size="is-small" style="position: relative; top:2px; ">
                                </b-icon>
                              </div>
                              <div class="column"></div>
                            </div>
                          </div>

                          <!-- ADD LIQUIDITY BOTTOM INPUT -->
                          <div class="column pool-main is-centered mb-2">
                            <div class="columns is-centered pools-card" :style="$screen.width < 576 ? 'width: 100% !important; margin: 0 !important':''">
                              <div class="column swap-section-top is-centered is-align-self-center">
                                <div class="columns m-0 p-0 is-flex-direction-column">
                                  <div class="column m-0 p-0">
                                    <p class="control" style="z-index: 3">
                                      <v-super-select ref="bottomswap" placeholder="Select"
                                                      @input="inputChangeBottom($event)"
                                                      :items="bottomSwapPairsList"
                                                      v-model="currentSelectionBottom" inputHeight="40px"
                                                      inputWidth="120px"
                                                      mobileHeaderBackgroundColor="#2c2432"
                                                      mobileHeaderBorderBottom="#2c2432">
                                        <option value="1">KDA</option>
                                        <option value="2">ADK</option>
                                      </v-super-select>
                                    </p>
                                  </div>
                                  <div class="column m-0 p-0">
                                    <div class="available">{{ displayFixedBottomBalance }}</div>
                                  </div>
                                </div>

                                <div v-if="$screen.width > 576" class="field-body">
                                  <div class="field has-addons">
                                    <p class="control margin-large-swap-input"
                                       :class="$screen.width > 764 ? 'margin-large-swap-input' : ''"
                                       style="width: 100%;">
                                      <b-numberinput v-model="bottomSwapAmount" @input="swapAmountBottomChange()"
                                                     :exponential=".5" :min="0" :step="1" type="is-buttonblue"
                                                     expanded controls-position="compact"
                                                     controls-alignment="right"></b-numberinput>
                                    </p>
                                    <p class="control">
                                      <b-button @click="clickBottomSwapMaxButton()" type="is-buttonblue"
                                                label="MAX"/>
                                    </p>
                                  </div>
                                </div>

                                <div v-if="$screen.width < 576" class="field-body">
                                  <div class="field has-addons">
                                    <p class="control margin-large-swap-input"
                                       :class="$screen.width > 764 ? 'margin-large-swap-input' : ''"
                                       style="width: 100%;">
                                      <b-input v-model="bottomSwapAmount" @input="swapAmountBottomChange()"
                                               :min="0" type="number" custom-class="is-buttonblue-small" expanded
                                               controls-position="compact" controls-alignment="right"></b-input>
                                    </p>
                                    <p class="control">
                                      <b-button @click="clickBottomSwapMaxButton()" type="is-buttonblue"
                                                label="MAX"/>
                                    </p>
                                  </div>
                                </div>


                              </div>
                            </div>
                          </div>


                          <!-- SWAP BUTTON -->
                          <div v-if="insufficientFunds === false" class="column pool-main is-centered mb-0 mt-0">
                            <div class="columns is-centered pools-card">
                              <div @click="clickAddLiquidity()"
                                   class="column pool-section-title is-centered is-align-self-center is-buttonblueswap btn-swap">
                                <!--                          <b-button expanded label="SWAP" class="is-buttonblueswap font5" style="width: 100%; border-radius: 2rem" />-->
                                <span class="font5">Add Liquidity</span>
                              </div>
                            </div>
                          </div>

                          <!-- Insufficient Funds Msg -->
                          <div v-if="insufficientFunds === true" class="column pool-main is-centered mb-0 mt-0">
                            <div class="columns is-centered pools-card">
                              <div
                                  class="column insufficient-funds-section is-centered is-align-self-center is-buttonlowfunds btn-lowfunds">
                                <!--                          <b-button expanded label="SWAP" class="is-buttonblueswap font5" style="width: 100%; border-radius: 2rem" />-->
                                <span class="font5">Insufficient Funds</span>
                              </div>
                            </div>
                          </div>

                        </div>

                        <!--     REMOVE LIQUIDITY -->
                        <div v-if="activeTab === '2' && showSlippage === false"  class="column is-8 is-12-mobile is-offset-2 is-offset-0-mobile">
                          <!-- REMOVE LIQUIDITY TOP SECTION -->
                          <div class="column pool-main is-centered" style="margin-top: -12px !important; padding-bottom: 8px;">
                          <div class="columns m-0"
                               style="display: flex; flex-direction: row; justify-content: center;">
                            <div class="column is-5 is-7-mobile pool-main-remove is-centered mb-2">
                              <div class="columns is-centered pools-card" style="margin-left: 2rem;">
                                <div class="column swap-section-top is-centered is-align-self-center">
                                  <div class="columns m-0 p-0 is-flex-direction-column">
                                    <div class="column m-0 p-0">
                                      <p class="control" style="z-index: 1">
                                        <v-super-select class="firstPairList" ref="topswap" placeholder="Select"
                                                        @input="inputChange($event)" :items="topSwapPairsList"
                                                        v-model="currentSelectionTop" inputHeight="40px"
                                                        inputWidth="185px">
                                          <option value="1">KDA</option>
                                          <option value="2">ADK</option>
                                        </v-super-select>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div v-if="$screen.width > 580"
                                 class="column is-narrow is-narrow-mobile pool-main-remove is-centered mb-1 mt-1 flipRemoveLiquidity">
                              <div class="columns is-centered pools-card">
                                <div @click="clickFlip"
                                     class="column is-2 pool-section-round is-centered is-align-self-center btn-flip"
                                     style="width: 50px; margin-top: 0px;">
                                  <b-icon
                                      pack="fas"
                                      icon="arrows-h"
                                      size="is-small" style="position: relative; top:2px; ">
                                  </b-icon>
                                </div>
                              </div>
                            </div>

                            <div class="column is-5 is-7-mobile pool-main-remove is-centered mb-2">
                              <div class="columns is-centered pools-card" style="margin-right: 2rem;">
                                <div class="column swap-section-top is-centered is-align-self-center" style="justify-content: flex-end;">
                                  <div class="columns m-0 p-0 is-flex-direction-column"
                                       style="position: relative; left: -15px;">
                                    <div class="column m-0 p-0">
                                      <p class="control">
                                        <v-super-select
                                            :class="$screen.width > 480 ? 'secondPairList' : 'firstPairList firstPairListSmall'"
                                            ref="bottomswap" placeholder="Select"
                                            @input="inputChangeBottom($event)" :items="bottomSwapPairsList"
                                            v-model="currentSelectionBottom" inputHeight="40px"
                                            inputWidth="120px">
                                          <option value="1">KDA</option>
                                          <option value="2">ADK</option>
                                        </v-super-select>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                            </div>

                          <div class="column pool-main is-centered mb-1">
                            <div class="columns is-centered pools-card">
                              <div class="column remove-balance-section is-centered is-align-self-center">
                                My Balance: {{ displayFixedLPBalance }}
                              </div>
                            </div>
                          </div>

                          <!-- REMOVE LIQUIDITY SLIDER -->
                          <div class="column pool-main is-centered mb-1">
                            <div class="columns is-centered pools-card">
                              <div class="column swap-section-top is-centered is-align-self-center">

                                <b-slider v-model="removeLiquiditySliderAmount" indicator :step="0.0000000000001"
                                          :tooltip="false" :max="Number(pairLPTokenBalance)"></b-slider>
                              </div>
                            </div>
                          </div>


                          <!-- REMOVE LIQUIDITY BUTTON -->
                          <div v-if="insufficientFunds === false" class="column pool-main is-centered mb-0 mt-0"
                               style="z-index: 1 !important;">
                            <div class="columns is-centered pools-card">
                              <div @click="clickRemoveLiquidity()"
                                   class="column pool-section-title is-centered is-align-self-center is-buttonblueswap btn-swap">
                                <!--                          <b-button expanded label="SWAP" class="is-buttonblueswap font5" style="width: 100%; border-radius: 2rem" />-->
                                <span class="font5">Remove {{ removeLiquiditySliderAmount }} Liquidity</span>
                              </div>
                            </div>
                          </div>

                          <!-- Insufficient Funds Msg -->
                          <div v-if="insufficientFunds === true" class="column pool-main is-centered mb-0 mt-0">
                            <div class="columns is-centered pools-card">
                              <div
                                  class="column insufficient-funds-section is-centered is-align-self-center is-buttonlowfunds btn-lowfunds">
                                <!--                          <b-button expanded label="SWAP" class="is-buttonblueswap font5" style="width: 100%; border-radius: 2rem" />-->
                                <span class="font5">Insufficient Funds</span>
                              </div>
                            </div>
                          </div>

                        </div>

                        <!--     SLIPPAGE SECTION -->
                        <div v-if="showSlippage === true" class="column">
                          <!-- SLIPPAGE SECTION -->
                          <div class="columns m-0"
                               style="display: flex; flex-direction: row; justify-content: center; margin-top: 5px;">
                            <!-- REMOVE LIQUIDITY SLIDER -->
                            <div class="column pool-main is-centered mb-2">
                              <div class="columns is-centered pools-card">
                                <div class="column swap-section-top is-centered is-align-self-center">

                                  <b-slider v-model="transactionDeadlineAmount" indicator :min="0" :max="60"
                                            :tooltip="false">
                                    <template v-for="val in [5, 10, 20, 30, 40, 50, 60]">
                                      <b-slider-tick :value="val" :key="val">{{ val }}</b-slider-tick>
                                    </template>
                                  </b-slider>

                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="column pool-main is-centered mb-3 mt-0">
                            <div class="columns is-centered pools-card">
                              <div
                                  class="column slippage-section-title is-centered is-align-self-center is-buttonblueswap btn-slip">
                                <!--                          <b-button expanded label="SWAP" class="is-buttonblueswap font5" style="width: 100%; border-radius: 2rem" />-->
                                <span class="font5" style="color: #5ad1c7;">TX Deadline: {{ transactionDeadlineAmount }} Minutes</span>
                              </div>
                            </div>
                          </div>

                          <!-- REMOVE LIQUIDITY SLIDER -->
                          <div class="column pool-main is-centered mb-2">
                            <div class="columns is-centered pools-card">
                              <div class="column swap-section-top is-centered is-align-self-center">

                                <b-slider v-model="slippageAmount" indicator :min="0" :max="99" :tooltip="false">
                                  <template v-for="val in [1, 5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 99]">
                                    <b-slider-tick :value="val" :key="val">{{ val }}</b-slider-tick>
                                  </template>
                                </b-slider>

                              </div>
                            </div>
                          </div>


                          <!-- Slippage label -->
                          <div class="column pool-main is-centered mb-0 mt-0">
                            <div class="columns is-centered pools-card">
                              <div
                                  class="column slippage-section-title is-centered is-align-self-center is-buttonblueswap btn-slip">
                                <!--                          <b-button expanded label="SWAP" class="is-buttonblueswap font5" style="width: 100%; border-radius: 2rem" />-->
                                <span class="font5" style="color: #5ad1c7;">Slippage {{ slippageAmount }}%</span>
                              </div>
                            </div>
                          </div>

                          <!-- Insufficient Funds Msg -->
                          <div v-if="insufficientFunds === true && insufficientLiquidity === false"
                               class="column pool-main is-centered mb-0 mt-0">
                            <div class="columns is-centered pools-card">
                              <div
                                  class="column insufficient-funds-section is-centered is-align-self-center is-buttonlowfunds btn-lowfunds">
                                <!--                          <b-button expanded label="SWAP" class="is-buttonblueswap font5" style="width: 100%; border-radius: 2rem" />-->
                                <span class="font5">Insufficient Funds</span>
                              </div>
                            </div>
                          </div>

                          <!-- Insufficient Funds Msg -->
                          <div v-if="insufficientLiquidity === true" class="column pool-main is-centered mb-0 mt-0">
                            <div class="columns is-centered pools-card">
                              <div
                                  class="column insufficient-funds-section is-centered is-align-self-center is-buttonlowfunds btn-lowfunds">
                                <!--                          <b-button expanded label="SWAP" class="is-buttonblueswap font5" style="width: 100%; border-radius: 2rem" />-->
                                <span class="font5">Insufficient Liquidity</span>
                              </div>
                            </div>
                          </div>

                        </div>


                      </div>
                    </div>


                  </section>
                  <!-- if not connected buttons-->
                  <div v-if="accountApproved === false" class="column is-offset-1 is-align-self-center stake-btn-col">
                    <div class="btn btn-lg stake-btn" style="border-radius: 1rem !important;">
                      <p>CONNECT WALLET</p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </accordion-item>
        </accordion>


      </div>
      <div class="column is-1"></div>
    </div>

  </div>
</template>

<script>
//import vue store variables to this page
import {mapGetters, mapState} from "vuex";
import Accordion from "./accordion.vue";
import AccordionItem from "./accordion-item";
import VSuperSelect from "v-super-select";


export default {
  name: "LiquidityPoolCard",
  components: {
    Accordion,
    AccordionItem,
    VSuperSelect,
  },
  props: {
    accountApproved: {
      type: Boolean,
      default: false,
    },
    pool_name: {
      type: String,
      default: "0",
    },
    pool_pair: {
      type: String,
      default: "0",
    },
    pool_id: {
      type: String,
      default: "0"
    },
    user_account: {
      type: String,
      default: "0",
    },
    pair_tokenA: {
      type: String,
      default: "0",
    },
    pair_tokenB: {
      type: String,
      default: "0",
    },
    pool_apr: {
      type: [String, Number],
      default: "0",
    },
    pool_reserveA: {
      type: [String, Number],
      default: "0",
    },
    pool_reserveB: {
      type: [String, Number],
      default: "0",
    },
    total_liquidity: {
      type: [String, Number],
      default: "0",
    },
    fees: {
      type: [String, Number],
      default: "0",
    },
    volume: {
      type: [String, Number],
      default: "0",
    },
    pair_tokenB_name: {
      type: String,
      default: "0",
    },
    pair_tokenB_namespace: {
      type: String,
      default: "0",
    },
    pair_tokenA_name: {
      type: String,
      default: "0",
    },
    pair_tokenA_namespace: {
      type: String,
      default: "0",
    },
    tokenA_ticker: {
      type: String,
      default: "0",
    },
    tokenB_ticker: {
      type: String,
      default: "0",
    },

  },
  data: function () {
    return {
      debug: false,
      flipped: false,
      showStakeControls: false,
      stakeAmount: 0.0,
      activeTab: "0",
      flippingSwapSections: false,
      showLoadingModal: true,
      currentSelectionTop: null,
      currentSelectionBottom: null,
      currentTemporarySelectionTop: null,
      currentTemporarySelectionBottom: null,
      topSwapAmount: 0.0,
      bottomSwapAmount: 0.0,
      doSwapExactOut: false,
      doSwapExactIn: true,
      currentTokenTop: "KDA",
      user_name: null,
      swapPairData: [],
      allPairsList: [
        {
          text: this.tokenA_ticker,
          value: this.pair_tokenA,
        },
        {
          text: this.tokenB_ticker,
          value: this.pair_tokenB,
        }
      ],
      bottomSwapPairsList: [
        {
          text: this.tokenA_ticker,
          value: this.pair_tokenA,
        },
        {
          text: this.tokenB_ticker,
          value: this.pair_tokenB,
        }
      ],
      topSwapPairsList: [
        {
          text: this.tokenA_ticker,
          value: this.pair_tokenA,
        },
        {
          text: this.tokenB_ticker,
          value: this.pair_tokenB,
        }
      ],
      topTokenPrecision: 12,
      bottomTokenPrecision: 12,
      currentSwapPairData: [],
      insufficientFunds: false,
      insufficientLiquidity: false,
      topSwapBalance: "0",
      bottomSwapBalance: "0",
      pairLPTokenBalance: 0,
      removeLiquiditySliderAmount: 0,
      addingLiquidity: false,
      removingLiquidity: false,
      swapping:false,
      removingLiquidityToastAmount: 0,
      addingLiquidityToastAmount: 0,
      isMakingTransaction: false,
      isMakingLPPoolCardTransaction: false,
      transactionDeadlineAmount: 10,
      slippageAmount: 5,
      showSlippage: false,
      t_totalLiquidity: this.total_liquidity,
      t_poolReserveA: this.pool_reserveA,
      t_poolReserveB: this.pool_reserveB,
    };
  },
  watch: {
    //lets watch our transactionPolling variable
    transactionPolling: async function (newValue, oldValue) {
      if (this.debug) {
        console.log("liquidity poolcard getTransactionPolling newValue: " + newValue);
        console.log("liquidity poolcard getTransactionPolling oldValue: " + oldValue);
      }

      if (newValue === true && this.isMakingLPPoolCardTransaction === true) {

        this.isMakingTransaction = true;

        let message1 = "Confirming new transaction with the blockchain";
        if (this.addingLiquidity === true) {
          message1 = "Adding " + this.addingLiquidityToastAmount + " Liquidity";
        } else if (this.removingLiquidity === true) {
          message1 = "Removing " + this.removingLiquidityToastAmount + " Liquidity";
        } else if (this.swapping === true) {
          message1 = "Swapping " + this.addingLiquidityToastAmount;
        }

        this.$buefy.toast.open({
          duration: 10000,
          message: message1,
          type: "is-info",
          pauseOnHover: true
        });

      } else if (newValue === false && this.isMakingLPPoolCardTransaction === true) {

        this.isMakingTransaction = false;
        this.isMakingLPPoolCardTransaction = false;

        let message = "Transaction Success!";
        if (this.addingLiquidity === true) {
          message = "Added " + this.addingLiquidityToastAmount + " Liquidity";
        } else if (this.removingLiquidity === true) {
          message = "Removed " + this.removingLiquidityToastAmount + " Liquidity";
        } else if (this.swapping === true) {
          message = "Swapped " + this.addingLiquidityToastAmount;
        }


        //Transaction success toast
        if (this.getTransactionFailed() === false) {

          this.$buefy.toast.open({
            duration: 10000,
            message: message,
            position: "is-top",
            type: "is-success"
          });

          this.removingLiquidity = false;
          this.addingLiquidity = false;
          this.swapping = false;
          this.topSwapAmount = 0;
          this.bottomSwapAmount = 0;


          if (this.currentSelectionTop !== undefined && this.currentSelectionTop !== null && this.currentSelectionBottom !== undefined && this.currentSelectionBottom !== null) {
            if (this.currentSelectionTop !== undefined && this.currentSelectionTop !== null && this.currentSelectionBottom !== undefined && this.currentSelectionBottom !== null) {
              const lptub_payload = {
                tokenA: this.currentSelectionTop,
                tokenB: this.currentSelectionBottom,
              };

              let lpTokenBalance = await this.$store.dispatch("accounts/getLPTokenUserBalance", lptub_payload);

              //Get user balances of both top + bottom tokens
              const tub_payloadA = {
                tokenA: this.currentSelectionTop
              };

              const tub_payloadB = {
                tokenA: this.currentSelectionBottom
              };

              let topTokenBalance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadA);
              let bottomTokenBalance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadB);

              this.topSwapBalance = topTokenBalance;
              this.bottomSwapBalance = bottomTokenBalance;

              this.pairLPTokenBalance = lpTokenBalance;

              const getlppayload = {
                poolName: this.pool_pair
              }

              let updatedPool = await this.$store.dispatch("accounts/getLiquidityPool", getlppayload);

              let reserveA = updatedPool.leg0.reserve;
              let reserveB = updatedPool.leg1.reserve;

              this.t_totalLiquidity = (Number(reserveA) + Number(reserveB)).toFixed(2);

              this.t_poolReserveA = reserveA.toFixed(2);
              this.t_poolReserveB = reserveB.toFixed(2);

              console.log("UPDATEDPOOL");
              console.log(updatedPool);

              if (updatedPool.leg0.reserve.decimal !== undefined && updatedPool.leg0.reserve.decimal !== null) {
                reserveA = updatedPool.leg0.reserve.decimal;
              }

              if (updatedPool.leg1.reserve.decimal !== undefined && updatedPool.leg1.reserve.decimal !== null) {
                reserveB = updatedPool.leg1.reserve.decimal;
              }

              //Check and make sure user has funds
              if (topTokenBalance < this.topSwapAmount || bottomTokenBalance < this.bottomSwapAmount) {
                this.insufficientFunds = true;
              } else {
                this.insufficientFunds = false;
              }

            }
          }


        } else if (this.getTransactionFailed() === true) {

          let message2 = "Transaction Failed";
          if (this.addingLiquidity === true) {
            message2 = "Transaction Failed - No Liquidity Added";
          } else if (this.addingLiquidity === true) {
            message2 = "Transaction Failed - No Liquidity Removed";
          } else if (this.swapping === true) {
            message2 = "Swap Transaction Failed";
          }

          this.$buefy.toast.open({
            duration: 10000,
            message: message2,
            position: "is-top",
            type: "is-danger"
          });

          this.removingLiquidity = false;
          this.addingLiquidity = false;
          this.swapping = false;
          this.topSwapAmount = 0;
          this.bottomSwapAmount = 0;
        }

      }

      if (newValue === false && oldValue === true) {
        if (this.debug) {
          console.log("polling change detected!");
        }
      }

      const lptub_payload = {
        tokenA: this.currentSelectionTop,
        tokenB: this.currentSelectionBottom,
        poolName: this.pool_name
      };

      let lpTokenBalance = await this.$store.dispatch("accounts/getLPTokenUserBalance", lptub_payload);

      this.pairLPTokenBalance = lpTokenBalance;

    },

    transactionRejected: function (newValue, oldValue) {
      if (this.debug) {
        console.log("getTransactionPolling newValue: " + newValue);
        console.log("getTransactionPolling oldValue: " + oldValue);
      }
      if (newValue === true) {
        this.removingLiquidity = false;
        this.addingLiquidity = false;
        this.swapping = false;
      }

    },

    activeTab: async function (newValue, oldValue) {
      if (this.debug) {
        console.log("activeTab newValue: " + newValue);
        console.log("activeTab oldValue: " + oldValue);
      }
      if (newValue === '0' || newValue === '1') {
        await this.swapAmountTopChange();
      }

    }

  },
  computed: {
    ...mapState("accounts", ["transactionPolling", "transactionRejected"]),

    displayFixedTopBalance() {
      if (this.topSwapBalance.length > 12) {
        return this.topSwapBalance.slice(0, 12) + "...";
      } else {
        return this.topSwapBalance;
      }
    },

    displayFixedBottomBalance() {
      if (this.bottomSwapBalance.length > 12) {
        return this.bottomSwapBalance.slice(0, 12) + "...";
      } else {
        return this.bottomSwapBalance;
      }
    },

    displayFixedLPBalance() {
      if (this.pairLPTokenBalance.length > 12) {
        return this.pairLPTokenBalance.slice(0, 12) + "...";
      } else {
        return this.pairLPTokenBalance;
      }
    },

  },
  methods: {
    ...mapGetters("accounts", ["getAccountExists", "getAccountConfirmed", "getTransactionFailed", "getTransactionPolling"]),

    setDoSwapOut(){
      this.doSwapExactOut = true;
      this.doSwapExactIn = false;
    },

    setDoSwapIn(){
      this.doSwapExactOut = false;
      this.doSwapExactIn = true;
    },

    coinDisplayName(coin){
      let name = coin;
      if(coin === 'coin'){
        name = 'KDA';
      }else if(coin === 'free.stake-token'){
        name = 'STEAK';
      }
      return name;
    },

    //Runs when user clicks add liquidity
    async clickAddLiquidity() {

      const txpolling = this.getTransactionPolling();

      if(this.debug){
        console.log("TXPOLLING");
        console.log(txpolling);
      }

      if(txpolling === false){

        if (this.debug) {
          console.log("Click addliquidity");
        }

        if (this.currentSelectionTop !== null && this.currentSelectionTop !== undefined) {
          //Get tokenA precision
          const tp_payload = {
            tokenA: this.currentSelectionTop,
          };
          this.topTokenPrecision = await this.$store.dispatch("accounts/getTokenPrecision", tp_payload);

          if (this.debug) {
            console.log("TOP TOKEN PRECISION");
            console.log(this.topTokenPrecision);
          }


          if (this.currentSelectionBottom !== null && this.currentSelectionBottom !== undefined) {
            //Get tokenA precision
            const tp_payload = {
              tokenA: this.currentSelectionBottom,
            };
            this.bottomTokenPrecision = await this.$store.dispatch("accounts/getTokenPrecision", tp_payload);

            if (this.debug) {
              console.log("BOTTOM TOKEN PRECISION");
              console.log(this.bottomTokenPrecision);
              console.log("CURRENT SWAP PAIR DATA");
              console.log(this.currentSwapPairData);
            }

            const liquidity_payload = {
              tokenA: this.currentSelectionTop,
              tokenB: this.currentSelectionBottom,
              amountA: this.topSwapAmount,
              amountB: this.bottomSwapAmount,
              amountAMin: this.reduceBalance(this.topSwapAmount * (1 - parseFloat("0.07")), this.topTokenPrecision),
              amountBMin: this.reduceBalance(this.bottomSwapAmount * (1 - parseFloat("0.07")), this.bottomTokenPrecision),
              poolName: this.pool_name
            };

            if (this.debug) {
              console.log("Liquidity payload");
              console.log(liquidity_payload);
            }

            if (this.currentSelectionTop !== this.currentSelectionBottom) {

              if (this.isMakingTransaction === false) {
                if (parseFloat(this.topSwapAmount) !== 0 && parseFloat(this.bottomSwapAmount) !== 0) {
                  this.addingLiquidity = true;
                  this.removingLiquidity = false;
                  this.swapping = false;
                  this.addingLiquidityToastAmount = this.topSwapAmount.toString() + " " + this.currentSelectionTop.toString() + " + " + this.bottomSwapAmount.toString() + " " + this.currentSelectionBottom.toString();
                  this.isMakingLPPoolCardTransaction = true;
                  this.$emit('showTxModal', true);
                  await this.$store.dispatch("accounts/stakeFactoryAddLiquidity", liquidity_payload);
                } else {
                  alert("Please select an amount of liquidity to add.");
                }
              } else {
                alert("Please wait for your current transaction to finish.");
              }


            } else {
              alert("Please select a token pair.");
            }


          } else {
            alert("Please select a token pair.");
          }

        } else {
          alert("Please select a token pair.");
        }

      }else {
        alert("Please wait for your current transaction to finish.")
      }



    },

    //Runs when user clicks remove liquidity
    async clickRemoveLiquidity() {

      const txpolling = this.getTransactionPolling();

      if(this.debug){
        console.log("TXPOLLING");
        console.log(txpolling);
      }

      if(txpolling === false){

        if (this.debug) {
          console.log("CLICK REMOVE LIQUIDITY");
        }

        if (this.currentSelectionTop !== null && this.currentSelectionTop !== undefined) {
          //Get tokenA precision
          const tp_payload = {
            tokenA: this.currentSelectionTop,
          };
          this.topTokenPrecision = await this.$store.dispatch("accounts/getTokenPrecision", tp_payload);

          if (this.debug) {
            console.log("TOP TOKEN PRECISION");
            console.log(this.topTokenPrecision);
          }


          if (this.currentSelectionBottom !== null && this.currentSelectionBottom !== undefined) {
            //Get tokenA precision
            const tp_payload = {
              tokenA: this.currentSelectionBottom,
            };
            this.bottomTokenPrecision = await this.$store.dispatch("accounts/getTokenPrecision", tp_payload);

            if (this.debug) {
              console.log("BOTTOM TOKEN PRECISION");
              console.log(this.bottomTokenPrecision);
              console.log("CURRENT SWAP PAIR DATA");
              console.log(this.currentSwapPairData);
            }

            const liquidity_payload = {
              tokenA: this.currentSelectionTop,
              tokenB: this.currentSelectionBottom,
              amountA: this.reduceBalance(this.removeLiquiditySliderAmount, 12),
              poolName: this.pool_name
            };

            if (this.debug) {
              console.log("Liquidity payload");
              console.log(liquidity_payload);
            }

            if (this.currentSelectionTop !== this.currentSelectionBottom) {
              if (this.isMakingTransaction === false) {
                if (parseFloat(this.removeLiquiditySliderAmount) !== 0) {
                  this.removingLiquidity = true;
                  this.addingLiquidity = false;
                  this.swapping = false;
                  this.removingLiquidityToastAmount = this.reduceBalance(this.removeLiquiditySliderAmount, 12).toString()+ " " + this.currentSelectionTop.toString()+":"+this.currentSelectionBottom.toString()+":"+this.pool_name;
                  this.isMakingLPPoolCardTransaction = true;
                  this.$emit('showTxModal', true);
                  await this.$store.dispatch("accounts/stakeFactoryRemoveLiquidity", liquidity_payload);
                } else {
                  alert("Please select an amount of liquidity to remove.");
                }
              } else {
                alert("Please wait for your current transaction to finish.");
              }


            } else {
              alert("Please select a token pair.");
            }

          } else {
            alert("Please select a token pair.");
          }

        } else {
          alert("Please select a token pair.");
        }

      }else {
        alert("Please wait for Stake Factory to confirm your current transaction.")
      }




    },

    //Runs when user clicks swap
    async clickSwap() {

      const txpolling = this.getTransactionPolling();

      if(this.debug){
        console.log("TXPOLLING");
        console.log(txpolling);
      }

      if(txpolling === false){


        if (this.debug) {
          console.log("Click swap");
        }

        if (this.currentSelectionTop !== null && this.currentSelectionTop !== undefined) {
          //Get tokenA precision
          const tp_payload = {
            tokenA: this.currentSelectionTop,
          };
          this.topTokenPrecision = await this.$store.dispatch("accounts/getTokenPrecision", tp_payload);

          if (this.debug) {
            console.log("TOP TOKEN PRECISION");
            console.log(this.topTokenPrecision);
          }


          if (this.currentSelectionBottom !== null && this.currentSelectionBottom !== undefined) {
            //Get tokenA precision
            const tp_payload = {
              tokenA: this.currentSelectionBottom,
            };
            this.bottomTokenPrecision = await this.$store.dispatch("accounts/getTokenPrecision", tp_payload);

            if (this.debug) {
              console.log("BOTTOM TOKEN PRECISION");
              console.log(this.bottomTokenPrecision);
              console.log("CURRENT SWAP PAIR DATA");
              console.log(this.currentSwapPairData);
            }

            //Swapexactin
            const swap_payload = {
              tokenA: this.currentSelectionTop,
              tokenB: this.currentSelectionBottom,
              amountA: this.topSwapAmount,
              amountBWithSlippage: this.reduceBalance(parseFloat(this.bottomSwapAmount) * (1 - (parseFloat(this.slippageAmount) * (1 / 100))), this.bottomTokenPrecision),
              ttl: (this.transactionDeadlineAmount * 60),
              poolName: this.pool_name
            };

            //Swapexactout
            const swap_exact_out_payload = {
              tokenA: this.currentSelectionTop,
              tokenB: this.currentSelectionBottom,
              amountA: this.topSwapAmount,
              amountBWithSlippage: this.reduceBalance(parseFloat(this.bottomSwapAmount) * (1 - (parseFloat(this.slippageAmount) * (1 / 100))), this.bottomTokenPrecision),
              ttl: (this.transactionDeadlineAmount * 60),
              poolName: this.pool_name
            };

            if (this.debug) {
              console.log("swap_payload");
              console.log(swap_payload);
            }

            if (this.currentSelectionTop !== this.currentSelectionBottom) {

              if (this.isMakingTransaction === false) {
                if (parseFloat(this.topSwapAmount) !== 0 && parseFloat(this.bottomSwapAmount) !== 0) {
                  this.swapping = true;
                  this.removingLiquidity = false;
                  this.addingLiquidity = false;
                  this.addingLiquidityToastAmount = this.topSwapAmount.toString() + " " + this.currentSelectionTop + " for ~"+this.bottomSwapAmount.toString()+" "+this.currentSelectionBottom.toString();
                  this.isMakingLPPoolCardTransaction = true;
                  this.$emit('showTxModal', true);
                  if(this.doSwapExactIn === true){
                    await this.$store.dispatch("accounts/stakeFactorySwap", swap_payload);
                  }else {
                    await this.$store.dispatch("accounts/stakeFactorySwapExactOut", swap_exact_out_payload);
                  }

                } else {
                  alert("Please select an amount to trade.");
                }
              } else {
                alert("Please wait for your current transaction to finish.");
              }

            } else {
              alert("Please select a token pair.");
            }

          } else {
            alert("Please select a token pair.");
          }

        } else {
          alert("Please select a token pair.");
        }

      }else {
        alert("Please wait for Stake Factory to confirm your current transaction");
      }


    },

    //Shows the slippage panel
    async clickShowSlippage() {
      this.showSlippage = true;
    },

    async clickPoolWindow(){
      let routeData = this.$router.resolve({path: "/trade/" + this.pool_pair});
      window.open(routeData.href, "_blank");
    },

    //Shows the swap panel
    async clickShowSwap() {
      this.showSlippage = false;
    },

    //Sets top swapper to users max balance
    async clickTopSwapMaxButton() {
      this.topSwapAmount = this.topSwapBalance;
      await this.swapAmountTopChange();
    },

    //Sets bottom swapper to users max balance
    async clickBottomSwapMaxButton() {
      this.bottomSwapAmount = this.bottomSwapBalance;
    },

    //Creates list of pairs from a single pool
    async generateSwapLists(){

      //Get token a name
      let t_tokenATicker = null;
      const t_tokenANamespace = this.pair_tokenA_namespace;
      const t_tokenAName = this.pair_tokenA_name;
      let t_tokenA = null;
      if (t_tokenANamespace === null) {
        t_tokenA = t_tokenAName;
      } else {
        t_tokenA = t_tokenANamespace + "." + t_tokenAName;
      }

      //Get token b name
      let t_tokenBTicker = null;
      const t_tokenBNamespace = this.pair_tokenB_namespace;
      const t_tokenBName = this.pair_tokenB_name;
      let t_tokenB = null;
      if (t_tokenBNamespace === null) {
        t_tokenB = t_tokenBName;
      } else {
        t_tokenB = t_tokenBNamespace + "." + t_tokenBName;
      }

      if (t_tokenA === "coin") {
        t_tokenATicker = "KDA";
      } else if (t_tokenA === "free.stake-token") {
        t_tokenATicker = "STAKE";
      } else if (t_tokenA === "free.anedak") {
        t_tokenATicker = "ADK";
      } else if (t_tokenA === "free.babena") {
        t_tokenATicker = "BABE";
      } else {
        t_tokenATicker = t_tokenA;
      }

      if (t_tokenB === "coin") {
        t_tokenBTicker = "KDA";
      } else if (t_tokenB === "free.stake-token") {
        t_tokenBTicker = "STAKE";
      } else if (t_tokenB === "free.anedak") {
        t_tokenBTicker = "ADK";
      } else if (t_tokenB === "free.babena") {
        t_tokenBTicker = "BABE";
      } else {
        t_tokenBTicker = t_tokenB;
      }

      //Make object for swap list
      let t_swapPairListObject_A = {};
      t_swapPairListObject_A.value = t_tokenA;
      t_swapPairListObject_A.text = t_tokenATicker;
      //Make object for swap list
      let t_swapPairListObject_B = {};
      t_swapPairListObject_B.value = t_tokenB;
      t_swapPairListObject_B.text = t_tokenBTicker;

      //Check if objects exist in list
      let hasTokenAInList = this.allPairsList.some(item => item["value"] === t_tokenA);
      let hasTokenBInList = this.allPairsList.some(item => item["value"] === t_tokenB);

      //Add to list if token isnt added already
      if (hasTokenAInList === false && t_swapPairListObject_A.value === this.tokena || hasTokenAInList === false && this.tokena === "0") {
        this.allPairsList.push(t_swapPairListObject_A);
      }

      //Add to list if token isnt added already
      if (hasTokenBInList === false && t_swapPairListObject_B.value === this.tokenb || hasTokenBInList === false && this.tokenb === "0") {
        this.allPairsList.push(t_swapPairListObject_B);
      }

      let hasTokenAInList2 = this.allPairsList.some(item => item["value"] === t_tokenA);
      let hasTokenBInList2 = this.allPairsList.some(item => item["value"] === t_tokenB);

      if (this.debug) {
        console.log("hasTokenA before: " + hasTokenAInList);
        console.log("hasTokenA after: " + hasTokenAInList2);
        console.log("hasTokenB before: " + hasTokenBInList);
        console.log("hasTokenB after: " + hasTokenBInList2);
      }

      this.topSwapPairsList = this.allPairsList;
      this.bottomSwapPairsList = this.allPairsList;

    },

    //Runs when someone clicks a flipper to rotate swap pairs
    async clickFlip() {
      this.flippingSwapSections = true;
      console.log("Flip click");
      console.log("refs");
      console.log(this.$refs);
      console.log("currentSelectionTop");
      console.log(this.currentSelectionTop);
      console.log("currentSelectionBottom");
      console.log(this.currentSelectionBottom);

      this.topSwapPairsList = Array.from(this.allPairsList);
      this.bottomSwapPairsList = Array.from(this.allPairsList);

      console.log("TOP PAIRS LIST");
      console.log(this.topSwapPairsList);

      console.log("BOTTOM PAIRS LIST");
      console.log(this.bottomSwapPairsList);


      if (this.currentSelectionTop !== undefined && this.currentSelectionTop !== null) {
        if (this.currentSelectionTop !== undefined && this.currentSelectionTop !== null) {

          this.currentTemporarySelectionTop = this.currentSelectionTop;


          if (this.currentSelectionBottom !== undefined && this.currentSelectionBottom !== null) {
            if (this.currentSelectionBottom !== undefined && this.currentSelectionBottom !== null) {

              this.currentTemporarySelectionBottom = this.currentSelectionBottom;

              this.$refs.bottomswap.selectItem(this.currentTemporarySelectionTop, false);
              this.$refs.bottomswap.selectValue(this.currentTemporarySelectionTop, false);

              console.log("SWITCHING TOP VALUE TO " + this.currentTemporarySelectionBottom);
              console.log("SWITCHING BOTTOM VALUE TO " + this.currentTemporarySelectionTop);

              this.$refs.topswap.selectItem(this.currentTemporarySelectionBottom, false);
              this.$refs.topswap.selectValue(this.currentTemporarySelectionBottom, false);

              this.topSwapAmount = this.bottomSwapAmount;


            }
          }
        }
      }


      this.flippingSwapSections = false;
      await this.swapAmountTopChange();


    },

    //Gets a pairs info
    async getSwapPairInfo(tokena, tokenb) {
      const spd_payload = {
        tokenA: tokena,
        tokenB: tokenb,
        poolName: this.pool_name
      };

      this.currentSwapPairData = await this.$store.dispatch("accounts/getSwapPairData", spd_payload);

      if(this.debug){
        console.log("SWAPPAIRDATA TEST");
        console.log(this.swapPairData);
      }

    },

    //When a new token is selected on top
    async inputChange(event) {

      if (event !== undefined) {
        this.currentSelectionTop = event;

        this.bottomSwapPairsList = Array.from(this.allPairsList);

        if (this.debug) {
          console.log("Top swap area selection change detected:");
          console.log(event);
        }

        if (this.currentSelectionTop !== undefined && this.currentSelectionTop !== null) {


          let topTokenBalance = this.topSwapAmount;
          let bottomTokenBalance = this.bottomSwapAmount;

          const tub_payloadA = {
            tokenA: this.currentSelectionTop
          };
          topTokenBalance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadA);
          this.topSwapBalance = topTokenBalance;

          //Get user balance for bottom token if possible
          if (this.currentSelectionBottom !== undefined && this.currentSelectionBottom !== null) {

            if(this.debug){
              console.log("GETTING SWAP PAIR DATA FOR");
              console.log("EVENT:");
              console.log(event);
              console.log("currentSelectionBottom");
              console.log(this.currentSelectionBottom);
            }

            this.currentSwapPairData = await this.getSwapPairInfo(event, this.currentSelectionBottom);

            const lptub_payload = {
              tokenA: this.currentSelectionTop,
              tokenB: this.currentSelectionBottom,
              poolName: this.pool_name
            };

            let lpTokenBalance = await this.$store.dispatch("accounts/getLPTokenUserBalance", lptub_payload);

            this.pairLPTokenBalance = lpTokenBalance;

            const tub_payloadB = {
              tokenA: this.currentSelectionBottom
            };

            let bottomTokenBalance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadB);

            this.bottomSwapBalance = bottomTokenBalance;
          }

          //Check and make sure user has funds
          if (topTokenBalance < this.topSwapAmount || bottomTokenBalance < this.bottomSwapAmount) {
            this.insufficientFunds = true;
          } else {
            this.insufficientFunds = false;
          }
        }


      }

    },

    //When a new token is selected on bottom
    async inputChangeBottom(event) {

      if (event !== undefined) {
        if (this.debug) {
          console.log("Bottom swap area selection change detected:");
          console.log(event);
        }

        this.currentSelectionBottom = event;

        if (this.currentSelectionBottom !== undefined && event !== undefined && event !== null) {

          this.currentSwapPairData = await this.getSwapPairInfo(this.currentSelectionBottom, event);


          let topTokenBalance = this.topSwapAmount;

          //Get user balances of both top + bottom tokens
          if (this.currentSelectionTop !== undefined && this.currentSelectionTop !== undefined && this.currentSelectionTop !== null) {
            const tub_payloadA = {
              tokenA: this.currentSelectionTop
            };
            topTokenBalance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadA);
            this.topSwapBalance = topTokenBalance;

            const lptub_payload = {
              tokenA: this.currentSelectionTop,
              tokenB: this.currentSelectionBottom,
              poolName: this.pool_name
            };

            let lpTokenBalance = await this.$store.dispatch("accounts/getLPTokenUserBalance", lptub_payload);

            this.pairLPTokenBalance = lpTokenBalance;
          }

          const tub_payloadB = {
            tokenA: this.currentSelectionBottom
          };

          let bottomTokenBalance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadB);

          this.bottomSwapBalance = bottomTokenBalance;

          //Check and make sure user has funds
          if (topTokenBalance < this.topSwapAmount || bottomTokenBalance < this.bottomSwapAmount) {
            this.insufficientFunds = true;
          } else {
            this.insufficientFunds = false;
          }
        }
      }
    },

    //Get correct price
    async getPriceImpactSlippage(payload) {
      if (this.debug) {
        console.log("Getting price impact on slippage");
      }
      const amountIn = Number(payload.amountIn);
      const amountOut = Number(payload.amountOut);
      const reserveIn = Number(payload.reserveIn);
      const reserveOut = Number(payload.reserveOut);

      if (this.debug) {
        console.log("amountIn");
        console.log(amountIn);
        console.log("amountOut");
        console.log(amountOut);
        console.log("reserveIn");
        console.log(reserveIn);
        console.log("reserveOut");
        console.log(reserveOut);
      }

      const midPrice = (reserveOut / reserveIn);

      if (this.debug) {
        console.log("midPrice");
        console.log(midPrice);
      }

      const exactQuote = amountIn * midPrice;

      if (this.debug) {
        console.log("exactQuote");
        console.log(exactQuote);
      }

      const slippage = (exactQuote - amountOut) / exactQuote;

      if (this.debug) {
        console.log("slippage");
        console.log(slippage);
      }

      return slippage;
    },

    //Gets correct compute out (swap-exat-in) price with fee
    async getComputeOut(payload) {
      if (this.debug) {
        console.log("Getting compute out");
      }
      const amountIn = Number(payload.amountIn);
      const reserveIn = Number(payload.reserveIn);
      const reserveOut = Number(payload.reserveOut);
      const fee = Number(payload.fee);

      if (this.debug) {
        console.log("amountIn");
        console.log(amountIn);
        console.log("reserveIn");
        console.log(reserveIn);
        console.log("reserveOut");
        console.log(reserveOut);
        console.log("fee");
        console.log(fee);
      }


      let numerator = Number((amountIn * (1 - fee)) * reserveOut);
      let denominator = Number(reserveIn + (amountIn * (1 - fee)));


      if(this.debug){
        console.log("numerator");
        console.log(numerator);
        console.log("denominator");
        console.log(denominator);
        console.log("numerator / denominator");
        console.log(numerator / denominator);
      }

      return numerator / denominator;

    },

    //Gets correct compute in (swap-exact-out) price with fee
    async getComputeIn(payload) {
      if (this.debug) {
        console.log("Getting compute in");
      }
      const amountOut = Number(payload.amountOut);
      const reserveIn = Number(payload.reserveIn);
      const reserveOut = Number(payload.reserveOut);
      const fee = Number(payload.fee);

      if (this.debug) {
        console.log("amountOut");
        console.log(amountOut);
        console.log("reserveIn");
        console.log(reserveIn);
        console.log("reserveOut");
        console.log(reserveOut);
        console.log("fee");
        console.log(fee);
      }


      let numerator = Number(reserveIn * amountOut);
      let denominator = Number((reserveOut-amountOut) *(1 - fee));


      if(this.debug){
        console.log("numerator");
        console.log(numerator);
        console.log("denominator");
        console.log(denominator);
        console.log("numerator / denominator");
        console.log(numerator / denominator);
      }

      return numerator / denominator;

    },

    //Runs when top input amount is changed
    async swapAmountTopChange() {

      if (this.currentSelectionTop !== undefined && this.currentSelectionTop !== null) {
        //Get tokenA precision
        const tp_payload = {
          tokenA: this.currentSelectionTop,
        };
        this.topTokenPrecision = await this.$store.dispatch("accounts/getTokenPrecision", tp_payload);

        if (this.debug) {
          console.log("TOP TOKEN PRECISION");
          console.log(this.topTokenPrecision);
        }


        if (this.currentSelectionBottom !== undefined && this.currentSelectionBottom !== null) {
          //Get tokenB precision
          const tp_payload = {
            tokenA: this.currentSelectionBottom,
          };
          this.bottomTokenPrecision = await this.$store.dispatch("accounts/getTokenPrecision", tp_payload);

          if (this.debug) {
            console.log("BOTTOM TOKEN PRECISION");
            console.log(this.bottomTokenPrecision);
            console.log("CURRENT SWAP PAIR DATA");
            console.log(this.currentSwapPairData);
          }

          //Get swap pair
          const spd_payload = {
            tokenA: this.currentSelectionTop,
            tokenB: this.currentSelectionBottom,
            poolName: this.pool_name
          };

          this.currentSwapPairData = await this.$store.dispatch("accounts/getSwapPairData", spd_payload);

          if (this.debug) {
            console.log("GOT SWAP DATA TEST!");
            console.log(this.currentSwapPairData);
          }

          const tokenANamespace = this.currentSwapPairData.leg0.token.refName.namespace;
          const tokenAName = this.currentSwapPairData.leg0.token.refName.name;

          const tokenBNamespace = this.currentSwapPairData.leg1.token.refName.namespace;
          const tokenBName = this.currentSwapPairData.leg1.token.refName.name;

          let reserveA = this.currentSwapPairData.leg0.reserve;
          let reserveB = this.currentSwapPairData.leg1.reserve;

          if (this.currentSwapPairData.leg0.reserve.decimal !== undefined && this.currentSwapPairData.leg0.reserve.decimal !== null) {
            reserveA = this.currentSwapPairData.leg0.reserve.decimal;
          }

          if (this.currentSwapPairData.leg1.reserve.decimal !== undefined && this.currentSwapPairData.leg1.reserve.decimal !== null) {
            reserveB = this.currentSwapPairData.leg1.reserve.decimal;
          }


          const splitTokenA = this.currentSelectionTop.split(".");
          const splitTokenB = this.currentSelectionBottom.split(".");

          if (this.debug) {
            console.log("tokenANamespace");
            console.log(tokenANamespace);
            console.log("tokenAName");
            console.log(tokenAName);
            console.log("tokenBNamespace");
            console.log(tokenBNamespace);
            console.log("tokenBName");
            console.log(tokenBName);
            console.log("splitTokenA");
            console.log(splitTokenA);
            console.log("splitTokenA Length");
            console.log(splitTokenA.length);
            console.log("splitTokenB");
            console.log(splitTokenB);
            console.log("splitTokenB Length");
            console.log(splitTokenB.length);
            console.log("reserveA");
            console.log(reserveA);
            console.log("reserveB");
            console.log(reserveB);
          }

          //Get quote depending on which token is on top / bottom
          const sq_payload = {
            amountA: this.reduceBalance(this.topSwapAmount, this.topTokenPrecision),
            reserveA: reserveA,
            reserveB: reserveB,
            poolName: this.pool_name
          };

          if (splitTokenA.length > 1) {
            if (tokenANamespace === splitTokenA[0] && tokenAName === splitTokenA[1]) {
              sq_payload.amountA = this.reduceBalance(this.topSwapAmount, this.topTokenPrecision);
              sq_payload.reserveA = reserveA;
              sq_payload.reserveB = reserveB;
            } else {
              sq_payload.amountA = this.reduceBalance(this.topSwapAmount, this.topTokenPrecision);
              sq_payload.reserveA = reserveA;
              sq_payload.reserveB = reserveB;
            }
          } else if (splitTokenA.length === 1) {
            if (tokenAName === splitTokenA[0]) {
              sq_payload.amountA = this.reduceBalance(this.topSwapAmount, this.topTokenPrecision);
              sq_payload.reserveA = reserveA;
              sq_payload.reserveB = reserveB;
            } else {
              sq_payload.amountA = this.reduceBalance(this.topSwapAmount, this.topTokenPrecision);
              sq_payload.reserveA = reserveB;
              sq_payload.reserveB = reserveA;
            }
          }


          let t_bottomSwapAmount = await this.$store.dispatch("accounts/getSwapQuote", sq_payload);

          if (typeof (t_bottomSwapAmount) === "object") {
            if (t_bottomSwapAmount.decimal !== undefined) {
              t_bottomSwapAmount = parseFloat(t_bottomSwapAmount.decimal);
            }
          }

          this.topSwapAmount = this.reduceBalance(this.topSwapAmount, this.topTokenPrecision);


          const co_payload = {
            amountIn: this.topSwapAmount,
            reserveIn: reserveA,
            reserveOut: reserveB,
            fee: this.fees
          };

          if (splitTokenA.length > 1) {
            if (tokenANamespace === splitTokenA[0] && tokenAName === splitTokenA[1]) {
              co_payload.reserveIn = reserveA;
              co_payload.reserveOut = reserveB;
            } else {
              co_payload.reserveIn = reserveB;
              co_payload.reserveOut = reserveA;
            }
          } else if (splitTokenA.length === 1) {
            if (tokenAName === splitTokenA[0]) {
              co_payload.reserveIn = reserveA;
              co_payload.reserveOut = reserveB;
            } else {
              co_payload.reserveIn = reserveB;
              co_payload.reserveOut = reserveA;
            }
          }

          let compute_out = await this.getComputeOut(co_payload);

          if (this.debug) {
            console.log("compute out test");
            console.log(compute_out);
          }

          if(this.activeTab ==='0'){
            //Swapping bottom amount
            this.bottomSwapAmount = this.reduceBalance(compute_out, this.bottomTokenPrecision);
          }else if(this.activeTab ==='1' && reserveA > 0 && reserveB > 0){
            //Adding liquidity bottom amount
            this.bottomSwapAmount = this.reduceBalance(t_bottomSwapAmount, this.bottomTokenPrecision);
          }


          const beforeImpact_bottomSwapAmount = this.reduceBalance(t_bottomSwapAmount, this.bottomTokenPrecision);

          const slip_payload = {
            amountIn: beforeImpact_bottomSwapAmount,
            amountOut: compute_out,
            reserveIn: reserveA,
            reserveOut: reserveB,
          };

          let priceImpact = await this.getPriceImpactSlippage(slip_payload);

          if (this.debug) {
            console.log("PRICE IMPACT RETURNED TEST");
            console.log(priceImpact);
          }

          //Get user balances of both top + bottom tokens
          const tub_payloadA = {
            tokenA: this.currentSelectionTop
          };

          const tub_payloadB = {
            tokenA: this.currentSelectionBottom
          };

          let topTokenBalance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadA);
          let bottomTokenBalance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadB);

          if(topTokenBalance.decimal !== undefined) {
            topTokenBalance = topTokenBalance.decimal;
          }

          if(topTokenBalance.decimal !== undefined) {
            bottomTokenBalance = bottomTokenBalance.decimal;
          }

          this.topSwapBalance = topTokenBalance;
          this.bottomSwapBalance = bottomTokenBalance;

          //Check and make sure user has funds
          if (topTokenBalance < this.topSwapAmount) {
            this.insufficientFunds = true;
          } else {
            this.insufficientFunds = false;
          }

          if (this.debug) {
            console.log("CHECKING MAX LIQUIDITY VS REQUESTED AMOUNT");
            console.log("reserveA");
            console.log(reserveA);
            console.log(typeof (reserveA));
            console.log("reserveB");
            console.log(reserveB);
            console.log(typeof (reserveB));
            console.log("this.topSwapAmount");
            console.log(this.topSwapAmount);
            console.log(typeof (this.topSwapAmount));
            console.log("this.bottomSwapAmount");
            console.log(this.bottomSwapAmount);

          }

          if (this.topSwapAmount > co_payload.reserveIn || this.bottomSwapAmount > co_payload.reserveOut) {
            this.insufficientLiquidity = true;
          } else {
            this.insufficientLiquidity = false;
          }


        } else {
          if (this.debug) {
            console.log("NO BOTTOM TOKEN TO GET PRECISION FOR!");
          }
        }

      } else {
        if (this.debug) {
          console.log("NO TOP TOKEN TO GET PRECISION FOR!");
        }
      }
    },

    //Runs when bottom swap input is changed
    async swapAmountBottomChange() {



      if (this.currentSelectionTop !== undefined && this.currentSelectionTop !== null) {
        //Get tokenA precision
        const tp_payload = {
          tokenA: this.currentSelectionTop,
        };
        this.topTokenPrecision = await this.$store.dispatch("accounts/getTokenPrecision", tp_payload);

        if (this.debug) {
          console.log("TOP TOKEN PRECISION");
          console.log(this.topTokenPrecision);
        }


        if (this.currentSelectionBottom !== undefined && this.currentSelectionBottom !== null) {
          //Get tokenB precision
          const tp_payload = {
            tokenA: this.currentSelectionBottom,
          };
          this.bottomTokenPrecision = await this.$store.dispatch("accounts/getTokenPrecision", tp_payload);

          if (this.debug) {
            console.log("BOTTOM TOKEN PRECISION");
            console.log(this.bottomTokenPrecision);
            console.log("CURRENT SWAP PAIR DATA");
            console.log(this.currentSwapPairData);
          }

          //Get swap pair
          const spd_payload = {
            tokenA: this.currentSelectionTop,
            tokenB: this.currentSelectionBottom,
            poolName: this.pool_name
          };

          this.currentSwapPairData = await this.$store.dispatch("accounts/getSwapPairData", spd_payload);

          if (this.debug) {
            console.log("GOT SWAP DATA TEST!");
            console.log(this.currentSwapPairData);
          }

          const tokenANamespace = this.currentSwapPairData.leg0.token.refName.namespace;
          const tokenAName = this.currentSwapPairData.leg0.token.refName.name;

          const tokenBNamespace = this.currentSwapPairData.leg1.token.refName.namespace;
          const tokenBName = this.currentSwapPairData.leg1.token.refName.name;

          let reserveA = this.currentSwapPairData.leg0.reserve;
          let reserveB = this.currentSwapPairData.leg1.reserve;

          if (this.currentSwapPairData.leg0.reserve.decimal !== undefined && this.currentSwapPairData.leg0.reserve.decimal !== null) {
            reserveA = this.currentSwapPairData.leg0.reserve.decimal;
          }

          if (this.currentSwapPairData.leg1.reserve.decimal !== undefined && this.currentSwapPairData.leg1.reserve.decimal !== null) {
            reserveB = this.currentSwapPairData.leg1.reserve.decimal;
          }


          const splitTokenA = this.currentSelectionTop.split(".");
          const splitTokenB = this.currentSelectionBottom.split(".");

          if (this.debug) {
            console.log("tokenANamespace");
            console.log(tokenANamespace);
            console.log("tokenAName");
            console.log(tokenAName);
            console.log("tokenBNamespace");
            console.log(tokenBNamespace);
            console.log("tokenBName");
            console.log(tokenBName);
            console.log("splitTokenA");
            console.log(splitTokenA);
            console.log("splitTokenA Length");
            console.log(splitTokenA.length);
            console.log("splitTokenB");
            console.log(splitTokenB);
            console.log("splitTokenB Length");
            console.log(splitTokenB.length);
            console.log("reserveA");
            console.log(reserveA);
            console.log("reserveB");
            console.log(reserveB);
          }

          //Get quote depending on which token is on top / bottom
          const sq_payload = {
            amountA: this.reduceBalance(this.bottomSwapAmount, this.bottomTokenPrecision),
            reserveA: reserveB,
            reserveB: reserveA,
            poolName: this.pool_name
          };

          if (splitTokenA.length > 1) {
            if (tokenANamespace === splitTokenA[0] && tokenAName === splitTokenA[1]) {
              sq_payload.amountA = this.reduceBalance(this.bottomSwapAmount, this.bottomTokenPrecision);
              sq_payload.reserveA = reserveB;
              sq_payload.reserveB = reserveA;
            } else {
              sq_payload.amountA = this.reduceBalance(this.bottomSwapAmount, this.bottomTokenPrecision);
              sq_payload.reserveA = reserveA;
              sq_payload.reserveB = reserveB;
            }
          } else if (splitTokenA.length === 1) {
            if (tokenAName === splitTokenA[0]) {
              sq_payload.amountA = this.reduceBalance(this.bottomSwapAmount, this.bottomTokenPrecision);
              sq_payload.reserveA = reserveB;
              sq_payload.reserveB = reserveA;
            } else {
              sq_payload.amountA = this.reduceBalance(this.bottomSwapAmount, this.bottomTokenPrecision);
              sq_payload.reserveA = reserveA;
              sq_payload.reserveB = reserveB;
            }
          }


          let t_bottomSwapAmount = await this.$store.dispatch("accounts/getSwapQuote", sq_payload);

          if (typeof (t_bottomSwapAmount) === "object") {
            if (t_bottomSwapAmount.decimal !== undefined) {
              t_bottomSwapAmount = parseFloat(t_bottomSwapAmount.decimal);
            }
          }

          this.topSwapAmount = this.reduceBalance(this.topSwapAmount, this.topTokenPrecision);


          const co_payload = {
            amountIn: this.bottomSwapAmount,
            amountOut: this.bottomSwapAmount,
            reserveIn: reserveA,
            reserveOut: reserveB,
            fee: this.fees
          };


          if (splitTokenA.length > 1) {
            if (tokenANamespace === splitTokenA[0] && tokenAName === splitTokenA[1]) {
              co_payload.reserveIn = reserveA;
              co_payload.reserveOut = reserveB;
            } else {
              co_payload.reserveIn = reserveB;
              co_payload.reserveOut = reserveA;
            }
          } else if (splitTokenA.length === 1) {
            if (tokenAName === splitTokenA[0]) {
              co_payload.reserveIn = reserveA;
              co_payload.reserveOut = reserveB;
            } else {
              co_payload.reserveIn = reserveB;
              co_payload.reserveOut = reserveA;
            }
          }

          let compute_out = await this.getComputeOut(co_payload);

          let compute_in = await this.getComputeIn(co_payload);

          if (this.debug) {
            console.log("compute out test");
            console.log(compute_out);
            console.log("compute in test");
            console.log(compute_in);
          }

          if(this.activeTab ==='0'){
            //Swapping bottom amount
            this.topSwapAmount = this.reduceBalance(compute_in, this.topTokenPrecision);
          }else if(this.activeTab ==='1' && reserveA > 0 && reserveB > 0){
            //Adding liquidity bottom amount
            this.topSwapAmount = this.reduceBalance(t_bottomSwapAmount, this.topTokenPrecision);
          }


          const beforeImpact_bottomSwapAmount = this.reduceBalance(t_bottomSwapAmount, this.bottomTokenPrecision);

          const slip_payload = {
            amountIn: beforeImpact_bottomSwapAmount,
            amountOut: compute_out,
            reserveIn: reserveA,
            reserveOut: reserveB,
          };

          let priceImpact = await this.getPriceImpactSlippage(slip_payload);

          if (this.debug) {
            console.log("PRICE IMPACT RETURNED TEST");
            console.log(priceImpact);
          }

          //Get user balances of both top + bottom tokens
          const tub_payloadA = {
            tokenA: this.currentSelectionTop
          };

          const tub_payloadB = {
            tokenA: this.currentSelectionBottom
          };

          let topTokenBalance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadA);
          let bottomTokenBalance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadB);

          if(topTokenBalance.decimal !== undefined) {
            topTokenBalance = topTokenBalance.decimal;
          }

          if(topTokenBalance.decimal !== undefined) {
            bottomTokenBalance = bottomTokenBalance.decimal;
          }

          this.topSwapBalance = topTokenBalance;
          this.bottomSwapBalance = bottomTokenBalance;

          //Check and make sure user has funds
          if (topTokenBalance < this.topSwapAmount) {
            this.insufficientFunds = true;
          } else {
            this.insufficientFunds = false;
          }

          if (this.debug) {
            console.log("CHECKING MAX LIQUIDITY VS REQUESTED AMOUNT");
            console.log("reserveA");
            console.log(reserveA);
            console.log(typeof (reserveA));
            console.log("reserveB");
            console.log(reserveB);
            console.log(typeof (reserveB));
            console.log("this.topSwapAmount");
            console.log(this.topSwapAmount);
            console.log(typeof (this.topSwapAmount));
            console.log("this.bottomSwapAmount");
            console.log(this.bottomSwapAmount);

          }

          if (this.topSwapAmount > co_payload.reserveIn || this.bottomSwapAmount > co_payload.reserveOut) {
            this.insufficientLiquidity = true;
          } else {
            this.insufficientLiquidity = false;
          }


        } else {
          if (this.debug) {
            console.log("NO BOTTOM TOKEN TO GET PRECISION FOR!");
          }
        }

      } else {
        if (this.debug) {
          console.log("NO TOP TOKEN TO GET PRECISION FOR!");
        }
      }






      // let topTokenBalance = this.topSwapAmount;
      //
      // if (this.debug) {
      //   console.log("Swap amount bottom change detected!");
      // }
      //
      // //Get user balances of both top + bottom tokens
      // if (this.currentSelectionTop !== undefined && this.currentSelectionTop !== null) {
      //   const tub_payloadA = {
      //     tokenA: this.currentSelectionTop
      //   };
      //   topTokenBalance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadA);
      //   this.topSwapBalance = topTokenBalance;
      // }
      //
      // let bottomTokenBalance = this.topSwapAmount;
      //
      // if (this.currentSelectionBottom !== undefined && this.currentSelectionBottom !== null) {
      //   const tub_payloadB = {
      //     tokenA: this.currentSelectionBottom
      //   };
      //   bottomTokenBalance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadB);
      //   this.bottomSwapBalance = bottomTokenBalance;
      // }
      //
      // //Check and make sure user has funds
      // if (topTokenBalance < this.topSwapAmount || bottomTokenBalance < this.bottomSwapAmount) {
      //   this.insufficientFunds = true;
      // } else {
      //   this.insufficientFunds = false;
      // }

    },

    //Gets a coin name from namespace + module name
    coinName(namespace, name) {
      let newnamespace = namespace + ".";
      let newname = name;
      if (namespace === null || namespace === "null") {
        newnamespace = "";
      }
      if (name === null || name === "null") {
        newname = "";
      }
      return newnamespace + newname;
    },

    //Reducer helper function
    reduceBalance(balance, prec = 6) {
      if (balance) {
        if (balance.int) balance = balance.int;
        if (balance.decimal) balance = balance.decimal;

        if (parseFloat(balance) % 1 === 0) {
          return parseInt(balance);
        }

        return Math.trunc(parseFloat(balance) * Math.pow(10, prec)) / Math.pow(10, prec);
      }

      if (balance === 0) return 0;
    },

    //Take user to pool page
    async clickPool() {
      //await this.$router.push({path: `/inspect/${this.id}`});
      //let routeData = this.$router.resolve({path: "/addliquidity"});
      let routeData = this.$router.resolve({path: "/addliquidity/" + this.pair_tokenA + "/" + this.pair_tokenB});
      window.open(routeData.href, "_blank");
    },

    //For testing
    number_test(n) {
      const result = (n - Math.floor(n)) !== 0;

      if (result) {
        if (this.debug) {
          console.log("Contains Decimal Place");
        }
        return true;
      } else {
        if (this.debug) {
          console.log("No decimal place");
        }
        return false;
      }

    },

    //Old useless function from other card
    async clickPoolControls() {
      let routeData = this.$router.resolve({path: "/pool-controls/" + this.pool_id + "/" + this.pool_contract});
      window.open(routeData.href, "_blank");
    },

    //Creates list of ALL pairs, currently unused here
    async makeSwapLists() {

      this.allLiquidityTreasuryAccountData = this.getAllTreasuryAccountsData();

      if (this.debug) {
        console.log("TEST ALL FACTORY POOLS LP");
        console.log(this.allLiquidityTreasuryAccountData);
        console.log("TEST ALL FACTORY POOLS NAME LP");
        console.log(this.allLiquidityTreasuryAccountData[0].tokenA.refName.name);
      }


      this.allPairsList = [];
      this.topSwapPairsList = [];
      this.bottomSwapPairsList = [];


      if (this.allLiquidityTreasuryAccountData.length > 0) {

        for (let j = this.allLiquidityTreasuryAccountData.length - 1; j >= 0; j--) {

          //Get token a name
          let t_tokenATicker = null;
          const t_tokenANamespace = this.allLiquidityTreasuryAccountData[j].tokenA.refName.namespace;
          const t_tokenAName = this.allLiquidityTreasuryAccountData[j].tokenA.refName.name;
          let t_tokenA = null;
          if (t_tokenANamespace === null) {
            t_tokenA = t_tokenAName;
          } else {
            t_tokenA = t_tokenANamespace + "." + t_tokenAName;
          }

          //Get token b name
          let t_tokenBTicker = null;
          const t_tokenBNamespace = this.allLiquidityTreasuryAccountData[j].tokenB.refName.namespace;
          const t_tokenBName = this.allLiquidityTreasuryAccountData[j].tokenB.refName.name;
          let t_tokenB = null;
          if (t_tokenBNamespace === null) {
            t_tokenB = t_tokenBName;
          } else {
            t_tokenB = t_tokenBNamespace + "." + t_tokenBName;
          }

          if (t_tokenA === "coin") {
            t_tokenATicker = "KDA";
          } else if (t_tokenA === "free.stake-token") {
            t_tokenATicker = "STAKE";
          } else if (t_tokenA === "free.anedak") {
            t_tokenATicker = "ADK";
          } else if (t_tokenA === "free.babena") {
            t_tokenATicker = "BABE";
          } else {
            t_tokenATicker = t_tokenA;
          }

          if (t_tokenB === "coin") {
            t_tokenBTicker = "KDA";
          } else if (t_tokenB === "free.stake-token") {
            t_tokenBTicker = "STAKE";
          } else if (t_tokenB === "free.anedak") {
            t_tokenBTicker = "ADK";
          } else if (t_tokenB === "free.babena") {
            t_tokenBTicker = "BABE";
          } else {
            t_tokenBTicker = t_tokenB;
          }

          //Make object for swap list
          let t_swapPairListObject_A = {};
          t_swapPairListObject_A.value = t_tokenA;
          t_swapPairListObject_A.text = t_tokenATicker;
          //Make object for swap list
          let t_swapPairListObject_B = {};
          t_swapPairListObject_B.value = t_tokenB;
          t_swapPairListObject_B.text = t_tokenBTicker;

          //Check if objects exist in list
          let hasTokenAInList = this.allPairsList.some(item => item["value"] === t_tokenA);
          let hasTokenBInList = this.allPairsList.some(item => item["value"] === t_tokenB);

          //Add to list if token isnt added already
          if (hasTokenAInList === false && t_swapPairListObject_A.value === this.tokena || hasTokenAInList === false && this.tokena === "0") {
            this.allPairsList.push(t_swapPairListObject_A);
          }

          //Add to list if token isnt added already
          if (hasTokenBInList === false && t_swapPairListObject_B.value === this.tokenb || hasTokenBInList === false && this.tokenb === "0") {
            this.allPairsList.push(t_swapPairListObject_B);
          }

          let hasTokenAInList2 = this.allPairsList.some(item => item["value"] === t_tokenA);
          let hasTokenBInList2 = this.allPairsList.some(item => item["value"] === t_tokenB);

          if (this.debug) {
            console.log("hasTokenA before: " + hasTokenAInList);
            console.log("hasTokenA after: " + hasTokenAInList2);
            console.log("hasTokenB before: " + hasTokenBInList);
            console.log("hasTokenB after: " + hasTokenBInList2);
          }

          this.topSwapPairsList = this.allPairsList;
          this.bottomSwapPairsList = this.allPairsList;


        }


      }

    },

  },
  async created() {

  await this.generateSwapLists();

    if(this.pair_tokenA !== '0' && this.pair_tokenB !== '0'){


      const tub_payloadA = {
        tokenA: this.pair_tokenA
      }

      const tub_payloadB = {
        tokenA: this.pair_tokenB
      }

      const lptub_payload = {
        tokenA: this.pair_tokenA,
        tokenB: this.pair_tokenB,
      }

      let topTokenBalance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadA);
      let bottomTokenBalance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadB);
      let lpTokenBalance = await this.$store.dispatch("accounts/getLPTokenUserBalance", lptub_payload);

      this.topSwapBalance = topTokenBalance;
      this.bottomSwapBalance = bottomTokenBalance;
      this.pairLPTokenBalance = lpTokenBalance;

      await this.$refs.bottomswap.selectItem(this.pair_tokenB, false);
      await this.$refs.bottomswap.selectValue(this.pair_tokenB, false);
      await this.$refs.topswap.selectItem(this.pair_tokenA, false);
      await this.$refs.topswap.selectValue(this.pair_tokenA, false);


      // setTimeout(async ()=>{
      //
      //     this.$nextTick(async () => {
      //       await this.$refs.bottomswap.selectItem(this.pair_tokenB, false);
      //       await this.$refs.bottomswap.selectValue(this.pair_tokenB, false);
      //       await this.$refs.topswap.selectItem(this.pair_tokenA, false);
      //       await this.$refs.topswap.selectValue(this.pair_tokenA, false);
      //     } )
      //
      //     // this.$nextTick(() => this.$refs.bottomswap.selectItem(this.tokenb, false))
      //     //     .then(await this.$nextTick(() => this.$refs.bottomswap.selectValue(this.tokenb, false))
      //     //         .then(await this.$nextTick(() => this.$refs.topswap.selectItem(this.tokena, false))
      //     //             .then(await this.$nextTick(() => this.$refs.topswap.selectValue(this.tokena, false)))));
      //
      //   }, 200);


    }

  }
};
</script>

<style scoped>


.leatherbg {
  background: radial-gradient(#00000078 0%, transparent 10%) 0 0, radial-gradient(#0000005c 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 250, 250, 0) 15%, transparent 20%) 8px 9px;
  background-color: #2c243275 !important;
  background-size: 16px 16px;
  border: 2px solid #11426573;
  background-blend-mode: darken;
}

/deep/ .b-tabs .tab-content {
  display:none !important;
}


/deep/ .b-slider .b-slider-tick {
  position: absolute;
  width: 3px;
  transform: translate(-50%, -50%);
  top: 50%;
  background: #3ffdf4 !important;
  border-radius: 4px;
}


/deep/ .super-select-dropdown {
  background-color: #333b4c !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #67b6ef !important;
  width: 455px !important;
}


@media screen and (max-width: 769px), print {
  /deep/ .super-select-dropdown {
    background-color: #333b4c !important;
    border: 1px solid !important;
    border-color: #2f597d !important;
    color: #67b6ef !important;
    width: 380px !important;
  }

  /deep/ .accordion__item {
    padding-left:0px !important;
    padding-right:0px !important;
  }
}

.swap-section-top {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #46a9e7;
  font-family: Source Code Pro, monospace !important;
  display: flex;
  background: #333b4c radial-gradient(circle farthest-corner at 50.3% 44.5%,
  rgba(107, 114, 129, 0.5),
  rgba(107, 114, 129, 0.99) 100.2%);
  background-blend-mode: hard-light;
}

.tradesection {
  background-color: #314b6a;
  color: rgba(52, 132, 179, 0.84);
  background: -webkit-linear-gradient(90deg, rgb(35 44 57), #333b4c);
  border: none;
  height: 70px;
  font-size: 20px;
  font-family: Source Code Pro, monospace !important;
  display: flex;
  justify-content: center;
  align-items: center;
}


.header-bg3 {
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
  height: 1px;
  padding-top: 0px;
}


/deep/.b-slider .b-slider-thumb-wrapper .b-slider-thumb {
  box-shadow: none;
  border: 1px solid #6affc8;
  border-radius: 4px;
  background: #233b58;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}

/deep/ .b-slider .b-slider-track {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  background: #3e8ed0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

/deep/ .b-slider.is-primary .b-slider-fill {
  background: #67efc3b0 !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

/deep/ .secondPairList .super-select-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
  justify-content: flex-end !important;
  display: flex;
  justify-self: flex-end;
  position: relative;
  left: 17px;
  text-align: end;
  margin-top: -3px !important;
  text-shadow: 0 1px 0 #578d84, 0 2px 0 #578d84;
  font-family: Source Code Pro,monospace !important;
  margin-left: 10px;
}

/deep/ .firstPairList .super-select-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
  left: 14px;
  margin-top: -3px !important;
  margin-left: -10px;
  text-align: left;
  text-shadow: 0 1px 0 #578d84, 0 2px 0 #578d84;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .super-select-input input {
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
  left: 14px;
  margin-top: -3px !important;
  margin-left: -10px;
  text-align: left;
  text-shadow: 0 1px 0 #578d84, 0 2px 0 #578d84;
  font-family: Source Code Pro,monospace !important;
}


/deep/ .field.has-addons .control:last-child .button, .field.has-addons .control:last-child .input, .field.has-addons .control:last-child .select select {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 10px !important;
  font-weight: 700 !important;
}


::v-deep .field.has-addons .control:last-child .button, .field.has-addons .control:last-child .input, .field.has-addons .control:last-child .select select {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 10px !important;
  font-weight: 700 !important;
}

.available{
  position: relative;
  top: -17px;
  left: 12px;
  margin-bottom: -20px;
  font-size: 12px;
  justify-content: flex-start;
  justify-self: flex-start;
  text-align: left;
  width: 176px;
  border-radius: 0.5rem;
  padding-left: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  color: #6c8fba82;
  max-width: 70px;
}


.margin-large-swap-input{
  margin-left:18px;
  margin-top: 0px;
}

.btn-flip{
  color: #fff;
  background: #333b4c;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: soft-light;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
  border: 1px solid rgba(3, 150, 255, 0.38);
  cursor: pointer;

}
.btn-flip:focus{ color: #fff; }
.btn-flip:hover{
  color: #5ad1c7;
  background: #333b4c;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: soft-light;
  box-shadow: 0 0 10px #5B86E5;
  border: 1px solid rgba(3, 150, 255, 0.38);
}
.btn-flip .icon{
  transition: all 0.3s ease 0s;
}
.btn-flip:hover .icon { transform: rotate(180deg); }
.btn-flip:before{
  content: '';
  background: linear-gradient(to right, transparent, rgba(91, 134, 229, 0.59));
  height: 100%;
  width: 20%;
  position: absolute;
  right: -20px;
  top: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.btn-flip:hover:before{ width: 100%; }



.btn-lowfunds{
  color: #fff;
  background: rgba(35, 183, 191, 0.29);
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
  border: 1px solid rgba(255, 3, 116, 0.38);
  cursor: not-allowed;
  border-radius: 2rem !important;
  padding: 0px;
  display: flex;
  height: 40px;
  justify-content: center;
  flex-direction: column;
  font-family: 'Lexend Deca', sans-serif;
}
.btn-lowfunds:focus{ color: #fff; }
.btn-lowfunds:hover{
  color: #fdb1b1;
  background: rgba(76, 30, 35, 0.63);
  box-shadow: 0 0 5px #e55b5b;
  border: 1px solid rgba(255, 3, 32, 0.38);
}
.btn-lowfunds i{
  transition: all 1s ease 0s;
}
.btn-lowfunds:before{
  content: '';
  background: linear-gradient(to right, transparent, rgba(229, 91, 107, 0.59));
  height: 100%;
  width: 20%;
  position: absolute;
  right: -80px;
  top: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.btn-lowfunds:hover:before{ width: 100%; }


.btn-swap{
  color: #fff;
  background: #333b4c;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: soft-light;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
  border: 1px solid rgba(3, 150, 255, 0.38);
  cursor: pointer;
  border-radius: 2rem !important;
  padding: 0px;
  display: flex;
  height: 40px;
  justify-content: center;
  flex-direction: column;
  font-family: 'Lexend Deca', sans-serif;
}
.btn-swap:focus{ color: #fff; }
.btn-swap:hover{
  color: #5ad1c7;
  background: #333b4c;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: soft-light;
  box-shadow: 0 0 5px #5B86E5;
  border: 1px solid rgba(3, 150, 255, 0.38);
}
.btn-swap i{
  transition: all 1s ease 0s;
}
.btn-swap:before{
  content: '';
  background: linear-gradient(to right, transparent, rgba(91, 134, 229, 0.59));
  height: 100%;
  width: 20%;
  position: absolute;
  right: -140px;
  top: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.btn-swap:hover:before{ width: 100%; }



.btn-slip{
  color: #fff;
  background: #333b4c;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: soft-light;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  border-radius: 2rem !important;
  padding: 0px;
  display: flex;
  height: 40px;
  justify-content: center;
  flex-direction: column;
}
.btn-slip:focus{ color: #fff; }
.btn-slip:hover{
  color: #5ad1c7;
  background: #333b4c;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: soft-light;
  box-shadow: 0 0 5px #5B86E5;
  border: 1px solid rgba(3, 150, 255, 0.38);
}
.btn-slip i{
  transition: all 1s ease 0s;
}
.btn-slip:before{
  content: '';
  background: linear-gradient(to right, transparent, rgba(91, 134, 229, 0.59));
  height: 100%;
  width: 20%;
  position: absolute;
  right: -80px;
  top: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.btn-slip:hover:before{ width: 100%; }


.centered-swap-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-46%, -50%);
}

/deep/ .super-select-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
  margin-top: -11px;
  font-size: 20px !important;
  text-shadow: 0 1px 0 #2b4d58, 0 2px 0 #355660 !important;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .secondPairList .super-select-dropdown {
  display: none;
  z-index: 0;
  position: absolute;
  right: -8px !important;
}

::v-deep .super-select-dropdown {
  background-color: #333b4c !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #67b6ef !important;
}



.super-select-dropdown {
  background-color: #333b4c !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #67b6ef !important;
}

/deep/ .super-select-item {
  background-color: #333b4c !important;
  border: none !important;
  outline: none !important;
  color: #67b6ef !important;
}

::v-deep .super-select-item {
  background-color: #333b4c !important;
  border: none !important;
  outline: none !important;
  color: #67b6ef !important;
}

.super-select-item {
  background-color: #333b4c !important;
  border: none !important;
  outline: none !important;
  color: #67b6ef !important;
}

/deep/ .super-select-item:hover {
  background-color: #215581 !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #5ad1c7 !important;
}

::v-deep .super-select-item:hover {
  background-color: #215581 !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #5ad1c7 !important;
}

/deep/ .super-select-item:focus {
  background-color: #215581 !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #5ad1c7 !important;
}

::v-deep .super-select-item:focus {
  background-color: #215581 !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #5ad1c7 !important;
}

/deep/ .super-select-item.active, .super-select-item:active, .super-select-item:hover {
  background-color: #215581 !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #5ad1c7 !important;
}

::v-deep .super-select-item.active, .super-select-item:active, .super-select-item:hover {
  background-color: #215581 !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #5ad1c7 !important;
}

/deep/ .super-select-item:active {
  background-color: #215581 !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #5ad1c7 !important;
}

::v-deep .super-select-item:active {
  background-color: #215581 !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #5ad1c7;
}

/deep/ .super-select-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
  margin-top: -12px;
  font-size: 20px !important;
  text-shadow: 0 1px 0 #2b4d58, 0 2px 0 #355660 !important;
}

/deep/ .super-select-input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;

}

.super-select-input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
}

::v-deep .super-select-input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
}

/deep/ .super-select-input input, .super-select-input select {
  background-color: #333b4c !important;
  border: none !important;
  color: #67b6ef !important;
}

.super-select-input input, .super-select-input select {
  background-color: #333b4c !important;
  border: none !important;
  color: #67b6ef !important;
}

::v-deep .super-select-input input, .super-select-input select {
  background-color: #333b4c !important;
  border: none !important;
  color: #67b6ef !important;
}

/deep/ .super-select-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
}

.super-select-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
}

::v-deep .super-select-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
}

.super-select-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
}

/deep/ .label {
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  position: absolute;
}

::v-deep .label {
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  position: absolute;
}


/deep/ .b-numberinput input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border-color: #2f597d !important;
  box-shadow: none !important;
  color: #5ad1c7 !important;
  border: none !important;
}

/deep/ .b-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border-color: #2f597d !important;
  box-shadow: none !important;
  color: #5ad1c7 !important;
  border: none !important;
}

/deep/ .is-buttonblue-small {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border-color: #2f597d !important;
  box-shadow: none !important;
  color: #5ad1c7 !important;
  border: none !important;
  margin-left: 20px;
}

/deep/ .is-buttonblue {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border-color: #2f597d !important;
  box-shadow: none !important;
  color: #5ad1c7 !important;
  border: none !important;
}


/deep/ .button.is-buttonblue {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  color: #4d84ab !important;
  border: none !important;
  box-shadow: none !important;
}

/deep/ .button.is-buttonblue:hover {
  /*background-color: rgba(56, 82, 135, 0.04) !important;*/
  background-color: transparent !important;
  color: #5ad1c7 !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
  border-radius: 2rem;
}



/deep/ .super-select-input input:hover {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #5ad1c7 !important;
}

/deep/ .button.is-buttonblue:focus {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  color: #4d84ab !important;
  border: none !important;
  box-shadow: none !important;

}

/deep/ .button.is-buttonblue:active {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  color: #5ad1c7 !important;
  border: none !important;
  box-shadow: none !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}


/deep/ .button.is-buttonlowfunds {
  background-color: #333b4c !important;
  border-color: #7d2f2f !important;
  border:none !important;
  color: #67b6ef !important;
}


/deep/ .button.is-buttonblueswap {
  background-color: #333b4c !important;
  border-color: #2f597d !important;
  border:none !important;
  color: #67b6ef !important;
}

/deep/ .button.is-buttonblueswap:hover {
  background-color: #2f597d !important;
  border-color: #2f597d !important;
  border:none !important;
  color: #67b6ef !important;
}

/deep/ .button.is-buttonblueswap:focus {
  background-color: #333b4c !important;
  border-color: #2f597d !important;
  border:none !important;
  color: #67b6ef !important;
}

/deep/ .button.is-buttonblueswap:active {
  background-color: #333b4c !important;
  border-color: #2f597d !important;
  border:none !important;
  color: #67b6ef !important;
}

/deep/ .super-select-container.super-select-dropdown-visible .super-select-input {
  -webkit-box-shadow:none !important;
  box-shadow: none !important;
  border-radius:0 !important;
  border: none !important;
}

::v-deep .super-select-container.super-select-dropdown-visible .super-select-input {
  -webkit-box-shadow:none !important;
  box-shadow: none !important;
  border-radius:0 !important;
  border: none !important;
}

.super-select-container.super-select-dropdown-visible .super-select-input {
  -webkit-box-shadow:none !important;
  box-shadow: none !important;
  border-radius:0 !important;
  border: none !important;
}

/deep/ .dropdown-content {
  background: -webkit-linear-gradient(90deg, rgba(55, 45, 69, 0.85), rgba(55, 45, 69, 0.85)) !important;
  border-radius: 4px !important;
  padding-bottom: 0.1rem !important;
  padding-top: 0.5rem !important;
  width: 435px !important;
  color: #5ad1c7 !important;
}

::v-deep .dropdown-content {
  background: -webkit-linear-gradient(90deg, rgba(55, 45, 69, 0.85), rgba(55, 45, 69, 0.85)) !important;
  border-radius: 4px !important;
  padding-bottom: 0.1rem !important;
  padding-top: 0.5rem !important;
  width: 435px !important;
  color: #5ad1c7 !important;
}

.dropdown-content {
  background: -webkit-linear-gradient(90deg, rgba(55, 45, 69, 0.85), rgba(55, 45, 69, 0.85)) !important;
  border-radius: 4px !important;
  padding-bottom: 0.1rem !important;
  padding-top: 0.5rem !important;
  width: 435px !important;
  color: #5ad1c7 !important;
}

::v-deep .super-select-val {
  color: #5ad1c7 !important;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: .1px;
  margin-right: 6px;
}

.title-back {
  color: #ffffff;
  font-family: Source Code Pro,monospace !important;
  border-radius: 1rem;
  background: -webkit-linear-gradient(90deg, rgba(49, 56, 64, 0.89), rgba(55, 45, 69, 0.85));
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.dropdown-item, .dropdown .dropdown-menu .has-link a:hover {
  color: #5ad1c7;
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  position: relative;
  background: -webkit-linear-gradient(90deg, rgb(51 59 76), rgba(53, 49, 58,0.84));
}

a.dropdown-item:hover, .dropdown .dropdown-menu .has-link a:hover, button.dropdown-item:hover {
  background-color: #67b6ef;
  color: #5ad1c7;
  border: 1px solid #5ad1c7;
}

a.dropdown-item.is-active, .dropdown .dropdown-menu .has-link a.is-active, button.dropdown-item.is-active {
  background-color: #67b6ef;
  color: #5ad1c7;
  border: 1px solid #5ad1c7;
}

a.dropdown-item.is-active, .dropdown .dropdown-menu .has-link a.is-active, button.dropdown-item.is-active {
  background-color: #67b6ef;
  color: #5ad1c7;
}

/* card background */
.home-card-bg {
  background: #ebeae5;
}

/deep/ .field.has-addons .control:last-child .control .button, .field.has-addons .control:last-child .control .input, .field.has-addons .control:last-child .control .select select {
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-top-left-radius: 20px !important;
  border: none;
  padding:1rem;
}


.pool-section-title {
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #46a9e7;
  font-family: Source Code Pro,monospace !important;
  background: #333b4c;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: soft-light;
}

.slippage-section-title {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  color: #46a9e7;
  font-family: Source Code Pro,monospace !important;
  background: #333b4c;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: soft-light;
}


.insufficient-funds-section{
  background-color: #333b4c;
  border: 1px solid rgba(255, 3, 3, 0.38);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #e74646;
  font-family: Source Code Pro,monospace !important;
}

.swap-section-top {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #46a9e7;
  font-family: Source Code Pro,monospace !important;
  display: flex;
  background: #333b4c radial-gradient(circle farthest-corner at 50.3% 44.5%,
  rgba(107, 114, 129, 0.5),
  rgba(107, 114, 129, 0.99) 100.2%);
  background-blend-mode: hard-light;
}


.pool-section-round {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 50%;
  color: #46a9e7;
  font-family: Source Code Pro,monospace !important;
}


/* border for item cards */
.cardborder {
  border: 2px outset rgba(51, 70, 80, 0.42);
  border-radius: 1rem;
  outline: none !important;
  color: #000000;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 1px 1px, rgba(0, 0, 0, 0.30) 1px 1px 0px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}



@media (max-width: 420px) {
  .super-select-container.super-select-dropdown-visible {
    top: 20px;
    width: 100vw;
    z-index: 6 !important;
  }
  /deep/ .super-select-dropdown, .super-select-dropdown.above {
    width: 99vw !important;
    z-index: 6 !important;
  }

  .super-select-mobile-header {
    background-color: #3abb81;
  }

  /deep/ .super-select-input input {
    font-size: 11px !important;
  }

  /deep/ .field.has-addons .control:last-child .button, .field.has-addons .control:last-child .input[data-v-3bbba6da], .field.has-addons .control:last-child .select select{
    font-size: 11px;
    margin-top: 5px;
  }


}

@media (min-width: 420px) and (max-width:576px) {

  /deep/ .margin-large-swap-input {
    margin-left: 50px;
    margin-top: 0px;
  }

}

.checkbox a {
  color: #3abb81;
}

/deep/ .field {
  /*background-color: #333b4c;*/
  background-color: transparent !important;
  border-radius: 0.5rem;
}

/deep/.field.has-addons {
  display: flex;
}

.select {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #5ad1c7;
  color: #5ad1c7;
}

/deep/ .select select[disabled], .taginput [disabled].taginput-container.is-focusable, [disabled].textarea, [disabled].input, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .taginput .taginput-container.is-focusable, .taginput fieldset[disabled] .taginput-container.is-focusable, fieldset[disabled] .textarea, fieldset[disabled] .input {
  background-color: #2a4855 !important;
  border-color: #24545f;
  box-shadow: none;
  color: #317884;
}

/deep/ .select select {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #5ad1c7;
  color: #5ad1c7;
}

/deep/ .select select option {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #5ad1c7;
  color: #5ad1c7;

}

/deep/ .b-steps .steps .step-items .step-item .step-details .step-title {
  color: #3b4f5c;
}

/deep/ .b-steps .steps .step-items .step-item.is-active .step-details .step-title {
  color: #5ad1c7;
}


/deep/ .b-steps .steps .step-items .step-item.is-active .step-marker {
  background-color: #2e484a;
  border-color: #5ad1c7;
  color: #5ad1c7;
}

/deep/ .b-steps .steps .step-items .step-item::before, .b-steps .steps .step-items .step-item::after {
  background: linear-gradient(to left, #67b6ef 50%, #67b6ef 50%);
  background-size: 200% 100%;
  background-position: right bottom;
}

/deep/ .b-steps .steps .step-items .step-item .step-marker {
  background-color: #526666;
  border-color: #3c6887;
  color: #67b6ef;
}

/deep/ .tabs.is-toggle li.is-active a {
  background-color: #314b6a;
  color: rgba(52, 132, 179, 0.84);
  background: -webkit-linear-gradient(90deg, rgb(35 44 57), #333b4c);
  border: none;
  font-size: 20px;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .tabs.is-toggle a {
  margin-bottom: 0;
  position: relative;
  background: -webkit-linear-gradient(90deg, rgb(35 44 57), #333b4c);
  color: #274a71;
  border: none;
  font-size: 11px;
  height: 50px;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .tabs.is-toggle a:active {
  margin-bottom: 0;
  position: relative;
  background: -webkit-linear-gradient(90deg, rgb(35 44 57), #333b4c);
  color: rgba(52, 132, 179, 0.84);
  border: none;
  font-size: 20px;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .tabs.is-toggle a:focus {
  margin-bottom: 0;
  position: relative;
  background: -webkit-linear-gradient(90deg, rgb(35 44 57), #333b4c);
  color: rgba(52, 132, 179, 0.84);
  border: none;
  font-size: 20px;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  margin-bottom: 0;
  position: relative;
  background: -webkit-linear-gradient(90deg, rgb(35 44 57), #333b4c);
  color: rgba(52, 132, 179, 0.84);
  border: none;
  font-size: 20px;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  margin-bottom: 0;
  position: relative;
  background: -webkit-linear-gradient(90deg, rgb(35 44 57), #333b4c);
  color: #5ad1c7;
  border: none;
  font-size: 20px;
  font-family: Source Code Pro,monospace !important;
}


.pools-card{
  padding-top:.5rem;
  color: #0d64c6;
  margin-left: 1rem;
  margin-right: 1rem;
}

/deep/ .control .plus .button .icon{
  width: 25px !important;
}

/deep/ .control .plus .button{
  width: 40px !important;
}

.remove-balance-section {
  /*border: 1px solid rgba(3, 150, 255, 0.38);*/
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #5ad1c7;
  font-family: 'Noto Sans', sans-serif;
  display: flex;
  justify-content: center;
  padding-top: 11px;
  padding-bottom: 11px;
  background: #333b4c;
  background-image: radial-gradient(circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2%);
  background-blend-mode: soft-light;
  font-size: 16px;
}

.margin-over-large {
  margin-right: 1.5%;
}

.highlight-span {
  background-color: rgba(39, 99, 74, 0.68);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  background-blend-mode: color;
  border: 1px solid rgba(177, 76, 226, 0.56) !important;
  outline: none;
}

.highlight-span span:before {
  background-color: #0a0a0a;
  padding: 7px 20px;
  display: block;
  border-radius: 50px;
  border: 4px solid #3abb81;
  background-blend-mode: color;
}

.highlight-span span:focus,
.highlight-span span:hover {
  background-color: rgba(27, 94, 71, 0.68);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 4px solid #309a6a;
  outline: none;
}

/deep/ .columns:not(:last-child) {
  margin-bottom: 0px;
}

.stake-col-lg {
}

.stake-col-sm {
  padding-left: 0px;
}

.withdraw-col-lg {
  padding-right: 0px;
}

.withdraw-col-sm {
  padding-left: 0px;
}

.withdraw-btn-lg {
  border-bottom-left-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
}

.withdraw-btn-sm {
  border-radius: 1rem;
}

.claim-btn-lg {
  border-bottom-right-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.claim-btn-sm {
  border-radius: 1rem;
}

/deep/ .minus {
  border-top-left-radius: 1rem;
}



/deep/ .button.is-buttonblue:hover {
  color: #19ac78;
}

/* card background */
.home-card-bg {
  background: #ebeae5;
}

.inset-bg {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding-bottom: 2rem;
}

.inset-bg2:active {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  width: 100%;
  height: 30px;
  position: relative;
  border-radius: 1rem;
  align-self: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 4px;
}


.col-title {
  color: #9573ba;
  font-size: 12px;
  font-family: Source Code Pro,monospace !important;
}

.pending-title {
  color: rgb(120 161 235);
  font-size: 14px;
}

.pool-control-title {
  color: rgb(120 161 235);
  font-size: 14px;
}

.pool-control-title:hover {
  color: #3abb81;
  font-size: 14px;
}

.pending-stat {
  color: #3cea36;
  font-size: 1em;
}

.pending-col {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: Source Code Pro,monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.col-shadow1 {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider {
  font-size: 12px;
}

.pool-name {
  font-size: 16px;
  color: rgb(120 161 235);
  font-family: Source Code Pro,monospace !important;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: rgb(38, 43, 56);
}

.pools-bg2 {
  background-color: transparent !important;
}

.pool-main {
  background-color: transparent !important;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border: 1px solid #554460;
  box-shadow: rgb(0 0 0 / 6%) 0px -23px 25px 0px inset, rgb(0 0 0 / 9%) 0px -36px 30px 0px inset, rgb(0 0 0 / 7%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;
}

.pool-item:hover {
  background-color: #333b4c;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border: 1px solid rgba(3, 255, 150, 0.38);
  box-shadow: rgb(0 0 0 / 11%) 0px -23px 25px 0px inset, rgb(0 0 0 / 9%) 0px -36px 30px 0px inset, rgb(0 0 0 / 7%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;
}

/*.pool-item {*/
/*  background-color: #333b4c;*/
/*  border: 1px solid rgba(3, 150, 255, 0.38);*/
/*  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;*/
/*  border-radius: 1rem;*/
/*  color: #3aacbb;*/
/*  font-family: Source Code Pro,monospace !important;*/

/*}*/

/*.pool-item:hover {*/
/*  background-color: #333b4c;*/
/*  border: 1px solid rgba(3, 255, 150, 0.38) !important;*/
/*  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;*/
/*  border-radius: 1rem;*/
/*  color: #3aacbb;*/
/*}*/


.poolselect-bg {
  background-color: #293038 !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 0px 0px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

/deep/ .tabs.is-toggle li.is-active a {
  background-color: #314b6a;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #3abb81;
  z-index: 1;
  font-family: 'Noto Sans', sans-serif;
}

/deep/ .tabs.is-toggle a {
  border-color: #575f85;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 0;
  position: relative;
  font-family: 'Noto Sans', sans-serif;
  color: #195da7;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
  color: #00b89c;
}


.pools-card {
  padding-top: .5rem;
  color: #0d64c6;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow {
  box-shadow: rgba(26, 64, 106, 0.11) 0px 0px 30px 3px;
}

/deep/ .field.has-addons .control:first-child .control .button, .field.has-addons .control:first-child .control .input, .field.has-addons .control:first-child .control .select select {
  border-radius: 0 !important;
}

/deep/ .field.has-addons:first-child .control:first-child .control:first-child .button:first-child, .field.has-addons:first-child .control:first-child .control:first-child .input:first-child, .field.has-addons:first-child .control:first-child .control:first-child .select:first-child select:first-child {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.stake-btn-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.stake-btn-2 {
  font-size: 14px !important;
  max-height: 40px;
  padding-left: 12px !important;
}

.btn {
  color: #87b6e5;
  background: rgba(3, 150, 255, 0.32);
  background-repeat: no-repeat;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}

.btn:focus,
.btn:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    margin-bottom: 30px;
  }
}

</style>
<style lang="scss" scoped>

.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: "Angkor", sans-serif;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

</style>
<style lang="scss" scoped>

//eccdff
.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro, monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}


$message-header-background-color: #e3f;

.loading-page-container {
  opacity: .95;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  z-index: 1;

}

.loading-page-container .progress {
  position: relative;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 6px solid #20182d;
  background-color: #2c2938;
  background-image: radial-gradient(circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2%);
  background-blend-mode: hard-light;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;

  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 1;
}

.loading-page-container .progress .inner {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: 50%;
  margin-left: -4px;
  margin-top: -1px;
  width: 196.5px;
  height: 196.5px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.loading-page-container .progress .inner .water {
  opacity: .55;
  position: absolute;
  z-index: 1;
  width: 200%;
  height: 200%;
  left: -50%;
  border-radius: 40%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  animation-name: spin;
  top: 55%;
  background: #32BAFA;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  box-shadow: 0 0 20px #152A4A;

}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 0.5rem;
  color: #296992;
}

.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}

.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}

// for styles that do not have ascii characters setting the height
$height: 15px;

.outer {
  margin-bottom: $height;
  width: 100%;
  height: $height;
}

.plain {

  &.outer {
    border: 1px solid limegreen;
  }

  .inner {
    width: 90%;
    background-color: limegreen;
    height: $height;
  }
}

.borderless .inner {
  width: 90%;
  background-color: limegreen;
  height: $height;
}

.punctuation.outer {
  border: 1px solid limegreen;
  line-height: 1.35;
}

@-webkit-keyframes progress-bar-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes spinning {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes pulsing-opacity {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}


.plain .inner, .borderless .inner {
  animation: progress-bar-width steps(20) 5s infinite;
}

.pipes .inner, .blocks .inner, .blocks-gaps {
  animation: progress-bar-width 5s infinite;
  overflow: hidden;
  animation-timing-function: linear;
}

.blocks-gaps .inner {
  letter-spacing: 2px;
}

.activity {
  text-align: center;
  overflow: hidden !important;
}

.spinning {
  animation: spinning steps(8, end) 2s infinite;
}

.pulsing {
  animation: pulsing-opacity steps(3) 0.5s infinite alternate;
}

</style>
