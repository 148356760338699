import Pact from "pact-lang-api";
import axios from "axios";

//Reduces numbers to precision
const reduceBalance = (balance, prec = 6) => {
    if (balance) {
        if (balance.int) balance = balance.int;
        if (balance.decimal) balance = balance.decimal;

        if (parseFloat(balance) % 1 === 0) {
            return parseInt(balance);
        }

        return Math.trunc(parseFloat(balance) * Math.pow(10, prec)) / Math.pow(10, prec);
    }

    if (balance === 0) return 0;
};

// const coinName = (namespace, name) => {
//     let newnamespace = namespace + ".";
//     let newname = name;
//     if (namespace === null || namespace === "null") {
//         newnamespace = "";
//     }
//     if (name === null || name === "null") {
//         newname = "";
//     }
//     return newnamespace + newname;
// };

//Float 2 decimal places
// function parseFloat2Decimals(value,decimalPlaces) {
//     return parseFloat(parseFloat(value).toFixed(decimalPlaces));
// }


const state = {
    //Our vue store state is located below, and in general contains all our apps state
    debug: true,
    loaded: false,
    showNavBar: false,
    doNFTS: false,
    activeAccount: null,
    accountExists: null,
    poolIdExists: false,
    poolIdExists1: false,
    poolIdExists2: false,
    gotBindings: false,
    platformDepositedTokens: 0,
    portfolioDepositedTokens: 0,
    platformYieldTokens: 0,
    portfolioYieldTokens: 0,
    platformYieldNFTs: 0,
    portfolioYieldNFTs: 0,
    platformDepositedNFTs: 0,
    portfolioDepositedNFTs: 0,
    platformYieldLPTokens: 0,
    portfolioYieldLPTokens: 0,
    portfolioDepositedLPTokens: 0,
    platformDepositedLPTokens: 0,
    senderAccountExists: null,
    currentSignRequest: null,
    accountData: null,
    receiverAccountData: null,
    accountName: null,
    accountConfirmed: false,
    accountKeys: [],
    accountPredicate: null,
    userStakeBalance: 0,
    userRequests: [],
    userNFTs: [],
    MarketNFTs:[],
    userPoolsFactoryStakeFungiv2: [],
    userPoolsFactoryVestingFungiv2: [],
    allPoolsFactoryStakeFungiv2: [],
    allCrowdFundingPools: [],
    userCrowdFundingPools: [],
    allPoolsFactoryStakeFungiv2Polyfungiv1: [],
    userPoolsFactoryStakeFungiv2Polyfungiv1: [],
    userPoolsFactoryStakeFNFFungiv2: [],
    allPoolsFactoryStakeFNFFungiv2: [],
    allPoolsFactoryStakePolyfungiv1Fungiv2: [],
    userPoolsFactoryStakePolyfungiv1Fungiv2: [],
    allPoolsFactoryStakePolyfungiv1: [],
    userPoolsFactoryStakePolyfungiv1: [],
    allPoolsFactoryStakePolyfungiv2: [],
    userPoolsFactoryStakePolyfungiv2: [],
    allPoolsFactoryStakePolyfungiv2Fungiv2: [],
    userPoolsFactoryStakePolyfungiv2Fungiv2: [],
    allPoolsFactoryStakeAswapTokensFungiv2: [],
    userPoolsFactoryStakeAswapTokensFungiv2: [],
    allPoolsFactoryStakeFactoryTokensFungiv2: [],
    userPoolsFactoryStakeFactoryTokensFungiv2: [],
    allPoolsFactoryStakeKDSwapTokensFungiv2: [],
    userPoolsFactoryStakeKDSwapTokensFungiv2: [],
    allPoolsFactoryStakeKaddexTokensFungiv2: [],
    userPoolsFactoryStakeKaddexTokensFungiv2: [],
    //Liquidity Pools
    allLiquidityPairsList: [],
    allLiquidityTokenNamesList: [],
    allLiquidityTickersList:[],
    allTreasuryAccountsData: [],
    allTreasuryAccountsList: [],
    transactionPolling: false,
    transactionRejected: false,
    requestPending: false,
    transactionConfirmed: false,
    transactionFailed: null,
    transactionHash: null,
    transactionConfirmedResult: null,
    chainId: "1",
    //network: "https://api.chainweb.com/chainweb/0.0/mainnet01/chain/1/pact",
    network: "https://api.testnet.chainweb.com/chainweb/0.0/testnet04/chain/1/pact",
    networkid: "testnet04",
    gasPrice: 0.000001,
    xwalletconnected: false,
};

const getters = {
    getCurrentSignRequest(state) {
      return state.currentSignRequest;
    },
    getActiveAccount(state) {
        return state.activeAccount;
    },
    getAccountExists(state) {
        return state.accountExists;
    },
    getShowNavBar(state) {
        return state.showNavBar;
    },
    getPoolIdExists(state) {
        return state.poolIdExists;
    },
    getPoolIdExists1(state) {
        return state.poolIdExists1;
    },
    getAccountKeys(state) {
        return state.accountKeys;
    },
    getAccountPredicate(state) {
        return state.accountPredicate;
    },
    getAccountData(state) {
        return state.accountData;
    },
    getAccountName(state) {
        if (state.debug) {
            console.log("Inside getAccountName");
        }
        return state.accountName;
    },
    getAccountConfirmed(state) {
        if (state.debug) {
            console.log("Inside store getAccountConfirmed");
            console.log("accountConfirmed:");
            console.log(state.accountConfirmed);
        }
        return state.accountConfirmed;
    },
    getStoreLoaded(state) {
        return state.loaded;
    },
    getTransactionPolling(state) {
        return state.transactionPolling;
    },
    getTransactionConfirmed(state) {
        return state.transactionConfirmed;
    },
    getTransactionFailed(state) {
        return state.transactionFailed;
    },
    getTransactionRejected(state) {
        return state.transactionRejected;
    },
    getTransactionHash(state) {
        return state.transactionHash;
    },
    getTransactionConfirmedResult(state) {
        return state.transactionConfirmedResult;
    },
    getXWalletConnected(state) {
        return state.xwalletconnected;
    },
    getRequestPending(state) {
        return state.requestPending;
    },
    getUserPoolsFactoryStakeFungiv2(state){
        return state.userPoolsFactoryStakeFungiv2;
    },
    getAllPoolsFactoryStakeFungiv2(state){
        return state.allPoolsFactoryStakeFungiv2;
    },
    getAllCrowdFundingPools(state){
        return state.allCrowdFundingPools;
    },
    getUserCrowdFundingPools(state){
        return state.userCrowdFundingPools;
    },
    getPlatformDepositedTokens(state){
        return state.platformDepositedTokens;
    },
    getPortfolioDepositedTokens(state){
        return state.portfolioDepositedTokens;
    },
    getPortfolioYieldTokens(state){
        return state.portfolioYieldTokens;
    },
    getPlatformYieldTokens(state){
        return state.platformYieldTokens;
    },
    getPlatformYieldNFTs(state){
        return state.platformYieldNFTs;
    },
    getUserPoolsFactoryStakeFungiv2Polyfungiv1(state){
        return state.userPoolsFactoryStakeFungiv2Polyfungiv1;
    },
    getUserNFTs(state){
        return state.userNFTs;
    },
    getMarketNFTs(state){
        return state.MarketNFTs;
    },
    getAllPoolsFactoryStakeFungiv2Polyfungiv1(state){
        return state.allPoolsFactoryStakeFungiv2Polyfungiv1;
    },
    getUserPoolsFactoryStakePolyfungiv1Fungiv2(state){
        return state.userPoolsFactoryStakePolyfungiv1Fungiv2;
    },
    getAllPoolsFactoryStakePolyfungiv1Fungiv2(state){
        return state.allPoolsFactoryStakePolyfungiv1Fungiv2;
    },
    getUserPoolsFactoryStakeFNFFungiv2(state){
        return state.userPoolsFactoryStakeFNFFungiv2;
    },
    getAllPoolsFactoryStakeFNFFungiv2(state){
        return state.allPoolsFactoryStakeFNFFungiv2;
    },
    getUserPoolsFactoryStakePolyfungiv2Fungiv2(state){
        return state.userPoolsFactoryStakePolyfungiv2Fungiv2;
    },
    getAllPoolsFactoryStakePolyfungiv2Fungiv2(state){
        return state.allPoolsFactoryStakePolyfungiv2Fungiv2;
    },
    getUserPoolsFactoryStakePolyfungiv1(state){
        return state.userPoolsFactoryStakePolyfungiv1;
    },
    getAllPoolsFactoryStakePolyfungiv1(state){
        return state.allPoolsFactoryStakePolyfungiv1;
    },
    getUserPoolsFactoryStakePolyfungiv2(state){
        return state.userPoolsFactoryStakePolyfungiv2;
    },
    getAllPoolsFactoryStakePolyfungiv2(state){
        return state.allPoolsFactoryStakePolyfungiv2;
    },
    getPortfolioYieldNFTs(state){
        return state.portfolioYieldNFTs;
    },
    getPlatformDepositedNFTs(state){
        return state.platformDepositedNFTs;
    },
    getPortfolioDepositedNFTs(state){
        return state.portfolioDepositedNFTs;
    },
    getUserPoolsFactoryStakeAswapTokensFungiv2(state){
        return state.userPoolsFactoryStakeAswapTokensFungiv2;
    },
    getUserPoolsFactoryVestingFungiv2(state){
        return state.userPoolsFactoryVestingFungiv2;
    },
    getAllPoolsFactoryStakeAswapTokensFungiv2(state){
        return state.allPoolsFactoryStakeAswapTokensFungiv2;
    },
    getUserPoolsFactoryStakeFactoryTokensFungiv2(state){
        return state.userPoolsFactoryStakeFactoryTokensFungiv2;
    },
    getAllPoolsFactoryStakeFactoryTokensFungiv2(state){
        return state.allPoolsFactoryStakeFactoryTokensFungiv2;
    },
    getPlatformYieldLPTokens(state){
        return state.platformYieldLPTokens;
    },
    getPortfolioYieldLPTokens(state){
        return state.portfolioYieldLPTokens;
    },
    getPortfolioDepositedLPTokens(state){
        return state.portfolioDepositedLPTokens;
    },
    getPlatformDepositedLPTokens(state){
        return state.platformDepositedLPTokens;
    },
    //Liquidity Pools
    getAllLiquidityPairsList(state){
        return state.allLiquidityPairsList;
    },
    getAllTreasuryAccountsList(state){
        return state.allTreasuryAccountsList;
    },
    getAllTreasuryAccountsData(state){
        return state.allTreasuryAccountsData;
    },
    getAllLiquidityTokenNamesList(state){
        return state.allLiquidityTokenNamesList;
    },
    getAllLiquidityTickersList(state){
        return state.allLiquidityTickersList;
    }


};

const actions = {

    //connects xwallet
    async connectXwallet() {

        if (state.debug) {
            console.log("connectXwallet");
        }

        const kadena = window.kadena;
        const networkId = state.networkid;

        if (window.kadena.isKadena === true) {
            let accountResult = await kadena.request({
                method: "kda_requestAccount",
                networkId: networkId,
                domain: window.location.hostname
            });

            if (state.debug) {
                console.log("accountResult:");
                console.log(accountResult);
            }


            if (accountResult.status === "success") {
                if (state.debug) {
                    console.log(accountResult.message);
                    console.log(accountResult.wallet.account);
                }


                await this.dispatch("accounts/getAccountVerification", accountResult.wallet.account);

                const t_keys = state.accountKeys;
                const t_pred = state.accountPredicate;
                const t_accountdata = state.accountData;

                if (state.debug) {
                    console.log("keys got from store:");
                    console.log(t_keys);
                    console.log("predicate got from store:");
                    console.log(t_pred);
                    console.log("data got from store:");
                    console.log(t_accountdata);
                }


                if (state.debug) {
                    console.log("dispatching confirm to store with account name:");
                    console.log(accountResult.wallet.account);
                }
                await this.dispatch("accounts/confirmAccountExists", accountResult.wallet.account);

                if (state.debug) {
                    console.log("setting local storage up.");
                }
                localStorage.setItem("accountName", accountResult.wallet.account);
                localStorage.setItem("isConnected", true);
                localStorage.setItem("isUsingXwallet", true);
                state.xwalletconnected = true;
                if (state.debug) {
                    console.log("isConnected:");
                    const connected = localStorage.getItem("isConnected");
                    console.log(connected);
                    console.log("accountname:");
                    const aname = localStorage.getItem("accountName");
                    console.log(aname);
                    console.log("isUsingXwallet:");
                    const xwalconnect = localStorage.getItem("isUsingXwallet");
                    console.log(xwalconnect);
                }

                if(state.debug){
                    console.log("USER LOGGED IN! AUTO-GETTING USER POOL INFORMATION!")
                }

            } else {
                console.log("Please connect X Wallet");
                localStorage.setItem("accountName", null);
                localStorage.setItem("isConnected", false);
                localStorage.setItem("isUsingXwallet", false);
                state.xwalletconnected = false;
            }
        }


        await kadena.request({method: "kda_connect", networkId: networkId});


    },

    //gets xwallet account
    async getXwalletAccount() {
        const kadena = window.kadena;
        if (state.debug) {
            console.log("getxwalletaccount");
        }


        let accountResult = await kadena.request({method: "kda_getSelectedAccount", networkId: state.networkid});

        if (state.debug) {
            console.log("accountResult:");

            console.log(accountResult);
        }

    },

    //Local call to verify if a coin account exists, used during account login to verify account existance
    async getAccountVerification({commit}, accountName) {
        if (state.debug) {
            console.log("Getting Kadena Account");
        }
        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(coin.details ${JSON.stringify(accountName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Account Found");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Account Found");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }
        commit("setAccountExists", exists);
        if (exists === true) {
            commit("setAccountData", dataResult);
            commit("setAccountKeys", dataResult[0]["data"]["guard"]["keys"]);
            commit("setAccountPredicate", dataResult[0]["data"]["guard"]["pred"]);
            localStorage.setItem("accountPredicate", dataResult[0]["data"]["guard"]["pred"]);
            localStorage.setItem("accountPublicKey", dataResult[0]["data"]["guard"]["keys"][0]);
            if (state.debug) {
                console.log(dataResult);
                console.log(dataResult[0]["data"]["guard"]["keys"][0]);
                console.log(dataResult[0]["data"]["guard"]["keys"]);
            }
        }
    },

    //Local call to verify if a coin account exists
    async getTransferVerification({commit}, accountName) {
        if (state.debug) {
            console.log("Getting Kadena Sender Account");
        }
        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(coin.details ${JSON.stringify(accountName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Recipient Account Found");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                exists = true;
                dataResult = [{...data.result}];
            } else {
                if (state.debug) {
                    console.log("Recipient Account NOT Found");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }
        commit("setSenderAccountExists", exists);
        if (exists === true) {
            commit("setReceiverAccountData", dataResult);
        }
    },

    //Local call to verify if a fungiv2 pool account exists, used during pool creation
    async getPoolIdVerificationFungiv2({commit}, poolID) {
        if (state.debug) {
            console.log("Getting Kadena Account");
        }
        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-fungiv2.get-pool-info ${JSON.stringify(poolID)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("WARNING: POOL ID ALREADY EXISTS!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
                state.poolIdExists = true;
            } else {
                if (state.debug) {
                    console.log("SUCCESS: POOL ID IS UNIQUE!");
                }
                exists = false;
                state.poolIdExists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(state.debug){
            console.log("POOL ID VERIFICATION DATA RESULT:");
            console.log(dataResult);
        }


        commit("setPoolIdExists", exists);
    },

    //Local call to verify if a vesting pool fungiv2 pool account exists, used during pool creation
    async getPoolIdVerificationVestingFungiv2({commit}, poolID) {
        if (state.debug) {
            console.log("Getting Kadena Account");
        }
        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-vesting-fungiv2.get-pool-info ${JSON.stringify(poolID)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("WARNING: POOL ID ALREADY EXISTS!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
                state.poolIdExists = true;
            } else {
                if (state.debug) {
                    console.log("SUCCESS: POOL ID IS UNIQUE!");
                }
                exists = false;
                state.poolIdExists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(state.debug){
            console.log("POOL ID VERIFICATION DATA RESULT:");
            console.log(dataResult);
        }


        commit("setPoolIdExists", exists);
    },

    //Local call to verify if a fungiv2/polyfungiv1 pool account exists, used during pool creation
    async getPoolIdVerificationFungiv2Polyfungiv1({commit}, poolID) {
        if (state.debug) {
            console.log("Getting Kadena Account");
        }
        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-fungiv2-polyfungiv1.get-pool-info ${JSON.stringify(poolID)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("WARNING: POOL ID ALREADY EXISTS!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
                state.poolIdExists = true;
            } else {
                if (state.debug) {
                    console.log("SUCCESS: POOL ID IS UNIQUE!");
                }
                exists = false;
                state.poolIdExists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(state.debug){
            console.log("POOL ID VERIFICATION DATA RESULT:");
            console.log(dataResult);
        }


        commit("setPoolIdExists", exists);
    },

    //Local call to verify if a polyfungiv1/fungiv2 pool account exists, used during pool creation
    async getPoolIdVerificationPolyfungiv1Fungiv2({commit}, poolID) {
        if (state.debug) {
            console.log("Getting Kadena Account");
        }
        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-polyfungiv1-fungiv2.get-pool-info ${JSON.stringify(poolID)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("WARNING: POOL ID ALREADY EXISTS!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
                state.poolIdExists = true;
            } else {
                if (state.debug) {
                    console.log("SUCCESS: POOL ID IS UNIQUE!");
                }
                exists = false;
                state.poolIdExists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(state.debug){
            console.log("POOL ID VERIFICATION DATA RESULT:");
            console.log(dataResult);
        }


        commit("setPoolIdExists", exists);
    },

    //Local call to verify if a polyfungv1/polyfungiv1 pool account exists, used during pool creation
    async getPoolIdVerificationPolyfungiv1({commit}, poolID) {
        if (state.debug) {
            console.log("Getting Kadena Polyfungiv1/Polyfungiv1 Account");
        }
        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-polyfungiv1.get-pool-info ${JSON.stringify(poolID)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("WARNING: POOL ID ALREADY EXISTS!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
                state.poolIdExists = true;
            } else {
                if (state.debug) {
                    console.log("SUCCESS: POOL ID IS UNIQUE!");
                }
                exists = false;
                state.poolIdExists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(state.debug){
            console.log("POOL ID VERIFICATION DATA RESULT:");
            console.log(dataResult);
        }


        commit("setPoolIdExists", exists);
    },

    //Local call to verify if a polyfungv2/polyfungiv2 pool account exists, used during pool creation
    async getPoolIdVerificationPolyfungiv2({commit}, poolID) {
        if (state.debug) {
            console.log("Getting Kadena Polyfungiv2/Polyfungiv2 Account");
        }
        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-polyfungiv2.get-pool-info ${JSON.stringify(poolID)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("WARNING: POOL ID ALREADY EXISTS!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
                state.poolIdExists = true;
            } else {
                if (state.debug) {
                    console.log("SUCCESS: POOL ID IS UNIQUE!");
                }
                exists = false;
                state.poolIdExists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(state.debug){
            console.log("POOL ID VERIFICATION DATA RESULT:");
            console.log(dataResult);
        }


        commit("setPoolIdExists", exists);
    },

    //Local call to verify if a aswaptokens/fungiv2 pool account exists, used during pool creation
    async getPoolIdVerificationAswapTokensFungiv2({commit}, poolID) {
        if (state.debug) {
            console.log("Getting Kadena Account");
        }
        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-tokens-fungiv2.get-pool-info ${JSON.stringify(poolID)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("WARNING: POOL ID ALREADY EXISTS!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
                state.poolIdExists = true;
            } else {
                if (state.debug) {
                    console.log("SUCCESS: POOL ID IS UNIQUE!");
                }
                exists = false;
                state.poolIdExists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(state.debug){
            console.log("POOL ID VERIFICATION DATA RESULT:");
            console.log(dataResult);
        }


        commit("setPoolIdExists", exists);
    },

    //Local call to verify if a factory/fungiv2 pool account exists, used during pool creation
    async getPoolIdVerificationFactoryTokensFungiv2({commit}, poolID) {
        if (state.debug) {
            console.log("Getting getPoolIdVerificationFactoryTokensFungiv2 verification");
        }
        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-factorytokens-fungiv23.get-pool-info ${JSON.stringify(poolID)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("WARNING: POOL ID ALREADY EXISTS!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
                state.poolIdExists = true;
            } else {
                if (state.debug) {
                    console.log("SUCCESS: POOL ID IS UNIQUE!");
                }
                exists = false;
                state.poolIdExists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(state.debug){
            console.log("POOL ID VERIFICATION DATA RESULT:");
            console.log(dataResult);
        }


        commit("setPoolIdExists", exists);
    },

    //Local call to verify if a token account exists by token and account name
    async getAccountVerificationToken({commit}, payload) {
        if (state.debug) {
            console.log("Getting Kadena Account Verification");
        }

        const tokenA = payload.tokenA;
        const name = payload.name;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(${tokenA}.get-balance ${JSON.stringify(name)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("WARNING: POOL ID ALREADY EXISTS!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
                state.poolIdExists = true;
            } else {
                if (state.debug) {
                    console.log("SUCCESS: POOL ID IS UNIQUE!");
                }
                exists = false;
                state.poolIdExists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(state.debug){
            console.log("POOL ID VERIFICATION DATA RESULT:");
            console.log(dataResult);
        }


        commit("setPoolIdExists1", exists);
    },

    //Local call to verify if a token account exists by token and account name
    async getAccountLPVerificationToken({commit}, payload) {
        if (state.debug) {
            console.log("Getting Kadena Account Verification");
        }

        if (state.debug) {
            console.log("GETTING USER LP TOKEN BALANCE!");
            console.log("TOKENA:");
            console.log(payload.tokenA);
            console.log("TOKENA:");
            console.log(payload.tokenB);
        }

        const tokenA = payload.tokenA;
        const tokenB = payload.tokenB;
        const name = payload.name;
        const poolName = payload.poolName;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-tokens3.get-balance (free.factory-exchange3.get-pair-key  ${tokenA} ${tokenB} ${JSON.stringify(poolName)}) ${JSON.stringify(name)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("WARNING: POOL ID ALREADY EXISTS!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
                state.poolIdExists = true;
            } else {
                if (state.debug) {
                    console.log("SUCCESS: POOL ID IS UNIQUE!");
                }
                exists = false;
                state.poolIdExists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(state.debug){
            console.log("POOL ID VERIFICATION DATA RESULT:");
            console.log(dataResult);
        }


        commit("setPoolIdExists2", exists);
    },

    //Local call to verify if a token account exists by token and account name
    async getTokenAccountVerification({commit}, payload) {
        if (state.debug) {
            console.log("Getting Kadena Account Verification");
        }

        const tokenA = payload.tokenA;
        const name = payload.name;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(${tokenA}.get-balance ${JSON.stringify(name)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("WARNING: POOL ID ALREADY EXISTS!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
                state.poolIdExists = true;
            } else {
                if (state.debug) {
                    console.log("SUCCESS: POOL ID IS UNIQUE!");
                }
                exists = false;
                state.poolIdExists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(state.debug){
            console.log("POOL ID VERIFICATION DATA RESULT:");
            console.log(dataResult);
        }


        commit("setPoolIdExists1", exists);
    },

    //Resets login, used when disconnecting/canceling the login screen
    async resetAccountExists({commit}) {
        const reset = null;
        this.accountExists = null;
        if (window.kadena && window.kadena.isKadna === true) {
            let result = await window.kadena.request({
                method: "kda_disconnect",
                networkId: state.networkid,
                domain: window.location.hostname
            });

            console.log(result);
        }

        commit("setAccountExists", reset);
        commit("setAccountConfirmed", false);
        commit("setXWalletConnected", false);
        localStorage.setItem("isUsingXwallet", false);
        localStorage.setItem("isUsingCloverwallet", false);
    },

    //Confirms login information and sets it in vue store
    async confirmAccountExists({commit}, acctname) {
        if (state.debug) {
            console.log("inside confirm account exists in store");
            console.log("attempting commit account name with acct name:");
            console.log(acctname);
        }

        commit("setAccountName", acctname);
        if (state.debug) {
            console.log("attempting commit account exists with true");
        }
        commit("setAccountExists", true);
        if (state.debug) {
            console.log("attempting commit account confirmed with true");
        }
        commit("setAccountConfirmed", true);


    },

    //Turns navbar section on
    async showNavBar({commit}, show) {
        commit("setShowNavBar", show);
    },

    //Transaction polling mechanism - polls pending transactions every 20 seconds
    async pollTransactionHash({commit}, hash) {
        if (state.debug) {
            console.log("Polling blockchain every 20 seconds for transaction confirmation:");
            console.log(hash);
        }
        let dataResult = null;
        try {

            let pollRes = await Pact.fetch.poll({
                requestKeys: [hash]
            }, state.network);

            if (state.debug) {
                console.log(pollRes[hash]);
            }

            if (pollRes[hash] === undefined) {
                console.log("Transaction is still confirming.. Checking again in 20 seconds..");
            } else {
                console.log(pollRes[hash]);
            }

            if (pollRes[hash] !== undefined) {

                if (pollRes[hash].result.status === "success") {

                    dataResult = [{...pollRes[hash].result}];
                    commit("setTransactionFailed", false);
                    commit("setTransactionConfirmedResult", dataResult);



                    const name = localStorage.getItem("accountName");

                    if (name === null || name === "") {
                        if(state.debug){
                            console.log("No user local accountName found.")
                        }
                    }else {
                        const t_payload = {
                            name: name
                        }
                        await this.dispatch("accounts/clearCurrentRewardBindings");
                        await this.dispatch("accounts/getCurrentBindings", t_payload);
                    }


                    commit("setTransactionPolling", false);

                    if (state.debug) {
                        console.log("Transaction Confirmed via blockchain:");
                        console.log(pollRes[hash].result);
                    }


                } else {
                    commit("setTransactionFailed", true);
                    commit("setTransactionConfirmedResult", dataResult);
                    commit("setTransactionPolling", false);
                    if (state.debug) {
                        console.log("Could not confirm recent transaction:");
                        console.log(pollRes[hash].result);
                    }

                }

            } else {

                setTimeout(async () => {
                    this.dispatch("accounts/pollTransactionHash", hash);
                }, 20000);

            }


        } catch (error) {
            if(state.debug){
                console.log("TX Confirmation ERROR:");

                console.log(error);
                console.log(error.json());
            }

        }

    },

    //Resets our store of any pending transaction polling action it was performing
    async clearTransactionPoll({commit}) {
        await commit("setTransactionHash", null);

        await commit("setTransactionPolling", false);

        await commit("setTransactionConfirmed", false);

        await commit("setTransactionFailed", null);

        await commit("setTransactionConfirmedResult", null);

    },

    //Commits xwallet is not connected changes to vue store variables
    async setXWalletIsNotConnected({commit}) {
        await commit("setXWalletConnected", false);

    },

    //Commits xwallet is connected changes to vue store variables
    async setXWalletIsConnected({commit}) {
        await commit("setXWalletConnected", true);

    },

    ///////////////////////
    //URI GETTERS
    ////////////////////////

    //Local call to get a polyfungiv1 URI Image
    async getURIpolyfungiv1Image(context, payload) {
        if (state.debug) {
            console.log("Getting polyfungiv1 token URI");
        }

        const tokenId = payload.tokenId;
        const moduleName = payload.moduleName;
        const moduleNamespace = payload.moduleNamespace;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(${moduleNamespace}.${moduleName}.uri ${JSON.stringify(tokenId)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got URI!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No URI Found");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("URI.data");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }


            //Is the result a string? If so read the string and check if its an image
            if(typeof(dataResult[0].data) === 'string'){

                const regex = new RegExp('^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+(?:png|jpg|jpeg|gif|svg)+$');

                const regexcheck = regex.test(dataResult[0].data);

                if(state.debug){
                    console.log("regex image check:");
                    console.log(regex.test(dataResult[0].data));
                }

                //If URI is a link to an image, this image is the NFTs image
                if(regexcheck === true){
                    return(dataResult[0].data)
                }else{

                    //If URI is not a link to an image, then its a link to an object (hopefully)
                    try{
                        let response = () => {
                            return new Promise(function(resolve) {
                                const res = axios.get(dataResult[0].data, {
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                });
                                resolve(res);
                            });
                        };
                        let responseData = await response();

                        if(state.debug){
                            console.log("URI axios response.data")
                            console.log(responseData.data);
                        }

                        return(responseData.data);
                    }catch(error){
                        console.log("Unknown URI format")
                        return("0");
                    }

                }

                //Lets check if this URI is an object
            }else if(typeof(dataResult[0].data) === 'object') {

                //Lets look through this object for an 'image' property
                if(dataResult[0].data.image !== undefined){
                    //Return the iamge property if it exists
                    return(dataResult[0].data.image);
                }else{
                    //If not return a 0 for unknown
                    return("0");
                }

            }

        }else {
            return(dataResult);
        }

    },

    //Local call to get a polyfungiv1 URI
    async getURIpolyfungiv1(context, payload) {
        if (state.debug) {
            console.log("Getting polyfungiv1 token URI");
        }

        const tokenId = payload.tokenId;
        const moduleName = payload.moduleName;
        const moduleNamespace = payload.moduleNamespace;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(${moduleNamespace}.${moduleName}.uri ${JSON.stringify(tokenId)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got URI!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No URI Found");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("URI.data");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }


            if(dataResult[0].data !== undefined){

                //Is the result a string? If so lets look up the URI
                if(typeof(dataResult[0].data) === 'string'){

                    //If URI is not a link to an image, then its a link to an object (hopefully)
                    try{
                        let response = () => {
                            return new Promise(function(resolve) {
                                const res = axios.get(dataResult[0].data, {
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                });
                                resolve(res);
                            });
                        };
                        let responseData = await response();

                        if(state.debug){
                            console.log("Returning URI axios response.data")
                            console.log(responseData.data);
                        }

                        return(responseData.data);
                    }catch(error){
                        console.log("Unknown URI format")
                        return("0");
                    }

                }else if (typeof(dataResult[0].data) === 'object'){
                    if(state.debug){
                        console.log("Returning dataResult[0].data object URI")
                        console.log(dataResult[0].data);
                    }
                    return(dataResult[0].data);
                }
            }

        }else {
            return(dataResult);
        }

    },

    //Local call to get a polyfungiv2 URI
    async getURIpolyfungiv2(context, payload) {
        if (state.debug) {
            console.log("Getting polyfungiv2 token URI");
        }

        const tokenId = payload.tokenId;
        const moduleName = payload.moduleName;
        const moduleNamespace = payload.moduleNamespace;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(${moduleNamespace}.${moduleName}.get-manifest ${JSON.stringify(tokenId)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got URI!");
                }
                if (state.debug) {
                    console.log(data.result);
                    console.log(data.result.data.data[0].datum);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No URI Found");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("URI.data");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));

                console.log("dataResult[0].data.data[0].datum.image");
                console.log(dataResult[0].data.data[0].datum);
                console.log(typeof(dataResult[0].data.data[0].datum));

            }

            let imageExists = false;
            if(dataResult[0] !== undefined){
                if(dataResult[0].data !== undefined){
                    if(dataResult[0].data.data[0] !== undefined){
                        if(dataResult[0].data.data[0].datum !== undefined){
                            if(dataResult[0].data.data[0].datum !== undefined){

                                if(typeof(dataResult[0].data.data[0].datum) === 'object'){
                                    imageExists = true;
                                    if(state.debug){
                                        console.log("Returning dataResult[0].data.data[0].datum");
                                        console.log(dataResult[0].data.data[0].datum);
                                    }
                                    return (dataResult[0].data.data[0].datum);
                                }

                            }
                        }
                    }
                }
            }
            if(imageExists === false){
                return("0");
            }
            //Is the result a string? If so read the string and check if its an image
            // if(typeof(dataResult[0].data) === 'string'){
            //
            //     let regex = new RegExp('^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+(?:png|jpg|jpeg|gif|svg)+$');
            //
            //     let regexcheck = regex.test(dataResult[0].data);
            //
            //     if(state.debug){
            //         console.log("regex image check:");
            //         console.log(regex.test(dataResult[0].data));
            //     }
            //
            //     //If URI is a link to an image, this image is the NFTs image
            //     if(regexcheck === true){
            //         return(dataResult[0].data)
            //     }else{
            //
            //         //If URI is not a link to an image, then its a link to an object (hopefully)
            //         try{
            //             let response = () => {
            //                 return new Promise(function(resolve) {
            //                     const res = axios.get(dataResult[0].data, {
            //                         headers: {
            //                             'Content-Type': 'application/json'
            //                         }
            //                     });
            //                     resolve(res);
            //                 });
            //             };
            //             let responseData = await response();
            //
            //             if(state.debug){
            //                 console.log("URI axios response.data")
            //                 console.log(responseData.data);
            //             }
            //
            //             return(responseData.data);
            //         }catch(error){
            //             console.log("Unknown URI format")
            //             return("0");
            //         }
            //
            //     }
            //
            //     //Lets check if this URI is an object
            // }else if(typeof(dataResult[0].data) === 'object') {
            //
            //     //Lets look through this object for an 'image' property
            //     if(dataResult[0].data.image !== undefined){
            //         //Return the iamge property if it exists
            //         return(dataResult[0].data.image);
            //     }else{
            //         //If not return a 0 for unknown
            //         return("0");
            //     }
            //
            // }

        }else {
            return("0");
        }

    },

    //Local call to get a polyfungiv2 URI Image
    async getURIpolyfungiv2Image(context, payload) {
        if (state.debug) {
            console.log("Getting polyfungiv2 token URI");
        }

        const tokenId = payload.tokenId;
        const moduleName = payload.moduleName;
        const moduleNamespace = payload.moduleNamespace;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(${moduleNamespace}.${moduleName}.get-manifest ${JSON.stringify(tokenId)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got URI!");
                }
                if (state.debug) {
                    console.log(data.result);
                    console.log(data.result.data.data[0].datum);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No URI Found");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("URI.data");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));

                console.log("dataResult[0].data.data[0].datum.image");
                console.log(dataResult[0].data.data[0].datum.image);
                console.log(typeof(dataResult[0].data.data[0].datum.image));

            }

            let imageExists = false;
            if(dataResult[0] !== undefined){
                if(dataResult[0].data !== undefined){
                    if(dataResult[0].data.data[0] !== undefined){
                        if(dataResult[0].data.data[0].datum !== undefined){
                            if(dataResult[0].data.data[0].datum.image !== undefined){

                                if(typeof(dataResult[0].data.data[0].datum.image) === 'string'){
                                    imageExists = true;
                                    return (dataResult[0].data.data[0].datum.image);
                                }

                            }
                        }
                    }
                }
            }
            if(imageExists === false){
                return("0");
            }
            //Is the result a string? If so read the string and check if its an image
            // if(typeof(dataResult[0].data) === 'string'){
            //
            //     let regex = new RegExp('^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+(?:png|jpg|jpeg|gif|svg)+$');
            //
            //     let regexcheck = regex.test(dataResult[0].data);
            //
            //     if(state.debug){
            //         console.log("regex image check:");
            //         console.log(regex.test(dataResult[0].data));
            //     }
            //
            //     //If URI is a link to an image, this image is the NFTs image
            //     if(regexcheck === true){
            //         return(dataResult[0].data)
            //     }else{
            //
            //         //If URI is not a link to an image, then its a link to an object (hopefully)
            //         try{
            //             let response = () => {
            //                 return new Promise(function(resolve) {
            //                     const res = axios.get(dataResult[0].data, {
            //                         headers: {
            //                             'Content-Type': 'application/json'
            //                         }
            //                     });
            //                     resolve(res);
            //                 });
            //             };
            //             let responseData = await response();
            //
            //             if(state.debug){
            //                 console.log("URI axios response.data")
            //                 console.log(responseData.data);
            //             }
            //
            //             return(responseData.data);
            //         }catch(error){
            //             console.log("Unknown URI format")
            //             return("0");
            //         }
            //
            //     }
            //
            //     //Lets check if this URI is an object
            // }else if(typeof(dataResult[0].data) === 'object') {
            //
            //     //Lets look through this object for an 'image' property
            //     if(dataResult[0].data.image !== undefined){
            //         //Return the iamge property if it exists
            //         return(dataResult[0].data.image);
            //     }else{
            //         //If not return a 0 for unknown
            //         return("0");
            //     }
            //
            // }

        }else {
            return("0");
        }

    },

    //Local call to get a tokens precision to see if it exists
    async getTokenExists(context, payload) {
        if (state.debug) {
            console.log("GETTING TOKEN EXISTS/PRECISION DATA!");
            console.log("TOKEN:");
            console.log(payload.tokenA);
        }

        const tokenA = payload.tokenA;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(${tokenA}.precision)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got token exists precision data!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Token precision data gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("PRECISION EXISTS DATA");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(true);

        }else {
            return(false);
        }

    },



    ////////////////////////////////////////////////////////////////
    ///// NFTs AND MINTING
    ////////////////////////////////////////////////////////////////

    //Gets ALL of a users NFTs from the blockchain
    async bindAllUserNFTs({commit}, accountName) {

            console.log("Getting User NFTs:");
            console.log(accountName);

        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-non-fungible9.get-user-details ${JSON.stringify(accountName)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];

                    console.log("Got User NFTs");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);



                commit("setUserNFTs", dataResult[0].data);

            } else {

                    console.log("Error bindAllUserNFTs: Could not get User NFTs");
                    console.log(data.result);

            }

        } catch (error) {
            console.log(error);
        }

    },

    //Gets a NFTs information
    async getNFT(context, payload) {
        if (state.debug) {
            console.log("GETTING NFT DETAILS:");
            console.log(payload.nftid);
        }

        const nftid = payload.nftid;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-non-fungible9.get-nft-details ${JSON.stringify(nftid)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got NFT details");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No NFT details gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("NFT DETAILS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets a NFTs information
    async getAllCollectionsDetails() {
        if (state.debug) {
            console.log("GETTING ALL COLLECTIONS DETAILS:");
        }

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-non-fungible9.get-all-collections-details)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got All Collections Details");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No All Collections Details Got 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("ALL COLLECTIONS DETAILS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets all collections
    async getAllCollections(context, payload) {
        if (state.debug) {
            console.log("GETTING COLLECTIONS DETAILS:");
            console.log(payload.collectionid)
        }

        const collectionid = payload.collectionid;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-non-fungible9.get-all-collection ${JSON.stringify(collectionid)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got Collection Details");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Collection Details Got 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("COLLECTION DETAILS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets a Collections information
    async getCollectionDetails(context, payload) {
        if (state.debug) {
            console.log("GETTING COLLECTIONS DETAILS:");
            console.log(payload.collectionid)
        }

        const collectionid = payload.collectionid;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-non-fungible9.get-collection-details ${JSON.stringify(collectionid)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got Collection Details");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Collection Details Got 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("COLLECTION DETAILS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets a users nfts for a specific collection information
    async getUserCollectionNFTsDetails(context, payload) {
        if (state.debug) {
            console.log("GETTING USER COLLECTION NFT DETAILS:");
            console.log(payload.collectionid)
        }

        const collectionid = payload.collectionid;
        const name = localStorage.getItem("accountName");

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-non-fungible9.get-all-user-nfts-in-collection ${JSON.stringify(name)} ${JSON.stringify(collectionid)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got User NFTs for a collection Details");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No User NFts for a collection  Details Got 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("USER NFT COLLECTION DETAILS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets a Collections whitelist offers
    async getCollectionWhitelistOffers(context, payload) {
        if (state.debug) {
            console.log("GETTING COLLECTION WHITELIST OFFERS:");
            console.log(payload.collectionid)
        }

        const collectionid = payload.collectionid;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-non-fungible9.get-collection-whitelist-offers ${JSON.stringify(collectionid)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got Collection Whitelist Offers");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Collection Whitelist Offers Got 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("COLLECTION WHITELIST OFFERS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets a Collections market offers
    async getCollectionMarketOffers(context, payload) {
        if (state.debug) {
            console.log("GETTING COLLECTION MARKET OFFERS:");
            console.log(payload.collectionid)
        }

        const collectionid = payload.collectionid;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-non-fungible9.get-collection-market-details ${JSON.stringify(collectionid)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got Collection Market Offers");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Collection Market Offers Got 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("COLLECTION MARKET OFFERS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets ALL of a users NFTs from the blockchain
    async bindAllMarketNFTs({commit}) {

        console.log("Getting Market NFTs:");

        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-non-fungible9.get-market-details)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];

                console.log("Got Market NFTs");
                console.log(data.result.data);
                console.log(dataResult[0].data);



                commit("setMarketNFTs", dataResult[0].data);

            } else {

                console.log("Error bindAllMarketNFTs: Could not get User NFTs");
                console.log(data.result);

            }

        } catch (error) {
            console.log(error);
        }

    },

    //Update a nfts market status
    async updateMarket({commit}, payload) {
        if (state.debug) {
            console.log("Updating marketplace");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const id = payload.id;
            const account = accountName2;
            const price = payload.price;
            const forsale = payload.forsale;
            const token = payload.token;

            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(id);
                console.log(account);
                console.log(price);
                console.log(forsale);
                console.log(token);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;

            const pactCode = `(free.factory-non-fungible9.sell ${JSON.stringify(id)} ${JSON.stringify(accountName2)} (read-decimal 'price) ${forsale} ${token})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove NFT Ownership", "Prove NFT Ownership", "free.factory-non-fungible9.ACCOUNT_GUARD", [id, accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "price": price,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-non-fungible9.sell ${JSON.stringify(id)} ${JSON.stringify(accountName2)} (read-decimal 'price) ${forsale} ${token})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove NFT Ownership", "Prove NFT Ownership", "free.factory-non-fungible9.ACCOUNT_GUARD", [id, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "price": price,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Buys a NFT off market
    async buyNFT({commit}, payload) {
        if (state.debug) {
            console.log("Buying NFT off marketplace");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const id = payload.id;
            const account = accountName2;
            const guard = accountGuard;
            const amount = payload.amount;
            const token = payload.token;
            const factoryaccount = payload.factoryaccount;

            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(id);
                console.log(account);
                console.log(guard);
                console.log(amount);
                console.log(token);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;

            const pactCode = `(free.factory-non-fungible9.buy ${JSON.stringify(id)} ${JSON.stringify(accountName2)} (read-keyset 'userguard) (read-decimal 'amount))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []),  Pact.lang.mkCap("Transfer "+token, "Transfer token to liquidity pool", token+'.'+"TRANSFER", [accountName2, factoryaccount, Number(amount)])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "amount": amount,
                    "userguard": guard
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-non-fungible9.buy ${JSON.stringify(id)} ${JSON.stringify(accountName2)} (read-keyset 'userguard) (read-decimal 'amount))`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []),  Pact.lang.mkCap("Transfer "+token, "Transfer token to liquidity pool", token+'.'+"TRANSFER", [accountName2, factoryaccount, Number(amount)])],
                        pactCode: xwalletcode,
                        envData: {
                            "amount": amount,
                            "userguard": guard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Transfer a NFT
    async transferNFT({commit}, payload) {
        if (state.debug) {
            console.log("Transfer NFT");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const id = payload.id;
            const sender = accountName2;
            const receiver = payload.receiver;
            const amount = payload.amount;

            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(id);
                console.log(sender);
                console.log(receiver);
                console.log(amount);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;

            const pactCode = `(free.factory-non-fungible9.transfer-create ${JSON.stringify(id)} ${JSON.stringify(sender)} ${JSON.stringify(receiver)} (at "guard" (coin.details ${JSON.stringify(receiver)} )) (read-decimal 'amount))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []),  Pact.lang.mkCap("Transfer NFT", "Transfer token", "free.factory-non-fungible9.TRANSFER", [id, sender, receiver, Number(amount)])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "amount": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-non-fungible9.transfer-create ${JSON.stringify(id)} ${JSON.stringify(sender)} ${JSON.stringify(receiver)} (at "guard" (coin.details ${JSON.stringify(receiver)} )) (read-decimal 'amount))`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []),  Pact.lang.mkCap("Transfer NFT", "Transfer token", "free.factory-non-fungible9.TRANSFER", [id, sender, receiver, Number(amount)])],
                        pactCode: xwalletcode,
                        envData: {
                            "amount": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Create NFT Collection
    async createNFTCollection({commit}, payload) {
        if (state.debug) {
            console.log("Creating new NFT collection");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const collectionid = payload.collectionid;
            const creator = accountName2;
            const link = payload.link;
            const signature = payload.signature;
            const token = payload.token;
            const image = payload.image;

            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(collectionid);
                console.log(creator);
                console.log(link);
                console.log(signature);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;

            const pactCode = `(free.factory-non-fungible9.create-collection ${JSON.stringify(collectionid)} ${JSON.stringify(accountName2)} ${JSON.stringify(link)} ${JSON.stringify(image)} ${JSON.stringify(signature)} ${token})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer Fee", "Transfer Pool Creation Fee", "free.stake-token.TRANSFER", [accountName2, "STAKEFACTORYTREASURY3", 0.01])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-non-fungible9.create-collection ${JSON.stringify(collectionid)} ${JSON.stringify(accountName2)} ${JSON.stringify(link)} ${JSON.stringify(image)} ${JSON.stringify(signature)} ${token})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer Fee", "Transfer Pool Creation Fee", "free.stake-token.TRANSFER", [accountName2, "STAKEFACTORYTREASURY3", 0.01])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Create NFT
    async createNFT({commit}, payload) {
        if (state.debug) {
            console.log("Creating new NFT");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const collectionid = payload.collectionid;
            const nftid = payload.nftid;
            const account = accountName2;
            const name = payload.name;
            const description = payload.description;
            const image = payload.image;
            const a1 = payload.a1;
            const a2 = payload.a2;
            const a3 = payload.a3;

            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(collectionid);
                console.log(nftid);
                console.log(account);
                console.log(name);
                console.log(description);
                console.log(image);
                console.log(a1);
                console.log(a2);
                console.log(a3);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-non-fungible9.mint ${JSON.stringify(collectionid)} ${JSON.stringify(nftid)} ${JSON.stringify(account)} (at "guard" (coin.details ${JSON.stringify(account)})) ${JSON.stringify(name)} ${JSON.stringify(description)} ${JSON.stringify(image)} ${JSON.stringify(a1)} [${a2}] [${a3}])`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-non-fungible9.CREATOR_GUARD", [collectionid])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-non-fungible9.mint ${JSON.stringify(collectionid)} ${JSON.stringify(nftid)} ${JSON.stringify(account)} (at "guard" (coin.details ${JSON.stringify(account)})) ${JSON.stringify(name)} ${JSON.stringify(description)} ${JSON.stringify(image)} ${JSON.stringify(a1)} [${a2}] [${a3}])`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-non-fungible9.CREATOR_GUARD", [collectionid])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Creates a new crowdfunding pool
    async createNFTWhitelist({commit}, payload) {
        if (state.debug) {
            console.log("Creating new NFT Whitelist");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const collectionid = payload.collectionid;
            const wl = payload.wl;
            const runtime = payload.runtime;
            const runtime2 = payload.runtime2;
            const maxbuy = payload.maxbuy;
            const price = payload.price;
            const anyonecanbuy = payload.anyonecanbuy;


            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(wl);
                console.log(maxbuy);
                console.log(collectionid);
                console.log(runtime);
            }

            console.log("whitelist info");
            console.log(wl);
            console.log(typeof(wl));

            let newwl = '';
            for(let i = 0; i < wl.length; i++){

                newwl += ` "`+wl[i]+`"`;

            }
            console.log("newwl");
            console.log(newwl);


            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;

            const pactCode = `(free.factory-non-fungible9.create-whitelist ${JSON.stringify(collectionid)} [`+newwl+`] (read-decimal 'runtime) (read-decimal 'runtime2) (read-decimal 'maxbuy) (read-decimal 'price) ${anyonecanbuy})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-non-fungible9.CREATOR_GUARD", [collectionid])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "runtime": runtime,
                    "runtime2": runtime2,
                    "maxbuy": maxbuy,
                    "price": price
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-non-fungible9.create-whitelist ${JSON.stringify(collectionid)} [`+newwl+`] (read-decimal 'runtime) (read-decimal 'runtime2) (read-decimal 'maxbuy) (read-decimal 'price) ${anyonecanbuy})`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-non-fungible9.CREATOR_GUARD", [collectionid])],
                        pactCode: xwalletcode,
                        envData: {
                            "runtime": runtime,
                            "runtime2": runtime2,
                            "maxbuy": maxbuy,
                            "price": price
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Mass Mint NFT
    async massMintNFT({commit}, payload) {
        if (state.debug) {
            console.log("Mass minting NFTS");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const nfts = payload.nfts;
            const collectionid = payload.collectionid;

            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(nfts);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-non-fungible9.mass-mint ${JSON.stringify(accountName2)} (at "guard" (coin.details ${JSON.stringify(accountName2)})) [${nfts}])`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-non-fungible9.CREATOR_GUARD", [collectionid])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-non-fungible9.mass-mint ${JSON.stringify(accountName2)} (at "guard" (coin.details ${JSON.stringify(accountName2)})) [${nfts}])`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-non-fungible9.CREATOR_GUARD", [collectionid])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Local call to check if a collection ID exists
    async getCollectionExists(context, payload) {
        if (state.debug) {
            console.log("GETTING COLLECTION EXISTS!");
            console.log("COLLECTION ID:");
            console.log(payload.collectionid);
        }

        const collectionid = payload.collectionid;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-non-fungible9.get-collection-details ${JSON.stringify(collectionid)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got collection exists");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No collection data gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("COLLECTION DATA GOTTEN");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(true);

        }else {
            return(false);
        }

    },

    //Local call to check if a signature exists
    async getSignatureExists(context, payload) {
        if (state.debug) {
            console.log("GETTING SIGNATURE EXISTS!");
            console.log("SIGNATURE:");
            console.log(payload.signature);
        }

        const signature = payload.signature;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-non-fungible9.get-signature-collection ${JSON.stringify(signature)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got signature exists");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No signature data gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("SIGNATURE DATA GOTTEN");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(true);

        }else {
            return(false);
        }

    },

    //Local call to check if a NFT ID exists
    async getNFTIDExists(context, payload) {
        if (state.debug) {
            console.log("GETTING NFTID EXISTS!");
            console.log("NFTID:");
            console.log(payload.nftid);
        }

        const nftid = payload.nftid;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-non-fungible9.get-nft-details ${JSON.stringify(nftid)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("NFT EXISTS");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("NO NFT EXISTS 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("NFT DATA GOTTEN");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(true);

        }else {
            return(false);
        }

    },

    //Local call to check if a user is whitelisted to a collection presale
    async checkIfCollectionWhitelisted(context, payload) {

            console.log("CHECKING IF WHITELISTED");
            console.log("COLLECTION ID:");
            console.log(payload.collectionid);
            console.log("USER");
            console.log(payload.userid);


        const collectionid = payload.collectionid;
        const userid = payload.userid;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-non-fungible9.check-if-whitelisted ${JSON.stringify(collectionid)} ${JSON.stringify(userid)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {

                    console.log("Got if user whitelisted!");


                    console.log(data.result);

                dataResult = [{...data.result}];
                exists = true;
            } else {

                    console.log("No collection whitelist check result gotten 🤬");

                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){


                console.log("COLLECTION WHITELIST CHECK GOTTEN");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));


            return(true);

        }else {
            return(false);
        }

    },

    //Gets a Collections information
    async getCollectionUserSupply(context, payload) {
        if (state.debug) {
            console.log("GETTING USER COLLECTION SUPPLY DETAILS:");
            console.log(payload.collectionid)
        }

        const collectionid = payload.collectionid;
        const name = localStorage.getItem("accountName");

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-non-fungible9.get-collection-user-supply ${JSON.stringify(collectionid)} ${JSON.stringify(name)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got Collection Supply");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Collection Supply Got 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("COLLECTION SUPPLY DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },


    ////////////////////////////////////////////////////////////////
    ///// DAO STUFF
    ////////////////////////////////////////////////////////////////

    //create new dao account
    async factoryCreateDAOAccount({commit}, payload) {
        if (state.debug) {
            console.log("Creating new DAO Account");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types

            const daoid = payload.daoid;
            const identifiers = payload.identifiers;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.create-account ${JSON.stringify(accountName2)} ${JSON.stringify(daoid)} ${JSON.stringify(identifiers)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.create-account ${JSON.stringify(accountName2)} ${JSON.stringify(daoid)} ${JSON.stringify(identifiers)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //edit a dao account
    async factoryEditDAOAccount({commit}, payload) {
        if (state.debug) {
            console.log("Creating new DAO Account");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const identifiers = payload.identifiers;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.edit-account ${JSON.stringify(accountName2)} ${JSON.stringify(identifiers)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.edit-account ${JSON.stringify(accountName2)} ${JSON.stringify(identifiers)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Gets an accounts info from a DAO
    async getDAOAccountInfo(context, payload) {
        if (state.debug) {
            console.log("GETTING USER INFO FROM DAO");
        }


        const accountName2 = localStorage.getItem("accountName");
        const subject_id = payload.subject_id;

        console.log("subject_id");
        console.log(subject_id);
        console.log("accountNmae2");
        console.log(accountName2)

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-dao.get-account-info ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got DAO ACCOUNT INFO!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("Got No DAO ACCOUNT INFO 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("DAO ACCOUNT INFO DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(dataResult[0].data);


        }else {
            return(0);
        }

    },

    //create new dao hive / subject
    async factoryCreateDAOHive({commit}, payload) {
        if (state.debug) {
            console.log("Creating new DAO Hive");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types

            const subject_id = payload.subject_id;
            const name = payload.name;
            const creator = accountName2;
            const icon = payload.icon;
            const description = payload.description;
            const about = payload.about
            const links = payload.links;
            const contacts = payload.contacts;
            const pool_cost = Number(payload.pool_cost);
            const subject_token = payload.subject_token;
            const creator_only = payload.creator_only;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.create-subject ${JSON.stringify(subject_id)} ${JSON.stringify(name)} ${JSON.stringify(creator)} ${JSON.stringify(icon)} ${JSON.stringify(description)} ${JSON.stringify(about)} ${JSON.stringify(links)} ${JSON.stringify(contacts)} (read-decimal 'cost) ${subject_token} ${creator_only} )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [creator])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2,
                    "cost": pool_cost
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.create-subject ${JSON.stringify(subject_id)} ${JSON.stringify(name)} ${JSON.stringify(creator)} ${JSON.stringify(icon)} ${JSON.stringify(description)} ${JSON.stringify(about)} ${JSON.stringify(links)} ${JSON.stringify(contacts)} (read-decimal 'cost) ${subject_token} ${creator_only} )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [creator])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2,
                            "cost": pool_cost
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //create new dao hive / subject
    async factoryEditDAOHive({commit}, payload) {
        if (state.debug) {
            console.log("Editing DAO Hive");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types

            const subject_id = payload.subject_id;
            const name = payload.name;
            const creator = accountName2;
            const icon = payload.icon;
            const description = payload.description;
            const about = payload.about
            const links = payload.links;
            const contacts = payload.contacts;
            const pool_cost = Number(payload.pool_cost);
            const subject_token = payload.subject_token;
            const required_verifiers = payload.required_verifiers;
            const minimum_proposal_time = payload.minimum_proposal_time;
            const creator_only = payload.creator_only;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.edit-subject ${JSON.stringify(creator)} ${JSON.stringify(subject_id)} ${JSON.stringify(name)} ${JSON.stringify(icon)} ${JSON.stringify(description)} ${JSON.stringify(about)} ${JSON.stringify(links)} ${JSON.stringify(contacts)} (read-decimal 'cost) ${subject_token} ${required_verifiers} (read-decimal 'minimum_proposal_time) ${creator_only} )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [creator]), Pact.lang.mkCap("Prove DAO Creator", "Prove DAO Creator", "free.factory-dao.CREATOR_GUARD", [subject_id, creator])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2,
                    "cost": pool_cost,
                    "minimum_proposal_time": minimum_proposal_time,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.edit-subject ${JSON.stringify(creator)} ${JSON.stringify(subject_id)} ${JSON.stringify(name)} ${JSON.stringify(icon)} ${JSON.stringify(description)} ${JSON.stringify(about)} ${JSON.stringify(links)} ${JSON.stringify(contacts)} (read-decimal 'cost) ${subject_token} ${required_verifiers} (read-decimal 'minimum_proposal_time) ${creator_only} )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [creator]), Pact.lang.mkCap("Prove DAO Creator", "Prove DAO Creator", "free.factory-dao.CREATOR_GUARD", [subject_id, creator])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2,
                            "cost": pool_cost,
                            "minimum_proposal_time": minimum_proposal_time,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //deactivates a task (moderator)
    async factoryDeactivateTask({commit}, payload) {
        if (state.debug) {
            console.log("Deactivating a task");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            const subject_id = payload.subject_id;
            const task_id = payload.task_id;
            const reason = payload.reason;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.deactivate-task ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(task_id)} ${JSON.stringify(reason)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Moderator", "Prove DAO Moderator", "free.factory-dao.MODERATOR_GUARD", [subject_id, accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.deactivate-task ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(task_id)} ${JSON.stringify(reason)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Moderator", "Prove DAO Moderator", "free.factory-dao.MODERATOR_GUARD", [subject_id, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //create new vote poll
    async factoryCreateVotePoll({commit}, payload) {
        if (state.debug) {
            console.log("Creating new Vote Poll");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types

            const subject_id = payload.subject_id;
            const name = payload.name;
            const creator = accountName2;
            const description = payload.description;
            const run_time = Number(payload.run_time);
            const vote_token = payload.vote_token;
            const required_votes = payload.required_votes;

            const tokenA = payload.tokenA;
            const pairAccount = payload.pairAccount;
            const amountA = payload.amountA;




            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.create-pool ${JSON.stringify(subject_id)} ${JSON.stringify(name)} ${JSON.stringify(description)} ${vote_token} ${JSON.stringify(creator)} (read-decimal 'runtime) (read-decimal 'requiredvotes))`;
            let signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [creator])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2,
                    "runtime": run_time,
                    "requiredvotes": required_votes
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            if(Number(amountA) > 0){
                signCmd.caps.push(Pact.lang.mkCap("Transfer "+tokenA, "Transfer token to liquidity pool", tokenA+'.'+"TRANSFER", [accountName2, pairAccount, Number(amountA)]));
            }



            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.create-pool ${JSON.stringify(subject_id)} ${JSON.stringify(name)} ${JSON.stringify(description)} ${vote_token} ${JSON.stringify(creator)} (read-decimal 'runtime) (read-decimal 'requiredvotes))`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [creator])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2,
                            "runtime": run_time,
                            "requiredvotes": required_votes
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                if(Number(amountA) > 0){
                    XWalletRequest.signingCmd.caps.push(Pact.lang.mkCap("Transfer "+tokenA, "Transfer token to liquidity pool", tokenA+'.'+"TRANSFER", [accountName2, pairAccount, Number(amountA)]));
                }

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //create new task
    async factoryCreateTask({commit}, payload) {
        if (state.debug) {
            console.log("Creating new Task");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types

            const subject_id = payload.subject_id;
            const name = payload.name;
            const account_id = accountName2;
            const description = payload.description;
            const run_time = Number(payload.run_time);
            const pay_token = payload.pay_token;
            const pay_account = payload.pay_account;
            const pay_amount = payload.pay_amount;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.create-task ${JSON.stringify(account_id)} ${JSON.stringify(subject_id)} ${JSON.stringify(name)} ${JSON.stringify(description)} ${JSON.stringify(pay_account)} (read-decimal 'payamount) ${pay_token} (read-decimal 'runtime))`;
            let signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [account_id]), Pact.lang.mkCap("Prove DAO Treasurer Role", "Prove DAO Treasurer Role", "free.factory-dao.TREASURY_GUARD", [subject_id, account_id])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2,
                    "runtime": run_time,
                    "payamount": pay_amount
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx



            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.create-task ${JSON.stringify(account_id)} ${JSON.stringify(subject_id)} ${JSON.stringify(name)} ${JSON.stringify(description)} ${JSON.stringify(pay_account)} (read-decimal 'payamount) ${pay_token} (read-decimal 'runtime))`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [account_id]), Pact.lang.mkCap("Prove DAO Treasurer Role", "Prove DAO Treasurer Role", "free.factory-dao.TREASURY_GUARD", [subject_id, account_id])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2,
                            "runtime": run_time,
                            "payamount": pay_amount
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //create a task message (user)
    async factoryCreateTaskMessage({commit}, payload) {
        if (state.debug) {
            console.log("Creating new Task Message");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            const subject_id = payload.subject_id;
            const task_id = payload.task_id;
            const message_title = payload.message_title;
            const message = payload.message;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.create-task-message ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(task_id)} ${JSON.stringify(message_title)} ${JSON.stringify(message)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.create-task-message ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(task_id)} ${JSON.stringify(message_title)} ${JSON.stringify(message)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //verifys a task
    async factoryVerifyTask({commit}, payload) {
        if (state.debug) {
            console.log("Verifying a task");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            const subject_id = payload.subject_id;
            const task_id = payload.task_id;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.verify-task ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(task_id)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove Treasury Guard", "Prove DAO Treasury", "free.factory-dao.TREASURY_GUARD", [subject_id, accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.verify-task ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(task_id)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove Treasury Guard", "Prove DAO Treasury", "free.factory-dao.TREASURY_GUARD", [subject_id, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //verifys a task
    async factoryCompleteTask({commit}, payload) {
        if (state.debug) {
            console.log("Verifying a task");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            const subject_id = payload.subject_id;
            const task_id = payload.task_id;
            const datenow = new Date().getTime();
            const datenow2 = new Date(datenow).toLocaleString();
            const message = "I have completed this task on "+ datenow2;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.complete-task ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(task_id)} ${JSON.stringify(message)} )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.complete-task ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(task_id)} ${JSON.stringify(message)} )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //create a task response message (moderator)
    async factoryCreateTaskMessageResponse({commit}, payload) {
        if (state.debug) {
            console.log("Creating new Task Message");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            const subject_id = payload.subject_id;
            const task_id = payload.task_id;
            const message_title = payload.message_title;
            const message = payload.message;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.create-task-response ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(task_id)} ${JSON.stringify(message_title)} ${JSON.stringify(message)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Moderator", "Prove DAO Moderator", "free.factory-dao.MODERATOR_GUARD", [subject_id, accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.create-task-response ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(task_id)} ${JSON.stringify(message_title)} ${JSON.stringify(message)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Moderator", "Prove DAO Moderator", "free.factory-dao.MODERATOR_GUARD", [subject_id, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //create a DAO message (moderator)
    async factoryCreateDAOMessage({commit}, payload) {
        if (state.debug) {
            console.log("Creating new DAO Message");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            const subject_id = payload.subject_id;
            const message_title = payload.message_title;
            const message = payload.message;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.create-subject-message ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(message_title)} ${JSON.stringify(message)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Moderator", "Prove DAO Moderator", "free.factory-dao.MODERATOR_GUARD", [subject_id, accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.create-subject-message ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(message_title)} ${JSON.stringify(message)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Moderator", "Prove DAO Moderator", "free.factory-dao.MODERATOR_GUARD", [subject_id, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //create a DAO treasury pool (treasurer)
    async factoryCreateDAOTreasuryPool({commit}, payload) {
        if (state.debug) {
            console.log("Creating new DAO Treasury Pool");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            const subject_id = payload.subject_id;
            const tokenA = payload.tokenA;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.create-subject-treasury ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${tokenA})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Treasury", "Prove DAO Treasury", "free.factory-dao.TREASURY_GUARD", [subject_id, accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.create-subject-treasury ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${tokenA})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Treasury", "Prove DAO Treasury", "free.factory-dao.TREASURY_GUARD", [subject_id, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //create a DAO creator (creator)
    async factoryCreateDAOCreator({commit}, payload) {
        if (state.debug) {
            console.log("Creating new DAO Creator");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            const subject_id = payload.subject_id;
            const new_creator_id = payload.new_creator_id;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.create-subject-creator ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(new_creator_id)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Creator", "Prove DAO Creator", "free.factory-dao.CREATOR_GUARD", [subject_id, accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.create-subject-creator ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(new_creator_id)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Creator", "Prove DAO Creator", "free.factory-dao.CREATOR_GUARD", [subject_id, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //create a DAO treasurer (creator)
    async factoryCreateDAOTreasurer({commit}, payload) {
        if (state.debug) {
            console.log("Creating new DAO Treasurer");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            const subject_id = payload.subject_id;
            const new_creator_id = payload.new_creator_id;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.create-subject-treasurer ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(new_creator_id)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Creator", "Prove DAO Creator", "free.factory-dao.CREATOR_GUARD", [subject_id, accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.create-subject-treasurer ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(new_creator_id)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Creator", "Prove DAO Creator", "free.factory-dao.CREATOR_GUARD", [subject_id, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //create a DAO treasurer (creator)
    async factoryCreateDAOModerator({commit}, payload) {
        if (state.debug) {
            console.log("Creating new DAO Moderator");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            const subject_id = payload.subject_id;
            const new_creator_id = payload.new_creator_id;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.create-subject-moderator ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(new_creator_id)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Creator", "Prove DAO Creator", "free.factory-dao.CREATOR_GUARD", [subject_id, accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.create-subject-moderator ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(new_creator_id)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Creator", "Prove DAO Creator", "free.factory-dao.CREATOR_GUARD", [subject_id, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //deactivates a proposition (moderator)
    async factoryDeactivateProposition({commit}, payload) {
        if (state.debug) {
            console.log("Deactivating a proposition");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            const subject_id = payload.subject_id;
            const pool_id = payload.pool_id;
            const reason = payload.reason;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.deactivate-pool ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(pool_id)} ${JSON.stringify(reason)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Moderator", "Prove DAO Moderator", "free.factory-dao.MODERATOR_GUARD", [subject_id, accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.deactivate-pool ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${JSON.stringify(pool_id)} ${JSON.stringify(reason)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Moderator", "Prove DAO Moderator", "free.factory-dao.MODERATOR_GUARD", [subject_id, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //withdraws from a DAO treasury (creator)
    async factoryWithdrawDAOTreasuryPool({commit}, payload) {
        if (state.debug) {
            console.log("Withdrawing from DAO Treasury Pool");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            const subject_id = payload.subject_id;
            const token = payload.token;
            const amount = payload.amount;
            const to_account = payload.to_account;
            const reason = payload.reason;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.claim-subject-treasury ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${token} (read-decimal 'amounttowithdraw) ${JSON.stringify(to_account)} ${JSON.stringify(reason)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Treasury", "Prove DAO Treasury", "free.factory-dao.TREASURY_GUARD", [subject_id, accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2,
                    "amounttowithdraw": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.claim-subject-treasury ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${token} (read-decimal 'amounttowithdraw) ${JSON.stringify(to_account)} ${JSON.stringify(reason)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Prove DAO Treasury", "Prove DAO Treasury", "free.factory-dao.TREASURY_GUARD", [subject_id, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2,
                            "amounttowithdraw": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //deposits into a DAO treasury (creator)
    async factoryDepositDAOTreasuryPool({commit}, payload) {
        if (state.debug) {
            console.log("Depositing into DAO Treasury Pool");
        }
        try {
            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            const subject_id = payload.subject_id;
            const token = payload.token;
            const amount = payload.amount;
            const reason = payload.reason;
            const pool_id = payload.pool_id;


            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.deposit-subject-treasury ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${token} (read-decimal 'amounttodeposit) ${JSON.stringify(reason)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Deposit Tokens", "Transfer and deposit tokens in pool", token+'.'+"TRANSFER", [accountName2, pool_id, Number(amount)])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "user": accountName2,
                    "amounttodeposit": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-dao.deposit-subject-treasury ${JSON.stringify(accountName2)} ${JSON.stringify(subject_id)} ${token} (read-decimal 'amounttodeposit) ${JSON.stringify(reason)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Deposit Tokens", "Transfer and deposit tokens in pool", token+'.'+"TRANSFER", [accountName2, pool_id, Number(amount)])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2,
                            "amounttodeposit": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Gets a DAO Hives information
    async getDAOHive(context, payload) {
        if (state.debug) {
            console.log("GETTING DAO HIVE DETAILS:");
            console.log(payload.daoid)
        }

        const daoid = payload.daoid;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-dao.get-subject-info ${JSON.stringify(daoid)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got DAO Hive Details");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("Got No DAO Hive Details 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("DAO DETAILS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Local call to check if a NFT ID exists
    async getDAOHiveExists(context, payload) {
        if (state.debug) {
            console.log("GETTING DAO HIVE EXISTS!");
            console.log("NFTID:");
            console.log(payload.daoid);
        }

        const daoid = payload.daoid;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-dao.get-subject-info ${JSON.stringify(daoid)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("DAO EXISTS");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("NO DAO EXISTS 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("DAO DATA GOTTEN");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(true);

        }else {
            return(false);
        }

    },

    //Gets a DAO Hives Pools information
    async getDAOHivePools(context, payload) {
        if (state.debug) {
            console.log("GETTING DAO HIVE POOLS:");
            console.log(payload.daoid)
        }

        const daoid = payload.daoid;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-dao.get-subject-pools ${JSON.stringify(daoid)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got DAO Hive Pools Details");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("Got No DAO Hive Pools Details 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("DAO POOL DETAILS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets all DAO Hive Tasks information
    async getDAOHiveTasks(context, payload) {
        if (state.debug) {
            console.log("GETTING DAO HIVE TASKS:");
            console.log(payload.daoid)
        }

        const daoid = payload.daoid;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-dao.get-subject-tasks ${JSON.stringify(daoid)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got DAO Hive Tasks Details");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("Got No DAO Hive Tasks Details 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("DAO TASK DETAILS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets all DAO Hives members
    async getDAOHiveMembers(context, payload) {
        if (state.debug) {
            console.log("GETTING DAO HIVE MEMBERS:");
            console.log(payload.daoid)
        }

        const daoid = payload.daoid;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-dao.get-all-subject-members ${JSON.stringify(daoid)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got DAO Hive Member Details");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("Got No DAO Hive Members Details 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("DAO MEMBERS DETAILS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets a tasks information
    async getTask(context, payload) {
        if (state.debug) {
            console.log("GETTING TASK DETAILS:");
            console.log(payload.poolid)
        }

        const poolid = payload.poolid;


        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-dao.get-task-info ${JSON.stringify(poolid)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got Task Details");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("Got No Task Details 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("DAO DETAILS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets a Vote Polls information
    async getVotePoll(context, payload) {
        if (state.debug) {
            console.log("GETTING VOTE POLL DETAILS:");
            console.log(payload.poolid)
        }

        const poolid = payload.poolid;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-dao.get-pool-info ${JSON.stringify(poolid)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got Vote Poll Details");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("Got No Vote Poll Details 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("DAO DETAILS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets a Vote Polls voting information
    async getVotePollVotes(context, payload) {
        if (state.debug) {
            console.log("GETTING VOTE POLL VOTES DETAILS:");
            console.log(payload.poolid)
        }

        const poolid = payload.poolid;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-dao.get-pool-votes ${JSON.stringify(poolid)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got Vote Poll Votes Details");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("Got No Voting Details 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("DAO VOTE DETAILS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets an Accounts Votes from a specific pool
    async getAccountVotePollVotes(context, payload) {
        if (state.debug) {
            console.log("GETTING VOTE POLL VOTES DETAILS:");
            console.log(payload.poolid)
        }

        const poolid = payload.poolid;
        const accountName2 = localStorage.getItem("accountName");

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-dao.get-account-pool-votes ${JSON.stringify(accountName2)} ${JSON.stringify(poolid)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got Vote Poll Votes Details");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("Got No Voting Details 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("DAO VOTE DETAILS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets all DAOS
    async getAllDAOs() {
        if (state.debug) {
            console.log("GETTING ALL DAOS");
        }


        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-dao.get-all-subjects)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got ALL DAOS Details");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("Got No ALL Dao Details 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("ALL DAOS DETAILS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets DAOS a user has stakes in
    async getAllUserStakedDAOs() {
        if (state.debug) {
            console.log("GETTING USER STAKED DAO HIVE DETAILS:");
        }

        const accountName2 = localStorage.getItem("accountName");

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-dao.get-all-user-pools ${JSON.stringify(accountName2)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got User DAO Hive Details");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("Got No User DAO Hive Details 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("User DAO DETAILS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //withdraws a stake from a voting pool
    async withdrawVoteStake({commit}, payload) {

        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");

            //Arrange types
            const poolID = payload.poolID;

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.withdraw-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Ownership", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-dao.withdraw-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Ownership", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Stakes into a voting poll
    async stakeFactoryStakeVote({commit}, payload) {

        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const amount = Number(payload.amount);
            const module = payload.moduleName;
            const votefor = payload.votefor;
            const voteagainst = payload.voteagainst;
            const treausry = payload.treasury;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(amount);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-dao.create-vote ${JSON.stringify(poolID)} (read-decimal 'stake-amount) ${JSON.stringify(accountName2)} ${votefor} ${voteagainst} )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Vote Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [accountName2, treausry, amount])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "stake-amount": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-dao.create-vote ${JSON.stringify(poolID)} (read-decimal 'stake-amount) ${JSON.stringify(accountName2)} ${votefor} ${voteagainst} )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-dao.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Vote Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [accountName2, treausry, amount])],
                        pactCode: xwalletcode,
                        envData: {
                            "stake-amount": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },



    ////////////////////////////////////////////////////////////////
    ///// TREASURY POOLS + NFT TREASURY POOLS
    ////////////////////////////////////////////////////////////////

    //Local call to get current pool redemptions
    async getRedemptions() {
        if (state.debug) {
            console.log("GETTING CURRENT STAKE REDEMPTIONS!");
        }
        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-emissions4.get-redemptions)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got stake factory redemptions");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No emission redemptions gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("REDEMPTIONS");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(dataResult[0].data);

        }else {
            return(0);
        }

    },

    //Local call to get current pool emissions day
    async getCurrentEmissionDay() {
        if (state.debug) {
            console.log("GETTING CURRENT STAKE EMISSION DAY!");
        }
        let exists = false;
        let dataResult = null;
        const poolName = "STAKE-EMISSIONS4";
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-emissions4.get-current-emissions-day ${JSON.stringify(poolName)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (state.debug) {
                console.log("EMISSIONS DAY RESULT")
                console.log(data.result);
            }

            console.log("EMISSIONS DAY RESULT")
            console.log(data.result);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got stake factory current emissions day");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No stake factory current emissions day gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("CURRENT EMISSIONS DAY");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(dataResult[0].data);

        }else {
            return(0);
        }

    },

    //Local call to get current total emissions
    async getCurrentTotalEmissions(context, payload) {
        if (state.debug) {
            console.log("GETTING CURRENT STAKE TOTAL EMISSIONS!");
        }

        const poolName = payload.poolName;
        const date = payload.date;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-emissions4.calculate-rewards-on ${JSON.stringify(poolName)} (read-decimal 'date))`,
                envData: {
                    "date": date,
                },
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (state.debug) {
                console.log("EMISSIONS TOTAL RESULT")
                console.log(data.result);
            }


            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got stake factory total emissions");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No stake factory total emissions gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("CURRENT EMISSIONS DAY");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(dataResult[0].data);

        }else {
            return(0);
        }

    },

    //Claim Treasury Emissions
    async claimTreasury({commit}, payload) {
        if (state.debug) {
            console.log("Claiming treasury emissions!");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolName = payload.poolName;
            const treasuryAccount = payload.treasury;
            const date = payload.date;

            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(treasuryAccount);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;

            const pactCode = `(free.factory-stake-emissions4.claim-day ${JSON.stringify(poolName)} ${JSON.stringify(treasuryAccount)} (read-decimal 'date) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", [])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "date": date,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-stake-emissions4.claim-day ${JSON.stringify(poolName)} ${JSON.stringify(treasuryAccount)} (read-decimal 'date) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: ttl,
                        envData: {
                            "date": date,
                        },
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", [])],
                        pactCode: xwalletcode,
                        networkId: NETWORKID,
                        signingPubKey: publickey,
                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Local call to get current NFT treasury accounts data (bindAllLiquidity pools for token treasury accounts)
    async getNFTTreasuryAccounts() {
        if (state.debug) {
            console.log("GETTING CURRENT NFT TREASURY ACCOUNTS");
        }
        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-non-fungible9.get-treasury-accounts )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (state.debug) {
                console.log("NFT TREASURY ACCOUNTS")
                console.log(data.result);
            }

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got NFT treasury accounts");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No NFT treasury accounts gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("CURRENT EMISSIONS DAY");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(dataResult[0].data);

        }else {
            return(0);
        }

    },

    ////////////////////////////////////////////////////////////////
    ///// LIQUIDITY POOLS
    ////////////////////////////////////////////////////////////////

    //Gets ALL liquidity pool information from the blockchain includint Treasury Accounts
    async bindAllLiquidityPools({commit}) {
        if (state.debug) {
            console.log("Getting ALL liquidity pools:");
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-exchange3.get-pools)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got all LIQUIDITY POOL factory treasury accounts");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Set all treasury account list data
                commit("setAllTreasuryAccountsData", dataResult[0].data)

            } else {
                if (state.debug) {
                    console.log("Error bindAllLiquidityPools: Could not get liquidity pools.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Local call to get a swap quote
    async getSwapQuote(context, payload) {
        if (state.debug) {
            console.log("GETTING SWAP QUOTE!");
        }

        const amountA = payload.amountA;
        const reserveA = payload.reserveA;
        const reserveB = payload.reserveB;
        const poolName = payload.poolName;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-exchange3.quote (read-decimal 'amounta) (read-decimal 'reservea) (read-decimal 'reserveb) ${JSON.stringify(poolName)})`,
                envData: {
                    "amounta": amountA,
                    "reservea": reserveA,
                    "reserveb": reserveB,
                },
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got swap quote!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Swap Quote Gotten =(");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("QUOTE DATA");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(dataResult[0].data);

        }else {
            return(0);
        }

    },

    //Local call to get a swap pairs data
    async getSwapPairData(context, payload) {
        if (state.debug) {
            console.log("GETTING SWAP PAIR DATA!");
        }

        const tokenA = payload.tokenA;
        const tokenB = payload.tokenB;
        const poolName = payload.poolName;

        if(state.debug){
            console.log("SWAP PAIR DATA PAYLOAD");
            console.log("tokenA");
            console.log(tokenA);
            console.log("tokenB");
            console.log(tokenB);
            console.log("poolName");
            console.log(poolName);
        }

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-exchange3.get-pair ${tokenA} ${tokenB} ${JSON.stringify(poolName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got swap pair data!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Swap Pair Data Gotten =(");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("SWAP PAIR DATA");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(dataResult[0].data);

        }else {
            return(0);
        }

    },

    //Local call to get a tokens precision
    async getTokenPrecision(context, payload) {
        if (state.debug) {
            console.log("GETTING TOKEN PRECISION DATA!");
            console.log("TOKEN:");
            console.log(payload.tokenA);
        }

        const tokenA = payload.tokenA;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(${tokenA}.precision)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got token precision data!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Token precision data gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("QUOTE DATA");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(dataResult[0].data);

        }else {
            return(0);
        }

    },

    //Local call to get a users balance for a specific token
    async getTokenUserBalance(context, payload) {
        if (state.debug) {
            console.log("GETTING USER BALANCE FOR TOKEN!");
            console.log("TOKEN:");
            console.log(payload.tokenA);
            console.log("test reduce balance");
            console.log(reduceBalance(10.0,1));
        }

        const tokenA = payload.tokenA;
        const name = localStorage.getItem("accountName");

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(${tokenA}.get-balance ${JSON.stringify(name)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got user token balance!");
                }
                if (state.debug) {
                    console.log(data.result);
                }

                if(state.debug){
                    console.log("data.result.decimal");
                    console.log(data.result.decimal)
                }

                if(data.result.decimal !== undefined){
                    data.result = data.result.decimal;
                }

                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Token balance for user gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("USER TOKEN "+ tokenA +" BALANCE");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data.decimal !== undefined){
                dataResult[0].data = dataResult[0].data.decimal;
            }

            console.log("returning");
            console.log(dataResult[0].data);

            return(dataResult[0].data);

        }else {
            return(0);
        }

    },

    //Local call to get a users balance for a specific token
    async getPolyfungiv1TokenUserBalance(context, payload) {
        if (state.debug) {
            console.log("GETTING USER BALANCE FOR TOKEN!");
            console.log("TOKEN:");
            console.log(payload.tokenA);
            console.log("test reduce balance");
            console.log(reduceBalance(10.0,1));
        }

        const tokenA = payload.tokenA;
        const nftID = payload.nftID;
        const name = localStorage.getItem("accountName");

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(${tokenA}.get-balance ${JSON.stringify(nftID)} ${JSON.stringify(name)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got user token balance!");
                }
                if (state.debug) {
                    console.log(data.result);
                }

                if(state.debug){
                    console.log("data.result.decimal");
                    console.log(data.result.decimal)
                }

                if(data.result.decimal !== undefined){
                    data.result = data.result.decimal;
                }

                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Token balance for user gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("USER TOKEN "+ tokenA +" BALANCE");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data.decimal !== undefined){
                dataResult[0].data = dataResult[0].data.decimal;
            }

            return(dataResult[0].data);

        }else {
            return(0);
        }

    },

    //Local call to get a specific users balance for a specific token
    async getBalance(context, payload) {
        if (state.debug) {
            console.log("GETTING SPECIFIC USER BALANCE FOR SPECIFIC TOKEN!");
            console.log("TOKEN:");
            console.log(payload.tokenA);
        }

        const tokenA = payload.tokenA;
        const name = payload.name;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(${tokenA}.get-balance ${JSON.stringify(name)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got specific user token balance!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Token balance for user gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("USER TOKEN "+ tokenA +" BALANCE");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(dataResult[0].data);

        }else {
            return(0);
        }

    },

    //Local call to get a swap pair token account
    async getPairAccount(context, payload) {
        if (state.debug) {
            console.log("GETTING SWAP PAIR ACCOUNT!");
        }

        const tokenA = payload.tokenA;
        const tokenB = payload.tokenB;
        const poolName = payload.poolName;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(at 'account (free.factory-exchange3.get-pair ${tokenA} ${tokenB} ${JSON.stringify(poolName)}))`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got swap pair account!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Swap Pair Account Gotten =(");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("SWAP PAIR ACCOUNT");
                console.log(dataResult[0].data);
            }

            return(dataResult[0].data);

        }else {
            return(0);
        }

    },

    //Local call to get a swap pair key
    async getPairKey(context, payload) {
        if (state.debug) {
            console.log("GETTING SWAP PAIR KEY!");
        }

        const tokenA = payload.tokenA;
        const tokenB = payload.tokenB;
        const poolName = payload.poolName;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-exchange3.get-pair-key ${tokenA} ${tokenB} ${JSON.stringify(poolName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got swap pair key!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Swap Pair Key Gotten =(");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("SWAP PAIR KEY RESPONSE");
                console.log(dataResult[0].data);
            }

            return(dataResult[0].data);

        }else {
            return(0);
        }

    },

    //Local call to get price impact slippage
    async getPriceImpactSlippage(context, payload) {
        if (state.debug) {
            console.log("Getting price impact on slippage");
        }
        const amountIn = Number(payload.amountIn);
        const amountOut = Number(payload.amountOut);
        const reserveIn = Number(payload.reserveIn);
        const reserveOut = Number(payload.reserveOut);
        const midPrice = (reserveOut/reserveIn);
        const exactQuote = amountIn * midPrice;
        const slippage = (exactQuote-amountOut) / exactQuote;
        return slippage;
    },

    //Local call to get price impact slippage
    async getReserves(context, payload) {
        if (state.debug) {
            console.log("Getting token pair reserves");
        }

        const tokenA = payload.tokenA;
        const tokenB = payload.tokenB;
        const poolName = payload.poolName;

        if (state.debug) {
            console.log("Tokens to get reserves for:");
            console.log(tokenA);
            console.log(tokenB);
        }

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(use free.factory-exchange3)
          (let*
            (
              (p (get-pair ${tokenA} ${tokenB} ${JSON.stringify(poolName)}))
              (reserveA (reserve-for p ${tokenA}))
              (reserveB (reserve-for p ${tokenB}))
            )[reserveA reserveB])`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got Reserves!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("Couldnt get reserves 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("URI.data");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }


            if(dataResult[0].data !== undefined){

                //Is the result a string? If so lets look up the URI
                if(typeof(dataResult[0].data) === 'string'){

                    //If URI is not a link to an image, then its a link to an object (hopefully)
                    try{
                        let response = () => {
                            return new Promise(function(resolve) {
                                const res = axios.get(dataResult[0].data, {
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                });
                                resolve(res);
                            });
                        };
                        let responseData = await response();

                        if(state.debug){
                            console.log("Returning URI axios response.data")
                            console.log(responseData.data);
                        }

                        return(responseData.data);
                    }catch(error){
                        console.log("Unknown URI format")
                        return("0");
                    }

                }else if (typeof(dataResult[0].data) === 'object'){
                    if(state.debug){
                        console.log("Returning dataResult[0].data object URI")
                        console.log(dataResult[0].data);
                    }
                    return(dataResult[0].data);
                }
            }

        }else {
            return(dataResult);
        }

    },

    //Local call to verify if a liquidity pool exists
    async getLiquidityPoolIdVerification({commit}, payload) {
        if (state.debug) {
            console.log("Getting Liquidtiy Pool Existance");
        }

        const tokenA = payload.tokenA;
        const tokenB = payload.tokenB;
        const poolName = payload.poolName;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-exchange3.get-treasury-account ${tokenA} ${tokenB} ${JSON.stringify(poolName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("WARNING: POOL ID ALREADY EXISTS!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
                state.poolIdExists = true;
            } else {
                if (state.debug) {
                    console.log("SUCCESS: POOL ID IS UNIQUE!");
                    console.log(data.result);
                }

                try {
                    const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
                    let data = await Pact.fetch.local({
                        pactCode: `(free.factory-exchange3.get-pool-name ${JSON.stringify(poolName)})`,
                        meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
                    }, state.network);

                    if (data.result.status === "success") {
                        if (state.debug) {
                            console.log("WARNING: POOL ID ALREADY EXISTS!");
                        }
                        if (state.debug) {
                            console.log(data.result);
                        }
                        dataResult = [{...data.result}];
                        exists = true;
                        state.poolIdExists = true;
                    } else {
                        if (state.debug) {
                            console.log("SUCCESS: POOL ID IS UNIQUE!");
                            console.log(data.result);
                        }
                        exists = false;
                        state.poolIdExists = false;
                    }

                } catch (error) {
                    console.log(error);
                }

            }

        } catch (error) {
            console.log(error);
        }

        if(state.debug){
            console.log("POOL ID VERIFICATION DATA RESULT:");
            console.log(dataResult);
        }


        commit("setPoolIdExists", exists);
    },

    //Local call to verify if a crowdfund pool exists
    async getCrowdFundPoolIdVerification({commit}, payload) {
        if (state.debug) {
            console.log("Getting Crowdfund Pool Existance");
            console.log("payload");
            console.log(payload);
        }

        const poolName = payload.poolName;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-crowdfund5.get-pool-info ${JSON.stringify(poolName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("WARNING: POOL ID ALREADY EXISTS!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
                state.poolIdExists = true;
            } else {
                if (state.debug) {
                    console.log("SUCCESS: POOL ID IS UNIQUE!");
                    console.log(data.result);
                }

            }

        } catch (error) {
            console.log(error);
        }

        if(state.debug){
            console.log("POOL ID VERIFICATION DATA RESULT:");
            console.log(dataResult);
        }


        commit("setPoolIdExists", exists);
    },

    //Local call to verify if a crowdfund liquidity pool exists
    async getCrowdFundLiquidityPoolIdVerification({commit}, payload) {
        if (state.debug) {
            console.log("Getting Crowdfunding Liquidtiy Pool Existance");
            console.log("payload");
            console.log(payload);
        }

        const tokenA = payload.tokenA;
        const tokenB = payload.tokenB;
        const poolName = payload.poolName;

        if(state.debug){
            console.log("tokenA");
            console.log(tokenA);
            console.log("tokenB");
            console.log(tokenB);
            console.log("poolName");
            console.log(poolName);
        }

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-exchange3.get-treasury-account ${tokenA} ${tokenB} ${JSON.stringify(poolName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("WARNING: POOL ID ALREADY EXISTS!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
                state.poolIdExists1 = true;
            } else {
                if (state.debug) {
                    console.log("SUCCESS: POOL ID IS UNIQUE!");
                    console.log(data.result);
                }

                try {
                    const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
                    let data = await Pact.fetch.local({
                        pactCode: `(free.factory-exchange3.get-pool-name ${JSON.stringify(poolName)})`,
                        meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
                    }, state.network);

                    if (data.result.status === "success") {
                        if (state.debug) {
                            console.log("WARNING: POOL ID ALREADY EXISTS!");
                        }
                        if (state.debug) {
                            console.log(data.result);
                        }
                        dataResult = [{...data.result}];
                        exists = true;
                        state.poolIdExists1 = true;
                    } else {
                        if (state.debug) {
                            console.log("SUCCESS: POOL ID IS UNIQUE!");
                            console.log(data.result);
                        }
                        exists = false;
                        state.poolIdExists1 = false;
                    }

                } catch (error) {
                    console.log(error);
                }

            }

        } catch (error) {
            console.log(error);
        }

        if(state.debug){
            console.log("POOL ID VERIFICATION DATA RESULT:");
            console.log(dataResult);
        }


        commit("setPoolIdExists1", exists);
    },

    //Local call to verify if a liquidity pool name exists
    async getLiquidityPoolNameVerification({commit}, poolName) {
        if (state.debug) {
            console.log("Getting Liquidtiy Pool Name Existance");
        }

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-exchange3.get-pool-name ${JSON.stringify(poolName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("WARNING: POOL ID ALREADY EXISTS!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
                state.poolIdExists = true;
            } else {
                if (state.debug) {
                    console.log("SUCCESS: POOL ID IS UNIQUE!");
                    console.log(data.result);
                }
                exists = false;
                state.poolIdExists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(state.debug){
            console.log("POOL ID VERIFICATION DATA RESULT:");
            console.log(dataResult);
        }


        commit("setPoolIdExists", exists);
    },

    //Add liquidity
    async stakeFactoryAddLiquidity({commit}, payload) {
        if (state.debug) {
            console.log("Adding liquidity to liquidity pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const tokenA = payload.tokenA;
            const tokenB = payload.tokenB;
            const amountA = payload.amountA;
            const amountB = payload.amountB;
            const amountAMin = payload.amountAMin;
            const amountBMin = payload.amountBMin;
            const poolName = payload.poolName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(tokenA);
                console.log(tokenB);
                console.log(amountA);
                console.log(amountB);
                console.log(amountAMin);
                console.log(amountBMin);
                console.log(poolName)
            }

            const accountPayload = {
                tokenA: tokenA,
                tokenB: tokenB,
                poolName: poolName
            }

            let pairAccount = await this.dispatch("accounts/getPairAccount", accountPayload);

            if(state.debug){
                console.log("PAIR ACCOUNT");
                console.log(pairAccount);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;

            const pactCode = `(free.factory-exchange3.addliquidity ${JSON.stringify(poolName)} ${tokenA} ${tokenB} (read-decimal 'amountA) (read-decimal 'amountB) (read-decimal 'amountAMin) (read-decimal 'amountBMin) ${JSON.stringify(accountName2)} ${JSON.stringify(accountName2)} (read-keyset 'user-ks) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer "+tokenA, "Transfer token to liquidity pool", tokenA+'.'+"TRANSFER", [accountName2, pairAccount, Number(amountA)]), Pact.lang.mkCap("Transfer "+tokenB, "Transfer token to liquidity pool", tokenB+'.'+"TRANSFER", [accountName2, pairAccount, Number(amountB)])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "amountA": amountA,
                    "amountB": amountB,
                    "amountAMin": amountAMin,
                    "amountBMin": amountBMin,
                    "user-ks": accountGuard
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-exchange3.addliquidity ${JSON.stringify(poolName)} ${tokenA} ${tokenB} (read-decimal 'amountA) (read-decimal 'amountB) (read-decimal 'amountAMin) (read-decimal 'amountBMin) ${JSON.stringify(accountName2)} ${JSON.stringify(accountName2)} (read-keyset 'user-ks) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer "+tokenA, "Transfer token to liquidity pool", tokenA+'.'+"TRANSFER", [accountName2, pairAccount, Number(amountA)]), Pact.lang.mkCap("Transfer "+tokenB, "Transfer token to liquidity pool", tokenB+'.'+"TRANSFER", [accountName2, pairAccount, Number(amountB)])],
                        pactCode: xwalletcode,
                        envData: {
                            "amountA": amountA,
                            "amountB": amountB,
                            "amountAMin": amountAMin,
                            "amountBMin": amountBMin,
                            "user-ks": accountGuard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }


            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Remove liquidity
    async stakeFactoryRemoveLiquidity({commit}, payload) {
        if (state.debug) {
            console.log("Removing liquidity to liquidity pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const tokenA = payload.tokenA;
            const tokenB = payload.tokenB;
            const amountA = payload.amountA;
            const poolName = payload.poolName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(tokenA);
                console.log(tokenB);
                console.log(amountA);
                console.log(poolName);
            }

            const accountPayload = {
                tokenA: tokenA,
                tokenB: tokenB,
                poolName: poolName
            }

            let pairAccount = await this.dispatch("accounts/getPairAccount", accountPayload);

            let pairKey = await this.dispatch("accounts/getPairKey", accountPayload);

            if(state.debug){
                console.log("PAIR ACCOUNT");
                console.log(pairAccount);
                console.log("PAIR KEY");
                console.log(pairKey);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;

            const pactCode = `(free.factory-exchange3.removeliquidity ${JSON.stringify(poolName)} ${tokenA} ${tokenB} (read-decimal 'amountA) 0.0 0.0 ${JSON.stringify(accountName2)} ${JSON.stringify(accountName2)} (read-keyset 'user-ks) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer LP Token", "Transfer LP Token to Liquidity Pool", "free.factory-tokens3.TRANSFER", [pairKey, accountName2, pairAccount, Number(amountA)])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "amountA": amountA,
                    "user-ks": accountGuard
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-exchange3.removeliquidity ${JSON.stringify(poolName)} ${tokenA} ${tokenB} (read-decimal 'amountA) 0.0 0.0 ${JSON.stringify(accountName2)} ${JSON.stringify(accountName2)} (read-keyset 'user-ks) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer LP Token", "Transfer LP Token to Liquidity Pool", "free.factory-tokens3.TRANSFER", [pairKey, accountName2, pairAccount, Number(amountA)])],
                        pactCode: xwalletcode,
                        envData: {
                            "amountA": amountA,
                            "user-ks": accountGuard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Local call to get a tokens precision
    async getLPTokenUserBalance(context, payload) {
        if (state.debug) {
            console.log("GETTING USER LP TOKEN BALANCE!");
            console.log("TOKENA:");
            console.log(payload.tokenA);
            console.log("TOKENA:");
            console.log(payload.tokenB);
            console.log("POOLNAME");
            console.log(payload.poolName);
        }

        const tokenA = payload.tokenA;
        const tokenB = payload.tokenB;
        const name = localStorage.getItem("accountName");
        const poolName = payload.poolName;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-tokens3.get-balance (free.factory-exchange3.get-pair-key  ${tokenA} ${tokenB} ${JSON.stringify(poolName)}) ${JSON.stringify(name)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got user lp token balance!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No LP Token balance for user gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("USER TOKEN LP BALANCE");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }



        }else {
            return(0);
        }

    },

    //Local call to get a tokens precision
    async getCheckWhitelist(context, payload) {
        if (state.debug) {
            console.log("CHECKING IF A USER IS WHITELISTED");
            console.log("POOLID:");
            console.log(payload.poolid);
        }

        const poolid = payload.poolid;
        const name = localStorage.getItem("accountName");

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-crowdfund5.check-if-whitelisted ${JSON.stringify(poolid)} ${JSON.stringify(name)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got user if user whitelisted!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No LP Token balance for user gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("USER TOKEN LP BALANCE");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(dataResult[0].data);



        }else {
            return(false);
        }

    },

    //Swap
    async stakeFactorySwap({commit}, payload) {
        if (state.debug) {
            console.log("Swapping tokens");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolName = payload.poolName;
            const tokenA = payload.tokenA;
            const tokenB = payload.tokenB;
            const amountA = payload.amountA;
            const amountBWithSlippage = payload.amountBWithSlippage;
            const ttl = payload.ttl;

            const poolNameObject = {
                poolName:poolName
            }

            const poolNameObjectComplete = [poolNameObject,poolNameObject]

            // const poolNameObject2 = `{"poolName":${JSON.stringify(poolName)}}{"poolName":${JSON.stringify(poolName)}}`

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(tokenA);
                console.log(tokenB);
                console.log(amountA);
                console.log(amountBWithSlippage);
                console.log(poolNameObject);
                console.log(poolNameObjectComplete)
            }

            const accountPayload = {
                tokenA: tokenA,
                tokenB: tokenB,
                poolName: poolName
            }

            let pairAccount = await this.dispatch("accounts/getPairAccount", accountPayload);

            if(state.debug){
                console.log("PAIR ACCOUNT");
                console.log(pairAccount);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;

            const pactCode = `(free.factory-exchange3.swapexactin [{"poolName":${JSON.stringify(poolName)}}{"poolName":${JSON.stringify(poolName)}}]  (read-decimal 'amountA) (read-decimal 'amountBWithSlippage) [${tokenA} ${tokenB}] ${JSON.stringify(accountName2)} ${JSON.stringify(accountName2)} (read-keyset 'user-ks) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer "+tokenA, "Transfer token to liquidity pool", tokenA+'.'+"TRANSFER", [accountName2, pairAccount, Number(amountA)])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "amountA": amountA,
                    "amountBWithSlippage": amountBWithSlippage,
                    "user-ks": accountGuard
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            //[{"poolName":"test-pool"}{"poolName":"test-pool"}]
            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-exchange3.swapexactin [{"poolName":${JSON.stringify(poolName)}}{"poolName":${JSON.stringify(poolName)}}]  (read-decimal 'amountA) (read-decimal 'amountBWithSlippage) [${tokenA} ${tokenB}] ${JSON.stringify(accountName2)} ${JSON.stringify(accountName2)} (read-keyset 'user-ks) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer "+tokenA, "Transfer token to liquidity pool", tokenA+'.'+"TRANSFER", [accountName2, pairAccount, Number(amountA)])],
                        pactCode: xwalletcode,
                        envData: {
                            "amountA": amountA,
                            "amountBWithSlippage": amountBWithSlippage,
                            "user-ks": accountGuard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Swap
    async stakeFactorySwapExactOut({commit}, payload) {
        if (state.debug) {
            console.log("Swapping tokens EXACT OUT");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolName = payload.poolName;
            const tokenA = payload.tokenA;
            const tokenB = payload.tokenB;
            const amountA = payload.amountA;
            const amountBWithSlippage = payload.amountBWithSlippage;
            const ttl = payload.ttl;

            const poolNameObject = {
                poolName:poolName
            }

            const poolNameObjectComplete = [poolNameObject,poolNameObject]

            // const poolNameObject2 = `{"poolName":${JSON.stringify(poolName)}}{"poolName":${JSON.stringify(poolName)}}`

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(tokenA);
                console.log(tokenB);
                console.log(amountA);
                console.log(amountBWithSlippage);
                console.log(poolNameObject);
                console.log(poolNameObjectComplete)
            }

            const accountPayload = {
                tokenA: tokenA,
                tokenB: tokenB,
                poolName: poolName
            }

            let pairAccount = await this.dispatch("accounts/getPairAccount", accountPayload);

            if(state.debug){
                console.log("PAIR ACCOUNT");
                console.log(pairAccount);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;

            const pactCode = `(free.factory-exchange3.swapexactout [{"poolName":${JSON.stringify(poolName)}}{"poolName":${JSON.stringify(poolName)}}]  (read-decimal 'amountA) (read-decimal 'amountBWithSlippage) [${tokenA} ${tokenB}] ${JSON.stringify(accountName2)} ${JSON.stringify(accountName2)} (read-keyset 'user-ks) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer "+tokenA, "Transfer token to liquidity pool", tokenA+'.'+"TRANSFER", [accountName2, pairAccount, Number(amountA)])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "amountA": amountA,
                    "amountBWithSlippage": amountBWithSlippage,
                    "user-ks": accountGuard
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            //[{"poolName":"test-pool"}{"poolName":"test-pool"}]
            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-exchange3.swapexactout [{"poolName":${JSON.stringify(poolName)}}{"poolName":${JSON.stringify(poolName)}}]  (read-decimal 'amountA) (read-decimal 'amountBWithSlippage) [${tokenA} ${tokenB}] ${JSON.stringify(accountName2)} ${JSON.stringify(accountName2)} (read-keyset 'user-ks) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer "+tokenA, "Transfer token to liquidity pool", tokenA+'.'+"TRANSFER", [accountName2, pairAccount, Number(amountA)])],
                        pactCode: xwalletcode,
                        envData: {
                            "amountA": amountA,
                            "amountBWithSlippage": amountBWithSlippage,
                            "user-ks": accountGuard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Create Liquidity Pool
    async stakeFactoryCreateLiquidityPool({commit}, payload) {
        if (state.debug) {
            console.log("Creating Liquidity Pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolName = payload.poolName;
            const tokenA = payload.tokenA;
            const tokenB = payload.tokenB;
            const fee = payload.fee;
            const random = Math.floor(Math.random() * 10) + 1;
            const hint = payload.poolName+random.toString();

            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(tokenA);
                console.log(tokenB);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;

            const pactCode = `(free.factory-exchange3.create-pair ${tokenA} ${tokenB} ${JSON.stringify(hint)} (read-decimal 'fee) ${JSON.stringify(poolName)} ${JSON.stringify(accountName2)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer Fee", "Transfer Pool Creation Fee", "free.stake-token.TRANSFER", [accountName2, "STAKEFACTORYTREASURY3", 0.01])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "fee": fee,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(free.factory-exchange3.create-pair ${tokenA} ${tokenB} ${JSON.stringify(hint)} (read-decimal 'fee) ${JSON.stringify(poolName)} ${JSON.stringify(accountName2)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: ttl,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer Fee", "Transfer Pool Creation Fee", "free.stake-token.TRANSFER", [accountName2, "STAKEFACTORYTREASURY3", 0.01])],
                        pactCode: xwalletcode,
                        envData: {
                            "fee": fee,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Transfer token
    async transferToken({commit}, payload) {
        if (state.debug) {
            console.log("Transfering token!");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const token = payload.token;
            const amount = payload.amount;
            const pool_account = payload.pool_account;

            const ttl = 600;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(token);
                console.log(amount);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;

            const pactCode = `(${token}.transfer ${JSON.stringify(pool_account)} ${JSON.stringify(accountName2)} (read-decimal 'amount))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer "+token, "Transfer token to liquidity pool", token+'.'+"TRANSFER", [pool_account, accountName2, Number(amount)])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: ttl,
                envData: {
                    "amount": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode =  `(${token}.transfer ${JSON.stringify(pool_account)} ${JSON.stringify(accountName2)} (read-decimal 'amount))`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: ttl,
                        envData: {
                            "amount": amount,
                        },
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer "+token, "Transfer token to liquidity pool", token+'.'+"TRANSFER", [pool_account, accountName2, Number(amount)])],
                        pactCode: xwalletcode,
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    }

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.xwalletconnected === true) {

                if(state.debug){
                    console.log("cmd response:");
                    console.log(cmd);
                }

                if(cmd.status === 'fail' && cmd.message === 'Rejected by user'){
                    if(state.debug){
                        console.log("TRANSACTION REJECTED BY USER")
                    }
                    await commit("setTransactionRejected", true);
                }else if(cmd.status === 'fail'){
                    if(state.debug){
                        console.log("TRANSACTION FAILED")
                    }
                    await commit("setTransactionRejected", true);
                }

                if(state.debug){
                    console.log("is xwallet connected:");
                    console.log(state.xwalletconnected);
                }

            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Gets a liquidity pools information
    async getLiquidityPool(context, payload) {
        if (state.debug) {
            console.log("GETTING LIQUIDITY POOL:");
            console.log(payload.poolName);
        }

        const poolName = payload.poolName;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-exchange3.get-pool-util ${JSON.stringify(poolName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got LP pool!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No LP pool gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("CROWDFUND POOL DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets a liquidity pools LP token total supply information
    async getLiquidityTotalSupply(context, payload) {
        if (state.debug) {
            console.log("GETTING LP TOTAL SUPPLY FOR PAIR:");
            console.log(payload.pair);
        }

        const pair = payload.pair;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-tokens3.total-supply ${JSON.stringify(pair)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got total LP supply pool!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No total LP supply gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("CROWDFUND POOL DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    ////////////////////////////////////////////////////////////////
    ///// CROWD FUNDING POOLS
    ////////////////////////////////////////////////////////////////

    //Creates a new crowdfunding pool
    async createCrowdFundingPool({commit}, payload) {
        if (state.debug) {
            console.log("Creating new crowdfunding pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const poolName = payload.poolName;
            const description = payload.description;
            const balance = payload.balance;
            const amountToSell = payload.amountToSell;
            const amountToDex = payload.amountToDex;
            const soldToken = payload.soldToken;
            const pledgeToken = payload.pledgeToken;
            const account = payload.accountName;
            const price = payload.price;
            const runTime = payload.runTime;
            const insuranceTime = payload.insuranceTime;
            const lpPoolName = payload.lpPoolName;
            const lpPoolFee = payload.lpPoolFee;
            const wlRunTime = payload.wlRunTime;
            const wl = payload.wl;
            const wlMaxBuy = payload.wlMaxBuy;
            const maxbuy = payload.maxbuy;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(poolName);
                console.log(balance);
                console.log(typeof(balance));
                console.log(description);
                console.log(amountToSell);
                console.log(amountToDex);
                console.log(soldToken);
                console.log(pledgeToken);
                console.log(price);
                console.log(runTime);
                console.log(insuranceTime);
                console.log(lpPoolName);
                console.log(lpPoolFee);
                console.log("whitelist info");
                console.log(wl);
                console.log(wlRunTime);
                console.log(wlMaxBuy);
            }

            console.log("whitelist info");
            console.log(wl);
            console.log(typeof(wl));
            console.log(wlRunTime);
            console.log(wlMaxBuy);

            let newwl = '';
            for(let i = 0; i < wl.length; i++){

                newwl += ` "`+wl[i]+`"`;

            }
            console.log("newwl");
            console.log(newwl);

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-crowdfund5.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} ${JSON.stringify(description)} (read-decimal 'pool-balance) (read-decimal 'amount-to-sell) (read-decimal 'amount-to-lp) `+soldToken+` `+pledgeToken+` ${JSON.stringify(accountName2)} (read-decimal 'price) (read-decimal 'maxbuy) (read-decimal 'run-time) (read-decimal 'insurance-time) ${JSON.stringify(lpPoolName)} (read-decimal 'lp-pool-fee) (read-decimal 'wl-run-time) [`+newwl+`] (read-decimal 'wl-max-buy) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-crowdfund5.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer Sell Token to Crowdfunding Pool", soldToken+'.'+"TRANSFER", [accountName2, poolID, balance]), Pact.lang.mkCap("Transfer Fee", "Transfer Pool Creation Fee", "free.stake-token.TRANSFER", [accountName2, "STAKEFACTORYTREASURY3", 0.01])],
                sender: accountName2,
                gasLimit: 17000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "pool-balance": balance,
                    "amount-to-sell": amountToSell,
                    "amount-to-lp": amountToDex,
                    "price": price,
                    "run-time": runTime,
                    "insurance-time": insuranceTime,
                    "lp-pool-fee": lpPoolFee,
                    "wl-run-time": wlRunTime,
                    "wl-max-buy": wlMaxBuy,
                    "maxbuy": maxbuy
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-crowdfund5.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} ${JSON.stringify(description)} (read-decimal 'pool-balance) (read-decimal 'amount-to-sell) (read-decimal 'amount-to-lp) `+soldToken+` `+pledgeToken+` ${JSON.stringify(accountName2)} (read-decimal 'price) (read-decimal 'maxbuy) (read-decimal 'run-time) (read-decimal 'insurance-time) ${JSON.stringify(lpPoolName)} (read-decimal 'lp-pool-fee) (read-decimal 'wl-run-time) [`+newwl+`] (read-decimal 'wl-max-buy) )`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 17000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-crowdfund5.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer Sell Token to Crowdfunding Pool", soldToken+'.'+"TRANSFER", [accountName2, poolID, balance]), Pact.lang.mkCap("Transfer Fee", "Transfer Pool Creation Fee", "free.stake-token.TRANSFER", [accountName2, "STAKEFACTORYTREASURY3", 0.01])],
                        pactCode: xwalletcode,
                        envData: {
                            "pool-balance": balance,
                            "amount-to-sell": amountToSell,
                            "amount-to-lp": amountToDex,
                            "price": price,
                            "run-time": runTime,
                            "insurance-time": insuranceTime,
                            "lp-pool-fee": lpPoolFee,
                            "wl-run-time": wlRunTime,
                            "wl-max-buy": wlMaxBuy,
                            "maxbuy": maxbuy
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Gets ALL pool information from the blockchain crowdfunding pools
    async bindAllCrowdFundingPools({commit}) {
        if (state.debug) {
            console.log("Getting ALL crowdpools:");
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-crowdfund5.get-all-pools)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got All Crowdfunding Pools");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the platforms deposited fungiv2 tokens
                if (dataResult[0]['data'].length > 0) {
                    let platformYieldTokens = 0;
                    let platformDepositedTokens = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        platformYieldTokens += dataResult[0]['data'][j]['balance'];
                        platformDepositedTokens += dataResult[0]['data'][j]['tokens-locked'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL PLATFORM YIELD TOKENS");
                        console.log(platformYieldTokens);
                        console.log("GOT TOTAL PLATFORM DEPOSITED TOKENS");
                        console.log(platformDepositedTokens);
                    }
                    commit("addPlatformYieldTokens", platformYieldTokens);
                    commit("addPlatformDepositedTokens", platformDepositedTokens);
                }


                commit("setAllCrowdFundingPools", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllCrowdFundingPools: Could not get Crowdfunding Pool Information.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Gets a users crowdpool information
    async bindAllUserCrowdFundingPools({commit}, accountName) {
        if (state.debug) {
            console.log("Getting User Crowdpooling Information:");
            console.log(accountName);
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-crowdfund5.get-all-user-pools ${JSON.stringify(accountName)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got User CrowdPool Information");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the users deposited fungiv2 tokens
                if (dataResult[0]['data'].length > 0) {
                    let userTokens = 0;
                    let pendingRewards = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        userTokens += dataResult[0]['data'][j]['stake_balance'];
                        pendingRewards += dataResult[0]['data'][j]['stake_rewards'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL USER CROWDPOOL TOKENS DEPOSITED");
                        console.log(userTokens);
                        console.log("GOT TOTAL USER CROWDPOOL TOKEN PENDING YIELD");
                        console.log(pendingRewards);
                    }
                    commit("addPortfolioDepositedTokens", userTokens);
                    commit("addPortfolioYieldTokens", pendingRewards);

                }

                commit("setUserCrowdFundingPools", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllUserCrowdFundingPools: Could not get User CrowdPool Information");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Gets a crowdpool information
    async getCrowdFundingPool(context, payload) {
        if (state.debug) {
            console.log("GETTING CROWDFUNDING POOL:");
            console.log(payload.poolName);
        }

        const poolName = payload.poolName;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-crowdfund5.get-pool-info ${JSON.stringify(poolName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got crowdfund pool!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No crowdfund pool gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("CROWDFUND POOL DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Pledges into a crowdfunding pool
    async crowdFundPledge({commit}, payload) {
        if (state.debug) {
            console.log("Pledging in a crowdfunding pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const amount = payload.amount;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(amount);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-crowdfund5.create-pledge ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'pledge-amount) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-crowdfund5.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Pledge Tokens", "Transfer and pledge tokens in pool", module+'.'+"TRANSFER", [accountName2, poolID, amount])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "pledge-amount": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-crowdfund5.create-pledge ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'pledge-amount) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-crowdfund5.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Pledge Tokens", "Transfer and pledge tokens in pool", module+'.'+"TRANSFER", [accountName2, poolID, amount])],
                        pactCode: xwalletcode,
                        envData: {
                            "pledge-amount": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Claims rewards crowdfunding pool
    async crowdFundClaimRewards({commit}, payload) {
        if (state.debug) {
            console.log("Claiming rewards from a crowdfunding pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-crowdfund5.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-crowdfund5.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-crowdfund5.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-crowdfund5.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Withdraws pledge from crowdfunding pool
    async crowdFundWithdraw({commit}, payload) {
        if (state.debug) {
            console.log("Withdrawing pledge from a crowdfunding pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-crowdfund5.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-crowdfund5.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-crowdfund5.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-crowdfund5.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Claims LP from crowdfunding pool for pool owner
    async crowdFundClaimLP({commit}, payload) {
        if (state.debug) {
            console.log("Claiming LP from a crowdfunding pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-crowdfund5.claim-lp ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-crowdfund5.POOL_CREATOR_GUARD", [poolID])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-crowdfund5.claim-lp ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-crowdfund5.POOL_CREATOR_GUARD", [poolID])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Claims LP from expired crowdfunding pool for pool owner
    async crowdFundClaimExpired({commit}, payload) {
        if (state.debug) {
            console.log("Claiming LP from an expired crowdfunding pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-crowdfund5.claim-expired ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-crowdfund5.POOL_CREATOR_GUARD", [poolID])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-crowdfund5.claim-expired ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-crowdfund5.POOL_CREATOR_GUARD", [poolID])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Generates a Liquidity Pool out of a funded and completed crowdfunding pool
    async crowdFundGenerateLP({commit}, payload) {
        if (state.debug) {
            console.log("Generating LP from a funded crowdfunding pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = accountName2;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-crowdfund5.generate-pair ${JSON.stringify(poolID)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", [])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-crowdfund5.generate-pair ${JSON.stringify(poolID)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", [])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Adds a whitelist member to a pool
    async crowdFundAddWhitelist({commit}, payload) {
        if (state.debug) {
            console.log("Adding a member to a whitelist");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
            }


            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-crowdfund5.add-account-to-whitelist ${JSON.stringify(poolID)} ${JSON.stringify(account)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-crowdfund5.POOL_CREATOR_GUARD", [poolID])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "user": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-crowdfund5.add-account-to-whitelist ${JSON.stringify(poolID)} ${JSON.stringify(account)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-crowdfund5.POOL_CREATOR_GUARD", [poolID])],
                        pactCode: xwalletcode,
                        envData: {
                            "user": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    ////////////////////////////////////////////////////////////////
    ///// VESTING POOLS
    ////////////////////////////////////////////////////////////////

    //Creates a new factory-vesting-fungiv2 vesting pool
    async createFactoryVestingFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Creating new factory-vesting-fungiv2 vesting pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const poolName = payload.poolName;
            const poolDescription = payload.poolDescription;
            const balance = Number(payload.balance);
            const rewardToken = payload.rewardToken;
            const stakeToken = payload.stakeToken;
            const account = payload.accountName;
            let claimWaitSeconds = Number(payload.claimWaitSeconds);
            const rewardDuration = Number(payload.rewardDuration);
            const rewardAmount = Number(payload.rewardAmount);
            let withdrawDuration = Number(payload.withdrawDuration);
            const stakables = payload.stakables;


            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(poolName);
                console.log(balance);
                console.log(typeof(balance));
                console.log(rewardToken);
                console.log(stakeToken);
                console.log(account);
                console.log(claimWaitSeconds);
                console.log(typeof(claimWaitSeconds));
                console.log(rewardDuration);
                console.log(typeof(rewardDuration));
                console.log(rewardAmount);
                console.log(typeof(rewardAmount));
                console.log(withdrawDuration);
                console.log(typeof(withdrawDuration));
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-vesting-fungiv2.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} ${JSON.stringify(poolDescription)} (read-decimal 'pool-balance) `+rewardToken+` ${JSON.stringify(accountName2)} (read-decimal 'pool-balance) (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration) [${stakables}])`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-vesting-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [accountName2, poolID, balance])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "pool-balance": balance,
                    "claim-wait-seconds": claimWaitSeconds,
                    "reward-duration": rewardDuration,
                    "reward-amount": rewardAmount,
                    "withdraw-duration": withdrawDuration
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-vesting-fungiv2.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} ${JSON.stringify(poolDescription)} (read-decimal 'pool-balance) `+rewardToken+` ${JSON.stringify(accountName2)} (read-decimal 'pool-balance) (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration) [${stakables}])`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-vesting-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [accountName2, poolID, balance])],
                        pactCode: xwalletcode,
                        envData: {
                            "pool-balance": balance,
                            "claim-wait-seconds": claimWaitSeconds,
                            "reward-duration": rewardDuration,
                            "reward-amount": rewardAmount,
                            "withdraw-duration": withdrawDuration
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Claims rewards from a factory-vesting-fungiv2 vesting pool
    async claimRewardsFactoryVestingFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Claiming rewards from a factory-vesting-fungiv2 vesting pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(module);
            }


            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-vesting-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", [])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-vesting-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", [])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Gets a users pool information from the blockchain for factory-vesting-fungiv2
    async bindAllUserPoolsFactoryVestingFungiv2({commit}, accountName) {
        if (state.debug) {
            console.log("Getting User Vesting Pooling Information:");
            console.log(accountName);
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-vesting-fungiv2.get-user-pools ${JSON.stringify(accountName)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got User Vesting Pool Information");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the users deposited fungiv2 tokens
                if (dataResult[0]['data'].length > 0) {
                    let pendingRewards = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        pendingRewards += dataResult[0]['data'][j]['pending_rewards'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL USER TOKEN PENDING VESTING YIELD");
                        console.log(pendingRewards);
                    }
                    commit("addPortfolioYieldTokens", pendingRewards);

                }

                commit("setUserPoolsFactoryVestingFungiv2", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllUserPoolsFactoryVestingFungiv2: Could not get User Pool Information for factory-vesting-fungiv2.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Gets a vesting pool information
    async getVestingPool(context, payload) {
        if (state.debug) {
            console.log("GETTING VESTING POOL:");
            console.log(payload.poolName);
        }

        const poolName = payload.poolName;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-vesting-fungiv2.get-pool-info ${JSON.stringify(poolName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got vesting pool!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No vesting pool gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("VESTING POOL DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(dataResult[0].data);


        }else {
            return(0);
        }

    },

    //Gets a vesting pool stakes information
    async getVestingPoolStakes(context, payload) {
        if (state.debug) {
            console.log("GETTING VESTING POOL STAKES:");
            console.log(payload.poolName);
        }

        const poolName = payload.poolName;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-vesting-fungiv2.get-pool-stakes ${JSON.stringify(poolName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got vesting pool stakes!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No vesting pool stakes gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("VESTING POOL STAKES DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(dataResult[0].data);


        }else {
            return(0);
        }

    },

    //Gets a users vesting pool stakes information
    async getUserVestingPoolStakes(context, payload) {
        if (state.debug) {
            console.log("GETTING USERS VESTING POOL STAKES:");
            console.log(payload.poolName);
        }

        const poolName = payload.poolName;

        const accountName2 = localStorage.getItem("accountName");

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-vesting-fungiv2.get-user-stakes ${JSON.stringify(poolName)} ${JSON.stringify(accountName2)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got user vesting pool stakes!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No user vesting pool stakes gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("USER VESTING POOL STAKES DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(dataResult[0].data);


        }else {
            return(0);
        }

    },

    //Gets a users vesting pools information
    async getUserVestingPools() {
        if (state.debug) {
            console.log("GETTING USERS VESTING POOLS:");
        }
        const accountName2 = localStorage.getItem("accountName");

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-vesting-fungiv2.get-user-pools ${JSON.stringify(accountName2)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got user vesting pools!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No user vesting pools gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("USER VESTING POOLS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(dataResult[0].data);


        }else {
            return(0);
        }

    },

    //Gets a users vesting pools information
    async getUserCreatedVestingPools() {
        if (state.debug) {
            console.log("GETTING USERS VESTING POOLS:");
        }
        const accountName2 = localStorage.getItem("accountName");

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-vesting-fungiv2.get-user-created-pools ${JSON.stringify(accountName2)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got user created vesting pools!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No user created vesting pools gotten 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("USER CREATED VESTING POOLS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(dataResult[0].data);


        }else {
            return(0);
        }

    },

    //remove account from vesting pool
    async removeAccountVestingFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("removing account from vesting pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const accountToRemove = payload.account;
            const remove = payload.remove;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(module);
            }


            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-vesting-fungiv2.remove-account ${JSON.stringify(poolID)} ${JSON.stringify(accountToRemove)} ${remove} )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-vesting-fungiv2.POOL_CREATOR_GUARD", [poolID])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-vesting-fungiv2.remove-account ${JSON.stringify(poolID)} ${JSON.stringify(accountToRemove)} ${remove} )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-vesting-fungiv2.POOL_CREATOR_GUARD", [poolID])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //withdraws tokens from a vesting pool
    async withdrawAmountVestingFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("withdrawing tokens from vesting pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const amountToRemove = payload.amount;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(module);
            }


            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-vesting-fungiv2.withdraw-funds ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'amount) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-vesting-fungiv2.POOL_CREATOR_GUARD", [poolID])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                    "amount": amountToRemove
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-vesting-fungiv2.withdraw-funds ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'amount) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-vesting-fungiv2.POOL_CREATOR_GUARD", [poolID])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                            "amount": amountToRemove
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //deactivates vesting pool and withdraws all funds
    async emergencyDeactivateVestingFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("deactivating vesting pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(module);
            }


            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-vesting-fungiv2.deactivate-pool ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-vesting-fungiv2.POOL_CREATOR_GUARD", [poolID])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-vesting-fungiv2.deactivate-pool ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)})`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-vesting-fungiv2.POOL_CREATOR_GUARD", [poolID])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //add balance to a vesting pool
    async addBalanceVestingFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("adding tokens to vesting pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const amountToRemove = payload.amount;
            const rewardToken = payload.token;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(module);
            }


            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-vesting-fungiv2.add-balance-to-pool ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'amount) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [accountName2, poolID, Number(amountToRemove)])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                    "amount": amountToRemove
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-vesting-fungiv2.add-balance-to-pool ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'amount) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [accountName2, poolID, Number(amountToRemove)])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                            "amount": amountToRemove
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },


    ////////////////////////////////////////////////////////////////
    ///// FUNGIv2 / FUNGIv2 STAKING POOLS
    ////////////////////////////////////////////////////////////////

    //Creates a new factory-stake-fungiv2 staking pool (fungiv2/fungiv2)
    async createFactoryStakeFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Creating new factory-stake-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const poolName = payload.poolName;
            const balance = Number(payload.balance);
            const rewardToken = payload.rewardToken;
            const stakeToken = payload.stakeToken;
            const account = payload.accountName;
            const claimWaitSeconds = Number(payload.claimWaitSeconds);
            const rewardDuration = Number(payload.rewardDuration);
            const rewardAmount = Number(payload.rewardAmount);
            const withdrawDuration = Number(payload.withdrawDuration);

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(poolName);
                console.log(balance);
                console.log(typeof(balance));
                console.log(rewardToken);
                console.log(stakeToken);
                console.log(account);
                console.log(claimWaitSeconds);
                console.log(typeof(claimWaitSeconds));
                console.log(rewardDuration);
                console.log(typeof(rewardDuration));
                console.log(rewardAmount);
                console.log(typeof(rewardAmount));
                console.log(withdrawDuration);
                console.log(typeof(withdrawDuration));
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-fungiv2.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) `+rewardToken+` `+stakeToken+` ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [accountName2, poolID, balance])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "pool-balance": balance,
                    "claim-wait-seconds": claimWaitSeconds,
                    "reward-duration": rewardDuration,
                    "reward-amount": rewardAmount,
                    "withdraw-duration": withdrawDuration
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-fungiv2.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) `+rewardToken+` `+stakeToken+` ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration))`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [accountName2, poolID, balance])],
                        pactCode: xwalletcode,
                        envData: {
                            "pool-balance": balance,
                            "claim-wait-seconds": claimWaitSeconds,
                            "reward-duration": rewardDuration,
                            "reward-amount": rewardAmount,
                            "withdraw-duration": withdrawDuration
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Stakes into a factory-stake-fungiv2 staking pool (fungiv2/fungiv2)
    async stakeFactoryStakeFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Staking in factory-stake-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const amount = Number(payload.amount);
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(amount);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-fungiv2.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [accountName2, poolID, amount])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "stake-amount": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-fungiv2.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [accountName2, poolID, amount])],
                        pactCode: xwalletcode,
                        envData: {
                            "stake-amount": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Claims rewards from a factory-stake-fungiv2 staking pool (fungiv2/fungiv2)
    async claimRewardsFactoryStakeFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Claiming rewards from a factory-stake-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fungiv2.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fungiv2.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Claim stake + rewards from a factory-stake-fungiv2 staking pool (fungiv2/fungiv2)
    async withdrawFactoryStakeFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Withdrawing stake + rewards from a factory-stake-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fungiv2.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fungiv2.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Gets ALL pool information from the blockchain for factory-stake-fungiv2 (fungiv2/fungiv2)
    async bindAllPoolsFactoryStakeFungiv2({commit}) {
        if (state.debug) {
            console.log("Getting ALL factory-stake-fungiv2 pools:");
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-fungiv2.get-all-pools)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got All Factory Stake Fungiv2 Pools");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the platforms deposited fungiv2 tokens
                if (dataResult[0]['data'].length > 0) {
                    let platformYieldTokens = 0;
                    let platformDepositedTokens = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        platformYieldTokens += dataResult[0]['data'][j]['balance'];
                        platformDepositedTokens += dataResult[0]['data'][j]['tokens-locked'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL PLATFORM YIELD TOKENS");
                        console.log(platformYieldTokens);
                        console.log("GOT TOTAL PLATFORM DEPOSITED TOKENS");
                        console.log(platformDepositedTokens);
                    }
                    commit("addPlatformYieldTokens", platformYieldTokens);
                    commit("addPlatformDepositedTokens", platformDepositedTokens);
                }


                commit("setAllPoolsFactoryStakeFungiv2", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllPoolsFactoryStakeFungiv2: Could not get fungiv2 Pool Information.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Gets a users pool information from the blockchain for factory-stake-fungiv2 (fungiv2/fungiv2)
    async bindAllUserPoolsFactoryStakeFungiv2({commit}, accountName) {
        if (state.debug) {
            console.log("Getting User Pooling Information:");
            console.log(accountName);
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-fungiv2.get-all-user-pools ${JSON.stringify(accountName)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got User Pool Information");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the users deposited fungiv2 tokens
                if (dataResult[0]['data'].length > 0) {
                    let userTokens = 0;
                    let pendingRewards = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        userTokens += dataResult[0]['data'][j]['stake_balance'];
                        pendingRewards += dataResult[0]['data'][j]['stake_pending_rewards'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL USER TOKENS DEPOSITED");
                        console.log(userTokens);
                        console.log("GOT TOTAL USER TOKEN PENDING YIELD");
                        console.log(pendingRewards);
                    }
                    commit("addPortfolioDepositedTokens", userTokens);
                    commit("addPortfolioYieldTokens", pendingRewards);

                }

                commit("setUserPoolsFactoryStakeFungiv2", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error getAllUserPoolsFactoryStakeFungiv2: Could not get User Pool Information for factory-stake-fungiv2.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Adds more tokens into a factory-stake-fungiv2 staking pool (fungiv2/fungiv2)
    async stakeFactoryAddBalanceFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Adding balance to a factory-stake-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const amount = Number(payload.amount);
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(amount);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-fungiv2.add-balance-to-pool ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'add-amount) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Add Tokens", "Transfer tokens in pool", module+'.'+"TRANSFER", [accountName2, poolID, amount])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "add-amount": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-fungiv2.add-balance-to-pool ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'add-amount) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Add Tokens", "Transfer tokens in pool", module+'.'+"TRANSFER", [accountName2, poolID, amount])],
                        pactCode: xwalletcode,
                        envData: {
                            "add-amount": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },



    ////////////////////////////////////////////////////////////////
    //////FUNGIv2 / POLYFUNGIV1 STAKING POOLS
    ////////////////////////////////////////////////////////////////

    //Claim stake + rewards from a factory-stake-fungiv2-polyfungiv1 staking pool (fungiv2/polyfungiv1)
    async withdrawFactoryStakeFungiv2Polyfungiv1({commit}, payload) {
        if (state.debug) {
            console.log("Withdrawing stake + rewards from a factory-stake-fungiv2-polyfungiv1 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-fungiv2-polyfungiv1.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fungiv2-polyfungiv1.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-fungiv2-polyfungiv1.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fungiv2-polyfungiv1.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Claims rewards from a factory-stake-fungiv2-polyfungiv1 staking pool (fungiv2/poly-fungi-v1)
    async claimRewardsFactoryStakeFungiv2Polyfungiv1({commit}, payload) {
        if (state.debug) {
            console.log("Claiming rewards from a factory-stake-fungiv2-polyfungiv1 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-fungiv2-polyfungiv1.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fungiv2-polyfungiv1.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-fungiv2-polyfungiv1.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fungiv2-polyfungiv1.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Stakes into a factory-stake-fungiv2-polyfungiv1 staking pool (fungiv2/poly-fungi-v1)
    async stakeFactoryStakeFungiv2Polyfungiv1({commit}, payload) {
        if (state.debug) {
            console.log("Staking in factory-stake-fungiv2-polyfungiv1 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const amount = Number(payload.amount);
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(amount);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-fungiv2-polyfungiv1.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fungiv2-polyfungiv1.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [accountName2, poolID, amount])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "stake-amount": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-fungiv2-polyfungiv1.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fungiv2-polyfungiv1.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [accountName2, poolID, amount])],
                        pactCode: xwalletcode,
                        envData: {
                            "stake-amount": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Gets ALL pool information from the blockchain for factory-stake-fungiv2-polyfungiv1
    async bindAllPoolsFactoryStakeFungiv2Polyfungiv1({commit}) {
        if (state.debug) {
            console.log("Getting ALL factory-stake-fungiv2-polyfungiv1 pools:");
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-fungiv2-polyfungiv1.get-all-pools)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got All Factory Stake Fungiv2 / Polyfungiv1 Pools");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the platforms deposited fungiv2 tokens
                if (dataResult[0]['data'].length > 0) {
                    let platformYieldTokens = 0;
                    let platformDepositedTokens = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        platformYieldTokens += dataResult[0]['data'][j]['balance'];
                        platformDepositedTokens += dataResult[0]['data'][j]['tokens-locked'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL PLATFORM YIELD FUNGIV2 POLYFUNGIV1 NFTS");
                        console.log(platformYieldTokens);
                        console.log("GOT TOTAL PLATFORM DEPOSITED FUNGIV2 POLYFUNGIV1 TOKENS");
                        console.log(platformDepositedTokens);
                    }
                    commit("addPlatformYieldNFTs", platformYieldTokens);
                    commit("addPlatformDepositedNFTs", platformDepositedTokens);
                }


                commit("setAllPoolsFactoryStakeFungiv2Polyfungiv1", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllPoolsFactoryStakeFungiv2Polyfungiv1: Could not get fungiv2-polyfungiv1 pool Information.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Gets a users pool information from the blockchain for factory-stake-fungiv2-polyfungiv1
    async bindAllUserPoolsFactoryStakeFungiv2Polyfungiv1({commit}, accountName) {
        if (state.debug) {
            console.log("Getting User Fungiv2/Polyfungiv1 Pooling Information:");
            console.log(accountName);
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-fungiv2-polyfungiv1.get-all-user-pools ${JSON.stringify(accountName)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got User Fungiv2/Polyfungiv1 Pool Information");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the users deposited fungiv2/polyfungiv1 tokens
                if (dataResult[0]['data'].length > 0) {
                    let userTokens = 0;
                    let pendingRewards = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        userTokens += dataResult[0]['data'][j]['stake_balance'];
                        pendingRewards += dataResult[0]['data'][j]['stake_pending_rewards'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL USER FUNGIV2/POLYFUNGIV1 DEPOSITED FUNGIV2 TOKENS");
                        console.log(userTokens);
                        console.log("GOT TOTAL USER TOKEN PENDING FUNGIV2/POLYFUNGIV1 NFT YIELD");
                        console.log(pendingRewards);
                    }
                    //The way we load data is important
                    commit("addPortfolioDepositedNFTs", userTokens);
                    commit("addPortfolioYieldNFTs", pendingRewards);

                }

                commit("setUserPoolsFactoryStakeFungiv2Polyfungiv1", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllUserPoolsFactoryStakeFungiv2Polyfungiv1: Could not get User Pool Information for factory-stake-fungiv2-polyfungiv1.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Creates a new factory-stake-fungiv2-polyfungiv1 staking pool (fungible-v2/poly-fungible-v1)
    async createFactoryStakeFungiv2Polyfungiv1({commit}, payload) {
        if (state.debug) {
            console.log("Creating new factory-stake-fungiv2-polyfungiblev1 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const poolName = payload.poolName;
            const balance = Number(payload.balance);
            const rewardTokenId = payload.rewardTokenId;
            const rewardToken = payload.rewardToken;
            const stakeToken = payload.stakeToken;
            const account = payload.accountName;
            const claimWaitSeconds = Number(payload.claimWaitSeconds);
            const rewardDuration = Number(payload.rewardDuration);
            const rewardAmount = Number(payload.rewardAmount);
            const withdrawDuration = Number(payload.withdrawDuration);

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(poolName);
                console.log(balance);
                console.log(typeof(balance));
                console.log(rewardTokenId);
                console.log(typeof(rewardTokenId));
                console.log(rewardToken);
                console.log(stakeToken);
                console.log(account);
                console.log(claimWaitSeconds);
                console.log(typeof(claimWaitSeconds));
                console.log(rewardDuration);
                console.log(typeof(rewardDuration));
                console.log(rewardAmount);
                console.log(typeof(rewardAmount));
                console.log(withdrawDuration);
                console.log(typeof(withdrawDuration));
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-fungiv2-polyfungiv1.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) ${JSON.stringify(rewardTokenId)} `+rewardToken+` `+stakeToken+` ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fungiv2-polyfungiv1.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [rewardTokenId, accountName2, poolID, balance])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "pool-balance": balance,
                    "claim-wait-seconds": claimWaitSeconds,
                    "reward-duration": rewardDuration,
                    "reward-amount": rewardAmount,
                    "withdraw-duration": withdrawDuration
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-fungiv2-polyfungiv1.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) ${JSON.stringify(rewardTokenId)} `+rewardToken+` `+stakeToken+` ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration))`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fungiv2-polyfungiv1.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [rewardTokenId, accountName2, poolID, balance])],
                        pactCode: xwalletcode,
                        envData: {
                            "pool-balance": balance,
                            "claim-wait-seconds": claimWaitSeconds,
                            "reward-duration": rewardDuration,
                            "reward-amount": rewardAmount,
                            "withdraw-duration": withdrawDuration
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Adds more tokens into a factory-stake-fungiv2 staking pool (fungiv2/polyfungiv1)
    async stakeFactoryAddBalanceFungiv2Polyfungiv1({commit}, payload) {
        if (state.debug) {
            console.log("Adding balance to a factory-stake-fungiv2-polyfungiv1 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const amount = Number(payload.amount);
            const module = payload.moduleName;
            const nftID = payload.nftID;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(amount);
                console.log(module);
                console.log(nftID);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-fungiv2-polyfungiv1.add-balance-to-pool ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'add-amount) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Add Tokens", "Transfer tokens in pool", module+'.'+"TRANSFER", [nftID, accountName2, poolID, amount])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "add-amount": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-fungiv2-polyfungiv1.add-balance-to-pool ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'add-amount) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Add Tokens", "Transfer tokens in pool", module+'.'+"TRANSFER", [nftID, accountName2, poolID, amount])],
                        pactCode: xwalletcode,
                        envData: {
                            "add-amount": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    ////////////////////////////////////////////////////////////////
    //////FNF / FUNGIV2 STAKING POOLS
    ////////////////////////////////////////////////////////////////

    //Claim stake + rewards from a factory-stake-polyfungiv1-fungiv2 staking pool (fnf/fungiv2)
    async withdrawFactoryStakeFNFFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Withdrawing stake + rewards from a factory-stake-fnf-fungiv21 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;
            const nftID = payload.nftID;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
                console.log(nftID);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-fnf-fungiv21.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true ${JSON.stringify(nftID)} )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fnf-fungiv21.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-fnf-fungiv21.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true ${JSON.stringify(nftID)} )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fnf-fungiv21.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Claims rewards from a factory-stake-polyfungiv1-fungiv2 staking pool (fnf/fungiv2)
    async claimRewardsFactoryStakeFNFFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Claiming rewards from a factory-stake-fnf-fungiv21 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-fnf-fungiv21.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false "n" )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fnf-fungiv21.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-fnf-fungiv21.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false "n" )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fnf-fungiv21.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Stakes into a factory-stake-polyfungiv1-fungiv2 staking pool (fnf/fungiv2)
    async stakeFactoryStakeFNFFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Staking in factory-stake-fnf-fungiv21 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const amount = payload.amount;
            const module = payload.moduleName;
            const nftID = payload.nftID;

            const poolID2 = account+':'+poolID+':'+nftID;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(amount);
                console.log(module);
                console.log(nftID);
                console.log("POOLID2");
                console.log(poolID2);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-fnf-fungiv21.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) ${JSON.stringify(nftID)} )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fnf-fungiv21.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [nftID, accountName2, poolID2, amount])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "stake-amount": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-fnf-fungiv21.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) ${JSON.stringify(nftID)} )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fnf-fungiv21.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [nftID, accountName2, poolID2, amount])],
                        pactCode: xwalletcode,
                        envData: {
                            "stake-amount": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Gets ALL pool information from the blockchain for fnf-fungiv2
    async bindAllPoolsFactoryStakeFNFFungiv2({commit}) {
        if (state.debug) {
            console.log("Getting ALL factory-stake-fnf-fungiv21 pools:");
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-fnf-fungiv21.get-all-pools)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got All Factory Stake FNF / Fungiv2 Pools");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the platforms deposited fungiv2 tokens
                if (dataResult[0]['data'].length > 0) {
                    let platformYieldTokens = 0;
                    let platformDepositedTokens = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        platformYieldTokens += dataResult[0]['data'][j]['balance'];
                        platformDepositedTokens += dataResult[0]['data'][j]['tokens-locked'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL PLATFORM YIELD FNF-FUNGIV2 TOKENS");
                        console.log(platformYieldTokens);
                        console.log("GOT TOTAL PLATFORM DEPOSITED FNF FUNGIV2 TOKENS");
                        console.log(platformDepositedTokens);
                    }
                    commit("addPlatformYieldNFTs", platformYieldTokens);
                    commit("addPlatformDepositedNFTs", platformDepositedTokens);
                }

                commit("setAllPoolsFactoryStakeFNFFungiv2", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllPoolsFactoryStakeFNFFungiv2: Could not get fnf-fungiv2 pool Information.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Gets a users pool information from the blockchain for fnf-fungiv2
    async bindAllUserPoolsFactoryStakeFNFFungiv2({commit}, accountName) {
        if (state.debug) {
            console.log("Getting User fnf/Fungiv2 Pooling Information:");
            console.log(accountName);
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-fnf-fungiv21.get-all-user-pools ${JSON.stringify(accountName)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got User fnf/Fungiv2 Pool Information");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the users deposited polyfungiv1/fungiv2 tokens
                if (dataResult[0]['data'].length > 0) {
                    let userTokens = 0;
                    let pendingRewards = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        userTokens += dataResult[0]['data'][j]['stake_balance'];
                        pendingRewards += dataResult[0]['data'][j]['stake_pending_rewards'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL USER FNF/FUNGIV2 DEPOSITED FUNGIV2 TOKENS");
                        console.log(userTokens);
                        console.log("GOT TOTAL USER TOKEN PENDING FNF/FUNGIV2 NFT YIELD");
                        console.log(pendingRewards);
                    }
                    //The way we load data is important
                    commit("addPortfolioDepositedNFTs", userTokens);
                    commit("addPortfolioYieldNFTs", pendingRewards);

                }

                commit("setUserPoolsFactoryStakeFNFFungiv2", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllUserPoolsFactoryStakeFNFFungiv2: Could not get User Pool Information for factory-stake-fnf-fungiv21.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Creates a new factory-stake-polyfungiv1-fungiv2 staking pool (fnf/fungiv2)
    async createFactoryStakeFNFFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Creating new factory-stake-fnf-fungiv21 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const poolName = payload.poolName;
            const balance = payload.balance;
            const collectionID = payload.collectionid;
            const rewardToken = payload.rewardToken;
            const stakeToken = "free.factory-non-fungible9";
            const account = payload.accountName;
            const claimWaitSeconds = payload.claimWaitSeconds;
            const rewardDuration = payload.rewardDuration;
            const rewardAmount = payload.rewardAmount;
            const withdrawDuration = payload.withdrawDuration;

            console.log(payload);

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(poolName);
                console.log(balance);
                console.log(typeof(balance));
                console.log(collectionID);
                console.log(typeof(collectionID));
                console.log(rewardToken);
                console.log(stakeToken);
                console.log(account);
                console.log(claimWaitSeconds);
                console.log(typeof(claimWaitSeconds));
                console.log(rewardDuration);
                console.log(typeof(rewardDuration));
                console.log(rewardAmount);
                console.log(typeof(rewardAmount));
                console.log(withdrawDuration);
                console.log(typeof(withdrawDuration));
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-fnf-fungiv21.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) `+rewardToken+` ${JSON.stringify(collectionID)} `+stakeToken+` ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fnf-fungiv21.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [accountName2, poolID, Number(balance)])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "pool-balance": balance,
                    "claim-wait-seconds": claimWaitSeconds,
                    "reward-duration": rewardDuration,
                    "reward-amount": rewardAmount,
                    "withdraw-duration": withdrawDuration
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-fnf-fungiv21.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) `+rewardToken+` ${JSON.stringify(collectionID)} `+stakeToken+` ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration))`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-fnf-fungiv21.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [accountName2, poolID, Number(balance)])],
                        pactCode: xwalletcode,
                        envData: {
                            "pool-balance": balance,
                            "claim-wait-seconds": claimWaitSeconds,
                            "reward-duration": rewardDuration,
                            "reward-amount": rewardAmount,
                            "withdraw-duration": withdrawDuration
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Gets a users staked nfts from a pool
    async getUserStakedNFTs(context, payload) {
        if (state.debug) {
            console.log("GETTING USER STAKED NFTS FOR POOL:");
            console.log(payload.poolid)
        }

        const poolid = payload.poolid;
        const name = localStorage.getItem("accountName");

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-fnf-fungiv21.get-user-stakes ${JSON.stringify(poolid)} ${JSON.stringify(name)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got User Staked NFTs");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Staked NFTS got for user 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("USER STAKED NFTS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets a specific pools info for fnf-fungiv2 staking pools
    async getFNFFungiv2PoolInfo(context, payload) {
        if (state.debug) {
            console.log("GETTING INFO FOR FNF/FUNGIV2 STAKING POOL:");
            console.log(payload.poolid)
        }

        const poolid = payload.poolid;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-fnf-fungiv21.get-pool-info ${JSON.stringify(poolid)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got Pool Info");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("Didnt get pool Info 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("POOL INFO DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(dataResult[0].data);


        }else {
            return(0);
        }

    },

    ////////////////////////////////////////////////////////////////
    //////POLYFUNGIV2 / FUNGIV2 STAKING POOLS
    ////////////////////////////////////////////////////////////////

    //Claim stake + rewards from a factory-stake-polyfungiv2-fungiv2 staking pool (polyfungiv2/fungiv2)
    async withdrawFactoryStakePolyFungiv2Fungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Withdrawing stake + rewards from a factory-stake-polyfungiv2-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;
            const nftID = payload.nftID;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
                console.log(nftID);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-polyfungiv2-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true ${JSON.stringify(nftID)} )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv2-fungiv2.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-polyfungiv2-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true ${JSON.stringify(nftID)} )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv2-fungiv2.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Claims rewards from a factory-stake-polyfungiv2-fungiv2 staking pool (polyfungiv2/fungiv2)
    async claimRewardsFactoryStakePolyFungiv2Fungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Claiming rewards from a factory-stake-polyfungiv2-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-polyfungiv2-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false "n" )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv2-fungiv2.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-polyfungiv2-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false "n" )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv2-fungiv2.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Stakes into a factory-stake-polyfungiv2-fungiv2 staking pool (polyfungiv2/fungiv2)
    async stakeFactoryStakePolyFungiv2Fungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Staking in factory-stake-polyfungiv2-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const amount = payload.amount;
            const module = payload.moduleName;
            const nftID = payload.nftID;

            const poolID2 = account+':'+poolID+':'+nftID;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(amount);
                console.log(module);
                console.log(nftID);
                console.log("POOLID2");
                console.log(poolID2);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-polyfungiv2-fungiv2.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) ${JSON.stringify(nftID)} )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv2-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [nftID, accountName2, poolID2, amount])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "stake-amount": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-polyfungiv2-fungiv2.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) ${JSON.stringify(nftID)} )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv2-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [nftID, accountName2, poolID2, amount])],
                        pactCode: xwalletcode,
                        envData: {
                            "stake-amount": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Gets ALL pool information from the blockchain for polyfungiv2-fungiv2
    async bindAllPoolsFactoryStakePolyFungiv2Fungiv2({commit}) {
        if (state.debug) {
            console.log("Getting ALL factory-stake-polyfungiv2-fungiv2 pools:");
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-polyfungiv2-fungiv2.get-all-pools)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got All Factory Stake polyfungiv2 / Fungiv2 Pools");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the platforms deposited fungiv2 tokens
                if (dataResult[0]['data'].length > 0) {
                    let platformYieldTokens = 0;
                    let platformDepositedTokens = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        platformYieldTokens += dataResult[0]['data'][j]['balance'];
                        platformDepositedTokens += dataResult[0]['data'][j]['tokens-locked'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL PLATFORM YIELD polyfungiv2-FUNGIV2 TOKENS");
                        console.log(platformYieldTokens);
                        console.log("GOT TOTAL PLATFORM DEPOSITED polyfungiv2 FUNGIV2 TOKENS");
                        console.log(platformDepositedTokens);
                    }
                    commit("addPlatformYieldNFTs", platformYieldTokens);
                    commit("addPlatformDepositedNFTs", platformDepositedTokens);
                }

                commit("setAllPoolsFactoryStakepolyfungiv2Fungiv2", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllPoolsFactoryStakepolyfungiv2Fungiv2: Could not get polyfungiv2-fungiv2 pool Information.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Gets a users pool information from the blockchain for polyfungiv2-fungiv2
    async bindAllUserPoolsFactoryStakePolyFungiv2Fungiv2({commit}, accountName) {
        if (state.debug) {
            console.log("Getting User polyfungiv2/Fungiv2 Pooling Information:");
            console.log(accountName);
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-polyfungiv2-fungiv2.get-all-user-pools ${JSON.stringify(accountName)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got User polyfungiv2/Fungiv2 Pool Information");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the users deposited polyfungiv2/fungiv2 tokens
                if (dataResult[0]['data'].length > 0) {
                    let userTokens = 0;
                    let pendingRewards = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        userTokens += dataResult[0]['data'][j]['stake_balance'];
                        pendingRewards += dataResult[0]['data'][j]['stake_pending_rewards'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL USER polyfungiv2/FUNGIV2 DEPOSITED FUNGIV2 TOKENS");
                        console.log(userTokens);
                        console.log("GOT TOTAL USER TOKEN PENDING polyfungiv2/FUNGIV2 NFT YIELD");
                        console.log(pendingRewards);
                    }
                    //The way we load data is important
                    commit("addPortfolioDepositedNFTs", userTokens);
                    commit("addPortfolioYieldNFTs", pendingRewards);

                }

                commit("setUserPoolsFactoryStakepolyfungiv2Fungiv2", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllUserPoolsFactoryStakepolyfungiv2Fungiv2: Could not get User Pool Information for factory-stake-polyfungiv2-fungiv2.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Creates a new factory-stake-polyfungiv2-fungiv2 staking pool (polyfungiv2/fungiv2)
    async createFactoryStakePolyFungiv2Fungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Creating new factory-stake-polyfungiv2-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];




            //Arrange types
            const poolID = payload.poolID;
            const poolName = payload.poolName;
            const balance = payload.balance;
            const collectionID = payload.collectionid;
            const rewardToken = payload.rewardToken;
            const stakeToken = payload.stakeToken;
            const stakableNFTs = payload.stakableNFTs;
            const account = payload.accountName;
            const claimWaitSeconds = payload.claimWaitSeconds;
            const rewardDuration = payload.rewardDuration;
            const rewardAmount = payload.rewardAmount;
            const withdrawDuration = payload.withdrawDuration;
            const imageLink = payload.image;

            console.log(payload);

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(poolName);
                console.log(balance);
                console.log(typeof(balance));
                console.log(collectionID);
                console.log(typeof(collectionID));
                console.log(rewardToken);
                console.log(stakeToken);
                console.log(account);
                console.log(claimWaitSeconds);
                console.log(typeof(claimWaitSeconds));
                console.log(rewardDuration);
                console.log(typeof(rewardDuration));
                console.log(rewardAmount);
                console.log(typeof(rewardAmount));
                console.log(withdrawDuration);
                console.log(typeof(withdrawDuration));
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;

        //     (test.kadena-stake-polyfungiv1-fungiv2.create-pool
        //     "test-pool"
        //     "Test Pool"
        //     50.0
        //     coin
        //         ["test-NFT" "test-NFT1" "test-NFT2"]
        //     test.poly-fungible-reference
        //     "k:stuart"
        //     0.0
        //     86400.0
        //     10.0
        //     86400.0
        //     "myimagelink"
        // )

            const pactCode = `(free.factory-stake-polyfungiv2-fungiv2.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) `+rewardToken+` [`+stakableNFTs+`] `+stakeToken+` ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration) ${JSON.stringify(imageLink)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv2-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [accountName2, poolID, Number(balance)])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "pool-balance": balance,
                    "claim-wait-seconds": claimWaitSeconds,
                    "reward-duration": rewardDuration,
                    "reward-amount": rewardAmount,
                    "withdraw-duration": withdrawDuration
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-polyfungiv2-fungiv2.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) `+rewardToken+` [`+stakableNFTs+`] `+stakeToken+` ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration) ${JSON.stringify(imageLink)})`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv2-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [accountName2, poolID, Number(balance)])],
                        pactCode: xwalletcode,
                        envData: {
                            "pool-balance": balance,
                            "claim-wait-seconds": claimWaitSeconds,
                            "reward-duration": rewardDuration,
                            "reward-amount": rewardAmount,
                            "withdraw-duration": withdrawDuration
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Gets a users staked nfts from a pool
    async getUserStakedNFTsPolyFungiv2Fungiv2(context, payload) {
        if (state.debug) {
            console.log("GETTING USER STAKED NFTS FOR POOL:");
            console.log(payload.poolid)
        }

        const poolid = payload.poolid;
        const name = localStorage.getItem("accountName");

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-polyfungiv2-fungiv2.get-user-stakes ${JSON.stringify(poolid)} ${JSON.stringify(name)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got User Staked NFTs");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Staked NFTS got for user 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("USER STAKED NFTS DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            if(dataResult[0].data < 0){
                return(0);
            }else {
                return(dataResult[0].data);
            }


        }else {
            return(0);
        }

    },

    //Gets a specific pools info for polyfungiv2-fungiv2 staking pools
    async getpolyfungiv2Fungiv2PoolInfoPolyFungiv2Fungiv2(context, payload) {
        if (state.debug) {
            console.log("GETTING INFO FOR polyfungiv2/FUNGIV2 STAKING POOL:");
            console.log(payload.poolid)
        }

        const poolid = payload.poolid;

        let exists = false;
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-polyfungiv2-fungiv2.get-pool-info ${JSON.stringify(poolid)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got Pool Info");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("Didnt get pool Info 🤬");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }

        if(exists === true){

            if(state.debug){
                console.log("POOL INFO DATA:");
                console.log(dataResult[0].data);
                console.log(typeof(dataResult[0].data));
            }

            return(dataResult[0].data);


        }else {
            return(0);
        }

    },


    ////////////////////////////////////////////////////////////////
    //////POLYFUNGIV1 / FUNGIV2 STAKING POOLS
    ////////////////////////////////////////////////////////////////

    //Claim stake + rewards from a factory-stake-polyfungiv1-fungiv2 staking pool (polyfungiv1/fungiv2)
    async withdrawFactoryStakePolyfungiv1Fungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Withdrawing stake + rewards from a factory-stake-polyfungiv1-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-polyfungiv1-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv1-fungiv2.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-polyfungiv1-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv1-fungiv2.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Claims rewards from a factory-stake-polyfungiv1-fungiv2 staking pool (polyfungiv1/fungiv2)
    async claimRewardsFactoryStakePolyfungiv1Fungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Claiming rewards from a factory-stake-polyfungiv1-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-polyfungiv1-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv1-fungiv2.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-polyfungiv1-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv1-fungiv2.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Stakes into a factory-stake-polyfungiv1-fungiv2 staking pool (polyfungiv1/fungiv2)
    async stakeFactoryStakePolyfungiv1Fungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Staking in factory-stake-polyfungiv1-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const amount = payload.amount;
            const module = payload.moduleName;
            const nftID = payload.nftID;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(amount);
                console.log(module);
                console.log(nftID);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-polyfungiv1-fungiv2.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv1-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [nftID, accountName2, poolID, amount])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "stake-amount": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-polyfungiv1-fungiv2.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv1-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [nftID, accountName2, poolID, amount])],
                        pactCode: xwalletcode,
                        envData: {
                            "stake-amount": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Gets ALL pool information from the blockchain for factory-stake-polyfungiv1-fungiv2
    async bindAllPoolsFactoryStakePolyfungiv1Fungiv2({commit}) {
        if (state.debug) {
            console.log("Getting ALL factory-stake-polyfungiv1-fungiv2 pools:");
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-polyfungiv1-fungiv2.get-all-pools)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got All Factory Stake Polyfungiv1 / Fungiv2 Pools");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the platforms deposited fungiv2 tokens
                if (dataResult[0]['data'].length > 0) {
                    let platformYieldTokens = 0;
                    let platformDepositedTokens = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        platformYieldTokens += dataResult[0]['data'][j]['balance'];
                        platformDepositedTokens += dataResult[0]['data'][j]['tokens-locked'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL PLATFORM YIELD POLYFUNGIV1-FUNGIV2 TOKENS");
                        console.log(platformYieldTokens);
                        console.log("GOT TOTAL PLATFORM DEPOSITED POLYFUNGIV1 FUNGIV2 TOKENS");
                        console.log(platformDepositedTokens);
                    }
                    commit("addPlatformYieldNFTs", platformYieldTokens);
                    commit("addPlatformDepositedNFTs", platformDepositedTokens);
                }


                commit("setAllPoolsFactoryStakePolyfungiv1Fungiv2", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllPoolsFactoryStakePolyfungiv1Fungiv2: Could not get polyfungiv1-fungiv2 pool Information.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Gets a users pool information from the blockchain for factory-stake-polyfungiv1-fungiv2
    async bindAllUserPoolsFactoryStakePolyfungiv1Fungiv2({commit}, accountName) {
        if (state.debug) {
            console.log("Getting User Polyfungiv1/Fungiv2 Pooling Information:");
            console.log(accountName);
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-polyfungiv1-fungiv2.get-all-user-pools ${JSON.stringify(accountName)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got User Polyfungiv1/Fungiv2 Pool Information");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the users deposited polyfungiv1/fungiv2 tokens
                if (dataResult[0]['data'].length > 0) {
                    let userTokens = 0;
                    let pendingRewards = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        userTokens += dataResult[0]['data'][j]['stake_balance'];
                        pendingRewards += dataResult[0]['data'][j]['stake_pending_rewards'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL USER POLYFUNGIV1/FUNGIV2 DEPOSITED FUNGIV2 TOKENS");
                        console.log(userTokens);
                        console.log("GOT TOTAL USER TOKEN PENDING POLYFUNGIV1/FUNGIV2 NFT YIELD");
                        console.log(pendingRewards);
                    }
                    //The way we load data is important
                    commit("addPortfolioDepositedNFTs", userTokens);
                    commit("addPortfolioYieldNFTs", pendingRewards);

                }

                commit("setUserPoolsFactoryStakePolyfungiv1Fungiv2", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllUserPoolsFactoryStakePolyfungiv1Fungiv2: Could not get User Pool Information for factory-stake-polyfungiv1-fungiv2.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Creates a new factory-stake-polyfungiv1-fungiv2 staking pool (polyfungiv1/fungiv2)
    async createFactoryStakePolyfungiv1Fungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Creating new factory-stake-polyfungiblev1-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const poolName = payload.poolName;
            const balance = payload.balance;
            const stakeTokenId = payload.stakeTokenId;
            const rewardToken = payload.rewardToken;
            const stakeToken = payload.stakeToken;
            const account = payload.accountName;
            const claimWaitSeconds = payload.claimWaitSeconds;
            const rewardDuration = payload.rewardDuration;
            const rewardAmount = payload.rewardAmount;
            const withdrawDuration = payload.withdrawDuration;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(poolName);
                console.log(balance);
                console.log(typeof(balance));
                console.log(stakeTokenId);
                console.log(typeof(stakeTokenId));
                console.log(rewardToken);
                console.log(stakeToken);
                console.log(account);
                console.log(claimWaitSeconds);
                console.log(typeof(claimWaitSeconds));
                console.log(rewardDuration);
                console.log(typeof(rewardDuration));
                console.log(rewardAmount);
                console.log(typeof(rewardAmount));
                console.log(withdrawDuration);
                console.log(typeof(withdrawDuration));
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-polyfungiv1-fungiv2.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) `+rewardToken+` ${JSON.stringify(stakeTokenId)} `+stakeToken+` ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv1-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [accountName2, poolID, balance])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "pool-balance": balance,
                    "claim-wait-seconds": claimWaitSeconds,
                    "reward-duration": rewardDuration,
                    "reward-amount": rewardAmount,
                    "withdraw-duration": withdrawDuration
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-polyfungiv1-fungiv2.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) `+rewardToken+` ${JSON.stringify(stakeTokenId)} `+stakeToken+` ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration))`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv1-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [accountName2, poolID, balance])],
                        pactCode: xwalletcode,
                        envData: {
                            "pool-balance": balance,
                            "claim-wait-seconds": claimWaitSeconds,
                            "reward-duration": rewardDuration,
                            "reward-amount": rewardAmount,
                            "withdraw-duration": withdrawDuration
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },


    ////////////////////////////////////////////////////////////////
    //////POLYFUNGIV1 / POLYFUNGIV1 STAKING POOLS
    ////////////////////////////////////////////////////////////////

    //Claim stake + rewards from a factory-stake-polyfungiv1 staking pool (polyfungiv1/polyfungiv1)
    async withdrawFactoryStakePolyfungiv1({commit}, payload) {
        if (state.debug) {
            console.log("Withdrawing stake + rewards from a factory-stake-polyfungiv1 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-polyfungiv1.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv1.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-polyfungiv1.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv1.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Claims rewards from a factory-stake-polyfungiv1 staking pool (polyfungiv1/poly-fungi-v1)
    async claimRewardsFactoryStakePolyfungiv1({commit}, payload) {
        if (state.debug) {
            console.log("Claiming rewards from a factory-stake-polyfungiv1 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-polyfungiv1.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv1.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-polyfungiv1.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv1.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Stakes into a factory-stake-polyfungiv1 staking pool (polyfungiv1/poly-fungi-v1)
    async stakeFactoryStakePolyfungiv1({commit}, payload) {
        if (state.debug) {
            console.log("Staking in factory-stake-polyfungiv1 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const amount = Number(payload.amount);
            const module = payload.moduleName;
            const tokenID = payload.tokenID;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(amount);
                console.log(module);
                console.log(tokenID);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-polyfungiv1.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv1.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [tokenID, accountName2, poolID, amount])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "stake-amount": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-polyfungiv1.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv1.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [tokenID, accountName2, poolID, amount])],
                        pactCode: xwalletcode,
                        envData: {
                            "stake-amount": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Gets ALL pool information from the blockchain for factory-stake-polyfungiv1 (polyfungiv1/polyfungiv1)
    async bindAllPoolsFactoryStakePolyfungiv1({commit}) {
        if (state.debug) {
            console.log("Getting ALL factory-stake-polyfungiv1 pools:");
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-polyfungiv1.get-all-pools)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got All Factory Stake Polyfungiv1 / Polyfungiv1 Pools");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the platforms deposited polyfungiv1 tokens
                if (dataResult[0]['data'].length > 0) {
                    let platformYieldTokens = 0;
                    let platformDepositedTokens = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        platformYieldTokens += dataResult[0]['data'][j]['balance'];
                        platformDepositedTokens += dataResult[0]['data'][j]['tokens-locked'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL PLATFORM YIELD POLYFUNGIV1 NFTS");
                        console.log(platformYieldTokens);
                        console.log("GOT TOTAL PLATFORM DEPOSITED POLYFUNGIV1 POLYFUNGIV1 NFT TOKENS");
                        console.log(platformDepositedTokens);
                    }
                    commit("addPlatformYieldNFTs", platformYieldTokens);
                    commit("addPlatformDepositedNFTs", platformDepositedTokens);
                }


                commit("setAllPoolsFactoryStakePolyfungiv1", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllPoolsFactoryStakePolyfungiv1: Could not get polyfungiv1-polyfungiv1 pool Information.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Gets a users pool information from the blockchain for factory-stake-polyfungiv1
    async bindAllUserPoolsFactoryStakePolyfungiv1({commit}, accountName) {
        if (state.debug) {
            console.log("Getting User Polyfungiv1/Polyfungiv1 Pooling Information:");
            console.log(accountName);
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-polyfungiv1.get-all-user-pools ${JSON.stringify(accountName)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got User Polyfungiv1 Pool Information");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the users deposited polyfungiv1/polyfungiv1 tokens
                if (dataResult[0]['data'].length > 0) {
                    let userTokens = 0;
                    let pendingRewards = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        userTokens += dataResult[0]['data'][j]['stake_balance'];
                        pendingRewards += dataResult[0]['data'][j]['stake_pending_rewards'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL USER POLYFUNGIV1/POLYFUNGIV1 DEPOSITED NFT TOKENS");
                        console.log(userTokens);
                        console.log("GOT TOTAL USER PENDING POLYFUNGIV1/POLYFUNGIV1 NFT YIELD");
                        console.log(pendingRewards);
                    }
                    //The way we load data is important
                    commit("addPortfolioDepositedNFTs", userTokens);
                    commit("addPortfolioYieldNFTs", pendingRewards);

                }

                commit("setUserPoolsFactoryStakePolyfungiv1", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllUserPoolsFactoryStakePolyfungiv1: Could not get User Pool Information for factory-stake-polyfungiv1.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Creates a new factory-stake-polyfungiv1 staking pool (polyfungiv1/poly-fungible-v1)
    async createFactoryStakePolyfungiv1({commit}, payload) {
        if (state.debug) {
            console.log("Creating new factory-stake-polyfungiblev1 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const poolName = payload.poolName;
            const balance = Number(payload.balance);
            const rewardTokenId = payload.rewardTokenId;
            const rewardToken = payload.rewardToken;
            const stakeTokenId = payload.stakeTokenId;
            const stakeToken = payload.stakeToken;
            const account = payload.accountName;
            const claimWaitSeconds = Number(payload.claimWaitSeconds);
            const rewardDuration = Number(payload.rewardDuration);
            const rewardAmount = Number(payload.rewardAmount);
            const withdrawDuration = Number(payload.withdrawDuration);

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(poolName);
                console.log(balance);
                console.log(typeof(balance));
                console.log(rewardTokenId);
                console.log(typeof(rewardTokenId));
                console.log(rewardToken);
                console.log(stakeTokenId);
                console.log(stakeToken);
                console.log(account);
                console.log(claimWaitSeconds);
                console.log(typeof(claimWaitSeconds));
                console.log(rewardDuration);
                console.log(typeof(rewardDuration));
                console.log(rewardAmount);
                console.log(typeof(rewardAmount));
                console.log(withdrawDuration);
                console.log(typeof(withdrawDuration));
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-polyfungiv1.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) ${JSON.stringify(rewardTokenId)} `+rewardToken+` ${JSON.stringify(stakeTokenId)} `+stakeToken+` ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv1.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [rewardTokenId, accountName2, poolID, balance])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "pool-balance": balance,
                    "claim-wait-seconds": claimWaitSeconds,
                    "reward-duration": rewardDuration,
                    "reward-amount": rewardAmount,
                    "withdraw-duration": withdrawDuration
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-polyfungiv1.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) ${JSON.stringify(rewardTokenId)} `+rewardToken+` ${JSON.stringify(stakeTokenId)} `+stakeToken+` ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration))`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv1.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [rewardTokenId, accountName2, poolID, balance])],
                        pactCode: xwalletcode,
                        envData: {
                            "pool-balance": balance,
                            "claim-wait-seconds": claimWaitSeconds,
                            "reward-duration": rewardDuration,
                            "reward-amount": rewardAmount,
                            "withdraw-duration": withdrawDuration
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Adds more tokens into a factory-stake-polyfungiiv1 staking pool (polyfungiv1/polyfungiv1)
    async stakeFactoryAddBalancePolyfungiv1({commit}, payload) {
        if (state.debug) {
            console.log("Adding balance to a factory-stake-polyfungiv1 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const amount = Number(payload.amount);
            const module = payload.moduleName;
            const nftID = payload.nftID;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(amount);
                console.log(module);
                console.log(nftID);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-polyfungiv1.add-balance-to-pool ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'add-amount) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Add Tokens", "Transfer tokens in pool", module+'.'+"TRANSFER", [nftID, accountName2, poolID, amount])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "add-amount": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-polyfungiv1.add-balance-to-pool ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'add-amount) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Add Tokens", "Transfer tokens in pool", module+'.'+"TRANSFER", [nftID, accountName2, poolID, amount])],
                        pactCode: xwalletcode,
                        envData: {
                            "add-amount": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    ////////////////////////////////////////////////////////////////
    //////POLYFUNGIV2 / POLYFUNGIV2 STAKING POOLS
    ////////////////////////////////////////////////////////////////

    //Claim stake + rewards from a factory-stake-polyfungiv2 staking pool (polyfungiv2/polyfungiv2)
    async withdrawFactoryStakePolyfungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Withdrawing stake + rewards from a factory-stake-polyfungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-polyfungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv2.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-polyfungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv2.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Claims rewards from a factory-stake-polyfungiv2 staking pool (polyfungiv2/poly-fungi-v2)
    async claimRewardsFactoryStakePolyfungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Claiming rewards from a factory-stake-polyfungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-polyfungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv2.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-polyfungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv2.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Stakes into a factory-stake-polyfungiv2 staking pool (polyfungiv2/poly-fungi-v2)
    async stakeFactoryStakePolyfungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Staking in factory-stake-polyfungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const amount = payload.amount;
            const module = payload.moduleName;
            const tokenID = payload.tokenID;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(amount);
                console.log(module);
                console.log(tokenID);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-polyfungiv2.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [tokenID, accountName2, poolID, amount])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "stake-amount": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-polyfungiv2.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [tokenID, accountName2, poolID, amount])],
                        pactCode: xwalletcode,
                        envData: {
                            "stake-amount": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Gets ALL pool information from the blockchain for factory-stake-polyfungiv2 (polyfungiv2/polyfungiv2)
    async bindAllPoolsFactoryStakePolyfungiv2({commit}) {
        if (state.debug) {
            console.log("Getting ALL factory-stake-polyfungiv2 pools:");
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-polyfungiv2.get-all-pools)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got All Factory Stake Polyfungiv2 / Polyfungiv2 Pools");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the platforms deposited polyfungiv2 tokens
                if (dataResult[0]['data'].length > 0) {
                    let platformYieldTokens = 0;
                    let platformDepositedTokens = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        platformYieldTokens += dataResult[0]['data'][j]['balance'];
                        platformDepositedTokens += dataResult[0]['data'][j]['tokens-locked'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL PLATFORM YIELD POLYFUNGIV2 NFTS");
                        console.log(platformYieldTokens);
                        console.log("GOT TOTAL PLATFORM DEPOSITED POLYFUNGIV2 POLYFUNGIV2 NFT TOKENS");
                        console.log(platformDepositedTokens);
                    }
                    commit("addPlatformYieldNFTs", platformYieldTokens);
                    commit("addPlatformDepositedNFTs", platformDepositedTokens);
                }


                commit("setAllPoolsFactoryStakePolyfungiv2", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllPoolsFactoryStakePolyfungiv2: Could not get polyfungiv2-polyfungiv2 pool Information.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Gets a users pool information from the blockchain for factory-stake-polyfungiv2
    async bindAllUserPoolsFactoryStakePolyfungiv2({commit}, accountName) {
        if (state.debug) {
            console.log("Getting User Polyfungiv2/Polyfungiv2 Pooling Information:");
            console.log(accountName);
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-polyfungiv2.get-all-user-pools ${JSON.stringify(accountName)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got User Polyfungiv2 Pool Information");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the users deposited polyfungiv1/polyfungiv1 tokens
                if (dataResult[0]['data'].length > 0) {
                    let userTokens = 0;
                    let pendingRewards = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        userTokens += dataResult[0]['data'][j]['stake_balance'];
                        pendingRewards += dataResult[0]['data'][j]['stake_pending_rewards'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL USER POLYFUNGIV2/POLYFUNGIV2 DEPOSITED NFT TOKENS");
                        console.log(userTokens);
                        console.log("GOT TOTAL USER PENDING POLYFUNGIV2/POLYFUNGIV2 NFT YIELD");
                        console.log(pendingRewards);
                    }
                    //The way we load data is important
                    commit("addPortfolioDepositedNFTs", userTokens);
                    commit("addPortfolioYieldNFTs", pendingRewards);

                }

                commit("setUserPoolsFactoryStakePolyfungiv2", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllUserPoolsFactoryStakePolyfungiv2: Could not get User Pool Information for factory-stake-polyfungiv2.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Creates a new factory-stake-polyfungiv2 staking pool (poly-fungible-v2/poly-fungible-v2)
    async createFactoryStakePolyfungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Creating new factory-stake-polyfungiblev2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const poolName = payload.poolName;
            const balance = payload.balance;
            const rewardTokenId = payload.rewardTokenId;
            const rewardToken = payload.rewardToken;
            const stakeTokenId = payload.stakeTokenId;
            const stakeToken = payload.stakeToken;
            const account = payload.accountName;
            const claimWaitSeconds = payload.claimWaitSeconds;
            const rewardDuration = payload.rewardDuration;
            const rewardAmount = payload.rewardAmount;
            const withdrawDuration = payload.withdrawDuration;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(poolName);
                console.log(balance);
                console.log(typeof(balance));
                console.log(rewardTokenId);
                console.log(typeof(rewardTokenId));
                console.log(rewardToken);
                console.log(stakeTokenId);
                console.log(stakeToken);
                console.log(account);
                console.log(claimWaitSeconds);
                console.log(typeof(claimWaitSeconds));
                console.log(rewardDuration);
                console.log(typeof(rewardDuration));
                console.log(rewardAmount);
                console.log(typeof(rewardAmount));
                console.log(withdrawDuration);
                console.log(typeof(withdrawDuration));
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-polyfungiv2.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) ${JSON.stringify(rewardTokenId)} `+rewardToken+` ${JSON.stringify(stakeTokenId)} `+stakeToken+` ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [rewardTokenId, accountName2, poolID, balance])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "pool-balance": balance,
                    "claim-wait-seconds": claimWaitSeconds,
                    "reward-duration": rewardDuration,
                    "reward-amount": rewardAmount,
                    "withdraw-duration": withdrawDuration
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-polyfungiv2.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) ${JSON.stringify(rewardTokenId)} `+rewardToken+` ${JSON.stringify(stakeTokenId)} `+stakeToken+` ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration))`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-polyfungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [rewardTokenId, accountName2, poolID, balance])],
                        pactCode: xwalletcode,
                        envData: {
                            "pool-balance": balance,
                            "claim-wait-seconds": claimWaitSeconds,
                            "reward-duration": rewardDuration,
                            "reward-amount": rewardAmount,
                            "withdraw-duration": withdrawDuration
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    ///////////////////////////////////////////////////////////
    ////// ASWAP LP TOKENS / FUNGIv2
    ///////////////////////////////////////////////////////////

    //Gets ALL pool information from the blockchain for factory-stake-aswaptokens-fungiv2
    async bindAllPoolsFactoryStakeAswapTokensFungiv2({commit}) {
        if (state.debug) {
            console.log("Getting ALL factory-stake-aswaptokens-fungiv2 pools:");
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-tokens-fungiv2.get-all-pools)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got All Factory Stake AswapTokens Fungiv2 Pools");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the platform and portfolio deposits and yields
                if (dataResult[0]['data'].length > 0) {
                    let platformYieldTokens = 0;
                    let platformDepositedTokens = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        platformYieldTokens += dataResult[0]['data'][j]['balance'];
                        platformDepositedTokens += dataResult[0]['data'][j]['tokens-locked'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL PLATFORM YIELD TOKENS");
                        console.log(platformYieldTokens);
                        console.log("GOT TOTAL PLATFORM DEPOSITED LP TOKENS");
                        console.log(platformDepositedTokens);
                    }
                    commit("addPlatformYieldLPTokens", platformYieldTokens);
                    commit("addPlatformDepositedLPTokens", platformDepositedTokens);
                }


                commit("setAllPoolsFactoryStakeAswapTokensFungiv2", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllPoolsFactoryStakeAswapTokensFungiv2: Could not get aswaptokens fungiv2 Pool Information.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Gets a users pool information from the blockchain for factory-stake-aswaptokens-fungiv2
    async bindAllUserPoolsFactoryStakeAswapTokensFungiv2({commit}, accountName) {
        if (state.debug) {
            console.log("Getting User AswapTokens Fungiv2 Pooling Information:");
            console.log(accountName);
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-tokens-fungiv2.get-all-user-pools ${JSON.stringify(accountName)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got User Aswaptokens Fungiv2 Pool Information");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the users deposited fungiv2 tokens
                if (dataResult[0]['data'].length > 0) {
                    let userTokens = 0;
                    let pendingRewards = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        userTokens += dataResult[0]['data'][j]['stake_balance'];
                        pendingRewards += dataResult[0]['data'][j]['stake_pending_rewards'];
                    }
                    if(state.debug){
                        console.log("ASWAPTOKENS/FUNGIV2: GOT TOTAL USER LP TOKENS DEPOSITED");
                        console.log(userTokens);
                        console.log("ASWAPTOKENS/FUNGIV2: GOT TOTAL USER TOKENS PENDING YIELD");
                        console.log(pendingRewards);
                    }
                    commit("addPortfolioDepositedLPTokens", userTokens);
                    commit("addPortfolioYieldTokens", pendingRewards);

                }

                commit("setUserPoolsFactoryStakeAswapTokensFungiv2", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllUserPoolsFactoryStakeAswapTokensFungiv2: Could not get User Pool Information for factory-stake-tokens-fungiv2.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Creates a new factory-stake-tokens-fungiv2 staking pool (aswaptokens/fungible-v2)
    async createFactoryStakeAswapTokensFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Creating new factory-stake-tokens-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const poolName = payload.poolName;
            const balance = payload.balance;
            //const rewardTokenId = payload.rewardTokenId;
            const rewardToken = payload.rewardToken;
            const stakeToken1 = payload.stakeToken1;
            const stakeToken2 = payload.stakeToken2;
            const account = payload.accountName;
            const claimWaitSeconds = payload.claimWaitSeconds;
            const rewardDuration = payload.rewardDuration;
            const rewardAmount = payload.rewardAmount;
            const withdrawDuration = payload.withdrawDuration;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(poolName);
                console.log(balance);
                console.log(typeof(balance));
                //console.log(rewardTokenId);
                //console.log(typeof(rewardTokenId));
                console.log(rewardToken);
                console.log(stakeToken1);
                console.log(stakeToken2);
                console.log(account);
                console.log(claimWaitSeconds);
                console.log(typeof(claimWaitSeconds));
                console.log(rewardDuration);
                console.log(typeof(rewardDuration));
                console.log(rewardAmount);
                console.log(typeof(rewardAmount));
                console.log(withdrawDuration);
                console.log(typeof(withdrawDuration));
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-tokens-fungiv2.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) `+rewardToken+` `+stakeToken1+` `+stakeToken2+` ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-tokens-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [accountName2, poolID, balance])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "pool-balance": balance,
                    "claim-wait-seconds": claimWaitSeconds,
                    "reward-duration": rewardDuration,
                    "reward-amount": rewardAmount,
                    "withdraw-duration": withdrawDuration
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-tokens-fungiv2.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) `+rewardToken+` `+stakeToken1+` `+stakeToken2+` ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration))`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-tokens-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [accountName2, poolID, balance])],
                        pactCode: xwalletcode,
                        envData: {
                            "pool-balance": balance,
                            "claim-wait-seconds": claimWaitSeconds,
                            "reward-duration": rewardDuration,
                            "reward-amount": rewardAmount,
                            "withdraw-duration": withdrawDuration
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Stakes into a factory-stake-tokens-fungiv2 staking pool (aswaptokens/fungible-v2)
    async stakeFactoryStakeAswapTokensFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Staking in factory-stake-tokens-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const amount = payload.amount;
            const module = payload.moduleName;
            const stakeToken = payload.stakeToken;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(amount);
                console.log(module);
                console.log(stakeToken);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-tokens-fungiv2.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-tokens-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [stakeToken, accountName2, poolID, amount])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "stake-amount": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-tokens-fungiv2.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-tokens-fungiv2.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [stakeToken, accountName2, poolID, amount])],
                        pactCode: xwalletcode,
                        envData: {
                            "stake-amount": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Claim stake + rewards from a factory-stake-fungiv2 staking pool (aswaptokens/fungible-v2)
    async withdrawFactoryStakeAswapTokensFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Withdrawing stake + rewards from a factory-stake-tokens-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-tokens-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-tokens-fungiv2.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-tokens-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-tokens-fungiv2.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Claims rewards from a factory-stake-fungiv2 staking pool (aswaptokens/fungible-v2)
    async claimRewardsFactoryStakeAswapTokensFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Claiming rewards from a factory-stake-tokens-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-tokens-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-tokens-fungiv2.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-tokens-fungiv2.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-tokens-fungiv2.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },


    ///////////////////////////////////////////////////////////
    ////// FACTORY LP TOKENS / FUNGIv2
    ///////////////////////////////////////////////////////////

    //Gets ALL pool information from the blockchain for factory-stake-factorytokens-fungiv2
    async bindAllPoolsFactoryStakeFactoryTokensFungiv2({commit}) {
        if (state.debug) {
            console.log("Getting ALL factory-stake-factorytokens-fungiv2 pools:");
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-factorytokens-fungiv23.get-all-pools)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got All Factory Stake FactoryTokens Fungiv2 Pools");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the platform and portfolio deposits and yields
                if (dataResult[0]['data'].length > 0) {
                    let platformYieldTokens = 0;
                    let platformDepositedTokens = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        platformYieldTokens += dataResult[0]['data'][j]['balance'];
                        platformDepositedTokens += dataResult[0]['data'][j]['tokens-locked'];
                    }
                    if(state.debug){
                        console.log("GOT TOTAL PLATFORM YIELD TOKENS");
                        console.log(platformYieldTokens);
                        console.log("GOT TOTAL PLATFORM DEPOSITED LP TOKENS");
                        console.log(platformDepositedTokens);
                    }
                    commit("addPlatformYieldLPTokens", platformYieldTokens);
                    commit("addPlatformDepositedLPTokens", platformDepositedTokens);
                }


                commit("setAllPoolsFactoryStakeFactoryTokensFungiv2", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllPoolsFactoryStakeFactoryTokensFungiv2: Could not get factorytokens fungiv2 Pool Information.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Gets a users pool information from the blockchain for factory-stake-factorytokens-fungiv2
    async bindAllUserPoolsFactoryStakeFactoryTokensFungiv2({commit}, accountName) {
        if (state.debug) {
            console.log("Getting User FactoryTokens Fungiv2 Pooling Information:");
            console.log(accountName);
        }
        let dataResult = null;
        try {
            const t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.factory-stake-factorytokens-fungiv23.get-all-user-pools ${JSON.stringify(accountName)} )`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 1000000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got User Factorytokens Fungiv2 Pool Information");
                    console.log(data.result.data);
                    console.log(dataResult[0].data);
                }

                //Now we need to quickly count the users deposited fungiv2 tokens
                if (dataResult[0]['data'].length > 0) {
                    let userTokens = 0;
                    let pendingRewards = 0;
                    for (let j = dataResult[0]['data'].length - 1; j >= 0; j--) {
                        userTokens += dataResult[0]['data'][j]['stake_balance'];
                        pendingRewards += dataResult[0]['data'][j]['stake_pending_rewards'];
                    }
                    if(state.debug){
                        console.log("FACTORYTOKENS/FUNGIV2: GOT TOTAL USER LP TOKENS DEPOSITED");
                        console.log(userTokens);
                        console.log("FACTORYTOKENS/FUNGIV2: GOT TOTAL USER TOKENS PENDING YIELD");
                        console.log(pendingRewards);
                    }
                    commit("addPortfolioDepositedLPTokens", userTokens);
                    commit("addPortfolioYieldTokens", pendingRewards);

                }

                commit("setUserPoolsFactoryStakeFactoryTokensFungiv2", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error bindAllUserPoolsFactoryStakeFactoryTokensFungiv2: Could not get User Pool Information for factory-stake-factorytokens-fungiv2.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Creates a new factory-stake-factorytokens-fungiv2 staking pool (factorytokens/fungible-v2)
    async createFactoryStakeFactoryTokensFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Creating new factory-stake-factorytokens-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const poolName = payload.poolName;
            const sf_poolName = payload.sf_poolName;
            const balance = Number(payload.balance);
            //const rewardTokenId = payload.rewardTokenId;
            const rewardToken = payload.rewardToken;
            const stakeToken1 = payload.stakeToken1;
            const stakeToken2 = payload.stakeToken2;
            const account = payload.accountName;
            const claimWaitSeconds = Number(payload.claimWaitSeconds);
            const rewardDuration = Number(payload.rewardDuration);
            const rewardAmount = Number(payload.rewardAmount);
            const withdrawDuration = Number(payload.withdrawDuration);

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(poolName);
                console.log(sf_poolName);
                console.log(balance);
                console.log(typeof(balance));
                //console.log(rewardTokenId);
                //console.log(typeof(rewardTokenId));
                console.log(rewardToken);
                console.log(stakeToken1);
                console.log(stakeToken2);
                console.log(account);
                console.log(claimWaitSeconds);
                console.log(typeof(claimWaitSeconds));
                console.log(rewardDuration);
                console.log(typeof(rewardDuration));
                console.log(rewardAmount);
                console.log(typeof(rewardAmount));
                console.log(withdrawDuration);
                console.log(typeof(withdrawDuration));
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-factorytokens-fungiv23.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) `+rewardToken+` `+stakeToken1+` `+stakeToken2+` ${JSON.stringify(sf_poolName)} ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-factorytokens-fungiv23.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [accountName2, poolID, balance])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "pool-balance": balance,
                    "claim-wait-seconds": claimWaitSeconds,
                    "reward-duration": rewardDuration,
                    "reward-amount": rewardAmount,
                    "withdraw-duration": withdrawDuration
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-factorytokens-fungiv23.create-pool ${JSON.stringify(poolID)} ${JSON.stringify(poolName)} (read-decimal 'pool-balance) `+rewardToken+` `+stakeToken1+` `+stakeToken2+` ${JSON.stringify(sf_poolName)} ${JSON.stringify(accountName2)} (read-decimal 'claim-wait-seconds) (read-decimal 'reward-duration) (read-decimal 'reward-amount) (read-decimal 'withdraw-duration))`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-factorytokens-fungiv23.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Transfer Pool Balance", "Transfer pool reward-token balance to pool", rewardToken+'.'+"TRANSFER", [accountName2, poolID, balance])],
                        pactCode: xwalletcode,
                        envData: {
                            "pool-balance": balance,
                            "claim-wait-seconds": claimWaitSeconds,
                            "reward-duration": rewardDuration,
                            "reward-amount": rewardAmount,
                            "withdraw-duration": withdrawDuration
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Stakes into a factory-stake-factorytokens-fungiv2 staking pool (factorytokens/fungible-v2)
    async stakeFactoryStakeFactoryTokensFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Staking in factory-stake-factorytokens-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const amount = payload.amount;
            const module = payload.moduleName;
            const stakeToken = payload.stakeToken;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(amount);
                console.log(module);
                console.log(stakeToken);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-factorytokens-fungiv23.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-factorytokens-fungiv23.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [stakeToken, accountName2, poolID, amount])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "stake-amount": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-factorytokens-fungiv23.create-stake ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'stake-amount) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-factorytokens-fungiv23.ACCOUNT_GUARD", [accountName2]), Pact.lang.mkCap("Stake Tokens", "Transfer and stake tokens in pool", module+'.'+"TRANSFER", [stakeToken, accountName2, poolID, amount])],
                        pactCode: xwalletcode,
                        envData: {
                            "stake-amount": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Claim stake + rewards from a factory-stake-factorytokens-fungiv2 staking pool (factorytokens/fungible-v2)
    async withdrawFactoryStakeFactoryTokensFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Withdrawing stake + rewards from a factory-stake-factorytokens-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-factorytokens-fungiv23.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-factorytokens-fungiv23.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-factorytokens-fungiv23.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} true )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-factorytokens-fungiv23.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Claims rewards from a factory-stake-factorytokens-fungiv2 staking pool (factorytokens/fungible-v2)
    async claimRewardsFactoryStakeFactoryTokensFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Claiming rewards from a factory-stake-factorytokens-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-factorytokens-fungiv23.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-factorytokens-fungiv23.ACCOUNT_GUARD", [accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "my-account": accountName2,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-factorytokens-fungiv23.claim ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} false )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Prove Account Guard", "Prove account ownership", "free.factory-stake-factorytokens-fungiv23.ACCOUNT_GUARD", [accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "my-account": accountName2,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Adds more tokens into a factory-stake-factorytokens-fungiv2 staking pool (factorytokens/fungiv2)
    async stakeFactorytokensAddBalanceFungiv2({commit}, payload) {
        if (state.debug) {
            console.log("Adding balance to a factory-stake-factorytokens-fungiv2 staking pool");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const poolID = payload.poolID;
            const account = payload.accountName;
            const amount = Number(payload.amount);
            const module = payload.moduleName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(poolID);
                console.log(account);
                console.log(amount);
                console.log(module);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-stake-factorytokens-fungiv23.add-balance-to-pool ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'add-amount) )`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Add Tokens", "Transfer tokens in pool", module+'.'+"TRANSFER", [accountName2, poolID, amount])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "add-amount": amount,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-stake-factorytokens-fungiv23.add-balance-to-pool ${JSON.stringify(poolID)} ${JSON.stringify(accountName2)} (read-decimal 'add-amount) )`;
                const XWalletRequest = {
                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Add Tokens", "Transfer tokens in pool", module+'.'+"TRANSFER", [accountName2, poolID, amount])],
                        pactCode: xwalletcode,
                        envData: {
                            "add-amount": amount,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    /////////////////////
    //MAIN BINDINGS
    /////////////////////

    async clearCurrentRewardBindings({commit}){
        const rewards = 0;
        state.userStakeBalance = 0;
        commit("setPortfolioDepositedLPTokens", rewards);
        commit("setPortfolioYieldLPTokens", rewards);
        commit("setPlatformYieldLPTokens", rewards);
        commit("setPlatformDepositedLPTokens", rewards);

        commit("setPortfolioDepositedTokens", rewards);
        commit("setPortfolioYieldTokens", rewards);
        commit("setPlatformYieldTokens", rewards);
        commit("setPlatformDepositedTokens", rewards);

        commit("setPortfolioDepositedNFTs", rewards);
        commit("setPortfolioYieldNFTs", rewards);
        commit("setPlatformYieldNFTs", rewards);
        commit("setPlatformDepositedNFTs", rewards);


    },

    async getCurrentBindings({commit}, payload){

        const name = payload.name;

        await this.dispatch("accounts/clearCurrentRewardBindings");

        const tub_payloadA = {
            tokenA: "free.stake-token"
        };

        state.userStakeBalance = await this.dispatch("accounts/getTokenUserBalance", tub_payloadA);

        //Fungiv2 Fungiv2 get
        //Get all pools from factory-stake-fungiv2
        await this.dispatch("accounts/bindAllPoolsFactoryStakeFungiv2");
        //Get user pools from factory-stake-fungiv2
        await this.dispatch("accounts/bindAllUserPoolsFactoryStakeFungiv2", name);

        // //Fungiv2 polyfungiv1 get
        // await this.dispatch("accounts/bindAllPoolsFactoryStakeFungiv2Polyfungiv1");
        // //Get user pools from factory-stake-fungiv2-polyfungiv1
        // await this.dispatch("accounts/bindAllUserPoolsFactoryStakeFungiv2Polyfungiv1", name);

        //FNF Fungiv2 get
        await this.dispatch("accounts/bindAllPoolsFactoryStakeFNFFungiv2");
        //Get user pools from factory-stake-fnf-fungiv21
        await this.dispatch("accounts/bindAllUserPoolsFactoryStakeFNFFungiv2", name);

        // //Polyfungiv1 Fungiv2 get
        // await this.dispatch("accounts/bindAllPoolsFactoryStakePolyfungiv1Fungiv2");
        // //Get user pools from factory-stake-fungiv2-polyfungiv1
        // await this.dispatch("accounts/bindAllUserPoolsFactoryStakePolyfungiv1Fungiv2", name);
        //
        // //Polyfungiv1 Polyfungiv1 get
        // //Get all pools from factory-stake-polyfungiv1
        // await this.dispatch("accounts/bindAllPoolsFactoryStakePolyfungiv1");
        // //Get user pools from factory-stake-polyfungiv1
        // await this.dispatch("accounts/bindAllUserPoolsFactoryStakePolyfungiv1", name);

        if(state.doNFTS === true){

            //Polyfungiv2 Polyfungiv2 get
            //Get all pools from factory-stake-polyfungiv2
            await this.dispatch("accounts/bindAllPoolsFactoryStakePolyfungiv2");
            //Get user pools from factory-stake-polyfungiv1
            await this.dispatch("accounts/bindAllUserPoolsFactoryStakePolyfungiv2", name);

            //ASWAP LP Tokens Fungiv2 get
            //Get all pools from factory-stake-aswaptokens-fungiv2
            await this.dispatch("accounts/bindAllPoolsFactoryStakeAswapTokensFungiv2");
            //Get user pools from factory-stake-aswaptokens-fungiv2
            await this.dispatch("accounts/bindAllUserPoolsFactoryStakeAswapTokensFungiv2", name);

        }


        //FACTORY LP Tokens Fungiv2 get
        //Get all pools from factory-stake-factorytokens-fungiv2
        await this.dispatch("accounts/bindAllPoolsFactoryStakeFactoryTokensFungiv2");
        //Get user pools from factory-stake-factorytokens-fungiv2
        await this.dispatch("accounts/bindAllUserPoolsFactoryStakeFactoryTokensFungiv2", name);

        //Liquidity Pool get
        //Get all liqudity pools
        await this.dispatch("accounts/bindAllLiquidityPools");

        //Crowdfund Pool get
        //Get all crowdfunding pools
        await this.dispatch("accounts/bindAllCrowdFundingPools");
        //Get user crowdfunding pools
        await this.dispatch("accounts/bindAllUserCrowdFundingPools", name);

        await commit("setGotBindings", true);
    },

    ///////////////////////////
    ///////////TEST FUNCTIONS
    //////////////////////////



    async mintTestLpTokens({commit}, payload) {
        if (state.debug) {
            console.log("Minting Test LP Tokens");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const tokenName = payload.tokenName;
            const amount = payload.amount;
            const account = payload.accountName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(tokenName);
                console.log(amount);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.tokens-reference.mint ${JSON.stringify(tokenName)} ${JSON.stringify(accountName2)} (read-keyset "user-ks") (read-decimal 'amount))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Mint", "Mint Test LP Token", "free.tokens-reference.MINT", [tokenName, accountName2, amount])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "amount": amount,
                    "user-ks": accountGuard
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.tokens-reference.mint ${JSON.stringify(tokenName)} ${JSON.stringify(accountName2)} (read-keyset "user-ks") (read-decimal 'amount))`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Mint", "Mint Test LP Token", "free.tokens-reference.MINT", [tokenName, accountName2, amount])],
                        pactCode: xwalletcode,
                        envData: {
                            "amount": amount,
                            "user-ks": accountGuard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    async mintTestPolyFungiv1Tokens({commit}, payload) {
        if (state.debug) {
            console.log("Minting Test Poly-Fungible-V1 Tokens");
        }
        try {

            //User information
            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];

            //Arrange types
            const tokenName = payload.tokenName;
            const amount = payload.amount;
            const account = payload.accountName;

            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
                console.log(tokenName);
                console.log(amount);
            }

            if (accountName2 !== account) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + account);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.factory-poly-fungible-reference.mint ${JSON.stringify(tokenName)} ${JSON.stringify(accountName2)} (read-keyset "user-ks") (read-decimal 'amount))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Mint", "Mint Test LP Token", "free.factory-poly-fungible-reference.MINT", [tokenName, accountName2, amount])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "amount": amount,
                    "user-ks": accountGuard
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            state.currentSignRequest = pactCode;


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.factory-poly-fungible-reference.mint ${JSON.stringify(tokenName)} ${JSON.stringify(accountName2)} (read-keyset "user-ks") (read-decimal 'amount))`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Pay Transaction Gas Fee", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Mint", "Mint Test LP Token", "free.factory-poly-fungible-reference.MINT", [tokenName, accountName2, amount])],
                        pactCode: xwalletcode,
                        envData: {
                            "amount": amount,
                            "user-ks": accountGuard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                state.currentSignRequest = xwalletcode;

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else {
                if(state.debug){
                    console.log("CHAINWEAVER/ZELCORE WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

};


const mutations = {
    //Mutations are used to alter our store's state from outside of our store
    //These all SET our stores variables from outside of our store such as from the homepage or navbar

    setActiveAccount(state, selectedAddress) {
        state.activeAccount = selectedAddress;
    },
    setGotBindings(state, got) {
        state.gotBindings = got;
    },
    setAccountExists(state, exists) {
        state.accountExists = exists;
    },
    setPoolIdExists(state, exists){
        state.poolIdExists = exists;
    },
    setPoolIdExists1(state, exists){
        state.poolIdExists1 = exists;
    },
    setPoolIdExists2(state, exists){
        state.poolIdExists2 = exists;
    },
    setShowNavBar(state, data) {
        state.showNavBar = data;
    },
    setAccountData(state, data) {
        state.accountData = data;
    },
    setAccountKeys(state, keys) {
        state.accountKeys = keys;
    },
    setAccountPredicate(state, pred) {
        state.accountPredicate = pred;
    },
    setAccountConfirmed(state, confirmed) {
        state.accountConfirmed = confirmed;
    },
    setAccountName(state, name) {
        state.accountName = name;
    },
    setStoreLoaded(state, isloaded) {
        state.loaded = isloaded;
    },
    setTransactionPolling(state, polling) {
        state.transactionPolling = polling;
    },
    setTransactionConfirmed(state, confirmed) {
        state.transactionConfirmed = confirmed;
    },
    setTransactionFailed(state, failed) {
        state.transactionFailed = failed;
    },
    setTransactionRejected(state, failed) {
        state.transactionRejected = failed;
    },
    setTransactionHash(state, hash) {
        state.transactionHash = hash;
    },
    setTransactionConfirmedResult(state, result) {
        state.transactionConfirmedResult = result;
    },
    setXWalletConnected(state, isconnected) {
        state.xwalletconnected = isconnected;
    },
    setUserRequests(state, requests) {
        state.userRequests = requests;
    },
    setRequestPending(state, pending) {
        state.requestPending = pending;
    },
    setUserPoolsFactoryStakeFungiv2(state, pools){
        state.userPoolsFactoryStakeFungiv2 = pools;
    },
    setUserPoolsFactoryVestingFungiv2(state, pools){
        state.userPoolsFactoryVestingFungiv2 = pools;
    },
    setUserCrowdFundingPools(state, pools){
        state.userCrowdFundingPools = pools;
    },
    setUserNFTs(state, nfts){
        state.userNFTs = nfts;
    },
    setMarketNFTs(state, nfts){
        state.MarketNFTs = nfts;
    },
    setAllPoolsFactoryStakeFungiv2(state, pools){
        state.allPoolsFactoryStakeFungiv2 = pools;
    },
    setAllCrowdFundingPools(state, pools){
        state.allCrowdFundingPools = pools;
    },
    setAllPoolsFactoryStakeAswapTokensFungiv2(state, pools){
        state.allPoolsFactoryStakeAswapTokensFungiv2 = pools;
    },
    setUserPoolsFactoryStakeAswapTokensFungiv2(state, pools){
        state.userPoolsFactoryStakeAswapTokensFungiv2 = pools;
    },
    setAllPoolsFactoryStakepolyfungiv2Fungiv2(state, pools){
        state.allPoolsFactoryStakePolyfungiv2Fungiv2 = pools;
    },
    setUserPoolsFactoryStakePolyfungiv2Fungiv2(state, pools){
        state.userPoolsFactoryStakePolyfungiv2Fungiv2 = pools;
    },
    setAllPoolsFactoryStakeFactoryTokensFungiv2(state, pools){
        state.allPoolsFactoryStakeFactoryTokensFungiv2 = pools;
    },
    setUserPoolsFactoryStakeFactoryTokensFungiv2(state, pools){
        state.userPoolsFactoryStakeFactoryTokensFungiv2 = pools;
    },
    setAllPoolsFactoryStakeKDSwapTokensFungiv2(state, pools){
        state.allPoolsFactoryStakeKDSwapTokensFungiv2 = pools;
    },
    setUserPoolsFactoryStakeKDSwapTokensFungiv2(state, pools){
        state.userPoolsFactoryStakeKDSwapTokensFungiv2 = pools;
    },
    setAllPoolsFactoryStakeKaddexTokensFungiv2(state, pools){
        state.allPoolsFactoryStakeKaddexTokensFungiv2 = pools;
    },
    setUserPoolsFactoryStakeKaddexTokensFungiv2(state, pools){
        state.userPoolsFactoryStakeKaddexTokensFungiv2 = pools;
    },
    setPortfolioDepositedTokens(state, tokens){
        state.portfolioDepositedTokens = tokens;
    },
    setPlatformDepositedTokens(state, tokens){
        state.platformDepositedTokens = tokens;
    },
    setPlatformYieldTokens(state, tokens){
        state.platformYieldTokens = tokens;
    },
    addPlatformYieldTokens(state, tokens){
        state.platformYieldTokens += tokens;
    },
    addPlatformDepositedTokens(state, tokens){
        state.platformDepositedTokens += tokens;
    },
    addPortfolioDepositedTokens(state, tokens){
        state.portfolioDepositedTokens += tokens;
    },
    setPortfolioYieldTokens(state, tokens){
        state.portfolioYieldTokens = tokens;
    },
    addPortfolioYieldTokens(state, tokens){
        state.portfolioYieldTokens += tokens;
    },
    setPlatformYieldNFTs(state, tokens){
        state.platformYieldNFTs = tokens;
    },
    addPlatformDepositedNFTs(state, tokens){
        state.platformDepositedNFTs += tokens;
    },
    setPlatformDepositedNFTs(state, tokens){
        state.platformDepositedNFTs = tokens;
    },
    addPlatformYieldNFTs(state, tokens){
        state.platformYieldNFTs += tokens;
    },
    setPortfolioYieldNFTs(state, tokens){
        state.portfolioYieldNFTs = tokens;
    },
    addPortfolioYieldNFTs(state, tokens){
        state.portfolioYieldNFTs += tokens;
    },
    addPortfolioDepositedNFTs(state, tokens){
        state.portfolioDepositedNFTs += tokens;
    },
    setPortfolioDepositedNFTs(state, tokens){
        state.portfolioDepositedNFTs = tokens;
    },
    setAllPoolsFactoryStakeFungiv2Polyfungiv1(state, pools){
        state.allPoolsFactoryStakeFungiv2Polyfungiv1 = pools;
    },
    setUserPoolsFactoryStakeFungiv2Polyfungiv1(state, pools){
        state.userPoolsFactoryStakeFungiv2Polyfungiv1 = pools;
    },
    setAllPoolsFactoryStakeFNFFungiv2(state, pools){
        state.allPoolsFactoryStakeFNFFungiv2 = pools;
    },
    setUserPoolsFactoryStakeFNFFungiv2(state, pools){
        state.userPoolsFactoryStakeFNFFungiv2 = pools;
    },
    setAllPoolsFactoryStakePolyfungiv1Fungiv2(state, pools){
        state.allPoolsFactoryStakePolyfungiv1Fungiv2 = pools;
    },
    setUserPoolsFactoryStakePolyfungiv1Fungiv2(state, pools){
        state.userPoolsFactoryStakePolyfungiv1Fungiv2 = pools;
    },
    setAllPoolsFactoryStakePolyfungiv1(state, pools){
        state.allPoolsFactoryStakePolyfungiv1 = pools;
    },
    setUserPoolsFactoryStakePolyfungiv1(state, pools){
        state.userPoolsFactoryStakePolyfungiv1 = pools;
    },
    setAllPoolsFactoryStakePolyfungiv2(state, pools){
        state.allPoolsFactoryStakePolyfungiv2 = pools;
    },
    setUserPoolsFactoryStakePolyfungiv2(state, pools){
        state.userPoolsFactoryStakePolyfungiv2 = pools;
    },
    setPlatformYieldLPTokens(state, tokens){
        state.platformYieldLPTokens = tokens;
    },
    addPlatformYieldLPTokens(state, tokens){
        state.platformYieldLPTokens += tokens;
    },
    setPlatformDepositedLPTokens(state, tokens){
        state.platformDepositedLPTokens = tokens;
    },
    addPlatformDepositedLPTokens(state, tokens){
        state.platformDepositedLPTokens += tokens;
    },
    setPortfolioDepositedLPTokens(state, tokens){
        state.portfolioDepositedLPTokens = tokens;
    },
    addPortfolioDepositedLPTokens(state, tokens){
        state.portfolioDepositedLPTokens += tokens;
    },
    setPortfolioYieldLPTokens(state, tokens){
        state.portfolioYieldLPTokens = tokens;
    },
    setAllLiquidityPairsList(state, list){
        state.allLiquidityPairsList = list;
    },
    setAllTreasuryAccountsList(state, list){
        state.allTreasuryAccountsList = list;
    },
    setAllLiquidityPoolTokenNamesList(state, list){
        state.allLiquidityTokenNamesList = list;
    },
    setAllLiquidityPoolTickersList(state, list){
        state.allLiquidityTickersList = list;
    },
    setAllTreasuryAccountsData(state, list){
        state.allTreasuryAccountsData = list;
    },



};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
