<template>
  <div style="overflow-x: hidden" :style="$screen.width > 1024 ? this.showLoadingModal === false ? 'margin-top: -85px' : 'margin-top: -132px' : 'margin-top: -108px'">



    <section v-if="this.showLoadingModal === false" id="flow" class="hero mt-6 topbackgroundanimation" style="z-index: 1; height:100%; margin-top: 0px !important; overflow: hidden; ">
      <div>
        <span class="flow-1"></span>
        <span class="flow-2"></span>
        <span class="flow-3"></span>
      </div>
    </section>

    <!--    Page loading animation section-->
    <div v-if="this.showLoadingModal === true" style="height: 100vh">
      <div class="loader2">
        <div class="loader__bar"></div>
        <div class="loader__bar loader__bar--delay-1"></div>
        <div class="loader__bar loader__bar--delay-2"></div>
        <div class="loader__bar loader__bar--delay-3"></div>
        <div class="loader__bar loader__bar--delay-4"></div>
        <div class="loader__bar loader__bar--delay-5"></div>
        <div>
        </div>
      </div>
    </div>

    <!--    Homepage shown after loading is completed-->
    <transition name="fade">
      <section v-if="this.showLoadingModal === false" class="hero">


        <!--      bubbles-->
        <div
            v-if="this.showLoadingModal === false"
            class="noscroll"
            style="height: 90vh; width:100vw; overflow-x: hidden !important; overflow-y: auto"
            :style="$screen.width < 480 ? 'width: 100vw' : 'width:100vw;' "
        >
          <div class="area" style="z-index: 0">
            <ul class="circles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>

          <section class="hero is-halfheight" style="z-index: 2">
            <div class="hero-body header-bg" style="z-index: 2">
              <div
                  class="column is-offset-0 is-7 landing-caption"
                  style="margin-bottom: 60px"
              >
                <div class="wave__content" style="top: 7px; z-index: 2">
                  <div class="wave__header">
                    <div class="wave__menu"></div>
                  </div>
                </div>

                <h1 class="title is-1 is-semibold is-spaced main-title p-3 pb-5" style="font-size: 4rem; padding-top: 3rem !important; padding-bottom: 3rem !important;">
                  <span class="text-gradient-black">Kadena Stake Factory</span>
                </h1>
                <h2
                    class="subtitle is-5 is-light is-thin has-text-left font3"
                    style="margin-top: 0px; color: #fff;"
                >
                  Trade + Stake + Pool + DAO + Kadena
                </h2>
                <!-- Buttons -->
                <p class="buttons is-hidden-mobile">
                  <a
                      href="/about"
                      class="button k-button k-primary raised has-gradient is-fat is-bold btn"
                  >
                    <span class="tex font2 black-text">Learn More</span>
                    <span class="front-gradient"></span>
                  </a>
                </p>
              </div>
            </div>
          </section>

          <!-- Landing page Hero -->
          <section
              class="hero is-fullheight is-transparent"
              style="z-index: 1; margin-top: -160px"
          >
            <!-- Icon Features section -->
            <section id="start" class="section is-transparent is-relative">
              <!-- Container -->
              <div class="container mt-6" style="z-index: 1">

                <!-- Content wrapper -->
                <div class="content-wrapper is-medium">
                  <div class="columns is-vcentered">
                    <!-- Feature -->
                    <div class="column is-4">
                      <div class="serviceBox darkblue">
                        <div class="service-icon">
                          <img
                              src="../assets/landing-3box-pool-blank.svg"
                              alt=""
                              data-aos="fade-up"
                              data-aos-delay="100"
                              data-aos-offset="200"
                              data-aos-easing="ease-out-quart"
                              width="200"
                          />
                        </div>
                        <div class="service-content">
                          <h3 class="title-black mb-4">Trade</h3>
                          <p class="description">Decentralized Kadena Trading; Swaps; NFTs; Liquidity Pools; Liquidity Tools;</p>
                        </div>
                      </div>
                    </div>
                    <!-- Feature -->
                    <div class="column is-4">
                      <div class="feature">
                        <div class="serviceBox2 darkblue">
                          <img
                              src="../assets/landing-3box-stake-blank.svg"
                              alt=""
                              data-aos="fade-up"
                              data-aos-delay="100"
                              data-aos-offset="200"
                              data-aos-easing="ease-out-quart"
                              width="100"
                              height="100"
                          />
                          <div class="service-content">
                            <h3 class="title-black mb-4">Stake</h3>
                            <p class="description">Decentralized Kadena Staking Pools; Staking Pool Solutions & Tools;</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Feature -->
                    <div class="column is-4">
                      <div class="feature">
                        <div class="serviceBox3 darkblue">
                          <div class="service-icon">
                            <img
                                src="../assets/landing-3box-factory-blank.svg"
                                alt=""
                                data-aos="fade-up"
                                data-aos-delay="100"
                                data-aos-offset="200"
                                data-aos-easing="ease-out-quart"
                                width="110"
                            />
                          </div>
                          <div class="service-content">
                            <h3 class="title-black mb-4">Create</h3>
                            <p class="description">NFTs; Crowdfunding Pools; Liquidity Pools; Staking Pools; DAO Solutions; Defi Solutions;</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Play video button -->
                  <div class="column is-full-desktop" style="margin-top: 100px; margin-bottom:100px;">
                    <div>
                      <h3 class="title is-4 is-light p-3 pb-6">
                        <img src="../assets/pool-factory-bucket-pool.svg" alt=""/>
                        <span class="font2">A factory of pooling possibilities..</span>
                      </h3>
                    </div>
                  </div>
                </div>
                <!-- Content wrapper -->
              </div>
              <!-- /Container -->
            </section>
            <!-- /Icon Features section -->

            <!-- Side Features section -->
            <section class="section is-transparent" style="margin-top:-40px;">
              <!-- Container -->
              <div class="container" style="z-index: 1">
                <!-- Title & subtitle -->
                <div class="divider is-centered mb-5"></div>
                <h2
                    class="title is-light is-semibold has-text-centered is-spaced p-6"
                >
                  Liquidity Pools,<br/> Staking Pools,<br/> Vesting Pools,<br/> NFT Pools,<br/>Crowdfunding Pools,<br/>DAO Tools
                </h2>
                <div class="divider is-centered" style="margin-bottom:100px;"></div>



                <!-- Content wrapper -->
                <div class="content-wrapper is-large pt-6">
                  <div class="columns is-vcentered">
                    <!-- Feature content -->
                    <div class="column is-5">
                      <div class="side-feature-content title-back">
                        <img src="../assets/about-staking-funnel.svg" class="pt-5 pb-5" width="450px" alt=""/>
                        <h3 class="title is-4 is-light">

                          <span>Pooling Solutions</span>
                        </h3>
                        <div class="divider is-long"></div>
                        <p class="is-light p-2 pb-3">Decentralized Kadena Pooling Solutions </p>
                      </div>
                    </div>

                    <!-- Feature image -->
                    <div class="column is-7">
                      <img
                          class="side-feature"
                          src="../assets/about-stake-jar.svg" width="500px"
                          alt=""
                      />
                    </div>
                  </div>

                  <div class="columns is-vcentered">
                    <!-- Feature image desktop -->
                    <div class="column is-7 is-hidden-mobile">
                      <img
                          class="side-feature"
                          src="../assets/about-pool-factory-solutions.svg" width="650px"
                          alt=""
                      />
                    </div>

                    <!-- Feature content -->
                    <div class="column is-5">
                      <div class="side-feature-content title-back">
                        <img src="../assets/about-kadena-trilemma.svg" class="pt-5 pb-5" width="450px" alt=""/>
                        <h3 class="title is-4 is-light">

                          <span>Built on Kadena</span>
                        </h3>
                        <div class="divider is-long"></div>
                        <p class="is-light p-2 pb-3">Smart pools built smart. <br/>Decentralization, security, and scalability included.</p>
                      </div>
                    </div>

                    <!-- Feature image only for mobile -->
                    <div class="column is-7 is-hidden-tablet">
                      <img
                          class="side-feature"
                          src="../assets/about-pool-factory-solutions.svg" width="650px"
                          alt=""
                      />
                    </div>
                  </div>

                  <div class="columns is-vcentered">
                    <!-- Feature content -->
                    <div class="column is-5">
                      <div class="side-feature-content title-back">
                        <img src="../assets/about-staking-opportunity.svg" class="pt-5 pb-5 pl-2 pr-2" width="100%" alt=""/>
                        <h3 class="title is-4 is-light">

                          <span>Built your way</span>
                        </h3>
                        <div class="divider is-long"></div>
                        <p class="is-light p-2 pb-3">Search from customized pools and find pooling options that suit your needs (or make your own).</p>
                      </div>
                    </div>

                    <!-- Feature image -->
                    <div class="column is-7">
                      <img
                          class="side-feature"
                          src="../assets/about-custom-pools.svg" width="600px"
                          alt=""
                      />
                    </div>
                  </div>
                </div>
                <!-- /Content wrapper -->
              </div>
              <!-- /Container -->
            </section>
            <!-- /Side Features section -->


            <!-- Hero footer -->
            <div v-show="false" class="hero-foot">
              <div class="content-wrapper is-large pt-6">
                <div class="columns is-vcentered">
                  <!-- Feature content -->
                  <div class="column is-4" style="z-index: 1">
                    <img
                        class="hero-logo"
                        src="https://via.placeholder.com/200x50"
                        alt=""
                    />
                  </div>
                  <div class="column is-4" style="z-index: 1">
                    <img
                        class="hero-logo"
                        src="https://via.placeholder.com/200x50"
                        alt=""
                    />
                  </div>
                  <div class="column is-4" style="z-index: 1">
                    <img
                        class="hero-logo"
                        src="https://via.placeholder.com/200x50"
                        alt=""
                    />
                  </div>

                </div>
              </div>
            </div>
          </section>
          <!-- /Landing page Hero -->

          <footer class="footer" style="margin-top: 100px;">
            <div class="container hero title-back p-3 mt-6" style="z-index: 1">

              <!-- Columns -->
              <div class="columns footer-columns is-vcentered">
                <div class="column is-4">
                  <!-- Links group -->
                  <ul class="footer-links">
                    <li>
                      <a class="sflink" href="https://twitter.com/StakeFactory" target="_blank">Twitter</a>
                    </li>
                  </ul>
                </div>
                <!-- Footer -->
                <div class="column is-4">
                  <div class="footer-logo">
                  <span class="sflink">
                    <img src="../assets/stake-factory-icon.svg" width="50px" alt="">
                    <div class="brand-name">Stake Factory</div>
                    <div class="brand-subtitle">Kadena Pooling Solutions</div>
                  </span>
                  </div>
                </div>
                <!-- Links group -->
                <div class="column is-4">
                  <ul class="footer-links">
                    <li>
                      <a class="sflink" href="https://t.me/StakeFactory" target="_blank">Telegram</a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Copyright -->
              <p class="k-copyright">© 2022 | Stake Factory</p>
              <br>
            </div>

          </footer>

          <!--      bottom spacer div-->
          <div style="margin-bottom: 7%"></div>

          <div class="area" style="z-index: 0">
          </div>

          <!--      bottom spacer div-->
          <div style="margin-bottom: 10%"></div>
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
//import vue store variables to this page
import {mapGetters, mapState} from "vuex";

//local state
export default {
  name: "HomePage",
  data() {
    return {
      debug: false,
      showLoadingModal: true,
      showOldmanVanish: false,
      activeTab: "0",
      currentPage: "0",
      testvalue: 0
    };
  },
  watch: {
    //lets watch our transactionPolling variable
    transactionPolling: function (newValue, oldValue) {
      if (this.debug) {
        console.log("getTransactionPolling newValue: " + newValue);
        console.log("getTransactionPolling oldValue: " + oldValue);
      }
      if (newValue === false && oldValue === true) {
        this.showOldmanVanish = true;
      }
    }
  },
  computed: {
    //import state variables from our vue store
    ...mapState("accounts", ["transactionPolling"])
  },
  methods: {
    //map getters from vue store
    ...mapGetters("accounts", ["getAccountExists", "getAccountConfirmed"]),

    async testinput(input) {
      this.testvalue = input;
    }
  },
  //created() executes 1 frame before our page is shown
  async created() {
    //Lets load blockchain state here

    //Lets get the users account from local storage if it exists
    const name = localStorage.getItem("accountName");

    //Lets check if our user is logged in, if so, lets grab the users state from the blockchain, such as pools and balances
    if (name === null || name === "") {
      if (this.debug) {
        console.log("No user local accountName found.");
      }
    } else {
      //User is logged in proceed to fetch state
      const t_name_payload = {
        name: name
      }

      await this.$store.dispatch("accounts/getCurrentBindings", t_name_payload);
    }

    //Close loading screen
    setTimeout(async () => {
      this.showLoadingModal = false;
    }, 1000);
  }
};
</script>

<style scoped>


.sflink {
  color:#67b6ef;
}

.sflink:hover {
  color: #2ee090;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


.footer {
  background-color: transparent !important;
}

.noscroll::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 769px), print {
  .screen-cutout {
    background-image: none !important;
    background-color: transparent !important;
  }

  /deep/ .super-select-dropdown {
    background-color: #333b4c !important;
    border: 1px solid !important;
    border-color: #2f597d !important;
    color: #67b6ef !important;
    width: 380px !important;
  }
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient( #3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5% );
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 40px;
}


.machineinterface {
  display: block;
  width: 100%;
  margin: -80px 0px;
  position: relative;
}

.monitor {
  min-height: 100vh;
  padding-top: 85px;
  z-index: 2;
  display: block;
  width: 100%;
  background-color: #47485a;
  position: relative;
  padding-bottom: 40px;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, #26202b08 35px, #26202b0d 70px);
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
}

.monitor-inner {
  padding-top: 15px;
  display: block;
  width: 95%;
  background-image: conic-gradient( #414254 0 10.5%, #414254 11% 11.5%, #414254 12% 38%, #414254 39% 61.5%, #414254 62% 88%, #414254 88.5% 89%, #414254 89.25% );
  margin: auto;
  position: relative;
  top: 20px;
  left: 0;
  right: 0;
  border-radius: 5px;
  margin-top: -51px;
  padding-bottom: 60px;
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient(#3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5%);
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 50px;
}

.screen {
  display: block;
  width: 95%;
  background-color: #4F5555;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  margin-top: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 30px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), transparent 85%), radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%), linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 7px -4px #4f545f inset, 0 -2px 1px #5c6574 inset;
}

.text-gradient-black {
  background: -webkit-linear-gradient(90deg, rgba(57, 49, 64, 0.77), #322432);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.divider {
  border:none;
  height: 20px;
  width: 90%;
  height: 50px;
  margin-top: 0;
  border-bottom: 1px solid #1f1209;
  box-shadow: 0 20px 20px -20px #333;
  margin: -50px auto 10px;
}

.header-bg {
  background-image: url(../assets/landing-cow-bg.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
}

.header-bg3 {
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
  height: 1px;
  padding-top: 0px;
}

.b-tabs .tabs {
  overflow: hidden !important;
}

/deep/ .b-tabs .tabs {
  overflow: hidden !important;
}

::v-deep .b-tabs .tabs {
  overflow: hidden !important;
}




.title-back {
  color: #ffffff;
  font-family: Source Code Pro,monospace !important;
  border-radius: 1rem;
  background: -webkit-linear-gradient(90deg, rgba(49, 56, 64, 0.89), rgba(55, 45, 69, 0.85));
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}


.serviceBox{
  font-family: Source Code Pro,monospace;
  text-align: center;
  padding: 25px 0 0;
  margin: 0 -5px;
  position: relative;
  padding-top: 100px;
}
.serviceBox:before{
  content: '';
  background: -webkit-linear-gradient(-70deg, rgba(24, 78, 179, 0.11), rgba(33, 136, 255, 0.45));
  border: 2px solid #cecece;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.30) 0px 18px 36px -18px inset;
  position: absolute;
  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 0;
  background-color: rgba(255, 255, 255, 0.32);
  background-image: url(../assets/landing-3box-stake.svg);
  background-repeat: no-repeat;
  /* background-position: center; */
  background-position-x: center;
  background-position-y: 6px;
  background-size: 180px;
}
.serviceBox .service-icon{
  color: #222;
  font-size: 50px;
  margin: 0 auto 10px;
}
.serviceBox .service-content{
  background: -webkit-linear-gradient(90deg, rgba(49, 56, 64, 0.47), rgba(42, 36, 50, 0.63));
  padding: 25px 30px;
  border-radius: 0 0 20px 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;

}
.serviceBox .service-content:before{
  content: "";
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  background-repeat: repeat;
  height: 10px;
  background-size: 20px 20px;
  transform: rotate(180deg);
  background-image:
      radial-gradient(circle at 10px -5px, transparent 12px, #8fc0ff 13px);
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

.serviceBox .service-content:after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-repeat: repeat;
  height: 15px;
  background-size: 40px 20px;
  border-radius: 0rem;


}
.serviceBox .title{
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 0 0 10px;
}
.serviceBox .description{
  color: #fffefe;
  font-size: 13px;
  line-height: 23px;
  margin: 0;
}

.serviceBox2{
  font-family: Source Code Pro,monospace;
  text-align: center;
  padding: 25px 0 0;
  margin: 0 -5px;
  position: relative;
  padding-top: 100px;
}
.serviceBox2:before{
  content: '';
  background: -webkit-linear-gradient(-70deg, rgba(24, 78, 179, 0.11), rgba(33, 136, 255, 0.45));
  border: 2px solid #cecece;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.30) 0px 18px 36px -18px inset;
  position: absolute;
  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 0;
  background-color: rgba(255, 255, 255, 0.32);
  background-image: url(../assets/landing-3box-pool.svg);
  background-repeat: no-repeat;
  /* background-position: center; */
  background-position-x: center;
  background-position-y: 10px;
  background-size: 350px;
}
.serviceBox2 .service-icon{
  color: #222;
  font-size: 50px;
  margin: 0 auto 10px;
}
.serviceBox2 .service-content{
  background: -webkit-linear-gradient(90deg, rgba(49, 56, 64, 0.47), rgba(42, 36, 50, 0.63));
  padding: 25px 30px;
  border-radius: 0 0 20px 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;

}
.serviceBox2 .service-content:before{
  content: "";
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  background-repeat: repeat;
  height: 10px;
  background-size: 20px 20px;
  transform: rotate(180deg);
  background-image:
      radial-gradient(circle at 10px -5px, transparent 12px, #8fc0ff 13px);
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

.serviceBox2 .service-content:after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-repeat: repeat;
  height: 15px;
  background-size: 40px 20px;
  border-radius: 0rem;


}
.serviceBox2 .title{
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 0 0 10px;
}
.serviceBox2 .description{
  color: #fffefe;
  font-size: 13px;
  line-height: 23px;
  margin: 0;
}



.serviceBox3{
  font-family: Source Code Pro,monospace;
  text-align: center;
  padding: 25px 0 0;
  margin: 0 -5px;
  position: relative;
  padding-top: 100px;
}
.serviceBox3:before{
  content: '';
  background: -webkit-linear-gradient(-70deg, rgba(24, 78, 179, 0.11), rgba(33, 136, 255, 0.45));
  border: 2px solid #cecece;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.30) 0px 18px 36px -18px inset;
  position: absolute;
  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 0;
  background-color: rgba(255, 255, 255, 0.32);
  background-image: url(../assets/landing-3box-factory.svg);
  background-repeat: no-repeat;
  /* background-position: center; */
  background-position-x: center;
  background-position-y: 4px;
  background-size: 270px;
}
.serviceBox3 .service-icon{
  color: #222;
  font-size: 50px;
  margin: 0 auto 10px;
}
.serviceBox3 .service-content{
  background: -webkit-linear-gradient(90deg, rgba(49, 56, 64, 0.47), rgba(42, 36, 50, 0.63));
  padding: 25px 30px;
  border-radius: 0 0 20px 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;

}
.serviceBox3 .service-content:before{
  content: "";
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  background-repeat: repeat;
  height: 10px;
  background-size: 20px 20px;
  transform: rotate(180deg);
  background-image:
      radial-gradient(circle at 10px -5px, transparent 12px, #8fc0ff 13px);
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

.serviceBox3 .service-content:after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-repeat: repeat;
  height: 15px;
  background-size: 40px 20px;
  border-radius: 0rem;


}
.serviceBox3 .title{
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 0 0 10px;
}
.serviceBox3 .description{
  color: #fffefe;
  font-size: 13px;
  line-height: 23px;
  margin: 0;
}


@media only screen and (max-width:990px){
  .serviceBox{ margin: 0 0 30px; }
}

.section {
  padding: 3rem 1.5rem;
  padding-right: 1.7rem;
}

.topbackgroundanimation {
  position: absolute;
  height: 200px;
  width: 100%;
  z-index: 1;
  overflow-x: hidden !important;
}

/* item card titles */
.title {
  color: #ffffff;
  font-family: Source Code Pro,monospace;
  border-radius: 1rem;
  background: -webkit-linear-gradient(90deg, rgba(49, 56, 64, 0.89), rgba(55, 45, 69, 0.85));
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.title-back {
  color: #ffffff;
  font-family: Source Code Pro,monospace;
  border-radius: 1rem;
  background: -webkit-linear-gradient(90deg, rgba(49, 56, 64, 0.89), rgba(55, 45, 69, 0.85));
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}


.title-black {
  color: #4c4a4c;
  font-family: Source Code Pro,monospace;
  border-radius: 1rem;
  background: linear-gradient(#eaeaea, rgba(199, 215, 234, 0.42));
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  padding: .2rem;
}

/* page loading style */
.loaderstyle {
  z-index: 1;
  position: absolute !important;
}

/* preloader styles */
@-webkit-keyframes goSmoke {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(2, 2) translateY(-20px) rotate(30deg);
  }
}

@-webkit-keyframes goWindow {
  0% {
    background-color: #F5F19A;
  }
  100% {
    background-color: #D3BD54;
  }
}

@-webkit-keyframes goWheels {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes goPackage {
  83% {
    -webkit-transform: translateX(120px);
  }
  95% {
    -webkit-transform: translateX(130px) translateY(25px) rotate(90deg);
  }
  100% {
    -webkit-transform: translateX(130px) translateY(25px) rotate(90deg);
  }
}


#wraper2 {
  width: 450px;
  height: 350px;
  margin: 0 auto;
  position: relative;
  border-radius: 40px;
  margin-left: -36px;
}

/* background animation styles */
.area {
  background: -webkit-linear-gradient(90deg, #75a0d8, #75a0d8);
  background-color: #75a0d8;
  background: radial-gradient(1.5em 6.28571em at 1.95em, rgba(255, 255, 255, 0) 50%,
  rgba(255, 255, 255, 0.02) 50%, rgba(255, 255, 255, 0.02) 55%,
  rgba(255, 255, 255, 0) 55%) 0 0,
  radial-gradient(1.5em 6.28571em at -0.45em,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.04) 50%,
      rgba(255, 255, 255, 0.04) 55%,
      rgba(255, 255, 255, 0) 55%) 1.5em 5.5em,
  radial-gradient(2.3em 4.57143em at 2.99em,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.03) 50%,
      rgba(255, 255, 255, 0.02) 55%,
      rgba(255, 255, 255, 0) 55%) 0 0,
  radial-gradient(2.3em 4.57143em at -0.69em,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.04) 50%,
      rgba(255, 255, 255, 0.03) 55%,
      rgba(255, 255, 255, 0) 55%) 2.3em 4em,
  radial-gradient(3.5em 6.28571em at 4.55em, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.04) 50%, rgba(255, 255, 255, 0.06) 55%, rgba(255, 255, 255, 0) 55%) 0 0,
  radial-gradient(3.5em 6.28571em at -1.05em, rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.04) 50%,
      rgba(255, 255, 255, 0.03) 55%,
      rgba(255, 255, 255, 0) 55%) 3.5em 5.5em,
  -webkit-linear-gradient(90deg, rgba(57, 49, 64, 0.77), #322432);
  background-size: 1.5em 11em, 1.5em 11em, 2.3em 8em, 2.3em 8em, 3.5em 11em, 3.5em 11em, 100% 100%;
  background-repeat: repeat;
  width: 100%;
  height: 165vh;
  position: absolute;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.4);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-2000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.wave__content {
  text-align: center;
  height: 100%;
  overflow-x: hidden;
  position: relative;
}

.wave__header {
  position: relative;
  height: 100px;
  background: none;
  overflow: hidden !important;
}

.wave__menu {
  position: absolute;
  width: 100%;
  top: 75px;
  height: 30px;
  animation: wave-animation 16s infinite linear;
  background: url(http://www.templates-preview.com/bootstrap-templates/300111854/images/wave1.png) 0 0 repeat-x;
  background-position-y: -10px;
  border-top-left-radius: 320px;
  border-top-right-radius: 320px;
  overflow-x: hidden;
  overflow-y: hidden !important;
}


/* ---------------------------------- */
@keyframes wave-animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 2000px 0;
  }
}

.pools-bg {
  background-color: #222b3a;
}

.pool-main {
  background-color: #222b3a;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
}

.pool-section-title {
  background-color: #2c3444;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #46a9e7;
  font-family: Source Code Pro,monospace !important;
}
.pool-section-title:hover {
  border: 1px solid rgba(72, 199, 142, 0.38);
}


.topbackgroundanimation {
  position: absolute;
  height: 200px;
  width: 100%;
  z-index: 1;
}

/* border for item cards */
.cardborder {
  border-radius: 1rem;
  color: #000000;
}

.poolselect-bg {
  background-color: #222b3a !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}



/deep/ .tabs.is-toggle li.is-active a {
  background-color: #252f3e;
  color: #3abb81;
  z-index: 1;
  font-family: Source Code Pro,monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}


/deep/ .tabs.is-toggle {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  background-color: #252f3e;
}

/deep/ .tabs.is-toggle a {
  margin-bottom: 0;
  position: relative;
  font-family: Source Code Pro,monospace !important;
  border:none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  color: #476074;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: #222b3a;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: #222b3a;
  border:none;
  color: #00b89c;
}


#style-8::-webkit-scrollbar-track
{
  border: 1px solid black;
  background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar
{
  width: 10px;
  background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar-thumb
{
  background-color: #000000;
}



.pools-card{
  padding-top:.5rem;
  color: #0d64c6;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow{
  box-shadow: rgba(0, 0, 0, 0.56) 8px -5px 40px;
  border-radius: 30px;
}

.stake-btn-col{
  display: flex;
  justify-content: center;
}

</style>
<style lang="scss" scoped>
//eccdff
.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: "Angkor", sans-serif;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

/* background animation styles */
#flow span {
  display: block;
  width: 200vw;
  height: 200vw;
  position: absolute;
  top: -160vw;
  left: -50vw;
  border-radius: 90vw;
  opacity: 0.6;
}

.flow-1 {
  background: -webkit-linear-gradient(-70deg, #75a0d8, #75a0d8);
  -webkit-animation: rotating 100s linear infinite;
  -moz-animation: rotating 100s linear infinite;
  -ms-animation: rotating 100s linear infinite;
  -o-animation: rotating 100s linear infinite;
  animation: rotating 100s linear infinite;
}

.flow-2 {
  background: -webkit-linear-gradient(-70deg, #75a0d8, rgba(117, 160, 216, 0.42));
  position: absolute;
  -webkit-animation: rotating 50s linear infinite;
  -moz-animation: rotating 50s linear infinite;
  -ms-animation: rotating 50s linear infinite;
  -o-animation: rotating 50s linear infinite;
  animation: rotating 50s linear infinite;
}

.flow-3 {
  background: -webkit-linear-gradient(-70deg, rgba(117, 208, 216, 0.15), rgba(33, 136, 255, 0.33));
  position: absolute;
  -webkit-animation: rotating 30s linear infinite;
  -moz-animation: rotating 30s linear infinite;
  -ms-animation: rotating 30s linear infinite;
  -o-animation: rotating 30s linear infinite;
  animation: rotating 30s linear infinite;
}

@-webkit-keyframes rotating /* Safari and Chrome */
{
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}



@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  display:flex;
  width: 35px;
  height: 35px;
  border-radius: 0.5rem;
  color: #296992;
}
.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}

.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}

// for styles that do not have ascii characters setting the height
$height: 15px;

.outer {
  margin-bottom: $height;
  width: 100%;
  height: $height;
}

.plain {

  &.outer {
    border: 1px solid limegreen;
  }

  .inner {
    width: 90%;
    background-color: limegreen;
    height: $height;
  }
}

.borderless .inner {
  width: 90%;
  background-color: limegreen;
  height: $height;
}

.punctuation.outer {
  border: 1px solid limegreen;
  line-height: 1.35;
}

@-webkit-keyframes progress-bar-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes spinning {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes pulsing-opacity {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}


.plain .inner, .borderless .inner {
  animation: progress-bar-width steps(20) 5s infinite;
}

.pipes .inner, .blocks .inner, .blocks-gaps {
  animation: progress-bar-width 5s infinite;
  overflow: hidden;
  animation-timing-function: linear;
}

.blocks-gaps .inner {
  letter-spacing: 2px;
}

.activity {
  text-align: center;
  overflow: hidden !important;
}

.spinning {
  animation: spinning steps(8, end) 5s infinite;
}

.pulsing {
  animation: pulsing-opacity steps(3) 0.5s infinite alternate;
}

</style>
<style scoped>
.container3 {
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  /* padding: 25px; */
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), #0f5cbb26 85%), radial-gradient(rgb(38 58 74 / 75%), transparent 70%), linear-gradient(#0e081a00, #211922, #0f142000);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
}
.cow {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#14ff7d, #00f3ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}
</style>

<style lang="scss" scoped>
.container2 {
  background-color: #1D1F21;
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  padding: 25px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background: -webkit-linear-gradient(#3e10463d, #21333e70) !important;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 37px 59px #0e192257 inset, 0 0px 2px #ff0ef8 inset;
  -webkit-animation: scanline-data-v-0d33d4c2 8s linear infinite;
  animation: scanline-data-v-0d33d4c2 8s linear infinite;
}

article {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 900px) {
    padding: 5px;
  }

  @media (max-width: 899px) {
    padding-bottom: 56px;
  }
  background-color: rgba(192, 192, 192, 0);
  color: #323232;
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  position: relative;
  & > * + * {
    margin-top: 1.25em;
  }

  &:after {
    content: "🥩";
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding-bottom: 0.25em;
    position: absolute;
    top: 0.75em;
    right: 0.75em;
    width: 1.75em;
    height: 1.75em;
    filter: invert(100) opacity(40%);

    background-color: transparent;
  }
}

.headline {
  font-size: 2.5em;
  font-weight: 700;
}

.subhead {
  font-size: 1.25em;
  margin-top: 0.25em;
}

.article-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    width: 3em;
    height: 3em;
    flex-shrink: 0;
  }

  div:first-of-type {
    margin-left: 0.75em;
    font-size: 0.875em;
    margin-right: auto;
    padding-right: 0.75em;
  }

  div:last-of-type {
    font-size: 0.875em;
  }
}

aside {
  padding: 4em;
  border-right: 1px solid rgba(171, 15, 232, 0.1);
  border-bottom: 1px solid rgba(171, 15, 232, 0.1);
  border-left: 1px solid rgba(171, 15, 232, 0.1);
  border-top: 1px solid rgba(171, 15, 232, 0.1);
  clear: both;
  text-shadow: 0 0 10px, 0 0 40px;
  background-color: rgb(46 40 66 / 39%);
}

.tag {
  display: inline-block;
  margin-right: 0.25em;
  margin-top: 0.25em;
  border-right: 1px solid rgba(238, 238, 238, 0.19);
  border-bottom: 1px solid rgba(238, 238, 238, 0.13);
  border-left: 1px solid #444;
  border-top: 1px solid #444;
  padding: 0.125em 0.375em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}



figure {
  background-color: #c0c0c0;
  box-shadow: 0 0 0 1px #eee, 0 0 0 2px #c0c0c0, 0 0 0 3px #444;
  position: relative;
  margin-bottom: 1.25em;
  width: calc(100% + 1.5em + 2em);
  text-shadow: 0 0 10px, 0 0 40px;
  @media (min-width: 800px) {
    max-width: 50%;
  }
  &:nth-of-type(odd) {
    float: left;
    margin-right: 1.5em;
    margin-left: -3em;
  }

  &:nth-of-type(even) {
    float: right;
    margin-left: 1.5em;
    margin-right: -3em;
  }

  &:after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    background-image: linear-gradient(
            to top,
            rgba(#ff9100, 0.2) 0%,
            rgba(#ffe630, 0.2) 60%
    ),
    linear-gradient(20deg, rgba(#ff0, 0.5) 0%, rgba(#ff0, 0) 35%);
    box-shadow: inset 0px 0px 100px rgba(0, 0, 20, 1);
    z-index: 1;
  }

  img {
    // opacity: 0;
    // filter: hue-rotate(90deg);
    // filter: sepia(20%) brightness(10%) contrast(130%)
  }
  figcaption {
    font-family: "DotGothic16", monospace;
    background-color: #6f4b80;
    box-shadow: inset 0 -1px 0 0 #020202;
    color: #eee;
    text-shadow: 0 0 10px, 0 0 40px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.25em;
    font-size: 0.875em;
    z-index: 2;
  }
}
.blink {
  opacity: 1;
  animation: blink 1s linear infinite;
}

.scanline  {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba(255,255,255,0.02);
  animation: scanline 8s linear infinite;
  pointer-events: none;
  &:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: rgba(255,255,255,0.02);
    animation: scanline 8s linear infinite;
    pointer-events: none;
    z-index: 10;
  }

}

// Scanline height 1005
@keyframes scanline {
  0.01% {
    height: 0;
  }
  99.99% {
    height: 100%
  }
  100% {
    height: 0;
  }
}
</style>
<style lang="scss" scoped>
main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro,monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

$message-header-background-color: #e3f;


.loading-page-container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -160px;
  z-index: 1;

}

.loading-page-container .progress {
  position: relative;
  border-radius: 50%;
  width: 400px;
  height: 400px;
  border: 6px solid #342b3b;
  background-blend-mode: hard-light;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 1;
}

.loading-page-container .progress .inner {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: 50%;
  margin-left: -4px;
  margin-top: -1px;
  width: 396.5px;
  height: 396.5px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.loading-page-container .progress .inner .water {
  opacity: .55;
  position: absolute;
  z-index: 1;
  width: 200%;
  height: 200%;
  left: -50%;
  border-radius: 40%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  animation-name: spin;
  top: 55%;
  background: #32BAFA;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  box-shadow: 0 0 20px #152A4A;

}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  display:flex;
  width: 35px;
  height: 35px;
  border-radius: 0.5rem;
  color: #296992;
}
.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}



.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}


// for styles that do not have ascii characters setting the height
$height: 15px;

.outer {
  margin-bottom: $height;
  width: 100%;
  height: $height;
}

.plain {

  &.outer {
    border: 1px solid limegreen;
  }

  .inner {
    width: 90%;
    background-color: limegreen;
    height: $height;
  }
}

.borderless .inner {
  width: 90%;
  background-color: limegreen;
  height: $height;
}

.punctuation.outer {
  border: 1px solid limegreen;
  line-height: 1.35;
}

@-webkit-keyframes progress-bar-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes spinning {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes pulsing-opacity {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}


.plain .inner, .borderless .inner {
  animation: progress-bar-width steps(20) 5s infinite;
}

.pipes .inner, .blocks .inner, .blocks-gaps {
  animation: progress-bar-width 5s infinite;
  overflow: hidden;
  animation-timing-function: linear;
}

.blocks-gaps .inner {
  letter-spacing: 2px;
}

.activity {
  text-align: center;
  overflow: hidden !important;
}

.spinning {
  animation: spinning steps(8, end) 8s infinite;
}

.pulsing {
  animation: pulsing-opacity steps(3) 0.5s infinite alternate;
}

</style>
