<template>
  <div id="app" >
    <DAOPage :poolid="this.poolid" ></DAOPage>
  </div>
</template>

<script>
import DAOPage from '../components/DAOPage.vue'

export default {
  name: 'DAO',
  props: {
    poolid: {
      type: String,
      default: "0"
    },
  },
  components: {
    DAOPage
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
