<template>
  <section>
    <div class="card3d" @click="clickDaoCard()" v-bind:class="[flipped ? useexpand ? 'flip-container flipped' : 'flip-container flippedhm' : 'flip-container']">
      <div class="flipper">
        <div class="front">
          <div class="card form-container frontcardbg" v-bind:class="[isPoolOwner > 0 ? 'highlight' : '']">
            <div v-if="dao_image !== '?' && dao_image != 'https://link-to-dao-icon.com'" class="card__product-img">
              <img :src="dao_image" alt="DAO" class="form-icon" style="max-width: 100px !important" />
            </div>
            <div v-if="dao_image === '?' || dao_image == 'https://link-to-dao-icon.com'" class="card__product-img">
              <img src="../assets/dao-placeholder.svg" alt="?" style="margin-top: -70px; width: 110px;">
            </div>
            <div class="card__content" style="max-width: 225px; overflow-wrap: anywhere;">
              <p class="card__name pt-2"><span style="font-size: 12px; color: #24aec5 !important;">{{dao_description}}</span></p>
            </div>
            <div class="card__content" style="max-width: 225px; overflow-wrap: anywhere; position: relative; bottom: -30px;">
              <p class="card__name pt-2"><span style="font-size: 12px; font-family: Source Code Pro, monospace; color: #43c68b; transform: translate(-46%, 0%);">{{dao_name}}</span></p>
            </div>
          </div>
          <div class="card__content-pending" style="z-index: 3;">
            <div class="card__price_pending">
              <p style="z-index: 3;">Proposals: {{dao_proposals}}</p>
            </div>
          </div>
          <div class="card__content-pending" style="z-index: 3; right: 0px">
            <div class="card__price_pending">
              <p style="z-index: 3;">Members: {{dao_members}}</p>
            </div>
          </div>
          <div class="card__content-apy-back">
            <div class="card__price_apy_back">
              <p><span style="color: #3bac81">{{ dao_id }}</span></p>
            </div>
          </div>
          <a v-if="show_flipper === true" v-on:click="flipped=true; $emit('flipped', true)" class="btn2" v-bind:class="[isPoolOwner > 0 ? 'highlight-span' : '']" href="#">
            <span> > </span>
          </a>
        </div>
        <div class="back">
          <slot name="back"></slot>

          <div class="card form-container" v-bind:class="[isPoolOwner > 0 ? 'highlight' : '']">



            <div class="back-col-main">



              <div class="card__content-apy-back">
                <div class="card__price_apy_back">
                  <p><span style="color: #3bac81">{{ dao_id }}</span></p>
                </div>
              </div>

              <div class="columns mb-1 back-col" style="position: relative; top: 0px; background-color: rgba(38,43,56,1); border-radius: 1rem;">
                <div class="column is-full is-align-self-center p-1">
                  <div class="columns">
                    <div class="column"  v-bind:class="[$screen.width > 768 ? 'stake-col-lg' : 'stake-col-sm']">
                      <div class="field-body" style="max-width: 233px;">
                        <div class="field cardfieldbg" style="display:flex; justify-content: space-evenly !important; overflow-wrap: anywhere;" id="style-11">
                          <div class="columns is-flex-direction-column">
                            <div class="column pt-3" style="height:40px; padding-bottom: 0px; margin-bottom: 0px;">
                              <p class="control is-expanded back-col-text" style="color:#3abb81">
                                {{dao_name.slice(0, 25)}}
                              </p>
                              <br/>
                              <div class="divider is-centered"></div>
                              <br/>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>



          </div>
          <a v-if="show_flipper === true" v-on:click="flipped=false; $emit('flipped', false)" class="btn2" v-bind:class="[isPoolOwner > 0 ? 'highlight-span' : '']" href="#">
            <span> &lt; </span>
          </a>
          <a v-if="show_flipper === true" v-on:click="flipped=false; $emit('flipped', false)" class="btn2" v-bind:class="[isPoolOwner > 0 ? 'highlight-span' : '']" href="#">
            <span> &lt; </span>
          </a>
        </div>
      </div>

    </div>

  </section>

</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "CollectionCard",
  components: {
  },
  props: {
    isPoolOwner: {
      type: Boolean,
      default: false,
    },
    show_flipper: {
      type: Boolean,
      default: false,
    },
    dao_name: {
      type: String,
      default: "?",
    },
    dao_id: {
      type: String,
      default: "?",
    },
    dao_description: {
      type: String,
      default: "?",
    },
    dao_image: {
      type: String,
      default: "?",
    },
    dao_members: {
      type: [Number, String],
      default: 0,
    },
    dao_proposals: {
      type: [Number, String],
      default: 0,
    },
    doclick: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      debug: false,
      flipped: this.is_flipped,
      card: this.$el,
    };
  },
  computed: {

  },
  methods: {
    ...mapGetters("accounts", ["getAccountExists", "getAccountConfirmed", "getAllPoolsFactoryStakeFungiv2", "getUserPoolsFactoryStakeFungiv2"]),

   async clickDaoCard(){
      if(this.doclick === true){
        await this.$router.push({path: "/dao/" + this.dao_id});
      }

    },

    coinName(namespace, name) {
      let newnamespace = namespace + ".";
      let newname = name;
      if (namespace === null || namespace === "null" || namespace === "") {
        newnamespace = "";
      }
      if (name === null || name === "null" || name === "") {
        newname = "";
      }
      return newnamespace + newname;
    },


  },
  async created() {

  }
};
</script>

<style scoped>

a:hover{
  color: #4ae39e;
}

/deep/ .modal-content, .modal-card {
  overflow: hidden !important;
}

.form-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.blue-bg {
  background-color: #314b6a;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #6ea4db;
}


.inset-bg-rounded2 {
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}



.cardfieldbg {
  background-color: transparent !important;
  height: 210px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.frontcardbg {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#style-11::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #200d31;
  border-radius: 10px;
}

#style-11::-webkit-scrollbar {
  width: 10px;
  background-color: #252432;
}

#style-11::-webkit-scrollbar-thumb {
  background-color: #242532;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
  rgba(0, 0, 0, .2) 25%,
  transparent 25%,
  transparent 50%,
  rgba(0, 0, 0, .2) 50%,
  rgba(0, 0, 0, .2) 75%,
  transparent 75%,
  transparent)
}

.divider2 {
  border: none;
  height: 20px;
  width: 90%;
  height: 70px;
  padding-top: 20px;
  border-bottom: 1px solid #224a84;
  box-shadow: 0 20px 20px -20px rgba(88, 116, 238, 0.56);
  margin: -50px auto 10px;
}


/deep/ .field {
  background-color: transparent;
  padding: 0.2rem;
  border-radius: 0.5rem;
}

input::placeholder {
  color: #2b8b5f;
}

.select {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #3abb81 !important;
  border: 1px solid !important;
  color: #3abb81;


}

/deep/ .textarea {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #3abb81;
  color: #3abb81;
}

/deep/ .select select[disabled], .taginput [disabled].taginput-container.is-focusable, [disabled].textarea, [disabled].input, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .taginput .taginput-container.is-focusable, .taginput fieldset[disabled] .taginput-container.is-focusable, fieldset[disabled] .textarea, fieldset[disabled] .input {
  background-color: #2a4855 !important;
  border-color: #24545f;
  box-shadow: none;
  color: #317884;

}

/deep/ .select select {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #3c5d6c;
  color: #3abb81;
  border-bottom: 1px solid !important;
}

/deep/ .select select option {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #3abb81;
  color: #3abb81;
}

.divider {
  border: none;
  height: 20px;
  width: 90%;
  height: 50px;
  margin-top: 0;
  border-bottom: 1px solid #3c2f92;
  box-shadow: 0 20px 20px -20px rgba(63, 36, 92, 0.56);
  margin: -60px auto 10px;
}


body {
  perspective: 1500px;
}

.wrapper {
  max-width: 295px;
  max-height: 295px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frameWrapper {
  height: 277px;
  width: 240px;
}

.frame {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: #2e3e60b8;
}

.primaryLayer,
.secondaryLayer {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.primaryLayer {
  clip-path: polygon(0% 0%, 100% 0%, 100% 40%, 0% 55%);
  background: radial-gradient(black 0%, transparent 10%) 0 0, radial-gradient(black 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0.1) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #2c313a;
  background-size: 16px 16px;
  border: 2px solid rgba(17, 66, 101, 0.3);
  background-blend-mode: darken;
}

.secondaryLayer {
  clip-path: polygon(100% 100%, 0% 100%, 0% 60%, 100% 45%);
  background: radial-gradient(black 0%, transparent 10%) 0 0, radial-gradient(black 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0.1) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #2c313a;
  background-size: 16px 16px;
  border: 2px solid rgba(17, 66, 101, 0.3);
  background-blend-mode: darken;
}

.image {
  object-fit: cover;
  position: absolute;
  min-height: 65%;
  max-height: 100%;
  width: 100%;
}

.primaryLayer > .image {
  top: 0px;
}

.secondaryLayer > .image {
  bottom: 0px;
}


.card3d {
  font-weight: bold;
  padding: 1em;
  color: #181a1a;
  width:  300px;
  max-height: 450px;
  box-shadow: 0 1px 5px #00000099;
  border-radius: 10px;
  position: relative;
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  transform: rotate3d(0,0,0,0);
}

.card3d:hover {
  transition-duration: 150ms;
  box-shadow: 0 5px 20px 5px #00000044;
  cursor: pointer;
}

.card3d .glow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  background-image: radial-gradient(circle at 50% -20%, #ffffff22, #0000000f);
}


.highlight{
  box-shadow: rgba(24, 202, 255, 0.13) 0px 0px 30px 3px !important;
  border-color: rgba(106, 70, 123, 0.56) !important;
  outline: none;
}

/deep/ .b-numberinput input {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
}

.pending-col {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: 'Lexend Deca', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.back-col {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px;
}

.back-col-main {
  background-color: rgba(38, 43, 56, 1);
  border-radius: 1rem;
  font-family: 'Lexend Deca', sans-serif;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  font-size: 12px;
  height: 100%;
  width: 100%;
  padding: .8rem;
}

.back-col-text{
  font-size: 12px;
}

.col-shadow1 {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider {
  font-size: 12px;
}

.pool-name {
  font-size: 16px;
  color: rgb(120 161 235);
  font-family: 'Lexend Deca', sans-serif;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: #293038;
}

.pool-main {
  background-color: transparent;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: 'Noto Sans', sans-serif;
}

.poolselect-bg {
  background-color: #293038 !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

/deep/ .tabs.is-toggle li.is-active a {
  background-color: #314b6a;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #3abb81;
  z-index: 1;
  font-family: 'Noto Sans', sans-serif;
}

/deep/ .tabs.is-toggle a {
  border-color: #575f85;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 0;
  position: relative;
  font-family: 'Noto Sans', sans-serif;
  color: #195da7;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
  color: #00b89c;
}


.pools-card {
  padding-top: .5rem;
  color: #0d64c6;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
}

/deep/ .field.has-addons .control:first-child .control .button, .field.has-addons .control:first-child .control .input, .field.has-addons .control:first-child .control .select select {
  border-radius: 0 !important;
}

/deep/ .field.has-addons:first-child  .control:first-child .control:first-child  .button:first-child , .field.has-addons:first-child  .control:first-child .control:first-child  .input:first-child , .field.has-addons:first-child .control:first-child .control:first-child  .select:first-child  select:first-child{
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.stake-btn-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.back-card-stat-col{
  display: flex;
  justify-content: left;
}

.stake-btn-2 {
  font-size: 14px !important;
  max-height: 40px;
  padding-left: 8px !important;
}

.btn {
  color: #87b6e5;
  background: rgba(3, 150, 255, 0.32);
  background-repeat: no-repeat;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}

.btn:focus,
.btn:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
  cursor: pointer;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    margin-bottom: 30px;
  }
}

.text3d {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 transparent, 0 3px 0 transparent, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px 0;
}


.pricingTable {
  background: #fff;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding: 0 0 30px;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: -20px;
}

.pricingTable .pricingTable-header {
  background: #c2803a;
  padding: 10px 0 10px;
  margin: 0 auto 40px;
  border-radius: 40px 40px 0 0;
  position: relative;
}

.pricingTable .pricingTable-header:hover {
  color: #fff;
  background: #7bc8fa;
}

.pricingTable .pricingTable-header:after {
  content: "";
  background: linear-gradient(-45deg, transparent 75%, #c2803a 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #c2803a 75%) 0 50%;
  background-repeat: repeat-x;
  background-size: 22px;
  width: 100%;
  height: 21px;
  position: absolute;
  bottom: -21px;
  left: 0;
  cursor: pointer;
}

.pricingTable .pricingTable-header:hover:after {
  content: "";
  background: linear-gradient(-45deg, transparent 75%, #7bc8fa 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #7bc8fa 75%) 0 50%;
  background-repeat: repeat-x;
  background-size: 22px;
  width: 100%;
  height: 21px;
  position: absolute;
  bottom: -21px;
  left: 0;
  cursor: pointer;
}


.pricingTable .title-back {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  cursor: pointer;
}


.pricingTable .price-value {
  color: #5b5b5b;
  margin: 0 0 20px;
}

.pricingTable .price-value .amount {
  font-size: 65px;
  font-weight: 400;
  line-height: 60px;
  display: block;
}

.pricingTable .price-value .duration {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  display: block;
}

.pricingTable .pricing-content {
  padding: 0 10px;
  margin: 0 0 10px;
  list-style: none;
}

.pricingTable .pricing-content li {
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  line-height: 1px;
  padding: 9px 15px 8px 10px;
  position: relative;
  overflow: auto;
}

.pricingTable .pricing-content li:nth-child(odd) {
  background: #e7e7e7;
}

.pricingTable .pricing-content li:nth-last-child(-n+4) {
  line-height: normal;
}

.pricingTable .pricingTable-signup a {
  color: #fff;
  background: #c2803a;
  font-size: 22px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 1px;
  padding: 5px 25px;
  border-radius: 50px;
  transition: all 0.3s ease 0s;
}

.pricingTable .pricingTable-signup a:hover {
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.9);
}

.pricingTable.red .pricingTable-header {
  background: #d83b50;
}

.pricingTable.red .pricingTable-header:after {
  background: linear-gradient(-45deg, transparent 75%, #d83b50 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #d83b50 75%) 0 50%;
  background-size: 22px;
}

.pricingTable.red .pricingTable-signup a {
  background: #d83b50;
}

.pricingTable.orange .pricingTable-header {
  background: #e0681e;
}

.pricingTable.orange .pricingTable-header:after {
  background: linear-gradient(-45deg, transparent 75%, #e0681e 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #e0681e 75%) 0 50%;
  background-size: 22px;
}

.pricingTable.orange .pricingTable-signup a {
  background: #e0681e;
}

.btn2 {
  color: #fff;
  background-color: #0a0a0a;
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 1px;
  border: none;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  transition: all 0.3s ease 0s;
  top: 0;
  right: 0px;
  background-blend-mode: color;
  outline: none;
  cursor: pointer;
}

.btn2:focus,
.btn2:hover {
  color: #fff;
  background-color: #0a0a0a;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) inset, 0 0 10px rgba(0, 0, 0, 0.3);
  background-blend-mode: color;
  outline: none;
  cursor: pointer;
}


.btn2 span {
  background-color: rgba(58, 91, 203, 0.91);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 1px solid #114265;
  outline: none;
  cursor: pointer;
}

.btn2 span:before {
  background-color: #0a0a0a;
  padding: 7px 20px;
  display: block;
  border-radius: 50px;
  border: 1px solid #114265;
  background-blend-mode: color;
  cursor: pointer;
}



.btn3{
  color: #87b6e5;
  background-color: rgba(38, 89, 125, 0.6) !important;
  background-repeat: no-repeat;
  font-family: 'Noto', sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}
.btn3:focus,
.btn3:hover{
  color: #fff;
  background: radial-gradient(circle at center,rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(38, 89, 125, 0.6);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
}
@keyframes bubbles{
  100%{
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%,26% -96%,-1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}


.highlight-span span {
  background-color: rgba(92, 57, 94, 0.82);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 1px solid #309a6b85;
  outline: none;
  cursor: pointer;
}

.highlight-span span:before {
  background-color: rgba(92, 57, 94, 0.82);
  padding: 7px 20px;
  display: block;
  border-radius: 50px;
  border: 1px solid #309a6b85;
  background-blend-mode: color;
  cursor: pointer;
}

.highlight-span span:focus,
.highlight-span span:hover {
  background-color: rgba(92, 57, 94, 0.82);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 1px solid #309a6b85;
  outline: none;
  cursor: pointer;
}


i.frontFlipBtn,
i.backFlipBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #FFFFFF;
}

i.backFlipBtn {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}


.flip-container {
  min-height: 120px;
}

.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  position: relative;
  max-width: 290px;
}

.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  width: 100%;
}

.back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  position: absolute;
}

.flip-container.flipped .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.flip-container.flipped .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flip-container.flippedhm .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.flip-container.flippedhm .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flip-container.flipped {
  min-height: 520px;
}

.flip-container.flippedhm {
  min-height: 320px;
}

.front {
  z-index: 2;
}


</style>

<style scoped lang="scss">
.icon {
  display: flex;
}

.card__content-pending {
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
  padding-top: 4px;
  position: absolute;
  bottom: 5px;
  width: 50%;
  background-size: 16px 16px;
  border-radius: 1rem;

  > * {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .card__price_pending {
    color: #2a8076;
    font-size: 13px;
    font-family: 'Lexend Deca', sans-serif;
  }
}


.card__content-apy-back {
  display: flex;
  justify-content: center;
  padding-top: 4px;
  padding-bottom: 0;
  position: absolute;
  top: 6px;
  left: 20px;

  > * {
    display: flex;
    align-items: center;
    font-weight: 600;
  }
}

.card__price_apy_back {
  color: #2a6980;
  font-size: 10px;
  font-family: 'Lexend Deca', sans-serif;
}

.card__content-bottom2 {
  display: flex;
  position: absolute;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 0;
  cursor: pointer;
  top: 80px;
  right: 0px;

  > * {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .card__maximize {
    color: #2a6980;
    font-size: 10px;
    font-family: 'Source Code Pro', sans-serif;
    height: 23px;
    font-size: 10px;
    background-color: #363d68;
    color: #262f38;
    border: none;
    padding-left: 4px;
    padding-right: 3px;
    padding-top:0px;
    margin-top:0px;
    padding-bottom:4px;
  }
  .card__maximize:hover {
    color: #2a6980;
    font-size: 10px;
    font-family: 'Source Code Pro', sans-serif;
    height: 23px;
    font-size: 10px;
    background-color: #2e7946;
    color: #a5d9c7;
    border: none;
    padding-left: 4px;
    padding-right: 3px;
  }
}

.card__content-bottom3 {
  display: flex;
  position: absolute;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 0;
  cursor: pointer;
  top: 75px;
  right: 0px;

  > * {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .card__maximize {
    color: #2a6980;
    font-size: 10px;
    font-family: 'Source Code Pro', sans-serif;
    height: 23px;
    font-size: 10px;
    background-color: #363d68;
    color: #262f38;
    border: none;
    padding-left: 4px;
    padding-right: 3px;
    padding-top:0px;
    margin-top:0px;
    padding-bottom:4px;
  }
  .card__maximize:hover {
    color: #2a6980;
    font-size: 10px;
    font-family: 'Source Code Pro', sans-serif;
    height: 23px;
    font-size: 10px;
    background-color: #2e7946;
    color: #a5d9c7;
    border: none;
    padding-left: 4px;
    padding-right: 3px;
  }
}

.card__content-bottom4 {
  display: flex;
  position: absolute;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 0;
  cursor: pointer;
  top: 120px;
  right: 0px;

  > * {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .card__maximize {
    color: #2a6980;
    font-size: 10px;
    font-family: 'Source Code Pro', sans-serif;
    height: 23px;
    font-size: 10px;
    background-color: #363d68;
    color: #262f38;
    border: none;
    padding-left: 4px;
    padding-right: 3px;
    padding-top:0px;
    margin-top:0px;
    padding-bottom:4px;
  }
  .card__maximize:hover {
    color: #2a6980;
    font-size: 10px;
    font-family: 'Source Code Pro', sans-serif;
    height: 23px;
    font-size: 10px;
    background-color: #2e7946;
    color: #a5d9c7;
    border: none;
    padding-left: 4px;
    padding-right: 3px;
  }
}

.card {
  background-color: #a0b3c4;
  color: #8BACD9;
  border-radius: 16px;
  width: 300px;
  //height: 500px;
  padding-bottom: 0;
  box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.1);

  .card__product-img {
    display: flex;
    position: relative;
    max-width: 300px;
    max-height: 300px;
    justify-content: center;
  }

  .card__product-img-2 {
    top: 0px;
    display: flex;
    position: relative;
    max-width: 300px;
    max-height: 300px;
  }

  .card__content {
    display: flex;
    flex-direction: column;

    .card__name {
      color: #495fb8;
      font-size: 16px;
      font-weight: bold;
      font-family: 'Lexend Deca', sans-serif;
    }

    .card__description {
      font-size: 1rem;
      line-height: 2.6rem;
      font-weight: lighter;
      position: absolute;
      top: 0px;
    }

    .card__content-bottom {
      display: flex;
      justify-content: center;
      padding-top: 4px;
      padding-bottom: 0;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price {
        color: #2a6980;
        font-size: 10px;
        font-family: 'Lexend Deca', sans-serif;
      }
    }



    .card__content-apy {
      display: flex;
      justify-content: center;
      padding-top: 4px;
      padding-bottom: 0;
      position: absolute;
      top: 6px;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price_apy {
        color: #2a6980;
        font-size: 10px;
        font-family: 'Lexend Deca', sans-serif;
      }
    }

    .card__content-staked {
      display: flex;
      justify-content: center;
      padding-top: 17px;
      padding-bottom: 0;
      position: absolute;
      top: 6px;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price_staked {
        color: #2a6980;
        font-size: 10px;
        font-family: 'Lexend Deca', sans-serif;
      }
    }

    .card__content-pending {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
      padding-top: 10px;
      position: absolute;
      border: 2px solid #114265;
      width: 90%;
      background-size:16px 16px;
      background-color: rgba(51, 59, 76, 0.92);
      border-radius:1rem;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price_pending {
        color: #2a6980;
        font-size: 20px;
        font-family: 'Lexend Deca', sans-serif;
      }
    }
  }

  .card__footer {
    display: flex;
    align-items: center;
    border-top: 1px solid #2E405A;
    gap: 16px;
    padding-top: 16px;

    .card__avatar {
      display: flex;
      border-radius: 90px;
      border: 1px solid white;

      img {
        width: 33px;
      }
    }

    .card__autor--alt-color {
      color: white;
      cursor: pointer;

      &:hover {
        color: #00FFF8;
      }
    }
  }
}

//Transition
.fade-enter-active,
.fade-leave-active {
  transition: 0.25s ease-out;
}

.fade-leave-to,
.fade-enter-from {
  opacity: 0;
}


.form-container {
  background: radial-gradient(black 0%, transparent 10%) 0 0, radial-gradient(black 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0.1) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #2c313a;
  background-size: 16px 16px;
  border: 2px solid #114265;
  background-blend-mode: darken;
  padding: 20px;
  padding-top: 0px;
  border-radius: 20px;
  box-shadow: rgb(56 154 255 / 22%) 0px 0px 30px 3px !important;
  height: 350px !important;
}


.form-container .form-icon {
  font-size: 8px;
  text-align: center;
  background-color: #2c313a;
  line-height: 160px;
  width: 100px;
  height: 100px;
  padding: 10px;
  margin: 0 auto 15px;
  margin-top: -20px !important;
  border-radius: 32px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.form-container .title {
  color: #ac40ab;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 20px;
}

.form-container .form-horizontal .form-group {
  margin: 0 0 25px 0;
}

.form-container .form-horizontal .form-group label {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.form-container .form-horizontal .form-group{ margin: 0 0 25px 0; }
.form-container .form-horizontal .form-group label{
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}
.form-container .form-horizontal .form-control{
  color: #333;
  background: #ecf0f3 !important;
  font-size: 15px;
  height: 50px;
  padding: 20px;
  letter-spacing: 1px;
  border: none;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  display: inline-block;
  transition: all 0.3s ease 0s;
}
.form-container .form-horizontal .form-control:focus{
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  outline: none;
}
.form-container .form-horizontal .form-control::placeholder{
  color: #808080;
  font-size: 14px;
}
.form-container .form-horizontal .btn{
  color: #000;
  background-color: #ac40ab;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  padding: 12px 15px 11px;
  border-radius: 20px;
  box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px #fff;
  border: none;
  transition: all 0.5s ease 0s;
}
.form-container .form-horizontal .btn:hover,
.form-container .form-horizontal .btn:focus{
  color: #fff;
  letter-spacing: 3px;
  box-shadow: none;
  outline: none;
}

</style>
