import Vue from 'vue';
import Router from 'vue-router';
import Home from "./pages/Home";
import Create from "./pages/Create";
import SelectPools from "./pages/SelectPools";
import AllPools from "./pages/MyPools";
import LiquidityPools from "./pages/LiquidityPools";
import StakingPools from "./pages/StakingPools";
import CrowdFundingPools from "./pages/CrowdFundingPools";
import NftPools from "./pages/NftPools";
import CollectionWhitelist from "./pages/CollectionWhitelist";
import PoolAdmin from "./pages/PoolAdmin";
import MyNFTs from "./pages/MyNFTs";
import Explore from "./pages/Explore";
import Market from "./pages/Market";
import Treasury from "./pages/Treasury";
import Trade from "./pages/Trade";
import Collection from "./pages/Collection";
import Stake from "./pages/Stake";
import Vest from "./pages/Vest";
import CrowdFundAdmin from "./pages/CrowdFundAdmin"
import CrowdFund from "@/pages/CrowdFund";
import Docs from "./pages/Docs.vue";
import VestingAdmin from "./pages/VestingAdmin";
import DAO from "./pages/DAO";
import DAOs from "./pages/DAOs";

Vue.use(Router);

export default new Router({
    // Make sure the server can handle the history mode
    // If not, set it to hash (or delete the mode)
    // More info here: https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
    mode: "history",
    routes: [
        {
            path: "/",
            name: "home",
            component: Home
        },
        {
            path: "/pools",
            name: "pools",
            component: SelectPools
        },
        {
            path: "/mypools",
            name: "mypools",
            component: AllPools
        },
        {
            path: "/mynfts",
            name: "mynfts",
            component: MyNFTs
        },
        {
            path: "/explore",
            name: "explore",
            component: Explore
        },
        {
            path: "/daos",
            name: "daos",
            component: DAOs
        },
        {
            path: "/collection/:collection",
            name: "collection",
            component: Collection,
            props: true
        },
        {
            path: "/whitelist/:collection",
            name: "whitelist",
            component: CollectionWhitelist,
            props: true
        },
        {
            path: "/market",
            name: "market",
            component: Market
        },
        {
            path: "/liquiditypools",
            name: "liquiditypools",
            component: LiquidityPools
        },
        {
            path: "/stakingpools",
            name: "stakingpools",
            component: StakingPools
        },
        {
            path: "/crowdfundingpools",
            name: "crowdfundingpools",
            component: CrowdFundingPools
        },
        {
            path: "/nftpools",
            name: "nftpools",
            component: NftPools
        },
        {
            path: "/createpool",
            name: "createpool",
            component: Create
        },
        {
            path: "/about",
            name: "about",
            component: Docs
        },
        {
            path: "/docs",
            name: "docs",
            component: Docs
        },
        {
            path: "/trade/:pair",
            name: "trade",
            component: Trade,
            props: true
        },
        {
            path: "/stake/:poolid/:contract",
            name: "stake",
            component: Stake,
            props: true
        },
        {
            path: "/vest/:poolid/:contract",
            name: "vest",
            component: Vest,
            props: true
        },
        {
            path: "/treasury",
            name: "treasury",
            component: Treasury
        },
        {
            path: "/pool-nfo/:contract/:pool",
            name: "pooladmin",
            component: PoolAdmin,
            props: true
        },
        {
            path: "/crowdfund-nfo/:contract/:pool",
            name: "crowdfundadmin",
            component: CrowdFundAdmin,
            props: true
        },
        {
            path: "/vesting-nfo/:contract/:pool",
            name: "vestingadmin",
            component: VestingAdmin,
            props: true
        },
        {
            path: "/dao/:poolid",
            name: "dao",
            component: DAO,
            props: true
        },
        {
            path: "/crowdfund/:poolid",
            name: "crowdFund",
            component: CrowdFund,
            props: true
        },
        {
            path: "*",
            redirect: { path: "/" }
        }
    ],
    linkActiveClass: "active"
});
