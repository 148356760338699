<template>
  <div style="overflow-x: hidden" :style="$screen.width > 1024 ? 'margin-top: -85px' : 'margin-top: -84px'">

    <!--    Page loading animation section-->
    <div v-if="this.showLoadingModal === true" style="overflow: hidden;">

        <section>

          <transition name="fade">
          <div
              class="noscroll"
              style="height: 100vh; width:100vw; overflow-x: hidden !important; overflow-y: auto"
              :style="$screen.width < 420 ? 'padding-right: 12px !important;' : ''">

            <section class="hero" style="z-index: 2">
              <div class="hero-body header-bg3" style="z-index: 2"></div>
            </section>


            <section class="hero" :class="$screen.width > 1024 ? '' : ''" style="z-index: 2; height:100vh">

              <div class="columns mt-4">

                <div class="column" style="z-index: 2">

                  <div class="machineinterface">
                    <div class="monitor">
                      <div class="monitor-inner" style="min-height: calc(100vh);">
                        <div class="screen-cutout">
                          <div class="screen">

                            <div class="my-pools-shadow column" style="z-index: 2; padding: 0px !important; margin-top: 20px;">
                              <div class="card pools-bg cardborder pb-5 " style="z-index: 2">
                                <div class="card-content poolselect-bg mb-1">
                                  <section >
                                    <b-tabs
                                        v-model="activeTab"
                                        headerClass="headertest"
                                        class="font1"
                                        position="is-centered"
                                        type="is-toggle"
                                        expanded
                                    >
                                      <b-tab-item value="0" label="loading..."></b-tab-item>
                                    </b-tabs>
                                  </section>
                                </div>

                                <div v-if="$screen.width <= 576" class="loading-page-container">
                                  <div class="progress">
                                    <div class="inner">
                                      <img src="../assets/stake-factory-icon.svg" height="150" width="150" style="margin-top:25px;"/>
                                      <div class="water"></div>
                                    </div>
                                  </div>
                                </div>
                                <!-- SELECT POOLS SECTION -->
                                <section v-if="activeTab === '0'">

                                  <section class="hero">
                                    <div class="hero-body">
                                      <div class="container container2 animated fadeInUp">
                                        <div class="columns is-multiline is-mobile">



                                          <div id="web" class="column is-12-desktop is-12-tablet is-12-mobile" style="height: 430px;">
                                            <div class="center" style="width: 100%">
                                              <div class="center-box">
                                                <div class="center-items">
                                                  <div class="column is-full is-justify-content-center is-vcentered">
                                                    <article v-if="$screen.width > 576">
                                                      <aside>
                                                        <div style="position: relative;"><pre class="activity spinning" style="overflow: hidden; background: none;">|
+
|</pre></div>

                                                        <div class="blocks-gaps outer pt-2 pb-2">
                                                          <div class="inner">&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;&blk34;
                                                          </div>
                                                        </div>

                                                        <span>LOADING</span>
                                                      </aside>
                                                    </article>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                          </div>

                                          <div class="divider mt-0 is-centered"></div>
                                          <br/>

                                        </div>
                                      </div>
                                    </div>
                                  </section>


                                </section>




                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div style="margin-bottom: 10%"></div>

            </section>
          </div>
          </transition>
        </section>


    </div>

    <!--    Homepage shown after loading is completed-->
    <transition name="fade">
    <section v-if="this.showLoadingModal === false">


      <!--      bubbles-->
      <div
          id="style-10"
          v-if="this.showLoadingModal === false"
          style="height: 100vh; width:100vw; overflow-x: hidden !important; overflow-y: auto"
          :style="$screen.width < 420 ? 'padding-right: 12px !important;' : ''">

        <section class="hero" style="z-index: 2">
          <div class="hero-body header-bg3" style="z-index: 2"></div>
        </section>


        <section class="hero" :class="$screen.width > 1024 ? '' : ''" style="z-index: 2; height:100vh">

          <div class="columns mt-4">

            <div class="column" style="z-index: 2">

              <div class="machineinterface">
                <div class="monitor">
                  <div class="monitor-inner" style="min-height: calc(100vh);">
                    <div class="screen-cutout">
                      <div class="screen">

                        <div class="my-pools-shadow column" style="z-index: 2; padding: 0px !important; margin-top: 20px;">
                          <div class="card pools-bg cardborder pb-5 " style="z-index: 2">
                            <div class="card-content poolselect-bg mb-1">
                              <section >
                                <b-tabs
                                    v-model="activeTab"
                                    headerClass="headertest"
                                    class="font1"
                                    position="is-centered"
                                    type="is-toggle"
                                    expanded
                                >
                                  <b-tab-item value="0" label="Pools"></b-tab-item>
                                </b-tabs>
                              </section>
                            </div>

                            <!-- SELECT POOLS SECTION -->
                            <section v-if="activeTab === '0'">

                              <section class="hero">
                                <div class="hero-body">
                                  <div class="container container2 animated fadeInUp">
                                    <div class="columns is-multiline is-mobile">
                                      <div id="jp" class="column is-12-desktop is-12-tablet is-12-mobile" style="height: 430px;">
                                        <div class="center" style="width: 100%">
                                          <div class="center-box">
                                            <div class="center-items">
                                              <div class="column is-full is-justify-content-center is-vcentered">
                                                <article>
                                                  <aside>
                                                    <pre v-if="showSF === 1" class="cow is-hidden-mobile" style="overflow: hidden; background: none; margin-left: -260px;">
      _/_/_/    _/                _/                      _/_/_/_/                      _/
                                     _/        _/_/_/_/    _/_/_/  _/  _/      _/_/        _/        _/_/_/    _/_/_/  _/_/_/_/    _/_/    _/  _/_/  _/    _/
                                      _/_/      _/      _/    _/  _/_/      _/_/_/_/      _/_/_/  _/    _/  _/          _/      _/    _/  _/_/      _/    _/
                                         _/    _/      _/    _/  _/  _/    _/            _/      _/    _/  _/          _/      _/    _/  _/        _/    _/
                                  _/_/_/        _/_/    _/_/_/  _/    _/    _/_/_/      _/        _/_/_/    _/_/_/      _/_/    _/_/    _/          _/_/_/
                                                                                                                                                       _/
                                                                                                                                                  _/_/</pre>
                                                    <pre v-if="showSF === 2" class="cow is-hidden-mobile" style="overflow: hidden; background: none; margin-left: 0px;">
   .dMMMb dMMMMMMP .aMMMb  dMP dMP dMMMMMP         dMMMMMP .aMMMb  .aMMMb dMMMMMMP .aMMMb  dMMMMb  dMP dMP
  dMP" VP   dMP   dMP"dMP dMP.dMP dMP             dMP     dMP"dMP dMP"VMP   dMP   dMP"dMP dMP.dMP dMP.dMP
  VMMMb    dMP   dMMMMMP dMMMMK" dMMMP           dMMMP   dMMMMMP dMP       dMP   dMP dMP dMMMMK"  VMMMMP
dP .dMP   dMP   dMP dMP dMP"AMF dMP             dMP     dMP dMP dMP.aMP   dMP   dMP.aMP dMP"AMF dA .dMP
VMMMP"   dMP   dMP dMP dMP dMP dMMMMMP         dMP     dMP dMP  VMMMP"   dMP    VMMMP" dMP dMP  VMMMP"
                                                                                                           </pre>
                                                    <pre v-if="showSF === 3" class="cow is-hidden-mobile" style="overflow: hidden; background: none; margin-left: -220px;">
 dP"8   d8           888              888'Y88                    d8
                              C8b Y  d88    ,"Y88b 888 ee  ,e e,    888 ,'Y  ,"Y88b  e88'888  d88    e88 88e  888,8, Y8b Y888P
                               Y8b  d88888 "8" 888 888 P  d88 88b   888C8   "8" 888 d888  '8 d88888 d888 888b 888 "   Y8b Y8P
                              b Y8D  888   ,ee 888 888 b  888   ,   888 "   ,ee 888 Y888   ,  888   Y888 888P 888      Y8b Y
                              8edP   888   "88 888 888 8b  "YeeP"   888     "88 888  "88,e8'  888    "88 88"  888       888
                                                                                                                        888
                                                                                                                        888    </pre>
                                                    <pre v-if="showSF === 4" class="cow is-hidden-mobile" style="overflow: hidden; background: none; margin-left: -220px;">
  [.. ..    [..            [..                    [........                   [..
[..    [..  [..            [..                    [..                         [..
                             [..      [.[. [.   [..    [..  [..   [..         [..         [..       [...[.[. [.   [..    [. [...[..   [..
                              [..      [..   [..  [.. [.. [..  [.   [..      [......   [..  [..  [..     [..   [..  [..  [..    [.. [..
                                [..   [..  [..   [.. [.[..   [..... [..     [..      [..   [.. [..      [..  [..    [.. [..      [...
                          [..    [..  [..  [..   [.. [.. [.. [.             [..      [..   [..  [..     [..   [..  [..  [..       [..
                           [.. ..     [..   [.. [...[..  [..  [....        [..        [.. [...   [...   [..    [..    [...      [..
                                                                                                                                 [..     </pre>
                                                    <pre v-if="showSF === 5" class="cow is-hidden-mobile" style="overflow: hidden; background: none; margin-left: -170px; padding-bottom: 0px !important;">
d8b                     ,d8888b
    d8P             ?88                     88P'                       d8P
 d888888P            88b                 d888888P                   d888888P
                      .d888b,  ?88'   d888b8b    888  d88' d8888b      ?88'     d888b8b   d8888b  ?88'   d8888b   88bd88b?88   d8P
                      ?8b,     88P   d8P' ?88    888bd8P' d8b_,dP      88P     d8P' ?88  d8P' `P  88P   d8P' ?88  88P'  `d88   88
                        `?8b   88b   88b  ,88b  d88888b   88b         d88      88b  ,88b 88b      88b   88b  d88 d88     ?8(  d88
                     `?888P'   `?8b  `?88P'`88bd88' `?88b,`?888P'    d88'      `?88P'`88b`?888P'  `?8b  `?8888P'd88'     `?88P'?8b
                                                                                                                                )88
                                                                                                                               ,d8P
                                                                                                                            `?888P'</pre>
                                                    <pre v-if="showSF === 6" class="cow is-hidden-mobile" style="overflow: hidden; background: none; margin-left: 0px; padding-bottom: 0px !important;">  ░░░░░░░ ░░░░░░░░  ░░░░░  ░░   ░░ ░░░░░░░     ░░░░░░░  ░░░░░   ░░░░░░ ░░░░░░░░  ░░░░░░  ░░░░░░  ░░    ░░
 ▒▒         ▒▒    ▒▒   ▒▒ ▒▒  ▒▒  ▒▒          ▒▒      ▒▒   ▒▒ ▒▒         ▒▒    ▒▒    ▒▒ ▒▒   ▒▒  ▒▒  ▒▒
▒▒▒▒▒▒▒    ▒▒    ▒▒▒▒▒▒▒ ▒▒▒▒▒   ▒▒▒▒▒       ▒▒▒▒▒   ▒▒▒▒▒▒▒ ▒▒         ▒▒    ▒▒    ▒▒ ▒▒▒▒▒▒    ▒▒▒▒
    ▓▓    ▓▓    ▓▓   ▓▓ ▓▓  ▓▓  ▓▓          ▓▓      ▓▓   ▓▓ ▓▓         ▓▓    ▓▓    ▓▓ ▓▓   ▓▓    ▓▓
  ███████    ██    ██   ██ ██   ██ ███████     ██      ██   ██  ██████    ██     ██████  ██   ██    ██   </pre>
                                                    <pre v-if="showSF === 6" class="cow is-hidden-desktop is-hidden-tablet" style="overflow: hidden; background: none; margin-left: 0px; padding-bottom: 0px !important;">░▄▀▀░▀█▀▒▄▀▄░█▄▀▒██▀░░
▒▄██░▒█▒░█▀█░█▒█░█▄▄▒░
▒█▀▒▄▀▄░▄▀▀░▀█▀░▄▀▄▒█▀▄░▀▄▀
░█▀░█▀█░▀▄▄░▒█▒░▀▄▀░█▀▄░▒█▒</pre>
                                                  </aside>
                                                  <!-- full size image: https://images.unsplash.com/flagged/photo-1556339911-7ef846e7db43-->

                                                  <ul class="list-unstyled mt-6">
                                                    <li><a href="/liquiditypools"><div class="button">liquidity pools</div></a></li>
                                                    <li><a href="/stakingpools" ><div class="button">staking pools</div></a></li>
                                                    <li><a href="/nftpools" ><div class="button">nft pools</div></a></li>
                                                    <li><a href="/crowdfundingpools" ><div class="button">crowdfunding pools</div></a></li>
<!--                                                    <li><a href="/allpools"><div class="button">all pools</div></a></li>-->
                                                  </ul>
                                                </article>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                      </div>

                                      <div class="divider mt-0 is-centered"></div>
                                      <br/>

                                    </div>
                                  </div>
                                </div>
                              </section>


                            </section>




                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

          <div style="margin-bottom: 10%"></div>

        </section>
      </div>
    </section>

    </transition>
  </div>

</template>

<script>


//local state
export default {
  name: "CrowdFundAdminPage",
  components:{
  },
  data: function () {
    return {
      debug: false,
      activeTab: "0",
      currentPage: "0",
      activeOption: 0,
      showLoadingModal: true,
      currentDate: null,
      showSF: 6,
    };
  },
  methods: {

    setOption(option){
      this.activeOption = option;
    },

    coinName(namespace, name){
      let newnamespace = namespace+'.';
      let newname = name;
      if(namespace === null || namespace === 'null'){
        newnamespace = '';
      }
      if(name === null || name === 'null'){
        newname = '';
      }
      return newnamespace+newname;
    },



  },
  async created() {

    const name = localStorage.getItem("accountName");

    //Lets check if our user is logged in for the pool creation page, if not, lets redirect them to home page
    if (name === null || name === "" || name === "null") {
      if (this.debug) {
        console.log("No user local accountName found.");
      }
      await this.$router.push({path: `/`});
    }else {

      const t_name_payload = {
        name: name
      }

      await this.$store.dispatch("accounts/getCurrentBindings", t_name_payload);

      this.showSF = Math.floor(Math.random() * 6) + 1;

      //Close loading screen
      setTimeout(async () => {
        this.showLoadingModal = false;
      }, 500);
    }

  }

};
</script>

<style scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


.cow {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(15 47 50);
  background: -webkit-linear-gradient(#3e1046, #6e2a57) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.divider {
  border: none;
  height: 20px;
  width: 90%;
  height: 50px;

  border-bottom: 1px solid #224a84;
  box-shadow: 0 20px 20px -20px rgba(88, 116, 238, 0.56);
  margin: -50px auto 10px;
  margin-top: -30px !important;
}

.noscroll::-webkit-scrollbar {
  display: none;
}

.container2 {
  background-color: #1D1F21;
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  padding: 25px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  text-shadow: 0px 0px 4px rgb(38 32 44);
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), transparent 85%), radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%), linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 7px -4px #4f545f inset, 0 -2px 1px #5c6574 inset;
}

.hero.is-dark {
  background-color: #1D1F21
}

ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

li a {
  color: #c5c8c6;
}

.button:hover {
  color: #6cffbc;
}

li a:hover,
li a:visited,
li a:active {
  text-decoration: none;
  transition: .1s;
}

#style-10::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #200d31;
  border-radius: 10px;
}

#style-10::-webkit-scrollbar
{
  width: 10px;
  background-color: #2c2432;
}

#style-10::-webkit-scrollbar-thumb
{
  background-color: #2c2432;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
  rgba(0, 0, 0, .2) 25%,
  transparent 25%,
  transparent 50%,
  rgba(0, 0, 0, .2) 50%,
  rgba(0, 0, 0, .2) 75%,
  transparent 75%,
  transparent)
}


#google {
  color: #CC6666;
}

#google ul li a {
  color: #c5c8c6;
}

#google ul li a:hover {
  color: #CC6666;
}

#jp {
  color: #81A2BE;
}

#jp ul li a {
  color: #c5c8c6;
}

#jp ul li a:hover {
  color: #81A2BE;
}

#boards {
  color: #B5BD68;
}

#boards ul li a {
  color: #c5c8c6;
}

#boards ul li a:hover {
  color: #B5BD68;
}

#dev {
  color: #B294BB;
}

#dev ul li a {
  color: #c5c8c6;
}

#dev ul li a:hover {
  color: #B294BB;
}

#docs {
  color: #A64848;
}

#docs ul li a {
  color: #c5c8c6;
}

#docs ul li a:hover {
  color: #A64848;
}

#utils {
  color: #597C99;
}

#utils ul li a {
  color: #c5c8c6;
}

#utils ul li a:hover {
  color: #597C99;
}

#web {
  color: #7B8235;
}

#web ul li a {
  color: #c5c8c6;
}

#web ul li a:hover {
  color: #7B8235;
}



.c1 {
  color: #CC6666;
}

.c1 ul li a {
  color: #c5c8c6;
}

.c1 ul li a:hover {
  color: #CC6666;
}

.c2 {
  color: #81A2BE;
}

.c2 ul li a {
  color: #c5c8c6;
}

.c2 ul li a:hover {
  color: #81A2BE;
}

.c3 {
  color: #B5BD68;
}

.c3 ul li a {
  color: #c5c8c6;
}

.c3 ul li a:hover {
  color: #B5BD68;
}

.c4 {
  color: #B294BB;
}

.c4 ul li a {
  color: #c5c8c6;
}

.c4 ul li a:hover {
  color: #B294BB;
}

.c5 {
  color: #A64848;
}

.c5 ul li a {
  color: #c5c8c6;
}

.c5 ul li a:hover {
  color: #A64848;
}

.c6 {
  color: #597C99;
}

.c6 ul li a {
  color: #c5c8c6;
}

.c6 ul li a:hover {
  color: #597C99;
}

.c7 {
  color: #7B8235;
}

.c7 ul li a {
  color: #c5c8c6;
}

.c7 ul li a:hover {
  color: #7B8235;
}


.pinkish {
  color: #59c2b6;
}

.pinkish ul li a {
  color: #c5c8c6;
}

.pinkish ul li a:hover {
  color: #59c2b6;
}

@media (min-width: 544px) {
  .container {
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .container {
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  .container {
    font-size: 16px;
  }
}

.form-control {
  background-color: #1D1F21;
  display: none;
  padding: 0px;
  border: none;
  color: #c5c8c6;
}

input[type="search-input"]:focus {
  background-color: #1D1F21;
  color: #c5c8c6;
}

input[type="search-input"]:hover {
  background-color: #222426;
  color: #c5c8c6;
}

#searchform {
  display: block;
  text-align: center;
  width: 100%;
}

form {
  display: inline-block;
  width: 90%;
}

label {
  color: #CC6666;
  display: inline-block;
  float: left;
  padding-right: 1em;
}

input {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
  box-shadow: inset 0 30px 60px -12px rgba(50, 50, 93, 0.25), inset 0 18px 36px -18px rgba(0, 0, 0, 0.30);
}

.label {
  color: #3abb81;
  display: block;
  font-size: 1rem;
  font-weight: 700;
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient( #3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5% );
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 40px;
}


.machineinterface {
  display: block;
  width: 100%;
  margin: -80px 0px;
  position: relative;
}

.monitor {
  z-index: 2;
  display: block;
  width: 100%;
  position: relative;
  background-image: conic-gradient( #47485a 0 10.5%, #47485a 11% 11.5%, #47485a 12% 38%, #47485a 39% 61.5%, #47485a 62% 88%, #47485a 88.5% 89%, #47485a 89.25% );
  padding-bottom: 40px;
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
}

.monitor-inner {
  display: block;
  width: 95%;
  background-image: conic-gradient( #47485a 0 10.5%, #47485a 11% 11.5%, #47485a 12% 38%, #47485a 39% 61.5%, #47485a 62% 88%, #47485a 88.5% 89%, #47485a 89.25% );
  margin: auto;
  position: relative;
  top: 20px;
  left: 0;
  right: 0;
  border-radius: 5px;
  margin-top: -51px;
  padding-bottom: 40px;
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient( #3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5% );
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 40px;
}

.screen {
  display: block;
  width: 95%;
  background-color: #4F5555;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  margin-top: 68px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 30px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), transparent 85%), radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%), linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 7px -4px #4f545f inset, 0 -2px 1px #5c6574 inset;
}

.header-bg3 {
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
  height: 1px;
  padding-top: 0px;
}

.b-tabs .tabs {
  overflow: hidden !important;
}

/deep/ .b-tabs .tabs {
  overflow: hidden !important;
}

::v-deep .b-tabs .tabs {
  overflow: hidden !important;
}


.center-flex{
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.title-back {
  color: #ffffff;
  font-family: Source Code Pro,monospace !important;
  border-radius: 1rem;
  background: -webkit-linear-gradient(90deg, rgba(49, 56, 64, 0.89), rgba(55, 45, 69, 0.85));
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}


/* card background */
.home-card-bg {
  background: #ebeae5;
}

.inset-bg{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding-bottom: 2rem;
  border-radius: 100px;
}

.inset-bg2:active{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  width: 100%;
  height: 30px;
  position: relative;
  border-radius: 1rem;
  align-self: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 4px;
}


.col-title {
  color: #36c0ea;
  font-size: 14px;
}

.pending-title {
  color: #3cea36;
  font-size: 14px;
}

.pending-stat {
  color: #3cea36;
  font-size: 1em;
}

.pending-col{
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.col-shadow1{
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider{
  font-size: 12px;
}

.pool-name{
  font-size: 14px;
  color: #36c0ea;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: #222b3a;
}

.pool-main {
  background-color: #222b3a;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
}

.pool-section-title {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);

  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #46a9e7;
  font-family: Source Code Pro,monospace !important;
}


.topbackgroundanimation {
  position: absolute;
  height: 200px;
  width: 100%;
  z-index: 1;
}

/* border for item cards */
.cardborder {
  border-radius: 1rem;
  color: #000000;
}

.poolselect-bg {
  background-color: #222b3a !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}



/deep/ .tabs.is-toggle li.is-active a {
  background-color: #252f3e;
  color: #3abb81;
  z-index: 1;
  font-family: Source Code Pro,monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}


/deep/ .tabs.is-toggle {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  background-color: #252f3e;
}

/deep/ .tabs.is-toggle a {
  margin-bottom: 0;
  position: relative;
  font-family: Source Code Pro,monospace !important;
  border:none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  color: #476074;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: #222b3a;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: #222b3a;
  border:none;
  color: #00b89c;
}


#style-8::-webkit-scrollbar-track
{
  border: 1px solid black;
  background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar
{
  width: 10px;
  background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar-thumb
{
  background-color: #000000;
}



.pools-card{
  padding-top:.5rem;
  color: #3abb81;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow{
  box-shadow: rgba(0, 0, 0, 0.56) 8px -5px 40px;
  border-radius: 30px;
}

.stake-btn-col{
  display: flex;
  justify-content: center;
}

.btn{
  color: #87b6e5;
  background: rgba(3, 150, 255, 0.32);
  background-repeat: no-repeat;
  font-family: Source Code Pro,monospace !important;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}
.btn:focus,
.btn:hover{
  color: #fff;
  background: radial-gradient(circle at center,rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
}
@keyframes bubbles{
  100%{
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%,26% -96%,-1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}
@media only screen and (max-width: 767px){
  .btn{ margin-bottom: 30px; }
}

@media screen and (max-width: 769px), print {
  .screen-cutout {
    background-image: none !important;
    background-color: transparent !important;
  }

  /deep/ .super-select-dropdown {
    background-color: #333b4c !important;
    border: 1px solid !important;
    border-color: #2f597d !important;
    color: #67b6ef !important;
    width: 380px !important;
  }
}

/* page loading style */
.loaderstyle {
  z-index: 1;
  position: absolute !important;
}

#wraper {
  width: 400px;
  height: 350px;
  margin: 90px 11px;
  position: relative;
  border-radius: 40px;
}

#wraper2 {
  width: 450px;
  height: 350px;
  margin: 0 auto;
  position: relative;
  border-radius: 40px;
  margin-left: -36px;
}


</style>
<style lang="scss" scoped>

.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro,monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

$message-header-background-color: #e3f;


.loading-page-container {
  opacity: .95;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  z-index: 1;

}

.loading-page-container .progress {
  position: relative;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 6px solid #20182d;
  background-color: #2c2938;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: hard-light;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;

  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 1;
}

.loading-page-container .progress .inner {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: 50%;
  margin-left: -4px;
  margin-top: -1px;
  width: 196.5px;
  height: 196.5px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.loading-page-container .progress .inner .water {
  opacity: .55;
  position: absolute;
  z-index: 1;
  width: 200%;
  height: 200%;
  left: -50%;
  border-radius: 40%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  animation-name: spin;
  top: 55%;
  background: #32BAFA;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  box-shadow: 0 0 20px #152A4A;

}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  display:flex;
  width: 35px;
  height: 35px;
  border-radius: 0.5rem;
  color: #296992;
}
.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}



.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}



// for styles that do not have ascii characters setting the height
$height: 15px;

.outer {
  margin-bottom: $height;
  width: 100%;
  height: $height;
}

.plain {

  &.outer {
    border: 1px solid limegreen;
  }

  .inner {
    width: 90%;
    background-color: limegreen;
    height: $height;
  }
}

.borderless .inner {
  width: 90%;
  background-color: limegreen;
  height: $height;
}

.punctuation.outer {
  border: 1px solid limegreen;
  line-height: 1.35;
}

@-webkit-keyframes progress-bar-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes spinning {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes pulsing-opacity {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}


.plain .inner, .borderless .inner {
  animation: progress-bar-width steps(20) 5s infinite;
}

.pipes .inner, .blocks .inner, .blocks-gaps {
  animation: progress-bar-width 5s infinite;
  overflow: hidden;
  animation-timing-function: linear;
}

.blocks-gaps .inner {
  letter-spacing: 2px;
}

.activity {
  text-align: center;
  overflow: hidden !important;
}

.spinning {
  animation: spinning steps(8, end) 2s infinite;
}

.pulsing {
  animation: pulsing-opacity steps(3) 0.5s infinite alternate;
}


.main-timeline{
  font-family: "Source Code Pro", monospace;
  position: relative;
}
.main-timeline:before{
  content: '';
  width: 5px;
  height: 100%;
  border-left: 5px dashed #a6eac0;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 1;
}
.main-timeline:after{
  content: '';
  display: block;
  clear: both;
}
.main-timeline .timeline{
  width: calc(50% + 25px);
  padding: 0;
  margin: 0 0 0 50px;
  float: right;
  position: relative;
}
.main-timeline .timeline-content{
  color: #59c2b6;
  padding: 15px 60px 15px 75px;
  border-top: 3px solid #18082d;
  display: block;
  position: relative;
}
.main-timeline .timeline-content:hover{ text-decoration: none; }
.main-timeline .timeline-content:before,
.main-timeline .timeline-content:after{
  content: '';
  background: linear-gradient(to bottom, rgba(255, 69, 104, 0.69), rgba(253, 38, 97, 0.71));
  height: 100%;
  width: 50px;
  position: absolute;
  left: 0;
  top: 0;
}
.main-timeline .timeline-content:after{
  background: #3aba6c;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  left: auto;
  right: 0;
  top: -5px;
}
.main-timeline .timeline-icon{
  color: #3aba6c;
  font-size: 40px;
  text-shadow: 0 0 5px rgba(0,0,0,0.1);
  position: absolute;
  top: 20px;
  right: 0;
}
.main-timeline .title{
  color: rgba(253, 38, 97, 0.66);
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 7px 0;
}
.main-timeline .description{
  font-size: 14px;
  letter-spacing: 1px;
  text-align: justify;
  margin: 0;
}
.main-timeline .timeline:nth-child(even){
  margin: 0 50px 0 0;
  float: left;
}
.main-timeline .timeline:nth-child(even) .timeline-content{ padding: 15px 70px 15px 60px; }
.main-timeline .timeline:nth-child(even) .timeline-content:before{
  left: auto;
  right: 0;
}
.main-timeline .timeline:nth-child(even) .timeline-content:after{
  right: auto;
  left: 0;
}
.main-timeline .timeline:nth-child(even) .timeline-icon{
  right: auto;
  left: 0;
}
.main-timeline .timeline:nth-child(4n+2) .timeline-content:before{
  background: linear-gradient(to bottom, rgba(255, 138, 45, 0.58), rgba(254, 101, 41, 0.79));
}
.main-timeline .timeline:nth-child(4n+2) .title{ color: rgba(254, 101, 41, 0.56); }
.main-timeline .timeline:nth-child(4n+3) .timeline-content:before{
  background: linear-gradient(to bottom, rgba(1, 169, 218, 0.55), rgba(0, 118, 244, 0.64));
}
.main-timeline .timeline:nth-child(4n+3) .title{ color: rgba(0, 118, 244, 0.49); }
.main-timeline .timeline:nth-child(4n+4) .timeline-content:before{
  background: linear-gradient(to bottom, rgba(60, 236, 163, 0.58), rgba(17, 173, 159, 0.68));
}
.main-timeline .timeline:nth-child(4n+4) .title{ color: rgba(17, 173, 159, 0.6); }
@media screen and (max-width:767px){
  .main-timeline:before{
    transform: translateX(0);
    left: 22px;
  }
  .main-timeline .timeline,
  .main-timeline .timeline:nth-child(even){
    width: 100%;
  }
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(even) .timeline-content{
    padding: 15px 60px 15px 70px;
  }
  .main-timeline .timeline:nth-child(even) .timeline-content:before{
    right: auto;
    left: 0;
  }
  .main-timeline .timeline:nth-child(even) .timeline-content:after{
    right: 0;
    left: auto;
  }
  .main-timeline .timeline:nth-child(even) .timeline-icon{
    left: auto;
    right: 0;
  }
}
@media screen and (max-width:567px){
  .main-timeline .title{ font-size: 18px; }
}

</style>
<style lang="scss" scoped>
$background: rgb(10, 0, 173);
$background-gray: #434455;
$background-red: #2b2331;
$text-red: rgb(173, 0, 0);
$text-white: rgb(233, 244, 255);

@mixin center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

@mixin font {
  font-family: 'VT323', monospace;
  letter-spacing: 1.2px;
}

@mixin font-button {
  font-size: 19px;
  font-weight: 900;
}

@mixin box-shadow($h, $w) {
  box-shadow: $h * 1px $w * 1px 0 0 rgba(0,0,0,0.2);
}

@mixin text-title {
  text-align: center;
  color: $text-red;
}

html {
  background-color: $background;
  height: 100%;
  @include font;
}

.center {
  @include center-absolute;
  background-color: $background-gray;
  padding: 6px;
  height: 100%;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), transparent 85%), radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%), linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 7px -4px #4f545f inset, 0 -2px 1px #5c6574 inset;
  &-box {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    border: 2px solid black;
  }

  &-text{
    &-style {
      font-size: 20px;
      line-height: 1.3rem;
      width: 20rem;
    }
    &-title {
      @include text-title;
    }
  }

  &-button {
    margin-top: 15px;
  }

  &-items {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.button {
  margin: 2px 10px 20px;
  background-color: $background-red;
  color: white;
  @include box-shadow(8, 10);
  padding: 5px 5px;
  outline: none;
  &-text{
    border: 1px solid white;
    @include font-button;
    @include font;
    padding: 2px 12px;

    &:hover {
      cursor:pointer;
      background: $background-gray;
      color: $background-red;
    }
  }
}

</style>
<style lang="scss" scoped>
article {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 900px) {
    padding: 80px;
  }

  @media (max-width: 899px) {
    padding-bottom: 56px;
  }
  background-color: rgba(192, 192, 192, 0);
  color: #323232;
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  position: relative;
  & > * + * {
    margin-top: 1.25em;
  }

  &:after {
    content: "🥩";
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding-bottom: 0.25em;
    position: absolute;
    top: 0.75em;
    right: 0.75em;
    width: 1.75em;
    height: 1.75em;
    filter: grayscale(90%);
    background-color: transparent;
    border-left: 1px solid rgba(238, 238, 238, 0.16);
    border-top: 1px solid rgba(238, 238, 238, 0.06);
    border-right: 1px solid rgba(68, 68, 68, 0.41);
    border-bottom: 1px solid rgba(68, 68, 68, 0.39);
  }
}

.headline {
  font-size: 2.5em;
  font-weight: 700;
}

.subhead {
  font-size: 1.25em;
  margin-top: 0.25em;
}

.article-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    width: 3em;
    height: 3em;
    flex-shrink: 0;
  }

  div:first-of-type {
    margin-left: 0.75em;
    font-size: 0.875em;
    margin-right: auto;
    padding-right: 0.75em;
  }

  div:last-of-type {
    font-size: 0.875em;
  }
}

aside {
  padding: 1em;
  border-right: 1px solid rgba(238, 238, 238, 0.11);
  border-bottom: 1px solid rgba(238, 238, 238, 0.06);
  border-left: 1px solid rgba(68, 68, 68, 0.45);
  border-top: 1px solid rgba(68, 68, 68, 0.39);
  clear: both;
  text-shadow: 0 0 10px, 0 0 40px;
}

.tag {
  display: inline-block;
  margin-right: 0.25em;
  margin-top: 0.25em;
  border-right: 1px solid rgba(238, 238, 238, 0.19);
  border-bottom: 1px solid rgba(238, 238, 238, 0.13);
  border-left: 1px solid #444;
  border-top: 1px solid #444;
  padding: 0.125em 0.375em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

h2:not(.subhead) {
  font-size: 1.5em;
  font-weight: 700;
  padding-bottom: 0.375em;
  box-shadow: 0 1px 0 0 #444, 0 2px 0 0 #eee;
}

figure {
  background-color: #c0c0c0;
  box-shadow: 0 0 0 1px #eee, 0 0 0 2px #c0c0c0, 0 0 0 3px #444;
  position: relative;
  margin-bottom: 1.25em;
  width: calc(100% + 1.5em + 2em);
  text-shadow: 0 0 10px, 0 0 40px;
  @media (min-width: 800px) {
    max-width: 50%;
  }
  &:nth-of-type(odd) {
    float: left;
    margin-right: 1.5em;
    margin-left: -3em;
  }

  &:nth-of-type(even) {
    float: right;
    margin-left: 1.5em;
    margin-right: -3em;
  }

  &:after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    background-image: linear-gradient(
            to top,
            rgba(#ff9100, 0.2) 0%,
            rgba(#ffe630, 0.2) 60%
    ),
    linear-gradient(20deg, rgba(#ff0, 0.5) 0%, rgba(#ff0, 0) 35%);
    box-shadow: inset 0px 0px 100px rgba(0, 0, 20, 1);
    z-index: 1;
  }

  img {
    // opacity: 0;
    // filter: hue-rotate(90deg);
    // filter: sepia(20%) brightness(10%) contrast(130%)
  }
  figcaption {
    font-family: "DotGothic16", monospace;
    background-color: #6f4b80;
    box-shadow: inset 0 -1px 0 0 #020202;
    color: #eee;
    text-shadow: 0 0 10px, 0 0 40px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.25em;
    font-size: 0.875em;
    z-index: 2;
  }
}

</style>
