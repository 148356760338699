<template>
  <div class="card3d" v-bind:class="[flipped ? useexpand ? 'flip-container flipped' : 'flip-container flippedhm' : 'flip-container']">
    <div class="flipper">
      <div class="front">
        <div class="card form-container" style="height: 373px !important;" v-bind:class="[l_stake_balance > 0 ? 'highlight' : '']">
          <div  v-if="token_image !== '0' && token_image != 'http://www.myimage.com'" class="card__product-img">
            <b-tooltip type="is-dark" multilined :label="'Stake any NFT from the NFT collection \n'+pool_stake_token_id+' and earn '+pool_reward_token"><img :src="token_image" alt="NFT" style="margin-top: 10px; width: 220px;"/></b-tooltip>
          </div>
          <div v-if="token_image === '0'" class="card__product-img">
            <b-tooltip type="is-dark" multilined :label="'NFT STAKING POOL '+pool_name"><img src="../assets/nft-placeholder.svg" alt="stake" style="margin-top: 10px; margin-left: 44px; width: 150px;"></b-tooltip>
          </div>
          <div  v-if="token_image == 'http://www.myimage.com'" class="card__product-img">
            <b-tooltip type="is-dark" multilined :label="'Stake any NFT from the NFT collection \n'+pool_stake_token_id+' and earn '+pool_reward_token"><img src="../assets/marmalade-icon.svg" alt="NFT" style="margin-top: 10px; width: 160px;"/></b-tooltip>
          </div>
          <div class="card__content">
            <p class="card__name">{{ pool_name }} </p>
            <div class="card__content-bottom">
              <div v-if="is_stake_nft === false" class="card__price">
                <p><span style="color: #3bac81">stake</span>: <span style="color: #3a99bb">{{coinDisplayName(pool_stake_token)}}</span></p>
              </div>
              <div v-if="is_stake_nft === true" class="card__price">
                <p><span style="color: #3bac81">stake nfts from:</span>: <b-tooltip type="is-dark" multilined :label="'Stake any NFT from the '+pool_stake_token_id+' collection. Click here to view all Stakable NFTs.'"><span @click="clickOpenCollection()" class="clickcollection">{{pool_stake_token_id}}</span></b-tooltip></p>
              </div>
            </div>
            <div class="card__content-bottom">
              <div v-if="is_stake_nft === false" class="card__price">
                <p><span style="color: #3bac81">earn</span>: <span style="color: #3a99bb">{{pool_reward_token}}({{pool_reward_token_id}})</span></p>
              </div>
              <div v-if="is_stake_nft === true" class="card__price">
                <p><span style="color: #3bac81">earn</span>: <span style="color: #3a99bb">{{coinDisplayName(pool_reward_token)}}</span></p>
              </div>
            </div>
            <div v-if="show_extra_buttons === true" class="card__content-bottom2">
              <b-tooltip type="is-dark"
                         label="Open pool in extra tab.">
              <div @click="clickPoolWindow()" class="card__maximize">
                <b-icon
                    pack="fas"
                    icon="window-maximize"
                    size="is-small" style="position: relative; top:2px; ">
                </b-icon>️
              </div>
              </b-tooltip>
            </div>
            <div v-if="show_extra_buttons === true" class="card__content-bottom3">
              <b-tooltip type="is-dark"
                         label="Pool info page.">
              <div @click="clickPoolControls()" class="card__maximize">
                <b-icon
                    pack="fas"
                    icon="info-circle"
                    size="is-small" style="position: relative; top:2px; ">
                </b-icon>️
              </div>
              </b-tooltip>
            </div>
            <div class="card__content-apy">
              <div class="card__price_apy">
                <p><span style="color: #3bac81">apy</span>: <span style="color: #3a99bb">{{l_pool_apy}}%</span></p>
              </div>
            </div>
            <div class="card__content-staked">
              <div class="card__price_staked">
                <p><span style="color: #3bac81">wallet:</span>: <span style="color: #3a99bb">{{l_wallet_stake_balance}}</span></p>
              </div>
            </div>
            <div v-if="l_stake_pending_rewards > 0.0 && showPendingRewards === true" @click="showPendingRewards = !showPendingRewards" class="card__content-pending centered">
              <div class="card__price_pending">
                <p><span style="color: #3bac81">pending</span>: <span style="color: #3a99bb">{{l_stake_pending_rewards}}</span></p>
              </div>
            </div>
          </div>
        </div>
        <a v-if="show_flipper === true" v-on:click="flipped=true; $emit('flipped', true)" class="btn2" v-bind:class="[l_stake_balance > 0 ? 'highlight-span' : '']" href="#">
          <span> > </span>
        </a>
      </div>
      <div class="back">
        <slot name="back"></slot>

        <div class="card form-container" v-bind:class="[l_stake_balance > 0 ? 'highlight' : '']">

          <div class="back-col-main">

            <div class="card__content-apy-back">
              <div class="card__price_apy_back">
                <p><span style="color: #3bac81">{{ pool_name }}</span></p>
              </div>
            </div>

            <div class="columns mb-0 back-col" style="position: relative; top: 5px; background-color: rgba(38,43,56,1); border-radius: 1rem;">
              <div v-if="accountApproved === true" class="column is-align-self-center back-card-stat-col" style="margin-left: 4%; margin-right: 1%;">
                <div class="columns">
                  <div class="column"  v-bind:class="[$screen.width > 768 ? 'stake-col-lg' : 'stake-col-sm']">
                    <div class="field-body">
                      <div class="field has-addons">
                        <p class="control is-expanded back-col-text">
                          PENDING: {{l_stake_pending_rewards}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns mt-0 mb-1 back-col" style="position: relative; top: 10px; background-color: rgba(38,43,56,1); border-radius: 1rem;">
              <div v-if="accountApproved === true" class="column is-align-self-center back-card-stat-col" style="margin-left: 4%; margin-right: 1%;">
                <div class="columns">
                  <div class="column"  v-bind:class="[$screen.width > 768 ? 'stake-col-lg' : 'stake-col-sm']">
                    <div class="field-body">
                      <div class="field has-addons">
                        <p class="control is-expanded back-col-text">
                          STAKED: {{l_stake_balance}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns mt-1 mb-1 back-col" style="position: relative; top: 10px; background-color: rgba(38,43,56,1); border-radius: 1rem;">
              <div v-if="accountApproved === true" class="column is-align-self-center back-card-stat-col" style="margin-left: 4%; margin-right: 1%;">
                <div class="columns">
                  <div class="column"  v-bind:class="[$screen.width > 768 ? 'stake-col-lg' : 'stake-col-sm']">
                    <div class="field-body">
                      <div class="field has-addons">
                        <p class="control is-expanded back-col-text">
                          EARNED: {{l_stake_rewards}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div v-if="accountApproved === false" class="columns mt-3 mb-3 pending-col" style="position: relative; top: 10px; background-color: rgba(38,43,56,1); border-radius: 1rem;">
            <div  class="column is-align-self-center stake-btn-col" style="margin-left: 4%; margin-right: 1%;">
              <div class="columns">
                <div class="column"  v-bind:class="[$screen.width > 768 ? 'stake-col-lg' : 'stake-col-sm']">
                  <div class="field-body">
                    <div class="field has-addons">
                      <div v-if="accountApproved === true" class="column is-align-self-center stake-btn-col">
                        <div class="btn btn-lg stake-btn" style="border-radius: 1rem !important;">
                          <span>CONNECT WALLET</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="columns mt-1 mb-0 pending-col"
               style="position: relative; top: 10px; background-color: rgba(38,43,56,1); border-radius: 1rem;">
            <!-- if connected pool buttons-->
<!--            <div v-if="accountApproved === true && is_pool_owner === false" class="column is-align-self-center stake-btn-col" style="margin-left: 4%; margin-right: 1%;">-->
<!--              <div class="columns">-->
<!--                <div class="column"  v-bind:class="[$screen.width > 768 ? 'stake-col-lg' : 'stake-col-sm']">-->
<!--                  <div class="field-body">-->
<!--                    <div class="field has-addons">-->
<!--                      <p class="control is-expanded">-->
<!--                        <b-select-->
<!--                            placeholder="Select NFT"-->
<!--                            v-model="nftidtostake"-->
<!--                            size="is-small"-->
<!--                            expanded>-->
<!--                          <optgroup label="CAN STAKE:">-->
<!--                            <option-->
<!--                                style="color:#3cbd83"-->
<!--                                v-for="option in usernfts"-->
<!--                                :value="option.id"-->
<!--                                :key="option.id">-->
<!--                              {{ option.id }}-->
<!--                            </option>-->
<!--                          </optgroup>-->
<!--                          <optgroup label="CAN WITHDRAW:">-->
<!--                            <option-->
<!--                                style="color:#3cbd83"-->
<!--                                v-for="option in userstakednfts"-->
<!--                                :value="option.id"-->
<!--                                :key="option.id">-->
<!--                              {{ option.id }}-->
<!--                            </option>-->
<!--                          </optgroup>-->

<!--                        </b-select>-->
<!--                      </p>-->

<!--                      <div @click="clickStake(pool_contract)" class="btn stake-btn-2"-->
<!--                           style="border-bottom-right-radius: 1rem; border-top-right-radius: 1rem; width: 35%; overflow: hidden;">-->
<!--                        <span class="font5">STAKE</span>-->
<!--                      </div>-->

<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="columns">-->
<!--                <div class="column" v-bind:class="[$screen.width > 768 ? 'withdraw-col-lg' : 'withdraw-col-sm']">-->
<!--                  <b-tooltip type="is-dark" multilined :label="'Withdraw a Staked NFT and claim pending rewards'">-->
<!--                  <div @click="clickWithdraw(pool_contract)"  class="btn btn-lg stake-btn" style="border-radius: 1rem;" v-bind:class="[$screen.width > 768 ? 'withdraw-btn-lg' : 'withdraw-btn-sm']">-->
<!--                    <span>WITHDRAW</span>-->
<!--                  </div>-->
<!--                  </b-tooltip>-->
<!--                </div>-->
<!--                <div class="column" style="padding-left: 0px;">-->
<!--                  <b-tooltip type="is-dark" multilined :label="'Claim pending rewards'">-->
<!--                  <div @click="clickClaimRewards(pool_contract)" class="btn btn-lg stake-btn" style="border-radius: 1rem;" v-bind:class="[$screen.width > 768 ? 'claim-btn-lg' : 'claim-btn-sm']">-->
<!--                    <span>CLAIM</span>-->
<!--                  </div>-->
<!--                  </b-tooltip>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

            <div v-if="accountApproved === true && is_pool_owner === false" class="column is-align-self-center stake-btn-col" style="margin-left: 0%; margin-right: 0%;">
              <div class="columns is-flex-direction-column">
                <div class="column">
                  <b-select
                      placeholder="Select NFT"
                      v-model="nftidtostake"
                      size="is-small"
                      expanded>
                    <optgroup label="CAN STAKE:">
                      <option
                          style="color:#3cbd83"
                          v-for="option in usernfts"
                          :value="option.id"
                          :key="option.id">
                        {{ option.id }}
                      </option>
                    </optgroup>
                    <optgroup label="CAN WITHDRAW:">
                      <option
                          style="color:#3cbd83"
                          v-for="option in userstakednfts"
                          :value="option.id"
                          :key="option.id">
                        {{ option.id }}
                      </option>
                    </optgroup>

                  </b-select>
                </div>
                <div class="column">
                  <div class="columns">
                    <div class="column">
                      <div @click="clickStake(pool_contract)"  class="btn btn-lg stake-btn" style="border-radius: 1rem;" v-bind:class="[$screen.width > 768 ? 'withdraw-btn-lg' : 'withdraw-btn-sm']">
                        <span>STAKE</span>
                      </div>
                    </div>
                    <div class="column">
                      <div @click="clickWithdraw(pool_contract)"  class="btn btn-lg stake-btn" style="border-radius: 1rem;" v-bind:class="[$screen.width > 768 ? 'withdraw-btn-lg' : 'withdraw-btn-sm']">
                        <span>WITHDRAW</span>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div @click="clickClaimRewards(pool_contract)"  class="btn btn-lg stake-btn" style="border-radius: 1rem;" v-bind:class="[$screen.width > 768 ? 'withdraw-btn-lg' : 'withdraw-btn-sm']">
                        <span>CLAIM</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="accountApproved === false" class="columns mt-6 mb-5 pending-col"
               style="position: relative; top: 10px; background-color: rgba(38,43,56,1); border-radius: 1rem;">
            <div class="column is-align-self-center stake-btn-col">
              <div class="btn btn-lg stake-btn" style="border-radius: 1rem !important;">
                <span>CONNECT WALLET</span>
              </div>
            </div>
          </div>

          <div v-if="accountApproved === true && is_pool_owner === true" class="column is-align-self-center stake-btn-col" style="height: 100px; padding-top: 50px; padding-bottom: 107px;">
            <div @click="clickPoolControls" class="btn btn-lg stake-btn" style="border-radius: 1rem !important;">
              <p>POOL ADMIN</p>
            </div>
          </div>


        </div>
        <a v-if="show_flipper === true" v-on:click="flipped=false; $emit('flipped', false)" class="btn2" v-bind:class="[l_stake_balance > 0 ? 'highlight-span' : '']" href="#">
          <span> &lt; </span>
        </a>
        <a v-if="show_flipper === true" v-on:click="flipped=false; $emit('flipped', false)" class="btn2" v-bind:class="[l_stake_balance > 0 ? 'highlight-span' : '']" href="#">
          <span> &lt; </span>
        </a>
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";
// import Accordion from "./accordion.vue";
// import AccordionItem from "./accordion-item";

export default {
  name: "FlipCard",
  components: {
  },
  props: {
    accountApproved: {
      type: Boolean,
      default: false,
    },
    pool_name: {
      type: String,
      default: "0",
    },
    pool_id: {
      type: [String, Number],
      default: "0"
    },
    user_account: {
      type: String,
      default: "0",
    },
    pool_apy: {
      type: [String, Number],
      default: "0",
    },
    pool_balance: {
      type: [String, Number],
      default: "0",
    },
    pool_claim_wait_seconds: {
      type: [String, Number],
      default: "0",
    },
    pool_end_time: {
      type: [String, Object],
      default: "0",
    },
    pool_has_vesting_connection: {
      type: Boolean,
      default: false,
    },
    pool_initialized: {
      type: Boolean,
      default: false,
    },
    pool_paid: {
      type: [String, Number],
      default: "0",
    },
    pool_contract: {
      type: String,
      default: "0",
    },
    pool_stake_token: {
      type: [String, Object],
      default: "0",
    },
    pool_reward_token: {
      type: [String, Object],
      default: "0",
    },
    pool_reward_token_id: {
      type: String,
      default: "0",
    },
    pool_stake_token_id: {
      type: String,
      default: "0",
    },
    pool_stakers: {
      type: [String, Number],
      default: "0",
    },
    pool_start_time: {
      type: [String, Object],
      default: "0",
    },
    pool_tokens_locked: {
      type: [String, Number],
      default: "0",
    },
    pool_vesting_pool_id: {
      type: [String, Number],
      default: "0",
    },
    pool_withdraw_duration: {
      type: [String, Object],
      default: "0",
    },
    stake_last_claimed: {
      type: [String, Object],
      default: "0",
    },
    stake_last_updated: {
      type: [String, Object],
      default: "0",
    },
    stake_last_withdraw: {
      type: [String, Object],
      default: "0",
    },
    stake_start_time: {
      type: [String, Object],
      default: "0",
    },
    stake_balance: {
      type: [String, Number],
      default: "0",
    },
    stake_rewards: {
      type: [String, Number],
      default: "0",
    },
    stake_pending_rewards: {
      type: [String, Number],
      default: "0",
    },
    token_image: {
      type: String,
      default: "0",
    },
    stake_token_image: {
      type: String,
      default: "0",
    },
    is_stake_nft: {
      type: Boolean,
      default: false,
    },
    show_flipper: {
      type: Boolean,
      default: true,
    },
    wallet_stake_balance: {
      type: [String, Number],
      default: "0",
    },
    is_pool_owner: {
      type: Boolean,
      default: false,
    },
    show_extra_buttons: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      debug: true,
      flipped: false,
      isMakingTransaction: false,
      isMakingPoolCardTransaction: false,
      isStaking: false,
      isStaking_poolid: null,
      isStaking_amount: null,
      isWithdrawing: false,
      isWithdrawing_poolid: null,
      isWithdrawing_amount: null,
      isClaiming: false,
      isClaiming_poolid: null,
      isClaiming_amount: null,
      showStakeControls: false,
      nftidtostake: null,
      stakeAmount: 1.0,
      useexpand: false,
      card: this.$el,
      showPendingRewards: true,
      bounds: 0,
      usernfts: [],
      userstakednfts: [],
      //Props
      l_wallet_stake_balance: this.wallet_stake_balance,
      l_stake_pending_rewards: this.stake_pending_rewards,
      l_pool_tokens_locked: this.pool_tokens_locked,
      lpool_endtime: this.pool_endtime,
      l_stake_rewards: this.stake_rewards,
      l_pool_apy: this.pool_apy,
      l_stake_balance: this.stake_balance,
      //Fungiv2 Polyfungiv1
      allPoolsFactoryStakeFungiv2Polyfungiv1: [],
      allActivePoolsFactoryStakeFungiv2Polyfungiv1:[],
      allActivePoolsIdsFungiv2Polyfungiv1: [],
      allUserPoolsFactoryStakeFungiv2Polyfungiv1: [],
      allUserPoolsIdsFungiv2Polyfungiv1:[],
      allPoolsToShowFactoryStakeFungiv2Polyfungiv1: [],
      allPoolIdsUserIsNotStakedInFungiv2Polyfungiv1:[],
      //Polyfungiv1 Fungiv2
      allPoolsFactoryStakePolyfungiv1Fungiv2: [],
      allActivePoolsFactoryStakePolyfungiv1Fungiv2:[],
      allActivePoolsIdsPolyfungiv1Fungiv2: [],
      allUserPoolsFactoryStakePolyfungiv1Fungiv2: [],
      allUserPoolsIdsPolyfungiv1Fungiv2:[],
      allPoolsToShowFactoryStakePolyfungiv1Fungiv2: [],
      allPoolIdsUserIsNotStakedInPolyfungiv1Fungiv2:[],
      //FNF Fungiv2
      allPoolsFactoryStakeFNFFungiv2: [],
      allActivePoolsFactoryStakeFNFFungiv2:[],
      allActivePoolsIdsFNFFungiv2: [],
      allUserPoolsFactoryStakeFNFFungiv2: [],
      allUserPoolsIdsFNFFungiv2:[],
      allPoolsToShowFactoryStakeFNFFungiv2: [],
      allPoolIdsUserIsNotStakedInFNFFungiv2:[],
    };
  },
  watch: {
    //lets watch our transactionPolling variable
    transactionPolling: async function (newValue, oldValue) {
      if (this.debug) {
        console.log("flipcard getTransactionPolling newValue: " + newValue);
        console.log("flipcard getTransactionPolling oldValue: " + oldValue);
      }


      if (newValue === true && this.isMakingPoolCardTransaction === true) {

        this.isMakingTransaction = true;

        let message1 = "Confirming new blockchain transaction";
        if (this.isStaking === true) {
          message1 = "Staking " + this.isStaking_amount + " in pool "+this.isStaking_poolid;
        } else if (this.isClaiming === true) {
          message1 = "Claiming " + this.isClaiming_amount + " rewards from pool "+this.isClaiming_poolid;
        } else if (this.isWithdrawing === true) {
          message1 = "Withdrawing " + this.isWithdrawing_amount + " from pool "+this.isWithdrawing_poolid;
        }

        this.$buefy.toast.open({
          duration: 10000,
          message: message1,
          type: "is-info",
          pauseOnHover: true
        });

      } else if (newValue === false && this.isMakingPoolCardTransaction === true) {

        this.isMakingTransaction = false;
        this.isMakingPoolCardTransaction = false;


        let message = "Transaction succeeded";
        if (this.isStaking === true) {
          message = "Staked " + this.isStaking_amount + " in pool "+this.isStaking_poolid;
        } else if (this.isClaiming === true) {
          message = "Claimed " + this.isClaiming_amount + " rewards from pool "+this.isClaiming_poolid;
        } else if (this.isWithdrawing === true) {
          message = "Withdrew " + this.isWithdrawing_amount + " from pool "+this.isWithdrawing_poolid;
        }


        //Transaction success toast
        if (this.getTransactionFailed() === false) {

          this.$buefy.toast.open({
            duration: 10000,
            message: message,
            position: "is-top",
            type: "is-success"
          });

          this.isStaking = false;
          this.isClaiming = false;
          this.isWithdrawing = false;
        }else if (this.getTransactionFailed() === true) {

          let message2 = "Transaction failed";
          if (this.isStaking === true) {
            message2 = "Failed to stake into pool "+this.isStaking_poolid;
          } else if (this.isClaiming === true) {
            message2 = "Failed to claim from pool "+this.isClaiming_poolid;
          } else if (this.isWithdrawing === true) {
            message2 = "Failed to withdraw from pool "+this.isWithdrawing_poolid;
          }

          this.$buefy.toast.open({
            duration: 10000,
            message: message2,
            position: "is-top",
            type: "is-danger"
          });

          this.isStaking = false;
          this.isClaiming = false;
          this.isWithdrawing = false;
        }
      }


      if (newValue === false && oldValue === true) {
        //this.showLoadingModal = true;
        console.log("FLIP CARD RESCANNING POOLS!!!!")
        await this.rescanPool();
        //this.showLoadingModal = false;
      }

    }
  },
  computed: {
    //import state variables from our vue store
    ...mapState("accounts", ["transactionPolling"]),

  },
  methods: {
    ...mapActions("accounts", ["getURIpolyfungiv1", "getURIpolyfungiv1Image"]),
    ...mapGetters("accounts", ["getAccountExists", "getAccountConfirmed", "getAllPoolsFactoryStakeFungiv2", "getUserPoolsFactoryStakeFungiv2", "getCurrentSignRequest", "getTransactionFailed", "getAllPoolsFactoryStakeFungiv2Polyfungiv1", "getUserPoolsFactoryStakeFungiv2Polyfungiv1"]),

    async clickOpenCollection(){
      let routeData = this.$router.resolve({path: "/collection/" + this.pool_stake_token_id});
      window.open(routeData.href, "_blank");
    },

    coinDisplayName(coin){
      let name = coin;
      if(coin === 'coin'){
        name = 'KDA';
      }else if(coin === 'free.stake-token'){
        name = 'STEAK';
      }
      return name;
    },

    number_test(n) {
      const result = (n - Math.floor(n)) !== 0;

      if (result) {
        if (this.debug) {
          console.log("Contains Decimal Place");
        }
        return true;
      } else {
        if (this.debug) {
          console.log("No decimal place");
        }
        return false;
      }

    },

    coinName(namespace, name){
      let newnamespace = namespace+'.';
      let newname = name;
      if(namespace === null || namespace === 'null'){
        newnamespace = '';
      }
      if(name === null || name === 'null'){
        newname = '';
      }
      return newnamespace+newname;
    },

    reduceBalance(balance, prec = 6){
      if (balance) {
        if (balance.int) balance = balance.int;
        if (balance.decimal) balance = balance.decimal;

        if (parseFloat(balance) % 1 === 0) {
          return parseInt(balance);
        }

        return Math.trunc(parseFloat(balance) * Math.pow(10, prec)) / Math.pow(10, prec);
      }

      if (balance === 0) return 0;
    },

    async clickPoolControls(){
      let routeData = this.$router.resolve({path: "/pool-nfo/" + this.pool_contract +"/"+this.pool_id});
      window.open(routeData.href, "_blank");
    },

    async clickPoolWindow(){
      let routeData = this.$router.resolve({path: "/stake/" + this.pool_id +"/"+this.pool_contract});
      window.open(routeData.href, "_blank");
    },

    async clickWithdraw(pool_contract){
      if(this.debug){
        console.log("CLAIMING POOL REWARDS")
        console.log(pool_contract);
      }

      const payload = {
        poolID: this.pool_id,
        accountName: this.user_account,
        moduleName: this.pool_stake_token,
        nftID: this.nftidtostake
      }

      if(this.debug){
        console.log(payload);
      }

      this.isWithdrawing = true;
      this.isWithdrawing_poolid = this.pool_name;
      this.isWithdrawing_amount = this.l_stake_pending_rewards.toString()+" "+this.pool_reward_token+" and "+this.l_stake_balance+" "+this.pool_stake_token;
      this.isMakingPoolCardTransaction = true;

      this.isStaking = false;
      this.isClaiming = false;

      if(pool_contract === "factory-stake-fungiv2-polyfungiv1"){
        this.$emit('showTxModal', true);
        await this.$store.dispatch("accounts/withdrawFactoryStakeFungiv2Polyfungiv1", payload);
      }else if(pool_contract === "factory-stake-polyfungiv1-fungiv2"){
        this.$emit('showTxModal', true);
        await this.$store.dispatch("accounts/withdrawFactoryStakePolyfungiv1Fungiv2", payload);
      }else if(pool_contract === "factory-stake-fnf-fungiv2"){
        this.$emit('showTxModal', true);
        await this.$store.dispatch("accounts/withdrawFactoryStakeFNFFungiv2", payload);
      }else if(pool_contract === "factory-stake-polyfungiv2-fungiv2"){
        this.$emit('showTxModal', true);
        await this.$store.dispatch("accounts/withdrawFactoryStakePolyFungiv2Fungiv2", payload);
      }



    },

    async clickClaimRewards(pool_contract){
      if(this.debug){
        console.log("CLAIMING POOL REWARDS")
        console.log(pool_contract);
      }

      const payload = {
        poolID: this.pool_id,
        accountName: this.user_account,
        moduleName: this.pool_stake_token
      }

      if(this.debug){
        console.log(payload);
      }

      this.isClaiming = true;
      this.isClaiming_poolid = this.pool_name;
      this.isClaiming_amount = this.l_stake_pending_rewards.toString()+" "+this.pool_reward_token;
      this.isMakingPoolCardTransaction = true;

      this.isStaking = false;
      this.isWithdrawing = false;

      if(pool_contract === "factory-stake-fungiv2-polyfungiv1"){
        this.$emit('showTxModal', true);
        await this.$store.dispatch("accounts/claimRewardsFactoryStakeFungiv2Polyfungiv1", payload);
      }else if(pool_contract === "factory-stake-polyfungiv1-fungiv2"){
        this.$emit('showTxModal', true);
        await this.$store.dispatch("accounts/claimRewardsFactoryStakePolyfungiv1Fungiv2", payload);
      }else if(pool_contract === "factory-stake-fnf-fungiv2"){
        this.$emit('showTxModal', true);
        await this.$store.dispatch("accounts/claimRewardsFactoryStakeFNFFungiv2", payload);
      }else if(pool_contract === "factory-stake-polyfungiv2-fungiv2"){
        this.$emit('showTxModal', true);
        await this.$store.dispatch("accounts/claimRewardsFactoryStakePolyFungiv2Fungiv2", payload);
      }

    },

    async clickStake(pool_contract) {

      if(this.debug){
        console.log("CREATING NEW STAKE")
        console.log(pool_contract);
      }



      this.isStaking = true;
      this.isStaking_poolid = this.pool_name;
      this.isStaking_amount = this.stakeAmount+ " "+this.pool_stake_token;
      this.isMakingPoolCardTransaction = true;
      this.isClaiming = false;
      this.isWithdrawing = false;

      if(pool_contract === "factory-stake-fungiv2-polyfungiv1"){

        const payload = {
          poolID: this.pool_id,
          amount: this.stakeAmount,
          accountName: this.user_account,
          moduleName: this.pool_stake_token
        }


        if(this.debug){
          console.log(payload);
        }

        this.$emit('showTxModal', true);
        await this.$store.dispatch("accounts/stakeFactoryStakeFungiv2Polyfungiv1", payload);
      }else if(pool_contract === "factory-stake-polyfungiv1-fungiv2"){

        const payload = {
          poolID: this.pool_id,
          amount: this.stakeAmount,
          accountName: this.user_account,
          moduleName: this.pool_stake_token,
          nftID: this.pool_stake_token_id
        }

        if(this.debug){
          console.log(payload);
        }

        this.$emit('showTxModal', true);
        await this.$store.dispatch("accounts/stakeFactoryStakePolyfungiv1Fungiv2", payload);
      }else if(pool_contract === "factory-stake-fnf-fungiv2"){

        const payload = {
          poolID: this.pool_id,
          amount: 1.0,
          accountName: this.user_account,
          moduleName: this.pool_stake_token,
          //nftID: this.pool_stake_token_id,
          nftID: this.nftidtostake,
        }

        if(this.debug){
          console.log(payload);
        }

        this.$emit('showTxModal', true);
        await this.$store.dispatch("accounts/stakeFactoryStakeFNFFungiv2", payload);
      } else if(pool_contract === "factory-stake-polyfungiv2-fungiv2"){

        const payload = {
          poolID: this.pool_id,
          amount: 1.0,
          accountName: this.user_account,
          moduleName: this.pool_stake_token,
          //nftID: this.pool_stake_token_id,
          nftID: this.nftidtostake,
        }

        if(this.debug){
          console.log(payload);
        }

        this.$emit('showTxModal', true);
        await this.$store.dispatch("accounts/stakeFactoryStakePolyFungiv2Fungiv2", payload);
      }

    },


    async rescanPool(){

      //Fungiv2 Polyfungiv1 reset
      this.allPoolsToShowFactoryStakeFungiv2Polyfungiv1 = [];
      this.allActivePoolsIdsFungiv2Polyfungiv1 = [];
      this.allUserPoolsIdsFungiv2Polyfungiv1 = [];
      this.allPoolIdsUserIsNotStakedInFungiv2Polyfungiv1 = [];
      this.allPoolsFactoryStakeFungiv2Polyfungiv1 = [];
      this.allUserPoolsFactoryStakeFungiv2Polyfungiv1 = [];
      this.allActivePoolsFactoryStakeFungiv2Polyfungiv1 = [];

      //Polyfungiv1 Fungiv2 reset
      this.allPoolsToShowFactoryStakePolyfungiv1Fungiv2 = [];
      this.allActivePoolsIdsPolyfungiv1Fungiv2 = [];
      this.allUserPoolsIdsPolyfungiv1Fungiv2 = [];
      this.allPoolIdsUserIsNotStakedInPolyfungiv1Fungiv2 = [];
      this.allPoolsFactoryStakePolyfungiv1Fungiv2 = [];
      this.allUserPoolsFactoryStakePolyfungiv1Fungiv2 = [];
      this.allActivePoolsFactoryStakePolyfungiv1Fungiv2 = [];

      //Polyfungiv1 Fungiv2 reset
      this.allPoolsToShowFactoryStakeFNFFungiv2 = [];
      this.allActivePoolsIdsFNFFungiv2 = [];
      this.allUserPoolsIdsFNFFungiv2 = [];
      this.allPoolIdsUserIsNotStakedInFNFFungiv2 = [];
      this.allPoolsFactoryStakeFNFFungiv2 = [];
      this.allUserPoolsFactoryStakeFNFFungiv2 = [];
      this.allActivePoolsFactoryStakeFNFFungiv2 = [];

      if(this.pool_contract === "factory-stake-fungiv2-polyfungiv1"){

        //Fungiv2 Polyfungiv1 bind
        //Bind local state for factory-stake-fungiv2-polyfungiv1
        this.allPoolsFactoryStakeFungiv2Polyfungiv1 = this.getAllPoolsFactoryStakeFungiv2Polyfungiv1();
        //Bind local state for user at factory-stake-fungiv2-polyfungiv1
        this.allUserPoolsFactoryStakeFungiv2Polyfungiv1 = this.getUserPoolsFactoryStakeFungiv2Polyfungiv1();

        //fungiv2 polyfungiv1

        //Lets make a list of active fungiv2 polyfungiv1 pools
        //First we got through the list of all fungiv2 polyfungiv1 pools if its not empty
        if (this.allPoolsFactoryStakeFungiv2Polyfungiv1.length > 0) {

          //We go through pools in reverse because its better
          for (let i = this.allPoolsFactoryStakeFungiv2Polyfungiv1.length - 1; i >= 0; i--) {

            if (this.debug === true) {
              console.log("STEP 1) POOL ID:");
              console.log(this.allPoolsFactoryStakeFungiv2Polyfungiv1[i].id)
            }

            //Now lets check and see if our pool is 'active' or not, if so, lets add it to our list of active pools
            if (this.allPoolsFactoryStakeFungiv2Polyfungiv1[i].active === true) {

              const allPoolsDetailsObject = {
                ...this.allPoolsFactoryStakeFungiv2Polyfungiv1[i],
              }

              //Lets double check and make sure our active pool list doesnt already contain this new pool we are adding
              if (this.allActivePoolsFactoryStakeFungiv2Polyfungiv1.includes(allPoolsDetailsObject) === false) {
                this.allActivePoolsFactoryStakeFungiv2Polyfungiv1.push(allPoolsDetailsObject);
              }

              //Lets also add this pools id to a list of ids to show later
              if (this.allActivePoolsIdsFungiv2Polyfungiv1.includes(this.allPoolsFactoryStakeFungiv2Polyfungiv1[i].id) === false) {
                this.allActivePoolsIdsFungiv2Polyfungiv1.push(this.allPoolsFactoryStakeFungiv2Polyfungiv1[i].id);
              }

            }

          }

        }

        //Now we need to quickly get a list of all fungiv2 polyfungiv1 pool ids the user has stakes in
        if (this.allUserPoolsFactoryStakeFungiv2Polyfungiv1.length > 0) {
          for (let j = this.allUserPoolsFactoryStakeFungiv2Polyfungiv1.length - 1; j >= 0; j--) {
            if (this.allUserPoolsIdsFungiv2Polyfungiv1.includes(this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[j].id) === false) {
              this.allUserPoolsIdsFungiv2Polyfungiv1.push(this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[j].id);
            }
          }
        }

        //Now lets filter out the fungiv2 polyfungiv1 pools the user has staked in from the list of all fungiv2 polyfungiv1 pools
        //This is our list of pools we must fill in with blank user data
        if (this.allActivePoolsIdsFungiv2Polyfungiv1.length > 0) {
          for (let m = this.allActivePoolsIdsFungiv2Polyfungiv1.length - 1; m >= 0; m--) {
            if (this.allUserPoolsIdsFungiv2Polyfungiv1.includes(this.allActivePoolsIdsFungiv2Polyfungiv1[m]) === false) {
              if (this.allPoolIdsUserIsNotStakedInFungiv2Polyfungiv1.includes(this.allActivePoolsIdsFungiv2Polyfungiv1[m]) === false) {
                this.allPoolIdsUserIsNotStakedInFungiv2Polyfungiv1.push(this.allActivePoolsIdsFungiv2Polyfungiv1[m]);
              }
            }
          }
        }

        //Now that we have a complete list of ACTIVE fungiv2 polyfungiv1 pools, we should go through them!
        for (let l = this.allActivePoolsFactoryStakeFungiv2Polyfungiv1.length - 1; l >= 0; l--) {

          if (this.debug === true) {
            console.log("STEP 2) POOL ID:");
            console.log(this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l].id)
          }

          //Now lets go through the list of ids the user hasnt staked in
          if (this.allPoolIdsUserIsNotStakedInFungiv2Polyfungiv1.length > 0) {

            for (let k = this.allPoolIdsUserIsNotStakedInFungiv2Polyfungiv1.length - 1; k >= 0; k--) {

              //Do any of the active pools match ids from pools the user is not staked in?
              //If so lets dress them with blank stake information to display


              if (this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l].id === this.allPoolIdsUserIsNotStakedInFungiv2Polyfungiv1[k]) {

                let t_pool_apy = this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['apy'];
                if (this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['apy'].decimal !== undefined) {
                  t_pool_apy = this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['apy'].decimal;
                }
                t_pool_apy = this.reduceBalance(t_pool_apy, 2);

                const t_payload = {
                  tokenId: this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['reward-token-id'],
                  moduleName: this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['reward-token']['refName']['name'],
                  moduleNamespace: this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['reward-token']['refName']['namespace']
                }

                let t_uri = null;
                t_uri = await this.getURIpolyfungiv1(t_payload);

                if(this.debug){
                  console.log("GOT fungiv2 polyfungiv1 T_URI!!")
                  console.log(t_uri);
                  console.log("t_uri[image]")
                  console.log(t_uri['image'])
                }



                let isPoolOwner = false;

                if (this.getAccountConfirmed() === true) {
                  if (this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['account'] === localStorage.getItem("accountName")) {
                    isPoolOwner = true;
                  }
                }

                let t_pool_endtime = this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['end-time'];

                if (this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['end-time'].time !== undefined) {
                  t_pool_endtime = this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['end-time'].time;
                }else if (this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['end-time'].timep !== undefined) {
                  t_pool_endtime = this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['end-time'].timep;
                }

                t_pool_endtime = t_pool_endtime.slice(0, 10);

                const t_pool_endtime_date = new Date(t_pool_endtime);
                const t_now = new Date();

                let canPush = true;

                const numberOfStakers = this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['stakers'];
                if(t_now > t_pool_endtime_date && numberOfStakers !== 0){
                  canPush = false;
                }

                if(canPush === false){
                  canPush = isPoolOwner;
                  if(this.debug){
                    console.log("canPush is now true due to user being pool owner")
                  }
                }


                if(this.debug){
                  console.log("t_pool_endtime");
                  console.log(t_pool_endtime);
                  console.log("this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]");
                  console.log(this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]);
                  console.log("this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['end-time']");
                  console.log(this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['end-time']);
                  console.log("this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['active']");
                  console.log(this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['active']);
                  if(t_now > t_pool_endtime_date){
                    console.log("t_now is GREATER than t_pool_endtime_date");
                  }else {
                    console.log("t_now is LESS than t_pool_endtime_date");
                  }
                }

                let t_wallet_balance = 0;

                if(canPush === true){
                  const tub_payloadA = {
                    tokenA: this.coinName(this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['stake-token']['refName']['namespace'], this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['stake-token']['refName']['name']),
                  };
                  t_wallet_balance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadA);

                  if(this.debug){
                    console.log("t_wallet_balance updated");
                    console.log(t_wallet_balance);
                  }
                }

                if(this.debug){
                  console.log("t_wallet_balance");
                  console.log(t_wallet_balance);
                }


                const poolDetailObject = {
                  pool_name: this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l].name,
                  pool_id: this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l].id,
                  pool_apy: t_pool_apy,
                  pool_endtime: t_pool_endtime,
                  pool_stake_token: this.coinName(this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['stake-token']['refName']['namespace'], this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['stake-token']['refName']['name']),
                  pool_reward_token: this.coinName(this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['reward-token']['refName']['namespace'], this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['reward-token']['refName']['name']),
                  pool_tokens_locked: this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['tokens-locked'],
                  stake_balance: 0,
                  stake_last_updated: 0,
                  stake_rewards: 0,
                  stake_last_claimed: 0,
                  stake_last_withdraw: 0,
                  stake_multiplier: 0,
                  stake_start_time: 0,
                  stake_pending_rewards: 0,
                  pool_contract: 'factory-stake-fungiv2-polyfungiv1',
                  stake_accountApproved: this.accountApproved,
                  token_image: t_uri['image'],
                  pool_reward_token_id: this.allActivePoolsFactoryStakeFungiv2Polyfungiv1[l]['reward-token-id'],
                  is_pool_owner: isPoolOwner,
                  wallet_stake_balance: t_wallet_balance
                }

                if (this.allPoolsToShowFactoryStakeFungiv2Polyfungiv1.includes(poolDetailObject) === false && canPush === true) {
                  this.allPoolsToShowFactoryStakeFungiv2Polyfungiv1.push(poolDetailObject);
                }
              }

            }
          }
        }

        //We now have our list to show on the screen filled with the fungiv2 polyfungiv1 pool data the user has NOT staked in
        //Now, Lets fill that same list with the data from fungiv2 polyfungiv1 pools the user has staked in
        if (this.allUserPoolsFactoryStakeFungiv2Polyfungiv1.length > 0) {

          for (let n = this.allUserPoolsFactoryStakeFungiv2Polyfungiv1.length - 1; n >= 0; n--) {

            if (this.debug === true) {
              console.log("STEP 3) POOL ID:");
              console.log(this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n].id)
            }

            let t_pool_apy = this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['apy'];
            if (this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['apy'].decimal !== undefined) {
              t_pool_apy = this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['apy'].decimal;
            }
            t_pool_apy = this.reduceBalance(t_pool_apy, 2);

            const t_payload = {
              tokenId: this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['reward-token-id'],
              moduleName: this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['reward-token']['refName']['name'],
              moduleNamespace: this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['reward-token']['refName']['namespace']
            }

            let t_uri = null;

            t_uri = await this.getURIpolyfungiv1Image(t_payload);

            console.log("GOT T_URI!!")
            console.log(t_uri);

            let t_pool_endtime = this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['end-time'];

            if (this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['end-time'].time !== undefined) {
              t_pool_endtime = this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['end-time'].time;
            }else if (this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['end-time'].timep !== undefined) {
              t_pool_endtime = this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['end-time'].timep;
            }

            t_pool_endtime = t_pool_endtime.slice(0, 10);

            const t_pool_endtime_date = new Date(t_pool_endtime);
            const t_now = new Date();

            let t_wallet_balance = 0;


            const tub_payloadA = {
              tokenA: this.coinName(this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['stake-token']['refName']['namespace'], this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['stake-token']['refName']['name']),
            };
            t_wallet_balance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadA);

            if(this.debug){
              console.log("fungiv2 t_wallet_balance updated");
              console.log(t_wallet_balance);
            }


            if(this.debug){
              console.log("t_pool_endtime");
              console.log(t_pool_endtime);
              console.log("this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]");
              console.log(this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]);
              console.log("this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[l]['end-time']");
              console.log(this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['end-time']);
              console.log("this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[l]['active']");
              console.log(this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['active']);
              if(t_now > t_pool_endtime_date){
                console.log("t_now is GREATER than t_pool_endtime_date");
              }else {
                console.log("t_now is LESS than t_pool_endtime_date");
              }
            }

            const poolDetailObject = {
              pool_name: this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n].name,
              pool_id: this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n].id,
              pool_apy: t_pool_apy,
              pool_endtime: t_pool_endtime,
              pool_stake_token: this.coinName(this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['stake-token']['refName']['namespace'], this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['stake-token']['refName']['name']),
              pool_reward_token: this.coinName(this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['reward-token']['refName']['namespace'], this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['reward-token']['refName']['name']),
              pool_tokens_locked: this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['tokens-locked'],
              pool_contract: 'factory-stake-fungiv2-polyfungiv1',
              stake_accountApproved: this.accountApproved,
              stake_pending_rewards: this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n].stake_pending_rewards,
              stake_balance: this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['stake_balance'],
              token_image: t_uri['image'],
              pool_reward_token_id: this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['reward-token-id'],
              stake_rewards: this.allUserPoolsFactoryStakeFungiv2Polyfungiv1[n]['stake_rewards'],
            }

            if (this.allPoolsToShowFactoryStakeFungiv2Polyfungiv1.includes(poolDetailObject) === false) {
              this.allPoolsToShowFactoryStakeFungiv2Polyfungiv1.push(poolDetailObject);
            }

          }
        }

        //At this point we can now display our allPoolsToShowFactoryStakeFungiv2Polyfungiv1 list on the screen
        if (this.debug) {
          console.log("allPoolsToShowFactoryStakeFungiv2Polyfungiv1");
          console.log(this.allPoolsToShowFactoryStakeFungiv2Polyfungiv1);
        }


        if (this.allPoolsToShowFactoryStakeFungiv2Polyfungiv1.length > 0) {

          for (let t = this.allPoolsToShowFactoryStakeFungiv2Polyfungiv1.length - 1; t >= 0; t--) {

            //this.l_pool_endtime = this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[t].pool_endtime;
            this.l_wallet_stake_balance = this.allPoolsToShowFactoryStakeFungiv2Polyfungiv1[t].wallet_stake_balance;
            this.l_pool_apy = this.allPoolsToShowFactoryStakeFungiv2Polyfungiv1[t].pool_apy;
            this.l_pool_tokens_locked = this.allPoolsToShowFactoryStakeFungiv2Polyfungiv1[t].pool_tokens_locked;
            this.l_stake_balance = this.allPoolsToShowFactoryStakeFungiv2Polyfungiv1[t].stake_balance;
            this.l_stake_pending_rewards = this.allPoolsToShowFactoryStakeFungiv2Polyfungiv1[t].stake_pending_rewards;
            this.l_stake_rewards = this.allPoolsToShowFactoryStakeFungiv2Polyfungiv1[t].stake_rewards;

          }

        }


      }else if(this.pool_contract === "factory-stake-fungiv2"){


        //Polyfungiv1 Fungiv2 bind
        //Bind local state for factory-stake-fungiv2-polyfungiv1
        this.allPoolsFactoryStakePolyfungiv1Fungiv2 = this.getAllPoolsFactoryStakePolyfungiv1Fungiv2();
        //Bind local state for user at factory-stake-fungiv2-polyfungiv1
        this.allUserPoolsFactoryStakePolyfungiv1Fungiv2 = this.getUserPoolsFactoryStakePolyfungiv1Fungiv2();


        //polyfungiv1 fungiv2

        //Lets make a list of active polyfungiv1 fungiv2 pools
        //First we got through the list of all polyfungiv1 fungiv2 pools if its not empty
        if (this.allPoolsFactoryStakePolyfungiv1Fungiv2.length > 0) {

          //We go through pools in reverse because its better
          for (let i = this.allPoolsFactoryStakePolyfungiv1Fungiv2.length - 1; i >= 0; i--) {

            if (this.debug === true) {
              console.log("STEP 1) POOL ID:");
              console.log(this.allPoolsFactoryStakePolyfungiv1Fungiv2[i].id)
            }

            //Now lets check and see if our pool is 'active' or not, if so, lets add it to our list of active pools
            if (this.allPoolsFactoryStakePolyfungiv1Fungiv2[i].active === true) {

              const allPoolsDetailsObject = {
                ...this.allPoolsFactoryStakePolyfungiv1Fungiv2[i],
              }

              //Lets double check and make sure our active pool list doesnt already contain this new pool we are adding
              if (this.allActivePoolsFactoryStakePolyfungiv1Fungiv2.includes(allPoolsDetailsObject) === false) {
                this.allActivePoolsFactoryStakePolyfungiv1Fungiv2.push(allPoolsDetailsObject);
              }

              //Lets also add this pools id to a list of ids to show later
              if (this.allActivePoolsIdsPolyfungiv1Fungiv2.includes(this.allPoolsFactoryStakePolyfungiv1Fungiv2[i].id) === false) {
                this.allActivePoolsIdsPolyfungiv1Fungiv2.push(this.allPoolsFactoryStakePolyfungiv1Fungiv2[i].id);
              }

            }

          }

        }

        //Now we need to quickly get a list of all polyfungiv1 fungiv2 pool ids the user has stakes in
        if (this.allUserPoolsFactoryStakePolyfungiv1Fungiv2.length > 0) {
          for (let j = this.allUserPoolsFactoryStakePolyfungiv1Fungiv2.length - 1; j >= 0; j--) {
            if (this.allUserPoolsIdsPolyfungiv1Fungiv2.includes(this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[j].id) === false) {
              this.allUserPoolsIdsPolyfungiv1Fungiv2.push(this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[j].id);
            }
          }
        }

        //Now lets filter out the polyfungiv1 fungiv2 pools the user has staked in from the list of all polyfungiv1 fungiv2 pools
        //This is our list of pools we must fill in with blank user data
        if (this.allActivePoolsIdsPolyfungiv1Fungiv2.length > 0) {
          for (let m = this.allActivePoolsIdsPolyfungiv1Fungiv2.length - 1; m >= 0; m--) {
            if (this.allUserPoolsIdsPolyfungiv1Fungiv2.includes(this.allActivePoolsIdsPolyfungiv1Fungiv2[m]) === false) {
              if (this.allPoolIdsUserIsNotStakedInPolyfungiv1Fungiv2.includes(this.allActivePoolsIdsPolyfungiv1Fungiv2[m]) === false) {
                this.allPoolIdsUserIsNotStakedInPolyfungiv1Fungiv2.push(this.allActivePoolsIdsPolyfungiv1Fungiv2[m]);
              }
            }
          }
        }

        //Now that we have a complete list of ACTIVE polyfungiv1 fungiv2 pools, we should go through them!
        for (let l = this.allActivePoolsFactoryStakePolyfungiv1Fungiv2.length - 1; l >= 0; l--) {

          if (this.debug === true) {
            console.log("STEP 2) POOL ID:");
            console.log(this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l].id)
          }

          //Now lets go through the list of ids the user hasnt staked in
          if (this.allPoolIdsUserIsNotStakedInPolyfungiv1Fungiv2.length > 0) {

            for (let k = this.allPoolIdsUserIsNotStakedInPolyfungiv1Fungiv2.length - 1; k >= 0; k--) {

              //Do any of the active pools match ids from pools the user is not staked in?
              //If so lets dress them with blank stake information to display


              if (this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l].id === this.allPoolIdsUserIsNotStakedInPolyfungiv1Fungiv2[k]) {

                let t_pool_apy = this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['apy'];
                if (this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['apy'].decimal !== undefined) {
                  t_pool_apy = this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['apy'].decimal;
                }
                t_pool_apy = this.reduceBalance(t_pool_apy, 2);

                const t_payload = {
                  tokenId: this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['stake-token-id'],
                  moduleName: this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['stake-token']['refName']['name'],
                  moduleNamespace: this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['stake-token']['refName']['namespace']
                }

                let t_uri = null;
                t_uri = await this.getURIpolyfungiv1(t_payload);

                if(this.debug){
                  console.log("GOT polyfungiv1 fungiv2 T_URI!!")
                  console.log(t_uri);
                  console.log("t_uri[image]")
                  console.log(t_uri['image'])
                }



                let isPoolOwner = false;

                if (this.getAccountConfirmed() === true) {
                  if (this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['account'] === localStorage.getItem("accountName")) {
                    isPoolOwner = true;
                  }
                }

                let t_pool_endtime = this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['end-time'];

                if (this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['end-time'].time !== undefined) {
                  t_pool_endtime = this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['end-time'].time;
                }else if (this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['end-time'].timep !== undefined) {
                  t_pool_endtime = this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['end-time'].timep;
                }

                t_pool_endtime = t_pool_endtime.slice(0, 10);

                const t_pool_endtime_date = new Date(t_pool_endtime);
                const t_now = new Date();

                let canPush = true;

                const numberOfStakers = this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['stakers'];
                if(t_now > t_pool_endtime_date && numberOfStakers !== 0){
                  canPush = false;
                }

                if(canPush === false){
                  canPush = isPoolOwner;
                  if(this.debug){
                    console.log("canPush is now true due to user being pool owner")
                  }
                }

                let t_wallet_balance = 0;

                if(canPush === true){
                  const tub_payloadA = {
                    tokenA: this.coinName(this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['stake-token']['refName']['namespace'], this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['stake-token']['refName']['name']),
                    nftID: this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['stake-token-id'],
                  };
                  t_wallet_balance = await this.$store.dispatch("accounts/getPolyfungiv1TokenUserBalance", tub_payloadA);

                  if(this.debug){
                    console.log("polyfungiv1 t_wallet_balance updated");
                    console.log(t_wallet_balance);
                  }
                }

                if(this.debug){
                  console.log("fungiv2 t_wallet_balance");
                  console.log(t_wallet_balance);
                }


                if(this.debug){
                  console.log("t_pool_endtime");
                  console.log(t_pool_endtime);
                  console.log("this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]");
                  console.log(this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]);
                  console.log("this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['end-time']");
                  console.log(this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['end-time']);
                  console.log("this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['active']");
                  console.log(this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['active']);
                  if(t_now > t_pool_endtime_date){
                    console.log("t_now is GREATER than t_pool_endtime_date");
                  }else {
                    console.log("t_now is LESS than t_pool_endtime_date");
                  }
                }


                const poolDetailObject = {
                  pool_name: this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l].name,
                  pool_id: this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l].id,
                  pool_apy: t_pool_apy,
                  pool_endtime: t_pool_endtime,
                  pool_stake_token: this.coinName(this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['stake-token']['refName']['namespace'], this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['stake-token']['refName']['name']),
                  pool_reward_token: this.coinName(this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['reward-token']['refName']['namespace'], this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['reward-token']['refName']['name']),
                  pool_tokens_locked: this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['tokens-locked'],
                  stake_balance: 0,
                  stake_last_updated: 0,
                  stake_rewards: 0,
                  stake_last_claimed: 0,
                  stake_last_withdraw: 0,
                  stake_multiplier: 0,
                  stake_start_time: 0,
                  stake_pending_rewards: 0,
                  pool_contract: 'factory-stake-polyfungiv1-fungiv2',
                  stake_accountApproved: this.accountApproved,
                  token_image: t_uri['image'],
                  pool_stake_token_id: this.allActivePoolsFactoryStakePolyfungiv1Fungiv2[l]['stake-token-id'],
                  is_pool_owner: isPoolOwner,
                  wallet_stake_balance: t_wallet_balance
                }

                if (this.allPoolsToShowFactoryStakePolyfungiv1Fungiv2.includes(poolDetailObject) === false && canPush === true) {
                  this.allPoolsToShowFactoryStakePolyfungiv1Fungiv2.push(poolDetailObject);
                }
              }

            }
          }
        }

        //We now have our list to show on the screen filled with the polyfungiv1 fungiv2 pool data the user has NOT staked in
        //Now, Lets fill that same list with the data from polyfungiv1 fungiv2 pools the user has staked in
        if (this.allUserPoolsFactoryStakePolyfungiv1Fungiv2.length > 0) {

          for (let n = this.allUserPoolsFactoryStakePolyfungiv1Fungiv2.length - 1; n >= 0; n--) {

            if (this.debug === true) {
              console.log("STEP 3) POOL ID:");
              console.log(this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n].id)
            }

            let t_pool_apy = this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['apy'];
            if (this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['apy'].decimal !== undefined) {
              t_pool_apy = this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['apy'].decimal;
            }
            t_pool_apy = this.reduceBalance(t_pool_apy, 2);

            const t_payload = {
              tokenId: this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['stake-token-id'],
              moduleName: this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['stake-token']['refName']['name'],
              moduleNamespace: this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['stake-token']['refName']['namespace']
            }

            let t_uri = null;

            t_uri = await this.getURIpolyfungiv1Image(t_payload);


            console.log("GOT T_URI!!")
            console.log(t_uri);

            let t_pool_endtime = this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['end-time'];

            if (this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['end-time'].time !== undefined) {
              t_pool_endtime = this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['end-time'].time;
            }else if (this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['end-time'].timep !== undefined) {
              t_pool_endtime = this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['end-time'].timep;
            }

            t_pool_endtime = t_pool_endtime.slice(0, 10);

            const t_pool_endtime_date = new Date(t_pool_endtime);
            const t_now = new Date();

            let t_wallet_balance = 0;


            const tub_payloadA = {
              tokenA: this.coinName(this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['stake-token']['refName']['namespace'], this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['stake-token']['refName']['name']),
              nftID: this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['stake-token-id'],
            };
            t_wallet_balance = await this.$store.dispatch("accounts/getPolyfungiv1TokenUserBalance", tub_payloadA);

            if(this.debug){
              console.log("polyfungiv1 t_wallet_balance updated");
              console.log(t_wallet_balance);
            }


            if(this.debug){
              console.log("t_pool_endtime");
              console.log(t_pool_endtime);
              console.log("this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]");
              console.log(this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]);
              console.log("this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[l]['end-time']");
              console.log(this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['end-time']);
              console.log("this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[l]['active']");
              console.log(this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['active']);
              if(t_now > t_pool_endtime_date){
                console.log("t_now is GREATER than t_pool_endtime_date");
              }else {
                console.log("t_now is LESS than t_pool_endtime_date");
              }
            }


            const poolDetailObject = {
              pool_name: this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n].name,
              pool_id: this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n].id,
              pool_apy: t_pool_apy,
              pool_endtime: t_pool_endtime,
              pool_stake_token: this.coinName(this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['stake-token']['refName']['namespace'], this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['stake-token']['refName']['name']),
              pool_reward_token: this.coinName(this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['reward-token']['refName']['namespace'], this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['reward-token']['refName']['name']),
              pool_tokens_locked: this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['tokens-locked'],
              pool_contract: 'factory-stake-polyfungiv1-fungiv2',
              stake_accountApproved: this.accountApproved,
              stake_pending_rewards: this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n].stake_pending_rewards,
              stake_balance: this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['stake_balance'],
              token_image: t_uri['image'],
              pool_stake_token_id: this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['stake-token-id'],
              stake_rewards: this.allUserPoolsFactoryStakePolyfungiv1Fungiv2[n]['stake_rewards'],
              wallet_stake_balance: t_wallet_balance
            }

            if (this.allPoolsToShowFactoryStakePolyfungiv1Fungiv2.includes(poolDetailObject) === false) {
              this.allPoolsToShowFactoryStakePolyfungiv1Fungiv2.push(poolDetailObject);
            }

          }
        }

        //At this point we can now display our allPoolsToShowFactoryStakePolyfungiv1Fungiv2 list on the screen
        if (this.debug) {
          console.log("allPoolsToShowFactoryStakePolyfungiv1Fungiv2");
          console.log(this.allPoolsToShowFactoryStakePolyfungiv1Fungiv2);
        }

        if (this.allPoolsToShowFactoryStakePolyfungiv1Fungiv2.length > 0) {

          for (let t = this.allPoolsToShowFactoryStakePolyfungiv1Fungiv2.length - 1; t >= 0; t--) {

            //this.l_pool_endtime = this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[t].pool_endtime;
            this.l_wallet_stake_balance = this.allPoolsToShowFactoryStakePolyfungiv1Fungiv2[t].wallet_stake_balance;
            this.l_pool_apy = this.allPoolsToShowFactoryStakePolyfungiv1Fungiv2[t].pool_apy;
            this.l_pool_tokens_locked = this.allPoolsToShowFactoryStakePolyfungiv1Fungiv2[t].pool_tokens_locked;
            this.l_stake_balance = this.allPoolsToShowFactoryStakePolyfungiv1Fungiv2[t].stake_balance;
            this.l_stake_pending_rewards = this.allPoolsToShowFactoryStakePolyfungiv1Fungiv2[t].stake_pending_rewards;
            this.l_stake_rewards = this.allPoolsToShowFactoryStakePolyfungiv1Fungiv2[t].stake_rewards;

          }

        }

      }else if(this.pool_contract === "factory-stake-fnf-fungiv2"){




        let payload = {
          collectionid: this.pool_stake_token_id
        }

        let nftids = await this.$store.dispatch("accounts/getUserCollectionNFTsDetails", payload);

        this.usernfts = nftids;

        let payload_staked = {
          poolid: this.pool_id
        }

        let stakednfts = await this.$store.dispatch("accounts/getUserStakedNFTs", payload_staked);

        let stakednftids = null;
        this.userstakednfts = [];
        if(stakednfts.ids !== undefined){
          stakednftids = stakednfts.ids;

          for(let i = 0; i < stakednftids.length; i++){

            const nftidobject = {
              id: stakednftids[i]
            }

            if (this.userstakednfts.includes(nftidobject) === false) {
              this.userstakednfts.push(nftidobject);
            }

          }

        }

        let thispool = await this.$store.dispatch("accounts/getFNFFungiv2PoolInfo", payload_staked);

        console.log("thispool");
        console.log(thispool);


        let t_wallet_balance = 0;

        t_wallet_balance = await this.$store.dispatch("accounts/getCollectionUserSupply", payload);

        if (t_wallet_balance.decimal !== undefined) {
          t_wallet_balance = t_wallet_balance.decimal;
        }


        let tokenslocked = thispool["tokens-locked"];

        if(thispool["tokens-locked"].decimal !== undefined){
          tokenslocked = thispool["tokens-locked"].decimal;
        }

        let stakerewards = stakednfts.rewards;

        if(stakednfts.rewards.decimal !== undefined){
          stakerewards = stakednfts.rewards.decimal;
        }

        let poolapy = thispool.apy;

        if(thispool.apy.decimal !== undefined){
          poolapy = thispool.apy.decimal;
        }

        let stakebalance = stakednfts.balance;

        if(stakednfts.balance.decimal !== undefined){
          stakebalance = stakednfts.balance.decimal;
        }

        let stakependingrewards = stakednfts.stake_pending_rewards;

        if(stakednfts.stake_pending_rewards.decimal !== undefined){
          stakependingrewards = stakednfts.stake_pending_rewards.decimal;
        }

        this.l_wallet_stake_balance = t_wallet_balance;
        this.l_stake_pending_rewards = stakependingrewards;
        this.l_pool_tokens_locked = tokenslocked;
        //this.lpool_endtime = this.pool_endtime;
        this.l_stake_rewards = stakerewards;
        this.l_pool_apy = poolapy;
        this.l_stake_balance =  stakebalance;



      }

    }


  },
  async created() {

    let payload = {
      collectionid: this.pool_stake_token_id
    }

    let nftids = await this.$store.dispatch("accounts/getUserCollectionNFTsDetails", payload);

    this.usernfts = nftids;

    let payload_staked = {
      poolid: this.pool_id
    }

    let stakednfts = await this.$store.dispatch("accounts/getUserStakedNFTs", payload_staked);

    let stakednftids = null;
    if(stakednfts.ids !== undefined){
      stakednftids = stakednfts.ids;

      for(let i = 0; i < stakednftids.length; i++){

        const nftidobject = {
          id: stakednftids[i]
        }

        if (this.userstakednfts.includes(nftidobject) === false) {
          this.userstakednfts.push(nftidobject);
        }

      }

    }


    console.log("test")
    console.log(payload);
    console.log(nftids);
    console.log("STAKED NFTS");
    console.log(stakednfts);
    console.log(stakednftids);

  }
};
</script>

<style scoped>

.clickcollection{
  color: #3a99bb;
  cursor: pointer;
}

.clickcollection:hover {
  color: #a54bd9;
}

body {
  perspective: 1500px;
}

/deep/ .button.is-buttonblue {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}


/deep/ .button.is-buttonblue:hover {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}


.wrapper {
  max-width: 295px;
  max-height: 295px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frameWrapper {
  height: 277px;
  width: 240px;
}

.frame {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: #2e3e60b8;
}

.primaryLayer,
.secondaryLayer {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.primaryLayer {
  clip-path: polygon(0% 0%, 100% 0%, 100% 40%, 0% 55%);
  background: radial-gradient(black 0%, transparent 10%) 0 0, radial-gradient(black 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0.1) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #2c313a;
  background-size: 16px 16px;
  border: 2px solid rgba(17, 66, 101, 0.3);
  background-blend-mode: darken;
}

.secondaryLayer {
  clip-path: polygon(100% 100%, 0% 100%, 0% 60%, 100% 45%);
  background: radial-gradient(black 0%, transparent 10%) 0 0, radial-gradient(black 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0.1) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #2c313a;
  background-size: 16px 16px;
  border: 2px solid rgba(17, 66, 101, 0.3);
  background-blend-mode: darken;
}

.image {
  object-fit: cover;
  position: absolute;
  min-height: 65%;
  max-height: 100%;
  width: 100%;
}

.primaryLayer > .image {
  top: 0px;
}

.secondaryLayer > .image {
  bottom: 0px;
}


.card3d {
  font-weight: bold;
  padding: 1em;
  color: #181a1a;
  width:  300px;
  max-height: 450px;
  box-shadow: 0 1px 5px #00000099;
  border-radius: 10px;
  position: relative;
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  transform: rotate3d(0,0,0,0);
}

.card3d:hover {
  transition-duration: 150ms;
  box-shadow: 0 5px 20px 5px #00000044;
}

.card3d .glow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  background-image: radial-gradient(circle at 50% -20%, #ffffff22, #0000000f);
}


.highlight{
  box-shadow: rgba(24, 202, 255, 0.13) 0px 0px 30px 3px !important;
  border-color: rgba(106, 70, 123, 0.56) !important;
  outline: none;
}

/deep/ .b-numberinput input {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
  box-shadow: inset 0 30px 60px -12px rgba(50, 50, 93, 0.25), inset 0 18px 36px -18px rgba(0, 0, 0, 0.30);
}

/deep/ .select select {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78 !important;
  box-shadow: inset 0 30px 60px -12px rgba(50, 50, 93, 0.25), inset 0 18px 36px -18px rgba(0, 0, 0, 0.30);
}

/deep/ .field.has-addons .control .control .button, .field.has-addons .control .control .input, .field.has-addons .control .control .select::placeholder {
  color: #19ac78;
}

.form-container .form-horizontal .form-control::placeholder {
  color: #34d44e;
  font-size: 14px;
}

.pending-col {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: 'Lexend Deca', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.back-col {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px;
}

.back-col-main {
  background-color: rgba(38, 43, 56, 1);
  border-radius: 1rem;
  font-family: 'Lexend Deca', sans-serif;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  font-size: 12px;
  height: 100%;
  width: 100%;
  padding: .8rem;
}

.back-col-text{
  font-size: 12px;
}

.col-shadow1 {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider {
  font-size: 12px;
}

.pool-name {
  font-size: 16px;
  color: rgb(120 161 235);
  font-family: 'Lexend Deca', sans-serif;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: #293038;
}

.pool-main {
  background-color: #293038;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: 'Noto Sans', sans-serif;
}

.poolselect-bg {
  background-color: #293038 !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

/deep/ .tabs.is-toggle li.is-active a {
  background-color: #314b6a;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #3abb81;
  z-index: 1;
  font-family: 'Noto Sans', sans-serif;
}

/deep/ .tabs.is-toggle a {
  border-color: #575f85;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 0;
  position: relative;
  font-family: 'Noto Sans', sans-serif;
  color: #195da7;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
  color: #00b89c;
}


.pools-card {
  padding-top: .5rem;
  color: #0d64c6;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
}

/deep/ .field.has-addons .control:first-child .control .button, .field.has-addons .control:first-child .control .input, .field.has-addons .control:first-child .control .select select {
  border-radius: 0 !important;
}

/deep/ .field.has-addons:first-child  .control:first-child .control:first-child  .button:first-child , .field.has-addons:first-child  .control:first-child .control:first-child  .input:first-child , .field.has-addons:first-child .control:first-child .control:first-child  .select:first-child  select:first-child{
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.stake-btn-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.back-card-stat-col{
  display: flex;
  justify-content: left;
}

.stake-btn-2 {
  font-size: 14px !important;
  max-height: 40px;
  padding-left: 8px !important;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}

.btn {
  color: #87b6e5;
  background: rgba(3, 150, 255, 0.32);
  background-repeat: no-repeat;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}

.btn:focus,
.btn:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    margin-bottom: 30px;
  }
}

.text3d {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 transparent, 0 3px 0 transparent, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px 0;
}


.pricingTable {
  background: #fff;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding: 0 0 30px;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: -20px;
}

.pricingTable .pricingTable-header {
  background: #c2803a;
  padding: 10px 0 10px;
  margin: 0 auto 40px;
  border-radius: 40px 40px 0 0;
  position: relative;
}

.pricingTable .pricingTable-header:hover {
  color: #fff;
  background: #7bc8fa;
}

.pricingTable .pricingTable-header:after {
  content: "";
  background: linear-gradient(-45deg, transparent 75%, #c2803a 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #c2803a 75%) 0 50%;
  background-repeat: repeat-x;
  background-size: 22px;
  width: 100%;
  height: 21px;
  position: absolute;
  bottom: -21px;
  left: 0;
  cursor: pointer;
}

.pricingTable .pricingTable-header:hover:after {
  content: "";
  background: linear-gradient(-45deg, transparent 75%, #7bc8fa 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #7bc8fa 75%) 0 50%;
  background-repeat: repeat-x;
  background-size: 22px;
  width: 100%;
  height: 21px;
  position: absolute;
  bottom: -21px;
  left: 0;
  cursor: pointer;
}


.pricingTable .title-back {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  cursor: pointer;
}


.pricingTable .price-value {
  color: #5b5b5b;
  margin: 0 0 20px;
}

.pricingTable .price-value .amount {
  font-size: 65px;
  font-weight: 400;
  line-height: 60px;
  display: block;
}

.pricingTable .price-value .duration {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  display: block;
}

.pricingTable .pricing-content {
  padding: 0 10px;
  margin: 0 0 10px;
  list-style: none;
}

.pricingTable .pricing-content li {
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  line-height: 1px;
  padding: 9px 15px 8px 10px;
  position: relative;
  overflow: auto;
}

.pricingTable .pricing-content li:nth-child(odd) {
  background: #e7e7e7;
}

.pricingTable .pricing-content li:nth-last-child(-n+4) {
  line-height: normal;
}

.pricingTable .pricingTable-signup a {
  color: #fff;
  background: #c2803a;
  font-size: 22px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 1px;
  padding: 5px 25px;
  border-radius: 50px;
  transition: all 0.3s ease 0s;
}

.pricingTable .pricingTable-signup a:hover {
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.9);
}

.pricingTable.red .pricingTable-header {
  background: #d83b50;
}

.pricingTable.red .pricingTable-header:after {
  background: linear-gradient(-45deg, transparent 75%, #d83b50 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #d83b50 75%) 0 50%;
  background-size: 22px;
}

.pricingTable.red .pricingTable-signup a {
  background: #d83b50;
}

.pricingTable.orange .pricingTable-header {
  background: #e0681e;
}

.pricingTable.orange .pricingTable-header:after {
  background: linear-gradient(-45deg, transparent 75%, #e0681e 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #e0681e 75%) 0 50%;
  background-size: 22px;
}

.pricingTable.orange .pricingTable-signup a {
  background: #e0681e;
}

.btn2 {
  color: #fff;
  background-color: #0a0a0a;
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 1px;
  border: none;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  transition: all 0.3s ease 0s;
  top: 0;
  right: 0px;
  background-blend-mode: color;
  outline: none;
  cursor: pointer;
}

.btn2:focus,
.btn2:hover {
  color: #fff;
  background-color: #0a0a0a;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) inset, 0 0 10px rgba(0, 0, 0, 0.3);
  background-blend-mode: color;
  outline: none;
  cursor: pointer;
}


.btn2 span {
  background-color: rgba(58, 91, 203, 0.91);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 1px solid #114265;
  outline: none;
  cursor: pointer;
}

.btn2 span:before {
  background-color: #0a0a0a;
  padding: 7px 20px;
  display: block;
  border-radius: 50px;
  border: 1px solid #114265;
  background-blend-mode: color;
  cursor: pointer;
}

.highlight-span span {
  background-color: rgba(92, 57, 94, 0.82);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 1px solid #309a6b85;
  outline: none;
  cursor: pointer;
}

.highlight-span span:before {
  background-color: rgba(92, 57, 94, 0.82);
  padding: 7px 20px;
  display: block;
  border-radius: 50px;
  border: 1px solid #309a6b85;
  background-blend-mode: color;
  cursor: pointer;
}

.highlight-span span:focus,
.highlight-span span:hover {
  background-color: rgba(92, 57, 94, 0.82);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 1px solid #309a6b85;
  outline: none;
  cursor: pointer;
}


i.frontFlipBtn,
i.backFlipBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #FFFFFF;
}

i.backFlipBtn {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}


.flip-container {
  min-height: 120px;
}

.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  position: relative;
  max-width: 290px;
}

.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  width: 100%;
}

.back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  position: absolute;
}

.flip-container.flipped .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.flip-container.flipped .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flip-container.flippedhm .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.flip-container.flippedhm .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flip-container.flipped {
  min-height: 520px;
}

.flip-container.flippedhm {
  min-height: 320px;
}

.front {
  z-index: 2;
}


</style>

<style scoped lang="scss">
.icon {
  display: flex;
}

.card__content-apy-back {
  display: flex;
  justify-content: center;
  padding-top: 4px;
  padding-bottom: 0;
  position: absolute;
  top: 6px;

  > * {
    display: flex;
    align-items: center;
    font-weight: 600;
  }
}

  .card__price_apy_back {
    color: #2a6980;
    font-size: 10px;
    font-family: 'Lexend Deca', sans-serif;
  }

.card {
  background-color: #a0b3c4;
  color: #8BACD9;
  border-radius: 16px;
  width: 300px;
  //height: 500px;
  padding-bottom: 0;
  box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.1);

  .card__product-img {
    display: flex;
    position: relative;
    max-width: 300px;
    max-height: 300px;
    justify-content: center;
  }

  .card__product-img-2 {
    top: 0px;
    display: flex;
    position: relative;
    max-width: 300px;
    max-height: 300px;
  }

  .card__content {
    display: flex;
    flex-direction: column;

    .card__name {
      color: #495fb8;
      font-size: 16px;
      font-weight: bold;
      font-family: 'Lexend Deca', sans-serif;
    }

    .card__description {
      font-size: 1rem;
      line-height: 2.6rem;
      font-weight: lighter;
      position: absolute;
      top: 0px;
    }

    .card__content-bottom {
      display: flex;
      justify-content: center;
      padding-top: 4px;
      padding-bottom: 0;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price {
        color: #2a6980;
        font-size: 10px;
        font-family: 'Lexend Deca', sans-serif;
      }
    }

    .card__content-bottom2 {
      display: flex;
      position: absolute;
      justify-content: center;
      padding-top: 25px;
      padding-bottom: 0;
      cursor: pointer;
      top: 30px;
      right: 0px;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__maximize {
        color: #2a6980;
        font-size: 10px;
        font-family: 'Source Code Pro', sans-serif;
        height: 23px;
        font-size: 10px;
        background-color: #363d68;
        color: #262f38;
        border: none;
        padding-left: 4px;
        padding-right: 4px;
        padding-top:0px;
        margin-top:0px;
        padding-bottom:4px;
      }
      .card__maximize:hover {
        color: #2a6980;
        font-size: 10px;
        font-family: 'Source Code Pro', sans-serif;
        height: 23px;
        font-size: 10px;
        background-color: #2e7946;
        color: #a5d9c7;
        border: none;
        padding-left: 4px;
        padding-right: 4px;
      }
    }

    .card__content-bottom3 {
      display: flex;
      position: absolute;
      justify-content: center;
      padding-top: 25px;
      padding-bottom: 0;
      cursor: pointer;
      top: 75px;
      right: 0px;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__maximize {
        color: #2a6980;
        font-size: 10px;
        font-family: 'Source Code Pro', sans-serif;
        height: 23px;
        font-size: 10px;
        background-color: #363d68;
        color: #262f38;
        border: none;
        padding-left: 4px;
        padding-right: 4px;
        padding-top:0px;
        margin-top:0px;
        padding-bottom:4px;
      }
      .card__maximize:hover {
        color: #2a6980;
        font-size: 10px;
        font-family: 'Source Code Pro', sans-serif;
        height: 23px;
        font-size: 10px;
        background-color: #2e7946;
        color: #a5d9c7;
        border: none;
        padding-left: 4px;
        padding-right: 4px;
      }
    }

    .card__content-apy {
      display: flex;
      justify-content: center;
      padding-top: 4px;
      padding-bottom: 0;
      position: absolute;
      top: 6px;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price_apy {
        color: #2a6980;
        font-size: 10px;
        font-family: 'Lexend Deca', sans-serif;
      }
    }

    .card__content-staked {
      display: flex;
      justify-content: center;
      padding-top: 17px;
      padding-bottom: 0;
      position: absolute;
      top: 6px;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price_staked {
        color: #2a6980;
        font-size: 10px;
        font-family: 'Lexend Deca', sans-serif;
      }
    }

    .card__content-pending {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
      padding-top: 10px;
      position: absolute;
      border: 2px solid #114265;
      width: 90%;
      background-size:16px 16px;
      background-color: rgba(51, 59, 76, 0.92);
      border-radius:1rem;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price_pending {
        color: #2a6980;
        font-size: 20px;
        font-family: 'Lexend Deca', sans-serif;
      }
    }
  }

  .card__footer {
    display: flex;
    align-items: center;
    border-top: 1px solid #2E405A;
    gap: 16px;
    padding-top: 16px;

    .card__avatar {
      display: flex;
      border-radius: 90px;
      border: 1px solid white;

      img {
        width: 33px;
      }
    }

    .card__autor--alt-color {
      color: white;
      cursor: pointer;

      &:hover {
        color: #00FFF8;
      }
    }
  }
}

//Transition
.fade-enter-active,
.fade-leave-active {
  transition: 0.25s ease-out;
}

.fade-leave-to,
.fade-enter-from {
  opacity: 0;
}


.form-container {
  background: radial-gradient(black 0%, transparent 10%) 0 0, radial-gradient(black 1%, transparent 13%) 8px 8px, radial-gradient(rgb(0 0 0) 1%, transparent 1%) 0 1px, radial-gradient(rgb(255 255 255 / 5%) 15%, transparent 20%) 8px 9px;
  background-color: #2c313a;
  background-color: #2c243275;
  background-size: 16px 16px;
  border: 2px solid #114265;
  background-blend-mode: darken;
  padding: 20px;
  padding-top: 40px;
  border-radius: 20px;
  box-shadow: rgb(56 154 255 / 22%) 0px 0px 30px 3px !important;
}

.form-container:hover {
  background: radial-gradient(black 0%, transparent 10%) 0 0, radial-gradient(black 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0.1) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #2c313a;
  background-size: 16px 16px;
  border: 2px solid rgba(3, 255, 150, 0.38);
  background-blend-mode: darken;
  padding: 20px;
  padding-top: 40px;
  border-radius: 20px;
  box-shadow: rgb(56 154 255 / 22%) 0px 0px 30px 3px !important;

}


.form-container .form-icon {
  color: #40ac98;
  font-size: 55px;
  text-align: center;
  line-height: 100px;
  width: 100px;
  height: 100px;
  margin: 0 auto 15px;
  border-radius: 50px;
  box-shadow: 7px 7px 10px #cbced1, -7px -7px 10px #fff;
}

.form-container .title {
  color: #ac40ab;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 20px;
}

.form-container .form-horizontal .form-group {
  margin: 0 0 25px 0;
}

.form-container .form-horizontal .form-group label {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.form-container .form-horizontal .form-control {
  color: #333;
  background: #ecf0f3;
  font-size: 15px;
  height: 50px;
  padding: 20px;
  letter-spacing: 1px;
  border: none;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.form-container .form-horizontal .form-control:focus {
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  outline: none;
}

.form-container .form-horizontal .form-control::placeholder {
  color: #808080;
  font-size: 14px;
}

.form-container .form-horizontal .btn {
  color: #000;
  background-color: #ac40ab;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  padding: 12px 15px 11px;
  border-radius: 20px;
  box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px #fff;
  border: none;
  transition: all 0.5s ease 0s;
}

.form-container .form-horizontal .btn:hover,
.form-container .form-horizontal .btn:focus {
  color: #fff;
  letter-spacing: 3px;
  box-shadow: none;
  outline: none;
}

</style>
