<template>
  <div style="overflow-x: hidden" :style="$screen.width > 1024 ? 'margin-top: -85px' : 'margin-top: -84px'">

    <!--    Page loading animation section-->
    <div v-if="this.showLoadingModal === true" style="height: 100vh">
      <div class="loader2">
        <div class="loader__bar"></div>
        <div class="loader__bar loader__bar--delay-1"></div>
        <div class="loader__bar loader__bar--delay-2"></div>
        <div class="loader__bar loader__bar--delay-3"></div>
        <div class="loader__bar loader__bar--delay-4"></div>
        <div class="loader__bar loader__bar--delay-5"></div>
        <div>
        </div>
      </div>
    </div>

    <!--    Homepage shown after loading is completed-->
    <section v-if="this.showLoadingModal === false">


      <!--      bubbles-->
      <div
          v-if="this.showLoadingModal === false"
          id="style-10"
          style="width:100vw; overflow-x: hidden !important; overflow-y: auto"
          :style="this.getShowNavBar() === true ? 'height: 92vh !important' : 'height: 104vh !important'">

        <section class="hero" style="z-index: 2">
          <div class="hero-body header-bg3" style="z-index: 2"></div>
        </section>


        <section class="hero" :class="$screen.width > 1024 ? '' : ''" style="z-index: 2">

          <div class="columns mt-4">

            <div class="column" style="z-index: 2">

              <div class="machineinterface">
                <div class="monitor">
                  <div class="monitor-inner" style="padding-bottom: 60px">
                    <div class="screen-cutout">
                      <div class="screen">

                        <div class="my-pools-shadow column" style="z-index: 2; padding: 0px !important; margin-top: 20px;">
                          <div class="card pools-bg cardborder pb-5 " style="z-index: 2">
                            <div class="card-content poolselect-bg mb-6">
                              <section >
                                <b-tabs
                                    v-model="activeTab"
                                    headerClass="headertest"
                                    class="font1"
                                    position="is-centered"
                                    type="is-toggle"
                                    expanded
                                >
                                  <b-tab-item value="0" :label="this.pool"></b-tab-item>
                                </b-tabs>
                              </section>
                            </div>

                            <!-- TREASURY ACCOUNTS SECTION -->
                            <section v-if="activeTab === '0'">


                              <section class="hero">
                                <div class="hero-body">
                                  <div class="container container2 animated fadeInUp">
                                    <div class="columns is-multiline is-mobile">
                                      <div class="column is-12-desktop is-12-tablet is-12-mobile txshadow reddish">
                                        <ul class="list-unstyled has-text-left">
                                          <li class="cow">about pool</li>
                                          <li><span style="color:#ee95f9">Pool Type:</span> <span style="color:#4ae39e">CROWDFUND</span></li>
                                          <li><span style="color:#ee95f9">Contract:</span> <span style="color:#4ae39e">{{this.contract}}</span></li>
                                          <li><span style="color:#ee95f9">Pool ID:</span> <span style="color:#4ae39e">{{this.pool}}</span></li>
                                        </ul>
                                      </div>
                                      <div class="column is-12-desktop is-12-tablet is-12-mobile reddish">
                                        <ul class="list-unstyled has-text-left">
                                          <li class="cow">summary</li>
                                          <li><span style="color:#ee95f9">Pool Description:</span> <span style="color:#4ae39e">{{allCrowdFundingPoolsToShow[0].pool_description}}</span></li>
                                          <li><br/></li>
                                          <li><span style="color:#ee95f9; font-size: 12px">* This pool is raising funds to form the liquidity pair</span> <span style="color:#4ae39e">{{allCrowdFundingPoolsToShow[0].pool_pledge_token}}:{{allCrowdFundingPoolsToShow[0].pool_sold_token}}.</span></li>
                                          <li><span style="color:#ee95f9; font-size: 12px">* This pool will sell</span> <span style="color:#4ae39e">{{allCrowdFundingPoolsToShow[0].pool_amount_to_sell}} {{allCrowdFundingPoolsToShow[0].pool_sold_token}}</span> <span style="color:#ee95f9">for</span> <span style="color:#4ae39e">{{allCrowdFundingPoolsToShow[0].pool_price}} {{allCrowdFundingPoolsToShow[0].pool_pledge_token}}</span> <span style="color:#ee95f9">each and allocate</span> <span style="color:#4ae39e">{{allCrowdFundingPoolsToShow[0].pool_amount_to_dex}} {{allCrowdFundingPoolsToShow[0].pool_sold_token}}</span> <span style="color:#ee95f9">to liquidity with all funds raised.</span></li>
                                        </ul>
                                      </div>
                                      <div class="column is-12-desktop is-12-tablet is-12-mobile reddish">
                                        <ul class="list-unstyled has-text-left">
                                          <li class="cow">whitelist presale duration</li>
                                          <li><span style="color:#ee95f9">Presale Time Remaining:</span> <span style="color:#4ae39e"><countdown :start-time="currentDate" :end-time="allCrowdFundingPoolsToShow[0].pool_wl_end_time" ></countdown></span> </li>
                                          <li><span style="color:#ee95f9">Max Tokens Per WL Account:</span> <span style="color:#4ae39e">{{allCrowdFundingPoolsToShow[0].pool_wl_max_buy}}</span> </li>
                                        </ul>
                                      </div>
                                      <div class="column is-12-desktop is-12-tablet is-12-mobile reddish">
                                        <ul class="list-unstyled has-text-left">
                                          <li class="cow">campaign duration</li>
                                          <li><span style="color:#ee95f9">Crowdfund Start Time:</span> <span style="color:#4ae39e">{{allCrowdFundingPoolsToShow[0].pool_start_time}}</span></li>
                                          <li><span style="color:#ee95f9">Crowdfund End Time:</span> <span style="color:#4ae39e">{{allCrowdFundingPoolsToShow[0].pool_end_time}}</span> </li>
                                          <li><span style="color:#ee95f9">Crowdfund Time Remaining:</span> <span style="color:#4ae39e"><countdown :start-time="currentDate" :end-time="allCrowdFundingPoolsToShow[0].stamp_pool_end_time" ></countdown></span> </li>
                                        </ul>
                                      </div>
                                      <div class="column is-12-desktop is-12-tablet is-12-mobile reddish">
                                        <ul class="list-unstyled has-text-left">
                                          <li class="cow">funding</li>
                                          <li><span style="color:#ee95f9">Funds Raised:</span> <span style="color:#4ae39e">{{allCrowdFundingPoolsToShow[0].pool_tokens_locked}} {{allCrowdFundingPoolsToShow[0].pool_pledge_token}}</span> </li>
                                          <li><span style="color:#ee95f9">Total to Raise:</span> <span style="color:#4ae39e">{{ ( allCrowdFundingPoolsToShow[0].pool_amount_to_sell * allCrowdFundingPoolsToShow[0].pool_price)  }} {{allCrowdFundingPoolsToShow[0].pool_pledge_token}}</span> </li>
                                          <li><span style="color:#ee95f9">Hard Cap:</span> <span style="color:#4ae39e">{{allCrowdFundingPoolsToShow[0].pool_hard_cap}} {{allCrowdFundingPoolsToShow[0].pool_pledge_token}}</span> </li>
                                          <li><span style="color:#ee95f9">Is Funded?:</span> <span style="color:#4ae39e">{{allCrowdFundingPoolsToShow[0].pool_funded}}</span> </li>
                                        </ul>
                                      </div>
                                      <div class="column is-12-desktop is-12-tablet is-12-mobile reddish">
                                        <ul class="list-unstyled has-text-left">
                                          <li class="cow">strategy</li>
                                          <li><span style="color:#ee95f9">Token Being Sold:</span> <span style="color:#4ae39e">{{allCrowdFundingPoolsToShow[0].pool_sold_token}}</span> </li>
                                          <li><span style="color:#ee95f9">Sell Price:</span> <span style="color:#4ae39e">{{allCrowdFundingPoolsToShow[0].pool_price}} {{allCrowdFundingPoolsToShow[0].pool_pledge_token}}</span> </li>
                                          <li><span style="color:#ee95f9">Total Amount Being Sold:</span> <span style="color:#4ae39e">{{allCrowdFundingPoolsToShow[0].pool_amount_to_sell}}</span> </li>
                                          <li><span style="color:#ee95f9">Max Tokens Per Account:</span> <span style="color:#4ae39e">{{allCrowdFundingPoolsToShow[0].pool_max_buy}}</span> </li>
                                        </ul>
                                      </div>
                                      <div class="column is-12-desktop is-12-tablet is-12-mobile reddish">
                                        <ul class="list-unstyled has-text-left">
                                          <li class="cow">insurance</li>
                                          <li><span style="color:#ee95f9">Insurance Start Time:</span> <span style="color:#4ae39e">{{allCrowdFundingPoolsToShow[0].pool_end_time}}</span> </li>
                                          <li><span style="color:#ee95f9">Insurance End Time:</span> <span style="color:#4ae39e">{{allCrowdFundingPoolsToShow[0].pool_insurance_end_time}}</span> </li>
                                          <li><span style="color:#ee95f9">Insurance Time Remaining:</span> <span style="color:#4ae39e"><countdown :start-time="allCrowdFundingPoolsToShow[0].stamp_pool_start_time" :end-time="allCrowdFundingPoolsToShow[0].stamp_insurance_end_time" ></countdown></span> </li>
                                        </ul>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </section>


                              <section class="hero" v-if="allCrowdFundingPoolsToShow[0].is_pool_owner === true && allCrowdFundingPoolsToShow[0].pool_lp_claimed === false" >
                                <div class="hero-body">
                                  <div class="container container2 animated fadeInUp">
                                    <div class="columns is-multiline is-mobile">
                                      <div class="column is-12-desktop is-12-tablet is-12-mobile web">
                                        <ul class="list-unstyled has-text-left">
                                          <li class="cow">creator</li>
                                          <li class="cow">Hello pool creator, make sure you claim your LP tokens below once rug pull insurance has ended:</li>
                                          <li><br/></li>
                                          <li v-if="allCrowdFundingPoolsToShow[0].is_pool_owner === true"><b-button @click="clickClaimLP()" type="is-dark bginput" expanded>CLAIM LP</b-button></li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <section class="hero" v-if="allCrowdFundingPoolsToShow[0].is_pool_owner === true && allCrowdFundingPoolsToShow[0].pool_wl_active === true">
                                <div class="hero-body">
                                  <div class="container container2 animated fadeInUp">
                                    <div class="columns is-multiline is-mobile">
                                      <div class="column is-12-desktop is-12-tablet is-12-mobile web">
                                        <ul class="list-unstyled has-text-left">
                                          <li class="cow">creator</li>
                                          <li class="cow">Hello pool creator, you can add more accounts to your whitelist below:</li>
                                          <li><br/></li>
                                          <b-field horizontal message="Add kadena account to whitelist">
                                            <b-input class="bginput" v-model="accountToAddToWhitelist" placeholder="k:account"
                                                     type="text"
                                                     icon="magnify">
                                            </b-input>
                                            <p class="control">
                                              <b-button @click="clickAddToWhitelist()" type="is-dark bginput" label="ADD ACCOUNT" />
                                            </p>
                                          </b-field>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                            </section>




                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

          <div style="margin-bottom: 10%"></div>

        </section>
      </div>
    </section>


  </div>
</template>

<script>
import countdown from "@/components/countdownlist";
import {mapGetters} from "vuex";

//local state
export default {
  name: "CrowdFundAdminPage",
  props: {
    pool: {
      type: String,
      default: "0"
    },
    contract: {
      type: String,
      default: "0"
    },
  },
  components:{
    countdown
  },
  data: function () {
    return {
      debug: false,
      activeTab: "0",
      currentPage: "0",
      accountToAddToWhitelist: "k:account",
      showLoadingModal: true,
      crowdFundPool: [],
      allCrowdFundingPoolsToShow: [],
      currentDate: null,
    };
  },
  methods: {

    ...mapGetters("accounts", ["getAccountExists", "getShowNavBar", "getAccountConfirmed", "getAllCrowdFundingPools", "getUserCrowdFundingPools", "getCurrentSignRequest"]),

    coinName(namespace, name){
      let newnamespace = namespace+'.';
      let newname = name;
      if(namespace === null || namespace === 'null'){
        newnamespace = '';
      }
      if(name === null || name === 'null'){
        newname = '';
      }
      return newnamespace+newname;
    },

    async clickClaimLP(){

      let name = localStorage.getItem("accountName");

      let claim_payload = {
        poolID: this.pool,
        accountName: name
      }

      await this.$store.dispatch("accounts/crowdFundClaimLP",claim_payload);

    },

    async clickAddToWhitelist(){

      let wl_payload = {
        poolID: this.pool,
        accountName: this.accountToAddToWhitelist
      }

      await this.$store.dispatch("accounts/crowdFundAddWhitelist", wl_payload);

    },

    async reScanPools(){

      let name = localStorage.getItem("accountName");
      this.user_name = name;

      //Lets check if our user is logged in, if so, lets grab the users state from the blockchain, including their NFT balances
      if (name === null || name === "") {
        if (this.debug) {
          console.log("No user local accountName found.");
        }



      } else {

        let t_name_payload = {
          name: name
        }

        await this.$store.dispatch("accounts/getCurrentBindings", t_name_payload);

        if(this.pool !== null && this.pool !== undefined && this.pool !== "" && this.pool !== '0'){

          let t_cfp_payload = {
            poolName: this.pool
          }

          this.crowdFundPool = await this.$store.dispatch("accounts/getCrowdFundingPool", t_cfp_payload);

          if(this.debug){
            console.log("this.crowdFundPool");
            console.log(this.crowdFundPool);
          }


          //Now lets go through the list
          if (this.crowdFundPool.id) {

            let isPoolOwner = false;

            if (this.crowdFundPool['account'] === localStorage.getItem("accountName")) {
              isPoolOwner = true;
            }

            let t_pool_wl_endtime = this.crowdFundPool['wl-end-time'];

            if (this.crowdFundPool['wl-end-time'].time !== undefined) {
              t_pool_wl_endtime = this.crowdFundPool['wl-end-time'].time;
            }else if (this.crowdFundPool['wl-end-time'].timep !== undefined) {
              t_pool_wl_endtime = this.crowdFundPool['wl-end-time'].timep;
            }

            let date_pool_wl_end_time = new Date(t_pool_wl_endtime);
            let stamp_pool_wl_end_time = date_pool_wl_end_time.getTime();


            t_pool_wl_endtime = t_pool_wl_endtime.slice(0, 10);

            let t_pool_endtime = this.crowdFundPool['end-time'];

            if (this.crowdFundPool['end-time'].time !== undefined) {
              t_pool_endtime = this.crowdFundPool['end-time'].time;
            }else if (this.crowdFundPool['end-time'].timep !== undefined) {
              t_pool_endtime = this.crowdFundPool['end-time'].timep;
            }

            let date_pool_end_time = new Date(t_pool_endtime);
            let stamp_pool_end_time = date_pool_end_time.getTime();

            t_pool_endtime = t_pool_endtime.slice(0, 10);

            let t_pool_endtime_date = new Date(t_pool_endtime);
            let t_now = new Date();

            let can_generate = false;

            let lpClaimed = this.crowdFundPool['lp-claimed'];

            if(t_now > t_pool_endtime_date && lpClaimed === false){
              can_generate = true;
            }

            let wlactive = true;

            if(t_now > date_pool_wl_end_time){
              wlactive = false;
            }

            let wl_payload = {
              poolid: this.crowdFundPool.id,
            }

            let isWhitelisted = await this.$store.dispatch("accounts/getCheckWhitelist", wl_payload);

            let t_pool_start_time = this.crowdFundPool['start-time'];

            if (this.crowdFundPool['start-time'].time !== undefined) {
              t_pool_start_time = this.crowdFundPool['start-time'].time;
            }else if (this.crowdFundPool['start-time'].timep !== undefined) {
              t_pool_start_time = this.crowdFundPool['start-time'].timep;
            }

            let date_pool_start_time = new Date(t_pool_start_time);
            let stamp_pool_start_time = date_pool_start_time.getTime();

            t_pool_start_time = t_pool_start_time.slice(0, 10);

            let t_pool_insurance_end_time = this.crowdFundPool['insurance-end-time'];

            if (this.crowdFundPool['insurance-end-time'].time !== undefined) {
              t_pool_insurance_end_time = this.crowdFundPool['insurance-end-time'].time;
            }else if (this.crowdFundPool['insurance-end-time'].timep !== undefined) {
              t_pool_insurance_end_time = this.crowdFundPool['insurance-end-time'].timep;
            }

            let date_insurance_end_time = new Date(t_pool_insurance_end_time);
            let stamp_insurance_end_time = date_insurance_end_time.getTime();

            t_pool_insurance_end_time = t_pool_insurance_end_time.slice(0, 10);

            //let t_pool_start_time_date = new Date(t_pool_start_time);

            let canPush = true;

            if(t_now > t_pool_endtime_date && lpClaimed === true){
              canPush = false;
            }

            if(canPush === false){
              canPush = isPoolOwner;
            }

            if(this.debug){
              console.log("crowdfunding t_pool_endtime");
              console.log(t_pool_endtime);
              console.log("crowdfunding this.allActivePoolsFactoryStakeFungiv2");
              console.log(this.crowdFundPool);
              console.log("crowdfunding this.allActivePoolsFactoryStakeFungiv2['end-time']");
              console.log(this.crowdFundPool['end-time']);
              console.log("crowdfunding this.allActivePoolsFactoryStakeFungiv2['active']");
              console.log(this.crowdFundPool['active']);
              if(t_now > t_pool_endtime_date){
                console.log("t_now is GREATER than t_pool_endtime_date");
              }else {
                console.log("t_now is LESS than t_pool_endtime_date");
              }
            }

            let t_wallet_balance = 0;

            if(canPush === true){
              const tub_payloadA = {
                tokenA: this.coinName(this.crowdFundPool['pledge-token']['refName']['namespace'], this.crowdFundPool['pledge-token']['refName']['name']),
              };
              t_wallet_balance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadA);

              if(this.debug){
                console.log("crowdfunding fungiv2 t_wallet_balance updated");
                console.log(t_wallet_balance);
              }
            }

            if(this.debug){
              console.log("crowdfunding fungiv2 t_wallet_balance");
              console.log(t_wallet_balance);
            }


            let poolDetailObject = {
              pool_name: this.crowdFundPool.name,
              pool_id: this.crowdFundPool.id,
              pool_end_time: t_pool_endtime,
              pool_start_time: t_pool_start_time,
              pool_run_time: this.crowdFundPool['run-time'],
              pool_pledge_token: this.coinName(this.crowdFundPool['pledge-token']['refName']['namespace'], this.crowdFundPool['pledge-token']['refName']['name']),
              pool_sold_token: this.coinName(this.crowdFundPool['sold-token']['refName']['namespace'], this.crowdFundPool['sold-token']['refName']['name']),
              pool_tokens_locked: this.crowdFundPool['tokens-locked'],
              pool_balance: this.crowdFundPool['balance'],
              pool_active: this.crowdFundPool['active'],
              pool_amount_sold: this.crowdFundPool['amount-sold'],
              pool_amount_to_sell: this.crowdFundPool['amount-to-sell'],
              pool_amount_to_dex: this.crowdFundPool['amount-to-dex'],
              pool_hard_cap: this.crowdFundPool['hard-cap'],
              pool_description: this.crowdFundPool['description'],
              pool_lp_claimed: this.crowdFundPool['lp-claimed'],
              pool_paid: this.crowdFundPool['paid'],
              pool_price: this.crowdFundPool['price'],
              pool_funded: this.crowdFundPool['funded'],
              pool_initialized: this.crowdFundPool['initialized'],
              pool_insurance_time: this.crowdFundPool['insurance-time'],
              pool_insurance_end_time: t_pool_insurance_end_time,
              pool_contract: 'factory-crowdfund',
              pool_accountApproved: this.accountApproved,
              is_pool_owner: isPoolOwner,
              wallet_pledge_balance: t_wallet_balance,
              pledge_balance: 0,
              pledge_rewards: 0,
              stamp_pool_end_time: stamp_pool_end_time,
              stamp_pool_start_time: stamp_pool_start_time,
              stamp_insurance_end_time: stamp_insurance_end_time,
              can_generate: can_generate,
              pool_pledgers: this.crowdFundPool['pledgers'],
              pool_wl_active: wlactive,
              pool_wl_end_time: stamp_pool_wl_end_time,
              pool_wl_max_buy: this.crowdFundPool['wl-max-amount'],
              pool_max_buy: this.crowdFundPool['max-can-buy'],
              isWhitelisted: isWhitelisted
            }

            if (this.allCrowdFundingPoolsToShow.includes(poolDetailObject) === false && canPush === true) {
              this.allCrowdFundingPoolsToShow.push(poolDetailObject);
            }
          }

          if(this.debug){
            console.log("this.allCrowdFundingPoolsToShow");
            console.log(this.allCrowdFundingPoolsToShow);
          }


        }



      }

    }

  },
  async created() {

    this.currentDate = new Date().getTime()

    if(this.pool !== null && this.pool !== undefined){
      await this.reScanPools();
    }

    this.showLoadingModal = false;

  }

};
</script>

<style scoped>

.bginput{
  background-color: #1b1d2d !important;
}

.bginput:hover{
  color: #4ede77 !important;
  border: 1px solid #7e4ede !important;
}

.noscroll::-webkit-scrollbar {
  display: none;
}

#style-10::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #200d31;
  border-radius: 10px;
}

#style-10::-webkit-scrollbar
{
  width: 10px;
  background-color: #2c2432;
}

#style-10::-webkit-scrollbar-thumb
{
  background-color: #2c2432;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
  rgba(0, 0, 0, .2) 25%,
  transparent 25%,
  transparent 50%,
  rgba(0, 0, 0, .2) 50%,
  rgba(0, 0, 0, .2) 75%,
  transparent 75%,
  transparent)
}


/deep/ input {
  background-color: #363636;
  border: none;
  color: #4ae39e;
}



.hero.is-dark {
  background-color: #1D1F21
}

li a {
  color: #c5c8c6;
}

li a:hover,
li a:visited,
li a:active {
  text-decoration: none;
  transition: .1s;
}

.txshadow {
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

.reddish {
  color: #380245;
  text-shadow: 0px 0px 4px rgba(17, 156, 5, 0.77) !important;
}

#google {
  color: #CC6666;
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

#google ul li a {
  color: #c5c8c6;
  text-shadow: 0px 0px 4px rgba(59, 87, 58, 0.77);
}

#google ul li a:hover {
  color: #CC6666;
}

#jp {
  color: #81A2BE;
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

#jp ul li a {
  color: #c5c8c6;
}

#jp ul li a:hover {
  color: #81A2BE;
}

#boards {
  color: #B5BD68;
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

#boards ul li a {
  color: #c5c8c6;
}

#boards ul li a:hover {
  color: #B5BD68;
}

#dev {
  color: #B294BB;
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

#dev ul li a {
  color: #c5c8c6;
}

#dev ul li a:hover {
  color: #B294BB;
}

#docs {
  color: #A64848;
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

#docs ul li a {
  color: #c5c8c6;
}

#docs ul li a:hover {
  color: #A64848;
}

#utils {
  color: #597C99;
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

#utils ul li a {
  color: #c5c8c6;
}

#utils ul li a:hover {
  color: #597C99;
}

#web {
  color: #7B8235;
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

#web ul li a {
  color: #c5c8c6;
}

#web ul li a:hover {
  color: #7B8235;
}


.web {
  color: #081309;
  text-shadow: 0px 0px 4px rgba(59, 87, 58, 0.77);
}

.web ul li a {
  color: #c5c8c6;
}

.web ul li a:hover {
  color: #7B8235;
}

#server {
  color: #845C90;
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

#server ul li a {
  color: #c5c8c6;
}

#server ul li a:hover {
  color: #845C90;
}

@media (min-width: 544px) {
  .container {
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .container {
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  .container {
    font-size: 16px;
  }
}

.form-control {
  background-color: #1D1F21;
  display: none;
  padding: 0px;
  border: none;
  color: #c5c8c6;
}

input[type="search-input"]:focus {
  background-color: #1D1F21;
  color: #c5c8c6;
}

input[type="search-input"]:hover {
  background-color: #222426;
  color: #c5c8c6;
}

#searchform {
  display: block;
  text-align: center;
  width: 100%;
}

form {
  display: inline-block;
  width: 90%;
}

label {
  color: #CC6666;
  display: inline-block;
  float: left;
  padding-right: 1em;
}

input {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
  box-shadow: inset 0 30px 60px -12px rgba(50, 50, 93, 0.25), inset 0 18px 36px -18px rgba(0, 0, 0, 0.30);
}

.label {
  color: #4ae39e;
  display: block;
  font-size: 1rem;
  font-weight: 700;
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient( #3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5% );
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 40px;
}


.machineinterface {
  display: block;
  width: 100%;
  margin: -80px 0px;
  position: relative;
}

.monitor {
  min-height: 100vh;
  padding-top: 85px;
  z-index: 2;
  display: block;
  width: 100%;
  background-color: #47485a;
  position: relative;
  padding-bottom: 40px;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, #26202b08 35px, #26202b0d 70px);
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
}

.monitor-inner {
  padding-top: 15px;
  display: block;
  width: 95%;
  background-image: conic-gradient( #414254 0 10.5%, #414254 11% 11.5%, #414254 12% 38%, #414254 39% 61.5%, #414254 62% 88%, #414254 88.5% 89%, #414254 89.25% );
  margin: auto;
  position: relative;
  top: 20px;
  left: 0;
  right: 0;
  border-radius: 5px;
  margin-top: -51px;
  padding-bottom: 60px;
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient(#3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5%);
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 50px;
}

.screen {
  display: block;
  width: 95%;
  background-color: #4F5555;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  margin-top: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 30px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), transparent 85%), radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%), linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 7px -4px #4f545f inset, 0 -2px 1px #5c6574 inset;
}
.header-bg3 {
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
  height: 1px;
  padding-top: 0px;
}

.b-tabs .tabs {
  overflow: hidden !important;
}

/deep/ .b-tabs .tabs {
  overflow: hidden !important;
}

::v-deep .b-tabs .tabs {
  overflow: hidden !important;
}


.center-flex{
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.title-back {
  color: #ffffff;
  font-family: Source Code Pro,monospace !important;
  border-radius: 1rem;
  background: -webkit-linear-gradient(90deg, rgba(49, 56, 64, 0.89), rgba(55, 45, 69, 0.85));
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}


/* card background */
.home-card-bg {
  background: #ebeae5;
}

.inset-bg{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding-bottom: 2rem;
  border-radius: 100px;
}

.inset-bg2:active{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  width: 100%;
  height: 30px;
  position: relative;
  border-radius: 1rem;
  align-self: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 4px;
}


.col-title {
  color: #36c0ea;
  font-size: 14px;
}

.pending-title {
  color: #3cea36;
  font-size: 14px;
}

.pending-stat {
  color: #3cea36;
  font-size: 1em;
}

.pending-col{
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.col-shadow1{
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider{
  font-size: 12px;
}

.pool-name{
  font-size: 14px;
  color: #36c0ea;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: #222b3a;
}

.pool-main {
  background-color: #222b3a;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
}

.bginput {
  background-color:#3a6a7c21 !important;
}

.pool-section-title {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);

  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #46a9e7;
  font-family: Source Code Pro,monospace !important;
}


.topbackgroundanimation {
  position: absolute;
  height: 200px;
  width: 100%;
  z-index: 1;
}

/* border for item cards */
.cardborder {
  border-radius: 1rem;
  color: #000000;
}

.poolselect-bg {
  background-color: #222b3a !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}



/deep/ .tabs.is-toggle li.is-active a {
  background-color: #252f3e;
  color: #4ae39e;
  z-index: 1;
  font-family: Source Code Pro,monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}


/deep/ .tabs.is-toggle {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  background-color: #252f3e;
}

/deep/ .tabs.is-toggle a {
  margin-bottom: 0;
  position: relative;
  font-family: Source Code Pro,monospace !important;
  border:none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  color: #476074;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: #222b3a;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: #222b3a;
  border:none;
  color: #00b89c;
}


#style-8::-webkit-scrollbar-track
{
  border: 1px solid black;
  background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar
{
  width: 10px;
  background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar-thumb
{
  background-color: #000000;
}



.pools-card{
  padding-top:.5rem;
  color: #4ae39e;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow{
  box-shadow: rgba(0, 0, 0, 0.56) 8px -5px 40px;
  border-radius: 30px;
}

.stake-btn-col{
  display: flex;
  justify-content: center;
}

.btn{
  color: #87b6e5;
  background: rgba(3, 150, 255, 0.32);
  background-repeat: no-repeat;
  font-family: Source Code Pro,monospace !important;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}
.btn:focus,
.btn:hover{
  color: #fff;
  background: radial-gradient(circle at center,rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
}
@keyframes bubbles{
  100%{
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%,26% -96%,-1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}
@media only screen and (max-width: 767px){
  .btn{ margin-bottom: 30px; }
}

@media screen and (max-width: 769px), print {
  .screen-cutout {
    background-image: none !important;
    background-color: transparent !important;
  }

  /deep/ .super-select-dropdown {
    background-color: #333b4c !important;
    border: 1px solid !important;
    border-color: #2f597d !important;
    color: #67b6ef !important;
    width: 380px !important;
  }
}

/* page loading style */
.loaderstyle {
  z-index: 1;
  position: absolute !important;
}

#wraper {
  width: 400px;
  height: 350px;
  margin: 90px 11px;
  position: relative;
  border-radius: 40px;
}

#wraper2 {
  width: 450px;
  height: 350px;
  margin: 0 auto;
  position: relative;
  border-radius: 40px;
  margin-left: -36px;
}


</style>
<style lang="scss" scoped>

.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro,monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

$message-header-background-color: #e3f;


.loading-page-container {
  opacity: .95;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  z-index: 1;

}

.loading-page-container .progress {
  position: relative;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 6px solid #20182d;
  background-color: #2c2938;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: hard-light;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;

  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 1;
}

.loading-page-container .progress .inner {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: 50%;
  margin-left: -4px;
  margin-top: -1px;
  width: 196.5px;
  height: 196.5px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.loading-page-container .progress .inner .water {
  opacity: .55;
  position: absolute;
  z-index: 1;
  width: 200%;
  height: 200%;
  left: -50%;
  border-radius: 40%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  animation-name: spin;
  top: 55%;
  background: #32BAFA;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  box-shadow: 0 0 20px #152A4A;

}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  display:flex;
  width: 35px;
  height: 35px;
  border-radius: 0.5rem;
  color: #296992;
}
.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}



.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}


// for styles that do not have ascii characters setting the height
$height: 15px;

.outer {
  margin-bottom: $height;
  width: 100%;
  height: $height;
}

.plain {

  &.outer {
    border: 1px solid limegreen;
  }

  .inner {
    width: 90%;
    background-color: limegreen;
    height: $height;
  }
}

.borderless .inner {
  width: 90%;
  background-color: limegreen;
  height: $height;
}

.punctuation.outer {
  border: 1px solid limegreen;
  line-height: 1.35;
}

@-webkit-keyframes progress-bar-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes spinning {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes pulsing-opacity {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}


.plain .inner, .borderless .inner {
  animation: progress-bar-width steps(20) 5s infinite;
}

.pipes .inner, .blocks .inner, .blocks-gaps {
  animation: progress-bar-width 5s infinite;
  overflow: hidden;
  animation-timing-function: linear;
}

.blocks-gaps .inner {
  letter-spacing: 2px;
}

.activity {
  text-align: center;
  overflow: hidden !important;
}

.spinning {
  animation: spinning steps(8, end) 2s infinite;
}

.pulsing {
  animation: pulsing-opacity steps(3) 0.5s infinite alternate;
}

</style>
<style scoped>
.container3 {
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  /* padding: 25px; */
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), #0f5cbb26 85%), radial-gradient(rgb(38 58 74 / 75%), transparent 70%), linear-gradient(#0e081a00, #211922, #0f142000);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
}
.cow {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#14ff7d, #00f3ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}
</style>

<style lang="scss" scoped>
.container2 {
  background-color: #1D1F21;
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  padding: 25px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background: -webkit-linear-gradient(#3e10463d, #21333e70) !important;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 37px 59px #0e192257 inset, 0 0px 2px #ff0ef8 inset;
  -webkit-animation: scanline-data-v-0d33d4c2 8s linear infinite;
  animation: scanline-data-v-0d33d4c2 8s linear infinite;
}

article {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 900px) {
    padding: 5px;
  }

  @media (max-width: 899px) {
    padding-bottom: 56px;
  }
  background-color: rgba(192, 192, 192, 0);
  color: #323232;
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  position: relative;
  & > * + * {
    margin-top: 1.25em;
  }

  &:after {
    content: "🥩";
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding-bottom: 0.25em;
    position: absolute;
    top: 0.75em;
    right: 0.75em;
    width: 1.75em;
    height: 1.75em;
    filter: invert(100) opacity(40%);

    background-color: transparent;
  }
}

.headline {
  font-size: 2.5em;
  font-weight: 700;
}

.subhead {
  font-size: 1.25em;
  margin-top: 0.25em;
}

.article-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    width: 3em;
    height: 3em;
    flex-shrink: 0;
  }

  div:first-of-type {
    margin-left: 0.75em;
    font-size: 0.875em;
    margin-right: auto;
    padding-right: 0.75em;
  }

  div:last-of-type {
    font-size: 0.875em;
  }
}

aside {
  padding: 4em;
  border-right: 1px solid rgba(171, 15, 232, 0.1);
  border-bottom: 1px solid rgba(171, 15, 232, 0.1);
  border-left: 1px solid rgba(171, 15, 232, 0.1);
  border-top: 1px solid rgba(171, 15, 232, 0.1);
  clear: both;
  text-shadow: 0 0 10px, 0 0 40px;
  background-color: rgb(46 40 66 / 39%);
}

.tag {
  display: inline-block;
  margin-right: 0.25em;
  margin-top: 0.25em;
  border-right: 1px solid rgba(238, 238, 238, 0.19);
  border-bottom: 1px solid rgba(238, 238, 238, 0.13);
  border-left: 1px solid #444;
  border-top: 1px solid #444;
  padding: 0.125em 0.375em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}



figure {
  background-color: #c0c0c0;
  box-shadow: 0 0 0 1px #eee, 0 0 0 2px #c0c0c0, 0 0 0 3px #444;
  position: relative;
  margin-bottom: 1.25em;
  width: calc(100% + 1.5em + 2em);
  text-shadow: 0 0 10px, 0 0 40px;
  @media (min-width: 800px) {
    max-width: 50%;
  }
  &:nth-of-type(odd) {
    float: left;
    margin-right: 1.5em;
    margin-left: -3em;
  }

  &:nth-of-type(even) {
    float: right;
    margin-left: 1.5em;
    margin-right: -3em;
  }

  &:after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    background-image: linear-gradient(
            to top,
            rgba(#ff9100, 0.2) 0%,
            rgba(#ffe630, 0.2) 60%
    ),
    linear-gradient(20deg, rgba(#ff0, 0.5) 0%, rgba(#ff0, 0) 35%);
    box-shadow: inset 0px 0px 100px rgba(0, 0, 20, 1);
    z-index: 1;
  }

  img {
    // opacity: 0;
    // filter: hue-rotate(90deg);
    // filter: sepia(20%) brightness(10%) contrast(130%)
  }
  figcaption {
    font-family: "DotGothic16", monospace;
    background-color: #6f4b80;
    box-shadow: inset 0 -1px 0 0 #020202;
    color: #eee;
    text-shadow: 0 0 10px, 0 0 40px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.25em;
    font-size: 0.875em;
    z-index: 2;
  }
}
.blink {
  opacity: 1;
  animation: blink 1s linear infinite;
}

.scanline  {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba(255,255,255,0.02);
  animation: scanline 8s linear infinite;
  pointer-events: none;
  &:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: rgba(255,255,255,0.02);
    animation: scanline 8s linear infinite;
    pointer-events: none;
    z-index: 10;
  }

}

// Scanline height 1005
@keyframes scanline {
  0.01% {
    height: 0;
  }
  99.99% {
    height: 100%
  }
  100% {
    height: 0;
  }
}
</style>
<style lang="scss" scoped>
main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro,monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

$message-header-background-color: #e3f;


.loading-page-container {
  opacity: .95;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  z-index: 1;

}

.loading-page-container .progress {
  position: relative;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 6px solid #20182d;
  background-color: #2c2938;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: hard-light;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;

  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 1;
}

.loading-page-container .progress .inner {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: 50%;
  margin-left: -4px;
  margin-top: -1px;
  width: 196.5px;
  height: 196.5px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.loading-page-container .progress .inner .water {
  opacity: .55;
  position: absolute;
  z-index: 1;
  width: 200%;
  height: 200%;
  left: -50%;
  border-radius: 40%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  animation-name: spin;
  top: 55%;
  background: #32BAFA;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  box-shadow: 0 0 20px #152A4A;

}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  display:flex;
  width: 35px;
  height: 35px;
  border-radius: 0.5rem;
  color: #296992;
}
.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}



.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}


// for styles that do not have ascii characters setting the height
$height: 15px;

.outer {
  margin-bottom: $height;
  width: 100%;
  height: $height;
}

.plain {

  &.outer {
    border: 1px solid limegreen;
  }

  .inner {
    width: 90%;
    background-color: limegreen;
    height: $height;
  }
}

.borderless .inner {
  width: 90%;
  background-color: limegreen;
  height: $height;
}

.punctuation.outer {
  border: 1px solid limegreen;
  line-height: 1.35;
}

@-webkit-keyframes progress-bar-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes spinning {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes pulsing-opacity {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}


.plain .inner, .borderless .inner {
  animation: progress-bar-width steps(20) 5s infinite;
}

.pipes .inner, .blocks .inner, .blocks-gaps {
  animation: progress-bar-width 5s infinite;
  overflow: hidden;
  animation-timing-function: linear;
}

.blocks-gaps .inner {
  letter-spacing: 2px;
}

.activity {
  text-align: center;
  overflow: hidden !important;
}

.spinning {
  animation: spinning steps(8, end) 8s infinite;
}

.pulsing {
  animation: pulsing-opacity steps(3) 0.5s infinite alternate;
}

</style>
