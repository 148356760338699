<template>
  <section>


    <!--    Bulma Navbar NOT LOGGED IN-->
    <transition name="fade">
      <b-navbar :mobile-burger="false" class="navbar-bg" style="z-index: 5">
        <template #brand>
          <div @click="goHome()" class="columns gohome" :class="$screen.width > 1024 ? 'p-0 m-0' : ''" style="align-items: center;display: flex;justify-content: center;flex-direction: row;margin-top: -5px;margin-left: -2px;">
            <div class="column is-narrow is-narrow-mobile"><img src="../assets/stake-factory-icon.svg" height="40" width="40"/></div>
            <div class="column is-10"><span class="logotitle">&nbsp; Kadena Stake Factory &nbsp;</span></div>
          </div>
        </template>
        <template #start>
          <b-navbar-dropdown style="z-index: 4;" v-if="accountConfirmedNavBar && displayFixedAccountName !== null && displayFixedAccountName !== 'null'" label="Pools">
            <b-navbar-item href="/mypools">
              My Pools
            </b-navbar-item>
            <b-navbar-item href="/liquiditypools">
              Liquidity Pools
            </b-navbar-item>
            <b-navbar-item href="/stakingpools">
              Staking Pools
            </b-navbar-item>
            <b-navbar-item href="/crowdfundingpools">
              Crowdfunding Pools
            </b-navbar-item>
            <b-navbar-item href="/daos">
             DAOs
            </b-navbar-item>
          </b-navbar-dropdown>
          <b-navbar-dropdown style="z-index: 4;" v-if="accountConfirmedNavBar && displayFixedAccountName !== null && displayFixedAccountName !== 'null'" label="NFTs">
            <b-navbar-item href="/mynfts">
              My NFTs
            </b-navbar-item>
            <b-navbar-item href="/market">
              Marketplace
            </b-navbar-item>
            <b-navbar-item href="/nftpools">
              NFT Staking Pools
            </b-navbar-item>
            <b-navbar-item href="/explore">
              Explore NFTs
            </b-navbar-item>
          </b-navbar-dropdown>
          <b-navbar-item v-if="accountConfirmedNavBar && displayFixedAccountName !== null && displayFixedAccountName !== 'null'" class="font-color-blue ml-3 navbar-font-size" :style="$screen.width < 1024 ? 'display: flex; justify-content: center;' : ''" href="/createpool">
            Factory
          </b-navbar-item>
          <b-navbar-item :style="$screen.width < 1024 ? 'display: flex; justify-content: center;' : ''" class="font-color-blue ml-3 navbar-font-size" href="/about">
            About
          </b-navbar-item>
        </template>
        <template v-if="$screen.width > 576" :showTransactionButton="this.showTransactionButton" :getRequestPending="this.getRequestPending()" :xwalletconnected="this.xwalletconnected" :accountConfirmedNavBar="this.accountConfirmedNavBar" :displayFixedAccountName="this.displayFixedAccountName()" #end>
          <b-navbar-item v-if="showTransactionButton === true" tag="div" :style="$screen.width < 1024 ? 'display: flex; justify-content: center;' : ''">
            Hold on while we confirm your transaction ->
            <div class="buttons" @click="clickShowTransactionModal">
              <a class="button" style="background-color: transparent; border: none;">
                <div class="clockloader"></div>
              </a>
            </div>
          </b-navbar-item>
          <b-navbar-item @click="clickShowNavBar()" v-if="accountConfirmedNavBar && displayFixedAccountName !== null && displayFixedAccountName !== 'null'" id="navbar-item2" :style="$screen.width < 1024 ? 'display: flex; justify-content: center;' : ''" tag="div">
            <div class="columns" :class="$screen.width > 1024 ? 'p-0 m-0' : ''" style="align-items: center;display: flex;justify-content: center;flex-direction: row;margin-top: -5px;margin-left: -2px;">
              <div class="column"><span class="steaktitle">&nbsp;{{reduceBalance(l_userStakeBalance,2)}} 🥩 &nbsp;</span></div>
            </div>
          </b-navbar-item>
          <b-navbar-item @click="clickShowNavBar()" v-if="accountConfirmedNavBar && displayFixedAccountName !== null && displayFixedAccountName !== 'null'" id="navbar-item2" :style="$screen.width < 1024 ? 'display: flex; justify-content: center;' : ''" tag="div">
            <div class="columns" :class="$screen.width > 1024 ? 'p-0 m-0' : ''" style="align-items: center;display: flex;justify-content: center;flex-direction: row;margin-top: -5px;margin-left: -2px;">
              <div class="column"><span class="steaktitle"> 🏭 &nbsp;</span></div>
            </div>
          </b-navbar-item>

          <b-navbar-item id="navbar-item2" :style="$screen.width < 1024 ? 'display: flex; justify-content: center;' : ''" tag="div">
            <div v-if="accountConfirmedNavBar && displayFixedAccountName !== null && displayFixedAccountName !== 'null'" class="buttons" @click="clickShowMyAccount">
              <a class="wave-btn">
                <span class="wave-btn__text font2">{{ displayFixedAccountName }}</span>
                <span class="wave-btn__waves"></span>
              </a>
            </div>
            <div v-if="accountConfirmedNavBar === false || displayFixedAccountName === null || displayFixedAccountName === 'null'" class="wrapper-wave" :style="$screen.width < 1024 ? 'display: flex; justify-content: center;' : ''" @click="connectWallet">
              <a class="wave-btn">
                <span class="wave-btn__text font2">Connect Wallet</span>
                <span class="wave-btn__waves"></span>
              </a>
            </div>
          </b-navbar-item>
          <b-navbar-item v-if="$screen.width < 1024"><div class="column is-12 pool-main is-centered" style="margin-top: -25px; padding: 0px;">
            <div class="columns is-centered">
              <div class="column pool-item is-centered is-align-self-center">
                <div class="columns">
                  <div class="column is-align-self-center">
                    <div class="column is-align-self-center" style="z-index: 3">
                      <div class="screen-container" style="z-index: 3">
                        <div class="screen-display">
                          <p>My Deposits</p>
                          <p>Token: {{reduceBalance(l_portfolioDepositedTokens, 2)}}</p>
                          <p>LP: {{reduceBalance(l_portfolioDepositedLPTokens, 2)}}</p>
                          <p>NFT: {{reduceBalance(l_portfolioDepositedNFTs, 2)}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-align-self-center" style="margin-top: -25px;">
                    <div class="column is-align-self-center" style="z-index: 3">
                      <div class="screen-container" style="z-index: 3">
                        <div class="screen-display">
                          <p>My Yield</p>
                          <p>Token: {{reduceBalance(l_portfolioYieldTokens, 2)}}</p>
                          <p>LP: {{reduceBalance(l_portfolioYieldLPTokens, 2)}}</p>
                          <p>NFT: {{reduceBalance(l_portfolioYieldNFTs, 2)}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
          </b-navbar-item>
        </template>
      </b-navbar>
    </transition>


    <transition name="fade">
      <!--    Bulma Navbar LOGGED IN-->
      <b-navbar :mobile-burger="false" v-if="this.getShowNavBar() === true && accountConfirmedNavBar && displayFixedAccountName !== null && displayFixedAccountName !== 'null' && $screen.width > 1024" class="navbar-bg" style=" z-index: 4">
        <template #start>
          <div class="column is-12 pool-main is-centered">
            <div class="columns is-centered">
              <div class="column is-1"></div>
              <div class="column pool-item is-centered is-align-self-center">
                <div class="columns">
                  <div class="column is-align-self-center">
                    <div class="column is-align-self-center" style="z-index: 3">
                      <div class="screen-container" style="z-index: 3">
                        <div class="screen-display">
                          <p>My Deposits</p>
                          <p>Token: {{reduceBalance(l_portfolioDepositedTokens, 2)}}</p>
                          <p>LP: {{reduceBalance(l_portfolioDepositedLPTokens, 2)}}</p>
                          <p>NFT: {{reduceBalance(l_portfolioDepositedNFTs, 2)}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-align-self-center">
                    <div class="column is-align-self-center" style="z-index: 3">
                      <div class="screen-container" style="z-index: 3">
                        <div class="screen-display">
                          <p>My Yield</p>
                          <p>Token: {{reduceBalance(l_portfolioYieldTokens, 2)}}</p>
                          <p>LP: {{reduceBalance(l_portfolioYieldLPTokens, 2)}}</p>
                          <p>NFT: {{reduceBalance(l_portfolioYieldNFTs, 2)}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="column is-1"></div>

            </div>
          </div>

        </template>


        <template #end>
          <div class="column is-12 pool-main is-centered">
            <div class="columns is-centered">
              <div class="column is-1"></div>
              <div class="column pool-item is-centered is-align-self-center">
                <div class="columns">
                  <div class="column is-align-self-center">
                    <div class="column is-align-self-center" style="z-index: 3">
                      <div class="screen-container-port" style="z-index: 3">
                        <div class="screen-display-port" style="text-align: end;">
                          <p>Factory Deposits</p>
                          <p>Token: {{reduceBalance(l_platformDepositedTokens, 2)}}</p>
                          <p>LP: {{reduceBalance(l_platformDepositedLPTokens, 2)}}</p>
                          <p>NFT: {{reduceBalance(l_platformDepositedNFTs, 2)}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-align-self-center">
                    <div class="screen-container-port" style="z-index: 3">
                      <div class="screen-display-port" style="text-align: end;">
                        <p>Factory Yield</p>
                        <p>Token: {{reduceBalance(l_platformYieldTokens, 2)}}</p>
                        <p>LP: {{reduceBalance(l_platformYieldLPTokens, 2)}}</p>
                        <p>NFT: {{reduceBalance(l_platformYieldNFTs, 2)}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-1"></div>
            </div>
          </div>
        </template>
      </b-navbar>
    </transition>




    <!--Connect Wallet Modal LG-->
    <b-modal v-if="this.showConnectWalletModal === true && $screen.width > 480" v-model="showConnectWalletModal" :width="640" scroll="keep" style="border-radius:20px;">\

      <div class="form-bg" style="border-radius:20px;">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-md-offset-4 blue-bg">
              <div class="form-container form-shadow">
                <div class="form-icon"><img src="../assets/stake-factory-icon.svg" width="70px"></div>
                <div class="container pb-4">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader">
                      Enter K:Account or Connect X-Wallet
                    </h3>
                  </div>
                </div>

                <b-message v-if="this.getAccountExists() === true && accountNameToVerify !== 'null'" class="is-success" style="background-color: rgb(9 40 52);">
                  <div class="columns is-centered pb-0">
                    <div class="column is-centered msg-center" style="overflow-wrap: anywhere !important;">
                      K:Account Found!
                    </div>
                  </div>
                </b-message>

                <b-message v-if="this.getAccountExists() === false || accountNameToVerify === 'null'" class="is-danger" style="background-color: rgb(9 40 52);">
                  <div class="columns is-centered pb-0">
                    <div class="column is-centered msg-center" style="overflow-wrap: anywhere !important;">
                      No K:Account Found!
                    </div>
                  </div>
                </b-message>

                <form class="form-horizontal">
                  <div class="form-group">
                    <label>K:Account</label>
                    <input @change="autoVerify()" @input="autoVerify()" class="form-control" style="width:100%;"
                           v-model="accountNameToVerify" placeholder="My K:Account">
                  </div>
                  <div class="columns is-mobile is-centered">
                    <div class="column">
                      <div v-if="this.getAccountExists() === true" class="notification has-text-centered inset-bg-rounded" style="background-color: #2d3a45;">
                        <div @click="clickConfirm" class="btn2 btn-lg stake-btn" style="border-radius: 1rem !important;">
                          <span>Connect k:Account</span>
                        </div>
                      </div>

                      <div v-if="this.getAccountExists() === false || this.getAccountExists() === '' || this.getAccountExists() === null || this.getAccountExists() === undefined" class="notification has-text-centered inset-bg-rounded" style="background-color: #2d3a45;">
                        <b-button disabled
                                  type="is-primary">Connect K:Account
                        </b-button>
                      </div>
                    </div>
                    <div class="column">
                      <div class="notification has-text-centered inset-bg-rounded" style="background-color: #2d3a45;">
                        <div @click="connectxwallet" class="btn2 btn-lg stake-btn" style="border-radius: 1rem !important;">
                          <span>Connect X-Wallet</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </b-modal>

    <!--Connect Wallet Modal SM-->
    <b-modal v-if="this.showConnectWalletModal === true && $screen.width <= 480" v-model="showConnectWalletModal" :width="640" scroll="keep" style="border-radius:20px;">\

      <div class="container" style="height:500px;">
        <div class="columns">
          <div class="column">
            <div class="form-container form-shadow">
              <div class="form-icon"><img src="../assets/stake-factory-icon.svg" width="70px"></div>
              <div class="container pb-4">
                <div class="level-item has-text-centered">
                  <h3 class="accountHeader">
                    Enter K:Account or Connect X-Wallet
                  </h3>
                </div>
              </div>

              <b-message v-if="this.getAccountExists() === true && accountNameToVerify !== 'null'" class="is-success" style="background-color: rgb(9 40 52);">
                <div class="columns is-centered pb-0">
                  <div class="column is-centered msg-center" style="overflow-wrap: anywhere !important;">
                    K:Account Found!
                  </div>
                </div>
              </b-message>

              <b-message v-if="this.getAccountExists() === false || accountNameToVerify === 'null'" class="is-danger" style="background-color: rgb(9 40 52);">
                <div class="columns is-centered pb-0">
                  <div class="column is-centered msg-center" style="overflow-wrap: anywhere !important;">
                    No K:Account Found!
                  </div>
                </div>
              </b-message>

              <form class="form-horizontal">
                <div class="form-group">
                  <label>K:Account</label>
                  <input @change="autoVerify()" @input="autoVerify()" class="form-control" style="width:100%;"
                         v-model="accountNameToVerify" placeholder="My K:Account">
                </div>
                <div class="columns is-mobile is-centered" style="flex-direction: column;">
                  <div class="column">
                    <div v-if="this.getAccountExists() === true" class="notification has-text-centered inset-bg-rounded" style="background-color: #2d3a45;">
                      <div @click="clickConfirm" class="btn2 btn-lg stake-btn" style="border-radius: 1rem !important;">
                        <span>Connect k:Account</span>
                      </div>
                    </div>

                    <div v-if="this.getAccountExists() === false || this.getAccountExists() === '' || this.getAccountExists() === null || this.getAccountExists() === undefined" class="notification has-text-centered inset-bg-rounded" style="background-color: #2d3a45;">
                      <b-button disabled
                                type="is-primary">Connect K:Account
                      </b-button>
                    </div>
                  </div>
                  <div class="column">
                    <div class="notification has-text-centered inset-bg-rounded" style="background-color: #2d3a45;">
                      <div @click="connectxwallet" class="btn2 btn-lg stake-btn" style="border-radius: 1rem !important;">
                        <span>Connect X-Wallet</span>
                      </div>
                    </div>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </b-modal>

    <!--Confirm X-Wallet Modal- shown after user clicks 'connect xwallet'-->
    <b-modal v-if="this.showXWalletFinalModal === true" v-model="showXWalletFinalModal" :width="640" scroll="keep">

      <div class="form-bg" style="border-radius:20px;">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-md-offset-4 blue-bg">
              <div class="form-container form-shadow">
                <div class="form-icon"><img src="../assets/stake-factory-icon.svg" width="70px"></div>
                <div class="container pb-4">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader">
                      A Stake Factory connection request has been sent to your wallet.
                    </h3>
                  </div>
                </div>

                <b-message class="is-success" style="background-color: rgb(9 40 52);">
                  <div class="columns is-centered pb-0">
                    <div class="column is-centered msg-center" style="overflow-wrap: anywhere !important;">
                      Connect and 'Save' the Stake Factory connection with your X-Wallet account and Confirm below when ready.
                    </div>
                  </div>
                </b-message>


                <form class="form-horizontal">
                  <div class="columns is-mobile is-centered">
                    <div class="column">
                      <div class="notification has-text-centered inset-bg-rounded" style="background-color: #2d3a45;">
                        <div @click="confirmxwallet" class="btn2 btn-lg stake-btn" style="border-radius: 1rem !important;">
                          <span>Confirm</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!--Transaction Pending Modal- shown when user clicks pending click icon-->
    <b-modal v-if="this.showTransactionModal === true" v-model="showTransactionModal" :width="640" scroll="keep">

      <div class="form-bg" style="border-radius:20px;">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-md-offset-4 blue-bg">
              <div class="form-container form-shadow">
                <div class="form-icon"><img src="../assets/stake-factory-icon.svg" width="70px"></div>
                <div class="container pb-4">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader">
                      Awaiting pending transaction confirmation:
                    </h3>
                  </div>
                </div>

                <b-message class="is-info" style="background-color: rgb(9 40 52);">
                  <div class="columns is-centered pb-0">
                    <div class="column is-centered msg-center" style="overflow-wrap: anywhere !important;">
                      <a :href="exploerLink" target="_blank" style="color: #1e76bf">{{this.getTransactionHash()}}</a>
                    </div>
                  </div>
                </b-message>

                <b-message v-if="this.getTransactionFailed() === false" class="is-success" style="background-color: rgb(9 40 52);">
                  <div class="columns is-centered pb-0">
                    <div class="column is-centered msg-center" style="overflow-wrap: anywhere !important;">
                      Transaction confirmed!
                    </div>
                  </div>
                </b-message>

                <b-message v-if="this.getTransactionFailed() === true" class="is-danger" style="background-color: rgb(9 40 52);">
                  <div class="columns is-centered pb-0">
                    <div class="column is-centered msg-center" style="overflow-wrap: anywhere !important;">
                      Transaction failed.
                    </div>
                  </div>
                </b-message>

                <form class="form-horizontal">
                  <div class="columns is-mobile is-centered">
                    <div class="column">
                      <div class="notification has-text-centered inset-bg-rounded" style="background-color: #2d3a45;">
                        <div @click="closeTransactionModal" class="btn2 btn-lg stake-btn" style="border-radius: 1rem !important;">
                          <span>Confirm</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!--My Wallet Modal MD LG - shown when user clicks their account-->
    <b-modal v-if="this.showMyWalletModal === true && $screen.width > 480" v-model="showMyWalletModal" :width="640" scroll="keep">

      <div class="form-bg" style="border-radius:20px;">
        <div class="container">
          <div class="columns">
            <div class="column is-12 is-12-mobile blue-bg">
              <div class="form-container form-shadow">
                <div class="form-icon"><img src="../assets/stake-factory-icon.svg" width="70px"></div>
                <div class="container pb-4">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader">
                      My Account:
                    </h3>
                  </div>
                </div>

                <b-message class="is-info" style="background-color: rgb(9 40 52);">
                  <div class="columns is-centered pb-0">
                    <div class="column is-centered msg-center" style="overflow-wrap: anywhere !important;">
                      {{getAccountName()}}
                    </div>
                  </div>
                </b-message>


                <form class="form-horizontal">
                  <div class="columns is-mobile is-centered">
                    <div class="column">
                      <div class="notification has-text-centered inset-bg-rounded" style="background-color: #2d3a45;">
                        <div @click="clickShowMyAccount" class="btn2 btn-lg stake-btn" style="border-radius: 1rem !important;">
                          <span>Confirm</span>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="notification has-text-centered inset-bg-rounded" style="background-color: #2d3a45;">
                        <div @click="clickDisconnect" class="btn2 btn-lg stake-btn" style="border-radius: 1rem !important;">
                          <span>Disconnect</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!--My Wallet Modal SMALL - shown when user clicks their account-->
    <b-modal v-if="this.showMyWalletModal === true && $screen.width <= 480" v-model="showMyWalletModal" :width="640" scroll="keep">

      <div class="form-bg" style="border-radius:20px;">
        <div class="container">
          <div class="columns">
            <div class="column is-12 is-12-mobile blue-bg">
              <div class="form-container form-shadow">
                <div class="form-icon"><img src="../assets/stake-factory-icon.svg" width="70px"></div>
                <div class="container pb-4">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader">
                      My Account:
                    </h3>
                  </div>
                </div>

                <b-message class="is-info" style="background-color: rgb(9 40 52);">
                  <div class="columns is-centered pb-0">
                    <div class="column is-centered msg-center" style="overflow-wrap: anywhere !important;">
                      {{getAccountName()}}
                    </div>
                  </div>
                </b-message>


                <form class="form-horizontal">
                  <div class="columns is-mobile is-centered" style="flex-direction: column;">
                    <div class="column">
                      <div class="notification has-text-centered inset-bg-rounded" style="background-color: #2d3a45;">
                        <div @click="clickShowMyAccount" class="btn2 btn-lg stake-btn" style="border-radius: 1rem !important;">
                          <span>Confirm</span>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="notification has-text-centered inset-bg-rounded" style="background-color: #2d3a45;">
                        <div @click="clickDisconnect" class="btn2 btn-lg stake-btn" style="border-radius: 1rem !important;">
                          <span>Disconnect</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

  </section>


</template>

<script>
//import vue store variables
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "Navbar",
  components: {},
  watch: {
    //lets watch if our xwalletconnected variable changes in the vue store and update our navbar when it does
    xwalletconnected: function (val) {
      this.accountConfirmedNavBar = val;
    },
    //lets watch our transactionPolling variable in the vue store and update our navbar with a clock icon when it does
    transactionPolling: function (newValue) {
      if (newValue === true) {
        this.showTransactionButton = true;
      } else if (newValue === false) {
        this.showTransactionButton = false;
      }

    },
    transactionRejected: function (newValue) {

      if (newValue === true) {
        this.$buefy.toast.open({
          duration: 10000,
          message: `Transaction Rejected By User`,
          position: 'is-top',
          type: 'is-danger'
        });
      }

    },
    userStakeBalance: function (newValue) {

      this.l_userStakeBalance = newValue;

    },
    platformDepositedTokens: function (val) {

      this.l_platformDepositedTokens = val;
    },
    portfolioDepositedTokens: function (val) {

      this.l_portfolioDepositedTokens = val;
    },
    platformYieldTokens: function (val) {

      this.l_platformYieldTokens = val;
    },
    portfolioYieldTokens: function (val) {
      this.l_portfolioYieldTokens = val;
    },
    platformYieldNFTs: function (val) {
      this.l_platformYieldNFTs = val;
    },
    portfolioYieldNFTs : function (val) {
      this.l_portfolioYieldNFTs = val;
    },
    platformYieldLPTokens: function (val) {
      this.l_platformYieldLPTokens = val;
    },
    portfolioYieldLPTokens: function (val) {
      this.l_portfolioYieldLPTokens = val;
    },
    platformDepositedLPTokens: function (val) {
      this.l_platformDepositedLPTokens = val;
    },
    portfolioDepositedLPTokens: function (val) {
      this.l_portfolioDepositedLPTokens = val;
    },
    platformDepositedNFTs: function (val) {
      this.l_platformDepositedNFTs = val;
    },
    portfolioDepositedNFTs: function (val) {
      this.l_portfolioDepositedNFTs = val;
    },
    showNavBar: function (val) {
      this.l_showNavBar = val;
    },

  },
  data() {
    return {
      debug: false, //enables console debug info
      l_showNavBar: true,
      showConnectWalletModal: false, //shows connect wallet modal
      showTransactionButton: false, //shows pending transaciton icon in navbar
      showTransactionModal: false, //shows pending transaction modal
      showMyWalletModal: false,
      showXWalletFinalModal: false, //shows confirm xwallet modal
      accountNameToVerify: "", //Account name to attempt to verify on connect wallet modal
      accountConfirmedNavBar: false, //confirms our navbar is finished loading a new user and can display it's information
      l_platformDepositedTokens: 0,
      l_portfolioDepositedTokens: 0,
      l_platformYieldTokens: 0,
      l_portfolioYieldTokens: 0,
      l_platformDepositedLPTokens: 0,
      l_portfolioDepositedLPTokens: 0,
      l_platformYieldLPTokens: 0,
      l_portfolioYieldLPTokens: 0,
      l_userStakeBalance: 0,
      l_platformDepositedNFTs: 0,
      l_portfolioYieldNFTs: 0,
      l_platformYieldNFTs: 0,
      l_portfolioDepositedNFTs: 0,
    };
  },
  computed: {
    //import state variables from our vue store
    ...mapState("accounts", ["transactionPolling", "showNavBar", "userStakeBalance", "transactionRejected", "xwalletconnected", "platformDepositedTokens", "portfolioDepositedTokens", "platformYieldTokens", "portfolioYieldTokens", "platformYieldLPTokens", "portfolioYieldLPTokens", "portfolioDepositedLPTokens", "platformDepositedLPTokens", "platformDepositedNFTs", "portfolioDepositedNFTs", "platformYieldNFTs", "portfolioYieldNFTs"]),

    displayFixedAccountName() {
      if (this.getXWalletConnected() === false) {
        if (this.accountNameToVerify.length > 12 && this.accountNameToVerify !== 'null' && this.accountNameToVerify !== 'CONNECT WALLET') {
          return this.accountNameToVerify.slice(0, 12) + "....";
        } else {
          return this.accountNameToVerify;
        }
      } else if (this.getXWalletConnected() === true && this.accountNameToVerify !== 'null' && this.accountNameToVerify !== 'CONNECT WALLET') {
        if (this.getAccountName().length > 12) {
          return this.getAccountName().slice(0, 12) + "....";
        } else {
          return this.getAccountName();
        }
      } else {
        return this.accountNameToVerify;
      }
    },

    //returns transaction hash link for pending transaction modal
    exploerLink(){
      return 'https://explorer.chainweb.com/testnet/tx/'+this.getTransactionHash().toString();
    }
    ,
  },
  methods: {
    //map getters and actions for vue store
    ...mapGetters("accounts", ["getAccountExists", "getShowNavBar", "getAccountName", "getAccountKeys", "getAccountPredicate", "getAccountData", "getAccountConfirmed", "getTransactionPolling", "getTransactionConfirmed", "getTransactionFailed", "getTransactionHash", "getTransactionConfirmedResult", "getXWalletConnected", "getRequestPending", "getPlatformDepositedTokens", "getPlatformYieldTokens", "getPortfolioDepositedTokens", "getPortfolioYieldTokens", "getPortfolioYieldLPTokens", "getPlatformYieldLPTokens", "getPlatformDepositedLPTokens", "getPortfolioDepositedLPTokens", "getPortfolioYieldNFTs", "getPortfolioDepositedNFTs", "getPlatformYieldNFTs", "getPlatformDepositedNFTs"]),
    ...mapActions("accounts", ["getAccountVerification"]),

    async goHome(){
      await this.$router.push({path: `/`});
    },

    clickShowNavBar(){

      this.$store.dispatch("accounts/showNavBar", !this.getShowNavBar());

      let payload = {
        name: localStorage.getItem("accountName")
      }

      this.$store.dispatch("accounts/getCurrentBindings", payload);

    },

    clickShowMyAccount(){
      this.showMyWalletModal = !this.showMyWalletModal;
    },

    reduceBalance(balance, prec = 6){
      if (balance) {
        if (balance.int) balance = balance.int;
        if (balance.decimal) balance = balance.decimal;

        if (parseFloat(balance) % 1 === 0) {
          return parseInt(balance);
        }

        return Math.trunc(parseFloat(balance) * Math.pow(10, prec)) / Math.pow(10, prec);
      }

      if (balance === 0) return 0;
    },


    //shows transaction modal when user clicks clock icon
    clickShowTransactionModal(){
      this.showTransactionModal = !this.showTransactionModal;
    },

    //closes transaction modal when user clicks confirm button from transaction modal
    closeTransactionModal(){
      this.showTransactionModal = !this.showTransactionModal;
    },

    //Handles when user clicks 'connect wallet' button from navbar & displays our connect wallet modal window to the user
    async connectWallet() {
      this.showConnectWalletModal = true;
    },

    //Handles disconnecting wallets when the user clicks disconnect
    async clickDisconnect() {
      this.clickShowMyAccount();
      if (this.getXWalletConnected() === false) {
        //Are we not using xwallet? If not, clickCancel
        await this.clickCancel();
      } else if (this.getXWalletConnected() === true) {
        //Are we using xwallet? If so, clickCancel + clickDisconnect
        await this.clickCancel();
        await this.clickDisconnectXwallet();
      }
    },

    //Disconnects Xwallet properly
    async clickDisconnectXwallet() {
      //xwallet injects itself into the window as kadena, lets look for it:
      const kadena = window.kadena;

      //lets send xwallet its kda_disconnect request and disconnect it
      let accountResult = await kadena.request({
        method: "kda_disconnect",
        networkId: "testnet04",
        domain: window.location.hostname
      });

      console.log(accountResult.status);

    },

    //Handles resetting account information when a user disconnects their wallet
    async clickCancel() {
      //Close modal
      this.showConnectWalletModal = false;
      //Clear account input field
      this.accountNameToVerify = "";
      //Reset account information in the vue store
      await this.$store.dispatch("accounts/resetAccountExists");
      //Reset local storage variables
      localStorage.setItem("accountName", "");
      localStorage.setItem("isConnected", false);
      localStorage.setItem("isUsingXwallet", false);

      //Reset local state of navbar display variables
      this.l_platformDepositedTokens = 0;
      this.l_portfolioDepositedTokens = 0;
      this.l_platformYieldTokens = 0;
      this.l_portfolioYieldTokens = 0;
      this.l_portfolioDepositedNFTs = 0;

      //Reset navbar and redirect user to home page
      this.accountConfirmedNavBar = false;
      if (this.$route.name !== "home") {
        await this.$router.push({path: `/`});
      }
    },

    //Handles connecting to xwallet
    async connectxwallet() {
      //Close connect wallet modal
      this.showConnectWalletModal = false;

      const kadena = window.kadena;

      if (window.kadena.isKadena === true) {
        let networkResult = await kadena.request({
          method: "kda_getNetwork"
        });

        if (networkResult.name === "Testnet" && networkResult.networkId === "testnet04") {
          //Show Confirm xwallet final modal
          this.showXWalletFinalModal = true;
          await this.$store.dispatch("accounts/connectXwallet");
        }else {
          alert("Stake Factory is currently testing on Kadena Testnet Chain 1")
        }
      }
      //Connect xwallet via vue store



    },

    async confirmxwallet() {
      //Close connect wallet modal
      this.showConnectWalletModal = false;
      //Show Confirm xwallet final modal
      this.showXWalletFinalModal = false;
      //Connect xwallet via vue store
      await this.$store.dispatch("accounts/connectXwallet");

      this.accountConfirmedNavBar = true;

      const name = localStorage.getItem("accountName");

      const t_name_payload = {
        name: name
      }
      await this.$store.dispatch("accounts/clearCurrentRewardBindings");
      await this.$store.dispatch("accounts/getCurrentBindings", t_name_payload);
    },

    //Attempts to verify kadena account as user types their k:account in the connect wallet input field
    async autoVerify() {
      const payload = this.accountNameToVerify;
      await this.$store.dispatch("accounts/getAccountVerification", payload);
    },

    //Handles what happens when a user clicks 'connect k:account"
    async clickConfirm() {
      //Close the connect wallet modal
      this.showConnectWalletModal = false;

      //Confirm the user wants to connect this account via the vue store
      await this.$store.dispatch("accounts/confirmAccountExists", this.accountNameToVerify);

      //Set up local storage variables to help us detect if the user ever comes back to our game after they leave
      localStorage.setItem("isUsingXwallet", false);
      localStorage.setItem("accountName", this.accountNameToVerify);
      localStorage.setItem("isConnected", true);

      this.accountConfirmedNavBar = true;

      const t_name_payload = {
        name: this.accountNameToVerify
      }
      await this.$store.dispatch("accounts/clearCurrentRewardBindings");
      await this.$store.dispatch("accounts/getCurrentBindings", t_name_payload);
    },


  },
  //Executes 1 frame before our navbar appears
  async created() {


    this.l_showNavBar = this.getShowNavBar();
    this.l_platformDepositedTokens = this.getPlatformDepositedTokens();
    this.l_platformYieldTokens = this.getPlatformYieldTokens();
    this.l_portfolioDepositedTokens = this.getPortfolioDepositedTokens();
    this.l_portfolioYieldTokens = this.getPortfolioYieldTokens();
    this.l_portfolioDepositedLPTokens = this.getPortfolioDepositedLPTokens();
    this.l_platformDepositedLPTokens = this.getPlatformDepositedLPTokens();
    this.l_portfolioYieldLPTokens = this.getPortfolioYieldLPTokens();
    this.l_platformYieldLPTokens = this.getPlatformYieldLPTokens();
    this.l_platformDepositedNFTs = this.getPlatformDepositedNFTs();
    this.l_portfolioYieldNFTs = this.getPortfolioYieldNFTs();
    this.l_platformYieldNFTs = this.getPlatformYieldNFTs();
    this.l_portfolioDepositedNFTs = this.getPortfolioDepositedNFTs()


    //Does our user have any of the localstorage variables we set up previously present in their browser?
    if (localStorage.getItem("accountName")) {

      //Lets get the accountName from local storage
      this.accountNameToVerify = localStorage.getItem("accountName");

      if(this.accountNameToVerify === 'null' || this.accountNameToVerify === ''){
        this.accountNameToVerify = 'CONNECT WALLET';
        await this.clickDisconnect();
      }

      if(this.accountNameToVerify !== 'CONNECT WALLET'){
        //Lets veryify the account from local storage with the vue store and store its data in the store
        await this.$store.dispatch("accounts/getAccountVerification", this.accountNameToVerify);
        await this.$store.dispatch("accounts/confirmAccountExists", this.accountNameToVerify);
      }

      this.l_portfolioDepositedTokens = this.getPortfolioDepositedTokens();
      this.l_portfolioYieldTokens = this.getPortfolioYieldTokens();
      this.l_portfolioDepositedLPTokens = this.getPortfolioDepositedLPTokens();
      this.l_portfolioYieldLPTokens = this.getPortfolioYieldLPTokens();




      if (window.kadena && window.kadena.isKadena === true) {
        //Xwallet is present

        if (localStorage.getItem("isUsingXwallet") === "true") {
          //Our user is using Xwallet

          //Lets update the store that Xwallet is connected
          await this.$store.dispatch("accounts/setXWalletIsConnected");

        }

      }



      //Lets update the navbar
      this.accountConfirmedNavBar = true;


    }

  },

};
</script>

<style scoped>

.gohome {
  cursor: pointer;
}

.gohome:hover {
  text-shadow: 0px 0px 6px rgba(94, 0, 255, 0.77) !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.navbar-font-size {
  font-size: 1.3rem;
}

.btn2{
  color: #87b6e5;
  background-color: rgba(38, 89, 125, 0.6) !important;
  background-repeat: no-repeat;
  font-family: 'Noto', sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}
.btn2:focus,
.btn2:hover{
  color: #fff;
  background: radial-gradient(circle at center,rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(38, 89, 125, 0.6);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
}
@keyframes bubbles{
  100%{
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%,26% -96%,-1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}


.msg-center{
  display: flex;
  justify-content: center;
  text-align: center;
}

.wrapper-wave {
  background-color: transparent;
  width: 100%;
  height: 100%;
}
.wrapper-wave {
  min-height: 100%;
  overflow: hidden;
}
.wrapper-wave,
.wave-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wave-btn {
  height: 40px;
  border-radius: 0 0 10px 10px;
  transition: all 0.8s ease 0s;
  text-decoration: none;
  overflow: hidden;
}
.wave-btn__text {
  z-index: 1;
  font-family: 'Noto Sans', sans-serif;
  color: #fffbd7;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.wave-btn,
.wave-btn__text {
  position: relative;
}
.wave-btn:hover {
  border-radius: 10px;
  transition: all 0.8s ease 0s;
  box-shadow: 0 0 40px rgba(73, 115, 255, 0.6);
}
.wave-btn:hover .wave-btn__waves {
  top: -50px;
}
.wave-btn,
.wave-btn__waves {
  width: 200px;
  margin-bottom: 0rem;
}
.wave-btn__waves {
  position: absolute;
  top: 0;
  left: 0;
  height: 200px;
  background-color: #67b6ef;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
  transition: all 0.8s ease 0s;
}
.wave-btn__waves:after,
.wave-btn__waves:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 250%;
  height: 250%;
  transform: translate3d(-50%, -96%, 0) rotate(0deg) scale(1);
}
.wave-btn__waves:before {
  background-color: #333b4c;
  border-radius: 48%;
  animation: waves 5s infinite linear;
}
.wave-btn__waves:after {
  background-color: #333b4c;
  border-radius: 44%;
  animation: waves 10s infinite linear;
}
@keyframes waves {
  0% {
    transform: translate3d(-50%, -96%, 0) rotate(0deg) scale(1);
  }
  100% {
    transform: translate3d(-50%, -96%, 0) rotate(360deg) scale(1);
  }
}


.form-container{
  background: #ecf0f3;
  font-family: 'Nunito', sans-serif;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
}
.form-container .form-icon{
  color: #ac40ab;
  font-size: 55px;
  text-align: center;
  line-height: 160px;
  width: 100px;
  height:100px;
  margin: 0 auto 15px;
  border-radius: 50px;
  box-shadow: 7px 7px 10px #cbced1, -7px -7px 10px #fff;
}
.form-container .title{
  color: #ac40ab;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 20px;
}
.form-container .form-horizontal .form-group{ margin: 0 0 25px 0; }
.form-container .form-horizontal .form-group label{
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}
.form-container .form-horizontal .form-control{
  color: #333;
  background: #ecf0f3;
  font-size: 15px;
  height: 50px;
  padding: 20px;
  letter-spacing: 1px;
  border: none;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  display: inline-block;
  transition: all 0.3s ease 0s;
}
.form-container .form-horizontal .form-control:focus{
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  outline: none;
}
.form-container .form-horizontal .form-control::placeholder{
  color: #808080;
  font-size: 14px;
}
.form-container .form-horizontal .btn{
  color: #000;
  background-color: #ac40ab;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  padding: 12px 15px 11px;
  border-radius: 20px;
  box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px #fff;
  border: none;
  transition: all 0.5s ease 0s;
}
.form-container .form-horizontal .btn:hover,
.form-container .form-horizontal .btn:focus{
  color: #fff;
  letter-spacing: 3px;
  box-shadow: none;
  outline: none;
}

.inset-bg-rounded{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
  rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.form-shadow{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.navbar-bg{
  /*background: -webkit-linear-gradient(90deg, rgba(35, 30, 40, 0.83), #322432);*/
  background: -webkit-linear-gradient(90deg, rgb(35 30 40), #2c2432);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}


/deep/ .navbar-menu{
  background: transparent;
}

/deep/ .modal-content{
  border-radius: 20px;
}

.accountInputField {
  width: 400px;
}

.accountHeader {
  width: 600px;
}

.navbar {
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-family: 'Angkor', sans-serif;
}

.blue-bg{
  background-color: #314b6a;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #6ea4db;
  overflow: hidden;
}

.navbar-menu{
  background-color: #0d64c6;
}

.navbar-link {
  color: #87ba74 !important;
}

.logotitle {
  color: #fffbd7 !important;
  background-color: #5f96ce;
  border-radius: 0.2rem;
  position: relative;
  left: 10px;
  height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 310px;
  font-size: 1.1rem;
  font-family: Source Code Pro,monospace;
}

.steaktitle {
  color: #749bba !important;
  background-color: transparent !important;
  border-radius: 0.2rem;
  position: relative;
  left: 10px;
  height: 40px;
  padding-top: 11px;
  padding-bottom: 6px;
  width: 310px;
  font-size: 1.1rem;
  font-family: Source Code Pro,monospace;
}

.portfoliotitle{
  color: #fffbd7 !important;
  border-radius: 0.2rem;
  position: relative;
  height: 40px;
  padding-top:10px;
}

.platformtitle{
  color: #fffbd7 !important;
  border-radius: 0.2rem;
  position: relative;
  height: 40px;
  padding-top:10px;
}

.navbar-item{
  font-family: Source Code Pro,monospace;
}

/deep/ .navbar-item.has-dropdown a{
  color: #3abb81;
}

/deep/ .navbar-dropdown a:hover {
  background-color: #813abb !important;
  color: #24de8a !important;
}

/deep/ .navbar-dropdown{
  color: #bce5e2 !important;
  background-color: #293038 !important;
  border-top-color: darkslateblue;
  border-radius: 0.2rem;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background: -webkit-linear-gradient(90deg, rgba(36, 41, 50, 0.53), rgba(35, 30, 40, 0.56));
  background-blend-mode: hard-light;
  box-shadow: rgba(0, 0, 0, 0.30) 0px 1px 2px, rgba(0, 0, 0, 0.20) 0px 1px 13px -1px, rgba(0, 0, 0, 0.30) 0px -1px 0px inset;
}

/deep/ .navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {
  color: #bce5e2 !important;
  background-color: #293038 !important;
  border-radius: 0.2rem;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background: -webkit-linear-gradient(90deg, rgba(36, 41, 50, 0.53), rgba(35, 30, 40, 0.56));
  background-blend-mode: hard-light;
  box-shadow: rgba(0, 0, 0, 0.30) 0px 1px 2px, rgba(0, 0, 0, 0.20) 0px 1px 13px -1px, rgba(0, 0, 0, 0.30) 0px -1px 0px inset;
}

/deep/ .navbar-link:hover {
  color: #bce5e2 !important;
  background-color: #293038 !important;
  border-radius: 0.2rem;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: hard-light;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}

/deep/ .navbar-link {
  color: #749bba !important;
  font-size: 1.3rem;
  background-color: transparent !important;
}

/deep/ .navbar-link:active {
  color: #bce5e2 !important;
  background-color: #293038 !important;
  border-radius: 0.2rem;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: hard-light;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}

.navbar-item:hover {
  color: #bce5e2 !important;
  background-color: #293038;
  border-radius: 0.2rem;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background: -webkit-linear-gradient(90deg, rgba(36, 41, 50, 0.53), rgba(35, 30, 40, 0.56));
  background-blend-mode: hard-light;
  box-shadow: rgba(0, 0, 0, 0.30) 0px 1px 2px, rgba(0, 0, 0, 0.20) 0px 1px 13px -1px, rgba(0, 0, 0, 0.30) 0px -1px 0px inset;
}

.navbar-item:active {
  color: #bce5e2 !important;
  background-color: #293038;
  border-radius: 0.2rem;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: hard-light;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}

.navbar-item:focus {
  color: #bce5e2 !important;
  background-color: #293038;
  border-radius: 0.2rem;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: hard-light;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}

.navbar-item2:hover{
  background-color: transparent !important;
  color: #bce5e2 !important;
  box-shadow: none !important;
}

/*.navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {*/
/*  background-color: #875ed2 !important;*/
/*}*/

.clockloader{
  background: repeating-linear-gradient(to bottom,#fff 10px,#f5f5f5 20px);
  background-blend-mode: multiply;
  height: 40px;
  width: 40px;
  margin: 0 auto 0;
  border: 5px solid #429be3;
  box-shadow: 0 0 5px #429be3 inset;
  border-radius: 50%;
  position: relative;
  top:0px;
  outline: none;
}
.clockloader:before,
.clockloader:after{
  content: '';
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: translate(-50%);
  transform-origin: bottom;
  position: absolute;
  left: 50%;
  bottom: 50%;
}
.clockloader:before{
  background-color: #eb3b5a;
  width: 3px;
  height: 31%;
  animation: rotate 5s linear infinite;
}
.clockloader:after{
  background-color: #2c3e50;
  width: 3px;
  height: 40%;
  animation: rotate 2s linear infinite;
}
@keyframes rotate{
  from{ transform: rotate(0); }
  to{ transform: rotate(360deg); }
}

.form-container{
  background-color: #2e3945;
  border-radius: 1rem;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #6ea4db;
  border-width: 20px;
  padding: 40px;
}
.form-container .form-icon{
  font-size: 8px;
  text-align: center;
  line-height: 160px;
  width: 100px;
  height:100px;
  margin: 0 auto 15px;
  border-radius: 50px;
  box-shadow: 7px 7px 10px #1f272f, -7px -7px 10px #1f272f;
}
.form-container .title{
  color: #ac40ab;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 20px;
}
.form-container .form-horizontal .form-group{ margin: 0 0 25px 0; }
.form-container .form-horizontal .form-group label{
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}
.form-container .form-horizontal .form-control{
  color: #333;
  background: #ecf0f3;
  font-size: 15px;
  height: 50px;
  padding: 20px;
  letter-spacing: 1px;
  border: none;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  display: inline-block;
  transition: all 0.3s ease 0s;
}
.form-container .form-horizontal .form-control:focus{
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  outline: none;
}
.form-container .form-horizontal .form-control::placeholder{
  color: #808080;
  font-size: 14px;
}
/*.form-container .form-horizontal .btn{*/
/*  color: #000;*/
/*  background-color: #ac40ab;*/
/*  font-size: 15px;*/
/*  font-weight: bold;*/
/*  text-transform: uppercase;*/
/*  width: 100%;*/
/*  padding: 12px 15px 11px;*/
/*  border-radius: 20px;*/
/*  box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px #fff;*/
/*  border: none;*/
/*  transition: all 0.5s ease 0s;*/
/*}*/
/*.form-container .form-horizontal .btn:hover,*/
/*.form-container .form-horizontal .btn:focus{*/
/*  color: #fff;*/
/*  letter-spacing: 3px;*/
/*  box-shadow: none;*/
/*  outline: none;*/
/*}*/

.inset-bg-rounded{
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.form-shadow{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}


.screen-container {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  padding: 18px 12px;
  background-color: #293038;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: hard-light;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}

.screen-display {
  display: block;
  width: 90%;
  height: 90%;
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2px;
  background-image: linear-gradient(90deg, transparent 15%,
  rgba(129, 132, 135, 0.05), transparent 85%),
  radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%),
  linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: 0 0 2px 0 #26272c,
  0 0 1px 1px #131723 inset,
  0 0 3px #1b1e23 inset,
  0 0 3px 0.5px #2a2a3b inset,
  0 0 5px #1b1d2d inset,
  9px 0 7px -4px #4f545f inset,
  0 -2px 1px #5c6574 inset;
}

.screen-display {
  padding: 7px 12px;
}

.screen-display p {
  font-family: "Source Code Pro", monospace;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
  color: #1f232b;
  text-shadow: 0 0 0.5px #222328,
  0.2px 0.2px 0.5px #45464b,
  -0.2px 0.2px 0.5px #4c4d52,
  0.2px -0.2px 0.5px #45464b,
  0 0 0.75px #4c4e52,
  0 0 1.25px #1c2028;
}

.screen-display p:last-child {
  display: grid;
  grid-template-columns: 80px 35px 85px 51px;
}


.screen-container-port {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  padding: 18px 12px;
  background-color: #293038;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: hard-light;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}

.screen-display-port {
  display: block;
  width: 90%;
  height: 90%;
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2px;
  background-image: linear-gradient(90deg, transparent 15%,
  rgba(129, 132, 135, 0.05), transparent 85%),
  radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%),
  linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: 0 0 2px 0 #26272c,
  0 0 1px 1px #131723 inset,
  0 0 3px #1b1e23 inset,
  0 0 3px 0.5px #2a2a3b inset,
  0 0 5px #1b1d2d inset,
  9px 0 7px -4px #4f545f inset,
  0 -2px 1px #5c6574 inset;
}

.screen-display-port {
  padding: 7px 12px;
}

.screen-display-port p {
  font-family: "Source Code Pro", monospace;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
  color: #1f232b;
  text-shadow: 0 0 0.5px #222328,
  0.2px 0.2px 0.5px #45464b,
  -0.2px 0.2px 0.5px #4c4d52,
  0.2px -0.2px 0.5px #45464b,
  0 0 0.75px #4c4e52,
  0 0 1.25px #1c2028;
}

.screen-display-port p:last-child {
  display: grid;
  text-align: right;
  grid-template-columns: 220px 0px 10px 16px;
}

/deep/ .modal-content, .modal-card {
  overflow: visible !important;
}

@media screen and (max-width: 480px) {

  /deep/ .modal {
    overflow: inherit !important;
  }

  /deep/ .modal-content, .modal-card {
    margin: 0 20px;
    max-height: calc(100vh) !important;
    overflow: hidden !important;
    position: relative;
    width: 100%;
  }
}

</style>
<style lang="scss" scoped>
$navbar-dropdown-background-color: #444;
</style>
