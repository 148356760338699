<template>
  <div v-bind:class="[flipped ? 'flip-container flipped' : 'flip-container']"
       class="column pool-main2 is-centered mb-4 mt-5">
    <div class="columns is-centered pools-card" :style="$screen.width < 576 ? 'margin-left: 0px !important; width: 100%':''">
      <div class="column is-1"></div>
      <div class="column pool-item is-centered is-align-self-center" :style="$screen.width < 576 ? 'padding-bottom: 0 !important; margin-bottom !important;' : ''">
        <accordion>
          <accordion-item :class="$screen.width < 770 ? 'small-pool' : ''">
            <!-- This slot will handle the title/header of the accordion and is the part you click on -->
            <template slot="accordion-trigger">
              <div class="columns" :class="$screen.width > 770 ? 'margin-over-large' : ''">
                <div class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Liquidity Pool: '+pool_name+' | Swap '+coinDisplayName(pair_tokenA)+' <=> '+coinDisplayName(pair_tokenB)">
                    <div class="pool-name">{{ pool_name }}</div>
                    <div class="provider">{{ coinDisplayName(pair_tokenA) }} &lt;=&gt; {{ coinDisplayName(pair_tokenB) }}</div>
                  </b-tooltip>
                </div>
                <div class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Liquidity in pool: 0'">
                    <div class="col-title">LIQUIDITY</div>
                    <div>0</div>
                  </b-tooltip>
                </div>
                <div class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Pool swap fee: '+fees">
                    <div class="col-title">FEE</div>
                    <div>{{ fees }}%</div>
                  </b-tooltip>
                </div>
              </div>
            </template>
            <!-- pool accordion content -->
            <template slot="accordion-content">
              <div class="columns mt-3 mb-3"
                   style="position: relative; top: 10px; background-color: rgba(38,43,56,0.29); border-radius: 1rem;">
                <div class="column is-12 is-align-self-center">

                  <!-- if connected pool buttons-->
                  <section class="columns is-justify-content-center is-vcentered is-flex-direction-column">

                    <div class="my-pools-shadow column is-12 is-12-mobile" :class="$screen.width > 1024 ? '':''" style="z-index: 2; padding: 0px !important; border-radius: 3rem !important;"
                         :style="$screen.width < 700 ? '' : 'min-width: 480px;'">
                      <div class="card pools-bg leatherbg cardborder pb-4 " style="z-index: 2">


                        <div class="poolselect-bg mb-0 tradesection container">
                          <div class="columns is-centered is-flex" style="width: 100%; align-items: center;">
                            <div class="column is-narrow is-narrow-mobile is-flex is-justify-content-center">
                              <a @click="clickShowSwap()" v-if="showSlippage === true" class="btm">
                                <b-icon
                                    pack="fas"
                                    icon="chevron-circle-left"
                                    size="is-small" style="position: relative; top:10px; left:10px; ">
                                </b-icon>
                              </a>
                            </div>
                            <div class="column is-8">
                              <b-tabs
                                  v-model="activeTab"
                                  headerClass="headertest"
                                  class="font1"
                                  position="is-centered"
                                  type="is-toggle"
                                  expanded
                              >
                                <b-tab-item value="0" label="Swap"></b-tab-item>
                              </b-tabs>
                              <!--                              <div v-if="activeTab === '0'">Swap</div>-->
                              <!--                              <div v-if="activeTab === '1'">Settings</div>-->
                            </div>
                            <div class="column is-narrow is-narrow-mobile is-flex is-justify-content-center">
                              <b-tooltip type="is-dark" :label="'Swap Settings'">
                                <a v-if="showSlippage === false" class="btm">
                                  <b-icon
                                      pack="fas"
                                      icon="sliders"
                                      size="is-small" style="position: relative; top:10px; left:10px; ">
                                  </b-icon>
                                </a>
                              </b-tooltip>
                            </div>
                            <div class="column is-narrow is-narrow-mobile is-flex is-justify-content-center">
                              <b-tooltip type="is-dark" :label="'Pool Page'">
                                <a class="btm">
                                  <b-icon
                                      pack="fas"
                                      icon="window-maximize"
                                      size="is-small" style="position: relative; top:10px; left:10px; ">
                                  </b-icon>
                                </a>
                              </b-tooltip>
                            </div>
                          </div>

                        </div>


                        <!--     TRADE SECTION -->
                        <div  class="column is-8 is-12-mobile is-offset-2 is-offset-0-mobile">
                          <!-- TOP SWAP SECTION -->
                          <div class="column pool-main is-centered mb-2">
                            <div class="columns is-centered pools-card" :style="$screen.width < 576 ? 'width: 100% !important; margin: 0 !important':''">
                              <div class="column swap-section-top is-centered is-align-self-center">
                                <div class="columns m-0 p-0 is-flex-direction-column" style="z-index: 6">
                                  <div class="column m-0 p-0">
                                    <p class="control" style="z-index: 3">
                                      <v-super-select ref="topswap" placeholder="Select"
                                                      v-model="currentSelectionTop" inputHeight="40px"
                                                      inputWidth="120px" :items="topSwapPairsList"
                                                      mobileHeaderBackgroundColor="#2c2432"
                                                      mobileHeaderBorderBottom="#2c2432">
                                        <option value="1">{{coinDisplayName(pair_tokenA)}}</option>
                                        <option value="2">{{coinDisplayName(pair_tokenB)}}</option>
                                      </v-super-select>
                                    </p>
                                  </div>
                                  <div class="column m-0 p-0">
                                    <div class="available">0</div>
                                  </div>
                                </div>

                                <div v-if="$screen.width > 576" class="field-body">
                                  <div class="field has-addons">
                                    <p class="control margin-large-swap-input"
                                       :class="$screen.width > 764 ? 'margin-large-swap-input' : ''"
                                       style="width: 100%;">
                                      <b-numberinput v-model="topSwapAmount" :exponential=".5"
                                                     :min="0" :step="1"
                                                     type="is-buttonblue" expanded controls-position="compact"
                                                     controls-alignment="right"></b-numberinput>
                                    </p>
                                    <p class="control">
                                      <b-button type="is-buttonblue" label="MAX"/>
                                    </p>
                                  </div>
                                </div>

                                <div v-if="$screen.width < 576" class="field-body">
                                  <div class="field has-addons">
                                    <p class="control margin-large-swap-input"
                                       :class="$screen.width > 764 ? 'margin-large-swap-input' : ''"
                                       style="width: 100%;">
                                      <b-input v-model="topSwapAmount" :min="0"
                                               type="number" custom-class="is-buttonblue-small" expanded
                                               controls-position="compact" controls-alignment="right"></b-input>
                                    </p>
                                    <p class="control">
                                      <b-button type="is-buttonblue" label="MAX"/>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- MIDDLE CIRCLE PLUS FLIPPER -->
                          <div class="column pool-main is-centered mb-1 mt-1">
                            <div class="columns is-centered pools-card" :style="$screen.width < 576 ? 'width: 100% !important; margin: 0 !important':''">
                              <div class="column"></div>
                              <div @click="clickFlip()"
                                   class="column is-2 pool-section-round is-centered is-align-self-center btn-flip"
                                   :class="$screen.width < 770 ? 'centered-swap-icon':''" style="width: 50px">
                                <b-icon
                                    pack="fas"
                                    icon="arrow-circle-down"
                                    size="is-small" style="position: relative; top:2px; ">
                                </b-icon>
                              </div>
                              <div class="column"></div>
                            </div>
                          </div>

                          <!-- BOTTOM SWAP INPUT -->
                          <div class="column pool-main is-centered mb-2">
                            <div class="columns is-centered pools-card" :style="$screen.width < 576 ? 'width: 100% !important; margin: 0 !important':''">
                              <div class="column swap-section-top is-centered is-align-self-center">
                                <div class="columns m-0 p-0 is-flex-direction-column">
                                  <div class="column m-0 p-0">
                                    <p class="control" style="z-index: 3">
                                      <v-super-select ref="bottomswap" placeholder="Select" :items="bottomSwapPairsList"
                                                      v-model="currentSelectionBottom" inputHeight="40px"
                                                      inputWidth="120px">
                                        <option value="1">{{coinDisplayName(pair_tokenB)}}</option>
                                        <option value="2">{{coinDisplayName(pair_tokenA)}}</option>
                                      </v-super-select>
                                    </p>
                                  </div>
                                  <div class="column m-0 p-0">
                                    <div class="available">0</div>
                                  </div>
                                </div>

                                <div v-if="$screen.width > 576" class="field-body">
                                  <div class="field has-addons">
                                    <p class="control margin-large-swap-input"
                                       :class="$screen.width > 764 ? 'margin-large-swap-input' : ''"
                                       style="width: 100%;">
                                      <b-numberinput v-model="bottomSwapAmount"
                                                     :exponential=".5" :min="0" :step="1" type="is-buttonblue" expanded
                                                     controls-position="compact"
                                                     controls-alignment="right"></b-numberinput>
                                    </p>
                                    <p class="control">
                                      <b-button type="is-buttonblue" label="MAX"/>
                                    </p>
                                  </div>
                                </div>

                                <div v-if="$screen.width < 576" class="field-body">
                                  <div class="field has-addons">
                                    <p class="control margin-large-swap-input"
                                       :class="$screen.width > 764 ? 'margin-large-swap-input' : ''"
                                       style="width: 100%;">
                                      <b-input v-model="bottomSwapAmount" :min="0"
                                               type="number" custom-class="is-buttonblue-small" expanded
                                               controls-position="compact" controls-alignment="right"></b-input>
                                    </p>
                                    <p class="control">
                                      <b-button type="is-buttonblue" label="MAX"/>
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>


                          <!-- SWAP BUTTON -->
                          <div class="column pool-main is-centered mb-0 mt-0">
                            <div class="columns is-centered pools-card">
                              <div
                                   class="column pool-section-title is-centered is-align-self-center is-buttonblueswap btn-swap">
                                <span class="font5">EXAMPLE</span>
                              </div>
                            </div>
                          </div>


                        </div>

                      </div>
                    </div>


                  </section>


                </div>
              </div>
            </template>
          </accordion-item>
        </accordion>


      </div>
      <div class="column is-1"></div>
    </div>

  </div>
</template>

<script>
//import vue store variables to this page
import {mapGetters, mapState} from "vuex";
import Accordion from "./accordion.vue";
import AccordionItem from "./accordion-item";
import VSuperSelect from "v-super-select";


export default {
  name: "LiquidityPoolCard",
  components: {
    Accordion,
    AccordionItem,
    VSuperSelect,
  },
  props: {
    accountApproved: {
      type: Boolean,
      default: false,
    },
    pool_name: {
      type: String,
      default: "0",
    },
    pool_pair: {
      type: String,
      default: "0",
    },
    pool_id: {
      type: String,
      default: "0"
    },
    user_account: {
      type: String,
      default: "0",
    },
    pair_tokenA: {
      type: String,
      default: "0",
    },
    pair_tokenB: {
      type: String,
      default: "0",
    },
    pool_apr: {
      type: [String, Number],
      default: "0",
    },
    total_liquidity: {
      type: [String, Number],
      default: "0",
    },
    fees: {
      type: [String, Number],
      default: "0",
    },
    volume: {
      type: [String, Number],
      default: "0",
    },
    pair_tokenB_name: {
      type: String,
      default: "0",
    },
    pair_tokenB_namespace: {
      type: String,
      default: "0",
    },
    pair_tokenA_name: {
      type: String,
      default: "0",
    },
    pair_tokenA_namespace: {
      type: String,
      default: "0",
    },
    tokenA_ticker: {
      type: String,
      default: "0",
    },
    tokenB_ticker: {
      type: String,
      default: "0",
    },

  },
  data: function () {
    return {
      debug: false,
      flipped: false,
      showStakeControls: false,
      stakeAmount: 0.0,
      activeTab: "0",
      flippingSwapSections: false,
      showLoadingModal: true,
      currentSelectionTop: null,
      currentSelectionBottom: null,
      currentTemporarySelectionTop: null,
      currentTemporarySelectionBottom: null,
      topSwapAmount: 0.0,
      bottomSwapAmount: 0.0,
      currentTokenTop: "KDA",
      user_name: null,
      swapPairData: [],
      allPairsList: [
        {
          text: this.tokenA_ticker,
          value: this.pair_tokenA,
        },
        {
          text: this.tokenB_ticker,
          value: this.pair_tokenB,
        }
      ],
      bottomSwapPairsList: [
        {
          text: this.tokenA_ticker,
          value: this.pair_tokenA,
        },
        {
          text: this.tokenB_ticker,
          value: this.pair_tokenB,
        }
      ],
      topSwapPairsList: [
        {
          text: this.tokenA_ticker,
          value: this.pair_tokenA,
        },
        {
          text: this.tokenB_ticker,
          value: this.pair_tokenB,
        }
      ],
      topTokenPrecision: 12,
      bottomTokenPrecision: 12,
      currentSwapPairData: [],
      insufficientFunds: false,
      insufficientLiquidity: false,
      topSwapBalance: "0",
      bottomSwapBalance: "0",
      pairLPTokenBalance: 0,
      removeLiquiditySliderAmount: 0,
      addingLiquidity: false,
      removingLiquidity: false,
      swapping:false,
      removingLiquidityToastAmount: 0,
      addingLiquidityToastAmount: 0,
      isMakingTransaction: false,
      isMakingLPPoolCardTransaction: false,
      transactionDeadlineAmount: 10,
      slippageAmount: 5,
      showSlippage: false,
    };
  },
  watch: {

  },
  computed: {
    ...mapState("accounts", ["transactionPolling", "transactionRejected"]),

    displayFixedTopBalance() {
      if (this.topSwapBalance.length > 12) {
        return this.topSwapBalance.slice(0, 12) + "...";
      } else {
        return this.topSwapBalance;
      }
    },

    displayFixedBottomBalance() {
      if (this.bottomSwapBalance.length > 12) {
        return this.bottomSwapBalance.slice(0, 12) + "...";
      } else {
        return this.bottomSwapBalance;
      }
    },

    displayFixedLPBalance() {
      if (this.pairLPTokenBalance.length > 12) {
        return this.pairLPTokenBalance.slice(0, 12) + "...";
      } else {
        return this.pairLPTokenBalance;
      }
    },

  },
  methods: {
    ...mapGetters("accounts", ["getAccountExists", "getAccountConfirmed", "getTransactionFailed", "getTransactionPolling"]),

    coinDisplayName(coin){
      let name = coin;
      if(coin === 'coin'){
        name = 'KDA';
      }else if(coin === 'free.stake-token'){
        name = 'STEAK';
      }
      return name;
    },

    //Gets a coin name from namespace + module name
    coinName(namespace, name) {
      let newnamespace = namespace + ".";
      let newname = name;
      if (namespace === null || namespace === "null") {
        newnamespace = "";
      }
      if (name === null || name === "null") {
        newname = "";
      }
      return newnamespace + newname;
    },

    //Reducer helper function
    reduceBalance(balance, prec = 6) {
      if (balance) {
        if (balance.int) balance = balance.int;
        if (balance.decimal) balance = balance.decimal;

        if (parseFloat(balance) % 1 === 0) {
          return parseInt(balance);
        }

        return Math.trunc(parseFloat(balance) * Math.pow(10, prec)) / Math.pow(10, prec);
      }

      if (balance === 0) return 0;
    },

    //For testing
    number_test(n) {
      const result = (n - Math.floor(n)) !== 0;

      if (result) {
        if (this.debug) {
          console.log("Contains Decimal Place");
        }
        return true;
      } else {
        if (this.debug) {
          console.log("No decimal place");
        }
        return false;
      }

    },

  },
  async created() {

  }
};
</script>

<style scoped>


.leatherbg {
  background: radial-gradient(#00000078 0%, transparent 10%) 0 0, radial-gradient(#0000005c 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 250, 250, 0) 15%, transparent 20%) 8px 9px;
  background-color: #2c243275 !important;
  background-size: 16px 16px;
  border: 2px solid #11426573;
  background-blend-mode: darken;
}

/deep/ .b-tabs .tab-content {
  display:none !important;
}


/deep/ .b-slider .b-slider-tick {
  position: absolute;
  width: 3px;
  transform: translate(-50%, -50%);
  top: 50%;
  background: #3ffdf4 !important;
  border-radius: 4px;
}


/deep/ .super-select-dropdown {
  background-color: #333b4c !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #67b6ef !important;
  width: 455px !important;
}


@media screen and (max-width: 769px), print {
  /deep/ .super-select-dropdown {
    background-color: #333b4c !important;
    border: 1px solid !important;
    border-color: #2f597d !important;
    color: #67b6ef !important;
    width: 380px !important;
  }

  /deep/ .accordion__item {
    padding-left:0px !important;
    padding-right:0px !important;
  }
}

.swap-section-top {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #46a9e7;
  font-family: Source Code Pro, monospace !important;
  display: flex;
  background: #333b4c radial-gradient(circle farthest-corner at 50.3% 44.5%,
  rgba(107, 114, 129, 0.5),
  rgba(107, 114, 129, 0.99) 100.2%);
  background-blend-mode: hard-light;
}

.tradesection {
  background-color: #314b6a;
  color: rgba(52, 132, 179, 0.84);
  background: -webkit-linear-gradient(90deg, rgb(35 44 57), #333b4c);
  border: none;
  height: 70px;
  font-size: 20px;
  font-family: Source Code Pro, monospace !important;
  display: flex;
  justify-content: center;
  align-items: center;
}


.header-bg3 {
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
  height: 1px;
  padding-top: 0px;
}


/deep/.b-slider .b-slider-thumb-wrapper .b-slider-thumb {
  box-shadow: none;
  border: 1px solid #6affc8;
  border-radius: 4px;
  background: #233b58;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}

/deep/ .b-slider .b-slider-track {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  background: #3e8ed0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

/deep/ .b-slider.is-primary .b-slider-fill {
  background: #67efc3b0 !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

/deep/ .secondPairList .super-select-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
  justify-content: flex-end !important;
  display: flex;
  justify-self: flex-end;
  position: relative;
  left: 17px;
  text-align: end;
  margin-top: -3px !important;
  text-shadow: 0 1px 0 #578d84, 0 2px 0 #578d84;
  font-family: Source Code Pro,monospace !important;
  margin-left: 10px;
}

/deep/ .firstPairList .super-select-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
  left: 14px;
  margin-top: -3px !important;
  margin-left: -10px;
  text-align: left;
  text-shadow: 0 1px 0 #578d84, 0 2px 0 #578d84;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .super-select-input input {
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
  left: 14px;
  margin-top: -3px !important;
  margin-left: -10px;
  text-align: left;
  text-shadow: 0 1px 0 #578d84, 0 2px 0 #578d84;
  font-family: Source Code Pro,monospace !important;
}


/deep/ .field.has-addons .control:last-child .button, .field.has-addons .control:last-child .input, .field.has-addons .control:last-child .select select {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 10px !important;
  font-weight: 700 !important;
}


::v-deep .field.has-addons .control:last-child .button, .field.has-addons .control:last-child .input, .field.has-addons .control:last-child .select select {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 10px !important;
  font-weight: 700 !important;
}

.available{
  position: relative;
  top: -17px;
  left: 12px;
  margin-bottom: -20px;
  font-size: 12px;
  justify-content: flex-start;
  justify-self: flex-start;
  text-align: left;
  width: 176px;
  border-radius: 0.5rem;
  padding-left: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  color: #6c8fba82;
  max-width: 70px;
}


.margin-large-swap-input{
  margin-left:18px;
  margin-top: 0px;
}

.btn-flip{
  color: #fff;
  background: #333b4c;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: soft-light;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
  border: 1px solid rgba(3, 150, 255, 0.38);
  cursor: pointer;

}
.btn-flip:focus{ color: #fff; }
.btn-flip:hover{
  color: #5ad1c7;
  background: #333b4c;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: soft-light;
  box-shadow: 0 0 10px #5B86E5;
  border: 1px solid rgba(3, 150, 255, 0.38);
}
.btn-flip .icon{
  transition: all 0.3s ease 0s;
}
.btn-flip:hover .icon { transform: rotate(180deg); }
.btn-flip:before{
  content: '';
  background: linear-gradient(to right, transparent, rgba(91, 134, 229, 0.59));
  height: 100%;
  width: 20%;
  position: absolute;
  right: -20px;
  top: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.btn-flip:hover:before{ width: 100%; }



.btn-lowfunds{
  color: #fff;
  background: rgba(35, 183, 191, 0.29);
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
  border: 1px solid rgba(255, 3, 116, 0.38);
  cursor: not-allowed;
  border-radius: 2rem !important;
  padding: 0px;
  display: flex;
  height: 40px;
  justify-content: center;
  flex-direction: column;
  font-family: 'Lexend Deca', sans-serif;
}
.btn-lowfunds:focus{ color: #fff; }
.btn-lowfunds:hover{
  color: #fdb1b1;
  background: rgba(76, 30, 35, 0.63);
  box-shadow: 0 0 5px #e55b5b;
  border: 1px solid rgba(255, 3, 32, 0.38);
}
.btn-lowfunds i{
  transition: all 1s ease 0s;
}
.btn-lowfunds:before{
  content: '';
  background: linear-gradient(to right, transparent, rgba(229, 91, 107, 0.59));
  height: 100%;
  width: 20%;
  position: absolute;
  right: -80px;
  top: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.btn-lowfunds:hover:before{ width: 100%; }


.btn-swap{
  color: #fff;
  background: #333b4c;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: soft-light;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
  border: 1px solid rgba(3, 150, 255, 0.38);
  cursor: pointer;
  border-radius: 2rem !important;
  padding: 0px;
  display: flex;
  height: 40px;
  justify-content: center;
  flex-direction: column;
  font-family: 'Lexend Deca', sans-serif;
}
.btn-swap:focus{ color: #fff; }
.btn-swap:hover{
  color: #5ad1c7;
  background: #333b4c;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: soft-light;
  box-shadow: 0 0 5px #5B86E5;
  border: 1px solid rgba(3, 150, 255, 0.38);
}
.btn-swap i{
  transition: all 1s ease 0s;
}
.btn-swap:before{
  content: '';
  background: linear-gradient(to right, transparent, rgba(91, 134, 229, 0.59));
  height: 100%;
  width: 20%;
  position: absolute;
  right: -140px;
  top: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.btn-swap:hover:before{ width: 100%; }



.btn-slip{
  color: #fff;
  background: #333b4c;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: soft-light;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  border-radius: 2rem !important;
  padding: 0px;
  display: flex;
  height: 40px;
  justify-content: center;
  flex-direction: column;
}
.btn-slip:focus{ color: #fff; }
.btn-slip:hover{
  color: #5ad1c7;
  background: #333b4c;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: soft-light;
  box-shadow: 0 0 5px #5B86E5;
  border: 1px solid rgba(3, 150, 255, 0.38);
}
.btn-slip i{
  transition: all 1s ease 0s;
}
.btn-slip:before{
  content: '';
  background: linear-gradient(to right, transparent, rgba(91, 134, 229, 0.59));
  height: 100%;
  width: 20%;
  position: absolute;
  right: -80px;
  top: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.btn-slip:hover:before{ width: 100%; }


.centered-swap-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-46%, -50%);
}

/deep/ .super-select-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
  margin-top: -11px;
  font-size: 20px !important;
  text-shadow: 0 1px 0 #2b4d58, 0 2px 0 #355660 !important;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .secondPairList .super-select-dropdown {
  display: none;
  z-index: 0;
  position: absolute;
  right: -8px !important;
}

::v-deep .super-select-dropdown {
  background-color: #333b4c !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #67b6ef !important;
}



.super-select-dropdown {
  background-color: #333b4c !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #67b6ef !important;
}

/deep/ .super-select-item {
  background-color: #333b4c !important;
  border: none !important;
  outline: none !important;
  color: #67b6ef !important;
}

::v-deep .super-select-item {
  background-color: #333b4c !important;
  border: none !important;
  outline: none !important;
  color: #67b6ef !important;
}

.super-select-item {
  background-color: #333b4c !important;
  border: none !important;
  outline: none !important;
  color: #67b6ef !important;
}

/deep/ .super-select-item:hover {
  background-color: #215581 !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #5ad1c7 !important;
}

::v-deep .super-select-item:hover {
  background-color: #215581 !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #5ad1c7 !important;
}

/deep/ .super-select-item:focus {
  background-color: #215581 !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #5ad1c7 !important;
}

::v-deep .super-select-item:focus {
  background-color: #215581 !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #5ad1c7 !important;
}

/deep/ .super-select-item.active, .super-select-item:active, .super-select-item:hover {
  background-color: #215581 !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #5ad1c7 !important;
}

::v-deep .super-select-item.active, .super-select-item:active, .super-select-item:hover {
  background-color: #215581 !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #5ad1c7 !important;
}

/deep/ .super-select-item:active {
  background-color: #215581 !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #5ad1c7 !important;
}

::v-deep .super-select-item:active {
  background-color: #215581 !important;
  border: 1px solid !important;
  border-color: #2f597d !important;
  color: #5ad1c7;
}

/deep/ .super-select-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
  margin-top: -12px;
  font-size: 20px !important;
  text-shadow: 0 1px 0 #2b4d58, 0 2px 0 #355660 !important;
}

/deep/ .super-select-input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;

}

.super-select-input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
}

::v-deep .super-select-input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
}

/deep/ .super-select-input input, .super-select-input select {
  background-color: #333b4c !important;
  border: none !important;
  color: #67b6ef !important;
}

.super-select-input input, .super-select-input select {
  background-color: #333b4c !important;
  border: none !important;
  color: #67b6ef !important;
}

::v-deep .super-select-input input, .super-select-input select {
  background-color: #333b4c !important;
  border: none !important;
  color: #67b6ef !important;
}

/deep/ .super-select-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
}

.super-select-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
}

::v-deep .super-select-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
}

.super-select-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #67b6ef !important;
}

/deep/ .label {
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  position: absolute;
}

::v-deep .label {
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  position: absolute;
}


/deep/ .b-numberinput input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border-color: #2f597d !important;
  box-shadow: none !important;
  color: #5ad1c7 !important;
  border: none !important;
}

/deep/ .b-input input {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border-color: #2f597d !important;
  box-shadow: none !important;
  color: #5ad1c7 !important;
  border: none !important;
}

/deep/ .is-buttonblue-small {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border-color: #2f597d !important;
  box-shadow: none !important;
  color: #5ad1c7 !important;
  border: none !important;
  margin-left: 20px;
}

/deep/ .is-buttonblue {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border-color: #2f597d !important;
  box-shadow: none !important;
  color: #5ad1c7 !important;
  border: none !important;
}


/deep/ .button.is-buttonblue {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  color: #4d84ab !important;
  border: none !important;
  box-shadow: none !important;
}

/deep/ .button.is-buttonblue:hover {
  /*background-color: rgba(56, 82, 135, 0.04) !important;*/
  background-color: transparent !important;
  color: #5ad1c7 !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
  border-radius: 2rem;
}



/deep/ .super-select-input input:hover {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  border: none !important;
  color: #5ad1c7 !important;
}

/deep/ .button.is-buttonblue:focus {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  color: #4d84ab !important;
  border: none !important;
  box-shadow: none !important;

}

/deep/ .button.is-buttonblue:active {
  /*background-color: #333b4c !important;*/
  background-color: transparent !important;
  color: #5ad1c7 !important;
  border: none !important;
  box-shadow: none !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}


/deep/ .button.is-buttonlowfunds {
  background-color: #333b4c !important;
  border-color: #7d2f2f !important;
  border:none !important;
  color: #67b6ef !important;
}


/deep/ .button.is-buttonblueswap {
  background-color: #333b4c !important;
  border-color: #2f597d !important;
  border:none !important;
  color: #67b6ef !important;
}

/deep/ .button.is-buttonblueswap:hover {
  background-color: #2f597d !important;
  border-color: #2f597d !important;
  border:none !important;
  color: #67b6ef !important;
}

/deep/ .button.is-buttonblueswap:focus {
  background-color: #333b4c !important;
  border-color: #2f597d !important;
  border:none !important;
  color: #67b6ef !important;
}

/deep/ .button.is-buttonblueswap:active {
  background-color: #333b4c !important;
  border-color: #2f597d !important;
  border:none !important;
  color: #67b6ef !important;
}

/deep/ .super-select-container.super-select-dropdown-visible .super-select-input {
  -webkit-box-shadow:none !important;
  box-shadow: none !important;
  border-radius:0 !important;
  border: none !important;
}

::v-deep .super-select-container.super-select-dropdown-visible .super-select-input {
  -webkit-box-shadow:none !important;
  box-shadow: none !important;
  border-radius:0 !important;
  border: none !important;
}

.super-select-container.super-select-dropdown-visible .super-select-input {
  -webkit-box-shadow:none !important;
  box-shadow: none !important;
  border-radius:0 !important;
  border: none !important;
}

/deep/ .dropdown-content {
  background: -webkit-linear-gradient(90deg, rgba(55, 45, 69, 0.85), rgba(55, 45, 69, 0.85)) !important;
  border-radius: 4px !important;
  padding-bottom: 0.1rem !important;
  padding-top: 0.5rem !important;
  width: 435px !important;
  color: #5ad1c7 !important;
}

::v-deep .dropdown-content {
  background: -webkit-linear-gradient(90deg, rgba(55, 45, 69, 0.85), rgba(55, 45, 69, 0.85)) !important;
  border-radius: 4px !important;
  padding-bottom: 0.1rem !important;
  padding-top: 0.5rem !important;
  width: 435px !important;
  color: #5ad1c7 !important;
}

.dropdown-content {
  background: -webkit-linear-gradient(90deg, rgba(55, 45, 69, 0.85), rgba(55, 45, 69, 0.85)) !important;
  border-radius: 4px !important;
  padding-bottom: 0.1rem !important;
  padding-top: 0.5rem !important;
  width: 435px !important;
  color: #5ad1c7 !important;
}

::v-deep .super-select-val {
  color: #5ad1c7 !important;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: .1px;
  margin-right: 6px;
}

.title-back {
  color: #ffffff;
  font-family: Source Code Pro,monospace !important;
  border-radius: 1rem;
  background: -webkit-linear-gradient(90deg, rgba(49, 56, 64, 0.89), rgba(55, 45, 69, 0.85));
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.dropdown-item, .dropdown .dropdown-menu .has-link a:hover {
  color: #5ad1c7;
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  position: relative;
  background: -webkit-linear-gradient(90deg, rgb(51 59 76), rgba(53, 49, 58,0.84));
}

a.dropdown-item:hover, .dropdown .dropdown-menu .has-link a:hover, button.dropdown-item:hover {
  background-color: #67b6ef;
  color: #5ad1c7;
  border: 1px solid #5ad1c7;
}

a.dropdown-item.is-active, .dropdown .dropdown-menu .has-link a.is-active, button.dropdown-item.is-active {
  background-color: #67b6ef;
  color: #5ad1c7;
  border: 1px solid #5ad1c7;
}

a.dropdown-item.is-active, .dropdown .dropdown-menu .has-link a.is-active, button.dropdown-item.is-active {
  background-color: #67b6ef;
  color: #5ad1c7;
}

/* card background */
.home-card-bg {
  background: #ebeae5;
}

/deep/ .field.has-addons .control:last-child .control .button, .field.has-addons .control:last-child .control .input, .field.has-addons .control:last-child .control .select select {
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-top-left-radius: 20px !important;
  border: none;
  padding:1rem;
}


.pool-section-title {
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #46a9e7;
  font-family: Source Code Pro,monospace !important;
  background: #333b4c;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: soft-light;
}

.slippage-section-title {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  color: #46a9e7;
  font-family: Source Code Pro,monospace !important;
  background: #333b4c;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: soft-light;
}


.insufficient-funds-section{
  background-color: #333b4c;
  border: 1px solid rgba(255, 3, 3, 0.38);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #e74646;
  font-family: Source Code Pro,monospace !important;
}

.swap-section-top {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #46a9e7;
  font-family: Source Code Pro,monospace !important;
  display: flex;
  background: #333b4c radial-gradient(circle farthest-corner at 50.3% 44.5%,
  rgba(107, 114, 129, 0.5),
  rgba(107, 114, 129, 0.99) 100.2%);
  background-blend-mode: hard-light;
}


.pool-section-round {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 50%;
  color: #46a9e7;
  font-family: Source Code Pro,monospace !important;
}


/* border for item cards */
.cardborder {
  border: 2px outset rgba(51, 70, 80, 0.42);
  border-radius: 1rem;
  outline: none !important;
  color: #000000;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 1px 1px, rgba(0, 0, 0, 0.30) 1px 1px 0px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}



@media (max-width: 420px) {
  .super-select-container.super-select-dropdown-visible {
    top: 20px;
    width: 100vw;
    z-index: 6 !important;
  }
  /deep/ .super-select-dropdown, .super-select-dropdown.above {
    width: 99vw !important;
    z-index: 6 !important;
  }

  .super-select-mobile-header {
    background-color: #3abb81;
  }

  /deep/ .super-select-input input {
    font-size: 11px !important;
  }

  /deep/ .field.has-addons .control:last-child .button, .field.has-addons .control:last-child .input[data-v-3bbba6da], .field.has-addons .control:last-child .select select{
    font-size: 11px;
    margin-top: 5px;
  }


}

@media (min-width: 420px) and (max-width:576px) {

  /deep/ .margin-large-swap-input {
    margin-left: 50px;
    margin-top: 0px;
  }

}

.checkbox a {
  color: #3abb81;
}

/deep/ .field {
  /*background-color: #333b4c;*/
  background-color: transparent !important;
  border-radius: 0.5rem;
}

/deep/.field.has-addons {
  display: flex;
}

.select {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #5ad1c7;
  color: #5ad1c7;
}

/deep/ .select select[disabled], .taginput [disabled].taginput-container.is-focusable, [disabled].textarea, [disabled].input, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .taginput .taginput-container.is-focusable, .taginput fieldset[disabled] .taginput-container.is-focusable, fieldset[disabled] .textarea, fieldset[disabled] .input {
  background-color: #2a4855 !important;
  border-color: #24545f;
  box-shadow: none;
  color: #317884;
}

/deep/ .select select {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #5ad1c7;
  color: #5ad1c7;
}

/deep/ .select select option {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #5ad1c7;
  color: #5ad1c7;

}

/deep/ .b-steps .steps .step-items .step-item .step-details .step-title {
  color: #3b4f5c;
}

/deep/ .b-steps .steps .step-items .step-item.is-active .step-details .step-title {
  color: #5ad1c7;
}


/deep/ .b-steps .steps .step-items .step-item.is-active .step-marker {
  background-color: #2e484a;
  border-color: #5ad1c7;
  color: #5ad1c7;
}

/deep/ .b-steps .steps .step-items .step-item::before, .b-steps .steps .step-items .step-item::after {
  background: linear-gradient(to left, #67b6ef 50%, #67b6ef 50%);
  background-size: 200% 100%;
  background-position: right bottom;
}

/deep/ .b-steps .steps .step-items .step-item .step-marker {
  background-color: #526666;
  border-color: #3c6887;
  color: #67b6ef;
}

/deep/ .tabs.is-toggle li.is-active a {
  background-color: #314b6a;
  color: rgba(52, 132, 179, 0.84);
  background: -webkit-linear-gradient(90deg, rgb(35 44 57), #333b4c);
  border: none;
  font-size: 20px;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .tabs.is-toggle a {
  margin-bottom: 0;
  position: relative;
  background: -webkit-linear-gradient(90deg, rgb(35 44 57), #333b4c);
  color: #274a71;
  border: none;
  font-size: 11px;
  height: 50px;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .tabs.is-toggle a:active {
  margin-bottom: 0;
  position: relative;
  background: -webkit-linear-gradient(90deg, rgb(35 44 57), #333b4c);
  color: rgba(52, 132, 179, 0.84);
  border: none;
  font-size: 20px;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .tabs.is-toggle a:focus {
  margin-bottom: 0;
  position: relative;
  background: -webkit-linear-gradient(90deg, rgb(35 44 57), #333b4c);
  color: rgba(52, 132, 179, 0.84);
  border: none;
  font-size: 20px;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  margin-bottom: 0;
  position: relative;
  background: -webkit-linear-gradient(90deg, rgb(35 44 57), #333b4c);
  color: rgba(52, 132, 179, 0.84);
  border: none;
  font-size: 20px;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  margin-bottom: 0;
  position: relative;
  background: -webkit-linear-gradient(90deg, rgb(35 44 57), #333b4c);
  color: #5ad1c7;
  border: none;
  font-size: 20px;
  font-family: Source Code Pro,monospace !important;
}


.pools-card{
  padding-top:.5rem;
  color: #0d64c6;
  margin-left: 1rem;
  margin-right: 1rem;
}

/deep/ .control .plus .button .icon{
  width: 25px !important;
}

/deep/ .control .plus .button{
  width: 40px !important;
}

.remove-balance-section {
  /*border: 1px solid rgba(3, 150, 255, 0.38);*/
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #5ad1c7;
  font-family: 'Noto Sans', sans-serif;
  display: flex;
  justify-content: center;
  padding-top: 11px;
  padding-bottom: 11px;
  background: #333b4c;
  background-image: radial-gradient(circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2%);
  background-blend-mode: soft-light;
  font-size: 16px;
}

.margin-over-large {
  margin-right: 1.5%;
}

.highlight-span {
  background-color: rgba(39, 99, 74, 0.68);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  box-shadow: rgba(24, 202, 255, 0.13) 0px 0px 30px 3px !important;
  background-blend-mode: color;
  border: 1px solid rgba(177, 76, 226, 0.56) !important;
  outline: none;
}

.highlight-span span:before {
  background-color: #0a0a0a;
  padding: 7px 20px;
  display: block;
  border-radius: 50px;
  border: 4px solid #3abb81;
  background-blend-mode: color;
}

.highlight-span span:focus,
.highlight-span span:hover {
  background-color: rgba(27, 94, 71, 0.68);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 4px solid #309a6a;
  outline: none;
}

/deep/ .columns:not(:last-child) {
  margin-bottom: 0px;
}

.stake-col-lg {
}

.stake-col-sm {
  padding-left: 0px;
}

.withdraw-col-lg {
  padding-right: 0px;
}

.withdraw-col-sm {
  padding-left: 0px;
}

.withdraw-btn-lg {
  border-bottom-left-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
}

.withdraw-btn-sm {
  border-radius: 1rem;
}

.claim-btn-lg {
  border-bottom-right-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.claim-btn-sm {
  border-radius: 1rem;
}

/deep/ .minus {
  border-top-left-radius: 1rem;
}



/deep/ .button.is-buttonblue:hover {
  color: #19ac78;
}

/* card background */
.home-card-bg {
  background: #ebeae5;
}

.inset-bg {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding-bottom: 2rem;
}

.inset-bg2:active {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  width: 100%;
  height: 30px;
  position: relative;
  border-radius: 1rem;
  align-self: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 4px;
}


.col-title {
  color: #9573ba;
  font-size: 12px;
  font-family: Source Code Pro,monospace !important;
}

.pending-title {
  color: rgb(120 161 235);
  font-size: 14px;
}

.pool-control-title {
  color: rgb(120 161 235);
  font-size: 14px;
}

.pool-control-title:hover {
  color: #3abb81;
  font-size: 14px;
}

.pending-stat {
  color: #3cea36;
  font-size: 1em;
}

.pending-col {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: Source Code Pro,monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.col-shadow1 {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider {
  font-size: 12px;
}

.pool-name {
  font-size: 16px;
  color: rgb(120 161 235);
  font-family: Source Code Pro,monospace !important;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: rgb(38, 43, 56);
}

.pools-bg2 {
  background-color: transparent !important;
}

.pool-main2 {
  background-color: transparent !important;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.pool-item:hover {
  background-color: #333b4c;
  border: 1px solid rgba(3, 255, 150, 0.38) !important;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
}


.poolselect-bg {
  background-color: #293038 !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 0px 0px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

/deep/ .tabs.is-toggle li.is-active a {
  background-color: #314b6a;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #3abb81;
  z-index: 1;
  font-family: 'Noto Sans', sans-serif;
}

/deep/ .tabs.is-toggle a {
  border-color: #575f85;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 0;
  position: relative;
  font-family: 'Noto Sans', sans-serif;
  color: #195da7;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
  color: #00b89c;
}


.pools-card {
  padding-top: .5rem;
  color: #0d64c6;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow {
  box-shadow: rgba(26, 64, 106, 0.11) 0px 0px 30px 3px;
}

/deep/ .field.has-addons .control:first-child .control .button, .field.has-addons .control:first-child .control .input, .field.has-addons .control:first-child .control .select select {
  border-radius: 0 !important;
}

/deep/ .field.has-addons:first-child .control:first-child .control:first-child .button:first-child, .field.has-addons:first-child .control:first-child .control:first-child .input:first-child, .field.has-addons:first-child .control:first-child .control:first-child .select:first-child select:first-child {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.stake-btn-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.stake-btn-2 {
  font-size: 14px !important;
  max-height: 40px;
  padding-left: 12px !important;
}

.btn {
  color: #87b6e5;
  background: rgba(3, 150, 255, 0.32);
  background-repeat: no-repeat;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}

.btn:focus,
.btn:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    margin-bottom: 30px;
  }
}

</style>
<style lang="scss" scoped>

.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: "Angkor", sans-serif;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

</style>
<style lang="scss" scoped>

//eccdff
.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro, monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}


$message-header-background-color: #e3f;

.loading-page-container {
  opacity: .95;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  z-index: 1;

}

.loading-page-container .progress {
  position: relative;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 6px solid #20182d;
  background-color: #2c2938;
  background-image: radial-gradient(circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2%);
  background-blend-mode: hard-light;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;

  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 1;
}

.loading-page-container .progress .inner {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: 50%;
  margin-left: -4px;
  margin-top: -1px;
  width: 196.5px;
  height: 196.5px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.loading-page-container .progress .inner .water {
  opacity: .55;
  position: absolute;
  z-index: 1;
  width: 200%;
  height: 200%;
  left: -50%;
  border-radius: 40%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  animation-name: spin;
  top: 55%;
  background: #32BAFA;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  box-shadow: 0 0 20px #152A4A;

}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 0.5rem;
  color: #296992;
}

.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}

.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}

// for styles that do not have ascii characters setting the height
$height: 15px;

.outer {
  margin-bottom: $height;
  width: 100%;
  height: $height;
}

.plain {

  &.outer {
    border: 1px solid limegreen;
  }

  .inner {
    width: 90%;
    background-color: limegreen;
    height: $height;
  }
}

.borderless .inner {
  width: 90%;
  background-color: limegreen;
  height: $height;
}

.punctuation.outer {
  border: 1px solid limegreen;
  line-height: 1.35;
}

@-webkit-keyframes progress-bar-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes spinning {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes pulsing-opacity {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}


.plain .inner, .borderless .inner {
  animation: progress-bar-width steps(20) 5s infinite;
}

.pipes .inner, .blocks .inner, .blocks-gaps {
  animation: progress-bar-width 5s infinite;
  overflow: hidden;
  animation-timing-function: linear;
}

.blocks-gaps .inner {
  letter-spacing: 2px;
}

.activity {
  text-align: center;
  overflow: hidden !important;
}

.spinning {
  animation: spinning steps(8, end) 2s infinite;
}

.pulsing {
  animation: pulsing-opacity steps(3) 0.5s infinite alternate;
}

</style>
