<template>
  <div class="card3d" v-bind:class="[flipped ? useexpand ? 'flip-container flipped' : 'flip-container flippedhm' : 'flip-container']">
    <div class="flipper">
      <div class="front">
        <div class="card form-container" v-bind:class="[stake_balance > 0 ? 'highlight' : '']">
          <div v-if="token_image !== '0' && token_image != 'http://www.myimage.com'" class="card__product-img">
            <img :src="token_image" alt="NFT" />
            <!--            <img src="https://via.placeholder.com/200x50" />-->
          </div>
          <div v-if="token_image === '0'" class="card__product-img">
            <img src="../assets/nft-placeholder.svg" alt="stake" style="margin-top: 10px; margin-left: 0px; width: 150px;">
          </div>
          <div v-if="token_image == 'http://www.myimage.com'" class="card__product-img">
            <img src="../assets/marmalade-icon.svg" alt="Marmalade" style="margin-top: 10px; margin-left: 0px; width: 150px;">
          </div>
          <div class="card__content">
            <p v-if="token_name !== '0'" class="card__name">{{ token_name }} </p>
            <div class="card__content-bottom">
              <div v-if="token_description !== '0'" class="card__price">
                <p><span style="color: #3bac81">description</span>: <span style="color: #3a99bb">{{token_description}}</span></p>
              </div>
            </div>
            <div class="card__content-bottom">
              <div class="card__price">
                <p><span style="color: #3bac81">token</span>: <span style="color: #3a99bb">{{pool_reward_token}}({{pool_reward_token_id}})</span></p>
              </div>
            </div>
          </div>
        </div>
        <a v-if="show_flipper === true" v-on:click="flipped=true; $emit('flipped', true)" class="btn2" v-bind:class="[stake_balance > 0 ? 'highlight-span' : '']" href="#">
          <span> > </span>
        </a>
      </div>
      <div class="back">
        <slot name="back"></slot>

        <div class="card form-container" v-bind:class="[stake_balance > 0 ? 'highlight' : '']">

          <div class="back-col-main">

            <div class="card__content-apy-back">
              <div class="card__price_apy_back">
                <p><span style="color: #3bac81">{{ pool_name }}</span></p>
              </div>
            </div>

            <div class="columns mb-1 back-col" style="position: relative; top: 10px; background-color: rgba(38,43,56,1); border-radius: 1rem;">
              <div v-if="accountApproved === true" class="column is-align-self-center back-card-stat-col" style="margin-left: 4%; margin-right: 1%;">
                <div class="columns">
                  <div class="column"  v-bind:class="[$screen.width > 768 ? 'stake-col-lg' : 'stake-col-sm']">
                    <div class="field-body">
                      <div class="field has-addons">
                        <p class="control is-expanded back-col-text">
                          PENDING: {{stake_pending_rewards}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns mt-5 mb-5 back-col" style="position: relative; top: 10px; background-color: rgba(38,43,56,1); border-radius: 1rem;">
              <div v-if="accountApproved === true" class="column is-align-self-center back-card-stat-col" style="margin-left: 4%; margin-right: 1%;">
                <div class="columns">
                  <div class="column"  v-bind:class="[$screen.width > 768 ? 'stake-col-lg' : 'stake-col-sm']">
                    <div class="field-body">
                      <div class="field has-addons">
                        <p class="control is-expanded back-col-text">
                          STAKED: {{stake_balance}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns mt-1 mb-3 back-col" style="position: relative; top: 10px; background-color: rgba(38,43,56,1); border-radius: 1rem;">
              <div v-if="accountApproved === true" class="column is-align-self-center back-card-stat-col" style="margin-left: 4%; margin-right: 1%;">
                <div class="columns">
                  <div class="column"  v-bind:class="[$screen.width > 768 ? 'stake-col-lg' : 'stake-col-sm']">
                    <div class="field-body">
                      <div class="field has-addons">
                        <p class="control is-expanded back-col-text">
                          EARNED: {{stake_rewards}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div v-if="accountApproved === false" class="columns mt-3 mb-3 pending-col" style="position: relative; top: 10px; background-color: rgba(38,43,56,1); border-radius: 1rem;">
            <div  class="column is-align-self-center stake-btn-col" style="margin-left: 4%; margin-right: 1%;">
              <div class="columns">
                <div class="column"  v-bind:class="[$screen.width > 768 ? 'stake-col-lg' : 'stake-col-sm']">
                  <div class="field-body">
                    <div class="field has-addons">
                      <div v-if="accountApproved === true" class="column is-align-self-center stake-btn-col">
                        <div class="btn btn-lg stake-btn" style="border-radius: 1rem !important;">
                          <span>CONNECT WALLET</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="columns mt-4 mb-0 pending-col"
               style="position: relative; top: 10px; background-color: rgba(38,43,56,1); border-radius: 1rem;">
            <!-- if connected pool buttons-->
            <div v-if="accountApproved === true" class="column is-align-self-center stake-btn-col" style="margin-left: 4%; margin-right: 1%;">
              <div class="columns">
                <div class="column"  v-bind:class="[$screen.width > 768 ? 'stake-col-lg' : 'stake-col-sm']">
                  <div class="field-body">
                    <div class="field has-addons">
                      <p class="control is-expanded">
                        <b-numberinput size="is-small" v-model="stakeAmount" min="0" type="is-buttonblue" controls-alignment="left"
                                       controls-position="compact"></b-numberinput>
                      </p>
                      <div @click="clickStake(pool_contract)" class="btn stake-btn-2"
                           style="border-bottom-right-radius: 1rem; border-top-right-radius: 1rem; width: 35%; overflow: hidden;">
                        <span class="font5">STAKE</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column" v-bind:class="[$screen.width > 768 ? 'withdraw-col-lg' : 'withdraw-col-sm']">
                  <div @click="clickWithdraw(pool_contract)"  class="btn btn-lg stake-btn" style="border-radius: 1rem;" v-bind:class="[$screen.width > 768 ? 'withdraw-btn-lg' : 'withdraw-btn-sm']">
                    <span>WITHDRAW</span>
                  </div>
                </div>
                <div class="column" style="padding-left: 0px;">
                  <div @click="clickClaimRewards(pool_contract)" class="btn btn-lg stake-btn" style="border-radius: 1rem;" v-bind:class="[$screen.width > 768 ? 'claim-btn-lg' : 'claim-btn-sm']">
                    <span>CLAIM</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="accountApproved === false" class="columns mt-6 mb-5 pending-col"
               style="position: relative; top: 10px; background-color: rgba(38,43,56,1); border-radius: 1rem;">
            <div class="column is-align-self-center stake-btn-col">
              <div class="btn btn-lg stake-btn" style="border-radius: 1rem !important;">
                <span>CONNECT WALLET</span>
              </div>
            </div>
          </div>


        </div>
        <a v-if="show_flipper === true" v-on:click="flipped=false; $emit('flipped', false)" class="btn2" v-bind:class="[stake_balance > 0 ? 'highlight-span' : '']" href="#">
          <span> &lt; </span>
        </a>
        <a v-if="show_flipper === true" v-on:click="flipped=false; $emit('flipped', false)" class="btn2" v-bind:class="[stake_balance > 0 ? 'highlight-span' : '']" href="#">
          <span> &lt; </span>
        </a>
      </div>
    </div>

  </div>
</template>

<script>

import {mapGetters} from "vuex";
// import Accordion from "./accordion.vue";
// import AccordionItem from "./accordion-item";

export default {
  name: "FlipCard",
  components: {
  },
  props: {
    accountApproved: {
      type: Boolean,
      default: false,
    },
    pool_name: {
      type: String,
      default: "0",
    },
    pool_id: {
      type: [String, Number],
      default: "0"
    },
    user_account: {
      type: String,
      default: "0",
    },
    pool_apy: {
      type: [String, Number],
      default: "0",
    },
    pool_balance: {
      type: [String, Number],
      default: "0",
    },
    pool_claim_wait_seconds: {
      type: [String, Number],
      default: "0",
    },
    pool_end_time: {
      type: [String, Object],
      default: "0",
    },
    pool_has_vesting_connection: {
      type: Boolean,
      default: false,
    },
    pool_initialized: {
      type: Boolean,
      default: false,
    },
    pool_paid: {
      type: [String, Number],
      default: "0",
    },
    pool_contract: {
      type: String,
      default: "0",
    },
    pool_stake_token: {
      type: [String, Object],
      default: "0",
    },
    pool_reward_token: {
      type: [String, Object],
      default: "0",
    },
    pool_reward_token_id: {
      type: String,
      default: "stakefactorynull",
    },
    pool_stakers: {
      type: [String, Number],
      default: "0",
    },
    pool_start_time: {
      type: [String, Object],
      default: "0",
    },
    pool_tokens_locked: {
      type: [String, Number],
      default: "0",
    },
    pool_vesting_pool_id: {
      type: [String, Number],
      default: "0",
    },
    pool_withdraw_duration: {
      type: [String, Object],
      default: "0",
    },
    stake_last_claimed: {
      type: [String, Object],
      default: "0",
    },
    stake_last_updated: {
      type: [String, Object],
      default: "0",
    },
    stake_last_withdraw: {
      type: [String, Object],
      default: "0",
    },
    stake_start_time: {
      type: [String, Object],
      default: "0",
    },
    stake_balance: {
      type: [String, Number],
      default: "0",
    },
    stake_rewards: {
      type: [String, Number],
      default: "0",
    },
    stake_pending_rewards: {
      type: [String, Number],
      default: "0",
    },
    token_image: {
      type: String,
      default: "0",
    },
    stake_token_image: {
      type: String,
      default: "0",
    },
    is_stake_nft: {
      type: Boolean,
      default: false,
    },
    show_flipper: {
      type: Boolean,
      default: true,
    },
    token_name: {
      type: String,
      default: "?",
    },
    token_description: {
      type: String,
      default: "?",
    }
  },
  data: function () {
    return {
      debug: false,
      flipped: false,
      showStakeControls: false,
      stakeAmount: 0.0,
      useexpand: false,
      card: this.$el,
      showPendingRewards: true,
      bounds: 0,
    };
  },
  computed: {

  },
  methods: {
    ...mapGetters("accounts", ["getAccountExists", "getAccountConfirmed", "getAllPoolsFactoryStakeFungiv2", "getUserPoolsFactoryStakeFungiv2"]),

    number_test(n) {
      let result = (n - Math.floor(n)) !== 0;

      if (result) {
        if (this.debug) {
          console.log("Contains Decimal Place");
        }
        return true;
      } else {
        if (this.debug) {
          console.log("No decimal place");
        }
        return false;
      }

    },

    async clickWithdraw(pool_contract){
      if(this.debug){
        console.log("CLAIMING POOL REWARDS")
        console.log(pool_contract);
      }

      let payload = {
        poolID: this.pool_id,
        accountName: this.user_account,
        moduleName: this.pool_stake_token
      }

      if(this.debug){
        console.log(payload);
      }

      if(pool_contract === "factory-stake-fungiv2-polyfungiv1"){
        await this.$store.dispatch("accounts/withdrawFactoryStakeFungiv2Polyfungiv1", payload);
      }

    },

    async clickClaimRewards(pool_contract){
      if(this.debug){
        console.log("CLAIMING POOL REWARDS")
        console.log(pool_contract);
      }

      let payload = {
        poolID: this.pool_id,
        accountName: this.user_account,
        moduleName: this.pool_stake_token
      }

      if(this.debug){
        console.log(payload);
      }

      if(pool_contract === "factory-stake-fungiv2-polyfungiv1"){
        await this.$store.dispatch("accounts/claimRewardsFactoryStakeFungiv2Polyfungiv1", payload);
      }

    },

    async clickStake(pool_contract) {

      if(this.debug){
        console.log("CREATING NEW STAKE")
        console.log(pool_contract);
      }

      let payload = {
        poolID: this.pool_id,
        amount: this.stakeAmount,
        accountName: this.user_account,
        moduleName: this.pool_stake_token
      }

      if(this.debug){
        console.log(payload);
      }

      if(pool_contract === "factory-stake-fungiv2-polyfungiv1"){
        await this.$store.dispatch("accounts/stakeFactoryStakeFungiv2Polyfungiv1", payload);
      }


    }



  },
  async created() {

  }
};
</script>

<style scoped>

body {
  perspective: 1500px;
}

.wrapper {
  max-width: 295px;
  max-height: 295px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frameWrapper {
  height: 277px;
  width: 240px;
}

.frame {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: #2e3e60b8;
}

.primaryLayer,
.secondaryLayer {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.primaryLayer {
  clip-path: polygon(0% 0%, 100% 0%, 100% 40%, 0% 55%);
  background: radial-gradient(black 0%, transparent 10%) 0 0, radial-gradient(black 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0.1) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #2c313a;
  background-size: 16px 16px;
  border: 2px solid rgba(17, 66, 101, 0.3);
  background-blend-mode: darken;
}

.secondaryLayer {
  clip-path: polygon(100% 100%, 0% 100%, 0% 60%, 100% 45%);
  background: radial-gradient(black 0%, transparent 10%) 0 0, radial-gradient(black 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0.1) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #2c313a;
  background-size: 16px 16px;
  border: 2px solid rgba(17, 66, 101, 0.3);
  background-blend-mode: darken;
}

.image {
  object-fit: cover;
  position: absolute;
  min-height: 65%;
  max-height: 100%;
  width: 100%;
}

.primaryLayer > .image {
  top: 0px;
}

.secondaryLayer > .image {
  bottom: 0px;
}


.card3d {
  font-weight: bold;
  padding: 1em;
  color: #181a1a;
  width:  300px;
  max-height: 450px;
  box-shadow: 0 1px 5px #00000099;
  border-radius: 10px;
  position: relative;
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  transform: rotate3d(0,0,0,0);
}

.card3d:hover {
  transition-duration: 150ms;
  box-shadow: 0 5px 20px 5px #00000044;
}

.card3d .glow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  background-image: radial-gradient(circle at 50% -20%, #ffffff22, #0000000f);
}


.highlight{
  box-shadow: rgba(24, 202, 255, 0.13) 0px 0px 30px 3px !important;
  border-color: rgba(106, 70, 123, 0.56) !important;
  outline: none;
}

/deep/ .b-numberinput input {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
}

.pending-col {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: 'Lexend Deca', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.back-col {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px;
}

.back-col-main {
  background-color: rgba(38, 43, 56, 1);
  border-radius: 1rem;
  font-family: 'Lexend Deca', sans-serif;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  font-size: 12px;
  height: 100%;
  width: 100%;
  padding: .8rem;
}

.back-col-text{
  font-size: 12px;
}

.col-shadow1 {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider {
  font-size: 12px;
}

.pool-name {
  font-size: 16px;
  color: rgb(120 161 235);
  font-family: 'Lexend Deca', sans-serif;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: #293038;
}

.pool-main {
  background-color: transparent;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: 'Noto Sans', sans-serif;
}

.poolselect-bg {
  background-color: #293038 !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

/deep/ .tabs.is-toggle li.is-active a {
  background-color: #314b6a;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #3abb81;
  z-index: 1;
  font-family: 'Noto Sans', sans-serif;
}

/deep/ .tabs.is-toggle a {
  border-color: #575f85;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 0;
  position: relative;
  font-family: 'Noto Sans', sans-serif;
  color: #195da7;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
  color: #00b89c;
}


.pools-card {
  padding-top: .5rem;
  color: #0d64c6;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
}

/deep/ .field.has-addons .control:first-child .control .button, .field.has-addons .control:first-child .control .input, .field.has-addons .control:first-child .control .select select {
  border-radius: 0 !important;
}

/deep/ .field.has-addons:first-child  .control:first-child .control:first-child  .button:first-child , .field.has-addons:first-child  .control:first-child .control:first-child  .input:first-child , .field.has-addons:first-child .control:first-child .control:first-child  .select:first-child  select:first-child{
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.stake-btn-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.back-card-stat-col{
  display: flex;
  justify-content: left;
}

.stake-btn-2 {
  font-size: 14px !important;
  max-height: 40px;
  padding-left: 8px !important;
}

.btn {
  color: #87b6e5;
  background: rgba(3, 150, 255, 0.32);
  background-repeat: no-repeat;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}

.btn:focus,
.btn:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
  cursor: pointer;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    margin-bottom: 30px;
  }
}

.text3d {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 transparent, 0 3px 0 transparent, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px 0;
}


.pricingTable {
  background: #fff;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding: 0 0 30px;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: -20px;
}

.pricingTable .pricingTable-header {
  background: #c2803a;
  padding: 10px 0 10px;
  margin: 0 auto 40px;
  border-radius: 40px 40px 0 0;
  position: relative;
}

.pricingTable .pricingTable-header:hover {
  color: #fff;
  background: #7bc8fa;
}

.pricingTable .pricingTable-header:after {
  content: "";
  background: linear-gradient(-45deg, transparent 75%, #c2803a 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #c2803a 75%) 0 50%;
  background-repeat: repeat-x;
  background-size: 22px;
  width: 100%;
  height: 21px;
  position: absolute;
  bottom: -21px;
  left: 0;
  cursor: pointer;
}

.pricingTable .pricingTable-header:hover:after {
  content: "";
  background: linear-gradient(-45deg, transparent 75%, #7bc8fa 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #7bc8fa 75%) 0 50%;
  background-repeat: repeat-x;
  background-size: 22px;
  width: 100%;
  height: 21px;
  position: absolute;
  bottom: -21px;
  left: 0;
  cursor: pointer;
}


.pricingTable .title-back {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  cursor: pointer;
}


.pricingTable .price-value {
  color: #5b5b5b;
  margin: 0 0 20px;
}

.pricingTable .price-value .amount {
  font-size: 65px;
  font-weight: 400;
  line-height: 60px;
  display: block;
}

.pricingTable .price-value .duration {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  display: block;
}

.pricingTable .pricing-content {
  padding: 0 10px;
  margin: 0 0 10px;
  list-style: none;
}

.pricingTable .pricing-content li {
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  line-height: 1px;
  padding: 9px 15px 8px 10px;
  position: relative;
  overflow: auto;
}

.pricingTable .pricing-content li:nth-child(odd) {
  background: #e7e7e7;
}

.pricingTable .pricing-content li:nth-last-child(-n+4) {
  line-height: normal;
}

.pricingTable .pricingTable-signup a {
  color: #fff;
  background: #c2803a;
  font-size: 22px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 1px;
  padding: 5px 25px;
  border-radius: 50px;
  transition: all 0.3s ease 0s;
}

.pricingTable .pricingTable-signup a:hover {
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.9);
}

.pricingTable.red .pricingTable-header {
  background: #d83b50;
}

.pricingTable.red .pricingTable-header:after {
  background: linear-gradient(-45deg, transparent 75%, #d83b50 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #d83b50 75%) 0 50%;
  background-size: 22px;
}

.pricingTable.red .pricingTable-signup a {
  background: #d83b50;
}

.pricingTable.orange .pricingTable-header {
  background: #e0681e;
}

.pricingTable.orange .pricingTable-header:after {
  background: linear-gradient(-45deg, transparent 75%, #e0681e 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #e0681e 75%) 0 50%;
  background-size: 22px;
}

.pricingTable.orange .pricingTable-signup a {
  background: #e0681e;
}

.btn2 {
  color: #fff;
  background-color: #0a0a0a;
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 1px;
  border: none;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  transition: all 0.3s ease 0s;
  top: 0;
  right: 0px;
  background-blend-mode: color;
  outline: none;
  cursor: pointer;
}

.btn2:focus,
.btn2:hover {
  color: #fff;
  background-color: #0a0a0a;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) inset, 0 0 10px rgba(0, 0, 0, 0.3);
  background-blend-mode: color;
  outline: none;
  cursor: pointer;
}


.btn2 span {
  background-color: rgba(58, 91, 203, 0.91);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 1px solid #114265;
  outline: none;
  cursor: pointer;
}

.btn2 span:before {
  background-color: #0a0a0a;
  padding: 7px 20px;
  display: block;
  border-radius: 50px;
  border: 1px solid #114265;
  background-blend-mode: color;
  cursor: pointer;
}

.highlight-span span {
  background-color: rgba(92, 57, 94, 0.82);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 1px solid #309a6b85;
  outline: none;
  cursor: pointer;
}

.highlight-span span:before {
  background-color: rgba(92, 57, 94, 0.82);
  padding: 7px 20px;
  display: block;
  border-radius: 50px;
  border: 1px solid #309a6b85;
  background-blend-mode: color;
  cursor: pointer;
}

.highlight-span span:focus,
.highlight-span span:hover {
  background-color: rgba(92, 57, 94, 0.82);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 1px solid #309a6b85;
  outline: none;
  cursor: pointer;
}


i.frontFlipBtn,
i.backFlipBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #FFFFFF;
}

i.backFlipBtn {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}


.flip-container {
  min-height: 120px;
}

.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  position: relative;
  max-width: 290px;
}

.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  width: 100%;
}

.back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  position: absolute;
}

.flip-container.flipped .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.flip-container.flipped .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flip-container.flippedhm .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.flip-container.flippedhm .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flip-container.flipped {
  min-height: 520px;
}

.flip-container.flippedhm {
  min-height: 320px;
}

.front {
  z-index: 2;
}


</style>

<style scoped lang="scss">
.icon {
  display: flex;
}

.card__content-apy-back {
  display: flex;
  justify-content: center;
  padding-top: 4px;
  padding-bottom: 0;
  position: absolute;
  top: 6px;

  > * {
    display: flex;
    align-items: center;
    font-weight: 600;
  }
}

.card__price_apy_back {
  color: #2a6980;
  font-size: 10px;
  font-family: 'Lexend Deca', sans-serif;
}

.card {
  background-color: #a0b3c4;
  color: #8BACD9;
  border-radius: 16px;
  width: 300px;
  //height: 500px;
  padding-bottom: 0;
  box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.1);

  .card__product-img {
    display: flex;
    position: relative;
    max-width: 300px;
    max-height: 300px;
    justify-content: center;
  }

  .card__product-img-2 {
    top: 0px;
    display: flex;
    position: relative;
    max-width: 300px;
    max-height: 300px;
  }

  .card__content {
    display: flex;
    flex-direction: column;

    .card__name {
      color: #495fb8;
      font-size: 16px;
      font-weight: bold;
      font-family: 'Lexend Deca', sans-serif;
    }

    .card__description {
      font-size: 1rem;
      line-height: 2.6rem;
      font-weight: lighter;
      position: absolute;
      top: 0px;
    }

    .card__content-bottom {
      display: flex;
      justify-content: center;
      padding-top: 4px;
      padding-bottom: 0;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price {
        color: #2a6980;
        font-size: 10px;
        font-family: 'Lexend Deca', sans-serif;
      }
    }

    .card__content-apy {
      display: flex;
      justify-content: center;
      padding-top: 4px;
      padding-bottom: 0;
      position: absolute;
      top: 6px;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price_apy {
        color: #2a6980;
        font-size: 10px;
        font-family: 'Lexend Deca', sans-serif;
      }
    }

    .card__content-staked {
      display: flex;
      justify-content: center;
      padding-top: 17px;
      padding-bottom: 0;
      position: absolute;
      top: 6px;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price_staked {
        color: #2a6980;
        font-size: 10px;
        font-family: 'Lexend Deca', sans-serif;
      }
    }

    .card__content-pending {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
      padding-top: 10px;
      position: absolute;
      border: 2px solid #114265;
      width: 90%;
      background-size:16px 16px;
      background-color: rgba(51, 59, 76, 0.92);
      border-radius:1rem;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price_pending {
        color: #2a6980;
        font-size: 20px;
        font-family: 'Lexend Deca', sans-serif;
      }
    }
  }

  .card__footer {
    display: flex;
    align-items: center;
    border-top: 1px solid #2E405A;
    gap: 16px;
    padding-top: 16px;

    .card__avatar {
      display: flex;
      border-radius: 90px;
      border: 1px solid white;

      img {
        width: 33px;
      }
    }

    .card__autor--alt-color {
      color: white;
      cursor: pointer;

      &:hover {
        color: #00FFF8;
      }
    }
  }
}

//Transition
.fade-enter-active,
.fade-leave-active {
  transition: 0.25s ease-out;
}

.fade-leave-to,
.fade-enter-from {
  opacity: 0;
}


.form-container {
  background: radial-gradient(black 0%, transparent 10%) 0 0, radial-gradient(black 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0.1) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #2c313a;
  background-size: 16px 16px;
  border: 2px solid #114265;
  background-blend-mode: darken;
  padding: 20px;
  padding-top: 40px;
  border-radius: 20px;
  box-shadow: rgb(56 154 255 / 22%) 0px 0px 30px 3px !important;
}


.form-container .form-icon {
  color: #40ac98;
  font-size: 55px;
  text-align: center;
  line-height: 100px;
  width: 100px;
  height: 100px;
  margin: 0 auto 15px;
  border-radius: 50px;
  box-shadow: 7px 7px 10px #cbced1, -7px -7px 10px #fff;
}

.form-container .title {
  color: #ac40ab;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 20px;
}

.form-container .form-horizontal .form-group {
  margin: 0 0 25px 0;
}

.form-container .form-horizontal .form-group label {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.form-container .form-horizontal .form-control {
  color: #333;
  background: #ecf0f3;
  font-size: 15px;
  height: 50px;
  padding: 20px;
  letter-spacing: 1px;
  border: none;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.form-container .form-horizontal .form-control:focus {
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  outline: none;
}

.form-container .form-horizontal .form-control::placeholder {
  color: #808080;
  font-size: 14px;
}

.form-container .form-horizontal .btn {
  color: #000;
  background-color: #ac40ab;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  padding: 12px 15px 11px;
  border-radius: 20px;
  box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px #fff;
  border: none;
  transition: all 0.5s ease 0s;
}

.form-container .form-horizontal .btn:hover,
.form-container .form-horizontal .btn:focus {
  color: #fff;
  letter-spacing: 3px;
  box-shadow: none;
  outline: none;
}

</style>
