<template>
  <div v-bind:class="[flipped ? 'flip-container flipped' : 'flip-container']"
       class="column pool-main2 is-centered mb-4 mt-5">
    <div class="columns is-centered pools-card" >
      <div
          class="column pool-item is-centered is-align-self-center" v-bind:class="[stake_balance > 0 ? 'highlight-span' : '']"
      >
        <accordion>
          <accordion-item>
            <!-- This slot will handle the title/header of the accordion and is the part you click on -->
            <template slot="accordion-trigger">
              <div class="columns">
                <div class="column is-align-self-center">
                  <div class="pool-name">{{ pool_name }}</div>
                  <div class="provider">stake: {{ pool_stake_token }}</div>
                  <div v-if="lp_platform !== '0'" class="provider">platform: {{ lp_platform }}</div>
                </div>
                <div class="column is-align-self-center">
                  <div class="col-title">APY</div>
                  <div>{{ pool_apy }}%</div>
                </div>
                <div class="column is-align-self-center">
                  <div class="col-title">YIELD</div>
                  <div>{{ pool_reward_token }}</div>
                </div>
              </div>
            </template>
            <!-- pool accordion content -->
            <template slot="accordion-content">
              <div class="columns mt-5 mb-3 pending-col leatherbg"
                   style="position: relative; top: 10px; background-color: rgba(38,43,56,0.29); border-radius: 1rem;">
                <!--                <div class="column is-2 is-align-self-center">-->
                <!--                  <div class="col-title">END TIME:</div>-->
                <!--                  <div>{{ pool_endtime }}</div>-->
                <!--                </div>-->
                <div class="column is-2 is-align-self-center">
                  <b-tooltip type="is-dark" label="You have 0 tokens">
                    <div class="col-title2">WALLET:</div>
                    <div style="color: #3abb81">0</div>
                  </b-tooltip>
                </div>
                <div class="column is-2 is-align-self-center">
                  <b-tooltip type="is-dark" label="You have 0 tokens">
                    <div class="col-title2">DEPOSITED:</div>
                    <div style="color: #3abb81">0</div>
                  </b-tooltip>
                </div>
                <div class="column is-2 is-align-self-center">
                  <b-tooltip type="is-dark" label="You have 0 tokens">
                    <div class="col-title2">CLAIMED:</div>
                    <div style="color: #3abb81">0</div>
                  </b-tooltip>
                </div>


                <!-- if connected pool buttons-->
                <div class="column is-align-self-center stake-btn-col" style="margin-left: 4%; margin-right: 1%;">
                  <div class="columns">
                    <div class="column"  v-bind:class="[$screen.width > 768 ? 'stake-col-lg' : 'stake-col-sm']">
                      <div class="field-body">
                        <div class="field has-addons">
                          <p class="control is-expanded">
                            <b-numberinput expanded size="is-small" v-model="stakeAmount" min="0" type="is-buttonblue" controls-alignment="left"
                                           controls-position="compact"></b-numberinput>
                          </p>
                          <div class="btn stake-btn-2"
                               style="border-bottom-right-radius: 1rem; border-top-right-radius: 1rem; width: 35%; overflow: hidden;">
                            <p class="font5">STAKE</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column" v-bind:class="[$screen.width > 768 ? 'withdraw-col-lg' : 'withdraw-col-sm']">
                      <div class="btn btn-lg stake-btn"
                           v-bind:class="[$screen.width > 768 ? 'withdraw-btn-lg' : 'withdraw-btn-sm']">
                        <p>WITHDRAW</p>
                      </div>
                    </div>
                    <div class="column" style="padding-left: 0px;">
                      <div class="btn btn-lg stake-btn"
                           v-bind:class="[$screen.width > 768 ? 'claim-btn-lg' : 'claim-btn-sm']">
                        <p>CLAIM</p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </template>
          </accordion-item>
        </accordion>


      </div>
    </div>

  </div>
</template>

<script>
//import vue store variables to this page
import {mapGetters} from "vuex";
import Accordion from "./accordion.vue";
import AccordionItem from "./accordion-item";

export default {
  name: "PoolCard",
  components: {
    Accordion,
    AccordionItem
  },
  props: {
    accountApproved: {
      type: Boolean,
      default: false,
    },
    pool_name: {
      type: String,
      default: "0",
    },
    pool_id: {
      type: [String, Number],
      default: "0"
    },
    user_account: {
      type: String,
      default: "0",
    },
    pool_apy: {
      type: [String, Number],
      default: "0",
    },
    pool_balance: {
      type: [String, Number],
      default: "0",
    },
    pool_claim_wait_seconds: {
      type: [String, Number],
      default: "0",
    },
    pool_end_time: {
      type: [String, Object],
      default: "0",
    },
    pool_has_vesting_connection: {
      type: Boolean,
      default: false,
    },
    pool_initialized: {
      type: Boolean,
      default: false,
    },
    pool_paid: {
      type: [String, Number],
      default: "0",
    },
    pool_contract: {
      type: String,
      default: "0",
    },
    pool_stake_token: {
      type: [String, Object],
      default: "0",
    },
    pool_reward_token: {
      type: [String, Object],
      default: "0",
    },
    pool_stakers: {
      type: [String, Number],
      default: "0",
    },
    pool_start_time: {
      type: [String, Object],
      default: "0",
    },
    pool_tokens_locked: {
      type: [String, Number],
      default: "0",
    },
    pool_vesting_pool_id: {
      type: [String, Number],
      default: "0",
    },
    pool_withdraw_duration: {
      type: [String, Object],
      default: "0",
    },
    stake_last_claimed: {
      type: [String, Object],
      default: "0",
    },
    stake_last_updated: {
      type: [String, Object],
      default: "0",
    },
    stake_last_withdraw: {
      type: [String, Object],
      default: "0",
    },
    stake_start_time: {
      type: [String, Object],
      default: "0",
    },
    stake_balance: {
      type: [String, Number],
      default: "0",
    },
    stake_rewards: {
      type: [String, Number],
      default: "0",
    },
    stake_pending_rewards: {
      type: [String, Number],
      default: "0",
    },
    token_image: {
      type: String,
      default: "0",
    },
    is_pool_owner: {
      type: Boolean,
      default: false,
    },
    lp_platform: {
      type: String,
      default: "0",
    },

  },
  data: function () {
    return {
      debug: false,
      flipped: false,
      showStakeControls: false,
      stakeAmount: 0.0
    };
  },
  computed: {},
  methods: {
    ...mapGetters("accounts", ["getAccountExists", "getAccountConfirmed"]),

    number_test(n) {
      let result = (n - Math.floor(n)) !== 0;

      if (result) {
        if (this.debug) {
          console.log("Contains Decimal Place");
        }
        return true;
      } else {
        if (this.debug) {
          console.log("No decimal place");
        }
        return false;
      }

    },

    async clickWithdraw(pool_contract){
      if(this.debug){
        console.log("CLAIMING POOL REWARDS")
        console.log(pool_contract);
      }

      let payload = {
        poolID: this.pool_id,
        accountName: this.user_account,
        moduleName: this.pool_stake_token
      }

      if(this.debug){
        console.log(payload);
      }

      if(pool_contract === "factory-stake-fungiv2"){
        await this.$store.dispatch("accounts/withdrawFactoryStakeFungiv2", payload);
      }else if(pool_contract === "factory-stake-tokens-fungiv2"){
        await this.$store.dispatch("accounts/withdrawFactoryStakeAswapTokensFungiv2", payload);
      }

    },

    async clickClaimRewards(pool_contract){
      if(this.debug){
        console.log("CLAIMING POOL REWARDS")
        console.log(pool_contract);
      }

      let payload = {
        poolID: this.pool_id,
        accountName: this.user_account,
        moduleName: this.pool_stake_token
      }

      if(this.debug){
        console.log(payload);
      }

      if(pool_contract === "factory-stake-fungiv2"){
        await this.$store.dispatch("accounts/claimRewardsFactoryStakeFungiv2", payload);
      }else if(pool_contract === "factory-stake-tokens-fungiv2"){
        await this.$store.dispatch("accounts/claimRewardsFactoryStakeAswapTokensFungiv2", payload);
      }

    },

    async clickStake(pool_contract) {

      if(this.debug){
        console.log("CREATING NEW STAKE")
        console.log(pool_contract);
      }

      let payload = {
        poolID: this.pool_id,
        amount: this.stakeAmount,
        accountName: this.user_account,
        moduleName: this.pool_stake_token,
        stakeToken: this.pool_stake_token,
      }

      if(pool_contract === "factory-stake-tokens-fungiv2") {
        payload.moduleName = 'free.tokens-reference'
      }

      if(this.debug){
        console.log(payload);
      }

      if(pool_contract === "factory-stake-fungiv2"){
        await this.$store.dispatch("accounts/stakeFactoryStakeFungiv2", payload);
      } else if(pool_contract === "factory-stake-tokens-fungiv2"){
        await this.$store.dispatch("accounts/stakeFactoryStakeAswapTokensFungiv2", payload);
      }


    },

    async clickPoolControls(){
      let routeData = this.$router.resolve({path: "/pool-controls/" + this.pool_id +"/"+this.pool_contract});
      window.open(routeData.href, "_blank");
    },

  },
};
</script>

<style scoped>


.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  border-radius: 0.5rem;
  color: #296992;
  margin-right: 10px !important;
}

.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 0.6);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}

.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}


.leatherbg {
  background: radial-gradient(#00000078 0%, transparent 10%) 0 0, radial-gradient(#0000005c 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 250, 250, 0) 15%, transparent 20%) 8px 9px;
  background-color: #2c243275 !important;
  background-size: 16px 16px;
  border: 2px solid #11426573;
  background-blend-mode: darken;
}

.small-pool {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 3%;
}

::v-deep .accordion__item {
  cursor: pointer;
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

/deep/ .accordion__item {
  cursor: pointer;
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

.accordion__item {
  cursor: pointer;
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

.highlight-span {
  background-color: rgba(39, 99, 74, 0.68);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  box-shadow: rgba(24, 202, 255, 0.13) 0px 0px 30px 3px !important;
  background-blend-mode: color;
  border: 1px solid rgba(177, 76, 226, 0.56) !important;
  outline: none;
}

.highlight-span:before {
  background-color: #0a0a0a;
  padding: 7px 20px;
  display: block;
  border-radius: 50px;
  border: 4px solid #3abb81;
  background-blend-mode: color;
}

.highlight-span:focus,
.highlight-span:hover {
  background-color: rgba(27, 94, 71, 0.68);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 4px solid #309a6a;
  outline: none;
}

/deep/ .columns:not(:last-child) {
  margin-bottom: 0px;
}

.stake-col-lg{
}

.stake-col-sm{
  padding-left: 0px;
}

.withdraw-col-lg{
  padding-right: 0px;
}

.withdraw-col-sm{
  padding-left: 0px;
}

.withdraw-btn-lg{
  border-bottom-left-radius: 1rem !important; border-top-left-radius: 1rem !important;
}
.withdraw-btn-sm{
  border-radius: 1rem;
}

.claim-btn-lg{
  border-bottom-right-radius: 1rem !important; border-top-right-radius: 1rem !important;
}

.claim-btn-sm{
  border-radius: 1rem;
}

/deep/ .minus {
  border-top-left-radius: 1rem;
}

/deep/ .b-numberinput input {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
  box-shadow: inset 0 30px 60px -12px rgba(50, 50, 93, 0.25), inset 0 18px 36px -18px rgba(0, 0, 0, 0.30);
}

/deep/ .button.is-buttonblue {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}


/deep/ .button.is-buttonblue:hover {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}

/* card background */
.home-card-bg {
  background: #ebeae5;
}

.inset-bg {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding-bottom: 2rem;
}

.inset-bg2:active {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  width: 100%;
  height: 30px;
  position: relative;
  border-radius: 1rem;
  align-self: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 4px;
}


.col-title {
  color: #9573ba;
  font-size: 12px;
  font-family: Source Code Pro,monospace !important;
}

.col-title2 {
  color: #36c0ea !important;
  font-size: 12px !important;
  font-family: Source Code Pro,monospace !important;
}

.pending-title {
  color: rgb(120 161 235);
  font-size: 14px;
}

.pool-control-title{
  color: #49566c;
  font-size: 14px;
}

.pool-control-title:hover{
  color: #3abb81;
  font-size: 14px;
}

.pending-stat {
  color: #3cea36;
  font-size: 1em;
}

.pending-col {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: Source Code Pro,monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.pending-col2 {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: Source Code Pro,monospace !important;
}

.col-shadow1 {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider {
  font-size: 12px;
}

.pool-name {
  font-size: 16px;
  color: rgb(120 161 235);
  font-family: Source Code Pro,monospace !important;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: #293038;
}

.pool-main {
  background-color: #293038;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.pool-item:hover {
  background-color: #333b4c;
  border: 1px solid rgba(3, 255, 150, 0.38) !important;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
}

.poolselect-bg {
  background-color: #293038 !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

/deep/ .tabs.is-toggle li.is-active a {
  background-color: #314b6a;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #3abb81;
  z-index: 1;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .tabs.is-toggle a {
  border-color: #575f85;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 0;
  position: relative;
  font-family: Source Code Pro,monospace !important;
  color: #195da7;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
  color: #00b89c;
}


.pools-card {
  padding-top: .5rem;
  color: #0d64c6;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
}

/deep/ .field.has-addons .control:first-child .control .button, .field.has-addons .control:first-child .control .input, .field.has-addons .control:first-child .control .select select {
  border-radius: 0 !important;
}

/deep/ .field.has-addons:first-child  .control:first-child .control:first-child  .button:first-child , .field.has-addons:first-child  .control:first-child .control:first-child  .input:first-child , .field.has-addons:first-child .control:first-child .control:first-child  .select:first-child  select:first-child{
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.stake-btn-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.stake-btn-2 {
  font-size: 14px !important;
  max-height: 40px;
  padding-left: 12px !important;
}

.btn {
  color: #87b6e5;
  background: rgb(34 77 118);
  background-repeat: no-repeat;
  font-family: Source Code Pro,monospace !important;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.btn:focus,
.btn:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    margin-bottom: 30px;
  }
  .small-pool{
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0 !important;
  }
}



</style>
<style lang="scss" scoped>

.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro,monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

</style>
