<template>
  <div v-if="l_pool_apy > 0 || l_stake_pending_rewards > 0" v-bind:class="[flipped ? 'flip-container flipped' : 'flip-container']"
       class="column is-centered mb-4 mt-5">
    <div class="columns is-centered pools-card" >
      <div class="column is-1"></div>
      <div
          class="column pool-item is-centered is-align-self-center" v-bind:class="[l_stake_balance > 0 ? 'highlight-span' : '']"
      >
        <accordion>
          <accordion-item :class="$screen.width < 770 ? 'small-pool' : ''">
            <!-- This slot will handle the title/header of the accordion and is the part you click on -->
            <template slot="accordion-trigger">
              <div class="columns">
                <div class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Staking Pool: '+pool_name">
                  <div class="pool-name">{{ pool_name }}</div>
                  <div v-if="lp_platform !== '0'" class="provider">platform: {{ lp_platform }}</div>
                  </b-tooltip>
                </div>
                  <div class="column is-3 is-align-self-center">
                    <b-tooltip type="is-dark" :label="this.pool_contract === 'factory-stake-factorytokens-fungiv2' ? 'Stakers stake '+pool_stake_token+':'+sf_poolname+' token in this pool.' : 'Stakers stake '+pool_stake_token+' token in this pool.' ">
                    <div class="col-title">STAKE</div>
                    <div v-if="sf_poolname === '0'">{{ coinDisplayName(pool_stake_token) }}</div>
                    <div v-if="sf_poolname !== '0'">{{ pool_stake_token }}:{{sf_poolname}}</div>
                    </b-tooltip>
                  </div>
                <div class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Stakers yield '+coinDisplayName(pool_reward_token)+' from staking in this pool.'">
                  <div class="col-title">YIELD</div>
                  <div>{{ coinDisplayName(pool_reward_token) }}</div>
                  </b-tooltip>
                </div>
                <div class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Annual Percentage Yield: '+l_pool_apy+'%'">
                  <div class="col-title">APY</div>
                  <div>{{ l_pool_apy }}%</div>
                  </b-tooltip>
                </div>
                <div v-if="l_stake_pending_rewards > 0.0" class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'You have: '+l_stake_pending_rewards+' pending rewards to claim.'">
                  <div class="pending-title">PENDING</div>
                  <div class="pending-title">{{ l_stake_pending_rewards }}</div>
                  </b-tooltip>
                </div>
                <div @click="clickPoolControls" class="column is-align-self-center pending-col2 btm" :style="$screen.width > 770 ? 'max-width: 50px' : ''">
                  <b-tooltip type="is-dark" :label="'Pool Info'">
                  <div class="pool-control-title"><div class="pool-control-title"><b-icon
                      pack="fas"
                      icon="info-circle"
                      size="is-small" style="position: relative; top:2px; ">
                  </b-icon>️</div></div>
                  </b-tooltip>

                </div>
                <div @click="clickPoolWindow" class="column is-align-self-center pending-col2 btm" :style="$screen.width > 770 ? 'max-width: 50px' : ''">
                  <b-tooltip type="is-dark" :label="'Pool Page'">
                  <div class="pool-control-title"><div class="pool-control-title"><b-icon
                      pack="fas"
                      icon="window-maximize"
                      size="is-small" style="position: relative; top:2px; ">
                  </b-icon>️</div></div>
                  </b-tooltip>

                </div>
              </div>
            </template>
            <!-- pool accordion content -->
            <template slot="accordion-content">
              <div class="columns mt-5 mb-3 pending-col leatherbg"
                   style="position: relative; top: 10px; background-color: rgba(38,43,56,0.29); border-radius: 1rem;">
<!--                <div class="column is-2 is-align-self-center">-->
<!--                  <div class="col-title">END TIME:</div>-->
<!--                  <div>{{ pool_endtime }}</div>-->
<!--                </div>-->
                <div class="column is-2 is-align-self-center">
                  <b-tooltip type="is-dark" :label="this.pool_contract === 'factory-stake-factorytokens-fungiv2' ? 'You have '+l_wallet_stake_balance+' '+pool_stake_token+':'+sf_poolname : 'You have '+l_wallet_stake_balance+' '+pool_stake_token ">
                  <div class="col-title2">WALLET:</div>
                  <div style="color: #3abb81">{{ l_wallet_stake_balance }}</div>
                  </b-tooltip>
                </div>
                <div class="column is-2 is-align-self-center">
                  <b-tooltip type="is-dark" :label="this.pool_contract === 'factory-stake-factorytokens-fungiv2' ? 'You have deposited '+l_stake_balance+' '+pool_stake_token+':'+sf_poolname : 'You have deposited '+l_stake_balance+' '+pool_stake_token ">
                  <div class="col-title2">DEPOSITED:</div>
                  <div style="color: #3abb81">{{ l_stake_balance }}</div>
                  </b-tooltip>
                </div>
                <div class="column is-2 is-align-self-center">
                  <b-tooltip type="is-dark" :label="this.pool_contract === 'factory-stake-factorytokens-fungiv2' ? 'You have claimed a total of '+l_stake_rewards+' '+pool_stake_token+':'+sf_poolname : 'You have claimed a total of '+l_stake_rewards+' '+pool_stake_token ">
                  <div class="col-title2">CLAIMED:</div>
                  <div style="color: #3abb81">{{ l_stake_rewards }}</div>
                  </b-tooltip>
                </div>
                <div v-if="pool_locktime !== '0'" class="column is-1 is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Staked funds are locked in this pool for '+pool_locktime+' seconds before they can be withdrawn'">
                  <div class="col-title2">LOCK TIME:</div>
                  <div style="color: #3abb81">{{ pool_locktime }}</div>
                  </b-tooltip>
                </div>
                <div v-if="pool_claimtime !== '0'" class="column is-1 is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Rewards can only be claimed once every '+pool_claimtime+' seconds from this pool.'">
                  <div class="col-title2">CLAIM TIME:</div>
                  <div style="color: #3abb81">{{ pool_claimtime }}</div>
                  </b-tooltip>
                </div>

                <!-- if connected pool buttons-->
                <div v-if="accountApproved === true && is_pool_owner === false"
                     class="column is-align-self-center stake-btn-col" style="margin-left: 4%; margin-right: 1%;">
                  <div v-if="l_stake_balance <= 0" class="columns">
                    <div class="column"  v-bind:class="[$screen.width > 768 ? 'stake-col-lg' : 'stake-col-sm']">
                      <div class="field-body">
                        <div class="field has-addons">
                          <p class="control is-expanded">
                            <b-numberinput expanded size="is-small" v-model="stakeAmount" min="0" type="is-buttonblue" controls-alignment="left"
                                           controls-position="compact"></b-numberinput>
                          </p>
                          <div @click="clickStake(pool_contract)" class="btn stake-btn-2"
                               style="border-bottom-right-radius: 1rem; border-top-right-radius: 1rem; width: 35%; overflow: hidden;">
                            <p class="font5">STAKE</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="l_stake_balance > 0" class="columns">
                    <div class="column" v-bind:class="[$screen.width > 768 ? 'withdraw-col-lg' : 'withdraw-col-sm']">
                      <div @click="clickWithdraw(pool_contract)"  class="btn btn-lg stake-btn"
                           v-bind:class="[$screen.width > 768 ? 'withdraw-btn-lg' : 'withdraw-btn-sm']">
                        <p>WITHDRAW</p>
                      </div>
                    </div>
                    <div class="column" style="padding-left: 0px;">
                      <div @click="clickClaimRewards(pool_contract)" class="btn btn-lg stake-btn"
                           v-bind:class="[$screen.width > 768 ? 'claim-btn-lg' : 'claim-btn-sm']">
                        <p>CLAIM</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- if not connected buttons-->
                <div v-if="accountApproved === false" class="column is-offset-1 is-align-self-center stake-btn-col">
                  <div class="btn btn-lg stake-btn" style="border-radius: 1rem !important;">
                    <p>CONNECT WALLET</p>
                  </div>
                </div>

                <div v-if="accountApproved === true && is_pool_owner === true" class="column is-offset-1 is-align-self-center stake-btn-col">
                  <div @click="clickPoolControls" class="btn btn-lg stake-btn" style="border-radius: 1rem !important;">
                    <p>POOL ADMIN</p>
                  </div>
                </div>
              </div>
            </template>
          </accordion-item>
        </accordion>


      </div>
      <div class="column is-1"></div>
    </div>

  </div>
</template>

<script>
//import vue store variables to this page
import {mapGetters, mapState} from "vuex";
import Accordion from "./accordion.vue";
import AccordionItem from "./accordion-item";

export default {
  name: "PoolCard",
  components: {
    Accordion,
    AccordionItem
  },
  props: {
    accountApproved: {
      type: Boolean,
      default: false,
    },
    pool_name: {
      type: String,
      default: "0",
    },
    pool_id: {
      type: [String, Number],
      default: "0"
    },
    user_account: {
      type: String,
      default: "0",
    },
    pool_apy: {
      type: [String, Number],
      default: "0",
    },
    pool_balance: {
      type: [String, Number],
      default: "0",
    },
    pool_claim_wait_seconds: {
      type: [String, Number],
      default: "0",
    },
    pool_end_time: {
      type: [String, Object],
      default: "0",
    },
    pool_has_vesting_connection: {
      type: Boolean,
      default: false,
    },
    pool_initialized: {
      type: Boolean,
      default: false,
    },
    pool_paid: {
      type: [String, Number],
      default: "0",
    },
    pool_contract: {
      type: String,
      default: "0",
    },
    pool_stake_token: {
      type: [String, Object],
      default: "0",
    },
    pool_reward_token: {
      type: [String, Object],
      default: "0",
    },
    pool_stakers: {
      type: [String, Number],
      default: "0",
    },
    pool_start_time: {
      type: [String, Object],
      default: "0",
    },
    pool_tokens_locked: {
      type: [String, Number],
      default: "0",
    },
    pool_vesting_pool_id: {
      type: [String, Number],
      default: "0",
    },
    pool_withdraw_duration: {
      type: [String, Object],
      default: "0",
    },
    stake_last_claimed: {
      type: [String, Object],
      default: "0",
    },
    stake_last_updated: {
      type: [String, Object],
      default: "0",
    },
    stake_last_withdraw: {
      type: [String, Object],
      default: "0",
    },
    stake_start_time: {
      type: [String, Object],
      default: "0",
    },
    stake_balance: {
      type: [String, Number],
      default: "0",
    },
    stake_rewards: {
      type: [String, Number],
      default: "0",
    },
    stake_pending_rewards: {
      type: [String, Number],
      default: "0",
    },
    token_image: {
      type: String,
      default: "0",
    },
    is_pool_owner: {
      type: Boolean,
      default: false,
    },
    lp_platform: {
      type: String,
      default: "0",
    },
    pool_endtime: {
      type: String,
      default: "0",
    },
    pool_locktime: {
      type: String,
      default: "0",
    },
    pool_claimtime: {
      type: String,
      default: "0",
    },
    sf_poolname: {
      type: String,
      default: "0",
    },
    wallet_stake_balance: {
      type: [String, Number],
      default: "0",
    },


  },
  data: function () {
    return {
      debug: false,
      flipped: false,
      showStakeControls: false,
      stakeAmount: 0.0,
      isMakingPoolCardTransaction: false,
      isMakingTransaction: false,
      isStaking: false,
      isStaking_poolid: null,
      isStaking_amount: null,
      isWithdrawing: false,
      isWithdrawing_poolid: null,
      isWithdrawing_amount: null,
      isClaiming: false,
      isClaiming_poolid: null,
      isClaiming_amount: null,
      //Props
      l_wallet_stake_balance: this.wallet_stake_balance,
      l_stake_pending_rewards: this.stake_pending_rewards,
      l_pool_tokens_locked: this.pool_tokens_locked,
      lpool_endtime: this.pool_endtime,
      l_stake_rewards: this.stake_rewards,
      l_pool_apy: this.pool_apy,
      l_stake_balance: this.stake_balance,
      //Fungiv2 Fungiv2
      allPoolsFactoryStakeFungiv2: [],
      allActivePoolsFactoryStakeFungiv2:[],
      allActivePoolsIds: [],
      allUserPoolsFactoryStakeFungiv2: [],
      allUserPoolsIds:[],
      allPoolsToShowFactoryStakeFungiv2: [],
      allPoolIdsUserIsNotStakedIn:[],
      //Tokens Fungiv2
      allPoolsToShowFactoryStakeAswapTokensFungiv2: [],
      allPoolsToShowFactoryStakeFactoryTokensFungiv2: [],
      allPoolsToShowFactoryStakeKDSwapTokensFungiv2: [],
      allPoolsToShowFactoryStakeKaddexTokensFungiv2: [],
      allPoolsFactoryStakeAswapTokensFungiv2: [],
      allPoolsFactoryStakeFactoryTokensFungiv2: [],
      allPoolsFactoryStakeKDSwapTokensFungiv2: [],
      allPoolsFactoryStakeKaddexTokensFungiv2: [],
      allActivePoolsFactoryStakeAswapTokensFungiv2:[],
      allActivePoolsFactoryStakeFactoryTokensFungiv2:[],
      allActivePoolsFactoryStakeKDSwapTokensFungiv2:[],
      allActivePoolsFactoryStakeKaddexTokensFungiv2:[],
      allActivePoolsIdsAswapTokensFungiv2: [],
      allActivePoolsIdsFactoryTokensFungiv2: [],
      allActivePoolsIdsKDSwapTokensFungiv2: [],
      allActivePoolsIdsKaddexTokensFungiv2: [],
      allUserPoolsFactoryStakeAswapTokensFungiv2: [],
      allUserPoolsFactoryStakeFactoryTokensFungiv2: [],
      allUserPoolsFactoryStakeKDSwapTokensFungiv2: [],
      allUserPoolsFactoryStakeKaddexTokensFungiv2: [],
      allUserPoolsIdsAswapTokensFungiv2:[],
      allUserPoolsIdsFactoryTokensFungiv2:[],
      allUserPoolsIdsKDSwapTokensFungiv2:[],
      allUserPoolsIdsKaddexTokensFungiv2:[],
      allPoolIdsUserIsNotStakedInAswapTokensFungiv2:[],
      allPoolIdsUserIsNotStakedInFactoryTokensFungiv2:[],
      allPoolIdsUserIsNotStakedInKDSwapTokensFungiv2:[],
      allPoolIdsUserIsNotStakedInKaddexTokensFungiv2:[],
    };
  },
  watch: {
    //lets watch our transactionPolling variable
    transactionPolling: async function (newValue, oldValue) {
      if (this.debug) {
        console.log("poolcard getTransactionPolling newValue: " + newValue);
        console.log("poolcard getTransactionPolling oldValue: " + oldValue);
      }


      if (newValue === true && this.isMakingPoolCardTransaction === true) {

        this.isMakingTransaction = true;

        let message1 = "Confirming new blockchain transaction";
        if (this.isStaking === true) {
          message1 = "Staking " + this.isStaking_amount + " in pool "+this.isStaking_poolid;
        } else if (this.isClaiming === true) {
          message1 = "Claiming " + this.isClaiming_amount + " rewards from pool "+this.isClaiming_poolid;
        } else if (this.isWithdrawing === true) {
          message1 = "Withdrawing " + this.isWithdrawing_amount + " from pool "+this.isWithdrawing_poolid;
        }

        this.$buefy.toast.open({
          duration: 10000,
          message: message1,
          type: "is-info",
          pauseOnHover: true
        });

      } else if (newValue === false && this.isMakingPoolCardTransaction === true) {

        this.isMakingTransaction = false;
        this.isMakingPoolCardTransaction = false;


        let message = "Transaction succeeded";
        if (this.isStaking === true) {
          message = "Staked " + this.isStaking_amount + " in pool "+this.isStaking_poolid;
        } else if (this.isClaiming === true) {
          message = "Claimed " + this.isClaiming_amount + " rewards from pool "+this.isClaiming_poolid;
        } else if (this.isWithdrawing === true) {
          message = "Withdrew " + this.isWithdrawing_amount + " from pool "+this.isWithdrawing_poolid;
        }


        //Transaction success toast
        if (this.getTransactionFailed() === false) {

          this.$buefy.toast.open({
            duration: 10000,
            message: message,
            position: "is-top",
            type: "is-success"
          });

          this.isStaking = false;
          this.isClaiming = false;
          this.isWithdrawing = false;
          await this.rescanPool();
        }else if (this.getTransactionFailed() === true) {

          let message2 = "Transaction failed";
          if (this.isStaking === true) {
            message2 = "Failed to stake into pool "+this.isStaking_poolid;
          } else if (this.isClaiming === true) {
            message2 = "Failed to claim from pool "+this.isClaiming_poolid;
          } else if (this.isWithdrawing === true) {
            message2 = "Failed to withdraw from pool "+this.isWithdrawing_poolid;
          }

          this.$buefy.toast.open({
            duration: 10000,
            message: message2,
            position: "is-top",
            type: "is-danger"
          });

          this.isStaking = false;
          this.isClaiming = false;
          this.isWithdrawing = false;

          await this.rescanPool();
        }
      }



    }
  },
    computed: {
    //import state variables from our vue store
    ...mapState("accounts", ["transactionPolling"]),

  },
  methods: {
    ...mapGetters("accounts", ["getAccountExists", "getTransactionPolling", "getAccountConfirmed", "getCurrentSignRequest", "getAllPoolsFactoryStakeFungiv2", "getUserPoolsFactoryStakeFungiv2",
      "getAllCrowdFundingPools", "getUserCrowdFundingPools", "getAllPoolsFactoryStakeFungiv2Polyfungiv1", "getUserPoolsFactoryStakeFungiv2Polyfungiv1", "getAllPoolsFactoryStakeAswapTokensFungiv2",
      "getUserPoolsFactoryStakeAswapTokensFungiv2", "getAllPoolsFactoryStakeFactoryTokensFungiv2", "getUserPoolsFactoryStakeFactoryTokensFungiv2", "getAllPoolsFactoryStakePolyfungiv1",
      "getUserPoolsFactoryStakePolyfungiv1", "getAllPoolsFactoryStakePolyfungiv1Fungiv2", "getUserPoolsFactoryStakePolyfungiv1Fungiv2", "getAllPoolsFactoryStakePolyfungiv2",
      "getUserPoolsFactoryStakePolyfungiv2", "getAllTreasuryAccountsData", "getTransactionFailed"]),

    coinDisplayName(coin){
      let name = coin;
      if(coin === 'coin'){
        name = 'KDA';
      }else if(coin === 'free.stake-token'){
        name = 'STEAK';
      }
      return name;
    },

    number_test(n) {
      const result = (n - Math.floor(n)) !== 0;

      if (result) {
        if (this.debug) {
          console.log("Contains Decimal Place");
        }
        return true;
      } else {
        if (this.debug) {
          console.log("No decimal place");
        }
        return false;
      }

    },

    coinName(namespace, name){
      let newnamespace = namespace+'.';
      let newname = name;
      if(namespace === null || namespace === 'null'){
        newnamespace = '';
      }
      if(name === null || name === 'null'){
        newname = '';
      }
      return newnamespace+newname;
    },

    reduceBalance(balance, prec = 6){
      if (balance) {
        if (balance.int) balance = balance.int;
        if (balance.decimal) balance = balance.decimal;

        if (parseFloat(balance) % 1 === 0) {
          return parseInt(balance);
        }

        return Math.trunc(parseFloat(balance) * Math.pow(10, prec)) / Math.pow(10, prec);
      }

      if (balance === 0) return 0;
    },

    async clickWithdraw(pool_contract){

      const txpolling = this.getTransactionPolling();

      if(this.debug){
        console.log("TXPOLLING");
        console.log(txpolling);
      }

      if(txpolling === false){

        if(this.debug){
          console.log("CLAIMING POOL REWARDS")
          console.log(pool_contract);
        }

        const payload = {
          poolID: this.pool_id,
          accountName: this.user_account,
          moduleName: this.pool_stake_token
        }

        if(this.debug){
          console.log(payload);
        }

        this.isWithdrawing = true;
        this.isWithdrawing_poolid = this.pool_name;
        this.isWithdrawing_amount = this.l_stake_pending_rewards.toString()+" "+this.pool_reward_token+" and "+this.l_stake_balance+" "+this.pool_stake_token;
        this.isMakingPoolCardTransaction = true;

        this.isStaking = false;
        this.isClaiming = false;



        if(pool_contract === "factory-stake-fungiv2"){
          this.$emit('showTxModal', true);
          if(this.l_pool_tokens_locked - this.l_stake_balance <= 0){
            this.$emit('reloadPage', true);
          }
          await this.$store.dispatch("accounts/withdrawFactoryStakeFungiv2", payload);
        }else if(pool_contract === "factory-stake-tokens-fungiv2"){
          this.$emit('showTxModal', true);
          if(this.l_pool_tokens_locked - this.l_stake_balance <= 0){
            this.$emit('reloadPage', true);
          }
          await this.$store.dispatch("accounts/withdrawFactoryStakeAswapTokensFungiv2", payload);
        } else if(pool_contract === "factory-stake-factorytokens-fungiv2"){
          this.$emit('showTxModal', true);
          if(this.l_pool_tokens_locked - this.l_stake_balance <= 0){
            this.$emit('reloadPage', true);
          }
          await this.$store.dispatch("accounts/withdrawFactoryStakeFactoryTokensFungiv2", payload);
        }

      }else{
        alert("Please wait for your current transaction to complete.");
      }




    },

    async clickClaimRewards(pool_contract){

      const txpolling = this.getTransactionPolling();

      if(this.debug){
        console.log("TXPOLLING");
        console.log(txpolling);
      }

      if(txpolling === false){

        if(this.debug){
          console.log("CLAIMING POOL REWARDS")
          console.log(pool_contract);
        }

        const payload = {
          poolID: this.pool_id,
          accountName: this.user_account,
          moduleName: this.pool_stake_token
        }

        if(this.debug){
          console.log(payload);
        }

        this.isClaiming = true;
        this.isClaiming_poolid = this.pool_name;
        this.isClaiming_amount = this.l_stake_pending_rewards.toString()+" "+this.pool_reward_token;
        this.isMakingPoolCardTransaction = true;

        this.isStaking = false;
        this.isWithdrawing = false;

        if(pool_contract === "factory-stake-fungiv2"){
          this.$emit('showTxModal', true);
          await this.$store.dispatch("accounts/claimRewardsFactoryStakeFungiv2", payload);
        }else if(pool_contract === "factory-stake-tokens-fungiv2"){
          this.$emit('showTxModal', true);
          await this.$store.dispatch("accounts/claimRewardsFactoryStakeAswapTokensFungiv2", payload);
        }else if(pool_contract === "factory-stake-factorytokens-fungiv2"){
          this.$emit('showTxModal', true);
          await this.$store.dispatch("accounts/claimRewardsFactoryStakeFactoryTokensFungiv2", payload);
        }

      }else {
        alert("Please wait for your current transaction to complete.");
      }




    },

    async clickStake(pool_contract) {

      const txpolling = this.getTransactionPolling();

      if(this.debug){
        console.log("TXPOLLING");
        console.log(txpolling);
      }

      if(txpolling === false){

        if(this.stakeAmount > 0){

          if(this.l_wallet_stake_balance > 0){

            if(this.l_wallet_stake_balance >= this.stakeAmount){

              if(this.debug){
                console.log("CREATING NEW STAKE")
                console.log(pool_contract);
              }

              const payload = {
                poolID: this.pool_id,
                amount: this.stakeAmount,
                accountName: this.user_account,
                moduleName: this.pool_stake_token,
                stakeToken: this.pool_stake_token,
              }

              if(pool_contract === "factory-stake-tokens-fungiv2") {
                payload.moduleName = 'free.tokens-reference'
              }

              if(pool_contract === "factory-stake-factorytokens-fungiv2") {
                payload.moduleName = 'free.factory-tokens3';
                payload.stakeToken = this.pool_stake_token+":"+this.sf_poolname;
              }

              if(this.debug){
                console.log(payload);
              }

              this.isStaking = true;
              this.isStaking_poolid = this.pool_name;
              this.isStaking_amount = this.stakeAmount+ " "+this.pool_stake_token;
              this.isMakingPoolCardTransaction = true;
              this.isClaiming = false;
              this.isWithdrawing = false;

              if(pool_contract === "factory-stake-fungiv2"){
                this.$emit('showTxModal', true);
                await this.$store.dispatch("accounts/stakeFactoryStakeFungiv2", payload);
              } else if(pool_contract === "factory-stake-tokens-fungiv2"){
                this.$emit('showTxModal', true);
                await this.$store.dispatch("accounts/stakeFactoryStakeAswapTokensFungiv2", payload);
              } else if(pool_contract === "factory-stake-factorytokens-fungiv2"){
                this.$emit('showTxModal', true);
                await this.$store.dispatch("accounts/stakeFactoryStakeFactoryTokensFungiv2", payload);
              }

            }else {
              alert("Insufficient funds.")
            }




          }else {
            alert("Insufficient funds.")
          }



        }else {
          alert("You must Stake > 0")
        }

      }else{
        alert("Please wait for your current transaction to complete.");
      }






    },

    async clickPoolControls(){
      let routeData = this.$router.resolve({path: "/pool-nfo/" + this.pool_contract +"/"+this.pool_id});
      window.open(routeData.href, "_blank");
    },

    async clickPoolWindow(){
      let routeData = this.$router.resolve({path: "/stake/" + this.pool_id +"/"+this.pool_contract});
      window.open(routeData.href, "_blank");
    },

    async rescanPool(){

      //Fungiv2 Fungiv2 reset
      this.allPoolsToShowFactoryStakeFungiv2 = [];
      this.allActivePoolsIds = [];
      this.allUserPoolsIds = [];
      this.allPoolIdsUserIsNotStakedIn = [];
      this.allPoolsFactoryStakeFungiv2 = [];
      this.allUserPoolsFactoryStakeFungiv2 = [];
      this.allActivePoolsFactoryStakeFungiv2 = [];

      //LP Tokens fungiv2 reset
      this.allPoolsToShowFactoryStakeAswapTokensFungiv2 = [];
      this.allPoolsToShowFactoryStakeFactoryTokensFungiv2 = [];
      this.allPoolsToShowFactoryStakeKDSwapTokensFungiv2= [];
      this.allPoolsToShowFactoryStakeKaddexTokensFungiv2= [];
      this.allPoolsFactoryStakeAswapTokensFungiv2= [];
      this.allPoolsFactoryStakeFactoryTokensFungiv2= [];
      this.allPoolsFactoryStakeKDSwapTokensFungiv2= [];
      this.allPoolsFactoryStakeKaddexTokensFungiv2= [];
      this.allActivePoolsFactoryStakeAswapTokensFungiv2=[];
      this.allActivePoolsFactoryStakeFactoryTokensFungiv2=[];
      this.allActivePoolsFactoryStakeKDSwapTokensFungiv2=[];
      this.allActivePoolsFactoryStakeKaddexTokensFungiv2=[];
      this.allActivePoolsIdsAswapTokensFungiv2= [];
      this.allActivePoolsIdsFactoryTokensFungiv2= [];
      this.allActivePoolsIdsKDSwapTokensFungiv2= [];
      this.allActivePoolsIdsKaddexTokensFungiv2= [];
      this.allUserPoolsFactoryStakeAswapTokensFungiv2= [];
      this.allUserPoolsFactoryStakeFactoryTokensFungiv2= [];
      this.allUserPoolsFactoryStakeKDSwapTokensFungiv2= [];
      this.allUserPoolsFactoryStakeKaddexTokensFungiv2= [];
      this.allUserPoolsIdsAswapTokensFungiv2=[];
      this.allUserPoolsIdsFactoryTokensFungiv2=[];
      this.allUserPoolsIdsKDSwapTokensFungiv2=[];
      this.allUserPoolsIdsKaddexTokensFungiv2=[];
      this.allPoolIdsUserIsNotStakedInAswapTokensFungiv2=[];
      this.allPoolIdsUserIsNotStakedInFactoryTokensFungiv2=[];
      this.allPoolIdsUserIsNotStakedInKDSwapTokensFungiv2=[];
      this.allPoolIdsUserIsNotStakedInKaddexTokensFungiv2=[];

      if(this.pool_contract === "factory-stake-factorytokens-fungiv2"){

        //FACTORY LP Tokens Fungiv2 bind
        //Bind local state for factory-stake-fungiv2
        this.allPoolsFactoryStakeFactoryTokensFungiv2 = this.getAllPoolsFactoryStakeFactoryTokensFungiv2();
        //Bind local state for user at factory-stake-fungiv2
        this.allUserPoolsFactoryStakeFactoryTokensFungiv2 = this.getUserPoolsFactoryStakeFactoryTokensFungiv2();

        //factorytokens fungiv2
        //Lets make a list of active factorytokens fungiv2 pools
        //First we got through the list of all factorytokens fungiv2 pools if its not empty
        if (this.allPoolsFactoryStakeFactoryTokensFungiv2.length > 0) {

          //We go through pools in reverse because its better
          for (let i = this.allPoolsFactoryStakeFactoryTokensFungiv2.length - 1; i >= 0; i--) {

            if (this.debug === true) {
              console.log("STEP 1) POOL ID:");
              console.log(this.allPoolsFactoryStakeFactoryTokensFungiv2[i].id)
            }

            //Now lets check and see if our pool is 'active' or not, if so, lets add it to our list of active pools
            if (this.allPoolsFactoryStakeFactoryTokensFungiv2[i].id === this.pool_id) {

              const allPoolsDetailsObject = {
                ...this.allPoolsFactoryStakeFactoryTokensFungiv2[i],
              }

              //Lets double check and make sure our active pool list doesnt already contain this new pool we are adding
              if (this.allActivePoolsFactoryStakeFactoryTokensFungiv2.includes(allPoolsDetailsObject) === false) {
                this.allActivePoolsFactoryStakeFactoryTokensFungiv2.push(allPoolsDetailsObject);
              }

              //Lets also add this pools id to a list of ids to show later
              if (this.allActivePoolsIdsFactoryTokensFungiv2.includes(this.allPoolsFactoryStakeFactoryTokensFungiv2[i].id) === false) {
                this.allActivePoolsIdsFactoryTokensFungiv2.push(this.allPoolsFactoryStakeFactoryTokensFungiv2[i].id);
              }

            }

          }

        }

        //Now we need to quickly get a list of all factorytokens fungiv2 pool ids the user has stakes in
        if (this.allUserPoolsFactoryStakeFactoryTokensFungiv2.length > 0) {
          for (let j = this.allUserPoolsFactoryStakeFactoryTokensFungiv2.length - 1; j >= 0; j--) {
            if (this.allUserPoolsIdsFactoryTokensFungiv2.includes(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[j].id) === false) {
              this.allUserPoolsIdsFactoryTokensFungiv2.push(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[j].id);
            }
          }
        }

        //Now lets filter out the factorytokens fungiv2 pools the user has staked in from the list of all factorytokens fungiv2 pools
        //This is our list of pools we must fill in with blank user data
        if (this.allActivePoolsIdsFactoryTokensFungiv2.length > 0) {
          for (let m = this.allActivePoolsIdsFactoryTokensFungiv2.length - 1; m >= 0; m--) {
            if (this.allUserPoolsIdsFactoryTokensFungiv2.includes(this.allActivePoolsIdsFactoryTokensFungiv2[m]) === false) {
              if (this.allPoolIdsUserIsNotStakedInFactoryTokensFungiv2.includes(this.allActivePoolsIdsFactoryTokensFungiv2[m]) === false) {
                this.allPoolIdsUserIsNotStakedInFactoryTokensFungiv2.push(this.allActivePoolsIdsFactoryTokensFungiv2[m]);
              }
            }
          }
        }

        //Now that we have a complete list of ACTIVE factorytokens fungiv2 pools, we should go through them!
        for (let l = this.allActivePoolsFactoryStakeFactoryTokensFungiv2.length - 1; l >= 0; l--) {

          if (this.debug === true) {
            console.log("STEP 2) POOL ID:");
            console.log(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l].id)
          }

          //Now lets go through the list of ids the user hasnt staked in
          if (this.allPoolIdsUserIsNotStakedInFactoryTokensFungiv2.length > 0) {

            for (let k = this.allPoolIdsUserIsNotStakedInFactoryTokensFungiv2.length - 1; k >= 0; k--) {

              //Do any of the active pools match ids from pools the user is not staked in?
              //If so lets dress them with blank stake information to display


              if (this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l].id === this.allPoolIdsUserIsNotStakedInFactoryTokensFungiv2[k]) {

                let t_pool_apy = this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['apy'];
                if (this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['apy'].decimal !== undefined) {
                  t_pool_apy = this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['apy'].decimal;
                }
                t_pool_apy = this.reduceBalance(t_pool_apy);

                let isPoolOwner = false;

                if (this.getAccountConfirmed() === true) {
                  if (this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['account'] === localStorage.getItem("accountName")) {
                    isPoolOwner = true;
                  }
                }

                let t_pool_endtime = this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['end-time'];



                if (this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['end-time'].time !== undefined) {
                  t_pool_endtime = this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['end-time'].time;
                }else if (this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['end-time'].timep !== undefined) {
                  t_pool_endtime = this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['end-time'].timep;
                }

                t_pool_endtime = t_pool_endtime.slice(0, 10);

                const t_pool_endtime_date = new Date(t_pool_endtime);
                const t_now = new Date();

                let canPush = true;

                const numberOfStakers = this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['stakers'];
                if(t_now > t_pool_endtime_date && numberOfStakers !== 0){
                  canPush = false;
                }

                if(canPush === false){
                  canPush = isPoolOwner;
                  if(this.debug){
                    console.log("canPush is now true due to user being pool owner")
                  }
                }

                let t_wallet_balance = 0;

                if(canPush === true){

                  const lptub_payload = {
                    tokenA: this.coinName(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['lp-token1']['refName']['namespace'], this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['lp-token1']['refName']['name']),
                    tokenB: this.coinName(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['lp-token2']['refName']['namespace'], this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['lp-token2']['refName']['name']),
                    poolName: this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['poolname']
                  };

                  t_wallet_balance = await this.$store.dispatch("accounts/getLPTokenUserBalance", lptub_payload);

                  if(this.debug){
                    console.log("lp t_wallet_balance updated");
                    console.log(t_wallet_balance);
                  }
                }


                if(this.debug){
                  console.log("lp t_wallet_balance");
                  console.log(t_wallet_balance);
                }


                if(this.debug){
                  console.log("t_pool_endtime");
                  console.log(t_pool_endtime);
                  console.log("this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]");
                  console.log(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]);
                  console.log("this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['end-time']");
                  console.log(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['end-time']);
                  console.log("this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['active']");
                  console.log(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['active']);
                  if(t_now > t_pool_endtime_date){
                    console.log("t_now is GREATER than t_pool_endtime_date");
                  }else {
                    console.log("t_now is LESS than t_pool_endtime_date");
                  }
                }



                const poolDetailObject = {
                  pool_name: this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l].name,
                  pool_id: this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l].id,
                  pool_apy: t_pool_apy,
                  pool_endtime: t_pool_endtime,
                  pool_stake_token: this.coinName(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['lp-token1']['refName']['namespace'], this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['lp-token1']['refName']['name'])+":"+this.coinName(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['lp-token2']['refName']['namespace'], this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['lp-token2']['refName']['name']),
                  pool_reward_token: this.coinName(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['reward-token']['refName']['namespace'], this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['reward-token']['refName']['name']),
                  pool_tokens_locked: this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['tokens-locked'],
                  stake_balance: 0,
                  stake_last_updated: 0,
                  stake_rewards: 0,
                  stake_last_claimed: 0,
                  stake_last_withdraw: 0,
                  stake_multiplier: 0,
                  stake_start_time: 0,
                  stake_pending_rewards: 0,
                  pool_contract: 'factory-stake-factorytokens-fungiv2',
                  stake_accountApproved: this.accountApproved,
                  is_pool_owner: isPoolOwner,
                  lp_platform: 'Stake Factory',
                  wallet_stake_balance: t_wallet_balance,
                  sf_poolname: this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['poolname'],
                }

                if (this.allPoolsToShowFactoryStakeFactoryTokensFungiv2.includes(poolDetailObject) === false && canPush === true) {
                  this.allPoolsToShowFactoryStakeFactoryTokensFungiv2.push(poolDetailObject);
                }
              }

            }
          }
        }

        //We now have our list to show on the screen filled with the factorytokens fungiv2 pool data the user has NOT staked in
        //Now, Lets fill that same list with the data from factorytokens fungiv2 pools the user has staked in
        if (this.allUserPoolsFactoryStakeFactoryTokensFungiv2.length > 0) {

          for (let n = this.allUserPoolsFactoryStakeFactoryTokensFungiv2.length - 1; n >= 0; n--) {

            if (this.debug === true) {
              console.log("STEP 3) POOL ID:");
              console.log(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n].id)
            }

            if(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n].id === this.pool_id){

              let t_pool_apy = this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['apy'];
              if (this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['apy'].decimal !== undefined) {
                t_pool_apy = this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['apy'].decimal;
              }
              t_pool_apy = this.reduceBalance(t_pool_apy, 2);

              let t_pool_endtime = this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['end-time'];

              if (this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['end-time'].time !== undefined) {
                t_pool_endtime = this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['end-time'].time;
              }else if (this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['end-time'].timep !== undefined) {
                t_pool_endtime = this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['end-time'].timep;
              }

              t_pool_endtime = t_pool_endtime.slice(0, 10);

              const t_pool_endtime_date = new Date(t_pool_endtime);
              const t_now = new Date();

              if(this.debug){
                console.log("t_pool_endtime");
                console.log(t_pool_endtime);
                console.log("this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]");
                console.log(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]);
                console.log("this.allUserPoolsFactoryStakeFactoryTokensFungiv2[l]['end-time']");
                console.log(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['end-time']);
                console.log("this.allUserPoolsFactoryStakeFactoryTokensFungiv2[l]['active']");
                console.log(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['active']);
                if(t_now > t_pool_endtime_date){
                  console.log("t_now is GREATER than t_pool_endtime_date");
                }else {
                  console.log("t_now is LESS than t_pool_endtime_date");
                }
              }

              let t_wallet_balance = 0;

              const lptub_payload = {
                tokenA: this.coinName(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['lp-token1']['refName']['namespace'], this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['lp-token1']['refName']['name']),
                tokenB: this.coinName(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['lp-token2']['refName']['namespace'], this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['lp-token2']['refName']['name']),
                poolName: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['poolname']
              };

              t_wallet_balance = await this.$store.dispatch("accounts/getLPTokenUserBalance", lptub_payload);

              if(this.debug){
                console.log("lp t_wallet_balance updated");
                console.log(t_wallet_balance);
              }


              if(this.debug){
                console.log("lp t_wallet_balance");
                console.log(t_wallet_balance);
              }

              const poolDetailObject = {
                pool_name: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n].name,
                pool_id: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n].id,
                pool_apy: t_pool_apy,
                pool_endtime: t_pool_endtime,
                pool_stake_token: this.coinName(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['lp-token1']['refName']['namespace'], this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['lp-token1']['refName']['name'])+":"+this.coinName(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['lp-token2']['refName']['namespace'], this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['lp-token2']['refName']['name']),
                pool_reward_token: this.coinName(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['reward-token']['refName']['namespace'], this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['reward-token']['refName']['name']),
                pool_tokens_locked: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['tokens-locked'],
                pool_contract: 'factory-stake-factorytokens-fungiv2',
                stake_accountApproved: this.accountApproved,
                stake_pending_rewards: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n].stake_pending_rewards,
                stake_balance: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['stake_balance'],
                pool_reward_token_id: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['reward-token-id'],
                stake_rewards: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['stake_rewards'],
                lp_platform: 'Stake Factory',
                sf_poolname: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['poolname'],
                wallet_stake_balance: t_wallet_balance
              }

              if (this.allPoolsToShowFactoryStakeFactoryTokensFungiv2.includes(poolDetailObject) === false) {
                this.allPoolsToShowFactoryStakeFactoryTokensFungiv2.push(poolDetailObject);
              }

            }



          }
        }

        //At this point we can now display our allPoolsToShowFactoryStakeFactoryTokensFungiv2 list on the screen
        if (this.debug) {
          console.log("allPoolsToShowFactoryStakeFactoryTokensFungiv2");
          console.log(this.allPoolsToShowFactoryStakeFactoryTokensFungiv2);
        }

        if (this.allPoolsToShowFactoryStakeFactoryTokensFungiv2.length > 0) {

          for (let t = this.allPoolsToShowFactoryStakeFactoryTokensFungiv2.length - 1; t >= 0; t--) {

          //this.l_pool_endtime = this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[t].pool_endtime;
          this.l_wallet_stake_balance = this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[t].wallet_stake_balance;
          this.l_pool_apy = this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[t].pool_apy;
          this.l_pool_tokens_locked = this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[t].pool_tokens_locked;
          this.l_stake_balance = this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[t].stake_balance;
          this.l_stake_pending_rewards = this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[t].stake_pending_rewards;
          this.l_stake_rewards = this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[t].stake_rewards;
          this.l_pool_tokens_locked = this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[t].pool_tokens_locked;

          }

        }


      }else if(this.pool_contract === "factory-stake-fungiv2"){


        //Fungiv2 Fungiv2 bind
        //Bind local state for factory-stake-fungiv2
        this.allPoolsFactoryStakeFungiv2 = this.getAllPoolsFactoryStakeFungiv2();
        //Bind local state for user at factory-stake-fungiv2
        this.allUserPoolsFactoryStakeFungiv2 = this.getUserPoolsFactoryStakeFungiv2();


        //////////////////////////////
        //fungiv2 fungiv2 Staking pools
        ///////////////////////////////

        //Lets make a list of active fungiv2 fungiv2 pools
        //First we got through the list of all fungiv2 fungiv2 pools if its not empty
        if (this.allPoolsFactoryStakeFungiv2.length > 0) {

          //We go through pools in reverse because its better
          for (let i = this.allPoolsFactoryStakeFungiv2.length - 1; i >= 0; i--) {

            if (this.debug === true) {
              console.log("STEP 1) POOL ID:");
              console.log(this.allPoolsFactoryStakeFungiv2[i].id)
            }

            //Now lets check and see if our pool is 'active' or not, if so, lets add it to our list of active pools
            if (this.allPoolsFactoryStakeFungiv2[i].id === this.pool_id) {

              const allPoolsDetailsObject = {
                ...this.allPoolsFactoryStakeFungiv2[i],
              }

              //Lets double check and make sure our active pool list doesnt already contain this new pool we are adding
              if (this.allActivePoolsFactoryStakeFungiv2.includes(allPoolsDetailsObject) === false) {
                this.allActivePoolsFactoryStakeFungiv2.push(allPoolsDetailsObject);
              }

              //Lets also add this pools id to a list of ids to show later
              if (this.allActivePoolsIds.includes(this.allPoolsFactoryStakeFungiv2[i].id) === false) {
                this.allActivePoolsIds.push(this.allPoolsFactoryStakeFungiv2[i].id);
              }

            }

          }

        }

        //Now we need to quickly get a list of all fungiv2 fungiv2 pool ids the user has stakes in
        if (this.allUserPoolsFactoryStakeFungiv2.length > 0) {
          for (let j = this.allUserPoolsFactoryStakeFungiv2.length - 1; j >= 0; j--) {
            if (this.allUserPoolsIds.includes(this.allUserPoolsFactoryStakeFungiv2[j].id) === false) {
              this.allUserPoolsIds.push(this.allUserPoolsFactoryStakeFungiv2[j].id);
            }
          }
        }

        //Now lets filter out the fungiv2 fungiv2 pools the user has staked in from the list of all fungiv2 fungiv2 pools
        //This is our list of pools we must fill in with blank user data
        if (this.allActivePoolsIds.length > 0) {
          for (let m = this.allActivePoolsIds.length - 1; m >= 0; m--) {
            if (this.allUserPoolsIds.includes(this.allActivePoolsIds[m]) === false) {
              if (this.allPoolIdsUserIsNotStakedIn.includes(this.allActivePoolsIds[m]) === false) {
                this.allPoolIdsUserIsNotStakedIn.push(this.allActivePoolsIds[m]);
              }
            }
          }
        }

        //Now that we have a complete list of ACTIVE fungiv2 fungiv2 pools, we should go through them!
        for (let l = this.allActivePoolsFactoryStakeFungiv2.length - 1; l >= 0; l--) {

          if (this.debug === true) {
            console.log("STEP 2) POOL ID:");
            console.log(this.allActivePoolsFactoryStakeFungiv2[l].id)
          }

          //Now lets go through the list of ids the user hasnt staked in
          if (this.allPoolIdsUserIsNotStakedIn.length > 0) {

            for (let k = this.allPoolIdsUserIsNotStakedIn.length - 1; k >= 0; k--) {

              //Do any of the active pools match ids from pools the user is not staked in?
              //If so lets dress them with blank stake information to display


              if (this.allActivePoolsFactoryStakeFungiv2[l].id === this.allPoolIdsUserIsNotStakedIn[k]) {

                let t_pool_apy = this.allActivePoolsFactoryStakeFungiv2[l]['apy'];
                if (this.allActivePoolsFactoryStakeFungiv2[l]['apy'].decimal !== undefined) {
                  t_pool_apy = this.allActivePoolsFactoryStakeFungiv2[l]['apy'].decimal;
                }
                t_pool_apy = this.reduceBalance(t_pool_apy);

                let isPoolOwner = false;

                if (this.getAccountConfirmed() === true) {
                  if (this.allActivePoolsFactoryStakeFungiv2[l]['account'] === localStorage.getItem("accountName")) {
                    isPoolOwner = true;
                  }
                }

                let t_pool_endtime = this.allActivePoolsFactoryStakeFungiv2[l]['end-time'];

                if (this.allActivePoolsFactoryStakeFungiv2[l]['end-time'].time !== undefined) {
                  t_pool_endtime = this.allActivePoolsFactoryStakeFungiv2[l]['end-time'].time;
                }else if (this.allActivePoolsFactoryStakeFungiv2[l]['end-time'].timep !== undefined) {
                  t_pool_endtime = this.allActivePoolsFactoryStakeFungiv2[l]['end-time'].timep;
                }

                t_pool_endtime = t_pool_endtime.slice(0, 10);

                const t_pool_endtime_date = new Date(t_pool_endtime);
                const t_now = new Date();

                let canPush = true;

                const numberOfStakers = this.allActivePoolsFactoryStakeFungiv2[l]['stakers'];
                if(t_now > t_pool_endtime_date && numberOfStakers !== 0){
                  canPush = false;
                }

                if(canPush === false){
                  canPush = isPoolOwner;
                }

                if(this.debug){
                  console.log("t_pool_endtime");
                  console.log(t_pool_endtime);
                  console.log("this.allActivePoolsFactoryStakeFungiv2[l]");
                  console.log(this.allActivePoolsFactoryStakeFungiv2[l]);
                  console.log("this.allActivePoolsFactoryStakeFungiv2[l]['end-time']");
                  console.log(this.allActivePoolsFactoryStakeFungiv2[l]['end-time']);
                  console.log("this.allActivePoolsFactoryStakeFungiv2[l]['active']");
                  console.log(this.allActivePoolsFactoryStakeFungiv2[l]['active']);
                  if(t_now > t_pool_endtime_date){
                    console.log("t_now is GREATER than t_pool_endtime_date");
                  }else {
                    console.log("t_now is LESS than t_pool_endtime_date");
                  }
                }

                let t_wallet_balance = 0;

                if(canPush === true){
                  const tub_payloadA = {
                    tokenA: this.coinName(this.allActivePoolsFactoryStakeFungiv2[l]['stake-token']['refName']['namespace'], this.allActivePoolsFactoryStakeFungiv2[l]['stake-token']['refName']['name']),
                  };
                  t_wallet_balance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadA);

                  if(this.debug){
                    console.log("fungiv2 t_wallet_balance updated");
                    console.log(t_wallet_balance);
                  }
                }

                if(this.debug){
                  console.log("fungiv2 t_wallet_balance");
                  console.log(t_wallet_balance);
                }


                const poolDetailObject = {
                  pool_name: this.allActivePoolsFactoryStakeFungiv2[l].name,
                  pool_id: this.allActivePoolsFactoryStakeFungiv2[l].id,
                  pool_apy: t_pool_apy,
                  pool_endtime: t_pool_endtime,
                  pool_stake_token: this.coinName(this.allActivePoolsFactoryStakeFungiv2[l]['stake-token']['refName']['namespace'], this.allActivePoolsFactoryStakeFungiv2[l]['stake-token']['refName']['name']),
                  pool_reward_token: this.coinName(this.allActivePoolsFactoryStakeFungiv2[l]['reward-token']['refName']['namespace'], this.allActivePoolsFactoryStakeFungiv2[l]['reward-token']['refName']['name']),
                  pool_tokens_locked: this.allActivePoolsFactoryStakeFungiv2[l]['tokens-locked'],
                  stake_balance: 0,
                  stake_last_updated: 0,
                  stake_rewards: 0,
                  stake_last_claimed: 0,
                  stake_last_withdraw: 0,
                  stake_multiplier: 0,
                  stake_start_time: 0,
                  stake_pending_rewards: 0,
                  pool_contract: 'factory-stake-fungiv2',
                  stake_accountApproved: this.accountApproved,
                  is_pool_owner: isPoolOwner,
                  wallet_stake_balance: t_wallet_balance
                }

                if (this.allPoolsToShowFactoryStakeFungiv2.includes(poolDetailObject) === false && canPush === true) {
                  this.allPoolsToShowFactoryStakeFungiv2.push(poolDetailObject);
                }
              }

            }
          }
        }

        //We now have our list to show on the screen filled with the fungiv2 fungiv2 pool data the user has NOT staked in
        //Now, Lets fill that same list with the data from fungiv2 fungiv2 pools the user has staked in
        if (this.allUserPoolsFactoryStakeFungiv2.length > 0) {

          for (let n = this.allUserPoolsFactoryStakeFungiv2.length - 1; n >= 0; n--) {

            if (this.debug === true) {
              console.log("STEP 3) POOL ID:");
              console.log(this.allUserPoolsFactoryStakeFungiv2[n].id)
            }

            if(this.allUserPoolsFactoryStakeFungiv2[n].id === this.pool_id){

              let t_pool_apy = this.allUserPoolsFactoryStakeFungiv2[n]['apy'];
              if (this.allUserPoolsFactoryStakeFungiv2[n]['apy'].decimal !== undefined) {
                t_pool_apy = this.allUserPoolsFactoryStakeFungiv2[n]['apy'].decimal;
              }
              t_pool_apy = this.reduceBalance(t_pool_apy, 2);

              let t_pool_endtime = this.allUserPoolsFactoryStakeFungiv2[n]['end-time'];

              if (this.allUserPoolsFactoryStakeFungiv2[n]['end-time'].time !== undefined) {
                t_pool_endtime = this.allUserPoolsFactoryStakeFungiv2[n]['end-time'].time;
              }else if (this.allUserPoolsFactoryStakeFungiv2[n]['end-time'].timep !== undefined) {
                t_pool_endtime = this.allUserPoolsFactoryStakeFungiv2[n]['end-time'].timep;
              }

              t_pool_endtime = t_pool_endtime.slice(0, 10);

              const t_pool_endtime_date = new Date(t_pool_endtime);
              const t_now = new Date();

              let t_wallet_balance = 0;

              const tub_payloadA = {
                tokenA: this.coinName(this.allUserPoolsFactoryStakeFungiv2[n]['stake-token']['refName']['namespace'], this.allUserPoolsFactoryStakeFungiv2[n]['stake-token']['refName']['name']),
              };
              t_wallet_balance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadA);

              if(this.debug){
                console.log("t_wallet_balance updated");
                console.log(t_wallet_balance);
              }


              if(this.debug){
                console.log("t_wallet_balance");
                console.log(t_wallet_balance);
              }

              if(this.debug){
                console.log("t_pool_endtime");
                console.log(t_pool_endtime);
                console.log("this.allUserPoolsFactoryStakeFungiv2[n]");
                console.log(this.allUserPoolsFactoryStakeFungiv2[n]);
                console.log("this.allUserPoolsFactoryStakeFungiv2[l]['end-time']");
                console.log(this.allUserPoolsFactoryStakeFungiv2[n]['end-time']);
                console.log("this.allUserPoolsFactoryStakeFungiv2[l]['active']");
                console.log(this.allUserPoolsFactoryStakeFungiv2[n]['active']);
                if(t_now > t_pool_endtime_date){
                  console.log("t_now is GREATER than t_pool_endtime_date");
                }else {
                  console.log("t_now is LESS than t_pool_endtime_date");
                }
              }

              const poolDetailObject = {
                pool_name: this.allUserPoolsFactoryStakeFungiv2[n].name,
                pool_id: this.allUserPoolsFactoryStakeFungiv2[n].id,
                pool_apy: t_pool_apy,
                pool_endtime: t_pool_endtime,
                pool_stake_token: this.coinName(this.allUserPoolsFactoryStakeFungiv2[n]['stake-token']['refName']['namespace'], this.allUserPoolsFactoryStakeFungiv2[n]['stake-token']['refName']['name']),
                pool_reward_token: this.coinName(this.allUserPoolsFactoryStakeFungiv2[n]['reward-token']['refName']['namespace'], this.allUserPoolsFactoryStakeFungiv2[n]['reward-token']['refName']['name']),
                pool_tokens_locked: this.allUserPoolsFactoryStakeFungiv2[n]['tokens-locked'],
                pool_contract: 'factory-stake-fungiv2',
                stake_accountApproved: this.accountApproved,
                stake_pending_rewards: this.allUserPoolsFactoryStakeFungiv2[n].stake_pending_rewards,
                stake_balance: this.allUserPoolsFactoryStakeFungiv2[n]['stake_balance'],
                stake_rewards: this.allUserPoolsFactoryStakeFungiv2[n]['stake_rewards'],
                wallet_stake_balance: t_wallet_balance
              }

              if (this.allPoolsToShowFactoryStakeFungiv2.includes(poolDetailObject) === false) {
                this.allPoolsToShowFactoryStakeFungiv2.push(poolDetailObject);
              }

            }



          }
        }

        //At this point we can now display our allPoolsToShowFactoryStakeFungiv2 list on the screen
        if (this.debug) {
          console.log("allPoolsToShowFactoryStakeFungiv2");
          console.log(this.allPoolsToShowFactoryStakeFungiv2);
        }

        if (this.allPoolsToShowFactoryStakeFungiv2.length > 0) {

          for (let t = this.allPoolsToShowFactoryStakeFungiv2.length - 1; t >= 0; t--) {

            //this.l_pool_endtime = this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[t].pool_endtime;
            this.l_wallet_stake_balance = this.allPoolsToShowFactoryStakeFungiv2[t].wallet_stake_balance;
            this.l_pool_apy = this.allPoolsToShowFactoryStakeFungiv2[t].pool_apy;
            this.l_pool_tokens_locked = this.allPoolsToShowFactoryStakeFungiv2[t].pool_tokens_locked;
            this.l_stake_balance = this.allPoolsToShowFactoryStakeFungiv2[t].stake_balance;
            this.l_stake_pending_rewards = this.allPoolsToShowFactoryStakeFungiv2[t].stake_pending_rewards;
            this.l_stake_rewards = this.allPoolsToShowFactoryStakeFungiv2[t].stake_rewards;

          }

        }

      }

    }

  },
};
</script>

<style scoped>


.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  border-radius: 0.5rem;
  color: #296992;
  margin-right: 10px !important;
}

.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 0.6);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}

.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}


.leatherbg {
  background: radial-gradient(#00000078 0%, transparent 10%) 0 0, radial-gradient(#0000005c 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 250, 250, 0) 15%, transparent 20%) 8px 9px;
  background-color: #2c243275 !important;
  background-size: 16px 16px;
  border: 2px solid #11426573;
  background-blend-mode: darken;
}

.small-pool {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 3%;
}

::v-deep .accordion__item {
    cursor: pointer;
    padding: 10px 40px 10px 40px;
    border-bottom: 1px solid #ebebeb;
    position: relative;
}

/deep/ .accordion__item {
  cursor: pointer;
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

.accordion__item {
  cursor: pointer;
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

.highlight-span {
  background-color: rgba(39, 99, 74, 0.68);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  background-blend-mode: color;
  border: 1px solid rgba(177, 76, 226, 0.56) !important;
  outline: none;
}

.highlight-span:before {
  background-color: #0a0a0a;
  padding: 7px 20px;
  display: block;
  border-radius: 50px;
  border: 4px solid #3abb81;
  background-blend-mode: color;
}

.highlight-span:focus,
.highlight-span:hover {
  background-color: rgba(27, 94, 71, 0.68);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 4px solid #309a6a;
  outline: none;
}

/deep/ .columns:not(:last-child) {
  margin-bottom: 0px;
}

.stake-col-lg{
}

.stake-col-sm{
  padding-left: 0px;
}

.withdraw-col-lg{
  padding-right: 0px;
}

.withdraw-col-sm{
  padding-left: 0px;
}

.withdraw-btn-lg{
  border-bottom-left-radius: 1rem !important; border-top-left-radius: 1rem !important;
}
.withdraw-btn-sm{
  border-radius: 1rem;
}

.claim-btn-lg{
  border-bottom-right-radius: 1rem !important; border-top-right-radius: 1rem !important;
}

.claim-btn-sm{
  border-radius: 1rem;
}

/deep/ .minus {
  border-top-left-radius: 1rem;
}

/deep/ .b-numberinput input {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
  box-shadow: inset 0 30px 60px -12px rgba(50, 50, 93, 0.25), inset 0 18px 36px -18px rgba(0, 0, 0, 0.30);
}

/deep/ .button.is-buttonblue {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}


/deep/ .button.is-buttonblue:hover {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}

/* card background */
.home-card-bg {
  background: #ebeae5;
}

.inset-bg {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding-bottom: 2rem;
}

.inset-bg2:active {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  width: 100%;
  height: 30px;
  position: relative;
  border-radius: 1rem;
  align-self: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 4px;
}


.col-title {
  color: #9573ba;
  font-size: 12px;
  font-family: Source Code Pro,monospace !important;
}

.col-title2 {
  color: #36c0ea !important;
  font-size: 12px !important;
  font-family: Source Code Pro,monospace !important;
}

.pending-title {
  color: rgb(120 161 235);
  font-size: 14px;
}

.pool-control-title{
  color: #49566c;
  font-size: 14px;
}

.pool-control-title:hover{
  color: #3abb81;
  font-size: 14px;
}

.pending-stat {
  color: #3cea36;
  font-size: 1em;
}

.pending-col {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: Source Code Pro,monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.pending-col2 {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: Source Code Pro,monospace !important;
}

.col-shadow1 {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider {
  font-size: 12px;
}

.pool-name {
  font-size: 16px;
  color: rgb(120 161 235);
  font-family: Source Code Pro,monospace !important;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: #293038;
}

.pool-main {
  background-color: #293038;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border: 1px solid #554460;
  box-shadow: rgb(0 0 0 / 6%) 0px -23px 25px 0px inset, rgb(0 0 0 / 9%) 0px -36px 30px 0px inset, rgb(0 0 0 / 7%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px !important;
}

.pool-item:hover {
  background-color: #333b4c;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border: 1px solid rgba(3, 255, 150, 0.38);
  box-shadow: rgb(0 0 0 / 11%) 0px -23px 25px 0px inset, rgb(0 0 0 / 9%) 0px -36px 30px 0px inset, rgb(0 0 0 / 7%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px !important;
}

.poolselect-bg {
  background-color: #293038 !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

/deep/ .tabs.is-toggle li.is-active a {
  background-color: #314b6a;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #3abb81;
  z-index: 1;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .tabs.is-toggle a {
  border-color: #575f85;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 0;
  position: relative;
  font-family: Source Code Pro,monospace !important;
  color: #195da7;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
  color: #00b89c;
}


.pools-card {
  padding-top: .5rem;
  color: #0d64c6;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
}

/deep/ .field.has-addons .control:first-child .control .button, .field.has-addons .control:first-child .control .input, .field.has-addons .control:first-child .control .select select {
  border-radius: 0 !important;
}

/deep/ .field.has-addons:first-child  .control:first-child .control:first-child  .button:first-child , .field.has-addons:first-child  .control:first-child .control:first-child  .input:first-child , .field.has-addons:first-child .control:first-child .control:first-child  .select:first-child  select:first-child{
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.stake-btn-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.stake-btn-2 {
  font-size: 14px !important;
  max-height: 40px;
  padding-left: 12px !important;
}

.btn {
  color: #87b6e5;
  background: rgb(34 77 118);
  background-repeat: no-repeat;
  font-family: Source Code Pro,monospace !important;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.btn:focus,
.btn:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    margin-bottom: 30px;
  }
  .small-pool{
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0 !important;
  }
}



</style>
<style lang="scss" scoped>

.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro,monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

</style>
