<template>
  <div style="overflow-x: hidden" :style="$screen.width > 1024 ? 'margin-top: -85px' : 'margin-top: -84px'">
    <transition name="fade">

      <!--    Page loading animation section-->
      <div v-if="this.showLoadingModal === true" style="height: 100vh">
        <div class="loader2">
          <div class="loader__bar"></div>
          <div class="loader__bar loader__bar--delay-1"></div>
          <div class="loader__bar loader__bar--delay-2"></div>
          <div class="loader__bar loader__bar--delay-3"></div>
          <div class="loader__bar loader__bar--delay-4"></div>
          <div class="loader__bar loader__bar--delay-5"></div>
          <div>
          </div>
        </div>
      </div>

    <!--    Homepage shown after loading is completed-->
    <section v-if="this.showLoadingModal === false">


      <!--      bubbles-->
      <div
          v-if="this.showLoadingModal === false"
          id="style-10"
          style="width:100vw; overflow-x: hidden !important; overflow-y: auto"
          :style="this.getShowNavBar() === true ? 'height: 92vh !important' : 'height: 104vh !important'">

        <section class="hero" style="z-index: 2">
          <div class="hero-body header-bg3" style="z-index: 2"></div>
        </section>


        <section class="hero" :class="$screen.width > 1024 ? '' : ''" style="z-index: 2">

          <div class="columns mt-4">

            <div class="column" style="z-index: 2">

              <div class="machineinterface">
                <div class="monitor">
                  <div class="monitor-inner" style="padding-bottom: 60px">
                    <div class="screen-cutout">
                      <div class="screen">

                        <div class="my-pools-shadow column" style="z-index: 2; padding: 0px !important; margin-top: 20px;">
                          <div class="card pools-bg cardborder pb-5 " style="z-index: 2">
                            <div class="card-content poolselect-bg mb-6">
                              <section >
                                <b-tabs
                                    v-model="goToPage"
                                    headerClass="headertest"
                                    class="font1"
                                    position="is-centered"
                                    type="is-toggle"
                                    expanded
                                >
<!--                                  <b-tab-item value="0" label="All Pools"></b-tab-item>-->
                                  <b-tab-item value="1" label="Liquidity"></b-tab-item>
                                  <b-tab-item value="2" label="Staking"></b-tab-item>
                                  <b-tab-item value="3" label="NFT"></b-tab-item>
                                  <b-tab-item value="4" label="Crowdfunding"></b-tab-item>
                                </b-tabs>
                              </section>
                            </div>


                            <section>

                              <div v-if="allPoolsPaginatedFactoryStakeFactoryTokensFungiv2.length > 0" class="column is-centered mb-4 mt-5">


                                <section class="hero" style="margin-top: 0px;">
                                  <div class="hero-body">
                                    <div class="container animated fadeInUp"
                                         style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                      <div class="columns is-multiline is-mobile"
                                           style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                        <div
                                            class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                            style="border-radius: 2rem;">
                                          <div class="columns">

                                            <div class="column is-flex is-flex-direction-column is-justify-content-center">
                                              <accordion>
                                                <accordion-item :class="$screen.width < 770 ? 'small-pool' : ''">
                                                  <template slot="accordion-trigger">

                                                    <b-tooltip type="is-dark" :label="'Decentralized Liquidity Pools & Token Swaps'">
                                                      <div class="column if-full pool-col-title mt-0 mb-0 pt-1 pb-0 token2token cow">
                                                        <span style="font-size: 20px">LP TOKEN STAKING POOLS 🔍</span>
                                                      </div>
                                                      <div class="column if-full pool-col-title mt-0 mb-0 pt-0 pb-1">
                                                        <span style="font-size: 12px; color: #2aaf7b;">Stake Tokens + Yield Tokens</span>
                                                      </div>
                                                    </b-tooltip>
                                                  </template>
                                                  <!-- pool accordion content -->
                                                  <template slot="accordion-content">


                                                    <div class="columns mt-5 mb-3 pending-col leatherbg" style="position: relative; top: 10px; background-color: rgba(38,43,56,0.29); border-radius: 1rem;">
                                                      <div class="column is-align-self-center stake-btn-col" style="margin-left: 4%; margin-right: 1%;">
                                                        <div class="columns">
                                                          <div class="column">
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <b-switch
                                                                    :rounded="true"
                                                                    :outlined="true"
                                                                    v-model="searchAllFactoryTokensFungiv2"
                                                                    size="is-small"
                                                                    type="is-success"
                                                                    :left-label='false'
                                                                    :passive-type='null'>{{ searchAllFactoryTokensFungiv2 ? "All" : "Staked" }}
                                                                </b-switch>
                                                                <p class="control is-expanded">
                                                                  <b-input expanded size="is-small" v-model="searchStringFactoryTokensFungiv2" min="0" type="is-buttonblue" controls-alignment="left"
                                                                           controls-position="compact"></b-input>
                                                                </p>
                                                                <div @click="clickSearchFactoryTokensFungiv2()" class="btn stake-btn-2" style="border-bottom-right-radius: 1rem; border-top-right-radius: 1rem; width: 35%; overflow: hidden;">
                                                                  <p class="font5">SEARCH</p>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>


                                                  </template>
                                                </accordion-item>
                                              </accordion>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>


                              </div>

                              <section v-if="allPoolsPaginatedFactoryStakeFactoryTokensFungiv2.length > 0">
                                <PoolCard v-for="p in allPoolsPaginatedFactoryStakeFactoryTokensFungiv2" :key="p.pool_id" @showTxModal="showTxModalEvent()" @reloadPage="setReloadPage()" :pool_contract="p.pool_contract" :pool_id="p.pool_id" :pool_name="p.pool_name"
                                          :is_pool_owner="p.is_pool_owner" :lp_platform="p.lp_platform" :pool_endtime="p.pool_endtime" :wallet_stake_balance="p.wallet_stake_balance"
                                          :sf_poolname="p.sf_poolname"
                                          :accountApproved="p['stake_accountApproved']" :pool_stake_token="p.pool_stake_token" :pool_reward_token="p.pool_reward_token"
                                          :pool_apy="p['pool_apy']" :user_account="user_name" :pool_tokens_locked="p.pool_tokens_locked"
                                          :stake_balance="p['stake_balance']" :stake_pending_rewards="p['stake_pending_rewards']" :stake_rewards="p['stake_rewards']"></PoolCard>
                              </section>

                              <div v-if="allPoolsPaginatedFactoryStakeFactoryTokensFungiv2.length > 0 && pagesFactoryTokensFungiv2.length > 0" style="margin-top: 60px">
                                <nav class="pagination-outer" aria-label="Page navigation">
                                  <ul class="pagination">
                                    <li class="page-item">
                                      <a  @click="pageFactoryTokensFungiv2 !== 1 ? [pageFactoryTokensFungiv2--,clickShowPageFactoryTokensFungiv2(pageFactoryTokensFungiv2)] : true;" href="#" class="page-link" aria-label="Previous">
                                        <span aria-hidden="true">«</span>
                                      </a>
                                    </li>
                                    <li  v-for="pageNumber in pagesFactoryTokensFungiv2.slice(pageFactoryTokensFungiv2-1, pageFactoryTokensFungiv2+5)"
                                         :key="pageNumber" class="page-item">
                                      <a type="button" class="page-link" @click="pageFactoryTokensFungiv2 = pageNumber;  clickShowPageFactoryTokensFungiv2(pageFactoryTokensFungiv2); "> {{ pageNumber }}
                                      </a>
                                    </li>
                                    <li class="page-item">
                                      <a @click="pageFactoryTokensFungiv2 < pagesFactoryTokensFungiv2.length ? [pageFactoryTokensFungiv2++, clickShowPageFactoryTokensFungiv2(pageFactoryTokensFungiv2)] : true;"  href="#" class="page-link" aria-label="Next">
                                        <span aria-hidden="true">»</span>
                                      </a>
                                    </li>
                                  </ul>
                                </nav>
                                <br/>
                                <div class="divider is-centered mt-1"></div>
                                <br/>
                              </div>

                              <div v-if="allPoolsToShowFactoryStakeAswapTokensFungiv2.length > 0" class="column pool-main is-centered mb-4 mt-5">
                                <div class="columns is-centered pools-card">
                                  <div class="column is-1"></div>
                                  <div class="column pool-section-title is-centered is-align-self-center font3d">
                                    <div class="columns is-centered is-vcentered is-flex-direction-column">
                                      <div class="column if-full pool-col-title mt-0 mb-0 pt-1 pb-0 lp2token">
                                        ASWAP LP Token > Token Staking Pools 🔍
                                      </div>
                                      <div class="column if-full pool-col-title mt-0 mb-0 pt-0 pb-1">
                                        <span style="font-size: 12px; color: #2aaf7b;">Stake ASWAP LP Tokens + Yield Tokens</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="column is-1"></div>
                                </div>
                              </div>

                              <section v-if="allPoolsToShowFactoryStakeAswapTokensFungiv2.length > 0">
                                <PoolCard v-for="p in allPoolsToShowFactoryStakeAswapTokensFungiv2" :key="p.pool_id" @showTxModal="showTxModalEvent()" :pool_contract="p.pool_contract" :pool_id="p.pool_id" :pool_name="p.pool_name"
                                          :is_pool_owner="p.is_pool_owner" :lp_platform="p.lp_platform" :pool_endtime="p.pool_endtime"
                                          :accountApproved="p['stake_accountApproved']" :pool_stake_token="p.pool_stake_token" :pool_reward_token="p.pool_reward_token"
                                          :pool_apy="p['pool_apy']" :user_account="user_name" :pool_tokens_locked="p.pool_tokens_locked"
                                          :stake_balance="p['stake_balance']" :stake_pending_rewards="p['stake_pending_rewards']" :stake_rewards="p['stake_rewards']"></PoolCard>
                              </section>

                            </section>


                            <section v-if="allPoolsPaginatedFactoryStakeFungiv2.length > 0">

                              <section class="hero" style="margin-top: 10px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns">

                                          <div class="column is-flex is-flex-direction-column is-justify-content-center">
                                            <accordion>
                                              <accordion-item :class="$screen.width < 770 ? 'small-pool' : ''">
                                                <template slot="accordion-trigger">

                                                  <b-tooltip type="is-dark" :label="'Decentralized Liquidity Pools & Token Swaps'">
                                                    <div class="column if-full pool-col-title mt-0 mb-0 pt-1 pb-0 token2token cow">
                                                      <span style="font-size: 20px">STAKING POOLS 🔍</span>
                                                    </div>
                                                    <div class="column if-full pool-col-title mt-0 mb-0 pt-0 pb-1">
                                                      <span style="font-size: 12px; color: #2aaf7b;">Stake Tokens + Yield Tokens</span>
                                                    </div>
                                                  </b-tooltip>
                                                </template>
                                                <!-- pool accordion content -->
                                                <template slot="accordion-content">


                                                  <div class="columns mt-5 mb-3 pending-col leatherbg" style="position: relative; top: 10px; background-color: rgba(38,43,56,0.29); border-radius: 1rem;">
                                                    <div class="column is-align-self-center stake-btn-col" style="margin-left: 4%; margin-right: 1%;">
                                                      <div class="columns">
                                                        <div class="column">
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <b-switch
                                                                  :rounded="true"
                                                                  :outlined="true"
                                                                  v-model="searchAllFungiv2"
                                                                  size="is-small"
                                                                  type="is-success"
                                                                  :left-label='false'
                                                                  :passive-type='null'>{{ searchAllFungiv2 ? "All" : "Staked" }}
                                                              </b-switch>
                                                              <p class="control is-expanded">
                                                                <b-input expanded size="is-small" v-model="searchStringFungiv2" min="0" type="is-buttonblue" controls-alignment="left"
                                                                         controls-position="compact"></b-input>
                                                              </p>
                                                              <div @click="clickSearchFungiv2()" class="btn stake-btn-2" style="border-bottom-right-radius: 1rem; border-top-right-radius: 1rem; width: 35%; overflow: hidden;">
                                                                <p class="font5">SEARCH</p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>


                                                </template>
                                              </accordion-item>
                                            </accordion>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>


                              <PoolCard v-for="p in allPoolsPaginatedFactoryStakeFungiv2" :key="p.pool_id" @showTxModal="showTxModalEvent()" :pool_contract="p.pool_contract" :pool_id="p.pool_id" :pool_name="p.pool_name"
                                        :is_pool_owner="p.is_pool_owner" :pool_endtime="p.pool_endtime" :wallet_stake_balance="p.wallet_stake_balance"
                                        :accountApproved="p['stake_accountApproved']" :pool_stake_token="p.pool_stake_token" :pool_reward_token="p.pool_reward_token"
                                        :pool_apy="p['pool_apy']" :user_account="user_name" :pool_tokens_locked="p.pool_tokens_locked"
                                        :stake_balance="p['stake_balance']" :stake_pending_rewards="p['stake_pending_rewards']" :stake_rewards="p['stake_rewards']"></PoolCard>


                              <div v-if="allPoolsPaginatedFactoryStakeFungiv2.length > 0 && pagesFungiv2.length > 0" style="margin-top: 60px;">
                                <nav class="pagination-outer" aria-label="Page navigation">
                                  <ul class="pagination">
                                    <li class="page-item">
                                      <a  @click="pageFungiv2 !== 1 ? [pageFungiv2--,clickShowPageFungiv2(pageFungiv2)] : true;" href="#" class="page-link" aria-label="Previous">
                                        <span aria-hidden="true">«</span>
                                      </a>
                                    </li>
                                    <li  v-for="pageNumber in pagesFungiv2.slice(pageFungiv2-1, pageFungiv2+5)"
                                         :key="pageNumber" class="page-item">
                                      <a type="button" class="page-link" @click="pageFungiv2 = pageNumber;  clickShowPageFungiv2(pageFungiv2); "> {{ pageNumber }}
                                      </a>
                                    </li>
                                    <li class="page-item">
                                      <a @click="pageFungiv2 < pagesFungiv2.length ? [pageFungiv2++, clickShowPageFungiv2(pageFungiv2)] : true;"  href="#" class="page-link" aria-label="Next">
                                        <span aria-hidden="true">»</span>
                                      </a>
                                    </li>
                                  </ul>
                                </nav>
                              </div>

                            </section>


                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                  <div style="margin-bottom: 4%"></div>
                </div>
              </div>

            </div>
          </div>

          <div style="margin-bottom: 10%"></div>

        </section>
      </div>
    </section>

    </transition>

    <!--Sign TX Modal-->
    <b-modal v-if="this.showTxModal === true" v-model="showTxModal" :width="640" scroll="keep"
             style="border-radius:20px;">

      <div class="form-bg" style="border-radius:20px;">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-md-offset-4 blue-bg">
              <div class="form-container form-shadow">
                <div class="form-icon"><img src="../assets/stake-factory-icon.svg" width="70px"></div>
                <div class="container pb-4">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader">
                      A new signature request was just sent to your wallet:
                    </h3>
                  </div>
                </div>
                <b-message class="is-success" style="background-color: rgb(9 40 52);">
                  <div class="columns is-centered pt-4 pb-0">
                    <div class="column is-centered" style="overflow-wrap: anywhere !important;">
                      {{ this.getCurrentSignRequest() }}
                    </div>
                  </div>
                </b-message>

                <div class="container mb-2">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader">
                      Review and sign the transaction in your wallet and click confirm below:
                    </h3>
                  </div>
                </div>

                <form class="form-horizontal">
                  <div class="columns is-mobile is-centered">
                    <div class="column">
                      <div class="notification has-text-centered inset-bg-rounded2" style="background-color: #2d3a45;">
                        <div @click="closeSignatureModal" class="btn2 btn-lg stake-btn"
                             style="border-radius: 1rem !important;">
                          <span>Confirm</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>


  </div>
</template>

<script>
//import vue store variables to this page
import {mapGetters, mapActions, mapState} from "vuex";
import PoolCard from "./PoolCard.vue";
import Accordion from "@/components/accordion";
import AccordionItem from "@/components/accordion-item";

//local state
export default {
  name: "StakingPoolsPage",
  components: {
    PoolCard,
    Accordion,
    AccordionItem,
  },
  data() {
    return {
      debug: false,
      showTxModal: false,
      activeTab: "0",
      goToPage: null,
      perPage: 4,
      currentPage: "0",
      rescanAllPoolsOnRefresh: false,
      showLoadingModal: true,
      accountApproved: false,
      user_name: null,
      //Fungiv2 Fungiv2
      allPoolsFactoryStakeFungiv2: [],
      allActivePoolsFactoryStakeFungiv2:[],
      allActivePoolsIds: [],
      allUserPoolsFactoryStakeFungiv2: [],
      allUserPoolsIds:[],
      allPoolsToShowFactoryStakeFungiv2: [],
      allPoolIdsUserIsNotStakedIn:[],

      allPoolsPaginatedFactoryStakeFungiv2: [],
      allPoolsToShowFactoryStakeFungiv2_Searched: [],
      pageFungiv2: 1,
      pagesFungiv2: [],
      searchedFungiv2: false,
      searchStringFungiv2: "",
      searchAllFungiv2: true,

      //Tokens Fungiv2
      allPoolsToShowFactoryStakeAswapTokensFungiv2: [],

      allPoolsToShowFactoryStakeFactoryTokensFungiv2: [],

      allPoolsPaginatedFactoryStakeFactoryTokensFungiv2: [],
      allPoolsToShowFactoryStakeFactoryTokensFungiv2_Searched: [],
      pageFactoryTokensFungiv2: 1,
      pagesFactoryTokensFungiv2: [],
      searchedFactoryTokensFungiv2: false,
      searchStringFactoryTokensFungiv2: "",
      searchAllFactoryTokensFungiv2: true,

      allPoolsToShowFactoryStakeKDSwapTokensFungiv2: [],
      allPoolsToShowFactoryStakeKaddexTokensFungiv2: [],
      allPoolsFactoryStakeAswapTokensFungiv2: [],
      allPoolsFactoryStakeFactoryTokensFungiv2: [],
      allPoolsFactoryStakeKDSwapTokensFungiv2: [],
      allPoolsFactoryStakeKaddexTokensFungiv2: [],
      allActivePoolsFactoryStakeAswapTokensFungiv2:[],
      allActivePoolsFactoryStakeFactoryTokensFungiv2:[],
      allActivePoolsFactoryStakeKDSwapTokensFungiv2:[],
      allActivePoolsFactoryStakeKaddexTokensFungiv2:[],
      allActivePoolsIdsAswapTokensFungiv2: [],
      allActivePoolsIdsFactoryTokensFungiv2: [],
      allActivePoolsIdsKDSwapTokensFungiv2: [],
      allActivePoolsIdsKaddexTokensFungiv2: [],
      allUserPoolsFactoryStakeAswapTokensFungiv2: [],
      allUserPoolsFactoryStakeFactoryTokensFungiv2: [],
      allUserPoolsFactoryStakeKDSwapTokensFungiv2: [],
      allUserPoolsFactoryStakeKaddexTokensFungiv2: [],
      allUserPoolsIdsAswapTokensFungiv2:[],
      allUserPoolsIdsFactoryTokensFungiv2:[],
      allUserPoolsIdsKDSwapTokensFungiv2:[],
      allUserPoolsIdsKaddexTokensFungiv2:[],
      allPoolIdsUserIsNotStakedInAswapTokensFungiv2:[],
      allPoolIdsUserIsNotStakedInFactoryTokensFungiv2:[],
      allPoolIdsUserIsNotStakedInKDSwapTokensFungiv2:[],
      allPoolIdsUserIsNotStakedInKaddexTokensFungiv2:[],
    };
  },
  watch: {

    allPoolsPaginatedFactoryStakeFungiv2: async function(){
      if(this.debug){
        console.log("allPoolsPaginatedFactoryStakeFungiv2 change detected");
      }
      this.setPagesFungiv2();
    },

    allPoolsPaginatedFactoryStakeFactoryTokensFungiv2: async function(){
      if(this.debug){
        console.log("allPoolsPaginatedFactoryStakeFactoryTokensFungiv2 change detected");
      }
      this.setPagesFactoryTokensFungiv2();
    },

    goToPage: async function (newValue){

      if(newValue === "0"){

        await this.$router.push({path: `/allpools`});

      }else if(newValue === "1"){

        await this.$router.push({path: `/liquiditypools`});


      }else if(newValue === "2"){

        if(this.debug){
          console.log("You are already on this page.")
        }

      }else if(newValue === "3"){

        await this.$router.push({path: `/nftpools`});

      }else if(newValue === "4"){

        await this.$router.push({path: `/crowdfundingpools`});

      }

    },

    transactionPolling: async function (newValue, oldValue) {
      if (this.debug) {
        console.log("getTransactionPolling newValue: " + newValue);
        console.log("getTransactionPolling oldValue: " + oldValue);
      }
      if (newValue === false && oldValue === true) {


        const name = localStorage.getItem("accountName");

        const t_name_payload = {
          name: name
        }

        await this.$store.dispatch("accounts/getCurrentBindings", t_name_payload);

        if(this.rescanAllPoolsOnRefresh === true){


          setTimeout(async ()=>{
            this.showLoadingModal = true;
            await this.reScanPools();
            this.showLoadingModal = false;
          }, 3000)

        }

        this.rescanAllPoolsOnRefresh = false;

        this.showLoadingModal = false;
      }

    },

  },
  computed: {
    //import state variables from our vue store
    ...mapState("accounts", ["transactionPolling"]),

  },
  methods: {
    //map getters from vue store
    ...mapActions("accounts", ["getURIpolyfungiv1", "getURIpolyfungiv1Image"]),
    ...mapGetters("accounts", ["getAccountExists", "getShowNavBar", "getAccountConfirmed", "getCurrentSignRequest", "getAllPoolsFactoryStakeFungiv2", "getUserPoolsFactoryStakeFungiv2", "getAllCrowdFundingPools", "getUserCrowdFundingPools",
      "getAllPoolsFactoryStakeFungiv2Polyfungiv1", "getUserPoolsFactoryStakeFungiv2Polyfungiv1", "getAllPoolsFactoryStakeAswapTokensFungiv2",
      "getUserPoolsFactoryStakeAswapTokensFungiv2", "getAllPoolsFactoryStakeFactoryTokensFungiv2", "getUserPoolsFactoryStakeFactoryTokensFungiv2", "getAllPoolsFactoryStakePolyfungiv1", "getUserPoolsFactoryStakePolyfungiv1", "getAllPoolsFactoryStakePolyfungiv1Fungiv2",
      "getUserPoolsFactoryStakePolyfungiv1Fungiv2", "getAllPoolsFactoryStakePolyfungiv2", "getUserPoolsFactoryStakePolyfungiv2", "getAllTreasuryAccountsData"]),


    async setReloadPage(){
      this.rescanAllPoolsOnRefresh = true;
    },

    async showTxModalEvent(){

      this.showTxModal = true;

    },

    async closeSignatureModal() {
      this.showTxModal = false;
    },

    async clickStake(pool_contract){

      console.log(pool_contract);

    },

    coinName(namespace, name){
      let newnamespace = namespace+'.';
      let newname = name;
      if(namespace === null || namespace === 'null'){
        newnamespace = '';
      }
      if(name === null || name === 'null'){
        newname = '';
      }
      return newnamespace+newname;
    },

    reduceBalance(balance, prec = 6){
      if (balance) {
        if (balance.int) balance = balance.int;
        if (balance.decimal) balance = balance.decimal;

        if (parseFloat(balance) % 1 === 0) {
          return parseInt(balance);
        }

        return Math.trunc(parseFloat(balance) * Math.pow(10, prec)) / Math.pow(10, prec);
      }

      if (balance === 0) return 0;
    },

    async reScanPools() {
      //Lets get the users account from local storage if it exists
      const name = localStorage.getItem("accountName");
      this.user_name = name;

      //Lets check if our user is logged in, if so, lets grab the users state from the blockchain, including their NFT balances
      if (name === null || name === "") {
        if (this.debug) {
          console.log("No user local accountName found.");
        }
      } else {

        //Fungiv2 Fungiv2 reset
        this.allPoolsToShowFactoryStakeFungiv2 = [];
        this.allActivePoolsIds = [];
        this.allUserPoolsIds = [];
        this.allPoolIdsUserIsNotStakedIn = [];
        this.allPoolsFactoryStakeFungiv2 = [];
        this.allUserPoolsFactoryStakeFungiv2 = [];
        this.allActivePoolsFactoryStakeFungiv2 = [];

        //LP Tokens fungiv2 reset
        this.allPoolsToShowFactoryStakeAswapTokensFungiv2 = [];
        this.allPoolsToShowFactoryStakeFactoryTokensFungiv2 = [];
        this.allPoolsToShowFactoryStakeKDSwapTokensFungiv2= [];
        this.allPoolsToShowFactoryStakeKaddexTokensFungiv2= [];
        this.allPoolsFactoryStakeAswapTokensFungiv2= [];
        this.allPoolsFactoryStakeFactoryTokensFungiv2= [];
        this.allPoolsFactoryStakeKDSwapTokensFungiv2= [];
        this.allPoolsFactoryStakeKaddexTokensFungiv2= [];
        this.allActivePoolsFactoryStakeAswapTokensFungiv2=[];
        this.allActivePoolsFactoryStakeFactoryTokensFungiv2=[];
        this.allActivePoolsFactoryStakeKDSwapTokensFungiv2=[];
        this.allActivePoolsFactoryStakeKaddexTokensFungiv2=[];
        this.allActivePoolsIdsAswapTokensFungiv2= [];
        this.allActivePoolsIdsFactoryTokensFungiv2= [];
        this.allActivePoolsIdsKDSwapTokensFungiv2= [];
        this.allActivePoolsIdsKaddexTokensFungiv2= [];
        this.allUserPoolsFactoryStakeAswapTokensFungiv2= [];
        this.allUserPoolsFactoryStakeFactoryTokensFungiv2= [];
        this.allUserPoolsFactoryStakeKDSwapTokensFungiv2= [];
        this.allUserPoolsFactoryStakeKaddexTokensFungiv2= [];
        this.allUserPoolsIdsAswapTokensFungiv2=[];
        this.allUserPoolsIdsFactoryTokensFungiv2=[];
        this.allUserPoolsIdsKDSwapTokensFungiv2=[];
        this.allUserPoolsIdsKaddexTokensFungiv2=[];
        this.allPoolIdsUserIsNotStakedInAswapTokensFungiv2=[];
        this.allPoolIdsUserIsNotStakedInFactoryTokensFungiv2=[];
        this.allPoolIdsUserIsNotStakedInKDSwapTokensFungiv2=[];
        this.allPoolIdsUserIsNotStakedInKaddexTokensFungiv2=[];

        const t_name_payload = {
          name: name
        }

        await this.$store.dispatch("accounts/getCurrentBindings", t_name_payload);

        //Fungiv2 Fungiv2 bind
        //Bind local state for factory-stake-fungiv2
        this.allPoolsFactoryStakeFungiv2 = this.getAllPoolsFactoryStakeFungiv2();
        //Bind local state for user at factory-stake-fungiv2
        this.allUserPoolsFactoryStakeFungiv2 = this.getUserPoolsFactoryStakeFungiv2();

        //ASWAP LP Tokens Fungiv2 bind
        //Bind local state for factory-stake-fungiv2
        this.allPoolsFactoryStakeAswapTokensFungiv2 = this.getAllPoolsFactoryStakeAswapTokensFungiv2();
        //Bind local state for user at factory-stake-fungiv2
        this.allUserPoolsFactoryStakeAswapTokensFungiv2 = this.getUserPoolsFactoryStakeAswapTokensFungiv2();

        //FACTORY LP Tokens Fungiv2 bind
        //Bind local state for factory-stake-fungiv2
        this.allPoolsFactoryStakeFactoryTokensFungiv2 = this.getAllPoolsFactoryStakeFactoryTokensFungiv2();
        //Bind local state for user at factory-stake-fungiv2
        this.allUserPoolsFactoryStakeFactoryTokensFungiv2 = this.getUserPoolsFactoryStakeFactoryTokensFungiv2();

        //Update local state accountApproved so we can check for this before the page loads
        this.accountApproved = this.getAccountConfirmed();

        //////////////////////////////
        //fungiv2 fungiv2 Staking pools
        ///////////////////////////////

        //Lets make a list of active fungiv2 fungiv2 pools
        //First we got through the list of all fungiv2 fungiv2 pools if its not empty
        if (this.allPoolsFactoryStakeFungiv2.length > 0) {

          //We go through pools in reverse because its better
          for (let i = this.allPoolsFactoryStakeFungiv2.length - 1; i >= 0; i--) {

            if (this.debug === true) {
              console.log("STEP 1) POOL ID:");
              console.log(this.allPoolsFactoryStakeFungiv2[i].id)
            }

            //Now lets check and see if our pool is 'active' or not, if so, lets add it to our list of active pools
            if (this.allPoolsFactoryStakeFungiv2[i].active === true) {

              const allPoolsDetailsObject = {
                ...this.allPoolsFactoryStakeFungiv2[i],
              }

              //Lets double check and make sure our active pool list doesnt already contain this new pool we are adding
              if (this.allActivePoolsFactoryStakeFungiv2.includes(allPoolsDetailsObject) === false) {
                this.allActivePoolsFactoryStakeFungiv2.push(allPoolsDetailsObject);
              }

              //Lets also add this pools id to a list of ids to show later
              if (this.allActivePoolsIds.includes(this.allPoolsFactoryStakeFungiv2[i].id) === false) {
                this.allActivePoolsIds.push(this.allPoolsFactoryStakeFungiv2[i].id);
              }

            }

          }

        }

        //Now we need to quickly get a list of all fungiv2 fungiv2 pool ids the user has stakes in
        if (this.allUserPoolsFactoryStakeFungiv2.length > 0) {
          for (let j = this.allUserPoolsFactoryStakeFungiv2.length - 1; j >= 0; j--) {
            if (this.allUserPoolsIds.includes(this.allUserPoolsFactoryStakeFungiv2[j].id) === false) {
              this.allUserPoolsIds.push(this.allUserPoolsFactoryStakeFungiv2[j].id);
            }
          }
        }

        //Now lets filter out the fungiv2 fungiv2 pools the user has staked in from the list of all fungiv2 fungiv2 pools
        //This is our list of pools we must fill in with blank user data
        if (this.allActivePoolsIds.length > 0) {
          for (let m = this.allActivePoolsIds.length - 1; m >= 0; m--) {
            if (this.allUserPoolsIds.includes(this.allActivePoolsIds[m]) === false) {
              if (this.allPoolIdsUserIsNotStakedIn.includes(this.allActivePoolsIds[m]) === false) {
                this.allPoolIdsUserIsNotStakedIn.push(this.allActivePoolsIds[m]);
              }
            }
          }
        }

        //Now that we have a complete list of ACTIVE fungiv2 fungiv2 pools, we should go through them!
        for (let l = this.allActivePoolsFactoryStakeFungiv2.length - 1; l >= 0; l--) {

          if (this.debug === true) {
            console.log("STEP 2) POOL ID:");
            console.log(this.allActivePoolsFactoryStakeFungiv2[l].id)
          }

          //Now lets go through the list of ids the user hasnt staked in
          if (this.allPoolIdsUserIsNotStakedIn.length > 0) {

            for (let k = this.allPoolIdsUserIsNotStakedIn.length - 1; k >= 0; k--) {

              //Do any of the active pools match ids from pools the user is not staked in?
              //If so lets dress them with blank stake information to display


              if (this.allActivePoolsFactoryStakeFungiv2[l].id === this.allPoolIdsUserIsNotStakedIn[k]) {

                let t_pool_apy = this.allActivePoolsFactoryStakeFungiv2[l]['apy'];
                if (this.allActivePoolsFactoryStakeFungiv2[l]['apy'].decimal !== undefined) {
                  t_pool_apy = this.allActivePoolsFactoryStakeFungiv2[l]['apy'].decimal;
                }
                t_pool_apy = this.reduceBalance(t_pool_apy);

                let isPoolOwner = false;

                if (this.getAccountConfirmed() === true) {
                  if (this.allActivePoolsFactoryStakeFungiv2[l]['account'] === localStorage.getItem("accountName")) {
                    isPoolOwner = true;
                  }
                }

                let t_pool_endtime = this.allActivePoolsFactoryStakeFungiv2[l]['end-time'];

                if (this.allActivePoolsFactoryStakeFungiv2[l]['end-time'].time !== undefined) {
                  t_pool_endtime = this.allActivePoolsFactoryStakeFungiv2[l]['end-time'].time;
                }else if (this.allActivePoolsFactoryStakeFungiv2[l]['end-time'].timep !== undefined) {
                  t_pool_endtime = this.allActivePoolsFactoryStakeFungiv2[l]['end-time'].timep;
                }

                let date_pool_end_time = new Date(t_pool_endtime);
                let stamp_pool_end_time = date_pool_end_time.getTime();
                let isExpired = false;

                let t_now2 = new Date().getTime();
                if(t_now2 > stamp_pool_end_time){
                  isExpired = true;
                }

                const t_pool_endtime_date = new Date(t_pool_endtime);

                t_pool_endtime = t_pool_endtime.slice(0, 10);


                const t_now = new Date();

                let canPush = true;

                const numberOfStakers = this.allActivePoolsFactoryStakeFungiv2[l]['stakers'];
                if(t_now > t_pool_endtime_date && numberOfStakers !== 0){
                  canPush = false;
                }

                if(canPush === false){
                  canPush = isPoolOwner;
                }

                if(this.debug){
                  console.log("t_pool_endtime");
                  console.log(t_pool_endtime);
                  console.log("this.allActivePoolsFactoryStakeFungiv2[l]");
                  console.log(this.allActivePoolsFactoryStakeFungiv2[l]);
                  console.log("this.allActivePoolsFactoryStakeFungiv2[l]['end-time']");
                  console.log(this.allActivePoolsFactoryStakeFungiv2[l]['end-time']);
                  console.log("this.allActivePoolsFactoryStakeFungiv2[l]['active']");
                  console.log(this.allActivePoolsFactoryStakeFungiv2[l]['active']);
                  if(t_now > t_pool_endtime_date){
                    console.log("t_now is GREATER than t_pool_endtime_date");
                  }else {
                    console.log("t_now is LESS than t_pool_endtime_date");
                  }
                }

                let t_wallet_balance = 0;

                if(canPush === true){
                  const tub_payloadA = {
                    tokenA: this.coinName(this.allActivePoolsFactoryStakeFungiv2[l]['stake-token']['refName']['namespace'], this.allActivePoolsFactoryStakeFungiv2[l]['stake-token']['refName']['name']),
                  };
                  t_wallet_balance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadA);

                  if(this.debug){
                    console.log("fungiv2 t_wallet_balance updated");
                    console.log(t_wallet_balance);
                  }
                }

                if(this.debug){
                  console.log("fungiv2 t_wallet_balance");
                  console.log(t_wallet_balance);
                }




                const poolDetailObject = {
                  pool_name: this.allActivePoolsFactoryStakeFungiv2[l].name,
                  pool_id: this.allActivePoolsFactoryStakeFungiv2[l].id,
                  pool_apy: t_pool_apy,
                  pool_endtime: t_pool_endtime,
                  pool_stake_token: this.coinName(this.allActivePoolsFactoryStakeFungiv2[l]['stake-token']['refName']['namespace'], this.allActivePoolsFactoryStakeFungiv2[l]['stake-token']['refName']['name']),
                  pool_reward_token: this.coinName(this.allActivePoolsFactoryStakeFungiv2[l]['reward-token']['refName']['namespace'], this.allActivePoolsFactoryStakeFungiv2[l]['reward-token']['refName']['name']),
                  pool_tokens_locked: this.allActivePoolsFactoryStakeFungiv2[l]['tokens-locked'],
                  stake_balance: 0,
                  stake_last_updated: 0,
                  stake_rewards: 0,
                  stake_last_claimed: 0,
                  stake_last_withdraw: 0,
                  stake_multiplier: 0,
                  stake_start_time: 0,
                  stake_pending_rewards: 0,
                  pool_contract: 'factory-stake-fungiv2',
                  stake_accountApproved: this.accountApproved,
                  is_pool_owner: isPoolOwner,
                  wallet_stake_balance: t_wallet_balance,
                  isExpired: isExpired
                }

                if (this.allPoolsToShowFactoryStakeFungiv2.includes(poolDetailObject) === false && canPush === true) {
                  this.allPoolsToShowFactoryStakeFungiv2.push(poolDetailObject);
                }
              }

            }
          }
        }

        //We now have our list to show on the screen filled with the fungiv2 fungiv2 pool data the user has NOT staked in
        //Now, Lets fill that same list with the data from fungiv2 fungiv2 pools the user has staked in
        if (this.allUserPoolsFactoryStakeFungiv2.length > 0) {

          for (let n = this.allUserPoolsFactoryStakeFungiv2.length - 1; n >= 0; n--) {

            if (this.debug === true) {
              console.log("STEP 3) POOL ID:");
              console.log(this.allUserPoolsFactoryStakeFungiv2[n].id)
            }

            let t_pool_apy = this.allUserPoolsFactoryStakeFungiv2[n]['apy'];
            if (this.allUserPoolsFactoryStakeFungiv2[n]['apy'].decimal !== undefined) {
              t_pool_apy = this.allUserPoolsFactoryStakeFungiv2[n]['apy'].decimal;
            }
            t_pool_apy = this.reduceBalance(t_pool_apy, 2);

            let t_pool_endtime = this.allUserPoolsFactoryStakeFungiv2[n]['end-time'];

            if (this.allUserPoolsFactoryStakeFungiv2[n]['end-time'].time !== undefined) {
              t_pool_endtime = this.allUserPoolsFactoryStakeFungiv2[n]['end-time'].time;
            }else if (this.allUserPoolsFactoryStakeFungiv2[n]['end-time'].timep !== undefined) {
              t_pool_endtime = this.allUserPoolsFactoryStakeFungiv2[n]['end-time'].timep;
            }

            let date_pool_end_time = new Date(t_pool_endtime);
            let stamp_pool_end_time = date_pool_end_time.getTime();
            let isExpired = false;

            let t_now2 = new Date().getTime();
            if(t_now2 > stamp_pool_end_time){
              isExpired = true;
            }
            const t_pool_endtime_date = new Date(t_pool_endtime);

            t_pool_endtime = t_pool_endtime.slice(0, 10);


            const t_now = new Date();

            let t_wallet_balance = 0;


            const tub_payloadA = {
              tokenA: this.coinName(this.allUserPoolsFactoryStakeFungiv2[n]['stake-token']['refName']['namespace'], this.allUserPoolsFactoryStakeFungiv2[n]['stake-token']['refName']['name']),
            };
            t_wallet_balance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadA);

            if(this.debug){
              console.log("t_wallet_balance updated");
              console.log(t_wallet_balance);
            }


            if(this.debug){
              console.log("t_wallet_balance");
              console.log(t_wallet_balance);
            }

            if(this.debug){
              console.log("t_pool_endtime");
              console.log(t_pool_endtime);
              console.log("this.allUserPoolsFactoryStakeFungiv2[n]");
              console.log(this.allUserPoolsFactoryStakeFungiv2[n]);
              console.log("this.allUserPoolsFactoryStakeFungiv2[l]['end-time']");
              console.log(this.allUserPoolsFactoryStakeFungiv2[n]['end-time']);
              console.log("this.allUserPoolsFactoryStakeFungiv2[l]['active']");
              console.log(this.allUserPoolsFactoryStakeFungiv2[n]['active']);
              if(t_now > t_pool_endtime_date){
                console.log("t_now is GREATER than t_pool_endtime_date");
              }else {
                console.log("t_now is LESS than t_pool_endtime_date");
              }
            }

            const poolDetailObject = {
              pool_name: this.allUserPoolsFactoryStakeFungiv2[n].name,
              pool_id: this.allUserPoolsFactoryStakeFungiv2[n].id,
              pool_apy: t_pool_apy,
              pool_endtime: t_pool_endtime,
              pool_stake_token: this.coinName(this.allUserPoolsFactoryStakeFungiv2[n]['stake-token']['refName']['namespace'], this.allUserPoolsFactoryStakeFungiv2[n]['stake-token']['refName']['name']),
              pool_reward_token: this.coinName(this.allUserPoolsFactoryStakeFungiv2[n]['reward-token']['refName']['namespace'], this.allUserPoolsFactoryStakeFungiv2[n]['reward-token']['refName']['name']),
              pool_tokens_locked: this.allUserPoolsFactoryStakeFungiv2[n]['tokens-locked'],
              pool_contract: 'factory-stake-fungiv2',
              stake_accountApproved: this.accountApproved,
              stake_pending_rewards: this.allUserPoolsFactoryStakeFungiv2[n].stake_pending_rewards,
              stake_balance: this.allUserPoolsFactoryStakeFungiv2[n]['stake_balance'],
              stake_rewards: this.allUserPoolsFactoryStakeFungiv2[n]['stake_rewards'],
              wallet_stake_balance: t_wallet_balance,
              isExpired: isExpired
            }

            if (this.allPoolsToShowFactoryStakeFungiv2.includes(poolDetailObject) === false) {
              this.allPoolsToShowFactoryStakeFungiv2.push(poolDetailObject);
            }

          }
        }

        //At this point we can now display our allPoolsToShowFactoryStakeFungiv2 list on the screen
        if (this.debug) {
          console.log("allPoolsToShowFactoryStakeFungiv2");
          console.log(this.allPoolsToShowFactoryStakeFungiv2);
        }

        await this.getPoolsToShowNowFungiv2(0, this.perPage, this.allPoolsToShowFactoryStakeFungiv2);

        //aswaptokens fungiv2

        //Lets make a list of active aswaptokens fungiv2 pools
        //First we got through the list of all aswaptokens fungiv2 pools if its not empty
        if (this.allPoolsFactoryStakeAswapTokensFungiv2.length > 0) {

          //We go through pools in reverse because its better
          for (let i = this.allPoolsFactoryStakeAswapTokensFungiv2.length - 1; i >= 0; i--) {

            if (this.debug === true) {
              console.log("STEP 1) POOL ID:");
              console.log(this.allPoolsFactoryStakeAswapTokensFungiv2[i].id)
            }

            //Now lets check and see if our pool is 'active' or not, if so, lets add it to our list of active pools
            if (this.allPoolsFactoryStakeAswapTokensFungiv2[i].active === true) {

              const allPoolsDetailsObject = {
                ...this.allPoolsFactoryStakeAswapTokensFungiv2[i],
              }

              //Lets double check and make sure our active pool list doesnt already contain this new pool we are adding
              if (this.allActivePoolsFactoryStakeAswapTokensFungiv2.includes(allPoolsDetailsObject) === false) {
                this.allActivePoolsFactoryStakeAswapTokensFungiv2.push(allPoolsDetailsObject);
              }

              //Lets also add this pools id to a list of ids to show later
              if (this.allActivePoolsIdsAswapTokensFungiv2.includes(this.allPoolsFactoryStakeAswapTokensFungiv2[i].id) === false) {
                this.allActivePoolsIdsAswapTokensFungiv2.push(this.allPoolsFactoryStakeAswapTokensFungiv2[i].id);
              }

            }

          }

        }

        //Now we need to quickly get a list of all aswaptokens polyfungiv1 pool ids the user has stakes in
        if (this.allUserPoolsFactoryStakeAswapTokensFungiv2.length > 0) {
          for (let j = this.allUserPoolsFactoryStakeAswapTokensFungiv2.length - 1; j >= 0; j--) {
            if (this.allUserPoolsIdsAswapTokensFungiv2.includes(this.allUserPoolsFactoryStakeAswapTokensFungiv2[j].id) === false) {
              this.allUserPoolsIdsAswapTokensFungiv2.push(this.allUserPoolsFactoryStakeAswapTokensFungiv2[j].id);
            }
          }
        }

        //Now lets filter out the aswaptokens polyfungiv1 pools the user has staked in from the list of all fungiv2 polyfungiv1 pools
        //This is our list of pools we must fill in with blank user data
        if (this.allActivePoolsIdsAswapTokensFungiv2.length > 0) {
          for (let m = this.allActivePoolsIdsAswapTokensFungiv2.length - 1; m >= 0; m--) {
            if (this.allUserPoolsIdsAswapTokensFungiv2.includes(this.allActivePoolsIdsAswapTokensFungiv2[m]) === false) {
              if (this.allPoolIdsUserIsNotStakedInAswapTokensFungiv2.includes(this.allActivePoolsIdsAswapTokensFungiv2[m]) === false) {
                this.allPoolIdsUserIsNotStakedInAswapTokensFungiv2.push(this.allActivePoolsIdsAswapTokensFungiv2[m]);
              }
            }
          }
        }

        //Now that we have a complete list of ACTIVE aswaptokens fungiv2 pools, we should go through them!
        for (let l = this.allActivePoolsFactoryStakeAswapTokensFungiv2.length - 1; l >= 0; l--) {

          if (this.debug === true) {
            console.log("STEP 2) POOL ID:");
            console.log(this.allActivePoolsFactoryStakeAswapTokensFungiv2[l].id)
          }

          //Now lets go through the list of ids the user hasnt staked in
          if (this.allPoolIdsUserIsNotStakedInAswapTokensFungiv2.length > 0) {

            for (let k = this.allPoolIdsUserIsNotStakedInAswapTokensFungiv2.length - 1; k >= 0; k--) {

              //Do any of the active pools match ids from pools the user is not staked in?
              //If so lets dress them with blank stake information to display


              if (this.allActivePoolsFactoryStakeAswapTokensFungiv2[l].id === this.allPoolIdsUserIsNotStakedInAswapTokensFungiv2[k]) {

                let t_pool_apy = this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['apy'];
                if (this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['apy'].decimal !== undefined) {
                  t_pool_apy = this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['apy'].decimal;
                }
                t_pool_apy = this.reduceBalance(t_pool_apy);

                let isPoolOwner = false;

                if (this.getAccountConfirmed() === true) {
                  if (this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['account'] === localStorage.getItem("accountName")) {
                    isPoolOwner = true;
                  }
                }

                let t_pool_endtime = this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['end-time'];

                if (this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['end-time'].time !== undefined) {
                  t_pool_endtime = this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['end-time'].time;
                }else if (this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['end-time'].timep !== undefined) {
                  t_pool_endtime = this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['end-time'].timep;
                }

                t_pool_endtime = t_pool_endtime.slice(0, 10);

                const t_pool_endtime_date = new Date(t_pool_endtime);
                const t_now = new Date();

                let canPush = true;

                const numberOfStakers = this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['stakers'];
                if(t_now > t_pool_endtime_date && numberOfStakers !== 0){
                  canPush = false;
                }

                if(canPush === false){
                  canPush = isPoolOwner;
                  if(this.debug){
                    console.log("canPush is now true due to user being pool owner")
                  }
                }


                if(this.debug){
                  console.log("t_pool_endtime");
                  console.log(t_pool_endtime);
                  console.log("this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]");
                  console.log(this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]);
                  console.log("this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['end-time']");
                  console.log(this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['end-time']);
                  console.log("this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['active']");
                  console.log(this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['active']);
                  if(t_now > t_pool_endtime_date){
                    console.log("t_now is GREATER than t_pool_endtime_date");
                  }else {
                    console.log("t_now is LESS than t_pool_endtime_date");
                  }
                }



                const poolDetailObject = {
                  pool_name: this.allActivePoolsFactoryStakeAswapTokensFungiv2[l].name,
                  pool_id: this.allActivePoolsFactoryStakeAswapTokensFungiv2[l].id,
                  pool_apy: t_pool_apy,
                  pool_endtime: t_pool_endtime,
                  pool_stake_token: this.coinName(this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['lp-token1']['refName']['namespace'], this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['lp-token1']['refName']['name'])+":"+this.coinName(this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['lp-token2']['refName']['namespace'], this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['lp-token2']['refName']['name']),
                  pool_reward_token: this.coinName(this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['reward-token']['refName']['namespace'], this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['reward-token']['refName']['name']),
                  pool_tokens_locked: this.allActivePoolsFactoryStakeAswapTokensFungiv2[l]['tokens-locked'],
                  stake_balance: 0,
                  stake_last_updated: 0,
                  stake_rewards: 0,
                  stake_last_claimed: 0,
                  stake_last_withdraw: 0,
                  stake_multiplier: 0,
                  stake_start_time: 0,
                  stake_pending_rewards: 0,
                  pool_contract: 'factory-stake-tokens-fungiv2',
                  stake_accountApproved: this.accountApproved,
                  is_pool_owner: isPoolOwner,
                  lp_platform: 'Aswap',
                }

                if (this.allPoolsToShowFactoryStakeAswapTokensFungiv2.includes(poolDetailObject) === false && canPush === true) {
                  this.allPoolsToShowFactoryStakeAswapTokensFungiv2.push(poolDetailObject);
                }
              }

            }
          }
        }

        //We now have our list to show on the screen filled with the aswaptokens fungiv2 pool data the user has NOT staked in
        //Now, Lets fill that same list with the data from aswaptokens fungiv2 pools the user has staked in
        if (this.allUserPoolsFactoryStakeAswapTokensFungiv2.length > 0) {

          for (let n = this.allUserPoolsFactoryStakeAswapTokensFungiv2.length - 1; n >= 0; n--) {

            if (this.debug === true) {
              console.log("STEP 3) POOL ID:");
              console.log(this.allUserPoolsFactoryStakeAswapTokensFungiv2[n].id)
            }

            let t_pool_apy = this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['apy'];
            if (this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['apy'].decimal !== undefined) {
              t_pool_apy = this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['apy'].decimal;
            }
            t_pool_apy = this.reduceBalance(t_pool_apy, 2);

            let t_pool_endtime = this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['end-time'];

            if (this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['end-time'].time !== undefined) {
              t_pool_endtime = this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['end-time'].time;
            }else if (this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['end-time'].timep !== undefined) {
              t_pool_endtime = this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['end-time'].timep;
            }
            const t_pool_endtime_date = new Date(t_pool_endtime);

            t_pool_endtime = t_pool_endtime.slice(0, 10);


            const t_now = new Date();


            if(this.debug){
              console.log("t_pool_endtime");
              console.log(t_pool_endtime);
              console.log("this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]");
              console.log(this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]);
              console.log("this.allUserPoolsFactoryStakeAswapTokensFungiv2[l]['end-time']");
              console.log(this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['end-time']);
              console.log("this.allUserPoolsFactoryStakeAswapTokensFungiv2[l]['active']");
              console.log(this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['active']);
              if(t_now > t_pool_endtime_date){
                console.log("t_now is GREATER than t_pool_endtime_date");
              }else {
                console.log("t_now is LESS than t_pool_endtime_date");
              }
            }

            const poolDetailObject = {
              pool_name: this.allUserPoolsFactoryStakeAswapTokensFungiv2[n].name,
              pool_id: this.allUserPoolsFactoryStakeAswapTokensFungiv2[n].id,
              pool_apy: t_pool_apy,
              pool_endtime: t_pool_endtime,
              pool_stake_token: this.coinName(this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['lp-token1']['refName']['namespace'], this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['lp-token1']['refName']['name'])+":"+this.coinName(this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['lp-token2']['refName']['namespace'], this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['lp-token2']['refName']['name']),
              //pool_stake_token: this.coinName(this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['stake-token']['refName']['namespace'], this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['stake-token']['refName']['name']),
              pool_reward_token: this.coinName(this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['reward-token']['refName']['namespace'], this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['reward-token']['refName']['name']),
              pool_tokens_locked: this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['tokens-locked'],
              pool_contract: 'factory-stake-tokens-fungiv2',
              stake_accountApproved: this.accountApproved,
              stake_pending_rewards: this.allUserPoolsFactoryStakeAswapTokensFungiv2[n].stake_pending_rewards,
              stake_balance: this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['stake_balance'],
              pool_reward_token_id: this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['reward-token-id'],
              stake_rewards: this.allUserPoolsFactoryStakeAswapTokensFungiv2[n]['stake_rewards'],
              lp_platform: 'Aswap',
            }

            if (this.allPoolsToShowFactoryStakeAswapTokensFungiv2.includes(poolDetailObject) === false) {
              this.allPoolsToShowFactoryStakeAswapTokensFungiv2.push(poolDetailObject);
            }

          }
        }

        //At this point we can now display our allPoolsToShowFactoryStakeAswapTokensFungiv2 list on the screen
        if (this.debug) {
          console.log("allPoolsToShowFactoryStakeAswapTokensFungiv2");
          console.log(this.allPoolsToShowFactoryStakeAswapTokensFungiv2);
        }




        //factorytokens fungiv2

        //Lets make a list of active factorytokens fungiv2 pools
        //First we got through the list of all factorytokens fungiv2 pools if its not empty
        if (this.allPoolsFactoryStakeFactoryTokensFungiv2.length > 0) {

          //We go through pools in reverse because its better
          for (let i = this.allPoolsFactoryStakeFactoryTokensFungiv2.length - 1; i >= 0; i--) {

            if (this.debug === true) {
              console.log("STEP 1) POOL ID:");
              console.log(this.allPoolsFactoryStakeFactoryTokensFungiv2[i].id)
            }

            //Now lets check and see if our pool is 'active' or not, if so, lets add it to our list of active pools
            if (this.allPoolsFactoryStakeFactoryTokensFungiv2[i].active === true) {

              const allPoolsDetailsObject = {
                ...this.allPoolsFactoryStakeFactoryTokensFungiv2[i],
              }

              //Lets double check and make sure our active pool list doesnt already contain this new pool we are adding
              if (this.allActivePoolsFactoryStakeFactoryTokensFungiv2.includes(allPoolsDetailsObject) === false) {
                this.allActivePoolsFactoryStakeFactoryTokensFungiv2.push(allPoolsDetailsObject);
              }

              //Lets also add this pools id to a list of ids to show later
              if (this.allActivePoolsIdsFactoryTokensFungiv2.includes(this.allPoolsFactoryStakeFactoryTokensFungiv2[i].id) === false) {
                this.allActivePoolsIdsFactoryTokensFungiv2.push(this.allPoolsFactoryStakeFactoryTokensFungiv2[i].id);
              }

            }

          }

        }

        //Now we need to quickly get a list of all factorytokens fungiv2 pool ids the user has stakes in
        if (this.allUserPoolsFactoryStakeFactoryTokensFungiv2.length > 0) {
          for (let j = this.allUserPoolsFactoryStakeFactoryTokensFungiv2.length - 1; j >= 0; j--) {
            if (this.allUserPoolsIdsFactoryTokensFungiv2.includes(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[j].id) === false) {
              this.allUserPoolsIdsFactoryTokensFungiv2.push(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[j].id);
            }
          }
        }

        //Now lets filter out the factorytokens fungiv2 pools the user has staked in from the list of all factorytokens fungiv2 pools
        //This is our list of pools we must fill in with blank user data
        if (this.allActivePoolsIdsFactoryTokensFungiv2.length > 0) {
          for (let m = this.allActivePoolsIdsFactoryTokensFungiv2.length - 1; m >= 0; m--) {
            if (this.allUserPoolsIdsFactoryTokensFungiv2.includes(this.allActivePoolsIdsFactoryTokensFungiv2[m]) === false) {
              if (this.allPoolIdsUserIsNotStakedInFactoryTokensFungiv2.includes(this.allActivePoolsIdsFactoryTokensFungiv2[m]) === false) {
                this.allPoolIdsUserIsNotStakedInFactoryTokensFungiv2.push(this.allActivePoolsIdsFactoryTokensFungiv2[m]);
              }
            }
          }
        }

        //Now that we have a complete list of ACTIVE factorytokens fungiv2 pools, we should go through them!
        for (let l = this.allActivePoolsFactoryStakeFactoryTokensFungiv2.length - 1; l >= 0; l--) {

          if (this.debug === true) {
            console.log("STEP 2) POOL ID:");
            console.log(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l].id)
          }

          //Now lets go through the list of ids the user hasnt staked in
          if (this.allPoolIdsUserIsNotStakedInFactoryTokensFungiv2.length > 0) {

            for (let k = this.allPoolIdsUserIsNotStakedInFactoryTokensFungiv2.length - 1; k >= 0; k--) {

              //Do any of the active pools match ids from pools the user is not staked in?
              //If so lets dress them with blank stake information to display


              if (this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l].id === this.allPoolIdsUserIsNotStakedInFactoryTokensFungiv2[k]) {

                let t_pool_apy = this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['apy'];
                if (this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['apy'].decimal !== undefined) {
                  t_pool_apy = this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['apy'].decimal;
                }
                t_pool_apy = this.reduceBalance(t_pool_apy);

                let isPoolOwner = false;

                if (this.getAccountConfirmed() === true) {
                  if (this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['account'] === localStorage.getItem("accountName")) {
                    isPoolOwner = true;
                  }
                }

                let t_pool_endtime = this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['end-time'];



                if (this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['end-time'].time !== undefined) {
                  t_pool_endtime = this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['end-time'].time;
                }else if (this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['end-time'].timep !== undefined) {
                  t_pool_endtime = this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['end-time'].timep;
                }

                let date_pool_end_time = new Date(t_pool_endtime);
                let stamp_pool_end_time = date_pool_end_time.getTime();
                let isExpired = false;

                let t_now2 = new Date().getTime();
                if(t_now2 > stamp_pool_end_time){
                  isExpired = true;
                }

                const t_pool_endtime_date = date_pool_end_time;
                t_pool_endtime = t_pool_endtime.slice(0, 10);


                const t_now = new Date();

                let canPush = true;

                const numberOfStakers = this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['stakers'];
                if(t_now > t_pool_endtime_date && numberOfStakers !== 0){
                  canPush = false;
                }

                if(canPush === false){
                  canPush = isPoolOwner;
                  if(this.debug){
                    console.log("canPush is now true due to user being pool owner")
                  }
                }

                let t_wallet_balance = 0;

                if(canPush === true){

                  const lptub_payload = {
                    tokenA: this.coinName(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['lp-token1']['refName']['namespace'], this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['lp-token1']['refName']['name']),
                    tokenB: this.coinName(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['lp-token2']['refName']['namespace'], this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['lp-token2']['refName']['name']),
                    poolName: this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['poolname']
                  };

                  t_wallet_balance = await this.$store.dispatch("accounts/getLPTokenUserBalance", lptub_payload);

                  if(this.debug){
                    console.log("lp t_wallet_balance updated");
                    console.log(t_wallet_balance);
                  }
                }


                if(this.debug){
                  console.log("lp t_wallet_balance");
                  console.log(t_wallet_balance);
                }


                if(this.debug){
                  console.log("t_pool_endtime");
                  console.log(t_pool_endtime);
                  console.log("this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]");
                  console.log(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]);
                  console.log("this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['end-time']");
                  console.log(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['end-time']);
                  console.log("this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['active']");
                  console.log(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['active']);
                  if(t_now > t_pool_endtime_date){
                    console.log("t_now is GREATER than t_pool_endtime_date");
                  }else {
                    console.log("t_now is LESS than t_pool_endtime_date");
                  }
                }



                const poolDetailObject = {
                  pool_name: this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l].name,
                  pool_id: this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l].id,
                  pool_apy: t_pool_apy,
                  pool_endtime: t_pool_endtime,
                  pool_stake_token: this.coinName(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['lp-token1']['refName']['namespace'], this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['lp-token1']['refName']['name'])+":"+this.coinName(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['lp-token2']['refName']['namespace'], this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['lp-token2']['refName']['name']),
                  pool_reward_token: this.coinName(this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['reward-token']['refName']['namespace'], this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['reward-token']['refName']['name']),
                  pool_tokens_locked: this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['tokens-locked'],
                  stake_balance: 0,
                  stake_last_updated: 0,
                  stake_rewards: 0,
                  stake_last_claimed: 0,
                  stake_last_withdraw: 0,
                  stake_multiplier: 0,
                  stake_start_time: 0,
                  stake_pending_rewards: 0,
                  pool_contract: 'factory-stake-factorytokens-fungiv2',
                  stake_accountApproved: this.accountApproved,
                  is_pool_owner: isPoolOwner,
                  lp_platform: 'Stake Factory',
                  wallet_stake_balance: t_wallet_balance,
                  sf_poolname: this.allActivePoolsFactoryStakeFactoryTokensFungiv2[l]['poolname'],
                  isExpired: isExpired
                }

                if (this.allPoolsToShowFactoryStakeFactoryTokensFungiv2.includes(poolDetailObject) === false && canPush === true) {
                  this.allPoolsToShowFactoryStakeFactoryTokensFungiv2.push(poolDetailObject);
                }
              }

            }
          }
        }

        //We now have our list to show on the screen filled with the factorytokens fungiv2 pool data the user has NOT staked in
        //Now, Lets fill that same list with the data from factorytokens fungiv2 pools the user has staked in
        if (this.allUserPoolsFactoryStakeFactoryTokensFungiv2.length > 0) {

          for (let n = this.allUserPoolsFactoryStakeFactoryTokensFungiv2.length - 1; n >= 0; n--) {

            if (this.debug === true) {
              console.log("STEP 3) POOL ID:");
              console.log(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n].id)
            }

            let t_pool_apy = this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['apy'];
            if (this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['apy'].decimal !== undefined) {
              t_pool_apy = this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['apy'].decimal;
            }
            t_pool_apy = this.reduceBalance(t_pool_apy, 2);

            let t_pool_endtime = this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['end-time'];

            if (this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['end-time'].time !== undefined) {
              t_pool_endtime = this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['end-time'].time;
            }else if (this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['end-time'].timep !== undefined) {
              t_pool_endtime = this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['end-time'].timep;
            }

            let date_pool_end_time = new Date(t_pool_endtime);
            let stamp_pool_end_time = date_pool_end_time.getTime();
            let isExpired = false;

            let t_now2 = new Date().getTime();
            if(t_now2 > stamp_pool_end_time){
              isExpired = true;
            }

            let t_pool_endtime_date = new Date(t_pool_endtime);
            t_pool_endtime = t_pool_endtime.slice(0, 10);


            let t_now = new Date();


            if(this.debug){
              console.log("t_pool_endtime");
              console.log(t_pool_endtime);
              console.log("this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]");
              console.log(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]);
              console.log("this.allUserPoolsFactoryStakeFactoryTokensFungiv2[l]['end-time']");
              console.log(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['end-time']);
              console.log("this.allUserPoolsFactoryStakeFactoryTokensFungiv2[l]['active']");
              console.log(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['active']);
              if(t_now > t_pool_endtime_date){
                console.log("t_now is GREATER than t_pool_endtime_date");
              }else {
                console.log("t_now is LESS than t_pool_endtime_date");
              }
            }

            let t_wallet_balance = 0;



            const lptub_payload = {
              tokenA: this.coinName(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['lp-token1']['refName']['namespace'], this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['lp-token1']['refName']['name']),
              tokenB: this.coinName(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['lp-token2']['refName']['namespace'], this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['lp-token2']['refName']['name']),
              poolName: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['poolname']
            };

            t_wallet_balance = await this.$store.dispatch("accounts/getLPTokenUserBalance", lptub_payload);

            if(this.debug){
              console.log("lp t_wallet_balance updated");
              console.log(t_wallet_balance);
            }


            if(this.debug){
              console.log("lp t_wallet_balance");
              console.log(t_wallet_balance);
            }

            const poolDetailObject = {
              pool_name: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n].name,
              pool_id: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n].id,
              pool_apy: t_pool_apy,
              pool_endtime: t_pool_endtime,
              pool_stake_token: this.coinName(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['lp-token1']['refName']['namespace'], this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['lp-token1']['refName']['name'])+":"+this.coinName(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['lp-token2']['refName']['namespace'], this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['lp-token2']['refName']['name']),
              pool_reward_token: this.coinName(this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['reward-token']['refName']['namespace'], this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['reward-token']['refName']['name']),
              pool_tokens_locked: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['tokens-locked'],
              pool_contract: 'factory-stake-factorytokens-fungiv2',
              stake_accountApproved: this.accountApproved,
              stake_pending_rewards: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n].stake_pending_rewards,
              stake_balance: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['stake_balance'],
              pool_reward_token_id: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['reward-token-id'],
              stake_rewards: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['stake_rewards'],
              lp_platform: 'Stake Factory',
              sf_poolname: this.allUserPoolsFactoryStakeFactoryTokensFungiv2[n]['poolname'],
              wallet_stake_balance: t_wallet_balance,
              isExpired: isExpired
            }

            if (this.allPoolsToShowFactoryStakeFactoryTokensFungiv2.includes(poolDetailObject) === false) {
              this.allPoolsToShowFactoryStakeFactoryTokensFungiv2.push(poolDetailObject);
            }

          }
        }

        //At this point we can now display our allPoolsToShowFactoryStakeFactoryTokensFungiv2 list on the screen
        if (this.debug) {
          console.log("allPoolsToShowFactoryStakeFactoryTokensFungiv2");
          console.log(this.allPoolsToShowFactoryStakeFactoryTokensFungiv2);
        }

        await this.getPoolsToShowNowFactoryTokensFungiv2(0, this.perPage, this.allPoolsToShowFactoryStakeFactoryTokensFungiv2);

      }
    },

    //Fungiv2 polyfungiv1 pagination
    async clickShowPageFungiv2(pagenumber) {

      let newfrom = (pagenumber * this.perPage) - this.perPage; //0 9
      let newto = (this.pageFungiv2 * this.perPage); //9 18


      if (newfrom < 0) {
        newfrom = 0;
      }

      if (this.debug) {
        console.log("newfrom: " + newfrom);
        console.log("newTo: " + newto);
      }

      if(this.searchAllFungiv2 === true){
        await this.getPoolsToShowNowFungiv2(newfrom, newto, this.allPoolsToShowFactoryStakeFungiv2);
      }else {
        await this.getPoolsToShowNowFungiv2(newfrom, newto, this.allPoolsToShowFactoryStakeFungiv2);
      }



    },

    setPagesFungiv2() {

      let nftlist = [];

      if(this.searchAllFungiv2 === true){
        nftlist = this.allPoolsToShowFactoryStakeFungiv2;
      }else {
        nftlist = this.allUserPoolsFactoryStakeFungiv2;
      }

      let numberOfPages = Math.ceil(nftlist.length / this.perPage);

      if(this.debug){
        console.log("setPagesFungiv2");
        console.log("nftlist");
        console.log(nftlist);
        console.log("nftlist.length");
        console.log(nftlist.length);
        console.log("numberofpages");
        console.log(numberOfPages);
      }

      this.pagesFungiv2 = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesFungiv2.push(index);
      }
      if (this.debug) {
        console.log("pagesFungiv2");
        console.log(this.pagesFungiv2);
      }
    },

    async getPoolsToShowNowFungiv2(from, to, poolsToShow) {

      this.allPoolsPaginatedFactoryStakeFungiv2 = [];

      const nftsToShowList = poolsToShow;

      for (let i = from; i < to; i++) {

        if (i <= nftsToShowList.length - 1) {

          if (this.allPoolsPaginatedFactoryStakeFungiv2.includes(nftsToShowList[i]) === false) {
            this.allPoolsPaginatedFactoryStakeFungiv2.push(nftsToShowList[i]);
          }


        }
      }
    },

    async clickSearchFungiv2(){

      if(this.debug){
        console.log("clickSearchFungiv2");
      }
      this.searchedFungiv2 = true;

      this.allPoolsToShowFactoryStakeFungiv2_Searched = [];

      if(this.allPoolsToShowFactoryStakeFungiv2 && this.allPoolsToShowFactoryStakeFungiv2.length >0){


          for(let i = 0; i < this.allPoolsToShowFactoryStakeFungiv2.length; i++){

            if(this.debug){
              console.log("comparing");
              console.log(this.allPoolsToShowFactoryStakeFungiv2[i].pool_name);
              console.log("to");
              console.log(this.searchStringFungiv2)
            }

            let exp = new RegExp(this.searchStringFungiv2.toString(), "i");

            if(this.allPoolsToShowFactoryStakeFungiv2[i].pool_name.match(exp) !== null || this.allPoolsToShowFactoryStakeFungiv2[i].pool_stake_token.match(exp) !== null || this.allPoolsToShowFactoryStakeFungiv2[i].pool_reward_token.match(exp) !== null){

              if(this.debug){
                console.log("matched");
              }



              if(this.allPoolsToShowFactoryStakeFungiv2_Searched.includes(this.allPoolsToShowFactoryStakeFungiv2[i]) === false){

                if(this.debug){
                  console.log("inserted");
                }

                let doSearch = true;
                if(this.searchAllFungiv2 === true){
                  doSearch = false;
                }

                if(doSearch === true && this.allPoolsToShowFactoryStakeFungiv2[i].stake_pending_rewards > 0){
                  this.allPoolsToShowFactoryStakeFungiv2_Searched.push(this.allPoolsToShowFactoryStakeFungiv2[i]);
                }else if(doSearch === false) {
                  this.allPoolsToShowFactoryStakeFungiv2_Searched.push(this.allPoolsToShowFactoryStakeFungiv2[i]);
                }



              }

            }

          }



        if(this.debug){
          console.log("allPoolsToShowFactoryStakeFungiv2_Searched");
          console.log(this.allPoolsToShowFactoryStakeFungiv2_Searched);
        }

        this.allPoolsPaginatedFactoryStakeFungiv2 = this.allPoolsToShowFactoryStakeFungiv2_Searched;
      }




    },


    //Factorytokens fungiv2 pagination
    async clickShowPageFactoryTokensFungiv2(pagenumber) {

      let newfrom = (pagenumber * this.perPage) - this.perPage; //0 9
      let newto = (this.pageFactoryTokensFungiv2 * this.perPage); //9 18


      if (newfrom < 0) {
        newfrom = 0;
      }

      if (this.debug) {
        console.log("newfrom: " + newfrom);
        console.log("newTo: " + newto);
      }

      if(this.searchAllFactoryTokensFungiv2 === true){
        await this.getPoolsToShowNowFactoryTokensFungiv2(newfrom, newto, this.allPoolsToShowFactoryStakeFactoryTokensFungiv2);
      }else {
        await this.getPoolsToShowNowFactoryTokensFungiv2(newfrom, newto, this.allUserPoolsFactoryStakeFactoryTokensFungiv2);
      }



    },

    setPagesFactoryTokensFungiv2() {

      let nftlist = [];

      if(this.searchAllFactoryTokensFungiv2 === true){
        nftlist = this.allPoolsToShowFactoryStakeFactoryTokensFungiv2;
      }else {
        nftlist = this.allUserPoolsFactoryStakeFactoryTokensFungiv2;
      }

      let numberOfPages = Math.ceil(nftlist.length / this.perPage);

      if(this.debug){
        console.log("setPagesFungiv2");
        console.log("nftlist");
        console.log(nftlist);
        console.log("nftlist.length");
        console.log(nftlist.length);
        console.log("numberofpages");
        console.log(numberOfPages);
      }

      this.pagesFactoryTokensFungiv2 = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesFactoryTokensFungiv2.push(index);
      }
      if (this.debug) {
        console.log("pagesFactoryTokensFungiv2");
        console.log(this.pagesFactoryTokensFungiv2);
      }
    },

    async getPoolsToShowNowFactoryTokensFungiv2(from, to, poolsToShow) {

      this.allPoolsPaginatedFactoryStakeFactoryTokensFungiv2 = [];

      const nftsToShowList = poolsToShow;

      for (let i = from; i < to; i++) {

        if (i <= nftsToShowList.length - 1) {

          if (this.allPoolsPaginatedFactoryStakeFactoryTokensFungiv2.includes(nftsToShowList[i]) === false) {
            this.allPoolsPaginatedFactoryStakeFactoryTokensFungiv2.push(nftsToShowList[i]);
          }


        }
      }
    },

    async clickSearchFactoryTokensFungiv2(){

      if(this.debug){
        console.log("clickSearchFungiv2");
      }
      this.searchedFactoryTokensFungiv2 = true;

      this.allPoolsToShowFactoryStakeFactoryTokensFungiv2_Searched = [];

      if(this.allPoolsToShowFactoryStakeFactoryTokensFungiv2 && this.allPoolsToShowFactoryStakeFactoryTokensFungiv2.length >0){


        for(let i = 0; i < this.allPoolsToShowFactoryStakeFactoryTokensFungiv2.length; i++){

          if(this.debug){
            console.log("comparing");
            console.log(this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[i].pool_name);
            console.log("to");
            console.log(this.searchStringFactoryTokensFungiv2)
          }

          let exp = new RegExp(this.searchStringFactoryTokensFungiv2.toString(), "i");

          if(this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[i].pool_name.match(exp) !== null || this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[i].pool_stake_token.match(exp) !== null || this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[i].pool_reward_token.match(exp) !== null){

            if(this.debug){
              console.log("matched");
            }



            if(this.allPoolsToShowFactoryStakeFactoryTokensFungiv2_Searched.includes(this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[i]) === false){

              if(this.debug){
                console.log("inserted");
              }

              let doSearch = true;
              if(this.searchAllFactoryTokensFungiv2 === true){
                doSearch = false;
              }

              if(doSearch === true && this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[i].stake_pending_rewards > 0){
                this.allPoolsToShowFactoryStakeFactoryTokensFungiv2_Searched.push(this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[i]);
              }else if(doSearch === false) {
                this.allPoolsToShowFactoryStakeFactoryTokensFungiv2_Searched.push(this.allPoolsToShowFactoryStakeFactoryTokensFungiv2[i]);
              }



            }

          }

        }



        if(this.debug){
          console.log("allPoolsToShowFactoryStakeFactoryTokensFungiv2_Searched");
          console.log(this.allPoolsToShowFactoryStakeFactoryTokensFungiv2_Searched);
        }

        this.allPoolsPaginatedFactoryStakeFactoryTokensFungiv2 = this.allPoolsToShowFactoryStakeFactoryTokensFungiv2_Searched;
      }




    },


  },
  //created() executes 1 frame before our page is shown
  async created() {

    let name = localStorage.getItem("accountName");

    //Lets check if our user is logged in for the pool creation page, if not, lets redirect them to home page
    if (name === null || name === "" || name === "null") {
      if (this.debug) {
        console.log("No user local accountName found.");
      }
      await this.$router.push({path: `/`});
    }else {
      await this.reScanPools();

      //Close loading screen
      setTimeout(async () => {
        this.showLoadingModal = false;
      }, 500);
    }

  }
};
</script>

<style scoped>


.container4 {
  /* padding: 25px; */
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-color: #1a222e;
  box-shadow: rgb(50 50 93 / 25%) 0px 30px 60px -12px inset, rgb(0 0 0 / 30%) 0px 18px 36px -18px inset;
  border: 2px solid #1b222f;
}

.txshadow {
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

.reddish {
  color: #380245;
  text-shadow: 0px 0px 4px rgba(17, 156, 5, 0.77) !important;
}

.divider {
  border: none;
  height: 20px;
  width: 90%;
  height: 50px;
  margin-top: 0;
  border-bottom: 1px solid #224a84;
  box-shadow: 0 20px 20px -20px rgba(88, 116, 238, 0.56);
  margin: -50px auto 10px;
}


/deep/ .button.is-buttonblue {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}


/deep/ .button.is-buttonblue:hover {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}


.leatherbg {
  background: radial-gradient(#00000078 0%, transparent 10%) 0 0, radial-gradient(#0000005c 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 250, 250, 0) 15%, transparent 20%) 8px 9px;
  background-color: #2c243275 !important;
  background-size: 16px 16px;
  border: 2px solid #11426573;
  background-blend-mode: darken;
}

.pagination-outer{ text-align: center; }
.pagination{
  background: rgba(21, 21, 21, 0.32);
  font-family: 'Sniglet', cursive;
  padding: 5px;
  box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  display: inline-flex;
  position: relative;
}
.pagination li a.page-link{
  color: #4ea4c9;
  background-color: #262836;
  font-size: 16px;
  line-height: 33px;
  height: 45px;
  width: 50px;
  margin: 0 2px 0 0;
  border: 1px solid #202020;
  border-top: 1px solid #353535;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.3);
  border-radius: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
  padding: 10px;
}
.pagination li.active a.page-link,
.pagination li a.page-link:hover,
.pagination li.active a.page-link:hover{
  color: rgba(46, 204, 113, 0.44);
  background: #1e202a;
  text-shadow: 0 0 15px rgba(46, 204, 113, 0.5);
  border: 1px solid transparent;
  border-bottom: 1px solid #252525;
  box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, 0.5);
}
.pagination li:first-child a.page-link{ border-radius: 40px 0 0 40px; }
.pagination li:last-child a.page-link{
  margin-right: 0;
  border-radius: 0 40px 40px 0;
}
@media only screen and (max-width: 480px){
  .pagination{
    display: block;
    border-radius: 20px;
  }
  .pagination li{
    margin: 5px 2px;
    display: inline-block;
  }
}

#style-10::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #200d31;
  border-radius: 10px;
}

#style-10::-webkit-scrollbar
{
  width: 10px;
  background-color: #2c2432;
}

#style-10::-webkit-scrollbar-thumb
{
  background-color: #2c2432;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
  rgba(0, 0, 0, .2) 25%,
  transparent 25%,
  transparent 50%,
  rgba(0, 0, 0, .2) 50%,
  rgba(0, 0, 0, .2) 75%,
  transparent 75%,
  transparent)
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.form-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}


.btn2 {
  color: #87b6e5;
  background-color: rgba(38, 89, 125, 0.6) !important;
  background-repeat: no-repeat;
  font-family: 'Noto', sans-serif;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}

.btn2:focus,
.btn2:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(38, 89, 125, 0.6);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn2 {
    margin-bottom: 30px;
  }
}


.form-container {
  background-color: #2e3945;
  border-radius: 1rem;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #6ea4db;
  border-width: 20px;
  padding: 40px;
}

.form-container .form-icon {
  font-size: 8px;
  text-align: center;
  line-height: 160px;
  width: 100px;
  height: 100px;
  margin: 0 auto 15px;
  border-radius: 50px;
  box-shadow: 7px 7px 10px #1f272f, -7px -7px 10px #1f272f;
}

.form-container .title {
  color: #ac40ab;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 20px;
}

.form-container .form-horizontal .form-group {
  margin: 0 0 25px 0;
}

.form-container .form-horizontal .form-group label {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.form-container .form-horizontal .form-control {
  color: #333;
  background: #ecf0f3;
  font-size: 15px;
  height: 50px;
  padding: 20px;
  letter-spacing: 1px;
  border: none;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.form-container .form-horizontal .form-control:focus {
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  outline: none;
}

.form-container .form-horizontal .form-control::placeholder {
  color: #808080;
  font-size: 14px;
}

.inset-bg-rounded2 {
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}


.blue-bg {
  background-color: #314b6a;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #6ea4db;
}

.noscroll::-webkit-scrollbar {
  display: none;
}

.col-title-image {
  height: 30px;
  top: 17px;
  margin-top: 6px;
}

.pool-col-title {
  display: flex;
  flex-direction: column;
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient( #3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5% );
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 40px;
}


.machineinterface {
  display: block;
  width: 100%;
  margin: -80px 0px;
  position: relative;
}

.monitor {
  min-height: 100vh;
  padding-top: 85px;
  z-index: 2;
  display: block;
  width: 100%;
  background-color: #47485a;
  position: relative;
  padding-bottom: 40px;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, #26202b08 35px, #26202b0d 70px);
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
}

.monitor-inner {
  padding-top: 15px;
  display: block;
  width: 95%;
  background-image: conic-gradient( #414254 0 10.5%, #414254 11% 11.5%, #414254 12% 38%, #414254 39% 61.5%, #414254 62% 88%, #414254 88.5% 89%, #414254 89.25% );
  margin: auto;
  position: relative;
  top: 20px;
  left: 0;
  right: 0;
  border-radius: 5px;
  margin-top: -51px;
  padding-bottom: 60px;
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient(#3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5%);
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 50px;
}

.screen {
  display: block;
  width: 95%;
  background-color: #4F5555;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  margin-top: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 30px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), transparent 85%), radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%), linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 7px -4px #4f545f inset, 0 -2px 1px #5c6574 inset;
}

.header-bg3 {
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
  height: 1px;
  padding-top: 0px;
}

.b-tabs .tabs {
  overflow: hidden !important;
}

/deep/ .b-tabs .tabs {
  overflow: hidden !important;
}

::v-deep .b-tabs .tabs {
  overflow: hidden !important;
}


.center-flex{
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.title-back {
  color: #ffffff;
  font-family: Source Code Pro,monospace !important;
  border-radius: 1rem;
  background: -webkit-linear-gradient(90deg, rgba(49, 56, 64, 0.89), rgba(55, 45, 69, 0.85));
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}


/* card background */
.home-card-bg {
  background: #ebeae5;
}

.inset-bg{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding-bottom: 2rem;
  border-radius: 100px;
}

.inset-bg2:active{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  width: 100%;
  height: 30px;
  position: relative;
  border-radius: 1rem;
  align-self: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 4px;
}


.col-title {
  color: #36c0ea;
  font-size: 14px;
}

.pending-title {
  color: #3cea36;
  font-size: 14px;
}

.pending-stat {
  color: #3cea36;
  font-size: 1em;
}

.pending-col{
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.col-shadow1{
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider{
  font-size: 12px;
}

.pool-name{
  font-size: 14px;
  color: #36c0ea;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: #222b3a;
}

.pool-main {
  background-color: #222b3a;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
}

.pool-section-title {
  background-color: #2c3444;
  padding: 1.5rem;
  border: 5px solid rgb(38 43 60);
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #46a9e7;
  font-family: Source Code Pro,monospace !important;
}
.pool-section-title:hover {
  border: 5px solid rgba(72, 199, 142, 0.38);
}


.topbackgroundanimation {
  position: absolute;
  height: 200px;
  width: 100%;
  z-index: 1;
}

/* border for item cards */
.cardborder {
  border-radius: 1rem;
  color: #000000;
}

.poolselect-bg {
  background-color: #222b3a !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}



/deep/ .tabs.is-toggle li.is-active a {
  background-color: #252f3e;
  color: #3abb81;
  z-index: 1;
  font-family: Source Code Pro,monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}


/deep/ .tabs.is-toggle {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  background-color: #252f3e;
}

/deep/ .tabs.is-toggle a {
  margin-bottom: 0;
  position: relative;
  font-family: Source Code Pro,monospace !important;
  border:none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  color: #476074;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: #222b3a;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: #222b3a;
  border:none;
  color: #00b89c;
}


#style-8::-webkit-scrollbar-track
{
  border: 1px solid black;
  background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar
{
  width: 10px;
  background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar-thumb
{
  background-color: #000000;
}



.pools-card{
  padding-top:.5rem;
  color: #0d64c6;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow{
  box-shadow: rgba(0, 0, 0, 0.56) 8px -5px 40px;
  border-radius: 30px;
}

.stake-btn-col{
  display: flex;
  justify-content: center;
}

.btn {
  color: #87b6e5;
  background: rgb(34 77 118);
  background-repeat: no-repeat;
  font-family: Source Code Pro,monospace !important;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.btn:focus,
.btn:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    margin-bottom: 30px;
  }
  .small-pool{
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0 !important;
  }
}

/deep/ input {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
  box-shadow: inset 0 30px 60px -12px rgba(50, 50, 93, 0.25), inset 0 18px 36px -18px rgba(0, 0, 0, 0.30);
}



@media screen and (max-width: 769px), print {
  .screen-cutout {
    background-image: none !important;
    background-color: transparent !important;
  }

  /deep/ .super-select-dropdown {
    background-color: #333b4c !important;
    border: 1px solid !important;
    border-color: #2f597d !important;
    color: #67b6ef !important;
    width: 380px !important;
  }
}

/* page loading style */
.loaderstyle {
  z-index: 1;
  position: absolute !important;
}

#wraper {
  width: 400px;
  height: 350px;
  margin: 90px 11px;
  position: relative;
  border-radius: 40px;
}

#wraper2 {
  width: 450px;
  height: 350px;
  margin: 0 auto;
  position: relative;
  border-radius: 40px;
  margin-left: -36px;
}


</style>
<style lang="scss" scoped>

.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro,monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

$message-header-background-color: #e3f;


.loading-page-container {
  opacity: .95;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  z-index: 1;

}

.loading-page-container .progress {
  position: relative;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 6px solid #20182d;
  background-color: #2c2938;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: hard-light;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;

  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 1;
}

.loading-page-container .progress .inner {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: 50%;
  margin-left: -4px;
  margin-top: -1px;
  width: 196.5px;
  height: 196.5px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.loading-page-container .progress .inner .water {
  opacity: .55;
  position: absolute;
  z-index: 1;
  width: 200%;
  height: 200%;
  left: -50%;
  border-radius: 40%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  animation-name: spin;
  top: 55%;
  background: #32BAFA;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  box-shadow: 0 0 20px #152A4A;

}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  display:flex;
  width: 35px;
  height: 35px;
  border-radius: 0.5rem;
  color: #296992;
}
.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}



.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}


// for styles that do not have ascii characters setting the height
$height: 15px;

.outer {
  margin-bottom: $height;
  width: 100%;
  height: $height;
}

.plain {

  &.outer {
    border: 1px solid limegreen;
  }

  .inner {
    width: 90%;
    background-color: limegreen;
    height: $height;
  }
}

.borderless .inner {
  width: 90%;
  background-color: limegreen;
  height: $height;
}

.punctuation.outer {
  border: 1px solid limegreen;
  line-height: 1.35;
}

@-webkit-keyframes progress-bar-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes spinning {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes pulsing-opacity {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}


.plain .inner, .borderless .inner {
  animation: progress-bar-width steps(20) 5s infinite;
}

.pipes .inner, .blocks .inner, .blocks-gaps {
  animation: progress-bar-width 5s infinite;
  overflow: hidden;
  animation-timing-function: linear;
}

.blocks-gaps .inner {
  letter-spacing: 2px;
}

.activity {
  text-align: center;
  overflow: hidden !important;
}

.spinning {
  animation: spinning steps(8, end) 8s infinite;
}

.pulsing {
  animation: pulsing-opacity steps(3) 0.5s infinite alternate;
}


</style>
<style scoped>
.container3 {
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  /* padding: 25px; */
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), #0f5cbb26 85%), radial-gradient(rgb(38 58 74 / 75%), transparent 70%), linear-gradient(#0e081a00, #211922, #0f142000);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
}
.cow {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#14ff7d, #00f3ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}
</style>

<style lang="scss" scoped>
.container2 {
  background-color: #1D1F21;
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  padding: 25px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background: -webkit-linear-gradient(#3e10463d, #21333e70) !important;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 37px 59px #0e192257 inset, 0 0px 2px #ff0ef8 inset;
  -webkit-animation: scanline-data-v-0d33d4c2 8s linear infinite;
  animation: scanline-data-v-0d33d4c2 8s linear infinite;
}

article {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 900px) {
    padding: 5px;
  }

  @media (max-width: 899px) {
    padding-bottom: 56px;
  }
  background-color: rgba(192, 192, 192, 0);
  color: #323232;
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  position: relative;
  & > * + * {
    margin-top: 1.25em;
  }

  &:after {
    content: "🥩";
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding-bottom: 0.25em;
    position: absolute;
    top: 0.75em;
    right: 0.75em;
    width: 1.75em;
    height: 1.75em;
    filter: invert(100) opacity(40%);

    background-color: transparent;
  }
}

.headline {
  font-size: 2.5em;
  font-weight: 700;
}

.subhead {
  font-size: 1.25em;
  margin-top: 0.25em;
}

.article-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    width: 3em;
    height: 3em;
    flex-shrink: 0;
  }

  div:first-of-type {
    margin-left: 0.75em;
    font-size: 0.875em;
    margin-right: auto;
    padding-right: 0.75em;
  }

  div:last-of-type {
    font-size: 0.875em;
  }
}

aside {
  padding: 4em;
  border-right: 1px solid rgba(171, 15, 232, 0.1);
  border-bottom: 1px solid rgba(171, 15, 232, 0.1);
  border-left: 1px solid rgba(171, 15, 232, 0.1);
  border-top: 1px solid rgba(171, 15, 232, 0.1);
  clear: both;
  text-shadow: 0 0 10px, 0 0 40px;
  background-color: rgb(46 40 66 / 39%);
}

.tag {
  display: inline-block;
  margin-right: 0.25em;
  margin-top: 0.25em;
  border-right: 1px solid rgba(238, 238, 238, 0.19);
  border-bottom: 1px solid rgba(238, 238, 238, 0.13);
  border-left: 1px solid #444;
  border-top: 1px solid #444;
  padding: 0.125em 0.375em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}



figure {
  background-color: #c0c0c0;
  box-shadow: 0 0 0 1px #eee, 0 0 0 2px #c0c0c0, 0 0 0 3px #444;
  position: relative;
  margin-bottom: 1.25em;
  width: calc(100% + 1.5em + 2em);
  text-shadow: 0 0 10px, 0 0 40px;
  @media (min-width: 800px) {
    max-width: 50%;
  }
  &:nth-of-type(odd) {
    float: left;
    margin-right: 1.5em;
    margin-left: -3em;
  }

  &:nth-of-type(even) {
    float: right;
    margin-left: 1.5em;
    margin-right: -3em;
  }

  &:after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    background-image: linear-gradient(
            to top,
            rgba(#ff9100, 0.2) 0%,
            rgba(#ffe630, 0.2) 60%
    ),
    linear-gradient(20deg, rgba(#ff0, 0.5) 0%, rgba(#ff0, 0) 35%);
    box-shadow: inset 0px 0px 100px rgba(0, 0, 20, 1);
    z-index: 1;
  }

  img {
    // opacity: 0;
    // filter: hue-rotate(90deg);
    // filter: sepia(20%) brightness(10%) contrast(130%)
  }
  figcaption {
    font-family: "DotGothic16", monospace;
    background-color: #6f4b80;
    box-shadow: inset 0 -1px 0 0 #020202;
    color: #eee;
    text-shadow: 0 0 10px, 0 0 40px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.25em;
    font-size: 0.875em;
    z-index: 2;
  }
}
.blink {
  opacity: 1;
  animation: blink 1s linear infinite;
}

.scanline  {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba(255,255,255,0.02);
  animation: scanline 8s linear infinite;
  pointer-events: none;
  &:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: rgba(255,255,255,0.02);
    animation: scanline 8s linear infinite;
    pointer-events: none;
    z-index: 10;
  }

}

// Scanline height 1005
@keyframes scanline {
  0.01% {
    height: 0;
  }
  99.99% {
    height: 100%
  }
  100% {
    height: 0;
  }
}
</style>
