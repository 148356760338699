<template>
  <div id="app" >
    <CrowdFundPage :poolid="poolid" ></CrowdFundPage>
  </div>
</template>

<script>
import CrowdFundPage from '../components/CrowdFundPage.vue'

export default {
  name: 'CrowdFund',
  props: {
    poolid: {
      type: String,
      default: "0"
    },
  },
  components: {
    CrowdFundPage
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
