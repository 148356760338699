<template>
  <div style="overflow-x: hidden" :style="$screen.width > 1024 ? 'margin-top: -85px' : 'margin-top: -84px'">

    <!--    Page loading animation section-->
    <div v-if="this.showLoadingModal === true" style="height: 100vh">
      <div class="loader2">
        <div class="loader__bar"></div>
        <div class="loader__bar loader__bar--delay-1"></div>
        <div class="loader__bar loader__bar--delay-2"></div>
        <div class="loader__bar loader__bar--delay-3"></div>
        <div class="loader__bar loader__bar--delay-4"></div>
        <div class="loader__bar loader__bar--delay-5"></div>
        <div>
        </div>
      </div>
    </div>

    <!--    Homepage shown after loading is completed-->
    <section v-if="this.showLoadingModal === false">


      <!--      bubbles-->
      <div
          v-if="this.showLoadingModal === false"
          id="style-10"
          style="width:100vw; overflow-x: hidden !important; overflow-y: auto"
          :style="this.getShowNavBar() === true ? 'height: 92vh !important' : 'height: 104vh !important'">

        <section class="hero" style="z-index: 2">
          <div class="hero-body header-bg3" style="z-index: 2"></div>
        </section>


        <section class="hero" :class="$screen.width > 1024 ? '' : ''" style="z-index: 2">

          <div class="columns mt-4">

            <div class="column" style="z-index: 2">

              <div class="machineinterface">
                <div class="monitor">
                  <div class="monitor-inner" style="padding-bottom: 60px">
                    <div class="screen-cutout">
                      <div class="screen">

                        <div class="my-pools-shadow column" style="z-index: 2; padding: 0px !important; margin-top: 20px;">
                          <div class="card pools-bg cardborder pb-5 " style="z-index: 2">
                            <div class="card-content poolselect-bg mb-6">
                              <section >
                                <b-tabs
                                    v-model="activeTab"
                                    headerClass="headertest"
                                    class="font1"
                                    position="is-centered"
                                    type="is-toggle"
                                    expanded
                                >
                                  <b-tab-item value="0" label="Treasury"></b-tab-item>
                                  <b-tab-item value="1" label="Emissions"></b-tab-item>
                                  <b-tab-item value="2" label="Admin"></b-tab-item>
                                </b-tabs>
                              </section>
                            </div>

                            <!-- TREASURY ACCOUNTS SECTION -->
                            <section v-if="activeTab === '0'">

                              <section class="hero" style="margin-top: -20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns">

                                          <div class="column is-flex is-flex-direction-column is-justify-content-center">
                                            <b-tooltip type="is-dark" :label="'Decentralized Liquidity Pools & Token Swaps'">
                                              <div class="column if-full pool-col-title mt-0 mb-0 pt-1 pb-0 token2token cow">
                                                <span style="font-size: 20px">TREASURY ACCOUNTS</span>
                                              </div>
                                              <div class="column if-full pool-col-title mt-0 mb-0 pt-0 pb-1">
                                                <span style="font-size: 12px; color: #2aaf7b;">The Stake Factory Treasury is governed by the Stake Factory DAO</span>
                                              </div>
                                            </b-tooltip>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <TreasuryAccountCard v-for="p in allLiquidityPoolsToShowNow" :key="p.pool_id" :pool_name="p.pool_name"
                                                   :treasury_account="p.treasury_account" :token-a="p.tokenA" :treasury_account_balance-a="p.balanceA"
                                                   :token-b="p.tokenB" :treasury_account_balance-b="p.balanceB" :accountApproved="accountApproved" ></TreasuryAccountCard>

                              <TreasuryAccountCard v-for="p in nftTreasuryAccountsToShowNow" :key="p.pool_id" :pool_name="p.pool_name"
                                                   :treasury_account="p.treasury_account" :token-a="p.tokenA" :treasury_account_balance-a="p.balanceA"
                                                   :token-b="p.tokenB" :treasury_account_balance-b="p.balanceB" :accountApproved="accountApproved" ></TreasuryAccountCard>

                            </section>


                            <!-- EMISSIONS SECTION -->
                            <section v-if="activeTab === '1'">

                              <section class="hero" style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns">

                                          <div class="column is-flex is-flex-direction-column is-justify-content-center">
                                            <b-tooltip type="is-dark" :label="'Decentralized Liquidity Pools & Token Swaps'">
                                              <div class="column if-full pool-col-title mt-0 mb-0 pt-1 pb-0 token2token cow">
                                                <span style="font-size: 20px">STEAK Emissions</span>
                                              </div>
                                              <div class="column if-full pool-col-title mt-0 mb-0 pt-0 pb-1">
                                                <span style="font-size: 12px; color: #2aaf7b;">Stake Factory STEAK Token 4 Year Emissions Data</span>
                                              </div>
                                            </b-tooltip>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <section class="hero" style="margin-top: -50px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns">

                                          <div class="column is-12">
                                            <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                                              <ul class="signature-content has-text-left">
                                                <li><i><b-icon pack="fas" icon="calendar" size="is-small"></b-icon></i><span style="font-weight: bold;">Current Day</span>: {{currentEmissionsDay}} </li>
                                                <li><i><b-icon pack="fas" icon="sort-numeric-desc" size="is-small"></b-icon></i><span style="font-weight: bold;">Days Remaining</span>: {{(1461 - Number(currentEmissionsDay))}}</li>
                                                <li><i><b-icon pack="fas" icon="sun" size="is-small"></b-icon></i><span style="font-weight: bold;">Today's Emissions</span>: {{currentTotalEmissions}}</li>
                                                <li><i><b-icon pack="fas" icon="sort-amount-asc" size="is-small"></b-icon></i><span style="font-weight: bold;">Emissions Remaining</span>: {{remaining_emissions}}</li>
                                              </ul>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>



                              <!-- LP Test Token Minting -->
                              <div class="column pool-main is-centered mb-4 mt-5 pool-section-title" style="margin-left: 200px; margin-right: 200px;">
                                <div class="columns is-centered pools-card">
                                <div class="column is-4 is-offset-1 ">
                                  <!-- Links group -->
                                  <div style="margin-top: 5px;">CLAIM EMISSIONS</div>
                                </div>
                                <!-- Footer -->
                                <div class="column is-5">
                                  <div>
                                    <b-field
                                        custom-class="is-small"
                                        horizontal>
                                      <template #label>

                                        <b-tooltip type="is-dark"
                                                   label="Enter the specific day to claim emissions for">
                                          <label class="label pt-1" style="width:50px;">DATE:</label>
                                        </b-tooltip>
                                      </template>
                                      <div class="field-body">
                                        <div class="field has-addons">
                                          <p class="control is-expanded">
                                            <input v-model="currentDate"
                                                   class="input select" type="number"
                                                   placeholder="DAY TO CLAIM">
                                            <br/>
                                          </p>
                                        </div>
                                      </div>
                                    </b-field>
                                  </div>


                                </div>

                                  <div class="column is-4 is-offset-1 ">
                                    <button @click="clickClaimEmissions()" class="button is-link" style="background-color: #2f597d !important">CLAIM</button>
                                  </div>
                              </div>
                              </div>

                              <section class="hero" style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns">

                                          <div class="column is-flex is-flex-direction-column is-justify-content-center">
                                            <b-tooltip type="is-dark" :label="'Decentralized Liquidity Pools & Token Swaps'">
                                              <div class="column if-full pool-col-title mt-0 mb-0 pt-1 pb-0 token2token cow">
                                                <span style="font-size: 20px">Emissions Log</span>
                                              </div>
                                              <div class="column if-full pool-col-title mt-0 mb-0 pt-0 pb-1">
                                                <span style="font-size: 12px; color: #2aaf7b;">Stake Factory DAO Emission Transfer Logs</span>
                                              </div>
                                            </b-tooltip>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <div v-for="p in this.currentEmissionRedemptions" :key="p" class="column pool-main is-centered mb-0 mt-0">
                                <div class="columns is-centered pools-card">
                                  <div class="column is-1"></div>
                                  <div  class="column pool-section-title is-centered is-align-self-center font3d">
                                   {{p}}
                                  </div>
                                  <div class="column is-1"></div>
                                </div>
                              </div>


                            </section>



                            <!-- EMISSIONS SECTION -->
                            <section v-if="activeTab === '2'">

                              <section class="hero" style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns">

                                          <div class="column is-flex is-flex-direction-column is-justify-content-center">
                                            <b-tooltip type="is-dark" :label="'Decentralized Liquidity Pools & Token Swaps'">
                                              <div class="column if-full pool-col-title mt-0 mb-0 pt-1 pb-0 token2token cow">
                                                <span style="font-size: 20px">Treasury Controls</span>
                                              </div>
                                            </b-tooltip>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>


                              <!--                              WITHDRAWS SECTION-->
                              <section class="hero" style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns is-flex-direction-column is-justify-content-center">

                                          <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                                               style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                                            <span style="color: #32445e !important;"><b-icon pack="fas" icon="balance-scale" size="is-large"></b-icon></span>
                                            <span class="cow" style="font-size: 20px;">WITHDRAW FUNDS FROM A TREASURY POOL</span>
                                          </div>


                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="What account with receive the tokens you are withdrawing?">
                                                  <label class="label pt-1 cow">Withdraw FROM Account:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">

                                                  <p class="control is-expanded">
                                                    <input v-model="admin_accountToWithdraw"
                                                           class="input select" type="text"
                                                           placeholder="k:account">
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>

                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="Reason for withdrawing from the DAO today?">
                                                  <label class="label pt-1 cow">Reason for Withdraw:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">

                                                  <p class="control is-expanded">
                                                    <b-input v-model="admin_reasonToWithdraw" maxlength="400" style="background-color: rgba(103, 103, 108, 0.1) !important; border: none; color: #4ae39e; box-shadow: inset 0 30px 60px -12px rgb(50 50 93 / 25%), inset 0 18px 36px -18px rgb(0 0 0 / 30%);" type="textarea"></b-input>
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>

                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="What account with receive the tokens you are withdrawing?">
                                                  <label class="label pt-1 cow">Withdraw TO Account:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">

                                                  <p class="control is-expanded">
                                                    <input v-model="admin_accountToWithdraw"
                                                           class="input select" type="text"
                                                           placeholder="k:account">
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>

                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="How much will you be withdrawing from the DAO?">
                                                  <label class="label pt-1 cow">Withdraw Amount:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">

                                                  <p class="control is-expanded">
                                                    <input v-model="admin_amountToWithdraw"
                                                           class="input select" type="number"
                                                           placeholder="k:account">
                                                  </p>
                                                  <p class="control">
                                                    <b-button class="button is-primary">WITHDRAW</b-button>
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>

                                          <div class="column is-12">
                                            <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                                              <p class="cow2 pt-2" style="font-size: 13px;">About Withdraws:</p>
                                              <div class="divider" style="margin-top: -40px;"></div>
                                              <ul class="signature-content has-text-left">
                                                <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Use this tool to withdraw funds from a DAO pool.</li>
                                              </ul>
                                            </div>
                                          </div>


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>



                            </section>





                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

          <div style="margin-bottom: 10%"></div>

        </section>
      </div>
    </section>


  </div>
</template>

<script>
//import vue store variables to this page
import {mapGetters, mapActions, mapState} from "vuex";
import TreasuryAccountCard from "./TreasuryAccountCard";

//local state
export default {
  name: "TreasuryPage",
  components: {

    TreasuryAccountCard,
  },
  data() {
    return {
      debug: false,
      activeTab: "0",
      currentPage: "0",
      showLoadingModal: true,
      accountApproved: false,
      user_name: null,
      currentEmissionsDay: 0,
      remaining_emissions: 0,
      currentEmissionRedemptions: [],
      currentTotalEmissions: 0,
      currentEmissions: [],
      currentDate: 0,
      //Liquidity Pools
      allLiquidityPoolsToShowNow: [],
      //NFT Treasury accounts
      nftTreasuryAccounts: [],
      nftTreasuryAccountsToShowNow: [],
    };
  },
  watch: {
    //lets watch our transactionPolling variable
    transactionPolling: function (newValue, oldValue) {
      if (this.debug) {
        console.log("getTransactionPolling newValue: " + newValue);
        console.log("getTransactionPolling oldValue: " + oldValue);
      }
      if (newValue === false && oldValue === true) {
        this.reScanPools();
      }

    },

  },
  computed: {
    //import state variables from our vue store
    ...mapState("accounts", ["transactionPolling"]),

  },
  methods: {
    //map getters from vue store
    ...mapActions("accounts", ["getURIpolyfungiv1", "getURIpolyfungiv1Image"]),
    ...mapGetters("accounts", ["getAccountExists", "getShowNavBar", "getAccountConfirmed", "getAllPoolsFactoryStakeFungiv2", "getUserPoolsFactoryStakeFungiv2",
      "getAllPoolsFactoryStakeFungiv2Polyfungiv1", "getUserPoolsFactoryStakeFungiv2Polyfungiv1", "getAllPoolsFactoryStakeAswapTokensFungiv2",
      "getUserPoolsFactoryStakeAswapTokensFungiv2", "getAllPoolsFactoryStakePolyfungiv1", "getUserPoolsFactoryStakePolyfungiv1", "getAllPoolsFactoryStakePolyfungiv1Fungiv2",
      "getUserPoolsFactoryStakePolyfungiv1Fungiv2", "getAllPoolsFactoryStakePolyfungiv2", "getUserPoolsFactoryStakePolyfungiv2", "getAllTreasuryAccountsData"]),

    async clickClaimEmissions(){
      console.log('click');

      let t_payload = {
        poolName: "STAKE-EMISSIONS2",
        treasury: "k:5c4b5dbbd0a16f18297f3bbeef5c5facd636a2d409887557f8718b419ccc5fee",
        date: this.currentDate
      }

      await this.$store.dispatch("accounts/claimTreasury", t_payload);

    },

    async clickStake(pool_contract){

      console.log(pool_contract);

    },

    coinName(namespace, name){
      let newnamespace = namespace+'.';
      let newname = name;
      if(namespace === null || namespace === 'null'){
        newnamespace = '';
      }
      if(name === null || name === 'null'){
        newname = '';
      }
      return newnamespace+newname;
    },

    reduceBalance(balance, prec = 6){
      if (balance) {
        if (balance.int) balance = balance.int;
        if (balance.decimal) balance = balance.decimal;

        if (parseFloat(balance) % 1 === 0) {
          return parseInt(balance);
        }

        return Math.trunc(parseFloat(balance) * Math.pow(10, prec)) / Math.pow(10, prec);
      }

      if (balance === 0) return 0;
    },


    async reScanPools() {
      //Lets get the users account from local storage if it exists
      let name = localStorage.getItem("accountName");
      this.user_name = name;

      //Lets check if our user is logged in, if so, lets grab the users state from the blockchain, including their NFT balances
      if (name === null || name === "") {
        if (this.debug) {
          console.log("No user local accountName found.");
        }
      } else {

        let t_name_payload = {
          name: name
        }

        await this.$store.dispatch("accounts/getCurrentBindings", t_name_payload);

        let t_currentRedemptions = await this.$store.dispatch("accounts/getRedemptions");

        let t_currentEmissionDay = await this.$store.dispatch("accounts/getCurrentEmissionDay");

        const t_currentTotalEmissionsPayload = {
          poolName: "STAKE-EMISSIONS4",
          date: t_currentEmissionDay,
        }

        let t_currentTotalEmissions = await this.$store.dispatch("accounts/getCurrentTotalEmissions", t_currentTotalEmissionsPayload);

        let r_balance_payload = {
          tokenA: "free.stake-token",
          name: "STAKE-EMISSIONS4"
        }

        this.remaining_emissions = await this.$store.dispatch("accounts/getBalance", r_balance_payload);



        this.currentEmissionsDay = t_currentEmissionDay;
        this.currentEmissionRedemptions = t_currentRedemptions;

        this.currentTotalEmissions = t_currentTotalEmissions;

        if(t_currentTotalEmissions.decimal !== undefined){
          this.currentTotalEmissions = t_currentTotalEmissions.decimal;
        }



        if(this.debug){
          console.log("t_currentRedemptions");
          console.log(t_currentRedemptions);
          console.log("t_currentEmissionDay");
          console.log(t_currentEmissionDay);
          console.log("t_currentTotalEmissions");
          console.log(t_currentTotalEmissions);

        }

        //NFT Treasury accounts
        //Bind local state for NFT treasury account data
        this.nftTreasuryAccountsToShowNow = [];
        this.nftTreasuryAccounts = await this.$store.dispatch("accounts/getNFTTreasuryAccounts");

        if(this.debug){
          console.log("nftTreasuryAccounts");
          console.log(this.nftTreasuryAccounts);
        }

        if(this.nftTreasuryAccounts !== undefined && this.nftTreasuryAccounts !== []) {

          if (this.nftTreasuryAccounts.length > 0) {

            for (let i = this.nftTreasuryAccounts.length - 1; i >= 0; i--) {

              //{"account": "Bof2UrpO8eLb5MnfhjNOC1ccr5l32ZK9g8dg8NTaJ5U","feebalance": 0.0,"id": "coin","token": coin}

              let tokenA = this.nftTreasuryAccounts[i].token.refName.name;

              if (this.nftTreasuryAccounts[i].token.refName.namespace !== undefined && this.nftTreasuryAccounts[i].token.refName.namespace !== null) {
                tokenA = this.nftTreasuryAccounts[i].token.refName.namespace + '.' + tokenA;
              }

              let balance_payload = {
                tokenA: tokenA,
                name: this.nftTreasuryAccounts[i].account
              }

              let t_balanceA = await this.$store.dispatch("accounts/getBalance", balance_payload);

              let t_treasuryAccountPoolObject = {
                pool_name: "NFT Treasury Account "+i.toString(),
                treasury_account: this.nftTreasuryAccounts[i].account,
                tokenA: tokenA,
                tokenB: "?",
                reserveA: "?",
                reserveB: "?",
                balanceA: t_balanceA,
                balanceB: "?",

              }

              if (this.nftTreasuryAccountsToShowNow.includes(t_treasuryAccountPoolObject) === false) {
                this.nftTreasuryAccountsToShowNow.push(t_treasuryAccountPoolObject);
              }



            }

          }

        }


        //Liquidity Pools bind
        //Bind local state for liquidity pool treasury account data
        let t_allLiquidityTreasuryAccountData = [];
        t_allLiquidityTreasuryAccountData = this.getAllTreasuryAccountsData();

        if(t_allLiquidityTreasuryAccountData !== undefined && t_allLiquidityTreasuryAccountData !== []) {

          if (t_allLiquidityTreasuryAccountData.length > 0) {

            for (let i = t_allLiquidityTreasuryAccountData.length - 1; i >= 0; i--) {

            let t_treasury_account = t_allLiquidityTreasuryAccountData[i].id;
            let t_pool_name = t_allLiquidityTreasuryAccountData[i].pair;

            let tokenA = t_allLiquidityTreasuryAccountData[i].leg0.token.refName.name;
            let tokenB = t_allLiquidityTreasuryAccountData[i].leg1.token.refName.name;

            if (t_allLiquidityTreasuryAccountData[i].leg0.token.refName.namespace !== undefined && t_allLiquidityTreasuryAccountData[i].leg0.token.refName.namespace !== null) {
              tokenA = t_allLiquidityTreasuryAccountData[i].leg0.token.refName.namespace + '.' + tokenA;
            }

            if (t_allLiquidityTreasuryAccountData[i].leg1.token.refName.namespace !== undefined && t_allLiquidityTreasuryAccountData[i].leg1.token.refName.namespace !== null) {
              tokenB = t_allLiquidityTreasuryAccountData[i].leg1.token.refName.namespace + '.' + tokenB;
            }

            let reserveA = t_allLiquidityTreasuryAccountData[i].leg0.reserve;
            let reserveB = t_allLiquidityTreasuryAccountData[i].leg1.reserve;

            if (t_allLiquidityTreasuryAccountData[i].leg0.reserve.decimal !== undefined && t_allLiquidityTreasuryAccountData[i].leg0.reserve.decimal !== null) {
              reserveA = t_allLiquidityTreasuryAccountData[i].leg0.reserve.decimal;
            }

            if (t_allLiquidityTreasuryAccountData[i].leg1.reserve.decimal !== undefined && t_allLiquidityTreasuryAccountData[i].leg1.reserve.decimal !== null) {
              reserveB = t_allLiquidityTreasuryAccountData[i].leg1.reserve.decimal;
            }

            let balance_payloadA = {
              tokenA: tokenA,
              name: t_treasury_account
            }

            let balance_payloadB = {
              tokenA: tokenB,
              name: t_treasury_account
            }

            let t_balanceA = await this.$store.dispatch("accounts/getBalance", balance_payloadA);

            let t_balanceB = await this.$store.dispatch("accounts/getBalance", balance_payloadB);


            if (this.debug) {
              console.log("t_pool_name");
              console.log(t_pool_name);
              console.log("treasury account");
              console.log(t_treasury_account);
              console.log("tokenA");
              console.log(tokenA);
              console.log("tokenB");
              console.log(tokenB);
              console.log("reserveA");
              console.log(reserveA);
              console.log("reserveB");
              console.log(reserveB);
              console.log("t_balanceA");
              console.log(t_balanceA);
              console.log("t_balanceB");
              console.log(t_balanceB);
            }

            let t_treasuryAccountPoolObject = {
              pool_name: t_pool_name,
              treasury_account: t_treasury_account,
              tokenA: tokenA,
              tokenB: tokenB,
              reserveA: reserveA,
              reserveB: reserveB,
              balanceA: t_balanceA,
              balanceB: t_balanceB,

            }

            if (this.allLiquidityPoolsToShowNow.includes(t_treasuryAccountPoolObject) === false) {
              this.allLiquidityPoolsToShowNow.push(t_treasuryAccountPoolObject);
            }

          }
        }

        }


        //
         this.allLiquidityTreasuryAccountData = t_allLiquidityTreasuryAccountData;

        console.log("t_allLiquidityTreasuryAccountData");
        console.log(t_allLiquidityTreasuryAccountData);

        //Update local state accountApproved so we can check for this before the page loads
        this.accountApproved = this.getAccountConfirmed();

      }
    },




  },
  //created() executes 1 frame before our page is shown
  async created() {

    let name = localStorage.getItem("accountName");

    //Lets check if our user is logged in for the pool creation page, if not, lets redirect them to home page
    if (name === null || name === "" || name === "null") {
      if (this.debug) {
        console.log("No user local accountName found.");
      }
      await this.$router.push({path: `/`});
    }else {
      await this.reScanPools();

      //Close loading screen
      setTimeout(async () => {
        this.showLoadingModal = false;
      }, 500);
    }

  }
};
</script>

<style scoped>

.divider {
  border: none;
  height: 20px;
  width: 100%;
  height: 50px;
  margin-top: 0;
  border-bottom: 1px solid #226484;
  box-shadow: 0 20px 20px -20px rgb(88 141 238 / 56%);
  margin: -50px auto 10px;
}

.container4 {
  /* padding: 25px; */
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-color: #1a222e;
  box-shadow: rgb(50 50 93 / 25%) 0px 30px 60px -12px inset, rgb(0 0 0 / 30%) 0px 18px 36px -18px inset;
  border: 2px solid #1b222f;
}

.cow {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#14ff7d, #00f3ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
}

.cow2 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(31 86 112);
  background: -webkit-linear-gradient(#50ffd7, #00b2ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
}

.cow3 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#1cd7ce, #00f3ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
}

.cow4 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(7 8 12);
  background: -webkit-linear-gradient(#b2f8ff, #6889ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
}

.cow5 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#14ff7d, #11ff00) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
}


.email-signature{
  position: relative;
}

.email-signature:after{
  transform: rotateZ(180deg);
  top: auto;
  bottom: 0;
}
.email-signature .signature-details{
  text-align: center;
  width: 46%;
  display: inline-block;
}
.email-signature .signature-icon{
  background-color: #1BC002;
  width: 120px;
  height: 120px;
  padding: 5px;
  border-radius: 50%;
  margin: 0 auto 10px;
}
.email-signature .signature-icon img{
  width: 100%;
  border-radius: 50%;
}
.email-signature .title{
  color: #1BC002;
  font-size: 27px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 0 0 5px;
}
.email-signature .post{
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px 15px 3px;
  box-shadow: 0 0 5px rgba(255,255,255,0.1);
  display: inline-block;
}
.email-signature .signature-content{
  width: 90%;
  padding: 10px 0 15px;
  margin: 15px 0;
  list-style: none;
  vertical-align: top;
  display: inline-block;
  position: relative;
}
.email-signature .signature-content:before,
.email-signature .signature-content:after{
  content: '';
  background: #523a7b;
  height: 100%;
  width: 25px;
  position: absolute;
  left: 10px;
  top: 0;
  z-index: 1;
}
.email-signature .signature-content:after{
  background: linear-gradient(to top right, rgb(57, 36, 94) 50%, transparent 51%);
  height: 10px;
  width: 10px;
  left: 35px;
}
.email-signature .signature-content li{
  color: #44a361;
  background-color: #1b2230;
  font-size: 14px;
  padding: 6px 10px 4px 45px;
  margin-bottom: 7px;
  position: relative;
}
.email-signature .signature-content li:last-child{ margin-bottom: 0; }
.email-signature .signature-content li i{
  color: #7790e9;
  font-size: 14px;
  position: absolute;
  left: 16px;
  top: 9px;
  z-index: 2;
}
@media screen and (max-width:576px){
  .email-signature{ padding: 35px 25px 10px; }
  .email-signature .signature-details,
  .email-signature .signature-content{
    width: 100%;
    margin: 0 0 25px;
  }
}


#style-10::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #200d31;
  border-radius: 10px;
}

#style-10::-webkit-scrollbar
{
  width: 10px;
  background-color: #2c2432;
}

#style-10::-webkit-scrollbar-thumb
{
  background-color: #2c2432;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
  rgba(0, 0, 0, .2) 25%,
  transparent 25%,
  transparent 50%,
  rgba(0, 0, 0, .2) 50%,
  rgba(0, 0, 0, .2) 75%,
  transparent 75%,
  transparent)
}


.noscroll::-webkit-scrollbar {
  display: none;
}


input {
  background-color: rgba(103, 103, 108, 0.1) !important;
  border: none;
  color: #4ae39e;
}

/deep/ .textarea {
  background-color: rgba(103, 103, 108, 0.1) !important;
  border: none;
  color: #4ae39e;
  box-shadow: inset 0 30px 60px -12px rgb(50 50 93 / 25%), inset 0 18px 36px -18px rgb(0 0 0 / 30%);
}

.label {
  color: #3abb81;
  display: block;
  font-size: 1rem;
  font-weight: 700;
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient( #3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5% );
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 40px;
}


.machineinterface {
  display: block;
  width: 100%;
  margin: -80px 0px;
  position: relative;
}

.monitor {
  min-height: 100vh;
  padding-top: 85px;
  z-index: 2;
  display: block;
  width: 100%;
  background-color: #47485a;
  position: relative;
  padding-bottom: 40px;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, #26202b08 35px, #26202b0d 70px);
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
}

.monitor-inner {
  padding-top: 15px;
  display: block;
  width: 95%;
  background-image: conic-gradient( #414254 0 10.5%, #414254 11% 11.5%, #414254 12% 38%, #414254 39% 61.5%, #414254 62% 88%, #414254 88.5% 89%, #414254 89.25% );
  margin: auto;
  position: relative;
  top: 20px;
  left: 0;
  right: 0;
  border-radius: 5px;
  margin-top: -51px;
  padding-bottom: 60px;
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient(#3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5%);
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 50px;
}

.screen {
  display: block;
  width: 95%;
  background-color: #4F5555;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  margin-top: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 30px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), transparent 85%), radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%), linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 7px -4px #4f545f inset, 0 -2px 1px #5c6574 inset;
}
.header-bg3 {
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
  height: 1px;
  padding-top: 0px;
}

.b-tabs .tabs {
  overflow: hidden !important;
}

/deep/ .b-tabs .tabs {
  overflow: hidden !important;
}

::v-deep .b-tabs .tabs {
  overflow: hidden !important;
}


.center-flex{
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.title-back {
  color: #ffffff;
  font-family: Source Code Pro,monospace !important;
  border-radius: 1rem;
  background: -webkit-linear-gradient(90deg, rgba(49, 56, 64, 0.89), rgba(55, 45, 69, 0.85));
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}


/* card background */
.home-card-bg {
  background: #ebeae5;
}

.inset-bg{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding-bottom: 2rem;
  border-radius: 100px;
}

.inset-bg2:active{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  width: 100%;
  height: 30px;
  position: relative;
  border-radius: 1rem;
  align-self: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 4px;
}


.col-title {
  color: #36c0ea;
  font-size: 14px;
}

.pending-title {
  color: #3cea36;
  font-size: 14px;
}

.pending-stat {
  color: #3cea36;
  font-size: 1em;
}

.pending-col{
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.col-shadow1{
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider{
  font-size: 12px;
}

.pool-name{
  font-size: 14px;
  color: #36c0ea;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: #222b3a;
}

.pool-main {
  background-color: #222b3a;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
}

.pool-section-title {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);

  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #46a9e7;
  font-family: Source Code Pro,monospace !important;
}


.topbackgroundanimation {
  position: absolute;
  height: 200px;
  width: 100%;
  z-index: 1;
}

/* border for item cards */
.cardborder {
  border-radius: 1rem;
  color: #000000;
}

.poolselect-bg {
  background-color: #222b3a !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}



/deep/ .tabs.is-toggle li.is-active a {
  background-color: #252f3e;
  color: #3abb81;
  z-index: 1;
  font-family: Source Code Pro,monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}


/deep/ .tabs.is-toggle {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  background-color: #252f3e;
}

/deep/ .tabs.is-toggle a {
  margin-bottom: 0;
  position: relative;
  font-family: Source Code Pro,monospace !important;
  border:none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  color: #476074;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: #222b3a;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: #222b3a;
  border:none;
  color: #00b89c;
}


#style-8::-webkit-scrollbar-track
{
  border: 1px solid black;
  background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar
{
  width: 10px;
  background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar-thumb
{
  background-color: #000000;
}



.pools-card{
  padding-top:.5rem;
  color: #3abb81;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow{
  box-shadow: rgba(0, 0, 0, 0.56) 8px -5px 40px;
  border-radius: 30px;
}

.stake-btn-col{
  display: flex;
  justify-content: center;
}

.btn{
  color: #87b6e5;
  background: rgba(3, 150, 255, 0.32);
  background-repeat: no-repeat;
  font-family: Source Code Pro,monospace !important;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}
.btn:focus,
.btn:hover{
  color: #fff;
  background: radial-gradient(circle at center,rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
}
@keyframes bubbles{
  100%{
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%,26% -96%,-1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}
@media only screen and (max-width: 767px){
  .btn{ margin-bottom: 30px; }
}

@media screen and (max-width: 769px), print {
  .screen-cutout {
    background-image: none !important;
    background-color: transparent !important;
  }

  /deep/ .super-select-dropdown {
    background-color: #333b4c !important;
    border: 1px solid !important;
    border-color: #2f597d !important;
    color: #67b6ef !important;
    width: 380px !important;
  }
}

/* page loading style */
.loaderstyle {
  z-index: 1;
  position: absolute !important;
}

#wraper {
  width: 400px;
  height: 350px;
  margin: 90px 11px;
  position: relative;
  border-radius: 40px;
}

#wraper2 {
  width: 450px;
  height: 350px;
  margin: 0 auto;
  position: relative;
  border-radius: 40px;
  margin-left: -36px;
}


</style>
<style lang="scss" scoped>

.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro,monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

$message-header-background-color: #e3f;


.loading-page-container {
  opacity: .95;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  z-index: 1;

}

.loading-page-container .progress {
  position: relative;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 6px solid #20182d;
  background-color: #2c2938;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: hard-light;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;

  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 1;
}

.loading-page-container .progress .inner {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: 50%;
  margin-left: -4px;
  margin-top: -1px;
  width: 196.5px;
  height: 196.5px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.loading-page-container .progress .inner .water {
  opacity: .55;
  position: absolute;
  z-index: 1;
  width: 200%;
  height: 200%;
  left: -50%;
  border-radius: 40%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  animation-name: spin;
  top: 55%;
  background: #32BAFA;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  box-shadow: 0 0 20px #152A4A;

}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  display:flex;
  width: 35px;
  height: 35px;
  border-radius: 0.5rem;
  color: #296992;
}
.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}



.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}


// for styles that do not have ascii characters setting the height
$height: 15px;

.outer {
  margin-bottom: $height;
  width: 100%;
  height: $height;
}

.plain {

  &.outer {
    border: 1px solid limegreen;
  }

  .inner {
    width: 90%;
    background-color: limegreen;
    height: $height;
  }
}

.borderless .inner {
  width: 90%;
  background-color: limegreen;
  height: $height;
}

.punctuation.outer {
  border: 1px solid limegreen;
  line-height: 1.35;
}

@-webkit-keyframes progress-bar-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes spinning {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes pulsing-opacity {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}


.plain .inner, .borderless .inner {
  animation: progress-bar-width steps(20) 5s infinite;
}

.pipes .inner, .blocks .inner, .blocks-gaps {
  animation: progress-bar-width 5s infinite;
  overflow: hidden;
  animation-timing-function: linear;
}

.blocks-gaps .inner {
  letter-spacing: 2px;
}

.activity {
  text-align: center;
  overflow: hidden !important;
}

.spinning {
  animation: spinning steps(8, end) 8s infinite;
}

.pulsing {
  animation: pulsing-opacity steps(3) 0.5s infinite alternate;
}

</style>
<style scoped>
.container3 {
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  /* padding: 25px; */
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), #0f5cbb26 85%), radial-gradient(rgb(38 58 74 / 75%), transparent 70%), linear-gradient(#0e081a00, #211922, #0f142000);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
}
.cow {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#14ff7d, #00f3ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}
</style>

<style lang="scss" scoped>
.container2 {
  background-color: #1D1F21;
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  padding: 25px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background: -webkit-linear-gradient(#3e10463d, #21333e70) !important;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 37px 59px #0e192257 inset, 0 0px 2px #ff0ef8 inset;
  -webkit-animation: scanline-data-v-0d33d4c2 8s linear infinite;
  animation: scanline-data-v-0d33d4c2 8s linear infinite;
}

article {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 900px) {
    padding: 5px;
  }

  @media (max-width: 899px) {
    padding-bottom: 56px;
  }
  background-color: rgba(192, 192, 192, 0);
  color: #323232;
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  position: relative;
  & > * + * {
    margin-top: 1.25em;
  }

  &:after {
    content: "🥩";
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding-bottom: 0.25em;
    position: absolute;
    top: 0.75em;
    right: 0.75em;
    width: 1.75em;
    height: 1.75em;
    filter: invert(100) opacity(40%);

    background-color: transparent;
  }
}

.headline {
  font-size: 2.5em;
  font-weight: 700;
}

.subhead {
  font-size: 1.25em;
  margin-top: 0.25em;
}

.article-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    width: 3em;
    height: 3em;
    flex-shrink: 0;
  }

  div:first-of-type {
    margin-left: 0.75em;
    font-size: 0.875em;
    margin-right: auto;
    padding-right: 0.75em;
  }

  div:last-of-type {
    font-size: 0.875em;
  }
}

aside {
  padding: 4em;
  border-right: 1px solid rgba(171, 15, 232, 0.1);
  border-bottom: 1px solid rgba(171, 15, 232, 0.1);
  border-left: 1px solid rgba(171, 15, 232, 0.1);
  border-top: 1px solid rgba(171, 15, 232, 0.1);
  clear: both;
  text-shadow: 0 0 10px, 0 0 40px;
  background-color: rgb(46 40 66 / 39%);
}

.tag {
  display: inline-block;
  margin-right: 0.25em;
  margin-top: 0.25em;
  border-right: 1px solid rgba(238, 238, 238, 0.19);
  border-bottom: 1px solid rgba(238, 238, 238, 0.13);
  border-left: 1px solid #444;
  border-top: 1px solid #444;
  padding: 0.125em 0.375em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}



figure {
  background-color: #c0c0c0;
  box-shadow: 0 0 0 1px #eee, 0 0 0 2px #c0c0c0, 0 0 0 3px #444;
  position: relative;
  margin-bottom: 1.25em;
  width: calc(100% + 1.5em + 2em);
  text-shadow: 0 0 10px, 0 0 40px;
  @media (min-width: 800px) {
    max-width: 50%;
  }
  &:nth-of-type(odd) {
    float: left;
    margin-right: 1.5em;
    margin-left: -3em;
  }

  &:nth-of-type(even) {
    float: right;
    margin-left: 1.5em;
    margin-right: -3em;
  }

  &:after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    background-image: linear-gradient(
            to top,
            rgba(#ff9100, 0.2) 0%,
            rgba(#ffe630, 0.2) 60%
    ),
    linear-gradient(20deg, rgba(#ff0, 0.5) 0%, rgba(#ff0, 0) 35%);
    box-shadow: inset 0px 0px 100px rgba(0, 0, 20, 1);
    z-index: 1;
  }

  img {
    // opacity: 0;
    // filter: hue-rotate(90deg);
    // filter: sepia(20%) brightness(10%) contrast(130%)
  }
  figcaption {
    font-family: "DotGothic16", monospace;
    background-color: #6f4b80;
    box-shadow: inset 0 -1px 0 0 #020202;
    color: #eee;
    text-shadow: 0 0 10px, 0 0 40px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.25em;
    font-size: 0.875em;
    z-index: 2;
  }
}
.blink {
  opacity: 1;
  animation: blink 1s linear infinite;
}

.scanline  {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba(255,255,255,0.02);
  animation: scanline 8s linear infinite;
  pointer-events: none;
  &:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: rgba(255,255,255,0.02);
    animation: scanline 8s linear infinite;
    pointer-events: none;
    z-index: 10;
  }

}

// Scanline height 1005
@keyframes scanline {
  0.01% {
    height: 0;
  }
  99.99% {
    height: 100%
  }
  100% {
    height: 0;
  }
}
</style>
