<template>
  <div v-bind:class="[flipped ? 'flip-container flipped' : 'flip-container']"
       class="column pool-main2 is-centered mb-4 mt-5">
    <div class="columns is-centered pools-card" >
      <div class="column is-1"></div>
      <div
          class="column pool-item is-centered is-align-self-center" v-bind:class="[2 < 1 ? 'highlight-span' : '']"
      >
        <accordion>
          <accordion-item :class="$screen.width < 770 ? 'small-pool' : ''">
            <!-- This slot will handle the title/header of the accordion and is the part you click on -->
            <template slot="accordion-trigger">
              <div class="columns is-justify-content-space-evenly">
                <div class="column is-align-self-center ">
                  <b-tooltip type="is-dark" :label="'Task: '+pool_name">
                    <div class="pool-name">{{ pool_name }}</div>
                  </b-tooltip>
                </div>
                <div class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Task Due: '+pool_due_date+'.'">
                    <div class="col-title">DUE</div>
                    <div>{{ pool_due_date }}</div>
                  </b-tooltip>
                </div>
                <div class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Task Status: ?'">
                    <div class="col-title">STATUS</div>
                    <div>{{pool_status}}</div>
                  </b-tooltip>
                </div>
              </div>
            </template>
            <!-- pool accordion content -->
            <template slot="accordion-content" style="overflow-wrap: anywhere">


              <div class="columns mt-0 mb-0 descriptionSection">
                <!-- if connected pool buttons-->
                <div class="column is-align-self-center is-vcentered stake-btn-col">
                  <div class="column is-12 is-align-self-center" style=" background-color: rgba(45, 41, 56, 0.55); border-radius: 1rem; box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                    <div class="column voterheader" style="font-size: 30px; color: #48c78e !important"><span style="font-size: 35px; ">{{pool_name}}</span></div>
                  </div>
                </div>
              </div>


              <div class="columns mt-0 mb-0 descriptionSection">
                <!-- if connected pool buttons-->
                <div class="column is-align-self-center is-vcentered stake-btn-col">
                  <div class="column is-12 is-align-self-center" style="background-color: #303241; box-shadow: rgb(50 50 93 / 25%) 0px 30px 60px -12px inset, rgb(0 0 0 / 30%) 0px 18px 36px -18px inset;border-radius: 2rem;padding: 3rem;">
                    <div class="divider is-long"></div>
                    <br/>
                    <div><span style="color: #4ad1e3">{{ pool_description }}</span></div>
                    <br/>
                    <div class="divider is-long"></div>
                  </div>
                </div>

              </div>

              <div class="columns mt-4">
                <div class="column is-12 is-flex is-justify-content-center" style=" background-color: rgba(45, 41, 56, 0.55); border-radius: 1rem; box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                  <div class="container">
                    <div class="columns is-flex-direction-column">

                        <div v-if="l_show_awaiting_verification === true" class="column is-12 is-flex is-justify-content-center" style=" background-color: rgba(45, 41, 56, 0.55); border-radius: 1rem; box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                          <div class="columns p-4" style="width: 100%">
                            <div class="column is-one-third voterheader" style="font-size: 30px; color: #b6c748 !important"><b-icon pack="fas" icon="gavel" size="is-medium" style="position: relative; top: 4px;"></b-icon></div>
                            <div class="column is-flex is-flex-direction-column is-justify-content-center is-two-thirds">
                              <span style="color:#b6c748">THIS TASK HAS BEEN COMPLETED BUT AWAITS VERIFICATION</span>
                              <span style="color:#b6c748">VERIFICATIONS: [1/3]</span>
                            </div>
                          </div>
                        </div>

                      <div v-if="l_show_performed === true" class="column is-12 is-flex is-justify-content-center" style=" background-color: rgba(45, 41, 56, 0.55); border-radius: 1rem; box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                        <div class="columns p-4" style="width: 100%">
                          <div class="column is-one-third voterheader" style="font-size: 30px; color: #48c78e !important"><b-icon pack="fas" icon="hourglass" size="is-medium" style="position: relative; top: 4px;"></b-icon></div>
                          <div class="column is-flex is-flex-direction-column is-justify-content-center is-two-thirds">
                            <span style="color:#48c78e">THIS TASK IS BEING PERFORMED</span>
                          </div>
                        </div>
                      </div>



                        <div v-if="l_show_completed === true" class="column is-12 is-flex is-justify-content-center" style=" background-color: rgba(45, 41, 56, 0.55); border-radius: 1rem; box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                          <div class="columns p-4" style="width: 100%">
                            <div class="column is-one-third voterheader" style="font-size: 30px; color: #48c78e !important"><b-icon pack="fas" icon="check" size="is-medium" style="position: relative; top: 4px;"></b-icon></div>
                            <div class="column is-flex is-flex-direction-column is-justify-content-center is-two-thirds">
                              <span style="color:#48c78e">THIS TASK WAS COMPLETED</span>
                            </div>
                          </div>
                        </div>

                      <div class="column is-12">
                        <div class="email-signature">
                          <ul class="signature-content has-text-left">
                            <li><i><b-icon pack="fas" icon="user-circle" size="is-small"></b-icon></i>This task is assigned to {{pool_pay_account}} </li>
                            <li><i><b-icon pack="fas" icon="handshake" size="is-small"></b-icon></i>This task pays {{pool_pay_amount}} {{pool_pay_token}} upon verification. </li>
                            <li><i><b-icon pack="fas" icon="calendar-plus" size="is-small"></b-icon></i>Task Creation Date: {{new Date(currentDate)}} </li>
                            <li><i><b-icon pack="fas" icon="bell" size="is-small"></b-icon></i>Task Due Date: {{pool_due_date}} </li>
                            <li v-if="pool_time_completed !== '?'"><i><b-icon pack="fas" icon="calendar-check" size="is-small"></b-icon></i>Task Completed Date: {{pool_time_completed}} </li>
                            <li v-if="pool_time_verified !== '?'"><i><b-icon pack="fas" icon="gavel" size="is-small"></b-icon></i>Task Verified Date: {{pool_time_verified}} </li>
                            <li v-if="pool_verified_by !== '?'"><i><b-icon pack="fas" icon="eye" size="is-small"></b-icon></i>Verified by: {{pool_verified_by}}.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <div class="container mt-6">
                <div class="row">
                  <div class="main-timeline">
                    <div class="timeline">
                      <div class="timeline-icon"></div>
                      <div class="timeline-content">
                        <span class="date">{{new Date(currentDate)}}</span>
                        <h5 class="title">k:account</h5>
                        <h5 class="title">New Message</h5>
                        <p class="description">
                          Task messages will appear here
                        </p>
                      </div>
                    </div>

                    <div class="timeline">
                      <div class="timeline-icon"></div>
                      <div class="timeline-content">
                        <span class="date">{{new Date(currentDate)}}</span>
                        <h5 class="title">k:account</h5>
                        <h5 class="title">New Message</h5>
                        <p class="description">
                          Task messages will appear here
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="divider is-long mt-2"></div>

              <div class="columns mt-4 mb-3 pending-col leatherbg is-flex is-justify-content-center"
                   style="position: relative; top: 10px; background-color: rgba(38,43,56,0.29); border-radius: 1rem;">
                <div class="column is-hidden-mobile is-2 is-align-self-center is-flex is-justify-content-center ">
                  <b-tooltip type="is-dark" :label="'Post a new message?'">
                    <span style="color: #32445e !important;"><b-icon pack="fas" icon="pencil-square" size="is-large"></b-icon></span>
                  </b-tooltip>
                </div>


                <div class="column is-align-self-center stake-btn-col" style="margin-left: 4%; margin-right: 1%;">
                  <div class="columns">
                    <div class="column"  v-bind:class="[$screen.width > 768 ? 'stake-col-lg' : 'stake-col-sm']">
                      <b-field label="Post a New Message:"
                               label-position="on-border">
                        <b-input maxlength="200" type="textarea"></b-input>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-4" v-bind:class="[$screen.width > 768 ? 'withdraw-col-lg' : 'withdraw-col-sm']">
                      <div @click="clickWithdraw()" class="btn btn-lg stake-btn" v-bind:class="[$screen.width > 768 ? 'withdraw-btn-lg' : 'withdraw-btn-sm']" style="border-bottom-right-radius: 1rem; border-top-right-radius: 1rem;">
                        <p>POST MESSAGE</p>
                      </div>
                    </div>
                  </div>
                </div>


              </div>


              <div class="columns mt-4 mb-3 pending-col leatherbg is-flex is-justify-content-center"
                   style="position: relative; top: 10px; background-color: rgba(38,43,56,0.29); border-radius: 1rem;">
                <div class="column is-hidden-mobile is-2 is-align-self-center is-flex is-justify-content-center ">
                  <b-tooltip type="is-dark" :label="'Task Tools'">
                    <span style="color: #32445e !important;"><b-icon pack="fas" icon="wrench" size="is-large"></b-icon></span>
                  </b-tooltip>
                </div>

                <div class="column is-align-self-center stake-btn-col" style="margin-left: 4%; margin-right: 1%;">
                  <div class="columns">
                    <div class="column is-4" v-bind:class="[$screen.width > 768 ? 'withdraw-col-lg' : 'withdraw-col-sm']">
                      <div @click="clickWithdraw()" class="btn btn-lg stake-btn" v-bind:class="[$screen.width > 768 ? 'withdraw-btn-lg' : 'withdraw-btn-sm']" style="border-bottom-right-radius: 1rem; border-top-right-radius: 1rem;">
                        <p>COMPLETE TASK</p>
                      </div>
                    </div>
                    <div class="column is-4" v-bind:class="[$screen.width > 768 ? 'withdraw-col-lg' : 'withdraw-col-sm']">
                      <div @click="clickWithdraw()" class="btn btn-lg stake-btn" v-bind:class="[$screen.width > 768 ? 'withdraw-btn-lg' : 'withdraw-btn-sm']" style="border-bottom-right-radius: 1rem; border-top-right-radius: 1rem;">
                        <p>VERIFY TASK</p>
                      </div>
                    </div>
                    <div class="column is-4" v-bind:class="[$screen.width > 768 ? 'withdraw-col-lg' : 'withdraw-col-sm']">
                      <div @click="clickWithdraw()" class="btn btn-lg stake-btn" v-bind:class="[$screen.width > 768 ? 'withdraw-btn-lg' : 'withdraw-btn-sm']" style="border-bottom-right-radius: 1rem; border-top-right-radius: 1rem;">
                        <p>BAN TASK</p>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

            </template>
          </accordion-item>
        </accordion>


      </div>
      <div class="column is-1"></div>
    </div>

  </div>
</template>

<script>
//import vue store variables to this page
import {mapGetters, mapState} from "vuex";
import Accordion from "./accordion.vue";
import AccordionItem from "./accordion-item";
//import countdown from "@/components/countdown";

export default {
  name: "PoolCard",
  components: {
    Accordion,
    AccordionItem,
    //countdown
  },
  props: {
    accountApproved: {
      type: Boolean,
      default: false,
    },
    task_id: {
      type: [String, Number],
      default: "0"
    },
    pool_subject_id: {
      type: [String, Number],
      default: "0"
    },
    pool_name: {
      type: String,
      default: "0",
    },
    pool_description: {
      type: String,
      default: "0",
    },
    pool_status: {
      type: String,
      default: "0",
    },
    pool_required_verifiers: {
      type: [String, Number],
      default: "0",
    },
    pool_pay_account: {
      type: String,
      default: "0",
    },
    pool_pay_amount: {
      type: [String, Number],
      default: "0",
    },
    pool_pay_token: {
      type: [String, Object],
      default: "0",
    },
    pool_time_created: {
      type: [String, Object, Number, Date],
      default: "0",
    },
    pool_due_date: {
      type: [String, Object, Number, Date],
      default: "0",
    },
    pool_time_completed: {
      type: [String, Object, Number, Date],
      default: "0",
    },
    pool_time_verified: {
      type: [String, Object, Number, Date],
      default: "0",
    },
    pool_verified_by: {
      type: [String, Object],
      default: "0",
    },
    pool_messages: {
      type: [String, Object],
      default: "0",
    },
    pool_active: {
      type: Boolean,
      default: false,
    },
    pool_completed: {
      type: Boolean,
      default: false,
    },
    pool_verified: {
      type: Boolean,
      default: false,
    },
    pool_paid: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      debug: false,
      flipped: false,
      showStakeControls: false,
      stakeAmount: 0.0,
      currentDate: null,
      isMakingCrowdFundTransaction: false,
      isMakingTransaction: false,
      isCompleting: false,
      l_wallet_token_balance: 0,
      l_show_awaiting_verification: false,
      l_show_completed: false,
      l_show_performed: true,

    };
  },
  watch: {
    //lets watch our transactionPolling variable
    transactionPolling: async function (newValue, oldValue) {
      if (this.debug) {
        console.log("poolcard getTransactionPolling newValue: " + newValue);
        console.log("poolcard getTransactionPolling oldValue: " + oldValue);
      }


      if (newValue === true && this.isMakingPoolCardTransaction === true) {

        this.isMakingTransaction = true;

        let message1 = "Confirming new blockchain transaction";
        if (this.isStaking === true) {
          message1 = "Completing Task " + this.task_id;
        }

        this.$buefy.toast.open({
          duration: 10000,
          message: message1,
          type: "is-info",
          pauseOnHover: true
        });

      } else if (newValue === false && this.isMakingPoolCardTransaction === true) {

        this.isMakingTransaction = false;
        this.isMakingPoolCardTransaction = false;


        let message = "Transaction succeeded";
        if (this.isCompleting === true) {
          message = "Completed Task " + this.task_id;
        }

        //Transaction success toast
        if (this.getTransactionFailed() === false) {

          this.$buefy.toast.open({
            duration: 10000,
            message: message,
            position: "is-top",
            type: "is-success"
          });

          this.isCompleting = false;

          await this.rescanPool();
        }else if (this.getTransactionFailed() === true) {

          let message2 = "Transaction failed";
          if (this.isCompleting === true) {
            message2 = "Failed complete task "+this.task_id;
          }

          this.$buefy.toast.open({
            duration: 10000,
            message: message2,
            position: "is-top",
            type: "is-danger"
          });

          this.isCompleting = false;

          await this.rescanPool();
        }
      }



    }
  },
  computed: {
    ...mapState("accounts", ["transactionPolling", "transactionRejected"]),
  },
  methods: {
    ...mapGetters("accounts", ["getAccountExists", "getAccountConfirmed", "getTransactionFailed", "getTransactionPolling", "getAllCrowdFundingPools", "getUserCrowdFundingPools"]),

    async clickWithdraw(){
      const txpolling = this.getTransactionPolling();

      if(this.debug){
        console.log("TXPOLLING");
        console.log(txpolling);
      }

      if(txpolling === false){

        if(this.l_stake_balance > 0){



          if(this.debug){
            console.log("CREATING NEW STAKE")
          }

          const payload = {
            poolID: this.pool_id,
          }

          if(this.debug){
            console.log("votepayload")
            console.log(payload);
          }

          console.log("votepayload")
          console.log(payload);

          this.isWithdrawing = true;
          this.isWithdrawing_poolid = payload.poolID
          this.isWithdrawing_amount = this.l_stake_balance+ " "+this.coinName(this.pool_vote_token.refName.namespace, this.pool_vote_token.refName.name);
          this.isMakingPoolCardTransaction = true;
          this.isClaiming = false;
          this.$emit('showTxModal', true);
          await this.$store.dispatch("accounts/withdrawVoteStake", payload);


        }else {
          alert("You must have staked tokens in this pool to withdraw any")
        }

      }else{
        alert("Please wait for your current transaction to complete.");
      }


    },

    async clickVoteFor(vote) {

      const txpolling = this.getTransactionPolling();

      if(this.debug){
        console.log("TXPOLLING");
        console.log(txpolling);
      }

      if(txpolling === false){

        if(this.stakeAmount > 0){

          if(this.l_wallet_vote_token_balance > 0){

            if(this.l_wallet_vote_token_balance >= this.stakeAmount){

              if(this.debug){
                console.log("CREATING NEW STAKE")
              }

              const payload = {
                poolID: this.pool_id,
                amount: this.stakeAmount,
                accountName: this.user_account,
                moduleName: this.coinName(this.pool_vote_token.refName.namespace, this.pool_vote_token.refName.name),
                treasury: this.pool_treasury_account,
                votefor: vote === true ? true : false,
                voteagainst: vote === false ? true : false,
              }


              if(this.debug){
                console.log("votepayload")
                console.log(payload);
              }

              console.log("votepayload")
              console.log(payload);

              this.isStaking = true;
              this.isStaking_poolid = payload.poolID
              this.isStaking_amount = this.stakeAmount+ " "+this.coinName(this.pool_vote_token.refName.namespace, this.pool_vote_token.refName.name);
              this.isMakingPoolCardTransaction = true;
              this.isClaiming = false;
              this.isWithdrawing = false;
              this.$emit('showTxModal', true);
              await this.$store.dispatch("accounts/stakeFactoryStakeVote", payload);

            }else {
              alert("Insufficient funds.")
            }




          }else {
            alert("Insufficient funds.")
          }



        }else {
          alert("You must Stake > 0")
        }

      }else{
        alert("Please wait for your current transaction to complete.");
      }






    },

    number_test(n) {
      const result = (n - Math.floor(n)) !== 0;

      if (result) {
        if (this.debug) {
          console.log("Contains Decimal Place");
        }
        return true;
      } else {
        if (this.debug) {
          console.log("No decimal place");
        }
        return false;
      }

    },

    coinName(namespace, name){
      let newnamespace = namespace+'.';
      let newname = name;
      if(namespace === null || namespace === 'null'){
        newnamespace = '';
      }
      if(name === null || name === 'null'){
        newname = '';
      }
      return newnamespace+newname;
    },

    coinDisplayName(coin){
      let name = coin;
      if(coin === 'coin'){
        name = 'KDA';
      }else if(coin === 'free.stake-token'){
        name = 'STEAK';
      }
      return name;
    },

    reduceBalance(balance, prec = 6){
      if (balance) {
        if (balance.int) balance = balance.int;
        if (balance.decimal) balance = balance.decimal;

        if (parseFloat(balance) % 1 === 0) {
          return parseInt(balance);
        }

        return Math.trunc(parseFloat(balance) * Math.pow(10, prec)) / Math.pow(10, prec);
      }

      if (balance === 0) return 0;
    },

    async rescanPool(){

      console.log("RESCANNING POOL");

      let hivepayload = {
        poolid: this.pool_id
      }

      let currentpool = await this.$store.dispatch("accounts/getVotePoll", hivepayload);

      console.log("CURRENTPOOL");
      console.log(currentpool);

      let current_user_vote = await this.$store.dispatch("accounts/getAccountVotePollVotes", hivepayload);

      console.log("current_user_vote");
      console.log(current_user_vote);

      this.l_pool_balance =  currentpool.balance.decimal ? currentpool.balance.decimal : currentpool.balance;
      this.l_pool_against_balance =  currentpool.against_balance.decimal ? currentpool.against_balance.decimal : currentpool.against_balance;
      this.l_pool_for_balance =  currentpool.for_balance.decimal ? currentpool.for_balance.decimal : currentpool.for_balance;
      this.l_pool_voters =  currentpool.voters.int ? currentpool.voters.int : currentpool.voters;
      this.l_pool_for_voters =  currentpool.for_voters;
      this.l_pool_against_voters =  currentpool.against_voters;

      if(current_user_vote !== 0 && current_user_vote !== '0' && current_user_vote !== [] && current_user_vote.length > 0){
        this.l_stake_balance =  current_user_vote[0].balance.decimal ? current_user_vote[0].balance.decimal : current_user_vote[0].balance;
        this.l_stake_final_vote_balance =  current_user_vote[0].final_vote_balance.decimal ? current_user_vote[0].final_vote_balance.decimal : current_user_vote[0].final_vote_balance;
        this.l_stake_voted =  current_user_vote[0].voted;
        this.l_stake_vote_for =  current_user_vote[0].for;
        this.l_stake_vote_against =  current_user_vote[0].against;
        this.l_stake_vote_date = current_user_vote[0].vote_date.timep ? current_user_vote[0].vote_date.timep : current_user_vote[0].vote_date;
        this.l_stake_withdraw_date = current_user_vote[0].withdraw_date.timep ? current_user_vote[0].withdraw_date.timep : current_user_vote[0].withdraw_date;

        if(this.l_stake_vote_for === true){ this.l_show_i_voted_for = true; }
        if(this.l_stake_vote_against === true){ this.l_show_i_voted_against = true; }

      }else {
        this.l_stake_balance = 0;

        this.l_stake_final_vote_balance =  0;
        this.l_stake_voted =  false;
        this.l_stake_vote_for =  false;
        this.l_stake_vote_against =  false;
        this.l_show_i_voted_for = false;
        this.l_show_i_voted_against = false;
      }

      const tub_payloadA = {
        tokenA: this.coinName(currentpool.vote_token.refName.namespace, currentpool.vote_token.refName.name)
      };

      this.l_wallet_vote_token_balance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadA);


    }

  },
  async created(){
    this.currentDate = new Date().getTime();


  }
};
</script>

<style scoped>

/deep/ .accordion__content {
  overflow-wrap: anywhere !important;
}

/deep/ .label {
  color: #4ad1e3;
}

/deep/ .field.is-floating-label .label:before {
  background-color: transparent !important;
}

/deep/ .textarea {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
  box-shadow: inset 0 30px 60px -12px rgb(50 50 93 / 25%), inset 0 18px 36px -18px rgb(0 0 0 / 30%);
}


.email-signature{
  position: relative;
}

.email-signature:after{
  transform: rotateZ(180deg);
  top: auto;
  bottom: 0;
}
.email-signature .signature-details{
  text-align: center;
  width: 46%;
  display: inline-block;
}
.email-signature .signature-icon{
  background-color: #1BC002;
  width: 120px;
  height: 120px;
  padding: 5px;
  border-radius: 50%;
  margin: 0 auto 10px;
}
.email-signature .signature-icon img{
  width: 100%;
  border-radius: 50%;
}
.email-signature .title{
  color: #1BC002;
  font-size: 27px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 0 0 5px;
}
.email-signature .post{
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px 15px 3px;
  box-shadow: 0 0 5px rgba(255,255,255,0.1);
  display: inline-block;
}
.email-signature .signature-content{
  width: 90%;
  padding: 10px 0 15px;
  margin: 15px 0;
  list-style: none;
  vertical-align: top;
  display: inline-block;
  position: relative;
}
.email-signature .signature-content:before,
.email-signature .signature-content:after{
  content: '';
  background: #523a7b;
  height: 100%;
  width: 25px;
  position: absolute;
  left: 10px;
  top: 0;
  z-index: 1;
}
.email-signature .signature-content:after{
  background: linear-gradient(to top right, rgb(57, 36, 94) 50%, transparent 51%);
  height: 10px;
  width: 10px;
  left: 35px;
}
.email-signature .signature-content li{
  color: #44a361;
  background-color: #1b2230;
  font-size: 14px;
  padding: 6px 10px 4px 45px;
  margin-bottom: 7px;
  position: relative;
}
.email-signature .signature-content li:last-child{ margin-bottom: 0; }
.email-signature .signature-content li i{
  color: #7790e9;
  font-size: 14px;
  position: absolute;
  left: 16px;
  top: 9px;
  z-index: 2;
}
@media screen and (max-width:576px){
  .email-signature{ padding: 35px 25px 10px; }
  .email-signature .signature-details,
  .email-signature .signature-content{
    width: 100%;
    margin: 0 0 25px;
  }
}








.main-timeline{
  overflow: hidden;
  position: relative;
  padding: 60px 0;
}
.main-timeline:before{
  content: "";
  width: 1px;
  height: 100%;
  background: #6cc9c9;
  position: absolute;
  top: 0;
  left: 50%;
}
.main-timeline .timeline{
  width: 50%;
  clear: both;
  position: relative;
}
.main-timeline .timeline:before,
.main-timeline .timeline:after{
  content: "";
  display: block;
  clear: both;
}
.main-timeline .timeline:first-child:before,
.main-timeline .timeline:last-child:before{
  content: "";
  width: 11px;
  height: 11px;
  background: #62bec7;
  box-sizing: content-box;
  border: 5px solid #3f605d;
  box-shadow: 0 0 0 2px #6cc9c9;
  position: absolute;
  top: -54px;
  right: -11px;
  transform: rotate(45deg);
}
.main-timeline .timeline:last-child:before{
  top: auto;
  bottom: -54px;
}
.main-timeline .timeline:last-child:nth-child(even):before{
  right: auto;
  left: -11px;
}
.main-timeline .timeline-icon{
  width: 24px;
  height: 24px;
  background: #333b4c;
  border: 1px solid #9f48d9;
  position: absolute;
  top: 17px;
  right: -13px;
  z-index: 1;
  transform: rotate(45deg);
}
.main-timeline .timeline-icon:before{
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: #333b4c;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.2s ease 0s;
}
.main-timeline .timeline:hover .timeline-icon:before{ background: #39b3ff; }
.main-timeline .timeline-content{
  width: 85%;
  padding: 18px 30px;
  background: #162430;
  text-align: right;
  float: left;
  border: 1px solid transparent;
  position: relative;
  transition: all 0.3s ease 0s;
}
.main-timeline .timeline:hover .timeline-content{
  border: 1px solid #6cc9c9;
}
.main-timeline .timeline-content:before{
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background: #9f48d9;
  border: 1px solid #6cc9c9;
  position: absolute;
  top: 21px;
  right: -7.3px;
  transform: rotate(45deg);
  transition: all 0.2s ease 0s;
}
.main-timeline .timeline:hover .timeline-content:before{
  background: #39b3ff;
  border-color: #39b3ff;
}
.main-timeline .timeline-content:after{
  content: "";
  width: 11%;
  height: 1px;
  background: #6cc9c9;
  position: absolute;
  top: 28px;
  right: -14%;
}
.main-timeline .date{
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: #39b3ff;
  margin: 0 0 8px;
  transition: all 0.3s ease 0s;
}
.main-timeline .timeline:hover .date{ color: #444; }
.main-timeline .title{
  font-size: 18px;
  color: #444;
  margin-top: 0;
  transition: all 0.3s ease 0s;
}
.main-timeline .timeline:hover .title{ color: #39b3ff; }
.main-timeline .description{
  font-size: 16px;
  color: #777;
  line-height: 28px;
  margin-top: 8px;
}
.main-timeline .timeline:nth-child(2n),
.main-timeline .timeline:nth-child(2n) .timeline-content{
  float: right;
  text-align: left;
}
.main-timeline .timeline:nth-child(2n) .timeline-icon{
  right: 0;
  left: -12px;
}
.main-timeline .timeline:nth-child(2n) .timeline-content:before{ left: -7.3px; }
.main-timeline .timeline:nth-child(2n) .timeline-content:after{ left: -14%; }
@media only screen and (max-width: 767px){
  .main-timeline{ padding-left: 20px; }
  .main-timeline:before{ left: 20px; }
  .main-timeline .timeline{ width: 100%; }
  .main-timeline .timeline,
  .main-timeline .timeline-content{
    float: right;
    text-align: left;
  }
  .main-timeline .timeline:first-child:before,
  .main-timeline .timeline:last-child:nth-child(odd):before{
    right: auto;
    left: -11px;
  }
  .main-timeline .timeline-icon{
    right: 0;
    left: -12px;
  }
  .main-timeline .timeline-content:before{ left: -7.3px; }
  .main-timeline .timeline-content:after{ left: -14%; }
}


.voterheader {
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -4px, rgb(0 0 0 / 20%) 0px -1px 0px inset;
  border-radius: 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background-color: #05060824;
}

.endcol{
  margin-left: 1rem;
  margin-right: 1rem;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -4px, rgba(0, 0, 0, 0.20) 0px -1px 0px inset;
  border-radius: 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background-color: #05060824;
}

.cow {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(15 47 50);
  background: -webkit-linear-gradient(#3e1046, #6e2a57) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  border-radius: 0.5rem;
  color: #296992;
  margin-right: 10px !important;
}

.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 0.6);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}

.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}

.pending-col2 {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: Source Code Pro,monospace !important;
}

.divider {
  border: none;
  height: 20px;
  width: 90%;
  height: 50px;
  margin-top: 0;
  border-bottom: 1px solid #1f1209;
  box-shadow: 0 20px 20px -20px #333;
  margin: -50px auto 10px;
}

/deep/ .progress-wrapper:not(:last-child) {
  margin-bottom: 0 !important;
}

/deep/ .progress-wrapper .progress.is-medium + .progress-value, .progress-wrapper .is-medium.progress-wrapper.is-not-native + .progress-value, .progress-wrapper .progress.is-medium .progress-value, .progress-wrapper .is-medium.progress-wrapper.is-not-native .progress-value, .progress-wrapper .progress-bar.is-medium + .progress-value, .progress-wrapper .progress-bar.is-medium .progress-value {
  font-size: calc(1.25rem / 1.5);
  line-height: 1.25rem;
  color: #772b7c !important;
  background-color: transparent !important;
}

.descriptionSection {
  position: relative;
  top: 10px;
  color: #3abb81;
}

.leatherbg {
  background: radial-gradient(#00000078 0%, transparent 10%) 0 0, radial-gradient(#0000005c 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 250, 250, 0) 15%, transparent 20%) 8px 9px;
  background-color: #2c243275 !important;
  background-size: 16px 16px;
  border: 2px solid #11426573;
  background-blend-mode: darken;
}

.small-pool {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 3%;
}

::v-deep .accordion__item {
  cursor: pointer;
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

/deep/ .accordion__item {
  cursor: pointer;
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

.accordion__item {
  cursor: pointer;
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

.highlight-span {
  background-color: rgba(39, 99, 74, 0.68);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  box-shadow: rgba(24, 202, 255, 0.13) 0px 0px 30px 3px !important;
  background-blend-mode: color;
  border: 1px solid rgba(177, 76, 226, 0.56) !important;
  outline: none;
}

.highlight-span:before {
  background-color: #0a0a0a;
  padding: 7px 20px;
  display: block;
  border-radius: 50px;
  border: 4px solid #3abb81;
  background-blend-mode: color;
}

.highlight-span:focus,
.highlight-span:hover {
  background-color: rgba(27, 94, 71, 0.68);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 4px solid #309a6a;
  outline: none;
}

/deep/ .columns:not(:last-child) {
  margin-bottom: 0px;
}

.stake-col-lg{
}

.stake-col-sm{
  padding-left: 0px;
}

.withdraw-col-lg{
  padding-right: 0px;
}

.withdraw-col-sm{
  padding-left: 0px;
}

.withdraw-btn-lg{
  border-bottom-left-radius: 1rem !important; border-top-left-radius: 1rem !important;
}
.withdraw-btn-sm{
  border-radius: 1rem;
}

.claim-btn-lg{
  border-bottom-right-radius: 1rem !important; border-top-right-radius: 1rem !important;
}

.claim-btn-sm{
  border-radius: 1rem;
}

/deep/ .minus {
  border-top-left-radius: 1rem;
}

/deep/ .b-numberinput input {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
  box-shadow: inset 0 30px 60px -12px rgba(50, 50, 93, 0.25), inset 0 18px 36px -18px rgba(0, 0, 0, 0.30);
}

/deep/ .button.is-buttonblue {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}


/deep/ .button.is-buttonblue:hover {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}

/* card background */
.home-card-bg {
  background: #ebeae5;
}

.inset-bg {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding-bottom: 2rem;
}

.inset-bg2:active {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  width: 100%;
  height: 30px;
  position: relative;
  border-radius: 1rem;
  align-self: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 4px;
}


.col-title {
  color: #9573ba;
  font-size: 12px;
  font-family: Source Code Pro,monospace !important;
}

.col-title2 {
  color: #36c0ea !important;
  font-size: 12px !important;
  font-family: Source Code Pro,monospace !important;
}

.pending-title {
  color: rgb(120 161 235);
  font-size: 14px;
}

.pool-control-title{
  color: #49566c;
  font-size: 14px;
}

.pool-control-title:hover{
  color: #3abb81;
  font-size: 14px;
}

.pending-stat {
  color: #3cea36;
  font-size: 1em;
}

.pending-col {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: Source Code Pro,monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.col-shadow1 {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider {
  font-size: 12px;
}

.pool-name {
  font-size: 16px;
  color: rgb(120 161 235);
  font-family: Source Code Pro,monospace !important;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: #293038;
}

.pool-main2 {
  background-color: transparent !important;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border: 1px solid #554460;
  box-shadow: rgb(0 0 0 / 6%) 0px -23px 25px 0px inset, rgb(0 0 0 / 9%) 0px -36px 30px 0px inset, rgb(0 0 0 / 7%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;
}

.pool-item:hover {
  background-color: #333b4c;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border: 1px solid rgba(3, 255, 150, 0.38);
  box-shadow: rgb(0 0 0 / 11%) 0px -23px 25px 0px inset, rgb(0 0 0 / 9%) 0px -36px 30px 0px inset, rgb(0 0 0 / 7%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;
}

.poolselect-bg {
  background-color: #293038 !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

/deep/ .tabs.is-toggle li.is-active a {
  background-color: #314b6a;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #3abb81;
  z-index: 1;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .tabs.is-toggle a {
  border-color: #575f85;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 0;
  position: relative;
  font-family: Source Code Pro,monospace !important;
  color: #195da7;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
  color: #00b89c;
}


.pools-card {
  padding-top: .5rem;
  color: #0d64c6;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
}

/deep/ .field.has-addons .control:first-child .control .button, .field.has-addons .control:first-child .control .input, .field.has-addons .control:first-child .control .select select {
  border-radius: 0 !important;
}

/deep/ .field.has-addons:first-child  .control:first-child .control:first-child  .button:first-child , .field.has-addons:first-child  .control:first-child .control:first-child  .input:first-child , .field.has-addons:first-child .control:first-child .control:first-child  .select:first-child  select:first-child{
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.stake-btn-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.stake-btn-2 {
  font-size: 14px !important;
  max-height: 40px;
  padding-left: 12px !important;
}

.btn {
  color: #87b6e5;
  background: rgb(34 77 118);
  background-repeat: no-repeat;
  font-family: Source Code Pro,monospace !important;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.btn:focus,
.btn:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    margin-bottom: 30px;
  }
}

</style>
<style lang="scss" scoped>

.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro,monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

</style>
<style scoped>
.container2 {
  background-color: #1D1F21;
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  padding: 25px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), transparent 85%), radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%), linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 7px -4px #4f545f inset, 0 -2px 1px #5c6574 inset;
}
</style>
<style lang="scss" scoped>
article {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 900px) {
    padding: 80px;
  }

  @media (max-width: 899px) {
    padding-bottom: 56px;
  }
  background-color: rgba(192, 192, 192, 0);
  color: #323232;
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  position: relative;
  & > * + * {
    margin-top: 1.25em;
  }

  &:after {
    content: "🥩";
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding-bottom: 0.25em;
    position: absolute;
    top: 0.75em;
    right: 0.75em;
    width: 1.75em;
    height: 1.75em;
    filter: grayscale(90%);
    background-color: transparent;
    border-left: 1px solid rgba(238, 238, 238, 0.16);
    border-top: 1px solid rgba(238, 238, 238, 0.06);
    border-right: 1px solid rgba(68, 68, 68, 0.41);
    border-bottom: 1px solid rgba(68, 68, 68, 0.39);
  }
}

.headline {
  font-size: 2.5em;
  font-weight: 700;
}

.subhead {
  font-size: 1.25em;
  margin-top: 0.25em;
}

.article-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    width: 3em;
    height: 3em;
    flex-shrink: 0;
  }

  div:first-of-type {
    margin-left: 0.75em;
    font-size: 0.875em;
    margin-right: auto;
    padding-right: 0.75em;
  }

  div:last-of-type {
    font-size: 0.875em;
  }
}

aside {
  padding: 1em;
  border-right: 1px solid rgba(238, 238, 238, 0.11);
  border-bottom: 1px solid rgba(238, 238, 238, 0.06);
  border-left: 1px solid rgba(68, 68, 68, 0.45);
  border-top: 1px solid rgba(68, 68, 68, 0.39);
  clear: both;
  text-shadow: 0 0 10px, 0 0 40px;
}

.tag {
  display: inline-block;
  margin-right: 0.25em;
  margin-top: 0.25em;
  border-right: 1px solid rgba(238, 238, 238, 0.19);
  border-bottom: 1px solid rgba(238, 238, 238, 0.13);
  border-left: 1px solid #444;
  border-top: 1px solid #444;
  padding: 0.125em 0.375em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

h2:not(.subhead) {
  font-size: 1.5em;
  font-weight: 700;
  padding-bottom: 0.375em;
  box-shadow: 0 1px 0 0 #444, 0 2px 0 0 #eee;
}

figure {
  background-color: #c0c0c0;
  box-shadow: 0 0 0 1px #eee, 0 0 0 2px #c0c0c0, 0 0 0 3px #444;
  position: relative;
  margin-bottom: 1.25em;
  width: calc(100% + 1.5em + 2em);
  text-shadow: 0 0 10px, 0 0 40px;
  @media (min-width: 800px) {
    max-width: 50%;
  }
  &:nth-of-type(odd) {
    float: left;
    margin-right: 1.5em;
    margin-left: -3em;
  }

  &:nth-of-type(even) {
    float: right;
    margin-left: 1.5em;
    margin-right: -3em;
  }

  &:after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    background-image: linear-gradient(
            to top,
            rgba(#ff9100, 0.2) 0%,
            rgba(#ffe630, 0.2) 60%
    ),
    linear-gradient(20deg, rgba(#ff0, 0.5) 0%, rgba(#ff0, 0) 35%);
    box-shadow: inset 0px 0px 100px rgba(0, 0, 20, 1);
    z-index: 1;
  }

  img {
    // opacity: 0;
    // filter: hue-rotate(90deg);
    // filter: sepia(20%) brightness(10%) contrast(130%)
  }
  figcaption {
    font-family: "DotGothic16", monospace;
    background-color: #6f4b80;
    box-shadow: inset 0 -1px 0 0 #020202;
    color: #eee;
    text-shadow: 0 0 10px, 0 0 40px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.25em;
    font-size: 0.875em;
    z-index: 2;
  }
}



</style>
