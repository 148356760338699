<template>
  <transition name="fade">
    <div id="app" >
      <DAOsPage ></DAOsPage>
    </div>
  </transition>
</template>

<script>
import DAOsPage from '../components/DAOsPage.vue'

export default {
  name: 'Home',
  components: {
    DAOsPage
  },
}
</script>

<style>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
