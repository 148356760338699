<template>
  <div style="overflow-x: hidden" :style="$screen.width > 1024 ? 'margin-top: -85px' : 'margin-top: -84px'">
    <transition name="fade">


      <!--    Page loading animation section-->
      <div v-if="this.showLoadingModal === true" style="height: 100vh">
        <div class="loader2">
          <div class="loader__bar"></div>
          <div class="loader__bar loader__bar--delay-1"></div>
          <div class="loader__bar loader__bar--delay-2"></div>
          <div class="loader__bar loader__bar--delay-3"></div>
          <div class="loader__bar loader__bar--delay-4"></div>
          <div class="loader__bar loader__bar--delay-5"></div>
          <div>
          </div>
        </div>
      </div>

      <!--    Homepage shown after loading is completed-->
      <section v-if="this.showLoadingModal === false">


        <!--      bubbles-->
        <div
            v-if="this.showLoadingModal === false"
            id="style-10"
            style="width:100vw; overflow-x: hidden !important; overflow-y: auto"
            :style="this.getShowNavBar() === true ? 'height: 92vh !important' : 'height: 104vh !important'">

          <section class="hero" style="z-index: 2">
            <div class="hero-body header-bg3" style="z-index: 2"></div>
          </section>


          <section class="hero" :class="$screen.width > 1024 ? '' : ''" style="z-index: 2">

            <div class="columns mt-4 is-flex-direction-column">

              <div class="column" style="z-index: 2;">

                <div class="machineinterface">
                  <div class="monitor">
                    <div class="monitor-inner" style="padding-bottom: 60px">
                      <div class="screen-cutout">
                        <div class="screen">

                          <section class="columns is-justify-content-center">

                            <div class="my-pools-shadow column is-full"
                                 style="z-index: 2; padding: 0px !important;"
                                 :style="$screen.width < 480 ? 'margin-right: 8px' : ''">

                              <div class="card pools-bg  cardborder">

                                <div class="card-content poolselect-bg is-hidden">
                                  <section>
                                    <b-tabs v-model="activeTab" class="font1" type="is-toggle" expanded>
                                      <b-tab-item :visible="true" value="-1" label="Factory Creation Tools"></b-tab-item>
                                      <b-tab-item :visible="false" value="0" label="Staking Pools"></b-tab-item>
                                      <!--                                    <b-tab-item value="1" label="Vesting Pools"></b-tab-item>-->
                                      <b-tab-item :visible="false" value="2" label="Liquidity Pools"></b-tab-item>
                                      <b-tab-item :visible="false" value="4" label="Crowdfunding Pools"></b-tab-item>
                                      <b-tab-item :visible="false" value="6" label="Vesting Pools"></b-tab-item>
                                      <b-tab-item :visible="false" value="5" label="NFT"></b-tab-item>
                                      <b-tab-item :visible="false" value="7" label="DAO"></b-tab-item>
                                    </b-tabs>
                                  </section>
                                </div>

                                <!-- Making pool section-->
                                <div v-if="makingPool === true && welcome === false && finishedCreating === false"
                                     class="column pool-main is-centered mb-0">

                                  <div class="columns is-centered">


                                    <div class="column pool-item is-centered is-align-self-center">
                                      <div class="columns">
                                        <div class="column is-align-self-center inset-bg">
                                          <div class="section" style="margin-top: -100px">
                                            <div class="wave__content" style="top: 7px; z-index: 2">
                                              <div class="wave__header">
                                                <div class="wave__menu"></div>
                                              </div>
                                            </div>

                                            <div class="box" style="height: 443px;">

                                              <div id="wraper2" class="container ontop centered"
                                                   style="margin-top: 50px;">

                                                <div id="wraper">
                                                  <section>
                                                    <svg id="Layer_1" data-name="Layer 1"
                                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 213.19 91.4">
                                                      <defs>
                                                      </defs>

                                                      <polygon class="cls-1"
                                                               points="112.66 68.57 95.77 68.57 95.77 85.55 112.66 85.55 112.66 68.57 112.66 68.57"/>
                                                      <path class="cls-2"
                                                            d="M31.28,84.41a1.48,1.48,0,1,1,0-3h8.43a3,3,0,0,0,3-3V52.65a6,6,0,0,1,6-6h39a1.48,1.48,0,0,1,0,3h-39a3,3,0,0,0-3,3v25.8a6,6,0,0,1-6,6Z"
                                                            transform="translate(0 -1.87)"/>
                                                      <polygon class="cls-3"
                                                               points="126.26 68.57 132.11 68.57 132.11 0 126.26 0 126.26 68.57 126.26 68.57"/>
                                                      <polygon class="cls-3"
                                                               points="135.56 68.57 141.42 68.57 141.42 0 135.56 0 135.56 68.57 135.56 68.57"/>
                                                      <polygon class="cls-1"
                                                               points="135.56 68.57 137.44 68.57 137.44 0 135.56 0 135.56 68.57 135.56 68.57"/>
                                                      <polygon class="cls-1"
                                                               points="126.26 68.57 128.14 68.57 128.14 0 126.26 0 126.26 68.57 126.26 68.57"/>
                                                      <path class="cls-3"
                                                            d="M50.41,87.42H63.94V37.83c0-3.74-3-5.92-6.77-5.92s-6.76,2.18-6.76,5.92V87.42Z"
                                                            transform="translate(0 -1.87)"/>
                                                      <path class="cls-1"
                                                            d="M50.41,87.42h4.45V37.83c-.23-6.95,5.16-5.47,6.34-4.89a7.89,7.89,0,0,0-4-1c-3.73,0-6.76,2.18-6.76,5.92V87.42Z"
                                                            transform="translate(0 -1.87)"/>
                                                      <path class="cls-3"
                                                            d="M65.66,87.42H79.2V37.83c0-3.74-3-5.92-6.77-5.92s-6.77,2.18-6.77,5.92V87.42Z"
                                                            transform="translate(0 -1.87)"/>
                                                      <path class="cls-1"
                                                            d="M65.66,87.42h4.45V37.83c-.22-6.95,5.17-5.47,6.35-4.89a7.89,7.89,0,0,0-4-1c-3.74,0-6.77,2.18-6.77,5.92V87.42Z"
                                                            transform="translate(0 -1.87)"/>
                                                      <polygon class="cls-1"
                                                               points="181.79 51.58 150.81 51.58 150.81 85.55 181.79 85.55 181.79 51.58 181.79 51.58"/>
                                                      <polygon class="cls-3"
                                                               points="181.79 51.58 206.39 51.58 206.39 85.55 181.79 85.55 181.79 51.58 181.79 51.58"/>
                                                      <polygon class="cls-3"
                                                               points="112.66 68.57 174.99 68.57 174.99 85.55 112.66 85.55 112.66 68.57 112.66 68.57"/>
                                                      <polygon class="cls-4"
                                                               points="152.72 71.6 134.92 71.6 134.92 75.47 152.72 75.47 152.72 71.6 152.72 71.6"/>
                                                      <polygon class="cls-5"
                                                               points="132.75 71.6 114.96 71.6 114.96 75.47 132.75 75.47 132.75 71.6 132.75 71.6"/>
                                                      <polygon class="cls-5"
                                                               points="172.69 71.6 154.89 71.6 154.89 75.47 172.69 75.47 172.69 71.6 172.69 71.6"/>
                                                      <path class="cls-3"
                                                            d="M80.92,87.42H94.45V37.83c0-3.74-3-5.92-6.76-5.92s-6.77,2.18-6.77,5.92V87.42Z"
                                                            transform="translate(0 -1.87)"/>
                                                      <path class="cls-1"
                                                            d="M80.92,87.42h4.45V37.83c-.22-6.95,5.17-5.47,6.34-4.89a7.85,7.85,0,0,0-4-1c-3.74,0-6.77,2.18-6.77,5.92V87.42Z"
                                                            transform="translate(0 -1.87)"/>
                                                      <path class="cls-6"
                                                            d="M94.45,87.42H79.2v-17h17v17Zm0-1.73Zm-1.22,0H82.14l5.55-5.54,5.54,5.54Zm-6.76-6.76-5.55,5.54V73.38l5.55,5.55Zm-4.33-6.77H93.23l-5.54,5.55-5.55-5.55Zm6.77,6.77,5.54-5.55V84.47l-5.54-5.54Z"
                                                            transform="translate(0 -1.87)"/>
                                                      <path class="cls-6"
                                                            d="M79.2,87.42H63.94v-17h17v17Zm0-1.73Zm-1.23,0H66.88l5.55-5.54L78,85.69Zm-6.76-6.76-5.55,5.54V73.38l5.55,5.55Zm-4.33-6.77H78l-5.54,5.55-5.55-5.55Zm6.77,6.77,5.55-5.55V84.47l-5.55-5.54Z"
                                                            transform="translate(0 -1.87)"/>
                                                      <path class="cls-6"
                                                            d="M63.94,87.42H48.68v-17h17v17Zm0-1.73Zm-1.22,0H51.63l5.54-5.54,5.55,5.54ZM56,78.93l-5.54,5.54V73.38L56,78.93Zm-4.32-6.77H62.72l-5.55,5.55-5.54-5.55Zm6.76,6.77,5.55-5.55V84.47l-5.55-5.54Z"
                                                            transform="translate(0 -1.87)"/>
                                                      <polygon class="cls-5"
                                                               points="199.58 79.13 188.59 79.13 188.59 83 199.58 83 199.58 79.13 199.58 79.13"/>
                                                      <polygon class="cls-4"
                                                               points="199.58 71.6 188.59 71.6 188.59 75.47 199.58 75.47 199.58 71.6 199.58 71.6"/>
                                                      <polygon class="cls-5"
                                                               points="199.58 64.07 188.59 64.07 188.59 67.94 199.58 67.94 199.58 64.07 199.58 64.07"/>
                                                      <polygon class="cls-5"
                                                               points="199.58 56.54 188.59 56.54 188.59 60.41 199.58 60.41 199.58 56.54 199.58 56.54"/>
                                                      <path class="cls-6"
                                                            d="M123.67,68.71V22.2h11V70.44h-11V68.71Zm8.09-44.79H125.4v6.36l6.36-6.36ZM133,31.5V25.14l-6.36,6.36Zm-7.58,3V40.8h6.36l-6.36-6.35ZM133,39.58V33.23h-6.36L133,39.58Zm-1.22,3H125.4v6.36l6.36-6.36ZM133,50.11V43.75l-6.36,6.36Zm-7.58,2.94v6.36h6.36l-6.36-6.36ZM133,58.19V51.83h-6.36L133,58.19Zm-1.22,2.94H125.4v6.36l6.36-6.36ZM133,68.71V62.35l-6.36,6.36Z"
                                                            transform="translate(0 -1.87)"/>
                                                      <path class="cls-6"
                                                            d="M133,68.71V22.2h11V70.44H133V68.71Zm8.08-44.79H134.7v6.36l6.36-6.36Zm1.22,7.58V25.14l-6.36,6.36Zm-7.58,3V40.8h6.36l-6.36-6.35Zm7.58,5.13V33.23h-6.36l6.36,6.35Zm-1.22,3H134.7v6.36l6.36-6.36Zm1.22,7.58V43.75l-6.36,6.36Zm-7.58,2.94v6.36h6.36l-6.36-6.36Zm7.58,5.14V51.83h-6.36l6.36,6.36Zm-1.22,2.94H134.7v6.36l6.36-6.36Zm1.22,7.58V62.35l-6.36,6.36Z"
                                                            transform="translate(0 -1.87)"/>
                                                      <polygon class="cls-3"
                                                               points="13.9 85.55 39.71 85.55 39.71 68.57 26.8 60.24 13.9 68.57 13.9 85.55 13.9 85.55"/>
                                                      <polygon class="cls-4"
                                                               points="33.26 75.47 20.35 75.47 20.35 85.55 33.26 85.55 33.26 75.47 33.26 75.47"/>
                                                      <polygon class="cls-1"
                                                               points="13.9 68.57 0.99 68.57 0.99 85.55 13.9 85.55 13.9 68.57 13.9 68.57"/>
                                                      <polygon class="cls-7"
                                                               points="13.9 68.57 0.99 68.57 13.9 60.24 26.8 60.24 13.9 68.57 13.9 68.57"/>
                                                      <polygon class="cls-8"
                                                               points="141.42 2.93 135.56 2.93 135.56 8.78 141.42 8.78 141.42 2.93 141.42 2.93"/>
                                                      <polygon class="cls-9"
                                                               points="137.44 2.93 135.56 2.93 135.56 8.78 137.44 8.78 137.44 2.93 137.44 2.93"/>
                                                      <polygon class="cls-8"
                                                               points="132.11 2.93 126.26 2.93 126.26 8.78 132.11 8.78 132.11 2.93 132.11 2.93"/>
                                                      <polygon class="cls-9"
                                                               points="128.14 2.93 126.26 2.93 126.26 8.78 128.14 8.78 128.14 2.93 128.14 2.93"/>
                                                      <polygon class="cls-8"
                                                               points="94.45 35.96 80.92 35.96 80.92 41.81 94.45 41.81 94.45 35.96 94.45 35.96"/>
                                                      <polygon class="cls-9"
                                                               points="85.37 35.96 80.92 35.96 80.92 41.81 85.37 41.81 85.37 35.96 85.37 35.96"/>
                                                      <polygon class="cls-8"
                                                               points="79.19 35.96 65.66 35.96 65.66 41.81 79.19 41.81 79.19 35.96 79.19 35.96"/>
                                                      <polygon class="cls-9"
                                                               points="70.11 35.96 65.66 35.96 65.66 41.81 70.11 41.81 70.11 35.96 70.11 35.96"/>
                                                      <polygon class="cls-8"
                                                               points="63.94 35.96 50.41 35.96 50.41 41.81 63.94 41.81 63.94 35.96 63.94 35.96"/>
                                                      <polygon class="cls-9"
                                                               points="54.85 35.96 50.41 35.96 50.41 41.81 54.85 41.81 54.85 35.96 54.85 35.96"/>
                                                      <path class="cls-2"
                                                            d="M108.65,84.41a1.48,1.48,0,0,0,0-3h-7.71a2.63,2.63,0,0,1-2-.89,3.13,3.13,0,0,1-.81-2.12v-8a6.23,6.23,0,0,0-1.6-4.22,5.2,5.2,0,0,0-3.85-1.75H57.05a1.49,1.49,0,0,0,0,3H92.73a2.64,2.64,0,0,1,1.94.88,3.16,3.16,0,0,1,.81,2.13v8a6.27,6.27,0,0,0,1.6,4.21,5.24,5.24,0,0,0,3.86,1.75Z"
                                                            transform="translate(0 -1.87)"/>
                                                      <polygon class="cls-10"
                                                               points="30.96 76.58 20.35 76.58 20.35 85.55 30.96 85.55 30.96 76.58 30.96 76.58"/>
                                                      <polygon class="cls-10"
                                                               points="150.43 72.71 134.92 72.71 134.92 75.47 150.43 75.47 150.43 72.71 150.43 72.71"/>
                                                      <polygon class="cls-10"
                                                               points="197.29 72.71 188.59 72.71 188.59 75.47 197.29 75.47 197.29 72.71 197.29 72.71"/>
                                                      <polygon class="cls-11"
                                                               points="130.46 72.71 114.96 72.71 114.96 75.47 130.46 75.47 130.46 72.71 130.46 72.71"/>
                                                      <polygon class="cls-11"
                                                               points="170.39 72.71 154.89 72.71 154.89 75.47 170.39 75.47 170.39 72.71 170.39 72.71"/>
                                                      <polygon class="cls-11"
                                                               points="197.29 65.18 188.59 65.18 188.59 67.94 197.29 67.94 197.29 65.18 197.29 65.18"/>
                                                      <polygon class="cls-11"
                                                               points="197.29 57.65 188.59 57.65 188.59 60.41 197.29 60.41 197.29 57.65 197.29 57.65"/>
                                                      <polygon class="cls-11"
                                                               points="197.29 80.24 188.59 80.24 188.59 83 197.29 83 197.29 80.24 197.29 80.24"/>
                                                      <path class="cls-2"
                                                            d="M167.44,70.44h2.95v-17a3,3,0,0,1,3-3h14.48a3,3,0,0,1,3,3h3a6,6,0,0,0-6-6H173.4a6,6,0,0,0-6,6v17Z"
                                                            transform="translate(0 -1.87)"/>
                                                      <path class="cls-2"
                                                            d="M161.53,70.44h3v-17a3,3,0,0,1,3-3H182a3,3,0,0,1,2.12.89,3,3,0,0,1,.89,2.12h3a6,6,0,0,0-6-6H167.5a6,6,0,0,0-6,6v17Z"
                                                            transform="translate(0 -1.87)"/>
                                                      <polygon class="cls-6"
                                                               points="213.19 85.55 0 85.55 0 91.4 213.19 91.4 213.19 85.55 213.19 85.55"/>
                                                      <polygon class="cls-12"
                                                               points="80.05 85.55 60.69 85.55 60.69 91.4 80.05 91.4 80.05 85.55 80.05 85.55"/>
                                                      <polygon class="cls-12"
                                                               points="105.86 85.55 86.5 85.55 86.5 91.4 105.86 91.4 105.86 85.55 105.86 85.55"/>
                                                    </svg>
                                                  </section>
                                                  <section style="position: absolute; top: -121px; left: 144px;">
                                                    <div id="stand1" class="stand"></div>
                                                    <div id="stand2" class="stand"></div>
                                                    <div id="band">
                                                      <div id="wheel1" class="wheel"></div>
                                                      <div id="wheel2" class="wheel"></div>
                                                      <div id="wheel3" class="wheel"></div>
                                                      <div id="wheel4" class="wheel"></div>
                                                    </div>
                                                    <div id="package1" class="package">🥩</div>
                                                    <div id="smoke1" class="smoke">
                                                      <div id="sCloud1" class="sCloud"></div>
                                                      <div id="sCloud2" class="sCloud"></div>
                                                      <div id="sCloud3" class="sCloud"></div>
                                                    </div>
                                                    <div id="smoke2" class="smoke">
                                                      <div id="sCloud4" class="sCloud"></div>
                                                      <div id="sCloud5" class="sCloud"></div>
                                                      <div id="sCloud6" class="sCloud"></div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>


                                              <div style="margin-top: -220px">
                                                <h1 class="title blue-bg">Please wait while we make your {{isMakingItem}}...</h1>
                                              </div>


                                            </div>


                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                  </div>


                                </div>

                                <!-- Collection created section-->
                                <div v-if="finishedCreating === true"
                                     class="column pool-main is-centered mb-0">

                                  <div class="columns is-centered">


                                    <div class="column pool-item is-centered is-align-self-center">
                                      <div class="columns">
                                        <div class="column is-align-self-center inset-bg">
                                          <div class="section" style="margin-top: -100px">
                                            <div class="wave__content" style="top: 7px; z-index: 2">
                                              <div class="wave__header">
                                                <div class="wave__menu"></div>
                                              </div>
                                            </div>

                                            <div class="box" style="height: 443px;">

                                              <div style="margin-top: 100px">
                                                <h1 class="title blue-bg">{{finishedCreating_message}}</h1>
                                              </div>


                                            </div>


                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                  </div>


                                </div>



                                <!-- Welcome select choice section -->
                                <div  v-if="welcome === true && finishedCreating === false"
                                     class="column pool-main is-centered mb-0">
                                  <div class="columns is-centered">


                                    <!-- Make something SECTION -->
                                    <div
                                         class="column pool-item is-centered is-align-self-center">
                                      <div class="columns">
                                        <div class="column is-align-self-center inset-bg">
                                          <div class="section" style="margin-top: -100px; padding: 0px !important;">
                                            <div class="wave__content" style="top: 7px; z-index: 2">
                                              <div class="wave__header">
                                                <div class="wave__menu"></div>
                                              </div>
                                            </div>

                                            <div class="box">

                                              <section class="hero" >
                                                <div class="hero-body">
                                                  <div class="container animated fadeInUp">
                                                    <div class="columns is-mobile is-flex-direction-column">
                                                      <div class="column is-12-desktop is-12-tablet is-12-mobile web mt-2">
                                                        <ul class="list-unstyled has-text-centered">
                                                          <li class="cow" style="font-size:20px">What would you like to create:</li>
                                                        </ul>
                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>
                                                      </div>
                                                      <div class="column is-12-desktop is-12-tablet is-12-mobile web">
                                                        <div class="columns is-multiline is-vcentered is-justify-content-space-evenly">
                                                          <div @click="activeTab = '0'" class="column is-8 account-bg1 bginput">
                                                            <div class="columns">
                                                              <div class="column is-4 createborder">
                                                                <span class="cow3">Create a </span>
                                                                <br/><br/>
                                                                <img src="../assets/staking-pool-icon.svg" height="48" width="48"/>
                                                                <br/><br/>
                                                                <span class="cow2">Staking Pool</span>
                                                              </div>
                                                              <div class="column is-8 is-flex is-flex-direction-column is-justify-content-center">
                                                                <span class="cow">Create a pool that distributes tokens to stakers. </span>
                                                              </div>
                                                            </div>
                                                          </div>


                                                          <div @click="activeTab = '2'" class="column is-8 account-bg1 bginput mt-4">
                                                            <div class="columns">
                                                              <div class="column is-4 createborder">
                                                                <span class="cow3">Create a </span>
                                                                <br/><br/>
                                                                <img src="../assets/lp-pool-icon.svg" height="50" width="50"/>
                                                                <br/><br/>
                                                                <span class="cow2">Liquidity Pool</span>
                                                              </div>
                                                              <div class="column is-8 is-flex is-flex-direction-column is-justify-content-center">
                                                                <span class="cow">Create a pool that allows 2 tokens to be swapped between each other. </span>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div @click="activeTab = '4'" class="column is-8 account-bg1 bginput mt-4">
                                                            <div class="columns">
                                                              <div class="column is-4 createborder">
                                                                <span class="cow3">Create a </span>
                                                                <br/><br/>
                                                                <img src="../assets/crowdfunding-pool-icon.svg" height="66" width="66"/>
                                                                <br/><br/>
                                                                <span class="cow2">Crowdfunding Pool</span>
                                                              </div>
                                                              <div class="column is-8 is-flex is-flex-direction-column is-justify-content-center">
                                                                <span class="cow">Create a pool that raises funds and forms a liquidity pool for your token. </span>
                                                              </div>
                                                            </div>
                                                          </div>


                                                          <div @click="activeTab = '6'" class="column is-8 account-bg1 bginput mt-4">
                                                            <div class="columns">
                                                              <div class="column is-4 createborder">
                                                                <span class="cow3">Create a </span>
                                                                <br/><br/>
                                                                <img src="../assets/vesting-pool-icon.svg" height="66" width="66"/>
                                                                <br/><br/>
                                                                <span class="cow2">Vesting Pool</span>
                                                              </div>
                                                              <div class="column is-8 is-flex is-flex-direction-column is-justify-content-center">
                                                                <span class="cow">Create a pool that distributes tokens to a set of accounts. </span>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div @click="activeTab = '5'" class="column is-8 account-bg1 bginput mt-4">
                                                            <div class="columns">
                                                              <div class="column is-4 createborder">
                                                                <span class="cow3">Create </span>
                                                                <br/><br/>
                                                                <img src="../assets/nft-pool-icon.svg" height="50" width="50"/>
                                                                <br/><br/>
                                                                <span class="cow2">NFT Solutions</span>
                                                              </div>
                                                              <div class="column is-8 is-flex is-flex-direction-column is-justify-content-center">
                                                                <span class="cow">Create NFTs, NFT collections, and NFT Whitelist Presales. </span>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div @click="activeTab = '7'" class="column is-8 account-bg1 bginput mt-4">
                                                            <div class="columns">
                                                              <div class="column is-4 createborder">
                                                                <span class="cow3">Create</span>
                                                                <br/><br/>
                                                                <img src="../assets/whitelist-icon.svg" height="50" width="50"/>
                                                                <br/><br/>
                                                                <span class="cow2">DAO Solutions</span>
                                                              </div>
                                                              <div class="column is-8 is-flex is-flex-direction-column is-justify-content-center">
                                                                <span class="cow">Create & Manage DAO Subjects, Voting Polls and Pools. </span>
                                                              </div>
                                                            </div>
                                                          </div>



                                                        </div>
                                                      </div>
                                                      <br/>
                                                      <br/>
                                                    </div>
                                                  </div>
                                                </div>
                                              </section>


                                            </div>


                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>


                                <!-- Make a pool section-->
                                <div v-if="makingPool === false && welcome === false && makeNftSelection === '0' && makeDaoSelection === '0' && finishedCreating === false"
                                     class="column pool-main is-centered mb-0">
                                  <div class="columns is-centered">

                                    <!-- STAKING POOLS SECTION -->
                                    <div v-if="activeTab === '0'"
                                         class="column pool-item is-centered is-align-self-center">
                                      <div class="columns">
                                        <div class="column is-align-self-center inset-bg">
                                          <div class="section" style="margin-top: -100px">
                                            <div class="wave__content" style="top: 7px; z-index: 2">
                                              <div class="wave__header">
                                                <div class="wave__menu"></div>
                                              </div>
                                            </div>

                                            <ValidationObserver v-slot="{ valid }">

                                              <div class="box">

                                                <!-- Create Pool Form -->
                                                <accordion>
                                                  <accordion-item>
                                                    <template slot="accordion-trigger">
                                                      <h1 class="title blue-bg info-icon" style="font-size: 25px; padding-top: 2rem; padding-bottom: 2rem;">Create a Staking Pool</h1>
                                                    </template>
                                                    <template slot="accordion-content">
                                                      <div class="blue-bg-2 has-text-left">
                                                        <span style="font-size: 17px">What are staking pools?</span>
                                                        <br/><br/>
                                                        Staking pools <span style="font-weight: bold">distribute 'Reward Tokens' over time</span>
                                                        to users who <span style="font-weight: bold">stake</span> or
                                                        <span
                                                            style="font-weight: bold">lock</span> their <span
                                                          style="font-weight: bold">'Staking Tokens'</span> in the
                                                        Staking
                                                        Pool.
                                                        <br/><br/>
                                                        Use the Stake Factory Staking Pool Creator to create traditional
                                                        and
                                                        non-tradtional Defi staking pools of various flavors where <span
                                                          style="font-weight: bold">pool members are rewarded over time for locking tokens of various types</span>.
                                                        <br/><br/>
                                                        <span style="font-size: 17px">What does this tool do?</span>
                                                        <br/><br/>
                                                        The Stake Factory Staking Pool Creator Tool allows anyone to create a staking pool and automatically launches it on the Stake Factory website.
                                                        <br/><br/>
                                                        The Staking Pool Creator can currently whip up
                                                        <span
                                                            style="font-weight: bold">several types of staking pools</span>
                                                        and
                                                        distribute several different types of token formats to your
                                                        staking audience, both <span
                                                          style="font-weight: bold">fungible</span> and <span
                                                          style="font-weight: bold">non-fungible</span> formats - You
                                                        pick
                                                        what is <span style="font-weight: bold">staked</span> and you
                                                        pick
                                                        what is <span style="font-weight: bold">rewarded</span> (and how).
                                                        <br/><br/>
                                                        The Pool Creator Tool gives pool creators great control over
                                                        <span
                                                            style="font-weight: bold">how</span> reward tokens become
                                                        available to
                                                        stakers in their staking pools <span style="font-weight: bold">over the course of time</span>
                                                        - Set <span style="font-weight: bold">exactly</span> how many
                                                        fractions of a
                                                        token you want to release <span style="font-weight: bold">per moment</span>,
                                                        and fine tune controls such as <span style="font-weight: bold">withdraw lock times</span>,
                                                        and <span style="font-weight: bold">reward claim times</span>.
                                                        <br/><br/>
                                                        Once published,<span style="font-weight: bold"> Stake Factory Staking Pools appear on the Stake Factory website</span>,
                                                        <span style="font-weight: bold">allowing the public un-interrupted access to your staking solution</span>.
                                                        <br/><br/>
                                                        Stake Factory pools automatically keep up with what is owed to
                                                        each staker
                                                        in a pool no matter what type of staking pool you can whip up -
                                                        Feel free to
                                                        release sub fractions of reward tokens at sub-second intervals
                                                        to
                                                        your
                                                        valued stakers <span
                                                          style="font-weight: bold">safely and properly</span>,
                                                        all the while providing an extra <span
                                                          style="font-weight: bold">layer of trust</span>
                                                        between stakers, your goals and your incentives, all with Stake
                                                        Factory
                                                        pools.
                                                        <br/><br/>
                                                        Once published, visit your pool's <span style="font-weight: bold">'Pool Creator' section</span>
                                                        by accessing your pool from the Staking Factory website <span
                                                          style="font-weight: bold">with the account you used to create your pool</span>
                                                        to view details about your pool, as well as connect your Staking
                                                        pool to
                                                        other types of pools made at the Stake Factory.
                                                        <br/><br/>
                                                        <span style="font-size: 17px">How does it work?</span>
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Step 1)</span> Complete this form and fill out how your Staking pool will behave.
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Step 2)</span> Confirm your pools preview and click the "Create Pool" at the end of this form to create your pool (Creating staking pools is free!)
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Step 3)</span> Once your pool is created, it appears on the Stake Factory website.
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Step 4)</span> Once created, the same account you use to create your pool with can access the pool's Admin Panel from the Pool's Info page.
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Step 5)</span> Remember once your pool runs out of tokens to give to stakers it shuts down and gets removed from Stake Factory - Use your admin page to add more tokens to your pool before it shuts down (also free).
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Step 6)</span> Send a link to your staking pool's page to your defi audience and let them stake!
                                                      </div>
                                                    </template>
                                                  </accordion-item>
                                                </accordion>

                                                <b-steps v-model="currentStep" :animated="true">

                                                  <!-- About Section -->
                                                  <b-step-item :step="0" :clickable="false" label="About">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4" style="background-color: #00000038 !important;">
                                                          <span style="font-weight: bold; font-size: 20px;">What is a Staking Pool?</span>
                                                        </p>

                                                        <PoolCardPreview
                                                            pool_contract="factory-stake-fungiv2"
                                                            pool_id="Staking" pool_name="A Staking Pool"
                                                            pool_stake_token="STEAK"
                                                            pool_reward_token="KDA"
                                                            pool_apy="10"></PoolCardPreview>


                                                        <p style="color: #4ad1e3"> <span style="font-weight: bold">Staking Pools</span> are special pools that <span style="font-weight: bold">distribute tokens over time</span>
                                                          to pool members who <span style="font-weight: bold">Stake</span> or <span style="font-weight: bold">Store</span> their <span style="font-weight: bold">tokens</span> in the Staking Pool.
                                                          <br/><br/>.</p>

                                                        <p class="blue-bg font-green mb-4 mt-4" style="padding-left: 6rem; padding-right: 6rem; background-color: #00000038 !important;">
                                                          <span style="font-weight: bold; font-size: 20px;">How do Staking Pools work?</span>
                                                          <br/><br/>
                                                          Staking Pools work by letting users stake one set tokens in the Staking Pool, and, over time and according to the rules of the Staking Pool, pool members earn another set of tokens for storing their liquidity in the pool- You can think of a Staking Pool as a virtual 'faucet' that 'drips' reward tokens to a group of Stakers.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">Why would I make a Staking Pool?</span>
                                                          <br/><br/>
                                                          Use Staking Pools to create incentives and reward a Defi audience for locking or staking their tokens over time, instantly giving an audience more to do with any token.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">What can I do with a Staking Pool?</span>
                                                          <br/><br/>
                                                          Stake Factory Staking Pools are great for giving any Defi audience rewards, and less reasons to sell any token in a safe and trustful way - All funds stored in Staking Pools cannot be accessed in anyway by their pool creators, providing a much needed layer of trust between stakers and pool creators.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">How do I create a Staking Pool Pool?</span>
                                                          <br/><br/>
                                                          This tool will guide you through the <span style="font-weight: bold">Staking Pool</span> design process by guiding you through a <span style="font-weight: bold">series of steps</span>, ultimately allowing you to publish your own Staking Pool to the Kadena Blockchain. Once published,<span style="font-weight: bold"> your pool will immediately appear on the Stake Factory website</span>,<span style="font-weight: bold">allowing the public un-interrupted access to your staking solution</span> until your Staking Pool expires.
                                                          <br/><br/>
                                                          Set up your Staking Pool by first planning out how many rewards your pool will give away to stakers and how often it releases rewards to stakers. Follow the steps of the creation tool to plan out exactly how long your pool will run, and how frequesntly it releases rewards to Stakers - All rewards are automatically split between Stakers according to their stake in the pool each time your pool distributes rewards.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">How do accounts receive tokens from Staking Pools?</span>
                                                          <br/><br/>
                                                          All accounts must log in and claim their tokens from the Staking Pools they are participating in - A Staking Pool will reserve reward tokens for someone indefinitely until they are claimed.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">Can I edit my Staking Pool?</span>
                                                          <br/><br/>
                                                          No. Staking Pools cannot be edited in anyway after they are launched. Once a Staking Pool runs out of rewards it will expire - You can always fill your Staking Pool up with more reward tokens to keep it from expiring, or create more Staking Pools. Only put reward tokens in your Staking Pool solutions that you plan to distribute to your Stakers, your Staking Pool's rewards cannot be withdrawn from your pool after your Staking Pool is created and your pool will run until it distributes all of it's rewards to Stakers.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">What is the cost to make a Staking Pool?</span>
                                                          <br/><br/>
                                                          Staking Pools are Free in all ways at Stake Factory, just pay Kadena gas costs when interacting with your Staking Pools.
                                                          <br/><br/>
                                                        </p>




                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <b-switch
                                                            class="mb-5 mt-1"
                                                            v-model="localModuleExists"
                                                            :rounded="true"
                                                            :outlined="true"
                                                            size="is-large"
                                                            type="is-success"
                                                            :left-label='false'
                                                            :passive-type='null'>{{ localModuleExists ? "OK" : "OK" }}
                                                        </b-switch>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Reward Token Section -->
                                                  <b-step-item :step="1" :clickable="false" label="Reward">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">


                                                        <section v-if="rewardType === 1">
                                                          <accordion>
                                                            <accordion-item>
                                                              <template slot="accordion-trigger">
                                                                <h1 class="blue-bg info-icon">What is the <span
                                                                    style="font-weight: bold">total amount</span> and
                                                                  <span
                                                                      style="font-weight: bold">type of fungible-v2 token</span>
                                                                  to be distributed by this pool?</h1>
                                                              </template>
                                                              <template slot="accordion-content">
                                                                <div class="blue-bg-2 has-text-left">
                                                                  <span style="font-size: 17px">How many tokens total will your pool give away to stakers?</span>
                                                                  <br/><br/>
                                                                  Staking pools distribute Reward Tokens over time to
                                                                  stakers.
                                                                  <br/><br/>
                                                                  What is the <span style="font-weight: bold">total amount of Reward Tokens</span>
                                                                  your pool will be distributing?
                                                                  <br/><br/>
                                                                  Your pool will distribute this total amount of Reward
                                                                  Tokens <span
                                                                    style="font-weight: bold">until it runs out of rewards to distribute</span>,
                                                                  in which case your pool will deactivate itself due to
                                                                  <span
                                                                      style="font-weight: bold">'exhaustion'</span>.
                                                                  <br/><br/>
                                                                  <span style="font-size: 17px">Pool Exhaustion</span>
                                                                  <br/><br/>
                                                                  Once a Staking Pool has exhausted all of its Reward
                                                                  Tokens, <span
                                                                    style="font-weight: bold">the pool shuts down</span>,
                                                                  and is
                                                                  automatically pruned from the Stake Factory website,
                                                                  however,
                                                                  these exhausted pools <span style="font-weight: bold">can still be accessed</span>
                                                                  by the stakers currently in the pool so that they may
                                                                  claim their
                                                                  rewards and withdraw their staked tokens.
                                                                  <br/><br/>
                                                                  <span style="font-size: 17px">You must deposit this amount of tokens into your pool</span>
                                                                  <br/><br/>
                                                                  You will also be <span style="font-weight: bold">depositing this exact amount of Reward Tokens into your pool when you finalize your pool</span>
                                                                  at the end of this form in order to publish it (Don't worry the pool creator tool automatically does it for you! Just make sure you have the tokens in your wallet).
                                                                  <br/><br/>
                                                                  Additional amounts of Reward Tokens can always be
                                                                  <span
                                                                      style="font-weight: bold">added</span> to your
                                                                  Staking
                                                                  Pool
                                                                  <span style="font-weight: bold">before</span> it runs
                                                                  out of
                                                                  Reward Token reserves to distribute to stakers in
                                                                  order
                                                                  to <span
                                                                    style="font-weight: bold">prevent your pool from becoming exhausted</span>.
                                                                  <br/><br/>
                                                                  You <span style="font-weight: bold">cannot</span> add
                                                                  more Reward
                                                                  Tokens to a Staking Pool that has already distributed
                                                                  all of it's
                                                                  tokens and shut down due to <span
                                                                    style="font-weight: bold">exhaustion</span>
                                                                  - Keep Staking Pools topped off with Reward Tokens, or
                                                                  they <span
                                                                    style="font-weight: bold">shut down due to becoming exhausted of Reward Tokens</span>.
                                                                  <br/><br/>
                                                                  You <span style="font-weight: bold">cannot</span>
                                                                  reactivate or
                                                                  re-enable deactivated pools which have shut down due
                                                                  to
                                                                  <span
                                                                      style="font-weight: bold">exhaustion</span> - Once
                                                                  a
                                                                  pool runs
                                                                  out of Reward Tokens, it <span
                                                                    style="font-weight: bold">always shuts off permanently</span>,
                                                                  and if you forget about your pool, it will eventually
                                                                  &
                                                                  automatically shut down this way <span
                                                                    style="font-weight: bold">when stakers earn all of the tokens</span>.
                                                                  <br/><br/>
                                                                  You <span style="font-weight: bold">cannot withdraw your Reward Tokens from your Staking Pool!</span>
                                                                  Once you deposit your Reward Tokens into your staking
                                                                  pool, <span
                                                                    style="font-weight: bold">stakers will have the un-interrupted ability to earn them until the pool is exhausted</span>,
                                                                  and therefore you <span style="font-weight: bold">cannot take staker rewards if you change your mind about your staking pool</span>.
                                                                  <br/><br/>
                                                                  <span style="font-size: 17px">Monitor and Top Off your Staking Pool</span>
                                                                  <br/><br/>
                                                                  Since <span style="font-weight: bold">tokens are locked in your pool until they are distributed to stakers and cannot be withdrawn</span>,
                                                                  it may be a good strategy to <span
                                                                    style="font-weight: bold">plan ahead</span>
                                                                  in order to make <span style="font-weight: bold">periodic Reward Token deposits</span>
                                                                  to your pool over time <span
                                                                    style="font-weight: bold">before</span> stakers earn
                                                                  all the
                                                                  tokens.
                                                                  <br/>
                                                                  <br/>
                                                                  <span style="font-weight: bold">Planning to top off your pool periodically</span>
                                                                  allows you to have <span
                                                                    style="font-weight: bold">more control</span> over
                                                                  your Reward
                                                                  Tokens, as you can always deposit more tokens over
                                                                  time
                                                                  when your
                                                                  pool is getting close to it's point of exhaustion.
                                                                  However, you
                                                                  <span style="font-weight: bold">cannot withdraw Reward tokens once they are deposited</span>
                                                                  if you change your mind.
                                                                  <br/><br/>
                                                                  It is <span style="font-weight: bold">important</span>
                                                                  you keep
                                                                  track of how quickly your Staking Pool distributes
                                                                  rewards so that
                                                                  you can always keep it topped off with tokens and
                                                                  <span
                                                                      style="font-weight: bold">prevent it from becoming exhausted</span>
                                                                  and thus shutting down.
                                                                  <br/><br/>
                                                                  Once your pool is created, <span
                                                                    style="font-weight: bold">you will have access to a private Pool Creator section on the Stake Factory website where you can view your pool's statistics</span>,
                                                                  including how many Reward Tokens remain in your pool,
                                                                  and at which
                                                                  day and time your pool will finish distributing it's
                                                                  current
                                                                  reserve of Reward Tokens- <span
                                                                    style="font-weight: bold">Use this private Pool Creator section once your pool is created to monitor and top off your Staking Pools before they shut down due to exhaustion</span>.
                                                                </div>
                                                              </template>
                                                            </accordion-item>
                                                          </accordion>
                                                          <b-field v-if="customFungiv2Reward === false"
                                                                   custom-class="is-small"
                                                                   horizontal>
                                                            <template #label>

                                                              <b-tooltip type="is-dark"
                                                                         label="This is the token stakers are rewarded with as they stake tokens in your pool.">
                                                                <label class="label pt-1"
                                                                       style="width:50px;">Reward:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control">
                                                      <span class="select">
                                                        <select v-model="rewardFungiV2Type"
                                                                @change="verifyTokenBalance2(rewardFungiV2Type, rewardAmount)">
                                                                  <option value="coin">KDA</option>
                                                                  <option value="free.stake-token">STAKE</option>
                                                                  <option value="free.anedak">ADK</option>
                                                                  <option value="free.babena">BABE</option>
                                                                  <option value="runonflux.flux">FLUX</option>
                                                                  <option value="hypercent.prod-hype-coin">HYPE</option>
                                                                  <option value="kdlaunch.token">KDL</option>
                                                                  <option value="lago.kwUSDC">kwUSDC</option>
                                                        </select>
                                                      </span>
                                                                </p>
                                                                <p class="control is-expanded">
                                                                  <ValidationObserver
                                                                      v-if="currentStep === 1 && rewardType === 1 && customFungiv2Reward === false">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthan: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="reward_amount" name="Reward Amount"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="rewardAmount"
                                                                             @change="verifyTokenBalance2(rewardFungiV2Type, rewardAmount)"
                                                                             @input="[checkValidInput(valid),verifyTokenBalance2(rewardFungiV2Type, rewardAmount)]"
                                                                             class="input select" type="number"
                                                                             step="0.01"
                                                                             placeholder="Amount of Reward Token">
                                                                      <span class="error is-danger validation-msg">{{
                                                                          errors[0]
                                                                        }}</span>
                                                                      <span v-if="!errors[0]"
                                                                            class="error validation-msg-green is-info">Total amount of rewards to distribute</span>
                                                                      <br/>
                                                                    </ValidationProvider>
                                                                  </ValidationObserver>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field v-if="customFungiv2Reward === true"
                                                                   custom-class="is-small"
                                                                   horizontal>
                                                            <template #label>

                                                              <b-tooltip type="is-dark"
                                                                         label="The total amount of tokens this staking pool will distribute to stakers.">
                                                                <label class="label pt-1" style="width:50px;">Total
                                                                  Tokens:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <ValidationObserver
                                                                      v-if="currentStep === 1 && rewardType === 1 && customFungiv2Reward === true">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthan: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="reward_amount" name="Reward Amount"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="rewardAmount"
                                                                             @change="verifyTokenBalance2(coinName(fungiv2RewardModuleNamespace,fungiv2RewardModuleName), rewardAmount)"
                                                                             @input="[checkValidInput(valid),verifyTokenBalance2(coinName(fungiv2RewardModuleNamespace,fungiv2RewardModuleName), rewardAmount)]"
                                                                             class="input select" type="number"
                                                                             step="0.01"
                                                                             placeholder="Amount of Reward Token">
                                                                      <span class="error is-danger validation-msg">{{
                                                                          errors[0]
                                                                        }}</span>
                                                                      <span v-if="!errors[0]"
                                                                            class="error validation-msg-green is-info">Total amount of reward-tokens to distribute by this pool</span>
                                                                      <br/>
                                                                    </ValidationProvider>
                                                                  </ValidationObserver>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>


                                                          <b-field v-if="customFungiv2Reward === true"
                                                                   custom-class="is-small"
                                                                   horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="All tokens have a module name (contract name), what is the name of the contract which defines your reward token?.">
                                                                <label class="label" style="width:100px;">Token Module
                                                                  Name:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info"
                                                                           message="Module name of the fungible-v2 Reward Token contract">
                                                                    <ValidationObserver
                                                                        v-if="currentStep === 1 && rewardType === 1">
                                                                      <ValidationProvider :rules="{required: true}"
                                                                                          vid="fungiv2reward_name"
                                                                                          name="Fungiv2Reward Name"
                                                                                          v-slot="{ errors, valid }">
                                                                        <input v-model="fungiv2RewardModuleName"
                                                                               @input="[checkValidInput(valid), verifyTokenExists(fungiv2RewardModuleNamespace, fungiv2RewardModuleName) ]"
                                                                               class="input select"
                                                                               type="text"
                                                                               placeholder="token-module-name">
                                                                      </ValidationProvider>
                                                                    </ValidationObserver>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field v-if="customFungiv2Reward === true"
                                                                   custom-class="is-small"
                                                                   horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="All Kadena Modules have a namespace, what is the namespace for the contract that defines your reward token?">
                                                                <label class="label" style="width:100px;">Token Module
                                                                  Namespace:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info"
                                                                           message="Module namespace for the fungible-v2 Reward Token contract">
                                                                    <ValidationObserver
                                                                        v-if="currentStep === 1 && rewardType === 1">
                                                                      <ValidationProvider :rules="{required: true}"
                                                                                          vid="fungiv2reward_namespace"
                                                                                          name="Fungiblev2Reward Namespace"
                                                                                          v-slot="{ errors, valid }">
                                                                        <input v-model="fungiv2RewardModuleNamespace"
                                                                               @input="[checkValidInput(valid), verifyTokenExists(fungiv2RewardModuleNamespace, fungiv2RewardModuleName) ]"
                                                                               class="input select"
                                                                               type="text" placeholder="free">
                                                                      </ValidationProvider>
                                                                    </ValidationObserver>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <div class="divider is-centered"></div>

                                                          <b-switch
                                                              class="mb-4 mt-1"
                                                              v-model="customFungiv2Reward"
                                                              :rounded="true"
                                                              :outlined="true"
                                                              size="is-small"
                                                              type="is-success"
                                                              :left-label='false'
                                                              :passive-type='null'>I don't see my token in the above
                                                            list
                                                            and I will type it in manually..
                                                          </b-switch>


                                                          <p v-if="customFungiv2Reward === false && localModuleExists === true"
                                                             class="blue-bg font-green"> This pool will distribute <span
                                                              style="font-weight: bold">{{
                                                              rewardAmount
                                                            }} {{ rewardFungiV2Type }}</span>
                                                            tokens to stakers. </p>
                                                          <p v-if="customFungiv2Reward === true && localModuleExists === true"
                                                             class="blue-bg font-green"> This pool will distribute <span
                                                              style="font-weight: bold">{{
                                                              rewardAmount
                                                            }} {{
                                                              fungiv2RewardModuleNamespace
                                                            }}.{{ fungiv2RewardModuleName }}</span>
                                                            tokens to stakers. </p>

                                                          <p v-if="localModuleExists === false"
                                                             class="blue-bg font-green"><span style="color:#ff222b">Error: You don't have enough of this token to send to your Staking Pool - Please enter another amount or choose another token.</span>
                                                          </p>

                                                        </section>


                                                        <section v-if="rewardType === 2">
                                                          <accordion>
                                                            <accordion-item>
                                                              <template slot="accordion-trigger">
                                                                <h1 class="blue-bg info-icon">What is the <span
                                                                    style="font-weight: bold">total amount</span> and
                                                                  <span
                                                                      style="font-weight: bold">type of poly-fungible-v1 token</span>
                                                                  to be distributed by this pool to stakers?</h1>
                                                              </template>
                                                              <template slot="accordion-content">
                                                                <div class="blue-bg-2">
                                                                  <span style="font-weight: bold">Poly-fungible staking pools</span>
                                                                  distribute <span style="font-weight: bold">poly-fungible Reward Tokens</span>
                                                                  to stakers over time.
                                                                  <br/><br/>
                                                                  What is the <span style="font-weight: bold">total amount of poly-fungible Reward Tokens</span>
                                                                  your pool will be distributing?
                                                                  <br/><br/>
                                                                  <span style="font-weight: bold">All poly-fungible tokens also have ID's</span>,
                                                                  this is a unique feature to the poly-fungible
                                                                  interface
                                                                  and is
                                                                  required - <span style="font-weight: bold">Make sure you have decided which token ID/NFT ID</span>
                                                                  your poly-fungible staking pool will be distributing
                                                                  to
                                                                  stakers
                                                                  and <span style="font-weight: bold">type it correctly in the ID field below</span>-
                                                                  Stake Factory poly-fungible staking pools <span
                                                                    style="font-weight: bold">only support distributing 1 token ID at a time</span>
                                                                  (such as a randomized lootbox). Failing to provide an
                                                                  accurate
                                                                  token ID will result in an error when finalizing your
                                                                  pool.
                                                                  <br/><br/>
                                                                  Your pool will distribute this total amount of
                                                                  poly-fungible
                                                                  Reward Tokens <span style="font-weight: bold">until it runs out of rewards to distribute</span>,
                                                                  in which case your pool will deactivate itself due to
                                                                  <span
                                                                      style="font-weight: bold">'exhaustion'</span>.
                                                                  <br/><br/>
                                                                  Once a Staking Pool has exhausted all of it's Reward
                                                                  Tokens, <span
                                                                    style="font-weight: bold">the pool shuts down</span>,
                                                                  and is
                                                                  automatically pruned from the Stake Factory website,
                                                                  however,
                                                                  these exhausted pools <span style="font-weight: bold">can still be accessed</span>
                                                                  by the stakers currently in the pool so that they may
                                                                  claim their
                                                                  rewards and withdraw their staked tokens.
                                                                  <br/><br/>
                                                                  You will also be <span style="font-weight: bold">depositing this exact amount of poly-fungible Reward Tokens into your pool when you finalize your pool</span>
                                                                  at the end of this form in order to publish it.
                                                                  <br/><br/>
                                                                  Additional amounts of Reward Tokens can always be
                                                                  <span
                                                                      style="font-weight: bold">added</span> to your
                                                                  Staking
                                                                  Pool
                                                                  <span style="font-weight: bold">before</span> it runs
                                                                  out of
                                                                  Reward Token reserves to distribute to stakers in
                                                                  order
                                                                  to <span
                                                                    style="font-weight: bold">prevent your pool from becoming exhausted</span>.
                                                                  <br/><br/>
                                                                  You <span style="font-weight: bold">cannot</span> add
                                                                  more Reward
                                                                  Tokens to a Staking Pool that has already distributed
                                                                  all of it's
                                                                  tokens and shut down due to <span
                                                                    style="font-weight: bold">exhaustion</span>
                                                                  - Keep Staking Pools topped off with Reward Tokens, or
                                                                  they <span
                                                                    style="font-weight: bold">shut down due to becoming exhausted of Reward Tokens</span>.
                                                                  <br/><br/>
                                                                  You <span style="font-weight: bold">cannot</span>
                                                                  reactivate or
                                                                  re-enable deactivated pools which have shut down due
                                                                  to
                                                                  <span
                                                                      style="font-weight: bold">exhaustion</span> - Once
                                                                  a
                                                                  pool runs
                                                                  out of Reward Tokens, it <span
                                                                    style="font-weight: bold">always shuts off permanently</span>,
                                                                  and if you forget about your pool, it will eventually
                                                                  &
                                                                  automatically shut down this way <span
                                                                    style="font-weight: bold">when stakers earn all of the tokens</span>.
                                                                  <br/><br/>
                                                                  You <span style="font-weight: bold">cannot withdraw your Reward Tokens from your Staking Pool!</span>
                                                                  Once you deposit your Reward Tokens into your staking
                                                                  pool, <span
                                                                    style="font-weight: bold">stakers will have the un-interrupted ability to earn them until the pool is exhausted</span>,
                                                                  and therefore you <span style="font-weight: bold">cannot take staker rewards if you change your mind about your staking pool</span>.
                                                                  <br/><br/>
                                                                  Since <span style="font-weight: bold">tokens are locked in your pool until they are distributed to stakers and cannot be withdrawn</span>,
                                                                  it may be a good strategy to <span
                                                                    style="font-weight: bold">plan ahead</span>
                                                                  in order to make <span style="font-weight: bold">periodic Reward Token deposits</span>
                                                                  to your pool over time <span
                                                                    style="font-weight: bold">before</span> stakers earn
                                                                  all the
                                                                  tokens.
                                                                  <br/>
                                                                  <br/>
                                                                  <span style="font-weight: bold">Planning to top off your pool periodically</span>
                                                                  allows you to have <span style="font-weight: bold">control</span>
                                                                  over your Reward Tokens, as you can always deposit
                                                                  more
                                                                  tokens
                                                                  over time when your pool is getting close to it's
                                                                  point
                                                                  of
                                                                  exhaustion. However, you <span
                                                                    style="font-weight: bold">cannot withdraw Reward tokens once they are deposited</span>
                                                                  if you change your mind.
                                                                  <br/><br/>
                                                                  It is <span style="font-weight: bold">important</span>
                                                                  you keep
                                                                  track of how quickly your Staking Pool distributes
                                                                  rewards so that
                                                                  you can always keep it topped off with Reward Tokens
                                                                  and
                                                                  <span
                                                                      style="font-weight: bold">prevent it from becoming exhausted</span>
                                                                  and thus shutting down.
                                                                  <br/><br/>
                                                                  Once your pool is created, <span
                                                                    style="font-weight: bold">you will have access to a private Pool Creator section on the Stake Factory website where you can view your pool's statistics</span>,
                                                                  including how many Reward Tokens remain in your pool,
                                                                  and at which
                                                                  day and time your pool will finish distributing it's
                                                                  current
                                                                  reserve of Reward Tokens- <span
                                                                    style="font-weight: bold">Use this private Pool Creator section once your pool is created to monitor and top off your Staking Pools before they shut down due to exhaustion</span>.
                                                                </div>
                                                              </template>
                                                            </accordion-item>
                                                          </accordion>
                                                          <br/>
                                                          <div v-if="rewardType === 2"
                                                               class="column is-centered mb-4 mt-5">
                                                            <div class="columns is-centered pools-card">
                                                              <FlipCardPreview class="column is-3"
                                                                               style="margin: 10px; width: 316px !important;"
                                                                               pool_contract="factory-stake-polyfungiv1"
                                                                               pool_id="?" pool_name="?"
                                                                               :token_image="t_rewardTokenImagePolyfungiv1"
                                                                               :pool_reward_token_id="polyFungiV1ID"
                                                                               :token_name="t_stakeTokenNamePolyfungiv1"
                                                                               :token_description="t_rewardTokenDescriptionPolyfungiv1"
                                                                               :pool_reward_token="polyFungiV1ModuleNamespace+'.'+polyFungiV1ModuleName"
                                                                               :show_flipper="false"
                                                                               stake_balance="0"
                                                                               stake_pending_rewards="0"
                                                                               stake_rewards="0"></FlipCardPreview>
                                                            </div>
                                                          </div>
                                                          <br/>
                                                          <b-field
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>

                                                              <b-tooltip type="is-dark"
                                                                         label="The total amount of tokens this staking pool will distribute to stakers.">
                                                                <label class="label pt-1" style="width:50px;">Total
                                                                  Tokens:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <ValidationObserver
                                                                      v-if="currentStep === 1 && rewardType === 2">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthan: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="reward_amount" name="Reward Amount"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="rewardAmount"
                                                                             @input="checkValidInput(valid)"
                                                                             class="input select" type="number"
                                                                             step="0.01"
                                                                             placeholder="Amount of Reward Token">
                                                                      <span class="error is-danger validation-msg">{{
                                                                          errors[0]
                                                                        }}</span>
                                                                      <span v-if="!errors[0]"
                                                                            class="error validation-msg-green is-info">Total amount of reward-tokens to distribute by this pool</span>
                                                                      <br/>
                                                                    </ValidationProvider>
                                                                  </ValidationObserver>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="All poly-fungible-v1 tokens have an ID, this is that ID (not the module's name).">
                                                                <label class="label" style="width:100px;">poly-fungible-v1
                                                                  Token
                                                                  ID:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info"
                                                                           message="ID of the poly-fungible-v1 token this pool will distribute to stakers">
                                                                    <ValidationObserver
                                                                        v-if="currentStep === 1 && rewardType === 2">
                                                                      <ValidationProvider :rules="{required: true}"
                                                                                          vid="polyfungi1_id"
                                                                                          name="Polyfungiblev1 ID"
                                                                                          v-slot="{ errors, valid }">
                                                                        <input v-model="polyFungiV1ID"
                                                                               @input="[checkValidInput(valid), getPolyFungiV1RewardTokenURI()]"
                                                                               class="input select"
                                                                               type="text" placeholder="My-NFT-ID">
                                                                      </ValidationProvider>
                                                                    </ValidationObserver>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="All tokens have a module name (contract name), what is the name of the contract which defines your reward token?">
                                                                <label class="label" style="width:100px;">Token Module
                                                                  Name:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info"
                                                                           message="Module name of the poly-fungible-v1 token contract">
                                                                    <ValidationObserver
                                                                        v-if="currentStep === 1 && rewardType === 2">
                                                                      <ValidationProvider :rules="{required: true}"
                                                                                          vid="polyfungi1_name"
                                                                                          name="Polyfungiblev1 Name"
                                                                                          v-slot="{ errors, valid }">
                                                                        <input v-model="polyFungiV1ModuleName"
                                                                               @input="[checkValidInput(valid), getPolyFungiV1RewardTokenURI()]"
                                                                               class="input select"
                                                                               type="text"
                                                                               placeholder="token-module-name">
                                                                      </ValidationProvider>
                                                                    </ValidationObserver>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="All Kadena Modules have a namespace, what is the namespace for the contract that defines your reward token?">
                                                                <label class="label" style="width:100px;">Token Module
                                                                  Namespace:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info"
                                                                           message="Module namespace for the poly-fungible-v1 token contract">
                                                                    <ValidationObserver
                                                                        v-if="currentStep === 1 && rewardType === 2">
                                                                      <ValidationProvider :rules="{required: true}"
                                                                                          vid="polyfungi1_namespace"
                                                                                          name="Polyfungiblev1 Namespace"
                                                                                          v-slot="{ errors, valid }">
                                                                        <input v-model="polyFungiV1ModuleNamespace"
                                                                               @input="[checkValidInput(valid), getPolyFungiV1RewardTokenURI()]"
                                                                               class="input select"
                                                                               type="text" placeholder="free">
                                                                      </ValidationProvider>
                                                                    </ValidationObserver>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <p class="blue-bg font-green">This pool will distribute <span
                                                              style="font-weight: bold">{{
                                                              rewardAmount
                                                            }} {{ polyFungiV1ModuleNamespace }}.{{
                                                              polyFungiV1ModuleName
                                                            }} {{
                                                              polyFungiV1ID
                                                            }}</span>
                                                            tokens to stakers.</p>
                                                        </section>

                                                        <section v-if="rewardType === 3">
                                                          <accordion>
                                                            <accordion-item>
                                                              <template slot="accordion-trigger">
                                                                <h1 class="blue-bg info-icon">What is the <span
                                                                    style="font-weight: bold">total amount</span> and
                                                                  <span
                                                                      style="font-weight: bold">type of poly-fungible-v2 token</span>
                                                                  to be distributed by this pool to stakers?</h1>
                                                              </template>
                                                              <template slot="accordion-content">
                                                                <div class="blue-bg-2">
                                                                  <span style="font-weight: bold">Poly-fungible-v2 staking pools</span>
                                                                  distribute <span style="font-weight: bold">poly-fungible-v2 Reward Tokens</span>
                                                                  to stakers over time.
                                                                  <br/><br/>
                                                                  What is the <span style="font-weight: bold">total amount of poly-fungible-v2 Reward Tokens</span>
                                                                  your pool will be distributing?
                                                                  <br/><br/>
                                                                  <span style="font-weight: bold">All poly-fungible-v2 tokens also have ID's</span>,
                                                                  this is a unique feature to the poly-fungible-v2
                                                                  interface and is
                                                                  required - <span style="font-weight: bold">Make sure you have decided which token ID/NFT ID</span>
                                                                  your poly-fungible-v2 staking pool will be
                                                                  distributing
                                                                  to stakers
                                                                  and <span style="font-weight: bold">type it correctly in the ID field below</span>-
                                                                  Stake Factory poly-fungible-v2 staking pools <span
                                                                    style="font-weight: bold">only support distributing 1 token ID at a time</span>
                                                                  (such as a randomized lootbox). Failing to provide an
                                                                  accurate
                                                                  token ID will result in an error when finalizing your
                                                                  pool.
                                                                  <br/><br/>
                                                                  Your pool will distribute this total amount of
                                                                  poly-fungible
                                                                  Reward Tokens <span style="font-weight: bold">until it runs out of rewards to distribute</span>,
                                                                  in which case your pool will deactivate itself due to
                                                                  <span
                                                                      style="font-weight: bold">'exhaustion'</span>.
                                                                  <br/><br/>
                                                                  Once a Staking Pool has exhausted all of it's Reward
                                                                  Tokens, <span
                                                                    style="font-weight: bold">the pool shuts down</span>,
                                                                  and is
                                                                  automatically pruned from the Stake Factory website,
                                                                  however,
                                                                  these exhausted pools <span style="font-weight: bold">can still be accessed</span>
                                                                  by the stakers currently in the pool so that they may
                                                                  claim their
                                                                  rewards and withdraw their staked tokens.
                                                                  <br/><br/>
                                                                  You will also be <span style="font-weight: bold">depositing this exact amount of poly-fungible Reward Tokens into your pool when you finalize your pool</span>
                                                                  at the end of this form in order to publish it.
                                                                  <br/><br/>
                                                                  Additional amounts of Reward Tokens can always be
                                                                  <span
                                                                      style="font-weight: bold">added</span> to your
                                                                  Staking
                                                                  Pool
                                                                  <span style="font-weight: bold">before</span> it runs
                                                                  out of
                                                                  Reward Token reserves to distribute to stakers in
                                                                  order
                                                                  to <span
                                                                    style="font-weight: bold">prevent your pool from becoming exhausted</span>.
                                                                  <br/><br/>
                                                                  You <span style="font-weight: bold">cannot</span> add
                                                                  more Reward
                                                                  Tokens to a Staking Pool that has already distributed
                                                                  all of it's
                                                                  tokens and shut down due to <span
                                                                    style="font-weight: bold">exhaustion</span>
                                                                  - Keep Staking Pools topped off with Reward Tokens, or
                                                                  they <span
                                                                    style="font-weight: bold">shut down due to becoming exhausted of Reward Tokens</span>.
                                                                  <br/><br/>
                                                                  You <span style="font-weight: bold">cannot</span>
                                                                  reactivate or
                                                                  re-enable deactivated pools which have shut down due
                                                                  to
                                                                  <span
                                                                      style="font-weight: bold">exhaustion</span> - Once
                                                                  a
                                                                  pool runs
                                                                  out of Reward Tokens, it <span
                                                                    style="font-weight: bold">always shuts off permanently</span>,
                                                                  and if you forget about your pool, it will eventually
                                                                  &
                                                                  automatically shut down this way <span
                                                                    style="font-weight: bold">when stakers earn all of the tokens</span>.
                                                                  <br/><br/>
                                                                  You <span style="font-weight: bold">cannot withdraw your Reward Tokens from your Staking Pool!</span>
                                                                  Once you deposit your Reward Tokens into your staking
                                                                  pool, <span
                                                                    style="font-weight: bold">stakers will have the un-interrupted ability to earn them until the pool is exhausted</span>,
                                                                  and therefore you <span style="font-weight: bold">cannot take staker rewards if you change your mind about your staking pool</span>.
                                                                  <br/><br/>
                                                                  Since <span style="font-weight: bold">tokens are locked in your pool until they are distributed to stakers and cannot be withdrawn</span>,
                                                                  it may be a good strategy to <span
                                                                    style="font-weight: bold">plan ahead</span>
                                                                  in order to make <span style="font-weight: bold">periodic Reward Token deposits</span>
                                                                  to your pool over time <span
                                                                    style="font-weight: bold">before</span> stakers earn
                                                                  all the
                                                                  tokens.
                                                                  <br/>
                                                                  <br/>
                                                                  <span style="font-weight: bold">Planning to top off your pool periodically</span>
                                                                  allows you to have <span style="font-weight: bold">control</span>
                                                                  over your Reward Tokens, as you can always deposit
                                                                  more
                                                                  tokens
                                                                  over time when your pool is getting close to it's
                                                                  point
                                                                  of
                                                                  exhaustion. However, you <span
                                                                    style="font-weight: bold">cannot withdraw Reward tokens once they are deposited</span>
                                                                  if you change your mind.
                                                                  <br/><br/>
                                                                  It is <span style="font-weight: bold">important</span>
                                                                  you keep
                                                                  track of how quickly your Staking Pool distributes
                                                                  rewards so that
                                                                  you can always keep it topped off with Reward Tokens
                                                                  and
                                                                  <span
                                                                      style="font-weight: bold">prevent it from becoming exhausted</span>
                                                                  and thus shutting down.
                                                                  <br/><br/>
                                                                  Once your pool is created, <span
                                                                    style="font-weight: bold">you will have access to a private Pool Creator section on the Stake Factory website where you can view your pool's statistics</span>,
                                                                  including how many Reward Tokens remain in your pool,
                                                                  and at which
                                                                  day and time your pool will finish distributing it's
                                                                  current
                                                                  reserve of Reward Tokens- <span
                                                                    style="font-weight: bold">Use this private Pool Creator section once your pool is created to monitor and top off your Staking Pools before they shut down due to exhaustion</span>.
                                                                </div>
                                                              </template>
                                                            </accordion-item>
                                                          </accordion>
                                                          <br/>
                                                          <div v-if="rewardType === 3"
                                                               class="column is-centered mb-4 mt-5">
                                                            <div class="columns is-centered pools-card">
                                                              <FlipCardPreview class="column is-3"
                                                                               style="margin: 10px; width: 316px !important;"
                                                                               pool_contract="factory-stake-polyfungiv2"
                                                                               pool_id="?" pool_name="?"
                                                                               :token_image="t_rewardTokenImagePolyfungiv2"
                                                                               :pool_reward_token_id="polyFungiV2ID"
                                                                               :token_name="t_rewardTokenNamePolyfungiv2"
                                                                               :token_description="t_rewardTokenDescriptionPolyfungiv2"
                                                                               :pool_reward_token="polyFungiV2ModuleNamespace+'.'+polyFungiV2ModuleName"
                                                                               :pool_apy="rewardAmount"
                                                                               :show_flipper="false"
                                                                               stake_balance="0"
                                                                               stake_pending_rewards="0"
                                                                               stake_rewards="0"></FlipCardPreview>
                                                            </div>
                                                          </div>
                                                          <br/>
                                                          <b-field
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>

                                                              <b-tooltip type="is-dark"
                                                                         label="The total amount of tokens this staking pool will distribute to stakers.">
                                                                <label class="label pt-1" style="width:50px;">Total
                                                                  Tokens:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <ValidationObserver
                                                                      v-if="currentStep === 1 && rewardType === 3">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthan: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="reward_amount" name="Reward Amount"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="rewardAmount"
                                                                             @input="[checkValidInput(valid), getPolyFungiV2RewardTokenURI()]"
                                                                             class="input select" type="number"
                                                                             step="0.01"
                                                                             placeholder="Amount of Reward Token">
                                                                      <span class="error is-danger validation-msg">{{
                                                                          errors[0]
                                                                        }}</span>
                                                                      <span v-if="!errors[0]"
                                                                            class="error validation-msg-green is-info">Total amount of reward-tokens to distribute by this pool</span>
                                                                      <br/>
                                                                    </ValidationProvider>
                                                                  </ValidationObserver>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="All poly-fungible-v2 tokens have an ID, type the tokens ID here properly (Token IDs are not the token's module name).">
                                                                <label class="label" style="width:100px;">poly-fungible-v2
                                                                  Token
                                                                  ID:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info"
                                                                           message="ID of the poly-fungible-v2 token this pool will distribute to stakers">
                                                                    <ValidationObserver
                                                                        v-if="currentStep === 1 && rewardType === 3">
                                                                      <ValidationProvider :rules="{required: true}"
                                                                                          vid="polyfungi2_id"
                                                                                          name="Polyfungiblev2 ID"
                                                                                          v-slot="{ errors, valid }">
                                                                        <input v-model="polyFungiV2ID"
                                                                               @input="[checkValidInput(valid), getPolyFungiV2RewardTokenURI()]"
                                                                               class="input select"
                                                                               type="text" placeholder="My-NFT-ID">
                                                                      </ValidationProvider>
                                                                    </ValidationObserver>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="All tokens have a module name (contract name), what is the name of the contract which defines your reward token?.">
                                                                <label class="label" style="width:100px;">Token Module
                                                                  Name:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info"
                                                                           message="Module name of the poly-fungible-v2 token contract">
                                                                    <ValidationObserver
                                                                        v-if="currentStep === 1 && rewardType === 3">
                                                                      <ValidationProvider :rules="{required: true}"
                                                                                          vid="polyfungi2_name"
                                                                                          name="Polyfungiblev2 Name"
                                                                                          v-slot="{ errors, valid }">
                                                                        <input v-model="polyFungiV2ModuleName"
                                                                               @input="[checkValidInput(valid), getPolyFungiV2RewardTokenURI()]"
                                                                               class="input select"
                                                                               type="text"
                                                                               placeholder="token-module-name">
                                                                      </ValidationProvider>
                                                                    </ValidationObserver>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="All Kadena Modules have a namespace, what is the namespace for the contract that defines your reward token?">
                                                                <label class="label" style="width:100px;">Token Module
                                                                  Namespace:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info"
                                                                           message="Module namespace for the poly-fungible-v2 token contract">
                                                                    <ValidationObserver
                                                                        v-if="currentStep === 1 && rewardType === 3">
                                                                      <ValidationProvider :rules="{required: true}"
                                                                                          vid="polyfungi2_namespace"
                                                                                          name="Polyfungiblev2 Namespace"
                                                                                          v-slot="{ errors, valid }">
                                                                        <input v-model="polyFungiV2ModuleNamespace"
                                                                               @input="[checkValidInput(valid), getPolyFungiV2RewardTokenURI()]"
                                                                               class="input select"
                                                                               type="text" placeholder="free">
                                                                      </ValidationProvider>
                                                                    </ValidationObserver>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <p class="blue-bg font-green">This pool will distribute <span
                                                              style="font-weight: bold">{{
                                                              rewardAmount
                                                            }} {{ polyFungiV2ModuleNamespace }}.{{
                                                              polyFungiV2ModuleName
                                                            }} {{
                                                              polyFungiV2ID
                                                            }}</span>
                                                            tokens to stakers.</p>
                                                        </section>


                                                      </div>

                                                    </section>

                                                  </b-step-item>
                                                  <!-- APY Section -->
                                                  <b-step-item :step="2" :clickable="false" label="APY">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Variable APY Section -->
                                                        <section type="is-info" class="mt-4">
                                                          <accordion>
                                                            <accordion-item>
                                                              <template slot="accordion-trigger">
                                                                <h1 class="blue-bg info-icon"> How will this pool <span
                                                                    style="font-weight: bold">distribute it's tokens</span>
                                                                  to
                                                                  stakers?</h1>
                                                              </template>
                                                              <template slot="accordion-content">
                                                                <div class="blue-bg-2 has-text-left">
                                                                  <span style="font-size: 17px">How often does this pool give away reward tokens to stakers? And how often?</span>
                                                                  <br/><br/>
                                                                  Staking pools distribute Reward Tokens over time to
                                                                  stakers
                                                                  according to the rules you set up below.
                                                                  <br/><br/>
                                                                  The <span style="font-weight: bold">2 settings</span>
                                                                  below adjust
                                                                  <span style="font-weight: bold">how frequently your pool will distribute reward tokens to stakers</span>
                                                                  up until the pool runs out of reward tokens
                                                                  completely (Imagine a water spout emitting tokens- these settings adjust how many tokens come out of your pool and how often)
                                                                  <br/><br/>
                                                                  <span style="font-weight: bold">Reward Amount</span>:
                                                                  The amount
                                                                  of Reward Tokens to distribute to stakers every Reward
                                                                  Duration.
                                                                  <br/>
                                                                  <span style="font-weight: bold">Reward Duration</span>:
                                                                  The number
                                                                  of seconds that must go by before the Reward Amount is
                                                                  distributed
                                                                  to stakers.
                                                                  <br/><br/>
                                                                  IE- Your pool will distribute the <span
                                                                    style="font-weight: bold">Reward Amount</span>
                                                                  of tokens, every <span
                                                                    style="font-weight: bold">Reward Duration</span>
                                                                  number of
                                                                  seconds (and not before).
                                                                  <br/><br/>
                                                                  It's common for Defi pools to release tiny amounts of
                                                                  reward
                                                                  tokens very frequently to stakers for which they can
                                                                  claim (such
                                                                  as every minute, second, or mili-second).
                                                                  <br/><br/>
                                                                  This means it may be best to figure out <span
                                                                    style="font-weight: bold">how</span> you want to
                                                                  distribute your
                                                                  tokens per day (IE- I have 1000 tokens and I want to
                                                                  release 10
                                                                  tokens a day to my stakers), and then figure out how
                                                                  many tokens
                                                                  you have to release every second up until 10 tokens
                                                                  are
                                                                  distributed per day.
                                                                  <br/><br/>
                                                                  Feel free to distribute your tokens to your stakers however you would like, just remember that stakers must first be staked in your pool this length of time before they recieve any rewards, so distributing rewards in long time intervals is not advised since stakers have to wait that long before they earn rewards.
                                                                  <br/>
                                                                  <br/>
                                                                  Remember, these settings control <span
                                                                    style="font-weight: bold">how often stakers earn new rewards</span>
                                                                  from your pool, and <span style="font-weight: bold">how long they must be staked</span>
                                                                  in your pool before they can claim those new rewards -
                                                                  Be mindful
                                                                  of how slowly you release rewards to your stakers
                                                                  (<span
                                                                    style="font-weight: bold">smaller time frames are better</span>),
                                                                  as it may force stakers to have to wait longer than
                                                                  you
                                                                  anticipated before they can claim new rewards! For
                                                                  example:
                                                                  <br/>
                                                                  <br/>- Distributing tokens every <span
                                                                    style="font-weight: bold">second</span>
                                                                  allows stakers to claim new rewards every <span
                                                                    style="font-weight: bold">second</span>.
                                                                  <br/>- Distributing rewards every <span
                                                                    style="font-weight: bold">60 seconds</span>
                                                                  allows stakers to claim new rewards every <span
                                                                    style="font-weight: bold">60 seconds</span>.
                                                                  <br/>- Distributing rewards every <span
                                                                    style="font-weight: bold">300 seconds</span>
                                                                  allows stakers to claim new rewards every <span
                                                                    style="font-weight: bold">300 seconds</span>.
                                                                  <br/>
                                                                  <br/>
                                                                  In the examples above we demonstrate that, the <span
                                                                    style="font-weight: bold">slower</span> your Staking
                                                                  Pool
                                                                  releases rewards to stakers, the <span
                                                                    style="font-weight: bold">longer</span>
                                                                  stakers must wait to claim rewards. In most cases, it
                                                                  is
                                                                  advised
                                                                  to distribute rewards in tiny/frequent time-frames so
                                                                  stakers can
                                                                  see their rewards counters move frequently instead of
                                                                  slowly.
                                                                  <br/><br/>
                                                                  If making stakers wait or lock tokens is your
                                                                  intention,
                                                                  don't
                                                                  achieve this result by releasing rewards slower to
                                                                  your
                                                                  stakers
                                                                  with the settings above. Instead, use the rules
                                                                  section
                                                                  found at
                                                                  the end of the pool creation form to enforce these
                                                                  custom wait
                                                                  periods and lock periods across your pool.
                                                                  <br/><br/>The default example settings below allows a
                                                                  pool to
                                                                  distribute 10 tokens per day by releasing a very small
                                                                  amount of
                                                                  tokens each second:
                                                                </div>
                                                              </template>
                                                            </accordion-item>
                                                          </accordion>
                                                          <br/>
                                                          <b-field
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="The amount of rewards which become available to stakers each reward duration.">
                                                                <label class="label pt-2" style="width:100px;">Reward
                                                                  Amount:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthan: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="reward_amount"
                                                                        name="Reward Duration Amount"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="rewardDurationAmount"
                                                                             @input="checkValidInput(valid)"
                                                                             class="input select"
                                                                             type="number" step="0.0001"
                                                                             placeholder="10.0">
                                                                      <span class="error is-danger validation-msg">{{
                                                                          errors[0]
                                                                        }}</span>
                                                                      <span v-if="!errors[0]"
                                                                            class="error validation-msg-green is-info">Amount of reward-tokens to distribute to stakers each Reward Duration</span>
                                                                    </ValidationProvider>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="The amount of time it takes (in seconds) for reward tokens to become available to stakers in the pool to claim.">
                                                                <label class="label pt-2" style="width:100px;">Reward
                                                                  Duration:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthan: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="reward_duration" name="Reward Duration"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="rewardDuration"
                                                                             @input="checkValidInput(valid)"
                                                                             class="input select"
                                                                             type="number" step="0.01"
                                                                             placeholder="86400.0">
                                                                      <span class="error is-danger validation-msg">{{
                                                                          errors[0]
                                                                        }}</span>
                                                                      <span v-if="!errors[0]"
                                                                            class="error validation-msg-green is-info">Number of seconds before the <span
                                                                          style="font-weight: bold">Reward Amount</span> is distributed to stakers</span>
                                                                    </ValidationProvider>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <br/>
                                                          <div class="divider is-centered"></div>
                                                          <br/>


                                                          <p v-if="rewardType === 1" class="blue-bg font-green"><span
                                                              v-if="customFungiv2Reward === false">Every <span
                                                              style="font-weight: bold">{{
                                                              rewardDuration
                                                            }} second(s) {{ rewardDurationAmount }} {{
                                                              rewardFungiV2Type
                                                            }}</span>
                                                becomes available to stakers in this pool.</span>
                                                            <span v-if="customFungiv2Reward === true">Every <span
                                                                style="font-weight: bold">{{ rewardDuration }} second(s) {{
                                                                rewardDurationAmount
                                                              }} {{
                                                                fungiv2RewardModuleNamespace
                                                              }}.{{ fungiv2RewardModuleName }}</span>
                                                becomes available to stakers in this pool.</span>
                                                            <span
                                                                v-if="getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) > 0.0"
                                                                style="font-size:13px"><br/>⌛ Every hour <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool.</span>
                                                            <span
                                                                v-if="getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) <= 0.0"
                                                                style="font-size:13px; color: #c57252; padding-top: 5px; padding-bottom: 5px;"><br/>Warning: Every hour <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool! <br/> Stakers must stake longer than 1 hour to claim rewards!</span>
                                                            <span
                                                                v-if="getDailyRewards(rewardDuration, rewardDurationAmount, rewardAmount) > 0.0"
                                                                style="font-size:13px"><br/>🕒 Every day <span
                                                                style="font-weight: bold">{{
                                                                getDailyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool.</span>
                                                            <span
                                                                v-if="getDailyRewards(rewardDuration, rewardDurationAmount, rewardAmount) <= 0.0"
                                                                style="font-size:13px; color: #c57252"><br/>Warning: Every day <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool! <br/> Stakers must stake longer than 1 day to claim rewards!</span>
                                                            <span
                                                                v-if="getMonthlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) > 0.0"
                                                                style="font-size:13px"><br/>🗓️ Every month <span
                                                                style="font-weight: bold">{{
                                                                getMonthlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool.</span>
                                                            <span
                                                                v-if="getMonthlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) <= 0.0"
                                                                style="font-size:13px; color: #c57252"><br/>Warning: Every month <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool! <br/> Stakers must stake longer than 1 month to claim rewards!</span>
                                                            <span
                                                                v-if="getYearlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) > 0.0"
                                                                style="font-size:13px"><br/>☀️ Every year <span
                                                                style="font-weight: bold">{{
                                                                getYearlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool.</span>
                                                            <span
                                                                v-if="getYearlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) <= 0.0"
                                                                style="font-size:13px; color: #c57252"><br/>Warning: Every year <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool! <br/> Stakers must stake longer than 1 year to claim rewards!</span>
                                                          </p>
                                                          <p v-if="rewardType === 2" class="blue-bg font-green"> Every
                                                            <span
                                                                style="font-weight: bold">{{
                                                                rewardDuration
                                                              }} seconds {{
                                                                rewardDurationAmount
                                                              }}  {{
                                                                polyFungiV1ModuleNamespace
                                                              }}.{{ polyFungiV1ModuleName }} ( {{
                                                                polyFungiV1ID
                                                              }} )</span>
                                                            becomes available to stakers in this pool.
                                                            <span
                                                                v-if="getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) > 0.0"
                                                                style="font-size:13px"><br/>⌛ Every hour <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool.</span>
                                                            <span
                                                                v-if="getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) <= 0.0"
                                                                style="font-size:13px; color: #c57252; padding-top: 5px; padding-bottom: 5px;"><br/>Warning: Every hour <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool! <br/> Stakers must stake longer than 1 hour to claim rewards!</span>
                                                            <span
                                                                v-if="getDailyRewards(rewardDuration, rewardDurationAmount, rewardAmount) > 0.0"
                                                                style="font-size:13px"><br/>🕒 Every day <span
                                                                style="font-weight: bold">{{
                                                                getDailyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool.</span>
                                                            <span
                                                                v-if="getDailyRewards(rewardDuration, rewardDurationAmount, rewardAmount) <= 0.0"
                                                                style="font-size:13px; color: #c57252"><br/>Warning: Every day <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool! <br/> Stakers must stake longer than 1 day to claim rewards!</span>
                                                            <span
                                                                v-if="getMonthlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) > 0.0"
                                                                style="font-size:13px"><br/>🗓️ Every month <span
                                                                style="font-weight: bold">{{
                                                                getMonthlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool.</span>
                                                            <span
                                                                v-if="getMonthlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) <= 0.0"
                                                                style="font-size:13px; color: #c57252"><br/>Warning: Every month <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool! <br/> Stakers must stake longer than 1 month to claim rewards!</span>
                                                            <span
                                                                v-if="getYearlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) > 0.0"
                                                                style="font-size:13px"><br/>☀️ Every year <span
                                                                style="font-weight: bold">{{
                                                                getYearlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool.</span>
                                                            <span
                                                                v-if="getYearlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) <= 0.0"
                                                                style="font-size:13px; color: #c57252"><br/>Warning: Every year <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool! <br/> Stakers must stake longer than 1 year to claim rewards!</span>
                                                          </p>
                                                          <p v-if="rewardType === 3" class="blue-bg font-green"> Every
                                                            <span
                                                                style="font-weight: bold">{{
                                                                rewardDuration
                                                              }} seconds {{
                                                                rewardDurationAmount
                                                              }} {{ polyFungiV2ModuleNamespace }}.{{
                                                                polyFungiV2ModuleName
                                                              }} ( {{ polyFungiV2ID }} )</span>
                                                            becomes available to stakers in this pool.
                                                            <span
                                                                v-if="getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) > 0.0"
                                                                style="font-size:13px"><br/>⌛ Every hour <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool.</span>
                                                            <span
                                                                v-if="getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) <= 0.0"
                                                                style="font-size:13px; color: #c57252; padding-top: 5px; padding-bottom: 5px;"><br/>Warning: Every hour <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool! <br/> Stakers must stake longer than 1 hour to claim rewards!</span>
                                                            <span
                                                                v-if="getDailyRewards(rewardDuration, rewardDurationAmount, rewardAmount) > 0.0"
                                                                style="font-size:13px"><br/>🕒 Every day <span
                                                                style="font-weight: bold">{{
                                                                getDailyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool.</span>
                                                            <span
                                                                v-if="getDailyRewards(rewardDuration, rewardDurationAmount, rewardAmount) <= 0.0"
                                                                style="font-size:13px; color: #c57252"><br/>Warning: Every day <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool! <br/> Stakers must stake longer than 1 day to claim rewards!</span>
                                                            <span
                                                                v-if="getMonthlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) > 0.0"
                                                                style="font-size:13px"><br/>🗓️ Every month <span
                                                                style="font-weight: bold">{{
                                                                getMonthlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool.</span>
                                                            <span
                                                                v-if="getMonthlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) <= 0.0"
                                                                style="font-size:13px; color: #c57252"><br/>Warning: Every month <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool! <br/> Stakers must stake longer than 1 month to claim rewards!</span>
                                                            <span
                                                                v-if="getYearlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) > 0.0"
                                                                style="font-size:13px"><br/>☀️ Every year <span
                                                                style="font-weight: bold">{{
                                                                getYearlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool.</span>
                                                            <span
                                                                v-if="getYearlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) <= 0.0"
                                                                style="font-size:13px; color: #c57252"><br/>Warning: Every year <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens become available to stakers in this pool! <br/> Stakers must stake longer than 1 year to claim rewards!</span>
                                                          </p>

                                                        </section>

                                                      </div>


                                                    </section>

                                                  </b-step-item>
                                                  <!-- Stake Token Section -->
                                                  <b-step-item :step="3" :clickable="false" label="Stake">
                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">
                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What <span
                                                                  style="font-weight: bold">type of token</span>
                                                                do stakers <span
                                                                    style="font-weight: bold">stake</span> in this pool
                                                                to
                                                                earn
                                                                rewards?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-size: 17px">What type of token do stakers stake?</span>
                                                                <br/><br/>
                                                                Staking pools distribute 'Reward Tokens' over time to
                                                                stakers who
                                                                stake <span
                                                                  style="font-weight: bold">'Staking Tokens'</span> in
                                                                your
                                                                pool.
                                                                <br/><br/>
                                                                What <span
                                                                  style="font-weight: bold">type of Staking Token</span>
                                                                will
                                                                stakers be staking in your pool?
                                                                <br/><br/>
                                                                Each token on the Kadena Blockchain implements a <span
                                                                  style="font-weight: bold">token interface</span> which
                                                                describes
                                                                how the token functions on the Kadena Blockchain - <span
                                                                  style="font-weight: bold">What interface is implemented by your Staking Token</span>?
                                                                <br/><br/>
                                                                Currently Stake Factory Staking Pools support
                                                                distributing
                                                                tokens of multiple token
                                                                formats/interfaces on the Kadena Blockchain, including:
                                                                <br/><br/>
                                                                <span style="font-weight: bold">fungible-v2</span>:
                                                                Traditional
                                                                Kadena Fungible Tokens (non-NFT) which implement the
                                                                fungible-v2
                                                                interface.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">factory-non-fungible</span>:
                                                                Stake Factory NFT interface.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">Stake Factory LP Token</span>:
                                                                Stake Factory Liquidity Pool Tokens - Any LP token from Stake Factory
                                                                <br/><br/>
                                                                Select the <span style="font-weight: bold">type of 'Staking Token' stakers will stake in your pool</span>
                                                                from the options below and <span
                                                                  style="font-weight: bold">only stakers who stake the Stake Token you specifiy</span>
                                                                in your pool will be eligible for the Reward Tokens your
                                                                pool
                                                                distributes.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>
                                                        <b-field
                                                            style="margin-top: 20px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark"
                                                                       label="What type of token do people Stake into this pool?">
                                                              <label class="label" style="width:50px;">Token
                                                                Type:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field">
                                                              <div class="control">
                                                                <div class="select">
                                                                  <select v-model="stakeType">
                                                                    <option :value="1">Fungible-v2</option>
                                                                    <option :value="3">Marmalade</option>
                                                                    <option :value="2">Factory-Non-Fungible</option>
                                                                    <option v-if="rewardType !== 2" :value="5">Stake
                                                                      Factory LP Tokens
                                                                    </option>
                                                                  </select>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <!--FUNGIV2 STAKE TYPE-->

                                                        <b-field v-if="stakeType === 1 && customFungiv2Stake === false"
                                                                 custom-class="is-small"
                                                                 horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark"
                                                                       label="This is the token stakers must stake in order to earn rewards.">
                                                              <label class="label" style="width:50px;">Staked
                                                                Token:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field">
                                                              <div class="control">
                                                                <div class="select">

                                                                  <select v-model="stakeFungiV2Type">
                                                                    <option value="coin">KDA</option>
                                                                    <option value="free.stake-token">STAKE</option>
                                                                    <option value="free.anedak">ADK</option>
                                                                    <option value="free.babena">BABE</option>
                                                                    <option value="runonflux.flux">FLUX</option>
                                                                    <option value="hypercent.prod-hype-coin">HYPE
                                                                    </option>
                                                                    <option value="kdlaunch.token">KDL</option>
                                                                    <option value="lago.kwUSDC">kwUSDC</option>
                                                                  </select>

                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <b-field v-if="stakeType === 1 && customFungiv2Stake === true"
                                                                 custom-class="is-small"
                                                                 horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="All tokens have a module name (contract name), what is the name of the contract which defines your stake token?.">
                                                              <label class="label" style="width:100px;">Token Module
                                                                Name:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <b-field type="is-info"
                                                                         message="Module name of the fungible-v2 Stake Token contract">
                                                                  <ValidationObserver
                                                                      v-if="stakeType === 1 && customFungiv2Stake === true">
                                                                    <ValidationProvider :rules="{required: true}"
                                                                                        vid="fungiv2reward_name"
                                                                                        name="Fungiv2Reward Name"
                                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="fungiv2StakeModuleName"
                                                                             @change="verifyTokenExists(fungiv2StakeModuleNamespace, fungiv2StakeModuleName)"
                                                                             @input="[checkValidInput(valid), verifyTokenExists(fungiv2StakeModuleNamespace, fungiv2StakeModuleName) ]"
                                                                             class="input select"
                                                                             type="text"
                                                                             placeholder="token-module-name">
                                                                    </ValidationProvider>
                                                                  </ValidationObserver>
                                                                </b-field>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <b-field v-if="stakeType === 1 && customFungiv2Stake === true"
                                                                 custom-class="is-small"
                                                                 horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="All Kadena modules have a namespace, what is the namespace for the contract that defines your stake token?">
                                                              <label class="label" style="width:100px;">Token Module
                                                                Namespace:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <b-field type="is-info"
                                                                         message="Module namespace for the fungible-v2 Stake Token contract">
                                                                  <ValidationObserver
                                                                      v-if="stakeType === 1 && customFungiv2Stake === true">
                                                                    <ValidationProvider :rules="{required: true}"
                                                                                        vid="fungiv2reward_namespace"
                                                                                        name="Fungiblev2Reward Namespace"
                                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="fungiv2StakeModuleNamespace"
                                                                             @change="verifyTokenExists(fungiv2StakeModuleNamespace, fungiv2StakeModuleName)"
                                                                             @input="[checkValidInput(valid), verifyTokenExists(fungiv2StakeModuleNamespace, fungiv2StakeModuleName) ]"
                                                                             class="input select"
                                                                             type="text" placeholder="free">
                                                                    </ValidationProvider>
                                                                  </ValidationObserver>
                                                                </b-field>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <div v-if="stakeType === 1" class="divider is-centered"></div>

                                                        <b-switch
                                                            v-if="stakeType === 1"
                                                            class="mb-1 mt-1"
                                                            v-model="customFungiv2Stake"
                                                            :rounded="true"
                                                            :outlined="true"
                                                            size="is-small"
                                                            type="is-success"
                                                            :left-label='false'
                                                            :passive-type='null'>I don't see my token in the above list
                                                          and I will type it in manually..
                                                        </b-switch>

                                                        <!--FACTORY NON FUNGIBLE STAKE TYPE-->

                                                        <div v-if="stakeType === 2"
                                                             class="column is-centered mb-4 mt-5">
                                                          <div class="columns is-centered pools-card">

                                                            <CollectionCardPreview class="column is-3"
                                                                                   style="margin: 10px; width: 316px !important;"
                                                                                   :collection_name="stake_CollectionID"
                                                                                   :collection_link="stake_CollectionLink"
                                                                                   :collection_signature="stake_CollectionSignature"
                                                                                   :collection_supply="stake_CollectionSupply"
                                                                                   :collection_token="stake_CollectionToken"
                                                                                   :collection_image="stake_CollectionImage"
                                                                                   :show_flipper="true"></CollectionCardPreview>

<!--                                                            <CollectionCardPreview class="column is-3"-->
<!--                                                                             style="margin: 10px; width: 316px !important;"-->
<!--                                                                             pool_contract="factory-stake-polyfungiv1"-->
<!--                                                                             pool_id="?" pool_name="?"-->
<!--                                                                             :token_image="t_stakeTokenImagePolyfungiv1"-->
<!--                                                                             :pool_reward_token_id="polyFungiV1StakeID"-->
<!--                                                                             :token_name="t_stakeTokenNamePolyfungiv1"-->
<!--                                                                             :token_description="t_stakeTokenDescriptionPolyfungiv1"-->
<!--                                                                             :pool_reward_token="polyFungiV1StakeModuleNamespace+'.'+polyFungiV1StakeModuleName"-->
<!--                                                                             :show_flipper="false"-->
<!--                                                                             stake_balance="0" stake_pending_rewards="0"-->
<!--                                                                             stake_rewards="0"></CollectionCardPreview>-->
                                                          </div>
                                                        </div>

                                                        <b-field
                                                            v-if="stakeType === 2"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="Enter the NFT Collection ID for the NFTs that Stakers are allowed to stake in this pool">
                                                              <label class="label" style="width:100px;">NFT Collection ID:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <b-field type="is-info"
                                                                         message="NFT Collection ID of the NFTs allowed to be staked in this pool.">
                                                                  <ValidationObserver
                                                                      v-if="currentStep === 2 && stakeType === 2">
                                                                    <ValidationProvider :rules="{required: true}"
                                                                                        vid="stake_collecitonid"
                                                                                        name="Stake Token Collection ID"
                                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="stake_CollectionID"
                                                                             @input="[checkValidInput(valid), getCollection()]"
                                                                             class="input select"
                                                                             type="text" placeholder="My-NFT-ID">
                                                                    </ValidationProvider>
                                                                  </ValidationObserver>
                                                                </b-field>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <!--POLYFUNGIV2 STAKE TYPE-->
                                                        <div v-if="stakeType === 3" class="divider is-centered mt-1"></div>

                                                        <div v-if="stakeType === 3" class="columns">
                                                          <div class="column" style="display: flex; flex-direction: column; justify-content: center; margin-left: 100px;">

                                                            <b-field
                                                                style="margin-top:20px;"
                                                                v-if="stakeType === 3"
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <template #label>
                                                                <b-tooltip type="is-dark" multilined
                                                                           label="Enter the Link to the Image you would like to show on your pool">
                                                                  <label class="label" style="width:100px;">Link to Pool Image:</label>
                                                                </b-tooltip>
                                                              </template>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <b-field type="is-info"
                                                                             message="Link to the Image to display on your Staking Pool Card">
                                                                      <ValidationObserver
                                                                          v-if="currentStep === 3 && stakeType === 3">
                                                                        <ValidationProvider :rules="{required: true}"
                                                                                            vid="polyFungiV2StakeImage"
                                                                                            name="Polyfungiblev2 Pool Image"
                                                                                            v-slot="{ errors, valid }">
                                                                          <input v-model="polyFungiV2StakeImage"
                                                                                 @input="checkValidInput(valid)"
                                                                                 class="input select"
                                                                                 type="text" placeholder="https://www.link-to-my-image.com">
                                                                        </ValidationProvider>
                                                                      </ValidationObserver>
                                                                    </b-field>
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>

                                                            <div v-if="stakeType === 3" class="divider is-centered mt-1"></div>

                                                            <b-field
                                                                style="margin-top:20px;"
                                                                v-if="stakeType === 3"
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <template #label>
                                                                <b-tooltip type="is-dark" multilined
                                                                           label="Enter the Marmalade poly-fungible-v2 Module Name">
                                                                  <label class="label" style="width:100px;">Marmalade Module Name:</label>
                                                                </b-tooltip>
                                                              </template>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <b-field type="is-info"
                                                                             message="Enter the Marmalade poly-fungible-v2 Module Name">
                                                                      <ValidationObserver
                                                                          v-if="currentStep === 3 && stakeType === 3">
                                                                        <ValidationProvider :rules="{required: true}"
                                                                                            vid="polyfungi2_stakename"
                                                                                            name="Polyfungiblev2 Stake Name"
                                                                                            v-slot="{ errors, valid }">
                                                                          <input v-model="polyFungiV2StakeModuleName"
                                                                                 @input="[checkValidInput(valid), getPolyFungiV2StakeTokenURI()]"
                                                                                 class="input select"
                                                                                 type="text"
                                                                                 placeholder="token-module-name">
                                                                        </ValidationProvider>
                                                                      </ValidationObserver>
                                                                    </b-field>
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>

                                                            <b-field
                                                                v-if="stakeType === 3"
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <template #label>
                                                                <b-tooltip type="is-dark" multilined
                                                                           label="Enter the Marmalade poly-fungible-v2 namespace">
                                                                  <label class="label" style="width:100px;">Marmalade Namespace:</label>
                                                                </b-tooltip>
                                                              </template>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <b-field type="is-info"
                                                                             message="Enter the Marmalade poly-fungible-v2 Namespace">
                                                                      <ValidationObserver
                                                                          v-if="currentStep === 3 && stakeType === 3">
                                                                        <ValidationProvider :rules="{required: true}"
                                                                                            vid="polyfungi2_stakenamespace"
                                                                                            name="Polyfungiblev2 Stake Namespace"
                                                                                            v-slot="{ errors, valid }">
                                                                          <input v-model="polyFungiV2StakeModuleNamespace"
                                                                                 @input="[checkValidInput(valid), getPolyFungiV2StakeTokenURI()]"
                                                                                 class="input select"
                                                                                 type="text" placeholder="free">
                                                                        </ValidationProvider>
                                                                      </ValidationObserver>
                                                                    </b-field>
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>


                                                          </div>
                                                          <div class="column" style="display: flex; flex-direction: column; justify-content: center;">
                                                            <div v-if="stakeType === 3"
                                                                 class="column is-centered mb-4 mt-5">
                                                              <div class="columns is-centered pools-card">
                                                                <FlipCard class="column is-3"
                                                                          style="margin: 10px; width: 316px !important;"
                                                                          pool_contract="factory-stake-fnf-fungiv2"
                                                                          pool_id="?" pool_name="My Staking Pool"
                                                                          :token_image="polyFungiV2StakeImage"
                                                                          pool_stake_token_id="[MY LIST OF NFT IDS]"
                                                                          :pool_reward_token="rewardFungiV2Type"
                                                                          :pool_stake_token="polyFungiV2StakeModuleNamespace+'.'+polyFungiV2StakeModuleName"
                                                                          :pool_apy="rewardAmount" :show_flipper="false"
                                                                          :is_stake_nft="true"
                                                                          stake_balance="0" stake_pending_rewards="0"
                                                                          stake_rewards="0"></FlipCard>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div v-if="stakeType === 3" class="divider is-centered mt-1"></div>

                                                        <accordion v-if="stakeType === 3" style="margin-top: 30px">
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Enter the <span
                                                                  style="font-weight: bold">NFT IDs</span>
                                                                that stakers are allowed to <span
                                                                    style="font-weight: bold">stake</span> in this pool
                                                                to
                                                                earn
                                                                rewards.</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-size: 17px">What type of token do stakers stake?</span>
                                                                <br/><br/>
                                                                Staking pools distribute 'Reward Tokens' over time to
                                                                stakers who
                                                                stake <span
                                                                  style="font-weight: bold">'Staking Tokens'</span> in
                                                                your
                                                                pool.
                                                                <br/><br/>
                                                                What <span
                                                                  style="font-weight: bold">type of Staking Token</span>
                                                                will
                                                                stakers be staking in your pool?
                                                                <br/><br/>
                                                                Each token on the Kadena Blockchain implements a <span
                                                                  style="font-weight: bold">token interface</span> which
                                                                describes
                                                                how the token functions on the Kadena Blockchain - <span
                                                                  style="font-weight: bold">What interface is implemented by your Staking Token</span>?
                                                                <br/><br/>
                                                                Currently Stake Factory Staking Pools support
                                                                distributing
                                                                tokens of multiple token
                                                                formats/interfaces on the Kadena Blockchain, including:
                                                                <br/><br/>
                                                                <span style="font-weight: bold">fungible-v2</span>:
                                                                Traditional
                                                                Kadena Fungible Tokens (non-NFT) which implement the
                                                                fungible-v2
                                                                interface.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">factory-non-fungible</span>:
                                                                Stake Factory NFT interface.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">Stake Factory LP Token</span>:
                                                                Stake Factory Liquidity Pool Tokens - Any LP token from Stake Factory
                                                                <br/><br/>
                                                                Select the <span style="font-weight: bold">type of 'Staking Token' stakers will stake in your pool</span>
                                                                from the options below and <span
                                                                  style="font-weight: bold">only stakers who stake the Stake Token you specifiy</span>
                                                                in your pool will be eligible for the Reward Tokens your
                                                                pool
                                                                distributes.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <b-field
                                                            style="margin-top:40px;"
                                                            v-if="stakeType === 3"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="Paste NFT IDs that are allowed to be Staked in this pool - 1 ID Per line">
                                                              <label class="label" style="width:100px;">Stakable NFT IDs:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <b-field type="is-info">
                                                                  <textarea v-model="polyFungiV2StakableIDs"
                                                                            @input="makeNFTIDList(polyFungiV2StakableIDs)"
                                                                            style="height:300px;"
                                                                            class="input select"
                                                                            type="textarea"
                                                                            placeholder=""/>

                                                                </b-field>
                                                                <span v-if="!errors[0]"
                                                                      class="error validation-msg-green is-info">Enter NFT IDs Stakers are allowed to Stake into this pool to earn rewards (One ID Per Line!).</span>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>









                                                        <b-field
                                                            v-if="stakeType !== 1 && stakeType !== 2 && stakeType !== 3 && customLPStakeToken === true"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="All tokens have a module name (contract name), what is the name of the smart contract for the first token in your LP token pair?">
                                                              <label class="label" style="width:100px;">LP Pair 1 Token
                                                                Module Name:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <b-field type="is-info"
                                                                         message="Module name of the 1st fungible-v2 token in your LP pair">
                                                                  <ValidationObserver
                                                                      v-if="stakeType !== 1 && stakeType !== 2 && stakeType !== 3 && customLPStakeToken === true">
                                                                    <ValidationProvider :rules="{required: true}"
                                                                                        vid="fungiv2reward_name"
                                                                                        name="Fungiv2Reward Name"
                                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="lp1StakeModuleName"
                                                                             @change="verifyTokenExists(lp1StakeModuleNamespace, lp1StakeModuleName)"
                                                                             @input="[checkValidInput(valid), verifyTokenExists(lp1StakeModuleNamespace, lp1StakeModuleName) ]"
                                                                             class="input select"
                                                                             type="text"
                                                                             placeholder="token-module-name">
                                                                    </ValidationProvider>
                                                                  </ValidationObserver>
                                                                </b-field>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <b-field
                                                            v-if="stakeType !== 1 && stakeType !== 2 && stakeType !== 3 && customLPStakeToken === true"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="All Kadena modules have a namespace, what is the namespace of the contract for the first token in your LP pair?">
                                                              <label class="label" style="width:100px;">LP Pair 1 Token
                                                                Module
                                                                Namespace:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <b-field type="is-info"
                                                                         message="Module namespace for the 1st fungible-v2 token in your LP token pair">
                                                                  <ValidationObserver
                                                                      v-if="stakeType !== 1 && stakeType !== 2 && stakeType !== 3 && customLPStakeToken === true">
                                                                    <ValidationProvider
                                                                        :rules="`lpNotSameCustom:${[lp1StakeModuleNamespace, lp1StakeModuleName, lp2StakeModuleNamespace, lp2StakeModuleName ]}`"
                                                                        vid="fungiv2reward_namespace"
                                                                        name="Fungiblev2Reward Namespace"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="lp1StakeModuleNamespace"
                                                                             @change="verifyTokenExists(lp1StakeModuleNamespace, lp1StakeModuleName)"
                                                                             @input="[checkValidInput(valid), verifyTokenExists(lp1StakeModuleNamespace, lp1StakeModuleName) ]"
                                                                             class="input select"
                                                                             type="text" placeholder="free">
                                                                    </ValidationProvider>
                                                                  </ValidationObserver>
                                                                </b-field>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <div
                                                            v-if="stakeType !== 1 && stakeType !== 2 && stakeType !== 3 && customLPStakeToken === true"
                                                            class="divider is-centered"></div>

                                                        <b-field
                                                            v-if="stakeType !== 1 && stakeType !== 2 && stakeType !== 3 && customLPStakeToken === true"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="All tokens have a module name (contract name), what is the module name of the smart contract for the 2nd token in your LP token pair?">
                                                              <label class="label" style="width:100px;">LP Pair 2 Token
                                                                Module Name:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <b-field type="is-info"
                                                                         message="Module name of the 1st fungible-v2 token in your LP pair">
                                                                  <ValidationObserver
                                                                      v-if="stakeType !== 1 && stakeType !== 2 && stakeType !== 3 && customLPStakeToken === true">
                                                                    <ValidationProvider :rules="{required: true}"
                                                                                        vid="fungiv2stake_name"
                                                                                        name="Fungiv2Stake Name"
                                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="lp2StakeModuleName"
                                                                             @change="[verifyTokenExists(lp2StakeModuleNamespace, lp2StakeModuleName), verifyLPTokenBalance()]"
                                                                             @input="[checkValidInput(valid), verifyTokenExists(lp2StakeModuleNamespace, lp2StakeModuleName) ]"
                                                                             class="input select"
                                                                             type="text"
                                                                             placeholder="token-module-name">
                                                                    </ValidationProvider>
                                                                  </ValidationObserver>
                                                                </b-field>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <b-field
                                                            v-if="stakeType !== 1 && stakeType !== 2 && stakeType !== 3 && customLPStakeToken === true"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="All Kadena modules have a namespace, what is the namespace of the contract that makes up the 2nd half of your LP token?">
                                                              <label class="label" style="width:100px;">LP Pair 2 Token
                                                                Module
                                                                Namespace:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <b-field type="is-info"
                                                                         message="Module namespace for the 2nd fungible-v2 token in your LP token pair.">
                                                                  <ValidationObserver
                                                                      v-if="stakeType !== 1 && stakeType !== 2 && stakeType !== 3 && customLPStakeToken === true">
                                                                    <ValidationProvider :rules="{required: true}"
                                                                                        vid="fungiv2reward_namespace"
                                                                                        name="Fungiblev2Reward Namespace"
                                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="lp2StakeModuleNamespace"
                                                                             @change="[verifyTokenExists(lp2StakeModuleNamespace, lp2StakeModuleName), verifyLPTokenBalance()]"
                                                                             @input="[checkValidInput(valid), verifyTokenExists(lp2StakeModuleNamespace, lp2StakeModuleName) ]"
                                                                             class="input select"
                                                                             type="text" placeholder="free">
                                                                    </ValidationProvider>
                                                                  </ValidationObserver>
                                                                </b-field>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <ValidationObserver
                                                            v-if="currentStep === 3">


                                                          <b-field
                                                              v-if="stakeType !== 1 && stakeType !== 2 && stakeType !== 3 && customLPStakeToken === false"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark"
                                                                         label="Select the 1st token of the LP token pair.">
                                                                <label class="label" style="width:50px;">LP Pair Token
                                                                  1:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field">
                                                                <div class="control">
                                                                  <div class="select">

                                                                    <ValidationProvider
                                                                        :rules="`lpNotKdaKda:${lpToken2Type}`"
                                                                        v-slot="{ errors, valid }">
                                                                      <select @input="valid" @change="verifyLPTokenBalance()" v-model="lpToken1Type">
                                                                        <option value="coin">KDA</option>
                                                                        <option value="free.stake-token">STAKE</option>
                                                                        <option value="free.anedak">ADK</option>
                                                                        <option value="free.babena">BABE</option>
                                                                        <option value="runonflux.flux">FLUX</option>
                                                                        <option value="hypercent.prod-hype-coin">HYPE
                                                                        </option>
                                                                        <option value="kdlaunch.token">KDL</option>
                                                                        <option value="lago.kwUSDC">kwUSDC</option>
                                                                      </select>
                                                                    </ValidationProvider>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field
                                                              v-if="stakeType !== 1 && stakeType !== 2 && stakeType !== 3 && customLPStakeToken === false"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark"
                                                                         label="Select the 2nd token of the LP token pair.">
                                                                <label class="label" style="width:50px;">LP Pair Token
                                                                  2:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field">
                                                                <div class="control">
                                                                  <div class="select">
                                                                    <select @change="verifyLPTokenBalance()" v-model="lpToken2Type">
                                                                      <option value="free.anedak">ADK</option>
                                                                      <option value="free.stake-token">STAKE</option>
                                                                      <option value="free.babena">BABE</option>
                                                                      <option value="coin">KDA</option>
                                                                      <option value="runonflux.flux">FLUX</option>
                                                                      <option value="hypercent.prod-hype-coin">HYPE
                                                                      </option>
                                                                      <option value="kdlaunch.token">KDL</option>
                                                                      <option value="lago.kwUSDC">kwUSDC</option>
                                                                    </select>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <div
                                                              v-if="stakeType !== 1 && stakeType !== 2 && stakeType !== 3 && customLPStakeToken === true"
                                                              class="divider is-centered"></div>

                                                          <b-field
                                                              v-if="stakeType === 5"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What is the name of the Stake Factory LP Pool for this LP Pair?">
                                                                <label class="label" style="width:100px;">Stake Factory
                                                                  LP
                                                                  Pool ID:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info"
                                                                           message="Pool Name of the Stake Factory LP Token Pair">
                                                                    <ValidationObserver
                                                                        v-if="currentStep === 3 && stakeType === 5">
                                                                      <ValidationProvider :rules="{required: true}"
                                                                                          vid="sf_poolname"
                                                                                          name="Stake Pool Name"
                                                                                          v-slot="{ errors, valid }">
                                                                        <input v-model="lpStakeTokenPoolName"
                                                                               @input="[checkValidInput(valid), verifyLPTokenBalance()]"
                                                                               class="input select"
                                                                               type="text" placeholder="stake-factory">
                                                                      </ValidationProvider>
                                                                    </ValidationObserver>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>



                                                        </ValidationObserver>


                                                        <div
                                                            v-if="stakeType !== 1 && stakeType !== 2 && stakeType !== 3"
                                                            class="divider is-centered"></div>

                                                        <b-switch
                                                            v-if="stakeType !== 1 && stakeType !== 2 && stakeType !== 3"
                                                            class="mb-1 mt-1"
                                                            v-model="customLPStakeToken"
                                                            :rounded="true"
                                                            :outlined="true"
                                                            size="is-small"
                                                            type="is-success"
                                                            :left-label='false'
                                                            :passive-type='null'>I don't see my token in the above lists
                                                          and I will type both in manually..
                                                        </b-switch>

                                                        <p v-if="stakeType === 1 && customFungiv2Stake === false"
                                                           class="blue-bg font-green mt-4"> Stakers will
                                                          stake <span style="font-weight: bold">{{
                                                              stakeFungiV2Type
                                                            }}</span> tokens
                                                          in this pool. </p>

                                                        <p v-if="stakeType === 1 && customFungiv2Stake === true"
                                                           class="blue-bg font-green mt-4"> Stakers will
                                                          stake <span
                                                              style="font-weight: bold">{{
                                                              fungiv2StakeModuleNamespace
                                                            }}.{{ fungiv2StakeModuleName }}</span>
                                                          tokens
                                                          in this pool. </p>

                                                        <p v-if="stakeType === 2" class="blue-bg font-green mt-4">
                                                          Stakers
                                                          will
                                                          stake NFTs from the NFT Collection <span style="font-weight: bold">{{stake_CollectionID}}</span>
                                                          in this pool. </p>

                                                        <p v-if="stakeType === 3" class="blue-bg font-green mt-4">
                                                          Stakers
                                                          will
                                                          stake <span style="font-weight: bold">{{
                                                            polyFungiV2StakeModuleNamespace
                                                          }}.{{ polyFungiV2StakeModuleName }} </span> tokens
                                                          in this pool, but <span style="font-weight: bold">only the ID's I allow above</span>. </p>

                                                        <p v-if="stakeType === 4 && customLPStakeToken === false && lpToken1Type !== lpToken2Type"
                                                           class="blue-bg font-green mt-4"> Stakers will stake <span
                                                            style="font-weight: bold">{{ lpToken1Type }}:{{
                                                            lpToken2Type
                                                          }} ASwap LP</span>
                                                          tokens in this pool. </p>

                                                        <p v-if="stakeType === 4 && customLPStakeToken === true && lp1StakeModuleNamespace+lp1StakeModuleName !== lp2StakeModuleNamespace+lp2StakeModuleName && lp1StakeModuleNamespace !== '' && lp2StakeModuleNamespace !== ''"
                                                           class="blue-bg font-green mt-4"> Stakers will stake <span
                                                            style="font-weight: bold">{{
                                                            lp1StakeModuleNamespace
                                                          }}.{{ lp1StakeModuleName }}:{{
                                                            lp2StakeModuleNamespace
                                                          }}.{{ lp2StakeModuleName }} ASwap LP</span>
                                                          tokens in this pool. </p>

                                                        <p v-if="stakeType === 4 && customLPStakeToken === true && lp1StakeModuleNamespace+lp1StakeModuleName !== lp2StakeModuleNamespace+lp2StakeModuleName && lp1StakeModuleNamespace === '' && lp2StakeModuleNamespace !== ''"
                                                           class="blue-bg font-green mt-4"> Stakers will stake <span
                                                            style="font-weight: bold">{{
                                                            lp1StakeModuleName
                                                          }}:{{ lp2StakeModuleNamespace }}.{{ lp2StakeModuleName }} ASwap LP</span>
                                                          tokens in this pool. </p>

                                                        <p v-if="stakeType === 4 && customLPStakeToken === true && lp1StakeModuleNamespace+lp1StakeModuleName !== lp2StakeModuleNamespace+lp2StakeModuleName && lp1StakeModuleNamespace !== '' && lp2StakeModuleNamespace === ''"
                                                           class="blue-bg font-green mt-4"> Stakers will stake <span
                                                            style="font-weight: bold">{{
                                                            lp1StakeModuleNamespace
                                                          }}.{{
                                                            lp1StakeModuleName
                                                          }}:{{ lp2StakeModuleName }} ASwap LP</span>
                                                          tokens in this pool. </p>

                                                        <p v-if="stakeType === 5 && customLPStakeToken === false && lpToken1Type !== lpToken2Type"
                                                           class="blue-bg font-green mt-4"> Stakers will stake <span
                                                            style="font-weight: bold">{{ lpToken1Type }}:{{
                                                            lpToken2Type
                                                          }}:{{ lpStakeTokenPoolName }} Stake Factory LP</span>
                                                          tokens in this pool. </p>

                                                        <p v-if="stakeType === 5 && customLPStakeToken === true && lp1StakeModuleNamespace+lp1StakeModuleName !== lp2StakeModuleNamespace+lp2StakeModuleName && lp1StakeModuleNamespace !== '' && lp2StakeModuleNamespace !== ''"
                                                           class="blue-bg font-green mt-4"> Stakers will stake <span
                                                            style="font-weight: bold">{{
                                                            lp1StakeModuleNamespace
                                                          }}.{{ lp1StakeModuleName }}:{{
                                                            lp2StakeModuleNamespace
                                                          }}.{{ lp2StakeModuleName }}:{{ lpStakeTokenPoolName }} Stake Factory LP</span>
                                                          tokens in this pool. </p>

                                                        <p v-if="stakeType === 5 && customLPStakeToken === true && lp1StakeModuleNamespace+lp1StakeModuleName !== lp2StakeModuleNamespace+lp2StakeModuleName && lp1StakeModuleNamespace === '' && lp2StakeModuleNamespace !== ''"
                                                           class="blue-bg font-green mt-4"> Stakers will stake <span
                                                            style="font-weight: bold">{{
                                                            lp1StakeModuleName
                                                          }}:{{ lp2StakeModuleNamespace }}.{{
                                                            lp2StakeModuleName
                                                          }}:{{ lpStakeTokenPoolName }} Stake Factory LP</span>
                                                          tokens in this pool. </p>

                                                        <p v-if="stakeType === 5 && customLPStakeToken === true && lp1StakeModuleNamespace+lp1StakeModuleName !== lp2StakeModuleNamespace+lp2StakeModuleName && lp1StakeModuleNamespace !== '' && lp2StakeModuleNamespace === ''"
                                                           class="blue-bg font-green mt-4"> Stakers will stake <span
                                                            style="font-weight: bold">{{
                                                            lp1StakeModuleNamespace
                                                          }}.{{
                                                            lp1StakeModuleName
                                                          }}:{{ lp2StakeModuleName }}:{{ lpStakeTokenPoolName }} Stake Factory LP</span>
                                                          tokens in this pool. </p>

                                                        <p v-if="stakeType === 6 && customLPStakeToken === false && lpToken1Type !== lpToken2Type"
                                                           class="blue-bg font-green mt-4"> Stakers will stake <span
                                                            style="font-weight: bold">{{ lpToken1Type }}:{{
                                                            lpToken2Type
                                                          }} KDSwap LP</span>
                                                          tokens in this pool. </p>

                                                        <p v-if="stakeType === 6 && customLPStakeToken === true && lp1StakeModuleNamespace+lp1StakeModuleName !== lp2StakeModuleNamespace+lp2StakeModuleName"
                                                           class="blue-bg font-green mt-4"> Stakers will stake <span
                                                            style="font-weight: bold">{{
                                                            lp1StakeModuleNamespace
                                                          }}.{{ lp1StakeModuleName }}:{{
                                                            lp2StakeModuleNamespace
                                                          }}.{{ lp2StakeModuleName }} KDSwap LP</span>
                                                          tokens in this pool. </p>

                                                        <p v-if="stakeType === 7 && customLPStakeToken === false && lpToken1Type !== lpToken2Type"
                                                           class="blue-bg font-green mt-4"> Stakers will stake <span
                                                            style="font-weight: bold">{{ lpToken1Type }}:{{
                                                            lpToken2Type
                                                          }} Kaddex LP</span>
                                                          tokens in this pool. </p>

                                                        <p v-if="stakeType === 7 && customLPStakeToken === true && lp1StakeModuleNamespace+lp1StakeModuleName !== lp2StakeModuleNamespace+lp2StakeModuleName"
                                                           class="blue-bg font-green mt-4"> Stakers will stake <span
                                                            style="font-weight: bold">{{
                                                            lp1StakeModuleNamespace
                                                          }}.{{ lp1StakeModuleName }}:{{
                                                            lp2StakeModuleNamespace
                                                          }}.{{ lp2StakeModuleName }} Kaddex LP</span>
                                                          tokens in this pool. </p>

                                                        <p v-if="stakeType !== 1 && stakeType !== 2 && stakeType !== 3 && customLPStakeToken === false && lpToken1Type === lpToken2Type"
                                                           class="blue-bg font-red mt-4"> Token pairs must be <span
                                                            style="font-weight: bold">unique</span> pairs. </p>

                                                        <p v-if="stakeType !== 1 && stakeType !== 2 && stakeType !== 3 && customLPStakeToken === true && lp1StakeModuleNamespace+lp1StakeModuleName === lp2StakeModuleNamespace+lp2StakeModuleName"
                                                           class="blue-bg font-red mt-4"> Token pairs must be <span
                                                            style="font-weight: bold">unique</span> pairs. </p>

                                                        <p v-if="localModuleExists === false" class="blue-bg is-danger mt-2 pt-2">
                                                          <span style="color:#f9868b">Error: Token you entered could not be found.</span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>
                                                  <!-- Pool Details Section -->
                                                  <b-step-item :step="4" :clickable="false" label="Pool">
                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">
                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Pool Rules and Custom
                                                                Options</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-size: 17px">Does your pool have any custom rules?</span>
                                                                <br/><br/>
                                                                Staking pools can <span
                                                                  style="font-weight: bold">do more</span>
                                                                than just distribute Reward Tokens to stakers over time, use these custom rules to set up custom withdraw lock times, or claim wait times.
                                                                <br/><br/>
                                                                Create custom <span
                                                                  style="font-weight: bold">Withdraw Wait Times</span>
                                                                and
                                                                <span
                                                                    style="font-weight: bold">Reward Claim Wait Times</span>
                                                                to
                                                                control how long stakers have to wait before they can
                                                                unstake their
                                                                tokens, or how long they have to wait before they can
                                                                claim their
                                                                rewards.
                                                                <br/><br/>
                                                                <span
                                                                    style="font-weight: bold">Withdraw Wait Time</span>:
                                                                The
                                                                number of seconds stakers must lock their tokens in your
                                                                pool before
                                                                they can withdraw them.
                                                                <br/><br/>
                                                                <span
                                                                    style="font-weight: bold">Reward Claim Wait Time</span>:
                                                                The
                                                                number of seconds stakers must wait each time before
                                                                they
                                                                can claim
                                                                rewards (<span style="font-weight: bold">this includes between reward claims</span>).
                                                                <br/><br/>
                                                                Use these settings to adjust custom <span
                                                                  style="font-weight: bold">withdraw locks</span>
                                                                and <span
                                                                  style="font-weight: bold">reward claim times</span>
                                                                for
                                                                your pool.
                                                                <br/><br/>
                                                                Once your pool is published, <span
                                                                  style="font-weight: bold">these settings cannot be changed</span>,
                                                                and always remain displayed next to your pool on the
                                                                Stake
                                                                Factory
                                                                website so stakers are aware of the conditions and terms
                                                                of your
                                                                pool.
                                                                <br/><br/>
                                                                Stakers can also always view the length of time their
                                                                tokens have
                                                                been staked in your pool from their staking pool panel
                                                                so
                                                                they can
                                                                plan their withdraws and claims accordingly.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>
                                                        <ValidationObserver v-if="currentStep === 4">
                                                          <b-field
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined label="The minimum time (in seconds) a staker must wait before withdrawing their staked tokens.">
                                                                <label class="label" style="width:100px;">Withdraw Wait
                                                                  Time:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info"
                                                                           message="Minimum seconds before a staker is allowed to unstake">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthanequalto: 0.0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="withdraw_wait_time"
                                                                        name="Withdraw Wait Time"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="withdrawWaitTime"
                                                                             @input="checkValidInput(valid)"
                                                                             class="input select"
                                                                             type="text" placeholder="0.0">
                                                                    </ValidationProvider>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="The minimum time (in seconds) a staker must wait between claiming rewards.">
                                                                <label class="label" style="width:100px;">Reward Claim
                                                                  Wait
                                                                  Time:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info"
                                                                           message="Minimum seconds between reward claims">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthanequalto: 0.0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="claim_wait_time" name="Claim Wait Time"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="rewardWaitTime"
                                                                             @input="checkValidInput(valid)"
                                                                             class="input select"
                                                                             type="text" placeholder="0.0">
                                                                    </ValidationProvider>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                        </ValidationObserver>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <div class="blue-bg font-green">
                                                          <p v-if="withdrawWaitTime === 0 || withdrawWaitTime === '0.0' || withdrawWaitTime === '0' || withdrawWaitTime === '0.' || withdrawWaitTime == 0">
                                                            Stakers can <span style="font-weight: bold">withdraw</span>
                                                            their stake
                                                            at <span style="font-weight: bold">any time</span>. </p>
                                                          <p v-if="withdrawWaitTime !== 0 && withdrawWaitTime !== '0.0' && withdrawWaitTime !== '0' && withdrawWaitTime !== '0.' && withdrawWaitTime != 0">
                                                            Stakers can <span style="font-weight: bold">withdraw</span>
                                                            their staked
                                                            tokens after <span style="font-weight: bold">{{
                                                              withdrawWaitTime
                                                            }} seconds</span>.
                                                          </p>

                                                          <p v-if="rewardWaitTime === 0 || rewardWaitTime === '0.0' || rewardWaitTime === '0' || rewardWaitTime === '0.' || rewardWaitTime == 0">
                                                            Stakers can <span style="font-weight: bold">claim</span>
                                                            their
                                                            rewards
                                                            at <span style="font-weight: bold">any time</span>. </p>
                                                          <p v-if="rewardWaitTime !== 0 && rewardWaitTime !== '0.0' && rewardWaitTime !== '0' && rewardWaitTime !== '0.' && rewardWaitTime != 0">
                                                            Stakers can <span style="font-weight: bold">claim</span>
                                                            their
                                                            rewards
                                                            once every <span
                                                              style="font-weight: bold">{{
                                                              rewardWaitTime
                                                            }} seconds</span>. </p>
                                                        </div>


                                                      </div>

                                                    </section>

                                                  </b-step-item>
                                                  <!-- Pool Name Section -->
                                                  <b-step-item :step="5" :clickable="false" label="Name">
                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">
                                                        <p class="blue-bg">Pool Details</p>
                                                        <br/>
                                                        <b-field
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="The Pool's ID. Example: test-pool">
                                                              <label class="label pt-1" style="width:50px;">Pool
                                                                ID:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="poolId" disabled
                                                                       class="input select-disabled"
                                                                       type="text" placeholder="10">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>
                                                        <p v-if="localPoolIdExists === false && localPoolIdExists1 === false && localPoolIdExists2 === false"
                                                           class="help is-success">
                                                          This Pool ID is available.
                                                        </p>
                                                        <p v-if="localPoolIdExists === true || localPoolIdExists1 === true || localPoolIdExists2 === true"
                                                           class="help is-danger">
                                                          This Pool ID is invalid.
                                                        </p>

                                                        <b-field
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="The Pool's Name shown to Stakers. Example: Test Pool">
                                                              <label class="label pt-1" style="width:50px;">Pool
                                                                Name:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="poolName"
                                                                       @change="determinePoolId(rewardType, stakeType)"
                                                                       @input="determinePoolId(rewardType, stakeType)"
                                                                       class="input select"
                                                                       type="text" placeholder="10">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                      </div>
                                                    </section>


                                                  </b-step-item>
                                                  <!-- Pool Review Section -->
                                                  <b-step-item :step="6" :clickable="false" label="Review">
                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">
                                                        <p class="blue-bg">Review</p>
                                                        <br/>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Here is a preview of your
                                                                pool! Does everything look ok so far? </h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Check out the preview of your pool below and make sure
                                                                everything looks ok - This is how your pool will appear
                                                                on
                                                                the Stake Factory website.
                                                                <br/><br/>
                                                                After clicking create pool, your pool will be created
                                                                and
                                                                active on the Stake Factory Website.
                                                                <br/><br/>
                                                                Always monitor your pool's End Time by visiting your
                                                                pools
                                                                settings page - Once your pool is finished distributing
                                                                tokens it will end and become expired.
                                                                <br/><br/>
                                                                You can always add more tokens to your pool before it
                                                                expires, to keep it from becoming expired.
                                                                <br/><br/>
                                                                You cannot add tokens to a pool that has already
                                                                expired.
                                                                <br/><br/>
                                                                To add more tokens to your pool, visit your pools
                                                                settings
                                                                page once it has been created.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <!-- POOL PREVIEWS -->

                                                        <PoolCardPreview
                                                            v-if="stakeType === 1 && rewardType === 1 && customLPStakeToken === true"
                                                            pool_contract="factory-stake-fungiv2"
                                                            :pool_id="poolId" :pool_name="poolName"
                                                            :pool_stake_token="coinName(fungiv2StakeModuleNamespace,fungiv2StakeModuleName)"
                                                            :pool_reward_token="coinName(fungiv2RewardModuleNamespace,fungiv2RewardModuleName)"
                                                            :pool_apy="rewardAmount"></PoolCardPreview>

                                                        <PoolCardPreview
                                                            v-if="stakeType === 1 && rewardType === 1 && customLPStakeToken === false"
                                                            pool_contract="factory-stake-fungiv2"
                                                            :pool_id="poolId" :pool_name="poolName"
                                                            :pool_stake_token="stakeFungiV2Type"
                                                            :pool_reward_token="coinName(fungiv2RewardModuleNamespace,fungiv2RewardModuleName)"
                                                            :pool_apy="rewardAmount"></PoolCardPreview>

                                                        <div
                                                            v-if="stakeType === 1 && rewardType === 2 && customFungiv2Stake === false"
                                                            class="column is-centered mb-4 mt-5">
                                                          <div class="columns is-centered pools-card">
                                                            <FlipCard class="column is-3"
                                                                      style="margin: 10px; width: 316px !important;"
                                                                      pool_contract="factory-stake-fungiv2-polyfungiv1"
                                                                      :pool_id="poolId" :pool_name="poolName"
                                                                      :token_image="t_rewardTokenImagePolyfungiv1"
                                                                      :pool_reward_token_id="polyFungiV1ID"
                                                                      :pool_stake_token="stakeFungiV2Type"
                                                                      :pool_reward_token="coinName(polyFungiV1ModuleName,polyFungiV1ModuleNamespace)"
                                                                      :pool_apy="rewardAmount" :show_flipper="false"
                                                                      :is_stake_nft="false"
                                                                      stake_balance="0" stake_pending_rewards="0"
                                                                      stake_rewards="0"></FlipCard>
                                                          </div>
                                                        </div>

                                                        <div
                                                            v-if="stakeType === 1 && rewardType === 2 && customFungiv2Stake === true"
                                                            class="column is-centered mb-4 mt-5">
                                                          <div class="columns is-centered pools-card">
                                                            <FlipCard class="column is-3"
                                                                      style="margin: 10px; width: 316px !important;"
                                                                      pool_contract="factory-stake-fungiv2-polyfungiv1"
                                                                      :pool_id="poolId" :pool_name="poolName"
                                                                      :token_image="t_rewardTokenImagePolyfungiv1"
                                                                      :pool_reward_token_id="polyFungiV1ID"
                                                                      :pool_stake_token="coinName(fungiv2StakeModuleNamespace,fungiv2StakeModuleName)"
                                                                      :pool_reward_token="coinName(polyFungiV1ModuleName,polyFungiV1ModuleNamespace)"
                                                                      :pool_apy="rewardAmount" :show_flipper="false"
                                                                      :is_stake_nft="false"
                                                                      stake_balance="0" stake_pending_rewards="0"
                                                                      stake_rewards="0"></FlipCard>
                                                          </div>
                                                        </div>

                                                        <div
                                                            v-if="stakeType === 2 && rewardType === 1 && customFungiv2Reward === true"
                                                            class="column is-centered mb-4 mt-5">
                                                          <div class="columns is-centered pools-card">
                                                            <FlipCard class="column is-3"
                                                                      style="margin: 10px; width: 316px !important;"
                                                                      pool_contract="factory-stake-fnf-fungiv2"
                                                                      :pool_id="poolId" :pool_name="poolName"
                                                                      :token_image="t_stakeTokenImagePolyfungiv1"
                                                                      :pool_stake_token_id="polyFungiV1StakeID"
                                                                      :pool_reward_token="coinName(fungiv2RewardModuleNamespace,fungiv2RewardModuleName)"
                                                                      :pool_stake_token="coinName(polyFungiV1StakeModuleNamespace,polyFungiV1StakeModuleName)"
                                                                      :pool_apy="rewardAmount" :show_flipper="false"
                                                                      :is_stake_nft="true"
                                                                      stake_balance="0" stake_pending_rewards="0"
                                                                      stake_rewards="0"></FlipCard>
                                                          </div>
                                                        </div>

                                                        <div
                                                            v-if="stakeType === 2 && rewardType === 1 && customFungiv2Reward === false"
                                                            class="column is-centered mb-4 mt-5">
                                                          <div class="columns is-centered pools-card">
                                                            <FlipCard class="column is-3"
                                                                      style="margin: 10px; width: 316px !important;"
                                                                      pool_contract="factory-stake-fnf-fungiv2"
                                                                      :pool_id="poolId" :pool_name="poolName"
                                                                      :token_image="stake_CollectionImage"
                                                                      :pool_stake_token_id="stake_CollectionID"
                                                                      :pool_reward_token="rewardFungiV2Type"
                                                                      :pool_stake_token="stake_CollectionID"
                                                                      :pool_apy="rewardAmount" :show_flipper="false"
                                                                      :is_stake_nft="true"
                                                                      stake_balance="0" stake_pending_rewards="0"
                                                                      stake_rewards="0"></FlipCard>
                                                          </div>
                                                        </div>

                                                        <div
                                                            v-if="stakeType === 3 && rewardType === 1 && customFungiv2Reward === true"
                                                            class="column is-centered mb-4 mt-5">
                                                          <div class="columns is-centered pools-card">
                                                            <FlipCard class="column is-3"
                                                                      style="margin: 10px; width: 316px !important;"
                                                                      pool_contract="factory-stake-polyfungiv2-fungiv2"
                                                                      :pool_id="poolId" :pool_name="poolName"
                                                                      :token_image="polyFungiV2StakeImage"
                                                                      pool_stake_token_id="[MY LIST OF NFT IDS]"
                                                                      :pool_reward_token="coinName(fungiv2RewardModuleNamespace,fungiv2RewardModuleName)"
                                                                      :pool_stake_token="coinName(polyFungiV2StakeModuleNamespace,polyFungiV2StakeModuleName)"
                                                                      :pool_apy="rewardAmount" :show_flipper="false"
                                                                      :is_stake_nft="true"
                                                                      stake_balance="0" stake_pending_rewards="0"
                                                                      stake_rewards="0"></FlipCard>
                                                          </div>
                                                        </div>

                                                        <div
                                                            v-if="stakeType === 3 && rewardType === 1 && customFungiv2Reward === false"
                                                            class="column is-centered mb-4 mt-5">
                                                          <div class="columns is-centered pools-card">
                                                            <FlipCard class="column is-3"
                                                                      style="margin: 10px; width: 316px !important;"
                                                                      pool_contract="factory-stake-polyfungiv2-fungiv2"
                                                                      :pool_id="poolId" :pool_name="poolName"
                                                                      :token_image="polyFungiV2StakeImage"
                                                                      pool_stake_token_id="[MY LIST OF NFT IDS]"
                                                                      :pool_reward_token="rewardFungiV2Type"
                                                                      :pool_stake_token="coinName(polyFungiV2StakeModuleNamespace,polyFungiV2StakeModuleName)"
                                                                      :pool_apy="rewardAmount" :show_flipper="false"
                                                                      :is_stake_nft="true"
                                                                      stake_balance="0" stake_pending_rewards="0"
                                                                      stake_rewards="0"></FlipCard>
                                                          </div>
                                                        </div>

                                                        <div v-if="stakeType === 2 && rewardType === 2"
                                                             class="column is-centered mb-4 mt-5">
                                                          <div class="columns is-centered pools-card">
                                                            <FlipCardNFTNFT class="column is-4"
                                                                            style="margin: 10px; width: 422px !important;"
                                                                            pool_contract="factory-stake-polyfungiv1"
                                                                            :pool_id="poolId" :pool_name="poolName"
                                                                            :token_image="t_rewardTokenImagePolyfungiv1"
                                                                            :pool_reward_token_id="polyFungiV1ID"
                                                                            :stake_token_image="t_stakeTokenImagePolyfungiv1"
                                                                            :pool_stake_token_id="polyFungiV1StakeID"
                                                                            :pool_stake_token="coinName(polyFungiV1StakeModuleNamespace,polyFungiV1StakeModuleName)"
                                                                            :pool_reward_token="coinName(polyFungiV1ModuleNamespace,polyFungiV1StakeModuleName)"
                                                                            :pool_apy="rewardAmount"
                                                                            :show_flipper="false"
                                                                            stake_balance="0" stake_pending_rewards="0"
                                                                            stake_rewards="0"></FlipCardNFTNFT>
                                                          </div>
                                                        </div>

                                                        <div v-if="stakeType === 3 && rewardType === 3"
                                                             class="column is-centered mb-4 mt-5">
                                                          <div class="columns is-centered pools-card">
                                                            <FlipCardNFTNFT class="column is-4"
                                                                            style="margin: 10px; width: 422px !important;"
                                                                            pool_contract="factory-stake-polyfungiv1"
                                                                            :pool_id="poolId" :pool_name="poolName"
                                                                            :token_image="t_rewardTokenImagePolyfungiv2"
                                                                            :pool_reward_token_id="polyFungiV2ID"
                                                                            :stake_token_image="t_stakeTokenImagePolyfungiv2"
                                                                            :pool_stake_token_id="polyFungiV2StakeID"
                                                                            :pool_stake_token="coinName(polyFungiV2StakeModuleNamespace,polyFungiV2StakeModuleName)"
                                                                            :pool_reward_token="coinName(polyFungiV2ModuleNamespace,polyFungiV2StakeModuleName)"
                                                                            :pool_apy="rewardAmount"
                                                                            :show_flipper="false"
                                                                            stake_balance="0" stake_pending_rewards="0"
                                                                            stake_rewards="0"></FlipCardNFTNFT>
                                                          </div>
                                                        </div>

                                                        <PoolCardPreview
                                                            v-if="stakeType === 4 && customLPStakeToken === true && rewardType === 1"
                                                            pool_contract="factory-stake-fungiv2"
                                                            :pool_id="poolId" :pool_name="poolName"
                                                            lp_platform="ASWAP"
                                                            :pool_stake_token="coinName(lp1StakeModuleNamespace,lp1StakeModuleName)+':'+coinName(lp2StakeModuleNamespace,lp2StakeModuleName)"
                                                            :pool_reward_token="coinName(fungiv2RewardModuleNamespace,fungiv2RewardModuleName)"
                                                            :pool_apy="rewardAmount"></PoolCardPreview>

                                                        <PoolCardPreview
                                                            v-if="stakeType === 4 && customLPStakeToken === false && rewardType === 1"
                                                            pool_contract="factory-stake-fungiv2"
                                                            :pool_id="poolId" :pool_name="poolName"
                                                            lp_platform="ASWAP"
                                                            :pool_stake_token="lpToken1Type+':'+lpToken2Type"
                                                            :pool_reward_token="coinName(fungiv2RewardModuleNamespace,fungiv2RewardModuleName)"
                                                            :pool_apy="rewardAmount"></PoolCardPreview>

                                                        <PoolCardPreview
                                                            v-if="stakeType === 5 && customLPStakeToken === true && rewardType === 1"
                                                            pool_contract="factory-stake-factorytokens-fungiv2"
                                                            :pool_id="poolId" :pool_name="poolName"
                                                            lp_platform="Stake Factory"
                                                            :sf_poolname="lpStakeTokenPoolName"
                                                            :pool_stake_token="coinName(lp1StakeModuleNamespace,lp1StakeModuleName)+':'+coinName(lp2StakeModuleNamespace,lp2StakeModuleName)+':'+lpStakeTokenPoolName"
                                                            :pool_reward_token="coinName(fungiv2RewardModuleNamespace,fungiv2RewardModuleName)"
                                                            :pool_apy="rewardAmount"></PoolCardPreview>

                                                        <PoolCardPreview
                                                            v-if="stakeType === 5 && customLPStakeToken === false && rewardType === 1"
                                                            pool_contract="factory-stake-factorytokens-fungiv2"
                                                            :pool_id="poolId" :pool_name="poolName"
                                                            lp_platform="Stake Factory"
                                                            :sf_poolname="lpStakeTokenPoolName"
                                                            :pool_stake_token="lpToken1Type+':'+lpToken2Type+':'+lpStakeTokenPoolName"
                                                            :pool_reward_token="coinName(fungiv2RewardModuleNamespace,fungiv2RewardModuleName)"
                                                            :pool_apy="rewardAmount"></PoolCardPreview>

                                                        <PoolCardPreview v-if="stakeType === 6 && rewardType === 1"
                                                                         pool_contract="factory-stake-fungiv2"
                                                                         :pool_id="poolId" :pool_name="poolName"
                                                                         lp_platform="Kaddex"
                                                                         :pool_stake_token="lp1StakeModuleNamespace+lp1StakeModuleName+':'+lp2StakeModuleNamespace+lp2StakeModuleName"
                                                                         :pool_reward_token="fungiv2RewardModuleNamespace+fungiv2RewardModuleName"
                                                                         :pool_apy="rewardAmount"></PoolCardPreview>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                        <!-- Create Pool Button -->

                                                        <div class="columns is-mobile mt-6 mb-1 is-flex-direction-column inset-bg" style="background-color: rgba(0, 0, 0, 0.22) !important;">

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  Pool Creation Fee: FREE!
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  <input
                                                                      v-model="iagree"
                                                                      class="checkbox"
                                                                      type="checkbox"
                                                                  />
                                                                  I agree to the <a href="#">terms and conditions</a>.
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">

                                                            <p v-if="localPoolIdExists === true || localPoolIdExists1 === true || localPoolIdExists2 === true"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: Pool name error.</span>
                                                            </p>

                                                            <p v-if="iagree === false"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: Please review Terms and conditions.</span>
                                                            </p>

                                                            <button
                                                                v-if="localPoolIdExists === false && localPoolIdExists1 === false && localPoolIdExists2 === false && iagree === true"
                                                                @click="clickCreatePool()"
                                                                class="button is-link">Create Pool
                                                            </button>
                                                          </div>

                                                        </div>

                                                      </div>
                                                    </section>


                                                    <!--                                 Button group -->


                                                  </b-step-item>
                                                  <!-- Previous Next Buttons-->
                                                  <template
                                                      #navigation="{previous, next}">
                                                    <div class="container create-buttons">
                                                      <div class="columns">
                                                        <div class="column">
                                                          <b-button v-if="valid === true && localModuleExists === true"
                                                                    outlined
                                                                    type="is-danger"
                                                                    :disabled="previous.disabled"
                                                                    @click.prevent="[previous.action(), doStepBackwards()]">
                                                            Previous
                                                          </b-button>
                                                        </div>
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && lpValid === true && localModuleExists === true"
                                                              outlined
                                                              type="is-success"
                                                              :disabled="next.disabled"
                                                              @click="[next.action(), doStepForward()]">
                                                            Next
                                                          </b-button>
                                                        </div>
                                                      </div>
                                                    </div>

                                                  </template>

                                                </b-steps>

                                              </div>

                                            </ValidationObserver>

                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!-- LIQUIDITY POOLS SECTION -->
                                    <div v-if="activeTab === '2'"
                                         class="column pool-item is-centered is-align-self-center">
                                      <div class="columns">
                                        <div class="column is-align-self-center inset-bg">
                                          <div class="section" style="margin-top: -100px">
                                            <div class="wave__content" style="top: 7px; z-index: 2">
                                              <div class="wave__header">
                                                <div class="wave__menu"></div>
                                              </div>
                                            </div>

                                            <ValidationObserver v-slot="{ valid }">

                                              <div class="box">

                                                <!-- Create Liquidity Pool Form -->
                                                <accordion>
                                                  <accordion-item>
                                                    <template slot="accordion-trigger">
                                                      <h1 class="title blue-bg info-icon" style="font-size: 25px; padding-top: 2rem; padding-bottom: 2rem;">Create a Liquidity Pool</h1>
                                                    </template>
                                                    <template slot="accordion-content">
                                                      <div class="blue-bg-2 has-text-left">
                                                        <span style="font-size: 17px">What are liquidity pools?</span>
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Liquidity pools</span> are specialized pools that allow users to swap
                                                        between 2 pairs of assets.
                                                        <br/><br/>
                                                        <span style="font-size: 17px">What does this tool do?</span>
                                                        <br/><br/>
                                                        Use the Stake Factory Liquidity Pool Creator Tool to create a  <span style="font-weight: bold">custom liquidity pool</span> on the Stake Factory website, allowing  <span style="font-weight: bold">anyone</span> to  <span style="font-weight: bold">swap between the two assets that make up the pool</span>.
                                                        <br/><br/>
                                                        <span style="font-size: 17px">What is special about Stake Factory liquidity pools?</span>
                                                        <br/><br/>
                                                        Stake Factory liquidity pools are unique in the sense that they allow pool creators to set a  <span style="font-weight: bold">custom swap fee</span> for their liquidity pools, allowing them to  <span style="font-weight: bold">offer unique options to their Defi auidience</span>.
                                                        <br/><br/>
                                                        Whenever a liquidity pool is created on stake factory,  <span style="font-weight: bold">a treasury account is also created with that pool which is controlled by the pool creator</span> who makes the pool - This treasury account collects a  <span style="font-weight: bold">portion of the custom Swap Fee</span> which the pool creator can then do  <span style="font-weight: bold">something</span> with.
                                                        <br/><br/>
                                                        Feel free to set and collect custom swap fees and use the fees to  <span style="font-weight: bold">create staking pools</span>, perform  <span style="font-weight: bold">buy backs and burns</span>, or whatever else you can think of to make your Defi audience  <span style="font-weight: bold">happy</span>.
                                                        <br/><br/>
                                                        <span style="font-size: 17px">How does it work?</span>
                                                        <br/><br/>
                                                        Creating a  <span style="font-weight: bold">Liquidity Pool</span> is simple- simply fill in the  <span style="font-weight: bold">module
                                                        namespace</span> and  <span style="font-weight: bold">module name</span> for each token in your Liquidity Pool,
                                                        set a  <span style="font-weight: bold">swap fee</span>, give your pool a  <span style="font-weight: bold">name</span> and click  <span style="font-weight: bold">"Create Pool"</span>.
                                                        <br/><br/>
                                                        <span style="font-weight: bold">After your pool is created</span>, it will appear on the Stake Factory
                                                        website, where it will <span style="font-weight: bold">only be visisble to you until liquidity is added</span>.
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Remember</span> to <span style="font-weight: bold">add liquidity</span> to your pool - Once you <span style="font-weight: bold">add liquidty</span> to your pool the <span style="font-weight: bold">public</span> can begin swapping between the assets in your pool on the Stake Factory website.
                                                        <br/><br/>
                                                        <span style="font-weight: bold">All swaps</span> in your pool will be subject to the <span style="font-weight: bold">Swap Fee</span> you set up
                                                        when
                                                        creating your pool.
                                                        <br/><br/>
                                                        <span style="font-size: 17px">Custom Fees</span>
                                                        <br/><br/>
                                                        Swap Fees are deducted as follows:
                                                        <br/><br/>
                                                        + <span style="font-weight: bold">50% of Swap Fee goes to Liquidity Providers</span>
                                                        <br/>
                                                        + <span style="font-weight: bold">25% of Swap Fee goes to the pool's Treasury Account</span>
                                                        <br/>
                                                        + <span style="font-weight: bold">25% of Swap Fee goes to Stake Factory Treasury</span>
                                                        <br/>
                                                        <br/>
                                                        Half of your fee is distributed to LP
                                                        providers in your pool, and the other half is distributed between the pools Treasury Account and Stake Factory to be distributed as STAKE rewards.
                                                      </div>
                                                    </template>
                                                  </accordion-item>
                                                </accordion>

                                                <b-steps v-model="currentStep">

                                                  <!-- About Section -->
                                                  <b-step-item :step="0" :clickable="false" label="About">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4" style="background-color: #00000038 !important;">
                                                          <span style="font-weight: bold; font-size: 20px;">What is a Liquidity Pool?</span>
                                                        </p>

                                                        <LiquidityPoolCardPreview :total_liquidity="0" volume="0" pool_pair="KDA:STEAK"
                                                                                  fees="0.3" pool_apr="0" pool_name="KDA + STEAK" pair_tokenA_namespace="" pair_tokenB_namespace="free" pair_tokenA_name="coin" pair_tokenB_name="stake-token"
                                                                                  :accountApproved="true" tokenA_ticker="KDA" tokenB_ticker="STEAK" pair_tokenA="coin" pair_tokenB="free.stake-token"></LiquidityPoolCardPreview>


                                                        <p style="color: #4ad1e3"><span style="font-weight: bold">Liquidity pools</span> are specialized pools that allow users to swap
                                                          between 2 tokenized assets.</p>

                                                        <p class="blue-bg font-green mb-4 mt-4" style="padding-left: 6rem; padding-right: 6rem; background-color: #00000038 !important;">
                                                          <span style="font-weight: bold; font-size: 20px;">How do Liquidity Pools work?</span>
                                                          <br/><br/>
                                                          Liquidity Pools let people swap between 2 different tokens - People provide tokens in the pool for others to swap between and are rewarded whenever someone uses the pool to swap between tokens.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">What's so special about Stake Factory Liquidity Pools?</span>
                                                          <br/><br/>
                                                          Stake Factory liquidity pools are not like others - All Stake Factory liquidity pool swap fees can be customized to create a liquidity pool that does more than just swap. All Stake Factory liquidity pools come with their own Treasury, which shares a portion of swap fees in order to provide a function for an audience.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">What's is a Treasury?</span>
                                                          <br/><br/>
                                                          A Treasury is what allows Stake Factory liquidity pools to fuel their very own custom Defi services and solutions beyond just swapping. Use a treasury to collect a portion of swap fees to do things like make Staking Pools for your liquidity providers and create your own farm, or even perform token burns, or buybacks, or all 3! A Treasury is what allows any Defi project to do more for it's audience based entirely off of supply and demand.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">Why would I make a Liquidty Pool at Stake Factory?</span>
                                                          <br/><br/>
                                                          Liquidity Pools let users swap between an asset and can be built at several other places on the Kadena Blockchain, but Stake Factory liquidity pools are designed to let defi projects design with a Treasury concept in mind from the start. A Treasury is what allows a project to do so much more for it's audience with it's liquidity pools beyond simply swapping. Liquidity Pools built at Stake Factory are for Defi Projects that have active plans and a need for a Treasury and wish to do more for their audiences with a Treasury. Use a liquidity pool built at Stake Factory to do things such as burn a portion of swap fees at the end of each month for your audience, or, create Staking Pools for your users - The possibilities are endless as to how your Treasury can behave and benefit your specific Defi audience so please feel free to use other Stake Factory pools and tools to help express how your own Treasury behaves for your users.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">The Stake Factory DAO</span>
                                                          <br/><br/>
                                                          All liquidity pools built at Stake Factory automatically have a role in the Stake Factory DAO - Half of your custom swap fees are shared with the Stake Factory DAO to be distributed as STEAK rewards. Use the Stake Factory DAO to Create and Vote on proposals to determine how your community, new liquidity pool and the STEAK token community is to combine forces.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">How do I create a Liquidty Pool?</span>
                                                          <br/><br/>
                                                          This tool will guide you through a series of liquidity pool design steps and ultimately allow you to Create your liquidity pool on the Stake Factory website. Once your liquidity pool is created you will be taken to your new pool so that you can add tokens to your pool for others to swap between. Your pool will also appear in your "My Pools" section of Stake Factory so that you can access and manage it's Treasury account.
                                                          <br/><br/>
                                                          Set up your Liqudity Pool by deciding which 2 tokens are going to be swapped in the pool, and set up your treasury account and swap fees. Your pool will immediately appear on the Stake Factory website once it's published, however it will only be visible to you until you add tokens to your pool for the public to begin swapping with.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">Can I edit my Liquidity Pool?</span>
                                                          <br/><br/>
                                                          No. Liquidity Pools may not be edited in any way after they have been created.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">What is the cost to make a Liquidity Pool?</span>
                                                          <br/><br/>
                                                          There is a cost in STEAK token to create a Liquidity Pool on Stake Factory.
                                                          <br/><br/>
                                                        </p>




                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <b-switch
                                                            class="mb-5 mt-1"
                                                            v-model="localModuleExists"
                                                            :rounded="true"
                                                            :outlined="true"
                                                            size="is-large"
                                                            type="is-success"
                                                            :left-label='false'
                                                            :passive-type='null'>{{ localModuleExists ? "OK" : "OK" }}
                                                        </b-switch>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Create Pair Section -->
                                                  <b-step-item :step="1" :clickable="false" label="Tokens">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Create Pair Section -->
                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Create Token Pair </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">first token</span>
                                                                in this liquidity pool?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-size: 17px">Enter the details about the first token in your liquidity pool</span>
                                                                <br/><br/>
                                                                What is the first token in this liquidity
                                                                pool?
                                                                <br/><br/>
                                                                Every fungible-v2 token has a module namespace and
                                                                module
                                                                name - Fill in the namespace and module name of the
                                                                first
                                                                token in your liquidity pool below.
                                                                <br/><br/>
                                                                <span style="font-size: 17px">Note</span>
                                                                <br/><br/>
                                                                Some tokens dont have a namespace, such as the default namespace for the KDA token.
                                                                <br/><br/>
                                                                If one of your tokens doesn't have a namespace (coin/KDA),
                                                                simply leave it blank and just fill in the module name only.
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>
                                                        <b-field
                                                            style="margin-top: 20px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="What is the module name of the first token in your liquidity pair?">
                                                              <label class="label pt-1" style="width:50px;">Token 1
                                                                Module:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="liquidityPairTokenAName"
                                                                       @change="verifyTokenExists(liquidityPairTokenANamespace, liquidityPairTokenAName)"
                                                                       @input="verifyTokenExists(liquidityPairTokenANamespace, liquidityPairTokenAName)"
                                                                       class="input select"
                                                                       type="text" placeholder="stake-token">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <b-field
                                                            style="margin-top: 10px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="What is the module namespace of the first token in your liquidity pair?">
                                                              <label class="label pt-1" style="width:50px;">Token 1
                                                                Namespace:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="liquidityPairTokenANamespace"
                                                                       @change="verifyTokenExists(liquidityPairTokenANamespace, liquidityPairTokenAName)"
                                                                       @input="verifyTokenExists(liquidityPairTokenANamespace, liquidityPairTokenAName)"
                                                                       class="input select"
                                                                       type="text" placeholder="free">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">second token</span>
                                                                in this liquidity pool?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-size: 17px">Enter the details about the second token in your liquidity pool</span>
                                                                <br/><br/>
                                                                What is the second token in this liquidity
                                                                pool?
                                                                <br/><br/>
                                                                Every fungible-v2 token has a module namespace and
                                                                module
                                                                name - Fill in the namespace and module name of the
                                                                second
                                                                token in your liquidity pool below.
                                                                <br/><br/>
                                                                <span style="font-size: 17px">Note</span>
                                                                <br/><br/>
                                                                Some tokens dont have a namespace, such as the default namespace for the KDA token.
                                                                <br/><br/>
                                                                If one of your tokens doesn't have a namespace (coin/KDA),
                                                                simply leave it blank and just fill in the module name only.
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>
                                                        <b-field
                                                            style="margin-top: 20px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="What is the module name of the second token in your liquidity pair?">
                                                              <label class="label pt-1" style="width:50px;">Token 2
                                                                Module:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="liquidityPairTokenBName"
                                                                       @change="verifyTokenExists(liquidityPairTokenBNamespace, liquidityPairTokenBName)"
                                                                       @input="verifyTokenExists(liquidityPairTokenBNamespace, liquidityPairTokenBName)"
                                                                       class="input select"
                                                                       type="text" placeholder="stake-token">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <b-field
                                                            style="margin-top: 10px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="What is the module namespace of the second token in your liquidity pair?">
                                                              <label class="label pt-1" style="width:50px;">Token 2
                                                                Namespace:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="liquidityPairTokenBNamespace"
                                                                       @change="verifyTokenExists(liquidityPairTokenBNamespace, liquidityPairTokenBName)"
                                                                       @input="verifyTokenExists(liquidityPairTokenBNamespace, liquidityPairTokenBName)"
                                                                       class="input select"
                                                                       type="text" placeholder="free">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p v-if="localModuleExists === true"
                                                           class="blue-bg font-green mb-4 mt-2">
                                              <span>My Liquidity Pool Pair: <span
                                                  style="font-weight: bold"> {{
                                                  coinName(liquidityPairTokenANamespace, liquidityPairTokenAName)
                                                }}:{{
                                                  coinName(liquidityPairTokenBNamespace, liquidityPairTokenBName)
                                                }} </span></span>
                                                        </p>

                                                        <p v-if="localModuleExists === false" class="blue-bg is-danger">
                                                          <span style="color:#f9868b">Error: One of the tokens you entered doesn't exist.</span>
                                                        </p>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Pool Fee Section -->
                                                  <b-step-item :step="2" :clickable="false" label="Fee">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Pool Fee </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">fee</span>
                                                                for this liquidity pool?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-size: 17px">What is the Swap Fee for this pool?</span>
                                                                <br/><br/>
                                                                Choose a <span style="font-weight: bold">swap fee</span>
                                                                for
                                                                this liquidity pool.
                                                                <br/>
                                                                <br/>
                                                                <span style="font-weight: bold">Swap Fees</span> are charged whenever a swap occurs from this pool.
                                                                <br/><br/>
                                                                Liquidity pools created at Stake Factory also create their own <span style="font-weight: bold">Treasury Account</span> which receives a percentage of your swap fee - <span style="font-weight: bold">25%</span> of your <span style="font-weight: bold">Swap Fee</span> goes to your pool's <span style="font-weight: bold">Treasury Account</span>.
                                                                <br/><br/>
                                                                This <span style="font-weight: bold">Treasury Account</span> is automatically created using the<span style="font-weight: bold"> coin account (KDA) guard you are using to create this pool with right now</span>.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">Access</span> your liquidity pools <span style="font-weight: bold">Treasury Account</span> from your <span style="font-weight: bold">liquidity pool's page</span> using the account you used to create this liquidity pool with in order to perform actions with this pool's treasury account - Feel free to create Staking Pools with the fees generated from your liquidity pool at Stake Factory at any time.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">Swap Fee Break Down:</span>
                                                                <br/><br/>
                                                                + <span style="font-weight: bold">50%</span> of Swap Fee Goes to LP Holders
                                                                <br/>
                                                                + <span style="font-weight: bold">25%</span> of Swap Fee goes to your Treasury Account
                                                                <br/>
                                                                + <span style="font-weight: bold">25%</span> of Swap Fee goes to the Stake Factory Treasury
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <b-field
                                                            style="margin-top: 10px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="What percent of each swap is the fee?">
                                                              <label class="label pt-2" style="width:100px;">Swap Fee
                                                                %:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <b-field type="is-info">
                                                                  <ValidationProvider
                                                                      :rules="{required: true, greaterthan: 0, lessthanequalto: 99, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                      vid="liquidity_pool_fee" name="Swap Fee"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="liquidityPoolFee"
                                                                           @input="[checkValidInput(valid), determineSwapFee(liquidityPoolFee)]"
                                                                           @change="determineSwapFee(liquidityPoolFee)"
                                                                           class="input select"
                                                                           type="number" step="0.01"
                                                                           placeholder="0.03">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span v-if="!errors[0]"
                                                                          class="error validation-msg-green is-info">Percent of each swap that is deducted as a fee</span>
                                                                  </ValidationProvider>
                                                                </b-field>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                              <span v-if="customFungiv2Reward === false">My Pool's Total Swap Fee: <span
                                                  style="font-weight: bold"> {{ liquidityPoolFee }}%  </span>
                                              <br/>
                                              <span style="font-weight: bold">{{ (liquidityPoolFee / 2) }}%</span> of each Swap Goes to <span style="font-weight: bold">LP Providers</span>
                                              <br/>
                                              <span
                                                  style="font-weight: bold">{{ (liquidityPoolFee / 4) }}%</span> of each Swap Goes to <span style="font-weight: bold">Your Pool's Treasury Account</span>
                                                          <br/>
                                                          <span
                                                              style="font-weight: bold">{{ (liquidityPoolFee / 4) }}%</span> of each Swap Goes to <span style="font-weight: bold">Stake Factory's Treasury</span></span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Pool Name Section -->
                                                  <b-step-item :step="3" :clickable="false" label="Name">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Pool Name </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">name</span>
                                                                of this liquidity pool?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Give your liquidity pool a <span
                                                                  style="font-weight: bold">unique name</span>.
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <b-field
                                                            style="margin-top: 10px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="The Pool's Name shown to Stakers. Example: Test Pool">
                                                              <label class="label pt-1" style="width:50px;">Pool
                                                                Name:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="liquidityPoolName"
                                                                       @change="verifyLPNameExists(liquidityPoolName)"
                                                                       @input="verifyLPNameExists(liquidityPoolName)"
                                                                       class="input select"
                                                                       type="text" placeholder="10">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Pool Review Section -->
                                                  <b-step-item :step="4" :clickable="false" label="Review">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Review </span>
                                                        </p>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <LiquidityPoolCardPreview :total_liquidity="0" volume="0" :pool_pair="coinName(liquidityPairTokenANamespace, liquidityPairTokenAName)+':'+coinName(liquidityPairTokenBNamespace, liquidityPairTokenBName)"
                                                                           :fees="liquidityPoolFee" pool_apr="0" :pool_name="liquidityPoolName" :pair_tokenA_namespace="liquidityPairTokenANamespace" :pair_tokenB_namespace="liquidityPairTokenBNamespace" :pair_tokenA_name="liquidityPairTokenAName" :pair_tokenB_name="liquidityPairTokenBName"
                                                                           :accountApproved="true" :tokenA_ticker="liquidityPairTokenAName" :tokenB_ticker="liquidityPairTokenBName" :pair_tokenA="coinName(liquidityPairTokenANamespace, liquidityPairTokenAName)" :pair_tokenB="coinName(liquidityPairTokenBNamespace, liquidityPairTokenBName)"></LiquidityPoolCardPreview>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <!-- Create Pool Button -->

                                                        <div class="columns is-mobile mt-6 mb-1 is-flex-direction-column inset-bg" style="background-color: rgba(0, 0, 0, 0.22) !important;">

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  Pool Creation Fee: 0.01 🥩
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  <input
                                                                      v-model="iagree"
                                                                      class="checkbox"
                                                                      type="checkbox"
                                                                  />
                                                                  I agree to the <a href="#">terms and conditions</a>.
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">

                                                            <p v-if="localPoolIdExists === true"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: Pool name error.</span>
                                                            </p>

                                                            <p v-if="iagree === false"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: Please review Terms and conditions.</span>
                                                            </p>

                                                            <button
                                                                v-if="localPoolIdExists === false"
                                                                @click="clickCreateLiquidityPool()"
                                                                class="button is-link">Create Pool
                                                            </button>
                                                          </div>

                                                        </div>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Previous Next Buttons-->
                                                  <template
                                                      #navigation="{previous, next}">
                                                    <div class="container create-buttons">
                                                      <div class="columns">
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && lpValid === true && localModuleExists === true"
                                                              outlined
                                                              type="is-danger"
                                                              :disabled="previous.disabled"
                                                              @click.prevent="[previous.action(), doStepBackwards()]">
                                                            Previous
                                                          </b-button>
                                                        </div>
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && lpValid === true && localModuleExists === true"
                                                              outlined
                                                              type="is-success"
                                                              :disabled="next.disabled"
                                                              @click="[next.action(), doStepForward()]">
                                                            Next
                                                          </b-button>
                                                        </div>
                                                      </div>
                                                    </div>

                                                  </template>

                                                </b-steps>

                                              </div>

                                            </ValidationObserver>

                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!-- CROWDFUNDING POOLS SECTION -->
                                    <div v-if="activeTab === '4'"
                                         class="column pool-item is-centered is-align-self-center">
                                      <div class="columns">
                                        <div class="column is-align-self-center inset-bg">
                                          <div class="section" style="margin-top: -100px">
                                            <div class="wave__content" style="top: 7px; z-index: 2">
                                              <div class="wave__header">
                                                <div class="wave__menu"></div>
                                              </div>
                                            </div>

                                            <ValidationObserver v-slot="{ valid }">

                                              <div class="box">

                                                <!-- Create Crowdfunding Pool Form -->
                                                <accordion>
                                                  <accordion-item>
                                                    <template slot="accordion-trigger">
                                                      <h1 class="title blue-bg info-icon" style="font-size: 25px; padding-top: 2rem; padding-bottom: 2rem;">Create a Crowdfunding Pool</h1>
                                                    </template>
                                                    <template slot="accordion-content">
                                                      <div class="blue-bg-2 has-text-left">
                                                        <span style="font-weight: bold">Crowdfunding pools</span> are special pools designed to <span style="font-weight: bold">raise funds</span> and
                                                        <span style="font-weight: bold">safely create a token pair and liquidity pool</span> out of the <span style="font-weight: bold">funds
                                                        raised</span>.
                                                        <br/><br/>
                                                        <span style="font-weight: bold; font-size: 17px;">Example:</span>
                                                        <br/><br/>
                                                        Alice has created a new token called ALICE, and she wants to put her token on a DEX so people can swap between KDA and her asset.
                                                        <br/>
                                                        <span style="font-weight: bold">Problem:</span> Alice wants to create a liquidity pool <span style="font-weight: bold">KDA+ALICE</span> but she <span style="font-weight: bold">doesn't have any KDA</span>.
                                                        <br/>
                                                        <span style="font-weight: bold">Solution:</span> Alice creates a crowdfunding pool and raises the KDA to form the other half of her liquidity pool.
                                                        <br/><br/>
                                                        <span style="font-weight: bold; font-size: 17px">How does it work?</span>
                                                        <br/><br/>
                                                        <span style="font-weight: bold; font-size: 17px">1) Create your Crowdfunding Pool</span>
                                                        <br/><br/>
                                                        Use this tool to fill out the details of your crowdfunding pool and how it will operate and raise funds on the blockchain.
                                                        <br/>
                                                        This tool ultimately will create a <span style="font-weight: bold">liquidity fundraising pool</span> on the Stake Factory website which allows
                                                        the public to <span style="font-weight: bold">raise funds</span> and form a <span style="font-weight: bold">liquidity pool</span> out of <span style="font-weight: bold">two
                                                        different fungible assets with the funds raised</span>.
                                                        <br/>
                                                        + <span style="font-weight: bold">Fill out</span> the crowdfunding creation tool with the details of your campaign.
                                                        + Once you finish using the tool, <span style="font-weight: bold">click the 'Create Pool' button at the end</span> - This will transfer your crowdfunding tokens to your pool and create your pool in one step.
                                                        + After you click 'Create Pool' <span style="font-weight: bold">please remain on the page</span> while Stake Factory confirms your transaction.
                                                        + Your pool will <span style="font-weight: bold">automatically appear on the Stake Factory website</span> and anyone can begin participating and crowdfunding <span style="font-weight: bold">as soon as your transaction is successfully completed</span>.
                                                        <br/><br/>
                                                        <span style="font-weight: bold; font-size: 17px">3) Market your crowdfunding campaign</span>
                                                        <br/><br/>
                                                        Tell the public about your <span style="font-weight: bold">crowdfunding campaign</span> and give them
                                                        the <span style="font-weight: bold">link</span> to your pool so they can <span style="font-weight: bold">pledge</span> to <span style="font-weight: bold">purchase tokens</span>.
                                                        <br/>
                                                        <span style="font-weight: bold">Crowdfunding pools</span> also have <span style="font-weight: bold">optional Whitelisting features</span> to
                                                        aid with marketing which allow the pools to presale tokens to a
                                                        list of whitelisted members before the public.
                                                        <br/><br/>
                                                        <span style="font-weight: bold; font-size: 17px">4) Generate liquidity pool</span>
                                                        <br/><br/>
                                                        Once your crowdfunding campaign has been <span style="font-weight: bold">successfully funded</span> and
                                                        the <span style="font-weight: bold">crowdfunding campaign timer has ended</span>, anyone can <span style="font-weight: bold">generate a
                                                        liquidity pool</span> out of the raised funds by clicking the <span style="font-weight: bold">"Generate
                                                        Liquidity Pool"</span> button <span style="font-weight: bold">that becomes visible when any
                                                        crowdfunding
                                                        pool is fully funded</span>.
                                                        <br/><br/>
                                                        <span style="font-weight: bold; font-size: 17px">5) Important notes</span>
                                                        <br/><br/>
                                                        Stake Factory Crowdfunding Pools have <span style="font-weight: bold">several unique options</span>
                                                        giving pool creators fine control over how their campaign
                                                        unfolds - Be sure to <span style="font-weight: bold">hover</span> over any fields & <span style="font-weight: bold">click</span> on the titles of each section to learn more about that section of the tool and it's options.
                                                        <br/><br/>
                                                        All crowdfunding campaigns can run a maximum of <span style="font-weight: bold">1 month</span>.
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Remember:</span> All Stake Factory liquidity pools create a <span style="font-weight: bold">treasury account</span> which <span style="font-weight: bold">receives 1/4 of your custom swap fee</span> - Access this treasury account from the pool creator section of your liquidity pool's page once it is generated.
                                                        <br/><br/>
                                                        If for any reason your crowdfunding campaign <span style="font-weight: bold">fails</span>, you can always <span style="font-weight: bold">withdraw your tokens and make another campaign</span> - Your campaign <span style="font-weight: bold">cannot be cancelled</span> and your tokens <span style="font-weight: bold">cannot be withdrawn until your campaign expires/fails</span>.
                                                        <br/><br/>
                                                        <span style="font-weight: bold">All crowdfunders may withdraw their pledge at any time up until your crowdfunding pool becomes funded</span>- Once your campaign is funded, the public can <span style="font-weight: bold">no longer withdraw their pledges</span> and may <span style="font-weight: bold">begin claiming the tokens they purchased</span> once this crowdfunding pool forms a <span style="font-weight: bold">liquidity pool</span> out of the raised funds.
                                                      </div>
                                                    </template>
                                                  </accordion-item>
                                                </accordion>

                                                <b-steps v-model="currentStep">

                                                  <!-- About Section -->
                                                  <b-step-item :step="0" :clickable="false" label="About">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4" style="background-color: #00000038 !important;">
                                                          <span style="font-weight: bold; font-size: 20px;">What is a Crowdfunding Pool?</span>
                                                        </p>

                                                        <section>
                                                          <CrowdFundPoolCardPreview
                                                              pool_contract="free.factory-crowdfund" pool_id="my-crowdfund" :pool_insurance_end_time="crowdFundInsuranceTime2" :pool_max_buy="crowdFundMaxBuyQuantity"
                                                              pool_balance="0" :pool_description="crowdFundPoolDescription" :pool_price="crowdFundSellTokenPrice" :pool_amount_to_dex="crowdFundPairQuantity" :pool_amount_to_sell="crowdFundSellTokenQuantity"
                                                              :pool_end_time="crowdFundCampaignTime2" :pool_start_time="currentDate" :wallet_pledge_balance="0" :pool_amount_sold="0"
                                                              :accountApproved="true" :pool_pledge_token="coinName(crowdFundPledgeTokenModuleNamespace, crowdFundPledgeTokenModuleName)" :pool_sell_token="coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)" :pledge_balance="0" :pledge_rewards="0"
                                                              :stamp_pool_start_time="currentDate" :stamp_pool_end_time="crowdFundCampaignTime2" :pool_active="true" :pool_funded="false" :can_generate="false"
                                                              :pool_can_claim="false" :funding_failed="false" :pool_hard_cap="crowdFundHardCap" :pool_maxxed="false" :pool_wl_end_time_full="crowdFundWhitelistTime2" :pool_end_time_full="crowdFundCampaignTime2"
                                                              :pool_wl_active="true" :pool_wl_end_time="crowdFundWhitelistTime2" :pool_wl_max_buy="crowdFundWhitelistMaxCanBuy" :isWhitelisted="true"
                                                              :is_pool_owner="true" :pool_tokens_locked="0" :pool_name="crowdFundPoolName"></CrowdFundPoolCardPreview>
                                                        </section>


                                                        <p style="color: #4ad1e3"><span style="font-weight: bold">Crowdfunding pools</span> are complex pools that raise funds, pre-sell tokens, and generate a swappable token pair and liquidity pool out of those funds.</p>

                                                        <p class="blue-bg font-green mb-4 mt-4" style="padding-left: 6rem; padding-right: 6rem; background-color: #00000038 !important;">
                                                          <span style="font-weight: bold; font-size: 20px;">How do Crowdfunding Pools work?</span>
                                                          <br/><br/>
                                                          One of the hurdles for any new Defi project is <span style="font-weight: bold">raising funds for liquidity</span> in an equal-access way so that the public may begin <span style="font-weight: bold">swapping</span> between their token and other tokens on the blockchain- <span style="font-weight: bold">Crowdfunding Pools</span> help token creators solve this problem all with one pool by running a <span style="font-weight: bold">Crowdfunding Campaign</span> that <span style="font-weight: bold">raises the funds needed for liquidity</span> and <span style="font-weight: bold">launches a liquidity pool</span> after liquidity funds are raised, all with <span style="font-weight: bold">one pool</span>.
                                                          <br/><br/>
                                                          <span style="font-weight: bold">Crowdfunding Pools</span> first <span style="font-weight: bold">pre-sell your tokens to the public in an equal access manner</span> over the course of time and according to the rules you implement when you design your Crowdfunding Pool. Once the pool raises the funds needed for your liquidity strategy, all the funds get converted into a swappable liquidity pool on the Stake Factory website, where anyone can begin swapping between your token and another token.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">Why would I make a Vesting Pool?</span>
                                                          <br/><br/>
                                                          <span style="font-weight: bold">Crowdfunding Pools</span> are a great way for any project to <span style="font-weight: bold">raise funds</span> in order to form a <span style="font-weight: bold">swappable token pair</span> and <span style="font-weight: bold">Liquidity Pool</span> so that a Defi audience can begin <span style="font-weight: bold">swapping</span> on Stake Factory.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">What can I do with a Vesting Pool?</span>
                                                          <br/><br/>
                                                          Like launchpads, crowdfunding pools are a hands-on approach to providing a fair launch and getting a token on a dex so the public can begin creating a market out of a Defi product. Use a crowdfunding pool to launch your very own Defi solution on the Stake Factory website.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">How do I create a Vesting Pool?</span>
                                                          <br/><br/>
                                                          This tool will <span style="font-weight: bold">guide you through a series of 10 steps</span> that help you design and publish your own <span style="font-weight: bold">Crowdfunding Pool</span>- Once you publish your crowdfunding pool your campaign will <span style="font-weight: bold">immediately appear on the Stake Factory website</span> where the public can begin participating and funding your campaign until it's timer runs out.
                                                          <br/><br/>
                                                          Crowdfunding pools are complex in that they accomplish several goals all at once- This tool works best by reading all the important information sections marked throughout the different sections of the tool by <b-icon pack="fas" icon="info-circle" size="is-small" style="position: relative; top:2px; color: #4ae39e !important; "></b-icon>️ icons.
                                                          <br/><br/>
                                                          Set up your Crowdfunding Pool according to your tokenomics and plan to market your crowdfunding pool to the public, as crowdfunding pools can only run a maximum of 30 days. Plan how many tokens will go to your dex, how many will be pre-sold to the public to raise funds, and exactly how much funds you will raise - All of these factors determine the actual price of your token once it turns into a DEX. Follow each step of the creation tool and make sure to examine the previews of your pool and review your pool before launching - Crowdfunding campaigns run until they expire.
                                                          <br/><br/>
                                                          You will be depositing the tokens your pool pre-sells to the public. Note, crowdfunding pools require that pledgers stake their tokens and pledge to purchase allocations of tokens from you after your pool succeeds- It is only after your crowdfunding pool becomes fully funded that it actually sells your tokens to pledgers in the pool. This means, pledgers are free to withdraw their pledged tokens at anytime before the crowdfunding pool becomes funded. Your pool, and the exact amount of funds you raise from investors must become fully funded by the time your campaign expires for it to successfully create your DEX. If your pool is not funded then your crowdfunding pool fails, and everyone who pledged is free to withdraw their staked tokens since your campaign did not reach the hard cap limits you set for your pledgers.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">How do funders receive tokens from Crowdfunding Pools?</span>
                                                          <br/><br/>
                                                          Crowdfunding Pools have several states, and users are free to withdraw their original pledged funds out of a crowdfunding pool and unpledge at anytime before the pool becomes fully funded.
                                                          <br/><br/>
                                                          Once a crowdfunding pool becomes funded and forms a liquidity pair, all the tokens funders pledged to buy during your campaign presale can be withdrawn out of the Crowdfunding Pool and immediately used for swapping at your new liquidity pool.
                                                          <br/><br/>
                                                          All crowdfunding pools run on a timer, and, funders should take note when a crowdfunding pool's campaign ends so that they can log in and withdraw the tokens they purchased and begin swapping.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">Can I edit my Crowdfunding Pool?</span>
                                                          <br/><br/>
                                                          No. Crowdfunding pools cannot be edited after they are created because they contain staked funds. All 100% of funds also go to form your liquidity as well. If your crowdfunding pool fails, you can withdraw your tokens back out of your failed campaign and make another.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">What is the cost to make a Voting Poll?</span>
                                                          <br/><br/>
                                                          There is a fee in STEAK token to create your pool which will be displayed at the end of the creation process.
                                                          <br/><br/>
                                                        </p>




                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <b-switch
                                                            class="mb-5 mt-1"
                                                            v-model="localModuleExists"
                                                            :rounded="true"
                                                            :outlined="true"
                                                            size="is-large"
                                                            type="is-success"
                                                            :left-label='false'
                                                            :passive-type='null'>{{ localModuleExists ? "OK" : "OK" }}
                                                        </b-switch>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Sell Token Section -->
                                                  <b-step-item :step="1" :clickable="false" label="Sell">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Sell Token Section -->
                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Sell Token  </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">token</span>
                                                                your pool will pre-sell to funders?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-weight: bold; font-size: 17px;">What token are you raising funds for?</span>
                                                                <br/><br/>
                                                                <span
                                                                    style="font-weight: bold">Crowdfunding pools</span>
                                                                sell tokens to crowdfunders in order to raise funds
                                                                which
                                                                ultimately form a <span style="font-weight: bold">token pair & liquidity pool</span>
                                                                at Stake Factory out of the funds raised and the token
                                                                being funded.
                                                                <br/><br/>
                                                                What <span style="font-weight: bold">token</span> is
                                                                your
                                                                pool going to offer to funders?
                                                                <br/><br/>
                                                                Every fungible-v2 token has a <span
                                                                  style="font-weight: bold">module namespace and module
                                                              name</span> - Fill in the <span style="font-weight: bold">namespace and module name</span>
                                                                of the token your pool will raise funds for.
                                                                <br/><br/>
                                                                Your crowdfunding pool will <span
                                                                  style="font-weight: bold">sell</span> this token to
                                                                crowdfunders according to the settings you set up in
                                                                this
                                                                form and until your campaign is fully funded or runs out
                                                                of time.
                                                                <br/><br/>
                                                                Once your campaign is <span style="font-weight: bold">fully funded</span> and has sold all of your tokens,
                                                                a liquidity pool will be formed out of the raised funds,
                                                                allowing anyone to <span
                                                                  style="font-weight: bold">swap</span> between the
                                                                assets.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">After your liquidity pool is formed</span>
                                                                from the funds the crowdfunding pool raises, pledgers
                                                                who
                                                                funded your campaign are allowed to <span
                                                                  style="font-weight: bold">withdraw</span> the tokens
                                                                they purchased from the crowdfunding campaign and
                                                                participate in the market they funded.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>
                                                        <ValidationObserver v-if="currentStep === 1">
                                                          <b-field
                                                              style="margin-top: 20px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What is the contract name / module name of your token?">
                                                                <label class="label pt-1" style="width:50px;">Token
                                                                  Module:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <ValidationProvider
                                                                      :rules="{required: true}"
                                                                      vid="crowdfund_module_name"
                                                                      name="Crowdfund Module Name"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="crowdFundSellTokenModuleName"
                                                                           @change="verifyTokenExists(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)"
                                                                           @input="verifyTokenExists(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)"
                                                                           class="input select"
                                                                           type="text" placeholder="my-token">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span
                                                                        class="error is-danger validation-msg is-hidden">{{
                                                                        valid[0]
                                                                      }}</span>
                                                                  </ValidationProvider>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field
                                                              style="margin-top: 10px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What is the contract namespace / module namespace of your token?">
                                                                <label class="label pt-1" style="width:50px;">Token
                                                                  Namespace:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">

                                                                  <ValidationProvider
                                                                      :rules="{required: true}"
                                                                      vid="crowdfund_module_namespace"
                                                                      name="Crowdfund Module Namespace"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="crowdFundSellTokenModuleNamespace"
                                                                           @change="verifyTokenExists(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)"
                                                                           @input="verifyTokenExists(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)"
                                                                           class="input select"
                                                                           type="text" placeholder="free">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span class="error is-danger validation-msg">{{
                                                                        valid[0]
                                                                      }}</span>
                                                                  </ValidationProvider>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                        </ValidationObserver>


                                                        <p class="blue-bg font-green mb-4 mt-5">
                                              <span v-if="customFungiv2Reward === false">My Crowdfunding will sell <span
                                                  style="font-weight: bold"> {{
                                                  coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)
                                                }} </span> tokens to funders.</span>
                                                        </p>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Pledge Token Section -->
                                                  <b-step-item :step="2" :clickable="false" label="Pledge">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Create Pair Section -->
                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Pledge Token  </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">token</span>
                                                                funders must pledge and pay with to purchase your token?
                                                              </h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-weight: bold; font-size: 17px;">What token will crowdfunders pay with?</span>
                                                                <br/><br/>
                                                                <span
                                                                    style="font-weight: bold">Crowdfunding pools</span>
                                                                allow crowdfunders to <span style="font-weight: bold">pledge tokens</span>
                                                                which will fund your campaign.
                                                                <br/><br/>
                                                                Once your crowdfunding campaign is started, this is the
                                                                token crowdfunders will use to pledge and purchase the
                                                                token your pool is offering to the crowd (on the previous page).
                                                                <br/><br/>
                                                                What token will crowdfunders <span
                                                                  style="font-weight: bold">pledge & pay</span> with to
                                                                purchase the token your selling in this Crowdfunding Pool?
                                                                <br/><br/>
                                                                <span style="font-weight: bold; font-size: 17px;">Enter the tokens module namespace and name</span>
                                                                <br/><br/>
                                                                Every fungible-v2 token has a <span
                                                                  style="font-weight: bold">module namespace</span> and
                                                                <span style="font-weight: bold">module
                                                              name</span> - Fill in the <span style="font-weight: bold">namespace and module name</span>
                                                                of the <span style="font-weight: bold">token funders must pledge with</span>
                                                                in this pool ( Typically an asset like KDA or a stable
                                                                coin ).
                                                                <br/><br/>
                                                                <span style="font-weight: bold; font-size: 17px;">Remember:</span>
                                                                <br/><br/>
                                                                This token is <span style="font-weight: bold">ultimately the token that makes up the other half of your liquidity pair</span>
                                                                in the Liquidity Pool formed <span
                                                                  style="font-weight: bold">after</span> your
                                                                Crowdfunding
                                                                Pool is funded - IE a DEX will be created where anyone can swap between the token your selling, and the token pledgers pledge with.
                                                                <br/><br/>
                                                                Your Crowdfunding pool will <span
                                                                  style="font-weight: bold">only</span> allow funders to
                                                                purchase your token with the token you set below.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>
                                                        <ValidationObserver>
                                                          <b-field
                                                              style="margin-top: 20px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What is the contract name / module name of your token?">
                                                                <label class="label pt-1" style="width:50px;">Token
                                                                  Module:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">


                                                                  <ValidationProvider
                                                                      :rules="{required: true}"
                                                                      vid="crowdfund_pledge_module_name"
                                                                      name="Crowdfund Pledge Token Module Name"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="crowdFundPledgeTokenModuleName"
                                                                           @change="verifyTokenExists(crowdFundPledgeTokenModuleNamespace, crowdFundPledgeTokenModuleName)"
                                                                           @input="verifyTokenExists(crowdFundPledgeTokenModuleNamespace, crowdFundPledgeTokenModuleName)"
                                                                           class="input select"
                                                                           type="text" placeholder="coin">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span
                                                                        class="error is-danger validation-msg is-hidden">{{
                                                                        valid[0]
                                                                      }}</span>
                                                                  </ValidationProvider>


                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>
                                                        </ValidationObserver>

                                                        <ValidationObserver>
                                                          <b-field
                                                              style="margin-top: 10px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What is the contract namespace / module namespace of your token?">
                                                                <label class="label pt-1" style="width:50px;">Token
                                                                  Namespace:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <input v-model="crowdFundPledgeTokenModuleNamespace"
                                                                         @change="verifyTokenExists(crowdFundPledgeTokenModuleNamespace, crowdFundPledgeTokenModuleName)"
                                                                         @input="verifyTokenExists(crowdFundPledgeTokenModuleNamespace, crowdFundPledgeTokenModuleName)"
                                                                         class="input select"
                                                                         type="text" placeholder="">
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>
                                                        </ValidationObserver>


                                                        <p class="blue-bg font-green mb-4 mt-5">
                                              <span v-if="customFungiv2Reward === false">Funders will pledge and purchase {{
                                                  coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)
                                                }} with <span
                                                    style="font-weight: bold"> {{
                                                    coinName(crowdFundPledgeTokenModuleNamespace, crowdFundPledgeTokenModuleName)
                                                  }} </span>.</span>
                                                        </p>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Sell Quantity Section -->
                                                  <b-step-item :step="3" :clickable="false" label="Quantity">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Quantity + Price </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">price</span>
                                                                crowdfunders will pay for {{
                                                                  coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)
                                                                }}?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-weight: bold; font-size: 17px;">What will people pay to purchase your token?</span>
                                                                <br/><br/>
                                                                Choose the <span style="font-weight: bold">price</span>
                                                                funders must pledge and pay if your pool is funded.
                                                                <br/>
                                                                <br/>
                                                                Your crowdfunding pool will <span style="font-weight: bold">sell your token to funders
                                                                at
                                                                this price</span> which can only be paid for in the <span style="font-weight: bold">Pledge
                                                                Token
                                                                type</span> you entered in the Pledge page of this tool.
                                                                <br/><br/>
                                                                Your campaign becomes <span style="font-weight: bold">fully funded</span> once it sells <span style="font-weight: bold">100%</span> of your tokens at this <span style="font-weight: bold">price</span>.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>
                                                        <ValidationObserver>
                                                          <b-field
                                                              style="margin-top: 20px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What is the price of your token?">
                                                                <label class="label pt-3"
                                                                       style="width:100px;">Price:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthan: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="sell_token_price" name="Sell Token Price"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="crowdFundSellTokenPrice"
                                                                             @input="checkValidInput(valid)"
                                                                             class="input select"
                                                                             type="number" step="0.0001"
                                                                             placeholder="4.0">
                                                                      <span class="error is-danger validation-msg">{{
                                                                          errors[0]
                                                                        }}</span>
                                                                      <span v-if="!errors[0]"
                                                                            class="error validation-msg-green is-info">Price funders must pledge and pay to purchase your Sell Token</span>
                                                                    </ValidationProvider>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>
                                                        </ValidationObserver>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">How <span
                                                                  style="font-weight: bold">many</span> {{
                                                                  coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)
                                                                }} tokens will this pool sell to funders?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-weight: bold; font-size: 17px;">How many tokens total will you only sell to crowdfunders?</span>
                                                                <br/><br/>
                                                                How <span style="font-weight: bold">many</span> tokens
                                                                will this pool be selling to funders?
                                                                <br/>
                                                                <br/>
                                                                This is the <span style="font-weight: bold">amount of tokens that must be sold</span>
                                                                by your crowdfunding pool in order for your crowdfund to
                                                                become <span
                                                                  style="font-weight: bold">fully funded</span>.
                                                                <br/><br/>
                                                                Once funders have pledged to purchase this <span
                                                                  style="font-weight: bold">full amount of tokens</span>,
                                                                your pool will then become<span
                                                                  style="font-weight: bold"> fully funded</span>.
                                                                <br/><br/>
                                                                When your pool is <span style="font-weight: bold">fully funded</span>,
                                                                pledgers can <span style="font-weight: bold">no longer withdraw their pledged tokens</span>,
                                                                and your liquidity pool <span style="font-weight: bold">can then be generated</span>
                                                                out of the raised funds.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">After your liquidity pool is generated</span>
                                                                out of the crowdfunded pledges, <span
                                                                  style="font-weight: bold">pledgers may withdraw the tokens they purchased</span>
                                                                from the crowdfunding campaign at anytime.
                                                                <br/><br/>
                                                                <span style="font-weight: bold; font-size: 17px;">This amount should not include the number of tokens you will send to your DEX</span>
                                                                <br/><br/>
                                                                This is ONLY the number of your tokens you will be selling to crowdfunders to be purchased.
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <ValidationObserver>
                                                          <b-field
                                                              style="margin-top: 20px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="How many tokens will this pool sell to pledgers?">
                                                                <label class="label pt-3" style="width:100px;">Sell
                                                                  Quantity:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthan: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="sell_token_quantity"
                                                                        name="Sell Token Quantity"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="crowdFundSellTokenQuantity"

                                                                             @change="verifyTokenBalance(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)"
                                                                             @input="[checkValidInput(valid),verifyTokenBalance(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)]"
                                                                             class="input select"
                                                                             type="number" step="0.0001"
                                                                             placeholder="0.0">
                                                                      <span class="error is-danger validation-msg">{{
                                                                          errors[0]
                                                                        }}</span>
                                                                      <span v-if="!errors[0]"
                                                                            class="error validation-msg-green is-info">Quantity of {{
                                                                          coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)
                                                                        }} tokens being offered to funders to purchase</span>
                                                                    </ValidationProvider>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>
                                                        </ValidationObserver>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">maximum amount of {{
                                                                  coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)
                                                                }} </span> someone is allowed to purchase?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-weight: bold; font-size: 17px;">Whats the max number of tokens someone can purchase</span>
                                                                <br/><br/>
                                                                How <span style="font-weight: bold">many</span> tokens is an account allowed to purchase from this pool?
                                                                <br/><br/>
                                                                Enter a maxmimum amount of tokens an account can ever purchase - They will not be allowed to purchase anymore.
                                                                <br/><br/>
                                                                This can help prevent a single person from ever purchasing too many tokens.
                                                                <br/><br/>
                                                                This amount can be temporarily over-ridden for whitelisted members while this pool is offering tokens to whitelisters.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <ValidationObserver>
                                                          <b-field
                                                              style="margin-top: 20px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="How many tokens will this pool sell to pledgers?">
                                                                <label class="label pt-3" style="width:100px;">Max Tokens
                                                                  Per Account:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthan: 0, lessthanequalto: Number(crowdFundSellTokenQuantity), regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="max_buy_quantity"
                                                                        name="Max Tokens Per Account"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="crowdFundMaxBuyQuantity"

                                                                             @change="verifyTokenBalance(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)"
                                                                             @input="[checkValidInput(valid),verifyTokenBalance(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)]"
                                                                             class="input select"
                                                                             type="number" step="0.0001"
                                                                             placeholder="0.0">
                                                                      <span class="error is-danger validation-msg">{{
                                                                          errors[0]
                                                                        }}</span>
                                                                      <span v-if="!errors[0]"
                                                                            class="error validation-msg-green is-info">Maximum number of {{
                                                                          coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)
                                                                        }} tokens a single account can purchase</span>
                                                                    </ValidationProvider>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>
                                                        </ValidationObserver>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                        <p v-if="localModuleExists === true"
                                                           class="blue-bg font-green mb-4">
                                                        <span>Pledgers will pay <span style="font-weight: bold"> {{
                                                            crowdFundSellTokenPrice
                                                          }} </span> <span style="font-weight: bold">{{
                                                            coinName(crowdFundPledgeTokenModuleNamespace, crowdFundPledgeTokenModuleName)
                                                          }}</span> per <span style="font-weight: bold">{{
                                                            coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)
                                                          }}.</span></span>
                                                          <br/>
                                                          <span>This pool will offer <span
                                                              style="font-weight: bold"> {{
                                                              crowdFundSellTokenQuantity
                                                            }} {{
                                                              coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)
                                                            }}</span> to funders for <span style="font-weight: bold"> {{
                                                              crowdFundSellTokenPrice
                                                            }} </span> <span style="font-weight: bold">{{
                                                              coinName(crowdFundPledgeTokenModuleNamespace, crowdFundPledgeTokenModuleName)
                                                            }}</span> each</span>.
                                                          <br/>
                                                          <span>Each crowdfunder can purchase a maximum of <span
                                                              style="font-weight: bold">{{crowdFundMaxBuyQuantity}} {{ coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName) }}</span></span>.
                                                          <br/>
                                                          <span>This pool becomes <span style="font-weight: bold">fully funded</span> once it raises <span
                                                              style="font-weight: bold">{{
                                                              crowdFundSellTokenQuantity * crowdFundSellTokenPrice
                                                            }} {{ coinName(crowdFundPledgeTokenModuleNamespace, crowdFundPledgeTokenModuleName) }}</span></span>.
                                                          <br/>

                                                        </p>

                                                        <p v-if="localModuleExists === false"
                                                           class="blue-bg font-green mb-4">
                                                          <span style="color: #ff3535">You don't have this many tokens to transfer into your crowdfunding pool - Please enter a different amount.</span>
                                                        </p>

                                                        <p v-if="hardCapOk === false" class="blue-bg font-green mb-4">
                                                          <span style="color: #ff3535">Hard Cap must be Greater Than or Equal To: (Sell Price * Sell Quantity).</span>
                                                        </p>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Pair Quantity Section -->
                                                  <b-step-item :step="4" :clickable="false" label="Pair">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Liquidity Pool Quantity </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">How <span
                                                                  style="font-weight: bold">many</span> {{
                                                                  coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)
                                                                }} tokens will provide liquidity?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-weight: bold; font-size: 17px;">Enter the amount of tokens you will send to your DEX</span>
                                                                <br/><br/>
                                                                How <span style="font-weight: bold">many</span> tokens
                                                                will this pool use to create a liquidity pool?
                                                                <br/>
                                                                <br/>
                                                                This is the <span style="font-weight: bold">amount of tokens</span>
                                                                that will be used to create your <span
                                                                  style="font-weight: bold">Liquidity Pool</span> after
                                                                your crowdfunding campaign has been funded.
                                                                <br/><br/>
                                                                This separate amount of tokens is <span style="font-weight: bold">NOT</span> being sold to crowdfunders by your campaign and will be sent directly to your liquidity pool for trading after your campaign is concluded.
                                                                <br/><br/>
                                                                Once pledgers have <span style="font-weight: bold">fully funded</span>
                                                                your campaign, this is the <span style="font-weight: bold">amount of tokens being paired
                                                                to their raised funds</span> and creating the token pair in
                                                                the
                                                                liquidity pool generated <span style="font-weight: bold">after your campaign is finished</span>.
                                                                <br/><br/>
                                                                <span style="font-weight: bold; font-size: 17px;">This amount should not include the number of tokens you are selling to crowdfunders</span>
                                                                <br/><br/>
                                                                This is ONLY the amount of your token you will be sending to your liquidity pool / dex once your campaign has sold all of its tokens to crowdfunders.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <b-field
                                                            style="margin-top: 20px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="How many tokens will go to your liquidity pool?">
                                                              <label class="label mt-2" style="width:100px;">Pair
                                                                Quantity:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <b-field type="is-info">
                                                                  <ValidationProvider
                                                                      :rules="{required: true, greaterthan: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                      vid="dex_quantity" name="Dex Quantity"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="crowdFundPairQuantity"
                                                                           @change="verifyTokenBalance2(coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName), (Number(crowdFundPairQuantity)+Number(crowdFundSellTokenQuantity)) )"
                                                                           @input="[checkValidInput(valid),verifyTokenBalance2(coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName), (Number(crowdFundPairQuantity)+Number(crowdFundSellTokenQuantity)))]"
                                                                           class="input select"
                                                                           type="number" step="0.0001"
                                                                           placeholder="1000000.0">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span v-if="!errors[0]"
                                                                          class="error validation-msg-green is-info">Quantity of {{
                                                                        coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)
                                                                      }} tokens being used to create LP token pair.</span>
                                                                  </ValidationProvider>
                                                                </b-field>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p v-if="localModuleExists === true"
                                                           class="blue-bg font-green mb-4">
                                                        <span>This pool will generate a liquidity pool with <span
                                                            style="font-weight: bold"> {{
                                                            crowdFundPairQuantity
                                                          }} {{
                                                            coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)
                                                          }}</span> paired with raised funds.</span>
                                                        </p>

                                                        <p v-if="localModuleExists === false"
                                                           class="blue-bg font-green mb-4">
                                                          <span style="color: #ff3535">You don't have this many tokens to transfer into your crowdfunding pool - Please enter a different amount.</span>
                                                        </p>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  WL Section -->
                                                  <b-step-item :step="5" :clickable="false" label="WL">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Whitelist </span>
                                                        </p>



                                                        <div v-if="hasWhitelist === true">


                                                          <accordion>
                                                            <accordion-item>
                                                              <template slot="accordion-trigger">
                                                                <h1 class="blue-bg info-icon">How <span
                                                                    style="font-weight: bold">long</span> will this
                                                                  crowdfund campaign pre-sell to whitelisted accounts?
                                                                </h1>
                                                              </template>
                                                              <template slot="accordion-content">
                                                                <div class="blue-bg-2 has-text-left">
                                                                  <span style="font-weight: bold; font-size: 17px;">Pick how long your pool will sell to whitelisters</span>
                                                                  <br/><br/>
                                                                  How <span style="font-weight: bold">long</span> will
                                                                  your campaign only pre-sell tokens to members in your
                                                                  Whitelist for?
                                                                  <br/><br/>
                                                                  White lists can run for a maximum of 15 days.
                                                                  <br/><br/>
                                                                  <br/><br/>
                                                                  Select the date at which your whitelist sales time will end.
                                                                  <br/><br/>
                                                                  If there is no whitelist for this campaign, disable your whitelist or enter the current date and time.
                                                                  <br/><br/>
                                                                  During this duration of time, only the accounts you
                                                                  have
                                                                  entered into your whitelist will be able to pledge
                                                                  tokens into your pool - guaranteeing them a spot in purchasing your token.
                                                                  <br/><br/>
                                                                  After the whitelist time is up, anyone can pledge to
                                                                  purchase tokens from your campaign.
                                                                </div>
                                                              </template>
                                                            </accordion-item>
                                                          </accordion>

                                                          <br/>
                                                          <div class="divider is-centered"></div>
                                                          <br/>

                                                          <div class="columns">

                                                            <div class="column is-offset-1-desktop">

                                                              <b-datetimepicker
                                                                  v-model="crowdFundWhitelistTime2"
                                                                  placeholder="Whitelist End Time"
                                                                  :inline="true"
                                                                  @input="checkWlTime()"
                                                                  @change="checkWlTime()"
                                                                  :min-datetime="minDatetime"
                                                                  :max-datetime="maxWlDatetime">
                                                              </b-datetimepicker>

                                                              <div class="pt-1 pb-4">
                                                            <span v-if="wlok === true"
                                                                  class="error validation-msg-green is-info">Time only whitelisted memebers will be able to purchase tokens.</span>

                                                                <span v-if="wlok === false"
                                                                      class="error is-danger validation-msg">Whitelist presale must end in the future.</span>
                                                              </div>

                                                            </div>

                                                            <div class="column is-8 pt-6">

                                                              <b-field
                                                                  custom-class="is-small"
                                                                  horizontal>
                                                                <template #label>
                                                                  <b-tooltip type="is-dark" multilined
                                                                             label="Enter the k:accounts in your whitelist, one per line - Individual accounts may be added later from your pools settings page.">
                                                                    <label class="label" style="width:100px;">Whitelist
                                                                      Members:</label>
                                                                  </b-tooltip>
                                                                </template>
                                                                <div class="field-body">
                                                                  <div class="field has-addons">
                                                                    <p class="control is-expanded">
                                                                      <b-field type="is-info">
                                                                  <textarea v-model="crowdFundWhitelist"
                                                                            @input="makeWhitelist(crowdFundWhitelist)"
                                                                            style="height:300px;"
                                                                            class="input select"
                                                                            type="textarea"
                                                                            placeholder=""/>

                                                                      </b-field>
                                                                      <span v-if="!errors[0]"
                                                                            class="error validation-msg-green is-info">Enter whitelisted accounts one per line.</span>
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </b-field>

                                                              <b-field
                                                                  custom-class="is-small"
                                                                  horizontal>
                                                                <template #label>
                                                                  <b-tooltip type="is-dark" multilined
                                                                             label="How many tokens is each whitelisted member allowed to buy?">
                                                                    <label class="label" style="width:100px;">Max Tokens Per
                                                                      Member:</label>
                                                                  </b-tooltip>
                                                                </template>
                                                                <div class="field-body">
                                                                  <div class="field has-addons">
                                                                    <p class="control is-expanded">
                                                                      <b-field type="is-info">
                                                                        <ValidationProvider
                                                                            :rules="{required: true, lessthanequalto: Number(crowdFundSellTokenQuantity), regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                            vid="max_canbuy_whitelist"
                                                                            name="Max Can Buy Whitelist"
                                                                            v-slot="{ errors, valid }">
                                                                          <input v-model="crowdFundWhitelistMaxCanBuy"
                                                                                 @input="checkValidInput(valid)"
                                                                                 class="input select"
                                                                                 type="number" step="0.0001"
                                                                                 placeholder="0">
                                                                          <span class="error is-danger validation-msg">{{
                                                                              errors[0]
                                                                            }}</span>
                                                                          <span v-if="!errors[0]"
                                                                                class="error validation-msg-green is-info">Maximum number of tokens whitelisted members can purchase.</span>
                                                                        </ValidationProvider>
                                                                      </b-field>
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </b-field>


                                                            </div>

                                                          </div>








                                                          <br/>
                                                          <div class="divider is-centered"></div>
                                                          <br/>

                                                          <b-switch
                                                              class="mb-4 mt-1"
                                                              v-model="hasWhitelist"
                                                              :rounded="true"
                                                              :outlined="true"
                                                              size="is-small"
                                                              type="is-success"
                                                              :left-label='false'
                                                              :passive-type='null'>Enable Whitelist
                                                          </b-switch>


                                                        </div>

                                                        <p class="blue-bg font-green mb-4">

                                                        <span>
                                              This pool will only allow whitelisted memebers to pledge first for: <span
                                                            style="font-weight: bold"> {{
                                                            reduceBalance(wlInSeconds,0)
                                                          }} seconds. </span>
                                              <br/>
                                              </span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Campaign Time Section -->
                                                  <b-step-item :step="6" :clickable="false" label="Time">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Campaign Time </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">How <span
                                                                  style="font-weight: bold">long</span> will the
                                                                crowdfund
                                                                campaign last <span
                                                                    style="font-weight: bold">after</span>
                                                                the whitelist pre-sale?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-weight: bold; font-size: 17px;">How long does your campaign last?</span>
                                                                How <span style="font-weight: bold">long</span> will
                                                                your
                                                                campaign run for after whitelisters are finished?
                                                                <br/>
                                                                <br/>
                                                                Select the date at which your campaign ends.
                                                                <br/><br/>
                                                                This is the total length of time your campaign will run for - If you enabled a Whitelist in your pool settings, this is the time that your campaign runs after your whitelisted presale time is up.
                                                                <br/><br/>
                                                                During this time, anyone will be able to pledge into your pool and pledgers will be able to withdraw their tokens at any
                                                                time
                                                                until your pool is fully funded.
                                                                <br/><br/>
                                                                If your campaign is not funded by the end of your
                                                                campaign time, your
                                                                pool will become expired and be pruned from the Stake
                                                                Factory website (but can still be accessed by pool
                                                                participants).
                                                                <br/><br/>
                                                                <span style="font-weight: bold; font-size: 17px;">Campaigns can run up to 1 month</span>
                                                                <br/><br/>
                                                                The maximum length of time a crowdfunding campaign can
                                                                run
                                                                is 1 month.
                                                                <br/><br/>
                                                                <span style="font-weight: bold; font-size: 17px;">Liquidity Pairs are generated after your campaign ends.</span>
                                                                <br/><br/>
                                                                Your liquidity pair can ONLY be generated after this
                                                                time
                                                                has expired (not before) and your campaign has been fully funded by pledgers.
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <b-datetimepicker
                                                            v-model="crowdFundCampaignTime2"
                                                            placeholder="Campaign End Time"
                                                            :inline="true"
                                                            @input="checkCampaignTime()"
                                                            @change="checkCampaignTime()"
                                                            :min-datetime="crowdFundWhitelistTime2"
                                                            :max-datetime="maxDatetime">
                                                        </b-datetimepicker>

                                                        <div class="pt-1 pb-4">
                                                          <span v-if="campaignok === true"
                                                                class="error validation-msg-green is-info pt-1 pb-4">Time this crowdfunding campaign ends.</span>

                                                          <span v-if="campaignok === false"
                                                                class="error is-danger validation-msg">Campaign must end in the future.</span>
                                                        </div>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                        <p class="blue-bg font-green mb-4">

                                                        <span>This pool will raise funds for: <span
                                                            style="font-weight: bold"> {{
                                                            reduceBalance((Number(campaignTimeInSeconds) + Number(wlInSeconds)),0)
                                                          }} seconds total.</span>
                                              <br/>
                                              This pool will only allow whitelisted memebers to pledge first for: <span
                                                              style="font-weight: bold"> {{
                                                              reduceBalance(wlInSeconds,0)
                                                            }} seconds. </span>
                                              <br/>
                                              </span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Insurance Section -->
                                                  <b-step-item :step="7" :clickable="false" label="Insurance">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Rug Pull Insurance </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">How long will rug pull
                                                                insurance <span style="font-weight: bold">last</span>?
                                                              </h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-weight: bold; font-size: 17px;">How long will Rug Pull Insurance last after this campaign has ended?</span>
                                                                <br/><br/>
                                                                How <span style="font-weight: bold">long</span> will rug
                                                                pull insurance last?
                                                                <br/>
                                                                <br/>
                                                                Select the date at which Rug Pull Insurance will end for your pool - There is no limit.
                                                                <br/><br/>
                                                                This is the amount of time your crowdfunding pool will
                                                                hold your liquidity pair tokens before you can withdraw them.
                                                                <br/><br/>
                                                                Enabling rug pull insurance gives pledgers assurance
                                                                that
                                                                liquidity will remain in the liquidity pool for this
                                                                length of time, allowing their newly funded market to
                                                                develop and reach depth.
                                                                <br/><br/>
                                                                This option becomes functional after your pool is fully
                                                                funded and your liquidity pair has been generated. If
                                                                your
                                                                pool is never funded, this insurance will cover your
                                                                pool.
                                                                <span style="font-weight: bold; font-size: 17px;">Visit your pool's info / admin page to withdraw your LP tokens after Rug Pull Insurance has ended</span>
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                        <b-datetimepicker
                                                            v-model="crowdFundInsuranceTime2"
                                                            placeholder="Rug Pull Insurance End Time"
                                                            :inline="true"
                                                            @input="checkInsuranceTime()"
                                                            @change="checkInsuranceTime()"
                                                            :min-datetime="crowdFundCampaignTime2">
                                                        </b-datetimepicker>

                                                        <div class="pt-1 pb-4">
                                                           <span v-if="insuranceok === true"
                                                                 class="error validation-msg-green is-info">Time Rug Pull Insurance ends.</span>

                                                          <span v-if="insuranceok === false"
                                                                class="error is-danger validation-msg">Rug Pull Insurance must end in the future.</span>
                                                        </div>




                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">

                                                        <span>This pool will raise funds for: <span
                                                            style="font-weight: bold"> {{
                                                             reduceBalance((Number(campaignTimeInSeconds) + Number(wlInSeconds)),0)
                                                          }} seconds total.</span>
                                              <br/>
                                              This pool will only allow whitelisted memebers to pledge first for: <span
                                                              style="font-weight: bold"> {{
                                                              reduceBalance(wlInSeconds,0)
                                                            }} seconds. </span>
                                              <br/>
                                              Rug pull insurance will last for: <span style="font-weight: bold">{{
                                                              reduceBalance(insurancetimeinseconds,0)
                                                            }}</span> seconds after this campaign generates a liquidity pair.
                                              <br/>
                                              </span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Pool ID / Description Section -->
                                                  <b-step-item :step="8" :clickable="false" label="Pool">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Pool Name & Description </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span style="font-weight: bold">name</span>
                                                                of this crowdfunding pool?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Give your crowdfunding pool a <span
                                                                  style="font-weight: bold">unique name</span>.
                                                                <br/><br/>
                                                                Pool names must be 30 characters or less and show up on
                                                                the Stake Factory website for all to see.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <b-field
                                                            style="margin-top: 20px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="The Pool's ID.">
                                                              <label class="label pt-1" style="width:50px;">Pool
                                                                ID:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="crowdFundPoolId" disabled
                                                                       class="input select-disabled"
                                                                       type="text" placeholder="10">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>


                                                        <b-field
                                                            style="margin-top: 10px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="The Pool's Name shown to Stakers. Example: Test Pool">
                                                              <label class="label pt-1" style="width:50px;">Pool
                                                                Name:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="crowdFundPoolName"
                                                                       @change="determineCrowdFundPoolId()"
                                                                       @input="determineCrowdFundPoolId()"
                                                                       class="input select"
                                                                       type="text" placeholder="My-Crowdfunding-Pool">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <p v-if="localPoolIdExists === false" class="help is-success">
                                                          This Pool ID is available.
                                                        </p>
                                                        <p v-if="localPoolIdExists === true" class="help is-danger">
                                                          This Pool ID is invalid.
                                                        </p>


                                                        <b-field style="margin-top: 10px;" custom-class="is-small" horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="Description of your crowdfunding campaign in 400 characters or less.">
                                                              <label class="label" style="width:100px;">Crowdfund
                                                                Description:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <b-input v-model="crowdFundPoolDescription" class="field-body"
                                                                   @change="verify400orLess(crowdFundPoolDescription)"
                                                                   @input="verify400orLess(crowdFundPoolDescription)"
                                                                   type="textarea"></b-input>
                                                        </b-field>

                                                        <p v-if="descriptonTooLong === false" class="help is-success">
                                                          Description of crowdfunding campaign ( 400 characters or less
                                                          )
                                                        </p>

                                                        <p v-if="descriptonTooLong === true" class="help is-danger">
                                                          Must be 400 characters or less
                                                        </p>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Liquidity Pool Section -->
                                                  <b-step-item :step="9" :clickable="false" label="LP">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Liquidity Pool </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">name</span>
                                                                of the liquidity pool created after the campaign?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Give your liquidity pool a <span
                                                                  style="font-weight: bold">unique name</span>.
                                                                <br/><br/>
                                                                Liquidity pool names must be 30 characters or less.
                                                                <br/><br/>
                                                                This is the name of your liquidity pool which will
                                                                appear
                                                                on the Stake Factory website for all to see.
                                                                <br/><br/>
                                                                Example: KDA+myTOKEN
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <b-field
                                                            style="margin-top: 20px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="The Pool's Name shown to Stakers. Example: Test Pool">
                                                              <label class="label pt-1" style="width:50px;">Liquidity
                                                                Pool
                                                                Name:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="crowdFundLiquidityPoolName"
                                                                       @change="verifyCrowdFundLiquidityPoolNameExists(crowdFundLiquidityPoolName)"
                                                                       @input="verifyCrowdFundLiquidityPoolNameExists(crowdFundLiquidityPoolName)"
                                                                       class="input select"
                                                                       type="text" placeholder="My-Crowdfunding-Pool">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <p v-if="localPoolIdExists1 === false" class="help is-success">
                                                          This Liquidity Pool ID is available.
                                                        </p>
                                                        <p v-if="localPoolIdExists1 === true" class="help is-danger">
                                                          This Liquidity Pool ID is invalid.
                                                        </p>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">swap fee</span> of your
                                                                pair's
                                                                liquidity pool?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-weight: bold; font-size: 17px;">What is the name of the liquidity pool this crowdfunding campaign will create after it ends?</span>
                                                                <br/><br/>
                                                                <span style="font-weight: bold">After</span> your
                                                                campaign
                                                                is <span style="font-weight: bold">funded</span> and the
                                                                <span
                                                                    style="font-weight: bold">campaign time has ended</span>,
                                                                a <span style="font-weight: bold">liquidity pool will be generated</span>
                                                                forming your <span style="font-weight: bold">{{
                                                                  coinName(crowdFundPledgeTokenModuleNamespace, crowdFundPledgeTokenModuleName)
                                                                }}:{{
                                                                  coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)
                                                                }} </span>
                                                                token pair out of the pledged funds.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">Liquidity pools</span>
                                                                allow anyone accessing the Stake Factory website to
                                                                <span
                                                                    style="font-weight: bold">swap between the assets</span>
                                                                in the liquidity pool's token pair.
                                                                <br/><br/>
                                                                Your liquidity pool also comes with it's own Treasury Account, which will collect 25% of your swap fee - Feel free to access this account from your Liquidity Pools page after its created.
                                                                <br/><br/>
                                                                Each time someone uses your liquidity pool to swap, a percentage of that swap will be deducted as a fee and distributed to LP providers, your treasury account, and stake factory.
                                                                <br/><br/>
                                                                Fee Breakdown:
                                                                <br/><br/>
                                                                + 50% of the Swap Fee goes to LP providers
                                                                + 25% of the Swap Fee goes to your pools Treasury Account
                                                                + 25% of the Swap Fee goes to the Stake Factory Treasury
                                                                <br/><br/>
                                                                What <span style="font-weight: bold">percentage</span>
                                                                of
                                                                each swap makes up the <span style="font-weight: bold">swap fee</span>
                                                                for the liquidity pool that gets created after your
                                                                campaign is funded?
                                                                <br/><br/>
                                                                <span style="font-weight: bold">Research</span> your
                                                                tokenomic strategy for your token pair and <span
                                                                  style="font-weight: bold">enter the percentage</span>
                                                                of
                                                                each swap which will be taken out as a fee for your
                                                                liquidity pool - This fee <span
                                                                  style="font-weight: bold">cannot be changed</span>
                                                                after your liquidity pool is created.
                                                                <br/><br/>
                                                                <span style="font-weight: bold; font-size: 17px;">Access your liquidity pools page / admin page to manage your Treasury Account and Swap Fees</span>
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <b-field
                                                            style="margin-top: 20px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="What is the swap fee of your pairs liquidity pool?">
                                                              <label class="label mt-2" style="width:100px;">Swap
                                                                Fee:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <b-field type="is-info">
                                                                  <ValidationProvider
                                                                      :rules="{required: true, greaterthan: 0, lessthanequalto: 99.0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                      vid="insurance_time" name="Insurance Time"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="crowdFundLiquidityPoolSwapFee"
                                                                           @input="[checkValidInput(valid), determineCrowdFundSwapFee(crowdFundLiquidityPoolSwapFee)]"
                                                                           @change="determineCrowdFundSwapFee(crowdFundLiquidityPoolSwapFee)"
                                                                           class="input select"
                                                                           type="number" step="0.01"
                                                                           placeholder="0.003">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span v-if="!errors[0]"
                                                                          class="error validation-msg-green is-info">Liquidity Pool Swap Fee</span>
                                                                  </ValidationProvider>
                                                                </b-field>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>


                                                        <br/>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                              <span>My Pool's Total Swap Fee: <span
                                                  style="font-weight: bold"> {{ crowdFundLiquidityPoolSwapFee }}%  </span>
                                              <br/>
                                              <span style="font-weight: bold">{{ (crowdFundLiquidityPoolSwapFee / 2) }}%</span> of each Swap Goes to <span style="font-weight: bold">LP Providers</span>
                                              <br/>
                                              <span
                                                  style="font-weight: bold">{{ (crowdFundLiquidityPoolSwapFee / 4) }}%</span> of each Swap Goes to <span style="font-weight: bold">Your Pool's Treasury Account</span>
                                                          <br/>
                                                          <span
                                                              style="font-weight: bold">{{ (crowdFundLiquidityPoolSwapFee / 4) }}%</span> of each Swap Goes to <span style="font-weight: bold">Stake Factory's Treasury</span></span>
                                                        </p>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Pool review Section -->
                                                  <b-step-item :step="10" :clickable="false" label="Review">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Review </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">How does everythign look so far? Please <span style="font-weight: bold">review</span>
                                                                your campaign below:</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Check out the pool your about to create below, does everything look <span
                                                                  style="font-weight: bold">ok</span>?
                                                                <br/><br/>
                                                                After reviewing your pool click the Create Pool button below and your campaign will begin being displayed on the Stake Factory Website.
                                                                <br/><br/>
                                                                Please stay on this page while Stake Factory confirms your transaction and once your transaction is confirmed this tool will automatically take you to your new crowdfunding page.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>


                                                        <section>
                                                          <CrowdFundPoolCardPreview
                                                                             pool_contract="free.factory-crowdfund" :pool_id="crowdFundPoolId" :pool_insurance_end_time="crowdFundInsuranceTime2" :pool_max_buy="crowdFundMaxBuyQuantity"
                                                                             pool_balance="0" :pool_description="crowdFundPoolDescription" :pool_price="crowdFundSellTokenPrice" :pool_amount_to_dex="crowdFundPairQuantity" :pool_amount_to_sell="crowdFundSellTokenQuantity"
                                                                             :pool_end_time="crowdFundCampaignTime2" :pool_start_time="currentDate" :wallet_pledge_balance="0" :pool_amount_sold="0"
                                                                             :accountApproved="true" :pool_pledge_token="coinName(crowdFundPledgeTokenModuleNamespace, crowdFundPledgeTokenModuleName)" :pool_sell_token="coinName(crowdFundSellTokenModuleNamespace, crowdFundSellTokenModuleName)" :pledge_balance="0" :pledge_rewards="0"
                                                                             :stamp_pool_start_time="currentDate" :stamp_pool_end_time="crowdFundCampaignTime2" :pool_active="true" :pool_funded="false" :can_generate="false"
                                                                             :pool_can_claim="false" :funding_failed="false" :pool_hard_cap="crowdFundHardCap" :pool_maxxed="false" :pool_wl_end_time_full="crowdFundWhitelistTime2" :pool_end_time_full="crowdFundCampaignTime2"
                                                                             :pool_wl_active="true" :pool_wl_end_time="crowdFundWhitelistTime2" :pool_wl_max_buy="crowdFundWhitelistMaxCanBuy" :isWhitelisted="true"
                                                                             :is_pool_owner="true" :pool_tokens_locked="0" :pool_name="crowdFundPoolName"></CrowdFundPoolCardPreview>
                                                        </section>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>



                                                        <!-- Create Pool Button -->

                                                        <div class="columns is-mobile mt-6 mb-1 is-flex-direction-column inset-bg" style="background-color: rgba(0, 0, 0, 0.22) !important;">

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  Pool Creation Fee: 0.01 🥩
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  <input
                                                                      v-model="iagree"
                                                                      class="checkbox"
                                                                      type="checkbox"
                                                                  />
                                                                  I agree to the <a href="#">terms and conditions</a>.
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">

                                                            <p v-if="localPoolIdExists === true || localPoolIdExists1 === true"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: One of your pool names is already taken, please try another.</span>
                                                            </p>

                                                            <p v-if="iagree === false"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: Please review Terms and conditions.</span>
                                                            </p>

                                                            <button
                                                                v-if="localPoolIdExists === false && localPoolIdExists1 === false && descriptonTooLong === false && iagree === true"
                                                                @click="clickCreateCrowdFundPool()"
                                                                class="button is-link">Create Pool
                                                            </button>
                                                          </div>

                                                        </div>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Previous Next Buttons-->
                                                  <template
                                                      #navigation="{previous, next}">
                                                    <div class="container create-buttons">
                                                      <div class="columns">
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && localModuleExists === true && wlok === true && campaignok === true && insuranceok === true"
                                                              outlined
                                                              type="is-danger"
                                                              :disabled="previous.disabled"
                                                              @click.prevent="[previous.action(), doStepBackwards()]">
                                                            Previous
                                                          </b-button>
                                                        </div>
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && lpValid === true && localModuleExists === true && wlok === true && campaignok === true && insuranceok === true"
                                                              outlined
                                                              type="is-success"
                                                              :disabled="next.disabled"
                                                              @click="[next.action(), doStepForward()]">
                                                            Next
                                                          </b-button>
                                                        </div>
                                                      </div>
                                                    </div>

                                                  </template>

                                                </b-steps>

                                              </div>

                                            </ValidationObserver>

                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!-- MAKE NFT STUFF SECTION -->
                                    <div v-if="activeTab === '5'"
                                         class="column pool-item is-centered is-align-self-center">
                                      <div class="columns">
                                        <div class="column is-align-self-center inset-bg">
                                          <div class="section" style="margin-top: -100px">
                                            <div class="wave__content" style="top: 7px; z-index: 2">
                                              <div class="wave__header">
                                                <div class="wave__menu"></div>
                                              </div>
                                            </div>

                                              <div class="box">

                                                <section class="hero" >
                                                  <div class="hero-body">
                                                    <div class="container animated fadeInUp">
                                                      <div class="columns is-mobile is-flex-direction-column">
                                                        <div class="column is-12-desktop is-12-tablet is-12-mobile web mt-2">
                                                          <ul class="list-unstyled has-text-centered">
                                                            <li class="cow" style="font-size:20px">Which NFT option do you choose:</li>
                                                          </ul>
                                                          <br/>
                                                          <div class="divider is-centered"></div>
                                                          <br/>
                                                        </div>
                                                        <div class="column is-12-desktop is-12-tablet is-12-mobile web">
                                                          <div class="columns is-multiline is-vcentered is-justify-content-space-evenly">
                                                            <div @click="makeNftSelection = '1'" class="column is-2 account-bg1 bginput">
                                                              <b-tooltip type="is-dark" :label="'Create a new NFT Collection to begin minting NFTs'">
                                                                <span class="cow3">i want to make a </span>
                                                                <br/><br/>
                                                                <img src="../assets/collection-pool-icon.svg" height="68" width="68"/>
                                                                <br/><br/>
                                                                <span class="cow2">nft collection</span>
                                                              </b-tooltip>
                                                            </div>

                                                          </div>
                                                        </div>
                                                        <div class="column is-12">
                                                          <br/>
                                                          <div class="divider is-centered"></div>
                                                          <br/>
                                                        </div>
                                                        <div class="column is-12-desktop is-12-tablet is-12-mobile web">
                                                          <div class="columns is-multiline is-vcentered is-justify-content-space-evenly">

                                                            <div @click="makeNftSelection = '2'" class="column is-2 account-bg1 bginput">
                                                              <b-tooltip type="is-dark" :label="'Mint a single NFT into your NFT Collection'">
                                                              <span class="cow3">i want to make a </span>
                                                              <br/><br/>
                                                              <img src="../assets/nft-pool-icon.svg" height="50" width="50"/>
                                                              <br/><br/>
                                                              <span class="cow2">nft</span>
                                                              </b-tooltip>
                                                            </div>

                                                            <div @click="makeNftSelection = '3'" class="column is-2 account-bg1 bginput">
                                                              <b-tooltip type="is-dark" :label="'Mint multiple NFTs into your NFT Collection at once'">
                                                              <span class="cow3">i want to make </span>
                                                              <br/><br/>
                                                              <img src="../assets/multiplenft-pool-icon.svg" style="margin-left:-15px;" height="53" width="53"/>
                                                              <br/><br/>
                                                              <span class="cow2">multiple nfts</span>
                                                              </b-tooltip>
                                                            </div>

                                                            <div @click="makeNftSelection = '4'" class="column is-2 account-bg1 bginput">
                                                              <b-tooltip type="is-dark" :label="'Create a Whitelist Pre-sale for your NFT Collection'">
                                                              <span class="cow3">i want to make a</span>
                                                              <br/><br/>
                                                              <img src="../assets/whitelist-icon.svg" height="50" width="50"/>
                                                              <br/><br/>
                                                              <span class="cow2">nft whitelist</span>
                                                              </b-tooltip>
                                                            </div>

                                                          </div>
                                                        </div>
                                                        <br/>
                                                        <br/>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </section>


                                              </div>


                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!-- MAKE DAO STUFF SECTION -->
                                    <div v-if="activeTab === '7'"
                                         class="column pool-item is-centered is-align-self-center">
                                      <div class="columns">
                                        <div class="column is-align-self-center inset-bg">
                                          <div class="section" style="margin-top: -100px">
                                            <div class="wave__content" style="top: 7px; z-index: 2">
                                              <div class="wave__header">
                                                <div class="wave__menu"></div>
                                              </div>
                                            </div>

                                            <div class="box">

                                              <section class="hero" >
                                                <div class="hero-body">
                                                  <div class="container animated fadeInUp">
                                                    <div class="columns is-mobile is-flex-direction-column">
                                                      <div class="column is-12-desktop is-12-tablet is-12-mobile web mt-2">
                                                        <ul class="list-unstyled has-text-centered">
                                                          <li class="cow" style="font-size:20px">Which DAO option do you choose:</li>
                                                        </ul>
                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>
                                                      </div>

                                                      <div class="column is-12-desktop is-12-tablet is-12-mobile web">
                                                        <div class="columns is-multiline is-vcentered is-justify-content-space-evenly">
                                                          <div @click="makeDaoSelection = '1'" class="column is-2 account-bg1 bginput mt-2">
                                                            <b-tooltip type="is-dark" :label="'Create a central DAO Hub to manage and operate a DAO'">
                                                              <span class="cow3">i want to make a </span>
                                                              <br/><br/>
                                                              <img src="../assets/dao-hive-icon.svg" height="60" width="60"/>
                                                              <br/><br/>
                                                              <span class="cow2">DAO Hive</span>
                                                            </b-tooltip>
                                                          </div>


                                                        </div>
                                                      </div>
                                                      <div class="column">
                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>
                                                      </div>
                                                      <div class="column is-12-desktop is-12-tablet is-12-mobile web">
                                                        <div class="columns is-multiline is-vcentered is-justify-content-space-evenly">

                                                          <div @click="makeDaoSelection = '2'" class="column is-2 account-bg1 bginput mt-2">
                                                            <b-tooltip type="is-dark" :label="'Create a Voting Poll that allows people to vote over a proposition'">
                                                              <span class="cow3">i want to make a </span>
                                                              <br/><br/>
                                                              <img src="../assets/vote-pool-icon.svg" height="60" width="60"/>
                                                              <br/><br/>
                                                              <span class="cow2">DAO Voting Poll</span>
                                                            </b-tooltip>
                                                          </div>

                                                          <div @click="makeDaoSelection = '3'" class="column is-2 account-bg1 bginput mt-2">
                                                            <b-tooltip type="is-dark" :label="'Create and Assign a Task to someone within a DAO'">
                                                              <span class="cow3">i want to make a </span>
                                                              <br/><br/>
                                                              <img src="../assets/whitelist-icon.svg" height="60" width="60"/>
                                                              <br/><br/>
                                                              <span class="cow2">DAO Task</span>
                                                            </b-tooltip>
                                                          </div>


                                                        </div>
                                                      </div>
                                                      <br/>
                                                      <br/>
                                                    </div>
                                                  </div>
                                                </div>
                                              </section>


                                            </div>


                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!-- VESTING POOLS SECTION -->
                                    <div v-if="activeTab === '6'"
                                         class="column pool-item is-centered is-align-self-center">
                                      <div class="columns">
                                        <div class="column is-align-self-center inset-bg">
                                          <div class="section" style="margin-top: -100px">
                                            <div class="wave__content" style="top: 7px; z-index: 2">
                                              <div class="wave__header">
                                                <div class="wave__menu"></div>
                                              </div>
                                            </div>

                                            <ValidationObserver v-slot="{ valid }">

                                              <div class="box">

                                                <!-- Create Pool Form -->
                                                <accordion>
                                                  <accordion-item>
                                                    <template slot="accordion-trigger">
                                                      <h1 class="title blue-bg info-icon" style="font-size: 25px; padding-top: 2rem; padding-bottom: 2rem;">Create a Vesting Pool</h1>
                                                    </template>
                                                    <template slot="accordion-content">
                                                      <div class="blue-bg-2 has-text-left">
                                                        <span style="font-size: 17px">What are vesting pools?</span>
                                                        <br/><br/>
                                                        Vesting pools <span style="font-weight: bold">distribute 'Reward Tokens' over time</span>
                                                        to a set of <span style="font-weight: bold">accounts</span> such as
                                                        <span
                                                            style="font-weight: bold">team members</span> or even <span
                                                          style="font-weight: bold">'other pools</span>.
                                                        <br/><br/>
                                                        Use the Stake Factory Vesting Pool Creator to create a pool that will release tokens over time to the accounts your add to the vesting pool - For example, you could create a vesting pool to unlock tokens to members of a team over time.
                                                        <br/><br/>
                                                        Vesting pools are great solutions where a set number of tokens are being locked up and distributed to a group of accounts over time - Create the pool, add the accounts, set how many tokens each account will get over time, and the pool will emit tokens to those accounts over time according to the rules you set up.
                                                        <br/><br/>
                                                        Vesting pools can be thought of as private staking pools designed specifically for a set of private accounts and not the public. With vesting pools, instead of accounts staking real tokens (such as with a staking pool), accounts are given manual allocations and weight in the vesting pool, resulting in a pool that pays accounts over time.
                                                        <br/><br/>
                                                        Also like Staking Pools, a Vesting Pool can be thought of as a 'virtual token faucet' that 'drips' tokens out over time to a set of private members - Each account added to a vesting pool can log in and withdraw tokens from the pool as they become available.
                                                        <br/><br/>
                                                        Vesting pools can hook up to other Stake Factory pools, such as Staking Pools, and once both pools are hooked together, the Staking Pool will automatically be fed tokens by the Vesting Pool, allowing pool creators greater control over their Staking solutions built at Stake Factory.
                                                        <br/><br/>
                                                        Also like Staking Pools, Vesting Pools are free to create. Vesting pools are always editable by their creators, however, they can be locked. If a vesting pool is locked it cannot be edited ever again, and will simply emit tokens according to it's rules until the pool becomes exhausted.
                                                        <br/><br/>
                                                        <span style="font-size: 17px">What does this tool do?</span>
                                                        <br/><br/>
                                                        The Stake Factory Vesting Pool Creator Tool allows anyone to create a Vesting Pool and after your pool is created, this tool will take you to your pool's page.
                                                        <br/><br/>
                                                        The Vesting Pool Creator will guide you a series of steps and help you design your vesting pool as well as add accounts to your vesting pool and set how many tokens each account gets.
                                                        <br/><br/>
                                                        This tool splits the entire pool design process into steps and ultimately generates a preview of your pool, if everything looks good to you in the preview you can create your pool - Vesting pools are free to create.
                                                        <br/><br/>
                                                        Vesting pools emit tokens at a constant rate over time, and as you add accounts to your vesting pool, you decide which percentage of the vest they get over time.
                                                        <br/><br/>
                                                        For Example: John creates a Vesting Pool that emits 10 tokens per day to his 4 team members, and set's his pool up so that each team member gets an equal 25% share of the vest - In this scenario, John's vesting pool would allow each team member to withdraw 2.5 tokens per day, or 25% of the vest.
                                                        <br/><br/>
                                                        The Pool Creator Tool gives pool creators great control over
                                                        <span
                                                            style="font-weight: bold">how</span> tokens become
                                                        available to
                                                        members of the vesting pool <span style="font-weight: bold">over the course of time</span>
                                                        - Set <span style="font-weight: bold">exactly</span> how many
                                                        fractions of a
                                                        token you want to release <span style="font-weight: bold">per moment</span>,
                                                        and fine tune controls such as <span style="font-weight: bold">maximum tokens per member</span>,
                                                        and <span style="font-weight: bold">claim wait times</span>.
                                                        <br/><br/>
                                                        Once published,<span style="font-weight: bold"> Stake Factory Vesting Pools appear on the Stake Factory website for members of the pool and the pool creator only</span>.
                                                        <br/><br/>
                                                        Once published, visit your pool's <span style="font-weight: bold">'Pool Creator' section</span>
                                                        by accessing your pool's page from the Staking Factory website <span
                                                          style="font-weight: bold">with the account you used to create your pool</span>
                                                        to view details about your pool, as well as make edits to your pool or add more tokens.
                                                        <br/><br/>
                                                        <span style="font-size: 17px">How does it work?</span>
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Step 1)</span> Complete this form and fill out how your Vesting pool will behave, including how many tokens it will emit over time.
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Step 2)</span> Add your members to your pool and set how much of the vest they will receieve from the pool - Set which percentage of the pool they will receieve, and a maximum number of tokens they can ever receieve, and any wait times that may be necessary.
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Step 3)</span> Confirm your pools preview and click the "Create Pool" at the end of this form to create your pool (Creating staking pools is free!)
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Step 4)</span> Once your pool is created, the tool will automatically take you to your Vesting Pools page- Make sure to share the link with members of the pool if necessary.
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Step 5)</span> Once created, the same account you use to create your pool with can access the pool's Admin Panel from the Pool's Info page.
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Step 6)</span> Remember once your pool runs out of tokens it shuts down and gets removed from Stake Factory - Use your admin page to add more tokens to your pool before it shuts down (also free).
                                                      </div>
                                                    </template>
                                                  </accordion-item>
                                                </accordion>

                                                <b-steps v-model="currentStep" :animated="true">

                                                  <!-- About Section -->
                                                  <b-step-item :step="0" :clickable="false" label="DAO">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4" style="background-color: #00000038 !important;">
                                                          <span style="font-weight: bold; font-size: 20px;">What is a Vesting Pool?</span>
                                                        </p>

                                                        <VestingPoolCardPreview
                                                            pool_contract="free.factory-vesting-fungiv2" :is_pool_owner="true" pool_name="A Vesting Pool" :pool_id="poolId"
                                                            :pool_description="vestingPool_description" :pool_withdraw_wait_seconds="withdrawWaitTime" :pool_claim_wait_seconds="rewardWaitTime"
                                                            :pool_reward_amount="rewardDurationAmount" :pool_reward_duration="rewardDuration" pool_balance="10000000"
                                                            :pool_member_object="VestingMemberData_temp"
                                                            :my_vest_token_balance="0" :my_total_claimed="0" :my_pending_rewards="0" :my_max_tokens="0" :my_last_claim_time="currentDate" :my_end_time_full="currentDate"
                                                            pool_start_balance="10000000" pool_end_time="10/20/2030" :pool_start_time="currentDate" :pool_initialized="true"
                                                            :pool_vest_token="customFungiv2Reward === false ? rewardFungiV2Type : coinName(fungiv2RewardModuleNamespace, fungiv2StakeModuleName)"></VestingPoolCardPreview>


                                                        <p style="color: #4ad1e3"> <span style="font-weight: bold">Vesting Pools</span> are special pools that distribute tokens to a set of accounts.</p>

                                                        <p class="blue-bg font-green mb-4 mt-4" style="padding-left: 6rem; padding-right: 6rem; background-color: #00000038 !important;">
                                                          <span style="font-weight: bold; font-size: 20px;">How do Vesting Pools work?</span>
                                                          <br/><br/>
                                                          Voting Polls work by letting pool creators set up a pool that emits tokens over time- The pool creator fills the pool with tokens, adds accounts to the pool, and the pool emits tokens to the accounts over the course of time.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">Why would I make a Vesting Pool?</span>
                                                          <br/><br/>
                                                          Use <span style="font-weight: bold">Vesting Pools</span> to do things like <span style="font-weight: bold">pay a set of team members over time</span> - You can think of a <span style="font-weight: bold">Vesting Pool</span> sort of as a <span style="font-weight: bold">Private Staking Pool</span>, except <span style="font-weight: bold">only accounts that are added to the pool receive rewards</span> from the pool.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">What can I do with a Vesting Pool?</span>
                                                          <br/><br/>
                                                          Vesting Pools are great for locking up tokens and distributing those tokens to accounts over time. Vesting Pools are a great solution for anyone who has to pay someone (even themselves), or groups of people, over time - Such as when paying team members over time and locking up tokens to be released at a later date.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">How do I create a Vesting Pool?</span>
                                                          <br/><br/>
                                                          This tool will guide you through a series of design steps and help you <span style="font-weight: bold">create and publish your own Vesting Pool</span>. Once published, <span style="font-weight: bold">Vesting Pools are only visible to the Pool Creator and Pool Member accounts in the pool</span> from the "My Pools" section of the Stake Factory website.
                                                          <br/><br/>
                                                          Set up your Vesting Pool by first deciding how many total tokens the pool has to vest over time, and to whom. Then, one by one set up pool accounts by allocating a percentage of the pool's tokens to each pool member and create your pool - The pool will distribute tokens to the accounts according to the rules you setup when you design the Vesting Pool.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">How do accounts receive tokens from Vesting Pools?</span>
                                                          <br/><br/>
                                                          Vesting Pools work in the same way Staking Pools work when it comes to distributing tokens to pool members - All accounts must log in and claim their tokens from their Vesting Pools.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">Can I edit my Vesting Pool?</span>
                                                          <br/><br/>
                                                          Yes, Vesting Pools are fully editable. Vesting Pools are designed for scenarios where the pool creator has full control over their pool and can add/delete accounts and funds from the Vesting Pool at anytime - All parts of a Vesting Pool are fully editable by pool creators by default, however, there is an Option to Lock your Vesting Pool after it is created for scenarios where a immutable pool is desired.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">What is the cost to make a Voting Poll?</span>
                                                          <br/><br/>
                                                          Vesting Pools are Free in all ways at Stake Factory, just pay Kadena gas costs when interacting with your Vesting Pools.
                                                          <br/><br/>
                                                        </p>




                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <b-switch
                                                            class="mb-5 mt-1"
                                                            v-model="localModuleExists"
                                                            :rounded="true"
                                                            :outlined="true"
                                                            size="is-large"
                                                            type="is-success"
                                                            :left-label='false'
                                                            :passive-type='null'>{{ localModuleExists ? "OK" : "OK" }}
                                                        </b-switch>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Reward Token Section -->
                                                  <b-step-item :step="1" :clickable="false" label="Token">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">


                                                        <section v-if="rewardType === 1">
                                                          <accordion>
                                                            <accordion-item>
                                                              <template slot="accordion-trigger">
                                                                <h1 class="blue-bg info-icon">What is the <span
                                                                    style="font-weight: bold">total amount</span> and
                                                                  <span
                                                                      style="font-weight: bold">type of token</span>
                                                                  to be distributed by this pool?</h1>
                                                              </template>
                                                              <template slot="accordion-content">
                                                                <div class="blue-bg-2 has-text-left">
                                                                  <span style="font-size: 17px">How many tokens total will your pool distribute amongst the members of the pool?</span>
                                                                  <br/><br/>
                                                                  Vesting pools distribute Reward Tokens over time amongst
                                                                  members of the Vesting Pool.
                                                                  <br/><br/>
                                                                  What is the <span style="font-weight: bold">total amount of Reward Tokens</span>
                                                                  your pool will be distributing amongst it's pool members?
                                                                  <br/><br/>
                                                                  Your pool will distribute this total amount of Reward
                                                                  Tokens amongst pool members <span
                                                                    style="font-weight: bold">until it runs out of tokens</span>,
                                                                  in which case your pool will deactivate itself due to
                                                                  <span
                                                                      style="font-weight: bold">'exhaustion'</span>.
                                                                  <br/><br/>
                                                                  <span style="font-size: 17px">Pool Exhaustion</span>
                                                                  <br/><br/>
                                                                  Once a Vesting Pool has exhausted all of its Reward
                                                                  Tokens, <span
                                                                    style="font-weight: bold">the pool shuts down</span>,
                                                                  and it cannot be restarted,
                                                                  however,
                                                                  these exhausted pools <span style="font-weight: bold">can still be accessed</span>
                                                                  by the members currently in the pool so that they may
                                                                  claim their unclaimed tokens.
                                                                  <br/><br/>
                                                                  <span style="font-size: 17px">You must deposit this exact amount of tokens into your pool!</span>
                                                                  <br/><br/>
                                                                  You will be <span style="font-weight: bold">depositing this exact amount of Reward Tokens into your pool when you finalize your pool</span>
                                                                  at the end of this form in order to publish it (Don't worry the pool creator tool automatically does it for you! Just make sure you have the tokens in your wallet) - Your pool will hold all the tokens you want it to distribute to pool members, and more tokens can always be added later if you want your pool to run longer.
                                                                  <br/><br/>
                                                                  Additional amounts of Reward Tokens can always be
                                                                  <span
                                                                      style="font-weight: bold">added</span> to your
                                                                  Vesting
                                                                  Pool
                                                                  <span style="font-weight: bold">before</span> it runs
                                                                  out of
                                                                  Reward Token reserves to distribute in
                                                                  order
                                                                  to <span
                                                                    style="font-weight: bold">prevent your pool from becoming exhausted</span>.
                                                                  <br/><br/>
                                                                  You <span style="font-weight: bold">cannot</span> add
                                                                  more Reward
                                                                  Tokens to a Vesting Pool that has already distributed
                                                                  all of it's
                                                                  tokens and shut down due to <span
                                                                    style="font-weight: bold">exhaustion</span>
                                                                  - Keep Vesting Pools topped off with Reward Tokens, or
                                                                  they <span
                                                                    style="font-weight: bold">shut down due to becoming exhausted of Reward Tokens</span>.
                                                                  <br/><br/>
                                                                  You <span style="font-weight: bold">cannot</span>
                                                                  reactivate or
                                                                  re-enable deactivated pools which have shut down due
                                                                  to
                                                                  <span
                                                                      style="font-weight: bold">exhaustion</span> - Once
                                                                  a
                                                                  pool runs
                                                                  out of Reward Tokens, it <span
                                                                    style="font-weight: bold">always shuts off permanently</span>,
                                                                  and if you forget about your pool, it will eventually
                                                                  &
                                                                  automatically shut down this way.
                                                                  <br/><br/>
                                                                  Once your pool is created, <span
                                                                    style="font-weight: bold">you will have access to a private Pool Creator section on the Stake Factory website where you can view your pool's statistics</span>,
                                                                  including how many Reward Tokens remain in your pool,
                                                                  and at which day and time your pool will finish distributing it's current reserve of Reward Tokens and to whom- <span
                                                                    style="font-weight: bold">Use this private Pool Creator section once your pool is created to monitor and add tokens or members to your Vesting Pool if necessary.</span>.
                                                                </div>
                                                              </template>
                                                            </accordion-item>
                                                          </accordion>
                                                          <b-field v-if="customFungiv2Reward === false"
                                                                   style="margin-top: 20px;"
                                                                   custom-class="is-small"
                                                                   horizontal>
                                                            <template #label>

                                                              <b-tooltip type="is-dark"
                                                                         label="This is the token pool members are rewarded with and claim from your pool.">
                                                                <label class="label pt-1"
                                                                       style="width:50px;">Reward:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control">
                                                      <span class="select">
                                                        <select v-model="rewardFungiV2Type"
                                                                @change="verifyTokenBalance2(rewardFungiV2Type, rewardAmount)">
                                                                  <option value="coin">KDA</option>
                                                                  <option value="free.stake-token">STAKE</option>
                                                                  <option value="free.anedak">ADK</option>
                                                                  <option value="free.babena">BABE</option>
                                                                  <option value="runonflux.flux">FLUX</option>
                                                                  <option value="hypercent.prod-hype-coin">HYPE</option>
                                                                  <option value="kdlaunch.token">KDL</option>
                                                                  <option value="lago.kwUSDC">kwUSDC</option>
                                                        </select>
                                                      </span>
                                                                </p>
                                                                <p class="control is-expanded">
                                                                  <ValidationObserver
                                                                      v-if="currentStep === 1 && rewardType === 1 && customFungiv2Reward === false">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthan: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="reward_amount" name="Reward Amount"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="rewardAmount"
                                                                             @change="[verifyTokenBalance2(rewardFungiV2Type, rewardAmount), calculateVestedMemberStats()]"
                                                                             @input="[checkValidInput(valid),verifyTokenBalance2(rewardFungiV2Type, rewardAmount)]"
                                                                             class="input select" type="number"
                                                                             step="0.01"
                                                                             placeholder="Amount of Reward Token">
                                                                      <span class="error is-danger validation-msg">{{
                                                                          errors[0]
                                                                        }}</span>
                                                                      <span v-if="!errors[0]"
                                                                            class="error validation-msg-green is-info">Total amount of tokens to distribute amongst pool members</span>
                                                                      <br/>
                                                                    </ValidationProvider>
                                                                  </ValidationObserver>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field v-if="customFungiv2Reward === true"
                                                                   style="margin-top: 20px;"
                                                                   custom-class="is-small"
                                                                   horizontal>
                                                            <template #label>

                                                              <b-tooltip type="is-dark"
                                                                         label="The total amount of tokens this vesting pool will distribute amongst pool members.">
                                                                <label class="label pt-1" style="width:50px;">Total
                                                                  Tokens:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <ValidationObserver
                                                                      v-if="currentStep === 1 && rewardType === 1 && customFungiv2Reward === true">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthan: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="reward_amount" name="Reward Amount"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="rewardAmount"
                                                                             @change="[verifyTokenBalance2(coinName(fungiv2RewardModuleNamespace,fungiv2RewardModuleName), rewardAmount), calculateVestedMemberStats()]"
                                                                             @input="[checkValidInput(valid),verifyTokenBalance2(coinName(fungiv2RewardModuleNamespace,fungiv2RewardModuleName), rewardAmount)]"
                                                                             class="input select" type="number"
                                                                             step="0.01"
                                                                             placeholder="Amount of Reward Token">
                                                                      <span class="error is-danger validation-msg">{{
                                                                          errors[0]
                                                                        }}</span>
                                                                      <span v-if="!errors[0]"
                                                                            class="error validation-msg-green is-info">Total amount of reward-tokens to distribute by this pool</span>
                                                                      <br/>
                                                                    </ValidationProvider>
                                                                  </ValidationObserver>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>


                                                          <b-field v-if="customFungiv2Reward === true"
                                                                   style="margin-top: 20px;"
                                                                   custom-class="is-small"
                                                                   horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="All tokens have a module name (contract name), what is the name of the contract which defines your reward token?.">
                                                                <label class="label" style="width:100px;">Token Module
                                                                  Name:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info"
                                                                           message="Module name of the fungible-v2 Reward Token contract">
                                                                    <ValidationObserver
                                                                        v-if="currentStep === 1 && rewardType === 1">
                                                                      <ValidationProvider :rules="{required: true}"
                                                                                          vid="fungiv2reward_name"
                                                                                          name="Fungiv2Reward Name"
                                                                                          v-slot="{ errors, valid }">
                                                                        <input v-model="fungiv2RewardModuleName"
                                                                               @input="[checkValidInput(valid), verifyTokenExists(fungiv2RewardModuleNamespace, fungiv2RewardModuleName) ]"
                                                                               class="input select"
                                                                               type="text"
                                                                               placeholder="token-module-name">
                                                                      </ValidationProvider>
                                                                    </ValidationObserver>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field v-if="customFungiv2Reward === true"
                                                                   style="margin-top: 20px;"
                                                                   custom-class="is-small"
                                                                   horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="All Kadena Modules have a namespace, what is the namespace for the contract that defines your reward token?">
                                                                <label class="label" style="width:100px;">Token Module
                                                                  Namespace:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info"
                                                                           message="Module namespace for the fungible-v2 Reward Token contract">
                                                                    <ValidationObserver
                                                                        v-if="currentStep === 1 && rewardType === 1">
                                                                      <ValidationProvider :rules="{required: true}"
                                                                                          vid="fungiv2reward_namespace"
                                                                                          name="Fungiblev2Reward Namespace"
                                                                                          v-slot="{ errors, valid }">
                                                                        <input v-model="fungiv2RewardModuleNamespace"
                                                                               @input="[checkValidInput(valid), verifyTokenExists(fungiv2RewardModuleNamespace, fungiv2RewardModuleName) ]"
                                                                               class="input select"
                                                                               type="text" placeholder="free">
                                                                      </ValidationProvider>
                                                                    </ValidationObserver>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <br/>
                                                          <div class="divider is-centered"></div>
                                                          <br/>

                                                          <b-switch
                                                              class="mb-4 mt-2"
                                                              v-model="customFungiv2Reward"
                                                              :rounded="true"
                                                              :outlined="true"
                                                              size="is-small"
                                                              type="is-success"
                                                              :left-label='false'
                                                              :passive-type='null'>I don't see my token in the above
                                                            list
                                                            and I will type it in manually..
                                                          </b-switch>


                                                          <p v-if="customFungiv2Reward === false && localModuleExists === true"
                                                             class="blue-bg font-green"> This pool will distribute <span
                                                              style="font-weight: bold">{{
                                                              rewardAmount
                                                            }} {{ rewardFungiV2Type }}</span>
                                                            tokens amongst pool members over time. </p>
                                                          <p v-if="customFungiv2Reward === true && localModuleExists === true"
                                                             class="blue-bg font-green"> This pool will distribute <span
                                                              style="font-weight: bold">{{
                                                              rewardAmount
                                                            }} {{
                                                              fungiv2RewardModuleNamespace
                                                            }}.{{ fungiv2RewardModuleName }}</span>
                                                            tokens amongst pool members over time. </p>

                                                          <p v-if="localModuleExists === false"
                                                             class="blue-bg font-green"><span style="color:#ff222b">Error: You don't have enough of this token to send to your Vesting Pool - Please enter another amount or choose another token.</span>
                                                          </p>

                                                        </section>


                                                      </div>

                                                    </section>

                                                  </b-step-item>
                                                  <!-- Distribution Section -->
                                                  <b-step-item :step="2" :clickable="false" label="Distribution">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Variable APY Section -->
                                                        <section type="is-info" class="mt-4">
                                                          <accordion>
                                                            <accordion-item>
                                                              <template slot="accordion-trigger">
                                                                <h1 class="blue-bg info-icon"> How <span
                                                                    style="font-weight: bold">often</span> will this pool <span
                                                                    style="font-weight: bold">distribute tokens</span>
                                                                  amongst
                                                                  pool members over time?</h1>
                                                              </template>
                                                              <template slot="accordion-content">
                                                                <div class="blue-bg-2 has-text-left">
                                                                  <span style="font-size: 17px">How often does this pool give away tokens to pool members? And how often?</span>
                                                                  <br/><br/>
                                                                  Vesting pools distribute Reward Tokens over time to
                                                                  pool members according to the rules you set up below.
                                                                  <br/><br/>
                                                                  These settings adjust when, as well as how many tokens become available in your pool for pool members to claim, and can be further adjusted on a per-member basis later when you go to add pool members to your account.
                                                                  <br/><br/>
                                                                  The <span style="font-weight: bold">2 settings</span>
                                                                  below adjust
                                                                  <span style="font-weight: bold">how frequently your pool will distribute reward tokens to the entire pool</span>
                                                                  up until the pool runs out of reward tokens
                                                                  completely (Imagine a 'water' faucet 'dripping' tokens to everyone in the pool- these settings adjust how many tokens come out of your pool and how often).
                                                                  <br/><br/>
                                                                  For example, if your Vesting Pool emits 2.73972602739726 tokens every 86400 seconds (1 day) to your pool members, you would set this up below. Once you add pool members to your pool, you can decide which percentage of the vest belongs to them each day, as well as a maximum number of tokens they are ever allowed to receive from the pool.
                                                                  <br/><br/>
                                                                  You can emit tokens to your pool however you would like, daily, monthly, weekly, per second, or sub-second - The choice is yours, and your pool will constantly emit tokens at the rate you set up below- Keep in mind you will split up your tokens to pool members, and you can adjust how many tokens each member receives as your pool emits tokens on the following pages.
                                                                  <br/><br/>
                                                                  StakeFactory recommends designing Vesting pools around a single goal, such as a pool that pays a group of Team members - As an example, if a project is paying it's team 1000 tokens over the course of a year, then, create a pool that emits 2.73972602739726 tokens per day. Then, on the following pages, add pool members to the pool and decide which % of the 2.73972602739726 tokens they can claim per day - You can split up member percentages any way you would like on the following steps of this tool.
                                                                  <br/><br/>
                                                                  Pre-planning is the key to creating vesting pools - Have a clear goal in mind when designing your pool- Know how many tokens it emits, when these tokens are fully emitted, and how much of the tokens belong to each member after the pool has finished running. Then, design a pool that emits tokens to it's members this way to achieve your pre-planned goal.
                                                                  <br/><br/>
                                                                  If you have a project that has decided to pay its team 1000 tokens over the course of a year with a vesting pool, load 1000 tokens into the pool and emit them daily, weekly, monthly, or however you wish until they are all emitted in exactly 365 days - Tokens become available to the entire pool depending on your settings below, and it's then up to you to split what percentage of the vest becomes available to each member as you add members to your pool.
                                                                  <br/><br/>
                                                                  <span style="font-weight: bold">Reward Amount</span>:
                                                                  The amount
                                                                  of Reward Tokens to distribute to pool member accounts every Reward
                                                                  Duration.
                                                                  <br/>
                                                                  <span style="font-weight: bold">Reward Duration</span>:
                                                                  The number
                                                                  of seconds that must go by before the Reward Amount is
                                                                  distributed
                                                                  to pool members.
                                                                  <br/><br/>
                                                                  IE- Your pool will distribute the <span
                                                                    style="font-weight: bold">Reward Amount</span>
                                                                  of tokens, every <span
                                                                    style="font-weight: bold">Reward Duration</span>
                                                                  number of
                                                                  seconds (and not before).
                                                                  <br/><br/>
                                                                  Feel free to deisgn a pool however you would like. Emit tokens to pool members every sub second, second, minute, hour, day, week, or month - Tokens only become available for claiming on the moment you decide below. As such, Stake Factory recommends emitting small amounts of tokens over short periods of time, such as 19.17808219178082 tokens every 604,800 seconds (week)- This scenario would make your pool 'drip' 19.17808219178082 tokens out to pool members every 604,800 seconds, which is then further split up between pool members depending on the settings you set on the following pages where you add pool members to your pool.
                                                                  <br/><br/>
                                                                  This means it's best to figure out and pre-plan exactly <span
                                                                    style="font-weight: bold">how</span> you want to
                                                                  distribute your
                                                                  tokens and when (IE- I have 1000 tokens and I want to
                                                                  release 19.17808219178082
                                                                  tokens a week to my pool members for them to claim).
                                                                  <br/><br/>
                                                                  Feel free to distribute your tokens to your stakers however you would like, just remember that stakers must first be staked in your pool this length of time before they recieve any rewards, so distributing rewards in long time intervals is not advised since stakers have to wait that long before they earn rewards.
                                                                  <br/>
                                                                  <br/>
                                                                  Remember, these settings control <span
                                                                    style="font-weight: bold">how often members can claim tokens</span>
                                                                  from your pool -
                                                                  Be mindful
                                                                  of how slowly you release rewards to your pool members
                                                                  (<span
                                                                    style="font-weight: bold">you may not want to set longer time frames such as yearly, as this will force pool members to have to wait a full year before claiming any tokens</span>),
                                                                  For
                                                                  example:
                                                                  <br/>
                                                                  <br/>- Distributing tokens every <span
                                                                    style="font-weight: bold">second</span>
                                                                  allows members to claim new tokens every <span
                                                                    style="font-weight: bold">second</span>.
                                                                  <br/>- Distributing tokens every <span
                                                                    style="font-weight: bold">60 seconds</span>
                                                                  allows members to claim new tokens every <span
                                                                    style="font-weight: bold">60 seconds</span>.
                                                                  <br/>- Distributing tokens every <span
                                                                    style="font-weight: bold">300 seconds</span>
                                                                  allows members to claim new rewards every <span
                                                                    style="font-weight: bold">300 seconds</span>.
                                                                  <br/>
                                                                  <br/>
                                                                  In the examples above we demonstrate that, the <span
                                                                    style="font-weight: bold">slower</span> your Vesting
                                                                  Pool
                                                                  releases rewards to it's pool members, the <span
                                                                    style="font-weight: bold">longer</span>
                                                                  pool members must wait to claim rewards.
                                                                  <br/><br/>The default example settings below allows a
                                                                  pool to
                                                                  distribute 10 tokens per day by releasing a very small
                                                                  amount of
                                                                  tokens each second:
                                                                </div>
                                                              </template>
                                                            </accordion-item>
                                                          </accordion>

                                                          <b-field
                                                              style="margin-top: 20px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="The amount of rewards which become split between pool members each reward duration.">
                                                                <label class="label pt-2" style="width:100px;">Reward
                                                                  Amount:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthan: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="reward_amount"
                                                                        name="Reward Duration Amount"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="rewardDurationAmount"
                                                                             @input="checkValidInput(valid)"
                                                                             @change="calculateVestedMemberStats()"
                                                                             class="input select"
                                                                             type="number" step="0.0001"
                                                                             placeholder="10.0">
                                                                      <span class="error is-danger validation-msg">{{
                                                                          errors[0]
                                                                        }}</span>
                                                                      <span v-if="!errors[0]"
                                                                            class="error validation-msg-green is-info">Amount of reward-tokens to distribute amongst pool members each<span
                                                                          style="font-weight: bold"> Reward Duration</span></span>
                                                                    </ValidationProvider>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field
                                                              style="margin-top: 10px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="The amount of time it takes (in seconds) for reward tokens to become distributed amongst members of the pool.">
                                                                <label class="label pt-2" style="width:100px;">Reward
                                                                  Duration:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthan: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="reward_duration" name="Reward Duration"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="rewardDuration"
                                                                             @input="checkValidInput(valid)"
                                                                             @change="calculateVestedMemberStats()"
                                                                             class="input select"
                                                                             type="number" step="0.01"
                                                                             placeholder="86400.0">
                                                                      <span class="error is-danger validation-msg">{{
                                                                          errors[0]
                                                                        }}</span>
                                                                      <span v-if="!errors[0]"
                                                                            class="error validation-msg-green is-info">Number of seconds before the <span
                                                                          style="font-weight: bold">Reward Amount</span> is distributed amongst pool members</span>
                                                                    </ValidationProvider>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <br/>
                                                          <div class="divider is-centered"></div>
                                                          <br/>


                                                          <p v-if="rewardType === 1" class="blue-bg font-green"><span
                                                              v-if="customFungiv2Reward === false">Every <span
                                                              style="font-weight: bold">{{
                                                              rewardDuration
                                                            }} second(s) {{ rewardDurationAmount }} {{
                                                              rewardFungiV2Type
                                                            }}(s)</span>
                                                are distributed amongst accounts in this pool.</span>
                                                            <span v-if="customFungiv2Reward === true">Every <span
                                                                style="font-weight: bold">{{ rewardDuration }} second(s) {{
                                                                rewardDurationAmount
                                                              }} {{
                                                                fungiv2RewardModuleNamespace
                                                              }}.{{ fungiv2RewardModuleName }}(s)</span>
                                                are distributed amongst accounts in this pool.</span>
                                                            <span
                                                                v-if="getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) > 0.0"
                                                                style="font-size:13px"><br/>⌛ Every hour <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens are distributed amongst accounts in this pool.</span>
                                                            <span
                                                                v-if="getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) <= 0.0"
                                                                style="font-size:13px; color: #c57252; padding-top: 5px; padding-bottom: 5px;"><br/>Warning: Every hour <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens are distributed amongst accounts in this pool! <br/> Accounts must wait longer than 1 hour to claim rewards!</span>
                                                            <span
                                                                v-if="getDailyRewards(rewardDuration, rewardDurationAmount, rewardAmount) > 0.0"
                                                                style="font-size:13px"><br/>🕒 Every day <span
                                                                style="font-weight: bold">{{
                                                                getDailyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens are distributed amongst accounts in this pool.</span>
                                                            <span
                                                                v-if="getDailyRewards(rewardDuration, rewardDurationAmount, rewardAmount) <= 0.0"
                                                                style="font-size:13px; color: #c57252"><br/>Warning: Every day <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens are distributed amongst accounts in this pool! <br/> Accounts must wait longer than 1 day to claim rewards!</span>
                                                            <span
                                                                v-if="getMonthlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) > 0.0"
                                                                style="font-size:13px"><br/>🗓️ Every month <span
                                                                style="font-weight: bold">{{
                                                                getMonthlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens are distributed amongst accounts in this pool.</span>
                                                            <span
                                                                v-if="getMonthlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) <= 0.0"
                                                                style="font-size:13px; color: #c57252"><br/>Warning: Every month <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens are distributed amongst accounts in this pool! <br/> Accounts must wait longer than 1 month to claim rewards!</span>
                                                            <span
                                                                v-if="getYearlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) > 0.0"
                                                                style="font-size:13px"><br/>☀️ Every year <span
                                                                style="font-weight: bold">{{
                                                                getYearlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens are distributed amongst accounts in this pool.</span>
                                                            <span
                                                                v-if="getYearlyRewards(rewardDuration, rewardDurationAmount, rewardAmount) <= 0.0"
                                                                style="font-size:13px; color: #c57252"><br/>Warning: Every year <span
                                                                style="font-weight: bold">{{
                                                                getHourlyRewards(rewardDuration, rewardDurationAmount, rewardAmount)
                                                              }}</span> tokens are distributed amongst accounts in this pool! <br/> Accounts must wait longer than 1 year to claim rewards!</span>
                                                          </p>

                                                        </section>

                                                      </div>


                                                    </section>

                                                  </b-step-item>
                                                  <!-- Pool Members Section -->
                                                  <b-step-item :step="3" :clickable="false" label="Members">
                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">
                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Pool <span
                                                                  style="font-weight: bold">Members</span>
                                                              </h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-size: 17px">How many members can claim tokens from this pool?</span>
                                                                <br/><br/>
                                                                Vesting pools distribute tokens between pool members of the pool over a period of time.
                                                                <br/><br/>
                                                                Enter all the different <span
                                                                  style="font-weight: bold">k:accounts</span>
                                                                that will be claiming tokens from this pool, and which percentage of the vest they will be claiming.
                                                                <br/><br/>
                                                                Adjust the percentage slider to modify how many tokens each account receives over time from the Vesting Pool.
                                                                <br/><br/>
                                                                Add accounts to your pool assigning each account a percentage of the pools vest until 100% of the pool's vest is being distributed.
                                                                <br/><br/>
                                                                Once 100% of your pool is distributed to pool members, your pool is set-up correctly and you can continue to the next page.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <div class="mt-2" style="background-color: #2a4855;padding: 2rem;border-radius: 2rem;border: 2px solid; color: #1bc174; margin-left: 4rem; margin-right: 4rem;" v-if="VestingMemberData.length <= 0">
                                                          Vesting Pools distribute tokens between pool members over time.
                                                          <br/><br/>
                                                          Add more members to your vesting pool using the tool below by assigning them which % of the pool's vest they will receive.
                                                          <br/><br/>
                                                          Please ensure that 100% of your pool's vest is completely distributed between pool members of your pool.
                                                        </div>

                                                        <b-table v-if="VestingMemberData.length > 0" style="padding-left: 7rem; padding-right: 7rem;" :data="VestingMemberData">

                                                          <b-table-column centered label="#" width="40" numeric v-slot="props">
                                                            {{ props.row.id }}
                                                          </b-table-column>



                                                          <b-table-column centered label="Percentage of Vest" width="40" v-slot="props">
                                                            {{ props.row.percentage }}%
                                                          </b-table-column>

                                                          <b-table-column centered label="Account" v-slot="props">
                                                            {{ props.row.account }}
                                                          </b-table-column>

                                                          <b-table-column centered label="Tokens Per Vest" v-slot="props">
                                                            {{ props.row.tokens_per_vest }}
                                                          </b-table-column>

                                                          <b-table-column centered label="Max Tokens" v-slot="props">
                                                            {{ props.row.max_tokens }}
                                                          </b-table-column>

<!--                                                          <b-table-column label="Final Pay Date" centered v-slot="props">-->
<!--                                                            {{ new Date(props.row.date).toLocaleDateString() }}-->
<!--                                                          </b-table-column>-->
                                                          <b-table-column label="Final Pay Date" centered>
                                                            {{ new Date(vestingPool_date).toLocaleDateString() }}
                                                          </b-table-column>

                                                          <b-table-column  label="X" centered>
                                                            <b-button  @click="resetVestingPool()" size="is-small" style="margin-top: -5px; background-color: #1d2f34 !important; border-color: #36b2b8; border-radius: .5rem;"><span style="font-size: 10px; color: #59f7d2; border-color: #4ae39e !important;">X</span></b-button>
                                                          </b-table-column>


                                                        </b-table>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <b-progress v-if="vestingPool_percentLeftTotal === 0" style="justify-content: flex-start; display: flex;" format="percent" size="is-large" :max="100">
                                                          <template #bar>
                                                            <b-progress-bar v-for="u in VestingMemberData"  :key="u.id" :value="u.percentage" show-value></b-progress-bar>
                                                          </template>
                                                        </b-progress>

                                                        <div class="blue-bg" style="color: #4ae39e !important; font-size: 13px;">This pool vests {{rewardDurationAmount}} tokens every {{rewardDuration}} seconds amongst pool members.
                                                        <br/><br/>
                                                          This pool is finished vesting 100% of {{rewardAmount}} tokens on {{vestingPool_date}}.
                                                          <p v-if="vestingPool_percentLeftTotal !== 0" class="blue-bg mt-4" style="font-size: 13px; color: #4ae39e; background-color: #25303e; padding-top: 20px !important; padding-bottom:20px;">
                                                            <span style="color:#f9868b">Warning: This pool only vests {{vestingPool_percentLeft}}% of it's tokens!</span>
                                                            <br/><br/>
                                                            <b-progress v-if="vestingPool_percentLeftTotal !== 100" style="justify-content: flex-start; display: flex;" format="percent" size="is-large" :max="100">
                                                              <template #bar>
                                                                <b-progress-bar v-for="u in VestingMemberData"  :key="u.id" :value="u.percentage" show-value></b-progress-bar>
                                                              </template>
                                                            </b-progress>
                                                            <span style="color:#f9868b">Please add more pool members~  {{vestingPool_percentLeftTotal}}% of the vest is Remaining!</span>
                                                          </p>

                                                        </div>

                                                        <br v-if="vestingPool_percentLeftTotal > 0" />
                                                        <div v-if="vestingPool_percentLeftTotal > 0" class="divider is-centered"></div>
                                                        <br v-if="vestingPool_percentLeftTotal > 0" />

                                                        <accordion v-if="vestingPool_percentLeftTotal > 0">
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Add <span
                                                                  style="font-weight: bold">New</span> Pool Members
                                                              </h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-size: 17px">How many members can claim tokens from this pool?</span>
                                                                <br/><br/>
                                                                Vesting pools distribute tokens between pool members of the pool over a period of time.
                                                                <br/><br/>
                                                                Enter all the different <span
                                                                  style="font-weight: bold">k:accounts</span>
                                                                that will be claiming tokens from this pool, and which percentage of the vest they will be claiming.
                                                                <br/><br/>
                                                                Adjust the percentage slider to modify how many tokens each account receives over time from the Vesting Pool.
                                                                <br/><br/>
                                                                Add accounts to your pool assigning each account a percentage of the pools vest until 100% of the pool's vest is being distributed.
                                                                <br/><br/>
                                                                Once 100% of your pool is distributed to pool members, your pool is set-up correctly and you can continue to the next page.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>




                                                        <b-field v-if="vestingPool_percentLeftTotal > 0"
                                                                 style="margin-top: 20px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="Account name of the new Pool member (k:Account or Pool ID)">
                                                              <label class="label pt-2" style="width:100px;">Account Name:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body" style="padding-right: 10rem !important;">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <b-field type="is-info">
                                                                  <ValidationProvider
                                                                      :rules="{required: true}"
                                                                      vid="vestingPool_account" name="Vesting Pool Account"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="vestingPool_account"
                                                                           @input="[checkValidInput(valid), calculateVestedMemberStats()]"
                                                                           @change="calculateVestedMemberStats()"
                                                                           class="input select"
                                                                           type="text"
                                                                           placeholder="k:pool-member-account">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span v-if="duplicateVestingPoolMember === true" class="error is-danger validation-msg">This pool member has already been added to the pool!</span>
                                                                    <span v-if="!errors[0] && duplicateVestingPoolMember === false"
                                                                          class="error validation-msg-green is-info">Pool Member <span style="font-weight: bold">Account</span> (k:account or Pool ID)</span>

                                                                  </ValidationProvider>
                                                                </b-field>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>


                                                        <b-field v-if="vestingPool_percentLeftTotal > 0"
                                                                 style="margin-top: 10px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="What percent of the pools vest does this account receive?">
                                                              <label class="label pt-2" style="width:100px;">Percentage of Vest:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body" style="padding-right: 10rem !important;">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <b-field>
                                                                  <b-slider style="color: #4ae39e !important" :custom-formatter="(val) => val + '%'" v-model="vestingPool_percentage" indicator :max="vestingPool_percentLeftTotal" @change="calculateVestedMemberStats()" lazy></b-slider>
                                                                </b-field>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>


                                                        <br v-if="vestingPool_percentLeftTotal > 0" />
                                                        <div v-if="vestingPool_percentLeftTotal > 0" class="divider is-centered"></div>
                                                        <br v-if="vestingPool_percentLeftTotal > 0" />


                                                        <b-field v-if="vestingPool_percentLeftTotal > 0"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="The maximum number of tokens this account will receive">
                                                              <label class="label pt-1" style="width:50px;">Max
                                                                Tokens:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body" style="padding-right: 10rem !important;">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="vestingPool_maxtokens" disabled
                                                                       class="input select-disabled"
                                                                       type="text" placeholder="10">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <b-field v-if="vestingPool_percentLeftTotal > 0"
                                                                 style="margin-top: 10px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="The number of tokens per vesting period this account will receive">
                                                              <label class="label pt-1" style="width:50px;">Tokens
                                                                Per Vest:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body" style="padding-right: 10rem !important;">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="vestingPool_tokensPerVest" disabled
                                                                       class="input select-disabled"
                                                                       type="text" placeholder="10">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <b-field v-if="vestingPool_percentLeftTotal > 0"
                                                                 style="margin-top: 10px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="The date this pool member will recieve their final payment">
                                                              <label class="label pt-1" style="width:50px;">Final
                                                                Pay Date:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body" style="padding-right: 10rem !important;">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="vestingPool_date" disabled
                                                                       class="input select-disabled"
                                                                       type="text" placeholder="10">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <br v-if="vestingPool_percentLeftTotal > 0"/>
                                                        <div v-if="vestingPool_percentLeftTotal > 0" class="divider is-centered"></div>
                                                        <br v-if="vestingPool_percentLeftTotal > 0"/>


                                                        <div v-if="vestingPool_percentLeftTotal > 0" style="padding-left: 10rem; padding-right: 10rem;">
                                                          <p v-if="vestingPool_account !== 'k:team-member-account'"
                                                             class="blue-bg font-green mt-4" > <span style="font-weight: bold">{{
                                                              vestingPool_account
                                                            }}</span> will be paid <span style="font-weight: bold">{{
                                                              vestingPool_tokensPerVest
                                                            }}</span>
                                                            tokens for <span style="font-weight: bold">{{
                                                                vestingPool_tokenPayPeriods
                                                              }}</span> vesting periods until they receive <span style="font-weight: bold">{{
                                                                vestingPool_maxtokens
                                                              }}</span> tokens on <span style="font-weight: bold">{{
                                                                vestingPool_date
                                                              }}</span>. </p>

                                                          <p v-if="localModuleExists === false" class="blue-bg is-danger mt-2 pt-2">
                                                            <span style="color:#f9868b">Error: Token you entered could not be found.</span>
                                                          </p>

                                                          <p v-if="vestingPool_account === 'k:team-member-account'" class="blue-bg is-danger mt-2 pt-2">
                                                            <span style="color:#f9868b">Please enter a k:account to add to your vesting pool and choose which percentage of the vest they will receive.</span>
                                                          </p>

                                                          <div v-if="vestingPool_account !== 'k:team-member-account'" class="buttons" style="padding-left: 1rem; padding-right: 1rem;">
                                                            <b-button @click="addNewVestingMember()" type="is-primary" style="background-color: #4b3762 !important; font-size: 20px; height: 50px;" expanded>Add New Member</b-button>
                                                          </div>
                                                        </div>






                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p v-if="vestingPool_percentLeftTotal === 0" class="blue-bg mt-2 pt-2 pb-2" style="font-size: 17px; color: #4ae39e">
                                                          <span style="color:#4ae39e">Success! Your vesting pool now completely vests 100% of it's tokens to it's pool members correctly.</span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>
                                                  <!-- Pool Details Section -->
                                                  <b-step-item :step="4" :clickable="false" label="Pool">
                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">
                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Pool Rules and Custom
                                                                Options</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-size: 17px">Does your pool have any custom rules?</span>
                                                                <br/><br/>
                                                                Vesting pools can <span
                                                                  style="font-weight: bold">do more</span>
                                                                than just distribute Reward Tokens to stakers over time, use these custom rules to set up custom withdraw lock times, or claim wait times.
                                                                <br/><br/>
                                                                Create custom <span
                                                                  style="font-weight: bold">Withdraw Wait Times</span>
                                                                and
                                                                <span
                                                                    style="font-weight: bold">Reward Claim Wait Times</span>
                                                                to
                                                                control how long Pool Members have to wait before they can claim tokens or how long they have to wait in between token claims.
                                                                <br/><br/>
                                                                <span
                                                                    style="font-weight: bold">Withdraw Wait Time</span>:
                                                                The
                                                                number of seconds all Pool Members must wait before
                                                                they can withdraw tokens for the first time.
                                                                <br/><br/>
                                                                <span
                                                                    style="font-weight: bold">Reward Claim Wait Time</span>:
                                                                The
                                                                number of seconds all Pool Members must wait each time before
                                                                they
                                                                can claim
                                                                tokens (<span style="font-weight: bold">this includes between reward claims</span>).
                                                                <br/><br/>
                                                                Use these settings to adjust custom <span
                                                                  style="font-weight: bold">reward claim times</span>
                                                                for all pool members of your pool.
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>
                                                        <ValidationObserver v-if="currentStep === 4">
                                                          <b-field
                                                              style="margin-top: 20px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined label="The minimum time (in seconds) a pool member must wait before withdrawing any tokens from the pool at first.">
                                                                <label class="label" style="width:100px;">Withdraw Wait
                                                                  Time:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info"
                                                                           message="Minimum seconds before a pool member is allowed to unstake">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthanequalto: 0.0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="withdraw_wait_time"
                                                                        name="Withdraw Wait Time"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="withdrawWaitTime"
                                                                             @input="checkValidInput(valid)"
                                                                             class="input select"
                                                                             type="text" placeholder="0.0">
                                                                    </ValidationProvider>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field
                                                              style="margin-top: 10px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="The minimum time (in seconds) a pool member must wait between claiming tokens.">
                                                                <label class="label" style="width:100px;">Reward Claim
                                                                  Wait
                                                                  Time:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info"
                                                                           message="Minimum seconds between token claims">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthanequalto: 0.0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="claim_wait_time" name="Claim Wait Time"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="rewardWaitTime"
                                                                             @input="checkValidInput(valid)"
                                                                             class="input select"
                                                                             type="text" placeholder="0.0">
                                                                    </ValidationProvider>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                        </ValidationObserver>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <div class="blue-bg font-green">
                                                          <p v-if="withdrawWaitTime === 0 || withdrawWaitTime === '0.0' || withdrawWaitTime === '0' || withdrawWaitTime === '0.' || withdrawWaitTime == 0">
                                                            Pool Members can <span style="font-weight: bold">withdraw</span>
                                                            their stake
                                                            at <span style="font-weight: bold">any time</span>. </p>
                                                          <p v-if="withdrawWaitTime !== 0 && withdrawWaitTime !== '0.0' && withdrawWaitTime !== '0' && withdrawWaitTime !== '0.' && withdrawWaitTime != 0">
                                                            Pool Members can <span style="font-weight: bold">withdraw</span>
                                                            their staked
                                                            tokens after <span style="font-weight: bold">{{
                                                              withdrawWaitTime
                                                            }} seconds</span>.
                                                          </p>

                                                          <p v-if="rewardWaitTime === 0 || rewardWaitTime === '0.0' || rewardWaitTime === '0' || rewardWaitTime === '0.' || rewardWaitTime == 0">
                                                            Pool Members can <span style="font-weight: bold">claim</span>
                                                            their
                                                            rewards
                                                            at <span style="font-weight: bold">any time</span>. </p>
                                                          <p v-if="rewardWaitTime !== 0 && rewardWaitTime !== '0.0' && rewardWaitTime !== '0' && rewardWaitTime !== '0.' && rewardWaitTime != 0">
                                                            Pool Members can <span style="font-weight: bold">claim</span>
                                                            their
                                                            rewards
                                                            once every <span
                                                              style="font-weight: bold">{{
                                                              rewardWaitTime
                                                            }} seconds</span>. </p>
                                                        </div>


                                                      </div>

                                                    </section>

                                                  </b-step-item>
                                                  <!-- Pool Name Section -->
                                                  <b-step-item :step="5" :clickable="false" label="Name">
                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">
                                                        <p class="blue-bg">Pool Details</p>
                                                        <br/>
                                                        <b-field
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="The Pool's ID. Example: my-teams-vesting-pool">
                                                              <label class="label pt-1" style="width:50px;">Pool
                                                                ID:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="poolId" disabled
                                                                       class="input select-disabled"
                                                                       type="text" placeholder="10">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>
                                                        <p v-if="localPoolIdExists === false && localPoolIdExists1 === false && localPoolIdExists2 === false"
                                                           class="help is-success">
                                                          This Pool ID is available.
                                                        </p>
                                                        <p v-if="localPoolIdExists === true || localPoolIdExists1 === true || localPoolIdExists2 === true"
                                                           class="help is-danger">
                                                          This Pool ID is invalid.
                                                        </p>

                                                        <b-field
                                                            style="margin-top: 10px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="The Pool's Name shown to Stakers. Example: Test Pool">
                                                              <label class="label pt-1" style="width:50px;">Pool
                                                                Name:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="poolName"
                                                                       @change="determineVestingPoolId()"
                                                                       @input="determineVestingPoolId()"
                                                                       class="input select"
                                                                       type="text" placeholder="10">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <b-field style="margin-top: 10px;" custom-class="is-small" horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="Enter a Short Description of your Vesting Pool - EX: My Teams Vesting Pool">
                                                              <label class="label" style="width:100px;">Pool
                                                                Description:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <b-input v-model="vestingPool_description" class="field-body"
                                                                   @change="verify400orLess(vestingPool_description)"
                                                                   @input="verify400orLess(vestingPool_description)"
                                                                   type="textarea"></b-input>
                                                        </b-field>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                      </div>
                                                    </section>


                                                  </b-step-item>
                                                  <!-- Pool Review Section -->
                                                  <b-step-item :step="6" :clickable="false" label="Review">
                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">
                                                        <p class="blue-bg">Review</p>
                                                        <br/>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Here is a preview of your
                                                                pool! Does everything look ok so far? </h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Check out the preview of your pool below and make sure
                                                                everything looks ok - This is how your pool will appear
                                                                on
                                                                the Stake Factory website.
                                                                <br/><br/>
                                                                After clicking create pool, your pool will be created
                                                                and you will be taken to your vesting pools page.
                                                                <br/><br/>
                                                                Make sure to give your vesting pools link to any members of the pool who may need to access the vesting pool to claim tokens.
                                                                <br/><br/>
                                                                Vesting pools only appear under the "My Pools" section of the Stake Factory website.
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <!-- POOL PREVIEWS -->

                                                        <VestingPoolCardPreview
                                                            pool_contract="free.factory-vesting-fungiv2" :is_pool_owner="true" :pool_name="poolName" :pool_id="poolId"
                                                            :pool_description="vestingPool_description" :pool_withdraw_wait_seconds="withdrawWaitTime" :pool_claim_wait_seconds="rewardWaitTime"
                                                        :pool_reward_amount="rewardDurationAmount" :pool_reward_duration="rewardDuration" :pool_balance="rewardAmount"
                                                            :pool_member_object="VestingMemberData"
                                                            :my_vest_token_balance="0" :my_total_claimed="0" :my_pending_rewards="0" :my_max_tokens="0" :my_last_claim_time="currentDate" :my_end_time_full="currentDate"
                                                        :pool_start_balance="rewardAmount" :pool_end_time="vestingPool_date" :pool_start_time="currentDate" :pool_initialized="true"
                                                        :pool_vest_token="customFungiv2Reward === false ? rewardFungiV2Type : coinName(fungiv2RewardModuleNamespace, fungiv2StakeModuleName)"></VestingPoolCardPreview>



                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                        <!-- Create Pool Button -->

                                                        <div class="columns is-mobile mt-6 mb-1 is-flex-direction-column inset-bg" style="background-color: rgba(0, 0, 0, 0.22) !important;">

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  Pool Creation Fee: FREE!
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  <input
                                                                      v-model="iagree"
                                                                      class="checkbox"
                                                                      type="checkbox"
                                                                  />
                                                                  I agree to the <a href="#">terms and conditions</a>.
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">

                                                            <p v-if="localPoolIdExists === true || localPoolIdExists1 === true || localPoolIdExists2 === true"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: Pool name error.</span>
                                                            </p>

                                                            <p v-if="iagree === false"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: Please review Terms and conditions.</span>
                                                            </p>

                                                            <button
                                                                v-if="localPoolIdExists === false && localPoolIdExists1 === false && localPoolIdExists2 === false && iagree === true"
                                                                @click="clickCreateVestingPool()"
                                                                class="button is-link">Create Pool
                                                            </button>
                                                          </div>

                                                        </div>

                                                      </div>
                                                    </section>


                                                    <!--                                 Button group -->


                                                  </b-step-item>
                                                  <!-- Previous Next Buttons-->
                                                  <template
                                                      #navigation="{previous, next}">
                                                    <div class="container create-buttons">
                                                      <div class="columns">
                                                        <div class="column">
                                                          <b-button v-if="valid === true && localModuleExists === true"
                                                                    outlined
                                                                    type="is-danger"
                                                                    :disabled="previous.disabled"
                                                                    @click.prevent="[previous.action(), doStepBackwards()]">
                                                            Previous
                                                          </b-button>
                                                        </div>
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && lpValid === true && localModuleExists === true && canGoNext === true"
                                                              outlined
                                                              type="is-success"
                                                              :disabled="next.disabled"
                                                              @click="[next.action(), doStepForward()]">
                                                            Next
                                                          </b-button>
                                                        </div>
                                                      </div>
                                                    </div>

                                                  </template>

                                                </b-steps>

                                              </div>

                                            </ValidationObserver>

                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>


                                <!-- Make a NFT section-->
                                <div v-if="makingPool === false && welcome === false && makeNftSelection !== '0' && finishedCreating === false"
                                     class="column pool-main is-centered mb-0">
                                  <div class="columns is-centered">

                                    <!-- MAKE COLLECTION SECTION -->
                                    <div v-if="makeNftSelection === '1'"
                                         class="column pool-item is-centered is-align-self-center">
                                      <div class="columns">
                                        <div class="column is-align-self-center inset-bg">
                                          <div class="section" style="margin-top: -100px">
                                            <div class="wave__content" style="top: 7px; z-index: 2">
                                              <div class="wave__header">
                                                <div class="wave__menu"></div>
                                              </div>
                                            </div>

                                            <ValidationObserver v-slot="{ valid }">

                                              <div class="box">

                                                <!-- Create collection Form -->
                                                <accordion>
                                                  <accordion-item>
                                                    <template slot="accordion-trigger">
                                                      <h1 class="title blue-bg info-icon" style="font-size: 25px; padding-top: 2rem; padding-bottom: 2rem;">Create a NFT Collection</h1>
                                                    </template>
                                                    <template slot="accordion-content">
                                                      <div class="blue-bg-2 has-text-left">
                                                        <span style="font-weight: bold; font-size: 17px;">What is a NFT collection?</span>
                                                        <br/><br/>
                                                        <span style="font-weight: bold">NFT Collections</span> are a group of NFT's which are all made by a single <span style="font-weight: bold">artist</span> on Stake Factory.
                                                        <br/><br/>
                                                        All NFTs are apart of a <span style="font-weight: bold">NFT Collection</span>, and you must make a collection <span style="font-weight: bold">first</span> in order to mint NFT's into a collection.
                                                        <br/><br/>
                                                        <span style="font-weight: bold">NFT Collections</span> are <span style="font-weight: bold">protected</span>, and <span style="font-weight: bold">only the artist who created the NFT collection</span> can mint more NFT's into their own NFT collection.
                                                        <br/><br/>
                                                        <span style="font-weight: bold">NFT Collections</span> are what allow an NFT artist to prove that she/he made their own collection of NFT's.
                                                        <br/><br/>
                                                        Create your own <span style="font-weight: bold">NFT Collection</span> on Stakefactory to begin making your own NFTs.
                                                        <br/><br/>
                                                        Once your <span style="font-weight: bold">NFT Collection</span> is created, you can begin using the NFT Minting Tools to mint NFT's into your collection.
                                                        <br/><br/>
                                                        <span style="font-weight: bold">NFT Collections</span> are <span style="font-weight: bold">hidden by default</span> until you begin to <span style="font-weight: bold">Transfer</span>, <span style="font-weight: bold">Sell</span>, or complete a <span style="font-weight: bold">Whitelist Presale</span> for any of the NFTs in your collection.
                                                        <br/><br/>
                                                        Remember: Once you <span style="font-weight: bold">transfer</span> or <span style="font-weight: bold">sell</span> any of your NFT's, your <span style="font-weight: bold">entire NFT collection will become visible</span> to anyone using the Stakefactory website - Do not transfer or sell your NFT's <span style="font-weight: bold">until you are ready for the public to see all of your NFTs</span>.
                                                        <br/><br/>
                                                        <span style="font-weight: bold; font-size: 17px">How does this tool work?</span>
                                                        <br/><br/>
                                                        This tool will guide you through a series of steps, ultimately creating your NFT collection on the Kadena blockchain once you complete all the steps.
                                                        <br/><br/>
                                                        This tool creates <span style="font-weight: bold">NFT Collections</span> (Not NFTs) - Create your NFT collection first with this tool and then visit the NFT Minting Tool to mint NFTs into your Collection.
                                                        <br/><br/>
                                                        <span style="font-weight: bold; font-size: 17px">1) Create your NFT Collection</span>
                                                        <br/><br/>
                                                        Use this tool to create your <span style="font-weight: bold">NFT collection</span> by filling out the details of your NFT collection and how it will appear on the Stakefactory website, such as:
                                                        <br/><br/>
                                                        + <span style="font-weight: bold">Collection Website</span>: Assign a useful  <span style="font-weight: bold">web link</span> to your NFT collection, such as it's <span style="font-weight: bold">website</span> or <span style="font-weight: bold">social page</span> so that anyone who views your NFT collection can easily identify your work and learn more about it.
                                                        <br/><br/>
                                                        + <span style="font-weight: bold">Collection Image</span>: Like NFT's, all NFT collections also have their own <span style="font-weight: bold">image</span> - NFT Collection images are displayed wherever your collection is and help people identify your NFTs.
                                                        <br/><br/>
                                                        + <span style="font-weight: bold">Collection Signature</span>: All collections have a unique <span style="font-weight: bold">signature</span> signed by the artist who created the NFTs signifying their authenticity - This autograph must be unique and may be anything you would like (under 30 characters), however no 2 collections can have the same signature.
                                                        <br/><br/>
                                                        + <span style="font-weight: bold">Collection Token (Optional)</span>: Optionally, NFT collections can be tied to a fungible <span style="font-weight: bold">token</span>. NFTs tied to a token must be bought and sold in that token on the Stake Factory Marketplace.
                                                        <br/><br/>
                                                        Each step of this tool will guide you through the NFT Collection creation process and at the end give you the opportunity to review your NFT collection before you actually create it.
                                                        <br/><br/>
                                                        All NFT collections have an initial <span style="font-weight: bold">creation fee</span> of 0.1 STAKE.
                                                      </div>
                                                    </template>
                                                  </accordion-item>
                                                </accordion>

                                                <b-steps v-model="currentStep">

                                                  <!-- About Section -->
                                                  <b-step-item :step="0" :clickable="false" label="About">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4" style="padding-left: 6rem; padding-right: 6rem; background-color: #00000038 !important;">
                                                          <span style="font-weight: bold; font-size: 17px;">What is a NFT collection?</span>
                                                        </p>

                                                        <div class="column is-centered mb-4 mt-5 center-flex">
                                                          <div class="columns is-multiline is-centered pools-card ">
                                                            <CollectionCardPreview class="column is-3"
                                                                                   style="margin: 10px; width: 316px !important;"
                                                                                   collection_name="Example Collection"
                                                                                   collection_link="?"
                                                                                   collection_signature="?"
                                                                                   :collection_author="currentName"
                                                                                   :collection_supply="'0'"
                                                                                   collection_token="STEAK"
                                                                                   collection_image="?"
                                                                                   :show_flipper="true"></CollectionCardPreview>
                                                          </div>
                                                        </div>

                                                        <p class="blue-bg font-green mb-4" style="padding-left: 6rem; padding-right: 6rem; background-color: #00000038 !important;">
                                                          <span style="font-weight: bold">NFT Collections</span> are a group of NFT's which are all made by a single <span style="font-weight: bold">artist</span> on Stake Factory.
                                                          <br/><br/>
                                                          All NFTs are apart of a <span style="font-weight: bold">NFT Collection</span> - You must make a collection <span style="font-weight: bold">first</span> in order to mint NFT's into a collection.
                                                          <br/><br/>
                                                          <span style="font-weight: bold">NFT Collections</span> are <span style="font-weight: bold">protected</span>, and <span style="font-weight: bold">only the artist who created the NFT collection</span> can mint more NFT's into their own NFT collection.
                                                          <br/><br/>
                                                          <span style="font-weight: bold">NFT Collections</span> are what allow an NFT artist to prove that she/he made their own NFT's.
                                                          <br/><br/>
                                                          Create your own <span style="font-weight: bold">NFT Collection</span> on Stake Factory to begin making your own NFTs on Stake Factory.
                                                          <br/><br/>
                                                          Once your <span style="font-weight: bold">NFT Collection</span> is created, you can begin using the NFT Minting Tools to mint NFT's into your collection.
                                                          <br/><br/>
                                                          <span style="font-weight: bold">NFT Collections</span> are <span style="font-weight: bold">hidden by default</span> until you begin to <span style="font-weight: bold">Transfer</span>, <span style="font-weight: bold">Sell</span>, or complete a <span style="font-weight: bold">Whitelisted Presale</span> for any of the NFTs in your collection.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 17px">How does this tool work?</span>
                                                          <br/><br/>
                                                          This tool will guide you through a series of steps, ultimately creating a NFT collection for you to begin minting NFTs into.
                                                          <br/><br/>
                                                          This tool creates <span style="font-weight: bold">NFT Collections</span> (Not NFTs) - Create your NFT collection first with this tool and then visit the NFT Minting Tool to mint NFTs into your Collection.
                                                          <br/><br/>
                                                          Use this tool to create your <span style="font-weight: bold">NFT collection</span> by filling out the details of your NFT collection and how it will appear on the Stake Factory website.
                                                          <br/><br/>
                                                          Each step of this tool will guide you through the NFT Collection creation process and at the end give you the opportunity to preview and review your NFT collection before you actually create it.
                                                          <br/><br/>
                                                          All NFT collections have an initial <span style="font-weight: bold">creation fee</span> in STEAK token.
                                                        </p>




                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <b-switch
                                                            class="mb-5 mt-1"
                                                            v-model="localModuleExists"
                                                            :rounded="true"
                                                            :outlined="true"
                                                            size="is-large"
                                                            type="is-success"
                                                            :left-label='false'
                                                            :passive-type='null'>{{ localModuleExists ? "OK" : "OK" }}
                                                        </b-switch>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Create collection Section -->
                                                  <b-step-item :step="1" :clickable="false" label="Collection">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Create collection Section -->
                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Collection ID </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">ID</span>
                                                                of this collection?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                All collections have a <span style="font-weight: bold">Collection ID</span>.
                                                                <br/><br/>
                                                                Your <span style="font-weight: bold">collection's ID</span> is your collections name and is used to identify your collection and displayed everywhere your collection is on the Stake Factory website.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">Collection ID's</span> can contain <span style="font-weight: bold">no spaces</span> and must be between <span style="font-weight: bold">3 and 30 characters in length</span>.
                                                                <br/><br/>
                                                                Example: StakeFactory-NFTs
                                                                <br/><br/>
                                                                <span style="font-weight: bold">Collection ID's</span> must be <span style="font-weight: bold">unique</span>- No other collection may share the same ID as yours.
                                                                <br/><br/>
                                                                Use the tool below to check if your collection ID is already taken by <span style="font-weight: bold">typing the ID you wish to use for your collection in the tool</span> - The tool will automatically check if your ID has been taken and notify you in such an event.
                                                                <br/><br/>
                                                                Once you have picked out your unique collection ID, click "<span style="font-weight: bold">Next</span>" to proceed - Your collection's ID <span style="font-weight: bold">cannot be changed</span> after it is created.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>
                                                        <b-field
                                                            style="margin-top: 20px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="What is your NFT Collection's ID?">
                                                              <label class="label pt-1" style="width:50px;">Collection ID:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="collectionID"
                                                                       @change="[determineCollectionID(), verifyCollectionDontExist(collectionID)]"
                                                                       @input="[determineCollectionID(), verifyCollectionDontExist(collectionID)]"
                                                                       class="input select"
                                                                       type="text" placeholder="stake-token">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                        <p v-if="localModuleExists === true"
                                                           class="blue-bg font-green mb-4 mt-2">
                                              <span>My Collection is called: <span
                                                  style="font-weight: bold"> {{
                                                  collectionID
                                                }}</span></span>
                                                        </p>

                                                        <p v-if="localModuleExists === false" class="blue-bg is-danger">
                                                          <span style="color:#f9868b">Error: The Collection ID you have entered is already taken, please try another.</span>
                                                        </p>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  collection link Section -->
                                                  <b-step-item :step="2" :clickable="false" label="Web Site">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Collection Website </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Enter this NFT Collection's <span
                                                                  style="font-weight: bold">website</span></h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Does your collection have a <span style="font-weight: bold">website</span> or some other web page you would like to have displayed next to your NFT collection?
                                                                <br/>
                                                                <br/>
                                                                Enter a <span style="font-weight: bold">link</span> to a <span style="font-weight: bold">website</span> such as your NFT Collections website or social page so that others can easily identify your NFT collection and learn more about it.
                                                                <br/>
                                                                <br/>
                                                                Collection Links have a <span style="font-weight: bold">max length of 256 characters</span>.
                                                                <br/>
                                                                <br/>
                                                                Your collection's link is displayed anywhere your collection is, and <span style="font-weight: bold">can be updated later</span> from your Collection's Page.
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <b-field
                                                            style="margin-top: 20px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="Does this collection have a web page? Enter it here.">
                                                              <label class="label pt-1 mt-2" style="width:50px;">Web Page:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="collectionLink"
                                                                       class="input select"
                                                                       type="text" placeholder="stake-token">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                              <span>My Collection's Web page is: <span style="font-weight: bold"> {{ collectionLink }}  </span>
                                              <br/>
                                                My Collection's ID is: <span style="font-weight: bold"> {{ collectionID }}  </span></span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  collection image Section -->
                                                  <b-step-item :step="3" :clickable="false" label="Image">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Collection Image </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Enter link to this Collection's <span
                                                                  style="font-weight: bold">Image</span></h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                All collections have an <span style="font-weight: bold">image</span> - Enter the link to your collection's image.
                                                                <br/>
                                                                <br/>
                                                                <span style="font-weight: bold">Collection images</span>, like NFT images, are displayed on cards in various places on the Stake Factory website and used to identify your collection of NFTs.
                                                                <br/>
                                                                <br/>
                                                                Enter a link to your collection's image and click "<span style="font-weight: bold">Next</span>" to proceed - Your <span style="font-weight: bold">collection's image</span>, like an NFT's image, <span style="font-weight: bold">cannot be changed later</span> after it is set.
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <b-field
                                                            style="margin-top: 20px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="Enter a link to this collections image.">
                                                              <label class="label pt-1 mt-2" style="width:50px;">Collection Image:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="collectionImageLink"
                                                                       class="input select"
                                                                       type="text" placeholder="stake-token">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <div class="column is-centered mb-4 mt-5 center-flex">
                                                          <div class="columns is-multiline is-centered pools-card ">
                                                            <CollectionCardPreview class="column is-3"
                                                                                 style="margin: 10px; width: 316px !important;"
                                                                                   :collection_name="collectionID"
                                                                                   :collection_link="collectionLink"
                                                                                   :collection_signature="collectionSignature"
                                                                                   :collection_author="myaccount"
                                                                                   :collection_supply="'0'"
                                                                                   :collection_token="coinName(make_nft_collectionNamespace, make_nft_collectionModuleName)"
                                                                                   :collection_image="collectionImageLink"
                                                                                 :show_flipper="true"></CollectionCardPreview>
                                                          </div>
                                                        </div>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                              <span>My Collection's Image link is: <span style="font-weight: bold"> {{ collectionImageLink }}  </span></span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Sell Token Section -->
                                                  <b-step-item :step="4" :clickable="false" label="Market Token">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Create Pair Section -->
                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Marketplace Token  </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">token</span>
                                                                this collection must be traded in?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                <span style="font-weight: bold; font-size: 17px;">What token must this entire NFT collection be traded in at the Stake Factory market? (Optional)</span>
                                                                <br/><br/>
                                                                <span style="font-weight: bold">NFT Collections</span> contain NFTs which can be traded on the Stake Factory market- Use this option to <span style="font-weight: bold">enforce which token your NFTs must be traded in on the Stake Factory Marketplace</span>.
                                                                <br/><br/>
                                                                Enter the token details of the token your collection must be traded in below, including it's <span style="font-weight: bold">module name and namespace</span>.
                                                                <br/><br/>
                                                                This is an <span style="font-weight: bold">optional feature</span> for your NFT collection and can also be turned off- Turning this option off will allow market sellers to pick their own token when they go to sell it on the marketplace.
                                                                <br/><br/>
                                                                Entering the official kadena coin contract is the same as turning this option off.
                                                                <br/><br/>
                                                                This option <span style="font-weight: bold">cannot be changed or updated later</span> after your collection has been created.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>



                                                        <ValidationObserver v-if="currentStep === 4 && collectionAnyToken === false">
                                                          <b-field
                                                              style="margin-top: 20px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What is the contract name / module name of the token your your collection must be traded in?">
                                                                <label class="label pt-1" style="width:50px;">Token
                                                                  Module:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <ValidationProvider
                                                                      :rules="{required: true}"
                                                                      vid="crowdfund_module_name"
                                                                      name="Crowdfund Module Name"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="make_nft_collectionModuleName"
                                                                           @change="verifyTokenExists(make_nft_collectionNamespace, make_nft_collectionModuleName)"
                                                                           @input="verifyTokenExists(make_nft_collectionNamespace, make_nft_collectionModuleName)"
                                                                           class="input select"
                                                                           type="text" placeholder="my-token">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span
                                                                        class="error is-danger validation-msg is-hidden">{{
                                                                        valid[0]
                                                                      }}</span>
                                                                  </ValidationProvider>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field
                                                              style="margin-top: 10px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What is the contract namespace / module namespace of the token this collection must be traded in?">
                                                                <label class="label pt-1" style="width:50px;">Token
                                                                  Namespace:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">

                                                                  <ValidationProvider
                                                                      :rules="{required: false}"
                                                                      vid="crowdfund_module_namespace"
                                                                      name="Crowdfund Module Namespace"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="make_nft_collectionNamespace"
                                                                           @change="verifyTokenExists(make_nft_collectionNamespace, make_nft_collectionModuleName)"
                                                                           @input="verifyTokenExists(make_nft_collectionNamespace, make_nft_collectionModuleName)"
                                                                           class="input select"
                                                                           type="text" placeholder="free">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span class="error is-danger validation-msg">{{
                                                                        valid[0]
                                                                      }}</span>
                                                                  </ValidationProvider>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                        </ValidationObserver>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <b-switch
                                                            class="mb-4 mt-1"
                                                            v-model="collectionAnyToken"
                                                            :rounded="true"
                                                            :outlined="true"
                                                            size="is-small"
                                                            type="is-success"
                                                            :left-label='false'
                                                            :passive-type='null'>My NFTs can be sold in ANY token
                                                        </b-switch>


                                                        <p class="blue-bg font-green mb-4 mt-2" v-if="collectionAnyToken === false">
                                              <span v-if="customFungiv2Reward === false">The NFTs in my NFT Collection must be bought and sold in <span
                                                  style="font-weight: bold"> {{
                                                  coinName(make_nft_collectionNamespace, make_nft_collectionModuleName)
                                                }} </span> on the Stake Factory Marketplace.</span>
                                                        </p>

                                                        <p class="blue-bg font-green mb-4 mt-2" v-if="collectionAnyToken === true">
                                              <span v-if="customFungiv2Reward === false">The NFTs in my NFT Collection must be bought and sold in <span
                                                  style="font-weight: bold"> any token </span> on the Stake Factory Marketplace.</span>
                                                        </p>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Signature Section -->
                                                  <b-step-item :step="5" :clickable="false" label="Signature">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Artist Signature </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Please give your collection a unique <span
                                                                  style="font-weight: bold">signature</span>:</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                On Stakefactory, all NFT Collections are <span style="font-weight: bold">signed</span> by the NFT Artist that makes the collection.
                                                                <br/><br/>
                                                                A <span style="font-weight: bold">Collections Signature</span> helps prove the collection was made by the original artist that created it, and not by someone else.
                                                                <br/><br/>
                                                                Please give your collection a <span style="font-weight: bold">unique signature</span>.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">Collection signatures</span> must be between <span style="font-weight: bold">3 and 30 characters</span> in length, and must be <span style="font-weight: bold">unique</span> - No other collection can share the same signature, even if it's the same artist.
                                                                <br/><br/>
                                                                Your signature will be listed under the details of your collection and all of it's NFTs and can be used to reference the authenticity of both.
                                                                <br/><br/>
                                                                Use the tool below to create a unique signature for your NFT collection - Every time you type in the tool below it will automatically check if your signature is unique and notify you if it is not.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <b-field
                                                            style="margin-top: 20px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="The Pool's Name shown to Stakers. Example: Test Pool">
                                                              <label class="label pt-1" style="width:50px;">Collection
                                                                Signature:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="collectionSignature"
                                                                       @change="verifySignatureExists(collectionSignature)"
                                                                       @input="verifySignatureExists(collectionSignature)"
                                                                       class="input select"
                                                                       type="text" placeholder="10">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                                          <span>My Collection's signature is: <span style="font-weight: bold"> {{ collectionSignature }}  </span></span>
                                                        </p>

                                                        <p v-if="localModuleExists === false" class="blue-bg is-danger">
                                                          <span style="color:#f9868b">Error: The Signature you have entered is already taken by another artist, please try another.</span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Review Section -->
                                                  <b-step-item :step="6" :clickable="false" label="Review">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Review your new NFT Collection </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Here is a preview of your new <span
                                                                  style="font-weight: bold">collection</span>, does everything look ok?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Please review your <span style="font-weight: bold">collection</span> and whenever you are ready click "Create Collection" to create it.
                                                                <br/><br/>
                                                                Once your collection is created, only the account you created the collection in can mint tokens to the collection.
                                                                <br/><br/>
                                                                After your collection is created, feel free to begin minting NFTs into your collection.
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <div class="column is-centered mb-4 mt-5 center-flex">
                                                          <div class="columns is-multiline is-centered pools-card ">
                                                            <CollectionCardPreview class="column is-3"
                                                                                   style="margin: 10px; width: 316px !important;"
                                                                                   :collection_name="collectionID"
                                                                                   :collection_link="collectionLink"
                                                                                   :collection_signature="collectionSignature"
                                                                                   :collection_author="myaccount"
                                                                                   :collection_supply="'0'"
                                                                                   :collection_token="coinName(make_nft_collectionNamespace, make_nft_collectionModuleName)"
                                                                                   :collection_image="collectionImageLink"
                                                                                   :show_flipper="true"></CollectionCardPreview>
                                                          </div>
                                                        </div>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <div class="columns is-mobile mt-6 mb-1 is-flex-direction-column inset-bg" style="background-color: rgba(0, 0, 0, 0.22) !important;">

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  Collection Creation Fee: 0.01 🥩
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  <input
                                                                      v-model="iagree"
                                                                      class="checkbox"
                                                                      type="checkbox"
                                                                  />
                                                                  I agree to the <a href="#">terms and conditions</a>.
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">

                                                            <p v-if="localPoolIdExists === true"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: This collections signature is already taken, please try another.</span>
                                                            </p>

                                                            <p v-if="iagree === false"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: Please review Terms and conditions.</span>
                                                            </p>

                                                            <button
                                                                v-if="localPoolIdExists === false && iagree === true"
                                                                @click="clickCreateNFTCollection()"
                                                                class="button is-link">Create Collection
                                                            </button>
                                                          </div>

                                                        </div>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Previous Next Buttons-->
                                                  <template
                                                      #navigation="{previous, next}">
                                                    <div class="container create-buttons">
                                                      <div class="columns">
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && lpValid === true && localModuleExists === true"
                                                              outlined
                                                              type="is-danger"
                                                              :disabled="previous.disabled"
                                                              @click.prevent="[previous.action(), doStepBackwards()]">
                                                            Previous
                                                          </b-button>
                                                        </div>
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && lpValid === true && localModuleExists === true"
                                                              outlined
                                                              type="is-success"
                                                              :disabled="next.disabled"
                                                              @click="[next.action(), doStepForward()]">
                                                            Next
                                                          </b-button>
                                                        </div>
                                                      </div>
                                                    </div>

                                                  </template>

                                                </b-steps>

                                              </div>

                                            </ValidationObserver>

                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!-- MAKE SINGLE NFT SECTION -->
                                    <div v-if="makeNftSelection === '2'"
                                         class="column pool-item is-centered is-align-self-center">
                                      <div class="columns">
                                        <div class="column is-align-self-center inset-bg">
                                          <div class="section" style="margin-top: -100px">
                                            <div class="wave__content" style="top: 7px; z-index: 2">
                                              <div class="wave__header">
                                                <div class="wave__menu"></div>
                                              </div>
                                            </div>

                                            <ValidationObserver v-slot="{ valid }">

                                              <div class="box">

                                                <!-- Mint NFT Form -->
                                                <accordion>
                                                  <accordion-item>
                                                    <template slot="accordion-trigger">
                                                      <h1 class="title blue-bg info-icon" style="font-size: 25px; padding-top: 2rem; padding-bottom: 2rem;">Mint a NFT</h1>
                                                    </template>
                                                    <template slot="accordion-content">
                                                      <div class="blue-bg-2 has-text-left">
                                                        <span style="font-weight: bold; font-size: 17px;">What is a NFT?</span>
                                                        <br/><br/>
                                                        <span style="font-weight: bold">NFTs</span> are unique non-fungible-tokens signifying various concepts imposed by their creators.
                                                        <br/><br/>
                                                        All <span style="font-weight: bold">NFTs</span> are apart of a NFT Collection, and you must make a collection <span style="font-weight: bold">first</span> and then mint your NFT's into your NFT Collection.
                                                        <br/><br/>
                                                        <span style="font-weight: bold">NFTs</span> made with Stake Factory tools are all apart of the <span style="font-weight: bold">factory-non-fungible</span> interface - Stake Factory's custom NFT standard.
                                                        <br/><br/>
                                                        <span style="font-weight: bold; font-size: 17px;">How does this tool work?</span>
                                                        <br/><br/>
                                                        This tool will allow you to <span style="font-weight: bold">design and mint a single NFT</span> into an existing NFT collection you <span style="font-weight: bold">currently own</span> by guiding you through a series of NFT design steps.
                                                        <br/><br/>
                                                        Follow <span style="font-weight: bold">all</span> the design steps this tool guides you through to generate a preview of your NFT at the end of the entire design process for you to review- And, if everything looks ok to you, you can then <span style="font-weight: bold">mint</span> your NFT directly into your NFT collection and into your wallet.
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Remember</span>, once an NFT is minted it <span style="font-weight: bold">cannot be edited or updated again</span>.
                                                        <br/><br/>
                                                        There is <span style="font-weight: bold">no</span> STEAK fee to mint NFTs.
                                                        <br/><br/>
                                                      </div>
                                                    </template>
                                                  </accordion-item>
                                                </accordion>

                                                <b-steps v-model="currentStep">

                                                  <!-- About Section -->
                                                  <b-step-item :step="0" :clickable="false" label="About">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4" style="padding-left: 6rem; padding-right: 6rem; background-color: #00000038 !important;">
                                                          <span style="font-weight: bold; font-size: 17px;">What is a Stake Factory NFT?</span>
                                                        </p>

                                                        <div class="column is-centered mb-4 mt-5 center-flex">
                                                          <div class="columns is-multiline is-centered pools-card ">
                                                            <FlipCardMintPreview class="column is-3"
                                                                                 style="margin: 10px; width: 316px !important;"
                                                                                 pool_contract="factory-stake-polyfungiv2"
                                                                                 pool_id="?" pool_name="?" :token_strings="['NFT']" :token_decimals="[1.0]" :token_integers="[1]"
                                                                                 token_image="?" token_collection="?"
                                                                                 pool_reward_token_id="Example NFT" :wlactive="false"
                                                                                 token_name="Example"
                                                                                 token_description="This is an example of a NFT"
                                                                                 pool_reward_token="free.factory-non-fungible"
                                                                                 :show_flipper="true"
                                                                                 stake_balance="0" stake_pending_rewards="0"
                                                                                 stake_rewards="0"></FlipCardMintPreview>
                                                          </div>
                                                        </div>

                                                        <p class="blue-bg font-green mb-4" style="padding-left: 6rem; padding-right: 6rem; background-color: #00000038 !important;">
                                                          <span style="font-weight: bold">NFTs</span> are unique non-fungible-tokens signifying various concepts imposed by their creators.
                                                          <br/><br/>
                                                          All <span style="font-weight: bold">NFTs</span> are apart of a NFT Collection, and you must make a collection <span style="font-weight: bold">first</span> and then mint your NFT's into your NFT Collection.
                                                          <br/><br/>
                                                          <span style="font-weight: bold">NFTs</span> made with Stake Factory tools are all apart of the <span style="font-weight: bold">factory-non-fungible</span> interface - Stake Factory's custom NFT standard.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 17px;">How does this tool work?</span>
                                                          <br/><br/>
                                                          This tool will allow you to <span style="font-weight: bold">design and mint a single NFT</span> into an existing NFT collection you <span style="font-weight: bold">currently own</span> by guiding you through a series of deisgn steps, such as helping you fill out your NFTs metadata as well as other characteristics of your NFT.
                                                          <br/><br/>
                                                          Follow <span style="font-weight: bold">all</span> the design steps this tool guides you through to generate a preview of your NFT at the end of the entire design process, and if everything looks ok to you, you can then <span style="font-weight: bold">mint</span> your NFT directly into your NFT collection.
                                                          <br/><br/>
                                                          <span style="font-weight: bold">Remember</span>, once an NFT is minted it <span style="font-weight: bold">cannot be edited or updated again</span> - Make sure all the characteristics for your NFT are ready for the blockchain and designed to last for your audience before minting your NFTs.
                                                          <br/><br/>
                                                          There is <span style="font-weight: bold">no</span> STEAK fee to mint NFTs.
                                                        </p>




                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <b-switch
                                                            class="mb-5 mt-1"
                                                            v-model="localModuleExists"
                                                            :rounded="true"
                                                            :outlined="true"
                                                            size="is-large"
                                                            type="is-success"
                                                            :left-label='false'
                                                            :passive-type='null'>{{ localModuleExists ? "OK" : "OK" }}
                                                        </b-switch>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- collection id section -->
                                                  <b-step-item :step="1" :clickable="false" label="Collection">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Create collection Section -->
                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> NFT Collection ID </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">Collection ID</span>
                                                                this NFT will be minted into?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                All NFTs are part of a <span style="font-weight: bold">collection</span>.
                                                                <br/><br/>
                                                                Enter the <span style="font-weight: bold">Collection ID</span> of the NFT collection you will mint this NFT into.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">Remember</span> you must be the creator of the collection in order to mint an NFT into that collection - You <span style="font-weight: bold">cannot</span> mint NFTs into someone elses NFT collection.
                                                                <br/><br/>
                                                                If you have not yet created a collection, <span style="font-weight: bold">create a collection first</span> with the NFT Collection Tool, and then mint your NFTs into your collection using this tool.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <div class="columns is-flex is-justify-content-center">

                                                          <div class="column is-6"  style="display: flex !important; justify-content: center !important; flex-direction: column !important;">
                                                            <b-field
                                                                style="margin-top: 20px;"
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <template #label>
                                                                <b-tooltip type="is-dark" multilined
                                                                           label="What is the ID of the NFT Collection you are minting this NFT into?">
                                                                  <label class="label pt-1">NFT Collection ID:</label>
                                                                </b-tooltip>
                                                              </template>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <input v-model="makenft_collectionID"
                                                                           @change="[determineCollectionIDMint(), verifyCollectionExists(makenft_collectionID), getCollectionDetails(makenft_collectionID)]"
                                                                           @input="[determineCollectionIDMint(), verifyCollectionExists(makenft_collectionID), getCollectionDetails(makenft_collectionID)]"
                                                                           class="input select"
                                                                           type="text" placeholder="my_nft_collection_id">
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>
                                                          </div>

                                                          <div class="column is-3 is-flex is-flex-direction-column is-justify-content-center" style="display: flex !important; justify-content: center !important; flex-direction: column !important;">
                                                            <CollectionCardPreview style="margin: 10px; width: 316px !important; display: flex !important; justify-content: center !important; flex-direction: column !important;"
                                                                                   :collection_name="whitelist_collectionData['collection-id'] ? whitelist_collectionData['collection-id'] : '?'"
                                                                                   :collection_link="whitelist_collectionData.link ? whitelist_collectionData.link : '?'"
                                                                                   :collection_signature="whitelist_collectionData.signature ? whitelist_collectionData.signature : '?'"
                                                                                   :collection_author="whitelist_collectionData.creator ? whitelist_collectionData.creator : '?'"
                                                                                   :collection_supply="'0'"
                                                                                   collection_token="?"
                                                                                   :collection_image="whitelist_collectionData.image ? whitelist_collectionData.image : '?'"
                                                                                   :show_flipper="false"></CollectionCardPreview>
                                                          </div>

                                                        </div>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                        <p v-if="localModuleExists === true || localModuleExists === null"
                                                           class="blue-bg font-green mb-4 mt-2">
                                              <span>This NFT will be minted into the collection: <span
                                                  style="font-weight: bold"> {{
                                                  makenft_collectionID
                                                }}</span></span>
                                                        </p>

                                                        <p v-if="localModuleExists === false" class="blue-bg is-danger">
                                                          <span style="color:#f9868b">Error: The Collection ID you have entered was not found, please try another.</span>
                                                        </p>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  nft id Section -->
                                                  <b-step-item :step="2" :clickable="false" label="NFT ID">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> NFT ID </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is this NFT's unique <span
                                                                  style="font-weight: bold">ID</span>?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Every NFT has a <span style="font-weight: bold">unique ID</span> which identifies it on the Kadena Blockchain.
                                                                <br/>
                                                                <br/>
                                                                Using this tool, type your NFT's <span style="font-weight: bold">unique ID</span> below - This tool will automatically notify you if your NFT ID is not unique so that you may change it.
                                                                <br/>
                                                                <br/>
                                                                After entering a <span style="font-weight: bold">unique ID</span> for your NFT, click "<span style="font-weight: bold">Next</span>" to proceed.
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <b-field
                                                            style="margin-top: 20px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="Does this collection have a web page? Enter it here.">
                                                              <label class="label pt-1 mt-2" style="width:50px;">NFT ID:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <input v-model="makenft_nftID"
                                                                       @change="[determineNFTIDMint(), verifyNFTIDExists(makenft_nftID)]"
                                                                       @input="[determineNFTIDMint(), verifyNFTIDExists(makenft_nftID)]"
                                                                       class="input select"
                                                                       type="text" placeholder="mynftid">
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p v-if="localModuleExists === true" class="blue-bg font-green mb-4">
                                              <span>My NFT's ID is: <span style="font-weight: bold"> {{ makenft_nftID }}  </span>
                                              <br/>
                                                My NFT's Collection is: <span style="font-weight: bold"> {{ makenft_collectionID }}  </span></span>
                                                        </p>

                                                        <p v-if="localModuleExists === false" class="blue-bg is-danger">
                                                          <span style="color:#f9868b">Error: The NFT ID you have entered is already taken, please try another.</span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Pool Meta 1 Section -->
                                                  <b-step-item :step="3" :clickable="false" label="Metadata 1">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> NFT Metadata </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is this NFT's <span
                                                                  style="font-weight: bold">metadata</span>?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                What is the <span style="font-weight: bold">metadata</span> for this NFT?
                                                                <br/><br/>
                                                                <span style="font-weight: bold">NFT Metadata</span> contains <span style="font-weight: bold">characteristics</span> that define your NFT's concept and is <span style="font-weight: bold">displayed</span> on your NFT's card wherever your NFT is displayed.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">Metadata</span> can be anything you like and contains <span style="font-weight: bold">characteristics</span> about the NFT you are creating, such as it's <span style="font-weight: bold">name</span>, <span style="font-weight: bold">description</span>, and <span style="font-weight: bold">image</span>.
                                                                <br/><br/>
                                                                The Metadata found on this screen are all <span style="font-weight: bold">required</span>, please enter the following pieces of information about your NFT:
                                                                <br/><br/>
                                                                + <span style="font-weight: bold">NFT Name</span>: The name of your NFT - NFT names must be between <span style="font-weight: bold">3 and 30 characters long</span>, ex: The Awesome NFT
                                                                <br/><br/>
                                                                + <span style="font-weight: bold">NFT Description</span>: The description of your NFT - Descriptions must be between <span style="font-weight: bold">3 and 256 characters long</span>, ex: The Awesome NFT of Awesomeness +10
                                                                <br/><br/>
                                                                + <span style="font-weight: bold">NFT Image</span>: Enter a link to your NFT's image - Links to images must be between <span style="font-weight: bold">3 and 256 characters long</span>, ex: https://www.linktomyimage.com
                                                                <br/><br/>
                                                                Once everything looks good to you on the NFT preview card, click "Next" to proceed to the next step.
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <div class="columns">
                                                          <div class="column is-8 mt-4">

                                                            <b-field
                                                                style="margin-top: 20px;"
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <template #label>
                                                                <b-tooltip type="is-dark" multilined
                                                                           label="What is the name of this NFT? Must be between 3 and 30 characters in length.">
                                                                  <label class="label" style="width:100px;">NFT Name:</label>
                                                                </b-tooltip>
                                                              </template>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <b-field type="is-info">
                                                                      <ValidationProvider
                                                                          :rules="{required: true, chargreaterthan: 3.0, charlessthan: 30.0}"
                                                                          vid="makenft_name" name="makenft name"
                                                                          v-slot="{ errors, valid }">
                                                                        <input v-model="makenft_name"
                                                                               @change="[determineNFTName(), checkValidInput(valid)]"
                                                                               @input="[determineNFTName(), checkValidInput(valid)]"
                                                                               class="input select"
                                                                               type="text" placeholder="My Awesome NFT">
                                                                        <span class="error is-danger validation-msg">{{
                                                                            errors[0]
                                                                          }}</span>
                                                                        <span v-if="!errors[0]"
                                                                              class="error validation-msg-green is-info">Enter the Name you would like to give this NFT</span>
                                                                      </ValidationProvider>
                                                                    </b-field>
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>


                                                            <b-field
                                                                style="margin-top: 10px;"
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <template #label>
                                                                <b-tooltip type="is-dark" multilined
                                                                           label="What is this NFT's description?">
                                                                  <label class="label" style="width:100px;">NFT
                                                                    Description:</label>
                                                                </b-tooltip>
                                                              </template>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <b-field type="is-info">
                                                                      <ValidationProvider
                                                                          :rules="{required: true, chargreaterthan: 3.0, charlessthan: 256.0}"
                                                                          vid="makenft_description" name="makenft description"
                                                                          v-slot="{ errors, valid }">
                                                                        <textarea v-model="makenft_description"
                                                                                  style="height:100px;"
                                                                               @change="[determineNFTDescription(), checkValidInput(valid)]"
                                                                               @input="[determineNFTDescription(), checkValidInput(valid)]"
                                                                               class="input select"
                                                                               type="text" placeholder="The NFT of Awesomeness +10"/>
                                                                        <span class="error is-danger validation-msg">{{
                                                                            errors[0]
                                                                          }}</span>
                                                                        <span v-if="!errors[0]"
                                                                              class="error validation-msg-green is-info">Enter the Description you would like to give this NFT</span>
                                                                      </ValidationProvider>
                                                                    </b-field>
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>

                                                            <b-field
                                                                style="margin-top: 10px;"
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <template #label>
                                                                <b-tooltip type="is-dark" multilined
                                                                           label="Enter link to your NFT's image">
                                                                  <label class="label pt-1" style="width:50px;">NFT
                                                                    Image:</label>
                                                                </b-tooltip>
                                                              </template>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <b-field type="is-info">
                                                                      <ValidationProvider
                                                                          :rules="{required: true, chargreaterthan: 3.0, charlessthan: 256.0}"
                                                                          vid="makenft_image" name="makenft image"
                                                                          v-slot="{ errors, valid }">
                                                                        <input v-model="makenft_image"
                                                                               @change="[determineNFTName(), checkValidInput(valid)]"
                                                                               @input="[determineNFTImage(), checkValidInput(valid)]"
                                                                               class="input select"
                                                                               type="text" placeholder="https://www.link_to_my_image.com">
                                                                        <span class="error is-danger validation-msg">{{
                                                                            errors[0]
                                                                          }}</span>
                                                                        <span v-if="!errors[0]"
                                                                              class="error validation-msg-green is-info">Enter the URL to this NFT's image</span>
                                                                      </ValidationProvider>
                                                                    </b-field>
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>
                                                          </div>
                                                          <div class="column is-centered mb-4 mt-4">
                                                            <div class="columns is-centered is-flex-direction-column pools-card">
                                                              <FlipCardMintPreview class="column is-3"
                                                                                   style="margin: 10px; width: 316px !important;"
                                                                                   pool_contract="factory-stake-polyfungiv2"
                                                                                   pool_id="?" pool_name="?" :token_strings="makenft_a1_final" :token_decimals="makenft_a2" :token_integers="makenft_a3"
                                                                                   :token_image="makenft_image" :token_collection="makenft_collectionID"
                                                                                   :pool_reward_token_id="makenft_nftID"
                                                                                   :wlactive="false"
                                                                                   :token_name="makenft_name"
                                                                                   :token_description="makenft_description"
                                                                                   pool_reward_token="free.factory-non-fungible"
                                                                                   :show_flipper="true"
                                                                                   stake_balance="0" stake_pending_rewards="0"
                                                                                   stake_rewards="0"></FlipCardMintPreview>
                                                              <br/>
                                                              <div class="column is-9 is-justify-content-center">
                                                                <span style="font-size: 12px">(current NFT preview)</span>
                                                              </div>

                                                            </div>
                                                          </div>
                                                        </div>



                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Pool Meta 2 Section -->
                                                  <b-step-item :step="4" :clickable="false" label="Metadata 2">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> NFT Metadata 2</span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Do you want to add any custom <span
                                                                  style="font-weight: bold">metadata attributes</span> to your NFT?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                Would you like to enter any more <span style="font-weight: bold">custom metadata</span> for this NFT? (Optional)
                                                                <br/><br/>
                                                                Custom Metadata are custom and optional pieces of data you can attach to your NFT in specific groups which appear in your NFTs metadata - Flip over the NFT preview card to preview how the data will appear on your NFT.
                                                                <br/><br/>
                                                                Below you will find 3 fields for you to type any custom Metadata you may want to include in your NFT, please note you <span style="font-weight: bold">must type the correct type of data in the correct field</span>.
                                                                <br/><br/>
                                                                Custom Metadata must be entered in the correct format listed in the fields title - For example, only <span style="font-weight: bold">String data should be typed in the "String Attributes" field</span>, and <span style="font-weight: bold">decimal type data in the "Decimal Attributes" field</span>.
                                                                <br/><br/>
                                                                Please enter each piece of custom data in the fields below, <span style="font-weight: bold">1 per line</span> (see the default placeholder examples in the input fields below), with up to 10 pieces of data per field.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">String Attribute Example</span>: This is a string
                                                                <br/><br/>
                                                                <span style="font-weight: bold">Decimal Attribute Example</span>: 4.3
                                                                <br/><br/>
                                                                <span style="font-weight: bold">Integer Attribute Example</span>: 384
                                                                <br/><br/>
                                                                Custom Metadata is optional, however, if your NFT has custom metadata please make sure you type the <span style="font-weight: bold">correct pieces of data in the correct fields</span> as shown above,<span style="font-weight: bold"> 1 per line</span>, and <span style="font-weight: bold">up to 10 pieces of data per field maximum</span>.
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <div class="columns">
                                                          <div class="column is-8 mt-5">
                                                            <b-field
                                                                style="margin-top: 20px;"
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <template #label>
                                                                <b-tooltip type="is-dark" multilined
                                                                           label="Enter only custom String type attributes for this NFT">
                                                                  <label class="label" style="width:100px;">String
                                                                    Attributes:</label>
                                                                </b-tooltip>
                                                              </template>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <b-field type="is-info">
                                                                  <textarea v-model="makenft_a1"
                                                                            @input="makea1list(makenft_a1)"
                                                                            style="height:100px;"
                                                                            class="input select"
                                                                            type="textarea"
                                                                            :placeholder="makenft_a1_placeholder"/>

                                                                    </b-field>
                                                                    <span v-if="!errors[0]"
                                                                          class="error validation-msg-green is-info">Enter any custom NFT string type attributes - One per line.</span>
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>

                                                            <b-field
                                                                style="margin-top: 10px;"
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <template #label>
                                                                <b-tooltip type="is-dark" multilined
                                                                           label="Enter only custom Decimal type attributes for this NFT">
                                                                  <label class="label" style="width:100px;">Decimal
                                                                    Attributes:</label>
                                                                </b-tooltip>
                                                              </template>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <b-field type="is-info">
                                                                  <textarea v-model="makenft_a2"
                                                                            @input="makea2list(makenft_a2)"
                                                                            style="height:100px;"
                                                                            class="input select"
                                                                            type="textarea"
                                                                            :placeholder="makenft_a2_placeholder"/>

                                                                    </b-field>
                                                                    <span v-if="!errors[0]"
                                                                          class="error validation-msg-green is-info">Enter any NFT custom decimal type attributes - One per line.</span>
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>

                                                            <b-field
                                                                style="margin-top: 10px;"
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <template #label>
                                                                <b-tooltip type="is-dark" multilined
                                                                           label="Enter only custom Integer type attributes for this NFT">
                                                                  <label class="label" style="width:100px;">Integer
                                                                    Attributes:</label>
                                                                </b-tooltip>
                                                              </template>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <b-field type="is-info">
                                                                  <textarea v-model="makenft_a3"
                                                                            @input="makea3list(makenft_a3)"
                                                                            style="height:100px;"
                                                                            class="input select"
                                                                            type="textarea"
                                                                            :placeholder="makenft_a3_placeholder"/>

                                                                    </b-field>
                                                                    <span v-if="!errors[0]"
                                                                          class="error validation-msg-green is-info">Enter any custom integer type attributes - One per line.</span>
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>
                                                          </div>
                                                          <div class="column is-centered mb-4 mt-5">
                                                            <div class="columns is-centered pools-card">
                                                              <FlipCardMintPreview class="column is-3"
                                                                               style="margin: 10px; width: 316px !important;"
                                                                               pool_contract="factory-stake-polyfungiv2"
                                                                               pool_id="?" pool_name="?" :token_strings="makenft_a1_final" :token_decimals="makenft_a2" :token_integers="makenft_a3"
                                                                               :token_image="makenft_image" :token_collection="makenft_collectionID"
                                                                               :pool_reward_token_id="makenft_nftID" :wlactive="false"
                                                                               :token_name="makenft_name"
                                                                               :token_description="makenft_description"
                                                                               pool_reward_token="free.factory-non-fungible"
                                                                               :show_flipper="true"
                                                                               stake_balance="0" stake_pending_rewards="0"
                                                                               stake_rewards="0"></FlipCardMintPreview>
                                                            </div>
                                                          </div>
                                                        </div>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <div class="columns is-mobile mt-6 mb-1 is-flex-direction-column inset-bg" style="background-color: rgba(0, 0, 0, 0.22) !important;">

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  NFT Creation Fee: FREE!
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  <input
                                                                      v-model="iagree"
                                                                      class="checkbox"
                                                                      type="checkbox"
                                                                  />
                                                                  I agree to the <a href="#">terms and conditions</a>.
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">

                                                            <p v-if="localPoolIdExists === true"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: This collections signature is already taken, please try another.</span>
                                                            </p>

                                                            <p v-if="iagree === false"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: Please review Terms and conditions.</span>
                                                            </p>

                                                            <button
                                                                v-if="localPoolIdExists === false && iagree === true"
                                                                @click="clickCreateNFT()"
                                                                class="button is-link">Create NFT
                                                            </button>
                                                          </div>

                                                        </div>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Previous Next Buttons-->
                                                  <template
                                                      #navigation="{previous, next}">
                                                    <div class="container create-buttons">
                                                      <div class="columns">
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && lpValid === true && localModuleExists === true"
                                                              outlined
                                                              type="is-danger"
                                                              :disabled="previous.disabled"
                                                              @click.prevent="[previous.action(), doStepBackwards()]">
                                                            Previous
                                                          </b-button>
                                                        </div>
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && lpValid === true && localModuleExists === true"
                                                              outlined
                                                              type="is-success"
                                                              :disabled="next.disabled"
                                                              @click="[next.action(), doStepForward()]">
                                                            Next
                                                          </b-button>
                                                        </div>
                                                      </div>
                                                    </div>

                                                  </template>

                                                </b-steps>

                                              </div>

                                            </ValidationObserver>

                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!-- MAKE MULTIPLE NFTS SECTION -->
                                    <div v-if="makeNftSelection === '3'"
                                         class="column pool-item is-centered is-align-self-center">
                                      <div class="columns">
                                        <div class="column is-align-self-center inset-bg">
                                          <div class="section" style="margin-top: -100px">
                                            <div class="wave__content" style="top: 7px; z-index: 2">
                                              <div class="wave__header">
                                                <div class="wave__menu"></div>
                                              </div>
                                            </div>

                                            <ValidationObserver v-slot="{ valid }">

                                              <div class="box">

                                                <!-- Mint NFT Form -->
                                                <accordion>
                                                  <accordion-item>
                                                    <template slot="accordion-trigger">
                                                      <h1 class="title blue-bg info-icon" style="font-size: 25px; padding-top: 2rem; padding-bottom: 2rem;">Mint Multiple NFTs</h1>
                                                    </template>
                                                    <template slot="accordion-content">
                                                      <div class="blue-bg-2">
                                                        Use the NFT Mass Minter Tool to <span style="font-weight: bold">mint multiple NFTs at once</span>.
                                                        <br/><br/>
                                                        NFTs can be pasted in the proper {format} one per line and up to <span style="font-weight: bold">25 NFTs can be minted at once</span>.
                                                        <br/><br/>
                                                        There is <span style="font-weight: bold">no</span> STAKE fee to mint NFTs.
                                                      </div>
                                                    </template>
                                                  </accordion-item>
                                                </accordion>

                                                <b-steps v-model="currentStep">


                                                  <!-- About Section -->
                                                  <b-step-item :step="0" :clickable="false" label="About">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4" style="padding-left: 6rem; padding-right: 6rem; background-color: #00000038 !important;">
                                                          <span style="font-weight: bold; font-size: 17px;">What is a Stake Factory NFT?</span>
                                                        </p>

                                                        <div class="column is-centered mb-4 mt-5 center-flex">
                                                          <div class="columns is-multiline is-centered pools-card ">
                                                            <FlipCardMintPreview class="column is-3"
                                                                                 style="margin: 10px; width: 316px !important;"
                                                                                 pool_contract="factory-stake-polyfungiv2"
                                                                                 pool_id="?" pool_name="?" :token_strings="['NFT']" :token_decimals="[1.0]" :token_integers="[1]"
                                                                                 token_image="?" token_collection="?"
                                                                                 pool_reward_token_id="Example NFT" :wlactive="false"
                                                                                 token_name="Example"
                                                                                 token_description="This is an example of a NFT"
                                                                                 pool_reward_token="free.factory-non-fungible"
                                                                                 :show_flipper="true"
                                                                                 stake_balance="0" stake_pending_rewards="0"
                                                                                 stake_rewards="0"></FlipCardMintPreview>
                                                          </div>
                                                        </div>

                                                        <p class="blue-bg font-green mb-4" style="padding-left: 6rem; padding-right: 6rem; background-color: #00000038 !important;">
                                                          <span style="font-weight: bold">NFTs</span> are unique non-fungible-tokens signifying various concepts imposed by their creators.
                                                          <br/><br/>
                                                          All <span style="font-weight: bold">NFTs</span> are apart of a NFT Collection, and you must make a collection <span style="font-weight: bold">first</span> and then mint your NFT's into your NFT Collection.
                                                          <br/><br/>
                                                          <span style="font-weight: bold">NFTs</span> made with Stake Factory tools are all apart of the <span style="font-weight: bold">factory-non-fungible</span> interface - Stake Factory's custom NFT standard.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 17px;">How does this tool work?</span>
                                                          <br/><br/>
                                                          This tool will allow you to <span style="font-weight: bold">design and mint multiple NFTs</span> into an existing NFT collection you <span style="font-weight: bold">currently own</span>.
                                                          <br/><br/>
                                                          NFTs must be pasted into the mass minter tool in the proper JSON {format}, one NFT per line- Up to <span style="font-weight: bold">25 NFTs can be minted at once</span>.
                                                          <br/><br/>
                                                          After pasting a NFT in the correct format into the mass minter make sure it appears properly on your screen. A NFT that is in the proper format will appear on your screen after it is pasted into the minting tool - Please review all your NFT's and their metadata as they pop up on your screen.
                                                          <br/><br/>
                                                          <span style="font-weight: bold">Remember</span>, once an NFT is minted it <span style="font-weight: bold">cannot be edited or updated again</span> - Make sure your NFT's are correctly formatted and appear on your screen correctly to avoid transaction errors.
                                                          <br/><br/>
                                                          There is <span style="font-weight: bold">no</span> STEAK fee to mint NFTs.
                                                        </p>




                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <b-switch
                                                            class="mb-5 mt-1"
                                                            v-model="localModuleExists"
                                                            :rounded="true"
                                                            :outlined="true"
                                                            size="is-large"
                                                            type="is-success"
                                                            :left-label='false'
                                                            :passive-type='null'>{{ localModuleExists ? "OK" : "OK" }}
                                                        </b-switch>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                      </div>

                                                    </section>

                                                  </b-step-item>
                                                  <!-- collection id section -->
                                                  <b-step-item :step="1" :clickable="false" label="NFT Collection">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Create collection Section -->
                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Collection ID </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">Collection ID</span>
                                                                to Mass Mint your NFTs into?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                All NFTs are part of a <span style="font-weight: bold">NFT Collection</span>.
                                                                <br/><br/>
                                                                Enter the <span style="font-weight: bold">Collection ID</span> of the <span style="font-weight: bold">NFT Collection</span> you will <span style="font-weight: bold">mass mint</span> your NFTs into.
                                                                <br/><br/>
                                                                Remember, <span style="font-weight: bold">you must be the creator of the collection in order to mint an NFT into that collection</span>.
                                                                <br/><br/>
                                                                If you have not yet created a collection, create a collection first using the NFT Collection Creation Tool, and then you can mint your NFTs into your NFT Collection using this tool.
                                                                <br/><br/>
                                                                For a more user-friendly NFT design process, we recommend first creating an NFT with the Single Mint NFT Tool, which will guide you through a step by step process in designing individual NFTs on Stake Factory.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <div class="columns is-flex is-justify-content-center">

                                                          <div class="column is-6"  style="display: flex !important; justify-content: center !important; flex-direction: column !important;">
                                                            <b-field
                                                                style="margin-top: 20px;"
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <template #label>
                                                                <b-tooltip type="is-dark" multilined
                                                                           label="What is the ID of the NFT Collection you are minting NFTs into?">
                                                                  <label class="label pt-1">NFT Collection ID:</label>
                                                                </b-tooltip>
                                                              </template>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <input v-model="makenft_collectionID"
                                                                           @change="[determineCollectionIDMint(), verifyCollectionExists(makenft_collectionID), getCollectionDetails(makenft_collectionID)]"
                                                                           @input="[determineCollectionIDMint(), verifyCollectionExists(makenft_collectionID), getCollectionDetails(makenft_collectionID)]"
                                                                           class="input select"
                                                                           type="text" placeholder="my_nft_collection_id">
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>
                                                          </div>

                                                          <div class="column is-3 is-flex is-flex-direction-column is-justify-content-center" style="display: flex !important; justify-content: center !important; flex-direction: column !important;">
                                                            <CollectionCardPreview style="margin: 10px; width: 316px !important; display: flex !important; justify-content: center !important; flex-direction: column !important;"
                                                                                   :collection_name="whitelist_collectionData['collection-id'] ? whitelist_collectionData['collection-id'] : '?'"
                                                                                   :collection_link="whitelist_collectionData.link ? whitelist_collectionData.link : '?'"
                                                                                   :collection_signature="whitelist_collectionData.signature ? whitelist_collectionData.signature : '?'"
                                                                                   :collection_author="whitelist_collectionData.creator ? whitelist_collectionData.creator : '?'"
                                                                                   :collection_supply="'0'"
                                                                                   collection_token="?"
                                                                                   :collection_image="whitelist_collectionData.image ? whitelist_collectionData.image : '?'"
                                                                                   :show_flipper="false"></CollectionCardPreview>
                                                          </div>

                                                        </div>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                        <p v-if="localModuleExists === true"
                                                           class="blue-bg font-green mb-4 mt-2">
                                              <span>All NFTs will be minted into the collection: <span
                                                  style="font-weight: bold"> {{
                                                  makenft_collectionID
                                                }}</span></span>
                                                        </p>

                                                        <p v-if="localModuleExists === false" class="blue-bg is-danger">
                                                          <span style="color:#f9868b">Error: The Collection ID you have entered was not found, please try another.</span>
                                                        </p>

                                                      </div>

                                                    </section>

                                                  </b-step-item>
                                                  <!-- mass mint section -->
                                                  <b-step-item :step="2" :clickable="false" label="Mass Mint">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Create collection Section -->
                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold; font-size: 20px;"> Mass Mint </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Paste up to <span
                                                                  style="font-weight: bold">25</span>
                                                                NFTs (see format)</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2" >
                                                                The Stake Factory Mass Minter is for users who want to mint multiple NFT's and who are willing to format their NFT's the proper way and paste them line-by-line in the box below.
                                                                <br/><br/>
                                                                Use these docs and the example {NFT} formatted in the Mass Minter to understand how the tool works.
                                                                <br/><br/>
                                                                As you edit the example code, and copy and paste more {NFT}'s, if they are propery formatted they appear as demo NFT cards for you to review and mass mint.
                                                                <br/><br/>
                                                                If you have never used the mass minter tool before, it is advised to check out the single NFT minter tool for a friendly NFT minting experience.
                                                                <br/><br/>
                                                                The NFT Mass Minter can mint up to 100 NFTs at a time, allowing you to mint a collection within a day if it is properly formatted.
                                                                <br/><br/>
                                                                Paste up to 100 NFTs in the box below, separating each NFT by a new line - Please see the example below.
                                                                <br/><br/>
                                                                NFT's must be pasted 1 per line in the proper {NFT} format:
                                                                <br/><br/>
                                                                Example:
                                                                <br/><br/>
                                                                <span style="background-color: transparent !important; color: #4ae39e; font-family: Source Code Pro, monospace;">{"id" : "my_nft_id","collectionid": "my_collection_id","name": "My NFT's Name","description": "The description of my nft","image": "https://emojipedia-us.s3.amazonaws.com/source/skype/289/cut-of-meat_1f969.png","a1": ["my attribute 1", "my attribute 2", "my attribute 3"],"a2": [1.0, 2.2, 33.34],"a3": [0, 349, 999]}</span>
                                                                <br/><br/>
                                                                All NFT's can be created in this {NFT} JSON format and pasted line by line- Please review your NFTs as they pop up to ensure your formatting is correct.
                                                                <br/><br/>
                                                                Here is a line-by-line breakdown of each piece of the a {NFT} object:
                                                                <br/><br/>
<pre style="position: absolute; top: 480px; left: -80px; background-color: transparent !important; color: #4ae39e; font-family: Source Code Pro, monospace;"><code>{
                    "id" : "my_nft_id"
                                     "collectionid": "my_collection_id",
                          "name": "My NFT's Name",
                                             "description": "The description of my nft",
                                                                                           "image": "https://emojipedia-us.s3.amazonaws.com/source/skype/289/cut-of-meat_1f969.png",
                                                               "a1": ["my attribute 1", "my attribute 2", "my attribute 3"],
                          "a2": [1.0, 2.2, 33.34],
                     "a3": [0, 349, 999]
}</code></pre>
                                                                <br/><br/>
                                                                <div class="has-text-left" style="margin-top: 140px; margin-left: 450px">
                                                                  NFT Properties:
                                                                <br/><br/>
                                                                  id:
                                                                  <br/>+ The unique ID of your NFT (Your ID must not exist)
                                                                  <br/>+ Enter NFT ID's in "String" format (inside quotes)
                                                                  <br/>+ NFT ID's should contain no spaces
                                                                  <br/>+ Example: "my_nft_id"
                                                                  <br/><Br/>
                                                                  collectionid:
                                                                  <br/>+ The unique ID of the NFT's collection
                                                                  <br/>+ You must be the creator of collection to mint into a collection
                                                                  <br/>+ Enter the NFT's Collection ID in "String" format (inside quotes)
                                                                  <br/>+ Collection ID's should contain no spaces
                                                                  <br/>+ Example: "my_collection_id"
                                                                  <br/><Br/>
                                                                  name:
                                                                  <br/>+ The name of your NFT
                                                                  <br/>+ NFT Names can be anything you like and may contain spaces
                                                                  <br/>+ Example: "My NFT's Name"
                                                                  <br/><Br/>
                                                                  description:
                                                                  <br/>+ The description of your NFT
                                                                  <br/>+ NFT Descriptions can be anything you like
                                                                  <br/>+ Example: "The description of my nft"
                                                                  <br/><br/>
                                                                  image:
                                                                  <br/>+ The link to your NFT's image
                                                                  <br/>+ Example: "https://emojipedia-us.s3.amazonaws.com/source/skype/289/cut-of-meat_1f969.png"
                                                                  <br/><br/>
                                                                  a1:
                                                                  <br/>+ The "a1" property contains any custom NFT String metadata attributes
                                                                  <br/>+ Must be "String" type's of metadata inside a single [array], ie: ["String", "String", "String"]
                                                                  <br/>+ Example: ["my attribute 1", "my attribute 2", "my attribute 3"]
                                                                  <br/><br/>
                                                                  a2:
                                                                  <br/>+ The "a2" property contains any custom NFT Decimal metadata attributes
                                                                  <br/>+ Must be proper Decimal type's of metadata (contain a decimal) inside a single [array], ie: [Decimal, Decimal, Decimal]
                                                                  <br/>+ Example: [1.0, 2.2, 33.34]
                                                                  <br/><br/>
                                                                  a3:
                                                                  <br/>+ The "a3" property contains any custom NFT Integer metadata attributes
                                                                  <br/>+ Must be Integer type's of metadata inside a single [array], ie: [Integer, Integer, Integer]
                                                                  <br/>+ Example: [0, 349, 999]
                                                                  <br/><br/>
                                                                </div>

                                                                Try copying the entire {NFT} code example in the box below, press Enter to make a new line, and paste a copy of the same {NFT} code on the new line to understand how the tool works.
                                                                <br/><br/>
                                                                If your NFT doesn't pop up when you paste a new {NFT} in the box below, then it is incorrectly formatted and you should review the NFT you added to your mint list and fix it's code - Always check and preview if your NFTs pop up after you add their code to ensure proper formatting and successful mass mint transactions.

                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>
                                                        <div class="column mt-4 is-12 is-flex is-flex-direction-column is-justify-content-center">
                                                          <b-field custom-class="is-small" horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What is this NFT's description?">
                                                                <label class="label pt-3" style="width:100px;">Paste {NFT}'s (One Per Line):</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">

                                                                <p class="control is-expanded">
                                                                  <b-input v-model="makenft_massNfts"
                                                                           expanded
                                                                           @change="makeMassMintNFTs(makenft_massNfts)"
                                                                           @input="makeMassMintNFTs(makenft_massNfts)" maxlength="7925" style="background-color: rgba(103, 103, 108, 0.1) !important; border: none; color: #4ae39e; box-shadow: inset 0 30px 60px -12px rgb(50 50 93 / 25%), inset 0 18px 36px -18px rgb(0 0 0 / 30%);" type="textarea"></b-input>
                                                                  <span v-if="!errors[0]"
                                                                        class="error validation-msg-green is-info">Paste 1 {NFT} Per Line (See proper format!)</span>
                                                                </p>


                                                              </div>
                                                            </div>
                                                          </b-field>
                                                          </div>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <div style="border: 1px solid #3abb81; color: #3abb81; padding: 2rem;" v-if="makenft_massNftsFinalJSON.length > 0">{{makenft_massNftsFinalJSON.length}} NFT's to Mint:</div>

                                                        <div v-if="makenft_massNftsFinalJSON.length > 0" class="column is-centered mb-4 mt-5 center-flex">
                                                          <div class="columns is-multiline is-centered pools-card ">
                                                            <FlipCardMintPreview class="column is-3"
                                                                                 v-for="p in makenft_massNftsFinalJSON" :key="p.id"
                                                                                 style="margin: 10px; width: 316px !important;"
                                                                                 pool_contract="factory-stake-polyfungiv2"
                                                                                 pool_id="?" pool_name="?" :token_strings="p.a1" :token_decimals="p.a2" :token_integers="p.a3"
                                                                                 :token_image="p.image" :token_collection="p['collection-id']"
                                                                                 :pool_reward_token_id="p.id"
                                                                                 :wlactive="false"
                                                                                 :idexists="p.idexists"
                                                                                 :token_name="p.name"
                                                                                 :token_description="p.description"
                                                                                 pool_reward_token="free.factory-non-fungible"
                                                                                 :show_flipper="true"
                                                                                 stake_balance="0" stake_pending_rewards="0"
                                                                                 stake_rewards="0"></FlipCardMintPreview>
                                                          </div>
                                                        </div>

                                                        <br v-if="makenft_massNftsFinalJSON.length > 0" />
                                                        <div v-if="makenft_massNftsFinalJSON.length > 0" class="divider is-centered"></div>
                                                        <br v-if="makenft_massNftsFinalJSON.length > 0" />

                                                        <div class="columns is-mobile mt-6 mb-1 is-flex-direction-column inset-bg" style="background-color: rgba(0, 0, 0, 0.22) !important;">

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  NFT Creation Fee: FREE!
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  <input
                                                                      v-model="iagree"
                                                                      class="checkbox"
                                                                      type="checkbox"
                                                                  />
                                                                  I agree to the <a href="#">terms and conditions</a>.
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">

                                                            <p v-if="localPoolIdExists === true"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: This collections signature is already taken, please try another.</span>
                                                            </p>

                                                            <p v-if="iagree === false"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: Please review Terms and conditions.</span>
                                                            </p>

                                                            <button
                                                                v-if="localPoolIdExists === false && iagree === true"
                                                                @click="clickMassMintNFTs()"
                                                                class="button is-link">Mass Mint NFTs
                                                            </button>
                                                          </div>

                                                        </div>



                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Previous Next Buttons-->
                                                  <template
                                                      #navigation="{previous, next}">
                                                    <div class="container create-buttons">
                                                      <div class="columns">
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && lpValid === true && localModuleExists === true"
                                                              outlined
                                                              type="is-danger"
                                                              :disabled="previous.disabled"
                                                              @click.prevent="[previous.action(), doStepBackwards()]">
                                                            Previous
                                                          </b-button>
                                                        </div>
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && lpValid === true && localModuleExists === true"
                                                              outlined
                                                              type="is-success"
                                                              :disabled="next.disabled"
                                                              @click="[next.action(), doStepForward()]">
                                                            Next
                                                          </b-button>
                                                        </div>
                                                      </div>
                                                    </div>

                                                  </template>

                                                </b-steps>

                                              </div>

                                            </ValidationObserver>

                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!-- MAKE NFT WHITELIST SECTION -->
                                    <div v-if="makeNftSelection === '4'"
                                         class="column pool-item is-centered is-align-self-center">
                                      <div class="columns">
                                        <div class="column is-align-self-center inset-bg">
                                          <div class="section" style="margin-top: -100px">
                                            <div class="wave__content" style="top: 7px; z-index: 2">
                                              <div class="wave__header">
                                                <div class="wave__menu"></div>
                                              </div>
                                            </div>

                                            <ValidationObserver v-slot="{ valid }">

                                              <div class="box">

                                                <!-- Mint NFT Form -->
                                                <accordion>
                                                  <accordion-item>
                                                    <template slot="accordion-trigger">
                                                      <h1 class="title blue-bg info-icon" style="font-size: 25px; padding-top: 2rem; padding-bottom: 2rem;">Make a NFT Whitelist Pre-Sale</h1>
                                                    </template>
                                                    <template slot="accordion-content">
                                                      <div class="blue-bg-2">
                                                        Do you want to hold a sale for your entire NFT collection at once?
                                                        <br/><br/>
                                                        The Pre-Sale creator tool allows you to run a series of sales rounds that will attempt to sell all of your NFTs from your entire NFT collection on the Stake Factory website- Use this tool after you have created all your NFTs.
                                                        <br/><br/>
                                                        This feature only works if you haven't sold or transferred any of the NFT's in the collection your attempting to create a pre-sale for yet- Attempting to create a pre-sale for a NFT collection that violates this rule will result in a failed transaction.
                                                        <br/><br/>
                                                        This tool will guide you through a series of steps and allow you to set up 2 different sales rounds for your entire NFT collection- A Whitelisted Members-only Pre-sale Round, and a Public Sale Round.
                                                        <br/><br/>
                                                        Follow each step of the design process and select a price for your NFTs, a date and time at which both sales rounds will end- Press 'create' at the end of the steps and the tool will run these sales rounds back to back and attempt to sell all your NFTs on the Stake Factory website.
                                                        <br/><br/>
                                                        After your pre-sale is created it will start immediately and appear on the Stake Factory website- This tool will take you to your Pre-Sale page after your sale as soon as it is created so that you can pass your sales page around to your community.
                                                        <br/><br/>
                                                      </div>
                                                    </template>
                                                  </accordion-item>
                                                </accordion>

                                                <b-steps v-model="currentStep">

                                                  <!-- About Section -->
                                                  <b-step-item :step="0" :clickable="false" label="About">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4" style="padding-left: 6rem; padding-right: 6rem; background-color: #00000038 !important;">
                                                          <span style="font-weight: bold; font-size: 17px;">What does this tool do?</span>
                                                          <br/><br/>
                                                          This tool will attempt to sell an entire <span style="font-weight: bold">NFT Collection</span> at once through a series of Sales rounds on the Stake Factory website.
                                                          <br/><br/>
                                                          The Pre-Sale creator tool allows you to run a series of sales rounds that will attempt to sell all of your NFTs from an entire NFT collection on the Stake Factory website- Use this tool after you have created all your NFTs.
                                                          <br/><br/>
                                                          This feature only works if you haven't sold or transferred any of the NFT's in the collection your attempting to create a pre-sale for yet- Attempting to create a pre-sale for a NFT collection that violates this rule will result in a failed transaction.
                                                          <br/><br/>
                                                          This tool will guide you through a series of steps and allow you to set up 2 different sales rounds for your entire NFT collection- A Whitelisted Members-only Pre-sale Round, and a Public Sale Round.
                                                          <br/><br/>
                                                          During the Whitelisted Members-only round, only the accounts you specifically added to your pre-sale whitelist can purchase NFTs from your sale & during the public round, anyone can purchase NFTs from your sale.
                                                          <br/><br/>
                                                          Follow each step of the design process and select a price for your NFTs, and a date and time at which both sales rounds will end- Press 'create' at the end of the design process and the tool will run both of your sales rounds back to back and attempt to sell all your NFTs on the Stake Factory website.
                                                          <br/><br/>
                                                          After your pre-sale is created it will start immediately and appear on the Stake Factory website and this tool will take you to your Pre-Sale page after your sale as soon as it is created so that you can pass your sales page link around to your community.
                                                          <br/><br/>
                                                          There is <span style="font-weight: bold">no</span> STEAK fee to create pre-sales.
                                                        </p>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <b-switch
                                                            class="mb-5 mt-1"
                                                            v-model="localModuleExists"
                                                            :rounded="true"
                                                            :outlined="true"
                                                            size="is-large"
                                                            type="is-success"
                                                            :left-label='false'
                                                            :passive-type='null'>{{ localModuleExists ? "OK" : "OK" }}
                                                        </b-switch>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- collection id section -->
                                                  <b-step-item :step="1" :clickable="false" label="Collection">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Create collection Section -->
                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Which NFT Collection will you be putting on sale?</span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">Collection ID</span>
                                                                of the NFT Collection being put on sale?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                All NFTs are part of a NFT collection.
                                                                <br/><br/>
                                                                Enter the Collection ID of the NFT collection you will putting on sale.
                                                                <br/><br/>
                                                                Remember you must be the creator of the collection in order to hold a whitelisted pre-sale for it.
                                                                <br/><br/>
                                                                If you have not yet created a collection, create a collection first and mint your NFTs into your collection, then create a whitelisted pre-sale after all your NFTs have been created.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <div class="columns is-flex is-justify-content-center">

                                                          <div class="column is-6"  style="display: flex !important; justify-content: center !important; flex-direction: column !important;">
                                                            <b-field
                                                                style="margin-top: 20px;"
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <template #label>
                                                                <b-tooltip type="is-dark" multilined
                                                                           label="What is the ID of the NFT Collection you are putting on sale?">
                                                                  <label class="label pt-1">NFT Collection ID:</label>
                                                                </b-tooltip>
                                                              </template>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <input v-model="whitelist_collectionID"
                                                                           @change="[verifyCollectionExists(whitelist_collectionID), getCollectionDetails(whitelist_collectionID)]"
                                                                           @input="[verifyCollectionExists(whitelist_collectionID), getCollectionDetails(whitelist_collectionID)]"
                                                                           class="input select"
                                                                           type="text" placeholder="my_collection_id">
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>
                                                          </div>

                                                          <div class="column is-3 is-flex is-flex-direction-column is-justify-content-center" style="display: flex !important; justify-content: center !important; flex-direction: column !important;">
                                                            <CollectionCardPreview style="margin: 10px; width: 316px !important; display: flex !important; justify-content: center !important; flex-direction: column !important;"
                                                                :collection_name="whitelist_collectionData['collection-id'] ? whitelist_collectionData['collection-id'] : '?'"
                                                                :collection_link="whitelist_collectionData.link ? whitelist_collectionData.link : '?'"
                                                                :collection_signature="whitelist_collectionData.signature ? whitelist_collectionData.signature : '?'"
                                                                :collection_author="whitelist_collectionData.creator ? whitelist_collectionData.creator : '?'"
                                                                :collection_supply="'0'"
                                                                collection_token="?"
                                                                :collection_image="whitelist_collectionData.image ? whitelist_collectionData.image : '?'"
                                                                :show_flipper="false"></CollectionCardPreview>
                                                          </div>

                                                        </div>





                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                        <p v-if="localModuleExists === true"
                                                           class="blue-bg font-green mb-4 mt-2">
                                              <span>I will conduct a sale for the NFT Collection: <span
                                                  style="font-weight: bold"> {{
                                                 whitelist_collectionID
                                                }}</span></span>
                                                        </p>

                                                        <p v-if="localModuleExists === false" class="blue-bg is-danger">
                                                          <span style="color:#f9868b">Error: The Collection ID you have entered was not found, please try another.</span>
                                                        </p>

                                                        <p v-if="localModuleExists === true && whitelist_collectionData !== [] && currentName !== null && whitelist_collectionData.creator !== currentName" class="blue-bg is-danger">
                                                          <span style="color:#f9868b">Error: This NFT Collection is owned by someone else!</span>
                                                        </p>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Sell Price Section -->
                                                  <b-step-item :step="2" :clickable="false" label="Price">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> NFT Sale Price </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">price</span>
                                                                buyers will pay for a NFT?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-weight: bold; font-size: 17px;">What price will buyers pay to purchase your NFTs during this presale?</span>
                                                                <br/><br/>
                                                                Choose the <span style="font-weight: bold">price</span> peoples must pay in order to purchase any NFT during the whitelisted presale time.
                                                                <br/>
                                                                <br/>
                                                                Your pre-sale will <span style="font-weight: bold">sell your NFTs to whitelisted members
                                                                at
                                                                this price</span> which can only be paid for in the <span style="font-weight: bold">Token Sale
                                                                Type</span> of this collection (determined when the collection was created).
                                                                <br/><br/>
                                                                If the collection's token sale type is 'any token', or you didnt pick a sale token type when you created your collection, then purchasers will pay in <span style="font-weight: bold">KDA</span> during your pre-sale in order to purchase your NFTs.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>
                                                        <ValidationObserver>
                                                          <b-field
                                                              style="margin-top: 20px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What is the price of your token?">
                                                                <label class="label pt-3"
                                                                       style="width:100px;">Price:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthan: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="wl_sell_token_price" name="WL Sell Token Price"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="whitelist_price"
                                                                             @input="[checkValidInput(valid), checkValidInputLME()]"
                                                                             @change="checkValidInputLME()"
                                                                             class="input select"
                                                                             type="number" step="0.0001"
                                                                             placeholder="4.0">
                                                                      <span class="error is-danger validation-msg">{{
                                                                          errors[0]
                                                                        }}</span>
                                                                      <span v-if="!errors[0]"
                                                                            class="error validation-msg-green is-info">Price whitelisted memebers must pay to purchase your NFTs</span>
                                                                    </ValidationProvider>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>
                                                        </ValidationObserver>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                        <p v-if="localModuleExists === true && whitelist_collectionData !== [] && whitelist_collectionData.token !== undefined"
                                                           class="blue-bg font-green mb-4">
                                                        <span>NFT Purchasers will pay <span style="font-weight: bold"> {{whitelist_price }} {{coinName(whitelist_collectionData.token.refName.namespace, whitelist_collectionData.token.refName.name)}}</span> tokens per <span style="font-weight: bold">NFT.</span></span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  WL Section -->
                                                  <b-step-item :step="3" :clickable="false" label="Whitelist">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> When does this Whitelisted Presale end? and, Who can buy NFTs from this sale? </span>
                                                        </p>



                                                        <div v-if="hasWhitelist === true">


                                                          <accordion>
                                                            <accordion-item>
                                                              <template slot="accordion-trigger">
                                                                <h1 class="blue-bg info-icon">What <span
                                                                    style="font-weight: bold">Date and Time</span> will Whitelisted Pre-sale end?
                                                                </h1>
                                                              </template>
                                                              <template slot="accordion-content">
                                                                <div class="blue-bg-2 has-text-left">
                                                                  <span style="font-weight: bold; font-size: 17px;">Pick how long your NFT collection will only be sold to whitelisted members</span>
                                                                  <br/><br/>
                                                                  How <span style="font-weight: bold">long</span> will
                                                                  your NFT Collection only pre-sell NFTs to members in your
                                                                  Whitelist for?
                                                                  <br/><br/>
                                                                  White lists can run for a maximum of 15 days- Pick the date and time at which your pre-sale will end.
                                                                  <br/><br/>
                                                                  Up until this date and time, only the accounts you
                                                                  have
                                                                  entered into your whitelist will be able to purchase your NFT's off your marketplace.
                                                                  <br/><br/>
                                                                  After the whitelist time is up, anyone will be able to purchase your NFTs.
                                                                </div>
                                                              </template>
                                                            </accordion-item>
                                                          </accordion>

                                                          <br/>
                                                          <div class="divider is-centered"></div>
                                                          <br/>

                                                          <div class="columns">

                                                            <div class="column is-offset-1-desktop">

                                                              <b-datetimepicker
                                                                  v-model="whitelist_time2"
                                                                  placeholder="Whitelist End Time"
                                                                  :inline="true"
                                                                  @input="checkNFTWlTime()"
                                                                  @change="checkNFTWlTime()"
                                                                  :min-datetime="minDatetime"
                                                                  :max-datetime="maxWlDatetime">
                                                              </b-datetimepicker>

                                                              <div class="pt-1 pb-4">
                                                            <span v-if="nftwlok === true"
                                                                  class="error validation-msg-green is-info">Time only whitelisted memebers will be able to purchase NFTs.</span>

                                                                <span v-if="nftwlok === false"
                                                                      class="error is-danger validation-msg">Whitelist presale must end in the future.</span>
                                                              </div>

                                                            </div>

                                                            <div class="column is-8 pt-6">

                                                              <b-switch
                                                                  class="mb-5 mt-0"
                                                                  v-model="whitelist_anyoneCanBuy"
                                                                  :rounded="false"
                                                                  :outlined="true"
                                                                  size="is-medium"
                                                                  type="is-success"
                                                                  :left-label='false'
                                                                  :passive-type='null'>{{ whitelist_anyoneCanBuy ? "Anyone can purchase from this sale." : "Only whitelist members can purchase from this sale." }}
                                                              </b-switch>

                                                              <p v-if="whitelist_anyoneCanBuy === false" style="margin-left: 50px">Add all the accounts that can purchase NFTs from this sale - 1 Account Per Line!</p>

                                                              <b-field
                                                                  style="margin-top: 10px;"
                                                                  custom-class="is-small"
                                                                  horizontal>
                                                                <template #label>
                                                                  <b-tooltip type="is-dark" multilined
                                                                             label="Enter the k:accounts in your whitelist, one per line - Individual accounts may be added later from your NFT collection's page.">
                                                                    <label class="label" style="width:100px;">Whitelist
                                                                      Members (1 Per Line!):</label>
                                                                  </b-tooltip>
                                                                </template>
                                                                <div class="field-body">
                                                                  <div class="field has-addons">
                                                                    <p class="control is-expanded">
                                                                      <b-field type="is-info">
                                                                  <textarea v-model="whitelist_whitelist"
                                                                            @input="makeNFTWhitelist(whitelist_whitelist)"
                                                                            style="height:300px;"
                                                                            class="input select"
                                                                            type="textarea"
                                                                            :disabled="whitelist_anyoneCanBuy"
                                                                            placeholder=""/>

                                                                      </b-field>
                                                                      <span v-if="!errors[0]"
                                                                            class="error validation-msg-green is-info">Enter whitelisted accounts one per line.</span>
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </b-field>

                                                              <b-field
                                                                  style="margin-top: 10px;"
                                                                  custom-class="is-small"
                                                                  horizontal>
                                                                <template #label>
                                                                  <b-tooltip type="is-dark" multilined
                                                                             label="How many tokens is someone allowed to buy during this presale?">
                                                                    <label class="label" style="width:100px;">Max NFTs Per
                                                                      Account:</label>
                                                                  </b-tooltip>
                                                                </template>
                                                                <div class="field-body">
                                                                  <div class="field has-addons">
                                                                    <p class="control is-expanded">
                                                                      <b-field type="is-info">
                                                                        <ValidationProvider
                                                                            :rules="{required: true, greaterthan: 0}"
                                                                            vid="max_canbuy_whitelist"
                                                                            name="Max Can Buy Whitelist"
                                                                            v-slot="{ errors, valid }">
                                                                          <input v-model="whitelist_maxbuy"
                                                                                 @input="checkValidInput(valid)"
                                                                                 class="input select"
                                                                                 type="number" step="0.0001"
                                                                                 placeholder="0">
                                                                          <span class="error is-danger validation-msg">{{
                                                                              errors[0]
                                                                            }}</span>
                                                                          <span v-if="!errors[0]"
                                                                                class="error validation-msg-green is-info">Maximum number of tokens whitelisted members can purchase.</span>
                                                                        </ValidationProvider>
                                                                      </b-field>
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </b-field>

                                                            </div>

                                                          </div>

                                                          <br/>
                                                          <div class="divider is-centered"></div>
                                                          <br/>

                                                        </div>

                                                        <p v-if="whitelist_time2 !== null" class="blue-bg font-green mb-4">

                                                        <span>My Whitelisted NFT Presale will run for <span style="font-weight: bold"> {{reduceBalance(whitelist_wlInSeconds,0) }} seconds </span> and end ~<span style="font-weight: bold">{{whitelist_time2}}</span>.
                                              <br/><br/>
                                                          During this time only Whitelisted Members may purchase NFTs and the maximum number of NFTs a whitelisted account may purchase is: <span style="font-weight: bold"> {{whitelist_maxbuy}}. </span>
                                              </span>
                                                        </p>

                                                        <p v-if="whitelist_time2 === null" class="blue-bg font-green mb-4">

                                                        <span>Please select a date and time at which your Whitelisted Presale round will end- During this time only members of your whitelist will be able to purchase NFTs. </span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Sale -->
                                                  <b-step-item :step="4" :clickable="false" label="Public Sale">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Public Sale </span>
                                                        </p>



                                                        <div v-if="hasWhitelist === true">


                                                          <accordion>
                                                            <accordion-item>
                                                              <template slot="accordion-trigger">
                                                                <h1 class="blue-bg info-icon">How <span style="font-weight: bold">long</span> the <span style="font-weight: bold">public</span> be able to purchase NFT's <span style="font-weight: bold">after</span> whitelisted members?
                                                                </h1>
                                                              </template>
                                                              <template slot="accordion-content">
                                                                <div class="blue-bg-2 has-text-left">
                                                                  <span style="font-weight: bold; font-size: 17px;">Pick how long your NFT collection will be offered to the public after the whitelist sale is over</span>
                                                                  <br/><br/>
                                                                  How <span style="font-weight: bold">long</span> will
                                                                  your NFT Collection be sold to the public after your whitelist sale ends?
                                                                  <br/><br/>
                                                                  Public sales can run for a maximum of 30 days.
                                                                  <br/><br/>
                                                                  Select the date at which your public sale time will end.
                                                                  <br/><br/>
                                                                  During this duration of time, anyone will be able purchase your NFT's off the marketplace, your NFT's will also appear obfuscated and randomized.
                                                                  <br/><br/>
                                                                  After this public sale ends, all NFTs will be revealed from both whitelist and public sale rounds.
                                                                </div>
                                                              </template>
                                                            </accordion-item>
                                                          </accordion>

                                                          <br/>
                                                          <div class="divider is-centered"></div>
                                                          <br/>

                                                          <div class="columns">

                                                            <div class="column is-offset-1-desktop">

                                                              <b-datetimepicker
                                                                  v-model="whitelist_publictime"
                                                                  placeholder="Public Sale End Time"
                                                                  :inline="true"
                                                                  @input="checkNFTPublicSaleTime()"
                                                                  @change="checkNFTPublicSaleTime()"
                                                                  :min-datetime="whitelist_time2"
                                                                  :max-datetime="maxDatetime">
                                                              </b-datetimepicker>

                                                              <div class="pt-1 pb-4">
                                                            <span v-if="nftwlok === true"
                                                                  class="error validation-msg-green is-info">Time when the public can purchase NFTs.</span>

                                                                <span v-if="nftwlok === false"
                                                                      class="error is-danger validation-msg">Public sale time must end in the future.</span>
                                                              </div>

                                                            </div>


                                                          </div>

                                                          <br/>
                                                          <div class="divider is-centered"></div>
                                                          <br/>

                                                        </div>

                                                        <p v-if="whitelist_publictime !== null" class="blue-bg font-green mb-4">

                                                        <span>After the whitelist pre-sale, the public will be allowed to purchase NFTs for <span style="font-weight: bold"> {{reduceBalance(whitelist_PublicTimeInSeconds,0) }} seconds, up until ~{{whitelist_publictime}}. </span></span>
                                                        </p>

                                                        <p v-if="whitelist_publictime === null" class="blue-bg font-green mb-4">

                                                          <span>Please select a date and time your public NFT sale will end.</span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  review Section -->
                                                  <b-step-item :step="5" :clickable="false" label="Review">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Review </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Please <span
                                                                  style="font-weight: bold">review</span> your presale rules and create your sale whenever you are ready.</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Does everything look <span style="font-weight: bold">ok</span> to you?
                                                                <br/>
                                                                <br/>
                                                                After you review your pre-sale click "Create Pre-sale" to activate your presale.
                                                                <br/><br/>
                                                                Remember, activating a whitelist does not list all your NFTs on the marketplace and you should now go add your NFTs to the marketplace by marking them for sale and setting a price.
                                                                <br/><br/>
                                                                All NFT's that appear in the marketplace and your collections marketplace during this time can only be purchased by whitelisted accounts and each NFT will appear obfuscated until the pre-sale has concluded.
                                                                <br/>
                                                                <br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p v-if="localModuleExists === true" class="blue-bg font-green mb-4">
                                                          <span>I am making a NFT Presale for the NFT Collection: <span style="font-weight: bold"> {{ whitelist_collectionID }}  </span></span>
                                                        </p>
                                                        <div v-if="localModuleExists === true" class="column is-offset-5 is-3 is-flex is-flex-direction-column is-justify-content-center" style="display: flex !important; justify-content: center !important; flex-direction: column !important;">
                                                          <CollectionCardPreview v-if="whitelist_collectionData !== []" style="margin: 10px; width: 316px !important; display: flex !important; justify-content: center !important; flex-direction: column !important;"
                                                                                 :collection_name="whitelist_collectionData['collection-id'] ? whitelist_collectionData['collection-id'] : '?'"
                                                                                 :collection_link="whitelist_collectionData.link ? whitelist_collectionData.link : '?'"
                                                                                 :collection_signature="whitelist_collectionData.signature ? whitelist_collectionData.signature : '?'"
                                                                                 :collection_author="whitelist_collectionData.creator ? whitelist_collectionData.creator : '?'"
                                                                                 :collection_supply="'0'"
                                                                                 collection_token="?"
                                                                                 :collection_image="whitelist_collectionData.image ? whitelist_collectionData.image : '?'"
                                                                                 :show_flipper="false"></CollectionCardPreview>
                                                        </div>
                                                        <p class="font-green" style="border: 1px solid;padding: 2rem;" v-if="whitelist_collectionData !== [] && whitelist_collectionData.token !== undefined">This NFT Pre-sale event will put <span style="font-weight: bold">ALL</span> of the NFTs in the <span style="font-weight: bold">{{whitelist_collectionData['collection-id']}}</span> NFT Collection on sale for <span style="font-weight: bold"> {{whitelist_price }} {{coinName(whitelist_collectionData.token.refName.namespace, whitelist_collectionData.token.refName.name)}}</span> tokens each.</p>
                                                        <p class="blue-bg font-green mt-4 mb-4 pt-5 pb-5" v-if="localModuleExists === true"><span>
                                                During the Whitelist round, <span style="font-weight: bold">Whitelisted Members</span> will be able to purchase <span style="font-weight: bold"> {{whitelist_maxbuy}} NFT(s)</span> each up until <span style="font-weight: bold">{{whitelist_time2}}. </span>
                                                  <br/><br/>
                                                  After the Whitelist round, the public will be allowed to purchase any remaining NFTs up until  <span style="font-weight: bold">{{whitelist_publictime}}. </span>
                                                          </span>
                                                        </p>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <div class="columns is-mobile mt-6 mb-1 is-flex-direction-column inset-bg" style="background-color: rgba(0, 0, 0, 0.22) !important;">

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  Pre-sale Creation Fee: FREE!
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  <input
                                                                      v-model="iagree"
                                                                      class="checkbox"
                                                                      type="checkbox"
                                                                  />
                                                                  I agree to the <a href="#">terms and conditions</a>.
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">

                                                            <p v-if="iagree === false"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: Please review Terms and conditions.</span>
                                                            </p>

                                                            <button
                                                                v-if="iagree === true"
                                                                @click="clickCreateNFTWhitelist()"
                                                                class="button is-link">Create Whitelisted Pre-Sale
                                                            </button>
                                                          </div>

                                                        </div>


                                                      </div>

                                                    </section>

                                                  </b-step-item>


                                                  <!-- Previous Next Buttons-->
                                                  <template
                                                      #navigation="{previous, next}">
                                                    <div class="container create-buttons">
                                                      <div class="columns">
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && localModuleExists === true"
                                                              outlined
                                                              type="is-danger"
                                                              :disabled="previous.disabled"
                                                              @click.prevent="[previous.action(), doStepBackwards()]">
                                                            Previous
                                                          </b-button>
                                                        </div>
                                                        <div v-if="currentStep !== 3" class="column">
                                                          <b-button
                                                              v-if="valid === true && localModuleExists === true && nftwlok === true"
                                                              outlined
                                                              type="is-success"
                                                              :disabled="next.disabled"
                                                              @click="[next.action(), doStepForward()]">
                                                            Next
                                                          </b-button>
                                                        </div>
                                                        <div v-if="currentStep === 3" class="column">
                                                          <b-button
                                                              v-if="valid === true && localModuleExists === true && nftwlok === true && whitelist_publictime !== null"
                                                              outlined
                                                              type="is-success"
                                                              :disabled="next.disabled"
                                                              @click="[next.action(), doStepForward()]">
                                                            Next
                                                          </b-button>
                                                        </div>
                                                      </div>
                                                    </div>

                                                  </template>

                                                </b-steps>

                                              </div>

                                            </ValidationObserver>

                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                  </div>
                                </div>

                                <!-- Make a DAO section-->
                                <div v-if="makingPool === false && welcome === false && makeNftSelection === '0' && makeDaoSelection !== '0' && finishedCreating === false"
                                     class="column pool-main is-centered mb-0">
                                  <div class="columns is-centered">

                                    <!-- MAKE DAO HIVE SECTION -->
                                    <div v-if="makeDaoSelection === '1'"
                                         class="column pool-item is-centered is-align-self-center">
                                      <div class="columns">
                                        <div class="column is-align-self-center inset-bg">
                                          <div class="section" style="margin-top: -100px">
                                            <div class="wave__content" style="top: 7px; z-index: 2">
                                              <div class="wave__header">
                                                <div class="wave__menu"></div>
                                              </div>
                                            </div>

                                            <ValidationObserver v-slot="{ valid }">

                                              <div class="box">

                                                <!-- Create dao hive Form -->
                                                <accordion>
                                                  <accordion-item>
                                                    <template slot="accordion-trigger">
                                                      <h1 class="title blue-bg info-icon" style="font-size: 25px; padding-top: 2rem; padding-bottom: 2rem;">Create a DAO Hive</h1>
                                                    </template>
                                                    <template slot="accordion-content">
                                                      <div class="blue-bg-2 has-text-left">
                                                        <span style="font-weight: bold; font-size: 17px;">What is a DAO Hive?</span>
                                                        <br/><br/>
                                                        <span style="font-weight: bold">DAOs</span> are Decentralized Autonomous Organizations and can be thought of as a group of people tied together through some sort of goal.
                                                        <br/><br/>
                                                        Stake Factory lets DAOs create a <span style="font-weight: bold">DAO Hive</span> or <span style="font-weight: bold">Hub</span>, which is a <span style="font-weight: bold">single organizational page</span> on the Stake Factory website that <span style="font-weight: bold">contains important information about a DAO</span> as well as <span style="font-weight: bold">tools for creating propositions and conducting votes</span>.
                                                        <br/><br/>
                                                        You can think of a <span style="font-weight: bold">DAO Hive</span> as a <span style="font-weight: bold">central hub</span> and <span style="font-weight: bold">collection of tools</span> that any type of DAO can use to <span style="font-weight: bold">conduct and manage important DAO operations</span>, such as <span style="font-weight: bold">voting</span>.
                                                        <br/><br/>
                                                        With a DAO Hive you can <span style="font-weight: bold">set up an entire web page</span> on Stake Factory where the DAO can <span style="font-weight: bold">conduct votes and make decisions together</span> and in general just <span style="font-weight: bold">record official information</span> on the blockchain.
                                                        <br/><br/>
                                                        A DAO Hive also <span style="font-weight: bold">holds important information about a DAO all in a single place</span> including important <span style="font-weight: bold">accounts</span> and <span style="font-weight: bold">links</span>, and enforces a set of rules concerning Voting Proposals created on the DAO's Hub.
                                                        <br/><br/>
                                                        For more information about <span style="font-weight: bold">DAO Hives</span>, make sure you check out the current DAO Hive's on Stake Factory, including the <span style="font-weight: bold">Stake Factory DAO</span> for a great example of how DAO Hive's work.
                                                        <br/><br/>
                                                        Ready to proceed? Click <span style="font-weight: bold">OK</span> whenever your ready to create your <span style="font-weight: bold">DAO Hive</span> on Stake Factory.
                                                      </div>
                                                    </template>
                                                  </accordion-item>
                                                </accordion>

                                                <b-steps v-model="currentStep">

                                                  <!-- About Section -->
                                                  <b-step-item :step="0" :clickable="false" label="DAO">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4" style="padding-left: 6rem; padding-right: 6rem; background-color: #00000038 !important;">
                                                          <span style="font-weight: bold; font-size: 20px;">What is a DAO Hive / Hub?</span>
                                                          <br/><br/>
                                                          A <span style="font-weight: bold">DAO Hive</span> is a decentralized hub and collection of tools a DAO can use to manage it's operations.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">How do DAO Hives work?</span>
                                                          <br/><br/>
                                                          Stake Factory lets DAOs create a Hive, which is a decentralized hub on the Stake Factory website that contains important information about a DAO, as well as tools for creating propositions and conducting votes. A DAO Hive/Hub let's a DAO conduct and record votes on the blockchain and organize it's decisions, accounts, pools, and other important pieces of information all in one place.
                                                          <br/><br/>
                                                          Use a DAO Hive to create and manage a DAO - DAO Hives let DAO's create DAO treasury pools and conduct votes and distribute tasks to DAO members, and more, all from a single place.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">How do I create a DAO Hive?</span>
                                                          <br/><br/>
                                                          This tool will guide you through a series of DAO Hive design steps allowing you to set up and represent your DAO on the Stake Factory website. Once published, your DAO Hive will immediately become visible on the Stake Factory website and users can begin interacting with your DAO according to the rules you set up when you designed your DAO Hive.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">Can I edit my DAO Hive?</span>
                                                          <br/><br/>
                                                          Yes. DAO's are fully editable and come full of customizable options depending on a members role in the DAO. As a DAO Creator, you will have a special Admin section listed on your DAO Hive for you to conduct administrative operations and edit your DAO Hive and assign member roles to DAO members at any time.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">What is the cost to make a DAO Hive?</span>
                                                          <br/><br/>
                                                          DAO Hives are free to make on Stake Factory.
                                                          <br/><br/>
                                                        </p>




                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <b-switch
                                                            class="mb-5 mt-1"
                                                            v-model="localModuleExists"
                                                            :rounded="true"
                                                            :outlined="true"
                                                            size="is-large"
                                                            type="is-success"
                                                            :left-label='false'
                                                            :passive-type='null'>{{ localModuleExists ? "OK" : "OK" }}
                                                        </b-switch>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- dao id Section -->
                                                  <b-step-item :step="1" :clickable="false" label="ID">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Create DAO ID Section -->
                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold; font-size: 17px;"> First things first, all DAO Hives have a DAO ID, what is your DAO's ID? </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">ID</span>
                                                                of this DAO?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                All DAO Hives have a <span style="font-weight: bold">DAO ID</span>.
                                                                <br/><br/>
                                                                A <span style="font-weight: bold">DAO ID</span> is a unique identifier used to identify your DAO on Stake Factory.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">DAO ID's</span> can contain <span style="font-weight: bold">no spaces</span> and must be between <span style="font-weight: bold">3 and 30 characters in length</span>.
                                                                <br/><br/>
                                                                Example: the-stakefactory-dao
                                                                <br/><br/>
                                                                <span style="font-weight: bold">DAO ID's</span> must be <span style="font-weight: bold">unique</span>- No other DAO may share the same ID as yours.
                                                                <br/><br/>
                                                                Use the tool below to check if your DAO ID is already taken by <span style="font-weight: bold">typing the ID you wish to use for your DAO in the tool</span> - The tool will automatically check if your ID has been taken and notify you in such an event.
                                                                <br/><br/>
                                                                Once you have picked out your unique DAO ID, click "<span style="font-weight: bold">Next</span>" to proceed - Your DAO's ID <span style="font-weight: bold">cannot be changed</span> after it is created.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <div class="columns" style="margin-top: 25px;">

                                                          <div class="column " style="margin-left: 120px;">

                                                            <div style="margin-top: 80px; border: 1px solid #4ae39e; padding-top: 20px; padding-bottom: 20px;">

                                                              <p style="color: #4ae39e !important">Give your DAO a unique ID</p>

                                                              <br/>
                                                              <div class="divider is-centered"></div>
                                                              <br/>

                                                              <b-field
                                                                  custom-class="is-small"
                                                                  horizontal>
                                                                <template #label>
                                                                  <b-tooltip type="is-dark" multilined
                                                                             label="What is your DAO's ID?">
                                                                    <label class="label pt-1" style="width:50px;">DAO ID:</label>
                                                                  </b-tooltip>
                                                                </template>
                                                                <div class="field-body">
                                                                  <div class="field has-addons">
                                                                    <p class="control is-expanded">
                                                                      <input v-model="dao_id"
                                                                             @change="[determineDaoID(), verifyDaoDontExist(dao_id)]"
                                                                             @input="[determineDaoID(), verifyDaoDontExist(dao_id)]"
                                                                             class="input select"
                                                                             type="text" placeholder="my-new-dao">
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </b-field>

                                                            </div>

                                                          </div>

                                                          <div class="column is-flex is-justify-content-center">

                                                            <div class="columns is-flex-direction-column is-justify-content-center" >
                                                              <div class="column">
                                                                <DaoCardPreview :dao_id="dao_id" dao_name="?" dao_description="???" dao_image="?" ></DaoCardPreview>
                                                              </div>
                                                              <div class="column">
                                                                <p style="color: #4ad1e3 !important">DAO Card Preview</p>
                                                              </div>
                                                            </div>






                                                          </div>

                                                        </div>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                        <p v-if="localModuleExists === true"
                                                           class="blue-bg font-green mb-4 mt-2">
                                              <span>My DAO's ID is: <span
                                                  style="font-weight: bold"> {{
                                                  dao_id
                                                }}</span></span>
                                                        </p>

                                                        <p v-if="localModuleExists === false" class="blue-bg is-danger">
                                                          <span style="color:#f9868b">Error: The DAO ID you have entered is already taken, please try another.</span>
                                                        </p>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  dao name Section -->
                                                  <b-step-item :step="2" :clickable="false" label="Name">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold">What is this DAO's Name?</span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Enter this DAO's <span
                                                                  style="font-weight: bold">name</span></h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Enter the <span style="font-weight: bold">name</span> of your DAO.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">DAO's Name</span> must be between <span style="font-weight: bold">3 and 30 characters in length</span>.
                                                                <br/><br/>
                                                                Example: The Stakefactory DAO
                                                                <br/><br/>
                                                                Once you have filled out your DAO's Name, click "<span style="font-weight: bold">Next</span>" to proceed - Your DAO's Name <span style="font-weight: bold">cannot be changed</span> after it is created.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <div class="columns" style="margin-top: 25px;">

                                                          <div class="column " style="margin-left: 120px;">

                                                            <div style="margin-top: 80px; border: 1px solid #4ae39e; padding-top: 20px; padding-bottom: 20px;">

                                                              <p style="color: #4ae39e !important">Enter your DAO's Name</p>

                                                              <br/>
                                                              <div class="divider is-centered"></div>
                                                              <br/>

                                                              <b-field
                                                                  custom-class="is-small"
                                                                  horizontal>
                                                                <template #label>
                                                                  <b-tooltip type="is-dark" multilined
                                                                             label="Enter the name of this DAO.">
                                                                    <label class="label pt-1 mt-2" style="width:50px;">Name:</label>
                                                                  </b-tooltip>
                                                                </template>
                                                                <div class="field-body">
                                                                  <div class="field has-addons">
                                                                    <p class="control is-expanded">
                                                                      <input v-model="dao_name"
                                                                             @change="determineDaoName()"
                                                                             @input="determineDaoName()"
                                                                             class="input select"
                                                                             type="text" placeholder="My DAO">
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </b-field>

                                                            </div>

                                                          </div>

                                                          <div class="column is-flex is-justify-content-center">

                                                            <div class="columns is-flex-direction-column is-justify-content-center" >
                                                              <div class="column">
                                                                <DaoCardPreview :dao_id="dao_id" :dao_name="dao_name" dao_description="???" dao_image="?" ></DaoCardPreview>
                                                              </div>
                                                              <div class="column">
                                                                <p style="color: #4ad1e3 !important">DAO Card Preview</p>
                                                              </div>
                                                            </div>






                                                          </div>

                                                        </div>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                              <span>My DAO's name is: <span style="font-weight: bold"> {{ dao_name }}  </span>
                                              <br/>
                                                My DAO's ID is: <span style="font-weight: bold"> {{ dao_id }}  </span></span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  dao icon Section -->
                                                  <b-step-item :step="3" :clickable="false" label="Icon">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold">What is this DAO's Icon?</span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Enter this DAO's <span
                                                                  style="font-weight: bold">name</span></h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Enter the <span style="font-weight: bold">name</span> of your DAO.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">DAO's Name</span> must be between <span style="font-weight: bold">3 and 30 characters in length</span>.
                                                                <br/><br/>
                                                                Example: The Stakefactory DAO
                                                                <br/><br/>
                                                                Once you have filled out your DAO's Name, click "<span style="font-weight: bold">Next</span>" to proceed - Your DAO's Name <span style="font-weight: bold">cannot be changed</span> after it is created.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <div class="columns" style="margin-top: 25px;">

                                                          <div class="column " style="margin-left: 120px;">

                                                            <div style="margin-top: 80px; border: 1px solid #4ae39e; padding-top: 20px; padding-bottom: 20px;">

                                                              <p style="color: #4ae39e !important">Enter a link to your DAO's Icon</p>

                                                              <br/>
                                                              <div class="divider is-centered"></div>
                                                              <br/>

                                                              <b-field
                                                                  custom-class="is-small"
                                                                  horizontal>
                                                                <template #label>
                                                                  <b-tooltip type="is-dark" multilined
                                                                             label="Enter the name of this DAO.">
                                                                    <label class="label pt-1 mt-2" style="width:50px;">Name:</label>
                                                                  </b-tooltip>
                                                                </template>
                                                                <div class="field-body">
                                                                  <div class="field has-addons">
                                                                    <p class="control is-expanded">
                                                                      <input v-model="dao_icon"
                                                                             class="input select"
                                                                             type="text" placeholder="My DAO">
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </b-field>

                                                            </div>

                                                          </div>

                                                          <div class="column is-flex is-justify-content-center">

                                                            <div class="columns is-flex-direction-column is-justify-content-center" >
                                                              <div class="column">
                                                                <DaoCardPreview :dao_id="dao_id" :dao_name="dao_name" dao_description="???" :dao_image="dao_icon" ></DaoCardPreview>
                                                              </div>
                                                              <div class="column">
                                                                <p style="color: #4ad1e3 !important">DAO Card Preview</p>
                                                              </div>
                                                            </div>






                                                          </div>

                                                        </div>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                              <span>My DAO's name is: <span style="font-weight: bold"> {{ dao_name }}  </span>
                                              <br/>
                                                My DAO's ID is: <span style="font-weight: bold"> {{ dao_id }}  </span></span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  dao description Section -->
                                                  <b-step-item :step="4" :clickable="false" label="Description">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Describe your DAO in a short sentence. </span>
                                                        </p>


                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Enter a short sentence <span
                                                                  style="font-weight: bold">describing</span> your DAO</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                <span style="font-weight: bold">DAO Descriptions</span> are a short sentence that describes the purpose of your DAO.
                                                                <br/>
                                                                <br/>
                                                                <span style="font-weight: bold">DAO Descriptions</span> are displayed under your DAO's name in various areas of the Stake Factory website and sum up the purpose of your community in a short sentence.
                                                                <br/>
                                                                <br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <div class="columns" style="margin-top: 25px;">

                                                          <div class="column " style="margin-left: 120px;">

                                                            <div style="margin-top: 80px; border: 1px solid #4ae39e; padding-top: 20px; padding-bottom: 20px;">

                                                              <p style="color: #4ae39e !important">Describe your DAO</p>

                                                              <br/>
                                                              <div class="divider is-centered"></div>
                                                              <br/>

                                                              <b-field
                                                                  custom-class="is-small"
                                                                  horizontal>
                                                                <template #label>
                                                                  <b-tooltip type="is-dark" multilined
                                                                             label="Describe this DAO in a short sentence.">
                                                                    <label class="label pt-1 mt-2" style="width:50px;">Description:</label>
                                                                  </b-tooltip>
                                                                </template>
                                                                <div class="field-body">
                                                                  <div class="field has-addons">
                                                                    <p class="control is-expanded">
                                                                <textarea v-model="dao_description"
                                                                          @input="determineDaoDescription()"
                                                                          style="height:80px;"
                                                                          class="input select"
                                                                          type="textarea"
                                                                          placeholder=""/>
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </b-field>

                                                            </div>

                                                          </div>

                                                          <div class="column is-flex is-justify-content-center">

                                                            <div class="columns is-flex-direction-column is-justify-content-center" >
                                                              <div class="column">
                                                                <DaoCardPreview :dao_id="dao_id" :dao_name="dao_name" :dao_description="dao_description" :dao_image="dao_icon" ></DaoCardPreview>
                                                              </div>
                                                              <div class="column">
                                                                <p style="color: #4ad1e3 !important">DAO Card Preview</p>
                                                              </div>
                                                            </div>

                                                          </div>

                                                        </div>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                                          <span>My DAO's Description is: <span style="font-weight: bold"> {{ dao_description }}  </span></span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  dao about Section -->
                                                  <b-step-item :step="5" :clickable="false" label="About">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Hub Welcome/About Message </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Enter a short paragraph <span
                                                                  style="font-weight: bold">about</span> your DAO</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                This <span style="font-weight: bold">'About'</span> paragraph is a short paragraph pinned to the top of your DAO's page, immediately visible to all users who visit the page.
                                                                <br/>
                                                                <br/>
                                                                Enter a short paragraph <span style="font-weight: bold">about</span> your DAO that you wish to have pinned to the top of your DAO page informing visitors about your DAO's <span style="font-weight: bold">community</span> and it's <span style="font-weight: bold">goals</span>, or whatever you would like.
                                                                <br/>
                                                                <br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <b-field
                                                            style="margin-right: 40px; margin-top: 20px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="Describe this DAO in a short sentence.">
                                                              <label class="label pt-1 mt-2" style="width:50px;">About:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <textarea v-model="dao_about"
                                                                          @input="determineDaoAbout()"
                                                                          style="height:100px;"
                                                                          class="input select"
                                                                          type="textarea"
                                                                          placeholder=""/>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                                          <span>Current DAO Hub Preview:</span>
                                                        </p>

                                                        <section class="hero">

                                                          <section class="hero" style="margin-top: 10px; " >
                                                            <div class="hero-body" style="padding-bottom: 1rem !important; ">
                                                              <div class="container animated fadeInUp" style="box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;" >
                                                                <div class="columns is-multiline is-mobile container4 is-flex is-justify-content-center" style="height: 180px; border-top-left-radius: 2rem; border-top-right-radius: 2rem;">
                                                                  <div class="column is-4-desktop is-4-tablet is-4-mobile reddish is-flex is-justify-content-center">
                                                                    <img style="max-width: 140px" :src="dao_icon" alt="?">
                                                                  </div>
                                                                  <div class="column is-8-desktop is-8-tablet is-8-mobile reddish is-flex is-justify-content-center" style="box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;">
                                                                    <ul class="list-unstyled is-flex is-flex-direction-column is-justify-content-center">
                                                                      <li class="cow2" style="font-size: 40px;">{{dao_name}}</li>
                                                                      <li class="cow3" style="font-size: 12px;">{{dao_id}}</li>
                                                                    </ul>

                                                                  </div>

                                                                </div>
                                                              </div>
                                                              <div class="container animated fadeInUp" style="box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;">
                                                                <div class="columns is-multiline is-mobile" style=" border-bottom-right-radius: 2rem; border-bottom-left-radius: 2rem;">
                                                                  <div class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish" >
                                                                    <ul class="list-unstyled">
                                                                      <li class="cow2" style="font-size: 15px; padding:5px; color: #b83838 !important;">{{dao_description}}</li>
                                                                    </ul>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div class="container animated fadeInUp">
                                                                <div class="columns is-multiline is-mobile ">
                                                                  <div class="column is-8-desktop is-8-tablet is-12-mobile txshadow reddish container_about" style="padding: 1rem;">
                                                                    <ul class="list-unstyled has-text-left">
                                                                      <li class="cow" style="font-size: 20px; color: #b83838 !important;">About</li>
                                                                      <li><div class="divider" style="margin-top: -45px;"></div><br/></li>
                                                                      <li class="cow3" style="font-size: 15px; color: #b83838 !important;">{{dao_about}}</li>
                                                                    </ul>
                                                                  </div>
                                                                  <div class="column is-4-desktop is-4-tablet is-12-mobile txshadow reddish container_links is-justify-content-center" style="padding: 1rem;">
                                                                    <b-button type="is-primary" v-for="p in dao_links" :key="p.name"  style="margin: 5px; color: #8ce8e3; font-family: 'Source Code Pro', monospace;">
                                                                      {{ p.name }}</b-button>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div class="container animated fadeInUp" style="box-shadow: rgba(0, 0, 0, 0.2) 0px 13px 40px -7px; margin-top: 10px; z-index:0">
                                                                <div class="columns is-multiline is-mobile" style=" border-bottom-right-radius: 2rem; border-bottom-left-radius: 2rem;">
                                                                  <div class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish" style="border-bottom-right-radius: 2rem; border-bottom-left-radius: 2rem;">
                                                                    <div class="columns" style="padding: 20px; overflow-wrap: anywhere">
                                                                      <div class="column">
                                                                        <span style="font-size: 12px;" class="cow4">Creator: {{currentName}}</span>
                                                                      </div>
                                                                      <div class="column" style="padding: 0px;">
                                                                        <div class="columns is-flex-direction-column">
                                                                          <div class="column pt-1">
                                                                            <span style="font-size: 12px;" class="cow4">Community Pool: ?</span>
                                                                          </div>
                                                                          <div class="column" style="padding: 0px;">
                                                                            <span style="font-size: 12px;" class="cow4">Balance: ?</span>
                                                                          </div>
                                                                        </div>

                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </section>

                                                        </section>

                                                        <p class="blue-bg font-green mt-5 mb-4">
                                                          <span>How does everything look so far? Click Next when your ready to continue.</span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  dao links Section -->
                                                  <b-step-item :step="6" :clickable="false" label="Links">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> DAO Links </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Create custom <span
                                                                  style="font-weight: bold">Link Buttons</span> for your DAO page</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Here you can create custom <span style="font-weight: bold">Links</span> for your DAO that appear as clickable button on your DAO page.
                                                                <br/>
                                                                <br/>
                                                                Enter a <span style="font-weight: bold">Link Title</span> to appear on the front of your Button. Clicking the button directs a persons browser to the <span style="font-weight: bold">Link</span>.                                                                <br/>
                                                                <br/>
                                                                <br/>
                                                                You must enter atleast 1 <span style="font-weight: bold">Link</span>, but can always add more links <span style="font-weight: bold">later</span>.
                                                                <br/>
                                                                <br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <div class="columns" style="margin-top: 25px">
                                                          <div class="column is-offset-1 is-6">
                                                            <p style="color: #4ae39e !important">Add important Links to display on your DAO page</p>

                                                            <br/>
                                                            <div class="divider is-centered"></div>
                                                            <br/>

                                                            <b-field
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <template #label>
                                                                <b-tooltip type="is-dark" multilined
                                                                           label="Enter the link title to display on the front of your new button.">
                                                                  <label class="label pt-1 mt-2" style="width:50px;">Link Title:</label>
                                                                </b-tooltip>
                                                              </template>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <input v-model="dao_newLinkTitle"
                                                                           @input="determineNewDaoLinkTitle()"
                                                                           class="input select"
                                                                           type="text" placeholder="Forum">
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>

                                                            <b-field
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <template #label>
                                                                <b-tooltip type="is-dark" multilined
                                                                           label="Enter actual link people are brought to when they click your button">
                                                                  <label class="label pt-1 mt-2" style="width:50px;">Link:</label>
                                                                </b-tooltip>
                                                              </template>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <input v-model="dao_newLink"
                                                                           class="input select"
                                                                           type="text" placeholder="https://link-to-dao-forum.com">
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>

                                                            <b-field
                                                                style="margin-top: 20px;"
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <b-button @click="addNewDaoLink()" type="is-dark" style="background-color: #4c2d71 !important">Add New Link</b-button>
                                                                  </p>
                                                                  <p class="control is-expanded">
                                                                    <b-button @click="clearDaoLinks()" type="is-dark" style="background-color: #712d2d !important">Clear Links</b-button>
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>



                                                          </div>
                                                          <div class="column is-4-desktop is-4-tablet is-12-mobile txshadow reddish container_links is-justify-content-center" style="padding: 1rem; border: 1px solid #3abb81; border-radius: 2rem;">
                                                            <p v-if="dao_exampleMode === true" style="color: #4ae39e !important">Example Links (Create your own!)</p>
                                                            <p v-if="dao_exampleMode === false" style="color: #4ae39e !important">Links (Shown on DAO Page)</p>
                                                            <b-button type="is-primary"  style="margin: 5px; color: #8ce8e3; font-family: 'Source Code Pro', monospace;" v-for="i in dao_links" :key="i.name">{{i.name}}</b-button>
                                                          </div>
                                                        </div>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                              <span>The above links will be displayed on your <span style="font-weight: bold"> DAO page </span>. Click Next when your ready to continue.</span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  dao contacts Section -->
                                                  <b-step-item :step="7" :clickable="false" label="Contacts">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> DAO Account Records </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Create a record of DAO <span
                                                                  style="font-weight: bold">Accounts</span></h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Here you can create a record of any <span style="font-weight: bold">Accounts</span> you want the public to be aware of on your DAO page.
                                                                <br/>
                                                                <br/>
                                                                All of the accounts you enter appear in a table on your DAO page for the purposes of record keeping, allowing you to maintain a record of important information and maintain transparency.
                                                                <br/>
                                                                <br/>
                                                                You must enter at least 1 <span style="font-weight: bold">Account</span>, and can always add more accounts <span style="font-weight: bold">later</span>.
                                                                <br/>
                                                                <br/>
                                                                Feel free to add any type of account information you would like - Whatever your DAO finds relevant to it's operations for on-chain and off-chain operations.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <div class="columns" style="margin-top: 25px">
                                                          <div class="column is-offset-1 is-5">

                                                            <p style="color: #4ae39e !important">Add important DAO Account Information to display on your DAO page</p>

                                                            <br/>
                                                            <div class="divider is-centered"></div>
                                                            <br/>

                                                            <b-field
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <template #label>
                                                                <b-tooltip type="is-dark" multilined
                                                                           label="Enter the contact title to display next to the contact information.">
                                                                  <label class="label pt-1" style="width:50px;">Account Name / Purpose:</label>
                                                                </b-tooltip>
                                                              </template>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <input v-model="dao_newContactTitle"
                                                                           @input="determineNewDaoLinkTitle()"
                                                                           class="input select"
                                                                           type="text" placeholder="Email">
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>

                                                            <b-field
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <template #label>
                                                                <b-tooltip type="is-dark" multilined
                                                                           label="Enter actual contact information">
                                                                  <label class="label pt-1 mt-2" style="width:50px;">Account:</label>
                                                                </b-tooltip>
                                                              </template>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <input v-model="dao_newContact"
                                                                           class="input select"
                                                                           type="text" placeholder="dao_email@dao_email.com">
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>

                                                            <b-field
                                                                style="padding-top: 10px;"
                                                                custom-class="is-small"
                                                                horizontal>
                                                              <div class="field-body">
                                                                <div class="field has-addons">
                                                                  <p class="control is-expanded">
                                                                    <b-button @click="addNewDaoContact()" type="is-dark" style="background-color: #4c2d71 !important">Add New Account</b-button>
                                                                  </p>
                                                                  <p class="control is-expanded">
                                                                    <b-button @click="clearDaoContacts()" type="is-dark" style="background-color: #712d2d !important">Clear All Accounts</b-button>
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </b-field>



                                                          </div>
                                                          <div class="column is-5 is-flex-direction-column is-justify-content-space-evenly" style="display:flex; border: 1px solid #3abb81; border-radius: 2rem;">
                                                            <p v-if="dao_exampleModeContacts === true" style="color: #4ae39e !important">Example Accounts (Add your own!)</p>
                                                            <p v-if="dao_exampleModeContacts === false" style="color: #4ae39e !important">List of DAO Accounts</p>

                                                            <p style="color: #e181ed !important" v-for="i in dao_contacts" :key="i.id">{{i.type}}: {{i.contact}}</p>


                                                          </div>
                                                        </div>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                                          <span>I am adding <span style="font-weight: bold"> {{ dao_currentContact }} account records to my DAO page </span></span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Proposal Token Section -->
                                                  <b-step-item :step="8" :clickable="false" label="Token">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Create Pair Section -->
                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> DAO Token  </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">token</span>
                                                                this DAO uses?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                <span style="font-weight: bold; font-size: 17px;">What token does this DAO use to create proposals with?</span>
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>



                                                        <ValidationObserver v-if="currentStep === 8">
                                                          <b-field style="margin-top: 20px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What is the contract name / module name of the token your DAOs token?">
                                                                <label class="label pt-1" style="width:50px;">Token
                                                                  Module:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <ValidationProvider
                                                                      :rules="{required: true}"
                                                                      vid="dao_moduleName"
                                                                      name="Dao Token Module Name"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="dao_moduleName"
                                                                           @change="verifyTokenExists(dao_moduleName, dao_namespace)"
                                                                           @input="verifyTokenExists(dao_moduleName, dao_namespace)"
                                                                           class="input select"
                                                                           type="text" placeholder="my-token">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span
                                                                        class="error is-danger validation-msg is-hidden">{{
                                                                        valid[0]
                                                                      }}</span>
                                                                  </ValidationProvider>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field
                                                              style="margin-top: 10px"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What is the contract namespace / module namespace of the token this collection must be traded in?">
                                                                <label class="label pt-1" style="width:50px;">Token
                                                                  Namespace:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">

                                                                  <ValidationProvider
                                                                      :rules="{required: false}"
                                                                      vid="dao_namespace"
                                                                      name="Dao Module Namespace"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="dao_namespace"
                                                                           @change="verifyTokenExists(dao_moduleName, dao_namespace)"
                                                                           @input="verifyTokenExists(dao_moduleName, dao_namespace)"
                                                                           class="input select"
                                                                           type="text" placeholder="free">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span class="error is-danger validation-msg">{{
                                                                        valid[0]
                                                                      }}</span>
                                                                  </ValidationProvider>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field
                                                              style="margin-top: 10px"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="The price (in DAO token) everyone has to pay to create a new proposal at the DAO">
                                                                <label class="label pt-2" style="width:100px;">New Proposal
                                                                  Cost:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <b-field type="is-info">
                                                                    <ValidationProvider
                                                                        :rules="{required: true, greaterthanequalto: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                        vid="dao_proposalCost"
                                                                        name="Dao Proposal Amount"
                                                                        v-slot="{ errors, valid }">
                                                                      <input v-model="dao_proposalCost"
                                                                             @input="checkValidInput(valid)"
                                                                             @change="calculateVestedMemberStats()"
                                                                             class="input select"
                                                                             type="number" step="0.0001"
                                                                             placeholder="10.0">
                                                                      <span class="error is-danger validation-msg">{{
                                                                          errors[0]
                                                                        }}</span>
                                                                      <span v-if="!errors[0]"
                                                                            class="error validation-msg-green is-info">The <span
                                                                          style="font-weight: bold">Cost</span> (in DAO token) to create a <span
                                                                          style="font-weight: bold">New Proposal</span> at the DAO Reward Duration</span>
                                                                    </ValidationProvider>
                                                                  </b-field>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                        </ValidationObserver>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <b-switch
                                                            class="mb-5 mt-1"
                                                            v-model="dao_creatorOnly"
                                                            :rounded="true"
                                                            :outlined="true"
                                                            size="is-large"
                                                            type="is-success"
                                                            :left-label='false'
                                                            :passive-type='null'>{{ dao_creatorOnly ? "Only DAO creator can make proposals" : "Anyone can make proposals" }}
                                                        </b-switch>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>



                                                        <p class="blue-bg font-green mb-4 mt-2">
                                                          <span v-if="dao_creatorOnly === true"> Only the creator (my account) of this DAO can make new proposals. </span>
                                                          <span v-if="dao_creatorOnly === false"> Anyone can create new proposals at my DAO. </span>
                                                          <br/><br/>
                                              <span v-if="customFungiv2Reward === false">My DAOs token is <span
                                                  style="font-weight: bold"> {{
                                                  coinName(dao_namespace, dao_moduleName)
                                                }}.</span></span>
                                                          <br/><br/>
                                                          <span v-if="customFungiv2Reward === false">New DAO proposals cost <span
                                                              style="font-weight: bold"> {{dao_proposalCost}} {{
                                                              coinName(dao_namespace, dao_moduleName)
                                                            }} </span> to create.</span>
                                                        </p>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Review Section -->
                                                  <b-step-item :step="9" :clickable="false" label="Review">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Review Your Hive </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Here is a preview of your new <span
                                                                  style="font-weight: bold">DAO Hub and DAO Card</span> - does everything look ok?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Please review your <span style="font-weight: bold">DAO Hive</span> and whenever you are ready click "Create" to create it.
                                                                <br/><br/>
                                                                Once your DAO Hive is created, you will be taken to your newly created DAO Page where you and anyone else can begin interacting with it.
                                                                <br/><br/>
                                                                If everything looks ok to you below, press Create to create your DAO Hive now.
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <div class="column is-flex is-justify-content-center">

                                                          <div class="columns is-flex-direction-column is-justify-content-center" >
                                                            <div class="column">
                                                              <DaoCardPreview :dao_id="dao_id" :dao_name="dao_name" :dao_description="dao_description" :dao_image="dao_icon" ></DaoCardPreview>
                                                            </div>
                                                            <div class="column">
                                                              <p style="color: #4ad1e3 !important">DAO Card Preview</p>
                                                            </div>
                                                          </div>

                                                        </div>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                                          <span>Current DAO Hub Preview:</span>
                                                        </p>

                                                        <section class="hero">

                                                          <section class="hero" style="margin-top: 10px; " >
                                                            <div class="hero-body" style="padding-bottom: 1rem !important; ">
                                                              <div class="container animated fadeInUp" style="box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;" >
                                                                <div class="columns is-multiline is-mobile container4 is-flex is-justify-content-center" style="height: 180px; border-top-left-radius: 2rem; border-top-right-radius: 2rem;">
                                                                  <div class="column is-4-desktop is-4-tablet is-4-mobile reddish is-flex is-justify-content-center">
                                                                    <img style="max-width: 140px" :src="dao_icon" alt="?">
                                                                  </div>
                                                                  <div class="column is-8-desktop is-8-tablet is-8-mobile reddish is-flex is-justify-content-center" style="box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;">
                                                                    <ul class="list-unstyled is-flex is-flex-direction-column is-justify-content-center">
                                                                      <li class="cow2" style="font-size: 40px;">{{dao_name}}</li>
                                                                      <li class="cow3" style="font-size: 12px;">{{dao_id}}</li>
                                                                    </ul>

                                                                  </div>

                                                                </div>
                                                              </div>
                                                              <div class="container animated fadeInUp" style="box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;">
                                                                <div class="columns is-multiline is-mobile" style=" border-bottom-right-radius: 2rem; border-bottom-left-radius: 2rem;">
                                                                  <div class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish" >
                                                                    <ul class="list-unstyled">
                                                                      <li class="cow2" style="font-size: 15px; padding:5px; color: #b83838 !important;">{{dao_description}}</li>
                                                                    </ul>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div class="container animated fadeInUp">
                                                                <div class="columns is-multiline is-mobile ">
                                                                  <div class="column is-8-desktop is-8-tablet is-12-mobile txshadow reddish container_about" style="padding: 1rem;">
                                                                    <ul class="list-unstyled has-text-left">
                                                                      <li class="cow" style="font-size: 20px; color: #b83838 !important;">About</li>
                                                                      <li><div class="divider" style="margin-top: -45px;"></div><br/></li>
                                                                      <li class="cow3" style="font-size: 15px; color: #b83838 !important;">{{dao_about}}</li>
                                                                    </ul>
                                                                  </div>
                                                                  <div class="column is-4-desktop is-4-tablet is-12-mobile txshadow reddish container_links is-justify-content-center" style="padding: 1rem;">
                                                                    <b-button type="is-primary" v-for="p in dao_links" :key="p.name"  style="margin: 5px; color: #8ce8e3; font-family: 'Source Code Pro', monospace;">
                                                                      {{ p.name }}</b-button>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div class="container animated fadeInUp" style="box-shadow: rgba(0, 0, 0, 0.2) 0px 13px 40px -7px; margin-top: 10px; z-index:0">
                                                                <div class="columns is-multiline is-mobile" style=" border-bottom-right-radius: 2rem; border-bottom-left-radius: 2rem;">
                                                                  <div class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish" style="border-bottom-right-radius: 2rem; border-bottom-left-radius: 2rem;">
                                                                    <div class="columns" style="padding: 20px; overflow-wrap: anywhere">
                                                                      <div class="column">
                                                                        <span style="font-size: 12px;" class="cow4">Creator: {{currentName}}</span>
                                                                      </div>
                                                                      <div class="column" style="padding: 0px;">
                                                                        <div class="columns is-flex-direction-column">
                                                                          <div class="column pt-1">
                                                                            <span style="font-size: 12px;" class="cow4">Community Pool: ?</span>
                                                                          </div>
                                                                          <div class="column" style="padding: 0px;">
                                                                            <span style="font-size: 12px;" class="cow4">Balance: ?</span>
                                                                          </div>
                                                                        </div>

                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </section>



                                                        </section>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mt-5 mb-4">
                                                          <span>How does everything look so far? Click Create when your ready to create your DAO.</span>
                                                        </p>

                                                        <div class="columns is-mobile mt-6 mb-1 is-flex-direction-column inset-bg" style="background-color: rgba(0, 0, 0, 0.22) !important;">

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  DAO Creation Fee: FREE
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  <input
                                                                      v-model="iagree"
                                                                      class="checkbox"
                                                                      type="checkbox"
                                                                  />
                                                                  I agree to the <a href="#">terms and conditions</a>.
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">

                                                            <p v-if="localPoolIdExists === true"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: This collections signature is already taken, please try another.</span>
                                                            </p>

                                                            <p v-if="iagree === false"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: Please review Terms and conditions.</span>
                                                            </p>

                                                            <button
                                                                v-if="localPoolIdExists === false && iagree === true"
                                                                @click="clickCreateDAOHive()"
                                                                class="button is-link">Create DAO
                                                            </button>
                                                          </div>

                                                        </div>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Previous Next Buttons-->
                                                  <template
                                                      #navigation="{previous, next}">
                                                    <div class="container create-buttons">
                                                      <div class="columns">
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && lpValid === true && localModuleExists === true"
                                                              outlined
                                                              type="is-danger"
                                                              :disabled="previous.disabled"
                                                              @click.prevent="[previous.action(), doStepBackwards()]">
                                                            Previous
                                                          </b-button>
                                                        </div>
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && lpValid === true && localModuleExists === true"
                                                              outlined
                                                              type="is-success"
                                                              :disabled="next.disabled"
                                                              @click="[next.action(), doStepForward()]">
                                                            Next
                                                          </b-button>
                                                        </div>
                                                      </div>
                                                    </div>

                                                  </template>

                                                </b-steps>

                                              </div>

                                            </ValidationObserver>

                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!-- MAKE VOTE POLL SECTION -->
                                    <div v-if="makeDaoSelection === '2'"
                                         class="column pool-item is-centered is-align-self-center">
                                      <div class="columns">
                                        <div class="column is-align-self-center inset-bg">
                                          <div class="section" style="margin-top: -100px">
                                            <div class="wave__content" style="top: 7px; z-index: 2">
                                              <div class="wave__header">
                                                <div class="wave__menu"></div>
                                              </div>
                                            </div>

                                            <ValidationObserver v-slot="{ valid }">

                                              <div class="box">

                                                <!-- Create collection Form -->
                                                <accordion>
                                                  <accordion-item>
                                                    <template slot="accordion-trigger">
                                                      <h1 class="title blue-bg info-icon" style="font-size: 25px; padding-top: 2rem; padding-bottom: 2rem;">Create a Voting Poll</h1>
                                                    </template>
                                                    <template slot="accordion-content">
                                                      <div class="blue-bg-2 has-text-left">
                                                        <span style="font-weight: bold; font-size: 17px;">What is a Voting Poll?</span>
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Voting Polls</span> are special pools that allow people to vote for or against a proposition.
                                                        <br/><br/>
                                                        With a Voting Poll, you create a proposition and set how long people can vote over the proposition - The poll runs allowing people to vote until the timer has ended. Once time is up, anyone can view the results of the vote by visiting the Poll.
                                                        <br/><br/>
                                                        Voting Polls are specifically used for DAO solutions, so that DAOs can conduct community votes and make decisions together.
                                                        <br/><br/>
                                                        Voting Polls get posted to the DAO Page you select when you create Voting Poll so that people at the DAO can participate in the Vote- Therefore, you must know which DAO Hive you want to post your Voting Poll to in before you create it.
                                                        <br/><br/>
                                                        This tool guides you through the process of creating a Voting Poll by presenting you with a series of questions and steps and ultimately generates a preview of your Voting Poll- If everything looks ok in your preview at the end of this process, you may create your Voting Poll.
                                                        <br/><br/>
                                                        For better examples of how Voting Polls work, make sure you visit a DAO on the Stake Factory website for a first hand experience with a Voting Poll.
                                                        <br/><br/>
                                                        Voting Polls are free to create (Just pay the Gas Fee). However, a DAO may have a propositional creation fee - Make sure you check your DAO's page for any proposal costs.
                                                        <br/><br/>
                                                      </div>
                                                    </template>
                                                  </accordion-item>
                                                </accordion>

                                                <b-steps v-model="currentStep">

                                                  <!-- About Section -->
                                                  <b-step-item :step="0" :clickable="false" label="DAO">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4" style="background-color: #00000038 !important;">
                                                          <span style="font-weight: bold; font-size: 20px;">What is a Voting Poll?</span>
                                                        </p>

                                                        <VotePoolCardPreview pool_name="A Voting Poll Example"
                                                                             :show_proposal_failed="true"
                                                                             :show_i_voted_against="true"
                                                                             pool_end_time="1/1/2030"
                                                                             pool_vote_token="STEAK"
                                                                             pool_description="This is an example Voting Poll. Voting Polls are used to let a community of token holders make decisions together and vote on whatever they want."
                                                                             :pool_for_balance="100"
                                                                             :stake_final_vote_balance="10"
                                                        :pool_against_balance="200"
                                                        :pool_for_voters="20"
                                                        :pool_against_voters="24"></VotePoolCardPreview>

                                                        <p style="color: #4ad1e3"> <span style="font-weight: bold">Voting Polls</span> are specialized pools that allow people to vote for or against a proposition.</p>

                                                        <p class="blue-bg font-green mb-4 mt-4" style="padding-left: 6rem; padding-right: 6rem; background-color: #00000038 !important;">
                                                          <span style="font-weight: bold; font-size: 20px;">How do Voting Polls work?</span>
                                                          <br/><br/>
                                                          Voting Polls work by letting people stake a token in order to vote - Simply stake to vote (Voters may withdraw their Staked Tokens at anytime).
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">Why would I make a Voting Poll?</span>
                                                          <br/><br/>
                                                          Voting Polls are designed specifically with DAOs in mind - Voting Polls are what allow DAOs and groups of people to come together to make choices and record those choices on the blockchain.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">What can I do with a Voting Poll?</span>
                                                          <br/><br/>
                                                          With a Voting Poll you can do things like allow your community to make important choices, or even get feed back from your community by letting the cast votes - A Voting Poll will run over a period of time and after the time is up, anyone can view the results of the vote forever.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">How do I create a Voting Poll?</span>
                                                          <br/><br/>
                                                          This tool will guide you through a series of steps that allows you to design your Voting Poll and launch it at a DAO - Once you launch your Voting Poll it will immediately become active on the DAO you launch the poll on.
                                                          <br/><br/>
                                                          Create your Voting Poll by deciding what topic people will vote over, then choose which token people have to stake and set how long the pool will run, finally assign your Voting Poll to a specific DAO and launch your Poll.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">Who can Vote in my Voting Poll?</span>
                                                          <br/><br/>
                                                          Anyone can Vote in your Voting Poll by Staking the token you have chosen when you design your Voting Poll - Voters must stake their tokens before the voting poll timer has ended, and withdraw their token after the poll timer has ended for their votes to count. If a voter withdraws their staked tokens before the Voting Poll timer is up, their vote will not count.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">What is the cost to make a Voting Poll?</span>
                                                          <br/><br/>
                                                          There is no cost to create a Voting Poll, however, a DAO may choose to charge a fee to create a proposal on the DAO - Make sure you check with the DAO your voting poll will be running on to see if there is any cost to create new proposals.
                                                          <br/><br/>
                                                        </p>




                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <b-switch
                                                            class="mb-5 mt-1"
                                                            v-model="localModuleExists"
                                                            :rounded="true"
                                                            :outlined="true"
                                                            size="is-large"
                                                            type="is-success"
                                                            :left-label='false'
                                                            :passive-type='null'>{{ localModuleExists ? "OK" : "OK" }}
                                                        </b-switch>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- dao id Section -->
                                                  <b-step-item :step="1" :clickable="false" label="ID">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Create collection Section -->
                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold; font-size: 17px;"> First things first - What DAO Hive will this Voting Poll appear on? </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">ID</span>
                                                                of the <span style="font-weight: bold">DAO</span> this Voting Poll is being created for?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                All DAO Hives have a <span style="font-weight: bold">DAO ID</span>.
                                                                <br/><br/>
                                                                Enter the DAO ID your Voting Poll will appear on - Remember DAOs may have a proposal cost! Visit the DAO you're creating your Voting Poll on and check if the DAO has a proposal cost!
                                                                <br/><br/>
                                                                Once you have entered the ID of the DAO this Voting Poll will be created on, click "<span style="font-weight: bold">Next</span>" to proceed - Your Voting Poll will <span style="font-weight: bold">appear</span> on the DAO after it is created using this tool.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <div class="columns" style="margin-top: 25px;">

                                                          <div class="column " style="margin-left: 120px;">

                                                            <div style="margin-top: 80px; border: 1px solid #4ae39e; padding-top: 20px; padding-bottom: 20px;">

                                                              <p style="color: #4ae39e !important">Enter DAO Hive ID</p>

                                                              <br/>
                                                              <div class="divider is-centered"></div>
                                                              <br/>

                                                              <b-field
                                                                  custom-class="is-small"
                                                                  horizontal>
                                                                <template #label>
                                                                  <b-tooltip type="is-dark" multilined
                                                                             label="Enter the ID of the DAO this Voting Poll will appear on">
                                                                    <label class="label pt-1" style="width:50px;">DAO ID:</label>
                                                                  </b-tooltip>
                                                                </template>
                                                                <div class="field-body">
                                                                  <div class="field has-addons">
                                                                    <p class="control is-expanded">
                                                                      <input v-model="vote_daoid"
                                                                             @change="[getDAOInfo(vote_daoid), verifyDaoExists(vote_daoid)]"
                                                                             @input="[getDAOInfo(vote_daoid), verifyDaoExists(vote_daoid)]"
                                                                             class="input select"
                                                                             type="text" placeholder="my-new-dao">
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </b-field>

                                                            </div>

                                                          </div>

                                                          <div class="column is-flex is-justify-content-center">

                                                            <div class="columns is-flex-direction-column is-justify-content-center" >
                                                              <div class="column">
                                                                <DaoCardPreview :dao_id="vote_currentdao.subject_id" :dao_name="vote_currentdao.name" :dao_description="vote_currentdao.description" :dao_image="vote_currentdao.icon" ></DaoCardPreview>
                                                              </div>
                                                              <div class="column">
                                                                <p style="color: #4ad1e3 !important">Create a poll on this DAO?</p>
                                                              </div>
                                                            </div>






                                                          </div>

                                                        </div>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                        <p v-if="localModuleExists === true"
                                                           class="blue-bg font-green mb-4 mt-2">
                                              <span>My DAO's ID is: <span
                                                  style="font-weight: bold"> {{
                                                  vote_daoid
                                                }}</span></span>
                                                        </p>

                                                        <p v-if="localModuleExists === false" class="blue-bg is-danger">
                                                          <span style="color:#f9868b">Error: The DAO ID you have entered was not found, please try another.</span>
                                                        </p>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Vote Token Section -->
                                                  <b-step-item :step="2" :clickable="false" label="Token">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Create Pair Section -->
                                                        <p class="blue-bg font-green mb-4">
                                              <span style="font-weight: bold; font-size: 20px;"> Vote Token  </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">token</span>
                                                                people vote with in this Voting Poll?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                <span style="font-weight: bold; font-size: 17px;">What token do users have to use to vote with?</span>
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>



                                                        <ValidationObserver v-if="currentStep === 2">
                                                          <b-field
                                                              style="margin-top: 20px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What is the contract name / module name of the voting token?">
                                                                <label class="label pt-1" style="width:50px;">Token
                                                                  Module:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <ValidationProvider
                                                                      :rules="{required: true}"
                                                                      vid="dao_moduleName"
                                                                      name="Dao Token Module Name"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="dao_moduleName"
                                                                           @change="verifyTokenExists(dao_namespace, dao_moduleName)"
                                                                           @input="verifyTokenExists(dao_namespace, dao_moduleName)"
                                                                           class="input select"
                                                                           type="text" placeholder="my-token">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span
                                                                        class="error is-danger validation-msg is-hidden">{{
                                                                        valid[0]
                                                                      }}</span>
                                                                  </ValidationProvider>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field
                                                              style="margin-top:10px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What is the contract namespace / module namespace of the voting token?">
                                                                <label class="label pt-1" style="width:50px;">Token
                                                                  Namespace:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">

                                                                  <ValidationProvider
                                                                      :rules="{required: false}"
                                                                      vid="dao_namespace"
                                                                      name="Dao Module Namespace"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="dao_namespace"
                                                                           @change="verifyTokenExists(dao_namespace, dao_moduleName)"
                                                                           @input="verifyTokenExists(dao_namespace, dao_moduleName)"
                                                                           class="input select"
                                                                           type="text" placeholder="free">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span class="error is-danger validation-msg">{{
                                                                        valid[0]
                                                                      }}</span>
                                                                  </ValidationProvider>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                        </ValidationObserver>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon"><span style="font-weight: bold">How many tokens</span> does this proposition require to reach consensus?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                <span style="font-weight: bold; font-size: 17px;">How many tokens will be required to make this vote pass? If this number of tokens is not staked into the pool when voting time runs out, this proposition will fail, even if more people voted for this proposition than against it - Enter 0.0 if you do not want to set a required limit to reach consensus..</span>
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <b-field v-if="currentStep === 2"
                                                                 style="margin-top:20px;"
                                                                 custom-class="is-small"
                                                                 horizontal>
                                                          <template #label>

                                                            <b-tooltip type="is-dark"
                                                                       label="The total number of tokens it requires to be staked in this pool for the proposition to pass.">
                                                              <label class="label pt-1" style="width:50px;">Tokens Required:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <ValidationObserver
                                                                    v-if="currentStep === 2">
                                                                  <ValidationProvider
                                                                      :rules="{required: true, greaterthanequalto: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                      vid="reward_amount" name="Reward Amount"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="rewardAmount"
                                                                           @change="checkValidInput(valid)"
                                                                           @input="checkValidInput(valid)"
                                                                           class="input select" type="number"
                                                                           step="0.01"
                                                                           placeholder="Amount of Reward Token">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span v-if="!errors[0]"
                                                                          class="error validation-msg-green is-info">Total amount of vote tokens required to pass this vote.</span>
                                                                    <br/>
                                                                  </ValidationProvider>
                                                                </ValidationObserver>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4 mt-2" v-if="collectionAnyToken === false">
                                                          <span>Voters must stake the token <span
                                                              style="font-weight: bold"> {{
                                                              coinName(dao_namespace, dao_moduleName)
                                                            }} </span> to vote with in this Poll. <br/><br/> A combined minimum of <span
                                                              style="font-weight: bold"> {{rewardAmount}}   {{
                                                              coinName(dao_namespace, dao_moduleName)
                                                            }} </span> must be staked in this pool by the time this poll ends for this proposition to pass.</span>
                                                        </p>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Vote Title Section -->
                                                  <b-step-item :step="3" :clickable="false" label="Title">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold; font-size: 20px;">What is the Name of this Proposition?</span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Enter this Proposition's <span
                                                                  style="font-weight: bold">Name</span></h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Enter the <span style="font-weight: bold">name</span> of your new Voting Proposition.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">Proposition Name's</span> must be between <span style="font-weight: bold">3 and 40 characters in length</span>.
                                                                <br/><br/>
                                                                Example: Hire New Team Member
                                                                <br/><br/>
                                                                Once you have filled out your Proposition's Name, click "<span style="font-weight: bold">Next</span>" to proceed.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <div class="columns mt-2">

                                                          <div class="column">

                                                            <div style="border: 1px solid #4ae39e; padding-top: 20px; padding-bottom: 20px;">

                                                              <p style="color: #4ae39e !important">Enter the Title of this Proposition</p>

                                                              <br/>
                                                              <div class="divider is-centered"></div>
                                                              <br/>

                                                              <b-field
                                                                  style="margin-right: 100px;"
                                                                  custom-class="is-small"
                                                                  horizontal>
                                                                <template #label>
                                                                  <b-tooltip type="is-dark" multilined
                                                                             label="Enter the name of this DAO.">
                                                                    <label class="label pt-1 mt-2" style="width:50px;">Name:</label>
                                                                  </b-tooltip>
                                                                </template>
                                                                <div class="field-body">
                                                                  <div class="field has-addons">
                                                                    <p class="control is-expanded">
                                                                      <input v-model="vote_name"
                                                                             @change="[determinePropositionName(), checkIfGreaterThanThree(vote_name)]"
                                                                             @input="[determinePropositionName(), checkIfGreaterThanThree(vote_name)]"
                                                                             class="input select"
                                                                             type="text" placeholder="My DAO">
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </b-field>

                                                            </div>

                                                          </div>

                                                        </div>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                                          <span>Current Voting Poll Preview:</span>
                                                        </p>

                                                        <VotePoolCardPreview :pool_name="vote_name"
                                                                             pool_end_time="3/3/3333"
                                                                             :show_date_unknown="true"
                                                                             :pool_vote_token="coinName(dao_namespace, dao_moduleName)"
                                                                             pool_description="?"
                                                                             :pool_for_balance="0"
                                                                             :stake_final_vote_balance="0"
                                                                             :pool_against_balance="0"
                                                                             :pool_for_voters="0"
                                                                             :pool_against_voters="0"></VotePoolCardPreview>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                              <span>My Voting Proposition's name is: <span style="font-weight: bold"> {{ vote_name }}  </span></span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Proposition Description Section -->
                                                  <b-step-item :step="4" :clickable="false" label="Proposition">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold; font-size: 20px;">What is your Proposition?</span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Describe your <span
                                                                  style="font-weight: bold">proposition</span> below so voters know what they are voting over.</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Describe your <span style="font-weight: bold">new</span> Voting Proposition.
                                                                <br/><br/>
                                                                <span style="font-weight: bold">Proposition's</span> must be between <span style="font-weight: bold">3 and 400 characters in length</span>.
                                                                <br/><br/>
                                                                Example: Our DAO wishes to hire a new team member! We propose 100 tokens be used to hire a new team member.
                                                                <br/><br/>
                                                                Once you have filled out your Proposition, click "<span style="font-weight: bold">Next</span>" to proceed.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <div class="columns mt-2">

                                                          <div class="column">

                                                            <div style="border: 1px solid #4ae39e; padding-top: 20px; padding-bottom: 20px;">

                                                              <p style="color: #4ae39e !important">Describe your new Proposition</p>

                                                              <br/>
                                                              <div class="divider is-centered"></div>
                                                              <br/>

                                                              <b-field
                                                                  style="margin-right: 100px;"
                                                                  custom-class="is-small"
                                                                  horizontal>
                                                                <template #label>
                                                                  <b-tooltip type="is-dark" multilined
                                                                             label="Enter the name of this DAO.">
                                                                    <label class="label pt-1 mt-2" style="width:50px;">Proposition:</label>
                                                                  </b-tooltip>
                                                                </template>
                                                                <div class="field-body">
                                                                  <div class="field has-addons">
                                                                    <p class="control is-expanded">
                                                                       <textarea v-model="vote_proposition"
                                                                                 @input="[determineProposition(), checkIfGreaterThanThree(vote_proposition)]"
                                                                                 style="height:300px;"
                                                                                 class="input select"
                                                                                 type="textarea"
                                                                                 placeholder=""/>
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </b-field>

                                                            </div>

                                                          </div>

                                                        </div>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                                          <span>Current Voting Poll Preview:</span>
                                                        </p>

                                                        <VotePoolCardPreview :pool_name="vote_name"
                                                                             pool_end_time="1/1/2030"
                                                                             :pool_vote_token="coinName(dao_namespace, dao_moduleName)"
                                                                             :pool_description="vote_proposition"
                                                                             :pool_for_balance="1"
                                                                             :stake_final_vote_balance="0"
                                                                             :pool_against_balance="1"
                                                                             :pool_for_voters="1"
                                                                             :pool_against_voters="1"></VotePoolCardPreview>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                                          <span>How does everything look? Click <span style="font-weight: bold">Next</span> to proceed when your ready.</span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Poll Run Time Section -->
                                                  <b-step-item :step="5" :clickable="false" label="Time">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold; font-size: 20px;"> Voting End Time </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">When will this Voting Poll <span
                                                                  style="font-weight: bold">Finalize</span>?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-weight: bold; font-size: 17px;">When does this Voting Poll end?</span>
                                                                How <span style="font-weight: bold">long</span> will
                                                                your voting poll run for?
                                                                <br/>
                                                                <br/>
                                                                Select the date at which your Voting Poll ends.
                                                                <br/><br/>
                                                                This is the Date at which all votes are actually counted and users must have staked their tokens in the pool by this date for their vote to be counted.
                                                                <br/><br/>
                                                                During this time, anyone will be able to vote in your voting poll.
                                                                <br/><br/>
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <b-datetimepicker
                                                            v-model="vote_time"
                                                            @change="checkPropositionTime()"
                                                            @input="checkPropositionTime()"
                                                            placeholder="Voting Poll End Time"
                                                            :inline="true"
                                                            :min-datetime="minDatetime"
                                                            :max-datetime="maxDatetime">
                                                        </b-datetimepicker>

                                                        <div class="pt-1 pb-4">
                                                          <span v-if="campaignok === true"
                                                                class="error validation-msg-green is-info pt-1 pb-4">Time this Voting Poll finalizes.</span>

                                                          <span v-if="campaignok === false"
                                                                class="error is-danger validation-msg">Time must end in the future.</span>
                                                        </div>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                        <p class="blue-bg font-green mb-4">

                                                        <span>This Voting Poll will run for: <span
                                                            style="font-weight: bold"> {{
                                                            reduceBalance(Number(vote_timeseconds),0)
                                                          }} seconds total and end on ~{{vote_time}}.</span></span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Review Section -->
                                                  <b-step-item :step="6" :clickable="false" label="Review">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Review Your Voting Poll </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Here is a preview of your new <span
                                                                  style="font-weight: bold">Voting Poll</span> - does everything look ok?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Please review your <span style="font-weight: bold">Voting Poll</span> and whenever you are ready click "Create" to create it.
                                                                <br/><br/>
                                                                Once your Voting Poll is created, you will be taken to the DAOs page you created it on.
                                                                <br/><br/>
                                                                If everything looks ok to you below, press Create to create & start your voting poll now.
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                                          <span>Current Voting Poll Preview:</span>
                                                        </p>

                                                        <VotePoolCardPreview :pool_name="vote_name"
                                                                             :pool_end_time="vote_time"
                                                                             :pool_vote_token="coinName(dao_namespace, dao_moduleName)"
                                                                             :pool_description="vote_proposition"
                                                                             :pool_for_balance="2"
                                                                             :stake_final_vote_balance="0"
                                                                             :pool_against_balance="1"
                                                                             :pool_for_voters="1"
                                                                             :pool_against_voters="1"></VotePoolCardPreview>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mt-5 mb-4">
                                                          <span>How does everything look so far? Click Create when your ready to create your Voting Poll.</span>
                                                        </p>

                                                        <div class="columns is-mobile mt-6 mb-1 is-flex-direction-column inset-bg" style="background-color: rgba(0, 0, 0, 0.22) !important;">

                                                          <div v-if="vote_currentdao_info !== null" class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  Poll Creation Fee: {{vote_currentdao_info.pool_cost.decimal ? vote_currentdao_info.pool_cost.decimal : vote_currentdao_info.pool_cost }} {{coinName(vote_currentdao_info.subject_token.refName.namespace, vote_currentdao_info.subject_token.refName.name)}}
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  <input
                                                                      v-model="iagree"
                                                                      class="checkbox"
                                                                      type="checkbox"
                                                                  />
                                                                  I agree to the <a href="#">terms and conditions</a>.
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">

                                                            <p v-if="localPoolIdExists === true"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: Please fix the errors highlighted by the creation tool.</span>
                                                            </p>

                                                            <p v-if="iagree === false"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: Please review Terms and conditions.</span>
                                                            </p>

                                                            <button
                                                                v-if="localPoolIdExists === false && iagree === true"
                                                                @click="clickCreateVotingPool()"
                                                                class="button is-link">Create Voting Poll
                                                            </button>
                                                          </div>

                                                        </div>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Previous Next Buttons-->
                                                  <template
                                                      #navigation="{previous, next}">
                                                    <div class="container create-buttons">
                                                      <div class="columns">
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && vote_ok === true && localModuleExists === true"
                                                              outlined
                                                              type="is-danger"
                                                              :disabled="previous.disabled"
                                                              @click.prevent="[previous.action(), doStepBackwards()]">
                                                            Previous
                                                          </b-button>
                                                        </div>
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && vote_ok === true && localModuleExists === true"
                                                              outlined
                                                              type="is-success"
                                                              :disabled="next.disabled"
                                                              @click="[next.action(), doStepForward()]">
                                                            Next
                                                          </b-button>
                                                        </div>
                                                      </div>
                                                    </div>

                                                  </template>

                                                </b-steps>

                                              </div>

                                            </ValidationObserver>

                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!-- MAKE DAO TASK SECTION -->
                                    <div v-if="makeDaoSelection === '3'"
                                         class="column pool-item is-centered is-align-self-center">
                                      <div class="columns">
                                        <div class="column is-align-self-center inset-bg">
                                          <div class="section" style="margin-top: -100px">
                                            <div class="wave__content" style="top: 7px; z-index: 2">
                                              <div class="wave__header">
                                                <div class="wave__menu"></div>
                                              </div>
                                            </div>

                                            <ValidationObserver v-slot="{ valid }">

                                              <div class="box">

                                                <!-- Create collection Form -->
                                                <accordion>
                                                  <accordion-item>
                                                    <template slot="accordion-trigger">
                                                      <h1 class="title blue-bg info-icon" style="font-size: 25px; padding-top: 2rem; padding-bottom: 2rem;">Create a DAO Task</h1>
                                                    </template>
                                                    <template slot="accordion-content">
                                                      <div class="blue-bg-2 has-text-left">
                                                        <span style="font-weight: bold; font-size: 17px;">What is a DAO Task?</span>
                                                        <br/><br/>
                                                        <span style="font-weight: bold">Tasks</span> are what allow DAO Hive's to pay others for doing things.
                                                        <br/><br/>
                                                        With a Task, you set up a an agreement between a DAO and another person, and, once the task is completed and verified, the DAO will pay the person who performed the task from one of it's pools.
                                                        <br/><br/>
                                                        Tasks are specifically used for DAOs, so that DAOs can pay folks for performing tasks at the DAO.
                                                        <br/><br/>
                                                        Tasks get posted to the DAO Page you select when you create the Task so that people at the DAO can follow the entire history of how the task was performed, verified, and paid for by the DAO - Only parties involved in the Task may post messages on the task's message board.
                                                        <br/><br/>
                                                        This tool guides you through the process of creating a Task by presenting you with a series of questions and steps and ultimately generates a preview of your Task for your to review and publish - If everything looks ok in your preview at the end of this process, you may create your Task and launch it on the DAO of your choice.
                                                        <br/><br/>
                                                        For better examples of how Tasks work, make sure you visit a DAO on the Stake Factory website for a first hand experience with a Task.
                                                        <br/><br/>
                                                        Tasks are free to create (Just pay the Gas Fee).
                                                        <br/><br/>
                                                      </div>
                                                    </template>
                                                  </accordion-item>
                                                </accordion>

                                                <b-steps v-model="currentStep">

                                                  <!-- About Section -->
                                                  <b-step-item :step="0" :clickable="false" label="About">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4" style="background-color: #00000038 !important;">
                                                          <span style="font-weight: bold; font-size: 20px;">What is a Task?</span>
                                                        </p>

                                                          <TaskCardPreview
                                                              task_id="TASKID"
                                                              pool_subject_id="DAOID"
                                                              pool_name="A Example Task"
                                                              pool_status="Completed"
                                                              pool_description="This is an example task! Here you would write a description about the task being performed and how it relates to your DAO and how much someone is getting paid for performing the task."
                                                              pool_required_verifiers="1"
                                                              :pool_pay_account="currentName"
                                                              pool_pay_amount="10"
                                                              pool_pay_token="free.stake-token"
                                                              :pool_time_created="new Date(currentDate)"
                                                              :pool_due_date="new Date(currentDate)"
                                                              :pool_time_completed="new Date(currentDate)"
                                                              :pool_time_verified="new Date(currentDate)"
                                                              :pool_verified_by="currentName"
                                                              :pool_active="true"></TaskCardPreview>

                                                        <p style="color: #4ad1e3"> <span style="font-weight: bold">Tasks</span> are special DAO features that allow DAOs to pay people for performing actions at the DAO.</p>

                                                        <p class="blue-bg font-green mb-4 mt-4" style="padding-left: 6rem; padding-right: 6rem; background-color: #00000038 !important;">
                                                          <span style="font-weight: bold; font-size: 20px;">How do Tasks work?</span>
                                                          <br/><br/>
                                                          Tasks work by creating an agreement between a DAO and someone performing the task at the DAO- Tasks allow people to get paid for doing work at the DAO, and records the entire process on the DAO for all to see.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">Why would I make a Task?</span>
                                                          <br/><br/>
                                                          Tasks are designed specifically with DAOs in mind - Tasks are what allow DAOs to pay people for doing jobs at the DAO. Create a Task for your DAO whenever your DAO is ready to begin paying others that things for your DAO.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">What can I do with a Task?</span>
                                                          <br/><br/>
                                                          With a Task you can create an agreement between your DAO and another person performing the task. Once the task is completed and verified by a certain number of treasurers at your DAO, the DAO will pay the person who performed the task.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">How do I create a Task?</span>
                                                          <br/><br/>
                                                          This tool will guide you through a series of steps that allows you to design your Task and launch it at a DAO - Once you launch your Task it will immediately become active on your DAO.
                                                          <br/><br/>
                                                          Create your Task by deciding what job is being performed for the DAO and who is performing it. Use this Task Creation Tool to set up the agreement between the DAO and the person performing the Task including how much they will be paid and when the work is due. The tool will guide you through a series of steps and questions and generate a preview of your Task for you to review, and, if your satisfied with the preview of your Task you can immediately launch it on your DAO of choice.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">Who can participate in the Task?</span>
                                                          <br/><br/>
                                                          Only the account listed in the Task may post messages and mark the task as completed once they are finished doing their job. DAO Moderators may also post responses and messages on the task. DAO treasurers are the only accounts which can verify and pay the task after it is completed.
                                                          <br/><br/>
                                                          <span style="font-weight: bold; font-size: 20px;">What is the cost to make a Task?</span>
                                                          <br/><br/>
                                                          There is no cost to create a Task- Just pay Kadena gas fees.
                                                          <br/><br/>
                                                        </p>




                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <b-switch
                                                            class="mb-5 mt-1"
                                                            v-model="localModuleExists"
                                                            :rounded="true"
                                                            :outlined="true"
                                                            size="is-large"
                                                            type="is-success"
                                                            :left-label='false'
                                                            :passive-type='null'>{{ localModuleExists ? "OK" : "OK" }}
                                                        </b-switch>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- dao id Section -->
                                                  <b-step-item :step="1" :clickable="false" label="ID">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Create collection Section -->
                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold; font-size: 17px;"> First things first - What DAO is this Task for? </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">ID</span>
                                                                of the <span style="font-weight: bold">DAO</span> this Task is being created for?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                All DAO Hives have a <span style="font-weight: bold">DAO ID</span>.
                                                                <br/><br/>
                                                                Enter the DAO ID your Task will appear on.
                                                                <br/><br/>
                                                                Once you have entered the ID of the DAO this Task will be created on, click "<span style="font-weight: bold">Next</span>" to proceed - Your Task will <span style="font-weight: bold">appear</span> on the DAO with the ID you enter once it is created using this tool.
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <div class="columns" style="margin-top: 25px;">

                                                          <div class="column " style="margin-left: 120px;">

                                                            <div style="margin-top: 80px; border: 1px solid #4ae39e; padding-top: 20px; padding-bottom: 20px;">

                                                              <p style="color: #4ae39e !important">Enter DAO Hive ID</p>

                                                              <br/>
                                                              <div class="divider is-centered"></div>
                                                              <br/>

                                                              <b-field
                                                                  custom-class="is-small"
                                                                  horizontal>
                                                                <template #label>
                                                                  <b-tooltip type="is-dark" multilined
                                                                             label="Enter the ID of the DAO this Voting Poll will appear on">
                                                                    <label class="label pt-1" style="width:50px;">DAO ID:</label>
                                                                  </b-tooltip>
                                                                </template>
                                                                <div class="field-body">
                                                                  <div class="field has-addons">
                                                                    <p class="control is-expanded">
                                                                      <input v-model="vote_daoid"
                                                                             @change="[getDAOInfo(vote_daoid), verifyDaoExists(vote_daoid)]"
                                                                             @input="[getDAOInfo(vote_daoid), verifyDaoExists(vote_daoid)]"
                                                                             class="input select"
                                                                             type="text" placeholder="my-new-dao">
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </b-field>

                                                            </div>

                                                          </div>

                                                          <div class="column is-flex is-justify-content-center">

                                                            <div class="columns is-flex-direction-column is-justify-content-center" >
                                                              <div class="column">
                                                                <DaoCardPreview :dao_id="vote_currentdao.subject_id" :dao_name="vote_currentdao.name" :dao_description="vote_currentdao.description" :dao_image="vote_currentdao.icon" ></DaoCardPreview>
                                                              </div>
                                                              <div class="column">
                                                                <p style="color: #4ad1e3 !important">Create a Task on this DAO?</p>
                                                              </div>
                                                            </div>






                                                          </div>

                                                        </div>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                        <p v-if="localModuleExists === true"
                                                           class="blue-bg font-green mb-4 mt-2">
                                              <span>This Task will be created on the DAO: <span
                                                  style="font-weight: bold"> {{
                                                  vote_daoid
                                                }}</span></span>
                                                        </p>

                                                        <p v-if="localModuleExists === false" class="blue-bg is-danger">
                                                          <span style="color:#f9868b">Error: The DAO ID you have entered was not found, please try another.</span>
                                                        </p>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Task Title / Description Section -->
                                                  <b-step-item :step="2" :clickable="false" label="Description">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Create Pair Section -->
                                                        <p class="blue-bg font-green mb-4">
                                                          <span style="font-weight: bold; font-size: 20px;"> Task </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">title</span>
                                                                of this Task?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                <span style="font-weight: bold; font-size: 17px;">What token do users have to use to vote with?</span>
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>



                                                        <ValidationObserver v-if="currentStep === 2">
                                                          <b-field
                                                              style="margin-top: 20px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What is this Task's title?">
                                                                <label class="label pt-1" >Task
                                                                  Title:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <ValidationProvider
                                                                      :rules="{required: true}"
                                                                      vid="dao_moduleName"
                                                                      name="Task Title Name"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="task_name"
                                                                           @change="[checkIfGreaterThanThree(task_name), checkIfLessThan40(task_name)]"
                                                                           @input="[checkIfGreaterThanThree(task_name), checkIfLessThan40(task_name)]"
                                                                           class="input select"
                                                                           type="text" placeholder="my-token">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span
                                                                        class="error is-danger validation-msg is-hidden">{{
                                                                        valid[0]
                                                                      }}</span>
                                                                  </ValidationProvider>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                        </ValidationObserver>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon"><span style="font-weight: bold">Describe</span> your task</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                <span style="font-weight: bold; font-size: 17px;">Use the area below to talk about your Task. Describe your task to the best of your abilities and inform the DAO of what your task is, who it is paying, and what it is paying them for. </span>
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>


                                                        <b-field
                                                            style="margin-top: 20px;"
                                                            custom-class="is-small"
                                                            horizontal>
                                                          <template #label>
                                                            <b-tooltip type="is-dark" multilined
                                                                       label="Enter the name of this DAO.">
                                                              <label class="label pt-1 mt-2" >Task:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                       <textarea v-model="task_description"
                                                                                 @input="[checkIfGreaterThanThree(task_description)]"
                                                                                 style="height:300px;"
                                                                                 class="input select"
                                                                                 type="textarea"
                                                                                 placeholder=""/>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p>Current Preveiw of your Task:</p>

                                                        <TaskCardPreview
                                                            task_id="TASKID"
                                                            pool_subject_id="DAOID"
                                                            :pool_name="task_name"
                                                            pool_status="Pending"
                                                            :pool_description="task_description"
                                                            pool_required_verifiers="1"
                                                            pool_pay_account="?"
                                                            pool_pay_amount="?"
                                                            pool_pay_token="?"
                                                            :pool_time_created="new Date(currentDate)"
                                                            pool_due_date="?"
                                                            pool_time_completed="?"
                                                            pool_time_verified="?"
                                                            pool_verified_by="?"
                                                            :pool_active="true"></TaskCardPreview>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4 mt-2" v-if="collectionAnyToken === false">
                                                          <span>My Tasks Title is: <span
                                                              style="font-weight: bold"> {{
                                                              task_name
                                                            }} </span> </span>
                                                        </p>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Task Token Section -->
                                                  <b-step-item :step="3" :clickable="false" label="Token">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <!-- Create Pair Section -->
                                                        <p class="blue-bg font-green mb-4">
                                                          <span style="font-weight: bold; font-size: 20px;"> Task Payment </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What is the <span
                                                                  style="font-weight: bold">account</span> that will receive payment after completing this this Task?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                <span style="font-weight: bold; font-size: 17px;">Enter the account that will be performing this task and receiving payment after the task is performed.</span>
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>



                                                        <ValidationObserver v-if="currentStep === 3">
                                                          <b-field
                                                              style="margin-top: 20px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What account will perform this task and receive pay after performing this task?">
                                                                <label class="label pt-1" style="width:50px;">Task
                                                                  Account:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <ValidationProvider
                                                                      :rules="{required: true}"
                                                                      vid="task_payaccount"
                                                                      name="Task Pay Account"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="task_payaccount"
                                                                           class="input select"
                                                                           type="text" placeholder="my-token">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span v-if="!errors[0]"
                                                                          class="error validation-msg-green is-info">Account that will perform the task and receive payment after task verification.</span>
                                                                    <span
                                                                        class="error is-danger validation-msg is-hidden">{{
                                                                        valid[0]
                                                                      }}</span>
                                                                  </ValidationProvider>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                        </ValidationObserver>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">What <span
                                                                  style="font-weight: bold">token</span>
                                                                will the DAO pay this account with?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                <span style="font-weight: bold; font-size: 17px;">What token will the DAO pay with once the task is completed?</span>
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>



                                                        <ValidationObserver v-if="currentStep === 3">
                                                          <b-field
                                                              style="margin-top: 20px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What is the contract name / module name of the payment token?">
                                                                <label class="label pt-1" >Token
                                                                  Module:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">
                                                                  <ValidationProvider
                                                                      :rules="{required: true}"
                                                                      vid="dao_moduleName"
                                                                      name="Dao Token Module Name"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="task_modulename"
                                                                           @change="verifyTokenExists(task_namespace, task_modulename)"
                                                                           @input="verifyTokenExists(task_namespace, task_modulename)"
                                                                           class="input select"
                                                                           type="text" placeholder="my-token">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span
                                                                        class="error is-danger validation-msg is-hidden">{{
                                                                        valid[0]
                                                                      }}</span>
                                                                  </ValidationProvider>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                          <b-field
                                                              style="margin-top:10px;"
                                                              custom-class="is-small"
                                                              horizontal>
                                                            <template #label>
                                                              <b-tooltip type="is-dark" multilined
                                                                         label="What is the contract namespace / module namespace of the payment token?">
                                                                <label class="label pt-1" >Token
                                                                  Namespace:</label>
                                                              </b-tooltip>
                                                            </template>
                                                            <div class="field-body">
                                                              <div class="field has-addons">
                                                                <p class="control is-expanded">

                                                                  <ValidationProvider
                                                                      :rules="{required: false}"
                                                                      vid="dao_namespace"
                                                                      name="Dao Module Namespace"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="task_namespace"
                                                                           @change="verifyTokenExists(task_namespace, task_modulename)"
                                                                           @input="verifyTokenExists(task_namespace, task_modulename)"
                                                                           class="input select"
                                                                           type="text" placeholder="free">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span class="error is-danger validation-msg">{{
                                                                        valid[0]
                                                                      }}</span>
                                                                  </ValidationProvider>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </b-field>

                                                        </ValidationObserver>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon"><span style="font-weight: bold">How many tokens</span> will be paid to {{task_payaccount}} after the task is completed?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                <span style="font-weight: bold; font-size: 17px;">How many tokens will the DAO be paying this account once the account completes the Task?</span>
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <b-field v-if="currentStep === 3"
                                                                 style="margin-top:20px;"
                                                                 custom-class="is-small"
                                                                 horizontal>
                                                          <template #label>

                                                            <b-tooltip type="is-dark"
                                                                       label="How many tokens will the DAO pay once this task is completed?">
                                                              <label class="label pt-1" style="width:50px;">Amount to Pay:</label>
                                                            </b-tooltip>
                                                          </template>
                                                          <div class="field-body">
                                                            <div class="field has-addons">
                                                              <p class="control is-expanded">
                                                                <ValidationObserver
                                                                    v-if="currentStep === 3">
                                                                  <ValidationProvider
                                                                      :rules="{required: true, greaterthan: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                                                      vid="reward_amount" name="Reward Amount"
                                                                      v-slot="{ errors, valid }">
                                                                    <input v-model="rewardAmount"
                                                                           @change="checkValidInput(valid)"
                                                                           @input="checkValidInput(valid)"
                                                                           class="input select" type="number"
                                                                           step="0.01"
                                                                           placeholder="Amount of Reward Token">
                                                                    <span class="error is-danger validation-msg">{{
                                                                        errors[0]
                                                                      }}</span>
                                                                    <span v-if="!errors[0]"
                                                                          class="error validation-msg-green is-info">Total amount of {{coinName(task_namespace, task_modulename)}} tokens to pay {{task_payaccount}}.</span>
                                                                    <br/>
                                                                  </ValidationProvider>
                                                                </ValidationObserver>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </b-field>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p>Current Preveiw of your Task:</p>

                                                        <TaskCardPreview
                                                            task_id="TASKID"
                                                            pool_subject_id="DAOID"
                                                            :pool_name="task_name"
                                                            pool_status="Pending"
                                                            :pool_description="task_description"
                                                            pool_required_verifiers="1"
                                                            :pool_pay_account="task_payaccount"
                                                            :pool_pay_amount="rewardAmount"
                                                            :pool_pay_token="coinName(task_namespace, task_modulename)"
                                                            :pool_time_created="new Date(currentDate)"
                                                            pool_due_date="?"
                                                            pool_time_completed="?"
                                                            pool_time_verified="?"
                                                            pool_verified_by="?"
                                                            :pool_active="true"></TaskCardPreview>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                        <p v-if="localModuleExists === false" class="blue-bg font-green mb-4 mt-2">
                                                         <span class="error is-danger validation-msg" style="font-size: 16px;">The token you entered cannot be found, please try again.</span>
                                                        </p>

                                                        <p v-if="rewardAmount === '0.0'" class="blue-bg font-green mb-4 mt-2">
                                                          <span class="error is-danger validation-msg" style="font-size: 16px;">Amount to pay must be greater than 0.0.</span>
                                                        </p>

                                                        <p class="blue-bg font-green mb-4 mt-2" v-if="localModuleExists === true">
                                                          <span>The account performing this task is: <span
                                                              style="font-weight: bold"> {{task_payaccount
                                                            }}.</span> <br/><br/> {{task_payaccount
                                                            }} will be paid <span
                                                              style="font-weight: bold"> {{rewardAmount}}   {{
                                                              coinName(task_namespace, task_modulename)
                                                            }} </span> once this task is completed and verified.</span>
                                                        </p>

                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Poll Run Time Section -->
                                                  <b-step-item :step="4" :clickable="false" label="Due Date">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold; font-size: 20px;"> Task Due Date </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">When will this Task become <span
                                                                  style="font-weight: bold">due</span>?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2 has-text-left">
                                                                <span style="font-weight: bold; font-size: 17px;">When is this task expected to be completed?</span>
                                                                <br/>
                                                                <br/>
                                                                Select the date at which your Task is due to be completed by the person performing the Task.
                                                                <br/><br/>
                                                                Tasks should be completed before the time they are due. This is the Date at which the Task will be due - Tasks can always be completed early by the account performing the task.
                                                                <br/><br/>
                                                                Task Due Dates are for record keeping purposes - No actual consequences are enforced when someone fails to complete a Task before the due date expires (unless done so by a DAO), the task is simply marked as 'Due' on a DAO's Hive once a due date expires and a task has not yet been completed.
                                                                <br/><br/>
                                                                Tasks can always be completed after their due dates, and Task due dates can always be extended if needed by DAO treasurers.
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <b-datetimepicker
                                                            v-model="vote_time"
                                                            @change="checkPropositionTime()"
                                                            @input="checkPropositionTime()"
                                                            placeholder="Task Due Date"
                                                            :inline="true"
                                                            :min-datetime="minDatetime">
                                                        </b-datetimepicker>

                                                        <div class="pt-1 pb-4">
                                                          <span v-if="campaignok === true"
                                                                class="error validation-msg-green is-info pt-1 pb-4">Date this Task becomes Due.</span>

                                                          <span v-if="campaignok === false"
                                                                class="error is-danger validation-msg">Due Dates must end in the future.</span>
                                                        </div>


                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>


                                                        <p class="blue-bg font-green mb-4">

                                                        <span>This Task will become due in: <span
                                                            style="font-weight: bold"> {{
                                                            reduceBalance(Number(vote_timeseconds),0)
                                                          }} seconds and should be completed by ~{{vote_time}}.</span></span>
                                                        </p>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!--  Review Section -->
                                                  <b-step-item :step="5" :clickable="false" label="Review">

                                                    <section class="pb-6 pool-card-margin">
                                                      <div class="message-card" type="is-info">

                                                        <p class="blue-bg font-green mb-4">
                                              <span
                                                  style="font-weight: bold"> Review Your New Task </span>
                                                        </p>

                                                        <accordion>
                                                          <accordion-item>
                                                            <template slot="accordion-trigger">
                                                              <h1 class="blue-bg info-icon">Here is a preview of your new <span
                                                                  style="font-weight: bold">Task</span> - does everything look ok?</h1>
                                                            </template>
                                                            <template slot="accordion-content">
                                                              <div class="blue-bg-2">
                                                                Please review your <span style="font-weight: bold">Task</span> and whenever you are ready click "Create" to create it.
                                                                <br/><br/>
                                                                Once your Task is created, you will be taken to the DAOs page you created it on.
                                                                <br/><br/>
                                                                If everything looks ok to you below, press Create to create & start your Task now.
                                                                <br/><br/>
                                                              </div>
                                                            </template>
                                                          </accordion-item>
                                                        </accordion>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mb-4">
                                                          <span>Current Task Preview:</span>
                                                        </p>

                                                        <TaskCardPreview
                                                            task_id="TASKID"
                                                            pool_subject_id="DAOID"
                                                            :pool_name="task_name"
                                                            pool_status="Pending"
                                                            :pool_description="task_description"
                                                            pool_required_verifiers="1"
                                                            :pool_pay_account="task_payaccount"
                                                            :pool_pay_amount="rewardAmount"
                                                            :pool_pay_token="coinName(task_namespace, task_modulename)"
                                                            :pool_time_created="new Date(currentDate)"
                                                            :pool_due_date="new Date(vote_time)"
                                                            pool_time_completed="?"
                                                            pool_time_verified="?"
                                                            pool_verified_by="?"
                                                            :pool_active="true"></TaskCardPreview>

                                                        <br/>
                                                        <div class="divider is-centered"></div>
                                                        <br/>

                                                        <p class="blue-bg font-green mt-5 mb-4">
                                                          <span>How does everything look so far? Click Create when your ready to create your DAO Task.</span>
                                                        </p>

                                                        <div class="columns is-mobile mt-6 mb-1 is-flex-direction-column inset-bg" style="background-color: rgba(0, 0, 0, 0.22) !important;">

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  Task Creation Fee: Free!
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">
                                                            <div class="field" style="background-color: rgb(35 48 56) !important;">
                                                              <p class="control">
                                                                <label class="checkbox mt-2">
                                                                  <input
                                                                      v-model="iagree"
                                                                      class="checkbox"
                                                                      type="checkbox"
                                                                  />
                                                                  I agree to the <a href="#">terms and conditions</a>.
                                                                </label>
                                                              </p>
                                                            </div>
                                                          </div>

                                                          <div class="column is-12" style="z-index:1">

                                                            <p v-if="localPoolIdExists === true"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: Please fix the errors highlighted by the creation tool.</span>
                                                            </p>

                                                            <p v-if="iagree === false"
                                                               class="blue-bg font-red mb-4">
                                                              <span>Error: Please review Terms and conditions.</span>
                                                            </p>

                                                            <button
                                                                v-if="localPoolIdExists === false && iagree === true"
                                                                @click="clickCreateTask()"
                                                                class="button is-link">Create Task
                                                            </button>
                                                          </div>

                                                        </div>


                                                      </div>

                                                    </section>

                                                  </b-step-item>

                                                  <!-- Previous Next Buttons-->
                                                  <template
                                                      #navigation="{previous, next}">
                                                    <div class="container create-buttons">
                                                      <div class="columns">
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && vote_ok === true && localModuleExists === true"
                                                              outlined
                                                              type="is-danger"
                                                              :disabled="previous.disabled"
                                                              @click.prevent="[previous.action(), doStepBackwards()]">
                                                            Previous
                                                          </b-button>
                                                        </div>
                                                        <div class="column">
                                                          <b-button
                                                              v-if="valid === true && vote_ok === true && localModuleExists === true"
                                                              outlined
                                                              type="is-success"
                                                              :disabled="next.disabled"
                                                              @click="[next.action(), doStepForward()]">
                                                            Next
                                                          </b-button>
                                                        </div>
                                                      </div>
                                                    </div>

                                                  </template>

                                                </b-steps>

                                              </div>

                                            </ValidationObserver>

                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                  </div>
                                </div>


                              </div>

                            </div>

                          </section>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column" style="z-index: 1; padding-bottom:500px"></div>
            </div>


          </section>


          <!--     Empty div -->
          <div class="column mb-6" style="z-index: 1"></div>
        </div>

        <!--      bottom spacer div-->
        <div style="margin-bottom: 10%"></div>

      </section>

    </transition>

    <!--Connect Wallet Modal-->
    <b-modal v-if="this.showCreatePoolModal === true" v-model="showCreatePoolModal" :width="640" scroll="keep"
             style="border-radius:20px;">

      <div class="form-bg" style="border-radius:20px;">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-md-offset-4 blue-bg">
              <div class="form-container form-shadow">
                <div class="form-icon"><img src="../assets/stake-factory-icon.svg" width="70px"></div>
                <div class="container pb-4">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader">
                      A new signature request was just sent to your wallet:
                    </h3>
                  </div>
                </div>
                <b-message class="is-success" style="background-color: rgb(9 40 52);">
                  <div class="columns is-centered pt-4 pb-0">
                    <div class="column is-centered" style="overflow-wrap: anywhere !important;">
                      {{ this.getCurrentSignRequest() }}
                    </div>
                  </div>
                </b-message>

                <div class="container mb-2">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader">
                      Review and sign the transaction in your wallet and click confirm below:
                    </h3>
                  </div>
                </div>

                <form class="form-horizontal">
                  <div class="columns is-mobile is-centered">
                    <div class="column">
                      <div class="notification has-text-centered inset-bg-rounded2" style="background-color: #2d3a45;">
                        <div @click="closeSignatureModal" class="btn2 btn-lg stake-btn"
                             style="border-radius: 1rem !important;">
                          <span>Confirm</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
//import vue store variables to this page
import {mapGetters, mapState} from "vuex";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {double, regex, required} from "vee-validate/dist/rules";
import Accordion from "./accordion.vue";
import AccordionItem from "./accordion-item";
import FlipCardNFTNFT from "./FlipCardNFTNFT.vue";
import FlipCard from "./FlipCard.vue";
import PoolCardPreview from "./PoolCardPreview.vue";
import FlipCardPreview from "./FlipCardPreview.vue";
import FlipCardMintPreview from "./FlipCardMintPreview.vue";
import CollectionCardPreview from "./CollectionCardPreview.vue";
import CrowdFundPoolCardPreview from "./CrowdFundPoolCardPreview.vue";
import LiquidityPoolCardPreview from "./LiquidityPoolCardPreview.vue";
import VestingPoolCardPreview from "./VestingPoolCardPreview.vue";
import DaoCardPreview from "./DaoCardPreview.vue";
import VotePoolCardPreview from "./VotePoolCardPreview";
import TaskCardPreview from "./TaskCardPreview";


//Rules for validation
extend("required", {
  ...required,
  message: "This field is required"
});
extend("double", {
  ...double,
  message: "Example: 100.00"
});
extend("regex", {
  ...regex,
  message: "Decimal required, Example: 0.0 or 100.0"
});
extend("lpNotKdaKda", {
  validate(value, args) {
    console.log("value " + value);
    console.log("args " + args.test);
    return value !== args.test;
  },
  params: ["test"],
  message: "LP Token Pairs must be different"
});
extend("lpNotSameCustom", {
  validate(value, args) {
    console.log("value " + value);
    console.log("args " + args.test[0] + args.test[1] + args.test[2] + args.test[3]);
    return args.test[0] + args.test[1] !== args.test[2] + args.test[3];
  },
  params: ["test"],
  message: "LP Token Pairs must be different"
});
extend("greaterthan", {
  params: ["target"],
  validate(value, {target}) {
    return value > target;
  },
  message: "This field must be greater than {target}"
});
extend("greaterthanequalto", {
  params: ["target"],
  validate(value, {target}) {
    return value >= target;
  },
  message: "This field must be greater than or equal to {target}"
});
extend("lessthanequalto", {
  params: ["target"],
  validate(value, {target}) {
    return value <= target;
  },
  message: "This field must be less than or equal to {target}"
});
extend("hardcap", {
  params: ["target"],
  validate(value, {target}) {
    return value >= target;
  },
  message: "Hard Cap must be greater than or equal to (Sell Quantity * Sell Price)"
});
extend("lessthan", {
  params: ["target"],
  validate(value, {target}) {
    return value <= target;
  },
  message: "This field must be less than or equal to {target}"
});
extend("chargreaterthan", {
  params: ["target"],
  validate(value, {target}) {
    return value.length >= target;
  },
  message: "This field must be greater than or equal to {target} characters in length"
});

extend("charlessthan", {
  params: ["target"],
  validate(value, {target}) {
    return value.length <= target;
  },
  message: "This field must be less than or equal to {target} characters in length"
});


//local state
export default {
  name: "CreatePoolPage",
  components: {
    ValidationProvider,
    ValidationObserver,
    Accordion,
    AccordionItem,
    FlipCardNFTNFT,
    FlipCard,
    PoolCardPreview,
    FlipCardPreview,
    FlipCardMintPreview,
    CrowdFundPoolCardPreview,
    LiquidityPoolCardPreview,
    CollectionCardPreview,
    VestingPoolCardPreview,
    DaoCardPreview,
    VotePoolCardPreview,
    TaskCardPreview
  },
  data() {
    const now = new Date().getTime();
    const min = new Date();
    min.setDate(min.getDate());
    min.setHours(0);
    min.setMinutes(0);
    min.setSeconds(0);
    const max = new Date();
    max.setDate(max.getDate() + 30);
    max.setHours(18);
    max.setMinutes(0);
    max.setSeconds(0);
    const maxwl = new Date();
    maxwl.setDate(maxwl.getDate() + 15);
    maxwl.setHours(18);
    maxwl.setMinutes(0);
    maxwl.setSeconds(0);
    return {
      debug: false,
      myaccount: null,
      showLoadingModal: true,
      showCreatePoolModal: false,
      localPoolIdExists: false,
      localModuleExists: null,
      currentStep: 0,
      currentPage: 1,
      makeNftSelection: "0",
      iagree: false,
      welcome: true,
      wlok: true,
      campaignok: true,
      makingPool: false,
      minDatetime: min,
      maxDatetime: max,
      maxWlDatetime: maxwl,
      currentDate: now,
      userAccount: null,
      wlInSeconds: 0,
      activeTab: "-1",
      showStakingPools: true,
      showVestingPools: false,
      showLiquidityPools: false,
      stopAutoField: false,
      inputisvalid: false,
      stakeType: 1, //1 = fungiv2 | 2 = ASWAP LP
      rewardType: 1, //1 = fungiv2 | 2 = poly-fungi-v1 | 3 = poly-fungi-v2
      customFungiv2Reward: false,
      customFungiv2Stake: false,
      customLPStakeToken: false,
      currentName: "Me",
      fungiv2RewardModuleName: "stake-token",
      fungiv2RewardModuleNamespace: "free",
      fungiv2StakeModuleName: "stake-token",
      fungiv2StakeModuleNamespace: "free",
      lpStakeTokenPoolName: "stake-factory",
      lp1StakeModuleNamespace: "",
      lp1StakeModuleName: "stake-token",
      lp2StakeModuleNamespace: "free",
      lp2StakeModuleName: "stake-token",
      polyFungiV1ID: "STAKE-NFT",
      polyFungiV1ModuleNamespace: "free",
      polyFungiV1ModuleName: "factory-poly-fungible-reference",
      polyFungiV2ID: "My-NFT-ID",
      polyFungiV2ModuleNamespace: "free",
      polyFungiV2ModuleName: "my-token",
      polyFungiV2StakeImage: "http://www.myimage.com",
      polyFungiV1StakeID: "My-NFT-ID",
      polyFungiV1StakeModuleNamespace: "free",
      polyFungiV1StakeModuleName: "my-token",
      polyFungiV2StakeID: "My-NFT-ID",
      polyFungiV2StakableIDs: "",
      polyFungiV2StakableIDs_list: [],
      polyFungiV2StakeModuleNamespace: "free",
      polyFungiV2StakeModuleName: "my-token",
      stakeFungiV2Type: "coin",
      rewardFungiV2Type: "coin",
      lpToken1Type: "coin",
      lpToken2Type: "free.anedak",
      lpValid: true,
      rewardAmount: "0.0",
      rewardDuration: "1.0",
      rewardDurationAmount: "0.00011574019",
      withdrawWaitTime: "0.0",
      rewardWaitTime: "0.0",
      poolId: "my-pool-id",
      poolName: "My Pool Name",
      t_stakeTokenImagePolyfungiv1: "0",
      t_rewardTokenImagePolyfungiv1: "0",
      t_stakeTokenNamePolyfungiv1: "0",
      t_rewardTokenNamePolyfungiv1: "0",
      t_stakeTokenDescriptionPolyfungiv1: "0",
      t_rewardTokenDescriptionPolyfungiv1: "0",
      t_rewardTokenImageFungiv2Polyfungiv1: "0",
      t_stakeTokenImagePolyfungiv2: "0",
      t_rewardTokenImagePolyfungiv2: "0",
      t_stakeTokenNamePolyfungiv2: "0",
      t_rewardTokenNamePolyfungiv2: "0",
      t_stakeTokenDescriptionPolyfungiv2: "0",
      t_rewardTokenDescriptionPolyfungiv2: "0",
      t_rewardTokenImageFungiv2Polyfungiv2: "0",
      errors: [],
      liquidityPairTokenAName: "stake-token",
      liquidityPairTokenANamespace: "free",
      liquidityPairTokenBName: "coin",
      liquidityPairTokenBNamespace: "",
      liquidityPoolName: "my-lp-pool",
      liquidityPoolFee: "0.3",
      liquidityPoolFeeFinal: 0.003,
      localPoolIdExists1: false,
      localPoolIdExists2: false,
      crowdFundSellTokenModuleName: "stake-token",
      crowdFundSellTokenModuleNamespace: "free",
      crowdFundPledgeTokenModuleNamespace: "",
      crowdFundPledgeTokenModuleName: "coin",
      crowdFundSellTokenPrice: "10.0",
      crowdFundSellTokenQuantity: "1.0",
      crowdFundMaxBuyQuantity: "1.0",
      crowdFundHardCap: "10.0",
      hardCapOk: true,
      crowdFundSoftCap: "10.0",
      crowdFundPairQuantity: "1.0",
      crowdFundCampaignTime: "604800.0",
      crowdFundCampaignTime2: null,
      campaignTimeInSeconds: 0,
      descriptonTooLong: false,
      hasWhitelist: true,
      crowdFundWhitelistTime: "0.0",
      crowdFundWhitelistTime2: null,
      crowdFundWhitelist: "",
      crowdFundWhitelist_list: [],

      crowdFundWhitelistMaxCanBuy: "0.0",
      crowdFundInsuranceTime: "604800.0",
      crowdFundInsuranceTime2: null,
      crowdFundPoolName: "My-Crowdfunding-Pool",
      crowdFundLiquidityPoolName: "My-Liquidity-Pool",
      crowdFundLiquidityPoolSwapFee: "0.3",
      crowdFundLiquidityPoolSwapFeeFinal: 0.003,
      crowdFundPoolId: "my-pool-id",
      crowdFundLiquidityPoolId: "my-pool-id",
      crowdFundPoolDescription: "My crowdfunding pool which will raise funds for my token",
      isCreatingNFTCollection: false,
      isCreatingNFTCollection_poolid: null,
      isCreatingNFT: false,
      isCreatingNFT_poolid: null,
      isMakingTransaction: false,
      isMakingCreatePoolTransaction: false,
      isCreatingDAOHive: false,
      isCreatingDAOHive_poolid: null,
      isCreatingDAOHive_message: null,
      isCreatingDAOHive_contract: null,
      isCreatingVotingPool: false,
      isCreatingVotingPool_poolid: null,
      isCreatingVotingPool_message: null,
      isCreatingVotingPool_contract: null,
      isCreatingTask: false,
      isCreatingTask_poolid: null,
      isCreatingTask_message: null,
      isCreatingTask_contract: null,
      isCreatingStakingPool: false,
      isCreatingStakingPool_poolid: null,
      isCreatingStakingPool_message: null,
      isCreatingStakingPool_contract: null,
      isCreatingVestingPool: false,
      isCreatingVestingPool_poolid: null,
      isCreatingVestingPool_message: null,
      isCreatingVestingPool_contract: null,
      isCreatingLiquidityPool: false,
      isCreatingLiquidityPool_pair: null,
      isCreatingLiquidityPool_poolid: null,
      isCreatingCrowdfundingPool: false,
      isCreatingCrowdfundingPool_message: "Creating crowdfunding pool",
      isCreatingCrowdfundingPool_poolid: null,
      isMassMintingNfts: false,
      isMassMintingNfts_poolid: null,
      insuranceok: true,
      insurancetimeinseconds: 0,
      //collection
      collectionID: "my-collection-id",
      collectionLink: "https://www.mylink.com",
      collectionImageLink: "https://emojipedia-us.s3.amazonaws.com/source/skype/289/cut-of-meat_1f969.png",
      collectionSignature: "my special signature",
      make_nft_collectionModuleName: "stake-token",
      make_nft_collectionNamespace: "free",
      makenft_nftID: "my-nft-id",
      makenft_name: "The Awesome NFT",
      makenft_description: "The Awesome NFT of Awesomeness +10",
      makenft_image: "https://www.link_to_my_image.com",
      makenft_a1_final: [],
      makenft_a2_final: [],
      makenft_a3_final: [],
      makenft_a1: "",
      makenft_a2: "",
      makenft_a3: "",
      makenft_a1_placeholder: 'my string attribute 1' + '\r\n' + 'my string attribute 2' + '\r\n' + 'my string attribute 3',
      makenft_a2_placeholder: '1.0' + '\r\n' + '10.0' + '\r\n' + '3.14',
      makenft_a3_placeholder: '1' + '\r\n' + '100' + '\r\n' + '883945',
      makenft_collectionID: "my-collection-id",
      makenft_massNfts: `{"id" : "my_nft_id","collection-id": "my_collection_id","name": "My NFT's Name","description": "The description of my nft","image": "https://emojipedia-us.s3.amazonaws.com/source/skype/289/cut-of-meat_1f969.png","a1": ["my attribute 1", "my attribute 2", "my attribute 3"],"a2": [1.0, 2.2, 33.34],"a3": [0, 349, 999]}`,
      makenft_massNftsFinal: [],
      makenft_massNftsFinalJSON: [{"id" : "my_nft_id", "collection-id": "my_collection_id", "name": "My NFT's Name", "description": "The description of my nft", "image": "https://emojipedia-us.s3.amazonaws.com/source/skype/289/cut-of-meat_1f969.png", "a1": ["my attribute 1", "my attribute 2", "my attribute 3"], "a2": [1.0, 2.2, 33.34], "a3": [0, 349, 999]}],
      finishedCreating: false,
      finishedCreating_message: "Your NFT has been successfully created!",
      isMakingItem: "pool",
      collectionAnyToken: false,
      //whitelist
      whitelist_wlInSeconds: 0,
      whitelist_collectionID: "my-collection",
      whitelist_collectionData: [],
      whitelist_maxbuy: 1,
      whitelist_list: [],
      whitelist_whitelist: "",
      whitelist_anyoneCanBuy: false,
      whitelist_time2: null,
      whitelist_time: "604800.0",
      whitelist_PublicTimeInSeconds: null,
      whitelist_publictime2: null,
      whitelist_publictime: null,
      nftwlok: true,
      isCreatingNFTWhitelist_poolid: "",
      isCreatingNFTWhitelist: false,
      whitelist_price: "10.0",
      stake_CollectionID: "my-collection-id",
      stake_CollectionLink: "?",
      stake_CollectionSignature: "?",
      stake_CollectionSupply: "?",
      stake_CollectionToken: "?",
      stake_CollectionImage: "?",
      //vesting
      canGoNext: true,
      vestingPool_count: 0,
      vestingPool_tokensLeft: 0,
      vestingPool_tokensLeftTotal: 100,
      vestingPool_percentLeftTotal: 100,
      vestingPool_percentLeft: 0,
      vestingPool_description: "My teams vesting pool",
      vestingPool_account: "k:team-member-account",
      vestingPool_accounts: [],
      duplicateVestingPoolMember: false,
      vestingPool_percentage: "20.0",
      vestingPool_maxtokens: "100.0",
      vestingPool_tokensPerVest: "100.0",
      vestingPool_tokenPayPeriods: "0",
      vestingPool_date: new Date().getTime().toLocaleString(),
      poolMemberCount: 3,
      VestingColumns: [
        {
          field: 'id',
          label: '#',
          width: '40',
          numeric: true,
          centered: true
        },
        {
          field: 'account',
          label: 'Account',
          centered: true
        },
        {
          field: 'percentage',
          label: '% of Vest',
          numeric: true,
          centered: true
        },
        {
          field: 'tokens_per_vest',
          label: 'Tokens Per Vest',
          numeric: true,
          centered: true
        },
        {
          field: 'max_tokens',
          label: 'Max Tokens',
          numeric: true,
          centered: true
        },
        {
          field: 'date',
          label: 'Final Pay Date',
          centered: true
        },


      ],
      VestingMemberData_temp: [
        { 'id': 1, 'account': 'k:team_member_1', 'percentage': 20, 'date': '2016-10-15 13:43:27', 'max_tokens': 2000000, 'tokens_per_vest' : 1000 },
        { 'id': 2, 'account': 'k:team_member_2', 'percentage': 20, 'date': '2016-12-15 06:00:53', 'max_tokens': 2000000, 'tokens_per_vest' : 1000 },
        { 'id': 3, 'account': 'k:team_member_3', 'percentage': 20, 'date': '2016-04-26 06:26:28', 'max_tokens': 2000000, 'tokens_per_vest' : 1000 },
        { 'id': 4, 'account': 'k:team_member_4', 'percentage': 20, 'date': '2016-04-10 10:28:46', 'max_tokens': 2000000, 'tokens_per_vest' : 1000 },
        { 'id': 5, 'account': 'k:team_member_5', 'percentage': 20, 'date': '2016-12-06 14:38:38', 'max_tokens': 2000000, 'tokens_per_vest' : 1000 }
      ],
      VestingMemberData: [],
      showDetailIcon: false,
      //DAO STUFF
      makeDaoSelection: "0",
      dao_id: "my-new-dao",
      dao_name: "My DAO",
      dao_description: "Hub for My Token Community to share proposals and make decisions together.",
      dao_about: "The Community Hub for the My Token Community - This first paragraph, aka The 'About' Section of your DAO page is a brief message that is pinned to the top of your page and is immediately visible to all visitors who visit your DAO page. Edit this into a breif description about your community and it's goals.",
      dao_aboutScript: "The Community Hub for the My Token Community - This first paragraph, aka The 'About' Section of your DAO page is a brief message that is pinned to the top of your page and is immediately visible to all visitors who visit your DAO page. Edit this into a breif description about your community and it's goals.",
      dao_links: [
        { 'id': 1, 'name': 'Wiki (Example)', 'link': "https://my-gitbook.io/mywiki" },
        { 'id': 2, 'name': 'Forum (Example)', 'link': "https://my-forum.com" }
      ],
      dao_contacts: [
        { 'id': 1, 'type': 'Email', 'contact': "email@email.email" },
        { 'id': 2, 'type': 'Discord', 'contact': "@mydiscord" }
      ],
      dao_contacts_final: [],
      dao_links_final: [],
      dao_image: "http://www.rhodesonthepawtuxet.com/wp-content/themes/options/images/skins/headers/full_width/header-midnightBlue.jpg",
      dao_icon: "https://link-to-dao-icon.com",
      dao_token: "free.stake-token",
      dao_proposalCost: "0.0",
      dao_creatorOnly: false,
      dao_newLinkTitle: "Forum",
      dao_newLink: "https://link-to-dao-forum.com",
      dao_exampleMode: true,
      dao_currentLink: 0,
      dao_newContactTitle: "Email",
      dao_newContact: "dao_email@dao_email.com",
      dao_exampleModeContacts: true,
      dao_currentContact: 0,
      dao_moduleName: "stake-token",
      dao_namespace: "free",
      //Vote Stuff
      vote_ok: true,
      vote_daoid: "my-dao-id",
      vote_name: "My new voting proposition",
      vote_proposition: "My new proposition example - We propose the DAO use 1000 tokens a month from the community pool to hire a new community manager to moderate our discord and telegram channels.",
      vote_time: null,
      vote_timeseconds: "0.0",
      vote_timeseconds2: null,
      vote_currentdao: [],
      vote_currentdao_info: null,
      //Tasks
      task_payaccount: "k:task_performer",
      task_name: "My new Task",
      task_description: "Use this area to describe your new task - What is your Task? Who will it pay? How does this task relate to the DAO?",
      task_namespace: "free",
      task_modulename: "stake-token",


    };
  },
  watch: {

    crowdFundWhitelistTime2: function () {

      let wlenddatetime = this.crowdFundWhitelistTime2.getTime();
      this.wlInSeconds = ((wlenddatetime - new Date().getTime()) / 1000);
      this.crowdFundWhitelistTime = this.wlInSeconds;

    },

    whitelist_time2: function () {

      let wlenddatetime = this.whitelist_time2.getTime();
      this.whitelist_wlInSeconds = ((wlenddatetime - new Date().getTime()) / 1000);
      this.whitelist_time = this.wlInSeconds;

    },

    whitelist_publictime: function () {

      let wlenddatetime = this.whitelist_publictime.getTime();
      let wlenddatetime1 = this.whitelist_time2.getTime();
      this.whitelist_PublicTimeInSeconds = ((wlenddatetime - wlenddatetime1) / 1000);
      this.whitelist_publictime2 = this.whitelist_PublicTimeInSeconds;

    },

    vote_time: function () {

      let wlenddatetime = this.vote_time.getTime();
      this.vote_timeseconds = ((wlenddatetime - new Date().getTime()) / 1000);
      this.vote_timeseconds2 = this.vote_timeseconds;

    },

    crowdFundCampaignTime2: function () {

      let wlenddatetime = this.crowdFundCampaignTime2.getTime();
      this.campaignTimeInSeconds = ((wlenddatetime - new Date().getTime()) / 1000);
      this.crowdFundCampaignTime = this.campaignTimeInSeconds;

    },

    crowdFundInsuranceTime2: function () {

      let wlenddatetime = this.crowdFundInsuranceTime2.getTime();
      this.insurancetimeinseconds = ((wlenddatetime - this.crowdFundCampaignTime2.getTime()) / 1000);
      this.crowdFundInsuranceTime = this.insurancetimeinseconds;

    },

    //lets watch our transactionPolling variable
    transactionPolling: function (newValue, oldValue) {
      if (this.debug) {
        console.log("getTransactionPolling newValue: " + newValue);
        console.log("getTransactionPolling oldValue: " + oldValue);
      }


      if (newValue === true && this.isMakingCreatePoolTransaction === true) {

        let message1 = "Confirming new transaction with the blockchain";
        if (this.isCreatingCrowdfundingPool === true) {
          message1 = "Creating Crowdfunding Pool " + this.isCreatingCrowdfundingPool_message;
          this.isMakingItem = "Crowdfunding Pool";
        } else if (this.isCreatingLiquidityPool === true) {
          message1 = "Creating Liquidity Pool " + this.isCreatingLiquidityPool_poolid;
          this.isMakingItem = "Liquidity Pool";
        } else if (this.isCreatingStakingPool === true) {
          message1 = "Creating Staking Pool " + this.isCreatingStakingPool_poolid;
          this.isMakingItem = "Staking Pool";
        }else if (this.isCreatingNFTCollection === true) {
          message1 = "Creating NFT Collection " + this.isCreatingNFTCollection_poolid;
          this.isMakingItem = "NFT Collection";
        }else if (this.isCreatingNFT === true) {
          message1 = "Creating NFT " + this.isCreatingNFT_poolid;
          this.isMakingItem = "NFT";
        }else if (this.isMassMintingNfts === true) {
          message1 = "Mass Minting NFTs into the NFT Collection " + this.isMassMintingNfts_poolid;
          this.isMakingItem = "NFTs";
        }else if (this.isCreatingNFTWhitelist === true) {
          message1 = "Creating NFT Whitelisted Presale for NFT Collection " + this.isCreatingNFTWhitelist_poolid;
          this.isMakingItem = "NFT Whitelisted Presale";
        }else if (this.isCreatingVestingPool === true) {
          message1 = "Creating Vesting Pool " + this.isCreatingVestingPool_poolid;
          this.isMakingItem = "Vesting Pool";
        }else if (this.isCreatingDAOHive === true) {
          message1 = "Creating DAO Hive " + this.isCreatingDAOHive_poolid;
          this.isMakingItem = "DAO Hive";
        }else if (this.isCreatingVotingPool === true) {
          message1 = "Creating Voting Poll " + this.isCreatingVotingPool_poolid;
          this.isMakingItem = "Voting Poll";
        }else if (this.isCreatingTask === true) {
          message1 = "Creating DAO Task " + this.isCreatingTask_poolid;
          this.isMakingItem = "DAO Task";
        }




        this.isMakingTransaction = true;
        this.makingPool = true;


        this.$buefy.toast.open({
          duration: 10000,
          message: message1,
          type: "is-info",
          pauseOnHover: true
        });


      } else if (newValue === false && this.isMakingCreatePoolTransaction === true) {

        this.isMakingTransaction = false;
        this.isMakingCreatePoolTransaction = false;

        let message = "Transaction Success!";
        if (this.isCreatingCrowdfundingPool === true) {
          message = "Created crowdfunding pool " + this.isCreatingCrowdfundingPool_message;
        } else if (this.isCreatingLiquidityPool === true) {
          message = "Created liquidity pool " + this.isCreatingLiquidityPool_poolid;
        } else if (this.isCreatingStakingPool === true) {
          message = "Created staking pool " + this.isCreatingStakingPool_poolid;
        }else if (this.isCreatingNFTCollection === true) {
          message = "Created NFT Collection " + this.isCreatingNFTCollection_poolid;
          this.finishedCreating_message = "Your NFT Collection "+this.isCreatingNFTCollection_poolid+" has been successfully created!";
          this.makingPool = false;
          this.finishedCreating = true;
        }else if (this.isCreatingNFT === true) {
          message = "Created NFT " + this.isCreatingNFT_poolid;
          this.finishedCreating_message = "Your NFT has been successfully minted into the collection "+this.isCreatingNFT_poolid+" and placed into your wallet!";
          this.makingPool = false;
          this.finishedCreating = true;
        }else if (this.isMassMintingNfts === true) {
          message = "Mass Minted NFTs into collection " + this.isMassMintingNfts_poolid;
          this.finishedCreating_message = "Your NFTs have been successfully minted into the collection "+this.isMassMintingNfts_poolid+" and placed into your wallet!";
          this.makingPool = false;
          this.finishedCreating = true;
        }else if (this.isCreatingNFTWhitelist === true) {
          message = "Activated Whitelisted Presale for NFT Collection " + this.isCreatingNFTWhitelist_poolid;
          this.finishedCreating_message = "Your Whitelisted Pre-sale has successfully been activated for the NFT Collection "+this.isCreatingNFTWhitelist_poolid+"!";
          this.makingPool = false;
          this.finishedCreating = true;
        } else if (this.isCreatingVestingPool === true) {
          message = "Created Vesting Pool " + this.isCreatingVestingPool_poolid;
        } else if (this.isCreatingVestingPool === true) {
          message = "Created Voting Poll " + this.isCreatingVotingPool_message;
        } else if (this.isCreatingVestingPool === true) {
          message = "Created DAO Hive " + this.isCreatingDAOHive_poolid;
        } else if (this.isCreatingTask === true) {
          message = "Created DAO Task " + this.isCreatingTask_poolid;
        }



        if (this.getTransactionFailed() === false) {

          this.$buefy.toast.open({
            duration: 10000,
            message: message,
            position: "is-top",
            type: "is-success"
          });

          if (this.isCreatingCrowdfundingPool === true) {
            setTimeout(async () => {

              await this.$router.push({path: "/crowdfund/" + this.isCreatingCrowdfundingPool_poolid});


            }, 4000);
          } else if (this.isCreatingLiquidityPool === true) {
            setTimeout(async () => {

              await this.$router.push({path: "/trade/" + this.isCreatingLiquidityPool_pair});


            }, 4000);
          } else if (this.isCreatingStakingPool === true) {
            setTimeout(async () => {

              await this.$router.push({path: "/stake/" + this.isCreatingStakingPool_poolid + "/" + this.isCreatingStakingPool_contract});


            }, 4000);
          } else if (this.isCreatingNFTWhitelist === true) {
            setTimeout(async () => {

              await this.$router.push({path: "/whitelist/" + this.isCreatingNFTWhitelist_poolid});


            }, 4000);
          }  else if (this.isCreatingVestingPool === true) {
            setTimeout(async () => {

              await this.$router.push({path: "/vest/" + this.isCreatingVestingPool_poolid + "/" + this.isCreatingVestingPool_contract});


            }, 4000);
          } else if (this.isCreatingDAOHive === true) {
            setTimeout(async () => {

              await this.$router.push({path: "/dao/" + this.isCreatingDAOHive_poolid});


            }, 4000);
          } else if (this.isCreatingVotingPool === true) {
            setTimeout(async () => {

              await this.$router.push({path: "/dao/" + this.isCreatingVotingPool_poolid});


            }, 4000);
          } else if (this.isCreatingTask === true) {
            setTimeout(async () => {

              await this.$router.push({path: "/dao/" + this.isCreatingTask_poolid});


            }, 4000);
          }

          this.isCreatingCrowdfundingPool = false;
          this.isCreatingLiquidityPool = false;
          this.isCreatingStakingPool = false;
          this.isCreatingVestingPool = false;
          this.isCreatingNFTCollection = false;
          this.isCreatingNFT = false;
          this.isMassMintingNfts = false;
          this.isCreatingNFTWhitelist = false;
          this.isCreatingVotingPool = false;
          this.isCreatingDAOHive = false;
          this.isCreatingTask = false;

        } else if (this.getTransactionFailed() === true) {

          this.finishedCreating_message = "Oh no! There was an error with your Transaction!";

          this.isMakingTransaction = false;

          this.makingPool = false;

          let message2 = "Transaction Failed";
          if (this.isCreatingCrowdfundingPool === true) {
            message2 = "Failed to create crowdfunding pool " + +this.isCreatingCrowdfundingPool_message;
          } else if (this.isCreatingLiquidityPool === true) {
            message2 = "Failed to create liquidity pool " + +this.isCreatingLiquidityPool_poolid;
          } else if (this.isCreatingStakingPool === true) {
            message2 = "Failed to create staking pool " + +this.isCreatingStakingPool_poolid;
          }else if (this.isCreatingNFTCollection === true) {
            message2 = "Failed to create NFT Collection " + +this.isCreatingNFTCollection_poolid;
          }else if (this.isCreatingNFT === true) {
            message2 = "Failed to create NFT " + +this.isCreatingNFT_poolid;
          }else if (this.isMassMintingNfts === true) {
            message2 = "Failed to Mass Mint to collection " + +this.isMassMintingNfts_poolid;
          }else if (this.isCreatingNFTWhitelist === true) {
            message2 = "Failed to create Whitelist Presale for collection " + +this.isCreatingNFTWhitelist_poolid;
          } else if (this.isCreatingVestingPool === true) {
            message2 = "Failed to create vesting pool " + +this.isCreatingVestingPool_poolid;
          } else if (this.isCreatingVotingPool === true) {
            message2 = "Failed to create voting pool " + +this.isCreatingVotingPool_message;
          } else if (this.isCreatingDAOHive === true) {
            message2 = "Failed to create DAO Hive " + +this.isCreatingDAOHive_poolid;
          } else if (this.isCreatingTask === true) {
            message2 = "Failed to create DAO Task " + +this.isCreatingTask_poolid;
          }

          this.$buefy.toast.open({
            duration: 10000,
            message: message2,
            position: "is-top",
            type: "is-danger"
          });

          this.isCreatingCrowdfundingPool = false;
          this.isCreatingLiquidityPool = false;
          this.isCreatingStakingPool = false;
          this.isCreatingVestingPool = false;
          this.isCreatingNFTCollection = false;
          this.isCreatingNFT = false;
          this.isMassMintingNfts = false;
          this.isCreatingNFTWhitelist = false;
          this.isCreatingVotingPool = false;
          this.isCreatingDAOHive = false;
          this.isCreatingTask = false;

        }

      }

    },

    poolName: function (newValue, oldValue) {
      if (this.debug) {
        console.log("poolIdExists newValue: " + newValue);
        console.log("poolIdExists oldValue: " + oldValue);
      }
      this.autoVerifyPoolId();

      if (this.getPoolIdExists() === true) {
        this.localPoolIdExists = true;
      } else {
        this.localPoolIdExists = false;
      }

    },

    poolIdExists1: function (newValue, oldValue) {
      if (this.debug) {
        console.log("poolIdExists1 newValue: " + newValue);
        console.log("poolIdExists1 oldValue: " + oldValue);
      }

      this.localPoolIdExists1 = newValue;

    },

    poolIdExists2: function (newValue, oldValue) {
      if (this.debug) {
        console.log("poolIdExists2 newValue: " + newValue);
        console.log("poolIdExists2 oldValue: " + oldValue);
      }

      this.localPoolIdExists2 = newValue;


    },

    activeTab: function () {

      if(this.activeTab !== '-1'){
        this.welcome = false;
        this.currentStep = 0;

        this.finishedCreating = false;

        this.localModuleExists = null;

        if(this.makeNftSelection !== "0"){
          this.makeNftSelection = "0";
        }
      }else {
        this.welcome = true;
        this.currentStep = 0;
        this.currentPage = 1;
        this.finishedCreating = false;
        this.makeNftSelection = "0";
        this.makeDaoSelection = "0";
        this.localModuleExists = null;
      }


    },

    transactionRejected: function () {
      this.makingPool = false;
    },

  },
  computed: {
    //import state variables from our vue store
    ...mapState("accounts", ["transactionPolling", "transactionRejected", "poolIdExists", "poolIdExists1", "poolIdExists2"]),

  },
  methods: {
    //map getters from vue store
    ...mapGetters("accounts", ["getAccountExists", "getShowNavBar", "getAccountConfirmed", "getCurrentSignRequest", "getPoolIdExists", "getPoolIdExists1", "getTransactionFailed"]),

    async addNewVestingMember(){

      if(this.vestingPool_accounts.includes(this.vestingPool_account) === false){

        this.duplicateVestingPoolMember = false;

        this.vestingPool_accounts.push(this.vestingPool_account);

        this.vestingPool_count++;

        this.vestingPool_percentLeft = Number(this.vestingPool_percentLeft) + Number(this.vestingPool_percentage);

        this.vestingPool_percentLeftTotal = Number(this.vestingPool_percentLeftTotal) - Number(this.vestingPool_percentage);

        this.vestingPool_tokensLeft = (Number(this.vestingPool_tokensLeftTotal) - ( Number(this.rewardDurationAmount) - ( Number(this.rewardDurationAmount) * (Number(this.vestingPool_percentage) * 0.01))) );

        this.vestingPool_tokensPerVest = ( Number(this.rewardDurationAmount) * (Number(this.vestingPool_percentage) * 0.01)).toFixed(12);

        let secondsUntilVested = (( ( Number(this.rewardAmount) / Number(this.rewardDurationAmount) ) *  Number(this.rewardDuration) ) * 1000);

        this.vestingPool_date = new Date(new Date().getTime() + secondsUntilVested);

        this.vestingPool_maxtokens = ( Number(this.rewardAmount) * (Number(this.vestingPool_percentage) * 0.01)).toFixed(12);

        this.vestingPool_tokenPayPeriods = ( Number(this.rewardAmount) / Number(this.rewardDurationAmount) );

        let t_object = {
          'id': this.vestingPool_count
          , 'account': this.vestingPool_account
          , 'percentage': this.vestingPool_percentage
          , 'date': this.vestingPool_date
          , 'max_tokens': this.vestingPool_maxtokens
          , 'tokens_per_vest' : this.vestingPool_tokensPerVest }

        if (this.VestingMemberData.includes(t_object) === false) {
          this.VestingMemberData.push(t_object);
        }

        console.log(t_object);

        if(this.vestingPool_percentLeftTotal === 0){
          this.canGoNext = true;
        }

      }else {
        this.duplicateVestingPoolMember = true;
      }




    },

    calculateVestedMemberStats(){

      if(this.vestingPool_accounts.includes(this.vestingPool_account) === false){

        this.duplicateVestingPoolMember = false;

        this.vestingPool_tokensLeft = ( Number(this.rewardDurationAmount) - ( Number(this.rewardDurationAmount) * (Number(this.vestingPool_percentage) * 0.01)));

        this.vestingPool_tokensPerVest = ( Number(this.rewardDurationAmount) * (Number(this.vestingPool_percentage) * 0.01)).toFixed(12);

        let secondsUntilVested = (( ( Number(this.rewardAmount) / Number(this.rewardDurationAmount) ) *  Number(this.rewardDuration) ) * 1000);

        this.vestingPool_tokenPayPeriods = ( Number(this.rewardAmount) / Number(this.rewardDurationAmount) );

        this.vestingPool_maxtokens = ( Number(this.rewardAmount) * (Number(this.vestingPool_percentage) * 0.01)).toFixed(12);

        this.vestingPool_date = new Date(new Date().getTime() + secondsUntilVested)

      }else {

        this.duplicateVestingPoolMember = true;

      }


    },

    checkHardCap() {
      if ((Number(this.crowdFundSellTokenPrice) * Number(this.crowdFundSellTokenQuantity)) <= Number(this.crowdFundHardCap)) {
        this.hardCapOk = true;
      } else {
        this.hardCapOk = false;
      }
    },

    async setTab() {
      if (this.debug) {
        console.log("Switching Tabs");
        console.log("Tab: 1");
      }
      this.currentTab = 1;
    },

    //Returns proper name if namespace is null
    coinName(namespace, name) {
      let newnamespace = namespace + ".";
      let newname = name;
      if (namespace === null || namespace === "null" || namespace === "") {
        newnamespace = "";
      }
      if (name === null || name === "null" || name === "") {
        newname = "";
      }
      return newnamespace + newname;
    },

    makeWhitelist(list) {

      //const str = 'first\nsecond\r\nthird\n';

      const result = list.split(/\r?\n/).filter(element => element);

      console.log(result);

      this.crowdFundWhitelist_list = result;


    },

    makeNFTIDList(list) {

      //const str = 'first\nsecond\r\nthird\n';

      const result = list.split(/\r?\n/).filter(element => element);

      console.log(result);

      this.polyFungiV2StakableIDs_list = result;


    },

    makeNFTWhitelist(list) {

      const result = list.split(/\r?\n/).filter(element => element);

      console.log(result);

      this.whitelist_list = result;


    },

    makea1list(list) {

      const result = list.split(/\r?\n/).filter(element => element);

      console.log(result);

      this.makenft_a1_final = result;

    },

    makea2list(list) {

      const result = list.split(/\r?\n/).filter(element => element);

      console.log(result);

      this.makenft_a2_final = result;


    },

    makea3list(list) {

      const result = list.split(/\r?\n/).filter(element => element);

      console.log(result);

      this.makenft_a3_final = result;


    },

    async makeMassMintNFTs(list) {

      const result = list.split(/\r?\n/).filter(element => element);

      const result2 = list.split(/\r?\n/).filter(element => JSON.parse(element));

      console.log("result");
      console.log(result);
      console.log("result2");
      console.log(result2);
      let test = JSON.parse(result2[0]);

      console.log("TEST");
      console.log(test.id);

      this.makenft_massNftsFinalJSON= [];

      for(let i = 0; i < result.length; i++){

        const temp = JSON.parse(result[i]);

        let exists_payload = {
          nftid: temp.id
        };

        let idexists = await this.$store.dispatch("accounts/getNFTIDExists", exists_payload);

        console.log("idexists");
        console.log(idexists);

        const t_object = {
          id: temp.id,
          "collection-id": temp['collection-id'],
          name: temp.name,
          description: temp.description,
          image: temp.image,
          a1: temp.a1,
          a2: temp.a2,
          a3: temp.a3,
          idexists: idexists
        }


        if (this.makenft_massNftsFinalJSON.includes(t_object) === false) {
          this.makenft_massNftsFinalJSON.push(t_object);
        }

      }

      this.makenft_massNftsFinal = result2;



    },

    getYearlyRewards(rewardDuration, rewardAmount, totalrewardAmount) {

      let yearlyRewards = 0.0;

      if (rewardDuration <= 31536000.0) {
        let rewardReleases = 31536000.0 / rewardDuration;

        yearlyRewards = rewardAmount * rewardReleases;
      }

      if (yearlyRewards > totalrewardAmount) {
        yearlyRewards = totalrewardAmount;
      }

      return yearlyRewards;

    },

    getMonthlyRewards(rewardDuration, rewardAmount, totalrewardAmount) {

      let monthlyRewards = 0.0;

      if (rewardDuration <= 2592000.0) {
        let rewardReleases = 2592000.0 / rewardDuration;

        monthlyRewards = rewardAmount * rewardReleases;
      }

      if (monthlyRewards > totalrewardAmount) {
        monthlyRewards = totalrewardAmount;
      }

      return monthlyRewards;

    },

    getDailyRewards(rewardDuration, rewardAmount, totalrewardAmount) {

      let dailyRewards = 0.0;

      if (rewardDuration <= 86400.0) {
        let rewardReleases = 86400.0 / rewardDuration;

        dailyRewards = rewardAmount * rewardReleases;
      }

      if (dailyRewards > totalrewardAmount) {
        dailyRewards = totalrewardAmount;
      }

      return dailyRewards;

    },

    reduceBalance(balance, prec = 6){
      if (balance) {
        if (balance.int) balance = balance.int;
        if (balance.decimal) balance = balance.decimal;

        if (parseFloat(balance) % 1 === 0) {
          return parseInt(balance);
        }

        return Math.trunc(parseFloat(balance) * Math.pow(10, prec)) / Math.pow(10, prec);
      }

      if (balance === 0) return 0;
    },

    getHourlyRewards(rewardDuration, rewardAmount, totalrewardAmount) {

      let hourlyRewards = 0.0;
      if (rewardDuration <= 3600) {
        let rewardReleases = 3600.0 / rewardDuration;

        hourlyRewards = rewardAmount * rewardReleases;
      }

      if (hourlyRewards > totalrewardAmount) {
        hourlyRewards = totalrewardAmount;
      }

      return hourlyRewards;

    },

    determineNewDaoLinkTitle(){

      let newLink = this.dao_newLinkTitle;

      newLink = newLink.slice(0,30);

      this.dao_newLinkTitle = newLink;

    },

    determineNewDaoContactTitle(){

      let newLink = this.dao_newContactTitle;

      newLink = newLink.slice(0,30);

      this.dao_newContactTitle = newLink;

    },

    addNewDaoContact(){

      if(this.dao_exampleModeContacts === true){
        this.dao_exampleModeContacts = false;
        this.dao_contacts = [];
      }



      this.dao_currentContact = this.dao_currentContact + 1;

      let t_object = { 'id': this.dao_currentContact, 'type': this.dao_newContactTitle, 'contact': this.dao_newContact };

      let t_object2 = { 'name': this.dao_newContactTitle, 'account': this.dao_newContact };

      this.dao_contacts.push(t_object);

      this.dao_contacts_final.push(t_object2);

    },

    clearDaoContacts(){
      this.dao_contacts = [];
      this.dao_currentContact = 0;
    },

    addNewDaoLink(){

      if(this.dao_exampleMode === true){
        this.dao_exampleMode = false;
        this.dao_links = [];
      }



      this.dao_currentLink = this.dao_currentLink + 1;

      let t_object = { 'id': this.dao_currentLink, 'name': this.dao_newLinkTitle, 'link': this.dao_newLink };

      let t_object2 = { 'name': this.dao_newLinkTitle, 'link': this.dao_newLink };

      this.dao_links.push(t_object);

      this.dao_links_final.push(t_object2);

    },

    clearDaoLinks(){
      this.dao_links = [];
      this.dao_links_final = [];
      this.dao_currentLink = 0;
    },

    determineProposition(){
      let newDAOID = this.vote_proposition;

      newDAOID = newDAOID.slice(0,400);

      this.vote_proposition = newDAOID;
    },

    determinePropositionName(){
      let newDAOID = this.vote_name;

      newDAOID = newDAOID.slice(0,40);

      this.vote_name = newDAOID;
    },

    determineDaoName(){

      let newDAOID = this.dao_name;

      newDAOID = newDAOID.slice(0,30);

      this.dao_name = newDAOID;

    },

    determineDaoID(){

      let newDAOID = this.dao_id.replaceAll(" ", "");

      newDAOID = newDAOID.slice(0,30);

      this.dao_id = newDAOID;

    },

    determineDaoDescription(){
      let newDAODescription = this.dao_description.replaceAll(/(\r\n|\n|\r)/gm, "");

      newDAODescription = newDAODescription.slice(0,75);

      this.dao_description = newDAODescription;

    },

    determineDaoAbout(){
      let newDAOAboutScript = this.dao_about.replaceAll(/(\r\n|\n|\r)/gm, "|NL");

      newDAOAboutScript = newDAOAboutScript.slice(0,400);

      let newDAOAbout = this.dao_about.slice(0,400);

      this.dao_about = newDAOAbout;
      this.dao_aboutScript = newDAOAboutScript;

    },

    determineCollectionID(){

      let newCollectionID = this.collectionID.replaceAll(" ", "");

      newCollectionID = newCollectionID.slice(0,30);

      this.collectionID = newCollectionID;

    },

    determineCollectionIDMint(){

      let newCollectionID = this.makenft_collectionID.replaceAll(" ", "");

      newCollectionID = newCollectionID.slice(0,30);

      this.makenft_collectionID = newCollectionID;

    },

    determineNFTName(){

      let newCollectionID = this.makenft_name;

      newCollectionID = newCollectionID.slice(0,30);

      this.makenft_name = newCollectionID;

    },

    determineNFTImage(){

      let newCollectionID = this.makenft_image;

      newCollectionID = newCollectionID.slice(0,256);

      this.makenft_image = newCollectionID;

    },

    determineNFTDescription(){

      let newCollectionID = this.makenft_description;

      newCollectionID = newCollectionID.slice(0,256);

      this.makenft_description = newCollectionID;

    },

    determineNFTIDMint(){

      let newCollectionID = this.makenft_nftID.replaceAll(" ", "");

      newCollectionID = newCollectionID.slice(0,30);

      this.makenft_nftID = newCollectionID;

    },

    determineVestingPoolDescription(){

      let newCollectionID = this.vestingPool_description;

      newCollectionID = newCollectionID.slice(0,256);

      this.vestingPool_description = newCollectionID;

    },

    async determinePoolId(rewardType, stakeType) {
      this.localPoolIdExists = this.getPoolIdExists();

      let name = localStorage.getItem("accountName");
      let poolname = this.poolName.replaceAll(" ", "");
      this.poolId = poolname + name;

      await this.autoVerifyPoolId(rewardType, stakeType);

      this.localPoolIdExists = this.getPoolIdExists();

      setTimeout(async () => {
        this.localPoolIdExists = this.getPoolIdExists();
      }, 200);

    },

    async determineVestingPoolId() {
      this.localPoolIdExists = this.getPoolIdExists();

      let name = localStorage.getItem("accountName");
      let poolname = this.poolName.replaceAll(" ", "");
      this.poolId = poolname + name;

      await this.autoVerifyPoolId(6, 6);

      this.localPoolIdExists = this.getPoolIdExists();

      setTimeout(async () => {
        this.localPoolIdExists = this.getPoolIdExists();
      }, 200);

    },

    async verifyPairExists(tokenA, tokenB) {

      if (this.debug) {
        console.log("Verifying pair doesn't exist:");
        console.log(tokenA);
        console.log(tokenB);
      }

    },

    async verifyTokenExists(namespace, name) {

      if (this.debug) {
        console.log("Verifying token exists:");
        console.log(namespace);
        console.log(name);
      }

      let exists_payload = {
        tokenA: this.coinName(namespace, name)
      };

      const exists = await this.$store.dispatch("accounts/getTokenExists", exists_payload);

      if (this.debug) {
        console.log("exists");
        console.log(exists);
      }

      this.localModuleExists = exists;

    },

    async verifyCollectionExists(collectionid) {

      let exists_payload = {
        collectionid: collectionid
      };

      const exists = await this.$store.dispatch("accounts/getCollectionExists", exists_payload);

      if (this.debug) {
        console.log("exists");
        console.log(exists);
      }

      this.localModuleExists = exists;

    },

    async getCollectionDetails(collectionid) {

      let exists_payload = {
        collectionid: collectionid
      };

      this.whitelist_collectionData = await this.$store.dispatch("accounts/getCollectionDetails", exists_payload);

    },

    async verifyDaoDontExist(daoid){

      let exists_payload = {
        daoid: daoid
      };

      const exists = await this.$store.dispatch("accounts/getDAOHiveExists", exists_payload);

      if (this.debug) {
        console.log("exists");
        console.log(exists);
      }

      this.localModuleExists = !exists;

    },

    async verifyDaoExists(daoid){

      let exists_payload = {
        daoid: daoid
      };

      const exists = await this.$store.dispatch("accounts/getDAOHiveExists", exists_payload);

      if (this.debug) {
        console.log("exists");
        console.log(exists);
      }

      this.localModuleExists = exists;

    },

    async getDAOInfo(daoid){

      const hivepayload = {
        daoid: daoid
      }

      this.vote_currentdao = await this.$store.dispatch("accounts/getDAOHive", hivepayload);

      this.vote_currentdao_info = this.vote_currentdao;

      // if(currentdao !== 0 && currentdao !== '0'){
      //   this.vote_currentdao = currentdao;
      // }



      console.log("vote_currentdao");
      console.log(this.vote_currentdao);

    },

    async verifyCollectionDontExist(collectionid) {

      let exists_payload = {
        collectionid: collectionid
      };

      const exists = await this.$store.dispatch("accounts/getCollectionExists", exists_payload);

      if (this.debug) {
        console.log("exists");
        console.log(exists);
      }

      this.localModuleExists = !exists;

    },

    async verifyNFTIDExists(nftid) {

      let exists_payload = {
        nftid: nftid
      };

      const exists = await this.$store.dispatch("accounts/getNFTIDExists", exists_payload);

      if (this.debug) {
        console.log("exists");
        console.log(exists);
      }

      this.localModuleExists = !exists;

    },

    async verifySignatureExists(signature) {

      let exists_payload = {
        signature: signature
      };

      const exists = await this.$store.dispatch("accounts/getSignatureExists", exists_payload);

      if (this.debug) {
        console.log("exists");
        console.log(exists);
      }

      this.localModuleExists = !exists;

    },

    async verifyTokenBalance2(token, amount) {

      if (this.debug) {
        console.log("Verifying token balance exists:");
        console.log(token);
      }

      let exists_payload = {
        tokenA: token
      };

      const balance = await this.$store.dispatch("accounts/getTokenUserBalance", exists_payload);

      if (this.debug) {
        console.log("balance");
        console.log(balance);
      }

      if (Number(amount) > balance) {
        this.localModuleExists = false;
      } else {
        this.localModuleExists = true;
      }


    },

    async verifyTokenBalance(namespace, name) {

      if (this.debug) {
        console.log("Verifying token balance exists:");
        console.log(namespace);
        console.log(name);
      }

      let exists_payload = {
        tokenA: this.coinName(namespace, name)
      };

      const balance = await this.$store.dispatch("accounts/getTokenUserBalance", exists_payload);

      if (this.debug) {
        console.log("balance");
        console.log(balance);
      }

      if (Number(this.crowdFundSellTokenQuantity) > balance) {
        this.localModuleExists = false;
      } else {
        this.localModuleExists = true;
      }


    },

    async verifyLPTokenBalance() {

        console.log("Verifying LP token balance exists:");


      let balance = 0;

      let lptoken1 = this.lpToken1Type;
      let lptoken2 = this.lpToken2Type;
      let poolname = this.lpStakeTokenPoolName;

      if(this.customLPStakeToken === true){
        lptoken1 = this.coinName(this.lp1StakeModuleNamespace,this.lp1StakeModuleName);
        lptoken2 = this.coinName(this.lp2StakeModuleNamespace,this.lp2StakeModuleName);
      }

      const lptub_payload = {
        tokenA: lptoken1,
        tokenB: lptoken2,
        poolName: poolname
      };

      balance = await this.$store.dispatch("accounts/getLPTokenUserBalance", lptub_payload);

      console.log("got balance")
      console.log(lptub_payload);
      console.log(balance);

      if (balance > 0) {
        this.localModuleExists = true;
      } else {
        this.localModuleExists = false;
      }


    },

    async verifyLPNameExists(poolName) {

      this.liquidityPoolName = poolName.replaceAll(" ", "");

      let t_payload = {
        tokenA: this.coinName(this.liquidityPairTokenANamespace, this.liquidityPairTokenAName),
        tokenB: this.coinName(this.liquidityPairTokenBNamespace, this.liquidityPairTokenBName),
        poolName: this.liquidityPoolName
      };

      if (this.debug) {
        console.log("Verifying lp pool name doesn't exist:");
        console.log(t_payload);
      }

      this.localPoolIdExists = this.getPoolIdExists();

      await this.autoVerifyLiquidityPoolId(t_payload);

      this.localPoolIdExists = this.getPoolIdExists();

      setTimeout(async () => {
        this.localPoolIdExists = this.getPoolIdExists();
      }, 200);

    },

    async determineCrowdFundPoolId() {

      this.localPoolIdExists = this.getPoolIdExists();

      let name = localStorage.getItem("accountName");
      let poolname = this.crowdFundPoolName.replaceAll(" ", "");
      this.crowdFundPoolId = poolname + name;

      if (this.debug) {
        console.log("determineCrowdFundPoolId");
        console.log("poolname");
        console.log(poolname);
        console.log("this.crowdFundPoolId");
        console.log(this.crowdFundPoolId);
      }

      await this.autoVerifyCrowdFundPool(this.crowdFundPoolId);

      this.localPoolIdExists = this.getPoolIdExists();

      //Now we need to verify the token accounts exist

      setTimeout(async () => {
        this.localPoolIdExists = this.getPoolIdExists();
      }, 200);

    },

    async determineSwapFee(swapfee) {

     this.liquidityPoolFeeFinal = this.reduceBalance((Number(swapfee) / 100), 4);

    },

    async determineCrowdFundSwapFee(swapfee) {

      this.crowdFundLiquidityPoolSwapFeeFinal = this.reduceBalance((Number(swapfee) / 100), 4);

    },

    async verify400orLess(stringtoverify) {

      if (stringtoverify.length >= 400) {
        this.descriptonTooLong = true;
      } else {
        this.descriptonTooLong = false;
      }

    },

    async verifyCrowdFundLiquidityPoolNameExists() {

      this.crowdFundLiquidityPoolName = this.crowdFundLiquidityPoolName.replaceAll(" ", "");

      let t_payload = {
        tokenA: this.coinName(this.crowdFundPledgeTokenModuleNamespace, this.crowdFundPledgeTokenModuleName),
        tokenB: this.coinName(this.crowdFundSellTokenModuleNamespace, this.crowdFundSellTokenModuleName),
        poolName: this.crowdFundLiquidityPoolName
      };

      if (this.debug) {
        console.log("Verifying future crowdfund lp pool name doesn't exist:");
        console.log(t_payload);
      }

      this.localPoolIdExists1 = this.getPoolIdExists1();

      await this.autoVerifyCrowdFundLiquidityPool(t_payload);

      this.localPoolIdExists1 = this.getPoolIdExists1();

      setTimeout(async () => {
        this.localPoolIdExists1 = this.getPoolIdExists1();
      }, 200);

    },

    async getStakeTokenImagePolyfungiv1(namespace, name, tokenid) {
      let t_payload = {
        tokenId: tokenid,
        moduleName: name,
        moduleNamespace: namespace
      };

      let t_uri = null;

      t_uri = await this.$store.dispatch("accounts/getURIpolyfungiv1", t_payload);

      this.t_stakeTokenImagePolyfungiv1 = t_uri;

    },

    //POLYFUNGIV1 URI GETTERS
    async getPolyFungiV1RewardTokenURI() {
      if (this.rewardType === 2) {

        let t_payload = {
          tokenId: this.polyFungiV1ID,
          moduleName: this.polyFungiV1ModuleName,
          moduleNamespace: this.polyFungiV1ModuleNamespace
        };

        if (this.debug) {
          console.log("PAYLOAD");
          console.log(t_payload);
        }

        let t_uri = null;

        this.t_rewardTokenImagePolyfungiv1 = "0";
        this.t_rewardTokenNamePolyfungiv1 = this.polyFungiV1ID;
        this.t_rewardTokenDescriptionPolyfungiv1 = this.polyFungiV1ModuleNamespace + this.polyFungiV1ModuleName;

        t_uri = await this.$store.dispatch("accounts/getURIpolyfungiv1", t_payload);

        if (this.debug) {
          console.log("Pool Creation Step Forward Metadata Check:");
          console.log("POLYFUNGIV1 REWARD TOKEN METADATA RESULT FOR t_uri:");
          console.log(t_uri);
          console.log("t_uri.image");
          console.log(t_uri.image);
          console.log("t_uri.name");
          console.log(t_uri.name);
        }

        if (t_uri.image !== undefined) {
          this.t_rewardTokenImagePolyfungiv1 = t_uri.image;
        }

        if (t_uri !== undefined) {
          if (t_uri !== "0") {


            if (t_uri.name !== undefined) {
              this.t_rewardTokenNamePolyfungiv1 = t_uri.name;
            }

            if (t_uri.description !== undefined) {
              this.t_rewardTokenDescriptionPolyfungiv1 = t_uri.description;
            }

          }
        }

      }
    },

    async getCollection(){

      let c_payload = {
        collectionid: this.stake_CollectionID
      }

      console.log("C PAYLOAD");
      console.log(c_payload);

      this.collectionDetails = await this.$store.dispatch("accounts/getCollectionDetails", c_payload);

      this.stake_CollectionImage = this.collectionDetails.image;
      this.stake_CollectionSupply = this.collectionDetails.supply;
      this.stake_CollectionLink = this.collectionDetails.link;
      this.stake_CollectionSignature = this.collectionDetails.hash;

      console.log("this.collectionDetails");
      console.log(this.collectionDetails);

    },

    async getPolyFungiV1StakeTokenURI() {
      if (this.stakeType === 2) {

        let t_payload = {
          tokenId: this.polyFungiV1StakeID,
          moduleName: this.polyFungiV1StakeModuleName,
          moduleNamespace: this.polyFungiV1StakeModuleNamespace
        };

        let t_uri = null;

        this.t_stakeTokenImagePolyfungiv1 = "0";
        this.t_stakeTokenNamePolyfungiv1 = this.polyFungiV1StakeID;
        this.t_stakeTokenDescriptionPolyfungiv1 = this.polyFungiV1StakeModuleNamespace + this.polyFungiV1StakeModuleName;

        t_uri = await this.$store.dispatch("accounts/getURIpolyfungiv1", t_payload);

        if (this.debug) {
          console.log("Pool Creation Step Forward Metadata Check:");
          console.log("POLYFUNGIV1 STAKE TOKEN METADATA RESULT FOR t_uri:");
          console.log(t_uri);
          console.log("t_uri.image");
          console.log(t_uri.image);
          console.log("t_uri.name");
          console.log(t_uri.name);
        }

        if (t_uri.image !== undefined) {
          this.t_stakeTokenImagePolyfungiv1 = t_uri.image;
        }

        if (t_uri !== undefined) {
          if (t_uri !== "0") {


            if (t_uri.name !== undefined) {
              this.t_stakeTokenNamePolyfungiv1 = t_uri.name;
            }

            if (t_uri.description !== undefined) {
              this.t_stakeTokenDescriptionPolyfungiv1 = t_uri.description;
            }

          }
        }

      }
    },

    //POLYFUNGI 2 URI GETTERS
    async getPolyFungiV2RewardTokenURI() {
      if (this.rewardType === 3) {

        let t_payload = {
          tokenId: this.polyFungiV2ID,
          moduleName: this.polyFungiV2ModuleName,
          moduleNamespace: this.polyFungiV2ModuleNamespace
        };

        let t_uri = null;

        this.t_rewardTokenImagePolyfungiv2 = "0";
        this.t_rewardTokenNamePolyfungiv2 = this.polyFungiV2ID;
        this.t_rewardTokenDescriptionPolyfungiv2 = this.polyFungiV2ModuleNamespace + this.polyFungiV2ModuleName;

        t_uri = await this.$store.dispatch("accounts/getURIpolyfungiv2", t_payload);

        if (this.debug) {
          console.log("Pool Creation Step Forward Metadata Check:");
          console.log("POLYFUNGIV2 REWARD TOKEN METADATA RESULT FOR t_uri:");
          console.log(t_uri);
          console.log("t_uri.image");
          console.log(t_uri.image);
          console.log("t_uri.name");
          console.log(t_uri.name);
        }

        if (t_uri.image !== undefined) {
          this.t_rewardTokenImagePolyfungiv2 = t_uri.image;
        }

        if (t_uri !== undefined) {
          if (t_uri !== "0") {


            if (t_uri.name !== undefined) {
              this.t_rewardTokenNamePolyfungiv2 = t_uri.name;
            }

            if (t_uri.description !== undefined) {
              this.t_rewardTokenDescriptionPolyfungiv2 = t_uri.description;
            }

          }
        }

      }
    },

    async getPolyFungiV2StakeTokenURI() {
      if (this.stakeType === 3) {

        let t_payload = {
          tokenId: this.polyFungiV2StakeID,
          moduleName: this.polyFungiV2StakeModuleName,
          moduleNamespace: this.polyFungiV2StakeModuleNamespace
        };

        let t_uri = null;

        this.t_stakeTokenImagePolyfungiv2 = "0";
        this.t_stakeTokenNamePolyfungiv2 = this.polyFungiV2StakeID;
        this.t_stakeTokenDescriptionPolyfungiv2 = this.polyFungiV2StakeModuleNamespace + this.polyFungiV2StakeModuleName;

        t_uri = await this.$store.dispatch("accounts/getURIpolyfungiv2", t_payload);

        if (this.debug) {
          console.log("Pool Creation Step Forward Metadata Check:");
          console.log("POLYFUNGIV2 STAKE TOKEN METADATA RESULT FOR t_uri:");
          console.log(t_uri);
          console.log("t_uri.image");
          console.log(t_uri.image);
          console.log("t_uri.name");
          console.log(t_uri.name);
        }

        if (t_uri.image !== undefined) {
          this.t_stakeTokenImagePolyfungiv2 = t_uri.image;
        }

        if (t_uri !== undefined) {
          if (t_uri !== "0") {


            if (t_uri.name !== undefined) {
              this.t_stakeTokenNamePolyfungiv2 = t_uri.name;
            }

            if (t_uri.description !== undefined) {
              this.t_stakeTokenDescriptionPolyfungiv2 = t_uri.description;
            }

          }
        }

      }
    },

    async getPolyFungiV2RewardTokenImage() {
      if (this.rewardType === 3) {

        let t_payload = {
          tokenId: this.polyFungiV2ID,
          moduleName: this.polyFungiV2ModuleName,
          moduleNamespace: this.polyFungiV2ModuleNamespace
        };

        let t_uri = null;

        this.t_rewardTokenImagePolyfungiv2 = "0";

        t_uri = await this.$store.dispatch("accounts/getURIpolyfungiv2Image", t_payload);

        if (this.debug) {
          console.log("Pool Creation Step Forward Metadata Check:");
          console.log("POLYFUNGIV2 REWARD TOKEN METADATA RESULT FOR t_uri:");
          console.log(t_uri);
        }

        if (t_uri !== undefined) {
          if (t_uri !== "0") {
            this.t_rewardTokenImagePolyfungiv2 = t_uri;
          }
        }

      }
    },

    async checkIfVestingPool100(){

      if(this.vestingPool_percentLeftTotal === 0){
        this.canGoNext = true;
      }

    },

    async checkIfEnoughContacts(){

      if(this.dao_currentContact >= 1){
        this.localModuleExists = true;
      }

    },

    async checkIfEnoughLinks(){

      if(this.dao_currentLink >= 1){
        this.localModuleExists = true;
      }

    },

    async checkIfDescriptionOk(){

      if(this.dao_description.length > 3){
        this.localModuleExists = true;
      }

    },

    async checkIfGreaterThanThree(name){

      if(name.toString().length > 3){
        this.localModuleExists = true;
      }else {
        this.localModuleExists = false;
      }

    },

    async checkIfLessThan40(name){

      if(name.toString().length <= 40){
        this.localModuleExists = true;
      }else {
        this.localModuleExists = false;
      }

    },





    async resetVestingPool(){

      this.canGoNext = false;
      this.vestingPool_count = 0;
      this.VestingMemberData = [];
      this.vestingPool_percentLeftTotal = 100;
      this.vestingPool_percentLeft = 0;
      this.vestingPool_accounts = [];

    },

    async doStepForward() {

      this.currentPage = this.currentPage + 1;

      if(this.currentPage === 4 && this.activeTab === '6'){
        this.canGoNext = false;
      }

      if(this.vestingPool_percentLeftTotal === 0){
        this.canGoNext = true;
      }

      if(this.makeDaoSelection === '3'){
        if(this.currentPage === 2){
          this.localModuleExists = false;
        }
        if(this.currentPage === 3){
          this.localModuleExists = false;
        }
        if(this.currentPage === 4){
          this.localModuleExists = false;
        }
        if(this.currentPage === 5){
          this.localModuleExists = false;
        }
      }

      if(this.makeDaoSelection === '2'){
        if(this.currentPage === 2){
          this.localModuleExists = false;
        }
        if(this.currentPage === 5){
          this.localModuleExists = false;
        }
        if(this.currentPage === 4){
          this.localModuleExists = false;
        }
        if(this.currentPage === 6){
          this.localModuleExists = false;
        }
      }

      if(this.makeDaoSelection === '1'){
        if(this.currentPage === 2){
          this.localModuleExists = false;
        }
      }

      if(this.makeNftSelection === '4'){
        if(this.currentPage === 2){
          this.localModuleExists = false;
        }
        if(this.currentPage === 3){
          this.localModuleExists = false;
        }
      }

      if(this.makeNftSelection === '3'){
        if(this.currentPage === 2){
          this.localModuleExists = false;
        }
      }

      if(this.makeNftSelection === '2'){
        if(this.currentPage === 2){
          this.localModuleExists = false;
        }
      }

      if (this.rewardType === 1 && this.customFungiv2Reward === false) {


        if (this.rewardFungiV2Type !== "coin") {
          let t_rewardType = this.rewardFungiV2Type.split(".");
          this.fungiv2RewardModuleNamespace = t_rewardType[0];
          this.fungiv2RewardModuleName = t_rewardType[1];
        } else if (this.rewardFungiV2Type === "coin") {
          this.fungiv2RewardModuleNamespace = "";
          this.fungiv2RewardModuleName = "coin";
        }


        if (this.debug) {
          console.log("Autoformatting fungiv2RewardModuleNamespace + fungiv2RewardModuleName for non-custom fungiv2 stake token");
        }


      }


      /////////////////////////////
      //POLYFUNGIV1 METADATA
      /////////////////////////////
      //Load poly-fungi-v1 reward token images
      // if (this.rewardType === 2) {
      //
      //   let t_payload = {
      //     tokenId: this.polyFungiV1ID,
      //     moduleName: this.polyFungiV1ModuleName,
      //     moduleNamespace: this.polyFungiV1ModuleNamespace
      //   };
      //
      //   let t_uri = null;
      //
      //   this.t_rewardTokenImagePolyfungiv1 = "0";
      //
      //   t_uri = await this.$store.dispatch("accounts/getURIpolyfungiv1", t_payload);
      //
      //   if (typeof (t_uri) === "object") {
      //
      //     //Lets look through this object for an 'image' property
      //     if (t_uri.image !== undefined) {
      //
      //       this.t_rewardTokenImagePolyfungiv1 = t_uri.image;
      //     } else {
      //       //If not return a 0 for unknown
      //       this.t_rewardTokenImagePolyfungiv1 = "0";
      //     }
      //
      //   }
      //
      // }

      // //Load poly-fungi-v1 stake token images
      // if (this.stakeType === 2) {
      //
      //   let t_payload = {
      //     tokenId: this.polyFungiV1StakeID,
      //     moduleName: this.polyFungiV1StakeModuleName,
      //     moduleNamespace: this.polyFungiV1StakeModuleNamespace
      //   };
      //
      //   let t_uri = null;
      //
      //   this.t_stakeTokenImagePolyfungiv1 = "0";
      //
      //   t_uri = await this.$store.dispatch("accounts/getURIpolyfungiv1", t_payload);
      //
      //   if (typeof (t_uri) === "object") {
      //
      //     //Lets look through this object for an 'image' property
      //     if (t_uri.image !== undefined) {
      //
      //       this.t_stakeTokenImagePolyfungiv1 = t_uri.image;
      //     } else {
      //       //If not return a 0 for unknown
      //       this.t_stakeTokenImagePolyfungiv1 = "0";
      //     }
      //
      //   }
      //
      // }


      // /////////////////////////////
      // //POLYFUNGIV2 METADATA
      // /////////////////////////////
      // //Load poly-fungi-v2 reward token images
      // if (this.rewardType === 3) {
      //
      //   let t_payload = {
      //     tokenId: this.polyFungiV2ID,
      //     moduleName: this.polyFungiV2ModuleName,
      //     moduleNamespace: this.polyFungiV2ModuleNamespace
      //   };
      //
      //   let t_uri = null;
      //
      //   this.t_rewardTokenImagePolyfungiv2 = "0";
      //
      //   t_uri = await this.$store.dispatch("accounts/getURIpolyfungiv2Image", t_payload);
      //
      //   if (this.debug) {
      //     console.log("Pool Creation Step Forward Metadata Check:");
      //     console.log("POLYFUNGIV2 REWARD TOKEN METADATA RESULT FOR t_uri:");
      //     console.log(t_uri);
      //   }
      //
      //   if (t_uri !== undefined) {
      //     if (t_uri !== "0") {
      //       this.t_rewardTokenImagePolyfungiv2 = t_uri;
      //     }
      //   }
      //
      // }

      // //Load poly-fungi-v2 stake token images
      // if (this.stakeType === 3) {
      //
      //   let t_payload = {
      //     tokenId: this.polyFungiV2StakeID,
      //     moduleName: this.polyFungiV2StakeModuleName,
      //     moduleNamespace: this.polyFungiV2StakeModuleNamespace
      //   };
      //
      //   let t_uri = null;
      //
      //   this.t_stakeTokenImagePolyfungiv1 = "0";
      //
      //   t_uri = await this.$store.dispatch("accounts/getURIpolyfungiv2Image", t_payload);
      //
      //   if (this.debug) {
      //     console.log("Pool Creation Step Forward Metadata Check:");
      //     console.log("POLYFUNGIV2 STAKE TOKEN METADATA RESULT FOR t_uri:");
      //     console.log(t_uri);
      //   }
      //
      //   if (t_uri !== undefined) {
      //     if (t_uri !== "0") {
      //       this.t_stakeTokenImagePolyfungiv2 = t_uri;
      //     }
      //   }
      //
      //
      // }


      // if (this.debug) {
      //   console.log("t_stakeTokenImagePolyfungiv1");
      //   console.log(this.t_stakeTokenImagePolyfungiv1);
      //   console.log("t_rewardTokenImagePolyfungiv1");
      //   console.log(this.t_rewardTokenImagePolyfungiv1);
      //   console.log("t_stakeTokenImagePolyfungiv2");
      //   console.log(this.t_stakeTokenImagePolyfungiv2);
      //   console.log("t_rewardTokenImagePolyfungiv2");
      //   console.log(this.t_rewardTokenImagePolyfungiv2);
      // }


      //Are we on the Pool rules page?
      if (this.currentPage === 4 && this.stopAutoField === false) {
        this.stopAutoField = true;
      }

      // if (this.currentPage === 4) {
      //   this.determinePoolId();
      // }
      if (this.debug) {
        console.log("Next slide");
      }

    },

    async doStepBackwards() {

      console.log("Previous slide");

      if(this.currentPage <= 3 && this.activeTab === 6){
        this.vestingPool_count = 0;
        this.VestingMemberData = [];
        this.vestingPool_percentLeftTotal = 100;
        this.vestingPool_percentLeft = 0;
        this.vestingPool_accounts = [];
      }

      this.currentPage = this.currentPage - 1;


      if(this.currentPage !== 3 && this.activeTab !== 6){
        this.canGoNext = true;
      }





    },

    checkLPInput() {

      console.log("checklpinput");

      if (this.lpToken1Type === this.lpToken2Type) {
        this.lpValid = false;
      } else if (this.lpToken1Type !== this.lpToken2Type) {
        this.lpValid = true;
      }

    },

    async checkValidInput(isvalid) {
      this.inputisvalid = isvalid;
    },

    async checkValidInputLME() {
      this.localModuleExists = true;
    },

    async checkWlTime() {

      let wlenddatetime = this.crowdFundWhitelistTime2.getTime();

      let wlseconds = ((wlenddatetime - new Date().getTime()) / 1000);

      if (wlseconds >= 0) {
        this.wlok = true;
      } else {
        this.wlok = false;
      }

    },

    async checkNFTWlTime() {

      let wlenddatetime = this.whitelist_time2.getTime();

      let wlseconds = ((wlenddatetime - new Date().getTime()) / 1000);

      if (wlseconds >= 0) {
        this.nftwlok = true;
        this.localModuleExists = true;
      } else {
        this.nftwlok = false;
      }

    },

    async checkNFTPublicSaleTime() {

      let wlenddatetime = this.whitelist_publictime.getTime();

      let wlseconds = ((wlenddatetime - new Date().getTime()) / 1000);

      if (wlseconds >= 0) {
        this.nftwlok = true;
        this.localModuleExists = true;
      } else {
        this.nftwlok = false;
      }

    },

    async checkCampaignTime() {

      let wlenddatetime = this.crowdFundCampaignTime2.getTime();

      let wlseconds = ((wlenddatetime - new Date().getTime()) / 1000);

      if (wlseconds >= 0) {
        this.campaignok = true;
      } else {
        this.campaignok = false;
      }

    },

    async checkInsuranceTime() {

      let wlenddatetime = this.crowdFundInsuranceTime2.getTime();

      let wlseconds = ((wlenddatetime - new Date().getTime()) / 1000);

      if (wlseconds >= 0) {
        this.insuranceok = true;
      } else {
        this.insuranceok = false;
      }

    },

    async checkPropositionTime() {

      let wlenddatetime = this.vote_time.getTime();

      this.vote_timeseconds = ((wlenddatetime - new Date().getTime()) / 1000);

      if (this.vote_timeseconds >= 0) {
        this.localModuleExists = true;
      } else {
        this.localModuleExists = false;
      }

    },

    async autoVerifyPoolId(rewardType, stakeType) {

      let payload = this.poolId;
      if (rewardType === 1 && stakeType === 1) {
        await this.$store.dispatch("accounts/getPoolIdVerificationFungiv2", payload);
      } else if (rewardType === 2 && stakeType === 1) {
        await this.$store.dispatch("accounts/getPoolIdVerificationFungiv2Polyfungiv1", payload);
      } else if (rewardType === 1 && stakeType === 4) {
        await this.$store.dispatch("accounts/getPoolIdVerificationAswapTokensFungiv2", payload);
      } else if (rewardType === 1 && stakeType === 5) {
        await this.$store.dispatch("accounts/getPoolIdVerificationFactoryTokensFungiv2", payload);
      } else if (rewardType === 2 && stakeType === 2) {
        await this.$store.dispatch("accounts/getPoolIdVerificationPolyfungiv1", payload);
      } else if (rewardType === 3 && stakeType === 3) {
        await this.$store.dispatch("accounts/getPoolIdVerificationPolyfungiv2", payload);
      } else if (rewardType === 1 && stakeType === 2) {
        await this.$store.dispatch("accounts/getPoolIdVerificationPolyfungiv1Fungiv2", payload);
      } else if (rewardType === 6 && stakeType === 6){
        await this.$store.dispatch("accounts/getPoolIdVerificationVestingFungiv2", payload);
      }

      // this.localPoolIdExists1 = false;
      // this.localPoolIdExists2 = false;

      //Make sure this fungiv2 account dont exist
      if (rewardType === 1) {

        let payload_token = {
          tokenA: this.rewardFungiV2Type,
          name: this.poolId
        };
        if (this.customFungiv2Reward === true) {
          payload_token.tokenA = this.fungiv2RewardModuleNamespace + "." + this.fungiv2RewardModuleName;
        }

        await this.$store.dispatch("accounts/getAccountVerificationToken", payload_token);
      }

      //Check if this Stake Factory LP token account doesnt exist
      if (stakeType === 5) {

        let payload_token = {
          tokenA: this.lpToken1Type,
          tokenB: this.lpToken2Type,
          poolName: this.lpStakeTokenPoolName,
          name: this.poolId
        };

        if (this.customLPStakeToken === true) {
          if (this.lp1StakeModuleNamespace !== "") {
            payload.tokenA = this.lp1StakeModuleNamespace + "." + this.lp1StakeModuleName;
          } else if (this.lp1StakeModuleNamespace === "") {
            payload.tokenA = this.lp1StakeModuleName;
          }

          if (this.lp2StakeModuleNamespace !== "") {
            payload.tokenB = this.lp2StakeModuleNamespace + "." + this.lp2StakeModuleName;
          } else if (this.lp2StakeModuleNamespace === "") {
            payload.tokenB = this.lp2StakeModuleName;
          }
        }

        await this.$store.dispatch("accounts/getAccountLPVerificationToken", payload_token);

      }


    },

    async autoVerifyLiquidityPoolId(poolID) {

      let payload = poolID;
      await this.$store.dispatch("accounts/getLiquidityPoolIdVerification", payload);

    },

    async autoVerifyCrowdFundPool(poolID) {

      if (this.debug) {
        console.log("autoVerifyCrowdFundPool");
        console.log("poolID");
        console.log(poolID);
      }

      const t_payload = {
        poolName: poolID
      };

      await this.$store.dispatch("accounts/getCrowdFundPoolIdVerification", t_payload);

    },

    async autoVerifyCrowdFundLiquidityPool(payload) {

      if (this.debug) {
        console.log("autoVerifyCrowdFundLiquidityPool");
        console.log("payload");
        console.log(payload);
      }

      const t_payload = payload;
      await this.$store.dispatch("accounts/getCrowdFundLiquidityPoolIdVerification", t_payload);

    },

    async closeSignatureModal() {
      this.showCreatePoolModal = false;
    },

    async clickCreateCrowdFundPool() {

      if (this.localPoolIdExists === false || this.localPoolIdExists1 === false) {

        if (this.isMakingTransaction === false) {

          let name = localStorage.getItem("accountName");

          let payload = {
            poolID: this.crowdFundPoolId,
            poolName: this.crowdFundPoolName,
            description: this.crowdFundPoolDescription,
            balance: (parseFloat(this.crowdFundSellTokenQuantity) + parseFloat(this.crowdFundPairQuantity)),
            amountToSell: this.crowdFundSellTokenQuantity,
            amountToDex: this.crowdFundPairQuantity,
            soldToken: this.coinName(this.crowdFundSellTokenModuleNamespace, this.crowdFundSellTokenModuleName),
            pledgeToken: this.coinName(this.crowdFundPledgeTokenModuleNamespace, this.crowdFundPledgeTokenModuleName),
            account: name,
            price: this.crowdFundSellTokenPrice,
            runTime: this.crowdFundCampaignTime,
            insuranceTime: this.crowdFundInsuranceTime,
            lpPoolName: this.crowdFundLiquidityPoolName,
            lpPoolFee: this.crowdFundLiquidityPoolSwapFeeFinal,
            wlRunTime: this.crowdFundWhitelistTime,
            wl: this.crowdFundWhitelist_list,
            wlMaxBuy: this.crowdFundWhitelistMaxCanBuy,
            maxbuy: this.crowdFundMaxBuyQuantity
          };


          if (this.debug) {
            console.log("CLICK clickCreateCrowdFundPool");
            console.log("payload");
            console.log(payload);
          }

          this.showCreatePoolModal = true;

          this.isCreatingCrowdfundingPool = true;
          this.isCreatingCrowdfundingPool_message = payload.poolName;
          this.isCreatingCrowdfundingPool_poolid = payload.poolID;
          this.isMakingCreatePoolTransaction = true;


          await this.$store.dispatch("accounts/createCrowdFundingPool", payload);

          this.makingPool = true;

        } else {
          alert("Please wait for your current transaction to go through before creating another pool.");
        }


      } else {
        alert("One of the pool names you entered is invalid, please try another.");
      }


    },

    async clickCreateLiquidityPool() {

      if (this.isMakingTransaction === false) {

        const payload = {
          poolName: this.liquidityPoolName,
          tokenA: this.coinName(this.liquidityPairTokenANamespace, this.liquidityPairTokenAName),
          tokenB: this.coinName(this.liquidityPairTokenBNamespace, this.liquidityPairTokenBName),
          fee: this.liquidityPoolFeeFinal
        };

        this.showCreatePoolModal = true;

        this.isCreatingLiquidityPool = true;
        this.isCreatingLiquidityPool_poolid = this.liquidityPoolName;
        this.isCreatingLiquidityPool_pair = await this.$store.dispatch("accounts/getPairKey", payload);
        this.isMakingCreatePoolTransaction = true;

        this.makingPool = true;

        await this.$store.dispatch("accounts/stakeFactoryCreateLiquidityPool", payload);

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

    async clickCreateNFTCollection() {

      if (this.isMakingTransaction === false) {

        const collectionid = this.collectionID;
        const link = this.collectionLink;
        const signature = this.collectionSignature;
        const image = this.collectionImageLink;

        let token = this.coinName(this.make_nft_collectionNamespace, this.make_nft_collectionModuleName);

        if(this.collectionAnyToken === true){
          token = "coin";
        }

        const payload = {
          collectionid: collectionid,
          link: link,
          image: image,
          signature: signature,
          token: token
        };

        this.showCreatePoolModal = true;

        this.isCreatingNFTCollection = true;
        this.isCreatingNFTCollection_poolid = collectionid;
        this.isMakingCreatePoolTransaction = true;
        this.makingPool = true;

        await this.$store.dispatch("accounts/createNFTCollection", payload);

      } else {
        alert("Please wait for your current transaction to go through before creating a NFT collection.");
      }


    },

    async clickCreateNFT() {

      if (this.isMakingTransaction === false) {

        const collectionid = this.makenft_collectionID;
        const nftid = this.makenft_nftID;
        const name = this.makenft_name;
        const description = this.makenft_description;
        const image = this.makenft_image;
        const a1 = this.makenft_a1_final;
        const a2 = this.makenft_a2_final;
        const a3 = this.makenft_a3_final;

        const payload = {
          collectionid: collectionid,
          nftid: nftid,
          name: name,
          description: description,
          image: image,
          a1: a1,
          a2: a2,
          a3: a3
        };

        console.log("PAYLOAD")
        console.log(payload);
        console.log("A1")
        console.log(a1);

        this.showCreatePoolModal = true;

        this.isCreatingNFT = true;
        this.isCreatingNFT_poolid = nftid;
        this.isMakingCreatePoolTransaction = true;
        this.makingPool = true;

        await this.$store.dispatch("accounts/createNFT", payload);

      } else {
        alert("Please wait for your current transaction to go through before creating a NFT.");
      }


    },

    async clickCreateNFTWhitelist() {

        if (this.isMakingTransaction === false) {

          let payload = {
            collectionid: this.whitelist_collectionID,
            wl: this.whitelist_list,
            runtime: this.whitelist_wlInSeconds,
            runtime2: this.whitelist_PublicTimeInSeconds,
            maxbuy: this.whitelist_maxbuy,
            price: this.whitelist_price,
            anyonecanbuy: this.whitelist_anyoneCanBuy
          };


          if (this.debug) {
            console.log("CLICK clickCreateNFTWhitelist");
            console.log("payload");
            console.log(payload);
          }

          this.showCreatePoolModal = true;

          this.isCreatingNFTWhitelist = true;
          this.isCreatingNFTWhitelist_poolid = payload.collectionid;
          this.isMakingCreatePoolTransaction = true;


          await this.$store.dispatch("accounts/createNFTWhitelist", payload);

          this.makingPool = true;

        } else {
          alert("Please wait for your current transaction to go through before creating a whitelist.");
        }


    },

    async clickMassMintNFTs() {

      if (this.isMakingTransaction === false) {

        const collectionid = this.makenft_collectionID;
        const nfts = this.makenft_massNftsFinal;

        const payload = {
          collectionid: collectionid,
          nfts: nfts,
        };

        console.log("PAYLOAD")
        console.log(payload);

        this.showCreatePoolModal = true;

        this.isMassMintingNfts = true;
        this.isMassMintingNfts_poolid = collectionid;
        this.isMakingCreatePoolTransaction = true;
        this.makingPool = true;

        await this.$store.dispatch("accounts/massMintNFT", payload);

      } else {
        alert("Please wait for your current transaction to go through before creating NFTs.");
      }


    },

    async clickCreatePool() {

      if (this.isMakingTransaction === false) {

        let rewardType = this.rewardType;
        let stakeType = this.stakeType;

        if (rewardType === 1 && stakeType === 1) {
          await this.createFactoryStakeFungiv2Pool();
        } else if (rewardType === 2 && stakeType === 1) {
          await this.createFactoryStakeFungiv2Polyfungiv1Pool();
        } else if (rewardType === 1 && stakeType === 4) {
          await this.createFactoryStakeAswapTokensFungiv2Pool();
        } else if (rewardType === 1 && stakeType === 5) {
          await this.createFactoryStakeFactoryTokensFungiv2Pool();
        } else if (rewardType === 2 && stakeType === 2) {
          await this.createFactoryStakePolyfungiv1Pool();
        } else if (rewardType === 3 && stakeType === 3) {
          await this.createFactoryStakePolyfungiv2Pool();
        } else if (rewardType === 1 && stakeType === 2) {
          await this.createFactoryStakeFNFFungiv2();
        } else if (rewardType === 1 && stakeType === 3) {
          await this.createFactoryStakePolyfungiv2Fungiv2Pool();
        }

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

    async clickCreateVestingPool() {

      if (this.isMakingTransaction === false) {

          await this.createFactoryVestingFungiv2();

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

    async clickCreateTask() {

      if (this.isMakingTransaction === false) {

        await this.createFactoryTask();

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

    async createFactoryTask() {

      if (this.isMakingTransaction === false) {

        if (this.localPoolIdExists === false) {


          let payload = {
            subject_id: this.vote_daoid,
            name: this.task_name,
            description: this.task_description,
            run_time: this.vote_timeseconds,
            pay_token: this.coinName(this.task_namespace, this.task_modulename),
            pay_account: this.task_payaccount,
            pay_amount: this.rewardAmount

          };

          console.log("createFactoryTask");
          console.log("payload");
          console.log(payload);

          this.showCreatePoolModal = true;

          this.isCreatingTask = true;
          this.isCreatingTask_contract = "factory-dao";
          this.isCreatingTask_message = payload.name;
          this.isCreatingTask_poolid = payload.subject_id;
          this.isMakingCreatePoolTransaction = true;

          await this.$store.dispatch("accounts/factoryCreateTask", payload);

        } else {
          alert("Please correct the errors highlighted on the Task Creator to proceed.");
        }

      } else {
        alert("Please wait for your current transaction to go through before creating a task.");
      }


    },

    async clickCreateVotingPool() {

      if (this.isMakingTransaction === false) {

        await this.createFactoryVotingPoll();

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

    async createFactoryVotingPoll() {

      if (this.isMakingTransaction === false) {

        if (this.localPoolIdExists === false) {

          let name = localStorage.getItem("accountName");

          const dhinfo = {
            daoid: this.vote_daoid
          }

          let subjectinfo = await this.$store.dispatch("accounts/getDAOHive", dhinfo );


          let payload = {
            subject_id: this.vote_daoid,
            name: this.vote_name,
            creator: name,
            description: this.vote_proposition,
            run_time: this.vote_timeseconds,
            vote_token: this.coinName(this.dao_namespace, this.dao_moduleName),
            tokenA: this.coinName(subjectinfo.subject_token.refName.namespace, subjectinfo.subject_token.refName.name),
            amountA: subjectinfo.pool_cost.decimal ? subjectinfo.pool_cost.decimal : subjectinfo.pool_cost,
            pairAccount: subjectinfo.treasury_account,
            required_votes: this.rewardAmount,

          };


          console.log("createFactoryVotingPoll");
          console.log("payload");
          console.log(payload);

          this.showCreatePoolModal = true;

          this.isCreatingVotingPool = true;
          this.isCreatingVotingPool_contract = "factory-dao";
          this.isCreatingVotingPool_message = payload.name;
          this.isCreatingVotingPool_poolid = payload.subject_id;
          this.isMakingCreatePoolTransaction = true;

          await this.$store.dispatch("accounts/factoryCreateVotePoll", payload);

        } else {
          alert("Please correct the errors highlighted on the DAO Hive Creator to proceed.");
        }

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

    async clickCreateDAOHive() {

      if (this.isMakingTransaction === false) {

        await this.createFactoryDAOHive();

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

    async createFactoryDAOHive() {

      if (this.isMakingTransaction === false) {

        if (this.localPoolIdExists === false) {

          let name = localStorage.getItem("accountName");


          let payload = {
            subject_id: this.dao_id,
            name: this.dao_name,
            creator: name,
            icon: this.dao_icon,
            description: this.dao_description,
            about: this.dao_about,
            links: this.dao_links_final,
            contacts: this.dao_contacts_final,
            pool_cost: this.dao_proposalCost,
            subject_token: this.coinName(this.dao_namespace, this.dao_moduleName),
            creator_only: this.dao_creatorOnly
          };


          console.log("createFactoryDAOHive");
          console.log("payload");
          console.log(payload);

          this.showCreatePoolModal = true;

          this.isCreatingDAOHive = true;
          this.isCreatingDAOHive_contract = "factory-dao";
          this.isCreatingDAOHive_message = payload.name;
          this.isCreatingDAOHive_poolid = payload.subject_id;
          this.isMakingCreatePoolTransaction = true;

          await this.$store.dispatch("accounts/factoryCreateDAOHive", payload);

        } else {
          alert("Please correct the errors highlighted on the DAO Hive Creator to proceed.");
        }

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

    async createFactoryStakeFungiv2Pool() {

      if (this.isMakingTransaction === false) {

        if (this.localPoolIdExists === false) {

          let name = localStorage.getItem("accountName");

          let payload = {
            poolID: this.poolId,
            poolName: this.poolName,
            balance: this.rewardAmount,
            rewardToken: this.rewardFungiV2Type,
            stakeToken: this.stakeFungiV2Type,
            account: name,
            claimWaitSeconds: this.rewardWaitTime,
            rewardDuration: this.rewardDuration,
            rewardAmount: this.rewardDurationAmount,
            withdrawDuration: this.withdrawWaitTime,
          };

          //Change reward module name if custom
          if (this.customFungiv2Reward === true) {
            payload.rewardToken = this.fungiv2RewardModuleNamespace + "." + this.fungiv2RewardModuleName;
          }

          //Change stake module name if custom
          if (this.customFungiv2Stake === true) {
            payload.stakeToken = this.fungiv2StakeModuleNamespace + "." + this.fungiv2StakeModuleName;
          }


          if (this.debug) {
            console.log("CLICK createFactoryStakeFungiv2Pool");
            console.log("payload");
            console.log(payload);
          }

          this.showCreatePoolModal = true;

          this.isCreatingStakingPool = true;
          this.isCreatingStakingPool_contract = "factory-stake-fungiv2";
          this.isCreatingStakingPool_message = payload.poolName;
          this.isCreatingStakingPool_poolid = payload.poolID;
          this.isMakingCreatePoolTransaction = true;

          await this.$store.dispatch("accounts/createFactoryStakeFungiv2", payload);

        } else {
          alert("The pool ID you entered is already taken, please try another.");
        }

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

    async createFactoryStakeFungiv2Polyfungiv1Pool() {

      if (this.isMakingTransaction === false) {

        if (this.localPoolIdExists === false) {

          let name = localStorage.getItem("accountName");

          let payload = {
            poolID: this.poolId,
            poolName: this.poolName,
            balance: this.rewardAmount,
            rewardTokenId: this.polyFungiV1ID,
            rewardToken: this.polyFungiV1ModuleNamespace + "." + this.polyFungiV1ModuleName,
            stakeToken: this.stakeFungiV2Type,
            account: name,
            claimWaitSeconds: this.rewardWaitTime,
            rewardDuration: this.rewardDuration,
            rewardAmount: this.rewardDurationAmount,
            withdrawDuration: this.withdrawWaitTime,
          };

          //Change stake module name if custom
          if (this.customFungiv2Stake === true) {
            payload.stakeToken = this.fungiv2StakeModuleNamespace + "." + this.fungiv2StakeModuleName;
          }


          if (this.debug) {
            console.log("createFactoryStakeFungivPolyfungiv1Pool");
            console.log("payload");
            console.log(payload);
          }

          this.showCreatePoolModal = true;

          this.isCreatingStakingPool = true;
          this.isCreatingStakingPool_contract = "factory-stake-fungiv2-polyfungiv1";
          this.isCreatingStakingPool_message = payload.poolName;
          this.isCreatingStakingPool_poolid = payload.poolID;
          this.isMakingCreatePoolTransaction = true;

          await this.$store.dispatch("accounts/createFactoryStakeFungiv2Polyfungiv1", payload);

        } else {
          alert("The pool ID you entered is already taken, please try another.");
        }

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

    async createFactoryStakeFNFFungiv2() {

      if (this.isMakingTransaction === false) {

        if (this.localPoolIdExists === false) {

          let name = localStorage.getItem("accountName");

          let payload = {
            poolID: this.poolId,
            poolName: this.poolName,
            balance: this.rewardAmount,
            rewardToken: this.rewardFungiV2Type,
            collectionid: this.stake_CollectionID,
            stakeToken: "free.factory-non-fungible8",
            account: name,
            claimWaitSeconds: this.rewardWaitTime,
            rewardDuration: this.rewardDuration,
            rewardAmount: this.rewardDurationAmount,
            withdrawDuration: this.withdrawWaitTime,
          };

          //Change stake module name if custom
          if (this.customFungiv2Reward === true) {
            payload.rewardToken = this.fungiv2RewardModuleNamespace + "." + this.fungiv2RewardModuleNamespace;
          }


          if (this.debug) {
            console.log("createFactoryStakeFNFFungiv2Pool");
            console.log("payload");
            console.log(payload);
          }

          this.showCreatePoolModal = true;

          this.isCreatingStakingPool = true;
          this.isCreatingStakingPool_contract = "factory-stake-fnf-fungiv2";
          this.isCreatingStakingPool_message = payload.poolName;
          this.isCreatingStakingPool_poolid = payload.poolID;
          this.isMakingCreatePoolTransaction = true;

          await this.$store.dispatch("accounts/createFactoryStakeFNFFungiv2", payload);

        } else {
          alert("The pool ID you entered is already taken, please try another.");
        }

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

    async createFactoryVestingFungiv2() {

      if (this.isMakingTransaction === false) {

        if (this.localPoolIdExists === false) {

          let name = localStorage.getItem("accountName");

          let stakables = [];

          let stakables2 = null;

          for(let i = 0; i < this.VestingMemberData.length; i++){

            let stakable = {
              "pool-id": this.poolId,
              account: this.VestingMemberData[i].account,
              amount: Number(this.VestingMemberData[i].percentage),
              "set-withdraw-duration": true,
              "withdraw-duration": 0.0,
              "max-rewards": Number(this.VestingMemberData[i].max_tokens)
            }

            let stakable2 = `{ "pool-id" : "${this.poolId}", "account" : "${this.VestingMemberData[i].account}", "amount" : ${Number(this.VestingMemberData[i].percentage).toFixed(2)}, "set-withdraw-duration" : true, "withdraw-duration" : 0.0, "max-rewards" : ${this.VestingMemberData[i].max_tokens} }`

            //const temp = JSON.parse(stakable);

            if (stakables.includes(stakable) === false) {
              stakables.push(stakable);
            }

            if(stakables2 === null){
              stakables2 = stakable2;
            }else{

              stakables2 = stakables2 + '|' + stakable2;


            }


          }

          //let stakables2 = JSON.stringify(stakables);

          console.log("stakables2");
          console.log(stakables2);

          let stakeables = stakables2.split('|').filter(element => JSON.parse(element));

          console.log("stakeables");
          console.log(stakeables);

          //let stakeables = stakables2.filter(element => JSON.parse(element));

          let payload = {
            poolID: this.poolId,
            poolName: this.poolName,
            poolDescription: this.vestingPool_description,
            balance: this.rewardAmount,
            rewardToken: this.rewardFungiV2Type,
            account: name,
            claimWaitSeconds: this.rewardWaitTime,
            rewardDuration: this.rewardDuration,
            rewardAmount: this.rewardDurationAmount,
            withdrawDuration: this.withdrawWaitTime,
            stakables: stakeables,
          };

          //Change stake module name if custom
          if (this.customFungiv2Reward === true) {
            payload.rewardToken = this.fungiv2RewardModuleNamespace + "." + this.fungiv2RewardModuleNamespace;
          }


            console.log("createFactoryVestingFungiv2Pool");
            console.log("payload");
            console.log(payload);

          this.showCreatePoolModal = true;

          this.isCreatingVestingPool = true;
          this.isCreatingVestingPool_contract = "factory-vesting-fungiv2";
          this.isCreatingVestingPool_message = payload.poolName;
          this.isCreatingVestingPool_poolid = payload.poolID;
          this.isMakingCreatePoolTransaction = true;

          await this.$store.dispatch("accounts/createFactoryVestingFungiv2", payload);

        } else {
          alert("The pool ID you entered is already taken, please try another.");
        }

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

    async createFactoryStakePolyfungiv1Fungiv2Pool() {

      if (this.isMakingTransaction === false) {

        if (this.localPoolIdExists === false) {

          let name = localStorage.getItem("accountName");

          let payload = {
            poolID: this.poolId,
            poolName: this.poolName,
            balance: this.rewardAmount,
            rewardToken: this.rewardFungiV2Type,
            stakeTokenId: this.polyFungiV1StakeID,
            stakeToken: this.polyFungiV1StakeModuleNamespace + "." + this.polyFungiV1StakeModuleName,
            account: name,
            claimWaitSeconds: this.rewardWaitTime,
            rewardDuration: this.rewardDuration,
            rewardAmount: this.rewardDurationAmount,
            withdrawDuration: this.withdrawWaitTime,
          };

          //Change stake module name if custom
          if (this.customFungiv2Reward === true) {
            payload.rewardToken = this.fungiv2RewardModuleNamespace + "." + this.fungiv2RewardModuleNamespace;
          }


          if (this.debug) {
            console.log("createFactoryStakePolyfungiv1Fungiv2Pool");
            console.log("payload");
            console.log(payload);
          }

          this.showCreatePoolModal = true;

          this.isCreatingStakingPool = true;
          this.isCreatingStakingPool_contract = "factory-stake-polyfungiv1-fungiv2";
          this.isCreatingStakingPool_message = payload.poolName;
          this.isCreatingStakingPool_poolid = payload.poolID;
          this.isMakingCreatePoolTransaction = true;

          await this.$store.dispatch("accounts/createFactoryStakePolyfungiv1Fungiv2", payload);

        } else {
          alert("The pool ID you entered is already taken, please try another.");
        }

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

    async createFactoryStakePolyfungiv2Fungiv2Pool() {

      if (this.isMakingTransaction === false) {

        if (this.localPoolIdExists === false) {

          let name = localStorage.getItem("accountName");

          let payload = {
            poolID: this.poolId,
            poolName: this.poolName,
            balance: this.rewardAmount,
            rewardToken: this.rewardFungiV2Type,
            stakableNFTs: this.polyFungiV2StakableIDs_list,
            stakeToken: this.polyFungiV2StakeModuleNamespace + "." + this.polyFungiV2StakeModuleName,
            account: name,
            claimWaitSeconds: this.rewardWaitTime,
            rewardDuration: this.rewardDuration,
            rewardAmount: this.rewardDurationAmount,
            withdrawDuration: this.withdrawWaitTime,
            image: this.polyFungiV2StakeImage
          };

          //Change stake module name if custom
          if (this.customFungiv2Reward === true) {
            payload.rewardToken = this.fungiv2RewardModuleNamespace + "." + this.fungiv2RewardModuleNamespace;
          }


          if (this.debug) {
            console.log("createFactoryStakePolyfungiv2Fungiv2Pool");
            console.log("payload");
            console.log(payload);
          }

          this.showCreatePoolModal = true;

          this.isCreatingStakingPool = true;
          this.isCreatingStakingPool_contract = "factory-stake-polyfungiv2-fungiv2";
          this.isCreatingStakingPool_message = payload.poolName;
          this.isCreatingStakingPool_poolid = payload.poolID;
          this.isMakingCreatePoolTransaction = true;

          await this.$store.dispatch("accounts/createFactoryStakePolyFungiv2Fungiv2", payload);

        } else {
          alert("The pool ID you entered is already taken, please try another.");
        }

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

    async createFactoryStakePolyfungiv1Pool() {

      if (this.isMakingTransaction === false) {

        if (this.localPoolIdExists === false) {

          let name = localStorage.getItem("accountName");

          let payload = {
            poolID: this.poolId,
            poolName: this.poolName,
            balance: this.rewardAmount,
            rewardTokenId: this.polyFungiV1ID,
            rewardToken: this.polyFungiV1ModuleNamespace + "." + this.polyFungiV1ModuleName,
            stakeToken: this.polyFungiV1StakeModuleNamespace + "." + this.polyFungiV1StakeModuleName,
            stakeTokenId: this.polyFungiV1StakeID,
            account: name,
            claimWaitSeconds: this.rewardWaitTime,
            rewardDuration: this.rewardDuration,
            rewardAmount: this.rewardDurationAmount,
            withdrawDuration: this.withdrawWaitTime,
          };


          if (this.debug) {
            console.log("createFactoryStakeFungivPolyfungiv1Pool");
            console.log("payload");
            console.log(payload);
          }

          this.showCreatePoolModal = true;

          this.isCreatingStakingPool = true;
          this.isCreatingStakingPool_contract = "factory-stake-polyfungiv1";
          this.isCreatingStakingPool_message = payload.poolName;
          this.isCreatingStakingPool_poolid = payload.poolID;
          this.isMakingCreatePoolTransaction = true;

          await this.$store.dispatch("accounts/createFactoryStakePolyfungiv1", payload);

        } else {
          alert("The pool ID you entered is already taken, please try another.");
        }

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

    async createFactoryStakePolyfungiv2Pool() {

      if (this.isMakingTransaction === false) {

        if (this.localPoolIdExists === false) {

          let name = localStorage.getItem("accountName");

          let payload = {
            poolID: this.poolId,
            poolName: this.poolName,
            balance: this.rewardAmount,
            rewardTokenId: this.polyFungiV2ID,
            rewardToken: this.polyFungiV2ModuleNamespace + "." + this.polyFungiV2ModuleName,
            stakeToken: this.polyFungiV2StakeModuleNamespace + "." + this.polyFungiV2StakeModuleName,
            stakeTokenId: this.polyFungiV2StakeID,
            account: name,
            claimWaitSeconds: this.rewardWaitTime,
            rewardDuration: this.rewardDuration,
            rewardAmount: this.rewardDurationAmount,
            withdrawDuration: this.withdrawWaitTime,
          };


          if (this.debug) {
            console.log("createFactoryStakeFungivPolyfungiv2Pool");
            console.log("payload");
            console.log(payload);
          }

          this.showCreatePoolModal = true;

          this.showCreatePoolModal = true;

          this.isCreatingStakingPool = true;
          this.isCreatingStakingPool_contract = "factory-stake-polyfungiv2";
          this.isCreatingStakingPool_message = payload.poolName;
          this.isCreatingStakingPool_poolid = payload.poolID;
          this.isMakingCreatePoolTransaction = true;

          await this.$store.dispatch("accounts/createFactoryStakePolyfungiv2", payload);

        } else {
          alert("The pool ID you entered is already taken, please try another.");
        }

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

    async createFactoryStakeAswapTokensFungiv2Pool() {

      if (this.isMakingTransaction === false) {


        if (this.localPoolIdExists === false) {

          let name = localStorage.getItem("accountName");

          let payload = {
            poolID: this.poolId,
            poolName: this.poolName,
            balance: this.rewardAmount,
            rewardToken: this.rewardFungiV2Type,
            stakeToken1: this.lpToken1Type,
            stakeToken2: this.lpToken2Type,
            account: name,
            claimWaitSeconds: this.rewardWaitTime,
            rewardDuration: this.rewardDuration,
            rewardAmount: this.rewardDurationAmount,
            withdrawDuration: this.withdrawWaitTime,
          };

          //Change reward module name if custom
          if (this.customFungiv2Reward === true) {
            payload.rewardToken = this.fungiv2RewardModuleNamespace + "." + this.fungiv2RewardModuleName;
          }

          //Change stake module name if custom
          if (this.customLPStakeToken === true) {
            if (this.lp1StakeModuleNamespace !== "") {
              payload.stakeToken1 = this.lp1StakeModuleNamespace + "." + this.lp1StakeModuleName;
            } else if (this.lp1StakeModuleNamespace === "") {
              payload.stakeToken1 = this.lp1StakeModuleName;
            }

            if (this.lp2StakeModuleNamespace !== "") {
              payload.stakeToken2 = this.lp2StakeModuleNamespace + "." + this.lp2StakeModuleName;
            } else if (this.lp2StakeModuleNamespace === "") {
              payload.stakeToken2 = this.lp2StakeModuleName;
            }
          }


          if (this.debug) {
            console.log("CLICK createFactoryStakeAswapTokensFungiv2Pool");
            console.log("payload");
            console.log(payload);
          }

          this.showCreatePoolModal = true;

          await this.$store.dispatch("accounts/createFactoryStakeAswapTokensFungiv2", payload);

        } else {
          alert("The pool ID you entered is already taken, please try another.");
        }

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

    async createFactoryStakeFactoryTokensFungiv2Pool() {

      if (this.isMakingTransaction === false) {

        if (this.localPoolIdExists === false && this.localPoolIdExists1 === false && this.localPoolIdExists2 === false) {

          let name = localStorage.getItem("accountName");

          let payload = {
            poolID: this.poolId,
            poolName: this.poolName,
            balance: this.rewardAmount,
            rewardToken: this.rewardFungiV2Type,
            stakeToken1: this.lpToken1Type,
            stakeToken2: this.lpToken2Type,
            account: name,
            claimWaitSeconds: this.rewardWaitTime,
            rewardDuration: this.rewardDuration,
            rewardAmount: this.rewardDurationAmount,
            withdrawDuration: this.withdrawWaitTime,
            sf_poolName: this.lpStakeTokenPoolName
          };

          //Change reward module name if custom
          if (this.customFungiv2Reward === true) {
            payload.rewardToken = this.fungiv2RewardModuleNamespace + "." + this.fungiv2RewardModuleName;
          }

          //Change stake module name if custom
          if (this.customLPStakeToken === true) {
            if (this.lp1StakeModuleNamespace !== "") {
              payload.stakeToken1 = this.lp1StakeModuleNamespace + "." + this.lp1StakeModuleName;
            } else if (this.lp1StakeModuleNamespace === "") {
              payload.stakeToken1 = this.lp1StakeModuleName;
            }

            if (this.lp2StakeModuleNamespace !== "") {
              payload.stakeToken2 = this.lp2StakeModuleNamespace + "." + this.lp2StakeModuleName;
            } else if (this.lp2StakeModuleNamespace === "") {
              payload.stakeToken2 = this.lp2StakeModuleName;
            }
          }


          if (this.debug) {
            console.log("CLICK createFactoryStakeFactoryTokensFungiv2Pool");
            console.log("payload");
            console.log(payload);
          }

          this.showCreatePoolModal = true;

          this.isCreatingStakingPool = true;
          this.isCreatingStakingPool_contract = "factory-stake-factorytokens-fungiv2";
          this.isCreatingStakingPool_message = payload.poolName;
          this.isCreatingStakingPool_poolid = payload.poolID;
          this.isMakingCreatePoolTransaction = true;

          await this.$store.dispatch("accounts/createFactoryStakeFactoryTokensFungiv2", payload);

        } else {
          alert("The pool ID you entered is already taken, please try another.");
        }

      } else {
        alert("Please wait for your current transaction to go through before creating another pool.");
      }


    },

  },
  //created() executes 1 frame before our page is shown
  async created() {

    //Lets get the users account from local storage if it exists
    let name = localStorage.getItem("accountName");

    this.currentName = name;

    //Lets check if our user is logged in for the pool creation page, if not, lets redirect them to home page
    if (name === null || name === "" || name === "null") {
      if (this.debug) {
        console.log("No user local accountName found.");
      }
      await this.$router.push({path: `/`});
    } else {
      this.myaccount = name;
      let t_payload = {
        name: name
      };
      await this.$store.dispatch("accounts/getCurrentBindings", t_payload);
    }

    //Close loading screen
    setTimeout(async () => {
      this.showLoadingModal = false;
    }, 100);
  }
};
</script>

<style scoped>

/deep/ .textarea {
  height: 300px;
}

.container_msg{
  border-style: solid;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  border-width: 5px 5px 5px 5px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(78deg, transparent 15%, rgb(129 132 135 / 0%), rgb(67 96 92 / 10%) 85%), radial-gradient(rgb(20 45 64 / 0%), transparent 70%), linear-gradient(#0e081a00, #1b233130, #0f142066);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
}

.container_about {
  border-style: solid;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  border-width: 5px 5px 5px 5px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgb(129 132 135 / 0%), #0f5cbb1a 85%), radial-gradient(rgb(38 58 74), transparent 70%), linear-gradient(#0e081a00, #1b233130, #0f142066);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
}

.container_links {
  border-style: solid;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  border-width: 5px 5px 5px 0px;
  border-left: 0px;
  /* padding: 25px; */
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgb(129 132 135 / 0%), #0f5cbb1a 85%), radial-gradient(rgb(38 58 74), transparent 70%), linear-gradient(#0e081a00, #1b233130, #0f142066);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
}

.container4 {
  /* padding: 25px; */
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-color: #1a222e;
  box-shadow: rgb(50 50 93 / 25%) 0px 30px 60px -12px inset, rgb(0 0 0 / 30%) 0px 18px 36px -18px inset;
  border: 2px solid #1b222f;
}

@media screen and (min-width: 770px) {
  .createborder {
    border-right: 1px solid #7e4ede !important;
  }
}

/deep/ .progress.is-large, .is-large.progress-wrapper.is-not-native {
  height: 1.5rem;
  background-color: darkseagreen;
}

/deep/ .progress-wrapper.is-not-native .progress-bar .progress-value {
  color: #ae7cbf;
  background-color: transparent;
}

/deep/ .progress-wrapper.is-not-native .progress-bar {
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: 100%;
  background-color: #4c2d71;
  border-right: 2px solid blueviolet;
  border-left: 2px solid blueviolet;
}

/deep/ .b-slider .b-slider-tick {
  position: absolute;
  width: 3px;
  transform: translate(-50%, -50%);
  top: 50%;
  background: #3ffdf4 !important;
  border-radius: 4px;
}

/deep/.b-slider .b-slider-thumb-wrapper .b-slider-thumb {
  box-shadow: none;
  border: 1px solid #6affc8;
  border-radius: 4px;
  background: #233b58;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}

/deep/ .b-slider .b-slider-track {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  background: #3e8ed0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

/deep/ .b-slider.is-primary .b-slider-fill {
  background: #67efc3b0 !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

/deep/ .b-table .table {
  width: 100%;
  border-bottom: 1px solid transparent;
  border-radius: 4px;
  border-collapse: separate;
  background-color: #2d3d48;
  color: #2ac37e;
  font-size: 12px;
  justify-content: center;
}

/deep/ .b-table .table th {
  font-weight: 600;
  color: #6ea4db !important;
  border-bottom: 1px solid #6ea4db;
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  vertical-align: top;
}

/deep/ .table td, .table th {
  border-bottom: 1px solid #2da1bb !important;
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  vertical-align: top;
  padding-top: 10px;
}


.boxbutton {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
}

.account-bg1{
  background-color: rgba(39, 42, 64, 0.53);
  box-shadow: #0000006b 3px 3px 6px 0px inset, #2400268c -3px -3px 6px 1px inset;
  border-radius: 1rem;
}


.bginput{
  background-color: rgba(27, 29, 45, 0.6) !important;
  color: #dedede !important;
  border: 4px outset #29303805 !important;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.bginput:hover{
  color: #4ede77 !important;
  border: 4px inset #4edeb8 !important;
  cursor: pointer;
}

.cow2 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(44 44 44);
  background: -webkit-linear-gradient(#5aff67, #55ff6a) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}

.cow3 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(80 58 80);
  background: -webkit-linear-gradient(#d4acf3, #e9d1ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}

/deep/ .datepicker .datepicker-content {
  background-color: #2d3d48;
}

/deep/ .dropdown-content {
  background-color: #122532 !important;
  border-radius: 4px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.10), 0 0px 0 1px rgba(10, 10, 10, 0.2);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

/deep/ .dropdown-menu {
  display: none;
  left: 0;
  min-width: 12rem;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  z-index: 20;
  background-color: #3abb81;
}

/deep/ .datepicker .datepicker-table .datepicker-header .datepicker-cell {
  color: #6740aa;
  font-weight: 600;
}

/deep/ .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable:hover:not(.is-selected), .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable:focus:not(.is-selected) {
  background-color: #51eaa5;
  color: #0a0a0a;
  cursor: pointer;
}


/deep/ .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable {
  color: #36b9bd;
}

/deep/ .datepicker .datepicker-footer {
  margin-top: 0.875rem;
  padding-top: 0.875rem;
  border-top: 1px solid #2d3d48;
}

/deep/ .datepicker .dropdown .input[readonly]::placeholder {
  color: #3abb81 !important;
  outline: none;
}

textarea::-webkit-input-placeholder {
  color: #626f6a;
}

/deep/ .datepicker .dropdown .input[readonly], .datepicker .dropdown-trigger .input[readonly] {
  cursor: pointer;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.5);
  background-color: #3c5d6c;
  border: 1px solid #3abb81;
  color: #3abb81 !important;
  outline: none;
}

/deep/ .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected {
  background-color: #3abb81 !important;
  color: #fff;
}

/deep/ .datepicker .datepicker-header {
  padding-bottom: 0.875rem;
  margin-bottom: 0.875rem;
  border-bottom: 1px solid #2d3d48;
}

/deep/ .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected {
  background-color: #67b6ef;
  color: #fff;
}

.field:not(:last-child) {
  margin-bottom: 0 !important;
}

.noscroll::-webkit-scrollbar {
  display: none;
}

#style-10::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #200d31;
  border-radius: 10px;
}

#style-10::-webkit-scrollbar {
  width: 10px;
  background-color: #2c2432;
}

#style-10::-webkit-scrollbar-thumb {
  background-color: #2c2432;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
  rgba(0, 0, 0, .2) 25%,
  transparent 25%,
  transparent 50%,
  rgba(0, 0, 0, .2) 50%,
  rgba(0, 0, 0, .2) 75%,
  transparent 75%,
  transparent)
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}




@media screen and (min-width: 1024px) {
  .field-body {
    padding-right: 5rem;
  }

  .monitor-inner2 {
    width: 75% !important;
  }

  .screen2 {
    width: 85% !important;
  }
}


@media screen and (max-width: 769px), print {
  .screen-cutout {
    background-image: none !important;
    background-color: transparent !important;
  }

  /deep/ .super-select-dropdown {
    background-color: #333b4c !important;
    border: 1px solid !important;
    border-color: #2f597d !important;
    color: #67b6ef !important;
    width: 380px !important;
  }
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient(#3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5%);
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 40px;
}


.machineinterface {
  display: block;
  width: 100%;
  margin: -80px 0px;
  position: relative;
}

.monitor {
  min-height: 100vh;
  padding-top: 85px;
  z-index: 2;
  display: block;
  width: 100%;
  background-color: #47485a;
  position: relative;
  padding-bottom: 40px;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, #26202b08 35px, #26202b0d 70px);
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
}

.monitor-inner {
  padding-top: 15px;
  display: block;
  width: 95%;
  background-image: conic-gradient( #414254 0 10.5%, #414254 11% 11.5%, #414254 12% 38%, #414254 39% 61.5%, #414254 62% 88%, #414254 88.5% 89%, #414254 89.25% );
  margin: auto;
  position: relative;
  top: 20px;
  left: 0;
  right: 0;
  border-radius: 5px;
  margin-top: -51px;
  padding-bottom: 60px;
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient(#3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5%);
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 50px;
}

.screen {
  display: block;
  width: 95%;
  background-color: #4F5555;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  margin-top: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 30px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), transparent 85%), radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%), linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 7px -4px #4f545f inset, 0 -2px 1px #5c6574 inset;
}

.screen2 {
  display: block;
  width: 95%;
  background-color: #4f5555;
  background-image: radial-gradient(#525b5a, #50585a);
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  margin-top: 68px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 30px;
  box-shadow: 0 0 2px 0 #26272c, inset 0 0 1px 1px #131723, inset 0 0 3px #1b1e23, inset 0 0 3px 0.5px #2a2a3b, inset 0 0 5px #1b1d2d, inset 9px 0 7px -4px #4f545f, inset 0 -2px 1px #5c6574;
}

.header-bg3 {
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
  height: 1px;
  padding-top: 0px;
}

.info-icon:after {
  content: "\00a0\00a0 ℹ️\00a0\00a0";
  position: relative;
  top: 2px;
  left: 40px;
  background-color: rgba(13, 104, 206, 0.54);
  border-radius: 1rem;
}

.btn2 {
  color: #87b6e5;
  background-color: rgba(38, 89, 125, 0.6) !important;
  background-repeat: no-repeat;
  font-family: 'Noto', sans-serif;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}

.btn2:focus,
.btn2:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(38, 89, 125, 0.6);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn2 {
    margin-bottom: 30px;
  }
}


.form-container {
  background-color: #2e3945;
  border-radius: 1rem;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #6ea4db;
  border-width: 20px;
  padding: 40px;
}

.form-container .form-icon {
  font-size: 8px;
  text-align: center;
  line-height: 160px;
  width: 100px;
  height: 100px;
  margin: 0 auto 15px;
  border-radius: 50px;
  box-shadow: 7px 7px 10px #1f272f, -7px -7px 10px #1f272f;
}

.form-container .title {
  color: #ac40ab;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 20px;
}

.form-container .form-horizontal .form-group {
  margin: 0 0 25px 0;
}

.form-container .form-horizontal .form-group label {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.form-container .form-horizontal .form-control {
  color: #333;
  background: #ecf0f3;
  font-size: 15px;
  height: 50px;
  padding: 20px;
  letter-spacing: 1px;
  border: none;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.form-container .form-horizontal .form-control:focus {
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  outline: none;
}

.form-container .form-horizontal .form-control::placeholder {
  color: #808080;
  font-size: 14px;
}

.inset-bg-rounded {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding-bottom: 2rem;
  border-radius: 100px;
}

.inset-bg-rounded2 {
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}

.form-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}


.checkbox a {
  color: #3abb81;
}

.checkbox {
  color: #3abb81;
}

.checkbox:hover {
  color: #3abb81;
}

.font-green {
  color: #3abb81 !important;
}

.font-red {
  color: #ff1d1d !important;
}

.field-bg {
  background-color: #dbdbdb;
  padding: 1rem;
}

/deep/ .field {
  background-color: #2d3d48;
  /*padding: 0.2rem;*/
  border-radius: 0.5rem;
}

.select {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #3abb81;
  color: #3abb81;

}

/deep/ .textarea {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #3abb81;
  color: #3abb81;
}

/deep/ .select select[disabled], .taginput [disabled].taginput-container.is-focusable, [disabled].textarea, [disabled].input, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .taginput .taginput-container.is-focusable, .taginput fieldset[disabled] .taginput-container.is-focusable, fieldset[disabled] .textarea, fieldset[disabled] .input {
  background-color: #2a4855 !important;
  border-color: #24545f;
  box-shadow: none;
  color: #317884;
}

/deep/ .select select {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #3abb81;
  color: #3abb81;
}

/deep/ .select select option {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #3abb81;
  color: #3abb81;

}

/deep/ .b-steps .steps .step-items .step-item .step-details .step-title {
  color: #3b4f5c;
}

/deep/ .b-steps .steps .step-items .step-item.is-active .step-details .step-title {
  color: #3abb81;
}


/deep/ .b-steps .steps .step-items .step-item.is-active .step-marker {
  background-color: #2e484a;
  border-color: #3abb81;
  color: #3abb81;
}

/deep/ .b-steps .steps .step-items .step-item::before, .b-steps .steps .step-items .step-item::after {
  background: linear-gradient(to left, #67b6ef 50%, #67b6ef 50%);
  background-size: 200% 100%;
  background-position: right bottom;
}

/deep/ .b-steps .steps .step-items .step-item .step-marker {
  background-color: #526666;
  border-color: #3c6887;
  color: #67b6ef;
}


.pools-bg2 {
  background-color: #222b3a;
}


.b-tabs .tabs {
  overflow: hidden !important;
}

/deep/ .b-tabs .tabs {
  overflow: hidden !important;
}

::v-deep .b-tabs .tabs {
  overflow: hidden !important;
}

/deep/ .tabs.is-toggle li.is-active a {
  background-color: #252f3e;
  color: #3abb81;
  z-index: 1;
  font-family: Source Code Pro, monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}


/deep/ .tabs.is-toggle {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  background-color: #252f3e;
}

/deep/ .tabs.is-toggle a {
  margin-bottom: 0;
  position: relative;
  font-family: Source Code Pro, monospace !important;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  color: #476074;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: #222b3a;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: #222b3a;
  border: none;
  color: #00b89c;
}

.blue-bg {
  background-color: #314b6a;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  color: #6ea4db;
}

.blue-bg-2 {
  background-color: rgba(49, 75, 106, 0.7);
  padding: 1rem;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  color: #6ea4db;
  margin-top: -10px;
  padding-top: 2rem;
  font-size: 12px;
}

.divider {
  border: none;
  height: 20px;
  width: 90%;
  height: 50px;
  margin-top: 0;
  border-bottom: 1px solid #224a84;
  box-shadow: 0 20px 20px -20px rgba(88, 116, 238, 0.56);
  margin: -50px auto 10px;
}

.pool-card-margin {
  margin-top: 10px;
}

.create-buttons {
  border-radius: 1rem;
}

.message-card {
  padding: 2rem;
  background: #2d3d48;
  color: #0d64c6;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
}

/* card background */
.home-card-bg {
  background: #ebeae5;
}

.validation-msg {
  color: #c55252;
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
}

.validation-msg-green {
  color: #1891d7;
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
}

.label {
  flex-grow: 1.5 !important;
  width: 60px;
}

.testc {
  outline: none;
}

.inset-bg {
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}

.box {
  margin: 0 auto;
  background-color: #293038;
}

/*.label {*/
/*  color: #3abb81;*/
/*}*/

.label {
  color: #6985cb;
}


.pools-bg {
  background: #2d3d48;
}

.pool-main {
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
  background-color: #293038;
  background-image: radial-gradient(circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2%);
  background-blend-mode: hard-light;
}

.pool-item {
  /*background-color: #333b4c;*/
  /*border: 1px solid #0390ff;*/
  color: #3aacbb;
  font-family: 'Noto Sans', sans-serif;
}

.poolselect-bg {
  background-color: #222b3a !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.topbackgroundanimation {
  position: absolute;
  height: 200px;
  width: 100%;
  z-index: 1;
}

/* border for item cards */
.cardborder {
  border-radius: 1rem;
  color: #000000;
}

/* item card titles */
.title {
  font-family: 'Noto Sans', sans-serif;
  border-radius: 1rem;
  font-size: 18px;
  padding: 1rem;
  /*background: linear-gradient(#75a0d8, #75a0d8);*/
}

/* buy button */
.buybutton {
  font-family: 'Noto Sans', sans-serif;
  border: 1px solid #000000 !important;
}

/* item card footer */
.card-footer {
  border-top: 1px solid #87ba74 !important;
  font-family: 'Iceberg', sans-serif !important;
}

/* item card footer  */
.card-footer-item:not(:last-child) {
  border-right: 1px solid #87ba74 !important;
}

/* page loading style */
.loaderstyle {
  z-index: 1;
  position: absolute !important;
}

/* scroll div styles */
.overflow-x-hidden {
  overflow-x: hidden !important;
}

/* preloader styles */
@-webkit-keyframes goSmoke {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(2, 2) translateY(-20px) rotate(30deg);
  }
}

@-webkit-keyframes goWindow {
  0% {
    background-color: #F5F19A;
  }
  100% {
    background-color: #D3BD54;
  }
}

@-webkit-keyframes goWheels {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes goPackage {
  83% {
    -webkit-transform: translateX(120px);
  }
  95% {
    -webkit-transform: translateX(130px) translateY(25px) rotate(90deg);
  }
  100% {
    -webkit-transform: translateX(130px) translateY(25px) rotate(90deg);
  }
}

#wraper {
  width: 400px;
  position: relative;
  border-radius: 40px;
}

#wraper2 {
  width: 450px;
  margin: 0 auto;
  position: relative;
  border-radius: 40px;
  margin-left: -36px;
}


.stand {
  width: 10px;
  height: 13px;
  position: absolute;
  background-color: #27282a;
  top: 279px;
}

#stand1 {
  left: 251px;
}

#stand2 {
  left: 321px;
}

#band {
  width: 100px;
  height: 13px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  background-color: #58595B;
  top: 266px;
  left: 241px;
}

.wheel {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #414042;
  position: absolute;
  /*-webkit-animation: goWheels 0.5s linear infinite normal;*/
}

.wheel:after {
  content: '';
  display: block;
  width: 12px;
  height: 2px;
  background-color: #F5F19A;
  position: absolute;
  top: 5px;
}

#wheel1 {
  left: 5px;
  -webkit-animation: goWheels 0.5s 0.1s linear infinite normal;
}

#wheel2 {
  left: 31px;
  -webkit-animation: goWheels 0.5s 0.2s linear infinite normal;
}

#wheel3 {
  left: 56px;
  -webkit-animation: goWheels 0.5s 0.3s linear infinite normal;
}

#wheel4 {
  left: 79px;
  -webkit-animation: goWheels 0.5s 0.4s linear infinite normal;
}

.package {
  width: 20px;
  height: 20px;
  font-size: 24px;
  position: absolute;
  top: 246px;
  left: 230px;
  -webkit-animation: goPackage 2s 0.3s linear infinite normal;
}


.smoke {
  width: 19px;
  height: 18px;
  position: absolute;
  opacity: 0;
}

.sCloud {
  width: 60%;
  height: 0.7em;
  background-color: #E6E7E8;
  position: absolute;
  border-radius: 18px;
}

#smoke1 {
  top: 91px;
  left: 107px;
  -webkit-transition-property: opacity, webkit-transform;
  -webkit-transition-duration: 1s, 1s;
  -webkit-transition-timing-function: linear, linear;
  -webkit-animation: goSmoke 2s 0.3s infinite normal;
}

#smoke2 {
  top: 71px;
  left: 80px;
  -webkit-transition-property: opacity, webkit-transform;
  -webkit-transition-duration: 1s, 1s;
  -webkit-transition-timing-function: linear, linear;
  -webkit-animation: goSmoke 2s 1.3s infinite normal;
}

#sCloud1,
#sCloud4 {
  top: 2px;
}

#sCloud2,
#sCloud5 {
  left: 6px;
}

#sCloud3,
#sCloud6 {
  top: 6px;
  left: 7px;
}

/* background animation styles */
.area {
  background: -webkit-linear-gradient(90deg, #75a0d8, #75a0d8);
  background-color: #75a0d8;
  background: radial-gradient(1.5em 6.28571em at 1.95em, rgba(255, 255, 255, 0) 50%,
  rgba(255, 255, 255, 0.02) 50%, rgba(255, 255, 255, 0.02) 55%,
  rgba(255, 255, 255, 0) 55%) 0 0,
  radial-gradient(1.5em 6.28571em at -0.45em,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.04) 50%,
      rgba(255, 255, 255, 0.04) 55%,
      rgba(255, 255, 255, 0) 55%) 1.5em 5.5em,
  radial-gradient(2.3em 4.57143em at 2.99em,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.03) 50%,
      rgba(255, 255, 255, 0.02) 55%,
      rgba(255, 255, 255, 0) 55%) 0 0,
  radial-gradient(2.3em 4.57143em at -0.69em,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.04) 50%,
      rgba(255, 255, 255, 0.03) 55%,
      rgba(255, 255, 255, 0) 55%) 2.3em 4em,
  radial-gradient(3.5em 6.28571em at 4.55em, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.04) 50%, rgba(255, 255, 255, 0.06) 55%, rgba(255, 255, 255, 0) 55%) 0 0,
  radial-gradient(3.5em 6.28571em at -1.05em, rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.04) 50%,
      rgba(255, 255, 255, 0.03) 55%,
      rgba(255, 255, 255, 0) 55%) 3.5em 5.5em,
  -webkit-linear-gradient(90deg, rgba(57, 49, 64, 0.77), #322432);
  background-size: 1.5em 11em, 1.5em 11em, 2.3em 8em, 2.3em 8em, 3.5em 11em, 3.5em 11em, 100% 100%;
  background-repeat: repeat;
  width: 100%;
  height: 165vh;
  position: absolute;
}

/*-webkit-linear-gradient(90deg, #75a0d8, #75a0d8);*/
/*-webkit-linear-gradient(90deg, #444444, #555555);*/
.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.4);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-2000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.wave__content {
  text-align: center;
  height: 100%;
  overflow-x: hidden;
  position: relative;
}

.wave__header {
  position: relative;
  height: 100px;
  background: none;
  overflow: hidden !important;
}

.wave__menu {
  position: absolute;
  width: 103%;
  left: -5px;
  top: 73px;
  height: 30px;
  animation: wave-animation 16s infinite linear;
  background: url(../assets/wave1.png) 0 0 repeat-x;
  background-position-y: -10px;
  border-top-left-radius: 320px;
  border-top-right-radius: 320px;
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.wave__footer {
  height: 100px;
  position: relative;
  background: none;
}

.wave__ft {
  position: absolute;
  top: 0;
  width: 100%;
  height: 30px;
  animation: wave-animation 16s infinite linear;
  background: url('http://www.templates-preview.com/bootstrap-templates/300111854/images/wave1.png') 0 0 repeat-x;
  transform: rotate(-180deg);
  background-position-y: -10px;
  background-position-y: -10px;
  border-top-left-radius: 320px;
  border-top-right-radius: 320px;
  overflow-x: hidden;
  overflow-y: hidden !important;
}

/* ---------------------------------- */
@keyframes wave-animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 2000px 0;
  }
}
</style>
<style lang="scss" scoped>
//eccdff
.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: "Angkor", sans-serif;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

/* background animation styles */
#flow span {
  display: block;
  width: 200vw;
  height: 200vw;
  position: absolute;
  top: -160vw;
  left: -50vw;
  border-radius: 90vw;
  opacity: 0.6;
}

.flow-1 {
  background: -webkit-linear-gradient(-70deg, #75a0d8, #75a0d8);
  -webkit-animation: rotating 100s linear infinite;
  -moz-animation: rotating 100s linear infinite;
  -ms-animation: rotating 100s linear infinite;
  -o-animation: rotating 100s linear infinite;
  animation: rotating 100s linear infinite;
}

.flow-2 {
  background: -webkit-linear-gradient(-70deg, #75a0d8, rgba(117, 160, 216, 0.42));
  position: absolute;
  -webkit-animation: rotating 50s linear infinite;
  -moz-animation: rotating 50s linear infinite;
  -ms-animation: rotating 50s linear infinite;
  -o-animation: rotating 50s linear infinite;
  animation: rotating 50s linear infinite;
}

.flow-3 {
  background: -webkit-linear-gradient(-70deg, rgba(117, 208, 216, 0.15), rgba(33, 136, 255, 0.33));
  position: absolute;
  -webkit-animation: rotating 30s linear infinite;
  -moz-animation: rotating 30s linear infinite;
  -ms-animation: rotating 30s linear infinite;
  -o-animation: rotating 30s linear infinite;
  animation: rotating 30s linear infinite;
}

@-webkit-keyframes rotating /* Safari and Chrome */
{
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.cls-1 {
  fill: #5c5a5a;
}

.cls-1, .cls-10, .cls-11, .cls-12, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
  fill-rule: evenodd;
}

.cls-2 {
  fill: #d32073;
}

.cls-3 {
  fill: #858383;
}

.cls-4 {
  fill: #63a29c;
}

.cls-5 {
  fill: #b4b4b4;
}

.cls-6 {
  fill: #2e2e2e;
}

.cls-7 {
  fill: #d3cece;
}

.cls-8 {
  fill: #eaa64b;
}

.cls-9 {
  fill: #b9721f;
}

.cls-10 {
  fill: #acf5ef;
}

.cls-11 {
  fill: #fff;
}

.cls-12 {
  fill: #686666;
}

$message-header-background-color: #e3f;

.loading-page-container {
  opacity: .95;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  z-index: 1;

}

.loading-page-container .progress {
  position: relative;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 6px solid #20182d;
  background-color: #2c2938;
  background-image: radial-gradient(circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2%);
  background-blend-mode: hard-light;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;

  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 1;
}

.loading-page-container .progress .inner {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: 50%;
  margin-left: -4px;
  margin-top: -1px;
  width: 196.5px;
  height: 196.5px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.loading-page-container .progress .inner .water {
  opacity: .55;
  position: absolute;
  z-index: 1;
  width: 200%;
  height: 200%;
  left: -50%;
  border-radius: 40%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  animation-name: spin;
  top: 55%;
  background: #32BAFA;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  box-shadow: 0 0 20px #152A4A;

}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 0.5rem;
  color: #296992;
}

.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}


.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}


</style>
<style lang="scss" scoped>
$steps-maker-default-color: #67b6ef;
$steps-active-color: #67b6ef;

// for styles that do not have ascii characters setting the height
$height: 15px;

.outer {
  margin-bottom: $height;
  width: 100%;
  height: $height;
}

.plain {

  &.outer {
    border: 1px solid limegreen;
  }

  .inner {
    width: 90%;
    background-color: limegreen;
    height: $height;
  }
}

.borderless .inner {
  width: 90%;
  background-color: limegreen;
  height: $height;
}

.punctuation.outer {
  border: 1px solid limegreen;
  line-height: 1.35;
}

@-webkit-keyframes progress-bar-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes spinning {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes pulsing-opacity {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}


.plain .inner, .borderless .inner {
  animation: progress-bar-width steps(20) 5s infinite;
}

.pipes .inner, .blocks .inner, .blocks-gaps {
  animation: progress-bar-width 5s infinite;
  overflow: hidden;
  animation-timing-function: linear;
}

.blocks-gaps .inner {
  letter-spacing: 2px;
}

.activity {
  text-align: center;
  overflow: hidden !important;
}

.spinning {
  animation: spinning steps(8, end) 8s infinite;
}

.pulsing {
  animation: pulsing-opacity steps(3) 0.5s infinite alternate;
}

</style>
<style scoped>
.container3 {
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  /* padding: 25px; */
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), #0f5cbb26 85%), radial-gradient(rgba(38, 58, 74, 0.75), transparent 70%), linear-gradient(#0e081a00, #211922, #0f142000);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
}

.cow {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#14ff7d, #00f3ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}
</style>

<style lang="scss" scoped>
.container2 {
  background-color: #1D1F21;
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  padding: 25px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background: -webkit-linear-gradient(#3e10463d, #21333e70) !important;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 37px 59px #0e192257 inset, 0 0px 2px #ff0ef8 inset;
  -webkit-animation: scanline-data-v-0d33d4c2 8s linear infinite;
  animation: scanline-data-v-0d33d4c2 8s linear infinite;
}

article {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 900px) {
    padding: 5px;
  }

  @media (max-width: 899px) {
    padding-bottom: 56px;
  }
  background-color: rgba(192, 192, 192, 0);
  color: #323232;
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  position: relative;

  & > * + * {
    margin-top: 1.25em;
  }

  &:after {
    content: "🥩";
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding-bottom: 0.25em;
    position: absolute;
    top: 0.75em;
    right: 0.75em;
    width: 1.75em;
    height: 1.75em;
    filter: invert(100) opacity(40%);

    background-color: transparent;
  }
}

.headline {
  font-size: 2.5em;
  font-weight: 700;
}

.subhead {
  font-size: 1.25em;
  margin-top: 0.25em;
}

.article-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    width: 3em;
    height: 3em;
    flex-shrink: 0;
  }

  div:first-of-type {
    margin-left: 0.75em;
    font-size: 0.875em;
    margin-right: auto;
    padding-right: 0.75em;
  }

  div:last-of-type {
    font-size: 0.875em;
  }
}

aside {
  padding: 4em;
  border-right: 1px solid rgba(171, 15, 232, 0.1);
  border-bottom: 1px solid rgba(171, 15, 232, 0.1);
  border-left: 1px solid rgba(171, 15, 232, 0.1);
  border-top: 1px solid rgba(171, 15, 232, 0.1);
  clear: both;
  text-shadow: 0 0 10px, 0 0 40px;
  background-color: rgb(46 40 66 / 39%);
}

.tag {
  display: inline-block;
  margin-right: 0.25em;
  margin-top: 0.25em;
  border-right: 1px solid rgba(238, 238, 238, 0.19);
  border-bottom: 1px solid rgba(238, 238, 238, 0.13);
  border-left: 1px solid #444;
  border-top: 1px solid #444;
  padding: 0.125em 0.375em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}


figure {
  background-color: #c0c0c0;
  box-shadow: 0 0 0 1px #eee, 0 0 0 2px #c0c0c0, 0 0 0 3px #444;
  position: relative;
  margin-bottom: 1.25em;
  width: calc(100% + 1.5em + 2em);
  text-shadow: 0 0 10px, 0 0 40px;
  @media (min-width: 800px) {
    max-width: 50%;
  }

  &:nth-of-type(odd) {
    float: left;
    margin-right: 1.5em;
    margin-left: -3em;
  }

  &:nth-of-type(even) {
    float: right;
    margin-left: 1.5em;
    margin-right: -3em;
  }

  &:after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    background-image: linear-gradient(
            to top,
            rgba(#ff9100, 0.2) 0%,
            rgba(#ffe630, 0.2) 60%
    ),
    linear-gradient(20deg, rgba(#ff0, 0.5) 0%, rgba(#ff0, 0) 35%);
    box-shadow: inset 0px 0px 100px rgba(0, 0, 20, 1);
    z-index: 1;
  }

  img {
    // opacity: 0;
    // filter: hue-rotate(90deg);
    // filter: sepia(20%) brightness(10%) contrast(130%)
  }

  figcaption {
    font-family: "DotGothic16", monospace;
    background-color: #6f4b80;
    box-shadow: inset 0 -1px 0 0 #020202;
    color: #eee;
    text-shadow: 0 0 10px, 0 0 40px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.25em;
    font-size: 0.875em;
    z-index: 2;
  }
}

.blink {
  opacity: 1;
  animation: blink 1s linear infinite;
}

.scanline {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba(255, 255, 255, 0.02);
  animation: scanline 8s linear infinite;
  pointer-events: none;

  &:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: rgba(255, 255, 255, 0.02);
    animation: scanline 8s linear infinite;
    pointer-events: none;
    z-index: 10;
  }

}

// Scanline height 1005
@keyframes scanline {
  0.01% {
    height: 0;
  }
  99.99% {
    height: 100%
  }
  100% {
    height: 0;
  }
}
</style>
