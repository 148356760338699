<template>
  <div v-bind:class="[flipped ? 'flip-container flipped' : 'flip-container']"
       class="column pool-main is-centered mb-4 mt-5">
    <div class="columns is-centered pools-card" >
      <div class="column is-1"></div>
      <div
          class="column pool-item is-centered is-align-self-center" v-bind:class="[l_pledge_balance > 0 ? 'highlight-span' : '']"
      >
        <accordion>
          <accordion-item :class="$screen.width < 770 ? 'small-pool' : ''">
            <!-- This slot will handle the title/header of the accordion and is the part you click on -->
            <template slot="accordion-trigger">
              <div class="columns is-justify-content-space-evenly">
                <div class="column is-align-self-center ">
                  <b-tooltip type="is-dark" :label="'Crowdfunding pool: '+pool_name">
                  <div class="pool-name">{{ pool_name }}</div>
                  <div class="provider">Ends: <span style="color:#3abb81"> {{ l_pool_end_time }} </span></div>
                  <div><countdown :start-time="currentDate" :end-time="l_stamp_pool_end_time" ></countdown></div>
                  </b-tooltip>
                </div>
                <div v-if="l_pool_wl_active === true" class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Pledge '+pool_pledge_token+ ' tokens for '+pool_sell_token+' tokens'">
                    <div class="col-title">PRESALE:</div>
                    <div><countdown :start-time="currentDate" :end-time="l_pool_wl_end_time" ></countdown></div>
                  </b-tooltip>
                </div>
                <div class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Pledge '+pool_pledge_token+ ' tokens for '+pool_sell_token+' tokens'">
                  <div class="col-title">PLEDGE</div>
                  <div>{{ coinDisplayName(pool_pledge_token) }}</div>
                  </b-tooltip>
                </div>
                <div class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Pledgers receive '+pool_sell_token+ ' once this pool becomes funded'">
                  <div class="col-title">YIELD</div>
                  <div>{{ coinDisplayName(pool_sell_token) }}</div>
                  </b-tooltip>
                </div>
                <div class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Pledgers pay '+pool_price+' '+pool_pledge_token+ ' for each '+pool_sell_token">
                  <div class="col-title">PRICE</div>
                  <div>{{ pool_price }}</div>
                  </b-tooltip>
                </div>
                <div class="column is-align-self-center">
                  <b-tooltip type="is-dark" :label="'Crowdfunders have pledged to purchase '+l_pool_amount_sold+ ' out of '+pool_amount_to_sell+' '+pool_sell_token+' tokens from this campaign.'">
                  <div class="col-title">PROGRESS</div>
                  <div v-if="l_funding_failed === false">{{ Number((l_pool_amount_sold.decimal ? reduceBalance(Number(l_pool_amount_sold.decimal),0) : reduceBalance(Number(l_pool_amount_sold) ,0) / pool_amount_to_sell.decimal ? Number(pool_amount_to_sell.decimal) : Number(pool_amount_to_sell) ) * 100) > 100 ? 100 : Number((l_pool_amount_sold.decimal ? reduceBalance(Number(l_pool_amount_sold.decimal),0) : reduceBalance(Number(l_pool_amount_sold) ,0) / pool_amount_to_sell.decimal ? Number(pool_amount_to_sell.decimal) : Number(pool_amount_to_sell) ) * 100) }}%</div>
                  <div v-if="l_funding_failed === true">EXPIRED</div>
                  </b-tooltip>
                </div>
                <div v-if="l_pool_can_claim === true && l_pledge_rewards !== 0" class="column is-align-self-center pending-col">
                  <b-tooltip type="is-dark" :label="'You have '+l_pledge_rewards+ ' '+pool_sell_token+' tokens waiting to be claimed from this pool.'">
                  <div class="pending-title">PENDING</div>
                  <div class="pending-title">{{ l_pledge_rewards }}</div>
                  </b-tooltip>
                </div>
                <div v-if="l_pool_can_claim !== true" class="column is-align-self-center pending-col2 btm" :style="$screen.width > 770 ? 'max-width: 50px' : ''">
                  <b-tooltip type="is-dark" :label="'Pool info'">
                  <div  @click="clickPoolControls" class="pool-control-title"><div class="pool-control-title"><b-icon
                      pack="fas"
                      icon="info-circle"
                      size="is-small" style="position: relative; top:2px; ">
                  </b-icon>️</div></div>
                  </b-tooltip>
                </div>
                <div v-if="l_pool_can_claim !== true" @click="clickPoolWindow" class="column is-align-self-center pending-col2 btm" :style="$screen.width > 770 ? 'max-width: 50px' : ''">
                  <b-tooltip type="is-dark" :label="'Pool Page'">
                  <div class="pool-control-title"><div class="pool-control-title"><b-icon
                      pack="fas"
                      icon="window-maximize"
                      size="is-small" style="position: relative; top:2px; ">
                  </b-icon>️</div></div>
                  </b-tooltip>
                </div>
              </div>
            </template>
            <!-- pool accordion content -->
            <template slot="accordion-content">

              <div class="columns mt-1 mb-1 pending-col" style="position: relative; top: 10px; background-color: rgba(43, 35, 49, 0.38); border-radius: 1rem;">
                <div class="column is-full is-align-self-center">
                  <b-progress
                      :value="Number((l_pool_amount_sold.decimal ? reduceBalance(Number(l_pool_amount_sold.decimal),0) : reduceBalance(Number(l_pool_amount_sold) ,0) / pool_amount_to_sell.decimal ? Number(pool_amount_to_sell.decimal) : Number(pool_amount_to_sell) ) * 100)"
                      size="is-medium"
                      type="is-success"
                      show-value
                  >
                    STATUS : {{displayPoolStatus}} : {{l_pool_amount_sold.decimal ? reduceBalance(Number(l_pool_amount_sold.decimal),0) : l_pool_amount_sold}} / {{ pool_amount_to_sell }} {{ coinDisplayName(pool_sell_token) }}
                  </b-progress>
                </div>
              </div>

              <div v-if="l_funding_failed === false" class="columns mt-2 mb-0 descriptionSection">
                <!-- if connected pool buttons-->
                <div class="column is-align-self-center is-vcentered stake-btn-col" style="margin-left: 4%; margin-right: 1%;">
                  <div class="column is-12 is-align-self-center">
                    <div v-if="l_pool_wl_active"> * THIS POOL IS CURRENTLY PRE-SELLING TO WHITELISTED MEMBERS ONLY * </div>
                    <br v-if="l_pool_wl_active" />
                    <div v-if="isWhitelisted && l_pool_wl_active"> * YOU ARE WHITELISTED & CAN PURCHASE UP TO {{ ( Number(l_pool_wl_max_buy) -  (Number(l_pledge_balance) / Number(pool_price)) )}} {{coinDisplayName(pool_sell_token)}} FOR {{( ( Number(l_pool_wl_max_buy) * Number(pool_price) ) - Number(l_pledge_balance))}} {{coinDisplayName(pool_pledge_token)}} * </div>
                    <br v-if="isWhitelisted && l_pool_wl_active" />
                    <div>{{ pool_description }}</div>
                    <br v-if="l_pool_wl_active === false" />
                    <div v-if="l_pool_wl_active === false"> * YOU CAN PURCHASE UP TO {{ ( Number(this.l_pool_max_buy) - ( Number(this.l_pledge_balance) * Number(pool_price) ) )}} {{coinDisplayName(pool_sell_token)}} FOR {{(( Number(this.l_pool_max_buy) - ( Number(this.l_pledge_balance) * Number(pool_price) ) ) * Number(pool_price))}} {{coinDisplayName(pool_pledge_token)}} * </div>
                    <br/>
                    <div class="divider is-long"></div>
                    <div><span style="font-style: italic; font-size: 12px; color: #5998f7; margin: 0 !important;">* Once funded, crowdfunders can generate a liquidity pool out of raised funds on: <span style="font-weight: bold; color: #874bd5"> {{ l_pool_end_time }}.</span></span></div>
                    <div><span style="font-style: italic; font-size: 12px; color: #5998f7; margin: 0 !important;">* Crowdfunders can claim thier purchased tokens <span style="font-weight: bold; color: #874bd5"> anytime after the liquidity pool has been generated.</span></span></div>
                    <div><span style="font-style: italic; font-size: 12px; color: #5998f7; margin: 0 !important;">* Liquidity Pool funds have rug insurance enabled until: <span style="font-weight: bold; color: #874bd5"> {{l_pool_insurance_end_time}}.</span></span></div>
                  </div>
                </div>

              </div>

              <div v-if="l_funding_failed === true" class="columns mt-2 mb-0 descriptionSection">
                <!-- if connected pool buttons-->
                <div class="column is-align-self-center is-vcentered stake-btn-col" style="margin-left: 4%; margin-right: 1%;">
                  <div class="column is-12 is-align-self-center">
                    <div>This Crowdfunding Pool has expired due to insufficient funding and all liquidity and pledged funds should be withdrawn.</div>
                    <br/>
                    <div class="divider is-long"></div>
                    <div style="color: #2e2b2f">{{ pool_description }}</div>
                    <br/>
                    <div class="divider is-long"></div>
                    <div><span style="font-style: italic; font-size: 12px; color: #5998f7; margin: 0 !important;">* This crowdfunding campaign has expired due to not being funded by: <span style="font-weight: bold; color: #874bd5"> {{ l_pool_end_time_full }}.</span></span></div>
                   </div>
                </div>

              </div>



              <div class="pending-col" style="position: relative; top: 10px; background-color: rgba(45, 41, 56, 0.55); border-radius: 1rem;">
                <div class="columns" >
                  <div class="column is-align-self-center endcol">
                    <b-tooltip type="is-dark" :label="'This pool ends on: '+l_pool_end_time_full">
                      <div class="col-title titlefs">ENDS: <span style="color:#45bfba"> {{ l_pool_end_time_full }}</span></div>
                    </b-tooltip>

                  </div>
                </div>
                <div class="columns" >
                  <div class="column is-one-third is-align-self-center">
                    <b-tooltip type="is-dark" :label="'This campaign is selling '+coinDisplayName(pool_sell_token)+' tokens' ">
                      <div class="col-title p-3 titlefs">TOKEN SOLD: <span style="color:#3abb81">{{ coinDisplayName(pool_sell_token) }}</span> </div>
                    </b-tooltip>

                  </div>
                  <div class="column is-one-third is-align-self-center">
                    <b-tooltip type="is-dark" :label="'This campaign seeks to sell '+l_pool_amount_to_sell+' '+coinDisplayName(pool_sell_token)+' tokens' ">
                      <div class="col-title titlefs">TOTAL TO SELL: <span style="color:#3abb81">{{ l_pool_amount_to_sell.decimal ? l_pool_amount_to_sell.decimal : l_pool_amount_to_sell }}</span> </div>
                    </b-tooltip>

                  </div>
                  <div class="column is-one-third is-align-self-center">
                    <b-tooltip type="is-dark" :label="'This crowdfunding campaign has sold '+l_pool_amount_sold+' '+coinDisplayName(pool_sell_token)+' tokens'">
                      <div class="col-title titlefs">AMOUNT SOLD: <span style="color:#3abb81"> {{ l_pool_amount_sold.decimal ? l_pool_amount_sold.decimal : l_pool_amount_sold }}</span></div>
                    </b-tooltip>

                  </div>
                </div>
                <div class="divider is-long"></div>
                <div class="columns" >
                  <div class="column is-one-third is-align-self-center">
                    <b-tooltip type="is-dark" :label="'This campaign seeks to raise '+( l_pool_amount_to_sell * pool_price )+' '+coinDisplayName(pool_pledge_token)+' tokens'">
                      <div class="col-title titlefs2">MAX PER USER: <span style="color:#3abb81">{{ l_pool_max_buy }} {{ coinDisplayName(pool_sell_token) }}</span> </div>
                    </b-tooltip>

                  </div>
                  <div class="column is-one-third is-align-self-center">
                    <b-tooltip type="is-dark" :label="'This campaign can raise a maximum '+pool_hard_cap+' '+pool_pledge_token+' tokens' ">
                      <div class="col-title titlefs2">HARD CAP: <span style="color:#3abb81">{{ pool_hard_cap }} {{ coinDisplayName(pool_pledge_token) }}</span> </div>
                    </b-tooltip>

                  </div>
                  <div class="column is-one-third is-align-self-center">
                    <b-tooltip type="is-dark" :label="'This crowdfunding campaign has raised '+( l_pool_amount_sold * pool_price )+' '+coinDisplayName(pool_pledge_token)+' tokens'">
                      <div class="col-title titlefs2">TOTAL RAISED: <span style="color:#3abb81"> {{ l_pool_tokens_locked }} {{ coinDisplayName(pool_pledge_token) }}</span></div>
                    </b-tooltip>

                  </div>
                </div>

                <div class="divider is-long"></div>
                <div class="columns mt-0 mb-1">
                  <div class="column is-full is-align-self-center pb-5">
                    <b-tooltip type="is-dark" :label="'This pool will create a liquidity pool out of '+pool_amount_to_dex+' '+coinDisplayName(pool_sell_token)+' tokens and up to '+pool_hard_cap+' '+coinDisplayName(pool_pledge_token)+' tokens'">
                      <div class="col-title titlefs3">LIQUIDITY FORMED: <span style="color:#3abb81">  {{ l_pool_tokens_locked }} {{ coinDisplayName(pool_pledge_token) }} / {{ pool_amount_to_dex }} {{coinDisplayName(pool_sell_token)}}  </span></div>
                    </b-tooltip>

                  </div>
                </div>

              </div>







              <div class="columns mt-2 mb-3 pending-col leatherbg"
                   style="position: relative; top: 10px; background-color: rgba(38,43,56,0.29); border-radius: 1rem;">
                <div class="column is-2 is-align-self-center">
                  <b-tooltip type="is-dark" :label="'You have '+l_wallet_pledge_balance+' '+pool_pledge_token">
                  <div class="col-title2">WALLET:</div>
                  <div><span style="color:#3abb81">{{ l_wallet_pledge_balance }}</span></div>
                  </b-tooltip>
                </div>
                <div class="column is-2 is-align-self-center">
                  <b-tooltip type="is-dark" :label="'You have pledged '+l_pledge_balance+' '+pool_pledge_token+' tokens'">
                  <div class="col-title2">PLEDGED:</div>
                  <div><span style="color:#3abb81">{{ l_pledge_balance }}</span></div>
                  </b-tooltip>
                </div>
                <div class="column is-2 is-align-self-center">
                  <b-tooltip type="is-dark" :label="'You have '+l_pledge_rewards+' '+pool_pledge_token+' tokens to claim once this crowdfunding pool has successfully been completed.'">
                  <div class="col-title2">PENDING:</div>
                  <div><span style="color:#3abb81">{{ l_pledge_rewards }}</span></div>
                  </b-tooltip>
                </div>

                <div class="column" style="border-left: 1px solid rgba(24,4,22,0.28);">
                  <div class="columns is-flex-direction-column">

                      <!-- if connected pool buttons-->
                      <div v-if="accountApproved === true && l_can_generate === false && l_pool_maxxed === false && is_pool_owner === false && l_funding_failed === false && l_isWhitelisted === true || accountApproved === true && l_can_generate === false && is_pool_owner === false && l_pool_maxxed === false && pool_wl_active === false"
                           class="column is-full is-align-self-center stake-btn-col mt-2">
                        <div class="columns">
                          <div class="column"  v-bind:class="[$screen.width > 768 ? 'stake-col-lg' : 'stake-col-sm']">
                            <div class="field-body">
                              <div class="field has-addons">
                                <p class="control is-expanded">
                                  <b-numberinput expanded size="is-small" v-model="stakeAmount" :max="l_pool_wl_active === true ? ( ( Number(l_pool_wl_max_buy) * Number(pool_price) ) - Number(l_pledge_balance)) : (( Number(this.l_pool_max_buy) - ( Number(this.l_pledge_balance) * Number(pool_price) ) ) * Number(pool_price))" :step="Number(pool_price)" :exponential="Number(pool_price)" min="0" type="is-buttonblue" controls-alignment="left"
                                                 controls-position="compact"></b-numberinput>
                                </p>
                                <div @click="clickStake(pool_contract)" class="btn stake-btn-2"
                                     style="width: 35%; overflow: hidden;"
                                     :style="((Number(l_pool_amount_sold)  / Number(pool_amount_to_sell)) * 100) < 90 ? 'border-bottom-right-radius: 1rem; border-top-right-radius: 1rem;' : ''"
                                >
                                  <p class="font5">PLEDGE</p>
                                </div>
                                <div v-if="((Number(l_pool_amount_sold)  / Number(pool_amount_to_sell)) * 100) >= 90" @click="clickSetMax()" class="btn stake-btn-2"
                                     style="border-bottom-right-radius: 1rem; border-top-right-radius: 1rem; width: 15%; overflow: hidden;">
                                  <p class="font5">MAX</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="l_pledge_balance > 0.0 && l_pool_funded === false" class="columns">
                          <div class="column" v-bind:class="[$screen.width > 768 ? 'withdraw-col-lg' : 'withdraw-col-sm']">
                            <div @click="clickWithdraw(pool_contract)"  class="btn btn-lg stake-btn"
                                 v-bind:class="[$screen.width > 768 ? 'withdraw-btn-lg' : 'withdraw-btn-sm']">
                              <p>WITHDRAW</p>
                            </div>
                          </div>
                          <div class="column" style="padding-left: 0px;">
                            <div @click="clickClaimRewards(pool_contract)" class="btn btn-lg stake-btn"
                                 v-bind:class="[$screen.width > 768 ? 'claim-btn-lg' : 'claim-btn-sm']">
                              <p>CLAIM</p>
                            </div>
                          </div>
                        </div>
                      </div>




                    <div v-if="accountApproved === true && l_can_generate === false && l_pool_funded === false && is_pool_owner === true" class="column">
                        <div @click="clickPoolControls" class="btn btn-lg stake-btn mt-2" style="border-radius: 1rem !important;">
                          <p>POOL ADMIN</p>
                        </div>
                      </div>

                      <div  v-if="accountApproved === true && l_pool_funded === true && l_can_generate === true && l_pool_active === true || accountApproved === true && l_pool_funded === true && l_can_generate === false && l_pool_active === true || accountApproved === true && l_pledge_rewards > 0 && l_pool_funded === true && l_pool_can_claim === true && l_pool_active === false || accountApproved === true && l_pledge_rewards > 0 && l_pool_funded === true && l_pool_can_claim === false && l_pool_active === true && l_can_generate === false || accountApproved === false || accountApproved === true && l_pool_funded === false && l_funding_failed === true && l_pool_active === true || accountApproved === true && l_pool_funded === false && l_funding_failed === true && is_pool_owner === false" class="column">
                        <div class="columns is-flex-direction-column">

                          <div v-if="accountApproved === true && l_pool_funded === true && l_can_generate === true && l_pool_active === true" class="column">
                            <div @click="clickGeneratePair" class="btn btn-lg stake-btn mt-2" style="border-radius: 1rem !important;">
                              <p>GENERATE LIQUIDITY PAIR</p>
                            </div>
                          </div>

                          <div v-if="accountApproved === true && l_pool_funded === true && l_can_generate === false && l_pool_active === true" class="column">
                            <div class="btn btn-lg stake-btn" style="border-radius: 1rem !important;">
                              <div>GENERATE LIQUIDITY PAIR IN:</div>
                              <div><countdown :start-time="currentDate" :end-time="l_stamp_pool_end_time" ></countdown></div>
                            </div>
                          </div>


                          <div v-if="accountApproved === true && l_pledge_rewards > 0 && l_pool_funded === true && l_pool_can_claim === true && l_pool_active === false" class="column">
                            <div @click="clickClaimRewards(pool_contract)" class="btn btn-lg stake-btn mt-2" style="border-radius: 1rem !important;">
                              <p>CLAIM</p>
                            </div>
                          </div>

                          <div v-if="accountApproved === true && l_pledge_rewards > 0 && l_pool_funded === true && l_pool_can_claim === false && l_pool_active === true && l_can_generate === false" class="column">
                            <div @click="clickClaimRewards(pool_contract)" class="btn btn-lg stake-btn" style="border-radius: 1rem !important;">
                              <div>CLAIM IN :</div>
                              <div><countdown :start-time="currentDate" :end-time="l_stamp_pool_end_time" ></countdown></div>
                            </div>
                          </div>

                          <div v-if="accountApproved === true && l_pool_funded === false && l_funding_failed === true && l_pool_active === true && is_pool_owner === true" class="column">
                            <div @click="clickClaimExpired(pool_contract)" class="btn btn-lg stake-btn mt-2" style="border-radius: 1rem !important;">
                              <p>WITHDRAW LIQUIDITY</p>
                            </div>
                          </div>

                          <div v-if="accountApproved === true && l_pool_funded === false && l_funding_failed === true && is_pool_owner === false" class="column">
                            <div @click="clickWithdraw(pool_contract)" class="btn btn-lg stake-btn mt-2" style="border-radius: 1rem !important;">
                              <p>WITHDRAW PLEDGE</p>
                            </div>
                          </div>

                          <div v-if="accountApproved === false" class="column is-offset-1 is-align-self-center stake-btn-col">
                            <div class="btn btn-lg stake-btn" style="border-radius: 1rem !important;">
                              <p>CONNECT WALLET</p>
                            </div>
                          </div>

                        </div>
                      </div>



                  </div>

                </div>


              </div>

            </template>
          </accordion-item>
        </accordion>


      </div>
      <div class="column is-1"></div>
    </div>

  </div>
</template>

<script>
//import vue store variables to this page
import {mapGetters, mapState} from "vuex";
import Accordion from "./accordion.vue";
import AccordionItem from "./accordion-item";
import countdown from "@/components/countdown";

export default {
  name: "PoolCard",
  components: {
    Accordion,
    AccordionItem,
    countdown
  },
  props: {
    accountApproved: {
      type: Boolean,
      default: false,
    },
    pool_name: {
      type: String,
      default: "0",
    },
    pool_description: {
      type: String,
      default: "0",
    },
    pool_id: {
      type: [String, Number],
      default: "0"
    },
    pool_amount_to_sell: {
      type: [String, Number],
      default: "0"
    },
    pool_amount_sold: {
      type: [String, Number],
      default: "0"
    },
    pool_amount_to_dex: {
      type: [String, Number],
      default: "0"
    },
    pool_user_balance: {
      type: [String, Number],
      default: "0"
    },
    pool_price: {
      type: [String, Number],
      default: "0"
    },
    user_account: {
      type: String,
      default: "0",
    },
    pool_creator: {
      type: String,
      default: "0",
    },
    pool_balance: {
      type: [String, Number],
      default: "0",
    },
    pool_end_time: {
      type: [String, Object],
      default: "0",
    },
    pool_run_time: {
      type: [String, Number],
      default: "0",
    },
    pool_start_time: {
      type: [String, Object],
      default: "0",
    },
    pool_insurance_end_time: {
      type: [String, Object],
      default: "0",
    },
    pool_insurance_time: {
      type: [String, Number],
      default: "0",
    },
    pool_initialized: {
      type: Boolean,
      default: false,
    },
    pool_funded: {
      type: Boolean,
      default: false,
    },
    pool_maxxed: {
      type: Boolean,
      default: false,
    },
    pool_can_claim: {
      type: Boolean,
      default: false,
    },
    pool_lp_claimed: {
      type: Boolean,
      default: false,
    },
    pool_paid: {
      type: [String, Number],
      default: "0",
    },
    pool_contract: {
      type: String,
      default: "0",
    },
    pool_sell_token: {
      type: [String, Object],
      default: "0",
    },
    pool_pledge_token: {
      type: [String, Object],
      default: "0",
    },
    pool_pledgers: {
      type: [String, Number],
      default: "0",
    },
    pool_tokens_locked: {
      type: [String, Number],
      default: "0",
    },
    is_pool_owner: {
      type: Boolean,
      default: false,
    },
    wallet_pledge_balance: {
      type: [String, Number],
      default: "0",
    },
    pledge_balance: {
      type: [String, Number],
      default: "0",
    },
    pledge_rewards: {
      type: [String, Number],
      default: "0",
    },
    stamp_pool_start_time: {
      type: [String, Number],
      default: "0",
    },
    stamp_pool_end_time: {
      type: [String, Number],
      default: "0",
    },
    can_generate: {
      type: Boolean,
      default: false,
    },
    pool_active: {
      type: Boolean,
      default: false,
    },
    funding_failed: {
      type: Boolean,
      default: false,
    },
    pool_wl_active: {
      type: Boolean,
      default: false,
    },
    isWhitelisted: {
      type: Boolean,
      default: false,
    },
    pool_wl_end_time: {
      type: [String, Number],
      default: "0",
    },
    pool_wl_end_time_full: {
      type: [String, Number, Date],
      default: "0",
    },
    pool_end_time_full: {
      type: [String, Number, Date],
      default: "0",
    },
    pool_wl_max_buy: {
      type: [String, Number],
      default: "0",
    },
    pool_hard_cap: {
      type: [String, Number],
      default: "0",
    },
    pool_max_buy: {
      type: [String, Number],
      default: "0",
    },
  },
  data: function () {
    return {
      debug: false,
      flipped: false,
      showStakeControls: false,
      stakeAmount: 0.0,
      currentDate: null,
      isMakingCrowdFundTransaction: false,
      isMakingTransaction: false,
      isPledging: false,
      isPledging_poolid: null,
      isPledging_amount: null,
      isWithdrawing: false,
      isWithdrawing_poolid: null,
      isWithdrawing_amount: null,
      isClaiming: false,
      isClaiming_poolid: null,
      isClaiming_amount: null,
      isWithdrawingExpired: false,
      isWithdrawingExpired_poolid: null,
      isWithdrawingExpired_amount: null,
      isGenerating: false,
      isGenerating_poolid: null,
      isGenerating_amount: null,
      //Crowdfunding Pools
      allCrowdFundingPools: [],
      allCrowdFundingPoolsToShowNow: [],
      allActiveCrowdFundingPools:[],
      allActiveCrowdFundingPoolsIds: [],
      allUserCrowdFundingPools: [],
      allUserCrowdFundingPoolsIds:[],
      allCrowdFundingPoolsToShow: [],
      allCrowdFundingPoolIdsUserIsNotStakedIn:[],
      l_pool_funded: this.pool_funded,
      l_pool_maxxed: this.pool_maxxed,
      l_pledge_rewards: this.pledge_rewards,
      l_pledge_balance: this.pledge_balance,
      l_wallet_pledge_balance: this.wallet_pledge_balance,
      l_pool_amount_sold: this.pool_amount_sold,
      l_pool_end_time: this.pool_end_time,
      l_pool_insurance_end_time: this.pool_insurance_end_time,
      l_pool_can_claim: this.pool_can_claim,
      l_stamp_pool_end_time: this.stamp_pool_end_time,
      l_pool_initialized: this.pool_initialized,
      l_pool_paid: this.pool_paid,
      l_pool_lp_claimed: this.pool_lp_claimed,
      l_pool_tokens_locked: this.pool_tokens_locked,
      l_pool_active: this.pool_active,
      l_pool_user_balance: this.pool_user_balance,
      l_funding_failed: this.funding_failed,
      l_pool_amount_to_sell: this.pool_amount_to_sell,
      l_can_generate: this.can_generate,
      l_pool_wl_active: this.pool_wl_active,
      l_pool_wl_end_time: this.pool_wl_end_time,
      l_pool_wl_end_time_full: this.pool_wl_end_time_full,
      l_pool_end_time_full: this.pool_end_time_full,
      l_pool_wl_max_buy: this.pool_wl_max_buy,
      l_isWhitelisted: this.isWhitelisted,
      l_pool_max_buy: this.pool_max_buy

    };
  },
  watch: {
    //lets watch our transactionPolling variable
    transactionPolling: async function (newValue, oldValue) {
      if (this.debug) {
        console.log("crowdfund poolcard getTransactionPolling newValue: " + newValue);
        console.log("crowdfund poolcard getTransactionPolling oldValue: " + oldValue);
      }

      if (newValue === true && this.isMakingCrowdFundTransaction === true) {

        this.isMakingTransaction = true;

        let message1 = "Confirming new transaction with the blockchain";
        if (this.isPledging === true) {
          message1 = "Pledging " + this.isPledging_amount + " in pool "+this.isPledging_poolid;
        } else if (this.isWithdrawing === true) {
          message1 = "Withdrawing pledged amount: " + this.isWithdrawing_amount + " from pool "+this.isWithdrawing_poolid;
        } else if (this.isClaiming === true) {
          message1 = "Claiming purchased tokens: " + this.isClaiming_amount + " from pool "+this.isClaiming_poolid;
        }  else if (this.isWithdrawingExpired === true) {
          message1 = "Withdrawing expired liquidity: " + this.isWithdrawingExpired_amount + " from pool "+this.isWithdrawingExpired_poolid;
        }  else if (this.isGenerating === true) {
          message1 = "Generating Liquidity Pool out of crowdfunding pool "+this.isGenerating_poolid;
        }

        this.$buefy.toast.open({
          duration: 10000,
          message: message1,
          type: "is-info",
          pauseOnHover: true
        });

      } else if (newValue === false && this.isMakingCrowdFundTransaction === true) {

        this.isMakingTransaction = false;
        this.isMakingCrowdFundTransaction = false;

        let message = "Transaction Success";
        if (this.isPledging === true) {
          message = "Pledged " + this.isPledging_amount + " in pool "+this.isPledging_poolid;
        } else if (this.isWithdrawing === true) {
          message = "Withdrew: " + this.isWithdrawing_amount + " from pool "+this.isWithdrawing_poolid;
        } else if (this.isClaiming === true) {
          message = "Claimed: " + this.isClaiming_amount + " from pool "+this.isClaiming_poolid;
        }  else if (this.isWithdrawingExpired === true) {
          message = "Expired liquidity withdrawn: " + this.isWithdrawingExpired_amount + " from pool "+this.isWithdrawingExpired_poolid;
        }  else if (this.isGenerating === true) {
          message = "Generated Liquidity Pool out of crowdfunding pool "+this.isGenerating_poolid;
        }


        //Transaction success toast
        if (this.getTransactionFailed() === false) {

          this.$buefy.toast.open({
            duration: 10000,
            message: message,
            position: "is-top",
            type: "is-success"
          });

          this.isPledging = false;
          this.isClaiming = false;
          this.isWithdrawing = false;
          this.isWithdrawingExpired = false;
          this.isGenerating = false;

          await this.rescanPool();


        } else if (this.getTransactionFailed() === true) {

          let message2 = "Transaction Failed";
          if (this.isPledging === true) {
            message2 = "Failed to pledge in pool "+this.isPledging_poolid;
          } else if (this.isWithdrawing === true) {
            message2 = "Failed to withdraw from pool "+this.isWithdrawing_poolid;
          } else if (this.isClaiming === true) {
            message2 = "Failed to claim from pool "+this.isClaiming_poolid;
          }  else if (this.isWithdrawingExpired === true) {
            message2 = "Failed to withdraw expired liquidity from pool "+this.isWithdrawingExpired_poolid;
          }  else if (this.isGenerating === true) {
            message2 = "Failed to generate liqudity pool from pool "+this.isGenerating_poolid;
          }

          this.$buefy.toast.open({
            duration: 10000,
            message: message2,
            position: "is-top",
            type: "is-danger"
          });

          this.isPledging = false;
          this.isClaiming = false;
          this.isWithdrawing = false;
          this.isWithdrawingExpired = false;
          this.isGenerating = false;

          await this.rescanPool();

        }

      }


    },

    transactionRejected: function (newValue, oldValue) {
      if (this.debug) {
        console.log("transactionRejected newValue: " + newValue);
        console.log("transactionRejected oldValue: " + oldValue);
      }


      let message3 = "Transaction Rejected.";

      this.$buefy.toast.open({
        duration: 10000,
        message: message3,
        position: "is-top",
        type: "is-danger"
      });

      this.isPledging = false;
      this.isClaiming = false;
      this.isWithdrawing = false;
      this.isWithdrawingExpired = false;
      this.isGenerating = false;

    },

    activeTab: async function (newValue, oldValue) {
      if (this.debug) {
        console.log("activeTab newValue: " + newValue);
        console.log("activeTab oldValue: " + oldValue);
      }
      if (newValue === '0' || newValue === '1') {
        await this.swapAmountTopChange();
      }

    }


  },
  computed: {
    ...mapState("accounts", ["transactionPolling", "transactionRejected"]),

    displayPoolStatus() {

      let status = "CROWDFUNDING";
      if(this.pool_lp_claimed === false && this.pool_funded === false && this.funding_failed === false && this.pool_wl_active === false){
        status = "CROWDFUNDING";
      }else if(this.pool_lp_claimed === false && this.pool_funded === false && this.funding_failed === false && this.pool_wl_active === true){
        status = "WHITELIST PRESALE";
      }else if(this.pool_lp_claimed === false && this.pool_funded === true && this.funding_failed === false){
        status = "FUNDED";
      }else if(this.pool_lp_claimed === true && this.pool_funded === true ){
        status = "COMPLETE";
      }else if(this.funding_failed === true && this.pool_funded === false){
        status = "EXPIRED";
      }

      return status;
    },
  },
  methods: {
    ...mapGetters("accounts", ["getAccountExists", "getAccountConfirmed", "getTransactionFailed", "getTransactionPolling", "getAllCrowdFundingPools", "getUserCrowdFundingPools"]),

    number_test(n) {
      const result = (n - Math.floor(n)) !== 0;

      if (result) {
        if (this.debug) {
          console.log("Contains Decimal Place");
        }
        return true;
      } else {
        if (this.debug) {
          console.log("No decimal place");
        }
        return false;
      }

    },

    coinName(namespace, name){
      let newnamespace = namespace+'.';
      let newname = name;
      if(namespace === null || namespace === 'null'){
        newnamespace = '';
      }
      if(name === null || name === 'null'){
        newname = '';
      }
      return newnamespace+newname;
    },

    coinDisplayName(coin){
      let name = coin;
      if(coin === 'coin'){
        name = 'KDA';
      }else if(coin === 'free.stake-token'){
        name = 'STEAK';
      }
      return name;
    },

    reduceBalance(balance, prec = 6){
      if (balance) {
        if (balance.int) balance = balance.int;
        if (balance.decimal) balance = balance.decimal;

        if (parseFloat(balance) % 1 === 0) {
          return parseInt(balance);
        }

        return Math.trunc(parseFloat(balance) * Math.pow(10, prec)) / Math.pow(10, prec);
      }

      if (balance === 0) return 0;
    },

    async clickGeneratePair(pool_contract){

      const txpolling = this.getTransactionPolling();

      if(this.debug){
        console.log("TXPOLLING");
        console.log(txpolling);
      }

      if(txpolling === false){

        if(this.debug){
          console.log("GENERATING LP PAIR")
          console.log(pool_contract);
        }

        const payload = {
          poolID: this.pool_id,
          accountName: this.user_account
        }

        if(this.debug){
          console.log(payload);
        }
        this.$emit('showTxModal', true);
        this.isGenerating = true;
        this.isGenerating_poolid = this.pool_id;
        this.isMakingCrowdFundTransaction = true;
        await this.$store.dispatch("accounts/crowdFundGenerateLP", payload);

      }else {
        alert("Please wait for Stake Factory to confirm your current transaction.")
      }



    },

    async clickSetMax(){
      this.stakeAmount = ( ( Number(this.pool_hard_cap) - Number(this.pool_amount_sold) ) - Number(this.l_pledge_balance))
    },

    async clickWithdraw(pool_contract){

      const txpolling = this.getTransactionPolling();

      if(this.debug){
        console.log("TXPOLLING");
        console.log(txpolling);
      }

      if(txpolling === false){

        if(this.debug){
          console.log("CLAIMING POOL REWARDS")
          console.log(pool_contract);
        }

        const payload = {
          poolID: this.pool_id,
          accountName: this.user_account,
          moduleName: this.pool_pledge_token
        }

        if(this.debug){
          console.log(payload);
        }
        this.$emit('showTxModal', true);
        this.isWithdrawing = true;
        this.isWithdrawing_poolid = this.pool_name;
        this.isWithdrawing_amount = this.l_pledge_balance.toString()+" "+this.coinDisplayName(this.pool_pledge_token);
        this.isMakingCrowdFundTransaction = true;
        this.isPledging = false;
        this.isClaiming = false;
        this.isWithdrawingExpired = false;
        this.isGenerating = false;
        if(this.funding_failed === true){
          this.$emit('reloadPage', true);
        }
        await this.$store.dispatch("accounts/crowdFundWithdraw", payload);

      }else {
        alert("Please wait while you confirm your current transaction.")
      }



    },

    async clickClaimRewards(pool_contract){

      const txpolling = this.getTransactionPolling();

      if(this.debug){
        console.log("TXPOLLING");
        console.log(txpolling);
      }

      if(txpolling === false){


        const currentTime = new Date().getTime();

        if(this.stamp_pool_end_time < currentTime) {

          if (this.debug) {
            console.log("CLAIMING POOL REWARDS")
            console.log(pool_contract);
          }

          const payload = {
            poolID: this.pool_id,
            accountName: this.user_account,
            moduleName: this.pool_pledge_token
          }

          if (this.debug) {
            console.log(payload);
          }
          this.$emit('showTxModal', true);
          this.$emit('reloadPage', true);
          this.isClaiming = true;
          this.isClaiming_poolid = this.pool_name;
          this.isClaiming_amount = this.l_pledge_rewards.toString()+" "+this.coinDisplayName(this.pool_sell_token);
          this.isMakingCrowdFundTransaction = true;
          this.isPledging = false;
          this.isWithdrawing = false;
          this.isWithdrawingExpired = false;
          this.isGenerating = false;
          await this.$store.dispatch("accounts/crowdFundClaimRewards", payload);
        }else {
          alert("Pending tokens can be claimed after the Crowdfunding Pool has ended.")
        }

      }else {
        alert("Please wait while you confirm your current transaction.")
      }


    },

    async clickClaimExpired(pool_contract){

      const txpolling = this.getTransactionPolling();

      if(this.debug){
        console.log("TXPOLLING");
        console.log(txpolling);
      }

      if(txpolling === false){

        if (this.debug) {
          console.log("CLAIMING EXPIRED POOL LP")
          console.log(pool_contract);
        }

        const payload = {
          poolID: this.pool_id,
          accountName: this.user_account,
          moduleName: this.pool_pledge_token
        }

        if (this.debug) {
          console.log(payload);
        }

        this.$emit('showTxModal', true);
        this.$emit('reloadPage', true);
        this.isWithdrawingExpired = true;
        this.isWithdrawingExpired_poolid = this.pool_id;
        this.isWithdrawingExpired_amount = this.pool_balance.toString()+" "+this.coinDisplayName(this.pool_sell_token);
        this.isMakingCrowdFundTransaction = true;
        this.isPledging = false;
        this.isClaiming = false;
        this.isWithdrawing = false;
        this.isGenerating = false;
        await this.$store.dispatch("accounts/crowdFundClaimExpired", payload);

      }else {
        alert("Please wait while you confirm your current transaction");
      }



    },

    async clickStake(pool_contract) {

      const txpolling = this.getTransactionPolling();

      if(this.debug){
        console.log("TXPOLLING");
        console.log(txpolling);
      }

      if(txpolling === false){

        if(this.stakeAmount > 0){

          if(this.l_wallet_pledge_balance > 0){

            if(this.l_wallet_pledge_balance >= this.stakeAmount){
              const currentTime = new Date().getTime();

              let canstake = true;
              if(this.l_pool_wl_active === true && ( ( (Number(this.l_pool_wl_max_buy) * Number(this.pool_price) ) - Number(this.l_pledge_balance)).toFixed(1) < Number(this.stakeAmount))){
                alert("You are only whitelisted to purchase "+(Number(this.l_pool_wl_max_buy) - Number(this.l_pledge_balance))+" tokens" );
                canstake = false;
              }

              if(this.l_pool_wl_active === false && ((Number(this.pool_hard_cap) - Number(this.l_pledge_balance)).toFixed(1) < Number(this.stakeAmount))){
                alert("This pool has a hard cap that will only allow you to purchase "+(Number(this.pool_hard_cap) - Number(this.l_pledge_balance)).toFixed(1)+" tokens" );
                canstake = false;
              }



              if(this.stamp_pool_end_time > currentTime){

                if(canstake){
                  if(this.debug){
                    console.log("CREATING NEW PLEDGE")
                    console.log(pool_contract);
                  }

                  const payload = {
                    poolID: this.pool_id,
                    amount: this.stakeAmount,
                    accountName: this.user_account,
                    moduleName: this.pool_pledge_token,
                    stakeToken: this.pool_pledge_token,
                  }

                  if(this.debug){
                    console.log(payload);
                  }
                  this.$emit('showTxModal', true);
                  this.isPledging = true;
                  this.isPledging_poolid = this.pool_name;
                  this.isPledging_amount = this.stakeAmount.toString()+" "+this.coinDisplayName(this.pool_pledge_token);
                  this.isMakingCrowdFundTransaction = true;
                  this.isClaiming = false;
                  this.isWithdrawing = false;
                  this.isWithdrawingExpired = false;
                  this.isGenerating = false;
                  await this.$store.dispatch("accounts/crowdFundPledge", payload);
                }



              }else {
                alert("You can only pledge in pools that haven't ended.")
              }


            }else {
              alert("Insufficient funds");
            }
          }else {
            alert("Insufficient funds");
          }
        }else {
          alert("You must Pledge > 0")
        }

      }else{
        alert("Please wait while you confirm your current transaction on this page.")
      }








    },

    async clickPoolControls(){
      let routeData = this.$router.resolve({path: "/crowdfund-nfo/" + this.pool_contract +"/"+this.pool_id});
      window.open(routeData.href, "_blank");
    },

    async clickPoolWindow(){
      let routeData = this.$router.resolve({path: "/crowdfund/" + this.pool_id});
      window.open(routeData.href, "_blank");
    },

    async rescanPool(){

      //Crowdfunding Pools
      this.allCrowdFundingPools = [];
      this.allCrowdFundingPoolsToShowNow= [];

      this.allActiveCrowdFundingPools=[];
      this.allActiveCrowdFundingPoolsIds=[];
      this.allUserCrowdFundingPools=[];
      this.allUserCrowdFundingPoolsIds=[];
      this.allCrowdFundingPoolsToShow=[];
      this.allCrowdFundingPoolIdsUserIsNotStakedIn=[];

      //Crowdfunding pools bind
      this.allCrowdFundingPools = this.getAllCrowdFundingPools();
      //Bind local state for user crowdfunding pools
      this.allUserCrowdFundingPools = this.getUserCrowdFundingPools();


      //////////////////////////////
      //Crowdfunding pools
      ///////////////////////////////

      //Lets make a list of active crowdfunding pools
      //First we got through the list of all crowdfunding pools if its not empty
      if (this.allCrowdFundingPools.length > 0) {

        //We go through pools in reverse because its better
        for (let i = this.allCrowdFundingPools.length - 1; i >= 0; i--) {

          if (this.debug === true) {
            console.log("STEP 1) POOL ID:");
            console.log(this.allCrowdFundingPools[i].id)
          }

          //Now lets check and see if our pool is 'active' or not, if so, lets add it to our list of active pools
          if (this.allCrowdFundingPools[i].id === this.pool_id) {

            const allPoolsDetailsObject = {
              ...this.allCrowdFundingPools[i],
            }

            //Lets double check and make sure our active pool list doesnt already contain this new pool we are adding
            if (this.allActiveCrowdFundingPools.includes(allPoolsDetailsObject) === false) {
              this.allActiveCrowdFundingPools.push(allPoolsDetailsObject);
            }

            //Lets also add this pools id to a list of ids to show later
            if (this.allActiveCrowdFundingPoolsIds.includes(this.allCrowdFundingPools[i].id) === false) {
              this.allActiveCrowdFundingPoolsIds.push(this.allCrowdFundingPools[i].id);
            }

          }

        }

      }

      //Now we need to quickly get a list of all crowdfunding pool ids the user has pledges in
      if (this.allUserCrowdFundingPools.length > 0) {
        for (let j = this.allUserCrowdFundingPools.length - 1; j >= 0; j--) {
          if (this.allUserCrowdFundingPoolsIds.includes(this.allUserCrowdFundingPools[j].id) === false) {
            this.allUserCrowdFundingPoolsIds.push(this.allUserCrowdFundingPools[j].id);
          }
        }
      }

      //Now lets filter out the crowdfunding pools the user has pledged in from the list of all crowdfunding pools
      //This is our list of pools we must fill in with blank user data
      if (this.allActiveCrowdFundingPoolsIds.length > 0) {
        for (let m = this.allActiveCrowdFundingPoolsIds.length - 1; m >= 0; m--) {
          if (this.allUserCrowdFundingPoolsIds.includes(this.allActiveCrowdFundingPoolsIds[m]) === false) {
            if (this.allCrowdFundingPoolIdsUserIsNotStakedIn.includes(this.allActiveCrowdFundingPoolsIds[m]) === false) {
              this.allCrowdFundingPoolIdsUserIsNotStakedIn.push(this.allActiveCrowdFundingPoolsIds[m]);
            }
          }
        }
      }

      //Now that we have a complete list of ACTIVE crowdfunding pools, we should go through them!
      for (let l = this.allActiveCrowdFundingPools.length - 1; l >= 0; l--) {

        if (this.debug === true) {
          console.log("STEP 2) POOL ID:");
          console.log(this.allActiveCrowdFundingPools[l].id)
        }

        //Now lets go through the list of ids the user hasnt staked in
        if (this.allCrowdFundingPoolIdsUserIsNotStakedIn.length > 0) {

          for (let k = this.allCrowdFundingPoolIdsUserIsNotStakedIn.length - 1; k >= 0; k--) {

            //Do any of the active pools match ids from pools the user is not staked in?
            //If so lets dress them with blank stake information to display


            if (this.allActiveCrowdFundingPools[l].id === this.allCrowdFundingPoolIdsUserIsNotStakedIn[k]) {

              let isPoolOwner = false;

              if (this.getAccountConfirmed() === true) {
                if (this.allActiveCrowdFundingPools[l]['account'] === localStorage.getItem("accountName")) {
                  isPoolOwner = true;
                }
              }

              let t_pool_endtime = this.allActiveCrowdFundingPools[l]['end-time'];

              if (this.allActiveCrowdFundingPools[l]['end-time'].time !== undefined) {
                t_pool_endtime = this.allActiveCrowdFundingPools[l]['end-time'].time;
              }else if (this.allActiveCrowdFundingPools[l]['end-time'].timep !== undefined) {
                t_pool_endtime = this.allActiveCrowdFundingPools[l]['end-time'].timep;
              }

              const date_pool_end_time = new Date(t_pool_endtime);
              const stamp_pool_end_time = date_pool_end_time.getTime();

              t_pool_endtime = t_pool_endtime.slice(0, 10);

              const t_pool_endtime_date = new Date(t_pool_endtime);
              const t_now = new Date();

              const t_now2 = new Date().getTime();

              let can_generate = false;

              const lpClaimed = this.allActiveCrowdFundingPools[l]['lp-claimed'];

              if(t_now2 > stamp_pool_end_time && lpClaimed === false){
                can_generate = true;
              }

              let t_pool_start_time = this.allActiveCrowdFundingPools[l]['start-time'];

              if (this.allActiveCrowdFundingPools[l]['start-time'].time !== undefined) {
                t_pool_start_time = this.allActiveCrowdFundingPools[l]['start-time'].time;
              }else if (this.allActiveCrowdFundingPools[l]['start-time'].timep !== undefined) {
                t_pool_start_time = this.allActiveCrowdFundingPools[l]['start-time'].timep;
              }

              const date_pool_start_time = new Date(t_pool_start_time);
              const stamp_pool_start_time = date_pool_start_time.getTime();

              t_pool_start_time = t_pool_start_time.slice(0, 10);

              let t_pool_insurance_end_time = this.allActiveCrowdFundingPools[l]['insurance-end-time'];

              if (this.allActiveCrowdFundingPools[l]['insurance-end-time'].time !== undefined) {
                t_pool_insurance_end_time = this.allActiveCrowdFundingPools[l]['insurance-end-time'].time;
              }else if (this.allActiveCrowdFundingPools[l]['insurance-end-time'].timep !== undefined) {
                t_pool_insurance_end_time = this.allActiveCrowdFundingPools[l]['insurance-end-time'].timep;
              }

              t_pool_insurance_end_time = t_pool_insurance_end_time.slice(0, 10);

              //let t_pool_start_time_date = new Date(t_pool_start_time);

              let canPush = true;

              if(t_now > t_pool_endtime_date && lpClaimed === true){
                canPush = false;
              }

              const t_funded = this.allActiveCrowdFundingPools[l]['funded'];

              let funding_failed = false;
              if(t_now2 > stamp_pool_end_time && t_funded === false){
                canPush = false;
                funding_failed = true;
              }

              if(t_now2 > stamp_pool_end_time && lpClaimed === true){
                canPush = false;
              }

              if(canPush === false){
                canPush = isPoolOwner;
              }

              if(this.debug){
                console.log("crowdfunding t_pool_endtime");
                console.log(t_pool_endtime);
                console.log("crowdfunding this.allActivePoolsFactoryStakeFungiv2[l]");
                console.log(this.allActiveCrowdFundingPools[l]);
                console.log("crowdfunding this.allActivePoolsFactoryStakeFungiv2[l]['end-time']");
                console.log(this.allActiveCrowdFundingPools[l]['end-time']);
                console.log("crowdfunding this.allActivePoolsFactoryStakeFungiv2[l]['active']");
                console.log(this.allActiveCrowdFundingPools[l]['active']);
                if(t_now > t_pool_endtime_date){
                  console.log("t_now is GREATER than t_pool_endtime_date");
                }else {
                  console.log("t_now is LESS than t_pool_endtime_date");
                }
              }

              let t_wallet_balance = 0;

              if(canPush === true){
                const tub_payloadA = {
                  tokenA: this.coinName(this.allActiveCrowdFundingPools[l]['pledge-token']['refName']['namespace'], this.allActiveCrowdFundingPools[l]['pledge-token']['refName']['name']),
                };
                t_wallet_balance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadA);

                if(this.debug){
                  console.log("crowdfunding fungiv2 t_wallet_balance updated");
                  console.log(t_wallet_balance);
                }
              }

              if(this.debug){
                console.log("crowdfunding fungiv2 t_wallet_balance");
                console.log(t_wallet_balance);
              }


              const poolDetailObject = {
                pool_name: this.allActiveCrowdFundingPools[l].name,
                pool_id: this.allActiveCrowdFundingPools[l].id,
                pool_end_time: t_pool_endtime,
                pool_start_time: t_pool_start_time,
                pool_run_time: this.allActiveCrowdFundingPools[l]['run-time'],
                pool_pledge_token: this.coinName(this.allActiveCrowdFundingPools[l]['pledge-token']['refName']['namespace'], this.allActiveCrowdFundingPools[l]['pledge-token']['refName']['name']),
                pool_sold_token: this.coinName(this.allActiveCrowdFundingPools[l]['sold-token']['refName']['namespace'], this.allActiveCrowdFundingPools[l]['sold-token']['refName']['name']),
                pool_tokens_locked: this.allActiveCrowdFundingPools[l]['tokens-locked'],
                pool_balance: this.allActiveCrowdFundingPools[l]['balance'],
                pool_active: this.allActiveCrowdFundingPools[l]['active'],
                pool_amount_sold: this.allActiveCrowdFundingPools[l]['amount-sold'],
                pool_amount_to_sell: this.allActiveCrowdFundingPools[l]['amount-to-sell'],
                pool_amount_to_dex: this.allActiveCrowdFundingPools[l]['amount-to-dex'],
                pool_description: this.allActiveCrowdFundingPools[l]['description'],
                pool_lp_claimed: this.allActiveCrowdFundingPools[l]['lp-claimed'],
                pool_paid: this.allActiveCrowdFundingPools[l]['paid'],
                pool_price: this.allActiveCrowdFundingPools[l]['price'],
                pool_funded: this.allActiveCrowdFundingPools[l]['funded'],
                pool_maxxed: this.allActiveCrowdFundingPools[l]['maxxed'],
                pool_initialized: this.allActiveCrowdFundingPools[l]['initialized'],
                pool_insurance_time: this.allActiveCrowdFundingPools[l]['insurance-time'],
                pool_insurance_end_time: t_pool_insurance_end_time,
                pool_contract: 'factory-crowdfund',
                pool_accountApproved: this.accountApproved,
                is_pool_owner: isPoolOwner,
                wallet_pledge_balance: t_wallet_balance,
                pledge_balance: 0,
                pledge_rewards: 0,
                stamp_pool_end_time: stamp_pool_end_time,
                stamp_pool_start_time: stamp_pool_start_time,
                can_generate: can_generate,
                pool_can_claim: this.allActiveCrowdFundingPools[l]['can-claim'],
                funding_failed: funding_failed
              }

              if (this.allCrowdFundingPoolsToShow.includes(poolDetailObject) === false && canPush === true) {
                this.allCrowdFundingPoolsToShow.push(poolDetailObject);
              }
            }

          }
        }
      }

      //We now have our list to show on the screen filled with the crowdfunding pool data the user has NOT pledged in
      //Now, Lets fill that same list with the data from crowdfunding pools the user has pledged in
      if (this.allUserCrowdFundingPools.length > 0) {

        for (let n = this.allUserCrowdFundingPools.length - 1; n >= 0; n--) {

          if (this.debug === true) {
            console.log("STEP 3) POOL ID:");
            console.log(this.allUserCrowdFundingPools[n].id)
          }

          if(this.allUserCrowdFundingPools[n].id === this.pool_id){


            let t_pool_endtime = this.allUserCrowdFundingPools[n]['end-time'];

            if (this.allUserCrowdFundingPools[n]['end-time'].time !== undefined) {
              t_pool_endtime = this.allUserCrowdFundingPools[n]['end-time'].time;
            }else if (this.allUserCrowdFundingPools[n]['end-time'].timep !== undefined) {
              t_pool_endtime = this.allUserCrowdFundingPools[n]['end-time'].timep;
            }

            const date_pool_end_time = new Date(t_pool_endtime);
            const stamp_pool_end_time = date_pool_end_time.getTime();

            t_pool_endtime = t_pool_endtime.slice(0, 10);

            const t_pool_endtime_date = new Date(t_pool_endtime);
            const t_now = new Date();

            const t_now2 = new Date().getTime();

            let t_pool_start_time = this.allUserCrowdFundingPools[n]['start-time'];

            const lpClaimed = this.allUserCrowdFundingPools[n]['lp-claimed'];

            let can_generate = false;

            if(t_now2 > stamp_pool_end_time && lpClaimed === false){
              can_generate = true;
            }

            const t_funded = this.allUserCrowdFundingPools[n]['funded'];

            let funding_failed = false;
            if(t_now2 > stamp_pool_end_time && t_funded === false){
              funding_failed = true;
            }

            if (this.allUserCrowdFundingPools[n]['start-time'].time !== undefined) {
              t_pool_start_time = this.allUserCrowdFundingPools[n]['start-time'].time;
            }else if (this.allUserCrowdFundingPools[n]['start-time'].timep !== undefined) {
              t_pool_start_time = this.allUserCrowdFundingPools[n]['start-time'].timep;
            }

            const date_pool_start_time = new Date(t_pool_start_time);
            const stamp_pool_start_time = date_pool_start_time.getTime();

            console.log("POOL START TIME!");
            console.log(t_pool_start_time);
            console.log("date_pool_start_time");
            console.log(date_pool_start_time)
            console.log("stamp_pool_start_time");
            console.log(stamp_pool_start_time)

            t_pool_start_time = t_pool_start_time.slice(0, 10);

            let t_pool_insurance_end_time = this.allUserCrowdFundingPools[n]['insurance-end-time'];

            if (this.allUserCrowdFundingPools[n]['insurance-end-time'].time !== undefined) {
              t_pool_insurance_end_time = this.allUserCrowdFundingPools[n]['insurance-end-time'].time;
            }else if (this.allUserCrowdFundingPools[n]['insurance-end-time'].timep !== undefined) {
              t_pool_insurance_end_time = this.allUserCrowdFundingPools[n]['insurance-end-time'].timep;
            }

            t_pool_insurance_end_time = t_pool_insurance_end_time.slice(0, 10);

            let t_wallet_balance = 0;

            const tub_payloadA = {
              tokenA: this.coinName(this.allUserCrowdFundingPools[n]['pledge-token']['refName']['namespace'], this.allUserCrowdFundingPools[n]['pledge-token']['refName']['name']),
            };
            t_wallet_balance = await this.$store.dispatch("accounts/getTokenUserBalance", tub_payloadA);

            if(this.debug){
              console.log("t_wallet_balance updated");
              console.log(t_wallet_balance);
            }


            if(this.debug){
              console.log("t_wallet_balance");
              console.log(t_wallet_balance);
            }

            if(this.debug){
              console.log("t_pool_endtime");
              console.log(t_pool_endtime);
              console.log("this.allUserPoolsFactoryStakeFungiv2[n]");
              console.log(this.allUserCrowdFundingPools[n]);
              console.log("this.allUserPoolsFactoryStakeFungiv2[l]['end-time']");
              console.log(this.allUserCrowdFundingPools[n]['end-time']);
              console.log("this.allUserPoolsFactoryStakeFungiv2[l]['active']");
              console.log(this.allUserCrowdFundingPools[n]['active']);
              if(t_now > t_pool_endtime_date){
                console.log("t_now is GREATER than t_pool_endtime_date");
              }else {
                console.log("t_now is LESS than t_pool_endtime_date");
              }
            }

            const poolDetailObject = {
              pool_name: this.allUserCrowdFundingPools[n].name,
              pool_id: this.allUserCrowdFundingPools[n].id,
              pool_end_time: t_pool_endtime,
              pool_start_time: t_pool_start_time,
              pool_run_time: this.allUserCrowdFundingPools[n]['run-time'],
              pool_pledge_token: this.coinName(this.allUserCrowdFundingPools[n]['pledge-token']['refName']['namespace'], this.allUserCrowdFundingPools[n]['pledge-token']['refName']['name']),
              pool_sold_token: this.coinName(this.allUserCrowdFundingPools[n]['sold-token']['refName']['namespace'], this.allUserCrowdFundingPools[n]['sold-token']['refName']['name']),
              pool_tokens_locked: this.allUserCrowdFundingPools[n]['tokens-locked'],
              pool_balance: this.allUserCrowdFundingPools[n]['balance'],
              pool_active: this.allUserCrowdFundingPools[n]['active'],
              pool_amount_sold: this.allUserCrowdFundingPools[n]['amount-sold'],
              pool_amount_to_sell: this.allUserCrowdFundingPools[n]['amount-to-sell'],
              pool_amount_to_dex: this.allUserCrowdFundingPools[n]['amount-to-dex'],
              pool_description: this.allUserCrowdFundingPools[n]['description'],
              pool_lp_claimed: this.allUserCrowdFundingPools[n]['lp-claimed'],
              pool_paid: this.allUserCrowdFundingPools[n]['paid'],
              pool_price: this.allUserCrowdFundingPools[n]['price'],
              pool_funded: this.allUserCrowdFundingPools[n]['funded'],
              pool_maxxed: this.allUserCrowdFundingPools[n]['maxxed'],
              pool_initialized: this.allUserCrowdFundingPools[n]['initialized'],
              pool_insurance_time: this.allUserCrowdFundingPools[n]['insurance-time'],
              pool_insurance_end_time: t_pool_insurance_end_time,
              pool_contract: 'factory-crowdfund',
              pool_accountApproved: this.accountApproved,
              is_pool_owner: false,
              wallet_pledge_balance: t_wallet_balance,
              pledge_balance: this.allUserCrowdFundingPools[n]['stake_balance'],
              pledge_rewards: this.allUserCrowdFundingPools[n]['stake_rewards'],
              stamp_pool_end_time: stamp_pool_end_time,
              stamp_pool_start_time: stamp_pool_start_time,
              can_generate: can_generate,
              pool_can_claim: this.allUserCrowdFundingPools[n]['can-claim'],
              funding_failed: funding_failed
            }

            if (this.allCrowdFundingPoolsToShow.includes(poolDetailObject) === false) {
              this.allCrowdFundingPoolsToShow.push(poolDetailObject);
            }

          }



        }
      }

      //At this point we can now display our allCrowdFundingPoolsToShowNow list on the screen
      if (this.debug) {
        console.log("allCrowdFundingPoolsToShow");
        console.log(this.allCrowdFundingPoolsToShow);
      }


      if (this.allCrowdFundingPoolsToShow.length > 0) {

        for (let t = this.allCrowdFundingPoolsToShow.length - 1; t >= 0; t--) {


          this.l_pool_funded = this.allCrowdFundingPoolsToShow[t]['pool_funded'];
          this.l_pool_maxxed = this.allCrowdFundingPoolsToShow[t]['pool_maxxed'];
          this.l_pledge_rewards= this.allCrowdFundingPoolsToShow[t]['pledge_rewards'];
          this.l_pledge_balance= this.allCrowdFundingPoolsToShow[t]['pledge_balance'];
          this.l_wallet_pledge_balance= this.allCrowdFundingPoolsToShow[t]['wallet_pledge_balance'];
          this.l_pool_amount_sold= this.allCrowdFundingPoolsToShow[t]['pool_amount_sold'];
          this.l_pool_end_time= this.allCrowdFundingPoolsToShow[t]['pool_end_time'];
          this.l_pool_insurance_end_time= this.allCrowdFundingPoolsToShow[t]['pool_insurance_end_time'];
          this.l_pool_can_claim= this.allCrowdFundingPoolsToShow[t]['pool_can_claim'];
          this.l_stamp_pool_end_time= this.allCrowdFundingPoolsToShow[t]['stamp_pool_end_time'];
          this.l_pool_initialized= this.allCrowdFundingPoolsToShow[t]['pool_initialized'];
          this.l_pool_paid= this.allCrowdFundingPoolsToShow[t]['pool_paid'];
          this.l_pool_lp_claimed= this.allCrowdFundingPoolsToShow[t]['pool_lp_claimed'];
          this.l_pool_tokens_locked= this.allCrowdFundingPoolsToShow[t]['pool_tokens_locked'];
          this.l_pool_active= this.allCrowdFundingPoolsToShow[t]['pool_active'];
          this.l_funding_failed= this.allCrowdFundingPoolsToShow[t]['funding_failed'];
          this.l_can_generate= this.allCrowdFundingPoolsToShow[t]['can_generate'];
          this.l_pool_amount_to_sell = this.allCrowdFundingPoolsToShow[t]['pool_amount_to_sell'];
          this.l_pool_user_balance = this.allCrowdFundingPoolsToShow[t]['funding_failed'];

        }

      }

    }


  },
  async created(){
    this.currentDate = new Date().getTime()
  }
};
</script>

<style scoped>

.endcol{
  margin-left: 1rem;
  margin-right: 1rem;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -4px, rgba(0, 0, 0, 0.20) 0px -1px 0px inset;
  border-radius: 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background-color: #05060824;
}

.cow {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(15 47 50);
  background: -webkit-linear-gradient(#3e1046, #6e2a57) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  border-radius: 0.5rem;
  color: #296992;
  margin-right: 10px !important;
}

.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 0.6);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}

.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}

.pending-col2 {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: Source Code Pro,monospace !important;
}

.divider {
  border: none;
  height: 20px;
  width: 90%;
  height: 50px;
  margin-top: 0;
  border-bottom: 1px solid #1f1209;
  box-shadow: 0 20px 20px -20px #333;
  margin: -50px auto 10px;
}

/deep/ .progress-wrapper:not(:last-child) {
  margin-bottom: 0 !important;
}

/deep/ .progress-wrapper .progress.is-medium + .progress-value, .progress-wrapper .is-medium.progress-wrapper.is-not-native + .progress-value, .progress-wrapper .progress.is-medium .progress-value, .progress-wrapper .is-medium.progress-wrapper.is-not-native .progress-value, .progress-wrapper .progress-bar.is-medium + .progress-value, .progress-wrapper .progress-bar.is-medium .progress-value {
  font-size: calc(1.25rem / 1.5);
  line-height: 1.25rem;
  color: #772b7c !important;
}

.descriptionSection {
  position: relative;
  top: 10px;
  color: #3abb81;
}

.leatherbg {
  background: radial-gradient(#00000078 0%, transparent 10%) 0 0, radial-gradient(#0000005c 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 250, 250, 0) 15%, transparent 20%) 8px 9px;
  background-color: #2c243275 !important;
  background-size: 16px 16px;
  border: 2px solid #11426573;
  background-blend-mode: darken;
}

.small-pool {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 3%;
}

::v-deep .accordion__item {
  cursor: pointer;
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

/deep/ .accordion__item {
  cursor: pointer;
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

.accordion__item {
  cursor: pointer;
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

.highlight-span {
  background-color: rgba(39, 99, 74, 0.68);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  background-blend-mode: color;
  border: 1px solid rgba(177, 76, 226, 0.56) !important;
  outline: none;
}

.highlight-span:before {
  background-color: #0a0a0a;
  padding: 7px 20px;
  display: block;
  border-radius: 50px;
  border: 4px solid #3abb81;
  background-blend-mode: color;
}

.highlight-span:focus,
.highlight-span:hover {
  background-color: rgba(27, 94, 71, 0.68);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 4px solid #309a6a;
  outline: none;
}

/deep/ .columns:not(:last-child) {
  margin-bottom: 0px;
}

.stake-col-lg{
}

.stake-col-sm{
  padding-left: 0px;
}

.withdraw-col-lg{
  padding-right: 0px;
}

.withdraw-col-sm{
  padding-left: 0px;
}

.withdraw-btn-lg{
  border-bottom-left-radius: 1rem !important; border-top-left-radius: 1rem !important;
}
.withdraw-btn-sm{
  border-radius: 1rem;
}

.claim-btn-lg{
  border-bottom-right-radius: 1rem !important; border-top-right-radius: 1rem !important;
}

.claim-btn-sm{
  border-radius: 1rem;
}

/deep/ .minus {
  border-top-left-radius: 1rem;
}

/deep/ .b-numberinput input {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
  box-shadow: inset 0 30px 60px -12px rgba(50, 50, 93, 0.25), inset 0 18px 36px -18px rgba(0, 0, 0, 0.30);
}

/deep/ .button.is-buttonblue {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}


/deep/ .button.is-buttonblue:hover {
  color: #19ac78;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}

/* card background */
.home-card-bg {
  background: #ebeae5;
}

.inset-bg {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding-bottom: 2rem;
}

.inset-bg2:active {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  width: 100%;
  height: 30px;
  position: relative;
  border-radius: 1rem;
  align-self: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 4px;
}


.col-title {
  color: #9573ba;
  font-size: 12px;
  font-family: Source Code Pro,monospace !important;
}

.col-title2 {
  color: #36c0ea !important;
  font-size: 12px !important;
  font-family: Source Code Pro,monospace !important;
}

.pending-title {
  color: rgb(120 161 235);
  font-size: 14px;
}

.pool-control-title{
  color: #49566c;
  font-size: 14px;
}

.pool-control-title:hover{
  color: #3abb81;
  font-size: 14px;
}

.pending-stat {
  color: #3cea36;
  font-size: 1em;
}

.pending-col {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: Source Code Pro,monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.col-shadow1 {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider {
  font-size: 12px;
}

.pool-name {
  font-size: 16px;
  color: rgb(120 161 235);
  font-family: Source Code Pro,monospace !important;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: #293038;
}

.pool-main {
  background-color: #293038;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border: 1px solid #554460;
  box-shadow: rgb(0 0 0 / 6%) 0px -23px 25px 0px inset, rgb(0 0 0 / 9%) 0px -36px 30px 0px inset, rgb(0 0 0 / 7%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px !important;
}

.pool-item:hover {
  background-color: #333b4c;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border: 1px solid rgba(3, 255, 150, 0.38);
  box-shadow: rgb(0 0 0 / 11%) 0px -23px 25px 0px inset, rgb(0 0 0 / 9%) 0px -36px 30px 0px inset, rgb(0 0 0 / 7%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px !important;
}

.poolselect-bg {
  background-color: #293038 !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

/deep/ .tabs.is-toggle li.is-active a {
  background-color: #314b6a;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #3abb81;
  z-index: 1;
  font-family: Source Code Pro,monospace !important;
}

/deep/ .tabs.is-toggle a {
  border-color: #575f85;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 0;
  position: relative;
  font-family: Source Code Pro,monospace !important;
  color: #195da7;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
  color: #00b89c;
}


.pools-card {
  padding-top: .5rem;
  color: #0d64c6;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
}

/deep/ .field.has-addons .control:first-child .control .button, .field.has-addons .control:first-child .control .input, .field.has-addons .control:first-child .control .select select {
  border-radius: 0 !important;
}

/deep/ .field.has-addons:first-child  .control:first-child .control:first-child  .button:first-child , .field.has-addons:first-child  .control:first-child .control:first-child  .input:first-child , .field.has-addons:first-child .control:first-child .control:first-child  .select:first-child  select:first-child{
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.stake-btn-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.stake-btn-2 {
  font-size: 14px !important;
  max-height: 40px;
  padding-left: 12px !important;
}

.btn {
  color: #87b6e5;
  background: rgb(34 77 118);
  background-repeat: no-repeat;
  font-family: Source Code Pro,monospace !important;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.btn:focus,
.btn:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    margin-bottom: 30px;
  }
}

</style>
<style lang="scss" scoped>

.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro,monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

</style>
<style scoped>
.container2 {
  background-color: #1D1F21;
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  padding: 25px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), transparent 85%), radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%), linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 7px -4px #4f545f inset, 0 -2px 1px #5c6574 inset;
}
</style>
<style lang="scss" scoped>
article {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 900px) {
    padding: 80px;
  }

  @media (max-width: 899px) {
    padding-bottom: 56px;
  }
  background-color: rgba(192, 192, 192, 0);
  color: #323232;
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  position: relative;
  & > * + * {
    margin-top: 1.25em;
  }

  &:after {
    content: "🥩";
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding-bottom: 0.25em;
    position: absolute;
    top: 0.75em;
    right: 0.75em;
    width: 1.75em;
    height: 1.75em;
    filter: grayscale(90%);
    background-color: transparent;
    border-left: 1px solid rgba(238, 238, 238, 0.16);
    border-top: 1px solid rgba(238, 238, 238, 0.06);
    border-right: 1px solid rgba(68, 68, 68, 0.41);
    border-bottom: 1px solid rgba(68, 68, 68, 0.39);
  }
}

.headline {
  font-size: 2.5em;
  font-weight: 700;
}

.subhead {
  font-size: 1.25em;
  margin-top: 0.25em;
}

.article-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    width: 3em;
    height: 3em;
    flex-shrink: 0;
  }

  div:first-of-type {
    margin-left: 0.75em;
    font-size: 0.875em;
    margin-right: auto;
    padding-right: 0.75em;
  }

  div:last-of-type {
    font-size: 0.875em;
  }
}

aside {
  padding: 1em;
  border-right: 1px solid rgba(238, 238, 238, 0.11);
  border-bottom: 1px solid rgba(238, 238, 238, 0.06);
  border-left: 1px solid rgba(68, 68, 68, 0.45);
  border-top: 1px solid rgba(68, 68, 68, 0.39);
  clear: both;
  text-shadow: 0 0 10px, 0 0 40px;
}

.tag {
  display: inline-block;
  margin-right: 0.25em;
  margin-top: 0.25em;
  border-right: 1px solid rgba(238, 238, 238, 0.19);
  border-bottom: 1px solid rgba(238, 238, 238, 0.13);
  border-left: 1px solid #444;
  border-top: 1px solid #444;
  padding: 0.125em 0.375em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

h2:not(.subhead) {
  font-size: 1.5em;
  font-weight: 700;
  padding-bottom: 0.375em;
  box-shadow: 0 1px 0 0 #444, 0 2px 0 0 #eee;
}

figure {
  background-color: #c0c0c0;
  box-shadow: 0 0 0 1px #eee, 0 0 0 2px #c0c0c0, 0 0 0 3px #444;
  position: relative;
  margin-bottom: 1.25em;
  width: calc(100% + 1.5em + 2em);
  text-shadow: 0 0 10px, 0 0 40px;
  @media (min-width: 800px) {
    max-width: 50%;
  }
  &:nth-of-type(odd) {
    float: left;
    margin-right: 1.5em;
    margin-left: -3em;
  }

  &:nth-of-type(even) {
    float: right;
    margin-left: 1.5em;
    margin-right: -3em;
  }

  &:after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    background-image: linear-gradient(
            to top,
            rgba(#ff9100, 0.2) 0%,
            rgba(#ffe630, 0.2) 60%
    ),
    linear-gradient(20deg, rgba(#ff0, 0.5) 0%, rgba(#ff0, 0) 35%);
    box-shadow: inset 0px 0px 100px rgba(0, 0, 20, 1);
    z-index: 1;
  }

  img {
    // opacity: 0;
    // filter: hue-rotate(90deg);
    // filter: sepia(20%) brightness(10%) contrast(130%)
  }
  figcaption {
    font-family: "DotGothic16", monospace;
    background-color: #6f4b80;
    box-shadow: inset 0 -1px 0 0 #020202;
    color: #eee;
    text-shadow: 0 0 10px, 0 0 40px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.25em;
    font-size: 0.875em;
    z-index: 2;
  }
}

</style>
