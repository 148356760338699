<template>
  <div>
    <header>
      <!--      Display Navbar section-->
      <transition name="fade">
      <Navbar />
      </transition>

      <div class="dripper" v-if="$screen.width > 576">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="0">
          <defs>
            <filter id="goo">
              <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation="10"
                  result="blur"
              />
              <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                  result="goo"
              />
              <feComposite in="SourceGraphic" in2="goo" operator="atop" />
            </filter>
          </defs>
        </svg>
        <div class="drip">
          <div class="droplet">🥩</div>
          <div class="droplet2"></div>
          <div class="drop"></div>
        </div>
      </div>
    </header>

    <main>
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </main>
  </div>
</template>

<script>
//Load our navbar
import Navbar from './components/Navbar.vue'

export default {
  name: 'App',
  components: {
    Navbar
  }
}
</script>

<style>
html {
  overflow-y: hidden !important;
  background-color: #47485a !important;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, #26202b08 35px, #26202b0d 70px);
}

:root{
  --color-light: #342b3b;
  --color-medium: #322939;
  --color-dark: #2f2736;
  --color-darkest: #2c2432;
  --animation-speed: 8s;
  --animation-loop: infinite;
}

*{
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html{
  height: 100vh;
  width: 100vw;
}

body{
  font-family: sans-serif;
  height: 100vh;
  width: 100vw;
}

@keyframes loader2 {
  0%{
    background: var(--color-dark);
    width:0%;
  }20%{
     width:100%;
   }39%{
      background: var(--color-dark);
    }40%{
       background: var(--color-light);
       width: 0%
     }60%{
        width: 100%;
      }80%{
         width:0%;
       }100%{
          background: var(--color-light);
          width: 0%;
        }
}

@keyframes loaderAlt {
  0%{
    background: var(--color-darkest);
    width:100%;
  }19%{
     background: var(--color-darkest);
   }20%{
      background: var(--color-medium);
      width: 0%;
    }40%{
       width: 100%;
     }59%{
        background: var(--color-medium);
      }60%{
         background: var(--color-darkest);
         width: 0;
       }80%{
          width: 100%;
        }100%{
           background: var(--color-darkest);
           width: 100%
         }
}

.loader2{
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.loader__bar{
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
}

.loader__bar:before{
  animation: loader2 ease var(--animation-speed) var(--animation-loop);
  animation-delay: 100ms;
  background: var(--color-darkest);
  background-size: 200% 200%;
  content: "";
  height:100%;
  width: 0%;
}

.loader__bar:after{
  animation: loaderAlt ease var(--animation-speed) var(--animation-loop);
  animation-delay: 100ms;
  background: var(--color-darkest);
  background-size: 200% 200%;
  content: "";
  height: 100%;
  width: 100%;
}

.loader__bar--delay-1:before,
.loader__bar--delay-1:after{
  animation-delay: 200ms;
}

.loader__bar--delay-2:before,
.loader__bar--delay-2:after{
  animation-delay: 300ms;
}

.loader__bar--delay-3:before,
.loader__bar--delay-3:after{
  animation-delay: 400ms;
}

.loader__bar--delay-4:before,
.loader__bar--delay-4:after{
  animation-delay: 500ms;
}

.loader__bar--delay-5:before,
.loader__bar--delay-5:after{
  animation-delay: 600ms;
}

@-webkit-keyframes jump {
  0% { width:20px; height: 180px; margin-left:0; margin-top:0;
    box-shadow: inset 0 1px 1px rgba(255,255,255,.2), 0 2px 2px rgba(0,0,0,.7); }
  50% { width:26px; height: 200px; margin-left:-3px; margin-top:-10px;
    box-shadow: inset 0 1px 1px rgba(255,255,255,.2), 0 20px 25px rgba(0,0,0,.7); }
  100% { width:20px; height: 180px; margin-left:0; margin-top:0;
    box-shadow: inset 0 1px 1px rgba(255,255,255,.2), 0 2px 2px rgba(0,0,0,.7); }
}

html {
  height: 100%;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}


.btn{
  color: #fff;
  background: #5f96ce;
  background-repeat: no-repeat;
  font-size: 18px;
  text-transform: uppercase;
  border: none;
  border-radius: 0;
  background: linear-gradient(120deg, #fcfcfc 0%, #ffffff 74%);
}
.btn:focus,
.btn:hover{
  color: #fff;
  background: radial-gradient(circle at center,rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color:#89bcea;
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
}
@keyframes bubbles{
  100%{
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%,26% -96%,-1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #eaf6ff;
  }
}
@media only screen and (max-width: 767px){
  .btn{ margin-bottom: 30px; }
}

.footer-link:hover{
  color: #3abb81;
}

.centered {
  position: absolute;
  top: 57%;
  left: 49%;
  transform: translate(-46%, -50%);
}

.ontop {
  z-index: 2;
}

.font1 {
  font-family: 'Angkor', sans-serif;
  font-family: Source Code Pro,monospace;
}
.font2 {
  font-family: 'Noto Sans', sans-serif;
  font-family: Source Code Pro,monospace;
}

.font3 {
  font-family: 'Angkor', sans-serif;
  font-family: Source Code Pro,monospace;
}

.font4 {
  font-family: 'Titan One', sans-serif;
  font-family: Source Code Pro,monospace;
}

.font5{
  font-family: 'Lexend Deca', sans-serif;
  font-family: Source Code Pro,monospace;
}

.font6{
  font-family: 'Inter', sans-serif;
  font-family: Source Code Pro,monospace;
}


.moveup {
  position: absolute;
  width: 100%;
  top: -30px;
}

.font-color-blue {
  color: #749bba !important;
}

.body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
<style lang="scss" scoped>
@mixin pattern($bg-primary, $bg-secondary) {
  background: repeating-linear-gradient(
          -45deg,
          transparent,
          transparent 1em,
          rgba($bg-secondary,0.4) 0,
          rgba($bg-secondary,0.1) 2em,
          transparent 0,
          transparent 1em,
          rgba($bg-secondary,0.3) 0,
          rgba($bg-secondary,0.2) 4em,
          transparent 0,
          transparent 1em,
          rgba($bg-primary,0.6) 0,
          rgba($bg-primary,0.2) 2em
  ),
  repeating-linear-gradient(
          45deg,
          transparent,
          transparent 1em,
          rgba($bg-secondary,0.4) 0,
          rgba($bg-secondary,0.1) 2em,
          transparent 0,
          transparent 1em,
          rgba($bg-secondary,0.3) 0,
          rgba($bg-secondary,0.2) 4em,
          transparent 0,
          transparent 1em,
          rgba($bg-primary,0.4) 0,
          rgba($bg-primary,0.1) 2em
  ), #FFF;
  background-blend-mode: multiply;
}

html {
  height: 100%;
  @include pattern(#c0ebfa, #7FD7F5);
}

/*SVG DRIP STYLES*/
$dripcolor: #73a3e2;


header .dripper {
  position: relative;
  z-index: 3;
  top: -50px;
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
  .drip {
    position: absolute;
    background: $dripcolor;
    width: 250px;
    height: 25%;
    bottom: 2px;
    left: 5%;
    cursor: default;
    -webkit-filter: url('#goo');
    filter: url('#goo');
    .drop {
      position: absolute;
      top: 0;
      left: 25%;
      width: 30px;
      height: 80px;
      border-radius: 50%;
      background: $dripcolor;
      transition: all 0.4s ease;
      &:hover {
        height: 120px;
      }
    }
    .droplet {
      position: absolute;
      top: 0;
      left: 15%;
      content: "🥩";
      width: 20px;
      height: 55px;
      border-radius: 50%;
      background: $dripcolor;
      z-index: 5;
      transition: all 0.4s ease;
      animation: droplet 1.4s 0.4s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1;
      &:hover {
        top: 20px;
        width: 40px;
        left: 13%;
        animation: reset;
      }
    }
    .droplet2 {
      position: absolute;
      top: 10px;
      content:"🥩";
      left: 10%;
      width: 20px;
      height: 35px;
      border-radius: 50%;
      z-index: 3;
      background: $dripcolor;
      transition: all 0.4s ease;
      &.dripit {
        animation: droplet 1s 0.6s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1;
      }
    }
  }
}

@keyframes drop {
  0% {
    transform: translate(0, 0);
  }
  50% {
    height: 40px;
    width: 40px;
  }
  100% {
    transform: translate(0, 550px);
  }
}

@keyframes droplet {
  0% {
    transform: translate(0, 0);
  }
  50% {
    height: 30px;
    width: 30px;
  }
  100% {
    transform: translate(0, 700px);
  }
}
</style>
