<template>
  <transition name="fade">
    <div id="app" >
      <NftPoolsPage ></NftPoolsPage>
    </div>
  </transition>
</template>

<script>
import NftPoolsPage from '../components/NftPoolsPage.vue'

export default {
  name: 'Home',
  components: {
    NftPoolsPage
  },
}
</script>

<style>


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#app {
  font-family: Source Code Pro, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
