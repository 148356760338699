<template>
  <div style="overflow-x: hidden" :style="$screen.width > 1024 ? 'margin-top: -85px' : 'margin-top: -84px'">

    <!--    Page loading animation section-->
    <div v-if="this.showLoadingModal === true" style="height: 100vh">
      <div class="loader2">
        <div class="loader__bar"></div>
        <div class="loader__bar loader__bar--delay-1"></div>
        <div class="loader__bar loader__bar--delay-2"></div>
        <div class="loader__bar loader__bar--delay-3"></div>
        <div class="loader__bar loader__bar--delay-4"></div>
        <div class="loader__bar loader__bar--delay-5"></div>
        <div>
        </div>
      </div>
    </div>

    <!--    Homepage shown after loading is completed-->
    <section v-if="this.showLoadingModal === false">


      <!--      bubbles-->
      <div
          v-if="this.showLoadingModal === false"
          id="style-10"
          style="width:100vw; overflow-x: hidden !important; overflow-y: auto"
          :style="this.getShowNavBar() === true ? 'height: 92vh !important' : 'height: 104vh !important'">

        <section class="hero" style="z-index: 2">
          <div class="hero-body header-bg3" style="z-index: 2"></div>
        </section>


        <section class="hero" :class="$screen.width > 1024 ? '' : ''" style="z-index: 2">

          <div class="columns mt-4">

            <div class="column" style="z-index: 2">

              <div class="machineinterface">
                <div class="monitor">
                  <div class="monitor-inner" style="padding-bottom: 60px">
                    <div class="screen-cutout">
                      <div class="screen">

                        <div class="my-pools-shadow column"
                             style="z-index: 2; padding: 0px !important; margin-top: 20px;">
                          <div class="card pools-bg2 cardborder pb-5 " style="z-index: 2">
                            <div class="card-content pools-bg poolselect-bg mb-6">
                              <section>
                                <b-tabs
                                    v-model="activeTab"
                                    headerClass="headertest"
                                    class="font1"
                                    position="is-centered"
                                    type="is-toggle"
                                    expanded
                                >
                                  <b-tab-item value="0" label="Hive"></b-tab-item>
<!--                                  <b-tab-item value="1" label="My Account"></b-tab-item>-->
                                  <b-tab-item v-if="is_moderator === true || is_treasurer === true || is_creator === true" value="2" label="Admin"></b-tab-item>
                                </b-tabs>
                              </section>
                            </div>

                            <!-- HIVE SECTION -->
                            <section v-if="activeTab === '0'" style="margin-top: -45px;">

                              <!--                              <section class="hero" v-bind:style="{ 'background-image': 'url(' + DAO_image + ')', 'background-size': 'cover' }">-->
                              <section class="hero"
                                       style="box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;">

                                <section class="hero" style="margin-top: 10px; ">
                                  <div class="hero-body" style="padding-bottom: 1rem !important; ">
                                    <div class="container animated fadeInUp"
                                         style="box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;">
                                      <div
                                          class="columns is-multiline is-mobile container4 is-flex is-justify-content-center daoshadow"
                                          style="height: 180px; border-top-left-radius: 2rem; border-top-right-radius: 2rem; box-shadow: rgb(145 145 209 / 25%) 0px 19px 60px -12px inset, rgb(0 0 0 / 30%) 0px 18px 39px -1px inset;">
                                        <div
                                            class="column is-4-desktop is-4-tablet is-4-mobile reddish is-flex is-justify-content-center">
                                          <img :src="DAO_SUBJECT_INFO.icon" alt="?"
                                               style="width: 100px; height: 100px; margin-top: 20px;">
                                        </div>
                                        <div
                                            class="column is-8-desktop is-8-tablet is-8-mobile reddish is-flex is-justify-content-center"
                                            style="box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;">
                                          <ul class="list-unstyled is-flex is-flex-direction-column is-justify-content-center">
                                            <li class="cow2" style="font-size: 40px;">{{ DAO_SUBJECT_INFO.name }}</li>
                                            <li class="cow3" style="font-size: 12px;">DAO ID:
                                              {{ DAO_SUBJECT_INFO.subject_id }}
                                            </li>
                                          </ul>

                                        </div>

                                      </div>
                                    </div>
                                    <div class="container animated fadeInUp"
                                         style="box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;">
                                      <div class="columns is-multiline is-mobile"
                                           style=" border-bottom-right-radius: 2rem; border-bottom-left-radius: 2rem; ">
                                        <div
                                            class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish" style="box-shadow: rgb(145 145 209 / 25%) 0px 8px 96px -25px inset, rgb(0 0 0 / 30%) 0px 90px 50px -40px inset;">
                                          <ul class="list-unstyled">
                                            <li class="cow2"
                                                style="font-size: 15px; padding:5px; color: #b83838 !important;">
                                              {{ DAO_SUBJECT_INFO.description }}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="container animated fadeInUp">
                                      <div class="columns is-multiline is-mobile ">
                                        <div
                                            class="column is-8-desktop is-8-tablet is-12-mobile txshadow reddish container_about"
                                            style="padding: 1rem;">
                                          <ul class="list-unstyled has-text-left">
                                            <li class="cow" style="font-size: 20px; color: #b83838 !important;">About
                                            </li>
                                            <li>
                                              <div class="divider" style="margin-top: -45px;"></div>
                                              <br/></li>
                                            <li class="cow3" style="font-size: 15px; color: #b83838 !important;">
                                              {{ DAO_SUBJECT_INFO.about }}
                                            </li>
                                          </ul>
                                        </div>
                                        <div
                                            class="column is-4-desktop is-4-tablet is-12-mobile txshadow reddish container_links is-justify-content-center"
                                            style="padding: 1rem; ">
                                          <b-button @click="goTo(p.link)" v-for="p in DAO_LINKS" :key="p.link"
                                                    type="is-primary"
                                                    style="margin: 5px; color: #8ce8e3; font-family: 'Source Code Pro', monospace;">
                                            {{ p.name }}
                                          </b-button>
                                          <br/>
                                          <div class="divider" style="margin-top: -45px;"></div>
                                          <br/>
                                          <b-button v-if="has_account === false" @click="clickCreateAccount()" type="is-darkdao"
                                                    style="margin: 5px; color: #8ce8e3; font-family: 'Source Code Pro', monospace; width: 100%;">
                                            Create Account
                                          </b-button>
                                          <b-button v-if="has_account === true" @click="clickEditAccount()" type="is-darkdao"
                                                    style="margin: 5px; color: #8ce8e3; font-family: 'Source Code Pro', monospace; width: 100%;">
                                            Edit Account
                                          </b-button>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="container animated fadeInUp"
                                         style="box-shadow: rgba(0, 0, 0, 0.2) 0px 13px 40px -7px; margin-top: 10px; z-index:0">
                                      <div class="columns is-multiline is-mobile"
                                           style=" border-bottom-right-radius: 2rem; border-bottom-left-radius: 2rem; box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;">
                                        <div
                                            class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                            style="border-bottom-right-radius: 2rem; border-bottom-left-radius: 2rem;">
                                          <div class="columns" style="padding: 20px; overflow-wrap: anywhere">
                                            <div class="column">
                                            <span class="cow"
                                                  style="font-size: 15px !important;">MEMBERS: {{ DAO_SUBJECT_INFO.member_count.int }}</span>
                                            </div>
                                            <div class="column">
                                            <span class="cow"
                                                  style="font-size: 15px !important;">PROPOSALS: {{ DAO_SUBJECT_INFO.pool_count.int }}</span>
                                            </div>
                                            <div class="column">
                                            <span class="cow"
                                                  style="font-size: 15px !important;">TASKS: {{ DAO_SUBJECT_INFO.task_count.int }}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>


                              </section>

                              <section class="hero">

                                <section class="hero" style="margin-top: 0px;">
                                  <div class="hero-body" style="padding-bottom: 1rem !important; ">
                                    <div class="container animated fadeInUp"
                                         style="box-shadow: rgba(0, 0, 0, 0.2) 0px 13px 40px -7px;">
                                      <div
                                          class="columns is-multiline is-mobile container4 is-flex is-flex-direction-column is-justify-content-center"
                                          style="padding-top: 15px; border-top-left-radius: 2rem; border-top-right-radius: 2rem; box-shadow: rgb(0 0 0 / 40%) 0px 0px 4px, rgb(0 0 0 / 30%) 0px 16px 13px -3px, rgb(0 0 0 / 20%) 0px -11px 0px inset;">
                                        <div class="column is-12-desktop is-12-tablet is-12-mobile reddish">
                                          <span style="color: rgba(74,227,209,0.7)">
                                            <b-tabs v-model="activeTab2" position="is-centered" class="block">
                                              <b-tab-item value="0" label="LATEST NEWS"></b-tab-item>
                                              <b-tab-item value="1" label="POOLS"></b-tab-item>
                                              <b-tab-item value="2" label="ACCOUNTS"></b-tab-item>
                                              <b-tab-item value="3" label="MEMBERS"></b-tab-item>
                                            </b-tabs>
                                          </span>
                                        </div>
                                        <div v-if="is_moderator === true || is_treasurer === true || is_creator === true" class="column is-12"
                                             style="padding-bottom: 15px;">
                                          <b-button v-if="activeTab2 === '0'" @click="clickPostMessageModerator()" type="is-darkdao"
                                                    style="color: #8ce8e3; font-family: 'Source Code Pro', monospace; width: 100%">
                                            POST NEW MESSAGE
                                          </b-button>
                                          <b-button v-if="activeTab2 === '1'" @click="clickShowPoolModal()" type="is-darkdao"
                                                    style="color: #8ce8e3; font-family: 'Source Code Pro', monospace; width: 100%">
                                            CREATE NEW POOL
                                          </b-button>
                                          <b-button v-if="activeTab2 === '3'" @click="clickShowPermissionsModal()" type="is-darkdao"
                                                    style="color: #8ce8e3; font-family: 'Source Code Pro', monospace; width: 100%">
                                            EDIT MEMBER PERMISSIONS
                                          </b-button>
                                        </div>
                                      </div>

                                    </div>
                                    <section v-if="activeTab2 === '0'">

                                      <div v-for="p in allMSGSPaginated" :key="p.message_date.timep"
                                           class="container animated fadeInUp">
                                        <div class="columns is-multiline is-mobile ">
                                          <div
                                              class="column is-12-desktop is-12-tablet is-12-mobile txshadow reddish container_msg"
                                              style="padding: 1rem;">
                                            <ul class="list-unstyled has-text-left">
                                              <li class="cow" style="font-size: 20px; color: #b83838 !important;">
                                                {{ p.message_date.timep ? new Date(p.message_date.timep) : new Date(p.message_date) }} -
                                                <span class="cow5">{{ p.message_title }}</span></li>
                                              <li>
                                                <div class="divider" style="margin-top: -45px;"></div>
                                                <br/></li>
                                              <li class="cow3" style="font-size: 15px; color: #b83838 !important;">
                                                {{ p.message }}
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>

                                    </section>

                                    <section v-if="activeTab2 === '1'">
                                      <div class="container animated fadeInUp">
                                        <div class="columns is-multiline is-mobile ">
                                          <div
                                              class="column is-12-desktop is-12-tablet is-12-mobile txshadow reddish container_about"
                                              style="padding: 1rem;">
                                            <ul class="list-unstyled is-flex is-justify-content-center has-text-centered mt-5 mb-2">
                                              <li class="cow3" style="height: 90px;">
                                                <div class="content" style="width: 150px; margin-left: 40px;">
                                                  <h2>Pools</h2>
                                                  <h2 style="margin-top: 0px !important;">Pools</h2>
                                                </div>
                                              </li>
                                            </ul>
                                            <b-table style="width:100%" :data="DAO_POOLS">

                                              <b-table-column centered label="Name" width="300" v-slot="props">
                                                {{ props.row.name }}
                                              </b-table-column>

                                              <b-table-column centered label="Account" v-slot="props">
                                                {{ props.row.account }}
                                              </b-table-column>

                                              <b-table-column centered label="Token" v-slot="props">
                                                {{ coinName( props.row.pool_token.refName.namespace, props.row.pool_token.refName.name) }}
                                              </b-table-column>

                                              <b-table-column centered label="Balance" v-slot="props">
                                                {{ props.row.balance }}
                                              </b-table-column>


                                            </b-table>
                                          </div>
                                        </div>
                                      </div>
                                    </section>

                                    <section v-if="activeTab2 === '2'">
                                      <div class="container animated fadeInUp">
                                        <div class="columns is-multiline is-mobile ">
                                          <div
                                              class="column is-12-desktop is-12-tablet is-12-mobile txshadow reddish container_about"
                                              style="padding: 1rem;">
                                            <ul class="list-unstyled is-flex is-justify-content-center has-text-centered mt-5 mb-2">
                                              <li class="cow3" style="height: 90px;">
                                                <div class="content" style="width: 215px; margin-left: 40px;">
                                                  <h2>Accounts</h2>
                                                  <h2 style="margin-top: 0px !important;">Accounts</h2>
                                                </div>
                                              </li>
                                            </ul>

                                            <div v-for="p in DAO_CONTACTS" :key="p.name"
                                                 class="container animated fadeInUp">
                                              <div class="columns is-multiline is-mobile ">
                                                <div
                                                    class="column is-6-desktop is-12-tablet is-12-mobile txshadow reddish"
                                                    style="padding: 0.2rem; padding-top: 0.5rem; border-right: none;">
                                                  <ul class="list-unstyled has-text-center">
                                                    <li class="cow5" style="font-size: 12px; color: #b83838 !important;">
                                                      {{ p.name }}
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div
                                                    class="column is-6-desktop is-12-tablet is-12-mobile txshadow reddish"
                                                    style="padding: 0.2rem; padding-top: 0.5rem; border-left: none;">
                                                  <ul class="list-unstyled has-text-center">
                                                    <li class="cow" style="font-size: 12px; color: #b83838 !important;">
                                                      <span class="cow5" style="font-size: 12px;">{{ p.account }}</span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                              <div class="divider"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>

                                    <section v-if="activeTab2 === '3'">
                                      <div class="container animated fadeInUp">
                                        <div class="columns is-multiline is-mobile ">
                                          <div
                                              class="column is-12-desktop is-12-tablet is-12-mobile txshadow reddish container_about"
                                              style="padding: 1rem;">
                                            <ul class="list-unstyled is-flex is-justify-content-center has-text-centered mt-5 mb-2">
                                              <li class="cow3" style="height: 90px;">
                                                <div class="content" style="width: 185px; margin-left: 40px;">
                                                  <h2>Members</h2>
                                                  <h2 style="margin-top: 0px !important;">Members</h2>
                                                </div>
                                              </li>
                                            </ul>

                                            <div v-for="p in DAO_MEMBERS" :key="p.name"
                                                 class="container animated fadeInUp">
                                              <div class="columns is-multiline is-mobile ">
                                                <div
                                                    class="column is-5-desktop is-12-tablet is-12-mobile txshadow reddish"
                                                    style="padding: 0.2rem; padding-top: 0.5rem; border-right: none;">
                                                  <ul class="list-unstyled has-text-center">
                                                    <li class="cow" style="font-size: 12px; color: #b83838 !important;">
                                                      {{ p.account_id }}
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div
                                                    class="column is-5-desktop is-12-tablet is-12-mobile txshadow reddish"
                                                    style="padding: 0.2rem; padding-top: 0.5rem; border-left: none;">
                                                  <ul class="list-unstyled has-text-center">
                                                    <li v-for="l in p.identifiers" :key="l.identifier" class="cow" style="font-size: 12px; color: #b83838 !important;">
                                                      <span class="cow2" style="font-size: 12px;">{{ l.name }} - {{l.identifier}}</span>
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div
                                                    class="column is-2-desktop is-12-tablet is-12-mobile txshadow reddish"
                                                    style="padding: 0.2rem; padding-top: 0.5rem; border-left: none;">
                                                  <ul class="list-unstyled has-text-center">
                                                    <li v-if="p.is_creator === true" class="cow5" style="font-size: 12px; color: #b83838 !important;">
                                                      Admin
                                                    </li>
                                                    <li v-if="p.is_treasurer === true && p.is_creator === false" class="cow5" style="font-size: 12px; color: #b83838 !important;">
                                                      Treasurer
                                                    </li>
                                                    <li v-if="p.is_treasurer === false && p.is_moderator === true" class="cow5" style="font-size: 12px; color: #b83838 !important;">
                                                      Moderator
                                                    </li>
                                                    <li v-if="p.is_treasurer === false && p.is_moderator === false && p.is_creator === false" class="cow5" style="font-size: 12px; color: #b83838 !important;">
                                                      Member
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                              <div class="divider"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>


                                    <div class="container animated fadeInUp"
                                         style="box-shadow: rgba(0, 0, 0, 0.2) 0px 13px 40px -7px; margin-top: 10px; z-index:0">
                                      <div class="columns is-multiline is-mobile"
                                           style=" border-bottom-right-radius: 2rem; border-bottom-left-radius: 2rem;">
                                        <div
                                            class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                            style="height: 80px; border-bottom-right-radius: 2rem; border-bottom-left-radius: 2rem; box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;">
                                          <div class="columns is-flex is-justify-content-center">
                                            <div v-if="activeTab2 === '1'" class="column is-flex is-justify-content-center" :class="is_moderator === true && activeTab2 === '1' || is_treasurer === true && activeTab2 === '1'  || is_creator === true && activeTab2 === '1' ? 'is-6' : 'is-12'"
                                                 style="padding-bottom: 15px;">
                                              <b-button @click="clickShowDepositModal()" type="is-darkdao"
                                                        style="color: #8ce8e3; font-family: 'Source Code Pro', monospace; width: 100%">
                                                DEPOSIT
                                              </b-button>
                                            </div>
                                            <div v-if="is_moderator === true && activeTab2 === '1' || is_treasurer === true && activeTab2 === '1'  || is_creator === true && activeTab2 === '1' " class="column is-flex is-justify-content-center is-6"
                                                 style="padding-bottom: 15px;">
                                              <b-button @click="clickShowWithdrawModal()" type="is-darkdao"
                                                        style="color: #8ce8e3; font-family: 'Source Code Pro', monospace; width: 100%">
                                                WITHDRAW
                                              </b-button>
                                            </div>

                                            <div class="column">
                                              <div v-if="activeTab2 === '0'">
                                                <nav class="pagination-outer" aria-label="Page navigation">
                                                  <ul class="pagination">
                                                    <li class="page-item">
                                                      <a @click="pageMSGS !== 1 ? [pageMSGS--,clickShowPageLP(pageMSGS)] : true;"
                                                         href="#" class="page-link" aria-label="Previous">
                                                        <span aria-hidden="true">«</span>
                                                      </a>
                                                    </li>
                                                    <li v-for="pageNumber in pagesMSGS.slice(pageMSGS-1, pageMSGS+5)"
                                                        :key="pageNumber" class="page-item">
                                                      <a type="button" class="page-link"
                                                         @click="pageMSGS = pageNumber;  clickShowPageLP(pageMSGS); ">
                                                        {{ pageNumber }}
                                                      </a>
                                                    </li>
                                                    <li class="page-item">
                                                      <a @click="pageMSGS < pagesMSGS.length ? [pageMSGS++, clickShowPageLP(pageMSGS)] : true;"
                                                         href="#" class="page-link" aria-label="Next">
                                                        <span aria-hidden="true">»</span>
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </nav>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>


                              </section>

                              <section v-if="DAO_PROPOSITIONS_ACTIVE.length > 0" class="hero" style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns">

                                          <div class="column is-flex is-flex-direction-column is-justify-content-center"
                                               style="height: 80px;">
                                            <span class="cow"
                                                  style="font-size: 15px;">ACTIVE PROPOSALS: {{ DAO_PROPOSITIONS_ACTIVE.length }}</span>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <section v-if="DAO_PROPOSITIONS_ACTIVE.length > 0" style="margin-top: -10px;">
                                <VotePoolCard v-for="p in DAO_PROPOSITIONS_ACTIVE" :key="p.pool_id"
                                @showCreateAccountModal="clickCreateAccount()"
                                              :accountApproved="has_account"
                                              :pool_treasury_account="p.pool_account"
                                              :pool_name="p.name"
                                              :stake_balance="p.stake_balance"
                                              @showTxModal="showTxModalEvent()"
                                              :pool_id="p.pool_id"
                                              :pool_end_time="p.end_time"
                                              :pool_start_time="p.start_time"
                                              :pool_required_votes="p.required_votes ? p.required_votes : 0"
                                              :pool_vote_token="p.vote_token"
                                              :pool_description="p.description"
                                              :pool_for_balance="p.for_balance"
                                              :stake_final_vote_balance="0"
                                              :pool_against_balance="p.against_balance"
                                              :pool_for_voters="p.for_voters"
                                              :pool_against_voters="p.against_voters"></VotePoolCard>
                              </section>

                              <section v-if="DAO_PROPOSITIONS_HISTORY.length > 0" class="hero"
                                       style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns">

                                          <div class="column is-flex is-flex-direction-column is-justify-content-center"
                                               style="height: 80px;">
                                            <span class="cow"
                                                  style="font-size: 15px;">PROPOSAL HISTORY: {{ DAO_PROPOSITIONS_HISTORY.length }}</span>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <section v-if="DAO_PROPOSITIONS_HISTORY.length > 0" style="margin-top: -10px;">
                                <VotePoolCard v-for="p in DAO_PROPOSITIONS_HISTORY" :key="p.pool_id"
                                              :pool_treasury_account="p.pool_account"
                                              @showTxModal="showTxModalEvent()"
                                              @showCreateAccountModal="clickCreateAccount()"
                                              :accountApproved="has_account"
                                              :pool_name="p.name"
                                              :pool_id="p.pool_id"
                                              :stake_balance="p.stake_balance"
                                              :pool_end_time="p.end_time"
                                              :pool_start_time="p.start_time"
                                              :pool_vote_token="p.vote_token"
                                              :pool_description="p.description"
                                              :pool_for_balance="p.for_balance"
                                              :stake_final_vote_balance="0"
                                              :pool_against_balance="p.against_balance"
                                              :pool_for_voters="p.for_voters"
                                              :pool_against_voters="p.against_voters"></VotePoolCard>
                              </section>

                              <section v-if="DAO_TASKS_PENDING.length > 0" class="hero"
                                       style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns">

                                          <div class="column is-flex is-flex-direction-column is-justify-content-center"
                                               style="height: 80px;">
                                            <span class="cow"
                                                  style="font-size: 15px;">ACTIVE TASKS: {{DAO_TASKS_PENDING.length}}</span>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <section style="margin-top: -10px;">
                                <TaskCard v-for="p in DAO_TASKS_PENDING" :key="p.task_id"
                                          @showTxModal="showTxModalEvent()"
                                          @showCreateAccountModal="clickCreateAccount()"
                                          :accountApproved="has_account"
                                          :task_id="p.task_id"
                                          :pool_subject_id="p.subject_id"
                                          :pool_name="p.name"
                                          :pool_completed="p.completed"
                                          :pool_verified="p.verified"
                                          :pool_verified_by="p.verified_by"
                                          :pool_description="p.description"
                                          :pool_messages="p.messages"
                                          :is_creator="DAO_ACCOUNT_INFO.is_creator"
                                          :is_moderator="DAO_ACCOUNT_INFO.is_moderator"
                                          :is_treasurer="DAO_ACCOUNT_INFO.is_treasurer"
                                          :pool_required_verifiers="DAO_SUBJECT_INFO.required_verifiers"
                                          :pool_pay_account="p.pay_account"
                                          :pool_pay_amount="p.pay_amount"
                                          :pool_pay_token="p.pay_token"
                                          :pool_time_created="p.time_created"
                                          :pool_due_date="p.due_date"
                                          :pool_time_completed="p.time_completed"
                                          :pool_time_verified="p.time_verified"
                                          :pool_active="p.active"></TaskCard>
                              </section>

                              <section v-if="DAO_TASKS_COMPLETED.length > 0" class="hero"
                                       style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns">

                                          <div class="column is-flex is-flex-direction-column is-justify-content-center"
                                               style="height: 80px;">
                                            <span class="cow"
                                                  style="font-size: 15px;">COMPLETED TASKS: {{DAO_TASKS_COMPLETED.length}}</span>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <section style="margin-top: -10px;">
                                <TaskCard v-for="p in DAO_TASKS_COMPLETED" :key="p.task_id"
                                          @showTxModal="showTxModalEvent()"
                                          @showCreateAccountModal="clickCreateAccount()"
                                          :accountApproved="has_account"
                                          :task_id="p.task_id"
                                          :pool_subject_id="p.subject_id"
                                          :pool_name="p.name"
                                          :pool_completed="p.completed"
                                          :pool_verified="p.verified"
                                          :pool_verified_by="p.verified_by"
                                          :pool_description="p.description"
                                          :pool_messages="p.messages"
                                          :is_creator="DAO_ACCOUNT_INFO.is_creator"
                                          :is_moderator="DAO_ACCOUNT_INFO.is_moderator"
                                          :is_treasurer="DAO_ACCOUNT_INFO.is_treasurer"
                                          :pool_required_verifiers="DAO_SUBJECT_INFO.required_verifiers"
                                          :pool_pay_account="p.pay_account"
                                          :pool_pay_amount="p.pay_amount"
                                          :pool_pay_token="p.pay_token"
                                          :pool_time_created="p.time_created"
                                          :pool_due_date="p.due_date"
                                          :pool_time_completed="p.time_completed"
                                          :pool_time_verified="p.time_verified"
                                          :pool_active="p.active"></TaskCard>
                              </section>


                            </section>

                            <!-- ADMIN SECTION -->
                            <section v-if="activeTab === '2'" style="margin-top: -45px;">

                              <!--                              POST MESSAGE-->
                              <section v-if="is_moderator === true || is_treasurer === true || is_creator === true" class="hero" style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns is-flex-direction-column is-justify-content-center">

                                          <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                                               style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                                            <span style="color: #32445e !important;"><b-icon pack="fas" icon="pencil-square" size="is-large"></b-icon></span>
                                            <span class="cow" style="font-size: 20px;">POST A NEW MESSAGE TO DAO</span>
                                          </div>

                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="What is the title of your new message?">
                                                  <label class="label pt-1 cow">Title of Message:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">

                                                  <p class="control is-expanded">
                                                    <input v-model="admin_new_message_title"
                                                           class="input select" type="text"
                                                           placeholder="My new message">
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>

                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="New message to post to the DAO">
                                                  <label class="label pt-1 cow">Message:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">

                                                  <p class="control is-expanded">
                                                    <b-input v-model="admin_new_message" maxlength="400" style="background-color: rgba(103, 103, 108, 0.1) !important; border: none; color: #4ae39e; box-shadow: inset 0 30px 60px -12px rgb(50 50 93 / 25%), inset 0 18px 36px -18px rgb(0 0 0 / 30%);" type="textarea"></b-input>
                                                  </p>
                                                  <p class="control">
                                                    <b-button @click="clickAdminPostNewMessage()" class="button is-primary">POST MESSAGE</b-button>
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>

                                          <div class="column is-12">
                                            <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                                              <p class="cow2 pt-2" style="font-size: 13px;">About New Messages:</p>
                                              <div class="divider" style="margin-top: -40px;"></div>
                                              <ul class="signature-content has-text-left">
                                                <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Use this tool to post a message under the DAO's 'Latest News' area.</li>
                                              </ul>
                                            </div>
                                          </div>


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

<!--                              PERMISSIONS SECTION-->
                              <section v-if="is_creator === true" class="hero" style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns is-flex-direction-column is-justify-content-center">

                                          <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                                               style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                                            <span style="color: #32445e !important;"><b-icon pack="fas" icon="user-plus" size="is-large"></b-icon></span>
                                            <span class="cow" style="font-size: 20px;">GRANT AN ACCOUNT SPECIAL DAO PERMISSIONS</span>
                                          </div>

                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="This is the token stakers are rewarded with as they stake tokens in your pool.">
                                                  <label class="label pt-1 cow">Permission:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">
                                                  <p class="control">
                                                      <span class="select">
                                                        <select v-model="admin_accountPermission">
                                                                  <option value="1">None</option>
                                                                  <option value="2">Moderator</option>
                                                                  <option value="3">Treasurer</option>
                                                                  <option value="4">Creator</option>
                                                        </select>
                                                      </span>
                                                  </p>
                                                  <p class="control is-expanded">
                                                    <input v-model="admin_accountToEdit"
                                                           class="input select" type="text"
                                                           placeholder="k:account">
                                                    <span class="label cow" style="font-size: 12px;">Account</span>

                                                  </p>
                                                  <p class="control">
                                                    <b-button @click="clickGrantNewPermissions()" class="button is-primary">GRANT</b-button>
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>

                                          <div class="column is-12">
                                            <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                                              <p class="cow2 pt-2" style="font-size: 13px;">About DAO Permissions:</p>
                                              <div class="divider" style="margin-top: -40px;"></div>
                                              <ul class="signature-content has-text-left">
                                                <li><i><b-icon pack="fas" icon="user-times" size="is-small"></b-icon></i><span style="font-weight: bold; color: #874bd5">None:</span> Remove all Special DAO Permissions from a DAO member account. </li>
                                                <li><i><b-icon pack="fas" icon="gavel" size="is-small"></b-icon></i><span style="font-weight: bold; color: #874bd5">Moderator:</span> A Moderator account can post messages and ban proposals. </li>
                                                <li><i><b-icon pack="fas" icon="balance-scale" size="is-small"></b-icon></i><span style="font-weight: bold; color: #874bd5">Treasurer:</span> Treasurers can do everything a Moderator can do, plus create and edit tasks and make DAO pools and payments.</li>
                                                <li><i><b-icon pack="fas" icon="wrench" size="is-small"></b-icon></i><span style="font-weight: bold; color: #874bd5">Creator:</span> Creator accounts can do everything Moderators and Treasurers can do, plus edit the DAOs rules and Hive as well as grant DAO permissions.</li>
                                              </ul>
                                            </div>
                                          </div>


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <!--                              CREATE TREASURY POOL-->
                              <section v-if="is_treasurer === true || is_creator === true" class="hero" style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns is-flex-direction-column is-justify-content-center">

                                          <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                                               style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                                            <span style="color: #32445e !important;"><b-icon pack="fas" icon="institution" size="is-large"></b-icon></span>
                                            <span class="cow" style="font-size: 20px;">CREATE A NEW DAO TREASURY POOL</span>
                                          </div>

                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="What is the Namespace of the token this pool will manage?">
                                                  <label class="label pt-1 cow">Token Namespace:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">

                                                  <p class="control is-expanded">
                                                    <input v-model="admin_namespace"
                                                           class="input select" type="text"
                                                           placeholder="My new message">
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>

                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="What is the Module Name of the Token this pool will manage?">
                                                  <label class="label pt-1 cow">Token Module:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">

                                                  <p class="control is-expanded">
                                                    <input v-model="admin_modulename"
                                                           class="input select" type="text"
                                                           placeholder="My new message">
                                                  </p>
                                                  <p class="control">
                                                    <b-button @click="clickCreateDAOTreasuryPool()" class="button is-primary">CREATE TREASURY POOL</b-button>
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>

                                          <div class="column is-12">
                                            <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                                              <p class="cow2 pt-2" style="font-size: 13px;">About Creating Treasury Pools:</p>
                                              <div class="divider" style="margin-top: -40px;"></div>
                                              <ul class="signature-content has-text-left">
                                                <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Use this tool to create a new DAO Pool which will hold a specific token for the DAO.</li>
                                                <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>DAO Pools appear in the 'Pools' section on a DAO's Hive and are managed by the DAO's treasury members.</li>
                                                <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Each DAO Pool can only manage 1 token type, and a DAO cannot make multiple pools of the same token type.</li>
                                              </ul>
                                            </div>
                                          </div>


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <!--                              WITHDRAWS SECTION-->
                              <section v-if="is_treasurer === true || is_creator === true" class="hero" style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns is-flex-direction-column is-justify-content-center">

                                          <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                                               style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                                            <span style="color: #32445e !important;"><b-icon pack="fas" icon="balance-scale" size="is-large"></b-icon></span>
                                            <span class="cow" style="font-size: 20px;">WITHDRAW FUNDS FROM A DAO TREASURY POOL</span>
                                          </div>


                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="Which pool will you be withdrawing funds from?">
                                                  <label class="label pt-1 cow">Withdraw from Pool:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">
                                                  <p class="control is-expanded">
                                                      <span class="select" style="width: 100%">
                                                        <b-select expanded v-model="admin_PoolToWithdraw">
                                                                  <option v-for="p in DAO_SUBJECT_INFO.pools" :key="p.account" :value="{ 'account': p.account, 'token': p.pool_token}">{{p.account}}</option>
                                                        </b-select>
                                                      </span>
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="This is the token type this pool holds">
                                                  <label class="label pt-1 cow">Pool Token:</label>
                                                </b-tooltip>
                                              </template>
                                              <b-input v-model="admin_pooltoken" placeholder="Disabled" disabled></b-input>
                                            </b-field>
                                          </div>

                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="Reason for withdrawing from the DAO today?">
                                                  <label class="label pt-1 cow">Reason for Withdraw:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">

                                                  <p class="control is-expanded">
                                                    <b-input v-model="admin_reasonToWithdraw" maxlength="400" style="background-color: rgba(103, 103, 108, 0.1) !important; border: none; color: #4ae39e; box-shadow: inset 0 30px 60px -12px rgb(50 50 93 / 25%), inset 0 18px 36px -18px rgb(0 0 0 / 30%);" type="textarea"></b-input>
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>

                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="What account with receive the tokens you are withdrawing?">
                                                  <label class="label pt-1 cow">Withdraw to Account:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">

                                                  <p class="control is-expanded">
                                                    <input v-model="admin_accountToWithdraw"
                                                           class="input select" type="text"
                                                           placeholder="k:account">
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>

                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="How much will you be withdrawing from the DAO?">
                                                  <label class="label pt-1 cow">Withdraw Amount:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">

                                                  <p class="control is-expanded">
                                                    <input v-model="admin_amountToWithdraw"
                                                           class="input select" type="number"
                                                           placeholder="k:account">
                                                  </p>
                                                  <p class="control">
                                                    <b-button @click="clickWithdrawTreasury()" class="button is-primary">WITHDRAW</b-button>
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>

                                          <div class="column is-12">
                                            <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                                              <p class="cow2 pt-2" style="font-size: 13px;">About Withdraws:</p>
                                              <div class="divider" style="margin-top: -40px;"></div>
                                              <ul class="signature-content has-text-left">
                                                <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Use this tool to withdraw funds from a DAO pool.</li>
                                              </ul>
                                            </div>
                                          </div>


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <!--                              DEPOSITS SECTION-->
                              <section v-if="is_treasurer === true || is_creator === true || is_moderator === true" class="hero" style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns is-flex-direction-column is-justify-content-center">

                                          <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                                               style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                                            <span style="color: #32445e !important;"><b-icon pack="fas" icon="balance-scale" size="is-large"></b-icon></span>
                                            <span class="cow" style="font-size: 20px;">DEPOSIT FUNDS INTO A DAO TREASURY POOL</span>
                                          </div>


                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="Which DAO pool will you be depositing into?">
                                                  <label class="label pt-1 cow">Deposit into Pool:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">
                                                  <p class="control is-expanded">
                                                      <span class="select" style="width: 100%">
                                                        <b-select expanded v-model="admin_depositPool">
                                                                  <option v-for="p in DAO_SUBJECT_INFO.pools" :key="p.account" :value="{ 'account': p.account, 'token': p.pool_token}">{{p.account}}</option>
                                                        </b-select>
                                                      </span>
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="This is the token this pool accepts deposits from">
                                                  <label class="label pt-1 cow">Pool Token:</label>
                                                </b-tooltip>
                                              </template>
                                              <b-input v-model="admin_depositpooltoken" placeholder="Disabled" disabled></b-input>
                                            </b-field>
                                          </div>

                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="Reason for depositing into the DAO today?">
                                                  <label class="label pt-1 cow">Reason for Deposit:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">

                                                  <p class="control is-expanded">
                                                    <b-input v-model="admin_reasonToDeposit" maxlength="400" style="background-color: rgba(103, 103, 108, 0.1) !important; border: none; color: #4ae39e; box-shadow: inset 0 30px 60px -12px rgb(50 50 93 / 25%), inset 0 18px 36px -18px rgb(0 0 0 / 30%);" type="textarea"></b-input>
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>


                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="How much will you be depositing into the DAO?">
                                                  <label class="label pt-1 cow">Deposit Amount:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">

                                                  <p class="control is-expanded">
                                                    <input v-model="admin_amountToDeposit"
                                                           class="input select" type="number"
                                                           placeholder="k:account">
                                                  </p>
                                                  <p class="control">
                                                    <b-button @click="clickDepositTreasury()" class="button is-primary">DEPOSIT</b-button>
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>

                                          <div class="column is-12">
                                            <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                                              <p class="cow2 pt-2" style="font-size: 13px;">About Deposits:</p>
                                              <div class="divider" style="margin-top: -40px;"></div>
                                              <ul class="signature-content has-text-left">
                                                <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Use this tool to deposit funds into a DAO pool.</li>
                                              </ul>
                                            </div>
                                          </div>


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <!--                              CAN CREATE PROPOSALS SECTION-->
                              <section v-if="is_creator === true" class="hero" style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns is-flex-direction-column is-justify-content-center">

                                          <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                                               style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                                            <span style="color: #32445e !important;"><b-icon pack="fas" icon="times-rectangle" size="is-large"></b-icon></span>
                                            <span class="cow" style="font-size: 20px;">WHO CAN MAKE PROPOSALS?</span>
                                          </div>

                                          <div class="container mt-5">
                                            <b-field>
                                              <b-switch
                                                  class="mb-5 mt-1"
                                                  v-model="admin_canMakeProposals"
                                                  :rounded="true"
                                                  :outlined="true"
                                                  size="is-large"
                                                  type="is-success"
                                                  :left-label='false'
                                                  :passive-type='null'></b-switch>
                                            </b-field>
                                          </div>

                                          <div class="columns">
                                            <div class="column">
                                              <span class="cow2">{{ admin_canMakeProposals ? "ONLY CREATORS CAN MAKE PROPOSALS" : "ANYONE CAN MAKE PROPOSALS" }}</span>
                                            </div>
                                          </div>

                                          <div class="columns">
                                            <div class="column">
                                              <b-button @click="clickSetWhoCanMakeProposals()" class="button is-primary">SET WHO CAN MAKE PROPOSALS</b-button>
                                            </div>
                                          </div>

                                          <div class="column is-12">
                                            <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                                              <p class="cow2 pt-2" style="font-size: 13px;">About Proposal Creation:</p>
                                              <div class="divider" style="margin-top: -40px;"></div>
                                              <ul class="signature-content has-text-left">
                                                <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Use this tool to set who can create proposals at this DAO.</li>
                                              </ul>
                                            </div>
                                          </div>


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <!--                              PROPOSAL COST SECTION-->
                              <section v-if="is_creator === true" class="hero" style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns is-flex-direction-column is-justify-content-center">

                                          <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                                               style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                                            <span style="color: #32445e !important;"><b-icon pack="fas" icon="institution" size="is-large"></b-icon></span>
                                            <span class="cow" style="font-size: 20px;">SET NEW PROPOSAL COST</span>
                                          </div>


                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="What is the cost to create proposals?">
                                                  <label class="label pt-1 cow">Proposal Cost:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">

                                                  <p class="control is-expanded">
                                                    <input v-model="admin_proposal_amount"
                                                           class="input select" type="number"
                                                           placeholder="k:account">
                                                  </p>
                                                  <p class="control">
                                                    <b-button @click="clickSetProposalCost()" class="button is-primary">SET PROPOSAL COST</b-button>
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>

                                          <div class="column is-12">
                                            <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                                              <p class="cow2 pt-2" style="font-size: 13px;">About Proposal Costs:</p>
                                              <div class="divider" style="margin-top: -40px;"></div>
                                              <ul class="signature-content has-text-left">
                                                <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Use this tool to set the cost of creating new proposals at the DAO.</li>
                                                <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Proposal payments are deposited into one of the DAOs treasury pools.</li>
                                              </ul>
                                            </div>
                                          </div>


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <!--                              BAN PROPOSAL SECTION-->
                              <section v-if="is_moderator === true || is_treasurer === true || is_creator === true" class="hero" style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns is-flex-direction-column is-justify-content-center">

                                          <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                                               style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                                            <span style="color: #32445e !important;"><b-icon pack="fas" icon="times-rectangle" size="is-large"></b-icon></span>
                                            <span class="cow" style="font-size: 20px;">DEACTIVATE PROPOSAL</span>
                                          </div>


                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="Reason for deactivating proposal?">
                                                  <label class="label pt-1 cow">Reason/Message:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">

                                                  <p class="control is-expanded">
                                                    <b-input v-model="admin_reasonToBan" maxlength="400" style="background-color: rgba(103, 103, 108, 0.1) !important; border: none; color: #4ae39e; box-shadow: inset 0 30px 60px -12px rgb(50 50 93 / 25%), inset 0 18px 36px -18px rgb(0 0 0 / 30%);" type="textarea"></b-input>
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>

                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="This is the token stakers are rewarded with as they stake tokens in your pool.">
                                                  <label class="label pt-1 cow">Proposal ID:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">

                                                  <p class="control is-expanded">
                                                    <input v-model="admin_proposalToDeactivate"
                                                           class="input select" type="text"
                                                           placeholder="k:account">
                                                  </p>
                                                  <p class="control">
                                                    <b-button @click="clickDeactivateProposal()" class="button is-primary">DEACTIVATE</b-button>
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>

                                          <div class="column is-12">
                                            <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                                              <p class="cow2 pt-2" style="font-size: 13px;">About Proposal Deactivation:</p>
                                              <div class="divider" style="margin-top: -40px;"></div>
                                              <ul class="signature-content has-text-left">
                                                <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Use this tool to deactivate / ban proposals that violate DAO terms and conditions.</li>
                                              </ul>
                                            </div>
                                          </div>


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <!--                              EDIT ABOUT MESSAGE-->
                              <section v-if="is_creator === true" class="hero" style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns is-flex-direction-column is-justify-content-center">

                                          <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                                               style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                                            <span style="color: #32445e !important;"><b-icon pack="fas" icon="pencil-square" size="is-large"></b-icon></span>
                                            <span class="cow" style="font-size: 20px;">EDIT DAO ABOUT MESSAGE</span>
                                          </div>


                                          <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                                            <b-field custom-class="is-small" horizontal>
                                              <template #label>
                                                <b-tooltip type="is-dark"
                                                           label="What would you like to change the DAO's about message to?">
                                                  <label class="label pt-1 cow">New About Message:</label>
                                                </b-tooltip>
                                              </template>
                                              <div class="field-body">
                                                <div class="field has-addons">

                                                  <p class="control is-expanded">
                                                    <b-input v-model="admin_newAboutMessage" maxlength="400" style="background-color: rgba(103, 103, 108, 0.1) !important; border: none; color: #4ae39e; box-shadow: inset 0 30px 60px -12px rgb(50 50 93 / 25%), inset 0 18px 36px -18px rgb(0 0 0 / 30%);" type="textarea"></b-input>
                                                  </p>
                                                  <p class="control">
                                                    <b-button @click="clickEditAboutMessage()" class="button is-primary">CHANGE ABOUT MESSAGE</b-button>
                                                  </p>

                                                </div>
                                              </div>
                                            </b-field>
                                          </div>

                                          <div class="column is-12">
                                            <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                                              <p class="cow2 pt-2" style="font-size: 13px;">About New Messages:</p>
                                              <div class="divider" style="margin-top: -40px;"></div>
                                              <ul class="signature-content has-text-left">
                                                <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Use this tool to post a message under the DAO's 'About' section.</li>
                                              </ul>
                                            </div>
                                          </div>


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <!--                              EDIT HUB DESCRIPTION-->
                              <section v-if="is_creator === true" class="hero" style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns is-flex-direction-column is-justify-content-center">

                                          <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                                               style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                                            <span style="color: #32445e !important;"><b-icon pack="fas" icon="pencil-square" size="is-large"></b-icon></span>
                                            <span class="cow" style="font-size: 20px;">EDIT HIVE DESCRIPTION</span>
                                          </div>


                                          <div class="columns mt-6">
                                            <div class="column mt-4 is-offset-1 is-6 is-flex is-flex-direction-column is-justify-content-center">
                                              <b-field custom-class="is-small" horizontal>
                                                <template #label>
                                                  <b-tooltip type="is-dark"
                                                             label="What would you like to change the Hive's description to?">
                                                    <label class="label pt-1 cow">New Hive Description:</label>
                                                  </b-tooltip>
                                                </template>
                                                <div class="field-body">
                                                  <div class="field has-addons">

                                                    <p class="control is-expanded">
                                                      <b-input v-model="admin_daoDescription" maxlength="75" style="background-color: rgba(103, 103, 108, 0.1) !important; border: none; color: #4ae39e; box-shadow: inset 0 30px 60px -12px rgb(50 50 93 / 25%), inset 0 18px 36px -18px rgb(0 0 0 / 30%);" type="textarea"></b-input>
                                                    </p>


                                                  </div>
                                                </div>
                                              </b-field>
                                              <b-button @click="clickEditDaoHiveDescription()" class="button is-primary">CHANGE HIVE DESCRIPTION</b-button>
                                            </div>

                                            <div class="column is-flex is-justify-content-center">

                                              <div class="columns is-flex-direction-column is-justify-content-center" >
                                                <div class="column">
                                                  <DaoCardPreview :dao_id="this.poolid" :dao_name="DAO_SUBJECT_INFO.name" :dao_description="admin_daoDescription" :dao_image="DAO_SUBJECT_INFO.icon" ></DaoCardPreview>
                                                </div>
                                                <div class="column">
                                                  <p style="color: #4ad1e3 !important">DAO Card Preview</p>
                                                </div>
                                              </div>

                                            </div>
                                          </div>



                                          <div class="column is-12">
                                            <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                                              <p class="cow2 pt-2" style="font-size: 13px;">About Hive Descriptions:</p>
                                              <div class="divider" style="margin-top: -40px;"></div>
                                              <ul class="signature-content has-text-left">
                                                <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Use this tool to change this Hive's description.</li>
                                                <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Hive descriptions are short sentences displayed on DAO cards.</li>
                                              </ul>
                                            </div>
                                          </div>


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <!--                              EDIT HUB ICON-->
                              <section v-if="is_creator === true" class="hero" style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns is-flex-direction-column is-justify-content-center">

                                          <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                                               style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                                            <span style="color: #32445e !important;"><b-icon pack="fas" icon="image" size="is-large"></b-icon></span>
                                            <span class="cow" style="font-size: 20px;">EDIT HIVE ICON</span>
                                          </div>


                                          <div class="columns mt-6">
                                            <div class="column mt-4 is-offset-1 is-6 is-flex is-flex-direction-column is-justify-content-center">
                                              <b-field custom-class="is-small" horizontal>
                                                <template #label>
                                                  <b-tooltip type="is-dark"
                                                             label="What would you like to change the Hive's icon to?">
                                                    <label class="label pt-1 cow">Link to New Icon:</label>
                                                  </b-tooltip>
                                                </template>
                                                <div class="field-body">
                                                  <div class="field has-addons">

                                                    <p class="control is-expanded">
                                                      <input v-model="admin_iconLink"
                                                             class="input select" type="text"
                                                             placeholder="http-link-to-icon">
                                                    </p>


                                                  </div>
                                                </div>
                                              </b-field>
                                              <b-button @click="clickEditDaoHiveIcon()" class="button is-primary">CHANGE HIVE ICON</b-button>
                                            </div>

                                            <div class="column is-flex is-justify-content-center">

                                              <div class="columns is-flex-direction-column is-justify-content-center" >
                                                <div class="column">
                                                  <DaoCardPreview :dao_id="this.poolid" :dao_name="DAO_SUBJECT_INFO.name" :dao_description="DAO_SUBJECT_INFO.description" :dao_image="admin_iconLink" ></DaoCardPreview>
                                                </div>
                                                <div class="column">
                                                  <p style="color: #4ad1e3 !important">DAO Card Preview</p>
                                                </div>
                                              </div>

                                            </div>
                                          </div>



                                          <div class="column is-12">
                                            <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                                              <p class="cow2 pt-2" style="font-size: 13px;">About Hive Icons:</p>
                                              <div class="divider" style="margin-top: -40px;"></div>
                                              <ul class="signature-content has-text-left">
                                                <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Use this tool to change this DAO's Icon.</li>
                                              </ul>
                                            </div>
                                          </div>


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <!--                              EDIT HUB LINKS-->
                              <section v-if="is_creator === true" class="hero" style="margin-top: 20px;">
                                <div class="hero-body">
                                  <div class="container animated fadeInUp"
                                       style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
                                    <div class="columns is-multiline is-mobile"
                                         style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                                      <div
                                          class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                                          style="border-radius: 2rem;">
                                        <div class="columns is-flex-direction-column is-justify-content-center">

                                          <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                                               style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                                            <span style="color: #32445e !important;"><b-icon pack="fas" icon="list-ul" size="is-large"></b-icon></span>
                                            <span class="cow" style="font-size: 20px;">EDIT HIVE LINKS</span>
                                          </div>

                                          <div class="columns" style="margin-top: 25px">
                                            <div class="column is-offset-1 is-5">
                                              <p style="color: #4ae39e !important">Add important Links to display on your DAO page</p>

                                              <br/>
                                              <div class="divider is-centered"></div>
                                              <br/>

                                              <b-field
                                                  custom-class="is-small"
                                                  horizontal>
                                                <template #label>
                                                  <b-tooltip type="is-dark" multilined
                                                             label="Enter the link title to display on the front of your new button.">
                                                    <label class="label pt-1 mt-2" style="width:50px;">Link Title:</label>
                                                  </b-tooltip>
                                                </template>
                                                <div class="field-body">
                                                  <div class="field has-addons">
                                                    <p class="control is-expanded">
                                                      <input v-model="admin_newlinktitle"
                                                             @input="determineNewDaoLinkTitle()"
                                                             class="input select"
                                                             type="text" placeholder="Forum">
                                                    </p>
                                                  </div>
                                                </div>
                                              </b-field>

                                              <b-field
                                                  custom-class="is-small"
                                                  horizontal>
                                                <template #label>
                                                  <b-tooltip type="is-dark" multilined
                                                             label="Enter actual link people are brought to when they click your button">
                                                    <label class="label pt-1 mt-2" style="width:50px;">Link:</label>
                                                  </b-tooltip>
                                                </template>
                                                <div class="field-body">
                                                  <div class="field has-addons">
                                                    <p class="control is-expanded">
                                                      <input v-model="admin_newlink"
                                                             class="input select"
                                                             type="text" placeholder="https://link-to-dao-forum.com">
                                                    </p>
                                                  </div>
                                                </div>
                                              </b-field>

                                              <b-field
                                                  style="margin-top: 20px;"
                                                  custom-class="is-small"
                                                  horizontal>
                                                <div class="field-body">
                                                  <div class="field has-addons">
                                                    <p class="control is-expanded">
                                                      <b-button @click="addNewDaoLink()" type="is-dark" style="background-color: #4c2d71 !important">Add New Link</b-button>
                                                    </p>
                                                    <p class="control is-expanded">
                                                      <b-button @click="clearDaoLinks()" type="is-dark" style="background-color: #712d2d !important">Clear Links</b-button>
                                                    </p>
                                                  </div>
                                                </div>
                                              </b-field>



                                            </div>
                                            <div class="column is-1"><br/></div>

                                            <div class="column is-4-desktop is-4-tablet is-12-mobile txshadow reddish container_links is-justify-content-center" style="padding: 1rem; border: 1px solid #3abb81; border-radius: 2rem;">
                                              <p style="color: #4ae39e !important">Links (Shown on DAO Page)</p>
                                              <b-button type="is-primary"  style="margin: 5px; color: #8ce8e3; font-family: 'Source Code Pro', monospace;" v-for="i in dao_links_final" :key="i.name">{{i.name}}</b-button>
                                            </div>
                                          </div>

                                          <div class="column is-offset-7 is-4">
                                            <b-button @click="clickEditDaoHiveLinks()" expanded class="button is-primary">CHANGE HIVE LINKS</b-button>
                                          </div>



                                          <div class="column is-12">
                                            <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                                              <p class="cow2 pt-2" style="font-size: 13px;">About Hive Links:</p>
                                              <div class="divider" style="margin-top: -40px;"></div>
                                              <ul class="signature-content has-text-left">
                                                <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Use this tool to add and remove DAO Hive Links.</li>
                                              </ul>
                                            </div>
                                          </div>


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>




                            </section>


                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

          <div style="margin-bottom: 10%"></div>

        </section>
      </div>
    </section>

    <!--Sign tx Modal-->
    <b-modal v-if="showTxModal === true" v-model="showTxModal" :width="640" scroll="keep"
             style="border-radius:20px;">

      <div class="form-bg" style="border-radius:20px;">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-md-offset-4 blue-bg">
              <div class="form-container form-shadow">
                <div class="form-icon"><img src="../assets/stake-factory-icon.svg" width="70px"></div>
                <div class="container pb-4">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader">
                      A new signature request was just sent to your wallet:
                    </h3>
                  </div>
                </div>
                <b-message class="is-success" style="background-color: rgb(9 40 52);">
                  <div class="columns is-centered pt-4 pb-0">
                    <div class="column is-centered" style="overflow-wrap: anywhere !important;">
                      {{ this.getCurrentSignRequest() }}
                    </div>
                  </div>
                </b-message>

                <div class="container mb-2">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader">
                      Review and sign the transaction in your wallet and click confirm below:
                    </h3>
                  </div>
                </div>

                <form class="form-horizontal">
                  <div class="columns is-mobile is-centered">
                    <div class="column">
                      <div class="notification has-text-centered inset-bg-rounded2" style="background-color: #2d3a45;">
                        <div @click="closeSignatureModal" class="btn2 btn-lg stake-btn"
                             style="border-radius: 1rem !important;">
                          <span>Confirm</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!--Create Account Modal-->
    <b-modal v-if="showCreateAccountModal === true" v-model="showCreateAccountModal" full-screen scroll="keep"
             style="border-radius:20px;">

      <div class="form-bg centered2" style="border-radius:20px;">
        <div class="container">
          <div class="columns">
            <div class="column blue-bg">
              <div class="form-container form-shadow">
                <div class="container pb-4">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader" style="font-size: 20px;">
                      Create a new account on this DAO with:
                    </h3>
                  </div>
                  <br/>
                  <div class="divider is-centered"></div>
                </div>
                <b-message class="is-success" style="background-color: rgb(9 40 52);">
                  <div class="columns is-centered pt-4 pb-0">
                    <div class="column is-centered" style="overflow-wrap: anywhere !important;">
                      {{ this.user_name }}
                    </div>
                  </div>
                </b-message>

                <div class="divider is-centered"></div>

                <div class="columns is-justify-content-center" style="margin-top: 45px">
                  <div class="column is-5" style="border-radius: 20px; box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; padding: 1rem;" >

                    <p style="padding: 1rem; border-radius: 20px; background-color: #253242; box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;">Add additional identifiers to be displayed under your account:</p>

                    <br/>
                    <div class="divider is-centered"></div>
                    <br/>

                    <b-field
                        custom-class="is-small"
                        horizontal>
                      <template #label>
                        <b-tooltip type="is-dark" multilined
                                   label="Enter the contact title to display next to the contact information.">
                          <label class="label pt-1" style="width:50px;"><span style="color: #4aabe3">Title:</span></label>
                        </b-tooltip>
                      </template>
                      <div class="field-body">
                        <div class="field has-addons">
                          <p class="control is-expanded">
                            <input v-model="newIDTitle"
                                   @input="determineNewIDTitle()"
                                   class="input select"
                                   type="text" placeholder="Email">
                          </p>
                        </div>
                      </div>
                    </b-field>

                    <b-field
                        custom-class="is-small"
                        horizontal>
                      <template #label>
                        <b-tooltip type="is-dark" multilined
                                   label="Enter actual contact information">
                          <label class="label pt-1 mt-2" style="width:50px;"><span style="color: #4aabe3">Identifier:</span></label>
                        </b-tooltip>
                      </template>
                      <div class="field-body">
                        <div class="field has-addons">
                          <p class="control is-expanded">
                            <input v-model="newID"
                                   @input="determineNewID()"
                                   class="input select"
                                   type="text" placeholder="dao_email@dao_email.com">
                          </p>
                        </div>
                      </div>
                    </b-field>

                    <b-field
                        style="padding-top: 10px;"
                        custom-class="is-small"
                        horizontal>
                      <div class="field-body">
                        <div class="field has-addons">
                          <div class="columns is-flex is-justify-content-center">
                            <div class="column">
                              <p class="control is-expanded">
                                <b-button @click="addNewDaoContact()" size="is-small" type="is-dark"
                                          style="background-color: #4c2d71 !important">Add ID
                                </b-button>
                              </p>
                            </div>
                            <div class="column">
                              <p class="control is-expanded">
                                <b-button @click="clearDaoContacts()" size="is-small" type="is-dark"
                                          style="background-color: #712d2d !important">Clear IDs
                                </b-button>
                              </p>
                            </div>
                          </div>


                        </div>
                      </div>
                    </b-field>


                  </div>
                  <div class="column is-1"></div>
                  <div class="column is-5 is-flex-direction-column reddish container_links is-justify-content-space-evenly"
                       style="display:flex; border: 1px solid #3abb81; border-radius: 2rem;">
                    <p v-if="dao_contacts.length === 0" style="color: #4ae39e !important">Add your own account identifiers here</p>

                    <span v-if="dao_contacts.length === 0" style="color: #32445e !important;"><b-icon pack="fas" icon="user" size="is-large"></b-icon></span>

                    <div v-for="i in dao_contacts" :key="i.id">
                      <p style="color: #81edcd !important; overflow-wrap: anywhere;" >{{ i.name }}</p>
                      <div class="divider is-centered"></div>
                      <p style="color: #e181ed !important; overflow-wrap: anywhere;">{{ i.identifier }}</p>
                    </div>



                  </div>
                </div>

                <div class="divider is-centered" style="margin-top: -20px;"></div>

                <div class="container mt-6 mb-2">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader">
                      Review and create your account to begin voting on this DAO
                    </h3>
                  </div>
                </div>

                <form class="form-horizontal">
                  <div class="columns is-mobile is-centered">
                    <div class="column">
                      <div class="notification has-text-centered inset-bg-rounded2" style="background-color: #2d3a45;">
                        <div @click="CreateAccount()" class="btn2 btn-lg stake-btn"
                             style="border-radius: 1rem !important;">
                          <span>CREATE ACCOUNT</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!--Edit Account Modal-->
    <b-modal v-if="showEditAccountModal === true" v-model="showEditAccountModal" full-screen scroll="keep"
             style="border-radius:20px;">

      <div class="form-bg centered2" style="border-radius:20px;">
        <div class="container">
          <div class="columns">
            <div class="column blue-bg">
              <div class="form-container form-shadow">
                <div class="container pb-4">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader" style="font-size: 20px;">
                      Update your account information with this DAO?
                    </h3>
                  </div>
                  <br/>
                  <div class="divider is-centered"></div>
                </div>
                <b-message class="is-success" style="background-color: rgb(9 40 52);">
                  <div class="columns is-centered pt-4 pb-0">
                    <div class="column is-centered" style="overflow-wrap: anywhere !important;">
                      {{ this.user_name }}
                    </div>
                  </div>
                </b-message>

                <div class="divider is-centered"></div>

                <div class="columns is-justify-content-center" style="margin-top: 45px">
                  <div class="column is-5" style="border-radius: 20px; box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; padding: 1rem; background-color: #29394c;" >

                    <p style="padding: 1rem; border-radius: 20px; background-color: #253242; box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;">Add additional identifiers to be displayed under your account:</p>

                    <br/>
                    <div class="divider is-centered"></div>
                    <br/>

                    <b-field
                        custom-class="is-small"
                        horizontal>
                      <template #label>
                        <b-tooltip type="is-dark" multilined
                                   label="Enter the contact title to display next to the contact information.">
                          <label class="label pt-1" style="width:50px;"><span style="color: #4aabe3">Title:</span></label>
                        </b-tooltip>
                      </template>
                      <div class="field-body">
                        <div class="field has-addons">
                          <p class="control is-expanded">
                            <input v-model="newIDTitle"
                                   @input="determineNewIDTitle()"
                                   class="input select"
                                   type="text" placeholder="Email">
                          </p>
                        </div>
                      </div>
                    </b-field>

                    <b-field
                        custom-class="is-small"
                        horizontal>
                      <template #label>
                        <b-tooltip type="is-dark" multilined
                                   label="Enter actual contact information">
                          <label class="label pt-1 mt-2" style="width:50px;"><span style="color: #4aabe3">Identifier:</span></label>
                        </b-tooltip>
                      </template>
                      <div class="field-body">
                        <div class="field has-addons">
                          <p class="control is-expanded">
                            <input v-model="newID"
                                   @input="determineNewID()"
                                   class="input select"
                                   type="text" placeholder="dao_email@dao_email.com">
                          </p>
                        </div>
                      </div>
                    </b-field>

                    <b-field
                        style="padding-top: 10px;"
                        custom-class="is-small"
                        horizontal>
                      <div class="field-body">
                        <div class="field has-addons">
                          <div class="columns is-flex is-justify-content-center">
                            <div class="column">
                              <p class="control is-expanded">
                                <b-button @click="addNewDaoContact()" size="is-small" type="is-dark"
                                          style="background-color: #4c2d71 !important">Add ID
                                </b-button>
                              </p>
                            </div>
                            <div class="column">
                              <p class="control is-expanded">
                                <b-button @click="clearDaoContacts()" size="is-small" type="is-dark"
                                          style="background-color: #712d2d !important">Clear IDs
                                </b-button>
                              </p>
                            </div>
                          </div>


                        </div>
                      </div>
                    </b-field>


                  </div>
                  <div class="column is-1"></div>
                  <div class="column is-5 is-flex-direction-column reddish container_links is-justify-content-space-evenly"
                       style="display:flex; border: 1px solid #3abb81; border-radius: 2rem;">
                    <p v-if="dao_contacts.length === 0" style="color: #4ae39e !important">Add your own account identifiers here</p>

                    <p v-if="dao_contacts.length !== 0" style="color: #4ae39e !important">Account Identifiers:</p>

                    <div v-for="i in dao_contacts" :key="i.id">
                      <p style="color: #81edcd !important; overflow-wrap: anywhere;" >{{ i.name }}</p>
                      <div class="divider is-centered"></div>
                      <p style="color: #e181ed !important; overflow-wrap: anywhere;">{{ i.identifier }}</p>
                    </div>


                  </div>
                </div>

                <div class="divider is-centered" style="margin-top: -20px;"></div>

                <div class="container mt-6 mb-2">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader">
                      Review and edit your account on this DAO when ready:
                    </h3>
                  </div>
                </div>

                <form class="form-horizontal">
                  <div class="columns is-mobile is-centered">
                    <div class="column">
                      <div class="notification has-text-centered inset-bg-rounded2" style="background-color: #2d3a45;">
                        <div @click="EditAccount()" class="btn2 btn-lg stake-btn"
                             style="border-radius: 1rem !important;">
                          <span>EDIT ACCOUNT</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!--Post Message Modal-->
    <b-modal v-if="showPostMessageModal === true" v-model="showPostMessageModal" full-screen scroll="keep"
             style="border-radius:20px;">

      <div class="form-bg centered2" style="border-radius:20px;">
        <!--                              POST MESSAGE-->
        <section class="hero" style="margin-top: 20px;">
          <div class="hero-body">
            <div class="container animated fadeInUp"
                 style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
              <div class="columns is-multiline is-mobile"
                   style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                <div
                    class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                    style="border-radius: 2rem;">
                  <div class="columns is-flex-direction-column is-justify-content-center">

                    <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                         style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                      <span style="color: #32445e !important;"><b-icon pack="fas" icon="pencil-square" size="is-large"></b-icon></span>
                      <span class="cow" style="font-size: 20px;">POST A NEW MESSAGE TO DAO</span>
                    </div>

                    <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                      <b-field custom-class="is-small" horizontal>
                        <template #label>
                          <b-tooltip type="is-dark"
                                     label="What is the title of your new message?">
                            <label class="label pt-1 cow">Title of Message:</label>
                          </b-tooltip>
                        </template>
                        <div class="field-body">
                          <div class="field has-addons">

                            <p class="control is-expanded">
                              <input v-model="admin_new_message_title"
                                     class="input select" type="text"
                                     placeholder="My new message">
                            </p>

                          </div>
                        </div>
                      </b-field>
                    </div>

                    <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                      <b-field custom-class="is-small" horizontal>
                        <template #label>
                          <b-tooltip type="is-dark"
                                     label="New message to post to the DAO">
                            <label class="label pt-1 cow">Message:</label>
                          </b-tooltip>
                        </template>
                        <div class="field-body">
                          <div class="field has-addons">

                            <p class="control is-expanded">
                              <b-input v-model="admin_new_message" maxlength="400" style="background-color: rgba(103, 103, 108, 0.1) !important; border: none; color: #4ae39e; box-shadow: inset 0 30px 60px -12px rgb(50 50 93 / 25%), inset 0 18px 36px -18px rgb(0 0 0 / 30%);" type="textarea"></b-input>
                            </p>


                          </div>
                        </div>
                      </b-field>
                      <b-button @click="clickModeratorPostNewMessage()" class="button is-primary">POST MESSAGE</b-button>
                    </div>

                    <div class="column is-12">
                      <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                        <p class="cow2 pt-2" style="font-size: 13px;">About New Messages:</p>
                        <div class="divider" style="margin-top: -40px;"></div>
                        <ul class="signature-content has-text-left">
                          <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Use this tool to post a message under the DAO's 'Latest News' area.</li>
                        </ul>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-modal>

    <!--Create Pool Modal-->
    <b-modal v-if="showPoolModal === true" v-model="showPoolModal" full-screen scroll="keep"
             style="border-radius:20px;">

      <div class="form-bg centered2" style="border-radius:20px;">
        <!--                              POST MESSAGE-->
        <section class="hero" style="margin-top: 20px;">
          <div class="hero-body">
            <div class="container animated fadeInUp"
                 style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
              <div class="columns is-multiline is-mobile"
                   style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                <div
                    class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                    style="border-radius: 2rem;">
                  <div class="columns is-flex-direction-column is-justify-content-center">

                    <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                         style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                      <span style="color: #32445e !important;"><b-icon pack="fas" icon="institution" size="is-large"></b-icon></span>
                      <span class="cow" style="font-size: 20px;">CREATE A NEW DAO TREASURY POOL</span>
                    </div>

                    <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                      <b-field custom-class="is-small" horizontal>
                        <template #label>
                          <b-tooltip type="is-dark"
                                     label="What is the Namespace of the token this pool will manage?">
                            <label class="label pt-1 cow">Token Namespace:</label>
                          </b-tooltip>
                        </template>
                        <div class="field-body">
                          <div class="field has-addons">

                            <p class="control is-expanded">
                              <input v-model="admin_namespace"
                                     class="input select" type="text"
                                     placeholder="My new message">
                            </p>

                          </div>
                        </div>
                      </b-field>
                    </div>

                    <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                      <b-field custom-class="is-small" horizontal>
                        <template #label>
                          <b-tooltip type="is-dark"
                                     label="What is the Module Name of the Token this pool will manage?">
                            <label class="label pt-1 cow">Token Module:</label>
                          </b-tooltip>
                        </template>
                        <div class="field-body">
                          <div class="field has-addons">

                            <p class="control is-expanded">
                              <input v-model="admin_modulename"
                                     class="input select" type="text"
                                     placeholder="My new message">
                            </p>
                            <p class="control">
                              <b-button @click="clickCreateDAOTreasuryPool()" class="button is-primary">CREATE TREASURY POOL</b-button>
                            </p>

                          </div>
                        </div>
                      </b-field>
                    </div>

                    <div class="column is-12">
                      <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                        <p class="cow2 pt-2" style="font-size: 13px;">About Creating Treasury Pools:</p>
                        <div class="divider" style="margin-top: -40px;"></div>
                        <ul class="signature-content has-text-left">
                          <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Use this tool to create a new DAO Pool which will hold a specific token for the DAO.</li>
                          <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>DAO Pools appear in the 'Pools' section on a DAO's Hive and are managed by the DAO's treasury members.</li>
                          <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Each DAO Pool can only manage 1 token type, and a DAO cannot make multiple pools of the same token type.</li>
                        </ul>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-modal>

    <!--Edit Permissions Modal-->
    <b-modal v-if="showPermissionsModal === true" v-model="showPermissionsModal" full-screen scroll="keep"
             style="border-radius:20px;">

      <div class="form-bg centered2" style="border-radius:20px;">
        <section class="hero" style="margin-top: 20px;">
          <div class="hero-body">
            <div class="container animated fadeInUp"
                 style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
              <div class="columns is-multiline is-mobile"
                   style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                <div
                    class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                    style="border-radius: 2rem;">
                  <div class="columns is-flex-direction-column is-justify-content-center">

                    <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                         style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                      <span style="color: #32445e !important;"><b-icon pack="fas" icon="user-plus" size="is-large"></b-icon></span>
                      <span class="cow" style="font-size: 20px;">GRANT AN ACCOUNT SPECIAL DAO PERMISSIONS</span>
                    </div>

                    <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                      <b-field custom-class="is-small" horizontal>
                        <template #label>
                          <b-tooltip type="is-dark"
                                     label="This is the token stakers are rewarded with as they stake tokens in your pool.">
                            <label class="label pt-1 cow">Permission:</label>
                          </b-tooltip>
                        </template>
                        <div class="field-body">
                          <div class="field has-addons">
                            <p class="control">
                                                      <span class="select">
                                                        <select v-model="admin_accountPermission">
                                                                  <option value="1">None</option>
                                                                  <option value="2">Moderator</option>
                                                                  <option value="3">Treasurer</option>
                                                                  <option value="4">Creator</option>
                                                        </select>
                                                      </span>
                            </p>
                            <p class="control is-expanded">
                              <input v-model="admin_accountToEdit"
                                     class="input select" type="text"
                                     placeholder="k:account">
                              <span class="label cow" style="font-size: 12px;">Account</span>

                            </p>
                            <p class="control">
                              <b-button @click="clickGrantNewPermissions()" class="button is-primary">GRANT</b-button>
                            </p>

                          </div>
                        </div>
                      </b-field>
                    </div>

                    <div class="column is-12">
                      <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                        <p class="cow2 pt-2" style="font-size: 13px;">About DAO Permissions:</p>
                        <div class="divider" style="margin-top: -40px;"></div>
                        <ul class="signature-content has-text-left">
                          <li><i><b-icon pack="fas" icon="user-times" size="is-small"></b-icon></i><span style="font-weight: bold; color: #874bd5">None:</span> Remove all Special DAO Permissions from a DAO member account. </li>
                          <li><i><b-icon pack="fas" icon="gavel" size="is-small"></b-icon></i><span style="font-weight: bold; color: #874bd5">Moderator:</span> A Moderator account can post messages and ban proposals. </li>
                          <li><i><b-icon pack="fas" icon="balance-scale" size="is-small"></b-icon></i><span style="font-weight: bold; color: #874bd5">Treasurer:</span> Treasurers can do everything a Moderator can do, plus create and edit tasks and make DAO pools and payments.</li>
                          <li><i><b-icon pack="fas" icon="wrench" size="is-small"></b-icon></i><span style="font-weight: bold; color: #874bd5">Creator:</span> Creator accounts can do everything Moderators and Treasurers can do, plus edit the DAOs rules and Hive as well as grant DAO permissions.</li>
                        </ul>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-modal>

    <!--Deposit Modal-->
    <b-modal v-if="showDepositModal === true" v-model="showDepositModal" full-screen scroll="keep"
             style="border-radius:20px;">

      <div class="form-bg centered2" style="border-radius:20px;">
        <section class="hero" style="margin-top: 20px;">
          <div class="hero-body">
            <div class="container animated fadeInUp"
                 style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
              <div class="columns is-multiline is-mobile"
                   style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                <div
                    class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                    style="border-radius: 2rem;">
                  <div class="columns is-flex-direction-column is-justify-content-center">

                    <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                         style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                      <span style="color: #32445e !important;"><b-icon pack="fas" icon="balance-scale" size="is-large"></b-icon></span>
                      <span class="cow" style="font-size: 20px;">DEPOSIT FUNDS INTO A DAO TREASURY POOL</span>
                    </div>


                    <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                      <b-field custom-class="is-small" horizontal>
                        <template #label>
                          <b-tooltip type="is-dark"
                                     label="Which DAO pool will you be depositing into?">
                            <label class="label pt-1 cow">Deposit into Pool:</label>
                          </b-tooltip>
                        </template>
                        <div class="field-body">
                          <div class="field has-addons">
                            <p class="control is-expanded">
                                                      <span class="select" style="width: 100%">
                                                        <b-select expanded v-model="admin_depositPool">
                                                                  <option v-for="p in DAO_SUBJECT_INFO.pools" :key="p.account" :value="{ 'account': p.account, 'token': p.pool_token}">{{p.account}}</option>
                                                        </b-select>
                                                      </span>
                            </p>

                          </div>
                        </div>
                      </b-field>
                      <b-field custom-class="is-small" horizontal>
                        <template #label>
                          <b-tooltip type="is-dark"
                                     label="This is the token this pool accepts deposits from">
                            <label class="label pt-1 cow">Pool Token:</label>
                          </b-tooltip>
                        </template>
                        <b-input v-model="admin_depositpooltoken" placeholder="Disabled" disabled></b-input>
                      </b-field>
                    </div>

                    <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                      <b-field custom-class="is-small" horizontal>
                        <template #label>
                          <b-tooltip type="is-dark"
                                     label="Reason for depositing into the DAO today?">
                            <label class="label pt-1 cow">Reason for Deposit:</label>
                          </b-tooltip>
                        </template>
                        <div class="field-body">
                          <div class="field has-addons">

                            <p class="control is-expanded">
                              <b-input v-model="admin_reasonToDeposit" maxlength="400" style="background-color: rgba(103, 103, 108, 0.1) !important; border: none; color: #4ae39e; box-shadow: inset 0 30px 60px -12px rgb(50 50 93 / 25%), inset 0 18px 36px -18px rgb(0 0 0 / 30%);" type="textarea"></b-input>
                            </p>

                          </div>
                        </div>
                      </b-field>
                    </div>


                    <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                      <b-field custom-class="is-small" horizontal>
                        <template #label>
                          <b-tooltip type="is-dark"
                                     label="How much will you be depositing into the DAO?">
                            <label class="label pt-1 cow">Deposit Amount:</label>
                          </b-tooltip>
                        </template>
                        <div class="field-body">
                          <div class="field has-addons">

                            <p class="control is-expanded">
                              <input v-model="admin_amountToDeposit"
                                     class="input select" type="number"
                                     placeholder="k:account">
                            </p>
                            <p class="control">
                              <b-button @click="clickDepositTreasury()" class="button is-primary">DEPOSIT</b-button>
                            </p>

                          </div>
                        </div>
                      </b-field>
                    </div>

                    <div class="column is-12">
                      <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                        <p class="cow2 pt-2" style="font-size: 13px;">About Deposits:</p>
                        <div class="divider" style="margin-top: -40px;"></div>
                        <ul class="signature-content has-text-left">
                          <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Use this tool to deposit funds into a DAO pool.</li>
                        </ul>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-modal>

    <!--Withdraws Modal-->
    <b-modal v-if="showWithdrawModal === true" v-model="showWithdrawModal" full-screen scroll="keep"
             style="border-radius:20px;">

      <div class="form-bg centered2" style="border-radius:20px;">
        <section class="hero" style="margin-top: 20px;">
          <div class="hero-body">
            <div class="container animated fadeInUp"
                 style="border-radius: 2rem; box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;">
              <div class="columns is-multiline is-mobile"
                   style=" border-radius: 2rem; margin-left: 0px; margin-right: 0px;     box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;">
                <div
                    class="column is-12-desktop container4 is-12-tablet is-12-mobile txshadow reddish"
                    style="border-radius: 2rem;">
                  <div class="columns is-flex-direction-column is-justify-content-center">

                    <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center"
                         style="height: 120px;background-color: #151d2a;border-radius: 2rem;box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;">
                      <span style="color: #32445e !important;"><b-icon pack="fas" icon="balance-scale" size="is-large"></b-icon></span>
                      <span class="cow" style="font-size: 20px;">WITHDRAW FUNDS FROM A DAO TREASURY POOL</span>
                    </div>


                    <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                      <b-field custom-class="is-small" horizontal>
                        <template #label>
                          <b-tooltip type="is-dark"
                                     label="Which pool will you be withdrawing funds from?">
                            <label class="label pt-1 cow">Withdraw from Pool:</label>
                          </b-tooltip>
                        </template>
                        <div class="field-body">
                          <div class="field has-addons">
                            <p class="control is-expanded">
                                                      <span class="select" style="width: 100%">
                                                        <b-select expanded v-model="admin_PoolToWithdraw">
                                                                  <option v-for="p in DAO_SUBJECT_INFO.pools" :key="p.account" :value="{ 'account': p.account, 'token': p.pool_token}">{{p.account}}</option>
                                                        </b-select>
                                                      </span>
                            </p>

                          </div>
                        </div>
                      </b-field>
                      <b-field custom-class="is-small" horizontal>
                        <template #label>
                          <b-tooltip type="is-dark"
                                     label="This is the token type this pool holds">
                            <label class="label pt-1 cow">Pool Token:</label>
                          </b-tooltip>
                        </template>
                        <b-input v-model="admin_pooltoken" placeholder="Disabled" disabled></b-input>
                      </b-field>
                    </div>

                    <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                      <b-field custom-class="is-small" horizontal>
                        <template #label>
                          <b-tooltip type="is-dark"
                                     label="Reason for withdrawing from the DAO today?">
                            <label class="label pt-1 cow">Reason for Withdraw:</label>
                          </b-tooltip>
                        </template>
                        <div class="field-body">
                          <div class="field has-addons">

                            <p class="control is-expanded">
                              <b-input v-model="admin_reasonToWithdraw" maxlength="400" style="background-color: rgba(103, 103, 108, 0.1) !important; border: none; color: #4ae39e; box-shadow: inset 0 30px 60px -12px rgb(50 50 93 / 25%), inset 0 18px 36px -18px rgb(0 0 0 / 30%);" type="textarea"></b-input>
                            </p>

                          </div>
                        </div>
                      </b-field>
                    </div>

                    <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                      <b-field custom-class="is-small" horizontal>
                        <template #label>
                          <b-tooltip type="is-dark"
                                     label="What account with receive the tokens you are withdrawing?">
                            <label class="label pt-1 cow">Withdraw to Account:</label>
                          </b-tooltip>
                        </template>
                        <div class="field-body">
                          <div class="field has-addons">

                            <p class="control is-expanded">
                              <input v-model="admin_accountToWithdraw"
                                     class="input select" type="text"
                                     placeholder="k:account">
                            </p>

                          </div>
                        </div>
                      </b-field>
                    </div>

                    <div class="column mt-4 is-offset-1 is-9 is-flex is-flex-direction-column is-justify-content-center">
                      <b-field custom-class="is-small" horizontal>
                        <template #label>
                          <b-tooltip type="is-dark"
                                     label="How much will you be withdrawing from the DAO?">
                            <label class="label pt-1 cow">Withdraw Amount:</label>
                          </b-tooltip>
                        </template>
                        <div class="field-body">
                          <div class="field has-addons">

                            <p class="control is-expanded">
                              <input v-model="admin_amountToWithdraw"
                                     class="input select" type="number"
                                     placeholder="k:account">
                            </p>
                            <p class="control">
                              <b-button @click="clickWithdrawTreasury()" class="button is-primary">WITHDRAW</b-button>
                            </p>

                          </div>
                        </div>
                      </b-field>
                    </div>

                    <div class="column is-12">
                      <div class="email-signature" style="background-color: #151b24;border-radius: 2rem; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;">
                        <p class="cow2 pt-2" style="font-size: 13px;">About Withdraws:</p>
                        <div class="divider" style="margin-top: -40px;"></div>
                        <ul class="signature-content has-text-left">
                          <li><i><b-icon pack="fas" icon="info-circle" size="is-small"></b-icon></i>Use this tool to withdraw funds from a DAO pool.</li>
                        </ul>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import VotePoolCard from "./VotePoolCard";
import TaskCard from "./TaskCard";
import DaoCardPreview from "./DaoCardPreview";

// import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
// import {double, regex, required} from "vee-validate/dist/rules";

//Rules for validation
// extend("required", {
//   ...required,
//   message: "This field is required"
// });
// extend("double", {
//   ...double,
//   message: "Example: 100.00"
// });
// extend("regex", {
//   ...regex,
//   message: "Decimal required, Example: 0.0 or 100.0"
// });
// extend("lpNotKdaKda", {
//   validate(value, args) {
//     console.log("value " + value);
//     console.log("args " + args.test);
//     return value !== args.test;
//   },
//   params: ["test"],
//   message: "LP Token Pairs must be different"
// });
// extend("lpNotSameCustom", {
//   validate(value, args) {
//     console.log("value " + value);
//     console.log("args " + args.test[0] + args.test[1] + args.test[2] + args.test[3]);
//     return args.test[0] + args.test[1] !== args.test[2] + args.test[3];
//   },
//   params: ["test"],
//   message: "LP Token Pairs must be different"
// });
// extend("greaterthan", {
//   params: ["target"],
//   validate(value, {target}) {
//     return value > target;
//   },
//   message: "This field must be greater than {target}"
// });
// extend("greaterthanequalto", {
//   params: ["target"],
//   validate(value, {target}) {
//     return value >= target;
//   },
//   message: "This field must be greater than or equal to {target}"
// });
// extend("lessthanequalto", {
//   params: ["target"],
//   validate(value, {target}) {
//     return value <= target;
//   },
//   message: "This field must be less than or equal to {target}"
// });
// extend("hardcap", {
//   params: ["target"],
//   validate(value, {target}) {
//     return value >= target;
//   },
//   message: "Hard Cap must be greater than or equal to (Sell Quantity * Sell Price)"
// });
// extend("lessthan", {
//   params: ["target"],
//   validate(value, {target}) {
//     return value <= target;
//   },
//   message: "This field must be less than or equal to {target}"
// });
// extend("chargreaterthan", {
//   params: ["target"],
//   validate(value, {target}) {
//     return value.length >= target;
//   },
//   message: "This field must be greater than or equal to {target} characters in length"
// });
//
// extend("charlessthan", {
//   params: ["target"],
//   validate(value, {target}) {
//     return value.length <= target;
//   },
//   message: "This field must be less than or equal to {target} characters in length"
// });


//local state
export default {
  name: "DAOPage",
  props: {
    poolid: {
      type: String,
      default: "0"
    },
  },
  components: {
    // ValidationProvider,
    // ValidationObserver,
    VotePoolCard,
    TaskCard,
    DaoCardPreview
  },
  data: function () {
    return {
      debug: false,
      activeTab: "0",
      activeTab2: "0",
      currentPage: "0",
      showLoadingModal: true,
      currentDate: null,
      inputisvalid: false,
      user_name: null,
      showTxModal: false,
      showCreateAccountModal: false,
      showEditAccountModal: false,
      showPostMessageModal: false,
      showPermissionsModal: false,
      showDepositModal: false,
      showWithdrawModal: false,
      showPoolModal: false,
      amountToWithdraw: "5.0",
      //dao
      isMakingCrowdFundTransaction: false,
      isMakingTransaction: false,
      newIDTitle: "k:account",
      newID: this.user_name,
      DAO_ACCOUNT_INFO: [],
      dao_contacts_final: [],
      dao_contacts: [],
      dao_currentContact: 0,
      ActivePoolCount: 0,
      admin_accountPermission: "1",
      admin_accountToEdit: "k:account_to_edit",
      DAO_POOL_COUNT: 0,
      DAO_SUBJECT_INFO: [],
      DAO_CONTACTS: [],
      DAO_MEMBERS: [],
      DAO_TREASURY_BALANCE: 0,
      DAO_POOLS: [],
      DAO_LINKS: [],
      DAO_CONTACTS_TEMP: [
        {
          "id": 1,
          "name": "Hive Creator",
          "account": "k:52d60e26c1750f19f65eda20e27cb7391af54dd9268d29ca4b05a6e982d6d214"
        },
        {"id": 2, "name": "squiegee discord", "account": "@squiegee"},
      ],
      DAO_POOLS_TEMP: [
        {
          "id": 1,
          "name": "Community Pool",
          "account": "XxZuRiEqamYhZpJJ_nphg9uCdArp7WEZpCTIElzZhTo",
          "pool_token": "free.stake-token",
          "pool_link": "www.stakefactory.io",
          "balance": 20345.0
        },
        {
          "id": 2,
          "name": "Another Pool",
          "account": "XxZuRiEqamYhZpJJ_nphg9uCdArp7WEZpCTIElzZhTo",
          "pool_token": "free.stake-token",
          "pool_link": "www.stakefactory.io",
          "balance": 20345645.0
        },
        {
          "id": 3,
          "name": "Vesting Pool for Vote#1",
          "account": "XxZuRiEqamYhZpJJ_nphg9uCdArp7WEZpCTIElzZhTo",
          "pool_token": "free.stake-token",
          "pool_link": "www.stakefactory.io",
          "balance": 46720345.0
        },
        {
          "id": 4,
          "name": "Staking Pool in response to Vote#456",
          "account": "XxZuRiEqamYhZpJJ_nphg9uCdArp7WEZpCTIElzZhTo",
          "pool_token": "free.stake-token",
          "pool_link": "www.stakefactory.io",
          "balance": 345.0
        },
        {
          "id": 5,
          "name": "Some other pool",
          "account": "XxZuRiEqamYhZpJJ_nphg9uCdArp7WEZpCTIElzZhTo",
          "pool_token": "free.stake-token",
          "pool_link": "www.stakefactory.io",
          "balance": 278788885.03453453
        }
      ],
      DAO_MSGS: [
        {
          "message_date": new Date().toLocaleString(),
          "message_title": "Genesis",
          "message": "Created DAO Hive [my-dao-hive]"
        },
        {
          "message_date": new Date().toLocaleString(),
          "message_title": "Updated Hive",
          "message": "Update DAO Hive [my-dao-hive]"
        },
        {
          "message_date": new Date().toLocaleString(),
          "message_title": "New Proposal",
          "message": "Created Proposal my-proposal"
        },
        {
          "message_date": new Date().toLocaleString(),
          "message_title": "Updated Proposal",
          "message": "Updated Proposal my-proposal"
        },
      ],
      DAO_PROPOSITIONS: [],
      DAO_TASKS: [],
      DAO_TASKS_PENDING: [],
      DAO_TASKS_COMPLETED: [],
      DAO_PROPOSITIONS_ACTIVE: [],
      DAO_PROPOSITIONS_HISTORY: [],
      DAO_image: "https://cdn.pixabay.com/photo/2016/07/30/19/47/banner-1557841_960_720.jpg",
      DAO_members: 0,
      DAO_proposals: 0,
      is_pool_owner: false,
      has_account: false,
      //Liquidity Pools
      allLiquidityPoolsToShowNow: [],
      allLiquidityPoolsPaginated: [],
      allLiquidityPoolsToShowNow_Searched: [],
      allMSGSPaginated: [],
      allMSGSToShowNow: [],
      perPage: 6,
      pageMSGS: 1,
      pagesMSGS: [],
      searchedMSGS: false,
      searchStringMSGS: "",
      searchAllMSGS: true,
      admin_new_message: "My new message to post to the DAO",
      admin_new_message_title: "My Important Message",
      isSendingMessage: false,
      isWithdrawingTreasury: false,
      isDepositingTreasury: false,
      isDepositingTreasury_poolid: "",
      isBanningProposal: false,
      isBanningProposal_poolid: "",
      isGrantingPermission: false,
      isSendingMessage_poolid: "",
      admin_amountToWithdraw: 0.0,
      admin_accountToWithdraw: "k:receiver",
      admin_pooltoken: "",
      admin_PoolToWithdraw: null,
      admin_reasonToBan: "My reason for deactivating this proposal",
      admin_reasonToWithdraw: "My reason for withdrawing from the DAO treasury",
      admin_proposalToDeactivate: "Proposal_ID",
      admin_modulename: "stake-token",
      admin_namespace: "free",
      isCreatingTreasuryPool: false,
      isCreatingTreasuryPool_poolid: null,
      admin_newAboutMessage: "",
      isEditingDao: false,
      isEditingDao_poolid: "",
      admin_daoDescription: "",
      admin_iconLink: "",
      dao_links_edit: [],
      dao_links_final: [],
      dao_currentLink: 0,
      admin_newlinktitle: "",
      admin_newlink: "",
      admin_canMakeProposals: null,
      admin_proposal_namespace: "",
      admin_proposal_modulename: "",
      admin_proposal_amount: 0.0,
      is_moderator: false,
      is_treasurer: false,
      is_creator: false,
      admin_depositpooltoken: "",
      admin_amountToDeposit: 0.0,
      admin_depositPool: null,
      admin_reasonToDeposit: "",

    };
  },
  watch: {



    admin_depositPool: async function (newValue) {

      if(newValue && newValue.token){
        this.admin_depositpooltoken = this.coinName(newValue.token.refName.namespace, newValue.token.refName.name);
      }



    },


    admin_PoolToWithdraw: async function (newValue) {

      console.log("admin_reasonToBan")
      console.log(newValue)

      if(newValue && newValue.token){
        this.admin_pooltoken = this.coinName(newValue.token.refName.namespace, newValue.token.refName.name);
      }



    },

    transactionPolling: async function (newValue, oldValue) {
      if (this.debug) {
        console.log("vesting admin getTransactionPolling newValue: " + newValue);
        console.log("vesting admin getTransactionPolling oldValue: " + oldValue);
      }

      if (newValue === true && this.isMakingCrowdFundTransaction === true) {

        this.isMakingTransaction = true;

        let message1 = "Confirming new transaction with the blockchain";
        if (this.isCreatingAccount === true) {
          message1 = "Creating new account with DAO " + this.poolid;
        } else if (this.isEditingAccount === true) {
          message1 = "Editing account in DAO " + this.poolid;
        } else if (this.isSendingMessage === true) {
          message1 = "Posting new message to DAO " + this.poolid;
        } else if (this.isGrantingPermission === true) {
          message1 = "Granting new permissions at DAO " + this.poolid;
        } else if (this.isWithdrawingTreasury === true) {
          message1 = "Withdrawing from Treasury for DAO " + this.poolid;
        } else if (this.isBanningProposal === true) {
          message1 = "Deactivating Proposal " + this.isBanningProposal_poolid;
        } else if (this.isEditingDao === true) {
          message1 = "Editing DAO " + this.isEditingDao_poolid;
        }  else if (this.isDepositingTreasury === true) {
          message1 = "Depositing " + this.isDepositingTreasury_poolid;
        }




        this.$buefy.toast.open({
          duration: 10000,
          message: message1,
          type: "is-info",
          pauseOnHover: true
        });

      } else if (newValue === false && this.isMakingCrowdFundTransaction === true) {

        this.isMakingTransaction = false;
        this.isMakingCrowdFundTransaction = false;

        let message = "Transaction Success";
        if (this.isCreatingAccount === true) {
          message = "Created account on DAO " + this.poolid;
        } else if (this.isEditingAccount === true) {
          message = "Edited account on DAO " + this.poolid;
        } else if (this.isSendingMessage === true) {
          message = "Posted new message to DAO " + this.poolid;
        } else if (this.isGrantingPermission === true) {
          message = "Granted new permissions at DAO " + this.poolid;
        } else if (this.isWithdrawingTreasury === true) {
          message = "Successfully transferred funds from DAO " + this.poolid;
        } else if (this.isBanningProposal === true) {
          message = "Deactivated Proposal " + this.isBanningProposal_poolid;
        } else if (this.isEditingDao === true) {
          message = "Edited DAO " + this.isEditingDao_poolid;
        } else if (this.isDepositingTreasury === true) {
          message = "Successfully deposited " + this.isDepositingTreasury_poolid;
        }

        //Transaction success toast
        if (this.getTransactionFailed() === false) {

          this.$buefy.toast.open({
            duration: 10000,
            message: message,
            position: "is-top",
            type: "is-success"
          });

         this.isCreatingAccount = false;
         this.isEditingAccount = false;
         this.isSendingMessage = false;
         this.isGrantingPermission = false;
         this.isWithdrawingTreasury = false;
         this.isEditingDao = false;
         this.isDepositingTreasury = false;

          setTimeout(async () => {
            this.showLoadingModal = true;
            window.location.reload();
            this.showLoadingModal = false;
          }, 3000);


        } else if (this.getTransactionFailed() === true) {

          let message2 = "Transaction Failed";
          if (this.isCreatingAccount === true) {
            message2 = "Failed to create account on DAO " + this.poolid;
          } else if (this.isEditingAccount === true) {
            message2 = "Failed to edit account on DAO " + this.poolid;
          } else if (this.isSendingMessage === true) {
            message2 = "Failed to most new message to DAO " + this.poolid;
          } else if (this.isGrantingPermission === true) {
            message2 = "Failed to grant new permission at DAO " + this.poolid;
          } else if (this.isWithdrawingTreasury === true) {
            message2 = "Failed to withdraw from DAO " + this.poolid;
          } else if (this.isBanningProposal === true) {
            message2 = "Failed to deactivate Proposal " + this.isBanningProposal_poolid;
          } else if (this.isEditingDao === true) {
            message2 = "Failed edit DAO " + this.isEditingDao_poolid;
          } else if (this.isDepositingTreasury === true) {
            message2 = "Failed make deposit into DAO " + this.poolid;
          }



          this.$buefy.toast.open({
            duration: 10000,
            message: message2,
            position: "is-top",
            type: "is-danger"
          });

          this.isCreatingAccount = false;
          this.isEditingAccount = false;
          this.isSendingMessage = false;
          this.isGrantingPermission = false;
          this.isWithdrawingTreasury = false;
          this.isEditingDao = false;
          this.isDepositingTreasury = false;

          await this.reScanPools();

        }

      }


    },

    transactionRejected: function (newValue, oldValue) {
      if (this.debug) {
        console.log("transactionRejected newValue: " + newValue);
        console.log("transactionRejected oldValue: " + oldValue);
      }


      let message3 = "Transaction Rejected.";

      this.$buefy.toast.open({
        duration: 10000,
        message: message3,
        position: "is-top",
        type: "is-danger"
      });

      this.isCreatingAccount = false;
      this.isEditingAccount = false;
      this.isSendingMessage = false;
      this.isGrantingPermission = false;
      this.isWithdrawingTreasury = false;
      this.isEditingDao = false;
      this.isDepositingTreasury = false;

    },

    allMSGSPaginated: async function () {
      this.setPagesMSGS();
    },

  },
  computed: {
    ...mapState("accounts", ["transactionPolling", "transactionRejected"]),
  },
  methods: {

    ...mapGetters("accounts", ["getAccountExists", "getShowNavBar", "getAccountConfirmed", "getTransactionFailed", "getAllCrowdFundingPools", "getUserCrowdFundingPools", "getCurrentSignRequest"]),


    async clickShowDepositModal(){
      this.showDepositModal = true;
    },

    async clickShowWithdrawModal(){
      this.showWithdrawModal = true;
    },

    async clickShowPermissionsModal(){

      this.showPermissionsModal = true;
    },

    async clickShowPoolModal(){

      this.showPoolModal = true;
    },

    addNewDaoLink(){

      this.dao_currentLink = this.dao_currentLink + 1;

      let t_object2 = { 'name': this.admin_newlinktitle, 'link': this.admin_newlink };


      this.dao_links_final.push(t_object2);

    },

    clearDaoLinks(){
      this.dao_links_edit = [];
      this.dao_links_final = [];
      this.dao_currentLink = 0;
    },

    determineNewDaoLinkTitle(){

      let newLink = this.admin_newlinktitle;

      newLink = newLink.slice(0,30);

      this.admin_newlinktitle = newLink;

    },

    async clickSetProposalCost(){
      let editpayload = {
        subject_id: this.poolid,
        name: this.DAO_SUBJECT_INFO.name,
        icon: this.DAO_SUBJECT_INFO.icon,
        description: this.DAO_SUBJECT_INFO.description,
        about: this.DAO_SUBJECT_INFO.about,
        links: this.DAO_SUBJECT_INFO.links,
        contacts: this.DAO_SUBJECT_INFO.contacts,
        pool_cost: this.admin_proposal_amount,
        subject_token: this.coinName(this.DAO_SUBJECT_INFO.subject_token.refName.namespace, this.DAO_SUBJECT_INFO.subject_token.refName.name),
        required_verifiers: this.DAO_SUBJECT_INFO.required_verifiers.int,
        minimum_proposal_time: this.DAO_SUBJECT_INFO.minimum_proposal_time,
        creator_only: this.DAO_SUBJECT_INFO.creator_only,
      }


      this.showCreateAccountModal = false;
      this.showTxModal = true;
      this.isMakingCrowdFundTransaction = true;
      this.isEditingDao = true;
      this.isEditingDao_poolid = this.poolid+" Hive Proposal Cost";
      await this.$store.dispatch("accounts/factoryEditDAOHive", editpayload);
    },

    async clickSetWhoCanMakeProposals(){

      let editpayload = {
        subject_id: this.poolid,
        name: this.DAO_SUBJECT_INFO.name,
        icon: this.DAO_SUBJECT_INFO.icon,
        description: this.DAO_SUBJECT_INFO.description,
        about: this.DAO_SUBJECT_INFO.about,
        links: this.DAO_SUBJECT_INFO.links,
        contacts: this.DAO_SUBJECT_INFO.contacts,
        pool_cost: this.DAO_SUBJECT_INFO.pool_cost,
        subject_token: this.coinName(this.DAO_SUBJECT_INFO.subject_token.refName.namespace, this.DAO_SUBJECT_INFO.subject_token.refName.name),
        required_verifiers: this.DAO_SUBJECT_INFO.required_verifiers.int,
        minimum_proposal_time: this.DAO_SUBJECT_INFO.minimum_proposal_time,
        creator_only: this.admin_canMakeProposals,
      }


      this.showCreateAccountModal = false;
      this.showTxModal = true;
      this.isMakingCrowdFundTransaction = true;
      this.isEditingDao = true;
      this.isEditingDao_poolid = this.poolid+" Hive Proposal Rules";
      await this.$store.dispatch("accounts/factoryEditDAOHive", editpayload);

    },

    async clickEditDaoHiveLinks(){

      let editpayload = {
        subject_id: this.poolid,
        name: this.DAO_SUBJECT_INFO.name,
        icon: this.DAO_SUBJECT_INFO.icon,
        description: this.DAO_SUBJECT_INFO.description,
        about: this.DAO_SUBJECT_INFO.about,
        links: this.dao_links_final,
        contacts: this.DAO_SUBJECT_INFO.contacts,
        pool_cost: this.DAO_SUBJECT_INFO.pool_cost,
        subject_token: this.coinName(this.DAO_SUBJECT_INFO.subject_token.refName.namespace, this.DAO_SUBJECT_INFO.subject_token.refName.name),
        required_verifiers: this.DAO_SUBJECT_INFO.required_verifiers.int,
        minimum_proposal_time: this.DAO_SUBJECT_INFO.minimum_proposal_time,
        creator_only: this.DAO_SUBJECT_INFO.creator_only,
      }


      this.showCreateAccountModal = false;
      this.showTxModal = true;
      this.isMakingCrowdFundTransaction = true;
      this.isEditingDao = true;
      this.isEditingDao_poolid = this.poolid+" Hive Links";
      await this.$store.dispatch("accounts/factoryEditDAOHive", editpayload);



    },

    async clickEditDaoHiveIcon(){

      let editpayload = {
        subject_id: this.poolid,
        name: this.DAO_SUBJECT_INFO.name,
        icon: this.admin_iconLink,
        description: this.DAO_SUBJECT_INFO.description,
        about: this.DAO_SUBJECT_INFO.about,
        links: this.DAO_SUBJECT_INFO.links,
        contacts: this.DAO_SUBJECT_INFO.contacts,
        pool_cost: this.DAO_SUBJECT_INFO.pool_cost,
        subject_token: this.coinName(this.DAO_SUBJECT_INFO.subject_token.refName.namespace, this.DAO_SUBJECT_INFO.subject_token.refName.name),
        required_verifiers: this.DAO_SUBJECT_INFO.required_verifiers.int,
        minimum_proposal_time: this.DAO_SUBJECT_INFO.minimum_proposal_time,
        creator_only: this.DAO_SUBJECT_INFO.creator_only,
      }


      this.showCreateAccountModal = false;
      this.showTxModal = true;
      this.isMakingCrowdFundTransaction = true;
      this.isEditingDao = true;
      this.isEditingDao_poolid = this.poolid+" Hive Icon";
      await this.$store.dispatch("accounts/factoryEditDAOHive", editpayload);



    },

    async clickEditDaoHiveDescription(){

      let editpayload = {
        subject_id: this.poolid,
        name: this.DAO_SUBJECT_INFO.name,
        icon: this.DAO_SUBJECT_INFO.icon,
        description: this.admin_daoDescription,
        about: this.DAO_SUBJECT_INFO.about,
        links: this.DAO_SUBJECT_INFO.links,
        contacts: this.DAO_SUBJECT_INFO.contacts,
        pool_cost: this.DAO_SUBJECT_INFO.pool_cost,
        subject_token: this.coinName(this.DAO_SUBJECT_INFO.subject_token.refName.namespace, this.DAO_SUBJECT_INFO.subject_token.refName.name),
        required_verifiers: this.DAO_SUBJECT_INFO.required_verifiers.int,
        minimum_proposal_time: this.DAO_SUBJECT_INFO.minimum_proposal_time,
        creator_only: this.DAO_SUBJECT_INFO.creator_only,
      }


      this.showCreateAccountModal = false;
      this.showTxModal = true;
      this.isMakingCrowdFundTransaction = true;
      this.isEditingDao = true;
      this.isEditingDao_poolid = this.poolid+" Hive Description";
      await this.$store.dispatch("accounts/factoryEditDAOHive", editpayload);



    },

    async clickEditAboutMessage(){

      let editpayload = {
        subject_id: this.poolid,
        name: this.DAO_SUBJECT_INFO.name,
        icon: this.DAO_SUBJECT_INFO.icon,
        description: this.DAO_SUBJECT_INFO.description,
        about: this.admin_newAboutMessage,
        links: this.DAO_SUBJECT_INFO.links,
        contacts: this.DAO_SUBJECT_INFO.contacts,
        pool_cost: this.DAO_SUBJECT_INFO.pool_cost,
        subject_token: this.coinName(this.DAO_SUBJECT_INFO.subject_token.refName.namespace, this.DAO_SUBJECT_INFO.subject_token.refName.name),
        required_verifiers: this.DAO_SUBJECT_INFO.required_verifiers.int,
        minimum_proposal_time: this.DAO_SUBJECT_INFO.minimum_proposal_time,
        creator_only: this.DAO_SUBJECT_INFO.creator_only,
      }


        this.showCreateAccountModal = false;
        this.showTxModal = true;
        this.isMakingCrowdFundTransaction = true;
        this.isEditingDao = true;
        this.isEditingDao_poolid = this.poolid+" About Section";
        await this.$store.dispatch("accounts/factoryEditDAOHive", editpayload);



    },

    async clickCreateDAOTreasuryPool(){

      let treasurypayload = {
        subject_id: this.poolid,
        tokenA: this.coinName(this.admin_namespace, this.admin_modulename)
      }

      let exists_payload = {
        tokenA: this.coinName(this.admin_namespace, this.admin_modulename)
      };

      const exists = await this.$store.dispatch("accounts/getTokenExists", exists_payload);

      if(exists === true){
        this.showPoolModal = false;
        this.showTxModal = true;
        this.isMakingCrowdFundTransaction = true;
        this.isCreatingTreasuryPool = true;
        this.isCreatingTreasuryPool_poolid = this.coinName(this.admin_namespace, this.admin_modulename)
        await this.$store.dispatch("accounts/factoryCreateDAOTreasuryPool", treasurypayload);

      }else {
        alert("The token you entered could not be found, please try again.");
      }



    },

    async clickDeactivateProposal(){


      let deactivatePayload = {
        subject_id: this.poolid,
        pool_id: this.admin_proposalToDeactivate,
        reason: this.admin_reasonToBan
      }

      this.showCreateAccountModal = false;
      this.showTxModal = true;
      this.isMakingCrowdFundTransaction = true;
      this.isBanningProposal = true;
      this.isBanningProposal_poolid = deactivatePayload.pool_id
      await this.$store.dispatch("accounts/factoryDeactivateProposition", deactivatePayload);

    },

    async clickWithdrawTreasury(){


      let withdrawPayload = {
        subject_id: this.poolid,
        token: this.admin_pooltoken,
        amount: this.admin_amountToWithdraw,
        pool_id: this.admin_accountToWithdraw,
        reason: this.admin_reasonToWithdraw,
      }

      this.showWithdrawModal = false;
      this.showTxModal = true;
      this.isMakingCrowdFundTransaction = true;
      this.isWithdrawingTreasury = true;
      this.isWithdrawingTreasury_poolid = this.poolid;
      await this.$store.dispatch("accounts/factoryWithdrawDAOTreasuryPool", withdrawPayload);

    },

    async clickDepositTreasury(){


      let depositPayload = {
        subject_id: this.poolid,
        token: this.admin_depositpooltoken,
        amount: this.admin_amountToDeposit,
        pool_id: this.admin_depositPool.account,
        reason: this.admin_reasonToDeposit,
      }

      this.showDepositModal = false;
      this.showTxModal = true;
      this.isMakingCrowdFundTransaction = true;
      this.isDepositingTreasury = true;
      this.isDepositingTreasury_poolid = depositPayload.amount.toString()+" "+depositPayload.token+" into DAO "+this.poolid;
      await this.$store.dispatch("accounts/factoryDepositDAOTreasuryPool", depositPayload);

    },

    async clickGrantNewPermissions(){

      let permissionPayload = {
        subject_id: this.poolid,
        new_creator_id: this.admin_accountToEdit,
      }

      this.showPermissionsModal = false;
      this.showTxModal = true;
      this.isMakingCrowdFundTransaction = true;
      this.isGrantingPermission = true;
      this.isSendingMessage_poolid = this.poolid;

      if(this.admin_accountPermission === "2"){
        await this.$store.dispatch("accounts/factoryCreateDAOModerator", permissionPayload);
      } else if(this.admin_accountPermission === "3"){
        await this.$store.dispatch("accounts/factoryCreateDAOTreasurer", permissionPayload);
      } else if(this.admin_accountPermission === "4"){
        await this.$store.dispatch("accounts/factoryCreateDAOCreator", permissionPayload);
      }


    },

    async clickAdminPostNewMessage(){

      let messagePayload = {
        subject_id: this.poolid,
        message_title: this.admin_new_message_title,
        message: this.admin_new_message
      }

      this.showPostMessageModal = false;
      this.showTxModal = true;
      this.isMakingCrowdFundTransaction = true;
      this.isSendingMessage = true;
      this.isSendingMessage_poolid = this.poolid;
      await this.$store.dispatch("accounts/factoryCreateDAOMessage", messagePayload);

    },

    async clickModeratorPostNewMessage(){

      let messagePayload = {
        subject_id: this.poolid,
        message_title: this.admin_new_message_title,
        message: this.admin_new_message
      }

      this.showPostMessageModal = false;
      this.showTxModal = true;
      this.isMakingCrowdFundTransaction = true;
      this.isSendingMessage = true;
      this.isSendingMessage_poolid = this.poolid;
      await this.$store.dispatch("accounts/factoryCreateDAOMessage", messagePayload);

    },

    async CreateAccount(){

      if(this.dao_contacts_final.length > 0){

        const payload = {
          daoid: this.poolid,
          identifiers: this.dao_contacts_final
        }

        this.showCreateAccountModal = false;
        this.showTxModal = true;
        this.isMakingCrowdFundTransaction = true;
        this.isCreatingAccount = true;
        this.isCreatingAccount_poolid = this.poolid;
        await this.$store.dispatch("accounts/factoryCreateDAOAccount", payload);

      }else {
        alert("Please add at least 1 Identifier to your account - Identifiers can be anything you like.")
      }



    },

    async EditAccount(){

      const payload = {
        identifiers: this.dao_contacts_final
      }

      this.showEditAccountModal = false;
      this.showTxModal = true;
      this.isMakingCrowdFundTransaction = true;
      this.isEditingAccount = true;
      await this.$store.dispatch("accounts/factoryEditDAOAccount", payload);

    },

    determineNewIDTitle(){

      let newLink = this.newIDTitle;

      newLink = newLink.slice(0,30);

      this.newIDTitle = newLink;

    },

    determineNewID(){

      let newLink = this.newID;

      newLink = newLink.slice(0,256);

      this.newID = newLink;

    },

    addNewDaoContact(){

      if(this.dao_exampleModeContacts === true){
        this.dao_exampleModeContacts = false;
        this.dao_contacts = [];
      }

      this.dao_currentContact = this.dao_currentContact + 1;

      let t_object = { 'id': this.dao_currentContact, 'name': this.newIDTitle, 'identifier': this.newID };

      let t_object2 = { 'name': this.newIDTitle, 'identifier': this.newID };

      this.dao_contacts.push(t_object);

      this.dao_contacts_final.push(t_object2);

    },

    clearDaoContacts(){
      this.dao_contacts = [];
      this.dao_contacts_final = [];
      this.dao_currentContact = 0;
    },

    async goTo(link) {
      window.open(link);
    },

    async getTokenBalance(token, name) {

      const tub_payloadA = {
        tokenA: token,
        name: name
      };
      let balance = await this.$store.dispatch("accounts/getBalance", tub_payloadA);
      return balance;
    },

    async clickPostMessageModerator(){

      this.showPostMessageModal = true;
    },

    async clickCreateAccount() {
      this.newID = this.user_name;
      this.showCreateAccountModal = true;
    },

    async clickEditAccount() {

      let acct_payload = {
        subject_id: this.poolid
      }

      let doeshaveaccount =  await this.$store.dispatch("accounts/getDAOAccountInfo", acct_payload);

      if(doeshaveaccount !== 0 && doeshaveaccount !== '0' && doeshaveaccount !== []){
        this.has_account = true;
        this.DAO_ACCOUNT_INFO = doeshaveaccount;
        this.dao_contacts = this.DAO_ACCOUNT_INFO.identifiers;

        for(let i = 0; i < this.DAO_ACCOUNT_INFO.identifiers.length; i++){
          this.dao_contacts_final.push(this.DAO_ACCOUNT_INFO.identifiers[i])
        }

      }
      this.showEditAccountModal = true;
    },

    async clickShowPageLP(pagenumber) {

      let newfrom = (pagenumber * this.perPage) - this.perPage; //0 9
      let newto = (this.pageMSGS * this.perPage); //9 18


      if (newfrom < 0) {
        newfrom = 0;
      }

      if (this.debug) {
        console.log("newfrom: " + newfrom);
        console.log("newTo: " + newto);
      }
      await this.getMsgsToShowNow(newfrom, newto, this.DAO_MSGS);

    },

    setPagesMSGS() {


      let msgs = this.DAO_MSGS;
      let numberOfPages = Math.ceil(msgs.length / this.perPage);

      if (this.debug) {
        console.log("setPagesMSGS");
        console.log("msgs");
        console.log(msgs);
        console.log("msgs.length");
        console.log(msgs.length);
        console.log("numberofpages");
        console.log(numberOfPages);
      }

      this.pagesMSGS = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesMSGS.push(index);
      }
      if (this.debug) {
        console.log("pagesMSGS");
        console.log(this.pagesMSGS);
      }
    },

    async getMsgsToShowNow(from, to, msgs) {

      this.allMSGSPaginated = [];

      const msgsToShow = msgs;

      for (let i = from; i < to; i++) {

        if (i <= msgsToShow.length - 1) {

          if (this.allMSGSPaginated.includes(msgsToShow[i]) === false) {
            this.allMSGSPaginated.push(msgsToShow[i]);
          }


        }
      }
    },

    async showTxModalEvent() {

      this.showTxModal = true;

    },

    async closeSignatureModal() {
      this.showTxModal = false;
    },

    async checkValidInput(isvalid) {
      this.inputisvalid = isvalid;
    },

    coinName(namespace, name) {
      let newnamespace = namespace + ".";
      let newname = name;
      if (namespace === null || namespace === "null" || namespace === "") {
        newnamespace = "";
      }
      if (name === null || name === "null") {
        newname = "";
      }
      return newnamespace + newname;
    },

    coinDisplayName(coin) {
      let name = coin;
      if (coin === "coin") {
        name = "KDA";
      } else if (coin === "free.stake-token") {
        name = "STAKE";
      }
      return name;
    },

    reduceBalance(balance, prec = 6) {
      if (balance) {
        if (balance.int) balance = balance.int;
        if (balance.decimal) balance = balance.decimal;

        if (parseFloat(balance) % 1 === 0) {
          return parseInt(balance);
        }

        return Math.trunc(parseFloat(balance) * Math.pow(10, prec)) / Math.pow(10, prec);
      }

      if (balance === 0) return 0;
    },

    async reScanPools() {

      let name = localStorage.getItem("accountName");
      this.user_name = name;
      this.newID = name;

      let hivepayload = {
        daoid: this.poolid
      };


      //Vesting pool reset
      this.DAO_SUBJECT_INFO = await this.$store.dispatch("accounts/getDAOHive", hivepayload);

      console.log("DAO_SUBJECT_INFO");
      console.log(this.DAO_SUBJECT_INFO);

      const acct_payload = {
        subject_id: this.poolid
      }

      let doeshaveaccount =  await this.$store.dispatch("accounts/getDAOAccountInfo", acct_payload);

      if(doeshaveaccount !== 0 && doeshaveaccount !== '0' && doeshaveaccount !== []){
        this.has_account = true;
        this.DAO_ACCOUNT_INFO = doeshaveaccount;
        this.dao_contacts = this.DAO_ACCOUNT_INFO.identifiers;
        this.is_moderator = this.DAO_ACCOUNT_INFO.is_moderator;
        this.is_treasurer = this.DAO_ACCOUNT_INFO.is_treasurer;
        this.is_creator = this.DAO_ACCOUNT_INFO.is_creator;

      }

      console.log("doeshaveaccount");
      console.log(doeshaveaccount);

      this.admin_newAboutMessage = this.DAO_SUBJECT_INFO.about;

      this.admin_daoDescription = this.DAO_SUBJECT_INFO.description;

      this.admin_iconLink = this.DAO_SUBJECT_INFO.icon;

      this.dao_links_edit = this.DAO_SUBJECT_INFO.links;

      this.dao_links_final = this.DAO_SUBJECT_INFO.links;

      this.admin_canMakeProposals = this.DAO_SUBJECT_INFO.creator_only;


      if (this.DAO_SUBJECT_INFO.creator === name) {
        this.is_pool_owner = true;
      }

      this.DAO_PROPOSITIONS = await this.$store.dispatch("accounts/getDAOHivePools", hivepayload);

      this.DAO_TASKS = await this.$store.dispatch("accounts/getDAOHiveTasks", hivepayload);

      this.DAO_MEMBERS = await this.$store.dispatch("accounts/getDAOHiveMembers", hivepayload);

      console.log("DAO_PROPOSITIONS");
      console.log(this.DAO_PROPOSITIONS);

      console.log("DAO_TASKS");
      console.log(this.DAO_TASKS);

      console.log("DAO_MEMBERS");
      console.log(this.DAO_MEMBERS);

      for (let k = 0; k < this.DAO_TASKS.length; k++) {

        if(this.DAO_TASKS[k].paid === false){

          if (this.DAO_TASKS_PENDING.includes(this.DAO_TASKS[k]) === false) {
            this.DAO_TASKS_PENDING.push(this.DAO_TASKS[k]);
          }

        }else {

          if (this.DAO_TASKS_COMPLETED.includes(this.DAO_TASKS[k]) === false) {
            this.DAO_TASKS_COMPLETED.push(this.DAO_TASKS[k]);
          }

        }

      }

      for (let i = 0; i < this.DAO_PROPOSITIONS.length; i++) {

        let p_date = this.DAO_PROPOSITIONS[i].end_time.timep ? new Date(this.DAO_PROPOSITIONS[i].end_time.timep) : new Date(this.DAO_PROPOSITIONS[i].end_time);

        if (this.currentDate > p_date) {

          let poolpayload = {
            poolid: this.DAO_PROPOSITIONS[i].pool_id
          };

          let current_user_vote = await this.$store.dispatch("accounts/getAccountVotePollVotes", poolpayload);

          console.log("current_user_vote");
          console.log(current_user_vote);

          if (current_user_vote !== 0 && current_user_vote !== "0" && current_user_vote !== [] && current_user_vote.length > 0) {
            this.DAO_PROPOSITIONS[i].stake_balance = current_user_vote[0].balance.decimal ? current_user_vote[0].balance.decimal : current_user_vote[0].balance;
          }

          if (this.DAO_PROPOSITIONS_HISTORY.includes(this.DAO_PROPOSITIONS[i]) === false) {
            this.DAO_PROPOSITIONS_HISTORY.push(this.DAO_PROPOSITIONS[i]);
          }

        } else {

          let current_user_vote = await this.$store.dispatch("accounts/getAccountVotePollVotes", hivepayload);

          console.log("current_user_vote");
          console.log(current_user_vote);

          if (current_user_vote !== 0 && current_user_vote !== "0") {
            this.DAO_PROPOSITIONS[i].stake_balance = current_user_vote[0].balance.decimal ? current_user_vote[0].balance.decimal : current_user_vote[0].balance;
          }

          if (this.DAO_PROPOSITIONS_ACTIVE.includes(this.DAO_PROPOSITIONS[i]) === false) {
            this.DAO_PROPOSITIONS_ACTIVE.push(this.DAO_PROPOSITIONS[i]);
          }

        }

      }

      console.log("DAO_PROPOSITIONS_HISTORY");
      console.log(this.DAO_PROPOSITIONS_HISTORY);

      this.ActivePoolCount = this.DAO_PROPOSITIONS.length;

      this.DAO_CONTACTS = this.DAO_SUBJECT_INFO.contacts;

      this.DAO_POOLS = this.DAO_SUBJECT_INFO.pools;

      console.log("DAO_POOLS");
      console.log(this.DAO_POOLS)

      for(let i = 0; i < this.DAO_POOLS.length; i++){

        this.DAO_POOLS[i].balance = await this.getTokenBalance(this.coinName( this.DAO_POOLS[i].pool_token.refName.namespace, this.DAO_POOLS[i].pool_token.refName.name), this.DAO_POOLS[i].account);

      }

      this.DAO_MSGS = this.DAO_SUBJECT_INFO.messages;

      this.DAO_LINKS = this.DAO_SUBJECT_INFO.links;

      this.DAO_POOL_COUNT = this.DAO_SUBJECT_INFO.pool_count;


      const tub_payloadA = {
        tokenA: this.coinName(this.DAO_SUBJECT_INFO.subject_token.refName.namespace, this.DAO_SUBJECT_INFO.subject_token.refName.name),
        name: this.DAO_SUBJECT_INFO.treasury_account
      };

      this.DAO_TREASURY_BALANCE = await this.$store.dispatch("accounts/getBalance", tub_payloadA);


      //Lets check if our user is logged in, if so, lets grab the users state from the blockchain, including their NFT balances
      if (name === null || name === "") {
        if (this.debug) {
          console.log("No user local accountName found.");
        }


      } else {

        let t_name_payload = {
          name: name
        };

        await this.$store.dispatch("accounts/getCurrentBindings", t_name_payload);


      }

      await this.getMsgsToShowNow(0, this.perPage, this.DAO_MSGS);

    }

  },
  async created() {

    this.currentDate = new Date().getTime();

    let name = localStorage.getItem("accountName");
    this.user_name = name;

    await this.reScanPools();

    //Close loading screen
    setTimeout(async () => {
      this.showLoadingModal = false;
    }, 500);


  }

};
</script>

<style scoped>

/deep/ [disabled].input {
  background-color: #11091470  !important;
  border-color: whitesmoke;
  box-shadow: none;
  color: #bf0d0d !important;
}

/deep/ .textarea {
  background-color: rgba(103, 103, 108, 0.1) !important;
  border: none;
  color: #4ae39e;
  box-shadow: inset 0 30px 60px -12px rgb(50 50 93 / 25%), inset 0 18px 36px -18px rgb(0 0 0 / 30%);
}

.email-signature{
  position: relative;
}

.email-signature:after{
  transform: rotateZ(180deg);
  top: auto;
  bottom: 0;
}
.email-signature .signature-details{
  text-align: center;
  width: 46%;
  display: inline-block;
}
.email-signature .signature-icon{
  background-color: #1BC002;
  width: 120px;
  height: 120px;
  padding: 5px;
  border-radius: 50%;
  margin: 0 auto 10px;
}
.email-signature .signature-icon img{
  width: 100%;
  border-radius: 50%;
}
.email-signature .title{
  color: #1BC002;
  font-size: 27px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 0 0 5px;
}
.email-signature .post{
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px 15px 3px;
  box-shadow: 0 0 5px rgba(255,255,255,0.1);
  display: inline-block;
}
.email-signature .signature-content{
  width: 90%;
  padding: 10px 0 15px;
  margin: 15px 0;
  list-style: none;
  vertical-align: top;
  display: inline-block;
  position: relative;
}
.email-signature .signature-content:before,
.email-signature .signature-content:after{
  content: '';
  background: #523a7b;
  height: 100%;
  width: 25px;
  position: absolute;
  left: 10px;
  top: 0;
  z-index: 1;
}
.email-signature .signature-content:after{
  background: linear-gradient(to top right, rgb(57, 36, 94) 50%, transparent 51%);
  height: 10px;
  width: 10px;
  left: 35px;
}
.email-signature .signature-content li{
  color: #44a361;
  background-color: #1b2230;
  font-size: 14px;
  padding: 6px 10px 4px 45px;
  margin-bottom: 7px;
  position: relative;
}
.email-signature .signature-content li:last-child{ margin-bottom: 0; }
.email-signature .signature-content li i{
  color: #7790e9;
  font-size: 14px;
  position: absolute;
  left: 16px;
  top: 9px;
  z-index: 2;
}
@media screen and (max-width:576px){
  .email-signature{ padding: 35px 25px 10px; }
  .email-signature .signature-details,
  .email-signature .signature-content{
    width: 100%;
    margin: 0 0 25px;
  }
}

.stake-btn-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.stake-btn-2 {
  font-size: 14px !important;
  max-height: 40px;
  padding-left: 12px !important;
}

.btn {
  color: #87b6e5;
  background: rgb(34 77 118);
  background-repeat: no-repeat;
  font-family: Source Code Pro,monospace !important;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px 20px;
  box-shadow: rgb(3 102 214 / 22%) 0px 0px 20px 0px;
  z-index: 5;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
}

.btn:focus,
.btn:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    margin-bottom: 30px;
  }
}



/deep/ .select select[disabled], .taginput [disabled].taginput-container.is-focusable, [disabled].textarea, [disabled].input, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .taginput .taginput-container.is-focusable, .taginput fieldset[disabled] .taginput-container.is-focusable, fieldset[disabled] .textarea, fieldset[disabled] .input {
  background-color: #2a4855 !important;
  border-color: #24545f;
  box-shadow: none;
  color: #317884;
}

/deep/ .select select {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #3abb81;
  color: #3abb81;
}

/deep/ .select select option {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #3abb81;
  color: #3abb81;

}

.daoshadow {
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.centered2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/deep/ .modal.is-full-screen > .animation-content, .modal.is-full-screen > .animation-content > .modal-card {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  margin: 0;
  background: transparent !important;
}

/deep/ .b-table .table-wrapper.has-mobile-cards tr:not([class*=is-]) {
  background: transparent !important;
}

/deep/ .b-table .table-wrapper.has-mobile-cards tr {
  background: transparent !important;
}

/deep/ .tabs li.is-active a {
  color: #2cc19e !important;
}

/deep/ .tabs a {
  color: #5275c3;
}

/deep/ .tabs a:hover {
  color: #52bfc3;
}

.pagination-outer {
  text-align: center;
}

.pagination {
  background: rgba(21, 21, 21, 0.32);
  font-family: 'Sniglet', cursive;
  padding: 5px;
  box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  display: inline-flex;
  position: relative;
}

.pagination li a.page-link {
  color: #4ea4c9;
  background-color: #262836;
  font-size: 16px;
  line-height: 33px;
  height: 45px;
  width: 50px;
  margin: 0 2px 0 0;
  border: 1px solid #202020;
  border-top: 1px solid #353535;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.3);
  border-radius: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
  padding: 10px;
}

.pagination li.active a.page-link,
.pagination li a.page-link:hover,
.pagination li.active a.page-link:hover {
  color: rgba(46, 204, 113, 0.44);
  background: #1e202a;
  text-shadow: 0 0 15px rgba(46, 204, 113, 0.5);
  border: 1px solid transparent;
  border-bottom: 1px solid #252525;
  box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, 0.04);
}

.pagination li:first-child a.page-link {
  border-radius: 40px 0 0 40px;
}

.pagination li:last-child a.page-link {
  margin-right: 0;
  border-radius: 0 40px 40px 0;
}

@media only screen and (max-width: 480px) {
  .pagination {
    display: block;
    border-radius: 20px;
  }

  .pagination li {
    margin: 5px 2px;
    display: inline-block;
  }
}


.divider {
  border: none;
  height: 20px;
  width: 100%;
  height: 50px;
  margin-top: 0;
  border-bottom: 1px solid #226484;
  box-shadow: 0 20px 20px -20px rgb(88 141 238 / 56%);
  margin: -50px auto 10px;
}

.content {
  position: relative;
}

.content h2 {
  color: #fff;
  font-size: 40px;
  position: absolute;
  transform: translate(0%, -50%);
}

.content h2:nth-child(1) {
  color: #4a9999;
  -webkit-text-stroke: 1px #0b1717;
}

.content h2:nth-child(2) {
  color: #4a9999;
  animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
    );
  }

  50% {
    clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
    );
  }
}

.form-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}


.btn2 {
  color: #87b6e5;
  background-color: rgba(38, 89, 125, 0.6) !important;
  background-repeat: no-repeat;
  font-family: 'Noto', sans-serif;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}

.btn2:focus,
.btn2:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(38, 89, 125, 0.6);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn2 {
    margin-bottom: 30px;
  }
}

.form-container {
  background-color: #2e3945;
  border-radius: 1rem;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #6ea4db;
  border-width: 20px;
  padding: 40px;
}

.form-container .form-icon {
  font-size: 8px;
  text-align: center;
  line-height: 160px;
  width: 100px;
  height: 100px;
  margin: 0 auto 15px;
  border-radius: 50px;
  box-shadow: 7px 7px 10px #1f272f, -7px -7px 10px #1f272f;
}

.form-container .title {
  color: #ac40ab;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 20px;
}

.form-container .form-horizontal .form-group {
  margin: 0 0 25px 0;
}

.form-container .form-horizontal .form-group label {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.form-container .form-horizontal .form-control {
  color: #333;
  background: #ecf0f3;
  font-size: 15px;
  height: 50px;
  padding: 20px;
  letter-spacing: 1px;
  border: none;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.form-container .form-horizontal .form-control:focus {
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  outline: none;
}

.form-container .form-horizontal .form-control::placeholder {
  color: #808080;
  font-size: 14px;
}

.inset-bg-rounded2 {
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}


.blue-bg {
  background-color: #314b6a;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #6ea4db;
}

.noscroll::-webkit-scrollbar {
  display: none;
}

.col-title-image {
  height: 30px;
  top: 17px;
  margin-top: 6px;
}

.pool-col-title {
  display: flex;
  flex-direction: column;
}


.container3 {
  border-radius: 2rem;
  padding: 1rem;
  /* padding: 25px; */
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), #0f5cbb26 85%), radial-gradient(rgb(38 58 74 / 75%), transparent 70%), linear-gradient(#0e081a00, #211922, #0f142000);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
}

/deep/ .help {
  color: #635286 !important;
}

/deep/ .b-table .table {
  width: 100%;
  border-bottom: 1px solid transparent;
  border-radius: 4px;
  border-collapse: separate;
  background-color: transparent;
  color: #2ac37e;
  font-size: 12px;
  justify-content: center;
}

/deep/ .b-table .table th {
  font-weight: 600;
  color: #6ea4db !important;
  border-bottom: 1px solid #6ea4db;
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  vertical-align: top;
}

/deep/ .table td, .table th {
  border-bottom: 1px solid #2da1bb !important;
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  vertical-align: top;
  padding-top: 10px;
}


.bginput {
  background-color: #1b1d2d !important;
}

.bginput:hover {
  color: #4ede77 !important;
  border: 1px solid #7e4ede !important;
}

.noscroll::-webkit-scrollbar {
  display: none;
}

#style-10::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #200d31;
  border-radius: 10px;
}

#style-10::-webkit-scrollbar {
  width: 10px;
  background-color: #2c2432;
}

#style-10::-webkit-scrollbar-thumb {
  background-color: #2c2432;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
  rgba(0, 0, 0, .2) 25%,
  transparent 25%,
  transparent 50%,
  rgba(0, 0, 0, .2) 50%,
  rgba(0, 0, 0, .2) 75%,
  transparent 75%,
  transparent)
}


.hero.is-dark {
  background-color: #1D1F21
}

li a {
  color: #c5c8c6;
}

li a:hover,
li a:visited,
li a:active {
  text-decoration: none;
  transition: .1s;
}

.txshadow {
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

.reddish {
  color: #380245;
  text-shadow: 0px 0px 4px rgba(17, 156, 5, 0.77) !important;
}

#google {
  color: #CC6666;
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

#google ul li a {
  color: #c5c8c6;
  text-shadow: 0px 0px 4px rgba(59, 87, 58, 0.77);
}

#google ul li a:hover {
  color: #CC6666;
}

#jp {
  color: #81A2BE;
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

#jp ul li a {
  color: #c5c8c6;
}

#jp ul li a:hover {
  color: #81A2BE;
}

#boards {
  color: #B5BD68;
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

#boards ul li a {
  color: #c5c8c6;
}

#boards ul li a:hover {
  color: #B5BD68;
}

#dev {
  color: #B294BB;
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

#dev ul li a {
  color: #c5c8c6;
}

#dev ul li a:hover {
  color: #B294BB;
}

#docs {
  color: #A64848;
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

#docs ul li a {
  color: #c5c8c6;
}

#docs ul li a:hover {
  color: #A64848;
}

#utils {
  color: #597C99;
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

#utils ul li a {
  color: #c5c8c6;
}

#utils ul li a:hover {
  color: #597C99;
}

#web {
  color: #7B8235;
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

#web ul li a {
  color: #c5c8c6;
}

#web ul li a:hover {
  color: #7B8235;
}


.web {
  color: #081309;
  text-shadow: 0px 0px 4px rgba(59, 87, 58, 0.77);
}

.web ul li a {
  color: #c5c8c6;
}

.web ul li a:hover {
  color: #7B8235;
}

#server {
  color: #845C90;
  text-shadow: 0px 0px 4px rgba(5, 29, 3, 0.77) !important;
}

#server ul li a {
  color: #c5c8c6;
}

#server ul li a:hover {
  color: #845C90;
}

@media (min-width: 544px) {
  .container {
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .container {
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  .container {
    font-size: 16px;
  }
}

.form-control {
  background-color: #1D1F21;
  display: none;
  padding: 0px;
  border: none;
  color: #c5c8c6;
}

input[type="search-input"]:focus {
  background-color: transparent;
  color: #c5c8c6;
}

input[type="search-input"]:hover {
  background-color: transparent;
  color: #c5c8c6;
}

#searchform {
  display: block;
  text-align: center;
  width: 100%;
}

form {
  display: inline-block;
  width: 90%;
}

label {
  color: #CC6666;
  display: inline-block;
  float: left;
  padding-right: 1em;
}

input {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
  box-shadow: inset 0 30px 60px -12px rgba(50, 50, 93, 0.25), inset 0 18px 36px -18px rgba(0, 0, 0, 0.30);
}

.label {
  color: #4ae39e;
  display: block;
  font-size: 1rem;
  font-weight: 700;
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient(#3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5%);
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 40px;
}


.machineinterface {
  display: block;
  width: 100%;
  margin: -80px 0px;
  position: relative;
}

.monitor {
  min-height: 100vh;
  padding-top: 85px;
  z-index: 2;
  display: block;
  width: 100%;
  background-color: #47485a;
  position: relative;
  padding-bottom: 40px;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, #26202b08 35px, #26202b0d 70px);
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
}

.monitor-inner {
  padding-top: 15px;
  display: block;
  width: 95%;
  background-image: conic-gradient(#414254 0 10.5%, #414254 11% 11.5%, #414254 12% 38%, #414254 39% 61.5%, #414254 62% 88%, #414254 88.5% 89%, #414254 89.25%);
  margin: auto;
  position: relative;
  top: 20px;
  left: 0;
  right: 0;
  border-radius: 5px;
  margin-top: -51px;
  padding-bottom: 60px;
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient(#3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5%);
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 50px;
}

.screen {
  display: block;
  width: 95%;
  background-color: #4F5555;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  margin-top: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 30px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), transparent 85%), radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%), linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 7px -4px #4f545f inset, 0 -2px 1px #5c6574 inset;
}

.header-bg3 {
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
  height: 1px;
  padding-top: 0px;
}

.b-tabs .tabs {
  overflow: hidden !important;
}

/deep/ .b-tabs .tabs {
  overflow: hidden !important;
}

::v-deep .b-tabs .tabs {
  overflow: hidden !important;
}


.center-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.title-back {
  color: #ffffff;
  font-family: Source Code Pro, monospace !important;
  border-radius: 1rem;
  background: -webkit-linear-gradient(90deg, rgba(49, 56, 64, 0.89), rgba(55, 45, 69, 0.85));
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}


/* card background */
.home-card-bg {
  background: #ebeae5;
}

.inset-bg {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding-bottom: 2rem;
  border-radius: 100px;
}

.inset-bg2:active {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  width: 100%;
  height: 30px;
  position: relative;
  border-radius: 1rem;
  align-self: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 4px;
}


.col-title {
  color: #36c0ea;
  font-size: 14px;
}

.pending-title {
  color: #3cea36;
  font-size: 14px;
}

.pending-stat {
  color: #3cea36;
  font-size: 1em;
}

.pending-col {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.col-shadow1 {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider {
  font-size: 12px;
}

.pool-name {
  font-size: 14px;
  color: #36c0ea;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg2 {
  background-color: #222b3a;
  background: radial-gradient(circle farthest-side at 0% 50%, #252f3e 23.5%, rgba(240, 166, 17, 0) 0) 21px 30px, radial-gradient(circle farthest-side at 0% 50%, #293444 24%, rgba(240, 166, 17, 0) 0) 19px 30px, linear-gradient(#252f3e 14%, rgba(240, 166, 17, 0) 0, rgba(240, 166, 17, 0) 85%, #252f3e 0) 0 0, linear-gradient(150deg, #252f3e 24%, #293444 0, #293444 26%, rgba(240, 166, 17, 0) 0, rgba(240, 166, 17, 0) 74%, #293444 0, #293444 76%, #252f3e 0) 0 0, linear-gradient(30deg, #252f3e 24%, #293444 0, #293444 26%, rgba(240, 166, 17, 0) 0, rgba(240, 166, 17, 0) 74%, #293444 0, #293444 76%, #252f3e 0) 0 0, linear-gradient(90deg, #293444 2%, #252f3e 0, #252f3e 98%, #293444 0%) 0 0 #252f3e;
  background-size: 40px 60px;
}

.pools-bg {
  background: radial-gradient(circle farthest-side at 0% 50%, #202734 5.5%, rgba(240, 166, 17, 0) 0) 21px 60px, radial-gradient(circle farthest-side at 0% 50%, #222d3e 26%, rgba(240, 166, 17, 0) 0) 19px 30px, linear-gradient(#222b3a 14%, rgba(240, 166, 17, 0) 0, rgba(240, 166, 17, 0) 85%, #222b3a 0) 0 0, linear-gradient(150deg, #232c3c 24%, #28354a 0, #222b3a 26%, rgb(255 255 255 / 0%) 0, rgba(240, 166, 17, 0) 74%, #222b3a 0, #222b3a 76%, #222b3a 0) 0 0, linear-gradient(30deg, #222b3a 24%, #2d394c 0, #19202c 26%, rgba(240, 166, 17, 0) 0, rgba(240, 166, 17, 0) 74%, #222b3a 0, #19202c 76%, #222b3a 0) 0 0, linear-gradient(90deg, #293850 2%, #222b3a 0, #19202c 98%, #212d40 0%) 0 0 #1a212e;
  background-size: 40px 60px;
}

.pool-main {
  background-color: #222b3a;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro, monospace !important;
}

.bginput {
  background-color: #3a6a7c21 !important;
}

.pool-section-title {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);

  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #46a9e7;
  font-family: Source Code Pro, monospace !important;
}


.topbackgroundanimation {
  position: absolute;
  height: 200px;
  width: 100%;
  z-index: 1;
}

/* border for item cards */
.cardborder {
  border-radius: 1rem;
  color: #000000;
}

.poolselect-bg {
  background-color: #222b3a !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgb(0 0 0 / 9%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;
}


/deep/ .tabs.is-toggle li.is-active a {
  background-color: #252f3e;
  color: #4ae39e;
  z-index: 1;
  font-family: Source Code Pro, monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}


/deep/ .tabs.is-toggle {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  background-color: #252f3e;
}

/deep/ .tabs.is-toggle a {
  margin-bottom: 0;
  position: relative;
  font-family: Source Code Pro, monospace !important;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  color: #476074;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: #222b3a;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: #222b3a;
  border: none;
  color: #00b89c;
}


#style-8::-webkit-scrollbar-track {
  border: 1px solid black;
  background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar-thumb {
  background-color: #000000;
}


.pools-card {
  padding-top: .5rem;
  color: #4ae39e;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow {
  box-shadow: rgba(0, 0, 0, 0.56) 8px -5px 40px;
  border-radius: 30px;
}

.stake-btn-col {
  display: flex;
  justify-content: center;
}



@media screen and (max-width: 769px), print {
  .screen-cutout {
    background-image: none !important;
    background-color: transparent !important;
  }

  /deep/ .super-select-dropdown {
    background-color: #333b4c !important;
    border: 1px solid !important;
    border-color: #2f597d !important;
    color: #67b6ef !important;
    width: 380px !important;
  }
}

/* page loading style */
.loaderstyle {
  z-index: 1;
  position: absolute !important;
}

#wraper {
  width: 400px;
  height: 350px;
  margin: 90px 11px;
  position: relative;
  border-radius: 40px;
}

#wraper2 {
  width: 450px;
  height: 350px;
  margin: 0 auto;
  position: relative;
  border-radius: 40px;
  margin-left: -36px;
}

/deep/ input {
  background-color: transparent;
  border: none;
  color: #4ae39e;
}

input {
  background-color: rgba(103, 103, 108, 0.1) !important;
  border: none;
  color: #4ae39e;
}


</style>
<style lang="scss" scoped>

.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro, monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

$message-header-background-color: #e3f;


.loading-page-container {
  opacity: .95;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  z-index: 1;

}

.loading-page-container .progress {
  position: relative;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 6px solid #20182d;
  background-color: #2c2938;
  background-image: radial-gradient(circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2%);
  background-blend-mode: hard-light;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;

  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 1;
}

.loading-page-container .progress .inner {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: 50%;
  margin-left: -4px;
  margin-top: -1px;
  width: 196.5px;
  height: 196.5px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.loading-page-container .progress .inner .water {
  opacity: .55;
  position: absolute;
  z-index: 1;
  width: 200%;
  height: 200%;
  left: -50%;
  border-radius: 40%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  animation-name: spin;
  top: 55%;
  background: #32BAFA;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  box-shadow: 0 0 20px #152A4A;

}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 0.5rem;
  color: #296992;
}

.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}


.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}


// for styles that do not have ascii characters setting the height
$height: 15px;

.outer {
  margin-bottom: $height;
  width: 100%;
  height: $height;
}

.plain {

  &.outer {
    border: 1px solid limegreen;
  }

  .inner {
    width: 90%;
    background-color: limegreen;
    height: $height;
  }
}

.borderless .inner {
  width: 90%;
  background-color: limegreen;
  height: $height;
}

.punctuation.outer {
  border: 1px solid limegreen;
  line-height: 1.35;
}

@-webkit-keyframes progress-bar-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes spinning {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes pulsing-opacity {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}


.plain .inner, .borderless .inner {
  animation: progress-bar-width steps(20) 5s infinite;
}

.pipes .inner, .blocks .inner, .blocks-gaps {
  animation: progress-bar-width 5s infinite;
  overflow: hidden;
  animation-timing-function: linear;
}

.blocks-gaps .inner {
  letter-spacing: 2px;
}

.activity {
  text-align: center;
  overflow: hidden !important;
}

.spinning {
  animation: spinning steps(8, end) 2s infinite;
}

.pulsing {
  animation: pulsing-opacity steps(3) 0.5s infinite alternate;
}


</style>
<style scoped>
.container3 {
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  /* padding: 25px; */
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), #0f5cbb26 85%), radial-gradient(rgb(38 58 74 / 75%), transparent 70%), linear-gradient(#0e081a00, #211922, #0f142000);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
}

.container_msg {
  border-style: solid;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  border-width: 5px 5px 5px 5px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(78deg, transparent 15%, rgb(129 132 135 / 0%), rgb(67 96 92 / 10%) 85%), radial-gradient(rgb(20 45 64 / 0%), transparent 70%), linear-gradient(#0e081a00, #1b233130, #0f142066);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
}

.container_about {
  border-style: solid;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  border-width: 5px 5px 5px 5px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgb(129 132 135 / 0%), #0f5cbb1a 85%), radial-gradient(rgb(38 58 74), transparent 70%), linear-gradient(#0e081a00, #1b233130, #0f142066);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
}

.container_links {
  border-style: solid;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  border-width: 5px 5px 5px 0px;
  border-left: 0px;
  /* padding: 25px; */
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgb(129 132 135 / 0%), #0f5cbb1a 85%), radial-gradient(rgb(38 58 74), transparent 70%), linear-gradient(#0e081a00, #1b233130, #0f142066);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
}

.container4 {
  /* padding: 25px; */
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-color: #1a222e;
  box-shadow: rgb(50 50 93 / 25%) 0px 30px 60px -12px inset, rgb(0 0 0 / 30%) 0px 18px 36px -18px inset;
  border: 2px solid #1b222f;
}

.cow {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#14ff7d, #00f3ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
}

.cow2 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(31 86 112);
  background: -webkit-linear-gradient(#50ffd7, #00b2ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
}

.cow3 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#1cd7ce, #00f3ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
}

.cow4 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(7 8 12);
  background: -webkit-linear-gradient(#b2f8ff, #6889ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
}

.cow5 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#14ff7d, #11ff00) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
}

</style>

<style lang="scss" scoped>


.container2 {
  background-color: #1D1F21;
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  padding: 25px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background: -webkit-linear-gradient(#3e10463d, #21333e70) !important;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 37px 59px #0e192257 inset, 0 0px 2px #ff0ef8 inset;
  -webkit-animation: scanline-data-v-0d33d4c2 8s linear infinite;
  animation: scanline-data-v-0d33d4c2 8s linear infinite;
}

article {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 900px) {
    padding: 5px;
  }

  @media (max-width: 899px) {
    padding-bottom: 56px;
  }
  background-color: rgba(192, 192, 192, 0);
  color: #323232;
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  position: relative;

  & > * + * {
    margin-top: 1.25em;
  }

  &:after {
    content: "🥩";
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding-bottom: 0.25em;
    position: absolute;
    top: 0.75em;
    right: 0.75em;
    width: 1.75em;
    height: 1.75em;
    filter: invert(100) opacity(40%);

    background-color: transparent;
  }
}

.headline {
  font-size: 2.5em;
  font-weight: 700;
}

.subhead {
  font-size: 1.25em;
  margin-top: 0.25em;
}

.article-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    width: 3em;
    height: 3em;
    flex-shrink: 0;
  }

  div:first-of-type {
    margin-left: 0.75em;
    font-size: 0.875em;
    margin-right: auto;
    padding-right: 0.75em;
  }

  div:last-of-type {
    font-size: 0.875em;
  }
}

aside {
  padding: 4em;
  border-right: 1px solid rgba(171, 15, 232, 0.1);
  border-bottom: 1px solid rgba(171, 15, 232, 0.1);
  border-left: 1px solid rgba(171, 15, 232, 0.1);
  border-top: 1px solid rgba(171, 15, 232, 0.1);
  clear: both;
  text-shadow: 0 0 10px, 0 0 40px;
  background-color: rgb(46 40 66 / 39%);
}

.tag {
  display: inline-block;
  margin-right: 0.25em;
  margin-top: 0.25em;
  border-right: 1px solid rgba(238, 238, 238, 0.19);
  border-bottom: 1px solid rgba(238, 238, 238, 0.13);
  border-left: 1px solid #444;
  border-top: 1px solid #444;
  padding: 0.125em 0.375em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}


figure {
  background-color: #c0c0c0;
  box-shadow: 0 0 0 1px #eee, 0 0 0 2px #c0c0c0, 0 0 0 3px #444;
  position: relative;
  margin-bottom: 1.25em;
  width: calc(100% + 1.5em + 2em);
  text-shadow: 0 0 10px, 0 0 40px;
  @media (min-width: 800px) {
    max-width: 50%;
  }

  &:nth-of-type(odd) {
    float: left;
    margin-right: 1.5em;
    margin-left: -3em;
  }

  &:nth-of-type(even) {
    float: right;
    margin-left: 1.5em;
    margin-right: -3em;
  }

  &:after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    background-image: linear-gradient(
            to top,
            rgba(#ff9100, 0.2) 0%,
            rgba(#ffe630, 0.2) 60%
    ),
    linear-gradient(20deg, rgba(#ff0, 0.5) 0%, rgba(#ff0, 0) 35%);
    box-shadow: inset 0px 0px 100px rgba(0, 0, 20, 1);
    z-index: 1;
  }

  img {
    // opacity: 0;
    // filter: hue-rotate(90deg);
    // filter: sepia(20%) brightness(10%) contrast(130%)
  }

  figcaption {
    font-family: "DotGothic16", monospace;
    background-color: #6f4b80;
    box-shadow: inset 0 -1px 0 0 #020202;
    color: #eee;
    text-shadow: 0 0 10px, 0 0 40px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.25em;
    font-size: 0.875em;
    z-index: 2;
  }
}

.blink {
  opacity: 1;
  animation: blink 1s linear infinite;
}

.scanline {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba(255, 255, 255, 0.02);
  animation: scanline 8s linear infinite;
  pointer-events: none;

  &:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: rgba(255, 255, 255, 0.02);
    animation: scanline 8s linear infinite;
    pointer-events: none;
    z-index: 10;
  }

}

// Scanline height 1005
@keyframes scanline {
  0.01% {
    height: 0;
  }
  99.99% {
    height: 100%
  }
  100% {
    height: 0;
  }
}
</style>
<style lang="scss" scoped>

.container2 {
  background-color: #1D1F21;
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1d2532;
  border-top-color: #1a212c;
  border-bottom-color: #19202a;
  padding: 25px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background: -webkit-linear-gradient(#3e10463d, #21333e70) !important;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 37px 59px #0e192257 inset, 0 0px 2px #ff0ef8 inset;
  -webkit-animation: scanline-data-v-0d33d4c2 8s linear infinite;
  animation: scanline-data-v-0d33d4c2 8s linear infinite;
}

main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro, monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

$message-header-background-color: #e3f;


.loading-page-container {
  opacity: .95;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  z-index: 1;

}

.loading-page-container .progress {
  position: relative;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 6px solid #20182d;
  background-color: #2c2938;
  background-image: radial-gradient(circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2%);
  background-blend-mode: hard-light;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;

  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 1;
}

.loading-page-container .progress .inner {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: 50%;
  margin-left: -4px;
  margin-top: -1px;
  width: 196.5px;
  height: 196.5px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.loading-page-container .progress .inner .water {
  opacity: .55;
  position: absolute;
  z-index: 1;
  width: 200%;
  height: 200%;
  left: -50%;
  border-radius: 40%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  animation-name: spin;
  top: 55%;
  background: #32BAFA;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  box-shadow: 0 0 20px #152A4A;

}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 0.5rem;
  color: #296992;
}

.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}


.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}


// for styles that do not have ascii characters setting the height
$height: 15px;

.outer {
  margin-bottom: $height;
  width: 100%;
  height: $height;
}

.plain {

  &.outer {
    border: 1px solid limegreen;
  }

  .inner {
    width: 90%;
    background-color: limegreen;
    height: $height;
  }
}

.borderless .inner {
  width: 90%;
  background-color: limegreen;
  height: $height;
}

.punctuation.outer {
  border: 1px solid limegreen;
  line-height: 1.35;
}

@-webkit-keyframes progress-bar-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes spinning {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes pulsing-opacity {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}


.plain .inner, .borderless .inner {
  animation: progress-bar-width steps(20) 5s infinite;
}

.pipes .inner, .blocks .inner, .blocks-gaps {
  animation: progress-bar-width 5s infinite;
  overflow: hidden;
  animation-timing-function: linear;
}

.blocks-gaps .inner {
  letter-spacing: 2px;
}

.activity {
  text-align: center;
  overflow: hidden !important;
}

.spinning {
  animation: spinning steps(8, end) 8s infinite;
}

.pulsing {
  animation: pulsing-opacity steps(3) 0.5s infinite alternate;
}

</style>
