<template>
  <section>
    <div class="card3d" v-bind:class="[flipped ? useexpand ? 'flip-container flipped' : 'flip-container flippedhm' : 'flip-container']">
      <div class="flipper">
        <div v-if="idexists === false" class="front">
          <div class="card form-container frontcardbg" v-bind:class="[isPoolOwner ? 'highlight' : '']">
            <div v-if="token_image !== 'https://www.link_to_my_image.com' && token_image !== '?'" class="card__product-img">
              <img :src="token_image" alt="NFT" style="max-width: 200px !important" />
            </div>
            <div v-if="token_image === 'https://www.link_to_my_image.com'" class="card__product-img">
              <img src="../assets/nft-placeholder.svg" alt="stake" style="margin-top: 10px; width: 150px;">
            </div>
            <div v-if="token_image === '?'" class="card__product-img">
              <img src="../assets/nft-placeholder.svg" alt="?" style="margin-top: 10px; width: 150px;">
            </div>
            <div class="card__content">
              <p v-if="token_name !== '0'" class="card__name pt-2">{{ token_name.slice(0,23) }} </p>
              <div v-if="show_market_buttons && l_isPoolOwner === false" @click="clickBuyNFT()" style=" overflow-wrap: anywhere !important; position: absolute; top: 265px; " class="is-buttonblue"><span style="overflow-wrap: anywhere !important;">Buy for {{l_price}} {{this.coinDisplayName(l_token)}}</span></div>
              <div v-if="l_isPoolOwner === true && l_forsale === true" style="position: absolute; top: 265px;" class="is-sale">{{l_price}} {{this.coinDisplayName(l_token)}}</div>
            </div>
          </div>
          <a v-if="show_flipper === true" v-on:click="flipped=true; $emit('flipped', true)" class="btn2" v-bind:class="[isPoolOwner ? 'highlight-span' : '']" href="#">
            <span> > </span>
          </a>
        </div>
        <div v-if="idexists === true" class="front">
          <div class="card form-container frontcardbg" v-bind:class="[isPoolOwner ? 'highlight' : '']">
            <div class="card__content">
              <p class="card__name pt-2" style="color:#f7595f">ERROR NFT ID TAKEN: {{ pool_reward_token_id }} </p>
            </div>
          </div>
          <a v-if="show_flipper === true" v-on:click="flipped=true; $emit('flipped', true)" class="btn2" v-bind:class="[isPoolOwner ? 'highlight-span' : '']" href="#">
            <span> > </span>
          </a>
        </div>
        <div class="back">
          <slot name="back"></slot>

          <div class="card form-container" v-bind:class="[isPoolOwner > 0 ? 'highlight' : '']">

            <div @click="clickCollection()" v-if="show_extra_buttons === true" class="card__content-bottom2">
              <b-tooltip type="is-dark" :label="'Go to NFT Collection Page'"><div class="card__maximize">
                <b-icon
                    pack="fas"
                    icon="window-maximize"
                    size="is-small" style="position: relative; top:2px; ">
                </b-icon>️
              </div></b-tooltip>
            </div>
            <div @click="openTransferModal" v-if="show_extra_buttons === true" class="card__content-bottom3">
              <b-tooltip type="is-dark" :label="'Transfer NFT'"><div  class="card__maximize">
                <b-icon
                    pack="fas"
                    icon="gift"
                    size="is-small" style="position: relative; top:2px; ">
                </b-icon>️
              </div></b-tooltip>
            </div>
            <div @click="openMarketModal" v-if="show_extra_buttons === true" class="card__content-bottom4">
              <b-tooltip type="is-dark" :label="'Add / Remove from Market'"><div  class="card__maximize">
                <b-icon
                    pack="fas"
                    icon="shopping-cart"
                    size="is-small" style="position: relative; top:2px; ">
                </b-icon>️
              </div></b-tooltip>
            </div>

            <div class="back-col-main">



              <div  class="card__content-apy-back">
                <div class="card__price_apy_back">
                  <p @click="clickCollection()" class="collectionlink"><b-tooltip type="is-dark" :label="'Open collection page'"><span class="collectionlink">{{ token_collection }}</span></b-tooltip></p>
                </div>
              </div>

              <div class="columns mb-1 back-col" style="position: relative; top: 0px; background-color: rgba(38,43,56,1); border-radius: 1rem;">
                <div class="column is-full is-align-self-center p-1">
                  <div class="columns">
                    <div class="column"  v-bind:class="[$screen.width > 768 ? 'stake-col-lg' : 'stake-col-sm']">
                      <div class="field-body" style="max-width: 233px;">
                        <div class="field cardfieldbg" style="display:flex; justify-content: space-evenly !important; overflow-wrap: anywhere;" id="style-11">
                          <div class="columns is-flex-direction-column">
                            <div class="column pt-3" style="height:40px; padding-bottom: 0px; margin-bottom: 0px;">
                              <p class="control is-expanded back-col-text" style="color:#3abb81">
                                {{token_name}}
                              </p>
                            </div>
                            <div class="column pt-3" style="height:40px; padding-bottom: 0px; margin-bottom: 0px;">
                              <br/>
                              <div class="divider is-centered"></div>
                              <br/>
                            </div>
                            <div class="column pt-0" style="height:40px; padding-bottom: 0px; padding-top: 0px; margin-top:0px; margin-bottom: 0px;">
                              <p v-if="wlactive === false" class="control is-expanded back-col-text" style="color:#3ab0bb; font-size: 10px;">
                                {{pool_reward_token_id.slice(0,30)}}
                              </p>
                              <p v-if="wlactive === true" class="control is-expanded back-col-text" style="color:#3ab0bb; font-size: 10px;">
                                This NFT is Hidden
                              </p>
                            </div>
                            <div class="column pt-3" style="height:40px; padding-bottom: 0px; margin-bottom: 0px;">
                              <br/>
                              <div class="divider is-centered"></div>
                              <br/>
                            </div>
                            <div class="column" style="margin-top: -10px">
                              <p class="control is-expanded back-col-text">
                                {{token_description}}
                              </p>
                            </div>
                            <div class="column pt-3" style="height:40px; padding-bottom: 0px; margin-bottom: 0px;">
                              <br/>
                              <div class="divider is-centered"></div>
                              <br/>
                            </div>
                            <div class="column">
                              <p class="control is-expanded back-col-text" style="color:#4d83db; font-family: Source Code Pro, monospace;">
                                NFT Attributes
                              </p>
                            </div>
                            <div class="column" style="margin-top: -20px">
                              <p v-for="p in token_strings" v-bind:key="p" class="control is-expanded back-col-text mt-1 mb-1 p-1" style="color:#4d83db !important; font-size: 10px; border: 1px dashed #3828a0;">
                                {{p}}
                              </p>
                            </div>
                            <div class="column">
                              <p class="control is-expanded back-col-text" style="color:#4d83db !important; font-size: 10px; border: 1px dashed #3828a0;">
                                {{token_decimals}}
                              </p>
                            </div>
                            <div class="column">
                              <p class="control is-expanded back-col-text" style="color:#4d83db !important; font-size: 10px; border: 1px dashed #3828a0;">
                                {{token_integers}}
                              </p>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>



          </div>
          <a v-if="show_flipper === true" v-on:click="flipped=false; $emit('flipped', false)" class="btn2" v-bind:class="[isPoolOwner > 0 ? 'highlight-span' : '']" href="#">
            <span> &lt; </span>
          </a>
          <a v-if="show_flipper === true" v-on:click="flipped=false; $emit('flipped', false)" class="btn2" v-bind:class="[isPoolOwner > 0 ? 'highlight-span' : '']" href="#">
            <span> &lt; </span>
          </a>
        </div>
      </div>

    </div>
    <!--Transfer NFT Modal-->
    <b-modal v-if="this.showTransferModal === true" v-model="showTransferModal" :width="640" scroll="keep"
             style="border-radius:20px;">

      <div class="form-bg" style="border-radius:20px;">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-md-offset-4 blue-bg">
              <div class="form-container form-shadow" style="height: 100% !important">
                <div class="form-icon"><b-icon
                    pack="fas"
                    icon="paper-plane"
                    size="is-large" style="position: relative; top:35px; height: 25px; width: 25px; font-size: 60px; font-weight: 700; left: 35px ">
                </b-icon>️<br/><span style="font-size: 10px;position: relative;top: -196px;left: 0px;z-index: 4;">Transfer</span></div>

                <b-message v-if="this.getAccountExists() === true && accountNameToVerify !== 'null' && accountNameToVerify !== ''" class="is-success" style="background-color: rgb(9 40 52);">
                  <div class="columns is-centered pb-0">
                    <div class="column is-centered msg-center" style="overflow-wrap: anywhere !important;">
                      Receiver K:Account Found!
                    </div>
                  </div>
                </b-message>

                <b-message v-if="this.getAccountExists() === false || accountNameToVerify === 'null'" class="is-danger" style="background-color: rgb(9 40 52);">
                  <div class="columns is-centered pb-0">
                    <div class="column is-centered msg-center" style="overflow-wrap: anywhere !important;">
                      Receiver K:Account Not Found!
                    </div>
                  </div>
                </b-message>



                <form class="form-horizontal">
                  <div class="form-group">
                    <label>RECEIVER K:Account</label>
                    <input @change="autoVerify()" @input="autoVerify()" class="form-control" style="width:100%;"
                           v-model="accountNameToVerify" placeholder="Receiver k:Account">
                    <br/>
                    <h3 class="accountHeader">
                      Enter the k:account you would like to Transfer this NFT to
                    </h3>
                  </div>
                  <div class="columns is-mobile is-centered">
                    <div class="column">
                      <div class="notification has-text-centered inset-bg-rounded2" style="background-color: #2d3a45;">
                        <div @click="clickTransferNFT()" class="btn3 btn-lg stake-btn"
                             style="border-radius: 1rem !important;">
                          <span>Transfer</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!--Market Status Modal-->
    <b-modal v-if="this.showMarketModal === true" v-model="showMarketModal" :width="640" scroll="keep"
             style="border-radius:20px;">

      <div class="form-bg" style="border-radius:20px;">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-md-offset-4 blue-bg">
              <div class="form-container form-shadow" style="height: 100% !important">
                <div class="form-icon"><b-icon
                    pack="fas"
                    icon="shopping-cart"
                    size="is-large" style="position: relative; top:35px; height: 25px; width: 25px; font-size: 60px; font-weight: 700; left: 35px ">
                </b-icon>️<br/><span style="font-size: 10px;position: relative;top: -196px;left: 0px;z-index: 4;">Market Status</span></div>


                <form class="form-horizontal">
                  <div class="form-group" :style="forSale ? 'color:#3abb81' : '' ">
                    <section class="pt-5">
                      <b-switch
                          v-model="forSale"
                          :rounded="false"
                          :outlined="false"
                          type="is-success"
                          :left-label='false'
                          :passive-type='null'><span style="font-size:20px">{{ forSale ? "This NFT is for sale" : "This NFT is NOT for sale" }}</span></b-switch>
                    </section>


                    <br/>
                    <div class="divider2 is-centered"></div>
                    <br/>


                      <div v-if="forSale" class="field-body">
                        <div class="field has-addons">
                          <p class="control">
                                                      <span class="select">
                                                        <select v-model="sellToken">
                                                                  <option v-if="token === 'coin'" value="coin">KDA</option>
                                                                  <option v-if="token === 'coin' || token === 'free.stake-token'"  value="free.stake-token">STAKE</option>
                                                                  <option v-if="token === 'coin' || token === 'lago.kwUSDC' " value="lago.kwUSDC">kwUSDC</option>
                                                                  <option v-if="token === 'coin'" value="iscustom">Custom</option>
                                                                  <option v-if="token !== 'coin' && token !== 'free.stake-token' && token !== 'lago.kwUSDC'" value="coin">{{token}}</option>
                                                        </select>
                                                      </span>
                          </p>
                          <p class="control is-expanded">
                            <ValidationObserver>
                              <ValidationProvider
                                  :rules="{required: true, greaterthan: 0, regex: /^[+-]?((\d+(\.\d*))|(\.\d+))$/}"
                                  vid="reward_amount" name="Reward Amount"
                                  v-slot="{ errors, valid }">
                                <input v-model="sellPrice"
                                       expanded
                                       @input="checkValidInput(valid)"
                                       class="input select" type="number"
                                       step="0.01"
                                       placeholder="Amount of Reward Token">
                                <span class="error is-danger validation-msg" style="color:#f7595f;">{{
                                    errors[0]
                                  }}</span>
                                <span v-if="!errors[0] && sellToken !== 'iscustom'"
                                      class="error validation-msg-green is-info">This NFT is on sale for {{sellPrice}} {{sellToken}}</span>
                                <span v-if="!errors[0] && sellToken === 'iscustom'"
                                      class="error validation-msg-green is-info">This NFT is on sale for {{sellPrice}} {{sellNftCustomModuleNamespace}}.{{sellNftCustomModuleName}}</span>
                                <br/>
                              </ValidationProvider>
                            </ValidationObserver>
                          </p>
                        </div>
                      </div>

                    <b-field v-if="forSale === true && sellToken === 'iscustom'"
                             custom-class="is-small"
                             horizontal>
                      <template #label>
                        <b-tooltip type="is-dark" multilined
                                   label="All tokens have a module name (contract name), what is the name of the contract which defines your stake token?.">
                          <label class="label" style="width:100px; color: #3abb81 !important">Token Module
                            Name:</label>
                        </b-tooltip>
                      </template>
                      <div class="field-body">
                        <div class="field has-addons">
                          <p class="control is-expanded">
                            <b-field type="is-info"
                                     message="Module name of the fungible-v2 Stake Token contract">
                              <ValidationObserver
                                  v-if="sellToken === 'iscustom'">
                                <ValidationProvider :rules="{required: true}"
                                                    vid="fungiv2reward_name"
                                                    name="Fungiv2Reward Name"
                                                    v-slot="{ errors, valid }">
                                  <input v-model="sellNftCustomModuleName"
                                         @change="verifyTokenExists(sellNftCustomModuleNamespace, sellNftCustomModuleName)"
                                         @input="[checkValidInput(valid), verifyTokenExists(sellNftCustomModuleNamespace, sellNftCustomModuleName) ]"
                                         class="input select"
                                         type="text"
                                         placeholder="token-module-name">
                                </ValidationProvider>
                              </ValidationObserver>
                            </b-field>
                          </p>
                        </div>
                      </div>
                    </b-field>

                    <b-field  v-if="forSale === true && sellToken === 'iscustom'"
                             custom-class="is-small"
                             horizontal>
                      <template #label>
                        <b-tooltip type="is-dark" multilined
                                   label="All Kadena modules have a namespace, what is the namespace for the token this NFT must be sold for?">
                          <label class="label" style="width:100px; color: #3abb81 !important">Token Module
                            Namespace:</label>
                        </b-tooltip>
                      </template>
                      <div class="field-body">
                        <div class="field has-addons">
                          <p class="control is-expanded">
                            <b-field type="is-info"
                                     message="Module namespace for the fungible-v2 token this NFT must be purchased with">
                              <ValidationObserver
                                  v-if="sellToken === 'iscustom'">
                                <ValidationProvider :rules="{required: true}"
                                                    vid="fungiv2reward_namespace"
                                                    name="Fungiblev2Reward Namespace"
                                                    v-slot="{ errors, valid }">
                                  <input v-model="sellNftCustomModuleNamespace"
                                         @change="verifyTokenExists(sellNftCustomModuleNamespace, sellNftCustomModuleName)"
                                         @input="[checkValidInput(valid), verifyTokenExists(sellNftCustomModuleNamespace, sellNftCustomModuleName) ]"
                                         class="input select"
                                         type="text" placeholder="free">
                                </ValidationProvider>
                              </ValidationObserver>
                            </b-field>
                          </p>
                        </div>
                      </div>
                    </b-field>

                  </div>
                  <div class="columns is-mobile is-centered">
                    <div class="column">
                      <div v-if="localModuleExists === true" class="notification has-text-centered inset-bg-rounded2" style="background-color: #2d3a45;">
                        <div @click="clickUpdateMarket()" class="btn3 btn-lg stake-btn"
                             style="border-radius: 1rem !important;">
                          <span>UPDATE NFT MARKET STATUS</span>
                        </div>
                      </div>
                      <div v-if="localModuleExists === false" class="notification has-text-centered inset-bg-rounded2" style="background-color: #2d3a45;">
                        <div @click="closeTransferModal" class="btn3 btn-lg stake-btn"
                             style="border-radius: 0rem !important; background-color: rgba(132,29,29,0.68) !important">
                          <span>Error: Token not found</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </section>

</template>

<script>

import {mapGetters, mapState} from "vuex";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {double, regex, required} from "vee-validate/dist/rules";

//Rules for validation
extend("required", {
  ...required,
  message: "This field is required"
});
extend("double", {
  ...double,
  message: "Example: 100.00"
});
extend("regex", {
  ...regex,
  message: "Decimal required, Example: 0.0 or 100.0"
});
extend("lpNotKdaKda", {
  validate(value, args) {
    console.log("value " + value);
    console.log("args " + args.test);
    return value !== args.test;
  },
  params: ["test"],
  message: "LP Token Pairs must be different"
});
extend("lpNotSameCustom", {
  validate(value, args) {
    console.log("value " + value);
    console.log("args " + args.test[0] + args.test[1] + args.test[2] + args.test[3]);
    return args.test[0] + args.test[1] !== args.test[2] + args.test[3];
  },
  params: ["test"],
  message: "LP Token Pairs must be different"
});
extend("greaterthan", {
  params: ["target"],
  validate(value, {target}) {
    return value > target;
  },
  message: "This field must be greater than {target}"
});
extend("greaterthanequalto", {
  params: ["target"],
  validate(value, {target}) {
    return value >= target;
  },
  message: "This field must be greater than or equal to {target}"
});
extend("lessthanequalto", {
  params: ["target"],
  validate(value, {target}) {
    return value <= target;
  },
  message: "This field must be less than or equal to {target}"
});
extend("hardcap", {
  params: ["target"],
  validate(value, {target}) {
    return value >= target;
  },
  message: "Hard Cap must be greater than or equal to (Sell Quantity * Sell Price)"
});
extend("lessthan", {
  params: ["target"],
  validate(value, {target}) {
    return value <= target;
  },
  message: "This field must be less than or equal to {target}"
});

// import Accordion from "./accordion.vue";
// import AccordionItem from "./accordion-item";

export default {
  name: "FlipCard",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    accountApproved: {
      type: Boolean,
      default: false,
    },
    pool_name: {
      type: String,
      default: "0",
    },
    pool_id: {
      type: [String, Number],
      default: "0"
    },
    user_account: {
      type: String,
      default: "0",
    },
    pool_apy: {
      type: [String, Number],
      default: "0",
    },
    pool_balance: {
      type: [String, Number],
      default: "0",
    },
    pool_claim_wait_seconds: {
      type: [String, Number],
      default: "0",
    },
    pool_end_time: {
      type: [String, Object],
      default: "0",
    },
    pool_has_vesting_connection: {
      type: Boolean,
      default: false,
    },
    pool_initialized: {
      type: Boolean,
      default: false,
    },
    pool_paid: {
      type: [String, Number],
      default: "0",
    },
    pool_contract: {
      type: String,
      default: "0",
    },
    pool_stake_token: {
      type: [String, Object],
      default: "0",
    },
    pool_reward_token: {
      type: [String, Object],
      default: "0",
    },
    pool_reward_token_id: {
      type: String,
      default: "stakefactorynull",
    },
    pool_stakers: {
      type: [String, Number],
      default: "0",
    },
    pool_start_time: {
      type: [String, Object],
      default: "0",
    },
    pool_tokens_locked: {
      type: [String, Number],
      default: "0",
    },
    pool_vesting_pool_id: {
      type: [String, Number],
      default: "0",
    },
    pool_withdraw_duration: {
      type: [String, Object],
      default: "0",
    },
    stake_last_claimed: {
      type: [String, Object],
      default: "0",
    },
    stake_last_updated: {
      type: [String, Object],
      default: "0",
    },
    stake_last_withdraw: {
      type: [String, Object],
      default: "0",
    },
    stake_start_time: {
      type: [String, Object],
      default: "0",
    },
    stake_balance: {
      type: [String, Number],
      default: "0",
    },
    stake_rewards: {
      type: [String, Number],
      default: "0",
    },
    stake_pending_rewards: {
      type: [String, Number],
      default: "0",
    },
    token_image: {
      type: String,
      default: "0",
    },
    stake_token_image: {
      type: String,
      default: "0",
    },
    is_stake_nft: {
      type: Boolean,
      default: false,
    },
    show_flipper: {
      type: Boolean,
      default: true,
    },
    token_name: {
      type: String,
      default: "?",
    },
    token_description: {
      type: String,
      default: "?",
    },
    token_strings: {
      type: [String, Array],
      default: "",
    },
    token_decimals: {
      type: [String, Array],
      default: "",
    },
    token_integers: {
      type: [String, Array],
      default: "",
    },
    token_collection: {
      type: String,
      default: "?",
    },
    is_flipped: {
      type: Boolean,
      default: false,
    },
    show_extra_buttons: {
      type: Boolean,
      default: false,
    },
    show_market_buttons: {
      type: Boolean,
      default: false,
    },
    forsale: {
      type: Boolean,
      default: false,
    },
    price: {
      type: [String, Number],
      default: "0",
    },
    token: {
      type: String,
      default: "?",
    },
    factoryaccount: {
      type: String,
      default: "?",
    },
    isPoolOwner: {
      type: Boolean,
      default: false,
    },
    wlactive: {
      type: Boolean,
      default: false,
    },
    idexists: {
      type: Boolean,
      default: false,
    }
  },
  data: function () {
    return {
      debug: false,
      flipped: this.is_flipped,
      showStakeControls: false,
      showTransferModal: false,
      showMarketModal: false,
      inputisvalid: false,
      sellNftCustomModuleName: "stake-token",
      sellNftCustomModuleNamespace: "free",
      forSale: this.l_forsale,
      sellPrice: 0.0,
      sellToken: "free.stake-token",
      stakeAmount: 0.0,
      useexpand: false,
      card: this.$el,
      showPendingRewards: true,
      bounds: 0,
      accountNameToVerify: "",
      localModuleExists: true,
      isMakingNFTTransaction: false,
      isMakingTransaction: false,
      isUpdatingMarket: false,
      isUpdatingMarket_nft: "",
      isUpdatingMarket_message: "",
      isTransferring: false,
      isTransferring_nft: "",
      isTransferring_receiver: "",
      isBuying: false,
      isBuying_nft: "",
      isBuying_message: "",
      l_currentPool: [],
      l_token: this.token,
      l_price: this.price,
      l_forsale: this.forsale,
      l_factoryaccount: this.factoryaccount,
      l_isPoolOwner: this.isPoolOwner
    };
  },
  watch: {
    //lets watch our transactionPolling variable
    transactionPolling: async function (newValue, oldValue) {
      if (this.debug) {
        console.log("nftcard getTransactionPolling newValue: " + newValue);
        console.log("nftcard getTransactionPolling oldValue: " + oldValue);
      }

      if (newValue === true && this.isMakingNFTTransaction === true) {

        this.isMakingTransaction = true;

        let message1 = "Confirming new transaction with the blockchain";
        if (this.isUpdatingMarket === true) {
          message1 = "Updating Market for " + this.isUpdatingMarket_nft + " to "+this.isUpdatingMarket_message;
        } else if (this.isTransferring === true) {
          message1 = "Transferring NFT " + this.isTransferring_nft + " to "+this.isTransferring_receiver;
        }else if (this.isBuying === true) {
          message1 = "Buying NFT " + this.isBuying_nft + " for "+this.isBuying_message;
        }

        this.$buefy.toast.open({
          duration: 10000,
          message: message1,
          type: "is-info",
          pauseOnHover: true
        });

      } else if (newValue === false && this.isMakingNFTTransaction === true) {

        this.isMakingTransaction = false;
        this.isMakingNFTTransaction = false;

        let message = "Transaction Success";
        if (this.isUpdatingMarket === true) {
          message = "Updated Market for " + this.isUpdatingMarket_nft;
        } else if (this.isWithdrawing === true) {
          message = "Transferred NFT " + this.isTransferring_nft;
        } else if (this.isBuying === true) {
          message = "Bought NFT " + this.isBuying_nft + "for " + this.isBuying_message;
        }

        //Transaction success toast
        if (this.getTransactionFailed() === false) {

          this.$buefy.toast.open({
            duration: 10000,
            message: message,
            position: "is-top",
            type: "is-success"
          });

          this.isTransferring = false;
          this.isUpdatingMarket = false;

          await this.rescanPool();


        } else if (this.getTransactionFailed() === true) {

          let message2 = "Transaction Failed";
          if (this.isTransferring === true) {
            message2 = "Failed to transfer NFT "+this.isTransferring_nft;
          } else if (this.isWithdrawing === true) {
            message2 = "Failed update Market for NFT "+this.isUpdatingMarket_nft;
          } else if (this.isBuying === true) {
            message2 = "Failed to buy NFT "+this.isBuying_nft;
          }

          this.$buefy.toast.open({
            duration: 10000,
            message: message2,
            position: "is-top",
            type: "is-danger"
          });

          this.isTransferring = false;
          this.isUpdatingMarket = false;

          await this.rescanPool();

        }

      }


    },

    transactionRejected: function (newValue, oldValue) {
      if (this.debug) {
        console.log("transactionRejected newValue: " + newValue);
        console.log("transactionRejected oldValue: " + oldValue);
      }


      let message3 = "Transaction Rejected.";

      this.$buefy.toast.open({
        duration: 10000,
        message: message3,
        position: "is-top",
        type: "is-danger"
      });

      this.isTransferring = false;
      this.isUpdatingMarket = false;

    },

  },
  computed: {
    ...mapState("accounts", ["transactionPolling", "transactionRejected"]),

  },
  methods: {
    ...mapGetters("accounts", ["getAccountExists", "getTransactionFailed", "getAccountConfirmed", "getAllPoolsFactoryStakeFungiv2", "getUserPoolsFactoryStakeFungiv2"]),

    async clickCollection(){

      await this.$router.push({path: "/collection/" + this.token_collection});
    },

    async rescanPool(){

      const accountName2 = localStorage.getItem("accountName");

      this.l_currentPool = [];

      const nft_payload = {
        nftid: this.pool_reward_token_id
      }

      this.l_currentPool = await this.$store.dispatch("accounts/getNFT", nft_payload);

      console.log("l_currentPool");
      console.log(this.l_currentPool);

      let newprice = this.l_currentPool.marketprice;

      if (this.l_currentPool.marketprice.decimal !== undefined && this.l_currentPool.marketprice.decimal !== null) {
        newprice = this.l_currentPool.marketprice.decimal;
      }

      if(this.l_currentPool["active"] === true){

        if(this.l_currentPool["endtime"]){

          let t_pool_endtime = this.l_currentPool["endtime"];

          if (this.l_currentPool["endtime"].time !== undefined) {
            t_pool_endtime = this.l_currentPool["endtime"].time;
          }else if (this.l_currentPool["endtime"].timep !== undefined) {
            t_pool_endtime = this.l_currentPool["endtime"].timep;
          }

          let date_pool_end_time = new Date(t_pool_endtime);
          this.whitelist_endtime = date_pool_end_time.getTime();

          if( (this.currentDate - this.whitelist_endtime) < 0 ){

            newprice = this.l_currentPool.price;

            if (this.l_currentPool.price.decimal !== undefined && this.l_currentPool.price.decimal !== null) {
              newprice = this.l_currentPool.price.decimal;
            }

          }

        }


      }

      const tokenNamespace = this.l_currentPool.token.refName.namespace;
      const tokenName = this.l_currentPool.token.refName.name;

      this.l_token = this.coinName(tokenNamespace, tokenName);
      this.l_price = newprice;
      this.l_factoryaccount = this.l_currentPool.factoryaccount;
      this.l_forsale = this.l_currentPool.forsale;

      if(accountName2 === this.l_currentPool.factoryaccount){
        this.l_isPoolOwner = true;
      }

      console.log("l_currentPool");
      console.log(this.l_currentPool);

    },

    coinName(namespace, name) {
      let newnamespace = namespace + ".";
      let newname = name;
      if (namespace === null || namespace === "null" || namespace === "") {
        newnamespace = "";
      }
      if (name === null || name === "null" || name === "") {
        newname = "";
      }
      return newnamespace + newname;
    },

    async verifyTokenExists(namespace, name) {

      if (this.debug) {
        console.log("Verifying token exists:");
        console.log(namespace);
        console.log(name);
      }

      let exists_payload = {
        tokenA: this.coinName(namespace, name)
      };

      const exists = await this.$store.dispatch("accounts/getTokenExists", exists_payload);

      if (this.debug) {
        console.log("exists");
        console.log(exists);
      }

      this.localModuleExists = exists;

    },

    async checkValidInput(isvalid) {
      this.inputisvalid = isvalid;
    },

    closeTransferModal() {
      this.showTransferModal = false;
    },

    openTransferModal(){
      this.showTransferModal = true;
    },

    openMarketModal(){
      this.showMarketModal = true;
    },

    //coin ticker display name
    coinDisplayName(coin){
      let name = coin;
      if(coin === 'coin'){
        name = 'KDA';
      }else if(coin === 'free.stake-token'){
        name = 'STEAK';
      }
      return name;
    },

    //update nft on market
    async clickUpdateMarket() {

      if(this.debug){
        console.log("UPDATING MARKET")
      }

      let payload = {
        id: this.pool_reward_token_id,
        price: this.sellPrice,
        forsale: this.forSale,
        token: this.coinName(this.sellNftCustomModuleNamespace, this.sellNftCustomModuleName)
      }

      if(this.debug){
        console.log(payload);
      }

      this.showMarketModal = false;
      this.$emit('showTxModal', true);
      this.isUpdatingMarket = true;
      this.isUpdatingMarket_nft = payload.id;

      if(payload.forsale === false || payload.forsale === 'false'){
        this.isUpdatingMarket_message = "Not for sale";
      }else {
        this.isUpdatingMarket_message = "for sale for "+payload.price+" "+payload.token;
      }

      this.isMakingNFTTransaction = true;

      await this.$store.dispatch("accounts/updateMarket", payload);


    },

    //buy nft
    async clickBuyNFT() {

      if(this.l_isPoolOwner === false){

        if(this.debug){
          console.log("BUYING NFT")
        }

        let payload = {
          id: this.pool_reward_token_id,
          amount: this.l_price,
          token: this.l_token,
          factoryaccount: this.l_factoryaccount,
        }

        if(this.debug){
          console.log(payload);
        }

        this.showMarketModal = false;
        this.$emit('reloadPage', true);
        this.$emit('showTxModal', true);
        this.isBuying = true;
        this.isBuying_nft = payload.id;
        this.isBuying_message = payload.amount.toString()+" "+payload.token;
        this.isMakingNFTTransaction = true;
        await this.$store.dispatch("accounts/buyNFT", payload);

      }else {
        alert("You cannot purchase your own NFTs.")
      }




    },

    //transfer nft
    async clickTransferNFT() {

        if(this.debug){
          console.log("TRANSFERRING NFT")
        }

        let payload = {
          id: this.pool_reward_token_id,
          receiver: this.accountNameToVerify,
          amount: 1.0,
        }

        if(this.debug){
          console.log(payload);
        }

        this.showMarketModal = false;
        this.$emit('reloadPage', true);
        this.$emit('showTxModal', true);
        this.isTransferring = true;
        this.isTransferring_nft = payload.id;
        this.isTransferring_receiver = payload.receiver;
        this.isMakingNFTTransaction = true;
        await this.$store.dispatch("accounts/transferNFT", payload);


    },

    //Verify an account has coin account and exists
    async autoVerify() {
      const payload = this.accountNameToVerify;
      await this.$store.dispatch("accounts/getAccountVerification", payload);
    },

  },
  async created() {

    this.currentDate = new Date().getTime();

    this.forSale = this.forsale;
    this.sellPrice = this.price;
    //await this.rescanPool();

    const nft_payload = {
      nftid: this.pool_reward_token_id
    }



    let l_currentPool = await this.$store.dispatch("accounts/getNFT", nft_payload);

    console.log("l_currentPool");
    console.log(l_currentPool);

  }
};
</script>

<style scoped>

.collectionlink {
  color: #3bac81;
  cursor: pointer;
}

.collectionlink:hover {
  color: #3ee8fd;
}

/deep/ .is-buttonblue {
  /*background-color: #333b4c !important;*/
  background-color: #1b1d2d !important;
  color: #4d84ab !important;
  border-radius: 1rem;
  border: none !important;
  font-size: 12px;
  font-family: Source Code Pro,monospace !important;
  padding: 5px !important;
  text-wrap: normal;
  cursor: pointer;
  width: 220px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

/deep/ .is-buttonblue:hover {
  /*background-color: #333b4c !important;*/
  background-color: #1b1d2d !important;
  color: #4dab73 !important;
  border-radius: 1rem;
  border: none !important;
  font-size: 12px;
  font-family: Source Code Pro,monospace !important;
  padding: 5px !important;
  text-wrap: normal;
  cursor: pointer;
  width: 220px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}


/deep/ .is-sale {
  /*background-color: #333b4c !important;*/
  background-color: #1b1d2d !important;
  color: #4d84ab !important;
  border-radius: 1rem;
  border: none !important;
  font-size: 12px;
  font-family: Source Code Pro,monospace !important;
  padding: 1px !important;
  text-wrap: normal;
  cursor: pointer;
  width: 225px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}



/deep/ .modal-content, .modal-card {
  overflow: hidden !important;
}

.form-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.blue-bg {
  background-color: #314b6a;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #6ea4db;
}


.inset-bg-rounded2 {
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}



.cardfieldbg {
  background-color: transparent !important;
  height: 185px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.frontcardbg {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#style-11::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #200d31;
  border-radius: 10px;
}

#style-11::-webkit-scrollbar {
  width: 10px;
  background-color: #252432;
}

#style-11::-webkit-scrollbar-thumb {
  background-color: #242532;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
  rgba(0, 0, 0, .2) 25%,
  transparent 25%,
  transparent 50%,
  rgba(0, 0, 0, .2) 50%,
  rgba(0, 0, 0, .2) 75%,
  transparent 75%,
  transparent)
}

.divider2 {
  border: none;
  height: 20px;
  width: 90%;
  height: 70px;
  padding-top: 20px;
  border-bottom: 1px solid #224a84;
  box-shadow: 0 20px 20px -20px rgba(88, 116, 238, 0.56);
  margin: -50px auto 10px;
}


/deep/ .field {
  background-color: transparent;
  padding: 0.2rem;
  border-radius: 0.5rem;
}

input::placeholder {
  color: #2b8b5f;
}

.select {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #3abb81 !important;
  border: 1px solid !important;
  color: #3abb81;


}

/deep/ .textarea {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #3abb81;
  color: #3abb81;
}

/deep/ .select select[disabled], .taginput [disabled].taginput-container.is-focusable, [disabled].textarea, [disabled].input, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .taginput .taginput-container.is-focusable, .taginput fieldset[disabled] .taginput-container.is-focusable, fieldset[disabled] .textarea, fieldset[disabled] .input {
  background-color: #2a4855 !important;
  border-color: #24545f;
  box-shadow: none;
  color: #317884;

}

/deep/ .select select {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #3c5d6c;
  color: #3abb81;
  border-bottom: 1px solid !important;
}

/deep/ .select select option {
  background-color: #3c5d6c !important;
  outline: none;
  border-color: #3abb81;
  color: #3abb81;
}

.divider {
  border: none;
  height: 20px;
  width: 90%;
  height: 50px;
  margin-top: 0;
  border-bottom: 1px solid #3c2f92;
  box-shadow: 0 20px 20px -20px rgba(63, 36, 92, 0.56);
  margin: -60px auto 10px;
}


body {
  perspective: 1500px;
}

.wrapper {
  max-width: 295px;
  max-height: 295px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frameWrapper {
  height: 277px;
  width: 240px;
}

.frame {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: #2e3e60b8;
}

.primaryLayer,
.secondaryLayer {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.primaryLayer {
  clip-path: polygon(0% 0%, 100% 0%, 100% 40%, 0% 55%);
  background: radial-gradient(black 0%, transparent 10%) 0 0, radial-gradient(black 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0.1) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #2c313a;
  background-size: 16px 16px;
  border: 2px solid rgba(17, 66, 101, 0.3);
  background-blend-mode: darken;
}

.secondaryLayer {
  clip-path: polygon(100% 100%, 0% 100%, 0% 60%, 100% 45%);
  background: radial-gradient(black 0%, transparent 10%) 0 0, radial-gradient(black 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0.1) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #2c313a;
  background-size: 16px 16px;
  border: 2px solid rgba(17, 66, 101, 0.3);
  background-blend-mode: darken;
}

.image {
  object-fit: cover;
  position: absolute;
  min-height: 65%;
  max-height: 100%;
  width: 100%;
}

.primaryLayer > .image {
  top: 0px;
}

.secondaryLayer > .image {
  bottom: 0px;
}


.card3d {
  font-weight: bold;
  padding: 1em;
  color: #181a1a;
  width:  300px;
  max-height: 450px;
  box-shadow: 0 1px 5px #00000099;
  border-radius: 10px;
  position: relative;
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  transform: rotate3d(0,0,0,0);
}

.card3d:hover {
  transition-duration: 150ms;
  box-shadow: 0 5px 20px 5px #00000044;
}

.card3d .glow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  background-image: radial-gradient(circle at 50% -20%, #ffffff22, #0000000f);
}


.highlight{
  box-shadow: rgba(24, 202, 255, 0.13) 0px 0px 30px 3px !important;
  border-color: rgba(106, 70, 123, 0.56) !important;
  outline: none;
}

/deep/ .b-numberinput input {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
}

.pending-col {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: 'Lexend Deca', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.back-col {
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px;
}

.back-col-main {
  background-color: rgba(38, 43, 56, 1);
  border-radius: 1rem;
  font-family: 'Lexend Deca', sans-serif;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  font-size: 12px;
  height: 100%;
  width: 100%;
  padding: .8rem;
}

.back-col-text{
  font-size: 12px;
}

.col-shadow1 {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider {
  font-size: 12px;
}

.pool-name {
  font-size: 16px;
  color: rgb(120 161 235);
  font-family: 'Lexend Deca', sans-serif;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: #293038;
}

.pool-main {
  background-color: transparent;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: 'Noto Sans', sans-serif;
}

.poolselect-bg {
  background-color: #293038 !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

/deep/ .tabs.is-toggle li.is-active a {
  background-color: #314b6a;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #3abb81;
  z-index: 1;
  font-family: 'Noto Sans', sans-serif;
}

/deep/ .tabs.is-toggle a {
  border-color: #575f85;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 0;
  position: relative;
  font-family: 'Noto Sans', sans-serif;
  color: #195da7;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: rgba(103, 182, 239, 0.27);
  border-color: #67b6ef;
  color: #00b89c;
}


.pools-card {
  padding-top: .5rem;
  color: #0d64c6;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
}

/deep/ .field.has-addons .control:first-child .control .button, .field.has-addons .control:first-child .control .input, .field.has-addons .control:first-child .control .select select {
  border-radius: 0 !important;
}

/deep/ .field.has-addons:first-child  .control:first-child .control:first-child  .button:first-child , .field.has-addons:first-child  .control:first-child .control:first-child  .input:first-child , .field.has-addons:first-child .control:first-child .control:first-child  .select:first-child  select:first-child{
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.stake-btn-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.back-card-stat-col{
  display: flex;
  justify-content: left;
}

.stake-btn-2 {
  font-size: 14px !important;
  max-height: 40px;
  padding-left: 8px !important;
}

.btn {
  color: #87b6e5;
  background: rgba(3, 150, 255, 0.32);
  background-repeat: no-repeat;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}

.btn:focus,
.btn:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
  cursor: pointer;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    margin-bottom: 30px;
  }
}

.text3d {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 transparent, 0 3px 0 transparent, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px 0;
}


.pricingTable {
  background: #fff;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding: 0 0 30px;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: -20px;
}

.pricingTable .pricingTable-header {
  background: #c2803a;
  padding: 10px 0 10px;
  margin: 0 auto 40px;
  border-radius: 40px 40px 0 0;
  position: relative;
}

.pricingTable .pricingTable-header:hover {
  color: #fff;
  background: #7bc8fa;
}

.pricingTable .pricingTable-header:after {
  content: "";
  background: linear-gradient(-45deg, transparent 75%, #c2803a 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #c2803a 75%) 0 50%;
  background-repeat: repeat-x;
  background-size: 22px;
  width: 100%;
  height: 21px;
  position: absolute;
  bottom: -21px;
  left: 0;
  cursor: pointer;
}

.pricingTable .pricingTable-header:hover:after {
  content: "";
  background: linear-gradient(-45deg, transparent 75%, #7bc8fa 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #7bc8fa 75%) 0 50%;
  background-repeat: repeat-x;
  background-size: 22px;
  width: 100%;
  height: 21px;
  position: absolute;
  bottom: -21px;
  left: 0;
  cursor: pointer;
}


.pricingTable .title-back {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  cursor: pointer;
}


.pricingTable .price-value {
  color: #5b5b5b;
  margin: 0 0 20px;
}

.pricingTable .price-value .amount {
  font-size: 65px;
  font-weight: 400;
  line-height: 60px;
  display: block;
}

.pricingTable .price-value .duration {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  display: block;
}

.pricingTable .pricing-content {
  padding: 0 10px;
  margin: 0 0 10px;
  list-style: none;
}

.pricingTable .pricing-content li {
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  line-height: 1px;
  padding: 9px 15px 8px 10px;
  position: relative;
  overflow: auto;
}

.pricingTable .pricing-content li:nth-child(odd) {
  background: #e7e7e7;
}

.pricingTable .pricing-content li:nth-last-child(-n+4) {
  line-height: normal;
}

.pricingTable .pricingTable-signup a {
  color: #fff;
  background: #c2803a;
  font-size: 22px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 1px;
  padding: 5px 25px;
  border-radius: 50px;
  transition: all 0.3s ease 0s;
}

.pricingTable .pricingTable-signup a:hover {
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.9);
}

.pricingTable.red .pricingTable-header {
  background: #d83b50;
}

.pricingTable.red .pricingTable-header:after {
  background: linear-gradient(-45deg, transparent 75%, #d83b50 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #d83b50 75%) 0 50%;
  background-size: 22px;
}

.pricingTable.red .pricingTable-signup a {
  background: #d83b50;
}

.pricingTable.orange .pricingTable-header {
  background: #e0681e;
}

.pricingTable.orange .pricingTable-header:after {
  background: linear-gradient(-45deg, transparent 75%, #e0681e 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #e0681e 75%) 0 50%;
  background-size: 22px;
}

.pricingTable.orange .pricingTable-signup a {
  background: #e0681e;
}

.btn2 {
  color: #fff;
  background-color: #0a0a0a;
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 1px;
  border: none;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  transition: all 0.3s ease 0s;
  top: 0;
  right: 0px;
  background-blend-mode: color;
  outline: none;
  cursor: pointer;
}

.btn2:focus,
.btn2:hover {
  color: #fff;
  background-color: #0a0a0a;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) inset, 0 0 10px rgba(0, 0, 0, 0.3);
  background-blend-mode: color;
  outline: none;
  cursor: pointer;
}


.btn2 span {
  background-color: rgba(58, 91, 203, 0.91);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 1px solid #114265;
  outline: none;
  cursor: pointer;
}

.btn2 span:before {
  background-color: #0a0a0a;
  padding: 7px 20px;
  display: block;
  border-radius: 50px;
  border: 1px solid #114265;
  background-blend-mode: color;
  cursor: pointer;
}



.btn3{
  color: #87b6e5;
  background-color: rgba(38, 89, 125, 0.6) !important;
  background-repeat: no-repeat;
  font-family: 'Noto', sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}
.btn3:focus,
.btn3:hover{
  color: #fff;
  background: radial-gradient(circle at center,rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(38, 89, 125, 0.6);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
}
@keyframes bubbles{
  100%{
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%,26% -96%,-1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}


.highlight-span span {
  background-color: rgba(92, 57, 94, 0.82);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 1px solid #309a6b85;
  outline: none;
  cursor: pointer;
}

.highlight-span span:before {
  background-color: rgba(92, 57, 94, 0.82);
  padding: 7px 20px;
  display: block;
  border-radius: 50px;
  border: 1px solid #309a6b85;
  background-blend-mode: color;
  cursor: pointer;
}

.highlight-span span:focus,
.highlight-span span:hover {
  background-color: rgba(92, 57, 94, 0.82);
  padding: 7px 20px;
  display: block;
  border-radius: 10px;
  border: none;
  background-blend-mode: color;
  border: 1px solid #309a6b85;
  outline: none;
  cursor: pointer;
}


i.frontFlipBtn,
i.backFlipBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #FFFFFF;
}

i.backFlipBtn {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}


.flip-container {
  min-height: 120px;
}

.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  position: relative;
  max-width: 290px;
}

.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  width: 100%;
}

.back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  position: absolute;
}

.flip-container.flipped .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.flip-container.flipped .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flip-container.flippedhm .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.flip-container.flippedhm .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flip-container.flipped {

}

.flip-container.flippedhm {

}

.front {
  z-index: 2;
}


</style>

<style scoped lang="scss">
.icon {
  display: flex;
}

.card__content-apy-back {
  display: flex;
  justify-content: center;
  padding-top: 4px;
  padding-bottom: 0;
  position: absolute;
  top: 6px;

  > * {
    display: flex;
    align-items: center;
    font-weight: 600;
  }
}

.card__price_apy_back {
  color: #2a6980;
  font-size: 10px;
  font-family: 'Lexend Deca', sans-serif;
}

.card__content-bottom2 {
  display: flex;
  position: absolute;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 0;
  cursor: pointer;
  top: 30px;
  right: 0px;

  > * {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .card__maximize {
    color: #2a6980;
    font-size: 10px;
    font-family: 'Source Code Pro', sans-serif;
    height: 23px;
    font-size: 10px;
    background-color: #363d68;
    color: #262f38;
    border: none;
    padding-left: 4px;
    padding-right: 3px;
    padding-top:0px;
    margin-top:0px;
    padding-bottom:4px;
  }
  .card__maximize:hover {
    color: #2a6980;
    font-size: 10px;
    font-family: 'Source Code Pro', sans-serif;
    height: 23px;
    font-size: 10px;
    background-color: #2e7946;
    color: #a5d9c7;
    border: none;
    padding-left: 4px;
    padding-right: 3px;
  }
}

.card__content-bottom3 {
  display: flex;
  position: absolute;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 0;
  cursor: pointer;
  top: 75px;
  right: 0px;

  > * {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .card__maximize {
    color: #2a6980;
    font-size: 10px;
    font-family: 'Source Code Pro', sans-serif;
    height: 23px;
    font-size: 10px;
    background-color: #363d68;
    color: #262f38;
    border: none;
    padding-left: 4px;
    padding-right: 3px;
    padding-top:0px;
    margin-top:0px;
    padding-bottom:4px;
  }
  .card__maximize:hover {
    color: #2a6980;
    font-size: 10px;
    font-family: 'Source Code Pro', sans-serif;
    height: 23px;
    font-size: 10px;
    background-color: #2e7946;
    color: #a5d9c7;
    border: none;
    padding-left: 4px;
    padding-right: 3px;
  }
}

.card__content-bottom4 {
  display: flex;
  position: absolute;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 0;
  cursor: pointer;
  top: 120px;
  right: 0px;

  > * {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .card__maximize {
    color: #2a6980;
    font-size: 10px;
    font-family: 'Source Code Pro', sans-serif;
    height: 23px;
    font-size: 10px;
    background-color: #363d68;
    color: #262f38;
    border: none;
    padding-left: 4px;
    padding-right: 3px;
    padding-top:0px;
    margin-top:0px;
    padding-bottom:4px;
  }
  .card__maximize:hover {
    color: #2a6980;
    font-size: 10px;
    font-family: 'Source Code Pro', sans-serif;
    height: 23px;
    font-size: 10px;
    background-color: #2e7946;
    color: #a5d9c7;
    border: none;
    padding-left: 4px;
    padding-right: 3px;
  }
}

.card {
  background-color: #a0b3c4;
  color: #8BACD9;
  border-radius: 16px;
  width: 300px;
  //height: 500px;
  padding-bottom: 0;
  box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.1);

  .card__product-img {
    display: flex;
    position: relative;
    max-width: 300px;
    max-height: 300px;
    justify-content: center;
  }

  .card__product-img-2 {
    top: 0px;
    display: flex;
    position: relative;
    max-width: 300px;
    max-height: 300px;
  }

  .card__content {
    display: flex;
    flex-direction: column;

    .card__name {
      color: #495fb8;
      font-size: 16px;
      font-weight: bold;
      font-family: 'Lexend Deca', sans-serif;
    }

    .card__description {
      font-size: 1rem;
      line-height: 2.6rem;
      font-weight: lighter;
      position: absolute;
      top: 0px;
    }

    .card__content-bottom {
      display: flex;
      justify-content: center;
      padding-top: 4px;
      padding-bottom: 0;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price {
        color: #2a6980;
        font-size: 10px;
        font-family: 'Lexend Deca', sans-serif;
      }
    }



    .card__content-apy {
      display: flex;
      justify-content: center;
      padding-top: 4px;
      padding-bottom: 0;
      position: absolute;
      top: 6px;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price_apy {
        color: #2a6980;
        font-size: 10px;
        font-family: 'Lexend Deca', sans-serif;
      }
    }

    .card__content-staked {
      display: flex;
      justify-content: center;
      padding-top: 17px;
      padding-bottom: 0;
      position: absolute;
      top: 6px;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price_staked {
        color: #2a6980;
        font-size: 10px;
        font-family: 'Lexend Deca', sans-serif;
      }
    }

    .card__content-pending {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
      padding-top: 10px;
      position: absolute;
      border: 2px solid #114265;
      width: 90%;
      background-size:16px 16px;
      background-color: rgba(51, 59, 76, 0.92);
      border-radius:1rem;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price_pending {
        color: #2a6980;
        font-size: 20px;
        font-family: 'Lexend Deca', sans-serif;
      }
    }
  }

  .card__footer {
    display: flex;
    align-items: center;
    border-top: 1px solid #2E405A;
    gap: 16px;
    padding-top: 16px;

    .card__avatar {
      display: flex;
      border-radius: 90px;
      border: 1px solid white;

      img {
        width: 33px;
      }
    }

    .card__autor--alt-color {
      color: white;
      cursor: pointer;

      &:hover {
        color: #00FFF8;
      }
    }
  }
}

//Transition
.fade-enter-active,
.fade-leave-active {
  transition: 0.25s ease-out;
}

.fade-leave-to,
.fade-enter-from {
  opacity: 0;
}


.form-container {
  background: radial-gradient(black 0%, transparent 10%) 0 0, radial-gradient(black 1%, transparent 13%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0.1) 1%, transparent 1%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #2c313a;
  background-size: 16px 16px;
  border: 2px solid #114265;
  background-blend-mode: darken;
  padding: 20px;
  padding-top: 40px;
  border-radius: 20px;
  box-shadow: rgb(56 154 255 / 22%) 0px 0px 30px 3px !important;
  height: 260px !important;
}


.form-container .form-icon {
  font-size: 8px;
  text-align: center;
  background-color: #2c313a;
  line-height: 160px;
  width: 100px;
  height: 100px;
  margin: 0 auto 15px;
  border-radius: 50px;
  box-shadow: 7px 7px 10px #1f272f, -7px -7px 10px #1f272f;
}

.form-container .title {
  color: #ac40ab;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 20px;
}

.form-container .form-horizontal .form-group {
  margin: 0 0 25px 0;
}

.form-container .form-horizontal .form-group label {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.form-container .form-horizontal .form-group{ margin: 0 0 25px 0; }
.form-container .form-horizontal .form-group label{
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}
.form-container .form-horizontal .form-control{
  color: #333;
  background: #ecf0f3 !important;
  font-size: 15px;
  height: 50px;
  padding: 20px;
  letter-spacing: 1px;
  border: none;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  display: inline-block;
  transition: all 0.3s ease 0s;
}
.form-container .form-horizontal .form-control:focus{
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  outline: none;
}
.form-container .form-horizontal .form-control::placeholder{
  color: #808080;
  font-size: 14px;
}
.form-container .form-horizontal .btn{
  color: #000;
  background-color: #ac40ab;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  padding: 12px 15px 11px;
  border-radius: 20px;
  box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px #fff;
  border: none;
  transition: all 0.5s ease 0s;
}
.form-container .form-horizontal .btn:hover,
.form-container .form-horizontal .btn:focus{
  color: #fff;
  letter-spacing: 3px;
  box-shadow: none;
  outline: none;
}

</style>
