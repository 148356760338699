<template>
  <div style="overflow-x: hidden" :style="$screen.width > 1024 ? 'margin-top: -85px' : 'margin-top: -84px'">

    <!--    Page loading animation section-->
    <div v-if="this.showLoadingModal === true" style="height: 100vh">
      <div class="loader2">
        <div class="loader__bar"></div>
        <div class="loader__bar loader__bar--delay-1"></div>
        <div class="loader__bar loader__bar--delay-2"></div>
        <div class="loader__bar loader__bar--delay-3"></div>
        <div class="loader__bar loader__bar--delay-4"></div>
        <div class="loader__bar loader__bar--delay-5"></div>
        <div>
        </div>
      </div>
    </div>

    <!--    Homepage shown after loading is completed-->
    <section v-if="this.showLoadingModal === false">


      <!--      bubbles-->
      <div
          id="style-10"
          v-if="this.showLoadingModal === false"
          style="height: 92vh; width:100vw; overflow-x: hidden !important; overflow-y: auto"
          :style="$screen.width < 420 ? 'padding-right: 12px !important;' : ''">

        <section class="hero" style="z-index: 2">
          <div class="hero-body header-bg3" style="z-index: 2"></div>
        </section>


        <section class="hero" :class="$screen.width > 1024 ? '' : ''" style="z-index: 2">

          <div class="columns mt-4">

            <div class="column" style="z-index: 2">

              <div class="machineinterface">
                <div class="monitor">
                  <div class="monitor-inner" style="padding-bottom: 60px">
                    <div class="screen-cutout">
                      <div class="screen">

                        <div class="my-pools-shadow column" style="z-index: 2; padding: 0px !important; margin-top: 20px;">
                          <div class="card pools-bg cardborder pb-5 " style="z-index: 2">
                            <div class="card-content poolselect-bg mb-6">
                              <section >
                                <b-tabs
                                    v-model="activeTab"
                                    headerClass="headertest"
                                    class="font1"
                                    position="is-centered"
                                    type="is-toggle"
                                    expanded
                                >
                                  <b-tab-item value="0" :label="this.pair"></b-tab-item>
                                  <b-tab-item v-if="this.creatorPoolAccount !== null" value="1" label="Treasury"></b-tab-item>
                                </b-tabs>
                              </section>
                            </div>

                            <!-- TREASURY ACCOUNTS SECTION -->
                            <section v-if="activeTab === '0'">
                              <section>
                                <LiquidityPoolCard v-for="p in allLiquidityPoolsToShowNow" :key="p.pool_id" @showTxModal="showTxModalEvent()" :total_liquidity="p.pool_liquidity" volume="0" :pool_reserveA="p.pool_reserveA.toFixed(2)" :pool_reserveB="p.pool_reserveB.toFixed(2)"
                                                   :fees="p.pool_fee" pool_apr="0" :pool_name="p.pool_name" :pair_tokenA_namespace="p.pool_tokenANamespace" :pair_tokenB_namespace="p.pool_tokenBNamespace" :pair_tokenA_name="p.pool_tokenAName" :pair_tokenB_name="p.pool_tokenBName"
                                                   :user_account="user_name" :accountApproved="true" :tokenA_ticker="p.pool_tickerA" :tokenB_ticker="p.pool_tickerB" :pair_tokenA="p.pool_tokenA" :pair_tokenB="p.pool_tokenB"></LiquidityPoolCard>
                              </section>
                            </section>

                            <section v-if="activeTab === '1' && this.creatorPoolAccount !== null" class="hero" >
                              <div class="hero-body">
                                <div class="container container2 animated fadeInUp">
                                  <div class="columns is-multiline is-mobile is-flex-direction-column">
                                    <div class="column is-12-desktop is-12-tablet is-12-mobile web mt-2">
                                      <ul class="list-unstyled has-text-centered">
                                        <li class="cow" style="font-size:17px">Hello pool creator, here is this pool's treasury account information:</li>
                                        <li class="cow" style="font-size:12px">+ This treasury account receives {{ (((Number(this.allLiquidityPoolsToShowNow[0].pool_fee) / 2 ) / 2 ) * 100)}}% of all swaps made from this pool.</li>
                                      </ul>
                                      <br/>
                                      <div class="divider is-centered"></div>
                                      <br/>
                                    </div>
                                    <div class="column is-12-desktop is-12-tablet is-12-mobile web">
                                      <div class="columns is-multiline is-vcentered is-justify-content-space-evenly">
                                        <div class="column is-5 account-bg1">
                                          <span class="cow3">{{this.creatorPoolAccount_tokenA}} balance:</span> <span class="cow2">{{this.creatorPoolAccount_balanceA}}</span>
                                          <br/><br/>
                                          <span class="cow3">account name:</span> <span class="cow2">{{this.creatorPoolAccount}}</span>
                                          <br/><br/>
                                          <ul class="list-unstyled has-text-left">
                                            <li ><b-button @click="clickTradeTokenA()" type="is-dark bginput" expanded>CLAIM</b-button></li>
                                          </ul>
                                        </div>
                                        <div class="column is-5 account-bg1">
                                          <span class="cow3">{{this.creatorPoolAccount_tokenB}} balance:</span> <span class="cow2">{{this.creatorPoolAccount_balanceB}}</span>
                                          <br/><br/>
                                          <span class="cow3">account name:</span> <span class="cow2">{{this.creatorPoolAccount}}</span>
                                          <br/><br/>
                                          <ul class="list-unstyled has-text-left">
                                            <li ><b-button @click="clickTradeTokenB()" type="is-dark bginput" expanded>CLAIM</b-button></li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <br/>
                                    <br/>
                                  </div>
                                </div>
                              </div>
                            </section>



                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

          <div style="margin-bottom: 10%"></div>

        </section>
      </div>
    </section>

    <!--Sign TX Modal-->
    <b-modal v-if="this.showTxModal === true" v-model="showTxModal" :width="640" scroll="keep"
             style="border-radius:20px;">

      <div class="form-bg" style="border-radius:20px;">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-md-offset-4 blue-bg">
              <div class="form-container form-shadow">
                <div class="form-icon"><img src="../assets/stake-factory-icon.svg" width="70px"></div>
                <div class="container pb-4">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader">
                      A new signature request was just sent to your wallet:
                    </h3>
                  </div>
                </div>
                <b-message class="is-success" style="background-color: rgb(9 40 52);">
                  <div class="columns is-centered pt-4 pb-0">
                    <div class="column is-centered" style="overflow-wrap: anywhere !important;">
                      {{ this.getCurrentSignRequest() }}
                    </div>
                  </div>
                </b-message>

                <div class="container mb-2">
                  <div class="level-item has-text-centered">
                    <h3 class="accountHeader">
                      Review and sign the transaction in your wallet and click confirm below:
                    </h3>
                  </div>
                </div>

                <form class="form-horizontal">
                  <div class="columns is-mobile is-centered">
                    <div class="column">
                      <div class="notification has-text-centered inset-bg-rounded2" style="background-color: #2d3a45;">
                        <div @click="closeSignatureModal" class="btn2 btn-lg stake-btn"
                             style="border-radius: 1rem !important;">
                          <span>Confirm</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
//import vue store variables to this page
import {mapGetters, mapActions, mapState} from "vuex";
import LiquidityPoolCard from "./LiquidityPoolCard.vue";

//local state
export default {
  name: "TradePage",
  props: {
    pair: {
      type: String,
      default: "0"
    }
  },
  components: {
    LiquidityPoolCard,
  },
  data() {
    return {
      debug: true,
      activeTab: "0",
      currentPage: "0",
      creatorPoolAccount: null,
      creatorPoolAccount_tokenA: null,
      creatorPoolAccount_tokenB: null,
      creatorPoolAccount_balanceA: 0,
      creatorPoolAccount_balanceB: 0,
      showLoadingModal: true,
      showTxModal: false,
      accountApproved: false,
      user_name: null,
      //Liquidity Pools
      allLiquidityPoolsToShowNow: [],
      allLiquidityTreasuryAccountData: [],
    };
  },
  watch: {
    //lets watch our transactionPolling variable
    transactionPolling: function (newValue, oldValue) {
      if (this.debug) {
        console.log("getTransactionPolling newValue: " + newValue);
        console.log("getTransactionPolling oldValue: " + oldValue);
      }
      if (newValue === false && oldValue === true) {
        setTimeout(async ()=>{
          this.showLoadingModal = true;
          await this.reScanPools();
          this.showLoadingModal = false;
        }, 3000)

      }

    },

  },
  computed: {
    //import state variables from our vue store
    ...mapState("accounts", ["transactionPolling"]),

  },
  methods: {
    //map getters from vue store
    ...mapActions("accounts", ["getURIpolyfungiv1", "getURIpolyfungiv1Image"]),
    ...mapGetters("accounts", ["getAccountExists", "getShowNavBar", "getAccountConfirmed", "getAllTreasuryAccountsData", "getCurrentSignRequest"]),

    async showTxModalEvent(){

      this.showTxModal = true;

    },

    async closeSignatureModal() {
      this.showTxModal = false;
    },

    async clickStake(pool_contract){

      console.log(pool_contract);

    },

    coinName(namespace, name){
      let newnamespace = namespace+'.';
      let newname = name;
      if(namespace === null || namespace === 'null'){
        newnamespace = '';
      }
      if(name === null || name === 'null'){
        newname = '';
      }
      return newnamespace+newname;
    },

    reduceBalance(balance, prec = 6){
      if (balance) {
        if (balance.int) balance = balance.int;
        if (balance.decimal) balance = balance.decimal;

        if (parseFloat(balance) % 1 === 0) {
          return parseInt(balance);
        }

        return Math.trunc(parseFloat(balance) * Math.pow(10, prec)) / Math.pow(10, prec);
      }

      if (balance === 0) return 0;
    },

    async reScanPools() {
      //Lets get the users account from local storage if it exists
      const name = localStorage.getItem("accountName");
      this.user_name = name;

      //Lets check if our user is logged in, if so, lets grab the users state from the blockchain, including their NFT balances
      if (name === null || name === "") {
        if (this.debug) {
          console.log("No user local accountName found.");
        }
      } else {

        const t_name_payload = {
          name: name
        }


        //Liquidity Pools
        this.allLiquidityTreasuryAccountData=[];
        this.allLiquidityPoolsToShowNow=[];

        await this.$store.dispatch("accounts/getCurrentBindings", t_name_payload);

        //Liquidity Pools bind
        //Bind local state for liquidity pools
        //this.allLiquidityTreasuryAccountData = this.getAllTreasuryAccountsData();

        let lppayload = {
          poolName: this.pair
        }

        this.allLiquidityTreasuryAccountData = await this.$store.dispatch("accounts/getLiquidityPool",lppayload)


        //Update local state accountApproved so we can check for this before the page loads
        this.accountApproved = this.getAccountConfirmed();

        //////////////////////////////
        //Liquidity pools
        ///////////////////////////////

        //Lets make our list of liquidity pools

        if(this.debug){
          console.log("TEST ALL FACTORY LIQUIDITY TREASURY POOLS");
          console.log(this.allLiquidityTreasuryAccountData);
          console.log("TEST ALL FACTORY POOLS NAME");
          if(this.allLiquidityTreasuryAccountData.length > 0){
            console.log(this.allLiquidityTreasuryAccountData[0].tokenA.refName.name);
          }

        }

        //LIQUIDITY POOLS
        if (this.allLiquidityTreasuryAccountData) {

            const creator = this.allLiquidityTreasuryAccountData.creator;
            if(this.debug){
              console.log("this.allLiquidityTreasuryAccountData.initialized")
              console.log(this.allLiquidityTreasuryAccountData.initialized);
              console.log("this.allLiquidityTreasuryAccountData.creator");
              console.log(this.allLiquidityTreasuryAccountData.creator);
            }



            const pair_test = this.allLiquidityTreasuryAccountData.pair;

            if(pair_test === this.pair){

              //Get token a name
              let t_tokenATicker = null;
              const t_tokenANamespace = this.allLiquidityTreasuryAccountData.tokenA.refName.namespace;
              const t_tokenAName = this.allLiquidityTreasuryAccountData.tokenA.refName.name;
              let t_tokenA = null;
              if(t_tokenANamespace === null){
                t_tokenA = t_tokenAName;
              }else {
                t_tokenA = t_tokenANamespace+'.'+t_tokenAName;
              }

              //Get token b name
              let t_tokenBTicker = null;
              const t_tokenBNamespace = this.allLiquidityTreasuryAccountData.tokenB.refName.namespace;
              const t_tokenBName = this.allLiquidityTreasuryAccountData.tokenB.refName.name;
              let t_tokenB = null;
              if(t_tokenBNamespace === null){
                t_tokenB = t_tokenBName;
              }else {
                t_tokenB = t_tokenBNamespace+'.'+t_tokenBName;
              }

              if(t_tokenA === 'coin'){
                t_tokenATicker = 'KDA'
              }else if(t_tokenA === 'free.stake-token'){
                t_tokenATicker = 'STAKE'
              }else if(t_tokenA === 'free.anedak'){
                t_tokenATicker = 'ADK'
              }else if(t_tokenA === 'free.babena'){
                t_tokenATicker = 'BABE'
              }else {
                t_tokenATicker = t_tokenA;
              }

              if(t_tokenB === 'coin'){
                t_tokenBTicker = 'KDA'
              }else if(t_tokenB === 'free.stake-token'){
                t_tokenBTicker = 'STAKE'
              }else if(t_tokenB === 'free.anedak'){
                t_tokenBTicker = 'ADK'
              }else if(t_tokenB === 'free.babena'){
                t_tokenBTicker = 'BABE'
              }else {
                t_tokenBTicker = t_tokenB;
              }

              //Get treasury account info
              const t_treasuryAccount = this.allLiquidityTreasuryAccountData.id;

              //Get pool pair names
              const t_poolPairName = this.allLiquidityTreasuryAccountData.pair;



              let isCreator = false;

              if(creator === name){
                isCreator = true;
              }

              if(isCreator === true){
                this.creatorPoolAccount = this.allLiquidityTreasuryAccountData["pool-account"];
                let balance_payloadA = {
                  tokenA: t_tokenA,
                  name: this.allLiquidityTreasuryAccountData["pool-account"]
                }

                let balance_payloadB = {
                  tokenA: t_tokenB,
                  name: this.allLiquidityTreasuryAccountData["pool-account"]
                }

                let ta_balanceA = await this.$store.dispatch("accounts/getBalance", balance_payloadA);

                let ta_balanceB = await this.$store.dispatch("accounts/getBalance", balance_payloadB);

                this.creatorPoolAccount_tokenA = t_tokenA;
                this.creatorPoolAccount_tokenB = t_tokenB;
                this.creatorPoolAccount_balanceA = ta_balanceA;
                this.creatorPoolAccount_balanceB = ta_balanceB;
              }

              let reserveA = this.allLiquidityTreasuryAccountData.leg0.reserve;
              let reserveB = this.allLiquidityTreasuryAccountData.leg1.reserve;

              if (this.allLiquidityTreasuryAccountData.leg0.reserve.decimal !== undefined && this.allLiquidityTreasuryAccountData.leg0.reserve.decimal !== null) {
                reserveA = this.currentSwapPairData.leg0.reserve.decimal;
              }

              if (this.allLiquidityTreasuryAccountData.leg1.reserve.decimal !== undefined && this.allLiquidityTreasuryAccountData.leg1.reserve.decimal !== null) {
                reserveB = this.allLiquidityTreasuryAccountData.leg1.reserve.decimal;
              }

              console.log("RESERVE A B");
              console.log(reserveA);
              console.log(reserveB);
              console.log(reserveA + reserveB)

              const t_treasuryAccountPoolObject = {
                pool_id: t_treasuryAccount,
                pool_tokenANamespace: this.allLiquidityTreasuryAccountData.tokenA.refName.namespace,
                pool_tokenAName: this.allLiquidityTreasuryAccountData.tokenA.refName.name,
                pool_tokenBNamespace: this.allLiquidityTreasuryAccountData.tokenB.refName.namespace,
                pool_tokenBName: this.allLiquidityTreasuryAccountData.tokenB.refName.name,
                pool_tokenA: t_tokenA,
                pool_tokenB: t_tokenB,
                pool_tickerA: t_tokenATicker,
                pool_tickerB: t_tokenBTicker,
                pool_pair: t_poolPairName,
                pool_name: this.allLiquidityTreasuryAccountData.poolName,
                pool_fee: this.allLiquidityTreasuryAccountData.fee,
                pool_reserveA: reserveA,
                pool_reserveB: reserveB,
                pool_liquidity: (Number(reserveA) + Number(reserveB)).toFixed(2),
              }

              if(this.allLiquidityTreasuryAccountData.leg0.reserve+this.allLiquidityTreasuryAccountData.leg0.reserve === 0 && creator === localStorage.getItem("accountName")){
                t_treasuryAccountPoolObject.pool_liquidity = "CROWDFUNDING";
              }


              if(this.allLiquidityPoolsToShowNow.includes(t_treasuryAccountPoolObject)===false){
                this.allLiquidityPoolsToShowNow.push(t_treasuryAccountPoolObject);
              }

            }



        }

        if(this.debug){
          console.log("GOT ALL LIQUIDITY POOLS");
          console.log("this.allLiquidityPoolsToShowNow")
          console.log(this.allLiquidityPoolsToShowNow);
        }


      }
    },

    async clickTradeTokenA(){

      let t_payload = {
        token: this.creatorPoolAccount_tokenA,
        amount: this.creatorPoolAccount_balanceA,
        pool_account: this.creatorPoolAccount
      }

      await this.$store.dispatch("accounts/transferToken", t_payload);

    },

    async clickTradeTokenB(){

      let t_payload = {
        token: this.creatorPoolAccount_tokenB,
        amount: this.creatorPoolAccount_balanceB,
        pool_account: this.creatorPoolAccount
      }

      await this.$store.dispatch("accounts/transferToken", t_payload);

    }




  },
  //created() executes 1 frame before our page is shown
  async created() {

    const name = localStorage.getItem("accountName");

    //Lets check if our user is logged in for the pool creation page, if not, lets redirect them to home page
    if (name === null || name === "" || name === "null") {
      if (this.debug) {
        console.log("No user local accountName found.");
      }
      await this.$router.push({path: `/`});
    }else {
      await this.reScanPools();

      //Close loading screen
      setTimeout(async () => {
        this.showLoadingModal = false;
      }, 500);
    }

  }
};
</script>

<style scoped>

.bginput{
  background-color: #1b1d2d !important;
  color: #dedede !important;
}

.bginput:hover{
  color: #4ede77 !important;
  border: 1px solid #7e4ede !important;
}


.divider {
  border: none;
  height: 20px;
  width: 90%;
  height: 50px;

  border-bottom: 1px solid #261626;
  box-shadow: 0 20px 20px -20px #1d111d;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  margin: -50px auto 10px;
  margin-top: -30px !important;
}

.form-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}


.btn2 {
  color: #87b6e5;
  background-color: rgba(38, 89, 125, 0.6) !important;
  background-repeat: no-repeat;
  font-family: 'Noto', sans-serif;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}

.btn2:focus,
.btn2:hover {
  color: #fff;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(38, 89, 125, 0.6);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
}

@keyframes bubbles {
  100% {
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%, 26% -96%, -1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}

@media only screen and (max-width: 767px) {
  .btn2 {
    margin-bottom: 30px;
  }
}


.form-container {
  background-color: #2e3945;
  border-radius: 1rem;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #6ea4db;
  border-width: 20px;
  padding: 40px;
}

.form-container .form-icon {
  font-size: 8px;
  text-align: center;
  line-height: 160px;
  width: 100px;
  height: 100px;
  margin: 0 auto 15px;
  border-radius: 50px;
  box-shadow: 7px 7px 10px #1f272f, -7px -7px 10px #1f272f;
}

.form-container .title {
  color: #ac40ab;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 20px;
}

.form-container .form-horizontal .form-group {
  margin: 0 0 25px 0;
}

.form-container .form-horizontal .form-group label {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.form-container .form-horizontal .form-control {
  color: #333;
  background: #ecf0f3;
  font-size: 15px;
  height: 50px;
  padding: 20px;
  letter-spacing: 1px;
  border: none;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.form-container .form-horizontal .form-control:focus {
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  outline: none;
}

.form-container .form-horizontal .form-control::placeholder {
  color: #808080;
  font-size: 14px;
}

.inset-bg-rounded2 {
  box-shadow: rgba(0, 0, 0, 0.40) 0px 2px 4px, rgba(0, 0, 0, 0.30) 0px 7px 13px -3px, rgba(0, 0, 0, 0.20) 0px -3px 0px inset;
}


.blue-bg {
  background-color: #314b6a;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  color: #6ea4db;
}

.noscroll::-webkit-scrollbar {
  display: none;
}

input {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
  box-shadow: inset 0 30px 60px -12px rgba(50, 50, 93, 0.25), inset 0 18px 36px -18px rgba(0, 0, 0, 0.30);
}

.label {
  color: #3abb81;
  display: block;
  font-size: 1rem;
  font-weight: 700;
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: linear-gradient( #938F6A, #E0DFC3 );
  background-image: conic-gradient( #3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5% );
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 40px;
}

#style-10::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #200d31;
  border-radius: 10px;
}

#style-10::-webkit-scrollbar
{
  width: 10px;
  background-color: #2c2432;
}

#style-10::-webkit-scrollbar-thumb
{
  background-color: #2c2432;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
  rgba(0, 0, 0, .2) 25%,
  transparent 25%,
  transparent 50%,
  rgba(0, 0, 0, .2) 50%,
  rgba(0, 0, 0, .2) 75%,
  transparent 75%,
  transparent)
}


.machineinterface {
  display: block;
  width: 100%;
  margin: -80px 0px;
  position: relative;
}

.monitor {
  min-height: 100vh;
  padding-top: 85px;
  z-index: 2;
  display: block;
  width: 100%;
  background-color: #47485a;
  position: relative;
  padding-bottom: 40px;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, #26202b08 35px, #26202b0d 70px);
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
}

.monitor-inner {
  padding-top: 15px;
  display: block;
  width: 95%;
  background-image: conic-gradient( #414254 0 10.5%, #414254 11% 11.5%, #414254 12% 38%, #414254 39% 61.5%, #414254 62% 88%, #414254 88.5% 89%, #414254 89.25% );
  margin: auto;
  position: relative;
  top: 20px;
  left: 0;
  right: 0;
  border-radius: 5px;
  margin-top: -51px;
  padding-bottom: 60px;
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient(#3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5%);
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 50px;
}

.screen {
  display: block;
  width: 95%;
  background-color: #4F5555;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  margin-top: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 30px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), transparent 85%), radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%), linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 7px -4px #4f545f inset, 0 -2px 1px #5c6574 inset;
}

.header-bg3 {
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
  height: 1px;
  padding-top: 0px;
}

.b-tabs .tabs {
  overflow: hidden !important;
}

/deep/ .b-tabs .tabs {
  overflow: hidden !important;
}

::v-deep .b-tabs .tabs {
  overflow: hidden !important;
}


.center-flex{
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.title-back {
  color: #ffffff;
  font-family: Source Code Pro,monospace !important;
  border-radius: 1rem;
  background: -webkit-linear-gradient(90deg, rgba(49, 56, 64, 0.89), rgba(55, 45, 69, 0.85));
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}


/* card background */
.home-card-bg {
  background: #ebeae5;
}

.inset-bg{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding-bottom: 2rem;
  border-radius: 100px;
}

.account-bg1{
  background-color: rgba(39, 42, 64, 0.53);
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  border-radius: 1rem;
}


.inset-bg2:active{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  width: 100%;
  height: 30px;
  position: relative;
  border-radius: 1rem;
  align-self: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 4px;
}


.col-title {
  color: #36c0ea;
  font-size: 14px;
}

.pending-title {
  color: #3cea36;
  font-size: 14px;
}

.pending-stat {
  color: #3cea36;
  font-size: 1em;
}

.pending-col{
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.col-shadow1{
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider{
  font-size: 12px;
}

.pool-name{
  font-size: 14px;
  color: #36c0ea;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: #222b3a;
}

.pool-main {
  background-color: #222b3a;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
}

.pool-section-title {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);

  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #46a9e7;
  font-family: Source Code Pro,monospace !important;
}


.topbackgroundanimation {
  position: absolute;
  height: 200px;
  width: 100%;
  z-index: 1;
}

/* border for item cards */
.cardborder {
  border-radius: 1rem;
  color: #000000;
}

.poolselect-bg {
  background-color: #222b3a !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}



/deep/ .tabs.is-toggle li.is-active a {
  background-color: #252f3e;
  color: #3abb81;
  z-index: 1;
  font-family: Source Code Pro,monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}


/deep/ .tabs.is-toggle {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  background-color: #252f3e;
}

/deep/ .tabs.is-toggle a {
  margin-bottom: 0;
  position: relative;
  font-family: Source Code Pro,monospace !important;
  border:none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  color: #476074;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: #222b3a;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: #222b3a;
  border:none;
  color: #00b89c;
}


#style-8::-webkit-scrollbar-track
{
  border: 1px solid black;
  background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar
{
  width: 10px;
  background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar-thumb
{
  background-color: #000000;
}



.pools-card{
  padding-top:.5rem;
  color: #3abb81;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow{
  box-shadow: rgba(0, 0, 0, 0.56) 8px -5px 40px;
  border-radius: 30px;
}

.stake-btn-col{
  display: flex;
  justify-content: center;
}

.btn{
  color: #87b6e5;
  background: rgba(3, 150, 255, 0.32);
  background-repeat: no-repeat;
  font-family: Source Code Pro,monospace !important;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}
.btn:focus,
.btn:hover{
  color: #fff;
  background: radial-gradient(circle at center,rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
}
@keyframes bubbles{
  100%{
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%,26% -96%,-1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}
@media only screen and (max-width: 767px){
  .btn{ margin-bottom: 30px; }
}

@media screen and (max-width: 769px), print {
  .screen-cutout {
    background-image: none !important;
    background-color: transparent !important;
  }

  /deep/ .super-select-dropdown {
    background-color: #333b4c !important;
    border: 1px solid !important;
    border-color: #2f597d !important;
    color: #67b6ef !important;
    width: 380px !important;
  }
}

/* page loading style */
.loaderstyle {
  z-index: 1;
  position: absolute !important;
}

#wraper {
  width: 400px;
  height: 350px;
  margin: 90px 11px;
  position: relative;
  border-radius: 40px;
}

#wraper2 {
  width: 450px;
  height: 350px;
  margin: 0 auto;
  position: relative;
  border-radius: 40px;
  margin-left: -36px;
}


</style>
<style lang="scss" scoped>

.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro,monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

$message-header-background-color: #e3f;


.loading-page-container {
  opacity: .95;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  z-index: 1;

}

.loading-page-container .progress {
  position: relative;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 6px solid #20182d;
  background-color: #2c2938;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: hard-light;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;

  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 1;
}

.loading-page-container .progress .inner {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: 50%;
  margin-left: -4px;
  margin-top: -1px;
  width: 196.5px;
  height: 196.5px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.loading-page-container .progress .inner .water {
  opacity: .55;
  position: absolute;
  z-index: 1;
  width: 200%;
  height: 200%;
  left: -50%;
  border-radius: 40%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  animation-name: spin;
  top: 55%;
  background: #32BAFA;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  box-shadow: 0 0 20px #152A4A;

}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  display:flex;
  width: 35px;
  height: 35px;
  border-radius: 0.5rem;
  color: #296992;
}
.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}



.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}


// for styles that do not have ascii characters setting the height
$height: 15px;

.outer {
  margin-bottom: $height;
  width: 100%;
  height: $height;
}

.plain {

  &.outer {
    border: 1px solid limegreen;
  }

  .inner {
    width: 90%;
    background-color: limegreen;
    height: $height;
  }
}

.borderless .inner {
  width: 90%;
  background-color: limegreen;
  height: $height;
}

.punctuation.outer {
  border: 1px solid limegreen;
  line-height: 1.35;
}

@-webkit-keyframes progress-bar-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes spinning {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes pulsing-opacity {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}


.plain .inner, .borderless .inner {
  animation: progress-bar-width steps(20) 5s infinite;
}

.pipes .inner, .blocks .inner, .blocks-gaps {
  animation: progress-bar-width 5s infinite;
  overflow: hidden;
  animation-timing-function: linear;
}

.blocks-gaps .inner {
  letter-spacing: 2px;
}

.activity {
  text-align: center;
  overflow: hidden !important;
}

.spinning {
  animation: spinning steps(8, end) 8s infinite;
}

.pulsing {
  animation: pulsing-opacity steps(3) 0.5s infinite alternate;
}

</style>
<style scoped>
.container3 {
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  /* padding: 25px; */
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), #0f5cbb26 85%), radial-gradient(rgba(38, 58, 74, 0.75), transparent 70%), linear-gradient(#0e081a00, #211922, #0f142000);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
}
.cow {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#14ff7d, #00f3ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}
.cow2 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(44 44 44);
  background: -webkit-linear-gradient(#5aff67, #55ff6a) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}

.cow3 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(80 58 80);
  background: -webkit-linear-gradient(#d4acf3, #e9d1ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}
</style>

<style lang="scss" scoped>
.container2 {
  background-color: #1D1F21;
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  padding: 25px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background: -webkit-linear-gradient(#3e10463d, #21333e70) !important;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 37px 59px #0e192257 inset, 0 0px 2px #ff0ef8 inset;
  -webkit-animation: scanline-data-v-0d33d4c2 8s linear infinite;
  animation: scanline-data-v-0d33d4c2 8s linear infinite;
}

article {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 900px) {
    padding: 5px;
  }

  @media (max-width: 899px) {
    padding-bottom: 56px;
  }
  background-color: rgba(192, 192, 192, 0);
  color: #323232;
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  position: relative;
  & > * + * {
    margin-top: 1.25em;
  }

  &:after {
    content: "🥩";
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding-bottom: 0.25em;
    position: absolute;
    top: 0.75em;
    right: 0.75em;
    width: 1.75em;
    height: 1.75em;
    filter: invert(100) opacity(40%);

    background-color: transparent;
  }
}

.headline {
  font-size: 2.5em;
  font-weight: 700;
}

.subhead {
  font-size: 1.25em;
  margin-top: 0.25em;
}

.article-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    width: 3em;
    height: 3em;
    flex-shrink: 0;
  }

  div:first-of-type {
    margin-left: 0.75em;
    font-size: 0.875em;
    margin-right: auto;
    padding-right: 0.75em;
  }

  div:last-of-type {
    font-size: 0.875em;
  }
}

aside {
  padding: 4em;
  border-right: 1px solid rgba(171, 15, 232, 0.1);
  border-bottom: 1px solid rgba(171, 15, 232, 0.1);
  border-left: 1px solid rgba(171, 15, 232, 0.1);
  border-top: 1px solid rgba(171, 15, 232, 0.1);
  clear: both;
  text-shadow: 0 0 10px, 0 0 40px;
  background-color: rgb(46 40 66 / 39%);
}

.tag {
  display: inline-block;
  margin-right: 0.25em;
  margin-top: 0.25em;
  border-right: 1px solid rgba(238, 238, 238, 0.19);
  border-bottom: 1px solid rgba(238, 238, 238, 0.13);
  border-left: 1px solid #444;
  border-top: 1px solid #444;
  padding: 0.125em 0.375em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}



figure {
  background-color: #c0c0c0;
  box-shadow: 0 0 0 1px #eee, 0 0 0 2px #c0c0c0, 0 0 0 3px #444;
  position: relative;
  margin-bottom: 1.25em;
  width: calc(100% + 1.5em + 2em);
  text-shadow: 0 0 10px, 0 0 40px;
  @media (min-width: 800px) {
    max-width: 50%;
  }
  &:nth-of-type(odd) {
    float: left;
    margin-right: 1.5em;
    margin-left: -3em;
  }

  &:nth-of-type(even) {
    float: right;
    margin-left: 1.5em;
    margin-right: -3em;
  }

  &:after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    background-image: linear-gradient(
            to top,
            rgba(#ff9100, 0.2) 0%,
            rgba(#ffe630, 0.2) 60%
    ),
    linear-gradient(20deg, rgba(#ff0, 0.5) 0%, rgba(#ff0, 0) 35%);
    box-shadow: inset 0px 0px 100px rgba(0, 0, 20, 1);
    z-index: 1;
  }

  img {
    // opacity: 0;
    // filter: hue-rotate(90deg);
    // filter: sepia(20%) brightness(10%) contrast(130%)
  }
  figcaption {
    font-family: "DotGothic16", monospace;
    background-color: #6f4b80;
    box-shadow: inset 0 -1px 0 0 #020202;
    color: #eee;
    text-shadow: 0 0 10px, 0 0 40px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.25em;
    font-size: 0.875em;
    z-index: 2;
  }
}
.blink {
  opacity: 1;
  animation: blink 1s linear infinite;
}

.scanline  {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba(255,255,255,0.02);
  animation: scanline 8s linear infinite;
  pointer-events: none;
  &:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: rgba(255,255,255,0.02);
    animation: scanline 8s linear infinite;
    pointer-events: none;
    z-index: 10;
  }

}

// Scanline height 1005
@keyframes scanline {
  0.01% {
    height: 0;
  }
  99.99% {
    height: 100%
  }
  100% {
    height: 0;
  }
}
</style>
