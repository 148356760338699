<template>
  <div style="overflow-x: hidden" :style="$screen.width > 1024 ? 'margin-top: -85px' : 'margin-top: -60px'">
    <transition name="fade">

      <!--    Page loading animation section-->
      <div v-if="this.showLoadingModal === true" style="height: 100vh">
        <div class="loader2">
          <div class="loader__bar"></div>
          <div class="loader__bar loader__bar--delay-1"></div>
          <div class="loader__bar loader__bar--delay-2"></div>
          <div class="loader__bar loader__bar--delay-3"></div>
          <div class="loader__bar loader__bar--delay-4"></div>
          <div class="loader__bar loader__bar--delay-5"></div>
          <div>
          </div>
        </div>
      </div>

    <!--    Homepage shown after loading is completed-->
    <section v-if="this.showLoadingModal === false">


      <!--      bubbles-->
      <div
          id="style-10"
          v-if="this.showLoadingModal === false"
          style="width:100vw; overflow-x: hidden !important; overflow-y: auto"
          :style="this.getShowNavBar() === true ? 'height: 92vh !important' : 'height: 110vh !important'">

        <section class="hero" style="z-index: 2">
          <div class="hero-body header-bg3" style="z-index: 2"></div>
        </section>


        <section class="hero" :class="$screen.width > 1024 ? '' : ''" style="z-index: 2">

          <div class="columns mt-4">

            <div class="column" style="z-index: 2">

              <div class="machineinterface">
                <div class="monitor" style="padding-bottom: 257px !important;">
                  <div class="monitor-inner">
                    <div class="screen-cutout">
                      <div class="screen">

                        <div class="my-pools-shadow column" style="z-index: 2; padding: 0px !important; margin-top: 20px;">
                          <div class="card pools-bg cardborder pb-5 " style="z-index: 2">
                            <div class="card-content poolselect-bg mb-1">
                              <section >
                                <b-tabs
                                    v-model="activeTab"
                                    headerClass="headertest"
                                    class="font1"
                                    position="is-centered"
                                    type="is-toggle"
                                    expanded
                                >
                                  <b-tab-item value="0" label="Stake Factory Docs"></b-tab-item>
                                </b-tabs>
                              </section>
                            </div>

                            <!-- TREASURY ACCOUNTS SECTION -->
                            <section v-if="activeTab === '0'">

                              <section class="hero">
                                <div class="hero-body">

                                  <div class="container container2 animated fadeInUp">
                                    <div class="columns is-multiline is-mobile">


                                      <div id="google" class="column is-3-desktop is-3-tablet is-6-mobile">
                                        <ul class="list-unstyled">
                                          <li>about</li>
                                          <li><a href="#" @click="setOption(1)">stake factory</a></li>
                                          <li><a href="#" @click="setOption(15)">steak token</a></li>
                                          <li><a href="#" @click="setOption(2)">roadmap</a></li>
                                          <li><a href="#" @click="setOption(3)">kadena</a></li>
                                          <li><a href="#" @click="setOption(4)">wallets</a></li>
                                          <li><a href="#" @click="setOption(5)" style="z-index: 5">gas</a></li>
                                          <li><br/></li>
                                        </ul>
                                      </div>
                                      <div id="nfts" class="column is-3-desktop is-3-tablet is-6-mobile">
                                        <ul class="list-unstyled">
                                          <li>nfts</li>
                                          <li><a href="#" @click="setOption(16)">nfts</a></li>
                                          <li><a href="#" @click="setOption(17)">collections</a></li>
                                          <li><a href="#" @click="setOption(18)">market</a></li>
                                          <li><a href="#" @click="setOption(19)">minting</a></li>
                                          <li><a href="#" @click="setOption(20)">mass minting</a></li>
                                          <li><a href="#" @click="setOption(21)">whitelists</a></li>
                                        </ul>
                                      </div>
                                      <div id="jp" class="column is-3-desktop is-3-tablet is-6-mobile">
                                        <ul class="list-unstyled">
                                          <li>pools (general)</li>
                                          <li><a href="#" @click="setOption(6)">liquidity pools</a></li>
                                          <li><a href="#" @click="setOption(25)">vesting pools</a></li>
                                          <li><a href="#" @click="setOption(7)">staking pools</a></li>
                                          <li><a href="#" @click="setOption(8)">crowdfunding pools</a></li>
                                          <li><a href="#" @click="setOption(8)">daos</a></li>
                                          <li><a href="#" @click="setOption(8)">voting polls</a></li>
                                        </ul>
                                      </div>
                                      <div id="boards" class="column is-3-desktop is-3-tablet is-6-mobile">
                                        <ul class="list-unstyled">
                                          <li>pools (creation)</li>
                                          <li><a  @click="setOption(22)" href="#">liquidity pools</a></li>
                                          <li><a href="#" @click="setOption(26)">vesting pools</a></li>
                                          <li><a  @click="setOption(23)" href="#">staking pools</a></li>
                                          <li><a  @click="setOption(24)" href="#">crowdfunding pools</a></li>
                                          <li><a href="#" @click="setOption(8)">daos</a></li>
                                          <li><a href="#" @click="setOption(8)">voting polls</a></li>
                                        </ul>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </section>

                              <transition name="fade">
                              <section class="hero" v-if="activeOption === 1" style="margin-top:-2rem;">

                                <div class="hero-body">
                                  <div class="container container2 animated fadeInUp">
                                    <div class="columns is-multiline is-mobile">
                                      <div  class="column is-12-desktop is-12-tablet is-12-mobile">
                                        <article>
                                          <pre class="is-hidden-mobile cow" style="background-color:transparent; margin-left: -200px; overflow:hidden;">
.oPYo.   o         8                ooooo                 o
8        8         8                8                     8
                     `Yooo.  o8P .oPYo. 8  .o  .oPYo.   o8oo   .oPYo. .oPYo.  o8P .oPYo. oPYo. o    o
                         `8   8  .oooo8 8oP'   8oooo8    8     .oooo8 8    '   8  8    8 8  `' 8    8
                          8   8  8    8 8 `b.  8.        8     8    8 8    .   8  8    8 8     8    8
                     `YooP'   8  `YooP8 8  `o. `Yooo'    8     `YooP8 `YooP'   8  `YooP' 8     `YooP8
                     :.....:::..::.....:..::...:.....::::..:::::.....::.....:::..::.....:..:::::....8
                     ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::ooP'.
                     ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::...::</pre>
                                          <h2 class="subhead">What is Stake Factory</h2>



                                          <aside>
                                            <p class="cow2">Stake Factory is a <span style="font-weight: bold">custom pooling & pool crafting platform for the Kadena blockchain</span> - Use Stake Factory to create customized pooling solutions for any Defi community on the Kadena blockchain.</p>
                                          </aside>

                                          <figure><img src="../assets/pool-factory-bucket-pool.svg" />
                                            <figcaption>A Factory of Pooling Possibilities For Kadena</figcaption>
                                          </figure>

                                          <br/>
                                          <p>The <span class="cow3">Kadena Stake Factory</span> is a collection of <span class="cow3">blockchain pools</span> and <span class="cow3">pool creation tools</span>, allowing anyone to <span class="cow3">create</span> and <span class="cow3">participate</span> in various defi solutions from a single place.</p>
                                          <p><br/></p>
                                          <p>With Stake Factory you can <span class="cow3">create</span> and <span class="cow3">participate</span> in:</p>
                                          <p></p>
                                          <p>
                                            <span class="cow2">NFTs</span> - Mint non-fungible tokens and NFT collections.
                                            <br/>
                                            <br/>
                                            <span class="cow2">Liquidity Pools</span> - Customized fungible liquidity pools and swapping solutions.
                                            <br/>
                                            <br/>
                                            <span class="cow2">Crowdfunding Pools</span> - Equal access liquidity pool crowdfunding.
                                           <br/>
                                            <br/>
                                            <span class="cow2">Staking Pools</span> - Customized fungible and non-fungible staking solutions.
                                            <br/>
                                            <br/>
                                            <span class="cow2">DAOs</span> - Create and Manage DAOs and DAO operations.
                                          </p>
                                          <p><br/></p>
                                          <p><br/></p>
                                          <p>Aside from being a set of community tools, <span class="cow3">Stake Factory is also a DAO</span> that creates and uses it's own tools and the Stake Factory website to govern the <span class="cow3">STEAK token</span> - Stake Factory's native governance token that is also used to incentivize liquidity and growth of the protocol. </p>
                                          <p><br/></p>
                                          <p><br/></p>
                                          <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>
                                          <p><br/></p>
                                          <h2 class="subhead">What's unique about Stake Factory?</h2>
                                          <p><br/></p>

                                          <aside>
                                            <p class="cow2">Stake Factory is a custom Kadena pooling platform built with the Kadena community in mind from the ground up.</p>
                                          </aside>
                                          <p><br/></p>

                                          <p>Not only does Stake Factory give <span class="cow3">everyone</span> the tools they need to <span class="cow3">create a complete experience for any Defi community</span>, but it's also a community in itself that seeks to help establish tools and trust on the Kadena Blockchain</p>

                                          <p><br/></p>

                                          <p>Stake Factory has been developed by 4 members of the Kadena Community - squiegee, leonida, kittykad, and georgep, and was originally created to help establish a <span class="cow3">layer of trust</span> between <span class="cow3">upgradable smart contract authors</span> and <span class="cow3">asset holders</span> on the Kadena Blockchain. As such, Stake Factory set out to give the Kadena community a <span class="cow3">central hub of trusted pooling solutions</span> by developing a safe haven of pools and tools completely governed by a community <span class="cow3">DAO</span> - Adding a much needed <span class="cow3">layer of trust</span> between upgradable smart contract authors and their communities of investors for those that choose to use it.</p>


                                          <aside style="margin-top: 50px;">
                                            <p class="cow2">Stake Factory and the Stake Factory DAO are what allow Kadena smart contract authors to safely and easily create customized Defi solutions for scenarios where trust is needed.</p>
                                          </aside>

                                          <figure style="margin-right: 20px;"><img style="width: 300px; padding-top:35px;" src="../assets/about-custom-pools.svg" />
                                            <figcaption><span style="color:#4ae39e;">A Factory of Pooling Possibilities For Kadena</span></figcaption>
                                          </figure>

                                          <p><br/></p>

                                          <h2 class="subhead">What can I make with Stake Factory?</h2>

                                          <p style="margin-top: 30px;">
                                            <span class="cow">Use Stake Factory and it's tools to <span class="cow2">create customized pools</span>- Create <span class="cow2">DAOs</span>, <span class="cow2">DEXs</span>, <span class="cow2">Staking Farms</span>, <span class="cow2">NFTs</span>, <span class="cow2">Vaults</span>, <span class="cow2">Vesting Pools</span> and whatever else you need to safely represent your idea on the Kadena blockchain with Stake Factory. </span>
                                            <br/>
                                            <br/>
                                            <span class="cow">Stake Factory has been built to aid <span class="cow3">projects</span> and <span class="cow3">individual</span> users on the Kadena Blockchain alike, and envision the Stake Factory DAO as a <span class="cow3">community driven protocol</span> composed of both projects and individuals from all areas from Kadena Community to help guide, create, and direct Stake Factory's future. </span>
                                            <br/>
                                            <br/>
                                            <span class="cow">With Stake Factory you can easily create customized pools that simply do more for any community, all in one place.<br/><br/>Easily create things like customized liquidity pools that <span class="cow2">do more</span> than just let users swap between assets - Why not create a liquidity pool that <span class="cow2">automatically collects a portion of swap fees</span> in a community treasury pool that benefits your community, so that your project can</span>:
                                            <br/>
                                            <br/>
                                            <span class="cow2">A) Conduct token burns for your community</span>
                                            <br/>
                                            <br/>
                                            <span class="cow2">B) Conduct token buy-backs for your community</span>
                                            <br/>
                                            <br/>
                                            <span class="cow2">C) Create staking pools out of swap fees for your community </span>
                                            <br/>
                                            <br/>
                                            <span class="cow2">D) Become a DAO </span>
                                          </p>

                                          <p><br/></p>

                                          <p><span class="cow3">Creating</span> and <span class="cow3">connecting</span> customizable pools that do more for an audience is at the <span class="cow3">heart</span> of <span class="cow3">Stake Factory</span>, and it's what <span class="cow3">enables</span> projects utilizing Stake Factory's tools to present a <span class="cow3">clearer</span> and more <span class="cow3">connected</span> picture to their communities. </p>

                                          <p style="margin-top: 70px;">Aside from <span class="cow3">fungible token tools</span> and <span class="cow3">staking pools</span>, Stake Factory also comes with a small arsenal of <span class="cow3">NFT tools</span>, allowing creators to easily <span class="cow3">bridge</span> and <span class="cow3">connect</span> their <span class="cow3">fungible audience</span> to <span class="cow3">non-fungible-token solutions</span>, including the ability to create NFTs that can <span class="cow3">only be traded in your token</span>  as well as craft  <span class="cow3">customizable NFT Staking Pools</span>.</p>

                                          <p><br/></p>
                                          <p><br/></p>
                                          <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>
                                          <p><br/></p>
                                          <h2 class="subhead">The Idea</h2>

                                          <aside>
                                            <p class="cow2">The <span class="cow">idea</span> at <span class="cow">Stake Factory</span> is to give <span class="cow">project creators</span> a <span class="cow">complete circle of connectable tools and customizable pooling solutions</span> that allow creators to put their <span class="cow">Defi message at the center of their audiences needs</span> and connect everything <span class="cow">together</span> in one place - All <span class="cow">backed</span> by an entire <span class="cow">Kadena community</span> of Factory Workers and the <span class="cow">STEAK token</span>.</p>
                                          </aside>


                                          <figure><img src="../assets/about-stakefactory-idea.svg" style="height: 400px; padding-top: 40px;"/>
                                            <figcaption>A Factory of Pooling Possibilities For Kadena</figcaption>
                                          </figure>

                                          <p>We envision a <span class="cow3">community driven Factory</span>, where defi projects and the STEAK community come together and find new and exciting ways to connect by using <span class="cow3">Stake Factory Tools</span> and the <span class="cow3">STEAK token</span> as the community driven <span class="cow3">'glue'</span> that holds it all together.</p>

                                          <p>Stake Factory was also created to help Kadena communities <span class="cow3">establish trust</span> on the Kadena Blockchain by supplying sets of <span class="cow3">tools</span> that follow built-in sets of <span class="cow3">rules</span> - For example, crowdfunding pool creators have <span class="cow3">trust building options</span> when creating their pools, such as <span class="cow3">Rug-Pull Insurance</span> which they can enable for their audience.</p>

                                          <p>Pool creators that make use of Stake Factory tools also do <span class="cow3">not</span> have the ability to <span class="cow3">upgrade</span> any of the <span class="cow3">contracts</span> that contain users funds which are staked or pooled in <span class="cow3">anyway</span> inside of the Stake Factory, supplying a much needed <span class="cow3">layer of trust</span> between <span class="cow3">audiences</span> and <span class="cow3">creators</span>.</p>

                                          <p>Establishing <span class="cow3">trust</span> between <span class="cow3">communities</span> on the Kadena Blockchain with Factory trusted tools, and combining it with the <span class="cow3">community guided</span> STEAK token is how we envisioned the Stake Factory - A <span class="cow3">single place</span> where projects could <span class="cow3">create</span> trusted <span class="cow3">pools</span> and <span class="cow3">concepts</span>, with the <span class="cow3">STEAK community</span> in between to glue things together with the <span class="cow3">STEAK governance token</span>.</p>

                                          <p>The <span class="cow3">STEAK token</span> is emitted out of the Factory <span class="cow3">daily</span>, over the course of <span class="cow3">4 years</span>, and it's up to the <span class="cow3">STEAK community</span> and <span class="cow3">Stake Factory DAO</span> to <span class="cow3">decide</span> where it goes as projects create things using Stake Factory tools - Use the STEAK token to <span class="cow3">create and vote on proposals</span> at how <span class="cow3">STEAK</span> behaves as it gets emitted out of the factory to <span class="cow3">community trusted projects</span> built using <span class="cow3">Stake Factory</span>.</p>


                                          <aside>
                                            <p><span class="cow3">Build better</span> Defi solutions by giving your community more <span class="cow3">customizable</span> and <span class="cow3">connectable</span> options that <span class="cow3">reinforce</span> your Defi message with <span class="cow3">Stake Factory</span>.</p>
                                          </aside>

                                        </article>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              </transition>

                              <transition name="fade">
                                <section class="hero" v-if="activeOption === 15" style="margin-top:-2rem;">

                                  <div class="hero-body">
                                    <div class="container container2 animated fadeInUp">
                                      <div class="columns is-multiline is-mobile">
                                        <div  class="column is-12-desktop is-12-tablet is-12-mobile">
                                          <article>
                                          <pre class="is-hidden-mobile cow" style="background-color:transparent; margin-left: 0px; overflow:hidden;"> .::::::.::::::::::::.,::::::   :::.      :::  .       ::::::::::::   ...      :::  .   .,:::::::::.    :::.
;;;`    `;;;;;;;;'''';;;;''''   ;;`;;     ;;; .;;,.    ;;;;;;;;''''.;;;;;;;.   ;;; .;;,.;;;;''''`;;;;,  `;;;
'[==/[[[[,    [[      [[cccc   ,[[ '[[,   [[[[[/'           [[    ,[[     \[[, [[[[[/'   [[cccc   [[[[[. '[[
  '''    $    $$      $$""""  c$$$cc$$$c _$$$$,             $$    $$$,     $$$_$$$$,     $$""""   $$$ "Y$c$$
 88b    dP    88,     888oo,__ 888   888,"888"88o,          88,   "888,_ _,88P"888"88o,  888oo,__ 888    Y88
  "YMmMY"     MMM     """"YUMMMYMM   ""`  MMM "MMP"         MMM     "YMMMMMP"  MMM "MMP" """"YUMMMMMM     YM</pre>

                                            <h2 class="subhead">What is STEAK Token</h2>

                                            <aside>
                                              <p class="cow2">The STEAK Token is Stake Factory's native Governance Token and is used to operate the <span style="font-weight: bold">Stake Factory</span> and participate in Factory Created Pools that receive a share of Factory Revenues.</p>
                                            </aside>

                                            <figure style="width: 500px; height: 400px;"><img style="width: 420px; padding-top:55px;" src="../assets/steak-token-funnel.svg" />
                                              <figcaption>A Factory of Pooling Possibilities For Kadena</figcaption>
                                            </figure>

                                            <br/>
                                            <p><span class="cow3">Stake Factory</span> is a collection of <span class="cow3">blockchain tools</span> that allow anyone to create customized DeFi solutions by using the <span class="cow3">STEAK token</span>.</p>
                                            <p>Whenever someone <span class="cow3">creates something</span> using <span class="cow3">Stake Factory</span>, there is a <span class="cow3">fee assessment</span> made to the creator in <span class="cow3">STEAK tokens</span>, which is then <span class="cow3">required</span> to be submitted with their transaction in order to instruct the Stake Factory to <span class="cow3">create something</span> for the user.</p>
                                            <p><span class="cow3">Fees</span> are also assessed to users <span class="cow3">whenever NFTs are purchased</span> through the Stake Factory NFT Marketplace, as well as whenever a user performs a <span class="cow3">Swap</span> through a <span class="cow3">liquidity pool</span> built with <span class="cow3">Stake Factory</span>.</p>
                                            <p><span class="cow3">All fees collected</span> throughout Stake Factory are then stored in the <span class="cow3">Stake Factory Treasury</span>, which then uses the revenues to <span class="cow3">generate Factory Staking Pools</span> that <span class="cow3">STEAK token holders</span> are free to <span class="cow3">participate</span> in. </p>
                                            <p><br/></p>
                                            <p><br/></p>

                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                            <p><br/></p>

                                            <aside>
                                              <h2 class="subhead"><span class="cow3" style="font-size: 20px;">Tasty STEAK Tokenomics</span></h2>
                                            </aside>

                                            <p><br/></p>



                                            <figure style="margin-right: 20px;" ><img style="width: 580px; padding: 40px" src="../assets/steak-tokenomics.svg" />
                                              <figcaption>A Factory of Pooling Possibilities For Kadena</figcaption>
                                            </figure>

                                            <p><br/></p>
                                            <p><br/></p>
                                            <p>Token Ticker: <span class="cow2">STEAK</span></p>
                                            <p>Total Supply: <span class="cow2">100,000,000</span> </p>
                                            <p>Crowdfunded Pre-Sale: <span class="cow2">2,500,000</span> </p>
                                            <p>Liquidity: <span class="cow2">2,500,000</span> </p>
                                            <p>Team: <span class="cow2">10,000,000 (~ 4 years)</span></p>
                                            <p>Treasury: <span class="cow2">15,000,000</span> </p>
                                            <p>Emissions: <span class="cow2">70,000,000 (~ 4 years)</span> </p>
                                            <br/>

                                            <p><br/></p>
                                            <p><br/></p>

                                            <p style="font-size: 20px;">Upon genesis 100,000,000 STEAK are minted and:</p>
                                            <p><br/></p>
                                            <p><span class="cow3">I.</span> <span class="cow2">70,000,000</span> STEAK tokens are sent to the <span class="cow2">Factory Emissions Pool</span> to be emitted over the course of 4 years via Factory Created Staking Pools.</p>
                                            <p><span class="cow3">II.</span> <span class="cow2">10,000,000</span> STEAK tokens are sent to the <span class="cow2">Team Emissions Pool</span> to be emitted over the course of 4 years to Stake Factory team members.</p>
                                            <p><span class="cow3">III.</span> <span class="cow2">15,000,000</span> STEAK tokens are sent to the <span class="cow2">Factory Treasury</span> for developing partner pools and emission programs.</p>
                                            <p><span class="cow3">IV.</span> <span class="cow2">2,500,000</span> STEAK tokens are used to create a <span class="cow2">Stake Factory Crowdfunding Campaign</span> and sold to raise liquidity for the KDA+STAKE liquidty pool.</p>
                                            <p><span class="cow3">V.</span> <span class="cow2">2,500,000</span> STEAK tokens are then used by the Crowdfunding Campaign to generate the <span class="cow2">Stake Factory KDA+STAKE liquidity pool</span>.</p>

                                            <p><br/></p>
                                            <p><br/></p>

                                            <figure><img style="padding-top: 10px;" src="../assets/steak-emissions-chart.svg" />
                                              <figcaption>A Factory of Pooling Possibilities For Kadena</figcaption>
                                            </figure>



                                            <p><br/></p>
                                            <p style="margin-top: 150px;">
                                              <span class="cow2">+</span> <span class="cow3">70,000,000 STEAK Tokens</span> are emitted over <span class="cow3">4 Years</span> by the <span class="cow3">Factory Emissions Pool</span> and turned into <span class="cow3">Factory Staking Pools</span> by the <span class="cow3">Stake Factory Treasury</span>.
                                            </p>
                                            <p><br/></p>
                                            <p><br/></p>
                                            <p style="margin-top: 100px;">+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>
                                            <p><br/></p>

                                            <h2 class="subhead">The Stake Factory Treasury</h2>

                                            <aside>
                                              <p class="cow2">The Steak Factory Treasury generates Factory Staking Pools from Factory Revenues and STEAK Emissions</p>
                                            </aside>



                                            <figure style="margin-right: 20px;" ><img style="width: 580px; padding: 20px; padding-top: 70px;" src="../assets/steak-treasury-about.svg" />
                                              <figcaption>A Factory of Pooling Possibilities For Kadena</figcaption>
                                            </figure>

                                            <p>
                                              <span class="cow2">+</span> A primary task of the <span class="cow3">Stake Factory Treasury</span> is to <span class="cow3">collect Factory Revenues and STEAK Emissions</span> and use them to <span class="cow3">generate Factory Staking Pools</span>.
                                              <br/>
                                              <br/>
                                              <span class="cow2">+</span> <span class="cow3">Factory Staking Pools</span> are pools created by the <span class="cow3">Stake Factory Treasury</span> and distribute <span class="cow3">STEAK Emissions</span> and <span class="cow3">Revenues</span> collected by the Factory <span class="cow3">back to the community</span> in the form of <span class="cow3">Staking Pools</span>.
                                              <br/>
                                              <br/>
                                              <span class="cow2">+</span> The <span class="cow3">creation</span> of new Factory Staking Pools, as well as the <span class="cow3">allocations</span> of <span class="cow3">STEAK</span> from the Factory Emissions Pool to Factory Staking Pools, including <span class="cow3">revenues</span> collected from fees, is determined by the <span class="cow3">Stake Factory Community</span> via <span class="cow3">Proposals</span>.
                                              <br/>
                                              <br/>
                                              <span class="cow2">+</span> In the long term we envision <span class="cow3">Stake Factory</span> to be a <span class="cow3">community governed protocol</span>, such as a <span class="cow3">DAO</span>, where <span class="cow3">STEAK</span> is a <span class="cow3">governance</span> token that will allow holders to <span class="cow3">vote</span> on community proposals via a decentralized blockchain application.
                                              <br/>
                                              <br/>
                                              <span class="cow2">+</span> <span class="cow3">Community Proposals</span> will be submitted to our <span class="cow3">forums</span>, where members can discuss it's merits and clarify questions. <span class="cow3">Proposals</span> may be brought up for voting by core team members and if they gain sufficient community traction they will be created/executed by the <span class="cow3">Stake Factory Treasury</span>.
                                              <br/>
                                              <br/>
                                              <span class="cow2">+</span> Major uses of the Stake Factory Treasury <span class="cow3">require 3 out of 5 signatures</span> from the Stake Factory team: @squiegee, @kitty_kad, @Leonida, @georgep, @optima.
                                            </p>

                                            <aside>
                                              <p class="cow2">Do you have an idea or a project you want to build on Stake Factory? Come talk to the Stake Factory community and team on our channels, our entire community is always looking for exciting new projects and opportunities for STEAK and it's partners.</p>
                                            </aside>



                                          </article>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </transition>

<!--                              NFT section-->
                              <transition name="fade">
                                <section class="hero" v-if="activeOption === 16" style="margin-top:-2rem;">

                                  <div class="hero-body">
                                    <div class="container container2 animated fadeInUp">
                                      <div class="columns is-multiline is-mobile">
                                        <div  class="column is-12-desktop is-12-tablet is-12-mobile">
                                          <article>
                                          <pre class="is-hidden-mobile cow" style="background-color:transparent; margin-left: -60px; overflow:hidden;">       ███▄▄▄▄      ▄████████     ███        ▄████████
       ███▀▀▀██▄   ███    ███ ▀█████████▄   ███    ███
      ███   ███   ███    █▀     ▀███▀▀██   ███    █▀
███   ███  ▄███▄▄▄         ███   ▀   ███
       ███   ███ ▀▀███▀▀▀         ███     ▀███████████
       ███   ███   ███            ███              ███
       ███   ███   ███            ███        ▄█    ███
         ▀█   █▀    ███           ▄████▀    ▄████████▀  </pre>

                                            <h2 class="subhead">Factory Non Fungible Tokens</h2>

                                            <aside>
                                              <p class="cow2"> Mint NFTs that do more for your audience with Stake Factory </p>
                                            </aside>

                                            <figure style="width: 500px; height: 400px;"><img style="width: 460px; padding-top:60px;" src="../assets/nfts-about.svg" />
                                              <figcaption>A Factory of Pooling Possibilities For Kadena</figcaption>
                                            </figure>

                                            <br/>
                                            <p><span class="cow3">Stake Factory</span> lets NFT creators mint NFTs that have the ability to interact with other <span class="cow3">Stake Factory Pools</span> and <span class="cow3">solutions</span>.</p>
                                            <p><span class="cow3">NFTs</span> minted with <span class="cow3">Stake Factory</span> can be built for both <span class="cow3">non-fungible</span> and <span class="cow3">fungible</span> audiences by being <span class="cow3">customized</span> in various ways that allows NFT artists to easily <span class="cow3">connect</span> and <span class="cow3">reinforce</span> other pieces of their project.</p>
                                            <p>With <span class="cow3">Stake Factory</span> you can create <span class="cow3">NFTs</span> that <span class="cow3">must</span> be traded in your fungible token of <span class="cow3">choice</span>, such as <span class="cow3">your own projects token</span>, and easily link <span class="cow3">your NFTs</span> to <span class="cow3">your project</span> by creating <span class="cow3">NFT Staking Pools</span> with your <span class="cow3">Stake Factory NFTs</span>.</p>
                                            <p><span class="cow3">All</span> Stake Factory NFTs are minted under the <span class="cow3">Factory Non Fungible Standard</span>,  which is Stake Factory's <span class="cow3">modified version of Kadena's poly-fungible-v1 token interface</span>.</p>

                                            <p><br/></p>
                                            <p><br/></p>
                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                            <p><br/></p>
                                            <h2 class="subhead">What is the Factory Non Fungible Standard?</h2>
                                            <p><span class="cow3">Stake Factory NFTs</span> are built under the <span class="cow3">Factory Non Fungible Interface</span> - A <span class="cow3">modified</span> version of the <span class="cow3">poly-fungible-v1 token interface</span>. We <span class="cow3">added</span> more features to the <span class="cow3">poly-fungi-v1</span> interface to focus it around <span class="cow3">Stake Factory Pools</span>, and to include things like a <span class="cow3">customizable marketplace</span>, <span class="cow3">NFT collections</span>, <span class="cow3">whitelisted pre-sales</span>, and a generalized metadata <span class="cow3">format</span>.</p>


                                            <div style="-webkit-text-fill-color: #4ae39e; float: left;"><FlipCardMintPreview class="column is-3"
                                                                      style="margin: 10px; width: 316px !important;"
                                                                      pool_contract="factory-non-fungible"
                                                                      pool_id="?" pool_name="?" :token_strings="['factory', 'non', 'fungible']" token_decimals="1.0" token_integers="1"
                                                                      token_image="https://emojipedia-us.s3.amazonaws.com/source/skype/289/cut-of-meat_1f969.png" token_collection="Stake Factory"
                                                                      pool_reward_token_id="FNF-1"
                                                                      token_name="Factory Non Fungible"
                                                                      token_description="This is an example of a Factory Non Fungible NFT built with Stake Factory!"
                                                                      pool_reward_token="free.factory-non-fungible"
                                                                      :show_flipper="true"
                                                                      stake_balance="0" stake_pending_rewards="0"
                                                                      stake_rewards="0"></FlipCardMintPreview></div>



                                            <p><span class="cow3">Stake Factory</span> has <span class="cow3">built on top of the poly-fungible-v1 interface</span> so that NFT artists can <span class="cow3">easily create inter-linking concepts out of their NFTs</span> all in one place, such as building an entire grouped <span class="cow3">NFT collection</span> under a banner of their choice, as well as the the added ability to easily craft <span class="cow3">NFT Staking Pools</span>. </p>
                                            <p>The <span class="cow3">Factory Non Fungible interface</span> also includes lots of <span class="cow3">useful functions for developers</span> so that projects can <span class="cow3">easily gather statistics and information</span> about their NFT Collections so they can <span class="cow3">easily perform smart contract logic</span> in their own DAPPs - Grab <span class="cow3">NFT metadata</span>, <span class="cow3">NFT collection data</span>, and perform <span class="cow3">pragmatic checks</span> and <span class="cow3">smart contract logic</span> with your entire collection of NFTs - All <span class="cow3">built right into the NFT interface</span>. </p>
                                            <p>The <span class="cow3">idea</span> with the <span class="cow3">Factory Non Fungible interface</span> is to allow developers to <span class="cow3">easily interact</span> with their <span class="cow3">users</span> and their <span class="cow3">users NFTs</span> on their own DAPPs - At the heart of <span class="cow3">Stake Factory</span> is <span class="cow3">connecting concepts</span> from one Defi solution to another, and we have made a few extra functions for developers to <span class="cow3">easily</span> interact with their <span class="cow3">collections</span> and <span class="cow3">audiences</span>.</p>

                                            <p><br/></p>

                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                            <p><br/></p>

                                            <p><br/></p>
                                            <h2 class="subhead">Factory Non Fungible Properties / Characteristics / Metadata / Format</h2>

                                            <p><span class="cow3">Stake Factory NFTs</span> are all built under the <span class="cow3">Factory Non Fungible Standard</span> - FNF.</p>

                                            <p>All <span class="cow3">Factory Non Fungibles</span> come have common NFT characteristics, including an <span class="cow3">ID</span>, a <span class="cow3">name</span> , an <span class="cow3">image</span> , and a <span class="cow3">description</span>:</p>

                                            <p><br/></p>

                                            <p><span class="cow3">1.</span> <span class="cow2">Collection ID</span> All NFTs made at Stake Factory are apart of a <span class="cow3">NFT Collection</span>.
                                            <br/>
                                              + Collection IDs must be unique, 3-30 characters in length, and contain no spaces, and you can only mint NFTs to your own NFT collections.
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + Example: my-collection-id:123
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">2.</span> <span class="cow2">NFT ID</span> All NFTs made at Stake Factory have a unique <span class="cow3">NFT ID</span>.
                                              <br/>
                                              + NFT IDs must be unique, 3-30 characters in length, and contain no spaces.
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + Example: my-nft-id:123
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">3.</span> <span class="cow2">NFT Name</span> All NFTs made at Stake Factory have a <span class="cow3">NFT Name</span>.
                                              <br/>
                                              + NFT Names must be 3-30 characters in length, and can contain spaces.
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + Example: My Cool NFT 1
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">4.</span> <span class="cow2">NFT Description</span> All NFTs made at Stake Factory have a <span class="cow3">NFT Description</span>.
                                              <br/>
                                              + NFT Descriptions must be 3-256 characters in length.
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + Example: This is my cool nft!
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">5.</span> <span class="cow2">NFT Image</span> All NFTs made at Stake Factory have a <span class="cow3">NFT Image</span>.
                                              <br/>
                                              + NFT Images fields should contain a link to your NFT's image, must be 3-256 characters in length.
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + Example: https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/lg/307/cut-of-meat_1f969.png
                                              <br/>
                                              + A great place to upload your NFT images is https://pinata.cloud/ , upload your images and come back and paste the links.
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">6.</span> <span class="cow2">Metadata | String Attributes</span> - Stake Factory NFTs may contain optional Metadata <span class="cow3">String Attributes</span>.
                                              <br/>
                                              + NFT String attributes must be entered 1 per line.
                                              <br/>
                                              + A String can contain any sequence of characters, including spaces, such as "Sword of Power +10"
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + An NFT can only have a maximum of 10 String Attributes
                                              <br/>
                                              + Example: NFT of Slaying +1
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">7.</span> <span class="cow2">Metadata | Decimal Attributes</span> - Stake Factory NFTs may contain optional Metadata <span class="cow3">Decimal Attributes</span>.
                                              <br/>
                                              + NFT Decimal attributes must be entered 1 per line.
                                              <br/>
                                              + A Decimal is a number that contains a decimal place, such as 3.14
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + An NFT can only have a maximum of 10 Decimal Attributes
                                              <br/>
                                              + Example: 203.43049
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">8.</span> <span class="cow2">Metadata | Integer Attributes</span> - Stake Factory NFTs may contain optional Metadata <span class="cow3">Integer Attributes</span>.
                                              <br/>
                                              + NFT Integer attributes must be entered 1 per line.
                                              <br/>
                                              + A Integer is a number that does not contain a decimal place, such as 3
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + An NFT can only have a maximum of 10 Integer Attributes
                                              <br/>
                                              + Example: 345
                                            </p>


                                            <p><br/></p>

                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                            <p><br/></p>

                                            <aside>
                                              <h2 class="subhead"><span class="cow3">Although our NFT interface has been made to easily understand for developers, more useful information on Factory Non Fungibles is on the way.. Do you have any questions about interactions between your DAPP and Stake Factory NFTs with the Factory Non Fungible interface? Feel free to ask us for any help you may need with any of the FNF functions in our channels until we write more about it here. </span></h2>
                                            </aside>

                                          </article>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </transition>

<!--                              NFT Collection section -->
                              <transition name="fade">
                                <section class="hero" v-if="activeOption === 17" style="margin-top:-2rem;">

                                  <div class="hero-body">
                                    <div class="container container2 animated fadeInUp">
                                      <div class="columns is-multiline is-mobile">
                                        <div  class="column is-12-desktop is-12-tablet is-12-mobile">
                                          <article>
                                          <pre class="is-hidden-mobile cow" style="background-color:transparent; margin-left: -60px; overflow:hidden;">░█▀█░█▀▀░▀█▀░░░█▀▀░█▀█░█░░░█░░░█▀▀░█▀▀░▀█▀░▀█▀░█▀█░█▀█░█▀▀
░█░█░█▀▀░░█░░░░█░░░█░█░█░░░█░░░█▀▀░█░░░░█░░░█░░█░█░█░█░▀▀█
░▀░▀░▀░░░░▀░░░░▀▀▀░▀▀▀░▀▀▀░▀▀▀░▀▀▀░▀▀▀░░▀░░▀▀▀░▀▀▀░▀░▀░▀▀▀</pre>

                                            <h2 class="subhead">What are NFT Collections?</h2>

                                            <aside>
                                              <p class="cow2"> NFT Collections are groups of NFTs all made by a single NFT Artist </p>
                                            </aside>

                                            <figure style="width: 500px; height: 450px;"><img style="width: 400px; padding-top:40px;" src="../assets/nft-collections-about.svg" />
                                              <figcaption>A Factory of Pooling Possibilities For Kadena</figcaption>
                                            </figure>

                                            <br/>
                                            <p><span class="cow3">Stake Factory</span> lets NFT creators mint their NFTs into <span class="cow3">representative groups</span> called <span class="cow3">NFT Collections</span>.</p>
                                            <p>A <span class="cow3">NFT Collection</span> is a <span class="cow3">set of NFTs all made by a single artist</span>, or project, and <span class="cow3">all NFTs are always apart of a NFT Collection</span>, even if there is only 1 NFT in the entire collection.</p>
                                            <p><span class="cow3">NFT Collections</span> are what allow projects and NFT artists using Stake Factory tools to easily <span class="cow3">connect their entire group of NFTs to other DeFi solutions and concepts</span> created on <span class="cow3">Stake Factory</span>, such as <span class="cow3">Staking Pools</span>.</p>
                                            <p><span class="cow3">NFT Collections</span> are also <span class="cow3">displayed on the Stake Factory website</span> - Each NFT Collection has it's own area/page on Stake Factory, allowing projects and artists to easily pass around a <span class="cow3">link</span> to their entire set of NFTs.</p>
                                            <p>Like NFT's, <span class="cow3">NFT Collections</span> also have <span class="cow3">metadata</span>, including an <span class="cow3">image</span>, <span class="cow3">description</span>, <span class="cow3">web link</span>, and <span class="cow3">artists signature</span> - Collections are what allow an artist or project to prove an <span class="cow3">NFT's authenticity</span>, and make it easier on developers when coming up with <span class="cow3">pact programming solutions</span> for their audiences.</p>

                                            <p><br/></p>
                                            <p><br/></p>
                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                            <p><br/></p>
                                            <h2 class="subhead">Creating an NFT Collection</h2>
                                            <p>Use the <span class="cow3">Stake Factory Collection Creator</span> to generate your  <span class="cow3">NFT Collection</span> - Simply follow the steps the Collection Creation tool guides you through to fill out the details of your NFT collection and create it on the blockchain.</p>

                                            <div style="-webkit-text-fill-color: #4ae39e; float: left;"> <CollectionCardPreview class="column is-3"
                                                                                                                                style="margin: 10px; width: 316px !important;"
                                                                                                                                collection_name="My NFT Collection"
                                                                                                                                collection_link="www.my-collection-link.io"
                                                                                                                                collection_signature="stakefactory"
                                                                                                                                collection_author="k:author"
                                                                                                                                :collection_supply="'0'"
                                                                                                                                collection_token="free.stake-token"
                                                                                                                                collection_image="https://emojipedia-us.s3.amazonaws.com/source/skype/289/cut-of-meat_1f969.png"
                                                                                                                                :show_flipper="true"></CollectionCardPreview></div>

                                            <p>All NFT Collections share similar characteristics, including:</p>

                                            <p><span class="cow3">I.</span> <span class="cow2">Collection ID</span>: The ID / Name of your NFT collection, which must be unique and under 30 characters in length, and contain no spaces.</p>
                                            <p><span class="cow3">II.</span> <span class="cow2">Web Site</span>: All collections have a link to a web site which is useful for giving users more information about your NFT collection, and must be under 256 characters in length.</p>
                                            <p><span class="cow3">III.</span> <span class="cow2">Image</span> Just like an NFT, a NFT Collection also has an image. Links to images must be under 256 characters in length.</p>
                                            <p><span class="cow3">IV.</span> <span class="cow2">Market Token</span> NFT Collections can be tied directly to a fungible token of the creators choice - When a NFT collection enforces a market token, all NFT's in that collection must be bought/sold in the market token of the NFT Collection.</p>
                                            <p><span class="cow3">V.</span> <span class="cow2">Signature</span> All NFT Collections also get signed by the artist that created them, this allows an artist to prove their collections authenticity - All signatures must be unique and under 30 characters in length.</p>

                                            <p><span class="cow3">NFT Collections</span> can only contain a <span class="cow3">maximum of 1000 NFTs</span> each, please make sure you plan out your NFT collection concepts in groups of 1000 or less.</p>

                                            <p><span class="cow3">Creating</span> a <span class="cow3">NFT Collection</span> has a <span class="cow3">fee of 0.1 STEAK tokens</span>, however, minting NFTs into your collection once your collection has been created is free.</p>

                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                            <p><br/></p>

                                            <aside>
                                              <h2 class="subhead"><span class="cow3">Have any questions about creating your NFT collection on Stake Factory? Feel free to join our channels to get answers to any questions you may have about Stake Factory Collections and NFTs.</span></h2>
                                            </aside>

                                          </article>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </transition>

                              <!--                              NFT Market section -->
                              <transition name="fade">
                                <section class="hero" v-if="activeOption === 18" style="margin-top:-2rem;">

                                  <div class="hero-body">
                                    <div class="container container2 animated fadeInUp">
                                      <div class="columns is-multiline is-mobile">
                                        <div  class="column is-12-desktop is-12-tablet is-12-mobile">
                                          <article>
                                          <pre class="is-hidden-mobile cow" style="background-color:transparent; margin-left: -60px; overflow:hidden;"> _______  ______________________    _____                __           __
   \      \ \_   _____/\__    ___/   /     \ _____ _______|  | __ _____/  |_
    /   |   \ |    __)    |    |     /  \ /  \\__  \\_  __ \  |/ // __ \   __\
 /    |    \|     \     |    |    /    Y    \/ __ \|  | \/    &gt;\  ___/|  |
 \____|__  /\___  /     |____|    \____|__  (____  /__|  |__|_ \\___  >__|
           \/     \/                        \/     \/           \/    \/      </pre>

                                            <h2 class="subhead">The NFT Marketplace</h2>

                                            <aside>
                                              <p class="cow2"> All Factory Non Fungibles can be traded on the Stake Factory NFT Marketplace </p>
                                            </aside>

                                            <br/>
                                            <p>All <span class="cow3">Factory Non Fungible NFTs</span> have the ability to be bought and sold on the <span class="cow3">Stake Factory NFT Marketplace</span> - users may also browse and search through the NFT marketplace to find NFTs they are interested in purchasing.</p>

                                            <p><span class="cow3">Adding</span> and <span class="cow3">removing</span> a <span class="cow3">NFT</span> to and from the <span class="cow3">Stake Factory Marketplace</span> is achieved by <span class="cow3">flipping over the NFT</span> and <span class="cow3">clicking the shopping cart icon</span> on the back of the NFT- Clicking the <span class="cow3">shopping cart</span> will allow you to <span class="cow3">set if the NFT is for sale or not for sale</span>, as well the <span class="cow3">price</span> you want to sell it for.</p>

                                            <p>NFT creators also have the ability to <span class="cow3">enforce which token their NFT's must be traded in on the NFT marketplace</span>, if this option is enabled for a NFT Collection, then the <span class="cow3">entire NFT collection</span> can only be traded in the <span class="cow3">token selected by the artist</span> who created the NFT collection.</p>

                                            <p>Once you <span class="cow3">send in your transaction</span> to update your NFT's market status and set it for sale and give it a price, it will <span class="cow3">immediately</span> appear on the <span class="cow3">Stake Factory NFT Marketplace</span> where anyone can purchase it for the exact price you set- You may also <span class="cow3">remove your NFT from the marketplace</span> in the same way by marking the NFT as <span class="cow3">not</span> for sale.</p>

                                            <p><span class="cow3">All NFTs sold</span> on the Stake Factory NFT marketplace are subject to a <span class="cow3">3% fee</span> which goes directly to the <span class="cow3">Stake Factory Treasury</span> to create more community pools.</p>

                                            <p><br/></p>
                                            <p><br/></p>
                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                          </article>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </transition>

                              <!--                              NFT Minting section -->
                              <transition name="fade">
                                <section class="hero" v-if="activeOption === 19" style="margin-top:-2rem;">

                                  <div class="hero-body">
                                    <div class="container container2 animated fadeInUp">
                                      <div class="columns is-multiline is-mobile">
                                        <div  class="column is-12-desktop is-12-tablet is-12-mobile">
                                          <article>
                                          <pre class="is-hidden-mobile cow" style="background-color:transparent; margin-left: 0px; overflow:hidden;">.##...##..######..##..##..######..######..##..##...####...........##..##..######..######...####..
.###.###....##....###.##....##......##....###.##..##..............###.##..##........##....##.....
.##.#.##....##....##.###....##......##....##.###..##.###..........##.###..####......##.....####..
.##...##....##....##..##....##......##....##..##..##..##..........##..##..##........##........##.
.##...##..######..##..##....##....######..##..##...####...........##..##..##........##.....####..
.................................................................................................</pre>

                                            <h2 class="subhead">Minting NFTs</h2>

                                            <aside>
                                              <p class="cow2"> Mint NFTs with the Stake Factory NFT Minter </p>
                                            </aside>

                                            <br/>
                                            <p>The <span class="cow3">Stake Factory NFT Minter Tool</span> allows users to mint <span class="cow3">NFTs</span> into their <span class="cow3">NFT Collections</span>, <span class="cow3">1 NFT at a time</span> - For minting <span class="cow3">multiple NFT's</span> at once, make sure you check out the <span class="cow3">Mass-Minting tool</span>.</p>

                                            <p><span class="cow3">Minting</span> a NFT requires that you first have created a <span class="cow3">NFT Collection</span> - <span class="cow3">All NFTs</span> are apart of a <span class="cow3">NFT collection</span>, even if there is only 1 NFT in the entire collection. Make sure you have created a <span class="cow3">NFT Collection</span> to mint your NFTs into before attempting to mint any NFTs.</p>

                                            <p><span class="cow3">All NFTs</span> minted by the Stake Factory NFT Minter are created under the <span class="cow3">Factory Non Fungible Standard</span> - A modified version of kadena's poly-fungible-v1 standard. Make sure you read up on all the cool things Factory Non Fungible NFTs can do in our docs.</p>

                                            <p><span class="cow3">The NFT Minter Tool</span> will guide you through a <span class="cow3">series of NFT design steps</span> and ultimately create a <span class="cow3">preview</span> of your <span class="cow3">NFT</span> at the <span class="cow3">end</span> of the process, if everything looks ok to you in the preview, you can then proceed and mint your NFT to the blockchain - <span class="cow3">Remember, your NFT's cannot be edited once they are minted</span>. </p>

                                            <p>Every <span class="cow3">step</span> of the design process includes <span class="cow3">Important Help & Info sections</span>, which are indicated by <b-icon
                                                pack="fas"
                                                icon="info-circle"
                                                size="is-small" style="position: relative; top:2px; color: #4ae39e !important; ">
                                            </b-icon>️ icons - <span class="cow3">Clicking</span> on these areas of Stake Factory tools will expose <span class="cow3">handy information</span> about the area your currently in and the step your currently working on. </p>

                                            <aside>
                                              <p class="cow2"> All minted NFTs are obfuscated and not shown on the Stake Factory website unless the entire NFT collection has already been revealed.</p>
                                            </aside>

                                            <p>All NFT collections are hidden at first so NFT creators can create white-listed pre-sales without the public seeing their NFTs - An NFT Collection becomes revealed once you attempt to sell or transfer any NFT in the collection.</p>

                                            <p>Please keep this in mind when minting your NFTs - If your collection is visible on Stake F</p>

                                            <p><span class="cow3">Minting one NFT at a time can take a long time</span>, especially if your have lot's of NFTs to mint - Stake Factory also has a <span class="cow3">mass minting tool</span> which will allow you to <span class="cow3">mint multiple NFTs at once</span>, however the mass-minter tool is more difficult to use than this step by step single NFT minter tool. As such, we recommend learning how to make NFTs <span class="cow3">first with this tool</span>, and <span class="cow3">once your familiar</span> with how Stake Factory NFTs are designed (and what data goes where), then proceeding to the mass minter tool for a <span class="cow3">quicker minting experience</span>. </p>

                                            <p><br/></p>

                                            <aside>
                                              <p class="cow2"> Follow the steps in the NFT minting tool to design all the characteristics & properties of your NFT listed below:</p>
                                            </aside>

                                            <p><br/></p>

                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>



                                            <p><br/></p>
                                            <h2 class="subhead">Factory Non Fungible Properties / Characteristics / Metadata / Format</h2>

                                            <p><span class="cow3">Stake Factory NFTs</span> are all built under the <span class="cow3">Factory Non Fungible Standard</span> - FNF.</p>

                                            <p>All <span class="cow3">Factory Non Fungibles</span> come have common NFT characteristics, including an <span class="cow3">ID</span>, a <span class="cow3">name</span> , an <span class="cow3">image</span> , and a <span class="cow3">description</span>:</p>

                                            <p><br/></p>

                                            <p><span class="cow3">1.</span> <span class="cow2">Collection ID</span> All NFTs made at Stake Factory are apart of a <span class="cow3">NFT Collection</span>.
                                              <br/>
                                              + Collection IDs must be unique, 3-30 characters in length, and contain no spaces, and you can only mint NFTs to your own NFT collections.
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + Example: my-collection-id:123
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">2.</span> <span class="cow2">NFT ID</span> All NFTs made at Stake Factory have a unique <span class="cow3">NFT ID</span>.
                                              <br/>
                                              + NFT IDs must be unique, 3-30 characters in length, and contain no spaces.
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + Example: my-nft-id:123
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">3.</span> <span class="cow2">NFT Name</span> All NFTs made at Stake Factory have a <span class="cow3">NFT Name</span>.
                                              <br/>
                                              + NFT Names must be 3-30 characters in length, and can contain spaces.
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + Example: My Cool NFT 1
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">4.</span> <span class="cow2">NFT Description</span> All NFTs made at Stake Factory have a <span class="cow3">NFT Description</span>.
                                              <br/>
                                              + NFT Descriptions must be 3-256 characters in length.
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + Example: This is my cool nft!
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">5.</span> <span class="cow2">NFT Image</span> All NFTs made at Stake Factory have a <span class="cow3">NFT Image</span>.
                                              <br/>
                                              + NFT Images fields should contain a link to your NFT's image, must be 3-256 characters in length.
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + Example: https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/lg/307/cut-of-meat_1f969.png
                                              <br/>
                                              + A great place to upload your NFT images is https://pinata.cloud/ , upload your images and come back and paste the links.
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">6.</span> <span class="cow2">Metadata | String Attributes</span> - Stake Factory NFTs may contain optional Metadata <span class="cow3">String Attributes</span>.
                                              <br/>
                                              + NFT String attributes must be entered 1 per line.
                                              <br/>
                                              + A String can contain any sequence of characters, including spaces, such as "Sword of Power +10"
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + An NFT can only have a maximum of 10 String Attributes
                                              <br/>
                                              + Example: NFT of Slaying +1
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">7.</span> <span class="cow2">Metadata | Decimal Attributes</span> - Stake Factory NFTs may contain optional Metadata <span class="cow3">Decimal Attributes</span>.
                                              <br/>
                                              + NFT Decimal attributes must be entered 1 per line.
                                              <br/>
                                              + A Decimal is a number that contains a decimal place, such as 3.14
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + An NFT can only have a maximum of 10 Decimal Attributes
                                              <br/>
                                              + Example: 203.43049
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">8.</span> <span class="cow2">Metadata | Integer Attributes</span> - Stake Factory NFTs may contain optional Metadata <span class="cow3">Integer Attributes</span>.
                                              <br/>
                                              + NFT Integer attributes must be entered 1 per line.
                                              <br/>
                                              + A Integer is a number that does not contain a decimal place, such as 3
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + An NFT can only have a maximum of 10 Integer Attributes
                                              <br/>
                                              + Example: 345
                                            </p>


                                            <p><br/></p>

                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>



                                            <aside>
                                              <p class="cow2"> Although all Stake Factory tools are designed to detect and notify you of when you enter information incorrectly in a spot it doesn't belong, please make sure you read the <b-icon pack="fas" icon="info-circle" size="is-small" style="position: relative; top:2px; color: #4ae39e !important; "></b-icon> Help/Info sections on each page of the Stake Factory tools to ensure you don't experience any failed transactions using the Stake Factory tools.</p>
                                            </aside>

                                            <p><br/></p>
                                            <p><br/></p>
                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                          </article>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </transition>

                              <!--                              Mass Minting section -->
                              <transition name="fade">
                                <section class="hero" v-if="activeOption === 20" style="margin-top:-2rem;">

                                  <div class="hero-body">
                                    <div class="container container2 animated fadeInUp">
                                      <div class="columns is-multiline is-mobile">
                                        <div  class="column is-12-desktop is-12-tablet is-12-mobile">
                                          <article>
                                          <pre class="is-hidden-mobile cow" style="background-color:transparent; margin-left: 0px; overflow:hidden;">.%%...%%...%%%%....%%%%....%%%%...........%%...%%..%%%%%%..%%..%%..%%%%%%..%%%%%%..%%..%%...%%%%..
.%%%.%%%..%%..%%..%%......%%..............%%%.%%%....%%....%%%.%%....%%......%%....%%%.%%..%%.....
.%%.%.%%..%%%%%%...%%%%....%%%%...........%%.%.%%....%%....%%.%%%....%%......%%....%%.%%%..%%.%%%.
.%%...%%..%%..%%......%%......%%..........%%...%%....%%....%%..%%....%%......%%....%%..%%..%%..%%.
.%%...%%..%%..%%...%%%%....%%%%...........%%...%%..%%%%%%..%%..%%....%%....%%%%%%..%%..%%...%%%%..
..................................................................................................</pre>

                                            <h2 class="subhead">Mass Minting NFTs</h2>

                                            <aside>
                                              <p class="cow2"> Mint Multiple NFTs at once with the Stake Factory NFT Mass Minter </p>
                                            </aside>

                                            <br/>
                                            <p>The <span class="cow3">Stake Factory NFT Mass Minter Tool</span> allows users to mint <span class="cow3">multiple NFTs at once</span> into their <span class="cow3">NFT Collections</span>.</p>

                                            <p><span class="cow3">Mass Minting</span> NFTs requires that you first have created a <span class="cow3">NFT Collection</span> - <span class="cow3">All NFTs</span> are apart of a <span class="cow3">NFT collection</span>, even if there is only 1 NFT in the entire collection. Make sure you have created a <span class="cow3">NFT Collection</span> to mint your NFTs into before attempting to mint any NFTs.</p>

                                            <p><span class="cow3">All NFTs</span> minted by the Stake Factory NFT Minter are created under the <span class="cow3">Factory Non Fungible Standard</span> - A modified version of kadena's poly-fungible-v1 standard. Make sure you read up on all the cool things Factory Non Fungible NFTs can do in our docs.</p>

                                            <p><span class="cow3">The NFT Mass Minter Tool</span> requires that you enter your NFT data as specially formatted JSON objects, <span class="cow3">one {nft JSON object} per line</span>. </p>

                                            <p>Whenever you paste a new {NFT} JSON object into the Mass Minting tool, a preview of the NFT should pop up below the tool indicating that the {NFT} JSON object was formatted correctly - If a preview of your NFT does not appear, then the {NFT} JSON Object you entered is incorrectly formatted and your transaction will fail.</p>

                                            <p>Remember all NFT ids must be unique on Stake Factory - If any of your NFT ids are already taken, your mass mint transaction will fail. A good way to ensure you always create unique IDs is to give them a custom prefix, for example: "STAKEFACTORY:NFT1", "STAKEFACTORY:NFT2", and so on.</p>

                                            <p>Every <span class="cow3">Stake Factory Tool</span> includes <span class="cow3">Important Help & Info sections</span>, which are indicated by <b-icon
                                                pack="fas"
                                                icon="info-circle"
                                                size="is-small" style="position: relative; top:2px; color: #4ae39e !important; ">
                                            </b-icon>️ icons - <span class="cow3">Clicking</span> on these areas of Stake Factory tools will expose <span class="cow3">handy information</span> about the area your currently in and the step your currently working on. </p>

                                            <p><br/></p>

                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>


                                            <p><br/></p>

                                            <h2 class="subhead">{NFT} JSON Object Format</h2>

                                            <p><br/></p>

                                            <p>In order to Mass Mint a Stake Factory NFT you must enter your NFT as a JSON Object, one per line, like the example below:</p>


                                            <aside>
                                              <p class="cow2"> {"id" : "my_nft_id","collection-id": "my_collection_id","name": "My NFT's Name","description": "The description of my nft","image": "https://emojipedia-us.s3.amazonaws.com/source/skype/289/cut-of-meat_1f969.png","a1": ["my attribute 1", "my attribute 2", "my attribute 3"],"a2": [1.0, 2.2, 33.34],"a3": [0, 349, 999]} </p>
                                            </aside>

                                            <p>Lets break apart the JSON object line by line so it's easier to understand how we format our NFTs:</p>


                                            <aside>
                                              <p class="cow2">  <br/>
                                                {
                                                <br/>
                                                "id" : <span class="cow3">"my_nft_id"</span>,
                                                <br/>
                                                "collectionid": <span class="cow3">"my_collection_id"</span>,
                                                <br/>
                                                "name": <span class="cow3">"My NFT's Name"</span>,
                                                <br/>
                                                "description": <span class="cow3">"The description of my nft"</span>,
                                                <br/>
                                                "image": <span class="cow3">"https://emojipedia-us.s3.amazonaws.com/source/skype/289/cut-of-meat_1f969.png"</span>,
                                                <br/>
                                                "a1": <span class="cow3">["my attribute 1", "my attribute 2", "my attribute 3"]</span>,
                                                <br/>
                                                "a2": <span class="cow3">[1.0, 2.2, 33.34]</span>,
                                                <br/>
                                                "a3": <span class="cow3">[0, 349, 999]</span>
                                                <br/>
                                                }
                                                <br/></p>
                                            </aside>

                                            <p>Notice above, how each section of the JSON object directly references one of the <span class="cow2">properties</span> and <span class="cow3">characteristics</span> of your NFT.</p>

                                            <p>For example, the NFT ID <span class="cow2">property</span> of your NFT is called <span class="cow2">"id"</span> in our JSON object. You would then type your NFT's actual NFT ID <span class="cow3">characteristic</span> on the other side of this property's semi-colon, replacing the <span class="cow3">"my_nft_id"</span> with your NFT's actual ID, as shown below:</p>

                                            <p><span class="cow2">"id"</span> : <span class="cow3">"my_cool_nft:1"</span></p>

                                            <p>In this last example, we gave our NFT the id <span class="cow3">"my_cool_nft:1"</span> - Notice how we leave the <span class="cow2">"id"</span> and semi-colon property definition, and type our NFT's actual ID on the other side.</p>

                                            <p>Each section of the <span class="cow3">JSON object</span> is a section of your NFT where a <span class="cow2">property</span> is declared, followed by a semi-colon, and then defined by a <span class="cow2">characterstic</span> that you give your NFT - Do not change, remove or add new propertys but simply fill out the template with your own.</p>

                                            <p>Remember, you must enter your entire JSON NFT object <span class="cow3">on one line</span> like we showed in the very first example- <span class="cow3">Do not copy and paste the split explanation above</span> (where we separated the JSON object into several lines), as this example was split up line by line to explain each section of the format.</p>

                                            <p>The <span class="cow3">Mass Minter tool</span> also comes filled with a <span class="cow3">JSON object</span> you can edit in place and watch the results as you edit, we recommend copying and pasting this demo NFT object over and over to get a feel for how the tool reads your NFTs - <span class="cow3">Copy</span> the demo JSON object, <span class="cow3">paste</span> it, <span class="cow3">hit enter to create a new line</span>, and if a preview of the NFT pops up, it means your new NFT is formatted correctly and you can paste another nft on a new line.</p>

                                            <p>Remember, <span class="cow3">JSON NFT objects</span> also surround things like <span class="cow3">properties</span> and <span class="cow3">strings</span> in <span class="cow3">"quotes"</span>, <span class="cow3">don't erase these quotes if they are present</span> - Keep all the quotes you see in the examples above and type within them, making sure to <span class="cow3">keep the quotations if they are present</span>, as well as commas <span class="cow3">,</span> or <span class="cow3">[</span> brackets <span class="cow3">]</span>.</p>

                                            <p>Minting NFTs costs <span class="cow3">Gas</span>! We recommend minting a <span class="cow3">maximum of 30-40 NFTs at once</span> depending on how much metadata you include in your NFTs - Do <span class="cow3">not</span> mint more than 45 NFTs at once with the mass minter tool or your transaction will <span class="cow3">fail</span> due to Kadenas <span class="cow3">Max Gas Limit of 150k</span>.</p>

                                            <p>Note that the Metadata propertys of NFTs are labled <span class="cow3">"a1"</span> and <span class="cow3">"a2"</span> and <span class="cow3">"a3"</span> - Implying Attributes 1, Attributes 2, Attributes 3.</p>

                                            <aside>
                                              <p class="cow2"> a1 = String Attributes.
                                              <br/>
                                              <br/>
                                                a2 = Decimal Attributes
                                                <br/>
                                                <br/>
                                                a3 = Integer Attributes
                                              </p>
                                            </aside>


                                            <p>Each attribute section is enclosed within <span class="cow3">[ brackets ]</span>, and each characteristic is separated by a <span class="cow3">comma</span> - Please make sure String Attributes go in the correct area, Decimal Attributes in the correct area, and Integer attributes all are in their respective sections correctly typed.</p>

                                            <p>Do <span class="cow3">not</span> type Decimal Attributes where Integer attributes are supposed to be typed, all metadata attribute sections must contain the correct type of data described above - We recommend messing with the default example in Mass Minter to see live editing results as you edit these areas of a NFT for the first time.</p>

                                            <p>For <span class="cow3">more information</span> on NFT <span class="cow3">properties</span> and <span class="cow2">characteristics</span> check out the Info below:</p>

                                            <p><br/></p>

                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>


                                            <p></p>

                                            <p><br/></p>
                                            <h2 class="subhead">Factory Non Fungible Properties / Characteristics / Metadata / Format</h2>

                                            <p><span class="cow3">Stake Factory NFTs</span> all have a common format, for example all NFT's have a property called "id" - Let's now go over each of the 7 individual properties of every <span class="cow3">Stake Factory NFT</span>.</p>

                                            <p><br/></p>

                                            <p><span class="cow3">1.</span> <span class="cow2">"id" | NFT ID</span> All NFTs made at Stake Factory have a unique <span class="cow3">NFT ID</span>.
                                              <br/>
                                              + NFT IDs must be unique, 3-30 characters in length, and contain no spaces.
                                              <br/>
                                              + NFT IDs must be surrounded in "quotes" in your JSON NFT objects.
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + Example: my-nft-id:123
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">1.</span> <span class="cow2">"collectionid" | Collection ID</span> All NFTs made at Stake Factory are apart of a <span class="cow3">NFT Collection</span>.
                                              <br/>
                                              + Collection IDs must be unique, 3-30 characters in length, and contain no spaces, and you can only mint NFTs to your own NFT collections.
                                              <br/>
                                              + Collection IDs must be surrounded in "quotes" in your JSON NFT objects.
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + Example: "my-collection-id:123"
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">3.</span> <span class="cow2">"name" | NFT Name</span> All NFTs made at Stake Factory have a <span class="cow3">NFT Name</span>.
                                              <br/>
                                              + NFT Names must be 3-30 characters in length, and can contain spaces.
                                              <br/>
                                              + NFT Names must be surrounded in "quotes" in your JSON NFT objects.
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + Example: "My Cool NFT 1"
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">4.</span> <span class="cow2">"description" | NFT Description</span> All NFTs made at Stake Factory have a <span class="cow3">NFT Description</span>.
                                              <br/>
                                              + NFT Descriptions must be 3-256 characters in length.
                                              <br/>
                                              + NFT Descriptions must be surrounded in "quotes" in your JSON NFT objects.
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + Example: "This is my cool nft!"
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">5.</span> <span class="cow2">"image" | NFT Image</span> All NFTs made at Stake Factory have a <span class="cow3">NFT Image</span>.
                                              <br/>
                                              + NFT Images fields should contain a link to your NFT's image, must be 3-256 characters in length.
                                              <br/>
                                              + NFT Images must be surrounded in "quotes" in your JSON NFT objects.
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + Example: "https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/lg/307/cut-of-meat_1f969.png"
                                              <br/>
                                              + A great place to upload your NFT images is https://pinata.cloud/ , upload your images and come back and paste the links.
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">6.</span> <span class="cow2">"a1" | String Attributes</span> - Stake Factory NFTs may contain optional Metadata <span class="cow3">String Attributes</span>.
                                              <br/>
                                              + NFT String attributes must be surrounded in quotes and separated by commas in your JSON NFT Objects.
                                              <br/>
                                              + A String can contain any sequence of characters, including spaces, such as "Sword of Power +10"
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + An NFT can only have a maximum of 10 String Attributes
                                              <br/>
                                              + Example: ["NFT of Slaying +1", "Sword", "Special"]
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">7.</span> <span class="cow2">"a2" | Decimal Attributes</span> - Stake Factory NFTs may contain optional Metadata <span class="cow3">Decimal Attributes</span>.
                                              <br/>
                                              + NFT Decimal attributes must be separated by commas, and not surrounded in quotes in your JSON object.
                                              <br/>
                                              + A Decimal is a number that contains a decimal place, such as 3.14
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + An NFT can only have a maximum of 10 Decimal Attributes
                                              <br/>
                                              + Example: [203.43049, 64.2, 999.9]
                                            </p>

                                            <p><br/></p>

                                            <p><span class="cow3">8.</span> <span class="cow2">"a3" | Integer Attributes</span> - Stake Factory NFTs may contain optional Metadata <span class="cow3">Integer Attributes</span>.
                                              <br/>
                                              + NFT Integer attributes must be separated by commas, and not surrounded in quotations.
                                              <br/>
                                              + A Integer is a number that does not contain a decimal place, such as 3
                                              <br/>
                                              + Is required.
                                              <br/>
                                              + An NFT can only have a maximum of 10 Integer Attributes
                                              <br/>
                                              + Example: [345, 3, 9, 0]
                                            </p>


                                            <p><br/></p>

                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>



                                            <aside>
                                              <p class="cow2"> Although all Stake Factory tools are designed to detect and notify you of when you enter information incorrectly in a spot it doesn't belong, please make sure you read the <b-icon pack="fas" icon="info-circle" size="is-small" style="position: relative; top:2px; color: #4ae39e !important; "></b-icon> Help/Info sections on each page of the Stake Factory tools to ensure you don't experience any failed transactions using the Stake Factory tools.</p>
                                            </aside>

                                            <p><br/></p>
                                            <p><br/></p>
                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                          </article>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </transition>

                              <!--                              Whitelists section -->
                              <transition name="fade">
                                <section class="hero" v-if="activeOption === 21" style="margin-top:-2rem;">

                                  <div class="hero-body">
                                    <div class="container container2 animated fadeInUp">
                                      <div class="columns is-multiline is-mobile">
                                        <div  class="column is-12-desktop is-12-tablet is-12-mobile">
                                          <article>
                                          <pre class="is-hidden-mobile cow" style="background-color:transparent; margin-left: 0px; overflow:hidden;">  _______  ______________________  __      __  ___ ___ ._________________________.____    .___  _____________________________
  \      \ \_   _____/\__    ___/ /  \    /  \/   |   \|   \__    ___/\_   _____/|    |   |   |/   _____/\__    ___/   _____/
 /   |   \ |    __)    |    |    \   \/\/   /    ~    \   | |    |    |    __)_ |    |   |   |\_____  \   |    |  \_____  \
 /    |    \|     \     |    |     \        /\    Y    /   | |    |    |        \|    |___|   |/        \  |    |  /        \
 \____|__  /\___  /     |____|      \__/\  /  \___|_  /|___| |____|   /_______  /|_______ \___/_______  /  |____| /_______  /
         \/     \/                       \/         \/                        \/         \/           \/                  \/ </pre>

                                            <h2 class="subhead">NFT Whitelisted Presales</h2>

                                            <aside>
                                              <p class="cow2"> Create Whitelisted Members-only Presales for your Stake Factory NFT Collections</p>
                                            </aside>

                                            <br/>
                                            <p>The <span class="cow3">Stake Factory Whitelist Presale Tool</span> lets NFT creators easily create an entire 'member's only' pre-sale out of their entire NFT collection.</p>

                                            <p><span class="cow3">Whitelisted Presales</span> allow NFT creators to pre-sell an <span class="cow3">entire NFT Collection</span> to a pre-determined <span class="cow3">group of whitelisted members</span>, for a pre-determined <span class="cow3">length of time</span>.</p>

                                            <p><span class="cow3">Whitelisted Presales</span> are entirely <span class="cow3">optional</span>, and this tool is built specifically for NFT creators who would like to hold a NFT Presale for their NFT audiences.</p>

                                            <p><span class="cow3">Whitelisted Presales can only be performed on a un-revealed NFT Collection</span> and only if all of the NFTs in the collection have ever been sold before, or transferred from the NFT Creators wallet. </p>

                                            <p>If your <span class="cow3">NFT Collection</span> is <span class="cow3">visible</span> (revealed) on the <span class="cow3">Stake Factory website</span> from the <span class="cow3">"Explore NFTs"</span> link, then <span class="cow3">your NFT Collection has already been revealed</span> and you can <span class="cow3">no longer conduct a Whitelisted Pre-sale</span> for that NFT Collection - A NFT collection must not be revealed in order to conduct a presale, and a collection becomes revealed after a NFT Creator sells or transfers one of their NFTs to someone else.</p>

                                            <p>Every <span class="cow3">Stake Factory Tool</span> includes <span class="cow3">important Help & Info sections</span>, which are indicated by <b-icon
                                                pack="fas"
                                                icon="info-circle"
                                                size="is-small" style="position: relative; top:2px; color: #4ae39e !important; ">
                                            </b-icon>️ icons - <span class="cow3">Clicking</span> on these areas of Stake Factory tools will expose <span class="cow3">handy information</span> about the area your currently in and the step your currently working on. </p>

                                            <p><span class="cow3">The Whitelist Presale generator tool</span> will <span class="cow3">guide you through a series of 3 design steps</span> which will ultimately allow you to generate an active live presale - Simply set the <span class="cow3">Collection ID</span> of the NFT Collection you will be pre-selling, the <span class="cow3">price</span> of each NFT, and then set the <span class="cow3">length of time the presale will run</span> and create:</p>

                                            <aside>
                                              <p class="cow2"> Step 1 ) Enter the Collection ID of the NFT Collection you will presale.
                                              <br/><br/>
                                              Step 2 ) Enter the Price of the NFTs in the presale.
                                              <br/><Br/>
                                              Step 3 ) Enter the length of time your Presale will run and add your whitelisted members.
                                              <br/><br/>
                                              Step 4 ) Create</p>
                                            </aside>

                                            <p><span class="cow3">After</span> your presale is <span class="cow3">created</span>, it will become visible on the Stake Factory website, however, <span class="cow3">all your NFTs will still be obfuscated until the presale has ended</span>.</p>

                                            <p>Once your presale is <span class="cow3">created</span>, you will automatically be taken to a <span class="cow3">https://www.stakefactory.io/whitelist/MYCOLLECTION</span> page of your presale, <span class="cow3">make sure to give this link out to your audience</span> so they can purchase your NFTs before your presale ends - Users may also purchase NFT's by <span class="cow3">finding your collection under the Stake Factory "Explore NFTs" section</span>.</p>

                                            <p>Once your presale has ended, the sale is over and <span class="cow3">all the NFTs in the entire collection become visible to everyone on the Stake Factory website</span> - If you didn't all your NFTs in your whitelisted presale, no worries, feel free to <span class="cow3">add the NFTs that didn't get sold to the Stake Factory NFT Marketplace</span> where anyone can purchase your NFTs.</p>

                                            <p><br/></p>
                                            <p><br/></p>
                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>


                                            <aside>
                                              <h2 class="subhead"><span class="cow3">Have any questions about creating your White Listed pre-sale on Stake Factory? Feel free to join our channels to get answers to any questions you may have about Stake Factory NFT Presales.</span></h2>
                                            </aside>

                                          </article>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </transition>


                              <!--                              liquidity pools creation section -->
                              <transition name="fade">
                                <section class="hero" v-if="activeOption === 22" style="margin-top:-2rem;">

                                  <div class="hero-body">
                                    <div class="container container2 animated fadeInUp">
                                      <div class="columns is-multiline is-mobile">
                                        <div  class="column is-12-desktop is-12-tablet is-12-mobile">
                                          <article>
                        <pre class="is-hidden-mobile cow" style="background-color:transparent; margin-left: -40px; overflow:hidden;">   _______  ______ _______ _______ _______ _____ __   _  ______
   |       |_____/ |______ |_____|    |      |   | \  | |  ____
   |_____  |    \_ |______ |     |    |    __|__ |  \_| |_____|

        _____  _____  _     _ _____ ______  _____ _______ __   __       _____   _____   _____         _______
 |        |   |   __| |     |   |   |     \   |      |      \_/        |_____] |     | |     | |      |______
 |_____ __|__ |____\| |_____| __|__ |_____/ __|__    |       |         |       |_____| |_____| |_____ ______|</pre>

                                            <h2 class="subhead">Creating Liquidity Pools</h2>

                                            <aside>
                                              <p class="cow2"> Create customized liquidity pools with custom fees and treasury pools built right in with Stake Factory.</p>
                                            </aside>

                                            <br/>

                                            <figure style="width: 500px; height: 400px;"><img style="padding-top:30px; height: 400px" src="../assets/about-liquidity-pools.svg" />
                                              <figcaption>A Factory of Pooling Possibilities For Kadena</figcaption>
                                            </figure>

                                            <p><span class="cow3">Stake Factory</span> lets you create customized liquidity pools that can do more for your audience - <span class="cow3">All Liquidity Pools built at StakeFactory</span> allow pool creators to set their own <span class="cow3">custom swap fee</span> which collects in their very own factory generated <span class="cow3">Treasury Pool</span>.</p>

                                            <p><span class="cow3">Treasury Pools</span> created along with <span class="cow3">Liquidity Pools</span>, are what allow projects at Stakefactory to <span class="cow3">do more for their audience</span> - With a <span class="cow3">treasury</span>, your project can <span class="cow3">collect a portion of swap fees</span> so that it can create <span class="cow3">Staking Pools</span>, or <span class="cow3">conduct token burns</span>, or do <span class="cow3">whatever it is your project does</span> for your audience and <span class="cow3">more of it</span>.</p>

                                            <p>When you create a <span class="cow3">Liquidity Pool</span> with Stake Factory, you automatically create a <span class="cow3">Treasury Account</span> with your liquidity pool that can control your Treasury Pool from your Liquidity Pools page - The Liquidity Pool creation tool will <span class="cow3">guide</span> you through each step of the <span class="cow3">Liquidty Pool</span> creation process and let you pick which percentage of a swap goes to your Treasury Pool.</p>

                                            <p>This powerful concept of <span class="cow3">Treasury Pools with Liquidty Pools</span> lets you craft and cater to your audience in <span class="cow3">whatever ways you can imagine</span>, allowing projects making use of Stake Factory liquidity pools to come up with truly <span class="cow3">customized Defi Solutions</span>.</p>

                                            <p><span class="cow3">Stake Factory</span> also collects a <span class="cow3">portion of Swap fees from all liquidity pools</span>, which builds up in the <span class="cow3">Stake Factory Treasury</span> and is used to create <span class="cow3">Factory Community STEAK Pools</span> that allow the <span class="cow3">STEAK community</span> to help your project as well.</p>

                                            <p>The <span class="cow3">Stake Factory</span> is a <span class="cow3">community driven factory</span> that gets to decide how the <span class="cow3">STEAK</span> token is emitted from the factory and to which pools - Building a <span class="cow3">liquidity pool</span> on Stakefactory <span class="cow3">automatically links your project to the STEAK token community</span> and, together, with your project's community and the STEAK community, you get an entire pool-load of ways your project can <span class="cow3">connect and do more</span>.</p>

                                            <p><br/></p>
                                            <p><br/></p>
                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>


                                            <aside>
                                              <h2 class="subhead"><span class="cow3">Have any questions about creating your Liquidty Pools on Stake Factory? Feel free to join our channels to get answers to any questions you may have about Stake Factory Liquidty Pools and the STEAK Community.</span></h2>
                                            </aside>

                                          </article>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </transition>

                              <!--                              staking pools creation section -->
                              <transition name="fade">
                                <section class="hero" v-if="activeOption === 23" style="margin-top:-2rem;">

                                  <div class="hero-body">
                                    <div class="container container2 animated fadeInUp">
                                      <div class="columns is-multiline is-mobile">
                                        <div  class="column is-12-desktop is-12-tablet is-12-mobile">
                                          <article>
                        <pre class="is-hidden-mobile cow" style="background-color:transparent; margin-left: -30px; overflow:hidden;">      _______  ______ _______ _______ _______ _____ __   _  ______
      |       |_____/ |______ |_____|    |      |   | \  | |  ____
      |_____  |    \_ |______ |     |    |    __|__ |  \_| |_____|

 _______ _______ _______ _     _ _____ __   _  ______       _____   _____   _____         _______
 |______    |    |_____| |____/    |   | \  | |  ____      |_____] |     | |     | |      |______
 ______|    |    |     | |    \_ __|__ |  \_| |_____|      |       |_____| |_____| |_____ ______|</pre>

                                            <h2 class="subhead">Creating Staking Pools</h2>

                                            <aside>
                                              <p class="cow2"> Create customized staking pools with Stake Factory.</p>
                                            </aside>

                                            <br/>

                                            <figure style="width: 500px; height: 400px;"><img style="padding-top:30px; height: 390px" src="../assets/about-staking-pools.svg" />
                                              <figcaption>A Factory of Pooling Possibilities For Kadena</figcaption>
                                            </figure>

                                            <p><span class="cow3">Stake Factory</span> lets you create Staking Pools for Defi audiences that allow you to distribute a set amount of tokens to your audience over a period of time.</p>

                                            <p><span class="cow3">Staking pools</span> built at <span class="cow3">Stake Factory</span> can be thought of as <span class="cow3">'virtual token faucets'</span> which <span class="cow3">'drip'</span> out tokens to stakers - You set <span class="cow3">how many tokens</span> 'drip' out of the 'faucet', and <span class="cow3">how often</span>.</p>

                                            <p><span class="cow3">Staking Pools</span> will <span class="cow3">distribute</span>, ie 'drip', tokens to your Stakers at a <span class="cow3">constant rate</span>, for as long as you want, and, once a Staking Pool runs out of tokens they become <span class="cow3">exhausted</span> and <span class="cow3">shut down</span>.</p>

                                            <p>All <span class="cow3">Staking Pools</span> distribute tokens to stakers depending on <span class="cow3">how many tokens</span> they have staked in the pool.</p>

                                            <p>Staking pools are <span class="cow3">not</span> editable, and pool creators <span class="cow3">cannot claim the tokens back out of a staking pool</span> once they put them in the Staking Pool - Once a pool is started, it <span class="cow3">cannot</span> be adjusted and pool creators may not re-claim the rewards their pool has distributed to Stakers to claim.</p>

                                            <p><span class="cow3">Pool creators</span> may <span class="cow3">not</span> stake in their own <span class="cow3">Staking Pool</span>.</p>

                                            <p>All Stakers must <span class="cow3">claim their own rewards</span> - Staking pools do <span class="cow3">not</span> automatically transfer rewards to stakers, however, they <span class="cow3">do</span> reserve them- A stakers rewards are always a stakers rewards once they are earned, and if a staker cannot claim the rewards that they earned, then the Staking Pool will hold them <span class="cow3">indefinitely</span>.</p>

                                            <p>Staking Pool creators <span class="cow3">cannot</span> ban or remove Stakers from the Staking Pool - All staking pools are <span class="cow3">equal access pools</span> (Except to the creator, who may not Stake in their own pool).</p>

                                            <p>A <span class="cow3">Staking Pool</span> will <span class="cow3">distribute</span> (drip) tokens at a <span class="cow3">constant rate</span>, for example: 1 token per hour, or 1 token per second, or 1 token per .5 seconds - Tokens <span class="cow3">only</span> get distributed on the interval you set and <span class="cow3">never before</span>.</p>

                                            <p>A <span class="cow3">Staking Pool</span> distributes a <span class="cow3">set amount</span> of tokens at the time interval you set and never before - If you set a Staking Pool to release 10 tokens every hour, it releases <span class="cow3">exactly</span> 10 tokens <span class="cow3">every hour</span>, <span class="cow3">on the hour</span>, and <span class="cow3">0</span> tokens before that hour is reached - Stakers will have 0 rewards to claim <span class="cow3">until exactly 1 hour later</span> with these pool settings.</p>

                                            <p>Stake Factory often recommends creating staking pools that <span class="cow3">release small amounts of rewards constantly</span> - These are <span class="cow3">traditional</span> staking pools where stakers <span class="cow3">constantly</span> see their reward tickers go up if they refresh their staking page. Setting long intervals to distribute rewards to stakers, such as monthly, will leave Stakers seeing '0' rewards earned until a month later - Please be aware of how often you release tokens to your Stakers so they are not left confused and unstake.</p>

                                            <p><span class="cow3">Pool creators</span> may <span class="cow3">add</span> more distribution tokens to a <span class="cow3">Staking Pool</span> at <span class="cow3">any time</span> to extend it's run-time, and <span class="cow3">keep it from shutting down and becoming exhausted</span>.. However, <span class="cow3">after</span> a Staking Pool shuts down, <span class="cow3">it cannot be restarted</span>, and, it <span class="cow3">cannot</span> be given more tokens to be restarted - Once a pool shuts down, the pool becomes <span class="cow3">invisible</span> to anyone who isn't staked in the pool so that unclaimed rewards may be claimed by stakers still in the pool.</p>

                                            <p><span class="cow3">Stake Factory</span> recommends that Pool Creators <span class="cow3">periodically fill up their staking pools</span> if they want them to continue distributing rewards and to <span class="cow3">plan accordingly</span> to their audiences needs - Pool creators know <span class="cow3">exactly</span> when a Staking Pool will shut down and become exhausted by <span class="cow3">checking the Staking Pools info page</span>, and can easily <span class="cow3">send more tokens to their Staking Pools to keep them from running out and shutting down</span>.</p>

                                            <p><span class="cow3">Staking Pools</span> created with Stake Factory let you give your audience reasons to <span class="cow3">hold</span> onto your token- Tokens being staked are tokens not being sold, and with <span class="cow3">Stake Factory Tools</span> it's easy for you to craft the Staking experience your Defi audience appreciates.</p>

                                            <p><span class="cow3">Stake Factory</span> wants you to <span class="cow3">make Staking Pools</span> for the Kadena community, and creating Staking Pools is <span class="cow3">free</span> - If you forget about your Staking Pool because you are too busy building and it runs out of rewards, you can always <span class="cow3">create a new one</span>.</p>

                                            <p>All Staking pools have their own <span class="cow3">web link</span>, and <span class="cow3">appear on the Stake Factory website until they run out of tokens</span>- Make sure you give a <span class="cow3">link</span> to your Staking Pool to your audience so they can <span class="cow3">easily get to your pool</span> and not have to search through Stake Factory to find it.</p>

                                            <p><br/></p>
                                            <p><br/></p>
                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>


                                            <aside>
                                              <h2 class="subhead"><span class="cow3">Have any questions about creating your Staking Pools on Stake Factory? Feel free to join our channels to get answers to any questions you may have about Stake Factory and Staking.</span></h2>
                                            </aside>

                                          </article>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </transition>

                              <!--                              crowdfunding pools creation section -->
                              <transition name="fade">
                                <section class="hero" v-if="activeOption === 24" style="margin-top:-2rem;">

                                  <div class="hero-body">
                                    <div class="container container2 animated fadeInUp">
                                      <div class="columns is-multiline is-mobile">
                                        <div  class="column is-12-desktop is-12-tablet is-12-mobile">
                                          <article>
                        <pre class="is-hidden-mobile cow" style="background-color:transparent; margin-left: -30px; overflow:hidden;">      _______  ______ _______ _______ _______ _____ __   _  ______
      |       |_____/ |______ |_____|    |      |   | \  | |  ____
      |_____  |    \_ |______ |     |    |    __|__ |  \_| |_____|

 _______  ______  _____  _  _  _ ______  _______ _     _ __   _ ______  _____ __   _  ______
 |       |_____/ |     | |  |  | |     \ |______ |     | | \  | |     \   |   | \  | |  ____
 |_____  |    \_ |_____| |__|__| |_____/ |       |_____| |  \_| |_____/ __|__ |  \_| |_____|

       _____   _____   _____         _______
      |_____] |     | |     | |      |______
                                                       |       |_____| |_____| |_____ ______|                                                     </pre>

                                            <h2 class="subhead">Creating Crowdfunding Pools</h2>

                                            <aside>
                                              <p class="cow2"> Create equal access Crowdfunding Pools that raise funds over time and generate a liquidity pool.</p>
                                            </aside>

                                            <br/>

                                            <figure style="width: 500px; height: 400px;"><img style="padding-top:30px; height: 390px" src="../assets/about-crowdfunding-pools.svg" />
                                              <figcaption>A Factory of Pooling Possibilities For Kadena</figcaption>
                                            </figure>

                                            <p><span class="cow3">Stake Factory</span> lets you create <span class="cow3">Crowdfunding Pools</span> which <span class="cow3">raise funds</span> in order to create a <span class="cow3">liquidity pool</span> for projects trying to launch their own tokens.</p>

                                            <p><span class="cow3">Crowdfunding pools</span> built at <span class="cow3">Stake Factory</span> pre-sell a set amount of your token to the public, and, after your<span class="cow3"> crowdfunding pool</span> raises enough funds, it <span class="cow3">pairs the raised funds with your token and generates a liquidity pool</span> so anyone can swap between the two tokens.</p>

                                            <p><span class="cow3">Before</span> creating a <span class="cow3">Crowdfunding Pool</span>, pool creators should <span class="cow3">understand how liquidity pools work at Stake Factory</span>, because all successful crowdfunding pools eventually turn into liquidity pools.</p>

                                            <p><span class="cow3">Crowdfunding pools</span> sell your token at a <span class="cow3">constant price</span> to funders in order to <span class="cow3">raise funds</span> for a token pair, once the funds are raised, a <span class="cow3">liquidity pool</span> is generated, and then <span class="cow3">all the funders may withdraw the tokens they pre-purchased out of the crowdfunding pool</span> and begin swapping on the liquiditiy pool with their purchased tokens.</p>

                                            <p><span class="cow3">Crowdfunding pools</span> raise funds in a fair way - <span class="cow3">All funds must be raised</span> in order for your campaign to be <span class="cow3">successful</span>, if all funds are not raised by the time your crowdfunding campaign ends, <span class="cow3">then your campaign fails</span> and all funders can simply withdraw their pledged tokens back out of the pool.</p>

                                            <p><span class="cow3">Crowdfunding pools</span> will let funders <span class="cow3">withdraw</span> their pledged tokens at <span class="cow3">anytime up until the crowdfunding pool is completely funded</span> - <span class="cow3">Once</span> the pool is <span class="cow3">funded</span>, all funds are <span class="cow3">locked</span> and funders can <span class="cow3">no longer withdraw their pledged tokens</span> and <span class="cow3">must wait for the campaign to end</span> before claiming their purchased tokens.</p>

                                            <p><span class="cow3">Crowdfunding pools</span> can be ran for up to a<span class="cow3"> maximum of one month</span> - If your campaign does not raise funds within your set time, it will fail, and everyone can withdraw their pledged funds back out of the pool.</p>

                                            <p><span class="cow3">Failed Crowndfunding Campaigns</span> are <span class="cow3">automatically removed</span> from the <span class="cow3">Stake Factory website</span>, however, <span class="cow3">anyone still participating in the pool still sees the pool on the Stake Factory website</span> so that they can withdraw/claim their tokens .</p>

                                            <p>Crowdfunding pools are <span class="cow3">not</span> editable once they are created, and pool creators <span class="cow3">cannot claim the tokens back out of a crowdfunding pool</span> once they put them in the Crowdfunding Pool unless the crowdfunding pool has failed.- Once a campaign is started, it <span class="cow3">cannot</span> be adjusted and it will run until it's timer expires.</p>

                                            <p><span class="cow3">Pool creators</span> may <span class="cow3">not</span> pledge in their own <span class="cow3">Crowdfunding Pools</span> and may not purchase their own tokens.</p>

                                            <p><span class="cow3">Crowdfunding Pools</span> have <span class="cow3">optional</span> presale <span class="cow3">whitelist</span> options, and can optionally pre-sell your tokens to a set of whitelisted members before the public can begin funding the pool - This allows you to plan marketing strategies around your crowdfunding pool before its ever created by offering whitelist positions to your community.</p>

                                            <p>All funders must <span class="cow3">claim their purchased tokens</span> - Crowdfunding pools do <span class="cow3">not</span> automatically transfer tokens funders, however, they <span class="cow3">do</span> reserve them- A funders tokens are always their tokens once they are purchased, and if a funder cannot claim the tokens from the crowdfunding pool, then the Crowdfunding Pool will hold them <span class="cow3">indefinitely</span>.</p>

                                            <p>Crowdfunding Pool creators <span class="cow3">cannot</span> ban or remove funders from the Crowdfunding Pool - All Crowdfunding Pools are <span class="cow3">equal access pools</span> (Except to the creator, who may not purchase from their own pool).</p>

                                            <p>All Crowdfunding pools have their own <span class="cow3">web link</span>, and <span class="cow3">appear on the Stake Factory website until they expire</span>- Make sure you give a <span class="cow3">link</span> to your Crowdfunding Pool to your audience so they can <span class="cow3">easily get to your pool</span> and not have to search through Stake Factory to find it.</p>

                                            <p><br/></p>
                                            <p><br/></p>
                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>


                                            <aside>
                                              <h2 class="subhead"><span class="cow3">Have any questions about creating Crowdfunding Pools on Stake Factory? Feel free to join our channels to get answers to any questions you may have about Stake Factory and Crowdpooling.</span></h2>
                                            </aside>

                                          </article>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </transition>

                              <!--                              roadmap section -->
                              <transition name="fade">
                              <section v-if="activeOption === 2" class="hero" style="margin-top:-2rem;">
                                <div class="hero-body">
                                  <div class="container container2 animated fadeInUp">
                                    <div class="columns is-multiline is-mobile">
                                      <div class="column is-hidden-mobile">
                                        <pre class="cow" style="background-color:transparent; margin-left: -50px; overflow:hidden;">8"""8                       8""8""8
            8   8  eeeee eeeee eeeee    8  8  8 eeeee eeeee
            8eee8e 8  88 8   8 8   8    8e 8  8 8   8 8   8
            88   8 8   8 8eee8 8e  8    88 8  8 8eee8 8eee8
         88   8 8   8 88  8 88  8    88 8  8 88  8 88
          88   8 8eee8 88  8 88ee8    88 8  8 88  8 88 </pre>
                                      </div>
                                      <div  class="column is-12-desktop is-12-tablet is-12-mobile pinkish">
                                        <div class="main-timeline">
                                          <div class="timeline">
                                            <a href="#" class="timeline-content">
                                              <div class="timeline-icon">
                                                <i class="fa fa-globe"></i>
                                              </div>
                                              <h3 class="title">App Construction + Staking Contract</h3>
                                              <p class="description cow">
                                                April 2022 - Stake Factory set out to create Staking Solutions and Pools on the Kadena Blockchain; The Stake Factory App is born and staking contracts are created allowing users to safely stake Fungible and Non-fungible tokenized assets.
                                              </p>
                                            </a>
                                          </div>
                                          <div class="timeline">
                                            <a href="#" class="timeline-content">
                                              <div class="timeline-icon">
                                                <i class="fa fa-rocket"></i>
                                              </div>
                                              <h3 class="title">App Modification + Liquidity Pools</h3>
                                              <p class="description cow">
                                                July of 2022 - Stake Factory forks and modifies the KadenaSwap AMM to allow users to create private liquidity pools with customized fees and treasury accounts that can be used to reinforce Staking Pools.
                                              </p>
                                            </a>
                                          </div>
                                          <div class="timeline">
                                            <a href="#" class="timeline-content">
                                              <div class="timeline-icon">
                                                <i class="fa fa-globe"></i>
                                              </div>
                                              <h3 class="title">App Modification + Crowdfunding Contract</h3>
                                              <p class="description cow">
                                               July of 2022 - Stake Factory begins construction on the Stake Factory Crowdfunding Contract which allows users to conduct equal access crowdfunding campaigns that generate liquidity pools for their projects.
                                              </p>
                                            </a>
                                          </div>
                                          <div class="timeline">
                                            <a href="#" class="timeline-content">
                                              <div class="timeline-icon">
                                                <i class="fa fa-globe"></i>
                                              </div>
                                              <h3 class="title">Token + Namespace + Launch + More</h3>
                                              <p class="description cow">
                                                Currently in progress.
                                              </p>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              </transition>

                              <transition name="fade">
                              <section v-if="activeOption === 3" class="hero" style="margin-top:-2rem;">
                                <div class="hero-body">
                                  <div class="container container2 animated fadeInUp">
                                    <div class="columns is-multiline is-mobile">
                                      <article>
                                          <pre class="cow" style="background-color:transparent; overflow:hidden;"> ______  _     _ _____        _______       _____  __   _      _     _ _______ ______  _______ __   _ _______
 |_____] |     |   |   |         |         |     | | \  |      |____/  |_____| |     \ |______ | \  | |_____|
 |_____] |_____| __|__ |_____    |         |_____| |  \_|      |    \_ |     | |_____/ |______ |  \_| |     |</pre>


                                        <aside>
                                          <p class="cow2">Stake Factory is <span class="c4">built on Kadena</span>.</p>
                                        </aside>

                                        <br/>
                                        <p>Build all your decentralized apps, pools, tools, games, ideas, dreams with <span class="cow3">Kadena</span> - Decentralization and Scalability included.</p>
                                        <br/><br/>

                                      </article>

                                    </div>
                                  </div>
                                </div>
                              </section>
                              </transition>


                              <transition name="fade">
                              <section v-if="activeOption === 4" class="hero" style="margin-top:-2rem;">
                                <div class="hero-body">
                                  <div class="container container2 animated fadeInUp">
                                    <div class="columns is-multiline is-mobile">


                                      <article>
                                          <pre class="cow" style="background-color:transparent; margin-left: -80px; overflow:hidden;"> __        __    _ _      _
       \ \      / /_ _| | | ___| |_ ___
         \ \ /\ / / _` | | |/ _ \ __/ __|
          \ V  V / (_| | | |  __/ |_\__ \
           \_/\_/ \__,_|_|_|\___|\__|___/</pre>



                                        <aside>
                                          <p class="cow2">Stake Factory currently works with the following <span class="c4">wallets</span>:</p>
                                        </aside>

                                        <br/>
                                        <div  class="column is-12-desktop is-12-tablet is-12-mobile pinkish">
                                          <ul class="list-unstyled">
                                            <li><a href="https://xwallet.kaddex.com/" target="_blank">X-Wallet</a></li>
                                            <li><a href="https://zelcore.io/" target="_blank">Zelcore</a></li>
                                            <li><a href="https://chainweaver.kadena.network/" target="_blank">Chainweaver</a></li>
                                          </ul>
                                        </div>
                                        <br/><br/>

                                      </article>

                                    </div>
                                  </div>
                                </div>
                              </section>
                              </transition>

                              <!--                              gas section -->
                              <transition name="fade">
                              <section v-if="activeOption === 5" class="hero" style="margin-top:-2rem;">
                                <div class="hero-body">
                                  <div class="container container2 animated fadeInUp">
                                    <div class="columns is-multiline is-mobile">

                                      <article>
                                          <pre class="cow" style="background-color:transparent; margin-left: 0px; overflow:hidden;">      ♥♥♥♥♥♥   ♥♥♥♥♥  ♥♥♥♥♥♥♥
♥♥       ♥♥   ♥♥ ♥♥
     ♥♥   ♥♥♥ ♥♥♥♥♥♥♥ ♥♥♥♥♥♥♥
     ♥♥    ♥♥ ♥♥   ♥♥      ♥♥
      ♥♥♥♥♥♥  ♥♥   ♥♥ ♥♥♥♥♥♥♥</pre>



                                        <aside>
                                          <p class="cow2">At Stake Factory all Kadena Gas costs are paid by the user's account performing the action in the app.</p>
                                        </aside>

                                        <br/>
                                        <div  class="column is-12-desktop is-12-tablet is-12-mobile">
                                          <ul class="list-unstyled">
                                            <li>Some tools, such as <span class="cow3">liquidity pools</span>, allow users to adjust <span class="cow3">Gas Limits</span> and <span class="cow3">Transaction Time Limits</span>- giving them more <span class="cow3">control</span> over their transactions.</li>
                                            <li><br/></li>
                                            <li>It is <span class="cow3">important</span> to note that on the Kadena Blockchain <span class="cow3">if a transaction fails</span> for a user, that user is automatically charged the <span class="cow3">FULL gas limit</span> they have set in their transaction settings - Please be mindful of all Gas Limits adjustments you make on the Kadena Blockchain.</li>
                                          </ul>
                                        </div>

                                      </article>



                                    </div>
                                  </div>
                                </div>
                              </section>
                              </transition>

                              <!--                              liquidity pools  section -->

                              <transition name="fade">
                              <section v-if="activeOption === 6" class="hero" style="margin-top:-2rem;">
                                <div class="hero-body">
                                  <div class="container container2 animated fadeInUp">
                                    <div class="columns is-multiline is-mobile">

                                      <article>
                                          <pre class="cow" style="background-color:transparent;  overflow:hidden;">.%%......%%%%%%...%%%%...%%..%%..%%%%%%..%%%%%...%%%%%%..%%%%%%..%%..%%.
.%%........%%....%%..%%..%%..%%....%%....%%..%%....%%......%%.....%%%%..
.%%........%%....%%.%%%..%%..%%....%%....%%..%%....%%......%%......%%...
.%%........%%....%%..%%..%%..%%....%%....%%..%%....%%......%%......%%...
.%%%%%%..%%%%%%...%%%%%...%%%%...%%%%%%..%%%%%...%%%%%%....%%......%%...
........................................................................
.................%%%%%....%%%%....%%%%...%%.......%%%%..................
.................%%..%%..%%..%%..%%..%%..%%......%%.....................
.................%%%%%...%%..%%..%%..%%..%%.......%%%%..................
.................%%......%%..%%..%%..%%..%%..........%%.................
.................%%.......%%%%....%%%%...%%%%%%...%%%%..................
........................................................................</pre>
                                        <h2 class="subhead">About Liquidity Pools</h2>



                                        <aside>
                                          <p class="cow2">Liquidity pools are special pools that let participants <span style="font-weight: bold">swap</span> between 2 different tokenized assets.</p>
                                        </aside>

                                        <br/>
                                        <div  class="column is-12-desktop is-12-tablet is-12-mobile">
                                          <ul class="list-unstyled has-text-left">
                                            <li><br/></li>
                                            <li><span class="cow3">Liquidity pools</span> are special pools that let participants <span class="cow3">swap</span> between 2 different tokenized assets.</li>
                                            <li>Stake Factory allows users to <span class="cow3">create</span> and <span class="cow3">participate</span> in customized <span class="cow3">Liquidity Pools</span> utilizing the Kadenaswap AMM.</li>
                                            <li>Browse from and swap tokens between various <span class="cow3">liquidity pools</span> created by users on the Kadena Blockchain at Stake Factory.</li>
                                            <li><br/></li>
                                            <li>Liquidity pools have 3 basic functions: <span class="cow3">Swap; Add Liquidity; Remove Liquidity</span></li>
                                            <li><span class="cow2">Swap</span> - Swap between assets in the liquidity pool.</li>
                                            <li><span class="cow2">Add Liquidity</span> - Add Liquidity to the liquidity pool for participants to swap between.</li>
                                            <li><span class="cow2">Remove Liquidity</span> - Remove Liquidity from the liquidity pool which you have previously added.</li>
                                            <li><br/></li>
                                            <accordion class="mr-4">
                                              <accordion-item>
                                                <!-- This slot will handle the title/header of the accordion and is the part you click on -->
                                                <template slot="accordion-trigger">
                                                  <li><span class="cow2" style="font-size:17px; text-transform: uppercase">+ How To Swap</span></li>
                                                </template>
                                                <template slot="accordion-content">
                                                  <li><img src="../assets/howtoswap.gif" alt="How To Swap"/></li>
                                                </template>
                                              </accordion-item>
                                            </accordion>
                                            <li><br/></li>
                                            <accordion class="mr-4">
                                              <accordion-item>
                                                <!-- This slot will handle the title/header of the accordion and is the part you click on -->
                                                <template slot="accordion-trigger">
                                                  <li><span class="cow2" style="font-size:17px; text-transform: uppercase">+ How To Add Liquidity</span></li>
                                                </template>
                                                <template slot="accordion-content">
                                                  <li><img src="../assets/howtoswap.gif" alt="How To Add Liquidity"/></li>
                                                </template>
                                              </accordion-item>
                                            </accordion>
                                            <li><br/></li>
                                            <accordion class="mr-4">
                                              <accordion-item>
                                                <!-- This slot will handle the title/header of the accordion and is the part you click on -->
                                                <template slot="accordion-trigger">
                                                  <li><span class="cow2" style="font-size:17px; text-transform: uppercase">+ How To Remove Liquidity</span></li>
                                                </template>
                                                <template slot="accordion-content">
                                                  <li><img src="../assets/howtoremoveliquidity.gif" alt="How To Remove Liquidity"/></li>
                                                </template>
                                              </accordion-item>
                                            </accordion>

                                            <p><br/></p>
                                            <p><br/></p>
                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                            <p><br/></p>
                                            <h2 class="subhead">What's special about Stake Factory's Liquidity Pools?</h2>

                                            <li><br/></li>
                                            <li><span class="cow3">Stakefactory Liquidity Pools</span> allow projects to <span class="cow3">customize</span> their liquidity pools so that they can <span class="cow3">do more their Defi audience</span> - At Stakefactory, <span class="cow3">every Liquidity pool has it's own treasury pool as well</span> which the project can use to <span class="cow3">do things for it's audience</span>.</li>
                                            <li><br/></li>
                                            <li>Stakefactory Liquidty Pools let you set <span class="cow3">custom swap fees</span> that get collected into a <span class="cow3">treasury pool</span> so your project can build <span class="cow3">staking pools</span> for your community, or <span class="cow3">burn tokens</span>, or <span class="cow3">do whatever it is your treasury does</span> for your community.</li>
                                            <li><br/></li>
                                            <li>With <span class="cow3">Stakefactory</span> you can make your projects <span class="cow3">liquidity pool</span> do more for your audience and design entire project-wide concepts based around your project's treasury, to help <span class="cow3">fuel whatever it is your project and it's community can imagine</span>.</li>

                                            <p><br/></p>
                                            <p><br/></p>
                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                            <p><br/></p>
                                            <h2 class="subhead">More about Liquidity Pools</h2>
                                            <li><br/></li>
                                            <li><span class="cow3">Swapping</span> in a liquidity pool <span class="cow3">subjects each swap to the liquidity pools fee</span>, which is automatically deducted as the swap is performed.</li>
                                            <li><br/></li>
                                            <li><span class="cow3">Add liquidity</span> to liquidity pools to begin <span class="cow3">collecting fees</span> from anyone who uses the liquidity pool.</li>
                                            <li><br/></li>
                                            <li>When you <span class="cow3">add liquidity</span> to a liquidity pool, you are sent a <span class="cow3">liquidity pool token</span> back as a receipt- feel free to create liquidity pool token staking pools to further provide incentives for your defi audience with Stake Factory.</li>
                                            <li><br/></li>
                                            <p><br/></p>
                                            <p><br/></p>
                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                            <p><br/></p>
                                            <h2 class="subhead">About Swap Fees</h2>
                                            <li><br/></li>
                                            <li>All <span class="cow3">liquidity pools</span> have a <span class="cow3">Swap Fee</span> where a percentage of each swap is deducted as a fee.</li>
                                            <li><br/></li>
                                            <li>Swap fees are listed next to each <span class="cow3">liquidity pool</span> and are automatically deducted when a swap is performed from a liquidity pool.</li>
                                            <li><br/></li>
                                            <li><span class="cow3">Half</span> of each swap fee is sent to <span class="cow3">Liquidity Providers</span> in the Liquidity Pool as rewards for providing liquidity for people to swap with, <span class="cow3">1/4</span> is sent to the Liquidity Pool's Treasury, and <span class="cow3">1/4</span> is sent to the <span class="cow3">Stake Factory Treasury</span> to be distributed as via Factory Community Pools.</li>
                                          </ul>
                                        </div>

                                      </article>

                                    </div>
                                  </div>
                                </div>
                              </section>
                              </transition>

                              <!--                              vesting pools  section -->
                              <transition name="fade">
                                <section v-if="activeOption === 25" class="hero" style="margin-top:-2rem;">
                                  <div class="hero-body">
                                    <div class="container container2 animated fadeInUp">
                                      <div class="columns is-multiline is-mobile">

                                        <article>
                                          <pre class="cow" style="background-color:transparent;  overflow:hidden;">.........##..##..######...####...######..######..##..##...####..........
.........##..##..##......##........##......##....###.##..##.............
.........##..##..####.....####.....##......##....##.###..##.###.........
..........####...##..........##....##......##....##..##..##..##.........
...........##....######...####.....##....######..##..##...####..........
........................................................................
.................#####....####....####...##.......####..................
.................##..##..##..##..##..##..##......##.....................
.................#####...##..##..##..##..##.......####..................
.................##......##..##..##..##..##..........##.................
.................##.......####....####...######...####..................
........................................................................</pre>
                                          <h2 class="subhead">About Vesting Pools</h2>



                                          <aside>
                                            <p class="cow2">Vesting pools are private pools that hold and distribute tokens to a set of pool members over time.</p>
                                          </aside>



                                          <br/>
                                          <div  class="column is-12-desktop is-12-tablet is-12-mobile">
                                            <ul class="list-unstyled has-text-left">
                                              <li><h2 class="subhead">How do Vesting Pools work?</h2></li>
                                              <li><br/></li>
                                              <li><span class="cow3">Vesting pools</span> are special pools that pay tokens to a set of pool member accounts over time.</li>
                                              <li>Use <span class="cow3">Vesting pools</span> to do things like pay a group of people performing a task, such as team members, over a period time.</li>
                                              <li><span class="cow3">Vesting pools</span> are private pools, and only appear in your "My Pools" section if your are a member of the pool.</li>
                                              <li>Stake Factory allows users to <span class="cow3">create</span> and <span class="cow3">participate</span> in customized <span class="cow3">Vesting Pools</span> of various types.</li>
                                              <li><br/></li>
                                              <li>Vesting pools have 1 basic function: <span class="cow3">Claim;</span></li>
                                              <li><br/></li>
                                              <li><span class="cow2">Claim</span> - Claim your pending rewards from the Vesting Pool.</li>
                                              <li><br/></li>
                                              <accordion class="mr-4">
                                                <accordion-item>
                                                  <!-- This slot will handle the title/header of the accordion and is the part you click on -->
                                                  <template slot="accordion-trigger">
                                                    <li><span class="cow2" style="font-size:17px; text-transform: uppercase">+ How To Claim</span></li>
                                                  </template>
                                                  <template slot="accordion-content">
                                                    <li><img src="../assets/howtoclaimstake.gif" alt="How To Claim"/></li>
                                                  </template>
                                                </accordion-item>
                                              </accordion>
                                              <li><br/></li>
                                              <p><br/></p>
                                              <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                              <li><br/></li>
                                              <li> <h2 class="subhead">More About Vesting Pools..</h2></li>
                                              <li><br/></li>
                                              <li><span class="cow3">Vesting pools</span> are crafted and customized by pool creators on the Kadena Blockchain- Pool creators have <span class="cow3">various settings</span> they can adjust during pool creation which ultimately <span class="cow3">affect how the Vesting Pool behaves</span>.</li>
                                              <li>All custom <span class="cow3">Vesting Pool Rules</span> and <span class="cow3">Settings</span> are <span class="cow3">displayed</span> on the Vesting Pool and in the Vesting Pool's info page.</li>
                                              <li>It is <span class="cow3">important</span> to note the <span class="cow3">Pool Rules and Settings</span> set up by the pool creator, including:</li>
                                              <li><br/></li>
                                              <li><span style="color:#3abb81">*</span> <span class="cow2">Claim Wait Times</span>: Length of time pool members must wait between claiming tokens.</li>
                                              <li><span style="color:#3abb81">*</span> <span class="cow2">Withdraw Lock Times</span>: Initial length of time all pool members must wait before claiming tokens for the first time.</li>
                                              <li><br/></li>
                                              <li><span style="color:#3abb81">*</span> All rule durations are displayed in <span class="cow3">seconds</span>, IE: 3600 seconds = 1 Hour; 864000 seconds = 1 Week;</li>
                                              <li><br/></li>
                                              <li><span style="color:#3abb81">*</span> A <span class="cow3">Claim Wait Time</span> of 864000 seconds indicates a participant <span class="cow3">cannot claim rewards</span> from the Vesting Pool except once every 864000 seconds.</li>
                                              <li><span style="color:#3abb81">*</span> A <span class="cow3">Withdraw Lock Time</span> of 864000 seconds indicates a participant <span class="cow3">cannot claim tokens</span> until 864000 seconds after they were first added to the Vesting Pool.</li>
                                              <li><br/></li>
                                              <li><span style="color:#3abb81">*</span> Participants <span class="cow3">must withdraw</span> their tokens from a Vesting Pool - Vesting pools do not automatically pay pool members.</li>
                                            </ul>
                                            <p><br/></p>
                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                          </div>

                                        </article>


                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </transition>

                              <!--                              staking pools  section -->
                              <transition name="fade">
                              <section v-if="activeOption === 7" class="hero" style="margin-top:-2rem;">
                                <div class="hero-body">
                                  <div class="container container2 animated fadeInUp">
                                    <div class="columns is-multiline is-mobile">

                                      <article>
                                          <pre class="cow" style="background-color:transparent;  overflow:hidden;">..%%%%...%%%%%%...%%%%...%%..%%..%%%%%%..%%..%%...%%%%..
.%%........%%....%%..%%..%%.%%.....%%....%%%.%%..%%.....
..%%%%.....%%....%%%%%%..%%%%......%%....%%.%%%..%%.%%%.
.....%%....%%....%%..%%..%%.%%.....%%....%%..%%..%%..%%.
..%%%%.....%%....%%..%%..%%..%%..%%%%%%..%%..%%...%%%%..
........................................................
.........%%%%%....%%%%....%%%%...%%.......%%%%..........
.........%%..%%..%%..%%..%%..%%..%%......%%.............
.........%%%%%...%%..%%..%%..%%..%%.......%%%%..........
.........%%......%%..%%..%%..%%..%%..........%%.........
.........%%.......%%%%....%%%%...%%%%%%...%%%%..........
........................................................</pre>
                                        <h2 class="subhead">About Staking Pools</h2>



                                        <aside>
                                          <p class="cow2">Staking pools are special pools that allow participants to <span style="font-weight: bold">safely store & stake</span> their tokenized assets in exchange for tokenized rewards over time.</p>
                                        </aside>



                                        <br/>
                                        <div  class="column is-12-desktop is-12-tablet is-12-mobile">
                                          <ul class="list-unstyled has-text-left">
                                            <li><h2 class="subhead">How do Staking Pools work?</h2></li>
                                            <li><br/></li>
                                            <li><span class="cow3">Staking pools</span> are special pools that allow participants to <span class="cow3">safely store</span> their tokenized assets in exchange for tokenized rewards.</li>
                                            <li>Stake Factory allows users to <span class="cow3">create</span> and <span class="cow3">participate</span> in customized <span class="cow3">Staking Pools</span> of various types.</li>
                                            <li>Browse from and stake tokens in various <span class="cow3">staking pools</span> created by users on the Kadena Blockchain at Stake Factory.</li>
                                            <li><br/></li>
                                            <li>Staking pools have 3 basic functions: <span class="cow3">Stake; Claim; Withdraw</span></li>
                                            <li><span class="cow2">Stake</span> - Stake and store your assets in the staking pool to begin yielding it's rewards.</li>
                                            <li><span class="cow2">Claim</span> - Claim your pending rewards from the Staking Pool without withdrawing your staked assets.</li>
                                            <li><span class="cow2">Withdraw</span> - Withdraw your pending rewards and staked assets from the Staking Pool.</li>
                                            <li><br/></li>
                                            <accordion class="mr-4">
                                              <accordion-item>
                                                <!-- This slot will handle the title/header of the accordion and is the part you click on -->
                                                <template slot="accordion-trigger">
                                                  <li><span class="cow2" style="font-size:17px; text-transform: uppercase">+ How To Stake</span></li>
                                                </template>
                                                <template slot="accordion-content">
                                                  <li><img src="../assets/howtostake.gif" alt="How To Stake"/></li>
                                                </template>
                                              </accordion-item>
                                            </accordion>
                                            <li><br/></li>
                                            <accordion class="mr-4">
                                              <accordion-item>
                                                <!-- This slot will handle the title/header of the accordion and is the part you click on -->
                                                <template slot="accordion-trigger">
                                                  <li><span class="cow2" style="font-size:17px; text-transform: uppercase">+ How To Claim</span></li>
                                                </template>
                                                <template slot="accordion-content">
                                                  <li><img src="../assets/howtoclaimstake.gif" alt="How To Claim"/></li>
                                                </template>
                                              </accordion-item>
                                            </accordion>
                                            <li><br/></li>
                                            <accordion class="mr-4">
                                              <accordion-item>
                                                <!-- This slot will handle the title/header of the accordion and is the part you click on -->
                                                <template slot="accordion-trigger">
                                                  <li><span class="cow2" style="font-size:17px; text-transform: uppercase">+ How To Withdraw</span></li>
                                                </template>
                                                <template slot="accordion-content">
                                                  <li><img src="../assets/howtowithdrawstake.gif" alt="How To Withdraw"/></li>
                                                </template>
                                              </accordion-item>
                                            </accordion>
                                            <p><br/></p>
                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                            <li><br/></li>
                                            <li> <h2 class="subhead">More About Staking Pools..</h2></li>
                                            <li><br/></li>
                                            <li><span class="cow3">Staking pools</span> are crafted and customized by pool creators on the Kadena Blockchain- Pool creators have <span class="cow3">various settings</span> they can adjust during pool creation which ultimately <span class="cow3">affect how the Staking Pool behaves</span>.</li>
                                            <li>All custom <span class="cow3">Staking Pool Rules</span> and <span class="cow3">Settings</span> are <span class="cow3">displayed</span> next to the Staking Pool.</li>
                                            <li>It is <span class="cow3">important</span> to note the <span class="cow3">Pool Rules and Settings</span> set up by the pool creator when attempting to participate in their pool, including:</li>
                                            <li><br/></li>
                                            <li><span style="color:#3abb81">*</span> <span class="cow2">Claim Wait Times</span>: How often rewards can be claimed from the pool.</li>
                                            <li><span style="color:#3abb81">*</span> <span class="cow2">Withdraw Lock Times</span>: How long tokenized assets are locked in the pool before they can be withdrawn.</li>
                                            <li><br/></li>
                                            <li><span style="color:#3abb81">*</span> All rule durations are displayed in <span class="cow3">seconds</span>, IE: 3600 seconds = 1 Hour; 864000 seconds = 1 Week;</li>
                                            <li><br/></li>
                                            <li><span style="color:#3abb81">*</span> A <span class="cow3">Claim Wait Time</span> of 864000 seconds indicates a participant <span class="cow3">cannot claim rewards</span> from the Staking Pool except once every 864000 seconds.</li>
                                            <li><span style="color:#3abb81">*</span> A <span class="cow3">Withdraw Lock Time</span> of 864000 seconds indicates a participant <span class="cow3">cannot withdraw their staked tokenized assets</span> until 864000 seconds after they were first staked in the Staking Pool.</li>
                                            <li><br/></li>
                                            <li><span style="color:#3abb81">*</span> Participants <span class="cow3">must withdraw</span> their Staked tokens from a Staking Pool if they intend to Stake more tokens in the same Staking Pool <span class="cow3">they are already participating in</span>.</li>
                                            <li><span style="color:#3abb81">*</span> Staking pools do <span class="cow3">not</span> charge a fee to participants, however, <span class="cow3">gas costs are always paid by the user performing the Staking Pool transactions</span>.</li>
                                          </ul>
                                          <p><br/></p>
                                          <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                        </div>

                                      </article>


                                    </div>
                                  </div>
                                </div>
                              </section>
                              </transition>


                              <transition name="fade">
                              <section v-if="activeOption === 8" class="hero" style="margin-top:-2rem;">
                                <div class="hero-body">
                                  <div class="container container2 animated fadeInUp">
                                    <div class="columns is-multiline is-mobile">

                                      <article>
                                          <pre class="cow" style="background-color:transparent;  overflow:hidden;">..%%%%...%%%%%....%%%%...%%...%%..%%%%%...%%%%%%..%%..%%..%%..%%..%%%%%...%%%%%%..%%..%%...%%%%..
.%%..%%..%%..%%..%%..%%..%%...%%..%%..%%..%%......%%..%%..%%%.%%..%%..%%....%%....%%%.%%..%%.....
.%%......%%%%%...%%..%%..%%.%.%%..%%..%%..%%%%....%%..%%..%%.%%%..%%..%%....%%....%%.%%%..%%.%%%.
.%%..%%..%%..%%..%%..%%..%%%%%%%..%%..%%..%%......%%..%%..%%..%%..%%..%%....%%....%%..%%..%%..%%.
..%%%%...%%..%%...%%%%....%%.%%...%%%%%...%%.......%%%%...%%..%%..%%%%%...%%%%%%..%%..%%...%%%%..
.................................................................................................
.........................%%%%%....%%%%....%%%%...%%.......%%%%..................................
.........................%%..%%..%%..%%..%%..%%..%%......%%.....................................
.........................%%%%%...%%..%%..%%..%%..%%.......%%%%..................................
.........................%%......%%..%%..%%..%%..%%..........%%.................................
.........................%%.......%%%%....%%%%...%%%%%%...%%%%..................................
................................................................................................ </pre>
                                        <h2 class="subhead">About CrowdFunding Pools</h2>



                                        <aside>
                                          <p class="cow2">Crowdfunding Pools are special pools that allow participants to <span style="font-weight:bold">pledge</span> funds and pre-purchase tokenized assets offered by pool creators- <span style="font-weight:bold">All raised funds</span> are automatically <span style="font-weight:bold">converted into a liquidity pool</span> at the Stake Factory once the crowdfunding campaign is <span style="font-weight:bold">successfully funded and concluded</span>.</p>
                                        </aside>

                                        <br/>
                                        <div  class="column is-12-desktop is-12-tablet is-12-mobile">
                                          <ul class="list-unstyled has-text-left">
                                            <li><br/></li>
                                            <li><span class="cow3">Crowdfunding Pools</span> are special pools that allow participants to <span class="cow3">pledge</span> funds and pre-purchase tokenized assets offered by pool creators- <span class="cow3">All raised funds</span> are automatically <span class="cow3">converted into a liquidity pool</span> at the Stake Factory once the crowdfunding campaign is <span class="cow3">successfully funded and concluded</span>.</li>
                                            <li><br/></li>
                                            <li>Stake Factory allows users to <span class="cow3">create</span> and <span class="cow3">participate</span> in customized equal access <span class="cow3">Crowdfunding Pools</span> safely by including optional pool creation functionalities such as <span class="cow3">rug-pull insurance</span>.</li>
                                            <li><br/></li>
                                            <p><br/></p>
                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                            <li><h2 class="subhead">How do Crowdfunding Pools work?</h2></li>
                                            <li><br/></li>
                                            <li>The <span class="cow3">goal</span> of all <span class="cow3">Crowdfunding Pools</span> is to <span class="cow3">raise funds</span> and automatically create a <span class="cow3">swappable liquidity pair and liquidity pool</span> out of those raised funds <span class="cow3">safely</span> on the Stake Factory website.</li>
                                            <li><br/></li>
                                            <li><span class="cow3">Crowdfunding Pools</span> operate on a <span class="cow3">timer</span> and <span class="cow3">Crowdfunders</span> are able to <span class="cow3">pledge</span> funds into the pool while the crowdfunding campaign timer is <span class="cow3">active and running</span>, effectively pre-purchasing tokens <span class="cow3">if the campaign succeeds</span>.</li>
                                            <li><br/></li>
                                            <li>Once a <span class="cow3">Crowdfunding Pool's</span> campaign <span class="cow3">timer</span> has <span class="cow3">ended</span>, if the pool's campaign was <span class="cow3">successfully funded</span>, a <span class="cow3">liquidity pool</span> can be <span class="cow3">generated</span> out of the pledged funds by clicking the "<span class="cow3">Generate Liquidity Pool</span>" button that appears on all funded Crowdfunding Pools.</li>
                                            <li><br/></li>
                                            <li><span class="cow3">Crowdfunders</span> can <span class="cow3">always withdraw</span> their pledged tokens at any time <span class="cow3">before the crowdfunding campaign has become fully funded</span>.</li>
                                            <li><br/></li>
                                            <li>Once a pool has become <span class="cow3">fully funded</span>, pledged funds are <span class="cow3">locked</span> into the pool, and they can <span class="cow3">no longer be withdrawn</span> - These <span class="cow3">pledged funds</span> are then <span class="cow3">used to generate a liquidity pool</span> at the Stake Factory.</li>
                                            <li><br/></li>
                                            <li><span class="cow3">After generating a liquidity pool</span>, Crowdfunders may then <span class="cow3">claim</span> the tokens they purchased from pledging into the crowdfunding pool's campaign.</li>
                                            <li><br/></li>
                                            <li>Browse from and pledge tokens in various <span class="cow3">Crowdfunding Pools</span> created by users at Stake Factory to help <span class="cow3">raise funds and be apart of future Defi solutions on the Kadena Blockchain</span>.</li>
                                            <li><br/></li>
                                            <li><span class="cow3">Crowdfunding pools</span> have 3 basic functions: <span class="cow3">Pledge; Claim; Withdraw</span></li>
                                            <li><span class="cow2">Pledge</span> - Pledge to purchase tokens from the crowdfunding pool once it has concluded - Your pledged tokens are stored in the crowdfunding pool but can be withdrawn anytime if the pool has not yet been fully crowdfunded.</li>
                                            <li><span class="cow2">Claim</span> - Claim your pending purchased tokens from the Crowdfunding Pool once it has successfully concluded.</li>
                                            <li><span class="cow2">Withdraw</span> - Withdraw your pledge from the Crowdfunding Pool and purchase nothing when the crowdfunding campaign is concluded.</li>
                                            <li><br/></li>
                                            <accordion class="mr-4">
                                              <accordion-item>
                                                <!-- This slot will handle the title/header of the accordion and is the part you click on -->
                                                <template slot="accordion-trigger">
                                                  <li><span class="cow2" style="font-size:17px; text-transform: uppercase">+ How To Pledge</span></li>
                                                </template>
                                                <template slot="accordion-content">
                                                  <li><img src="../assets/howtopledge.gif" alt="How To Pledge"/></li>
                                                </template>
                                              </accordion-item>
                                            </accordion>
                                            <li><br/></li>
                                            <accordion class="mr-4">
                                              <accordion-item>
                                                <!-- This slot will handle the title/header of the accordion and is the part you click on -->
                                                <template slot="accordion-trigger">
                                                  <li><span class="cow2" style="font-size:17px; text-transform: uppercase">+ How To Claim</span></li>
                                                </template>
                                                <template slot="accordion-content">
                                                  <li><img src="../assets/howtoclaimstake.gif" alt="How To Claim"/></li>
                                                </template>
                                              </accordion-item>
                                            </accordion>
                                            <li><br/></li>
                                            <accordion class="mr-4">
                                              <accordion-item>
                                                <!-- This slot will handle the title/header of the accordion and is the part you click on -->
                                                <template slot="accordion-trigger">
                                                  <li><span class="cow2" style="font-size:17px; text-transform: uppercase">+ How To Withdraw</span></li>
                                                </template>
                                                <template slot="accordion-content">
                                                  <li><img src="../assets/howtowithdrawpledge.gif" alt="How To Withdraw"/></li>
                                                </template>
                                              </accordion-item>
                                            </accordion>
                                            <p><br/></p>
                                            <p>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span>+<span class="cow3">-</span></p>

                                            <li><br/></li>
                                            <li><h2 class="subhead">More About CrowdFunding Pools..</h2></li>
                                            <li><span class="cow3">Crowdfunding pools</span> are crafted and customized by pool creators on the Kadena Blockchain- Pool creators have <span class="cow3">various settings</span> they can adjust during pool creation which ultimately <span class="cow3">affect how the Crowdfunding Pool behaves</span>.</li>
                                            <li>All custom <span class="cow3">Crowdfunding Campaign Rules</span> and <span class="cow3">Settings</span> are <span class="cow3">displayed</span> next to the Crowdfunding Pool.</li>
                                            <li>It is <span class="cow3">important</span> to note the <span class="cow3">Pool Rules and Settings</span> set up by the pool creator when attempting to participate in their crowdfunding campaign, including:</li>
                                            <li><br/></li>
                                            <li><span style="color:#3abb81">*</span> <span class="cow2">Tokens Sold vs Liquidity</span>: How many tokens are being sold to crowdfunders and how many are going to generate a liquidity pool?</li>
                                            <li><span style="color:#3abb81">*</span> <span class="cow2">Campaign Time</span>: How long the campaign is going to be running - pre-purchased tokens cannot be claimed until the pools timer runs out.</li>
                                            <li><span style="color:#3abb81">*</span> <span class="cow2">Liquidity Pool Fee</span>: The Swap Fee of the the liquidity pool being generated out of the campaign.</li>
                                            <li><span style="color:#3abb81">*</span> <span class="cow2">Rug Insurance Time</span>: The time in seconds the pool creator is unable to withdraw LP tokens from Stake Factory.</li>
                                            <li><br/></li>
                                            <li><span style="color:#3abb81">*</span> All rule durations are displayed in <span class="cow3">seconds</span>, IE: 3600 seconds = 1 Hour; 864000 seconds = 1 Week;</li>
                                            <li><br/></li>
                                            <li>Although <span class="cow3">Stake Factory</span> adds functions such as Rug Pull Insurance to help establish trust between crowdfunders and pool creators, Stake Factory ultimately has no control over what pool creators do after rug pull insurance has ended - <span class="cow3">Always</span> take note of a crowdfunding pool's rules and <span class="cow3">research crowdfunding campaigns before pledging and crowdfunding,</span>.</li>
                                          </ul>
                                        </div>

                                      </article>


                                    </div>
                                  </div>
                                </div>
                              </section>
                              </transition>





                            </section>




                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

          <div style="margin-bottom: 10%"></div>

        </section>
      </div>
    </section>

    </transition>
  </div>
</template>

<script>
import Accordion from "./accordion.vue";
import AccordionItem from "./accordion-item";
import {mapGetters} from "vuex";
import FlipCardMintPreview from "./FlipCardMintPreview.vue";
import CollectionCardPreview from "./CollectionCardPreview.vue";

//local state
export default {
  name: "CrowdFundAdminPage",
  components:{
    Accordion,
    AccordionItem,
    FlipCardMintPreview,
    CollectionCardPreview
  },
  data: function () {
    return {
      debug: false,
      activeTab: "0",
      currentPage: "0",
      activeOption: 1,
      showLoadingModal: true,
      currentDate: null,
    };
  },
  computed:{

  },
  methods: {
    ...mapGetters("accounts", ["getAccountExists", "getShowNavBar", "getAccountName"]),

    setOption(option){
      this.activeOption = option;
    },

    coinName(namespace, name){
      let newnamespace = namespace+'.';
      let newname = name;
      if(namespace === null || namespace === 'null'){
        newnamespace = '';
      }
      if(name === null || name === 'null'){
        newname = '';
      }
      return newnamespace+newname;
    },



  },
  async created() {

    let name = localStorage.getItem("accountName");

    //Lets check if our user is logged in for the pool creation page, if not, lets redirect them to home page
    if (name !== null || name !== "" || name !== "null") {

      let t_name_payload = {
        name: name
      }

      await this.$store.dispatch("accounts/getCurrentBindings", t_name_payload);

    }
    //Close loading screen
    setTimeout(async () => {
      this.showLoadingModal = false;
    }, 100);

  }

};
</script>

<style scoped>

.cow2 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(44 44 44);
  background: -webkit-linear-gradient(#5aff67, #55ff6a) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}

.cow3 {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(80 58 80);
  background: -webkit-linear-gradient(#d4acf3, #e9d1ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


.divider {
  border: none;
  height: 20px;
  width: 90%;
  height: 50px;

  border-bottom: 1px solid #353836;
  box-shadow: 0 20px 20px -20px #353836;
  margin: -50px auto 10px;
  margin-top: -30px !important;
}

.noscroll::-webkit-scrollbar {
  display: none;
}

.container2 {
  background-color: #1D1F21;
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  padding: 25px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), transparent 85%), radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%), linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
}

.hero.is-dark {
  background-color: #1D1F21
}

li a {
  color: #353836;
}

li a:hover,
li a:visited,
li a:active {
  text-decoration: none;
  transition: .1s;
}

#style-10::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #200d31;
  border-radius: 10px;
}

#style-10::-webkit-scrollbar
{
  width: 10px;
  background-color: #2c2432;
}

#style-10::-webkit-scrollbar-thumb
{
  background-color: #2c2432;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
  rgba(0, 0, 0, .2) 25%,
  transparent 25%,
  transparent 50%,
  rgba(0, 0, 0, .2) 50%,
  rgba(0, 0, 0, .2) 75%,
  transparent 75%,
  transparent)
}


#google {
  color: #fd3c3c;
  text-shadow: 0px 0px 4px rgb(0 0 0) !important;
}

#google ul li a {
  color: #43bdb4;
  text-shadow: 0px 0px 4px rgba(10, 2, 19, 0.77) !important;
}

#google ul li a:hover {
  color: #CC6666;
}

#jp {
  color: #6aaee7;
  text-shadow: 0px 0px 4px rgb(0 0 0) !important;
}

#jp ul li a {
  color: #43bdb4;
  text-shadow: 0px 0px 4px rgba(10, 2, 19, 0.77) !important;
}

#jp ul li a:hover {
  color: #81A2BE;
}

#boards {
  color: #B5BD68;
  text-shadow: 0px 0px 4px rgb(0 0 0) !important;
}

#boards ul li a {
  color: #43bdb4;
  text-shadow: 0px 0px 4px rgba(10, 2, 19, 0.77) !important;
}

#boards ul li a:hover {
  color: #B5BD68;
}

#nfts {
  color: #bd6ae7;
  text-shadow: 0px 0px 4px rgb(0 0 0) !important;
}

#nfts ul li a {
  color: #43bdb4;
  text-shadow: 0px 0px 4px rgba(10, 2, 19, 0.77) !important;
}

#nfts ul li a:hover {
  color: #81A2BE;
}

#dev {
  color: #B294BB;
}

#dev ul li a {
  color: #353836;
}

#dev ul li a:hover {
  color: #B294BB;
}

#docs {
  color: #A64848;
}

#docs ul li a {
  color: #353836;
}

#docs ul li a:hover {
  color: #A64848;
}

#utils {
  color: #597C99;
}

#utils ul li a {
  color: #353836;
}

#utils ul li a:hover {
  color: #597C99;
}

#web {
  color: #7B8235;
}

#web ul li a {
  color: #353836;
}

#web ul li a:hover {
  color: #7B8235;
}



.c1 {
  color: #ad3434;
}

.c1 ul li a {
  color: #c5c8c6;
}

.c1 ul li a:hover {
  color: #ad3434;
}

.c2 {
  color: #2c5c84;
}

.c2 ul li a {
  color: #c5c8c6;
}

.c2 ul li a:hover {
  color: #2c5c84;
}

.c3 {
  color: #B5BD68;
}

.c3 ul li a {
  color: #c5c8c6;
}

.c3 ul li a:hover {
  color: #B5BD68;
}

.c4 {
  color: #2a2a62;
  font-weight: bold;
}

.c4 ul li a {
  color: #c5c8c6;
}

.c4 ul li a:hover {
  color: #360046;
}

.c5 {
  color: #5e1919;
}

.c5 ul li a {
  color: #c5c8c6;
}

.c5 ul li a:hover {
  color: #5e1919;
}

.c6 {
  color: #307135;
}

.c6 ul li a {
  color: #c5c8c6;
}

.c6 ul li a:hover {
  color: #307135;
}

.c7 {
  color: #424613;
}

.c7 ul li a {
  color: #c5c8c6;
}

.c7 ul li a:hover {
  color: #424613;
}

/deep/ .accordion__item{
  cursor: pointer;
  padding: 10px 20px 10px 40px;
  border-bottom: 1px solid #ebebeb;
  position: initial;
}

.pinkish {
  color: #59c2b6;
}

.pinkish ul li a {
  color: #c5c8c6;
}

.pinkish ul li a:hover {
  color: #59c2b6;
}

@media (min-width: 544px) {
  .container {
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .container {
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  .container {
    font-size: 16px;
  }
}

.form-control {
  background-color: #1D1F21;
  display: none;
  padding: 0px;
  border: none;
  color: #c5c8c6;
}

input[type="search-input"]:focus {
  background-color: #1D1F21;
  color: #c5c8c6;
}

input[type="search-input"]:hover {
  background-color: #222426;
  color: #c5c8c6;
}

#searchform {
  display: block;
  text-align: center;
  width: 100%;
}

form {
  display: inline-block;
  width: 90%;
}

label {
  color: #CC6666;
  display: inline-block;
  float: left;
  padding-right: 1em;
}

input {
  background-color: #215581 !important;
  border-color: #2f597d !important;
  box-shadow: none;
  color: #19ac78;
  box-shadow: inset 0 30px 60px -12px rgba(50, 50, 93, 0.25), inset 0 18px 36px -18px rgba(0, 0, 0, 0.30);
}

.label {
  color: #3abb81;
  display: block;
  font-size: 1rem;
  font-weight: 700;
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient( #3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5% );
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 40px;
}


.machineinterface {
  display: block;
  width: 100%;
  margin: -80px 0px;
  position: relative;
}

.monitor {
  min-height: 100vh;
  padding-top: 85px;
  z-index: 2;
  display: block;
  width: 100%;
  background-color: #47485a;
  position: relative;
  padding-bottom: 40px;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, #26202b08 35px, #26202b0d 70px);
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
}

.monitor-inner {
  padding-top: 15px;
  display: block;
  width: 95%;
  background-image: conic-gradient( #414254 0 10.5%, #414254 11% 11.5%, #414254 12% 38%, #414254 39% 61.5%, #414254 62% 88%, #414254 88.5% 89%, #414254 89.25% );
  margin: auto;
  position: relative;
  top: 20px;
  left: 0;
  right: 0;
  border-radius: 5px;
  margin-top: -51px;
  padding-bottom: 60px;
}

.screen-cutout {
  display: block;
  width: 95%;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 21px;
  border-radius: 5px;
  background-image: conic-gradient(#3a3c4c 12.5%, #3a3c4c 15.5% 33%, #3a3c4c 34% 65.5%, #3a3c4c 66.5% 83.5%, #3a3c4c 86.5%);
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 50px;
}

.screen {
  display: block;
  width: 95%;
  background-color: #4F5555;
  background-image: radial-gradient(#525B5A, #50585A);
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  margin-top: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 30px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), transparent 85%), radial-gradient(rgba(84, 90, 95, 0.75), transparent 70%), linear-gradient(#647580, #647580, #647580);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 7px -4px #4f545f inset, 0 -2px 1px #5c6574 inset;
}

.header-bg3 {
  box-shadow: rgb(22 24 26) 0px 10px 8px 0px inset;
  height: 1px;
  padding-top: 0px;
}

.b-tabs .tabs {
  overflow: hidden !important;
}

/deep/ .b-tabs .tabs {
  overflow: hidden !important;
}

::v-deep .b-tabs .tabs {
  overflow: hidden !important;
}


.center-flex{
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.title-back {
  color: #ffffff;
  font-family: Source Code Pro,monospace !important;
  border-radius: 1rem;
  background: -webkit-linear-gradient(90deg, rgba(49, 56, 64, 0.89), rgba(55, 45, 69, 0.85));
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}


/* card background */
.home-card-bg {
  background: #ebeae5;
}

.inset-bg{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding-bottom: 2rem;
  border-radius: 100px;
}

.inset-bg2:active{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  width: 100%;
  height: 30px;
  position: relative;
  border-radius: 1rem;
  align-self: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 4px;
}


.col-title {
  color: #36c0ea;
  font-size: 14px;
}

.pending-title {
  color: #3cea36;
  font-size: 14px;
}

.pending-stat {
  color: #3cea36;
  font-size: 1em;
}

.pending-col{
  background-color: rgba(38, 43, 56, 0.29);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.col-shadow1{
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.provider{
  font-size: 12px;
}

.pool-name{
  font-size: 14px;
  color: #36c0ea;
}

/*.pool-name:before{*/
/*  content: "🥩";*/
/*  color: #ff2323;*/
/*}*/


.pools-bg {
  background-color: #222b3a;
}

.pool-main {
  background-color: #222b3a;
  border-radius: 1rem !important;
}

.box[data-v-7e668c97] {
  margin: 0 auto;
  background-color: #293038;
}

.pool-item {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 30px 3px;
  border-radius: 1rem;
  color: #3aacbb;
  font-family: Source Code Pro,monospace !important;
}

.pool-section-title {
  background-color: #333b4c;
  border: 1px solid rgba(3, 150, 255, 0.38);

  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  color: #46a9e7;
  font-family: Source Code Pro,monospace !important;
}


.topbackgroundanimation {
  position: absolute;
  height: 200px;
  width: 100%;
  z-index: 1;
}

/* border for item cards */
.cardborder {
  border-radius: 1rem;
  color: #000000;
}

.poolselect-bg {
  background-color: #222b3a !important;
  border: none;
  border-radius: 1rem !important;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}



/deep/ .tabs.is-toggle li.is-active a {
  background-color: #252f3e;
  color: #3abb81;
  z-index: 1;
  font-family: Source Code Pro,monospace !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}


/deep/ .tabs.is-toggle {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 1rem;
  background-color: #252f3e;
}

/deep/ .tabs.is-toggle a {
  margin-bottom: 0;
  position: relative;
  font-family: Source Code Pro,monospace !important;
  border:none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  color: #476074;
}

/deep/ .b-tabs .tabs.is-toggle li a:focus {
  background-color: #222b3a;
}

/deep/ .b-tabs .tabs.is-toggle li a:hover {
  background-color: #222b3a;
  border:none;
  color: #00b89c;
}


#style-8::-webkit-scrollbar-track
{
  border: 1px solid black;
  background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar
{
  width: 10px;
  background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar-thumb
{
  background-color: #000000;
}



.pools-card{
  padding-top:.5rem;
  color: #3abb81;
  margin-left: 2rem;
  margin-right: 2rem;
}


.my-pools-shadow{
  box-shadow: rgba(0, 0, 0, 0.56) 8px -5px 40px;
  border-radius: 30px;
}

.stake-btn-col{
  display: flex;
  justify-content: center;
}

.btn{
  color: #87b6e5;
  background: rgba(3, 150, 255, 0.32);
  background-repeat: no-repeat;
  font-family: Source Code Pro,monospace !important;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 20px;
  box-shadow: rgba(3, 102, 214, 0.22) 0px 0px 20px 0px;
  z-index: 5;
}
.btn:focus,
.btn:hover{
  color: #fff;
  background: radial-gradient(circle at center,rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
  #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 54% 105% / 1.07em 1.07em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 5% 149% / 0.73em 0.73em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 40% 142% / 0.54em 0.54em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 95% 118% / 0.57em 0.57em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 16% 147% / 0.74em 0.74em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 31% 85% / 0.72em 0.72em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 0% 120% / 0.89em 0.89em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 51% 83% / 0.79em 0.79em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 27% 101% / 0.61em 0.61em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 67% 90% / 0.7em 0.7em,
  radial-gradient(circle at center, rgba(0, 0, 0, 0) 30%, #eeeeff 60%,
      #eeeeff 65%, rgba(0, 0, 0, 0) 70%) 63% 94% / 0.65em 0.65em;
  background-color: rgba(3, 150, 255, 0.38);
  background-repeat: no-repeat;
  animation: bubbles 0.8s forwards ease-out;
}
@keyframes bubbles{
  100%{
    background-position: 64% -186%, 12% -128%, 47% -151%, 100% -22%, 19% -289%,26% -96%,-1% -271%, 57% -242%, 31% -147%, 62% -90%, 65% -49%;
    box-shadow: inset 0 -6.5em 0 #255e86;
    color: #00b89c;
  }
}
@media only screen and (max-width: 767px){
  .btn{ margin-bottom: 30px; }
}

@media screen and (max-width: 769px), print {
  .screen-cutout {
    background-image: none !important;
    background-color: transparent !important;
  }

  /deep/ .super-select-dropdown {
    background-color: #333b4c !important;
    border: 1px solid !important;
    border-color: #2f597d !important;
    color: #67b6ef !important;
    width: 380px !important;
  }
}

/* page loading style */
.loaderstyle {
  z-index: 1;
  position: absolute !important;
}

#wraper {
  width: 400px;
  height: 350px;
  margin: 90px 11px;
  position: relative;
  border-radius: 40px;
}

#wraper2 {
  width: 450px;
  height: 350px;
  margin: 0 auto;
  position: relative;
  border-radius: 40px;
  margin-left: -36px;
}


</style>
<style lang="scss" scoped>

.main-title {
  background: linear-gradient(0deg, rgba(205, 212, 255, 0.45), #FFF);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #3a5fbb;
  font-family: Source Code Pro,monospace !important;
  box-shadow: none;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

$message-header-background-color: #e3f;


.loading-page-container {
  opacity: .95;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  z-index: 1;

}

.loading-page-container .progress {
  position: relative;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 6px solid #20182d;
  background-color: #2c2938;
  background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%, rgb(107 114 129) 0%, rgb(107 114 129) 100.2% );
  background-blend-mode: hard-light;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;

  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 1;
}

.loading-page-container .progress .inner {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: 50%;
  margin-left: -4px;
  margin-top: -1px;
  width: 196.5px;
  height: 196.5px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.loading-page-container .progress .inner .water {
  opacity: .55;
  position: absolute;
  z-index: 1;
  width: 200%;
  height: 200%;
  left: -50%;
  border-radius: 40%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  animation-name: spin;
  top: 55%;
  background: #32BAFA;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  box-shadow: 0 0 20px #152A4A;

}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btm {
  cursor: pointer;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(51, 64, 71, 0.4),
  -1px -1px 4px 2px rgba(56, 68, 83, 0.4);
  transition: background 0.1s ease-in-out;
  display:flex;
  width: 35px;
  height: 35px;
  border-radius: 0.5rem;
  color: #296992;
}
.btm:hover {
  color: #48ad9e;
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}



.btm:active {
  background-color: rgba(35, 44, 57, 1);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2),
  -10px -10px 20px 5px rgba(54, 48, 76, 0.4),
  -1px -1px 4px 2px rgba(48, 43, 68, 0.4);
  border: none !important;
  outline: none !important;
}


// for styles that do not have ascii characters setting the height
$height: 15px;

.outer {
  margin-bottom: $height;
  width: 100%;
  height: $height;
}

.plain {

  &.outer {
    border: 1px solid limegreen;
  }

  .inner {
    width: 90%;
    background-color: limegreen;
    height: $height;
  }
}

.borderless .inner {
  width: 90%;
  background-color: limegreen;
  height: $height;
}

.punctuation.outer {
  border: 1px solid limegreen;
  line-height: 1.35;
}

@-webkit-keyframes progress-bar-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes spinning {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes pulsing-opacity {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}


.plain .inner, .borderless .inner {
  animation: progress-bar-width steps(20) 5s infinite;
}

.pipes .inner, .blocks .inner, .blocks-gaps {
  animation: progress-bar-width 5s infinite;
  overflow: hidden;
  animation-timing-function: linear;
}

.blocks-gaps .inner {
  letter-spacing: 2px;
}

.activity {
  text-align: center;
  overflow: hidden !important;
}

.spinning {
  animation: spinning steps(8, end) 8s infinite;
}

.pulsing {
  animation: pulsing-opacity steps(3) 0.5s infinite alternate;
}

.main-timeline{
  font-family: "Source Code Pro", monospace;
  position: relative;
}
.main-timeline:before{
  content: '';
  width: 5px;
  height: 100%;
  border-left: 5px dashed #a6eac0;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 1;
}
.main-timeline:after{
  content: '';
  display: block;
  clear: both;
}
.main-timeline .timeline{
  width: calc(50% + 25px);
  padding: 0;
  margin: 0 0 0 50px;
  float: right;
  position: relative;
}
.main-timeline .timeline-content{
  color: #223835;
  padding: 15px 60px 15px 75px;
  border-top: 3px solid #18082d;
  display: block;
  position: relative;
}
.main-timeline .timeline-content:hover{ text-decoration: none; }
.main-timeline .timeline-content:before,
.main-timeline .timeline-content:after{
  content: '';
  background: linear-gradient(to bottom, rgba(255, 69, 104, 0.69), rgba(253, 38, 97, 0.71));
  height: 100%;
  width: 50px;
  position: absolute;
  left: 0;
  top: 0;
}
.main-timeline .timeline-content:after{
  background: #3aba6c;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  left: auto;
  right: 0;
  top: -5px;
}
.main-timeline .timeline-icon{
  color: #3aba6c;
  font-size: 40px;
  text-shadow: 0 0 5px rgba(0,0,0,0.1);
  position: absolute;
  top: 20px;
  right: 0;
}
.main-timeline .title{
  color: rgba(253, 38, 97, 0.66);
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 7px 0;
}
.main-timeline .description{
  font-size: 14px;
  letter-spacing: 1px;
  text-align: justify;
  margin: 0;
}
.main-timeline .timeline:nth-child(even){
  margin: 0 50px 0 0;
  float: left;
}
.main-timeline .timeline:nth-child(even) .timeline-content{ padding: 15px 70px 15px 60px; }
.main-timeline .timeline:nth-child(even) .timeline-content:before{
  left: auto;
  right: 0;
}
.main-timeline .timeline:nth-child(even) .timeline-content:after{
  right: auto;
  left: 0;
}
.main-timeline .timeline:nth-child(even) .timeline-icon{
  right: auto;
  left: 0;
}
.main-timeline .timeline:nth-child(4n+2) .timeline-content:before{
  background: linear-gradient(to bottom, rgba(255, 138, 45, 0.58), rgba(254, 101, 41, 0.79));
}
.main-timeline .timeline:nth-child(4n+2) .title{ color: rgba(254, 101, 41, 0.56); }
.main-timeline .timeline:nth-child(4n+3) .timeline-content:before{
  background: linear-gradient(to bottom, rgba(1, 169, 218, 0.55), rgba(0, 118, 244, 0.64));
}
.main-timeline .timeline:nth-child(4n+3) .title{ color: rgba(0, 118, 244, 0.49); }
.main-timeline .timeline:nth-child(4n+4) .timeline-content:before{
  background: linear-gradient(to bottom, rgba(60, 236, 163, 0.58), rgba(17, 173, 159, 0.68));
}
.main-timeline .timeline:nth-child(4n+4) .title{ color: rgba(17, 173, 159, 0.6); }
@media screen and (max-width:767px){
  .main-timeline:before{
    transform: translateX(0);
    left: 22px;
  }
  .main-timeline .timeline,
  .main-timeline .timeline:nth-child(even){
    width: 100%;
  }
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(even) .timeline-content{
    padding: 15px 60px 15px 70px;
  }
  .main-timeline .timeline:nth-child(even) .timeline-content:before{
    right: auto;
    left: 0;
  }
  .main-timeline .timeline:nth-child(even) .timeline-content:after{
    right: 0;
    left: auto;
  }
  .main-timeline .timeline:nth-child(even) .timeline-icon{
    left: auto;
    right: 0;
  }
}
@media screen and (max-width:567px){
  .main-timeline .title{ font-size: 18px; }
}

</style>
<style scoped>
.container3 {
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  /* padding: 25px; */
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background-image: linear-gradient(90deg, transparent 15%, rgba(129, 132, 135, 0.05), #0f5cbb26 85%), radial-gradient(rgba(38, 58, 74, 0.75), transparent 70%), linear-gradient(#0e081a00, #211922, #0f142000);
  background-size: 10px 100%, 200px 200px, 100% 100%;
  background-position: 0 0, center center, 0 0;
  background-repeat: repeat, no-repeat, no-repeat;
}
.cow {
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#14ff7d, #00f3ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}
</style>

<style lang="scss" scoped>
.container2 {
  background-color: #1D1F21;
  border-style: solid;
  border-width: 20px 10px 20px 10px;
  border-color: #1b222e;
  border-top-color: #191f2a;
  border-bottom-color: #191f2a;
  padding: 25px;
  font-family: Source Code Pro, monospace;
  font-size: 13px;
  background: -webkit-linear-gradient(#3e10463d, #21333e70) !important;
  box-shadow: 0 0 2px 0 #26272c, 0 0 1px 1px #131723 inset, 0 0 3px #1b1e23 inset, 0 0 3px 0.5px #2a2a3b inset, 0 0 5px #1b1d2d inset, 9px 0 37px 59px #0e192257 inset, 0 0px 2px #ff0ef8 inset;
  -webkit-animation: scanline-data-v-0d33d4c2 8s linear infinite;
  animation: scanline-data-v-0d33d4c2 8s linear infinite;
}

article {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 900px) {
    padding: 5px;
  }

  @media (max-width: 899px) {
    padding-bottom: 56px;
  }
  background-color: rgba(192, 192, 192, 0);
  color: rgb(69 27 82);
  text-shadow: 0px 0px 4px rgb(34 126 126);
  background: -webkit-linear-gradient(#14ff7d, #00f3ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  position: relative;
  & > * + * {
    margin-top: 1.25em;
  }

  &:after {
    content: "🥩";
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding-bottom: 0.25em;
    position: absolute;
    top: 0.75em;
    right: 0.75em;
    width: 1.75em;
    height: 1.75em;
    filter: invert(100) opacity(40%);

    background-color: transparent;
  }
}

.headline {
  font-size: 2.5em;
  font-weight: 700;
}

.subhead {
  font-size: 1.25em;
  margin-top: 0.25em;
}

.article-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    width: 3em;
    height: 3em;
    flex-shrink: 0;
  }

  div:first-of-type {
    margin-left: 0.75em;
    font-size: 0.875em;
    margin-right: auto;
    padding-right: 0.75em;
  }

  div:last-of-type {
    font-size: 0.875em;
  }
}

aside {
  padding: 4em;
  border-right: 1px solid rgba(171, 15, 232, 0.1);
  border-bottom: 1px solid rgba(171, 15, 232, 0.1);
  border-left: 1px solid rgba(171, 15, 232, 0.1);
  border-top: 1px solid rgba(171, 15, 232, 0.1);
  clear: both;
  text-shadow: 0 0 10px, 0 0 40px;
  background-color: rgb(46 40 66 / 39%);
}

.tag {
  display: inline-block;
  margin-right: 0.25em;
  margin-top: 0.25em;
  border-right: 1px solid rgba(238, 238, 238, 0.19);
  border-bottom: 1px solid rgba(238, 238, 238, 0.13);
  border-left: 1px solid #444;
  border-top: 1px solid #444;
  padding: 0.125em 0.375em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}



figure {
  background-color: #c0c0c0;
  box-shadow: 0 0 0 1px #eee, 0 0 0 2px #c0c0c0, 0 0 0 3px #444;
  position: relative;
  margin-bottom: 1.25em;
  width: calc(100% + 1.5em + 2em);
  text-shadow: 0 0 10px, 0 0 40px;
  @media (min-width: 800px) {
    max-width: 50%;
  }
  &:nth-of-type(odd) {
    float: left;
    margin-right: 1.5em;
    margin-left: 1em;
  }

  &:nth-of-type(even) {
    float: right;
    margin-left: 1.5em;
    margin-right: -3em;
  }

  &:after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    background-image: linear-gradient(
            to top,
            rgba(#462a55, 0.2) 0%,
            rgba(#08392c, 0.2) 60%
    ),
    linear-gradient(20deg, rgba(#4c2d71, 0.5) 0%, rgba(#38167d, 0) 35%);
    box-shadow: inset 0px 0px 100px rgba(0, 0, 20, 1);
    z-index: 1;
  }

  img {
    // opacity: 0;
    // filter: hue-rotate(90deg);
    // filter: sepia(20%) brightness(10%) contrast(130%)
  }
  figcaption {
    font-family: "DotGothic16", monospace;
    background-color: #6f4b80;
    box-shadow: inset 0 -1px 0 0 #020202;
    color: #612d72;
    text-shadow: 0 0 10px, 0 0 40px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.25em;
    font-size: 0.875em;
    z-index: 2;
  }
}
.blink {
  opacity: 1;
  animation: blink 1s linear infinite;
}

.scanline  {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba(255,255,255,0.02);
  animation: scanline 8s linear infinite;
  pointer-events: none;
  &:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: rgba(255,255,255,0.02);
    animation: scanline 8s linear infinite;
    pointer-events: none;
    z-index: 10;
  }

}

// Scanline height 1005
@keyframes scanline {
  0.01% {
    height: 0;
  }
  99.99% {
    height: 100%
  }
  100% {
    height: 0;
  }
}
</style>
